export const PUSHER_EVENTS = {
  addToChat: 'AddToChat',
  newMessage: 'NewMessage',
  liveChatNewUserMessage: 'NewUserMessage',
  liveChatNewChat: 'NewChannelCreated',
};

export const PUSHER_CHANNELS = {
  liveChatNotificationChannel: 'assignee-:userId',
};

export default {};
