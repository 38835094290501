import appConfig from '@configs/appConfig';
import postWithResponse from '@services/api/common/postWithResponse';
import { getHeaders } from '@utils/transfered';

export const getRegexExpression = data =>
  postWithResponse(appConfig.baseUrl.concat('/Parsers/getRegExpression'), data);

export const getOnlyLetterNumberText = text => {
  return text
    ? text
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/[^a-zA-Z0-9]+/g, '')
    : '';
};

export const getData = async (data, onClose, redirect) => {
  const url = `${appConfig.baseUrl}/parsers/test`;
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    redirect('login');
  }
  if (response.status < 200 || response.status >= 300) {
    onClose();
  }
  return response.json();
};
