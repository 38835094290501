import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { ExpandMore } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { OptInSettingsInit } from '@store/slices/optInSettings';
import { ThunkSaveOptInSettings } from '@store/slices/optInSettings/thunks';

import CheckBoxWrapper from '@components/Settings/OptInSettings/components/CheckBoxWrapper';
import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import MinutesPeriodPicker from '@common/form/DateTime/MinutesPeriodPicker';

import { formSet, optInSettings } from '../constants';

const OptInConfiguration = () => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const initialValues = useSelector(OptInSettingsInit);

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkSaveOptInSettings({ ...initialValues, ...values }))
      .unwrap()
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Opt-in Settings
      </AccordionSummary>

      <AccordionDetails>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {optInSettings.map(i => (
                  <CheckBoxWrapper
                    key={i.id}
                    id={i.id}
                    label={i.label}
                    formSet={formSet}
                    processing={processing}
                  />
                ))}
              </div>

              <FieldWrapper
                label="Opt-in link expires in"
                labelSize={3}
                contentSize={9}
                fullWidth
                content={
                  <Field
                    name="expirationMin"
                    id="ExpirationMin"
                    render={MinutesPeriodPicker}
                    disabled={processing}
                  />
                }
              />

              <ReusableButton label="Update" onClick={handleSubmit} />
            </div>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OptInConfiguration;
