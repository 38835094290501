import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0px',
    margin: '20px 80px',
    fontStyle: 'normal',
  },
  gridContainer: {
    display: 'block',
  },
  gridLabelOuter: {
    textAlign: 'left',
    height: 'auto',
  },
  classNameLabelInner: {
    maxWidth: 'inherit',
  },
  disabledInput: {
    '& :disabled': {
      backgroundColor: '#e5e5e5',
    },
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
    width: '100%',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentFormWrapper: {
    width: '100%',
  },
  notificationTypes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    width: '100%',
    marginTop: '2.5rem',
  },
  inputWrapper: {
    marginBottom: '0.625rem',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:last-child': {
        marginLeft: '1.875rem',
      },
    },
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  actionButton: {
    borderRadius: '20px',
    cursor: 'pointer',
    minWidth: '28px',
    margin: '0 5px 0 5px',
    '&:hover': {
      background: '#efefef',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
  iconContainer: {
    margin: '0px 5px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  notificationTypesContainer: {
    padding: '10px 0',
  },
  rounded: {
    fontStyle: 'normal',
    borderRadius: '0.3125rem',
  },
  fullWidth: {
    width: '100%',
  },
  tilesSpace: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'space-between',
    },
    marginTop: '1rem',
  },
  typesContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  typesIcon: {
    margin: '0.8rem',
    width: '1.8rem',
  },
  textDecorator: {
    textAlign: 'center',
    fontSize: 'initial',
    color: '#8f8f8f',
  },
}));

export default useStyles;
