import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '90vh',
    overflowY: 'auto',
  },
  paper: {
    width: '80%',
  },
  display: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem',
    padding: '1rem',
  },
  inputStyle: {
    width: '30%',
    margin: '0 1rem',
  },
  buttonsBlock: {
    width: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1rem',
  },
}));

export default useStyles;
