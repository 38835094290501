import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@assets/icons/delete.svg';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { deleteAutotaskWebhook } from '../helpers';

const TableItemActions = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(
    id => {
      setLoading(true);
      deleteAutotaskWebhook(id)
        .then(() => {
          enqueueSnackbar('Successfully delete', { variant: 'success' });
          setOpenDialog(false);
          dispatch(
            actions.saveList(list.filter(item => item.id !== record.id)),
          );
        })
        .catch(err => {
          enqueueSnackbar(err.message, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [enqueueSnackbar, dispatch, list, record],
  );

  return (
    <div>
      <ActionFieldItem
        icon={DeleteIcon}
        alt="delete"
        handler={() => {
          setOpenDialog(true);
        }}
        toolTip="Delete"
      />
      {openDialog && (
        <ConfirmDialog
          open={openDialog}
          onCancel={() => {
            setOpenDialog(false);
          }}
          onAccept={() => {
            handleDelete(record.id);
          }}
          loading={loading}
          title="Deleting Item"
          content="Are you sure you want to delete this item?"
        />
      )}
    </div>
  );
};

TableItemActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default TableItemActions;
