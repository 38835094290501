import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import crmSources from '@constants/crmSources';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import ConnectWiseCreateTicketForm from '@components/CreateTicket/components/ConnectWiseCreateTicketForm';

import AutotaskCreateTicketForm from '@components/CreateTicket/components/AutotaskCreateTicketForm';
import FieldWrapper from '@common/form/FieldWrapper';
import KaseyaCreateTicketForm from '@components/CreateTicket/components/KaseyaCreateTicketForm';

import useStyles from './styles';

/*
 * Creating ticket WITHOUT CRM DEFAULT ID
 * */
const CreateTicket = () => {
  const [loading, setLoading] = useState(true);
  const [selectedPsa, setSelectedPsa] = useState(0);
  const [crmOptions, setCrmOptions] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles({
    psaSelected: selectedPsa !== 0,
  });

  const form = useMemo(() => {
    const selected = crmOptions.find(option => option.id === selectedPsa);

    if (selected) {
      switch (selected.crmType) {
        case crmSources.ConnectWise.name:
          return <ConnectWiseCreateTicketForm psaId={selected.id} />;
        case crmSources.Autotask.name:
          return <AutotaskCreateTicketForm psaId={selected.id} />;
        case crmSources.Kaseya.name:
          return <KaseyaCreateTicketForm psaId={selected.id} />;
        default:
          return <></>;
      }
    }
    return <></>;
  }, [crmOptions, selectedPsa]);

  useEffect(() => {
    if (!!crmOptions && crmOptions.length === 1) {
      setSelectedPsa(crmOptions[0].id);
    }
  }, [crmOptions]);

  const onMount = useCallback(async () => {
    setLoading(true);
    const options = await dispatch(ThunkGetCrmSources()).unwrap();
    setCrmOptions(
      options.filter(
        i =>
          i.crmType === crmSources.ConnectWise.name ||
          i.crmType === crmSources.Autotask.name ||
          i.crmType === crmSources.Kaseya.name,
      ),
    );
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={cx(classes.ddContainer, classes.boxShadow)}>
        <FieldWrapper
          label="PSA integration"
          labelSize={12}
          contentSize={12}
          content={
            <DropDown
              input={{
                value: selectedPsa,
                onChange: e => {
                  setSelectedPsa(e.target.value);
                },
              }}
              options={crmOptions}
              valueName="id"
              labelName="name"
            />
          }
        />
      </div>

      <div style={{ height: '70%' }}>{form}</div>
    </div>
  );
};

export default CreateTicket;
