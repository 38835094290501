import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';

import useStyles from '../styles';

const ClientPortalSupportInfo = () => {
  const classes = useStyles();

  const fields = useMemo(() => {
    return [
      { name: 'Company Name', field: 'techCompanyName' },
      { name: 'Company Phone', field: 'techPhone' },
      { name: 'On-Call Technician', field: 'techName' },
      { name: 'On-Call Email', field: 'techEmail' },
      { name: 'On-Call Phone Number', field: 'afterHoursTechPhone' },
      { name: 'On-Call SMS', field: 'afterHoursTechEmail' },
    ];
  }, []);

  return (
    <Grid container spacing={2} className={classes.wrapper}>
      {fields.map(f => (
        <Grid key={f.field} item xs={6}>
          <FieldWrapper
            label={f.name}
            labelSize={12}
            contentSize={12}
            fullWidth
            content={
              <Field
                name={f.field}
                id={f.field}
                render={Input}
                dateProps={{ maxLength: 200 }}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ClientPortalSupportInfo;
