import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '0.9375rem',
  },
  margin: {
    marginRight: '5px',
  },
  toolBar: {
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      marginTop: '-65px',
    },
    '@media (min-width: 769px) and (max-width: 1024px)': {
      marginTop: '-100px',
      marginRight: '-15px',
      display: 'grid',
    },
  },
  buttonWrapper: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1rem',
    },
  },
}));

export default useStyles;
