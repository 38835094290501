import React, { useState } from 'react';
import useAsync from '@services/api/common/useAsync';
import { storageUsage } from '@components/Settings/TenantStatistic/helpers';
import Loading from '@common/Loading/Loading';
import SourceItemValue from './SourceItemValue';

const Storage = () => {
  const [data, setData] = useState({});
  const loading = useAsync(storageUsage, setData);

  if (loading) return <Loading />;
  return (
    <div>
      <SourceItemValue
        value={data?.lettersSizeMb}
        label="Letters container"
        endOfLine="Mb;"
      />
      <SourceItemValue
        value={data?.userImagesSizeMb}
        label="User images container"
        endOfLine="Mb;"
      />
    </div>
  );
};

export default Storage;
