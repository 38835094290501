import { useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { splitCsl } from '@components/Settings/Types/helpers';
import { useSafeSetState } from 'react-admin';
import {
  defaultBgColor,
  defaultCircleBgColor,
  defaultCircleBorderColor,
  defaultFontColor,
} from '@constants/defaultBageColors';

const useValueTypeDialog = item => {
  const [chipData, setChipData] = useState(
    item && item.values ? splitCsl(item.values) : [],
  );
  const [name, setName] = useState(item ? item.name : '');
  const [loading, setLoading] = useSafeSetState(false);
  const [textColor, setTextColor] = useState(
    item && item.fontColor ? item.fontColor : defaultFontColor,
  );
  const [bgColor, setBgColor] = useState(
    item && item.backgroundColor ? item.backgroundColor : defaultBgColor,
  );
  const [icon, setIcon] = useState(item && item.icon ? item.icon : '');
  const [circleBgColor, setCircleBgColor] = useState(
    item && item.circleBgColor ? item.circleBgColor : defaultCircleBgColor,
  );
  const [circleBorderColor, setCircleBorderColor] = useState(
    item && item.circleBorderColor
      ? item.circleBorderColor
      : defaultCircleBorderColor,
  );

  const inputTextRef = useRef();

  const handleArrowClick = form => {
    const input = inputTextRef.current;
    if (input && input.value) {
      const inputText = input.value.replace(',', `\\,`);
      if (chipData.indexOf(inputText) > -1) {
        enqueueSnackbar('Record Exist', { variant: 'info' });
      } else {
        const newChips = [...chipData];
        newChips.push(inputText);
        setChipData(newChips);
        form.reset();
      }
    }
  };

  const handleChipsChange = (form, event) => {
    if (event.charCode === 13 && event.target.value) {
      event.preventDefault();
      handleArrowClick(form);
    }
  };

  return {
    circleBorderColor,
    setCircleBorderColor,
    circleBgColor,
    setCircleBgColor,
    icon,
    setIcon,
    bgColor,
    setBgColor,
    textColor,
    setTextColor,
    loading,
    setLoading,
    name,
    setName,
    chipData,
    setChipData,
    inputTextRef,
    handleArrowClick,
    handleChipsChange,
  };
};

export default useValueTypeDialog;
