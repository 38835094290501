import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { kaseyaSendCode } from '@services/requests/kaseyaTicket';

export const ThunkKaseyaSendCode = createAsyncThunk(
  `${KASEYA_TICKET}/kaseyaSendCode`,
  payload => kaseyaSendCode(payload),
);

export default {};
