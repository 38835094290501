import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { formatDate } from '@services/requests/common';

export const ThunkConvertDateTime = createAsyncThunk(
  `${COMMON}/convertDate`,
  async ({ value }) => {
    const res = await formatDate(value);
    return res;
  },
);

export default {};
