import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MESSAGING_TEMPLATES } from '@services/requests/requestResources';
import { CREATE_TRIAGE_TICKET } from '@constants/routes';

import ReusableButton from '@ui/components/common/Button/Button';
import AttachTicketDialog from '../dialogs/AttachTicketDialog';

import useStyles from '../styles';

const TableActions = () => {
  const [openLinkTicketDialog, setOpenLinkTicketDialog] = useState(false);
  const { push } = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.tableActionContainer}>
      <ReusableButton
        label="Templates"
        onClick={() => {
          push(MESSAGING_TEMPLATES);
        }}
      />
      <ReusableButton
        label="Add Ticket"
        onClick={() => {
          push(CREATE_TRIAGE_TICKET);
        }}
      />
      <ReusableButton
        label="Attach Ticket"
        onClick={() => {
          setOpenLinkTicketDialog(true);
        }}
      />

      {openLinkTicketDialog && (
        <AttachTicketDialog
          open={openLinkTicketDialog}
          setOpen={setOpenLinkTicketDialog}
        />
      )}
    </div>
  );
};

TableActions.propTypes = {};

export default TableActions;
