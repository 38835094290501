import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { addVerificationPhoneNumber } from '@services/requests/verificationSettings';

export const ThunkAddVerificationPhoneNumber = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/addVerificationPhoneNumber`,
  async payload => {
    const res = await addVerificationPhoneNumber(payload);
    return res;
  },
);

export default {};
