import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketTimeEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';
import moment from 'moment';

export const ThunkGetConnectWiseTicketTimeEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketTimeEntry`,
  async ({ crmId, timeEntryId }) => {
    const timeEntry = await getConnectWiseTicketTimeEntry(crmId, timeEntryId);
    return {
      ...timeEntry,
      startDateTime: moment(timeEntry.startDateTime).format('YYYY-MM-DDThh:mm'),
      endDateTime: moment(timeEntry.endDateTime).format('YYYY-MM-DDThh:mm'),
    };
  },
);

export default {};
