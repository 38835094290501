import React from 'react';
import ColorForm from '@components/Settings/CustomPalette/components/ColorForm';

import useStyles from './styles';

const CustomPalette = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <ColorForm />
      </div>
    </div>
  );
};

export default CustomPalette;
