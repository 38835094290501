import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getAllKaseyaContacts } from '@services/requests/kaseyaTicket';

export const ThunkGetAllKaseyaContacts = createAsyncThunk(
  `${KASEYA_TICKET}/getAllKaseyaContacts`,
  ({ crmId }) => getAllKaseyaContacts(crmId),
);

export default {};
