import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getIntegrationOrganizations,
  getSophosTenantAlerts,
} from '@components/DataSources/helpers';
import { integrationSources } from '@constants/integrationSources';
import BagelsContainer from '@components/DataSources/Dashboards/Statistic/BagelsContainer';
import CircularStatic from '@components/DataSources/Dashboards/CircularProgress';
import statusColors from '@components/DataSources/Dashboards/statusColors';
import SophosAlertGrid from '@components/DataSources/Dashboards/Sophos/alerts/SophosAlertGrid';

const transformData = data =>
  data.map(i => {
    return {
      id: i.id,
      category: i.category,
      description: i.description,
      product: i.product,
      raisedAt: i.raisedAt,
      severity: i.severity,
      tenantName: i.tenant.name,
      type: i.type,
    };
  });

const bagelsField = 'severity';

const bagelItems = {
  high: { title: 'HIGH', color: statusColors.high, total: 0, count: 0 },
  medium: { title: 'MEDIUM', color: statusColors.medium },
  low: { title: 'LOW', color: statusColors.low },
};

const SophosAlertsDashboard = ({ loading, setLoading }) => {
  const [data, setData] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const [statusFilter, setStatusFilter] = useState(null);

  const adjustProgress = (current, total) => {
    setProgress((100 * current) / total);
  };

  useEffect(() => {
    getIntegrationOrganizations(integrationSources.Sophos.name)
      .then(i => {
        let dt = [];
        for (let s = 0, p = Promise.resolve(); s <= i.length; s += 1) {
          p = p
            .then(() =>
              s !== i.length
                ? getSophosTenantAlerts(
                    i[s].integrationId,
                    i[s].organizationId,
                    i[s].apiHost,
                  )
                : Promise.resolve(null),
            )
            // eslint-disable-next-line no-loop-func
            .then(rsp => {
              if (rsp === null) {
                setData(dt);
                setLoading(false);
                return;
              }
              dt = dt.concat(transformData(rsp));
            })
            .catch(e => console.log(e.message))
            .finally(() => adjustProgress(s + 1, i.length));
        }
      })
      .catch(e => console.log(e.message));
  }, []);

  const filteredData = () => {
    if (!statusFilter) return data;
    return data.filter(
      item => item[bagelsField] === statusFilter.toLowerCase(),
    );
  };

  return !loading ? (
    <div>
      <BagelsContainer
        data={data}
        field={bagelsField}
        setStatusFilter={setStatusFilter}
        items={bagelItems}
      />
      <SophosAlertGrid data={filteredData()} />
    </div>
  ) : (
    <CircularStatic progress={progress} />
  );
};

SophosAlertsDashboard.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default SophosAlertsDashboard;
