import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { TableContext } from '@components/Table/context';

import useStyles from '../styles';

const TableBulkActions = ({ className }) => {
  const classes = useStyles();
  const { bulkActions, selectedIds } = useContext(TableContext);

  return (
    <div className={cx(classes.bulk, classes.sticky, className)}>
      <div>{bulkActions}</div>
      <div>{`Selected Items: ${selectedIds.length}`}</div>
    </div>
  );
};

TableBulkActions.propTypes = {
  className: PropTypes.string,
};

export default TableBulkActions;
