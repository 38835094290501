import React, { useMemo } from 'react';
import { TEAMS_CONNECTIONS } from '@services/requests/requestResources';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import TeamsConnectionsTableActions from './components/TeamsConnectionsTableActions';
import TeamsConnectionsTableItemActions from './components/TeamsConnectionsTableItemActions';

import useStyles from './styles';

const TeamsConnections = () => {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Url',
        key: 'url',
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 150,
        accessor: record => (
          <TeamsConnectionsTableItemActions record={record} />
        ),
      },
    ],
    [],
  );
  return (
    <div className={classes.container}>
      <TeamsConnectionsTableActions />
      <Table
        defaultSort={{ fieldName: 'name', order: 'ASC' }}
        columns={columns}
        resource={TEAMS_CONNECTIONS.replace('/', '')}
      />
    </div>
  );
};

export default TeamsConnections;
