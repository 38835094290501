import composeValidators from '@utils/validators/composeValidators';
import { requiredValidator } from '@utils/validators';

const maxDeviceNameLength = 500;

const deviceNameLength = value => {
  if (value.trim().length > maxDeviceNameLength) {
    return 'validation.deviceName.length';
  }
};

const deviceNameValidator = url =>
  composeValidators(requiredValidator, deviceNameLength)(url);

export default deviceNameValidator;
