/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { FILE_UPLOAD } from '@store/slices/resources';
import {
  ThunkGetAutotaskConfigurationItems,
  ThunkGetAutotaskPsaCompanies,
} from '@store/slices/fileUpload/thunks';

const fileUploadSlice = createSlice({
  name: FILE_UPLOAD,
  reducers: {},
  initialState: {
    companies: [],
    items: [],
  },
  extraReducers: builder => {
    builder.addCase(ThunkGetAutotaskPsaCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    builder.addCase(
      ThunkGetAutotaskConfigurationItems.fulfilled,
      (state, action) => {
        state.items = action.payload;
      },
    );
  },
});

export default fileUploadSlice.reducer;
export const FileUploadOptionsSelector = state => state[FILE_UPLOAD];
