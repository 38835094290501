import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { kaseyaCheckCode } from '@services/requests/kaseyaTicket';

export const ThunkKaseyaCheckCode = createAsyncThunk(
  `${KASEYA_TICKET}/kaseyaCheckCode`,
  payload => kaseyaCheckCode(payload),
);

export default {};
