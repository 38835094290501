import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmTicketPhoneNumber } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetCrmTicketPhoneNumber = createAsyncThunk(
  `${CRM_TICKET}/getCrmTicketPhoneNumber`,
  async ({ crmId, ticketId }) => {
    const phoneNumber = await getCrmTicketPhoneNumber(crmId, ticketId);
    return phoneNumber;
  },
);

export default {};
