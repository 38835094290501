import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChannelLogs } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetConfigurationLogs = createAsyncThunk(
  `${MESSAGING}/getConfigurationLogs`,
  async ({ channelId = null, configurationId }) => {
    const logs = await getChannelLogs(configurationId, channelId);
    return logs.map(({ isSuccess, ...rest }) => ({
      color: isSuccess ? 'black' : 'red',
      ...rest,
    }));
  },
);

export default {};
