/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import {
  ThunkCreateKaseyaTicketNote,
  ThunkDeleteKaseyaTicketNote,
  ThunkGetKaseyaAccounts,
  ThunkGetKaseyaAssignees,
  ThunkGetKaseyaContacts,
  ThunkGetKaseyaIssueSubTypes,
  ThunkGetKaseyaIssueTypes,
  ThunkGetKaseyaLocations,
  ThunkGetKaseyaPriorities,
  ThunkGetKaseyaQueues,
  ThunkGetKaseyaStatuses,
  ThunkGetKaseyaTicketNotes,
  ThunkGetKaseyaTypes,
  ThunkUpdateKaseyaTicketNote,
} from '@store/slices/kaseyaTicket/thunks';

const kaseyaTicketSlice = createSlice({
  name: KASEYA_TICKET,
  initialState: {
    accounts: [],
    locations: [],
    contacts: [],
    queues: [],
    statuses: [],
    priorities: [],
    types: [],
    issueTypes: [],
    issueSubTypes: [],
    notes: [],
    assignees: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetKaseyaAccounts.fulfilled, (state, { payload }) => {
      state.accounts = payload;
    });

    builder.addCase(ThunkGetKaseyaContacts.fulfilled, (state, { payload }) => {
      state.contacts = payload;
    });

    builder.addCase(
      ThunkGetKaseyaIssueSubTypes.fulfilled,
      (state, { payload }) => {
        state.issueSubTypes = payload;
      },
    );

    builder.addCase(
      ThunkGetKaseyaIssueTypes.fulfilled,
      (state, { payload }) => {
        state.issueTypes = payload;
      },
    );

    builder.addCase(ThunkGetKaseyaLocations.fulfilled, (state, { payload }) => {
      state.locations = payload;
    });

    builder.addCase(
      ThunkGetKaseyaPriorities.fulfilled,
      (state, { payload }) => {
        state.priorities = payload;
      },
    );

    builder.addCase(ThunkGetKaseyaQueues.fulfilled, (state, { payload }) => {
      state.queues = payload;
    });

    builder.addCase(ThunkGetKaseyaStatuses.fulfilled, (state, { payload }) => {
      state.statuses = payload;
    });

    builder.addCase(ThunkGetKaseyaTypes.fulfilled, (state, { payload }) => {
      state.types = payload;
    });

    builder.addCase(
      ThunkGetKaseyaTicketNotes.fulfilled,
      (state, { payload }) => {
        state.notes = payload;
      },
    );

    builder.addCase(
      ThunkCreateKaseyaTicketNote.fulfilled,
      (state, { payload }) => {
        state.notes.push(payload);
      },
    );

    builder.addCase(
      ThunkUpdateKaseyaTicketNote.fulfilled,
      (state, { payload }) => {
        state.notes = state.notes.map(note =>
          note.id === payload.id ? payload : note,
        );
      },
    );

    builder.addCase(
      ThunkDeleteKaseyaTicketNote.fulfilled,
      (state, { payload }) => {
        state.notes = state.notes.filter(note => note.id !== payload.id);
      },
    );

    builder.addCase(ThunkGetKaseyaAssignees.fulfilled, (state, { payload }) => {
      state.assignees = payload;
    });
  },
});

export default kaseyaTicketSlice.reducer;
export const KaseyaTicketOptionsSelector = state => state[KASEYA_TICKET];
export const KaseyaTicketNotesSelector = state => state[KASEYA_TICKET].notes;
