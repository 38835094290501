import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  div: {
    height: '1.25rem',
    width: '1.25rem',
    borderRadius: '50%',
    display: 'inline-block',
  },
}));

export default useStyles;
