const initConfiguration = {
  gradientColor1: '#CFCFCF',
  gradientColor2: '#545B6B',
  headerText: 'Psa Contact Validation',
  headerColor: 'black',
  successMessageText: 'You pass the verification. Close this window',
  successMessageColor: '#0e601c',
  failedMessageText: 'You do not pass the validation. ${error}',
  failedMessageColor: '#b11212',
};

export default initConfiguration;
