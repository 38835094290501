import React, { useState } from 'react';
import useAsync from '@services/api/common/useAsync';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import clone from 'lodash/clone';
import { histogramConfig } from '@components/Dashboard/GridLayout/Tile/Layouts/constants';
import forEach from 'lodash/forEach';
import { createTheme } from '@material-ui/core';
import { lightTheme } from '@services/themes/mainTheme';
import useStyles from '../../styles';
import { getStat } from '../../../../helpers';

const theme = createTheme(lightTheme());

const Statistic = () => {
  const [data, setData] = useState({});
  useAsync(getStat, setData);
  const classes = useStyles();
  const dataLoaded = data && data.length > 0;

  const getHistogramConfig = inputData => {
    const chartConfig = clone(histogramConfig(theme));
    const emailCounts = [];
    const smsCounts = [];
    const categories = [];
    forEach(inputData, item => {
      categories.push(item.name);
      emailCounts.push(item.emailCount);
      smsCounts.push(item.smsCount);
    });
    chartConfig.xAxis = { categories, type: 'category' };
    chartConfig.series = [
      // {
      //   id: '1',
      //   name: 'email',
      //   data: emailCounts,
      // },
      {
        id: '2',
        name: 'sms',
        data: smsCounts,
      },
    ];
    chartConfig.tooltip = {
      shared: true,
      useHTML: true,
      formatter() {
        const self = this;
        let formattedString = '<small></small><table>';
        self.points.forEach(elem => {
          formattedString += `<tr><td style="color: {series.color}">${elem.series.name}: </td>`;
          formattedString += `<td style="text-align: right"><b>${elem.y}</b></td></tr>`;
        });
        return formattedString;
      },
    };
    return chartConfig;
  };
  return dataLoaded ? (
    <div className={classes.chartContainer} style={{ height: 300 }}>
      <HighchartsReact
        containerProps={{ style: { height: '100%' } }}
        highcharts={Highcharts}
        options={getHistogramConfig(data)}
      />
    </div>
  ) : (
    <div />
  );
};

export default Statistic;
