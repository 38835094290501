import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  badge: {
    border: '1px solid #dfdfdf',
    borderRadius: '13px',
    padding: '3px 5px',
  },
  badgeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const FieldsBadges = ({ input, selector = i => i }) => {
  const classes = useStyles();
  const bages = useMemo(() => input.map(selector), [input]);
  return (
    <div className={classes.badgeContainer}>
      {bages.map(i => (
        <div className={classes.badge} key={i}>
          {i}
        </div>
      ))}
    </div>
  );
};

FieldsBadges.propTypes = {
  input: PropTypes.arrayOf(PropTypes.any),
  selector: PropTypes.func,
};

export default FieldsBadges;
