import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTicketLink } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetTicketLink = createAsyncThunk(
  `${CRM_TICKET}/getTicketLink`,
  async ({ ticketId, crmId, crmDefaultId }) => {
    const link = await getTicketLink(ticketId, crmId, crmDefaultId);
    return link;
  },
);

export default {};
