import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'repeat(4, 1fr)',
    padding: '15px',
    width: '80vw',
    height: '83vh',
    overflow: 'auto',
    margin: '10px auto',
  },
  categoryItemLabel: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    margin: '5px 0px',
  },
  categoryItemList: {
    display: 'flex',
    padding: '10px 0px',
    gap: '20px',
    flexWrap: 'wrap',
  },
  item: {
    width: '100%',
    marginRight: '20px',
    borderRadius: '13px',
    background: theme.palette.card.background,
    boxShadow: '0px 4px 10px 0px #00000026',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '8rem',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.card.hoverBackground,
    },
  },
  tileContainer: {
    height: '75px',
    width: '25%',
    background: '#ffffff',
    padding: '15px',
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    borderRadius: '12px',
    border: '1px solid transparent',
    position: 'relative',
    '&:hover': {
      border: '1px solid #569FF4',
      cursor: 'pointer',
      '& $tileIconBackdrop': {
        background: '#569FF41A',
      },
      '& $tileTextTitle': {
        color: '#569FF4',
      },
      '& $tileIconContainer': {
        '& svg path': {
          // fill: '#569FF4',
          stroke: '#569FF4',
        },
      },
    },
  },
  tileIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      width: '2rem',
      height: '2rem',
      zIndex: '10',
    },
  },
  tileIconBackdrop: {
    display: 'box',
    position: 'absolute',
    width: '3rem',
    height: '3rem',
    background: '#F4F4F4',
    borderRadius: '50%',
  },
  tileTextContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tileTextTitle: {
    fontWeight: '600',
    fontSize: '18px',
  },
  tileTextDescription: {
    color: ' #97A2B6',
    fontSize: '15px',
    fontWeight: '300',
  },
}));

export default useStyles;
