import { createAsyncThunk } from '@reduxjs/toolkit';
import { getKaseyaContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetKaseyaContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getKaseyaContactData`,
  async ({ crmId, contactId }) => getKaseyaContactData(crmId, contactId),
);

export default {};
