import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import useStyles from '../styles';

const Accordion = ({ children, details }) => {
  const classes = useStyles();

  return (
    <MuiAccordion classes={{ root: classes.widthStyle }}>
      <AccordionSummary
        classes={{ root: classes.widthStyle, content: classes.widthStyle }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {children}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  children: PropTypes.node,
  details: PropTypes.node,
};

export default Accordion;
