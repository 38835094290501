import React, { useCallback, useMemo, useState } from 'react';
import Loading from '@common/Loading/Loading';

import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import cx from 'classnames';

import ReusableButton from '@common/Button/Button';
import TabPanel from '@common/TabPanel/TabPanel';
import backIcon from '@assets/icons/arrowGrey.svg';
import { DATA } from '@constants/routes';
import localStorageConst from '@constants/localStorage';
import localStorage from '@services/localStorage';
import useAsync from '@services/api/common/useAsync';
import useStyles from '../styles';
import Download from './components/Download/Download';
import GoogleSheets from './components/GoogleSheets/GoogleSheets';
import WebHooks from './components/WebHooks/WebHooks';
import FtpExport from './components/FTP/FtpExport';
import { getDownloadLinks } from '../helpers';

const ExportPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dataViewInfo = useMemo(
    () => localStorage.getItem(localStorageConst.SELECTED_AUDITVIEW),
    [],
  );

  if (!dataViewInfo) {
    history.push(DATA);
  }

  const [includeServiceInfo, setIncludeServiceInfo] = useState(false);
  const [resource, setResource] = useState({});
  const getData = useCallback(() => getDownloadLinks(dataViewInfo), [
    dataViewInfo,
  ]);
  const loading = useAsync(getData, setResource);

  const tabsData = [
    {
      label: 'Download',
      value: (
        <Download
          resource={resource}
          setResource={setResource}
          includeServiceInfo={includeServiceInfo}
          setIncludeServiceInfo={setIncludeServiceInfo}
        />
      ),
    },
    {
      label: 'Google sheets',
      value: <GoogleSheets resource={resource} />,
    },
    { label: 'Webhook', value: <WebHooks /> },
    {
      label: 'FTP',
      value: (
        <FtpExport
          resource={resource}
          includeServiceInfo={includeServiceInfo}
          setIncludeServiceInfo={setIncludeServiceInfo}
        />
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={cx(classes.wrapper, classes.margin)}>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <ReusableButton
            size="md"
            onClick={() => history.push(DATA)}
            icon={null}
          >
            <>
              <img src={backIcon} alt="back" />
              Back
            </>
          </ReusableButton>
        </Grid>
        <Grid item xs={12}>
          <TabPanel
            classNameWrapperTabs={classes.tabsWrapper}
            tabs={tabsData}
            viewTypeLink="flooded"
            isTransparent
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ExportPage;
