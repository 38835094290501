import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sliderThumb: {
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      boxShadow: 'none',
    },
  },
  sliderFocusVisible: {
    boxShadow: 'none !important',
  },
  slideMark: {
    backgroundColor: theme.palette.secondary.main,
  },
  sliderRail: {
    backgroundColor: theme.palette.secondary.light,
  },
  sliderTrack: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default useStyles;
