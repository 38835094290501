import { createListenerMiddleware } from '@reduxjs/toolkit';
import { addErrors } from '@store/slices/errors';

export const errorListener = createListenerMiddleware();

errorListener.startListening({
  predicate: action => {
    if (action.error && action.error.message.startsWith('RequestError'))
      return true;
    return false;
  },
  effect: (action, listenerApi) => {
    listenerApi.dispatch(addErrors(action.error));
  },
});

export default errorListener.middleware;
