import { createAsyncThunk } from '@reduxjs/toolkit';
import { NOTIFICATION_GROUPS } from '@store/slices/resources';
import { getNotificationGroupsAutocomplete } from '@services/requests/notificationGroups';

export const ThunkGetNotificationGroupsAutocomplete = createAsyncThunk(
  `${NOTIFICATION_GROUPS}/getNotificationGroupsAutocomplete`,
  async (getAll = false) => {
    const groups = await getNotificationGroupsAutocomplete(getAll);
    return groups;
  },
);

export default {};
