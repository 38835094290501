import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import crmSources from '@constants/crmSources';
import EditKaseyaInfoForm from '@components/CrmTicket/Edit/components/EditKaseyaInfoForm';
import EditHaloInfoForm from './components/EditHaloInfoForm';
import EditAutotaskInfoForm from './components/EditAutotaskInfoForm';
import EditConnectWiseInfoForm from './components/EditConnectWiseInfoForm';

import useStyles from './styles';

const CrmTicketEdit = ({ match }) => {
  const classes = useStyles();
  const { goBack } = useHistory();

  const handleBackRedirect = useCallback(() => {
    goBack();
  }, [goBack]);

  const getCrmForm = useMemo(() => {
    switch (match.params.crmName) {
      case crmSources.Autotask.label:
        return (
          <EditAutotaskInfoForm
            crmDefaultId={match.params.crmDefaultId}
            ticketId={match.params.ticketId}
            handleBackRedirect={handleBackRedirect}
          />
        );
      case crmSources.ConnectWise.label:
        return (
          <EditConnectWiseInfoForm
            crmDefaultId={match.params.crmDefaultId}
            ticketId={match.params.ticketId}
            handleBackRedirect={handleBackRedirect}
          />
        );
      case crmSources.Halo.name:
        return (
          <EditHaloInfoForm
            crmDefaultId={match.params.crmDefaultId}
            ticketId={match.params.ticketId}
            handleBackRedirect={handleBackRedirect}
          />
        );
      case crmSources.Kaseya.name:
        return (
          <EditKaseyaInfoForm
            crmDefaultId={match.params.crmDefaultId}
            ticketId={match.params.ticketId}
            handleBackRedirect={handleBackRedirect}
          />
        );
      default:
        return null;
    }
  }, [
    handleBackRedirect,
    match.params.crmDefaultId,
    match.params.ticketId,
    match.params.crmName,
  ]);

  return <div className={classes.container}>{getCrmForm}</div>;
};

CrmTicketEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      crmDefaultId: PropTypes.string,
      ticketId: PropTypes.string,
      crmName: PropTypes.string,
      configurationId: PropTypes.string,
      channelId: PropTypes.string,
    }),
  }),
};

export default CrmTicketEdit;
