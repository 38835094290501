import React from 'react';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Input from '@components/Auth/Common/Input';
import FormSwitch from '@components/Auth/Common/FormSwitch';

const AutotaskNoteForm = ({ processing }) => {
  return (
    <>
      <FieldWrapper
        label="Title:"
        labelSize={12}
        contentSize={12}
        fullWidth
        content={
          <Field name="title" id="title" render={Input} disabled={processing} />
        }
      />
      <FieldWrapper
        label="Description:"
        labelSize={12}
        contentSize={12}
        fullWidth
        content={
          <Field
            name="description"
            id="description"
            render={Input}
            disabled={processing}
            multiline
            minRows={5}
          />
        }
      />
      <FieldWrapper
        label="Is Private:"
        labelSize={12}
        contentSize={12}
        fullWidth
        content={
          <Field
            name="isPrivate"
            id="isPrivate"
            type="checkbox"
            viewType="selector"
            render={FormSwitch}
            disabled={processing}
          />
        }
      />
    </>
  );
};

AutotaskNoteForm.propTypes = {
  processing: PropTypes.bool,
};

export default AutotaskNoteForm;
