import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';
import useStyles from '../styles';

const CreatedItem = ({ name, crmLength }) => {
  const classes = useStyles({ count: crmLength });

  return (
    <div className={classes.rowCell}>
      <Field
        name={name}
        placeholder="Local Company"
        styleType="main"
        inputView="text"
        component={Input}
        classNameWrapper={classes.input}
      />
    </div>
  );
};

CreatedItem.propTypes = {
  name: PropTypes.string,
  crmLength: PropTypes.number,
};

export default CreatedItem;
