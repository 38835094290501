import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaLocations } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaLocations = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaLocations`,
  ({ crmDefaultId, accountId }) => getKaseyaLocations(crmDefaultId, accountId),
);

export default {};
