import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import columnsTable from '@services/columnsTable';
import ColumnContextProvider from '../context/columns';
import Cell from '../components/Cell';
import DraggableHeaderWrapper from '../components/DraggableHeaderWrapper';

import { getColumnHidden, getColumnWidth } from '../helpers';

const withUpdatedColumns = Component => {
  const HOC = ({
    columns,
    resource,
    tableKey,
    bulkActions,
    selectorKey,
    ...props
  }) => {
    const updatedColumns = useMemo(() => {
      const columnsTableSize = columnsTable.getColumnsTableSize(
        resource || tableKey,
      );
      const hiddenTableColumns = columnsTable.getHiddenTableColumns(
        resource || tableKey,
      );
      return columns.map(column => ({
        ...column,
        width: getColumnWidth(column, columnsTableSize),
        resizable: true,
        hidden: getColumnHidden(column, hiddenTableColumns),
        formatter: Cell,
        headerRenderer: DraggableHeaderWrapper,
      }));
    }, [columns, resource, tableKey]);

    return (
      <ColumnContextProvider tableKey={resource || tableKey}>
        <Component
          {...props}
          tableKey={tableKey}
          resource={resource}
          bulkActions={bulkActions}
          columns={updatedColumns}
          selectorKey={selectorKey}
        />
      </ColumnContextProvider>
    );
  };

  HOC.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    tableKey: PropTypes.string,
    selectorKey: PropTypes.string,
    resource: PropTypes.string,
    bulkActions: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  };

  return HOC;
};

export default withUpdatedColumns;
