import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@material-ui/core';

import {
  ThunkCheckSecureLink,
  ThunkGetSecureLinkPageContent,
} from '@store/slices/secureData/thunks';

import appConfig from '@configs/appConfig';
import { SECURE_DATA } from '@services/requests/requestResources';
import { lightTheme } from '@services/themes/mainTheme';

import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import Loading from '@common/Loading/Loading';

import { useSnackbar } from 'notistack';
import useStyles from './styles';

const theme = createTheme(lightTheme());

const SecureData = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [pageContent, setPageContent] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleDataCopy = () => {
    window.navigator.clipboard.writeText(data);
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  const checkCode = useCallback(() => {
    if (match.params.code) {
      setProcessing(true);
      dispatch(ThunkCheckSecureLink({ code: match.params.code }))
        .unwrap()
        .then(res => {
          if (res?.data) {
            setData(res.data);
          }
          if (!res?.isSuccess) {
            setData('Data is not available');
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [dispatch, match.params.code, pageContent]);

  const hanldeDownload = () => {
    window.open(
      `${appConfig.baseUrl}${SECURE_DATA}/file/check?code=${match.params.code}`,
    );
  };

  const onMount = useCallback(() => {
    if (match.params.code) {
      setLoading(true);

      dispatch(ThunkGetSecureLinkPageContent({ code: match.params.code }))
        .unwrap()
        .then(res => {
          if (res) {
            setPageContent(res);
          }
        })
        .catch(e => {
          setError(
            e.message.split('RequestError:')[1] ?? 'Something went wrong',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [match, dispatch]);

  useEffect(() => {
    onMount();
  }, []);

  if (error)
    return (
      <div className={classes.container}>
        <div className={classes.title}>{error}</div>
      </div>
    );
  if (!match.params.code) return <div>Bad Url</div>;
  if (loading) return <Loading />;
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className={classes.box}>
          {pageContent && pageContent.logo && (
            <img
              alt="Logo"
              src={pageContent.logo}
              className={classes.imageRoot}
            />
          )}

          {pageContent && pageContent.title && (
            <div className={classes.title}>{pageContent.title}</div>
          )}

          {pageContent && pageContent.onLinkUsedMessage && data && (
            <div style={{ textAlign: 'center' }}>
              {pageContent.onLinkUsedMessage}
            </div>
          )}

          {data && pageContent?.isTextData && (
            <Input
              meta={{ touched: false }}
              input={{ value: data }}
              disabled
              multiline
              minRows={5}
            />
          )}

          {!data && pageContent?.isTextData && (
            <ReusableButton
              label="Reveal Data"
              viewType="blue"
              onClick={checkCode}
              loading={processing}
              disabled={processing}
              size="xl"
            />
          )}

          {!data && !pageContent?.isTextData && (
            <ReusableButton
              label="Download File"
              viewType="blue"
              onClick={hanldeDownload}
              loading={processing}
              disabled={processing}
              size="xl"
            />
          )}

          {data && pageContent?.isTextData && (
            <ReusableButton
              label="Copy to Clipboard"
              onClick={handleDataCopy}
              disabled={!data}
              viewType="blue"
              size="xl"
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

SecureData.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
};

export default SecureData;
