import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getChats } from '@services/requests/liveChats';

export const ThunkGetChats = createAsyncThunk(
  `${LIVE_CHATS}/getChats`,
  ({ id, skipClosed = false }) => getChats(id, skipClosed),
);

export default {};
