import React from 'react';
import useSearch from '@components/Ticketing/Notifications/hooks/useSearch';
import Form from './DmSettingsForm';

const DmSettingFormCreate = () => {
  const id = useSearch();
  const data = id ? { deviceMonitorId: id } : null;
  return <Form data={data} />;
};

export default DmSettingFormCreate;
