import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalAdmins } from '@services/requests/clientPortal';

export const ThunkGetClientPortalAdmins = createAsyncThunk(
  `${CLIENT_PORTALS}/getClientPortalAdmins`,
  () => getClientPortalAdmins(),
);

export default {};
