import { createAsyncThunk } from '@reduxjs/toolkit';
import { deletePhoneNumber } from '@services/requests/phoneNumbers';
import { PHONE_NUMBERS } from '@store/slices/resources';
import { normalizePhoneNumber } from '../generator';

export const ThunkDeletePhoneNumber = createAsyncThunk(
  `${PHONE_NUMBERS}/deletePhoneNumber`,
  async ({ id }, { getState }) => {
    const state = getState();
    await deletePhoneNumber(id);
    return state.phoneNumbers.list
      .filter(phoneNumber => phoneNumber.id !== id)
      .map(number => normalizePhoneNumber(number));
  },
);

export default {};
