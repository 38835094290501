import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Chip, Paper, Typography, MenuItem } from '@material-ui/core';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';

import useStyles from './styles';

const MultiSelectDialog = ({
  title,
  values,
  setValues,
  fullArray,
  disabled = false,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  if (!values) {
    return null;
  }

  const getMenuItems = () => {
    return fullArray.filter(el => {
      return !values.map(i => i.value).includes(el.value);
    });
  };

  const handleClick = event => {
    if (getMenuItems().length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleSelect = item => {
    setValues([...values, item]);
    setAnchorEl(null);
  };
  const handleDelete = value => {
    const newData = [...values].filter(i => i !== value);
    setValues(newData);
  };

  return (
    <div className={classes.mainBlock}>
      <Typography className={classes.label}>{title}</Typography>
      <Paper className={classes.root}>
        <ActionButton
          handler={handleClick}
          icon={<img src={iconPlus} alt="iconPlus" />}
          toolTip="Add"
          disabled={disabled}
        />
        {values.map(data => {
          return (
            <li key={data.value}>
              <Chip
                label={data.label}
                onDelete={() => handleDelete(data)}
                className={classes.chip}
                disabled={disabled}
              />
            </li>
          );
        })}
      </Paper>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {getMenuItems().map(i => (
          <MenuItem key={i.value} onClick={() => handleSelect(i)}>
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

MultiSelectDialog.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.object),
  fullArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  setValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MultiSelectDialog;
