import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const ConfirmErrorsDialog = ({
  open,
  setOpen,
  onAccept,
  onCancel,
  title,
  errors,
}) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setOpen(null);
    }
  };

  const prepareItem = value =>
    typeof value === 'string' ? (
      <li key={value}>{value}</li>
    ) : (
      <>
        <li key={value.label}>{value.label}</li>
        <ul>
          {value.errors.map(e => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </>
    );
  return (
    <MuiDialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="ul" variant="body2">
          <ul>{errors.map(e => prepareItem(e))}</ul>
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            No, continue editing
          </Button>
          <Button onClick={onAccept} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </MuiDialog>
  );
};

ConfirmErrorsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
};

export default ConfirmErrorsDialog;
