import React, { useCallback, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ThunkPushMessagesToEmail } from '@store/slices/clientPortalAdmin/thunks';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { requiredValidator } from '@utils/validators';

const PushMessagesToEmailDialog = ({ open, setOpen, clientPortalChatId }) => {
  const [processing, setProcessing] = useState(false);

  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();

  const submit = useCallback(
    ({ email }) => {
      setProcessing(true);
      dispatch(
        ThunkPushMessagesToEmail({
          clientChatId: clientPortalChatId,
          payload: { email },
        }),
      )
        .unwrap()
        .then(() => {
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [clientPortalChatId, dispatch, setOpen],
  );

  const validate = values => ({
    email: requiredValidator(values.email),
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Send Chat Log to Email</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Email"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                content={
                  <Field
                    name="email"
                    id="email"
                    render={Input}
                    loading={processing}
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

PushMessagesToEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clientPortalChatId: PropTypes.number.isRequired,
};
export default PushMessagesToEmailDialog;
