import { createAsyncThunk } from '@reduxjs/toolkit';
import { editHaloTicket } from '@services/requests/haloTickets';
import { HALO_TICKET } from '@store/slices/resources';

export const ThunkEditHaloTicket = createAsyncThunk(
  `${HALO_TICKET}/editHaloTicket`,
  async ({ crmId, payload }) => {
    const ticket = await editHaloTicket(crmId, payload);
    return ticket;
  },
);

export default {};
