import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import {
  ThunkGetEmailTemplatesSettings,
  ThunkGetInviteClientPortalTemplateVariables,
  ThunkSaveEmailTemplatesSettings,
} from '@store/slices/emailSettings/thunks';

import Checkbox from '@ui/components/common/Checkbox';
import HtmlInput from '@common/HtmlInput';
import Loading from '@common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';

import useStyles from './styles';

const EmailTemplateSettings = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [inviteCpTemplateVariables, setInviteCpTemplateVariables] = useState(
    [],
  );
  const [inviteClientPortalAnchorEl, setInviteClientPortalAnchorEl] = useState(
    null,
  );

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(ThunkGetEmailTemplatesSettings())
      .unwrap()
      .then(d => setData(d))
      .finally(() => setLoading(false));

    dispatch(ThunkGetInviteClientPortalTemplateVariables())
      .unwrap()
      .then(d => setInviteCpTemplateVariables(d));
  }, [dispatch]);

  const submit = async values => {
    const payload = {
      ...values,
    };
    setLoading(true);
    dispatch(ThunkSaveEmailTemplatesSettings({ payload }))
      .unwrap()
      .then(result => {
        if (result) {
          enqueueSnackbar('Record updated', { variant: 'success' });
          setData(result);
        } else {
          enqueueSnackbar('Unable to update email settings', {
            variant: 'error',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return !loading ? (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          initialValues={data}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.formContentWrapper}>
                <FieldWrapper
                  label="Use custom email body for Client Portal invite"
                  classNameContainer={classes.formItem}
                  classNameLabelContainer={classes.dialogLabelContainer}
                  labelSize={4}
                  contentSize={8}
                  content={
                    <div style={{ marginLeft: '20px' }}>
                      <Field
                        name="useCustomTemplateForClientPortalInvite"
                        id="useCustomTemplateForClientPortalInvite"
                        render={Checkbox}
                        type="checkbox"
                      />
                    </div>
                  }
                />

                {values.useCustomTemplateForClientPortalInvite && (
                  <FieldWrapper
                    label="Invite Client Portal Email Template"
                    labelSize={4}
                    contentSize={8}
                    content={
                      <div className={classes.dialogTemplate}>
                        <HtmlInput
                          name="inviteClientPortalUserEmailTemplate"
                          form={form}
                          templateVariables={inviteCpTemplateVariables}
                        />
                      </div>
                    }
                  />
                )}
              </div>
              <div className={classes.buttonWrapper}>
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  label="Update"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default EmailTemplateSettings;
