import { createChatBot } from '@services/requests/chatBots';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CHATS_BOTS } from '../../resources';

export const ThunkCreateChatBot = createAsyncThunk(
  `${CHATS_BOTS}/createChatBot`,
  ({ payload }) => createChatBot(payload),
);

export default {};
