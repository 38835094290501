import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '15px',
  },
  blockWrapper: {
    background: 'white',
    marginTop: '15px',
    borderRadius: '5px',
    padding: '15px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 10px',
  },
  statusBlock: {
    minWidth: '100px',
    textAlign: 'center',
    marginLeft: 'auto',
  },
  dateBlock: {
    minWidth: '100px',
    textAlign: 'center',
    marginLeft: '10px',
    borderLeft: '2px solid #ccc',
    paddingLeft: '10px',
  },
  detailsBlock: {
    padding: '15px',
  },
  buttonWrapper: {
    margin: '0px 10px',
  },
}));

export default useStyles;
