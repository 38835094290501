import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { checkUserInviteCode } from '@services/requests/usersInvite';

export const ThunkCheckUserInviteCode = createAsyncThunk(
  `${USER}/sendUsersInvite`,
  async ({ code }) => checkUserInviteCode(code),
);

export default {};
