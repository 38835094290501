import { useEffect, useState } from 'react';

const useAsync = (asyncFn, onSuccess) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    asyncFn().then(data => {
      setLoading(false);
      if (isMounted) onSuccess(data);
    });
    return () => {
      isMounted = false;
    };
  }, [asyncFn, onSuccess]);
  return loading;
};

export default useAsync;
