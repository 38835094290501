import { createAsyncThunk } from '@reduxjs/toolkit';
import { editIncidentNote } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';

export const ThunkEditIncidentNote = createAsyncThunk(
  `${INCIDENT}/editIncidentNote`,
  async ({ data, noteId }) => {
    const note = await editIncidentNote(data, noteId);
    return note;
  },
);

export default {};
