import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAutotaskTicket } from '@services/requests/createTicket';
import { CREATE_TICKET } from '@store/slices/resources';

const generatePayload = values => ({
  ...values,
  createChannel: values.createChannel || false,
});

// * used to create ticket without psa default id, only with psa integration id
export const ThunkCreateAutotaskTicket = createAsyncThunk(
  `${CREATE_TICKET}/createAutotaskTicket`,
  ({ psaId, payload }) => createAutotaskTicket(psaId, generatePayload(payload)),
);

export default {};
