import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import useAsync from '@services/api/common/useAsync';
import { getServiceNowSources } from '@components/Ticketing/Integrations/helpers';

const ServiceNowForm = ({ classes, loading, useLabels }) => {
  const [sources, setSources] = useState([]);

  useAsync(getServiceNowSources, setSources);

  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>User Name:</Typography>
      )}
      <Field
        id="publicKey"
        name="publicKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="UserName"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Password:</Typography>
      )}
      <Field
        id="privateKey"
        name="privateKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="password"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Password"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Company Query:</Typography>
      )}
      <Field
        id="companyQuery"
        name="companyQuery"
        styleType="main"
        fullWidth
        inputView="text"
        component={Input}
        disabled={loading}
        classNameWrapper={classes.inputWrapper}
        placeholder="Company Query"
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>
          Company Table Source:
        </Typography>
      )}
      <Field
        id="companyTableSource"
        name="companyTableSource"
        component={DropDown}
        options={sources}
        disabled={loading}
        classNameWrapper={classes.inputWrapper}
        label={!useLabels && 'Company Table Source'}
      />
    </>
  );
};

ServiceNowForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default ServiceNowForm;
