import PropTypes from 'prop-types';
import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';

const DateAccessor = ({ value }) => moment(value).format(defaultDateFormat);

DateAccessor.propTypes = {
  value: PropTypes.string,
};

export default DateAccessor;
