import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAutotaskIntegrationCompanies } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetAutotaskIntegrationCompanies = createAsyncThunk(
  `${INTEGRATION}/getAutotaskIntegrationCompanies`,
  async ({ crmId }) => {
    const companies = await getAutotaskIntegrationCompanies(crmId);
    return companies;
  },
);

export default {};
