import { createAsyncThunk } from '@reduxjs/toolkit';
import { editResourceAssignedTemplates } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkEditResourceAssignedTemplates = createAsyncThunk(
  `${MESSAGING}/editResourceAssignedTemplate`,
  async payload => {
    const templates = await editResourceAssignedTemplates(payload);
    return templates;
  },
);

export default {};
