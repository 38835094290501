import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT } from '@store/slices/resources';
import { getTenantCountry } from '@services/requests/tenant';

export const ThunkGetTenantCountry = createAsyncThunk(
  `${TENANT}/getTenantCountry`,
  async () => {
    const response = await getTenantCountry();
    return response;
  },
);

export default {};
