import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useListContext } from 'react-admin';
import { Button } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowUpward } from '@material-ui/icons';
import moment from 'moment';
import { sortOrders, COUNT_PRODUCT_PER_PAGE } from '@constants/filters';

import calendarGrayIcon from '@assets/icons/calendar.svg';
import calendarGreenIcon from '@assets/icons/calendar_green.svg';
import useStyles from './style';
import ActionFieldItem from '../ActionButtons/ActionFieldItem';

const defaultDateFormat = 'MM/DD/YYYY';

const TableDateLabel = ({ item: { field, name }, date, setIsOpen }) => {
  const classes = useStyles();
  const {
    setFilters,
    filterValues,
    currentSort,
    setSort,
    setPerPage: setCountData,
  } = useListContext();

  const isCurrentFieldSorted = currentSort.field === field;

  const setSorting = () => {
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setSort(field);
    setFilters(filterValues, filterValues, true);
  };

  const classNameForButtonIcon = cx(classes.tableHeaderButtonIcon, {
    [classes.tableHeaderButtonIconRotate]:
      isCurrentFieldSorted && currentSort.order === sortOrders.asc,
  });

  const calendarInfo = useMemo(
    () =>
      date.start && date.end
        ? {
            icon: calendarGreenIcon,
            hint: `${moment(date.start).format(defaultDateFormat)} - ${moment(
              date.end,
            ).format(defaultDateFormat)}`,
          }
        : { icon: calendarGrayIcon, hint: 'Filter date range' },
    [date],
  );

  const handleClick = e => {
    e.preventDefault();
    setIsOpen(prevState => !prevState);
  };

  return (
    <>
      <span className={classes.marginForText}>{name}</span>
      <Button onClick={setSorting} className={classes.tableHeaderButton}>
        <ArrowUpward className={classNameForButtonIcon} />
      </Button>
      <ActionFieldItem
        icon={calendarInfo.icon}
        alt="calendarIcon"
        handler={handleClick}
        toolTip={calendarInfo.hint}
      />
    </>
  );
};

TableDateLabel.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  setIsOpen: PropTypes.func,
};

export default TableDateLabel;
