import { createSlice } from '@reduxjs/toolkit';
import { SECURE_DATA_SETTINGS } from '@store/slices/resources';
import { ThunkGetSecureDataSettings } from '@store/slices/secureDataSettings/thunks/getSecureDataSettings';

const initialState = {
  initialValue: {
    period: 1,
    removeAfterView: false,
    saveClientNote: false,
    saveDataInternalNote: false,
    saveLogsInternalNote: false,
    lockOptions: false,
  },
};
const secureDataSettingsSlice = createSlice({
  name: SECURE_DATA_SETTINGS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      ThunkGetSecureDataSettings.fulfilled,
      (state, { payload }) => {
        state.initialValue = payload;
      },
    );
  },
});

export default secureDataSettingsSlice.reducer;
