import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import NumberInput from '@components/Auth/Common/NumberInput';
import moment from 'moment';
import useStyles from './styles';

const calculate = value => {
  const minutes = moment.duration(value, 'minutes').minutes();
  const hours = moment.duration(value, 'minutes').hours();
  const days = moment.duration(value, 'minutes').days();
  return { minutes, hours, days };
};

const MinutesPeriodPicker = ({
  input: { value, onChange },
  meta,
  disabled = false,
}) => {
  const classes = useStyles();

  const [minutes, setMinutes] = useState(calculate(value).minutes);
  const [hours, setHours] = useState(calculate(value).hours);
  const [days, setDays] = useState(calculate(value).days);

  useEffect(() => {
    onChange(days * 1440 + hours * 60 + +minutes);
  }, [minutes, hours, days, onChange]);

  const items = useMemo(
    () => [
      { id: 1, val: days, func: setDays, text: 'day(s)', maxValue: 120 },
      { id: 2, val: hours, func: setHours, text: 'hour(s)', maxValue: 23 },
      {
        id: 3,
        val: minutes,
        func: setMinutes,
        text: 'minute(s)',
        maxValue: 59,
      },
    ],
    [days, hours, minutes],
  );

  return (
    <div className={classes.container}>
      {items.map(item => (
        <div key={item.id} className={classes.item}>
          <NumberInput
            input={{
              value: item.val,
              onChange: e => item.func(e.target.value),
            }}
            className={classes.numberInout}
            meta={meta}
            min={0}
            max={item.maxValue}
            disabled={disabled}
          />
          <span className={classes.text}>{item.text}</span>
        </div>
      ))}
    </div>
  );
};

MinutesPeriodPicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default MinutesPeriodPicker;
