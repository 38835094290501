import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTicketContact } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetTicketContact = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketContact`,
  async ({ tenantId, crmId, contactId }) => {
    const res = await getTicketContact(tenantId, crmId, contactId);
    return res;
  },
);

export default {};
