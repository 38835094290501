import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  btnWrapper: {
    [theme.breakpoints.up('md')]: {
      margin: '0 5px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    height: '35px',
  },
  whiteBtnWrapper: {
    '&:hover': {
      background: 'rgba(255,255,255,0.9)',
    },
  },
}));
export default useStyles;
