import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMessagingConfiguration } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkUpdateMessagingConfiguration = createAsyncThunk(
  `${MESSAGING}/updateMessagingConfiguration`,
  async ({ id, payload }) => {
    const res = await updateMessagingConfiguration(id, payload);
    return res;
  },
);

export default {};
