import React from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import iconCopy from '@assets/icons/copyGrey.svg';

import useStyles from './styles';

const CodeBlock = ({ children }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={classes.codeBlockBackground}>
      <CopyToClipboard
        text={children}
        onCopy={() =>
          enqueueSnackbar('Copied to clipboard', { variant: 'success' })
        }
      >
        <img
          src={iconCopy}
          alt="iconCopy"
          className={classes.codeBlockCopyButton}
        />
      </CopyToClipboard>
      <code>{children}</code>
    </div>
  );
};

CodeBlock.propTypes = {
  children: PropTypes.arrayOf(PropTypes.string),
};

export default CodeBlock;
