import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmDefaultsAutocomplete } from '@services/requests/crm';
import { CRM } from '@store/slices/resources';

export const ThunkGetCrmDefaultsAutocomplete = createAsyncThunk(
  `${CRM}/getCrmDefaultsAutocomplete`,
  async () => {
    const res = await getCrmDefaultsAutocomplete();
    return res;
  },
);

export default {};
