const resources = {
  documents: 'documents',
  posts: 'posts',
  role: 'role',
  users: 'users',
  emailTemplates: 'emailTemplates',
  settings: 'settings',
  chats: 'chats',
  messages: 'messages',
  profile: 'profile',
  generator: 'generator',
  calendar: 'calendar',
  tasks: 'tasks',
  files: 'files',
  statusBar: 'statusBar',
  comments: 'comments',
  chatReadMessages: 'chatReadMessages',
  companies: 'companies',
  formBuilder: 'formBuilder',
  dynamicForm: 'dynamicForm',
  audit: 'audit',
  admin: 'admin',
  tenants: 'tenants',
  parsers: 'parsers',
  subscriptions: 'subscriptions',
  pricingPlan: 'pricingPlan',
  billing: 'billing',
  mailBoxes: 'mailBoxes',
  home: 'home',
  types: 'types',
  tenantSettings: 'tenantSettings',
  customPalette: 'customPalette',
  notificationSettings: 'notificationSettings',
  user: 'user',
  apiKeys: 'apiKeys',
  localCompany: 'localCompany',
  roadMap: 'roadmap',
  deviceMonitors: 'deviceMonitors',
  ftpConnections: 'ftpConnections',
  parsingStatus: 'parsingStatus',
  deviceThreshold: 'deviceThreshold',
  smsLogs: 'smsLogs',
  dataSourcesIntegrations: 'dataSourcesIntegrations',
  securityIntegrations: 'securityIntegrations',
  webHooks: 'webHooks',
  alertTemplates: 'alertTemplates',
  customFields: 'customFields',
  demoReferralLinks: 'demoReferralLinks',
  coupons: 'coupons',
  crmTickets: 'crmTickets',
  crmUsers: 'crmUsers',
  notificationGroups: 'notificationGroups',
  billingLinks: 'billingLinks',
  autotaskHooks: 'autotaskHooks',
  connectWiseHooks: 'connectWiseHooks',
  triggers: 'mailTriggers',
  incidents: 'incidents',
  webHookResults: 'webHookResults',
  genericSources: 'genericSources',
  escalationRules: 'escalationRules',
  tenantStatistic: 'tenantStatistic',
};

export default resources;
