import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../styles';

const DataViewFields = ({ fields, activeField, onClick }) => {
  const { fieldTile, fieldTilesRegion } = useStyles();
  return (
    <div className={fieldTilesRegion}>
      {fields.map(i => (
        <div
          role="presentation"
          key={i}
          className={fieldTile}
          style={{
            backgroundColor: i === activeField ? '#dbdbdb' : 'transparent',
          }}
          onClick={() => onClick(i)}
        >
          {i}
        </div>
      ))}
    </div>
  );
};

DataViewFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  activeField: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default DataViewFields;
