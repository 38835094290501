import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, TextField } from '@material-ui/core';

import { currentUserDataSelector } from '@store/selectors';
import ViewAutoComplete from '@ui/components/common/ViewAutoComplete/ViewAutoComplete';
import Switch from '@common/FilterInputs/Switch';
import { useSnackbar } from 'notistack';
import { useTableContext } from '../../../../../hooks/table';

import useStyles from '../../styles';

const ConnectWiseTicketTableFilters = () => {
  const classes = useStyles();
  const [showOnlyMyTickets, setShowOnlyMyTickets] = useState({ value: false });
  const [ticketDateRange, setTicketDateRange] = useState(null);
  const [ticketDateTimeFrom, setTicketDateTimeFrom] = useState('');
  const [ticketDateTimeTo, setTicketDateTimeTo] = useState('');

  const { setFilteringObject, filtering } = useTableContext();

  const currentUser = useSelector(currentUserDataSelector);
  const { enqueueSnackbar } = useSnackbar();

  const handleDateChange = (date, filterName) => {
    setFilteringObject(filtering, filterName, date);
  };

  const handleRangeChange = (item, setter, filterName) => {
    setter(item);

    if (item?.value !== 'Custom') {
      const {
        CustomCreationDateUtcFrom,
        CustomCreationDateUtcTo,
        ...filters
      } = filtering;
      setFilteringObject(filters, filterName, item?.value);
    }
  };

  const handleShowOnlySwitchChange = useCallback(
    item => {
      if (!item.value) {
        setFilteringObject(filtering, 'identity', '');
      }
      if (item.value && currentUser?.cwUserLinked) {
        setFilteringObject(
          filtering,
          'identity',
          `${currentUser.connectwiseLinkedUser.userId}|${currentUser.connectwiseLinkedUser.identity}`,
        );
        setShowOnlyMyTickets(item);
      }
      if (!currentUser.cwUserLinked) {
        enqueueSnackbar('Connect ConnectWise user first. Go to Profile page.', {
          variant: 'warning',
        });
      }
    },
    [currentUser, filtering, setFilteringObject, enqueueSnackbar],
  );

  return (
    <div className={classes.cwFiltersContainer}>
      <FormControlLabel
        className={classes.cwSwitch}
        control={
          <Switch
            input={{
              onChange: () => {
                setShowOnlyMyTickets(!showOnlyMyTickets.value);
                handleShowOnlySwitchChange(
                  { value: !showOnlyMyTickets.value },
                  setShowOnlyMyTickets,
                  'skipCompleted',
                );
              },
              value: showOnlyMyTickets.value,
            }}
            checked={showOnlyMyTickets.value}
          />
        }
        label="My Tickets"
        labelPlacement="start"
      />

      <ViewAutoComplete
        options={[
          { value: undefined, label: '-' },
          { value: 'Today', label: 'Today' },
          { value: 'ThisWeek', label: 'This Week' },
          { value: 'LastWeek', label: 'Last Week' },
          { value: 'ThisMonth', label: 'This Month' },
          { value: 'LastMonth', label: 'Last Month' },
          { value: 'Custom', label: 'Custom' },
        ]}
        onChange={item => {
          handleRangeChange(item, setTicketDateRange, 'ticketDateRange');
        }}
        currentView={ticketDateRange}
        textFieldLabel="Date"
      />
      {ticketDateRange?.value === 'Custom' && (
        <div className={classes.datePickerWrapper}>
          <TextField
            type="date"
            variant="outlined"
            className={classes.datePicketInput}
            value={ticketDateTimeFrom}
            onChange={e => {
              setTicketDateTimeFrom(e.target.value);

              handleDateChange(
                new Date(e.target.value).toUTCString(),
                'CustomCreationDateUtcFrom',
              );
            }}
          />
          <TextField
            type="date"
            variant="outlined"
            className={classes.datePicketInput}
            value={ticketDateTimeTo}
            onChange={e => {
              setTicketDateTimeTo(e.target.value);

              handleDateChange(
                new Date(e.target.value).toUTCString(),
                'CustomCreationDateUtcTo',
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectWiseTicketTableFilters;
