import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import Checkbox from '@ui/components/common/Checkbox';
import DropDown from '@ui/components/Auth/Common/DropDown';
import InfoBox from '@ui/components/common/InfoBox/InfoBox';

import useStyles from '../../styles';

const emptyFilterBox = '_';

const DataViewFormFilters = ({
  setFilterMailBoxesOptions,
  selectedMailBox,
  setSelectedMailBox,
  allowedParserOptions,
  selectedParser,
  setSelectedParser,
  webHookOptions,
  selectedWebHook,
  setSelectedWebHook,
  filterHooksOnly,
  setFilterHooksOnly,
  genericSourcesOptions,
  selectedSourceOption,
  setSelectedSourceOption,
  selectAllSourceOptions,
  setSelectAllSourceOptions,
  mailBoxOptions,
}) => {
  const classes = useStyles();
  const [filtersExpanded, setFiltersExpanded] = useState(true);

  useEffect(() => {
    const newOptions = mailBoxOptions
      .filter(o => o.value !== 0)
      .map(o => ({ value: o.label, label: o.label }));
    newOptions.unshift({ value: emptyFilterBox, label: 'All MailBoxes' });
    setFilterMailBoxesOptions(newOptions);
  }, [mailBoxOptions]);

  return (
    <Grid item xs={12} sm={12}>
      <Accordion
        expanded={filtersExpanded}
        onChange={() => setFiltersExpanded(!filtersExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Filters
          <InfoBox text="Allows to filter field names by MailBox, Parser, WebHook" />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} className={classes.row}>
            <Grid item xs={12} sm={12} md={3}>
              <span>Mail Box</span>
              <DropDown
                options={mailBoxOptions}
                input={{
                  value: selectedMailBox,
                  onChange: e => setSelectedMailBox(+e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <span>Parser</span>
              <DropDown
                options={allowedParserOptions}
                disabled={!selectedMailBox}
                input={{
                  value: selectedParser,
                  onChange: e => setSelectedParser(+e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12} sm={12} md={6}>
              <span>Web Hook</span>
              <DropDown
                options={webHookOptions}
                input={{
                  value: selectedWebHook,
                  onChange: e => setSelectedWebHook(+e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.checkboxField}>
              <span
                role="presentation"
                onClick={() => {
                  setFilterHooksOnly(!filterHooksOnly);
                }}
              >
                WebHooks only
              </span>

              <Checkbox
                checked={filterHooksOnly}
                onChange={e => setFilterHooksOnly(e.target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <span>Generic Sources</span>
              <DropDown
                options={genericSourcesOptions}
                input={{
                  value: selectedSourceOption,
                  onChange: e => setSelectedSourceOption(+e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.checkboxField}>
              <span
                role="presentation"
                onClick={() => {
                  setSelectAllSourceOptions(!selectAllSourceOptions);
                }}
              >
                Generic Sources only
              </span>

              <Checkbox
                checked={selectAllSourceOptions}
                onChange={e => setSelectAllSourceOptions(e.target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default DataViewFormFilters;
