import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { sendMessageToClientPortal } from '@services/requests/clientPortal';

export const ThunkSendMessageToClientPortal = createAsyncThunk(
  `${CLIENT_PORTALS}/sendMessageToClientPortal`,
  ({ chatId, payload }) => sendMessageToClientPortal(chatId, payload),
);

export default {};
