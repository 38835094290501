import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCountriesOptions } from '@constants/countries/countries';
import { Field } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import { useDispatch } from 'react-redux';
import {
  ThunkGetTenantCountry,
  ThunkUpdateTenantCountry,
} from '@store/slices/tenant/thunks';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import useStyles from './styles';

const CountrySettings = ({ loading, readOnlyMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [country, setCountry] = useState(null);

  const onChange = value => {
    if (value) {
      setCountry(value);
      dispatch(ThunkUpdateTenantCountry(value));
    }
  };

  const onMount = useCallback(() => {
    dispatch(ThunkGetTenantCountry())
      .unwrap()
      .then(p => setCountry(p.data));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <FieldWrapper
      classNameGridContainer={classes.gridContainer}
      classNameLabelOuter={classes.fieldLabelOuter}
      justify="space-evenly"
      label="Country"
      labelSize={3}
      contentSize={9}
      content={
        <Field
          id="country"
          name="country"
          component={({ input, ...rest }) => (
            <AutocompleteFormInput
              {...rest}
              input={{
                value: country,
                onChange,
              }}
            />
          )}
          items={getCountriesOptions()}
          disabled={loading || readOnlyMode}
        />
      }
    />
  );
};

CountrySettings.propTypes = {
  loading: PropTypes.bool.isRequired,
  readOnlyMode: PropTypes.bool.isRequired,
};

export default CountrySettings;
