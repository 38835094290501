import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTriageTicket } from '@services/requests/triage';
import { TRIAGE } from '@store/slices/resources';

export const ThunkCreateTriageTicket = createAsyncThunk(
  `${TRIAGE}/createTriageTicket`,
  async payload => {
    const ticket = await createTriageTicket(payload);
    return ticket;
  },
);

export default {};
