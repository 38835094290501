import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTOTASK_TICKET } from '@store/slices/resources';
import { createAutotaskNote } from '@services/requests/autotaskTicket';

export const ThunkCreateAutotaskNote = createAsyncThunk(
  `${AUTOTASK_TICKET}/createAutotaskNote`,
  ({ crmDefaultId, ticketId, payload }) =>
    createAutotaskNote(crmDefaultId, ticketId, payload),
);

export default {};
