import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA } from '@store/slices/resources';
import { getSecureLinkPageContent } from '@services/requests/secureData';

export const ThunkGetSecureLinkPageContent = createAsyncThunk(
  `${SECURE_DATA}/getSecureLinkPageContent`,
  ({ code }) => getSecureLinkPageContent(code),
);

export default {};
