import { attachmentNameMaxLength } from '@constants/numericConstants';

const getItemName = value => {
  if (value.length > attachmentNameMaxLength) {
    if (!value.includes('.')) {
      return `${value.substring(0, 15)}~`;
    }
    const arr = value.split('.');
    const ext = arr.pop();
    const name = arr.join();
    return `${name.substring(0, 15)}~.${ext}`;
  }
  return value;
};

export default getItemName;
