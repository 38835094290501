import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { Field, useForm } from 'react-final-form';

import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import DropDown from '@components/Auth/Common/DropDown';

import {
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
} from '@store/slices/integration/thunks';
import { ThunkGetConnectWisePriorities } from '@store/slices/createTicket/thunks';

const CWClientPortalForm = ({ psaId, item = undefined }) => {
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const form = useForm();
  const { values } = form.getState();

  const [boards, setBoards] = useState([]);
  const [ticketPriorities, setTicketPriorities] = useState([]);
  const [sources, setSources] = useState([]);

  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardItems, setBoardItems] = useState([]);
  const [boardSubTypes, setSubTypes] = useState([]);
  const [selectedTicketType, setSelectedTicketType] = useState();

  const onSelect = async d => {
    setProcessing(true);
    if (d.BoardId) {
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: d.BoardId,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: d.BoardId,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(async res => {
        const subtypes = res[1].reduce((acc, cur) => {
          acc[cur.id] = cur.boardSubTypes || [];
          return acc;
        }, {});

        const boardItemsOptions = subtypes[d.typeId]
          ?.find(i => i.id === d.subTypeId)
          ?.items.map(({ id, name }) => ({ value: id, label: name }));

        setBoardStatuses(
          res[0].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
        setBoardTypes(res[1]);
        setSubTypes(subtypes);
        setBoardItems(boardItemsOptions || []);
      });
    }

    setProcessing(false);
  };

  useEffect(() => {
    if (item) {
      onSelect(item.default);
    }
  }, [item]);

  const handleBoardIdChange = useCallback(
    async value => {
      setProcessing(true);
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(async res => {
        setBoardStatuses(
          res[0].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
        setBoardTypes(res[1]);
        setSubTypes(
          res[1].reduce((acc, cur) => {
            acc[cur.id] = cur.boardSubTypes;
            return acc;
          }, {}),
        );
      });

      setProcessing(false);
    },
    [dispatch, psaId],
  );

  const handleTypeSelect = value => {
    setSelectedTicketType(value);
  };

  const handleSubTypeSelect = async value => {
    if (value !== '' && boardSubTypes[selectedTicketType]) {
      setBoardItems(
        boardSubTypes[selectedTicketType]
          ?.find(i => i.id === value)
          ?.items.map(({ id, name }) => ({ value: id, label: name })),
      );
    } else {
      setBoardItems([]);
    }
  };

  const onMount = useCallback(async () => {
    setProcessing(true);

    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationServiceBoards({
              id: psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWisePriorities({
              psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationSources({
              id: psaId,
            }),
          ).unwrap(),
        ),
      ),
    ])
      .then(res => {
        setBoards(res[0]);
        setTicketPriorities(res[1]);
        setSources(res[2]);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <FieldWrapper
        label="Board"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.BoardId"
            name="default.BoardId"
            size="small"
            labelName="name"
            valueName="id"
            component={DropDown}
            options={boards}
            disabled={processing}
          />
        }
      />

      <OnChange name="default.BoardId">
        {value => {
          handleBoardIdChange(value);
        }}
      </OnChange>

      <FieldWrapper
        label="Ticket Type"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketTypeId"
            name="default.TicketTypeId"
            size="small"
            component={DropDown}
            labelName="name"
            valueName="id"
            allowEmpty
            options={boardTypes}
            disabled={processing}
          />
        }
      />

      <OnChange name="default.TicketTypeId">
        {value => {
          handleTypeSelect(value);
        }}
      </OnChange>

      <FieldWrapper
        label="Ticket Subtype"
        labelSize={12}
        contentSize={12}
        content={
          <Field
            id="default.TicketSubTypeId"
            name="default.TicketSubTypeId"
            size="small"
            labelName="name"
            valueName="id"
            component={DropDown}
            allowEmpty
            options={boardSubTypes[values?.default?.TicketTypeId] || []}
            disabled={processing || !values?.default?.TicketTypeId}
          />
        }
      />

      <OnChange name="default.TicketSubTypeId">
        {value => {
          handleSubTypeSelect(value);
        }}
      </OnChange>

      <FieldWrapper
        label="Ticket Item"
        labelSize={12}
        contentSize={12}
        content={
          <Field
            name="default.TicketItemId"
            id="default.TicketItemId"
            render={AutocompleteFormInput}
            items={boardItems}
            loading={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Open Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketOpenStatusId"
            name="default.TicketOpenStatusId"
            size="small"
            component={DropDown}
            options={boardStatuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Close Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketCloseStatusId"
            name="default.TicketCloseStatusId"
            size="small"
            component={DropDown}
            options={boardStatuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Message Received Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketOnMessageStatusId"
            name="default.TicketOnMessageStatusId"
            size="small"
            component={DropDown}
            options={boardStatuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Source"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.SourceId"
            name="default.SourceId"
            size="small"
            valueName="id"
            labelName="name"
            component={DropDown}
            options={sources}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Priority"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.PriorityId"
            name="default.PriorityId"
            size="small"
            component={DropDown}
            options={ticketPriorities}
            disabled={processing}
          />
        }
      />
    </>
  );
};

export default CWClientPortalForm;
