import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketNotes } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketNotes = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketNotes`,
  async ({ crmDefaultId, ticketId }) => {
    const notes = getConnectWiseTicketNotes(crmDefaultId, ticketId);
    return notes;
  },
);

export default {};
