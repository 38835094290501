import { emailListValidator, requiredValidator } from '@utils/validators';

const handleErrors = values => {
  return {
    subject: requiredValidator(values.subject),
    email: emailListValidator(values.email),
    dateTimeFormat:
      values.convertUtcToLocal && values.isFormatInvalid
        ? 'Invalid format'
        : undefined,
  };
};

export default handleErrors;
