import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Menu, MenuItem } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Chip from '@common/Chip/Chip';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import useStyles from './styles';

const ChipDialog = ({ name, values, setValues, fullArray }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!values) {
    return null;
  }

  const getMenuItems = () => {
    return fullArray.filter(el => {
      return !values.includes(el);
    });
  };

  const handleClick = event => {
    if (getMenuItems().length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleSelect = item => {
    setValues({
      target: {
        name,
        value: [...values, item],
      },
    });
    setAnchorEl(null);
  };
  const handleDelete = value => {
    const newData = [...values].filter(i => i !== value);
    setValues({
      target: {
        name,
        value: newData,
      },
    });
  };

  return (
    <div>
      <Paper className={classes.root}>
        <ActionButton
          handler={handleClick}
          icon={<AddIcon color="primary" />}
          toolTip="Add item"
        />
        {values.map(data => {
          return (
            <li key={data}>
              <Chip
                label={data}
                onDelete={() => handleDelete(data)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </Paper>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {getMenuItems().map(i => (
          <MenuItem key={i} onClick={() => handleSelect(i)}>
            {i}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ChipDialog.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  fullArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValues: PropTypes.func.isRequired,
};

export default ChipDialog;
