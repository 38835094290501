import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 104px)',
    overflow: 'auto',
    padding: '25px',
    background: 'white',
    margin: '10px',
    borderRadius: '15px',
  },
  dialogLabelText: {
    width: '100%',
    textAlign: 'start',
  },
  dialogLabelContainer: {
    width: '100%',
  },
  dialogActions: {
    margin: '25px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '10px',
    },
  },
  tableActions: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      margin: '0px 5px',
    },
  },
  templatesTableActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
    '& button:nth-child(odd)': {
      margin: '0.1rem',
    },
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  },
  dialogTemplateContainer: {
    '& > div': {
      width: '100%',
    },
  },
  dialogContainer: {
    minHeight: '300px',
    '& > div': {
      height: '100%',
      minWidth: '100%',
      minHeight: '500px',
    },
  },
}));

export default useStyles;
