import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ThunkDeleteConversationTemplate } from '@store/slices/messaging/thunks';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import AddConversationTemplateDialog from '../dialogs/AddConversationTemplateDialog';

const ConversationTemplatesTableItemActions = ({ record }) => {
  const [processing, setProcessing] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = useCallback(
    async id => {
      setProcessing(true);
      await dispatch(ThunkDeleteConversationTemplate(id));
      setProcessing(false);
    },
    [dispatch],
  );

  return (
    <div>
      <ActionFieldItem
        icon={DeleteIcon}
        alt="delete"
        toolTip="Delete"
        handler={() => {
          setOpenDeleteDialog(true);
        }}
      />

      <ActionFieldItem
        icon={EditIcon}
        alt="edit"
        toolTip="Edit"
        handler={() => {
          setOpenEditDialog(true);
        }}
      />

      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          onAccept={() => {
            handleDelete(record.id);
          }}
          content="Are you sure you want to delete this item?"
          title="Confirm Action"
          loading={processing}
        />
      )}

      {openEditDialog && (
        <AddConversationTemplateDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          item={record}
        />
      )}
    </div>
  );
};

ConversationTemplatesTableItemActions.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default ConversationTemplatesTableItemActions;
