/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, isFulfilled } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '../resources';

import {
  ThunkCreateLiveChatConfigurations,
  ThunkGetChatMessages,
  ThunkGetChats,
  ThunkGetLiveChatConfigurations,
  ThunkGetResources,
  ThunkUpdateLiveChatConfigurations,
  ThunkDeleteLiveChatConfigurations,
} from './thunks';

const initialState = {
  resourcesOptions: [],
  resourcesLoading: false,
  resourceChats: [],
  chatsLoading: false,
  currentChatMessages: [],
  currentChatMessagesLoading: false,
  currentUserInfo: {},
  liveChatConfigurations: [],
};

const liveChatsSlice = createSlice({
  name: LIVE_CHATS,
  initialState,
  reducers: {
    addMessage(state, action) {
      state.currentChatMessages.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(ThunkGetResources.fulfilled, (state, { payload }) => {
        state.resourcesOptions = payload;
      })

      .addCase(ThunkGetChats.fulfilled, (state, { payload }) => {
        state.resourceChats = payload;
      })

      .addCase(
        ThunkGetChatMessages.fulfilled,
        (state, { payload: { messages, ...rest } }) => {
          state.currentChatMessages = messages;
          state.currentUserInfo = rest;
        },
      )

      .addCase(
        ThunkGetLiveChatConfigurations.fulfilled,
        (state, { payload }) => {
          state.liveChatConfigurations = payload;
        },
      )

      .addCase(
        ThunkCreateLiveChatConfigurations.fulfilled,
        (state, { payload }) => {
          state.liveChatConfigurations.push(payload);
        },
      )

      .addCase(
        ThunkUpdateLiveChatConfigurations.fulfilled,
        (state, { payload }) => {
          state.liveChatConfigurations = state.liveChatConfigurations.map(i =>
            +i.id === +payload.id ? payload : i,
          );
        },
      )
      .addCase(
        ThunkDeleteLiveChatConfigurations.fulfilled,
        (state, { payload }) => {
          state.liveChatConfigurations = state.liveChatConfigurations.filter(
            i => i.id !== payload,
          );
        },
      )

      .addMatcher(isAnyOf(ThunkGetResources.pending), state => {
        state.resourcesLoading = true;
      })

      .addMatcher(isFulfilled(ThunkGetResources), state => {
        state.resourcesLoading = false;
      })

      .addMatcher(isAnyOf(ThunkGetChats.pending), state => {
        state.chatsLoading = true;
      })

      .addMatcher(isFulfilled(ThunkGetChats), state => {
        state.chatsLoading = false;
      })

      .addMatcher(isAnyOf(ThunkGetChatMessages.pending), state => {
        state.currentChatMessagesLoading = true;
      })

      .addMatcher(isFulfilled(ThunkGetChatMessages), state => {
        state.currentChatMessagesLoading = false;
      });
  },
});

export const { addMessage } = liveChatsSlice.actions;
export default liveChatsSlice.reducer;
