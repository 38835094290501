import React from 'react';
import PropTypes from 'prop-types';

import Cell from '@common/MuiVirtualizedTable/components/Cell';
import Checkbox from '@common/Checkbox';
import { CHECKBOXES_WIDTH } from '@common/MuiVirtualizedTable/constants';

const getCheckboxCell = ({ classes, ids, selectedIds, onToggleItem, rows }) => {
  return {
    key: 'checkbox',
    width: CHECKBOXES_WIDTH,
    formatter: Cell,
    hidden: false,
    frozen: true,
    groupToggle: childRowsIds => {
      return (
        <div className={classes.checkboxWrapper}>
          <Checkbox
            input={{
              checked: selectedIds.some(o => childRowsIds.includes(o)),
              onChange: () => onToggleItem(childRowsIds),
            }}
          />
        </div>
      );
    },
    name: (
      <div className={classes.checkboxWrapper}>
        <Checkbox
          input={{
            checked: selectedIds.length === rows.length,
            onChange: () => onToggleItem(ids),
          }}
        />
      </div>
    ),
    accessor: rowData => (
      <Checkbox
        input={{
          checked: selectedIds.includes(rowData.id),
          onChange: () => onToggleItem(rowData.id),
        }}
      />
    ),
  };
};

getCheckboxCell.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  ids: PropTypes.arrayOf(PropTypes.any),
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  onToggleItem: PropTypes.func,
  rows: PropTypes.objectOf(PropTypes.any),
};

export default getCheckboxCell;
