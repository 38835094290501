import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px 0px',
  },
  tableActionContainer: {
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
    '@media (max-width: 767px)': {
      margin: '0 auto',
      rowGap: '10px',
      padding: '10px',
      width: '90%',
      '& > button': {
        width: '100%',
        marginLeft: '0px',
        marginTop: '10px',
      },
    },
  },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
  tableLink: {
    color: 'blue',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dialogUserInfoContainer: {
    margin: '5px 0px',
    '& > div': {
      '& > b': {
        marginRight: '5px',
      },
      '& > span': {
        marginLeft: '10px',
        cursor: 'pointer',
      },
    },
  },
  unActiveTick: {
    cursor: 'not-allowed',
    '& > img': {
      cursor: 'not-allowed',
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
  },
  tableBulkActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      marginLeft: '10px',
    },
  },
}));

export default useStyles;
