import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import cx from 'classnames';

import useStyles from './styles';

const viewTypes = {
  switch: '#ADADADFF 0%, #ADADADFF 100%',
  selector: '#ADADADFF 0%, #ADADADFF 100%',
  instant: '#ADADADFF 0%, #ADADADFF 100%',
};

const SwitchComponent = ({
  classNameWrapper,
  input,
  viewType = 'switch',
  ...props
}) => {
  const classes = useStyles({ colorType: viewTypes[viewType] });

  return (
    <Switch
      className={cx(classes.switcher, classNameWrapper)}
      {...input}
      {...props}
    />
  );
};

SwitchComponent.propTypes = {
  classNameWrapper: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  viewType: PropTypes.oneOf(['switch', 'selector', 'instant']),
};

export default SwitchComponent;
