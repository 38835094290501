import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainContainer: {
    fontStyle: 'normal',
  },
  rowContainer: {
    display: 'flex',
    padding: '3px',
    borderBottom: '1px solid #d7d7d7',
    alignItems: 'center',
  },
  nameCol: {
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  urlCol: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionCol: {
    width: '25px',
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

export default useStyles;
