import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Modal from '@ui/common/Modal/Modal';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';

import { actions } from '@store/actions';
import { requiredValidator } from '@utils/validators';

import { editMsTenantName } from '@services/requests/integration';

import useStyles from '../styles';

const EditMsTenantNameDialog = ({ item, setModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState(false);

  const submit = values => {
    setProcessing(true);
    editMsTenantName(item.id, values)
      .then(() => {
        setModal(null);
        dispatch(
          actions.updateItemInList({
            ...item,
            msTenantName: values.msTenantName,
          }),
        );
        enqueueSnackbar('Tenant name was updated', {
          variant: 'success',
        });
      })
      .catch(err => {
        enqueueSnackbar(err.title || 'Something went wrong', {
          variant: 'error',
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    msTenantName: requiredValidator(values.msTenantName),
  });

  return (
    <Modal
      open
      setOpen={setModal}
      title="Edit Microsoft 365 Tenant Name"
      isCloseButton={false}
    >
      <Form
        onSubmit={submit}
        initialValues={{ msTenantName: item.msTenantName }}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FieldWrapper
              label="Tenant name"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="msTenantName"
                  id="msTenantName"
                  render={Input}
                  disabled={processing}
                />
              }
            />
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Cancel"
                onClick={() => {
                  setModal(null);
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
              />
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

EditMsTenantNameDialog.propTypes = {
  setModal: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
    msTenantName: PropTypes.string,
  }),
};

export default EditMsTenantNameDialog;
