import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAutotaskTicketResources } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetAutotaskTicketResources = createAsyncThunk(
  `${CRM_TICKET}/getAutotaskTicketResources`,
  async ({ crmId }) => {
    const resources = await getAutotaskTicketResources(crmId);
    return resources;
  },
);

export default {};
