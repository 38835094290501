import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteTechnicianNumber } from '@services/requests/crm';
import { MESSAGING } from '@store/slices/resources';

export const ThunkDeleteTechnicianNumber = createAsyncThunk(
  `${MESSAGING}/deleteTechnicianNumber`,
  async channelId => {
    await deleteTechnicianNumber(channelId);
    return true;
  },
);

export default {};
