import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../styles';

const SourceTableItem = ({ rowData }) => {
  const sourceText = useMemo(
    () => rowData.triggerName || rowData.webHookName || rowData.parserName,
    [rowData],
  );

  const sourceType = useMemo(() => rowData.incidentSource, [rowData]);

  const sourceColor = useMemo(() => {
    if (rowData.triggerName && rowData.incidentSource === 'Trigger')
      return '#38A000';
    if (rowData.parserName) return '#000CF5';
    if (rowData.webHookName) return '#9515f5';
    return '#D00098';
  }, [rowData]);

  const classes = useStyles({ color: sourceColor });
  return (
    <div className={classes.sourceWrapper}>
      {sourceText}
      <p className={classes.sourceType}>{`[${sourceType}]`}</p>
    </div>
  );
};

SourceTableItem.propTypes = {
  rowData: PropTypes.shape({
    triggerName: PropTypes.string,
    webHookName: PropTypes.string,
    parserName: PropTypes.string,
  }),
};

export default SourceTableItem;
