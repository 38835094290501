import appConfig from '@configs/appConfig';
import { notificationGroups } from '@constants/appRoutes';
import deleteData from '@services/api/common/deleteData';
import getData from '@services/api/common/getData';
import postWithResponse from '@services/api/common/postWithResponse';
import putWithResponse from '@services/api/common/putWithResponse';

const resource = '/SchedulerRules';

export const prepareDate = date => date.split('T')[0];

/**
 *
 * @param {*} res
 * @param {*} match
 * @returns eventsArray
 */
export const generateEventArray = (res, selectedGroup) => {
  const events = [];
  res.forEach(rule => {
    rule.periods.forEach(period => {
      events.push({
        start: period.start,
        end: period.end,
        color: rule.color,
        title: rule.title,
        userId: rule.userId,
        userName: rule.userName,
        ruleId: rule.schedulerRuleId,
        groupId: selectedGroup,
      });
    });
  });
  return events;
};

export const getSchedulerRulesForGroup = (id, startDate, endDate) =>
  getData(
    `${appConfig.baseUrl}${resource}/group/${id}/calendar?from=${startDate}&to=${endDate}`,
  );

export const getSchedulerRulesForUser = (id, startDate, endDate) =>
  getData(
    `${appConfig.baseUrl}${resource}/user/${id}/calendar?from=${startDate}&to=${endDate}`,
  );

export const createScheduleRule = data =>
  postWithResponse(`${appConfig.baseUrl}${resource}`, data);

export const updateScheduleRule = (id, data) =>
  putWithResponse(`${appConfig.baseUrl}${resource}/${id}`, data);

export const getScheduleRule = id =>
  getData(`${appConfig.baseUrl}${resource}/${id}`);

export const getNotificationGroupUsers = groupId =>
  getData(
    `${appConfig.baseUrl}${notificationGroups}users/${groupId}/autocomplete`,
  );

export const deleteScheduleRule = id =>
  deleteData(`${appConfig.baseUrl}${resource}/${id}`);

export const testScheduleRule = data =>
  postWithResponse(
    `${appConfig.baseUrl}${resource}/test?from=${data.startDate}&to=${data.endDate}`,
    data,
  );

export const getNotificationGroupAutocomplete = () =>
  getData(`${appConfig.baseUrl}/NotificationGroups/groups`);

export const cloneScheduleRule = ruleId =>
  postWithResponse(`${appConfig.baseUrl}${resource}/clone/${ruleId}`);
