import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import {
  dataSourcesIntegrations,
  integrationAutotask,
  integrationConnectWise,
  integrationMeraki,
  integrationOrganizations,
  merakiAlertTemplate,
  merakiOrganizationAlertSettings,
  sophosAlerts,
  sophosEndpoints,
  sophosTenants,
} from '@constants/appRoutes';
import Duo from '@assets/logos/duoAuth.png';
import DuoAdmin from '@assets/logos/duoAdmin.png';
import DuoWebSdk from '@assets/logos/duoWebSdk.png';
import MicrosoftAuthenticator from '@assets/logos/microsoftAuthenticator.png';
import postWithResponse from '@services/api/common/postWithResponse';
import deleteData from '@services/api/common/deleteData';
import putData from '@services/api/common/putData';
import { securityIntegrationSources } from '@constants/integrationSources';
import postData from '@services/api/common/postData';
import putWithResponse from '@services/api/common/putWithResponse';
import deleteWithResponse from '@services/api/common/deleteWithResponse';

export const getIntegrationById = id =>
  getData(`${appConfig.baseUrl}${dataSourcesIntegrations}${id}`);

export const createIntegration = data =>
  postWithResponse(appConfig.baseUrl.concat(dataSourcesIntegrations), data);

export const updateIntegration = (id, data) =>
  putData(`${appConfig.baseUrl}${dataSourcesIntegrations}${id}`, data);

export const getIntegrationImage = name =>
  ({
    [securityIntegrationSources.Duo.name]: Duo,
    [securityIntegrationSources.DuoAdmin.name]: DuoAdmin,
    [securityIntegrationSources.DuoWebSdk.name]: DuoWebSdk,
    [securityIntegrationSources.MicrosoftAuthenticator
      .name]: MicrosoftAuthenticator,
  }[name] || null);

export const selectAllIntegrationOrganizations = data =>
  postWithResponse(
    `${appConfig.baseUrl}${integrationOrganizations}groupAdd`,
    data,
  );

export const deselectAllIntegrationOrganizations = id =>
  postWithResponse(
    `${appConfig.baseUrl}${integrationOrganizations}groupDelete?integrationId=${id}`,
  );

export const getMerakiDeviceStatuses = (integrationId, organizationId) =>
  getData(
    `${appConfig.baseUrl}${integrationMeraki}${integrationId}/deviceStatuses?orgId=${organizationId}`,
  );

export const getSophosTenants = itemId =>
  getData(appConfig.baseUrl.concat(sophosTenants(itemId)));

export const getSophosTenantEndpoints = (
  itemId,
  tenantId,
  tenantUrl,
  useTenantName = false,
) =>
  getData(
    appConfig.baseUrl.concat(
      sophosEndpoints(itemId, tenantId, tenantUrl, useTenantName),
    ),
  );

export const getSophosTenantAlerts = (itemId, tenantId, tenantUrl) =>
  getData(appConfig.baseUrl.concat(sophosAlerts(itemId, tenantId, tenantUrl)));

export const getMerakiOrganizationAlertSettings = (itemId, organizationId) =>
  getData(
    appConfig.baseUrl.concat(
      merakiOrganizationAlertSettings(itemId, organizationId),
    ),
  );

export const updateAlertSettings = (integrationId, data) =>
  postWithResponse(
    `${appConfig.baseUrl}${integrationMeraki}${integrationId}/applyAlertTemplate`,
    data,
  );

export const getIntegrationInfo = id =>
  getData(`${appConfig.baseUrl}${dataSourcesIntegrations}${id}`);

export const getMerakiHookAlerts = (integrationId, organizationId) =>
  getData(
    `${appConfig.baseUrl}${integrationMeraki}${integrationId}/getAlerts?orgId=${organizationId}`,
  );

export const getTemplateOptions = () =>
  getData(`${appConfig.baseUrl}${merakiAlertTemplate}getAutocomplete`);

export const getTemplateData = id =>
  getData(`${appConfig.baseUrl}${merakiAlertTemplate}${id}`);

export const updateTemplateData = (id, data) =>
  putWithResponse(`${appConfig.baseUrl}${merakiAlertTemplate}${id}`, data);

export const createTemplateData = data =>
  postWithResponse(`${appConfig.baseUrl}${merakiAlertTemplate}`, data);

export const deleteTemplateData = id =>
  deleteData(`${appConfig.baseUrl}${merakiAlertTemplate}${id}`);

export const applyAlertTemplatesById = (integrationId, data) =>
  postWithResponse(
    `${appConfig.baseUrl}${integrationMeraki}${integrationId}/applyAlertTemplateById`,
    data,
  );

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const logTemplateApplying = model =>
  postData(
    `${appConfig.baseUrl}${integrationMeraki}logTemplateApplication`,
    model,
  );

export const getAtHooks = crmId =>
  getData(`${appConfig.baseUrl}${integrationAutotask}${crmId}/getHooks`);

export const deleteAtHooks = (crmId, hookId) =>
  deleteWithResponse(
    `${appConfig.baseUrl}${integrationAutotask}${crmId}/deleteHook/${hookId}`,
  );

export const getCwHooks = crmId =>
  getData(`${appConfig.baseUrl}${integrationConnectWise}${crmId}/getHooks`);

export const deleteCwHooks = (crmId, hookId) =>
  deleteWithResponse(
    `${appConfig.baseUrl}${integrationConnectWise}${crmId}/deleteHook/${hookId}`,
  );
