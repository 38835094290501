import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../../../styles';

const NetworkItem = ({ id, name, orgName, list, onClick }) => {
  const classes = useStyles();
  const checked = list.some(i => i.id === id);

  const handleClick = () => {
    onClick(id, name, orgName, checked);
  };

  return (
    <div
      role="presentation"
      className={cx(classes.columnItem, classes.networkColumnItem)}
      onClick={handleClick}
    >
      <Checkbox
        style={{ height: '25px' }}
        checked={checked}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span>{name}</span>
    </div>
  );
};

NetworkItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  list: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NetworkItem;
