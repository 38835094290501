import React from 'react';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

import Input from '@components/Auth/Common/Input';
import Switch from '@common/FilterInputs/Switch';

import { maxLengthProps } from '@utils/maxLengthProps';

const ConnectWiseNoteForm = ({ processing }) => {
  const { values } = useFormState({ subscription: { values: true } });

  return (
    <>
      <FieldWrapper
        label="Text:"
        labelSize={12}
        contentSize={12}
        fullWidth
        content={
          <Field
            name="text"
            id="text"
            render={Input}
            {...maxLengthProps(values.text)}
            disabled={processing}
            multiline
            minRows={5}
          />
        }
      />

      <FieldWrapper
        label="Internal"
        labelSize={2}
        contentSize={10}
        fullWidth
        content={
          <Field
            name="internalFlag"
            id="internalFlag"
            type="checkbox"
            render={Switch}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Resolution"
        labelSize={2}
        contentSize={10}
        fullWidth
        content={
          <Field
            name="resolutionFlag"
            id="resolutionFlag"
            type="checkbox"
            render={Switch}
            disabled={processing}
          />
        }
      />
    </>
  );
};

ConnectWiseNoteForm.propTypes = {
  processing: PropTypes.bool,
};

export default ConnectWiseNoteForm;
