import React, { useState, useMemo } from 'react';
import { usePermissions } from 'react-admin';
import Loading from '@common/Loading/Loading';
import { Redirect } from 'react-router-dom';

import useAsync from '@services/api/common/useAsync';
import { CRM_CREATE } from '@constants/routes';
import { basePermissions } from '@constants/permissions';
import useStyles from './styles';

import { getIntegrations } from './helpers';
import CrmCard from './components/CrmCard';
import AddCrmCard from './components/AddCrmCard';

const Integrations = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  const allowCreate = useMemo(
    () => permissions && permissions.integrations[basePermissions.create],
    [permissions],
  );

  const [data, setData] = useState({});
  useAsync(getIntegrations, setData);

  const isDataLoaded = Array.isArray(data);

  if (isDataLoaded && !data?.length) {
    return allowCreate ? (
      <Redirect to={CRM_CREATE} />
    ) : (
      <span className={classes.noIntegrations}>
        No integrations in the current tenant
      </span>
    );
  }

  const content = () => {
    if (isDataLoaded) {
      return (
        <div className={classes.div}>
          {data.map(i => (
            <CrmCard key={i.id} item={i} />
          ))}
          {allowCreate && <AddCrmCard />}
        </div>
      );
    }
    return <Loading />;
  };
  return content();
};

export default Integrations;
