import React from 'react';
import { useHistory } from 'react-router-dom';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import { SCHEDULE_USER } from '@constants/routes';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';
import CalendarIcon from '@assets/icons/updated/calendar.svg';

const UsersGridActionField = ({ record, onEdit, onDelete }) => {
  const { push } = useHistory();
  return (
    <div>
      <ActionFieldItem
        handler={() => {
          push(
            SCHEDULE_USER.replace(':userId', record.id).replace(
              ':groupId',
              record.notificationGroupId,
            ),
          );
        }}
        toolTip="Calendar"
      >
        <img src={CalendarIcon} alt="calendar" />
      </ActionFieldItem>

      <ActionFieldItem handler={() => onEdit(record)} toolTip="Edit">
        <img src={EditIcon} alt="edit" />
      </ActionFieldItem>

      <ActionFieldItem handler={() => onDelete(record)} toolTip="Delete">
        <img src={DeleteIcon} alt="delete" />
      </ActionFieldItem>
    </div>
  );
};

export default UsersGridActionField;
