import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import {
  addCustomField,
  customFields,
  letterDetails,
} from '@constants/appRoutes';
import postData from '@services/api/common/postData';

const attributesRegex = /(\s+((?!rowspan|colspan)[\w_:]+)=(?<q>["'])(.*?)\k<q>)|(\s+((?!rowspan|colspan)[\w_:]+)=[\w\d\-_#]+)/gis;
const brRegex = /(<br\s*\/?>)/gis;
const tBodyRegex = /(<\/?tbody>)/gis;
const metaRegex = /(<meta[^>]*?>)/gis;
const titleRegex = /(<title.+?\/title>)/gis;

export const getAttachmentFileName = name =>
  name.startsWith('attachment_') ? name.substring(11) : null;

export const hasSameFileTypes = (bodyView, attachments) => {
  const filename = bodyView.startsWith('attachment_')
    ? bodyView.substring(11)
    : null;
  const fileExt = /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
  return (
    attachments &&
    attachments
      .filter(p => p.name !== filename)
      .some(i => i.name?.endsWith(fileExt))
  );
};

const textAndClosedTagsRegex = new RegExp(
  '(?<br><brs*/?>)|(>|^)([^<]*)|(?<closedTags><(?<tag>w*)[^>]*>[^<]*</k{tag}>)|(?<endTag><[^/][^>]*>)*$',
  'gmi',
);

export const postAddCustomField = data =>
  postData(appConfig.baseUrl.concat(addCustomField(data)), undefined);

export const getLetterDetails = id =>
  getData(appConfig.baseUrl.concat(letterDetails).concat(id));

export const getCustomFields = () =>
  getData(appConfig.baseUrl.concat(customFields));

export const getHeaderName = el => {
  if (!(el instanceof Element)) return;
  return el.parentNode.children[0]?.innerText;
};

export const getDomPath = (el, trimId) => {
  if (!(el instanceof Element)) return;
  const stack = [];
  while (el.parentNode != null) {
    let sibCount = 0;
    let sibIndex = 0;
    for (let i = 0; i < el.parentNode.childNodes.length; i++) {
      const sib = el.parentNode.childNodes[i];
      if (sib.nodeName === el.nodeName) {
        if (sib === el) {
          sibIndex = sibCount;
        }
        sibCount++;
      }
    }

    // TODO if document has several TBODY tags we'll have a problem cause all TRs splits into one table.
    if (el.hasAttribute('id') && el.id === trimId) {
      stack.unshift(`${el.nodeName.toLowerCase()}#${el.id}`);
    } else if (sibCount > 1 && el.nodeName !== 'TBODY') {
      stack.unshift(`${el.nodeName.toLowerCase()}[${sibIndex + 1}]`);
    } else if (
      el.nodeName !== 'TBODY' &&
      el.nodeName !== 'BODY' &&
      el.nodeName !== 'HTML'
    ) {
      stack.unshift(el.nodeName.toLowerCase());
    }
    el = el.parentNode;
  }
  const idx = stack.indexOf(`div#${trimId}`);
  const slicePosition = idx >= 0 ? idx + 2 : 1;
  return stack.slice(slicePosition); // removes the html element
};

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

export const getBadge = text => {
  return `<span class="selectedBadge">${text}</span>`;
};

// const getPreviousSibling = node => {
//   if (node.previousSibling != null) {
//     return node.previousSibling;
//   }
//   if (node.parentNode != null) {
//     return getPreviousSibling(node.parentNode);
//   }
//   return null;
// };

export const removeHtmlAttributes = text =>
  !text
    ? text
    : text
        .replace(attributesRegex, '')
        .replace(brRegex, '<br>')
        .replace(tBodyRegex, '')
        .replace(metaRegex, '')
        .replace(titleRegex, '');

export const startWithSpacesRegex = /^\s+/gis;
export const startsWithSpaces = text =>
  text && text.match(startWithSpacesRegex);
export const allSpaces = text => text && text.match(/^\s+$/gis);
export const countStartWhitespace = text =>
  text ? text.match(startWithSpacesRegex)[0].length : 0;

export const getElementOffsetFromParent = (
  element,
  parent,
  converter = text => text,
) => {
  let offset = 0;
  let whitespaceOffset = 0;
  while (element.parentElement !== parent || element.previousSibling != null) {
    if (element.previousSibling != null) {
      const convertedHtml = converter(element.previousSibling.outerHTML);
      offset += convertedHtml?.length || element.previousSibling.length || 0;
      if (
        typeof convertedHtml === 'undefined' &&
        startsWithSpaces(element.previousSibling.data)
      ) {
        if (allSpaces(element.previousSibling.data)) {
          whitespaceOffset += element.previousSibling.length;
        } else {
          whitespaceOffset = countStartWhitespace(element.previousSibling.data);
        }
      } else if (convertedHtml?.length !== 0) {
        whitespaceOffset = 0;
      }

      element = element.previousSibling;
    } else {
      offset += converter(
        element.parentElement.outerHTML.substring(
          0,
          element.parentElement.outerHTML.indexOf(
            `>${element.parentElement.innerHTML}`,
          ) + 1,
        ),
      ).length;
      element = element.parentElement;
    }
  }

  return Math.max(offset - whitespaceOffset - 1, 0);
};

export const getCaretCharacterOffsetWithin = element => {
  let caretOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);

      return (
        getElementOffsetFromParent(
          range.startContainer,
          element,
          removeHtmlAttributes,
        ) + range.startOffset
      );
    }
  } else if ((sel = doc.selection) && sel.type !== 'Control') {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
};

export const prepareValue = value => {
  let outStr = value;
  const firstTagPattern = /^<(.*?)>/gm;
  if (firstTagPattern.test(value)) {
    outStr = value.replace(firstTagPattern, '');
  }
  return outStr.replace(tBodyRegex, '');
};

// Get common ancestor
// https://stackoverflow.com/questions/3960843/how-to-find-the-nearest-common-ancestors-of-two-or-more-nodes/7648545#7648545
export const commonAncestor = (node1, node2) => {
  const method = 'contains' in node1 ? 'contains' : 'compareDocumentPosition';
  const test = method === 'contains' ? 1 : 0x10;

  if (node1 === node2) {
    return node1;
  }

  while ((node1 = node1.parentNode)) {
    if ((node1[method](node2) & test) === test) return node1;
  }

  return null;
};

export const getUnclosedTags = text => {
  let oldText = text;

  do {
    oldText = text;
    text = text.replace(textAndClosedTagsRegex, '$2');
  } while (oldText !== text);

  return oldText;
};
