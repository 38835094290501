import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CLIENT_PORTAL_CONFIGS } from '@constants/routes';
import { ThunkGetClientPortalConfigurationOptions } from '@store/slices/clientPortalAdmin/thunks';

import {
  SelectClientPortalConfigurationOptions,
  SelectClientPortalConfigurationOptionsLoading,
} from '@store/slices/clientPortalAdmin/selectors';
import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@common/Loading/Loading';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import ReusableButton from '@common/Button/Button';
import ClientPortalChatActions from '@components/ClientPortal/components/ClientPortalChatActions';
import ClientPortalChatTableActions from '@components/ClientPortal/components/ClientPortalChatTableActions';

import getFormattedDate from '@utils/getFormattedDate';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import { booleanOptions } from '@components/PsaTickets/components/Halo/constants';
import { currentUserDataSelector } from '@store/selectors';
import Roles from '@constants/roles';
import useStyles from './styles';

const ClientChats = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { push } = useHistory();

  const configurationOptions = useSelector(
    SelectClientPortalConfigurationOptions,
  );
  const configurationOptionsLoading = useSelector(
    SelectClientPortalConfigurationOptionsLoading,
  );
  const currentUser = useSelector(currentUserDataSelector);

  const [selectedConfig, setSelectedConfig] = useState(undefined);

  const columns = useMemo(
    () => [
      {
        name: 'Is Answered',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'isAnswered',
        key: 'isAnswered',
        minWidth: 175,
        width: 175,
        isCustomAccessor: true,
        accessor: rowData => (
          <BooleanAccessor value={rowData.isAnswered} useMultiColor />
        ),
      },
      {
        name: 'Is Closed',
        key: 'isClosed',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        type: TableTypes.dropdown,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'isClosed',
        minWidth: 175,
        width: 175,
        accessor: rowData => (
          <BooleanAccessor value={rowData.isClosed} useMultiColor />
        ),
      },
      {
        name: 'Name',
        sortable: true,
        searchable: true,
        key: 'name',
        minWidth: 175,
      },
      {
        name: 'Last Message Date',
        key: 'lastMessageDate',
        sortable: true,
        isCustomAccessor: true,
        minWidth: 200,
        accessor: i => <>{getFormattedDate(i.lastMessageDate)}</>,
      },
      {
        name: 'Last Message',
        searchable: true,
        key: 'lastMessage',
        minWidth: 200,
      },
      {
        name: 'Ticket ID',
        key: 'ticketId',
        searchable: true,
        sortable: true,
        isCustomAccessor: true,
        minWidth: 150,
        accessor: item => (
          <div
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            role="presentation"
            onClick={() => {
              window.open(item.ticketLink, '_blank');
            }}
          >
            {item?.ticketId}
          </div>
        ),
      },
      {
        name: 'Contact Name',
        key: 'contactName',
        minWidth: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Assignee Name',
        key: 'assigneeName',
        minWidth: 200,
        searchable: true,
        sortable: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        minWidth: 100,
        accessor: item => (
          <ClientPortalChatTableActions
            {...item}
            selectedResource={selectedConfig}
          />
        ),
      },
    ],
    [selectedConfig],
  );

  useEffect(() => {
    if (!!configurationOptions && configurationOptions.length === 1) {
      setSelectedConfig(configurationOptions[0].value);
    }
  }, [configurationOptions]);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetClientPortalConfigurationOptions());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (configurationOptionsLoading) return <Loading />;

  return (
    <div className={classes.container}>
      <div className={classes.tableActions}>
        <DropDown
          options={configurationOptions}
          input={{
            onChange: e => setSelectedConfig(e.target.value),
            value: +selectedConfig,
          }}
          classNameWrapper={classes.configSelector}
        />
        {currentUser && currentUser?.roleId !== Roles.READ_ONLY && (
          <ReusableButton
            label="Configurations"
            onClick={() => push(CLIENT_PORTAL_CONFIGS)}
          />
        )}
      </div>
      {!selectedConfig && (
        <div className={classes.selectLanguage}>
          Select Client Portal configuration
        </div>
      )}
      {selectedConfig && (
        <div className={classes.tableContainer}>
          <Table
            actions={<ClientPortalChatActions />}
            columns={columns}
            resource={`ClientPortalChats/configurations/${selectedConfig}/chats`}
            defaultFilters={{ isClosed: false }}
            defaultSort={{ fieldName: 'lastMessageDate', order: 'DESC' }}
          />
        </div>
      )}
    </div>
  );
};

export default ClientChats;
