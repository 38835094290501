import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FormSwitch from '@common/FilterInputs/Switch';
import Input from '@common/Input/Input';
import { OnChange } from 'react-final-form-listeners';
import FieldWrapper from '@common/form/FieldWrapper';
import { ThunkConvertDateTime } from '@store/slices/common/thunks';
import { useDispatch } from 'react-redux';
import help from '@constants/help';
import HelpBox from '@common/HelpBox/HelpBox';

const DateFormatSelector = ({ values, disabled, data, form }) => {
  const dispatch = useDispatch();

  const [formatValue, setFormatValue] = useState('');

  const handleChangeFormat = value => {
    dispatch(ThunkConvertDateTime({ value }))
      .unwrap()
      .then(d => {
        setFormatValue(d.data);
        form.change('isFormatInvalid', d.data === 'Invalid format');
      })
      .catch(() => setFormatValue('Invalid format'));
  };

  const handleChangeSwitch = value => {
    if (value) {
      handleChangeFormat(values.dateTimeFormat);
    }
  };

  useEffect(() => {
    if (data && data.convertUtcToLocal) {
      handleChangeFormat(data.dateTimeFormat);
    }
  }, [data]);

  return (
    <FieldWrapper
      label="Convert UTC to Local"
      labelSize={3}
      content={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Field
            id="convertUtcToLocal"
            name="convertUtcToLocal"
            type="checkbox"
            viewType="selector"
            component={FormSwitch}
            disabled={disabled}
          />
          <OnChange name="convertUtcToLocal">{handleChangeSwitch}</OnChange>
          {values.convertUtcToLocal && (
            <>
              <Field
                id="dateTimeFormat"
                name="dateTimeFormat"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                disabled={disabled}
              />
              <OnChange name="dateTimeFormat">{handleChangeFormat}</OnChange>
              <HelpBox resource={help.commonDateFormat} />
              <div
                style={{
                  width: '100%',
                  marginLeft: '0.5rem',
                  color: values.isFormatInvalid ? 'red' : 'gray',
                }}
              >
                {formatValue}
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

DateFormatSelector.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default DateFormatSelector;
