import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { MessagingClientHistorySelector } from '@store/slices/messaging';
import getFormattedDate from '@utils/getFormattedDate';

import useStyles from '../styles';

const HistorySubList = ({ messagesLoading }) => {
  const clientHistory = useSelector(MessagingClientHistorySelector);

  const classes = useStyles();
  return (
    <div>
      <div className={classes.subListHeader}>
        Client Messages
        {messagesLoading && (
          <CircularProgress style={{ width: '20px', height: '20px' }} />
        )}
      </div>
      <div className={classes.subList}>
        {!messagesLoading &&
          clientHistory.map(i => (
            <div key={i.id} className={classes.subListItem}>
              <div>{i.body}</div>
              <div className={classes.subListItemDate}>
                {getFormattedDate(i.dateTimeUtc)}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

HistorySubList.propTypes = {
  messagesLoading: PropTypes.bool.isRequired,
};

export default HistorySubList;
