import React, { useState } from 'react';
import { useRedirect, useSafeSetState } from 'react-admin';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import useAsync from '@services/api/common/useAsync';
import ReusableButton from '@common/Button/Button';
import Paper from '@common/Paper/Paper';
import Loading from '@common/Loading/Loading';
import Input from '@common/Input/Input';
import { getMfaInfo, postMfaInfo } from './helpers';

import useStyles from './styles';

const MultiFactorSetup = ({ refetch = () => {} }) => {
  const redirect = useRedirect();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useSafeSetState(false);
  const [data, setData] = useState({});
  useAsync(getMfaInfo, setData);

  const validate = values => {
    const errors = {
      mfCode: undefined,
    };
    if (!values.mfCode) {
      errors.mfCode = 'Required';
    }
  };

  const submit = async values => {
    const payload = {
      qrUri: data.qrUri,
      qrCode: data.qrCode,
      mfCode: values.mfCode,
    };

    setLoading(true);
    if (await postMfaInfo(payload)) {
      enqueueSnackbar('Successfully enabled 2 factor authentication', {
        variant: 'success',
      });
      setLoading(false);
      refetch();
      redirect('home');
    } else {
      enqueueSnackbar('Authentication failed', { variant: 'error' });
      setLoading(false);
    }
  };

  if (!data) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper>
              <Grid container spacing={1} justify="center" alignItems="center">
                <Grid item xs={12}>
                  To setup 2 factor authentication, please setup Google
                  Authenticator on your device and create account using QR code
                  or code. Get MFA Code from app, put it to the input. Then
                  press SAVE button
                </Grid>
                <Grid container justify="center">
                  <img
                    className={classes.qrCodeStyle}
                    src={data.qrUri}
                    alt="new"
                  />
                </Grid>
                <Grid container justify="center" style={{ margin: '0.5rem' }}>
                  {data.qrCode}
                </Grid>
                <Grid container justify="center" style={{ margin: '0.5rem' }}>
                  <Field
                    id="mfCode"
                    name="mfCode"
                    inputView="text"
                    styleType="main"
                    component={Input}
                    disabled={loading}
                    placeholder="Multi factor auth code"
                  />
                </Grid>
                <Grid container justify="center" style={{ margin: '0.5rem' }}>
                  <ReusableButton
                    size="md"
                    viewType="black"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    label="Update"
                  />
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
    </div>
  );
};

MultiFactorSetup.propTypes = {
  refetch: PropTypes.func,
};

export default MultiFactorSetup;
