import { actionTypes } from '@store/actions';

const defaultState = {
  crmOptions: [],
};

const crmUsersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CRM_USERS_SET_CRM_OPTIONS:
      return { ...state, crmOptions: action.payload };
    default:
      return state;
  }
};

export default crmUsersReducer;
