import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMessagingChannelFromTicketByCrmId } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkCreateMessagingChannelFromTicketByCrmId = createAsyncThunk(
  `${CRM_TICKET}/createMessagingChannelFromTicketByCrmId`,
  async ({ crmId, payload }) => {
    const channel = await createMessagingChannelFromTicketByCrmId(
      crmId,
      payload,
    );
    return channel;
  },
);

export default {};
