import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { getRoleIdFromStorage } from '@services/api';
import roles from '@constants/roles';
import BulkAction from './BulkAction';
import { toggleEnabled, toggleGlobal } from '../../../helpers';
import StatusCircle from './StatusCircle';
import ActionField from './ActionField';
import useStyles from './styles';

const ParsersList = ({ onParserSelect, onDelete, mailBoxId }) => {
  const isSuperAdmin = getRoleIdFromStorage() === roles.SUPER_ADMIN;
  const dispatch = useDispatch();
  const classes = useStyles();

  const list = useSelector(listSelector);

  const parserResource = 'parsers';

  const parserFieldConfig = [
    { source: 'name', label: 'Parser Name' },
    { source: 'tenantName', label: 'Tenant' },
  ];

  const handleToggleActive = async (id, isActive) => {
    await toggleEnabled(id, mailBoxId, !isActive);
    const item = list.find(p => p.id === id);
    const newItem = {
      ...item,
      isEnabled: !isActive,
    };
    dispatch(actions.updateItemInList(newItem));
  };

  const handleToggleGlobal = async (id, isTenant) => {
    const { tenantName } = await toggleGlobal(id, isTenant);
    const item = list.find(p => p.id === id);
    const newItem = {
      ...item,
      tenantName: tenantName ?? null,
      isGlobal: !tenantName,
    };
    dispatch(actions.updateItemInList(newItem));
  };

  const queryString = `${parserResource}/mailbox/${mailBoxId}`;
  return (
    <div className={classes.tableWrapper}>
      <MuiVirtualizedTable
        actions={null}
        bulkActions={<BulkAction mailBoxId={mailBoxId} />}
        columns={[
          ...parserFieldConfig.map(({ source, label }) => ({
            name: <TableLabel item={{ name: label, field: source }} />,
            key: source,
            width: 362,
          })),
          {
            name: 'Enabled',
            sort: 'isEnabled',
            key: 'isEnabled',
            accessor: rowData => (
              <StatusCircle
                record={rowData}
                label="Enabled"
                handleClick={handleToggleActive}
              />
            ),
            width: 206,
          },
          {
            name: 'Actions',
            key: 'actions',
            accessor: rowData => (
              <ActionField
                label="Actions"
                handleUpdateClick={e => onParserSelect(e)}
                handleDeleteClick={e => onDelete(e)}
                isUserAdmin={isSuperAdmin}
                handleMakeGlobal={handleToggleGlobal}
                record={rowData}
              />
            ),
          },
        ]}
        resource={queryString}
      />
    </div>
  );
};

ParsersList.propTypes = {
  onParserSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  mailBoxId: PropTypes.string.isRequired,
};

export default ParsersList;
