import { getHeaders } from '@utils/transfered';
import { getAccessToken } from './user';

export const sendFormData = (url, formData) =>
  fetch(url, {
    method: 'POST',
    headers: {
      ...getHeaders(true),
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: formData,
  });

export const downLoadFile = url =>
  fetch(url, {
    method: 'GET',
    headers: {
      ...getHeaders(true),
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const uploadFile = async (url, formData) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...getHeaders(true),
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.text();

    return data;
  } catch (error) {
    console.error('Error during file upload:', error);
    return null;
  }
};
