import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'end',
  },
  tableContainer: {
    borderRadius: '15px 15px 0 0',
    boxShadow: 'unset',
  },
  arrow: {
    width: '18px',
  },
  sortContainer: {
    display: 'flex',
    cursor: 'pointer',
    width: 'max-content',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  createButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
  actionButton: {
    borderRadius: '20px',
    minWidth: '28px',
    '&:hover': {
      background: '#efefef',
    },
  },
  buttonAddWrapper: {
    marginTop: '0.875rem',
  },
  dataGridWrapper: ({ tableHeight }) => ({
    /**
     * we should override style due to "!important", for removing borders
     */
    '& div[role=columnheader]': {
      borderRight: 'none !important',
    },
    maxHeight: '76vh',
    /**
     * set additional "2" to height help us to avoid unnecessary horizontal scroll;
     */
    height: tableHeight + 2,
  }),

  containerHeader: {
    textAlign: 'center',
  },
  arrowDown: {
    marginLeft: '-5px',
  },
  tableHeaderButton: {
    textTransform: 'none',
    color: theme.palette.info.main,
    fontSize: '1rem',
    fontWeight: 600,
    padding: 0,
  },
}));

export default useStyles;
