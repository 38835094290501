import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 104px)',
    overflow: 'auto',
    padding: '25px',
    background: 'white',
    margin: '10px',
    borderRadius: '15px',
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
  },
  templatesTableActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
  },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
}));

export default useStyles;
