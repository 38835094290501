// in src/createAdminStore.js
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { adminReducer, USER_LOGOUT } from 'react-admin';
import thunkMiddleware from 'redux-thunk';

import combineReducer from './combinedReducer';
import errorMiddleware from './middleware/errorMiddleware';

export default ({ history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    ...combineReducer,
  });
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        errorMiddleware,
        // add your own middlewares here
      ),
    ),
  );
  return store;
};
