/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { ThunkGetTenantSettingsPhoneNumbers } from '@store/slices/common/thunks/getTenantSettingsPhoneNumbers';
import { ThunkGetTenantSettings, ThunkGetTimeZones } from './thunks';

const getTimeZonesSlice = createSlice({
  name: COMMON,
  initialState: {
    timeZones: [],
    isLoading: true,
    tenantSettings: undefined,
    phoneNumbers: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetTimeZones.fulfilled, (state, action) => {
      state.types = action.payload;
    });

    builder.addCase(ThunkGetTenantSettings.fulfilled, (state, action) => {
      state.tenantSettings = action.payload;
    });

    builder.addCase(
      ThunkGetTenantSettingsPhoneNumbers.fulfilled,
      (state, action) => {
        state.phoneNumbers = action.payload;
      },
    );
    builder.addCase(ThunkGetTenantSettingsPhoneNumbers.pending, state => {
      state.isLoading = true;
    });

    builder.addMatcher(
      isAnyOf(
        ThunkGetTimeZones.fulfilled,
        ThunkGetTenantSettingsPhoneNumbers.fulfilled,
      ),
      state => {
        state.isLoading = false;
      },
    );
  },
});

export const TenantSettingsSelector = state => state[COMMON].tenantSettings;
export const TenantSettingsStateSelector = state => state[COMMON];

export default getTimeZonesSlice.reducer;
