import { useCallback, useState } from 'react';
import { getTemplateVariables } from '@components/Ticketing/Notifications/TicketSettings/helpers';
import useAsync from '@services/api/common/useAsync';
import { useSelector } from 'react-redux';
import { notificationSettingSource } from '@store/selectors/notifications';

const useTemplateVariables = ({ paramsId, dataViewId, func }) => {
  const src = useSelector(notificationSettingSource);
  const execFunc = func || getTemplateVariables;
  const isCreate = paramsId === '0';
  const id = isCreate ? dataViewId : paramsId;

  const [templateVariables, setTemplateVariables] = useState([]);

  const getVariables = useCallback(() => execFunc(id, isCreate, src === 1), [
    execFunc,
    id,
    isCreate,
    src,
  ]);

  useAsync(getVariables, setTemplateVariables);

  return { templateVariables };
};

export default useTemplateVariables;
