import React from 'react';
import cx from 'classnames';
import DropDown from '@components/Auth/Common/DropDown';
import { Field } from 'react-final-form';
import Checkbox from '@common/Checkbox/FormControlCheckBox';
import SwitchComponent from '@common/FilterInputs/Switch';
import {
  periodInMinutesOptions,
  ssidOptions,
  thresholdOptions,
} from './options';
import useStyles from './styles';

const FailToConnectAlert = ({ form }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.flexDisplaying}>
        <Field
          id="smartEnabled"
          name="smartEnabled"
          classNameWrapper={classes.inputWrapper}
          input={{
            checked: form.getFieldState('smartEnabled')?.value || false,
            onChange: (_, value) => form.change('smartEnabled', value),
          }}
          component={SwitchComponent}
        />
        <span>Enable smart thresholds</span>
      </div>
      <div className={classes.flexDisplaying}>
        <span>Clients using</span>
        <Field
          id="ssidNum"
          name="ssidNum"
          styleType="main"
          inputView="text"
          classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
          component={DropDown}
          options={ssidOptions}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
        <span>width</span>
        <Field
          id="threshold"
          name="threshold"
          styleType="main"
          inputView="text"
          classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
          component={DropDown}
          options={thresholdOptions}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
        <span>failure of</span>
      </div>
      <div className={classes.flexDisplaying}>
        <Field
          id="assoc"
          name="assoc"
          label="Association"
          type="checkbox"
          component={Checkbox}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
        <Field
          id="auth"
          name="auth"
          label="Authentication"
          type="checkbox"
          component={Checkbox}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
        <Field
          id="dhcp"
          name="dhcp"
          label="DHCP"
          type="checkbox"
          component={Checkbox}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
        <Field
          id="dns"
          name="dns"
          label="DNS"
          type="checkbox"
          component={Checkbox}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
        <span>for more than</span>
        <Field
          id="duration"
          name="duration"
          styleType="main"
          inputView="text"
          classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
          component={DropDown}
          options={periodInMinutesOptions}
          disabled={form.getFieldState('smartEnabled')?.value}
        />
      </div>
    </div>
  );
};

export default FailToConnectAlert;
