import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';

import { isGroupExists } from '@components/Settings/NotificationGroups/helpers';
import { emailGroupNameLength } from '@constants/fieldLengths';
import { actions } from '@store/actions';
import { ThunkGetTeamsConnections } from '@store/slices/teamsConnections/thunks';
import { validateRequired } from '@common/functions/validators';

import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import DropDown from '@ui/components/Auth/Common/DropDown';
import FormSwitch from '@ui/components/Auth/Common/FormSwitch';

import useStyles from '@components/Settings/NotificationGroups/styles';

const initialValue = { name: '' };

const NotificationGroupForm = ({ onClose, init = initialValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [initialName, setInitialName] = useState(init?.name);

  useEffect(() => {
    if (init) {
      setInitialName(init.name);
    }
  }, [init]);

  const onMount = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(ThunkGetTeamsConnections()).unwrap();
    setConnections(
      res.map(item => ({
        label: item.name,
        value: item.id,
      })),
    );
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const create = value => {
    setLoading(true);
    dataProvider
      .create('NotificationGroups', { data: value })
      .then(d => dispatch(actions.updateItemInList(d.data)))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const update = value => {
    setLoading(true);
    dataProvider
      .update('NotificationGroups', { id: value.id, data: value })
      .then(() => {
        setInitialName(value.name);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = value => {
    if (value.id) {
      update(value);
    } else {
      create(value);
    }
  };

  const validate = async value => {
    let asyncError;
    if (value.name) {
      if (!init || initialName !== value.name) {
        const isNameExists = await isGroupExists(value.name);
        asyncError = isNameExists ? 'Name already taken' : undefined;
      }
      asyncError =
        value.name.length > emailGroupNameLength
          ? `${emailGroupNameLength} symbols length limit`
          : asyncError;
    }
    return {
      name: validateRequired(value.name) || asyncError,
    };
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={init}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div>
            <Field
              id="name"
              name="name"
              fullWidth
              component={Input}
              placeholder="Name"
              disabled={loading}
            />
          </div>

          <FieldWrapper
            label="Teams Connection"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.alignLeft}
            fullWidth
            content={
              <Field
                name="teamsConnectionId"
                id="teamsConnectionId"
                render={DropDown}
                disabled={loading}
                options={connections}
              />
            }
          />
          <div className={classes.checkboxWrapper}>
            <Field
              name="useTeams"
              id="useTeams"
              type="checkbox"
              render={FormSwitch}
              label="Use Teams Connection"
              disabled={loading}
              labelPlacement="start"
            />
          </div>

          <div className={classes.buttonGroup}>
            <ReusableButton
              label="Cancel"
              onClick={onClose}
              disabled={loading}
            />
            <ReusableButton
              type="submit"
              viewType="black"
              label="Confirm"
              loading={loading}
              disabled={loading}
            />
          </div>
        </form>
      )}
    />
  );
};

NotificationGroupForm.propTypes = {
  init: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    teamsConnectionId: PropTypes.oneOf([null, PropTypes.number]),
    useTeams: PropTypes.bool,
  }),
  onClose: PropTypes.func.isRequired,
};

export default NotificationGroupForm;
