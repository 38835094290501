import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    background: 'linear-gradient(117.3deg, #CFCFCF 0.68%, #545B6B 95.06%)',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  container: {
    width: '500px',
    background: 'white',
    height: '500px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
}));

export default useStyles;
