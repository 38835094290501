import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bulkActionsLabel: {
    color: '#fff',
    marginTop: '5px',
  },
  header: {
    textAlign: 'center',
    margin: '5px 0 5px 0',
    fontStyle: 'normal',
  },
  tableHeaderInput: {
    '& input': {
      padding: '5px 31px',
      borderRadius: '0.3125rem',
      border: '0.0625rem solid #ebc7c0',
      background: 'white',
    },

    '& div': {
      border: 'none',
    },

    '& .MuiFilledInput-underline:before': {
      display: 'none',
    },
  },
  buttonWrapper: {
    margin: '0.25rem 1rem',
  },
  container: {
    marginLeft: '23px',
  },
  circle: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
  containerLetter: {
    textAlign: 'left',
  },
  icon: {
    margin: '0 5px',
  },
  tableWrapper: {
    marginTop: '50px',
  },
}));

export default useStyles;
