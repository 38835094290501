import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { deleteUser } from '@components/Ticketing/Integrations/helpers';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import iconDelete from '@assets/icons/delete.svg';

const DeleteActionField = ({ record = {} }) => {
  const { enqueueSnackbar } = useSnackbar();

  const list = useSelector(listSelector);

  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);

  const handleDeleteAlert = () => {
    setDeleteDialogOpen(false);
    deleteUser(record.id)
      .then(() => {
        const newList = list.filter(item => item.id !== record.id);
        dispatch(actions.saveList(newList));
        enqueueSnackbar('Successfully removed', { variant: 'success' });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  return (
    <>
      <ActionButton
        icon={<img src={iconDelete} alt="iconDelete" />}
        handler={handleOpenDeleteDialog}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDeleteAlert}
        onCancel={() => setDeleteDialogOpen(false)}
        title={`Deleting ${record.name} from ${record.tenant}`}
        content="Are you sure you want to delete this item? This action can not be undone."
      />
    </>
  );
};

DeleteActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

export default DeleteActionField;
