import React from 'react';
import PropTypes from 'prop-types';
import letterStatuses from '@constants/letterStatuses';

import useStyles from './styles';

const StatusBadge = ({ status = '' }) => {
  const info = letterStatuses[status.charAt(0).toLowerCase() + status.slice(1)];
  const statusName = {
    name: info?.name || status,
  };
  const classes = useStyles(statusName);
  return (
    <div className={classes.container}>
      <span className={classes.wrapper}>{info?.name}</span>
    </div>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string,
};

export default StatusBadge;
