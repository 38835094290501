import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  chipFieldWrapper: {
    '& input': {
      paddingRight: '1.5rem',
    },
  },
  iconDown: {
    cursor: 'pointer',
    height: '37px',
    position: 'absolute',
    color: '#333333',
    right: '2px',
  },
  dataSelectorWrapper: {
    width: '100%',
    position: 'relative',
  },
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: '10px 0',
  },
  smallPadding: {
    padding: '5px',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: '0.5rem',
    marginLeft: 0,
    '& button:last-child': {
      marginLeft: '0.1rem',
    },
    '& button:first-child': {
      marginRight: '0.1rem',
    },
  },
  paperRoot: {
    padding: '1rem',
    marginTop: '0.5rem',
  },
  paperContainer: {
    width: '50rem',
    padding: '1rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  emailComponentMinHeight: {
    minHeight: '82px',
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  radioLabel: {
    marginRight: '1rem',
  },
  dropDown: {
    width: '13rem',
    marginRight: '0.5rem',
  },
  submitButton: {
    margin: '0 0.5rem',
  },
}));

export default useStyles;
