import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      margin: '0px 10px',
    },
  },
}));

export default useStyles;
