import { createAsyncThunk } from '@reduxjs/toolkit';
import { PASSWORDS } from '@store/slices/resources';
import { deletePassword } from '@services/requests/passwords';

export const ThunkDeletePassword = createAsyncThunk(
  `${PASSWORDS}/deletePassword`,
  async id => {
    const res = await deletePassword(id);
    return res;
  },
);

export default {};
