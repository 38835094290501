import React, { useMemo } from 'react';

import { SMS_BLACK_LIST } from '@services/requests/requestResources';
import DateTimeField from '@common/TableComponents/DateTimeField';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import SmsBlackListTableItemActions from './components/SmsBlackListTableItemActions';
import SmsBlackListTableActions from './components/SmsBlackListTableActions';
import BulkActionButtons from './components/BulkActionButtons';

import useStyles from './styles';

const SmsBlackList = () => {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        name: 'Phone',
        key: 'blockedPhoneNumber',
        type: TableTypes.phoneNumber,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Date',
        key: 'dateOfBlocking',
        sortable: true,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="dateOfBlocking"
            source="dateOfBlocking"
            label="Date"
            sortable
          />
        ),
      },
      {
        name: 'Tenant',
        key: 'tenantName',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        accessor: record => <SmsBlackListTableItemActions record={record} />,
      },
    ],
    [],
  );

  return (
    <div className={classes.container}>
      <SmsBlackListTableActions />
      <Table
        bulkActions={<BulkActionButtons />}
        bulkActionsClassName={classes.bulkActions}
        defaultSort={{ fieldName: 'dateOfBlocking', order: 'DESC' }}
        columns={columns}
        resource={SMS_BLACK_LIST}
      />
    </div>
  );
};

export default SmsBlackList;
