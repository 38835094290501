import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import InfoBox from '@common/InfoBox/InfoBox';
import MessageIgnoreExpression from './Components/MessageIgnoreExpression';
import DangerZone from './Components/DangerZone';

import useStyles from './styles';

const Settings = ({ mailBoxId }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <span className={classes.heading}>Ignore messages</span>
        <Box className={classes.secondaryHeadingWrapper}>
          <span className={classes.secondaryHeading}>
            Use this setting to ignore messages for current mailbox
          </span>
          <InfoBox text="Activate this setting to provide ignoring messages based on logical rules" />
        </Box>
        <MessageIgnoreExpression mailBoxId={mailBoxId} />
      </Grid>
      <Grid item xs={12}>
        <DangerZone mailBoxId={mailBoxId} />
      </Grid>
    </Grid>
  );
};

Settings.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Settings;
