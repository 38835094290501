import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

function useThunk(thunk) {
  const [isPending, setIsPending] = useState(false);

  const dispatch = useDispatch();

  const fetch = useCallback(
    (arg = null) => {
      const promise = dispatch(thunk(arg));

      setIsPending(true);

      promise.then(res => {
        setIsPending(false);

        return res;
      });

      return promise;
    },
    [dispatch, thunk],
  );

  return [fetch, isPending];
}

export default useThunk;
