import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bagelContainer: {
    [theme.breakpoints.up('sm')]: {
      margin: '0.5rem 2rem',
    },
  },
  bagelGridContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  bagelGrid: {
    backgroundColor: '#F7F9FB',
  },
  filterClearButton: {
    position: 'absolute',
    top: '70px',
    right: '12px',
  },
}));

export default useStyles;
