import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { ThemeProvider, createTheme } from '@material-ui/core';

import {
  ThunkCheckCode,
  ThunkGetPassword,
} from '@store/slices/passwords/thunks';
import useQuery from '@utils/useQuery';
import { lightTheme } from '@services/themes/mainTheme';
import { requiredValidator } from '@utils/validators';

import Loading from '@ui/components/common/Loading/Loading';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useStyles from './styles';

const theme = createTheme(lightTheme());

const OneTimePassword = () => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const handleCopyClick = value => {
    navigator.clipboard.writeText(value);
  };

  const validate = values => {
    return {
      secretPhrase: values.secretPhrase
        ? requiredValidator(values.secretPhrase)
        : 'This field can`t be empty',
    };
  };

  const submit = async ({ secretPhrase }) => {
    setProcessing(true);
    await dispatch(ThunkGetPassword({ code: query.get('code'), secretPhrase }))
      .unwrap()
      .then(res => {
        setPassword(res.data);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(
      ThunkCheckCode({ code: query.get('code') }),
    ).unwrap();
    if (!res.isSuccess) {
      setError(res.message);
    }
    setLoading(false);
  }, [dispatch, query]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          {error ? (
            <>{error}</>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <FieldWrapper
                      label="Secret Phrase"
                      labelSize={12}
                      contentSize={12}
                      showLabel
                      classNameLabelInner={classes.alignLeft}
                      content={
                        <Field
                          name="secretPhrase"
                          id="secretPhrase"
                          render={Input}
                          disabled={processing}
                        />
                      }
                    />

                    <div style={{ margin: '20px 0px' }}>
                      <ReusableButton
                        type="submit"
                        viewType="black"
                        label="Submit"
                        loading={processing}
                        disabled={processing}
                      />
                    </div>
                  </form>
                )}
              />
              {password && (
                <div>
                  <div>
                    Password:
                    {password}
                  </div>
                  {password && (
                    <ReusableButton
                      label="Copy Password"
                      onClick={() => {
                        handleCopyClick(password);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default OneTimePassword;
