import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import {
  ThunkGetOptInSettingsForForm,
  ThunkSaveOptInForm,
  ThunkValidateOptInKey,
} from '@store/slices/optIn/thunks';
import { ThunkGetTicketContact } from '@store/slices/connectWiseTicket/thunks';
import { OptInSettingsInit } from '@store/slices/optInSettings';

import {
  phoneWithCharactersValidator,
  requiredValidator,
} from '@utils/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import Checkbox from '@common/Checkbox';
import DropDown from '@components/Auth/Common/DropDown';

import { lightTheme } from '@services/themes/mainTheme';

import crmSources from '@constants/crmSources';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { getContactMethodsOptions } from '@services/requests/optIn';

import { validateRequired } from '@common/functions/validators';

import phoneValidator from '@utils/validators/phoneValidator';
import PhoneField from '@common/PhoneField';
import { joinCodeWithPhone } from '@ui/pages/optIn/OptInForm/helpers';
import { useCountryCodesOptions } from '../../../../hooks/useCountryCodesOptions';
import { usePhoneInitialData } from '../../../../hooks/usePhoneInitialData';

import useStyles from './styles';

const theme = createTheme(lightTheme);

const OptInForm = () => {
  const { countryCodesOptions, countries } = useCountryCodesOptions();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [isSuccessfully, setIsSuccessfully] = useState(false);
  const [contactData, setContactData] = useState();
  const [optInCrmType, setOptInCrmType] = useState();
  const [defaultTenantCodes, setDefaultTenantCodes] = useState({
    tenantCountryCode: '',
    tenantDialCode: '',
  });
  const [options, setOptions] = useState([]);
  const [errorText, setErrorText] = useState(null);

  const isHaloCrmType = optInCrmType === crmSources.Halo.name;

  const {
    initialIcon,
    countryCodeFormValue,
    nationalNumber,
  } = usePhoneInitialData({
    phone: isHaloCrmType
      ? contactData?.workMobileNumber || ''
      : contactData?.mobilePhone || contactData?.contactPhone || '',
    countries,
    defaultTenantCodes,
  });

  const {
    initialIcon: homeNumberInitialIcon,
    countryCodeFormValue: homeNumberCountryCode,
    nationalNumber: homeMobileNumber,
  } = usePhoneInitialData({
    phone: contactData?.homeMobileNumber || '',
    countries,
    defaultTenantCodes,
  });
  const { enqueueSnackbar } = useSnackbar();

  const optInSettings = useSelector(OptInSettingsInit);
  const styles = useMemo(() => {
    if (optInSettings.styles) {
      return JSON.parse(optInSettings.styles);
    }
    return undefined;
  }, [optInSettings]);

  const classes = useStyles({ bgColor: styles && styles.bgColor });
  const sharedClasses = useSharedStyles();

  const dispatch = useDispatch();
  const { search } = useLocation();

  const key = useMemo(() => search.split('X-Api-Key=')[1], [search]);

  const submit = useCallback(
    async ({ contactPhoneNumber, ...rest }) => {
      setProcessing(true);
      const { contactMethod, ...payload } = {
        contactPhoneNumber: joinCodeWithPhone(
          rest.countryCode,
          contactPhoneNumber || '',
        ),
        ...rest,
      };
      if (isHaloCrmType) {
        delete payload.contactPhoneNumber;
        payload.workMobileNumber = joinCodeWithPhone(
          rest.countryCode,
          rest.workMobileNumber || '',
        );
        payload.homeMobileNumber = joinCodeWithPhone(
          rest.homeNumberCountryCode,
          rest.homeMobileNumber || '',
        );

        delete payload.homeNumberCountryCode;
      }

      delete payload.countryCode;

      if (contactMethod) {
        payload.preferredContactMethod = contactMethod;
      }

      await dispatch(
        ThunkSaveOptInForm({
          key,
          payload,
        }),
      )
        .unwrap()
        .then(() => {
          setIsSuccessfully(true);
        })
        .catch(e => {
          enqueueSnackbar(e.message, { variant: 'error' });
          setProcessing(false);
        });
      setProcessing(false);
    },
    [dispatch, key, isHaloCrmType, enqueueSnackbar],
  );

  const validate = useCallback(
    values => {
      const validateSchema = {
        contactName: requiredValidator(values.contactName),
        contactPhoneNumber:
          phoneValidator(values.contactPhoneNumber, values.countryCode) ||
          phoneWithCharactersValidator(values.contactPhoneNumber, 1),
        countryCode: validateRequired(values.countryCode),
      };

      if (isHaloCrmType) {
        validateSchema.contactPhoneNumber = undefined;
        validateSchema.homeNumberCountryCode = validateRequired(
          values.homeNumberCountryCode,
        );
        validateSchema.workMobileNumber =
          phoneValidator(values.workMobileNumber, values.countryCode, false) ||
          phoneWithCharactersValidator(values.workMobileNumber, 1) ||
          (values.workMobileNumber || values.homeMobileNumber
            ? undefined
            : ' ');
        validateSchema.homeMobileNumber =
          phoneValidator(
            values.homeMobileNumber,
            values.homeNumberCountryCode,
            false,
          ) ||
          phoneWithCharactersValidator(values.homeMobileNumber, 1) ||
          (values.workMobileNumber || values.homeMobileNumber
            ? undefined
            : 'Please provide at least one contact phone number.');
        validateSchema.countryCode = values.workMobileNumber
          ? validateRequired(values.countryCode)
          : undefined;
        validateSchema.homeNumberCountryCode = values.homeMobileNumber
          ? validateRequired(values.homeNumberCountryCode)
          : undefined;
      }

      if (options.length > 1) {
        validateSchema.contactMethod = requiredValidator(values.contactMethod);
      }

      return validateSchema;
    },
    [isHaloCrmType, options.length],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkValidateOptInKey({ key }))
      .unwrap()
      .then(
        async ({
          crmId,
          contactId,
          tenantId,
          crmType,
          tenantCountryCode,
          tenantDialCode,
        }) => {
          setOptInCrmType(crmType);
          setDefaultTenantCodes({ tenantCountryCode, tenantDialCode });
          await dispatch(ThunkGetTicketContact({ crmId, contactId, tenantId }))
            .unwrap()
            .then(data => {
              setContactData(data);
            });

          await dispatch(ThunkGetOptInSettingsForForm(tenantId));

          const contactMethodsOptions = await getContactMethodsOptions(
            tenantId,
            crmId,
          );

          if (contactMethodsOptions.length > 1) {
            setOptions(
              contactMethodsOptions.sort((a, b) =>
                a.label.localeCompare(b.label),
              ),
            );
          }
        },
      )
      .catch(e => {
        setErrorText(e.message?.replace('RequestError:', ''));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [key, dispatch]);

  useEffect(() => {
    onMount();
  }, [dispatch, key, onMount]);

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Form
          onSubmit={submit}
          initialValues={{
            allowMessaging: true,
            contactName: contactData?.contactName,
            contactPhoneNumber: isHaloCrmType ? undefined : nationalNumber,
            countryCode: countryCodeFormValue,
            homeNumberCountryCode: isHaloCrmType
              ? homeNumberCountryCode
              : undefined,
            workMobileNumber: isHaloCrmType ? nationalNumber : undefined,
            homeMobileNumber: isHaloCrmType ? homeMobileNumber : undefined,
            updatePsaContact: true,
            contactMethod: isHaloCrmType
              ? contactData?.preferedContactMethod
              : undefined,
          }}
          validate={validate}
          render={({ handleSubmit, values, errors, touched }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              {optInSettings.logo && (
                <div className={classes.logo}>
                  <img src={optInSettings.logo} alt="Logo" />
                </div>
              )}
              {errorText && <div>{errorText}</div>}

              {!isSuccessfully && !errorText && (
                <>
                  <FieldWrapper
                    label="User Name"
                    labelSize={12}
                    contentSize={12}
                    fullWidth
                    classNameLabelInner={sharedClasses.alignLeft}
                    content={
                      <Field
                        name="contactName"
                        id="contactName"
                        render={Input}
                        disabled
                      />
                    }
                  />
                  {optInCrmType === crmSources.Halo.name ? (
                    <>
                      <PhoneField
                        label="Work Mobile Number"
                        phoneName="workMobileNumber"
                        phoneCodeName="countryCode"
                        countryCodesOptions={countryCodesOptions}
                        initialIcon={initialIcon}
                      />
                      <PhoneField
                        label="Home Mobile Number"
                        phoneName="homeMobileNumber"
                        phoneCodeName="homeNumberCountryCode"
                        countryCodesOptions={countryCodesOptions}
                        initialIcon={homeNumberInitialIcon}
                      />
                    </>
                  ) : (
                    <PhoneField
                      phoneName="contactPhoneNumber"
                      phoneCodeName="countryCode"
                      countryCodesOptions={countryCodesOptions}
                      initialIcon={initialIcon}
                      isRequired
                    />
                  )}
                  {options.length > 1 && (
                    <FieldWrapper
                      label="Preferred contact method"
                      labelSize={12}
                      contentSize={12}
                      fullWidth
                      classNameLabelInner={sharedClasses.alignLeft}
                      isRequired={options.length > 1}
                      content={
                        <>
                          <Field
                            name="contactMethod"
                            id="contactMethod"
                            render={DropDown}
                            options={options}
                            disabled={processing}
                            classNameWrapper={classes.inputContainer}
                          />
                          {errors.contactMethod && touched.contactMethod && (
                            <div className={classes.required}>Required</div>
                          )}
                        </>
                      }
                    />
                  )}
                  <FieldWrapper
                    label="I agree to receive SMS"
                    labelSize={12}
                    contentSize={4}
                    classNameContainer={classes.checkboxFormContainer}
                    classNameLabelInner={sharedClasses.alignLeft}
                    classNameContentContainer={classes.checkboxFormContent}
                    content={
                      <Field
                        name="allowMessaging"
                        id="allowMessaging"
                        component={Checkbox}
                        type="checkbox"
                        checked={values.allowMessaging}
                        disabled={processing}
                        classNameWrapper={classes.greenCheckbox}
                      />
                    }
                  />
                  <FieldWrapper
                    label="I want to update my Phone Number for SMS"
                    labelSize={12}
                    contentSize={4}
                    classNameContainer={classes.checkboxFormContainer}
                    classNameLabelInner={sharedClasses.alignLeft}
                    classNameContentContainer={classes.checkboxFormContent}
                    content={
                      <Field
                        name="updatePsaContact"
                        id="updatePsaContact"
                        component={Checkbox}
                        type="checkbox"
                        checked={values.updatePsaContact}
                        disabled={processing}
                        classNameWrapper={classes.greenCheckbox}
                      />
                    }
                  />
                  <div className={classes.caption}>
                    By subscribing via text, you agree to receive technical
                    support related messages including scheduling updates at the
                    phone number provided. Reply #STOP to cancel at any time.
                    Msg rates may apply.
                  </div>
                  <div className={sharedClasses.dialogActionsContainer}>
                    <ReusableButton label="Submit" type="submit" />
                  </div>
                </>
              )}

              {isSuccessfully && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    height: '200px',
                  }}
                >
                  Opted-in Successfully
                </div>
              )}
            </form>
          )}
        />
      </div>
    </ThemeProvider>
  );
};

OptInForm.propTypes = {};

export default OptInForm;
