import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './styles';

const ActionButtonLight = ({ toolTip, icon, handler }) => {
  const classes = useStyles();
  return (
    <Tooltip title={toolTip}>
      <div
        role="presentation"
        className={classes.actionButton}
        onClick={handler}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

ActionButtonLight.propTypes = {
  toolTip: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  handler: PropTypes.func.isRequired,
};

export default ActionButtonLight;
