import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkDeletePassword } from '@store/slices/passwords/thunks';
import { actions } from '@store/actions';

import CopyIcon from '@assets/icons/copy_blue.svg';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ReusableButton from '@common/Button/Button';
import DateTimeField from '@common/TableComponents/DateTimeField';
import ActionField from '@common/ActionFields/ActionField';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import copyText from '@utils/copy';
import { useSnackbar } from 'notistack';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import CreateDialog from './CreateDialog';

import useStyles from './styles';

const resource = 'passwords';

const Passwords = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = useCallback(() => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  }, []);

  const handleDelete = useCallback(
    id => {
      setLoading(true);
      dispatch(ThunkDeletePassword(id))
        .unwrap()
        .then(() => dispatch(actions.removeItemFromList({ id })))
        .finally(handleCancelDelete);
    },
    [dispatch, handleCancelDelete],
  );

  const handleCopyClick = useCallback(
    link => {
      copyText(link).then(() => {
        enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
      });
    },
    [enqueueSnackbar],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Link',
        key: 'link',
        isCustomAccessor: true,
        accessor: rowData => (
          <div className={classes.copyLinkContainer}>
            <a href={rowData.link} target="_blank" rel="noreferrer">
              Click to Open Link
            </a>
            <div>
              <ActionFieldItem
                icon={CopyIcon}
                alt="Copy"
                handler={() => {
                  handleCopyClick(rowData.link);
                }}
                toolTip="Copy Link"
              />
            </div>
          </div>
        ),
      },
      {
        name: 'Expiration Date',
        key: 'expirationDateUtc',
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="expirationDateUtc"
            source="expirationDateUtc"
            highlightExpired
          />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField record={rowData} onDelete={onDelete} fieldKey="key" />
        ),
        width: 100,
      },
    ],
    [classes.copyLinkContainer, handleCopyClick],
  );

  return (
    <div className={classes.container}>
      <ReusableButton
        size="md"
        viewType="black"
        label="Add"
        onClick={() => setCreateDialogOpen(true)}
        classNameWrapper={classes.addButton}
        disabled={loading}
      />
      <Table
        columns={columns}
        defaultSort={{ fieldName: 'name', order: 'ASC' }}
        resource={resource}
      />

      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          onAccept={() => handleDelete(idForDelete)}
          onCancel={handleCancelDelete}
          title="Delete one time password record?"
          content="This action cannot be undone."
          loading={loading}
        />
      )}

      {createDialogOpen && (
        <CreateDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default Passwords;
