import React from 'react';
import PropTypes from 'prop-types';
import ItemGroup from '@components/DataSources/AlertForm/AlertList/components/ItemGroup';
import AlertItem from '@components/DataSources/AlertForm/AlertList/components/AlertItem';
import { groupedAlerst } from '@components/DataSources/AlertForm/AlertList/alertsDescription';
import useStyles from '../formStyles';

const AlertsList = ({ hooks, alerts, setAlerts }) => {
  const classes = useStyles();
  const onItemUpdate = item => {
    const newData = { ...alerts };
    newData[item.type] = item;
    setAlerts(newData);
  };

  return (
    <div className={classes.alertsLayout}>
      {Object.keys(groupedAlerst).map(grp => (
        <>
          <ItemGroup name={grp} key={grp}>
            {groupedAlerst[grp].map(i => (
              <AlertItem
                item={i}
                key={i.name}
                hooks={hooks}
                data={alerts[i.type]}
                onUpdate={onItemUpdate}
                info={i.info}
                filter={i.filter}
              />
            ))}
          </ItemGroup>
          <hr className={classes.hrGradient} />
        </>
      ))}
    </div>
  );
};

AlertsList.propTypes = {
  hooks: PropTypes.arrayOf(PropTypes.any),
  alerts: PropTypes.objectOf(PropTypes.any),
  setAlerts: PropTypes.func,
};

export default AlertsList;
