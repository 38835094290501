import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '@common/form/FieldWrapper';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import { Field } from 'react-final-form';
import Input from '@components/Auth/Common/Input';
import NumberInput from '@components/Auth/Common/NumberInput';
import Checkbox from '@common/Checkbox/Checkbox';
import {
  accordionStyle,
  contentSize,
  emailMaxLength,
  labelSize,
  smsMaxLength,
} from '@components/Settings/VerificationSettings/components/constants';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import {
  ThunkGetTechResponseSettingsVariables,
  ThunkGetVerificationAutoResponseSettingsVariables,
} from '@store/slices/verificationSettings/thunks';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import InfoBox from '@common/InfoBox/InfoBox';
import iconRefresh from '@assets/icons/refreshItem.svg';

import ActionButtonLight from '@ui/components/common/buttons/ActionButton/ActionButtonLight';
import useStyles from '../styles';

const ticketNotesTemplateVariables = [
  { value: '${from}', label: 'From' },
  { value: '${ticketId}', label: 'TicketId' },
  { value: '${techName}', label: 'Tech Account Name' },
  { value: '${contactName}', label: 'Contact Name' },
  { value: '${code}', label: 'Code' },
];

const PsaTechResponseSettings = ({ processing, form, values, onReset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [responseAnchorEl, setResponseAnchorEl] = useState(null);
  const [autoResponseAnchorEl, setAutoResponseAnchorEl] = useState(null);
  const [templateVariables, setTemplateVariables] = useState([]);
  const [
    verificationPhoneNumberResponseTemplateVariables,
    setVerificationPhoneNumberResponseTemplateVariables,
  ] = useState([]);
  const [codeResponseAnchorEl, setCodeResponseAnchorEl] = useState(null);
  const [ticketNoteAnchorEl, setTicketNoteAnchorEl] = useState(null);

  const onMount = useCallback(async () => {
    dispatch(ThunkGetTechResponseSettingsVariables())
      .unwrap()
      .then(d => setTemplateVariables(d));

    dispatch(ThunkGetVerificationAutoResponseSettingsVariables())
      .unwrap()
      .then(d => setVerificationPhoneNumberResponseTemplateVariables(d));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Tech Verification Settings
          <InfoBox text="Allows to configure message at Tech Verification Requests page " />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <ActionButtonLight
            handler={onReset}
            toolTip="Reset value"
            icon={<img src={iconRefresh} alt="refresh" />}
          />

          <FieldWrapper
            label="Verification Code Message"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event =>
                    setCodeResponseAnchorEl(event.currentTarget)
                  }
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="techVerificationCodeConfirmMessage"
                  id="techVerificationCodeConfirmMessage"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: smsMaxLength }}
                  helperText={`${
                    values.techVerificationCodeConfirmMessage
                      ? values.techVerificationCodeConfirmMessage.length
                      : 0
                  }/${smsMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />

          <FieldWrapper
            label="Confirm Response template"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setResponseAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="techResponseTemplate"
                  id="techResponseTemplate"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: smsMaxLength }}
                  helperText={`${
                    values.techResponseTemplate
                      ? values.techResponseTemplate.length
                      : 0
                  }/${smsMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />

          <FieldWrapper
            label="Auto responder when SMS for ticket is sent to Verification Number"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event =>
                    setAutoResponseAnchorEl(event.currentTarget)
                  }
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="verificationPhoneNumberResponseOnWrongMessage"
                  id="verificationPhoneNumberResponseOnWrongMessage"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: smsMaxLength }}
                  helperText={`${
                    values.verificationPhoneNumberResponseOnWrongMessage
                      ? values.verificationPhoneNumberResponseOnWrongMessage
                          .length
                      : 0
                  }/${smsMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />

          <FieldWrapper
            label="Display tickets, updated in last N days"
            labelSize={labelSize}
            contentSize={contentSize}
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            fullWidth
            isRequired
            content={
              <div className={classes.dialogTemplate}>
                <Field
                  name="numberOfDaysTicketsHaveForPsaTechVerification"
                  id="numberOfDaysTicketsHaveForPsaTechVerification"
                  render={NumberInput}
                  disabled={processing}
                  min={0}
                  max={100}
                />
                <span>[days]</span>
              </div>
            }
          />

          <FieldWrapper
            label="Note the ticket about confirmation"
            labelSize={labelSize}
            contentSize={contentSize}
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            fullWidth
            content={
              <Field
                name="noteTicketAboutConfirmation"
                id="noteTicketAboutConfirmation"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Use 6-digit code for verification"
            labelSize={labelSize}
            contentSize={contentSize}
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            fullWidth
            infoText="SMS with code will send back to a contact"
            content={
              <Field
                name="useCodeForTechVerification"
                id="useCodeForTechVerification"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Ticket Confirmation Log"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setTicketNoteAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="techVerificationTicketNoteMessage"
                  id="techVerificationTicketNoteMessage"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: emailMaxLength }}
                  helperText={`${
                    values.techVerificationTicketNoteMessage
                      ? values.techVerificationTicketNoteMessage.length
                      : 0
                  }/${emailMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />
        </AccordionDetails>
      </Accordion>
      <TemplateMenu
        anchorEl={responseAnchorEl}
        setAnchorEl={setResponseAnchorEl}
        form={form}
        name="techResponseTemplate"
        templateVariables={templateVariables}
      />
      <TemplateMenu
        anchorEl={autoResponseAnchorEl}
        setAnchorEl={setAutoResponseAnchorEl}
        form={form}
        name="verificationPhoneNumberResponseOnWrongMessage"
        templateVariables={verificationPhoneNumberResponseTemplateVariables}
      />
      <TemplateMenu
        anchorEl={codeResponseAnchorEl}
        setAnchorEl={setCodeResponseAnchorEl}
        form={form}
        name="techVerificationCodeConfirmMessage"
        templateVariables={templateVariables.filter(p =>
          p.label.toLowerCase().includes('code'),
        )}
      />
      <TemplateMenu
        anchorEl={ticketNoteAnchorEl}
        setAnchorEl={setTicketNoteAnchorEl}
        form={form}
        name="techVerificationTicketNoteMessage"
        templateVariables={ticketNotesTemplateVariables}
      />
    </>
  );
};

PsaTechResponseSettings.propTypes = {
  processing: PropTypes.bool,
  form: PropTypes.objectOf(PropTypes.any),
  values: PropTypes.objectOf(PropTypes.any),
  onReset: PropTypes.func,
};

export default PsaTechResponseSettings;
