import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toggleContainer: {},
  title: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  content: {
    fontSize: '0.8rem',
  },
  color: props => (props.selected ? 'white' : '#393B3E'),
  tile: {
    display: 'flex',
    padding: '30px 30px',
    flexDirection: 'column',
    background: props => (props.selected ? '#2F498A' : '#EEEEEE'),
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    color: props => (props.selected ? 'white' : 'black'),
  },
  planName: {
    fontWeight: 'bolder',
    fontSize: '20px',
    margin: '5px 0px',
  },
  pricing: {
    fontSize: '15px',
    margin: '5px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  scopes: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    margin: '10px 0px',
  },
  scope: {
    display: 'grid',
    gridTemplateColumns: '10px 1fr',
    gap: '10px',
    alignItems: 'center',
  },
  dot: {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    background: props => (props.selected ? 'white' : '#2F498A'),
  },
  viewAll: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 'small',
  },
}));

export default useStyles;
