import { makeRequest } from '@services/requests/makeRequest';

export const exportVerificationHistory = type =>
  makeRequest(
    'GET',
    `/VerificationReports/export?type=${type}`,
    undefined,
    undefined,
    false,
  );

export default {};
