import { actionTypes } from '../actions';

const defaultState = {
  crmType: '',
  crmId: 0,
};

const crmIntegrationReducer = (state = defaultState, action) => {
  if (action.type === actionTypes.SET_CRM_TYPE) {
    return {
      ...state,
      crmType: action.payload.crmType,
      crmId: action.payload.crmId,
    };
  }
  return state;
};

export default crmIntegrationReducer;
