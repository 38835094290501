import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    margin: '1rem 1rem 0 1rem',
  },
  largeFont: {
    fontSize: 'x-large',
  },
  wrapper: {
    margin: '1rem 0',
    padding: '1rem',
  },
  wrapperLabel: {
    fontSize: 'larger',
    marginBottom: '1rem',
  },
  item: {
    margin: '0.5rem 1rem 0.5rem 2rem',
    display: 'flex',
  },
  sourceItem: {
    margin: '0.3rem 1rem 0.3rem 0.5rem',
  },
  itemValue: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  iconButton: {
    padding: '0.3125rem',
    transition: '0.3s',
    width: '13px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
}));

export default useStyles;
