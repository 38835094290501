import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { editClientPortalConfigurations } from '@services/requests/clientPortal';

export const ThunkEditClientPortalConfigurations = createAsyncThunk(
  `${CLIENT_PORTALS}/editClientPortalConfigurations`,
  payload => editClientPortalConfigurations(payload),
);

export default {};
