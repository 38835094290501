import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
  },
  gridWrapper: {
    display: 'grid',
    gridTemplateColumns: props => props.gridTemplateColumns,
  },
}));

const ItemGroup = ({ children, name, gridTemplateColumns = '1fr 1fr 1fr' }) => {
  const classes = useStyles({ gridTemplateColumns });
  return (
    <>
      <span className={classes.title}>{name}</span>
      <div className={classes.gridWrapper}>{children}</div>
    </>
  );
};

ItemGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.objectOf(PropTypes.any),
  ]),
  name: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
};

export default ItemGroup;
