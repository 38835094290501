import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneMessagingTemplate } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkCloneMessagingTemplate = createAsyncThunk(
  `${MESSAGING}/cloneMessagingTemplate`,
  async ({ templateId }) => {
    const clone = await cloneMessagingTemplate(templateId);
    return clone;
  },
);

export default {};
