import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';

import { Link } from '@material-ui/core';

import { ThunkGetOutgoingMailbox } from '@store/slices/outgoingMail/thunks/getOutGoingMailbox';

import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import Loading from '@common/Loading/Loading';

import AutocompleteWithPagination from '@common/AutocompleteWithPagination/AutocompleteWithPagination';
import { ThunkGetOutgoingMailboxUsers } from '@store/slices/outgoingMail/thunks/getOutGoingMailboxUsers';
import { outgoingMailSelector } from '@store/slices/outgoingMail';
import { validateRequired } from '@common/functions/validators';
import { ThunkUpdateOutgoingMailbox } from '@store/slices/outgoingMail/thunks/updateOutGoingMailbox';
import { ThunkSetupOutgoingMailbox } from '@store/slices/outgoingMail/thunks/setupOutGoingMailbox';
import { ThunkDeleteOutgoingMailbox } from '@store/slices/outgoingMail/thunks/deleteOutGoingMailbox';
import MicrosoftLogo from '@assets/logos/microsoft365.png';
import { TEST_EMAIL } from '@constants/routes';

import useStyles from './styles';

const OutgoingMail = () => {
  const location = useLocation();
  const { push } = useHistory();
  const hasError = location.search.includes('errorMessage');
  const {
    loading,
    outboundMailbox: { msTenantId, msEmail },
    users,
    totalOptionsCount,
    disabled,
  } = useSelector(outgoingMailSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const onMount = useCallback(() => {
    dispatch(ThunkGetOutgoingMailbox());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (msTenantId) {
      dispatch(ThunkGetOutgoingMailboxUsers());
    }
  }, [dispatch, msTenantId]);

  const submit = values => {
    if (msTenantId) {
      dispatch(ThunkUpdateOutgoingMailbox(values));
    } else {
      dispatch(
        ThunkSetupOutgoingMailbox({
          redirectUrl: window.location.href.split('?')[0],
        }),
      )
        .unwrap()
        .then(({ data }) => {
          window.location.href = data;
        });
    }
  };

  const validate = values => {
    return {
      msEmail: msTenantId ? validateRequired(values.msEmail) : undefined,
    };
  };

  const currentSteps = useMemo(() => {
    return {
      setup: !hasError && !msTenantId,
      setupEmail: msTenantId && !msEmail,
      error: hasError && !msTenantId,
      delete: msEmail && msTenantId,
    };
  }, [hasError, msEmail, msTenantId]);

  const buttonText = useMemo(() => {
    if (msTenantId) {
      return 'Submit';
    }
    return hasError ? 'Try Again' : 'Start Setup';
  }, [hasError, msTenantId]);

  if (loading)
    return <Loading customText="Please wait; just chatting with Bill Gates." />;

  return (
    <div className={classes.container}>
      <div className={classes.greyBox}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.microsoftForm}>
              <img
                src={MicrosoftLogo}
                alt="microsoft"
                className={classes.logo}
              />
              {currentSteps.error && (
                <div>
                  {decodeURIComponent(
                    location.search.replace('?errorMessage=', ''),
                  )}
                </div>
              )}
              {msEmail && (
                <div className={classes.email}>
                  <div>Microsoft shared email configured.</div>
                  <div>
                    <strong>Email: </strong>
                    {msEmail}
                  </div>
                </div>
              )}
              {currentSteps.setupEmail && (
                <FieldWrapper
                  label="Select which email to use"
                  labelSize={12}
                  contentSize={12}
                  content={
                    <Field
                      id="msEmail"
                      name="msEmail"
                      showLabel
                      component={AutocompleteWithPagination}
                      items={users}
                      size="small"
                      resource="/OutboundMailbox/setup/users"
                      totalOptionsCount={totalOptionsCount}
                    />
                  }
                />
              )}
              {!msEmail && (
                <div className={classes.buttonWrapper}>
                  <ReusableButton
                    size="md"
                    type="submit"
                    viewType="blue"
                    classNameWrapper={classes.buttonWrapper}
                    disabled={disabled}
                  >
                    {buttonText}
                  </ReusableButton>
                  {currentSteps.setup && (
                    <div className={classes.infoText}>
                      This feature allows to send email via your Microsoft 365
                      shared mailboxes.
                    </div>
                  )}
                </div>
              )}
              <div className={classes.linkWrapper}>
                <span className={classes.infoText}>
                  To learn more about this feature and how it works, click
                </span>
                <Link
                  target="_blank"
                  href="https://kb.mspprocess.com/details/134/11"
                  className={classes.link}
                >
                  here
                </Link>
              </div>
            </form>
          )}
        />
        {msEmail && msTenantId && (
          <div className={classes.secondButtonsWrapper}>
            <ReusableButton
              size="md"
              viewType="red"
              classNameWrapper={classes.secondButton}
              onClick={() => {
                dispatch(ThunkDeleteOutgoingMailbox());
              }}
              disabled={disabled}
            >
              Delete
            </ReusableButton>
            <ReusableButton
              size="md"
              viewType="blue"
              classNameWrapper={classes.secondButton}
              disabled={disabled}
              onClick={() => push(TEST_EMAIL)}
            >
              Test
            </ReusableButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutgoingMail;
