import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketScheduleStatuses } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketScheduleStatuses = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketScheduleStatuses`,
  async crmId => {
    const scheduleStatuses = await getConnectWiseTicketScheduleStatuses(crmId);
    return scheduleStatuses;
  },
);

export default {};
