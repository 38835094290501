import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: 'calc(100vh - 3rem)',
    overflowY: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0.25rem',
    },
    padding: '1.25rem',
    backgroundColor: theme.palette.tenantSettings.background,
    borderRadius: '0.8125rem',
    minWidth: '35vw',
  },
  formContentWrapper: {
    width: '70vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
    padding: '10px',
    columnGap: '0.5rem',
  },
}));

export default useStyles;
