import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkEditSmsCampaignGroupItem } from '@store/slices/smsCampaigns/thunks/editSmsCampaignGroupItem';
import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';

import { actions } from '@store/actions';
import maxLengthValidator from '@utils/validators/maxLengthValidator';
import phoneValidation from '@constants/phoneValidation';

import { ReactComponent as PhoneIcon } from '@assets/icons/phone_password.svg';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import ActionButton from '@common/buttons/ActionButton/ActionButton';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';

const EditItemDialog = ({ open, setOpen }) => {
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkEditSmsCampaignGroupItem({ id: open.id, payload: values }))
      .unwrap()
      .then(data => dispatch(actions.updateItemInList(data)))
      .finally(() => {
        setProcessing(false);
        setOpen(false);
      });
  };

  const beautifyPhone = useCallback(
    (phone, form) => {
      setProcessing(true);
      dispatch(ThunkBeautifyPhoneNumber(phone))
        .unwrap()
        .then(d => form.change('phoneNumber', d.data))
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  const validate = values => {
    return {
      fullName: maxLengthValidator(values.fullName, 250),
      phoneNumber: phoneValidation(values.phoneNumber),
    };
  };

  return (
    <Dialog open={open} onClose={() => setOpen(null)}>
      <DialogTitle>Edit User Info</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={open}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FieldWrapper
                label="Full Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="fullName"
                    id="fullName"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Phone number"
                labelSize={12}
                contentSize={12}
                fullWidth
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="phoneNumber"
                    id="phoneNumber"
                    render={Input}
                    disabled={processing}
                    endAdornment={
                      <ActionButton
                        icon={<PhoneIcon />}
                        handler={() => beautifyPhone(values.phoneNumber, form)}
                        toolTip="Fix Phone Number Format"
                      />
                    }
                  />
                }
              />

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => setOpen(false)}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditItemDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default EditItemDialog;
