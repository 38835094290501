import React, { useContext } from 'react';
import { TableBody as MUITableBody } from '@material-ui/core';

import PropTypes from 'prop-types';
import { TableContext } from '../context';

import TableHead from './components/TableHead';
import TableBodyContent from './components/TableBody';
import TableBulkActions from '../TableBulkActions/TableBulkActions';

import useStyles from '../styles';

const TableBody = ({ bulkActionsClassName }) => {
  const { selectedIds } = useContext(TableContext);
  const classes = useStyles();

  return (
    <MUITableBody className={classes.tableBody}>
      {!!selectedIds.length && (
        <TableBulkActions className={bulkActionsClassName} />
      )}
      <TableHead />
      <TableBodyContent />
    </MUITableBody>
  );
};

TableBody.propTypes = {
  bulkActionsClassName: PropTypes.string,
};

export default TableBody;
