import { deleteChatBot } from '@services/requests/chatBots';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CHATS_BOTS } from '../../resources';

export const ThunkDeleteChatBot = createAsyncThunk(
  `${CHATS_BOTS}/deleteChatBot`,
  async ({ chatBotId }) => {
    await deleteChatBot(chatBotId);
    return { id: chatBotId };
  },
);

export default {};
