import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import {
  ThunkGetTicketStatusesByChatId,
  ThunkReopenLiveChat,
} from '@store/slices/liveChats/thunks';
import { useDispatch } from 'react-redux';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field, Form } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const LiveChatReopenDialog = ({
  open,
  setOpen,
  liveChatId,
  ticketId,
  handleDataReload,
}) => {
  const [processing, setProcessing] = useState(false);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [ticketStatus, setTicketStatus] = useState(null);

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const submit = values => {
    setProcessing(true);
    const payload = {
      ...values,
      ticketId: ticketId ?? null,
      chatId: liveChatId,
    };

    dispatch(ThunkReopenLiveChat({ payload }))
      .then(() => {
        handleDataReload();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = () => {};

  const onMount = useCallback(async () => {
    if (ticketId) {
      setProcessing(true);
      await dispatch(ThunkGetTicketStatusesByChatId({ liveChatId }))
        .unwrap()
        .then(res => {
          setTicketStatuses(res.options);
          setTicketStatus(res.current);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [dispatch, liveChatId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Re-Open Live Chat</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={{ ticketStatus }}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              Are you sure you want to close this conversation?
              {ticketId && (
                <FieldWrapper
                  label="Ticket Status"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="ticketStatus"
                      id="ticketStatus"
                      render={DropDown}
                      options={ticketStatuses}
                      disabled={processing}
                    />
                  }
                />
              )}
              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LiveChatReopenDialog;
