import { makeRequest } from '../makeRequest';
import { SMS_CONVERSATIONS } from '../requestResources';

export const getMessagingConversations = channelId =>
  makeRequest(
    'GET',
    `${SMS_CONVERSATIONS}/${channelId ? `?channelId=${channelId}` : ''}`,
  );

export const getTemplateVariables = () =>
  makeRequest('GET', `${SMS_CONVERSATIONS}/smsTextTemplateVariables`);
