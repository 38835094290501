import React from 'react';

import ActionButtonLight from '@common/buttons/ActionButton/ActionButtonLight';
import iconRemove from '@assets/icons/remove.svg';

const RemoveAction = ({ handleOnClick, title }) => (
  <ActionButtonLight
    toolTip={title}
    icon={<img src={iconRemove} alt="iconRemove" />}
    handler={handleOnClick}
  />
);

export default RemoveAction;
