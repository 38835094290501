export const getPricePlanCount = (
  pricePlan,
  techCount,
  minTechCount,
  discountData,
) => {
  const basePrice = pricePlan.basePriceCents / 100;
  const pricePerTech = pricePlan.pricePerTechCents / 100;
  const extraTechLicences = techCount - minTechCount;

  const techPrice =
    extraTechLicences > 0 ? extraTechLicences * pricePerTech : 0;

  if (discountData?.data) {
    const discountArr = discountData.data.split(' ');

    if (discountArr[0].includes('%')) {
      const discountPercentage = Number(
        discountArr[0].replace('%', ' ').replace(',', '.'),
      );

      const discountAmount =
        (basePrice + techPrice) * (discountPercentage / 100);

      return `$${Number(basePrice + techPrice - discountAmount).toFixed(2)}`;
    }

    if (discountArr[0].includes('$')) {
      const discountAmount = Number(discountArr[0].replace('$', ''));
      return `$${Number(basePrice + techPrice - discountAmount).toFixed(2)}`;
    }
    return `$${Number(basePrice + techPrice).toFixed(2)}`;
  }

  return `$${Number(basePrice + techPrice).toFixed(2)}`;
};

export default {};
