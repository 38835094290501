import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSafeSetState } from 'react-admin';
import { useSnackbar } from 'notistack';
import useStyles from '@components/Billing/components/styles';
import { Field, Form, FormSpy } from 'react-final-form';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import {
  countries,
  getCountriesOptions,
  getStatesOptions,
} from '@constants/countries/countries';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import useValidate from '@components/Hooks/useValidate';
import { getBillingCustomer, updateBillingCustomer } from './helpers';

const Address = ({ authKey }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useSafeSetState(false);
  const [data, setData] = React.useState({});
  const { required, ifPredicate } = useValidate();

  useEffect(() => {
    setLoading(true);
    getBillingCustomer(authKey)
      .then(d => setData(d))
      .catch(e => enqueueSnackbar(e.message || e, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [authKey, setLoading, enqueueSnackbar]);

  const validate = values => {
    const errors = {};
    errors.fullName = required(values.fullName);
    errors.country = required(values.country);
    errors.state = required(values.state);
    errors.postalCode = ifPredicate(
      values.postalCode && values.postalCode.length !== 5,
      'Zip code is invalid',
    );
    return errors;
  };

  const submit = values => {
    setLoading(true);
    const payload = {
      name: values.fullName,
      phone: values.phone,
      address: {
        city: values.city,
        country: values.country,
        line1: values.address1,
        state: values.state,
        postalCode: values.postalCode,
      },
    };
    updateBillingCustomer(authKey, payload)
      .then(d => setData(d))
      .catch(e => enqueueSnackbar(e.message || e, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  const handleChangeForm = (values, form) => {
    if (values.values.country) {
      const { states } = countries[values.values.country];
      if (!states.some(s => s.value === values.values.state)) {
        form.change('state', undefined);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <span className={classes.font}>UPDATE YOUR BILLING ADDRESS</span>
      </div>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            fullName: data.name ?? null,
            phone: data.phone ?? null,
            address1:
              data.address && data.address.line1 ? data.address.line1 : null,
            country:
              data.address && data.address.country
                ? data.address.country
                : null,
            state:
              data.address && data.address.state ? data.address.state : null,
            city: data.address && data.address.city ? data.address.city : null,
            postalCode:
              data.address && data.address.postalCode
                ? data.address.postalCode
                : null,
          }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  autoFocus
                  id="fullName"
                  name="fullName"
                  inputView="text"
                  styleType="main"
                  component={Input}
                  fullWidth
                  placeholder="Full Name"
                  disabled={loading}
                />
                <Field
                  id="phone"
                  name="phone"
                  inputView="number"
                  styleType="main"
                  fullWidth
                  component={Input}
                  classNameWrapper={classes.inputWrapper}
                  placeholder="Phone"
                  disabled={loading}
                />
                <Field
                  id="address1"
                  name="address1"
                  inputView="text"
                  styleType="main"
                  fullWidth
                  component={Input}
                  classNameWrapper={classes.inputWrapper}
                  placeholder="Address 1"
                  disabled={loading}
                />
                <div className={classes.inputGroup}>
                  <div className={classes.preWidth}>
                    <Field
                      id="country"
                      name="country"
                      fullWidth
                      component={AutocompleteFormInput}
                      label="Country"
                      items={getCountriesOptions()}
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.preWidth}>
                    <Field
                      id="state"
                      name="state"
                      component={AutocompleteFormInput}
                      label="State"
                      items={getStatesOptions(values.country)}
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <Field
                    id="city"
                    name="city"
                    inputView="text"
                    styleType="main"
                    component={Input}
                    classNameWrapper={classes.inputSmallWrapper}
                    placeholder="City"
                    disabled={loading}
                  />
                  <Field
                    id="postalCode"
                    name="postalCode"
                    inputView="text"
                    styleType="main"
                    component={Input}
                    classNameWrapper={classes.inputSmallWrapper}
                    placeholder="Zip Code"
                    disabled={loading}
                  />
                </div>
              </div>
              <div className={classes.buttonWrapper}>
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                  label="Update"
                />
              </div>
              <FormSpy onChange={val => handleChangeForm(val, form)} />
            </form>
          )}
        />
      </div>
    </div>
  );
};

Address.propTypes = {
  authKey: PropTypes.string,
};

export default Address;
