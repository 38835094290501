import { createAsyncThunk } from '@reduxjs/toolkit';
import { editConnectWiseTicketNote } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkEditConnectWiseTicketNote = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/editConnectWiseTicketNote`,
  async ({ crmDefaultId, ticketId, payload }) => {
    const note = await editConnectWiseTicketNote(
      crmDefaultId,
      ticketId,
      payload.id,
      { text: payload.text, internalFlag: payload.internalFlag },
    );
    return note;
  },
);

export default {};
