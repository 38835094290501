import React from 'react';
import { ReactComponent as FtpIcon } from '@assets/icons/upload_active.svg';
import List from './FtpConnections';

const Icon = () => <FtpIcon alt="ftpIcon" />;

export default {
  list: List,
  icon: Icon,
};
