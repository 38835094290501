import { makeRequest } from '@services/requests/makeRequest';
import { USER } from '@services/requests/requestResources';

export const getCrmUsersByDefaultsId = crmDefaultId =>
  makeRequest('GET', `${USER}/crmDefault/${crmDefaultId}/getCrmUsers`);

export const linkCrmUserToAccount = payload =>
  makeRequest('PUT', `${USER}/linkCrmUser`, payload);

export const getMfaCode = () => makeRequest('GET', `${USER}/MFCode`);

export const getDuoAuthUrl = () =>
  makeRequest('GET', `/Account/get-duo-verification-page`);

export const updateUser = (id, payload) =>
  makeRequest('PUT', `${USER}/${id}`, payload);

export const createUser = payload =>
    makeRequest('POST', `${USER}`, payload);
