import { getHeaders } from '@utils/transfered';
import localStorage from '@services/localStorage';

const deleteData = async (url, withErrorData = false) => {
  const request = new Request(url, {
    method: 'DELETE',
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (!response.ok && withErrorData) {
    const errorData = await response.json();

    return { error: errorData, ok: response.ok };
  }
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('accessToken');
    window.location.href = '#/login';
    return Promise.reject({ message: false });
  }
  return response.status >= 200 && response.status < 300;
};

export default deleteData;
