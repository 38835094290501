import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkGetIncident,
  ThunkGetIncidentNotes,
  ThunkGetIncidentTickets,
  ThunkGetIncidentTimeEntry,
} from '@store/slices/incident/thunks';
import {
  SelectCurrentIncident,
  SelectIncidentPending,
} from '@store/slices/incident/selectors';

import Loading from '@ui/components/common/Loading/Loading';
import Details from './components/Details/Details';
import Notes from './components/Notes/Notes';
import Tickets from './components/Tickets/Tickets';
import TimeEntry from './components/TimeEntry/TimeEntry';

import useStyles from './styles';

const Incident = React.memo(({ match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const incident = useSelector(SelectCurrentIncident);
  const isPending = useSelector(SelectIncidentPending);

  const onMount = useCallback(async () => {
    if (match && match.params.id) {
      dispatch(ThunkGetIncident(match.params.id));
      dispatch(ThunkGetIncidentNotes(match.params.id));
      dispatch(ThunkGetIncidentTickets(match.params.id));
      dispatch(ThunkGetIncidentTimeEntry(match.params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (isPending) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.firstRowContainer}>
        <Details incident={incident} />
        <Notes incidentId={incident.id} />
      </div>

      <div className={classes.secondRowContainer}>
        <Tickets incidentId={incident.id} />
        <TimeEntry incidentId={incident.id} />
      </div>
    </div>
  );
});

Incident.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default Incident;
