import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ThunkGetMessagingChannelCrmInfo } from '@store/slices/messaging/thunks';
import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../styles';

const TicketInfoDialog = ({ open, setOpen, channelId }) => {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});

  const dispatch = useDispatch();
  const classes = useStyles();

  const onMount = useCallback(async () => {
    setLoading(true);
    const data = await dispatch(
      ThunkGetMessagingChannelCrmInfo(channelId),
    ).unwrap();
    setInfo(data);
    setLoading(false);
  }, [channelId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>PSA Ticket Info</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading classNameWrapper={classes.loader} />
        ) : (
          <>
            {info?.data.map(({ key, value }) => (
              <div key={key} className={classes.itemContainer}>
                <div className={classes.channelDetailsLabel}>{key}</div>
                <div className={classes.channelDetailsAlignment}>{value}</div>
              </div>
            ))}

            <div className={classes.dialogActions}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

TicketInfoDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  channelId: PropTypes.number,
};
export default TicketInfoDialog;
