/* eslint-disable consistent-return */
import appConfig from '@configs/appConfig';
import { getHeaders } from '@utils/transfered';

export const makeRequest = async (
  method,
  url,
  data,
  formData,
  resJson = true,
) => {
  const response = await fetch(`${appConfig.baseUrl}${url}`, {
    method,
    body: JSON.stringify(data),
    headers: getHeaders(true),
    formData,
  });
  if (response.status === 401 && url?.includes('Token')) {
    const errorData = await response.json();
    const errorMessage = `RequestError: ${errorData.message ||
      response.statusText ||
      'Something went wrong'}`;
    throw new Error(errorMessage);
  }
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('accessToken');
    window.location.href = '#/login';
    return response.json();
  }
  if (
    response.status < 200 ||
    response.status >= 300 ||
    response.status === 400
  ) {
    const errorData = await response.json();
    const errorMessage = `RequestError: ${errorData.message ||
      response.statusText ||
      'Something went wrong'}`;
    throw new Error(errorMessage);
  }

  if (response.status === 204) {
    return true;
  }

  if (resJson) return response.json();
  if (!resJson) return response;
};

export default {};
