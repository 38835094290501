import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
    backgroundColor: props => props.backgroundColor,
    padding: '5px 10px',
    borderRadius: '0.5rem',
  },
}));

const StatusesAccessor = ({ item, array }) => {
  const { name, color, backgroundColor = '' } = array[item ?? 0];
  const { colorBlock } = useStyles({ color, backgroundColor });

  return <span className={colorBlock}>{name}</span>;
};

StatusesAccessor.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  array: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
};

export default StatusesAccessor;
