import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getConnectWiseTicketPriorities } from '@services/requests/createTicket';

export const ThunkGetConnectWisePriorities = createAsyncThunk(
  `${CREATE_TICKET}/getConnectWiseTicketPriorities`,
  ({ psaId }) => getConnectWiseTicketPriorities(psaId),
);

export default {};
