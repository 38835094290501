import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFIED_PSA_TECH_BY_SMS } from '@store/slices/resources';
import { getContactByPhoneNumber } from '@services/requests/psaTechVerification';

export const ThunkGetContactByPhoneNumber = createAsyncThunk(
  `${VERIFIED_PSA_TECH_BY_SMS}/getContactByPhone`,
  async payload => getContactByPhoneNumber(payload),
);

export default {};
