import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 16px',
    '& table': {
      maxHeight: '65vh',
    },
  },
  chartContainer: {
    maxWidth: '300px',
  },
}));

export default useStyles;
