import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '0.9rem',
  },
  iconButton: {
    height: '20px',
    transition: '0.3s',
    marginLeft: '0.3rem',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  codeBlockBackground: {
    backgroundColor: '#f6f8fa',
    borderRadius: '6px',
    padding: '16px',
    position: 'relative',
  },
  codeBlockCopyButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '1rem',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  imgResize: {
    '& img': {
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
