import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import useStyles from './accordionStyles';

const AccordionItem = ({ header, children, name, expanded, handleChange }) => {
  const classes = useStyles();

  return (
    <Accordion
      classes={{ root: classes.widthStyle }}
      expanded={expanded === name}
      onChange={handleChange(name)}
    >
      <AccordionSummary
        classes={{
          root: classes.widthStyle,
          content: classes.widthStyle,
          expanded: classes.expanded,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span>{header}</span>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailsLayout }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

AccordionItem.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  expanded: PropTypes.string,
};

export default AccordionItem;
