import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingCrmStatistic } from '@services/requests/messagingDashboard';
import { MESSAGING_DASHBOARD } from '@store/slices/resources';

export const ThunkGetMessagingCrmStatistic = createAsyncThunk(
  `${MESSAGING_DASHBOARD}/getMessagingCrmStatistic`,
  async () => {
    const crmStatistic = await getMessagingCrmStatistic();
    return crmStatistic.map(item => ({
      name: item.crmType,
      y: item.channelsNumber,
    }));
  },
);

export default {};
