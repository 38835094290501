import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_INTEGRATIONS } from '@store/slices/resources';
import { updateIntegration } from '@services/requests/securityIntegrations';

export const ThunkUpdateSecurityIntegration = createAsyncThunk(
  `${SECURITY_INTEGRATIONS}/updateIntegration`,
  async id => {
    const data = await updateIntegration(id);
    return data;
  },
);

export default {};
