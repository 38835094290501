import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getVerificationPhone } from '@services/requests/verificationSettings';

export const ThunkGetVerificationPhone = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getVerificationPhone`,
  async () => {
    const res = await getVerificationPhone();
    return res;
  },
);

export default {};
