import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';
import some from 'lodash/some';

export function hasParams(search, filters, sort) {
  if (search && search.query && trim(search.query)) return true;

  if (some(keys(filters), item => filters[item] && filters[item].length))
    return true;

  return Boolean(sort.field) && sort.order !== 'NONE';
}

export function getFilterData({
  data = [],
  filters = {},
  search = {
    query: '',
    fields: [],
  },
  sort = {
    field: '',
    order: 'NONE',
  },
}) {
  let result = Object.assign(data);

  if (search && search.query && trim(search.query)) {
    const queryLowerCase = toLower(search.query);

    result = data.filter(item =>
      search.fields.some(field => {
        const fieldLowerCase = toLower(item[field]);
        return fieldLowerCase.includes(queryLowerCase);
      }),
    );
  }

  if (filters && keys(filters).length) {
    keys(filters).forEach(field => {
      if (filters[field] && filters[field].length) {
        result = filter(result, item => {
          const fieldValue = item[field];
          if (isNil(fieldValue)) return false;

          if (isString(filters[field])) {
            const fieldLowerCase = toLower(fieldValue);
            const queryLowerCase = toLower(filters[field]);
            return fieldLowerCase.includes(queryLowerCase);
          }

          return false;
        });
      }
    });
  }

  if (sort.field && sort.order !== 'NONE') {
    result = orderBy(result, sort.field, sort.order === 'ASC' ? 'asc' : 'desc');
  }

  return result;
}
