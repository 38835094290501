import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPsaCompaniesAutocomplete } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetPsaCompaniesAutocomplete = createAsyncThunk(
  `${MESSAGING}/getCrmCompaniesAutocomplete`,
  async payload => {
    const companies = await getPsaCompaniesAutocomplete(payload);
    return companies;
  },
);

export default {};
