import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_DEFAULT } from '@store/slices/resources';
import { updateClientPortalDefault } from '@services/requests/clientPortal';

export const ThunkUpdateClientPortalDefault = createAsyncThunk(
  `${CLIENT_PORTAL_DEFAULT}/updateClientPortalDefault`,
  ({ defaultId, payload }) => updateClientPortalDefault(defaultId, payload),
);

export default {};
