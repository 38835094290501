import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'stretch',
    padding: '0',
  },
  paperSheet: {
    borderRadius: '4px',
    width: '100%',
    padding: '5px',
    margin: '5px 0',
    boxShadow: '2px 2px 3px 1px #888888',
    backgroundColor: 'white',
  },
  bigBox: {
    minHeight: '300px',
  },
  smallBox: {
    minHeight: '19px',
  },
  caption: {
    alignSelf: 'baseline',
  },
  loadButton: {
    alignSelf: 'baseline',
    width: '125px',
    textAlign: 'center',
    minHeight: '21px',
    marginBottom: '10px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  font: {
    fontStyle: 'normal',
  },
  metaCaption: {
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0.5rem',
    },
  },
  attachmentBadge: {
    maxHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available',
    maxWidth: ({ isMaxContent }) => isMaxContent && 'max-content',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '3px 15px',
    border: '1px solid #9f9f9f',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0.1rem 0.5rem',
    '&:hover': {
      boxShadow: '3px 3px 3px 0px #D6D6D6',
    },
  },
  marginRight: {
    marginRight: '5px',
  },
  flex: {
    display: 'flex',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mailBody: {
    height: 'calc(100vh - 340px)',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100vw',
    },
    overflow: 'auto',
  },
  tabsWrapper: {
    backgroundColor: 'transparent',
  },
  tabsHeaderWrapper: {
    borderBottom: `0.0625rem solid ${theme.palette.info.light}`,
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  loading: {
    color: 'white',
    '& > h1': {
      opacity: 1,
    },
    '& > div': {
      opacity: 1,
    },
  },
  paddingHorizontal: {
    padding: '0.5rem 0',
  },
  modalContext: {
    position: 'fixed',
    zIndex: '1',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,.5)',
  },
  textStyle: {
    maxWidth: 'calc(100vw - 15px)',
    maxHeight: 'calc(100vw - 15px)',
    padding: '10px',
  },
  jsonTextStyle: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '20vw',
      maxWidth: '76vw',
    },
  },
  metaDataGridRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.25rem 0.5rem',
    },
  },
  warningDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  warningDialogRedWord: {
    color: 'red',
    marginTop: '2rem',
  },
  marginTop: {
    marginTop: '0.5rem',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    textAlign: 'center',
  },
  customFieldButton: {
    margin: '3px',
  },
  textField: {
    width: '100%',
    paddingTop: '10px',
  },
  submitButton: {
    margin: '3px',
  },
}));

export default useStyles;
