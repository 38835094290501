import React, { useCallback, useEffect, useState } from 'react';

import Table from '@components/Table';
import { getOptionsList } from '@services/requests/common';
import { optionsTypeName } from '@constants/common';
import VerificationHistoryActions from './components/VerificationHistoryActions';

import { verificationHistoryColumns } from './constants';

import useStyles from './styles';

const VerificationHistory = () => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  const onMount = useCallback(() => {
    getOptionsList(optionsTypeName.ContactVerificationTypes).then(res => {
      setOptions(res);
    });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.container}>
      {!!options.length && (
        <>
          <VerificationHistoryActions />
          <Table
            columns={verificationHistoryColumns(options)}
            resource="VerificationReports"
            defaultSort={{ fieldName: 'verificationDateTime', order: 'DESC' }}
          />
        </>
      )}
    </div>
  );
};

export default VerificationHistory;
