import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  billingBenefits: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    maxWidth: '70%',
    margin: '0 auto',
    height: '100%',
    '@media (max-width: 1100px)': {
      display: 'none',
    },
  },
  benefit: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: 'medium',
    '& svg': {
      color: 'white',
    },
  },
  card: {
    margin: '30px 0px',
    padding: '30px',
    background: 'white',
    color: 'black',
    borderRadius: '10px',
  },
  selectedPlan: {
    color: 'white',
    background:
      'linear-gradient(292.19deg, #012169 -5.34%, #455FAE 39.72%, #091456 92.29%),\nlinear-gradient(0deg, #012169, #012169),\nlinear-gradient(0deg, rgba(191, 184, 184, 0.4), rgba(191, 184, 184, 0.4))',
  },
  plansContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '5px auto',
  },
  pricePlan: {
    padding: '20px',
    boxShadow: '0px 4px 39px 0px #7878780F',
    border: '1px solid #BFB8B866',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  technicianCount: {
    width: '100%',
    padding: '0px 20px',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '10fr 3fr',
    alignItems: 'center',
  },
  totalValue: {
    width: '100%',
    padding: '0px 20px',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '10fr 3fr',
    alignItems: 'center',
  },
  error: {
    width: '100%',
    padding: '0px 20px',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '10fr 3fr',
    alignItems: 'center',
    color: 'red',
  },
  billingButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
    padding: '0px 25px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContainer: {
    color: 'white',
    margin: '30px',
    padding: '160px 20px 20px 20px',
    borderRadius: '15px',
    border: '1px solid',
    borderImageSource:
      'linear-gradient(107.23deg, rgba(255, 255, 255, 0.5) -9.9%, rgba(255, 255, 255, 0.11) 103.17%)',
    background:
      'linear-gradient(107.23deg, rgba(255, 255, 255, 0.5) -9.9%, rgba(255, 255, 255, 0.11) 103.17%),\nlinear-gradient(0deg, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32))',
  },
  cardsData: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    maxWidth: '70%',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    '@media (max-width: 1100px)': {
      display: 'none',
    },
  },
  cardsTitle: {
    fontWeight: 900,
    fontSize: 'large',
  },
  cardHolder: {
    fontWeight: 300,
    fontSize: 'small',
  },
  rowAlign: {
    display: 'flex',
    justifyContent: ' space-between',
  },
  summary: {
    padding: '30px',
    background: 'white',
    color: 'black',
    margin: '0px 30px',
  },
  summaryBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '15px',
  },
  total: {
    borderTop: '1px solid #00000052',
    borderBottom: '1px solid #00000052',
    padding: '10px 0px',
    margin: '3px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
