import { makeRequest } from '../makeRequest';
import { EMAIL_TEMPLATE_SETTINGS } from '../requestResources';

export const getEmailTemplatesSettings = () =>
  makeRequest('GET', `${EMAIL_TEMPLATE_SETTINGS}`);

export const getInviteClientPortalTemplateVariables = () =>
  makeRequest(
    'GET',
    `${EMAIL_TEMPLATE_SETTINGS}/inviteClientPortalTemplateVariables`,
  );

export const saveEmailTemplatesSettings = payload =>
  makeRequest('POST', `${EMAIL_TEMPLATE_SETTINGS}`, payload);

export default {};
