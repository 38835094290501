import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  '@global': {
    '@keyframes spin': {
      '0%': {
        transform: 'rotate( 0deg )',
      },
      '100%': {
        transform: 'rotate( 360deg )',
      },
    },
  },
  container: {
    display: 'grid',
    placeItems: 'center',
    alignContent: 'center',
    fontFamily: 'Roboto, sans-serif',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      height: '100vh',
      marginTop: '-3em',
    },
    '& > h1': {
      opacity: 0.5,
    },
    '& > div': {
      opacity: 0.5,
    },
    '& > img': {
      width: '50px',
      height: '50px',
    },
  },
}));

export default useStyles;
