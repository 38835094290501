import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getVerificationSettings } from '@services/requests/verificationSettings';
import {
  getContactByPhoneNumber,
  getContactTickets,
} from '@services/requests/psaTechVerification';

export const ThunkGetTechnicianVerificationPodData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getVerificationSettings`,
  async ({ crmId, phone }) =>
    Promise.all([
      getVerificationSettings(),
      getContactByPhoneNumber({ crmId, phone }),
    ]).then(async res => {
      let isContactExist = false;
      let tickets = [];
      if (res[1]?.userId) {
        isContactExist = true;
        tickets = await getContactTickets({ crmId, contactId: res[1]?.userId });
      }

      return {
        techResponseTemplate: res[0].techResponseTemplate,
        isContactExist,
        contactName: res[1]?.userName ?? '',
        companyName: res[1]?.companyName ?? '',
        contactPhone: res[1]?.phone ?? '',
        tickets,
      };
    }),
);

export default {};
