import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA_SETTINGS } from '@store/slices/resources';
import { updateSecureDataSettings } from '@services/requests/secureDataSettings';

export const ThunkUpdateSecureDataSettings = createAsyncThunk(
  `${SECURE_DATA_SETTINGS}/updateSecureDataSettings`,
  async payload => {
    const res = await updateSecureDataSettings(payload);
    return res;
  },
);

export default {};
