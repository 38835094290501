import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { reopenLiveChat } from '@services/requests/liveChats';

export const ThunkReopenLiveChat = createAsyncThunk(
  `${LIVE_CHATS}/reopenLiveChat`,
  ({ payload }) => reopenLiveChat(payload),
);

export default {};
