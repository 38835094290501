import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(theme => ({
  imageText: {
    marginTop: theme.spacing(1),
    color: '#9D9D9DFF',
    fontWeight: 'bold',
    fontSize: 'small',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
}));

export default useStyles;
