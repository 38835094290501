import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT_MODULES } from '@store/slices/resources';
import { getTenantsOptions } from '@services/requests/admin';

export const ThunkGetTenantsOptions = createAsyncThunk(
  `${TENANT_MODULES}/Tenants`,
  async () => {
    const tenantOptions = await getTenantsOptions();
    const labelValueData = tenantOptions.map(item => ({
      label: item.value,
      value: item.id,
    }));
    return labelValueData;
  },
);

export default {};
