import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';

import {
  ThunkGetCrmStatusesAndTypes,
  ThunkSyncCrm,
} from '@store/slices/crm/thunks';
import { ThunkGetCrmSyncSettings } from '@store/slices/integration/thunks';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import FormMultiselect from '@components/FormMultiselect';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const PsaSyncDialog = ({ open = true, onClose, crmId }) => {
  const [loading, setLoading] = useState(true);
  const [companyStatuses, setCompanyStatuses] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    types: [],
    statuses: [],
  });

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkSyncCrm({ crmId, payload: values }))
      .unwrap()
      .then(() => {
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetCrmSyncSettings(crmId)).unwrap(),
      dispatch(ThunkGetCrmStatusesAndTypes(crmId)).unwrap(),
    ])
      .then(([settings, res]) => {
        setInitialValues(settings);
        setCompanyTypes(
          res.types
            .map(i => ({ label: i.type, value: i.type }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        );
        setCompanyStatuses(
          res.statuses
            .map(i => ({ label: i.status, value: i.status }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crmId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sync Data</DialogTitle>
      <DialogContent style={{ width: '500px' }}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <FieldWrapper
                  label="Company Types"
                  labelSize={12}
                  contentSize={12}
                  content={
                    <Field
                      id="types"
                      name="types"
                      render={FormMultiselect}
                      options={companyTypes}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Company Statuses"
                  labelSize={12}
                  contentSize={12}
                  content={
                    <Field
                      id="statuses"
                      name="statuses"
                      render={FormMultiselect}
                      options={companyStatuses}
                      disabled={processing}
                    />
                  }
                />

                <div className={sharedClasses.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={onClose}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Sync"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

PsaSyncDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  crmId: PropTypes.number.isRequired,
};

export default PsaSyncDialog;
