/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { INTEGRATION } from '../resources';

import {
  ThunkGetIntegrationCompanies,
  ThunkGetIntegrationPriorities,
  ThunkGetIntegrationResources,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
  ThunkGetIntegrationTicketCategories,
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
} from './thunks';

const initialState = {
  companies: [],
  priorities: [],
  resources: [],
  serviceBoards: [],
  sources: [],
  categories: [],
};

const integrationSlice = createSlice({
  name: INTEGRATION,
  initialState,
  reducer: {},
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetIntegrationCompanies.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationPriorities.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationResources.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationServiceBoards.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationSources.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationTicketCategories.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationBoardStatuses.fulfilled,
        (state, { payload }) => payload,
      )

      .addCase(
        ThunkGetIntegrationBoardTypes.fulfilled,
        (state, { payload }) => payload,
      );
  },
});

export default integrationSlice.reducer;
