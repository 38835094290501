import React from 'react';
import { ReactComponent as LinkIcon } from '@assets/icons/link_orange.svg';
import List from './BillingLinks';

const Icon = () => <LinkIcon alt="linkIcon" />;

export default {
  list: List,
  icon: Icon,
};
