import composeValidators from '@utils/validators/composeValidators';
import { requiredValidator } from '@utils/validators';

const thresholdValue = ({ value, type }) => {
  const number = +value;
  if (+type && (number === 0 || number < 1)) {
    return 'validation.threshold.invalid';
  }
};

const thresholdlastNumbers = value => {
  if (+value < 1) {
    return 'validation.threshold.invalid';
  }
};

const requiredValidatorWrapper = ({ value, type }) => {
  if (+type && !value) {
    return requiredValidator(value);
  }
};

const dmSettingsValidator = (value, type) => {
  if (type !== undefined) {
    return composeValidators(
      requiredValidatorWrapper,
      thresholdValue,
    )({ value, type });
  }
  return composeValidators(requiredValidator, thresholdlastNumbers)(value);
};
export default dmSettingsValidator;
