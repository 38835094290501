import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../../../styles';

const OrgItem = ({ id, name, api, onClick, selectionObject }) => {
  const classes = useStyles({ enabled: api.enabled });

  const handleClick = (event, isCheckbox, selected) => {
    event.stopPropagation();
    onClick(id, name, isCheckbox, selected);
  };

  const checked =
    selectionObject &&
    selectionObject.total !== 0 &&
    selectionObject.selected !== 0;

  const indeterminate =
    selectionObject &&
    selectionObject.selected !== 0 &&
    selectionObject.total !== selectionObject.selected;

  return (
    <div
      role="presentation"
      onClick={event => api.enabled && handleClick(event)}
      className={cx(classes.columnItem, classes.orgColumnItem)}
    >
      {api.enabled && (
        <Checkbox
          style={{ height: '25px' }}
          checked={checked}
          defaultChecked={indeterminate}
          indeterminate={indeterminate}
          inputProps={{ 'aria-label': 'indeterminate checkbox' }}
          onClick={event => handleClick(event, true, checked)}
        />
      )}
      <span>{name}</span>
    </div>
  );
};

OrgItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  api: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
  selectionObject: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrgItem;
