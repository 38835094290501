import React from 'react';
import { ReactComponent as SmsIcon } from '@assets/icons/sms.svg';
import List from './List';

const Icon = () => <SmsIcon alt="smsIcon" />;

export default {
  list: List,
  icon: Icon,
};
