import { actionTypes } from '../actions';

const defaultState = {
  config: undefined,
};

const pusherReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_PUSHER_CONFIG:
      return { config: action.payload };
    default:
      return state;
  }
};

export default pusherReducer;
