import { createAsyncThunk } from '@reduxjs/toolkit';
import { assignPhoneNumber } from '@services/requests/phoneNumbers';
import { BUFFER_PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkAssignPhoneNumber = createAsyncThunk(
  `${BUFFER_PHONE_NUMBERS}/buyPhoneForTenant`,
  async ({ assignType, ...payload }) => assignPhoneNumber(payload, assignType),
);

export default {};
