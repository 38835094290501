import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import columnsTable from '@services/columnsTable';

export const ColumnsContext = React.createContext(null);

const ColumnContextProvider = ({ tableKey, children }) => {
  const [columns, setColumns] = useState([]);
  const [isDraggable, setIsDraggable] = useState(false);

  const setColumnsInLocalStorage = updatedColumns => {
    setColumns(updatedColumns);
    const hiddenColumns = [];
    const columnsOrder = updatedColumns.map(item => {
      if (item.hidden) hiddenColumns.push(item.key);
      return item.key;
    });
    columnsTable.setColumnsInStorage(tableKey, {
      columnsData: { columnsOrder, hiddenColumns },
    });
  };

  const setDefaultColumns = useCallback(
    defaultColumns => {
      const columnsOrder = columnsTable.getTableColumnsOrder(tableKey);
      if (!columnsOrder) {
        setColumns(defaultColumns);
      } else {
        const orderedColumns = [];
        columnsOrder.forEach(column => {
          orderedColumns.push(defaultColumns.find(i => i.key === column));
        });
        setColumns(orderedColumns);
      }
    },
    [tableKey],
  );

  useEffect(() => {
    setIsDraggable(false);
  }, [children]);

  return (
    <ColumnsContext.Provider
      value={{
        columns,
        setDefaultColumns,
        isDraggable,
        setIsDraggable,
        setColumnsInLocalStorage,
      }}
    >
      {children}
    </ColumnsContext.Provider>
  );
};

ColumnContextProvider.propTypes = {
  children: PropTypes.node,
  tableKey: PropTypes.string,
};

export default ColumnContextProvider;
