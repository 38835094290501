import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tabsWrapper: {
    display: 'flex',
    borderBottom: '0.0625rem solid #D9D5D5',
    backgroundColor: 'white',
  },
}));

export default useStyles;
