import React, { useCallback, useState } from 'react';
import Loading from '@common/Loading/Loading';

import useAsync from '@services/api/common/useAsync';
import UserForm from './components/newLayout/UserForm';
import { getRolesOptions, getUserData } from './helpers';

const EditUser = ({ match }) => {
  const [data, setData] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);
  const getDataModel = useCallback(() => getUserData(match.params.id), [match]);
  const userLoading = useAsync(getDataModel, setData);
  const rolesLoading = useAsync(getRolesOptions, setRoleOptions);

  return userLoading || rolesLoading ? (
    <Loading />
  ) : (
    <UserForm user={data} rolesOptions={roleOptions} />
  );
};

export default EditUser;
