import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PlusIcon from '@assets/icons/add.svg';
import RefreshIcon from '@assets/icons/refreshItem.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import AddNoteDialog from '@components/CrmTicket/Notes/dialogs/AddNoteDialog';
import TicketNote from './componets/TicketNote';

import { useTicketNotesGenericFunctions } from './helpers/useTicketNotesGenericFunctions';
import useStyles from './styles';

const TicketNotes = ({ psaType }) => {
  const [loading, setLoading] = useState(true);
  const [openAddNoteDialog, setOpenAddNoteDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { ticketId, crmDefaultId } = useParams();
  const {
    getNotes,
    notesSelector,
    convertNote,
  } = useTicketNotesGenericFunctions({
    psaType,
  });

  const notes = useSelector(notesSelector);

  const convertedNotes = useMemo(() => {
    return notes.map(note => convertNote(note));
  }, [notes, convertNote]);

  const onMount = useCallback(async () => {
    setLoading(true);
    if (getNotes) {
      await dispatch(getNotes({ crmDefaultId, ticketId }));
    }
    setLoading(false);
  }, [dispatch, crmDefaultId, ticketId, getNotes]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.notesContainer}>
      <div className={classes.notesContainerHeader}>
        Notes
        {loading ? (
          <CircularProgress className={classes.notesContainerHeaderLoader} />
        ) : (
          <div>
            <ActionFieldItem
              handler={() => {
                onMount();
              }}
              alt="Refresh"
              toolTip="Refresh"
              icon={RefreshIcon}
            />
            <ActionFieldItem
              alt="Add Note"
              toolTip="Add Note"
              handler={() => {
                setOpenAddNoteDialog(true);
              }}
              icon={PlusIcon}
            />
          </div>
        )}
      </div>

      <div className={classes.notesList}>
        {!!notes.length &&
          convertedNotes.map(note => (
            <TicketNote key={note.id} note={note} psaType={psaType} />
          ))}
      </div>

      {openAddNoteDialog && (
        <AddNoteDialog
          open={openAddNoteDialog}
          setOpen={setOpenAddNoteDialog}
          psaType={psaType}
        />
      )}
    </div>
  );
};

TicketNotes.propTypes = {
  psaType: PropTypes.string.isRequired,
};

export default TicketNotes;
