import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import DateTimeField from '@common/TableComponents/DateTimeField';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import Table from '@components/Table';

import { actions } from '@store/actions';
import { TableTypes } from '@components/Table/constants';
import booleanOptions from '@utils/constants/options';

import ActionField from './ActionField';
import {
  getSubscriptionStatuses,
  pauseSubscription,
  syncWithStripe,
} from './helpers';

import useStyles from '../styles';

const List = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const handleToggleStateClick = useCallback(
    item => {
      setLoading(true);
      pauseSubscription(item.stripeId)
        .then(resp => {
          dispatch(actions.updateItemInList(resp));
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
        .finally(() => setLoading(false));
    },
    [dispatch, enqueueSnackbar],
  );

  const handleSyncClick = useCallback(
    item => {
      setLoading(true);
      syncWithStripe(item.stripeId)
        .then(resp => {
          dispatch(actions.updateItemInList(resp));
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
        .finally(() => setLoading(false));
    },
    [dispatch, enqueueSnackbar],
  );

  const onMount = useCallback(() => {
    getSubscriptionStatuses().then(res => {
      setStatuses(res);
    });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = useMemo(
    () => [
      {
        name: 'Tenant Name',
        key: 'tenantName',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Subscription ID',
        key: 'stripeId',
        minWidth: 250,
      },
      {
        name: 'Status',
        key: 'status',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: statuses,
        optionsResource: `/Subscriptions/statuses`,
        filterByAutocomplete: 'status',
        minWidth: 200,
        width: 200,
      },
      {
        name: 'Demo Subscription',
        key: 'isDemoSubscription',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'isDemoSubscription',
        isCustomAccessor: true,
        minWidth: 200,
        width: 200,
        accessor: rowData => (
          <BooleanAccessor value={rowData.isDemoSubscription} useMultiColor />
        ),
      },
      {
        name: 'Obsolete Plan',
        key: 'isObsoletePlan',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        type: TableTypes.dropdown,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'isObsoletePlan',
        minWidth: 200,
        width: 200,
        accessor: rowData => (
          <BooleanAccessor value={rowData.isObsoletePlan} useMultiColor />
        ),
      },
      {
        name: 'Tech Utilization',
        key: 'planId',
        minWidth: 150,
        width: 150,
        isCustomAccessor: true,
        accessor: rowData => (
          <div>{`${rowData.techUsed}/${rowData.techReserved}`}</div>
        ),
      },
      {
        name: 'Start Date',
        key: 'startDate',
        minWidth: 200,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="startDate"
            source="startDate"
            label="Start Date"
            sortable
          />
        ),
      },
      {
        name: 'Next Payment',
        key: 'nextPayment',
        minWidth: 200,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="nextPayment"
            source="nextPayment"
            label="Next Payment"
            sortable
          />
        ),
      },
      {
        name: 'Last Updated',
        key: 'lastSyncDate',
        sort: 'lastSyncDate',
        isCustomAccessor: true,
        minWidth: 200,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="lastSyncDate"
            source="lastSyncDate"
            label="Last Updated"
            sortable
          />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 125,
        minWidth: 125,
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            handleToggleStateClick={handleToggleStateClick}
            handleSyncClick={handleSyncClick}
            loading={loading}
          />
        ),
      },
    ],
    [handleSyncClick, handleToggleStateClick, loading, statuses],
  );

  return (
    <div className={classes.subscriptionsTable}>
      <Table
        columns={columns}
        resource="/subscriptions"
        defaultSort={{ fieldName: 'tenantName', order: 'ASC' }}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
