import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getCompanyVerificationSettings } from '@services/requests/verificationSettings';

export const ThunkGetCompanyVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getCompanyVerificationSettings`,
  ({ crmId, companyId }) => getCompanyVerificationSettings(crmId, companyId),
);

export default {};
