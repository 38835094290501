import appConfig from '@configs/appConfig';
import putWithResponse from '@services/api/common/putWithResponse';
import { subscriptions } from '@constants/appRoutes';
import { makeRequest } from '@services/requests/makeRequest';

export const syncWithStripe = stripeId =>
  putWithResponse(`${appConfig.baseUrl}${subscriptions}${stripeId}/sync`, null);

export const pauseSubscription = stripeId =>
  putWithResponse(
    `${appConfig.baseUrl}${subscriptions}${stripeId}/pause`,
    null,
  );

export const editSubscriptionId = (stripeId, data) =>
  putWithResponse(`${appConfig.baseUrl}${subscriptions}${stripeId}`, data);

export const getSubscriptionStatuses = () =>
  makeRequest('GET', `${subscriptions}statuses`);
