import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as CloneIcon } from '@assets/icons/copy.svg';
import { ReactComponent as ViewIcon } from '@assets/icons/visibility_blue.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';

import useStyles from './styles';

const TypesListItem = React.memo(
  ({
    name,
    id,
    readOnly,
    handleViewClick,
    handleCloneClick,
    handleDeleteClick,
  }) => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const classes = useStyles();

    return (
      <div key={id} className={classes.typesListItem}>
        <div>{name}</div>

        <div className={classes.typeListItemActions}>
          <div className={classes.typeListItemIcons}>
            <Tooltip title={readOnly ? 'View' : 'Edit'}>
              <button
                type="button"
                onClick={() => {
                  handleViewClick();
                }}
              >
                {readOnly ? (
                  <ViewIcon alt="view" className={classes.viewIcon} />
                ) : (
                  <EditIcon alt="edit" />
                )}
              </button>
            </Tooltip>
          </div>

          <div className={classes.typeListItemIcons}>
            <Tooltip title="Clone">
              <button
                type="button"
                onClick={() => {
                  handleCloneClick();
                }}
              >
                <CloneIcon alt="clone" />
              </button>
            </Tooltip>
          </div>

          {!readOnly && (
            <div className={classes.typeListItemIcons}>
              <Tooltip title="Edit">
                <button
                  type="button"
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                >
                  <DeleteIcon alt="delete" />
                </button>
              </Tooltip>
            </div>
          )}
        </div>

        {deleteDialog && (
          <ConfirmDialog
            open={deleteDialog}
            setOpen={() => {
              setDeleteDialog(true);
            }}
            onCancel={() => {
              setDeleteDialog(false);
            }}
            title="Confirm delete action"
            content="Are you sure you want to delete this item?"
            onAccept={handleDeleteClick}
          />
        )}
      </div>
    );
  },
);

TypesListItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  handleViewClick: PropTypes.func,
  handleCloneClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
};
export default TypesListItem;
