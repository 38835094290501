import { createAsyncThunk } from '@reduxjs/toolkit';
import { getVerificationBufferPhoneNumbers } from '@services/requests/phoneNumbers';
import { BUFFER_PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkGetVerificationPhoneNumbers = createAsyncThunk(
  `${BUFFER_PHONE_NUMBERS}/getVerificationPhoneNumbers`,
  async () => {
    const phoneNumbers = await getVerificationBufferPhoneNumbers();
    return phoneNumbers;
  },
);

export default {};
