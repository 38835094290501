import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    background: 'linear-gradient(117.3deg, #CFCFCF 0.68%, #545B6B 95.06%)',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 20px',
    boxShadow: '3px 3px 25px 2px #545b6b',
    borderRadius: '25px',
  },
  avatar: {
    margin: theme.spacing(2),
    display: 'none',
    justifyContent: 'center',
  },
  navButtons: {
    margin: '2.25rem 0',
    width: '16rem',
  },
  formWrapper: {
    maxWidth: '27rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem',
    },
    padding: '0 2.125rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      position: 'absolute',
      top: '0',
      left: '0',
      background: 'white',
      maxWidth: 'unset',
      width: '100%',
      height: '100vh',
      padding: '15px 0px',
      overflowY: 'auto',
    },
  },
  icon: {
    backgroundColor: '#33849E',
  },
  logoStyle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '15px',
    width: '100%',
  },
  stepperStyle: {
    margin: '10px 0',
    '& .MuiMobileStepper-dots': {
      justifyContent: 'center',
    },
  },
  stepperDot: {
    width: '0.375rem',
    height: '0.375rem',
  },
  stepperDotActive: {
    backgroundColor: '#33849E',
  },
  stepper: {
    /*    position: 'absolute',
    bottom: '1.375rem', */
  },
}));

export default useStyles;
