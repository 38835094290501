import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Checkbox from '@common/Checkbox/FormControlCheckBox';
import ReusableButton from '../common/Button/Button';
import CustomStepper from './components/Stepper';
import { disableSetupWizard } from './helpers';

import useStyles from './styles';

const SetupWizard = ({ open, setOpen, showCloseForeverCheckbox = true }) => {
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCloseClick = useCallback(() => {
    if (neverShowAgain) {
      disableSetupWizard();
    }
    handleClose();
  }, [handleClose, neverShowAgain]);

  const handleTextClick = useCallback(() => {
    setNeverShowAgain(!neverShowAgain);
  }, [neverShowAgain]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Setup Wizard</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CustomStepper handleClose={handleClose} />
      </DialogContent>
      <DialogActions>
        {showCloseForeverCheckbox && (
          <div className={classes.neverShowAgainContainer}>
            <span
              onClick={handleTextClick}
              role="presentation"
              style={{ cursor: 'pointer' }}
            >
              Never show this dialog on login
            </span>

            <Checkbox
              checked={neverShowAgain}
              onChange={e => setNeverShowAgain(e.target.checked)}
            />
          </div>
        )}

        <ReusableButton
          label="Close"
          onClick={() => {
            handleCloseClick();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

SetupWizard.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  showCloseForeverCheckbox: PropTypes.bool,
};

export default SetupWizard;
