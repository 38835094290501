import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { mfaLogin } from '@services/requests/auth';

export const ThunkMfaLogin = createAsyncThunk(
  `${AUTH}/mfaLogin`,
  ({ payload }) => mfaLogin(payload),
);

export default {};
