import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getKaseyaAssignees,
  getKaseyaWorkTypes,
  getKaseyaStatuses,
  baseFieldName,
} from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults/SubForms/helper';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import useStyles from '../../styles';

const requiredFields = {
  assigneeId: 'assigneeId',
  workTypeId: 'workTypeId',
  statusId: 'statusId',
};

const KaseyaSubForm = ({ crmId }) => {
  const classes = useStyles();

  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [workTypeOptions, setWorkTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getKaseyaAssignees(crmId),
      getKaseyaWorkTypes(crmId),
      getKaseyaStatuses(crmId),
    ])
      .then(resp => {
        setAssigneeOptions(resp[0]);
        setWorkTypeOptions(resp[1]);
        setStatusOptions(resp[2]);
      })
      .catch(e => console.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <div>
        <Typography className={classes.inputLabel}>Time Entry User</Typography>
        <Field
          id={baseFieldName + requiredFields.assigneeId}
          name={baseFieldName + requiredFields.assigneeId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={assigneeOptions}
          fullWidth
          disabled={loading}
        />
      </div>

      <div>
        <Typography className={classes.inputLabel}>
          Time Entry Work Type
        </Typography>
        <Field
          id={baseFieldName + requiredFields.workTypeId}
          name={baseFieldName + requiredFields.workTypeId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={workTypeOptions}
          fullWidth
          disabled={loading}
        />
      </div>

      <div>
        <Typography className={classes.inputLabel}>
          Time Entry Status
        </Typography>
        <Field
          id={baseFieldName + requiredFields.statusId}
          name={baseFieldName + requiredFields.statusId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={statusOptions}
          fullWidth
          disabled={loading}
        />
      </div>
    </>
  );
};

KaseyaSubForm.propTypes = {
  crmId: PropTypes.number.isRequired,
};

export default KaseyaSubForm;
