export const initState = data => {
  return {
    loading: false,
    currentBoardId: data?.defaultBoardId || 0,
    currentTypeId: data?.typeId || null,
    currentSubTypeId: data?.subTypeId || null,
    boardTypesOptions: [],
    subTypeOptions: [],
    statusesOptions: [],
    ignoredStatuses: [],
    itemOptions: [],
  };
};

export const actions = Object.freeze({
  loadingOn: 'loadingOn',
  loadingOff: 'loadingOff',
  setCurrentBoardId: 'setCurrentBoardId',
  setCurrentTypeId: 'setCurrentTypeId',
  setCurrentSubTypeId: 'setCurrentSubTypeId',
  setBoardTypesOptions: 'setBoardTypesOptions',
  setSubTypeOptions: 'setSubTypeOptions',
  setStatusesOptions: 'setStatusesOptions',
  setIgnoredStatuses: 'setIgnoredStatuses',
  setItemOptions: 'setItemOptions',
});

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.loadingOn:
      return { ...state, loading: true };
    case actions.loadingOff:
      return { ...state, loading: false };
    case actions.setCurrentBoardId:
      return { ...state, currentBoardId: action.payload };
    case actions.setCurrentTypeId:
      return { ...state, currentTypeId: action.payload };
    case actions.setCurrentSubTypeId:
      return { ...state, currentSubTypeId: action.payload };
    case actions.setBoardTypesOptions:
      return { ...state, boardTypesOptions: action.payload };
    case actions.setSubTypeOptions:
      return { ...state, subTypeOptions: action.payload };
    case actions.setStatusesOptions:
      return { ...state, statusesOptions: action.payload };
    case actions.setIgnoredStatuses:
      return { ...state, ignoredStatuses: action.payload };
    case actions.setItemOptions:
      return { ...state, itemOptions: action.payload };
    default:
      throw new Error();
  }
};
