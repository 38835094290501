import { validateRequired } from '@ui/components/common/functions/validators';
import crmDefaultFields from '../updateCrm/CrmDefaults/constants';

export const initialValues = {
  name: '',
  [crmDefaultFields.defaultCompany]: '',
  [crmDefaultFields.defaultBoard]: '',
  [crmDefaultFields.openBoardStatusId]: '',
  [crmDefaultFields.acknowledgedBoardStatusId]: '',
  [crmDefaultFields.closedBoardStatusId]: '',
  [crmDefaultFields.typeId]: '',
  [crmDefaultFields.ticketCategoryId]: '',
  [crmDefaultFields.sourceId]: '',
  [crmDefaultFields.ticketPriority]: null,
  [crmDefaultFields.crmSpecificSettings]: {
    serviceLevelAgreementId: null,
  },
};

export const haloRequiredFieldsType = Object.freeze({
  text: 0,
  date: 1,
  category: 2,
  impact: 3,
  urgency: 4,
  groupFields: 5,
  customTextArea: 6,
  customMultiSelect: 7,
  deadline: 8,
});

export const uniqueRequiredFields = {
  [haloRequiredFieldsType.category]: crmDefaultFields.category,
  [haloRequiredFieldsType.impact]: crmDefaultFields.impact,
  [haloRequiredFieldsType.urgency]: crmDefaultFields.urgency,
};

export const validation = (values, requiredFieldList) => {
  const validate = {
    name: validateRequired(values.name),
    [crmDefaultFields.defaultCompany]: validateRequired(
      values[crmDefaultFields.defaultCompany],
    ),
    [crmDefaultFields.defaultBoard]: validateRequired(
      values[crmDefaultFields.defaultBoard],
    ),
    [crmDefaultFields.openBoardStatusId]: validateRequired(
      values[crmDefaultFields.openBoardStatusId],
    ),
    [crmDefaultFields.closedBoardStatusId]: validateRequired(
      values[crmDefaultFields.closedBoardStatusId],
    ),
    [crmDefaultFields.typeId]: validateRequired(
      values[crmDefaultFields.typeId],
    ),
    [crmDefaultFields.ticketCategoryId]: validateRequired(
      values[crmDefaultFields.ticketCategoryId],
    ),
    [crmDefaultFields.sourceId]: validateRequired(
      values[crmDefaultFields.sourceId],
    ),
  };

  if (requiredFieldList.length) {
    validate.crmSpecificSettings = {};
    requiredFieldList.forEach(field => {
      const fieldType = field.field;
      const uniqueFieldName = uniqueRequiredFields[fieldType];

      if (
        fieldType === haloRequiredFieldsType.text ||
        fieldType === haloRequiredFieldsType.customMultiSelect ||
        fieldType === haloRequiredFieldsType.customTextArea ||
        fieldType === haloRequiredFieldsType.deadline ||
        fieldType === haloRequiredFieldsType.date
      ) {
        const crmSpecificSettingsName = field.name;
        validate.crmSpecificSettings[
          crmSpecificSettingsName
        ] = validateRequired(
          values.crmSpecificSettings[crmSpecificSettingsName],
        );
      } else if (uniqueFieldName) {
        validate[uniqueFieldName] = validateRequired(values[uniqueFieldName]);
      }
    });
    if (
      Object.values(validate.crmSpecificSettings).filter(Boolean).length === 0
    ) {
      delete validate.crmSpecificSettings;
    }
  }
  return validate;
};
