import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';

const KaseyaForm = ({ classes, loading, useLabels }) => {
  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>URL:</Typography>
      )}
      {!useLabels && (
        <span className={classes.monospace}>
          Enter URL without http prefix (like na1bmspreview.kaseya.com)
        </span>
      )}
      <Field
        id="uri"
        name="uri"
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="PSA URL"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>User Name:</Typography>
      )}
      <Field
        id="publicKey"
        name="publicKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="UserName"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Password:</Typography>
      )}
      <Field
        id="privateKey"
        name="privateKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="password"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Password"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Tenant name:</Typography>
      )}
      <Field
        id="companyId"
        name="companyId"
        styleType="main"
        fullWidth
        inputView="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Tenant name"
        disabled={loading}
      />
    </>
  );
};

KaseyaForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default KaseyaForm;
