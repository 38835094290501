import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { getParsersAutocomplete } from '../../../helpers';

const AssignParserDialog = ({ open, onClose, onSelect }) => {
  const [parserOptions, setParserOptions] = useState([]);
  useEffect(() => {
    if (open) {
      getParsersAutocomplete().then(data => setParserOptions(data));
    }
  }, [open]);

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select Parser</DialogTitle>
      <List>
        {parserOptions.map(parser => (
          <ListItem
            button
            onClick={() => onSelect(parser.value)}
            key={parser.value}
          >
            <ListItemText primary={parser.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

AssignParserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AssignParserDialog;
