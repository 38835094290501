import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketTypes } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketTypes = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketTypes`,
  async ({ crmId, boardId }) => {
    const statuses = await getConnectWiseTicketTypes(crmId, boardId);
    return statuses;
  },
);

export default {};
