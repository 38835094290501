import { actionTypes } from '../actions';

const defaultState = {
  dashboardList: [],
  dashboardTilesMap: {},
  currentDashboardTiles: [],
  dashboardTilesItemMap: {},
  changedLayouts: [],
  dataViewOptions: [],
};

const dashboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_SAVE_CHANGED_LAYOUTS:
      return { ...state, changedLayouts: action.payload };
    case actionTypes.DASHBOARD_SET_TILES:
      return {
        ...state,
        currentDashboardTiles: action.payload.map(i => i.id),
        dashboardTilesMap: action.payload.reduce(
          (ac, a) => ({ ...ac, [a.id]: a }),
          {},
        ),
      };
    case actionTypes.DASHBOARD_ADD_TILE: {
      const newState = { ...state };
      newState.currentDashboardTiles = [
        ...newState.currentDashboardTiles,
        action.payload.id,
      ];
      newState.dashboardTilesMap = {
        ...newState.dashboardTilesMap,
        [action.payload.id]: action.payload,
      };
      return newState;
    }
    case actionTypes.DASHBOARD_REMOVE_TILE: {
      const newState = { ...state };
      newState.currentDashboardTiles = newState.currentDashboardTiles.filter(
        i => i !== action.payload,
      );
      delete newState.dashboardTilesMap[action.payload];
      return newState;
    }
    case actionTypes.DASHBOARD_SET_DATAVIEW_OPTIONS:
      return {
        ...state,
        dataViewOptions: action.payload,
      };
    case actionTypes.DASHBOARD_SET_OPTIONS: {
      return {
        ...state,
        dashboardList: action.payload,
      };
    }
    case actionTypes.DASHBOARD_ADD_ITEM: {
      return {
        ...state,
        dashboardTilesItemMap: {
          ...state.dashboardTilesItemMap,
          [action.payload.id]: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
