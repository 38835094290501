import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiPaper-root': {
      height: '78vh',
    },
    padding: '20px',
    boxSizing: 'border-box',
  },
  actionsContainer: {
    margin: '10px 0',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
