import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Typography } from '@material-ui/core';
import { OnChange } from 'react-final-form-listeners';

import { ThunkGetTicketInfo } from '@store/slices/crmTicket/thunks';
import {
  ThunkEditHaloTicket,
  ThunkGetHaloClientsByDefaultId,
  ThunkGetHaloSites,
  ThunkGetHaloTicketStatuses,
  ThunkGetHaloTicketTypes,
  ThunkGetHaloUsers,
} from '@store/slices/HaloTickets/thunks';

import { requiredValidator } from '@utils/validators';

import Loading from '@common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import Input from '@ui/components/Auth/Common/Input';

import useStyles from '../styles';

const EditHaloInfoForm = ({ crmDefaultId, ticketId, handleBackRedirect }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [ticketInfo, setTicketInfo] = useState(undefined);
  const [options, setOptions] = useState({
    clients: [],
    statuses: [],
    types: [],
    sites: [],
    users: [],
  });

  const handleClientChange = useCallback(
    async (clientId, formChange) => {
      setProcessing(true);
      const sites = await dispatch(
        ThunkGetHaloSites({ crmId: crmDefaultId, clientId, useNameOnly: true }),
      ).unwrap();

      setOptions({
        ...options,
        sites,
      });

      formChange('siteId', undefined);
      setProcessing(false);
    },
    [dispatch, crmDefaultId, options],
  );

  const handleSiteChange = useCallback(
    async (siteId, formChange, clientId) => {
      if (siteId) {
        const users = await dispatch(
          ThunkGetHaloUsers({
            crmId: crmDefaultId,
            clientId,
            siteId,
            useNameOnly: true,
          }),
        ).unwrap();

        setOptions({
          ...options,
          users,
        });

        formChange('userId', undefined);
        setProcessing(false);
      } else {
        formChange('userId', undefined);
      }
    },
    [dispatch, crmDefaultId, options],
  );

  const submit = useCallback(
    async values => {
      setProcessing(true);
      await dispatch(
        ThunkEditHaloTicket({ crmId: crmDefaultId, payload: values }),
      )
        .unwrap()
        .then(() => {
          handleBackRedirect();
        });
      setProcessing(false);
    },
    [dispatch, crmDefaultId, handleBackRedirect],
  );

  const validation = values => {
    return {
      summary: requiredValidator(values.summary),
      details: requiredValidator(values.details),
      clientId: requiredValidator(values.clientId),
      siteId: requiredValidator(values.siteId),
      userId: requiredValidator(values.userId),
      statusId: requiredValidator(values.statusId),
      ticketTypeId: requiredValidator(values.ticketTypeId),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    const info = await dispatch(
      ThunkGetTicketInfo({
        crmId: crmDefaultId,
        ticketId,
      }),
    ).unwrap();
    setTicketInfo(info);

    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetHaloClientsByDefaultId(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetHaloTicketStatuses(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetHaloTicketTypes(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetHaloSites({
              crmId: crmDefaultId,
              clientId: info.clientId,
              useNameOnly: true,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetHaloUsers({
              crmId: crmDefaultId,
              clientId: info.clientId,
              siteId: info.siteId,
              useNameOnly: true,
            }),
          ).unwrap(),
        ),
      ),
    ]).then(res => {
      setOptions({
        clients: res[0],
        statuses: res[1],
        types: res[2],
        sites: res[3],
        users: res[4],
      });
    });

    setLoading(false);
  }, [dispatch, crmDefaultId, ticketId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      validate={validation}
      initialValues={ticketInfo}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">Edit Ticket Information</Typography>

          <FieldWrapper
            label="Summary"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="summary"
                name="summary"
                fullWidth
                variant="outlined"
                component={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Details"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="details"
                name="details"
                fullWidth
                variant="outlined"
                component={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Client"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="clientId"
                name="clientId"
                fullWidth
                variant="outlined"
                component={AutocompleteFormInput}
                items={options.clients}
                loading={processing}
              />
            }
          />

          <OnChange name="clientId">
            {clientId => {
              handleClientChange(clientId, form.change);
            }}
          </OnChange>

          <FieldWrapper
            label="Site"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="siteId"
                name="siteId"
                fullWidth
                variant="outlined"
                component={AutocompleteFormInput}
                items={options.sites}
                loading={processing || !values.clientId}
              />
            }
          />

          <OnChange name="siteId">
            {siteId => {
              handleSiteChange(siteId, form.change, values.clientId);
            }}
          </OnChange>

          <FieldWrapper
            label="User"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="userId"
                name="userId"
                fullWidth
                variant="outlined"
                component={AutocompleteFormInput}
                items={options.users}
                loading={processing || !values.clientId || !values.siteId}
              />
            }
          />

          <FieldWrapper
            label="Status"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="statusId"
                name="statusId"
                fullWidth
                variant="outlined"
                component={AutocompleteFormInput}
                items={options.statuses}
                loading={processing}
              />
            }
          />

          <FieldWrapper
            label="Type"
            labelSize={12}
            contentSize={12}
            classNameLabelInner={classes.deleteDialogFieldLabelInner}
            classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
            classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
            content={
              <Field
                id="ticketTypeId"
                name="ticketTypeId"
                fullWidth
                variant="outlined"
                component={AutocompleteFormInput}
                items={options.types}
                loading={processing}
              />
            }
          />

          <div className={classes.deleteDialogActions}>
            <ReusableButton
              label="Cancel"
              onClick={() => {
                handleBackRedirect();
              }}
              disabled={processing}
            />
            <ReusableButton
              label="Submit"
              type="submit"
              disabled={processing}
              loading={processing}
            />
          </div>
        </form>
      )}
    />
  );
};

EditHaloInfoForm.propTypes = {
  crmDefaultId: PropTypes.string.isRequired,
  ticketId: PropTypes.string.isRequired,
  handleBackRedirect: PropTypes.func.isRequired,
};

export default EditHaloInfoForm;
