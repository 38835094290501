import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFormattedDate from '@utils/getFormattedDate';
import useStyles from '../styles';

const HistoryItem = React.memo(
  ({
    body,
    from,
    clientNumber,
    dateTimeUtc,
    mediaUrls,
    handleFullSizeOpen,
    useAltDesignForMessages = false,
  }) => {
    const classes = useStyles();

    const isClientMessage = useMemo(() => from === clientNumber, [
      clientNumber,
      from,
    ]);

    const attachments = useMemo(() => mediaUrls.filter(item => item), [
      mediaUrls,
    ]);

    const attachmentsPort = useMemo(() => {
      return attachments.map(item => (
        <div
          className={cx(classes.image, classes.imageContainer, {
            [classes.rightSideItem]: !isClientMessage,
          })}
          onClick={() => {
            handleFullSizeOpen(item);
          }}
          role="presentation"
          key={item}
        >
          <img
            src={item}
            alt="Something went wrong"
            className={classes.image}
          />
        </div>
      ));
    }, [attachments, handleFullSizeOpen, isClientMessage, classes]);

    const bodyPort = useMemo(() => {
      return (
        <div
          className={cx(classes.historyItem, {
            [classes.rightSideItem]: !isClientMessage,
          })}
        >
          {!useAltDesignForMessages && (
            <div
              className={cx(classes.historyItemHeader, {
                [classes.rightSideItemHeader]: !isClientMessage,
              })}
            >
              <div>{from}</div>
            </div>
          )}
          <div
            style={{
              wordBreak: 'break-word',
              textAlign: !isClientMessage ? 'left' : 'right',
            }}
          >
            {body}
          </div>
          <div className={classes.historyItemDate}>
            {getFormattedDate(dateTimeUtc)}
          </div>
        </div>
      );
    }, [
      body,
      dateTimeUtc,
      from,
      isClientMessage,
      mediaUrls,
      useAltDesignForMessages,
      classes,
    ]);

    return (
      <>
        {!!attachments?.length && attachmentsPort}
        {!!body && bodyPort}
      </>
    );
  },
);

HistoryItem.propTypes = {
  body: PropTypes.string,
  from: PropTypes.string,
  clientNumber: PropTypes.string,
  dateTimeUtc: PropTypes.string,
  mediaUrls: PropTypes.arrayOf(PropTypes.string),
  handleFullSizeOpen: PropTypes.func,
};

export default HistoryItem;
