import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import AttachmentMessage from '@components/ClientPortalChat/components/Attachment';
import getFormattedDate from '@utils/getFormattedDate';

import useStyles from '../styles';

const Message = ({ message, fromPodPage }) => {
  const classes = useStyles();

  const componentFooter = useMemo(() => {
    switch (message.status) {
      case 'loading':
        return (
          <>
            <CircularProgress
              style={{ width: '10px', height: '10px', color: 'white' }}
            />
          </>
        );
      default:
        return <>{getFormattedDate(message.dateTime)}</>;
    }
  }, [message.dateTime, message.status]);

  return (
    <div
      key={message.id}
      className={cx(
        !message.contactName && !message.metadata && classes.clientMessage,
        message.metadata && !message.contactName && classes.attachmentClient,
        message.metadata && message.contactName && classes.attachment,
      )}
    >
      <div className={classes.name}>
        {!message.metadata && (
          <>{message.resourceName || message.contactName}</>
        )}
      </div>
      {!message.metadata ? (
        <div>{message.message}</div>
      ) : (
        <AttachmentMessage
          resourceName={message.resourceName}
          metadata={message.metadata}
          fromPodPage={fromPodPage}
        />
      )}

      <div className={cx(classes.date, 'dateTime')}>{componentFooter}</div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    dateTime: PropTypes.string,
    metadata: PropTypes.string,
    resourceName: PropTypes.string,
    contactName: PropTypes.string,
    id: PropTypes.number,
    message: PropTypes.string,
  }),
  fromPodPage: PropTypes.bool,
};
export default Message;
