import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid } from '@material-ui/core';
import InfoBox from '@common/InfoBox/InfoBox';

import useStyles from './formElementStyles';

const FieldWrapper = ({
  label,
  content,
  isRequired,
  classNameLabelOuter,
  classNameLabelInner,
  classNameContainer,
  classNameGridContainer,
  classNameLabelContainer,
  classNameContentContainer,
  classNameContent,
  justify = 'flex-start',
  alignItems = 'flex-start',
  labelSize = 12,
  contentSize = 12,
  spacing = 1,
  infoText,
  showLabel = true,
  isCheckbox = false,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={cx(isCheckbox && classes.checkbox, classNameContainer)}
      spacing={spacing}
      justifyContent={justify}
      alignItems={alignItems}
    >
      {showLabel && (
        <Grid
          item
          xs={12}
          xl={labelSize}
          md={labelSize}
          className={cx(
            classNameGridContainer,
            classNameLabelContainer,
            classes.alignCenter,
            { [classes.disabled]: disabled },
          )}
        >
          <div className={cx(classes.fieldLabelOuter, classNameLabelOuter)}>
            <div className={cx(classes.fieldLabelInner, classNameLabelInner)}>
              {label}
              {isRequired ? ' *' : null}
              {infoText && <InfoBox text={infoText} />}
            </div>
          </div>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        xl={contentSize}
        md={contentSize}
        className={cx(classNameGridContainer, classNameContentContainer)}
      >
        <div style={{ textAlign: 'left' }} className={cx(classNameContent)}>
          {content}
        </div>
      </Grid>
    </Grid>
  );
};

FieldWrapper.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isRequired: PropTypes.bool,
  content: PropTypes.element,
  labelSize: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  contentSize: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  classNameLabelInner: PropTypes.string,
  classNameLabelOuter: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameGridContainer: PropTypes.string,
  classNameLabelContainer: PropTypes.string,
  classNameContentContainer: PropTypes.string,
  classNameContent: PropTypes.string,
  justify: PropTypes.string,
  alignItems: PropTypes.string,
  spacing: PropTypes.number,
  infoText: PropTypes.string,
  showLabel: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FieldWrapper;
