import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getVerificationSettingsEmailSubjectVariables } from '@services/requests/verificationSettings';

export const ThunkGetVerificationSettingsEmailSubjectVariables = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/emailSubjectVariables`,
  async () => {
    const res = await getVerificationSettingsEmailSubjectVariables();
    return res;
  },
);

export default {};
