import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    marginTop: '0.75rem',
    justifyContent: 'flex-end',
  },
  button: {
    '&:last-child': {
      marginLeft: '1rem',
    },
  },
  operator: {
    width: '110px',
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '0.5rem',
  },
  label: {
    minWidth: '100px',
    textAlign: 'right',
    marginRight: '0.5rem',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  operatorWrapper: {
    width: '110px',
  },
  inputWrapper: {
    width: '450px',
  },
  spaceDiv: {
    width: '10px',
  },
}));

export default useStyles;
