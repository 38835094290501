import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getSophosTenantEndpoints,
  getIntegrationOrganizations,
} from '@components/DataSources/helpers';
import { integrationSources } from '@constants/integrationSources';
import CircularStatic from '@components/DataSources/Dashboards/CircularProgress';
import SophosGrid from '@components/DataSources/Dashboards/Sophos/endpoints/SophosGrid';
import statusColors from '@components/DataSources/Dashboards/statusColors';
import BagelsContainer from '@components/DataSources/Dashboards/Statistic/BagelsContainer';

const bagelItems = {
  good: { title: 'GOOD', color: statusColors.online, total: 0, count: 0 },
  suspicious: {
    title: 'SUSPICIOUS',
    color: statusColors.offline,
  },
  bad: {
    title: 'BAD',
    color: statusColors.alerting,
  },
  unknown: {
    title: 'UNKNOWN',
    color: statusColors.dormant,
  },
};

const bagelsField = 'overall';

const transformData = (data, integrationId, tenantId, apiHost) =>
  data.map(i => {
    return {
      id: i.id,
      type: i.type,
      hostname: i.hostname,
      overall: i.health.overall,
      threats: i.health.threats.status,
      services: i.health.services.status,
      tenantName: i.tenant.name,
      integrationId,
      tenantId,
      apiHost,
      data: i,
    };
  });

const SophosEndpointsDashboard = ({ loading, setLoading }) => {
  const [data, setData] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const [statusFilter, setStatusFilter] = useState(null);

  const adjustProgress = (current, total) => {
    setProgress((100 * current) / total);
  };

  useEffect(() => {
    getIntegrationOrganizations(integrationSources.Sophos.name)
      .then(i => {
        let dt = [];
        for (let s = 0, p = Promise.resolve(); s <= i.length; s += 1) {
          p = p
            .then(() =>
              s !== i.length
                ? getSophosTenantEndpoints(
                    i[s].integrationId,
                    i[s].organizationId,
                    i[s].apiHost,
                    true,
                  )
                : Promise.resolve(null),
            )
            // eslint-disable-next-line no-loop-func
            .then(rsp => {
              if (rsp === null) {
                setData(dt);
                setLoading(false);
                return;
              }
              dt = dt.concat(
                transformData(
                  rsp,
                  i[s].integrationId,
                  i[s].organizationId,
                  i[s].apiHost,
                ),
              );
            })
            .catch(e => console.log(e.message))
            .finally(() => adjustProgress(s + 1, i.length));
        }
      })
      .catch(e => console.log(e.message));
  }, []);

  const filteredData = () => {
    if (!statusFilter) return data;
    return data.filter(
      item => item[bagelsField] === statusFilter.toLowerCase(),
    );
  };

  return !loading ? (
    <div>
      <BagelsContainer
        data={data}
        field={bagelsField}
        setStatusFilter={setStatusFilter}
        items={bagelItems}
      />
      <SophosGrid data={filteredData()} />
    </div>
  ) : (
    <CircularStatic progress={progress} />
  );
};

SophosEndpointsDashboard.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default SophosEndpointsDashboard;
