import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { updateUser } from '@services/requests/user';

// eslint-disable-next-line import/prefer-default-export
export const ThunkUpdateUser = createAsyncThunk(
  `${USER}/updateUser`,
  async ({ id, payload }) => {
    return updateUser(id, payload);
  },
);
