import React, { useCallback, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { RateReview as RateReviewIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Badge, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { PUSHER_CHANNELS, PUSHER_EVENTS } from '@constants/pusherEvents';
import { LIVE_CHAT_DETAILS } from '@constants/routes';
import { currentUserDataSelector } from '@store/selectors';

import useStyles from './styles';

const LiveChatNotifications = ({ liveChatPusher }) => {
  const [notifications, setNotification] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState(undefined);

  const open = Boolean(anchorEl);
  const currentUser = useSelector(currentUserDataSelector);
  const classes = useStyles({
    hasNotifications: notifications?.length > 0,
  });
  const { push } = useHistory();

  const redirectToLiveChat = chatId => {
    setNotification(notifications.filter(i => +i.chatId !== +chatId));
    push(LIVE_CHAT_DETAILS.replace(':id', chatId));
  };

  const handleClick = event => {
    if (notifications.length) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setNotification([]);
  };

  const handleAddNotifications = useCallback(
    data => {
      if (!notifications.find(i => i.chatId === data.chatId)) {
        setNotification([
          ...notifications,
          {
            chatId: data.chatId,
            message: `Message from ${data.userName}`,
          },
        ]);
      }
    },
    [notifications],
  );

  const handleAddChats = useCallback(
    data => {
      if (!notifications.find(i => i.chatId === data.chatId)) {
        setNotification([
          ...notifications,
          {
            chatId: data.chatId,
            message: `New chat with ${data.userName}`,
          },
        ]);
      }
    },
    [notifications],
  );

  useEffect(() => {
    if (message) {
      if (message?.type === 'msg') {
        handleAddNotifications(message);
      }
      if (message?.type === 'chat') {
        handleAddChats(message);
      }
    }
  }, [message, handleAddNotifications, handleAddChats]);

  // * LIVE CHAT PUSHER
  useEffect(() => {
    const chatsPusher = new Pusher(liveChatPusher.key, {
      cluster: liveChatPusher.cluster,
    });

    const channel = chatsPusher.subscribe(
      PUSHER_CHANNELS.liveChatNotificationChannel.replace(
        ':userId',
        currentUser.id,
      ),
    );

    channel.bind(PUSHER_EVENTS.liveChatNewUserMessage, data => {
      setMessage({ ...data, type: 'msg' });
    });

    channel.bind(PUSHER_EVENTS.liveChatNewChat, data => {
      setMessage({ ...data, type: 'chat' });
    });

    return () => {
      channel.unbind_all();
      channel.disconnect(
        PUSHER_CHANNELS.liveChatNotificationChannel.replace(
          ':userId',
          currentUser.id,
        ),
      );
      chatsPusher.unsubscribe();
      chatsPusher.disconnect();
    };
  }, []);

  return (
    <div>
      <Tooltip title="Live Chat Notifications" className={classes.container}>
        <Badge
          overlap="rectangular"
          color="primary"
          onClick={handleClick}
          badgeContent={notifications.length}
        >
          <RateReviewIcon />
        </Badge>
      </Tooltip>
      <span />

      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <div style={{ width: '300px', height: '150px', overflowY: 'auto' }}>
          {notifications.map(n => (
            <MenuItem
              key={n.chatId}
              onClick={() => {
                redirectToLiveChat(n.chatId);
              }}
            >
              {n.message}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
};

LiveChatNotifications.propTypes = {
  liveChatPusher: PropTypes.shape({
    key: PropTypes.string,
    cluster: PropTypes.string,
  }),
};

export default LiveChatNotifications;
