import { createAsyncThunk } from '@reduxjs/toolkit';
import { TICKET_CREATION_TEMPLATES } from '@store/slices/resources';
import { getTicketCreationTemplate } from '@services/requests/ticketCreationTemplates';

export const ThunkGetTicketCreationTemplate = createAsyncThunk(
  `${TICKET_CREATION_TEMPLATES}/getTicketCreationTemplate`,
  ({ templateId }) => getTicketCreationTemplate(templateId),
);

export default {};
