export const regionSelectionEvents = Object.freeze({
  Selection: 'userCustomSelection',
  ClearSelection: 'userClearCustomSelection',
});

export const socketMessageEvents = Object.freeze({
  SocketMessageReceived: 'SocketMessageReceived',
});

export const fieldTransformersSelectionEvents = Object.freeze({
  SelectAll: 'selectAllTransformers',
});

export const verificationEvents = Object.freeze({
  VerificationCompleted: 'verificationCompleted',
});

export const SignalREvents = {
  TenantSubscription: 'TenantSubscription',
  ContactVerification: 'ContactVerificationPassed',
  MessageChannelSms: 'MessagingSmsToChannel',
  PsaTechVerification: 'PsaTechVerificationSmsRequest',
};
