import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { checkCodeRequest } from '@services/requests/psaPods';

export const ThunkCheckCode = createAsyncThunk(
  `${CONNECTWISE_POD}/checkCode`,
  async ({ crmId, code }) => {
    const res = await checkCodeRequest({
      crmId,
      code,
    });
    return res;
  },
);

export default {};
