import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { assignKaseyaTicketContact } from '@services/requests/kaseyaTicket';

export const ThunkAssignKaseyaTicketContact = createAsyncThunk(
  `${KASEYA_TICKET}/assignKaseyaTicketContact`,
  ({ crmDefaultId, ticketId, contactId }) =>
    assignKaseyaTicketContact(crmDefaultId, ticketId, contactId),
);

export default {};
