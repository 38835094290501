import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EllipsisTooltip from '@common/EllipsisTooltip';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import DialogParsedObject from '@components/GenericSources/pages/GenericSourcesLogs/components/DialogParsedObject';

import DocumentsIcon from '@assets/icons/documents.svg';
import DocumentBlueIcom from '@assets/icons/document_blue.svg';
import getFormattedDate from '@utils/getFormattedDate';

import useStyles from '../styles';

const GenericSourcesLogsDetailsItem = ({ log }) => {
  const [openBodyDialog, setOpenBodyDialog] = useState(false);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);

  const classes = useStyles({ error: !log.isSuccess });

  return (
    <div className={classes.detailsItems}>
      <div>{getFormattedDate(log.dateTime)}</div>
      <div className={classes.item}>
        <div className={classes.flex}>
          <div>
            <b>{log.type}</b>
          </div>

          {log.url && <EllipsisTooltip text={log.url} />}

          {!log.type && !log.url && !log.body && !log.response && (
            <EllipsisTooltip text={log.message} />
          )}

          <div style={{ marginLeft: 'auto' }}>
            {log.body && (
              <ActionFieldItem
                handler={() => {
                  setOpenBodyDialog(true);
                }}
                toolTip="Body"
                icon={DocumentBlueIcom}
              />
            )}
            {log.response && (
              <ActionFieldItem
                handler={() => {
                  setOpenResponseDialog(true);
                }}
                toolTip="Response"
                icon={DocumentsIcon}
              />
            )}
          </div>
        </div>
      </div>

      {openBodyDialog && (
        <DialogParsedObject
          object={log.body}
          onClose={() => {
            setOpenBodyDialog(false);
          }}
        />
      )}

      {openResponseDialog && (
        <DialogParsedObject
          object={log.response}
          onClose={() => {
            setOpenResponseDialog(false);
          }}
        />
      )}
    </div>
  );
};

GenericSourcesLogsDetailsItem.propTypes = {
  log: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    body: PropTypes.string,
    response: PropTypes.string,
    message: PropTypes.string,
    dateTime: PropTypes.string,
    isSuccess: PropTypes.bool,
  }).isRequired,
};

export default GenericSourcesLogsDetailsItem;
