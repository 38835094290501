import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_INTEGRATIONS } from '@store/slices/resources';
import { addIntegration } from '@services/requests/securityIntegrations';

export const ThunkAddSecurityIntegration = createAsyncThunk(
  `${SECURITY_INTEGRATIONS}/addIntegration`,
  async id => {
    const data = await addIntegration(id);
    return data;
  },
);

export default {};
