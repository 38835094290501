import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    border: '0.0625rem solid #E2E2E2',
    borderRadius: '0.3125rem',
    '& input': {
      fontSize: '0.875rem',
    },
  },
  multipleField: {
    '& .MuiInputBase-inputAdornedStart': {
      height: '0',
      padding: '0 !important',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  emptyItem: {
    height: '1.5rem',
  },
  label: {
    color: theme.palette.info.main,
    transform: 'translate(24px, 8px) scale(1)',
  },
  rootSelect: {
    textAlign: 'left',
    fontSize: '0.875rem',
    padding: '0.5rem 0.9375rem',
    display: 'flex',
  },
  fieldWrapper: {
    width: '100%',
    position: 'relative',
    '& > div': {
      border: 'none',
    },
    '& .Mui-error': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.error.main,
      },
    },
  },
  icon: {
    paddingRight: theme.spacing(1),
    display: 'flex',
  },
  helperText: {
    position: 'absolute',
    margin: theme.spacing(0, 1.5, 0),
  },
  loadWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  img: {
    height: '25px',
    width: '25px',
  },
  chipLabel: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

export default useStyles;
