import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    flexGrow: 1,
  },
  tabsWrapper: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingTop: '1.75rem',
    marginTop: 0,
    borderBottom: '0.0625rem solid #D9D5D5',
    display: 'flex',
  },
  margin: {
    margin: '1rem',
  },
}));

export default useStyles;
