import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-1.25rem',
      marginLeft: '14px',
      marginRight: '14px',
    },
  },
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },
  helperInfoText: {
    position: 'absolute',
    bottom: '-20px',
    left: '14px',
  },
}));

export default useStyles;
