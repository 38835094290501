import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSuperOpsIntegrationStatuses } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetSuperOpsIntegrationStatuses = createAsyncThunk(
  `${INTEGRATION}/getSuperOpsIntegrationStatuses`,
  async ({ crmId }) => {
    const statuses = await getSuperOpsIntegrationStatuses(crmId);
    return statuses;
  },
);

export default {};
