import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import appConfig from '@configs/appConfig';

const TelemetryProvider = ({ children }) => {
  const browserHistory = createBrowserHistory({ basename: '' });
  const reactPlugin = new ReactPlugin();

  useEffect(() => {
    if (appConfig.appInsights) {
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appConfig.appInsights,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
          enableAutoRouteTracking: true,
        },
      });

      appInsights.loadAppInsights();
    }
  }, [appConfig.appInsights]);

  return <>{children}</>;
};

TelemetryProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TelemetryProvider;
