import React from 'react';
import { useSafeSetState } from 'react-admin';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, Form } from 'react-final-form';
import { Typography } from '@material-ui/core';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import { createBox } from '../../helpers';
import { checkMailBoxName } from '../../../MailBox/helpers';

import useStyles from './styles';

const CreateMailBoxCard = ({ onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useSafeSetState(false);

  const validate = async values => {
    const name = values.name ? values.name : ' ';
    const errors = {
      name: undefined,
    };

    const checkName = await checkMailBoxName(name);
    errors.name = checkName.data !== 'Allow' ? checkName.data : undefined;

    if (!/^([A-Za-z0-9_]*)$/.test(name)) {
      errors.name = 'Name contains incorrect symbols';
    }

    if (!values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  const submit = async value => {
    setLoading(true);
    if (await createBox(value)) {
      onSubmit();
    } else {
      enqueueSnackbar('Error during mail box creation', { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <div
      className={cx(classes.container, classes.zeroPadding)}
      role="presentation"
    >
      <Typography className={classes.createTitle}>
        Create new mailbox
      </Typography>
      <Typography className={classes.createDesc}>
        Enter name for your mailbox
      </Typography>
      <div className={classes.createCardBody}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit, form }) => (
            <form
              onSubmit={async event => {
                await handleSubmit(event);
                form.restart();
              }}
              noValidate
              className={classes.createForm}
            >
              <div>
                <Field
                  id="name"
                  name="name"
                  inputView="text"
                  styleType="main"
                  component={Input}
                  placeholder="Mailbox name"
                  disabled={loading}
                  fullWidth
                />
              </div>
              <ReusableButton
                size="md"
                viewType="orange"
                type="submit"
                classNameWrapper={classes.button}
                disabled={loading}
                loading={loading}
                label="Create"
              />
            </form>
          )}
        />
      </div>
    </div>
  );
};

CreateMailBoxCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CreateMailBoxCard;
