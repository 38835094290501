import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import cx from 'classnames';

import { selectionSource } from '@constants/selectionSource';
import { defaultDateFormat } from '@constants/formats';
import InfoSpan from './InfoSpan';
import useStyles from './styles';

const getPrettyDate = date => moment(date).format(defaultDateFormat);

const Metadata = ({
  content,
  onMouseOver,
  onMouseDown,
  onMouseUp,
  onDateMouseUp,
  key,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment key={key}>
      <Grid
        item
        classes={{ root: cx(classes.metaDataGridRoot, classes.marginTop) }}
        xs={12}
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <InfoSpan
          className={selectionSource.FromName}
          onMouseUp={() => onMouseUp('')}
          onMouseOver={onMouseOver}
          onMouseDown={onMouseDown}
          text={content.fromName}
          name="From Name"
          copyable
        />
      </Grid>
      <Grid
        item
        classes={{ root: cx(classes.metaDataGridRoot, classes.marginTop) }}
        xs={12}
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <InfoSpan
          className={selectionSource.From}
          onMouseUp={() => onMouseUp('')}
          onMouseOver={onMouseOver}
          onMouseDown={onMouseDown}
          text={content.from}
          name="From"
          copyable
        />
      </Grid>
      <Grid
        item
        classes={{ root: cx(classes.metaDataGridRoot, classes.marginTop) }}
        xs={12}
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <InfoSpan
          className={selectionSource.To}
          onMouseUp={() => onMouseUp('')}
          onMouseOver={onMouseOver}
          onMouseDown={onMouseDown}
          text={content.to}
          name="To"
          copyable
        />
      </Grid>
      <Grid
        item
        classes={{ root: cx(classes.metaDataGridRoot, classes.marginTop) }}
        xs={12}
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <InfoSpan
          className={selectionSource.ReceivedAt}
          onMouseUp={onDateMouseUp}
          onMouseOver={onMouseOver}
          onMouseDown={onMouseDown}
          text={getPrettyDate(content.date)}
          name="Received"
        />
      </Grid>
      <Grid
        item
        classes={{ root: cx(classes.metaDataGridRoot, classes.marginTop) }}
        xs={12}
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <InfoSpan
          className={selectionSource.Subject}
          onMouseUp={() => onMouseUp('')}
          onMouseOver={onMouseOver}
          onMouseDown={onMouseDown}
          text={content.subject}
          name="Subject"
          copyable
        />
      </Grid>
    </React.Fragment>
  );
};

Metadata.propTypes = {
  content: PropTypes.objectOf(PropTypes.any),
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onDateMouseUp: PropTypes.func,
  key: PropTypes.number,
};

export default Metadata;
