import { createAsyncThunk } from '@reduxjs/toolkit';
import { hetHaloTicketTypes } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetHaloTicketTypes = createAsyncThunk(
  `${INTEGRATION}/halo/getTicketTypes`,
  async id => {
    const res = await hetHaloTicketTypes(id);
    return res;
  },
);

export default {};
