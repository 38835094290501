import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'stretch',
    padding: '0',
    fontStyle: 'normal',
  },
  rootSelect: {
    textAlign: 'left',
    fontSize: '0.875rem',
    padding: '0.5rem 0.9375rem',
  },

  formControl: {
    width: '100%',
    border: '0.0625rem solid #E2E2E2',
    borderRadius: '0.125rem',

    '& fieldset': {
      display: 'none',
    },
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    width: '100%',
    margin: '10px 0',
  },
  label: {
    minWidth: '200px',
    textAlign: 'right',
    paddingRight: '10px',
    alignSelf: 'center',
  },
  info: {
    alignSelf: 'center',
  },
  component: {
    width: '100%',
  },
  link: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
