import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Typography } from '@material-ui/core';
import { OnChange } from 'react-final-form-listeners';

import {
  ThunkGetConnectWiseTicketBoards,
  ThunkGetConnectWiseTicketCompanies,
  ThunkGetConnectWiseTicketContacts,
  ThunkGetConnectWiseTicketImpacts,
  ThunkGetConnectWiseTicketMembers,
  ThunkGetConnectWiseTicketPriorities,
  ThunkGetConnectWiseTicketSeverities,
  ThunkGetConnectWiseTicketSites,
  ThunkGetConnectWiseTicketSources,
  ThunkGetConnectWiseTicketStatuses,
  ThunkGetConnectWiseTicketTeams,
  ThunkGetConnectWiseTicketTypes,
} from '@store/slices/connectWiseTicket/thunks';
import {
  ThunkGetTicketInfo,
  ThunkUpdateConnectWiseTicket,
} from '@store/slices/crmTicket/thunks';
import { requiredValidator } from '@utils/validators';

import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import DropDown from '@ui/components/Auth/Common/DropDown';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import Input from '@ui/components/Auth/Common/Input';
import Switch from '@common/FilterInputs/Switch';
import TicketNotes from '@components/CrmTicket/Notes/TicketNotes';
import crmSources from '@constants/crmSources';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import TimeEntriesTimer from '../../components/TimeEntriesTimer';

import useStyles from '../styles';

const EditConnectWiseInfoForm = ({
  crmDefaultId,
  ticketId,
  handleBackRedirect,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  // Form Options
  const [ticketInfo, setTicketInfo] = useState(undefined);
  const [companies, setCompanies] = useState([]);
  const [boards, setBoards] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [sources, setSources] = useState([]);
  const [teams, setTeams] = useState([]);
  const [sites, setSites] = useState([]);
  const [impacts, setImpacts] = useState([]);
  const [severities, setSeverities] = useState([]);
  const [owners, setOwners] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [items, setItems] = useState([]);

  const handleCompanyChange = async (value, form) => {
    setProcessing(true);

    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketTeams({
              crmId: crmDefaultId,
              companyId: value,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketSites({
              crmId: crmDefaultId,
              companyId: value,
            }),
          ).unwrap(),
        ),
      ),
    ]).then(res => {
      setTeams(res[0]);
      setSites(res[1]);
    });

    form.change('teamId', '');
    form.change('siteId', '');

    setProcessing(false);
  };

  const handleBoardChange = async (value, form) => {
    setProcessing(true);

    await dispatch(
      ThunkGetConnectWiseTicketStatuses({
        crmId: crmDefaultId,
        boardId: value,
      }),
    )
      .unwrap()
      .then(res => {
        setStatuses(res);
      });

    await dispatch(
      ThunkGetConnectWiseTicketTypes({
        crmId: crmDefaultId,
        boardId: value,
      }),
    )
      .unwrap()
      .then(res => {
        setTypes(res);
      });

    form.change('statusId', '');
    form.change('typeId', '');
    setProcessing(false);
  };

  const handleTypeChange = (value, form) => {
    form.change('subTypeId', '');
    form.change('itemId', '');
    setSubTypes(types.find(i => +i.id === +value)?.boardSubTypes || []);
  };

  const handleSubTypeChange = (value, form) => {
    form.change('itemId', '');
    setItems(subTypes.find(i => +i.id === +value)?.items || []);
  };

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      ThunkUpdateConnectWiseTicket({
        crmId: crmDefaultId,
        payload: values,
      }),
    )
      .unwrap()
      .then(() => {
        handleBackRedirect();
      })
      .finally(() => setProcessing(false));
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    let info;

    await dispatch(
      ThunkGetTicketInfo({
        crmId: crmDefaultId,
        ticketId,
      }),
    )
      .unwrap()
      .then(res => {
        setTicketInfo(res);
        info = res;
      });

    await Promise.all([
      new Promise(res =>
        res(
          dispatch(ThunkGetConnectWiseTicketCompanies(crmDefaultId)).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketBoards(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketStatuses({
              crmId: crmDefaultId,
              boardId: info.boardId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetConnectWiseTicketPriorities(crmDefaultId)).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketSources(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketTeams({
              crmId: crmDefaultId,
              companyId: info.companyId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketSites({
              crmId: crmDefaultId,
              companyId: info.companyId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketImpacts(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetConnectWiseTicketSeverities(crmDefaultId)).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketContacts({ crmId: crmDefaultId }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketMembers(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketTypes({
              crmId: crmDefaultId,
              boardId: info.boardId,
            }),
          ).unwrap(),
        ),
      ),
    ]).then(res => {
      setCompanies(res[0]);
      setBoards(res[1]);
      setStatuses(res[2]);
      setPriorities(res[3]);
      setSources(res[4]);
      setTeams(res[5]);
      setSites(res[6]);
      setImpacts(res[7]);
      setSeverities(res[8]);
      setContacts(res[9]);
      setOwners(res[10]);
      setTypes(res[11]);
      if (info.typeId) {
        const subtypesAutocomplete = res[11].find(i => +i.id === +info.typeId)
          .boardSubTypes;
        setSubTypes(subtypesAutocomplete);
        if (info.subTypeId) {
          setItems(
            subtypesAutocomplete.find(i => +i.id === +info.subTypeId)?.items ||
              [],
          );
        }
      }
    });

    setLoading(false);
  }, [dispatch, crmDefaultId, ticketId]);

  const validate = values => {
    return {
      boardId: requiredValidator(values.boardId),
      statusId: requiredValidator(values.statusId),
    };
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.withNotesContainer}>
      <Form
        onSubmit={submit}
        initialValues={ticketInfo}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h5">Edit Ticket Information</Typography>
            <div className={classes.twoColumns}>
              <FieldWrapper
                label="Summary"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                spacing={0}
                content={
                  <Field
                    id="summary"
                    name="summary"
                    fullWidth
                    variant="outlined"
                    component={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Company"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="companyId"
                    name="companyId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={companies}
                    loading={processing}
                  />
                }
              />
              <OnChange name="companyId">
                {value => handleCompanyChange(value, form)}
              </OnChange>

              <FieldWrapper
                label="Board"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="boardId"
                    name="boardId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={boards}
                    loading={processing}
                  />
                }
              />

              <OnChange name="boardId">
                {value => handleBoardChange(value, form)}
              </OnChange>

              <FieldWrapper
                label="Status"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="statusId"
                    name="statusId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={statuses}
                    disabled={processing || !values.boardId}
                  />
                }
              />

              <FieldWrapper
                label="Team"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="teamId"
                    name="teamId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={teams}
                    loading={processing || !values.companyId}
                  />
                }
              />

              <FieldWrapper
                label="Site"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="siteId"
                    name="siteId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={sites}
                    loading={processing || !values.companyId}
                  />
                }
              />

              <FieldWrapper
                label="Priority"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="priorityId"
                    name="priorityId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={priorities}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Source"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="sourceId"
                    name="sourceId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={sources}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Impact"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="impact"
                    name="impact"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={impacts}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Severity"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="severity"
                    name="severity"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={severities}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Owner"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="ownerId"
                    name="ownerId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={owners}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Contact"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="contactId"
                    name="contactId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={contacts}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Automatic Email to Contact"
                labelSize={8}
                contentSize={4}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameContentContainer={classes.checkBoxAlignment}
                content={
                  <Field
                    id="automaticEmailContactFlag"
                    name="automaticEmailContactFlag"
                    component={Switch}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Automatic Email to Resource"
                labelSize={8}
                contentSize={4}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameContentContainer={classes.checkBoxAlignment}
                content={
                  <Field
                    id="automaticEmailResourceFlag"
                    name="automaticEmailResourceFlag"
                    component={Switch}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Automatic CC"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <InputWithAutocomplete
                    name="automaticEmailCcArray"
                    options={[]}
                    disabled={processing}
                    disableCloseOnSelect
                    freeSolo
                    getOptionValue={i => (typeof i === 'object' ? i.value : i)}
                    getOptionLabel={i => (typeof i === 'object' ? i.label : i)}
                  />
                }
              />

              <FieldWrapper
                label="Type"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="typeId"
                    name="typeId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={types}
                    disabled={processing}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                  />
                }
              />

              <OnChange name="typeId">
                {value => handleTypeChange(value, form)}
              </OnChange>

              <FieldWrapper
                label="Subtype"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="subTypeId"
                    name="subTypeId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={subTypes}
                    disabled={processing}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                  />
                }
              />

              <OnChange name="subTypeId">
                {value => handleSubTypeChange(value, form)}
              </OnChange>

              <FieldWrapper
                label="Item"
                labelSize={3}
                contentSize={9}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                content={
                  <Field
                    id="itemId"
                    name="itemId"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={items}
                    disabled={processing}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                  />
                }
              />
            </div>

            <div className={classes.deleteDialogActions}>
              <ReusableButton
                label="Cancel"
                onClick={() => {
                  handleBackRedirect();
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
                loading={processing}
              />
            </div>
            <div>
              <TimeEntriesTimer />
            </div>
          </form>
        )}
      />
      <TicketNotes psaType={crmSources.ConnectWise.name} />
    </div>
  );
};

EditConnectWiseInfoForm.propTypes = {
  crmDefaultId: PropTypes.string.isRequired,
  ticketId: PropTypes.string.isRequired,
  handleBackRedirect: PropTypes.func.isRequired,
};

export default EditConnectWiseInfoForm;
