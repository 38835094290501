import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConversationTemplate } from '@services/requests/messaging';
import { SMS_CONVERSATIONS } from '@store/slices/resources';

export const ThunkAddConversationTemplate = createAsyncThunk(
  `${SMS_CONVERSATIONS}/addMessagingChannel`,
  async payload => {
    const res = await addConversationTemplate(payload);
    return res;
  },
);

export default {};
