import { createSlice } from '@reduxjs/toolkit';
import { ISSUE_TYPES } from '@store/slices/resources';
import {
  ThunkDeleteIssueType,
  ThunkGetIssueTypes,
} from '@store/slices/issueTypes/thunks';

const initialState = {
  issueTypes: [],
};

const surveysSlice = createSlice({
  name: ISSUE_TYPES,
  reducers: {
    pushIssueTypeToList: (state, action) => {
      state.issueTypes = [...state.issueTypes, action.payload];
    },
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(ThunkGetIssueTypes.fulfilled, (state, { payload }) => {
      state.issueTypes = payload;
    });

    builder.addCase(ThunkDeleteIssueType.fulfilled, (state, action) => {
      state.issueTypes = state.issueTypes.filter(i => i.id !== action.payload);
    });
  },
});

export default surveysSlice.reducer;

export const IssueTypesSelector = state => state[ISSUE_TYPES].issueTypes;

export const { pushIssueTypeToList } = surveysSlice.actions;
