import { getHeaders } from '@utils/transfered';
import localStorage from '@services/localStorage';

const getData = async url => {
  const request = new Request(url, {
    method: 'GET',
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('accessToken');
    window.location.href = '#/login';
    return;
  }
  if (response.status < 200 || response.status >= 300) {
    const errorData = await response.json();
    throw errorData || response.statusText;
  }

  if (response.status === 204) {
    return null;
  }

  return response.json();
};

export default getData;
