import deviceMonitorTypes, { ports } from '@constants/deviceMonitorTypes';
import compose from '@components/Helpers/compose';

const format = n => `${(n / 60) ^ 0} hrs, ${n % 60} mins`;

export const custom = 'custom';

export const formattedData = data => {
  const { port, customPort, ...rest } = data;
  const isTypePort = data.type === deviceMonitorTypes.TcpPing.id;
  if (isTypePort) {
    const index = rest.url.indexOf(':');
    if (index !== -1) rest.url = rest.url.slice(0, index);
    const newPort = port === custom ? customPort : port;
    rest.url = `${rest.url}:${newPort}`;
  }

  return { ...rest, url: rest.url };
};

const onFormattedLoadData = ({ data, index }) => {
  const port = data.url.slice(index + 1);
  const isPopularPort = ports.some(p => p.value === port);
  const isTypePort = data.type === deviceMonitorTypes.TcpPing.value;

  if (isTypePort) {
    return {
      ...data,
      url: data.url.slice(0, index),
      ...(!isPopularPort && isTypePort
        ? {
            port: custom,
            customPort: port,
          }
        : {
            port: data.url.slice(index + 1),
          }),
    };
  }
  return data;
};

const onFindIndex = data => {
  const index = data.url.indexOf(':');
  return { data, index };
};

export const loadData = data => {
  const formattedLoadData =
    data && compose(onFormattedLoadData, onFindIndex)(data);

  return {
    id: formattedLoadData?.id || 0,
    deviceName: formattedLoadData?.deviceName || null,
    company: formattedLoadData?.company || null,
    site: formattedLoadData?.site || null,
    type:
      (formattedLoadData?.type !== undefined &&
        String(deviceMonitorTypes[formattedLoadData.type]?.id)) ||
      null,
    url: formattedLoadData?.url || null,
    port: formattedLoadData?.port || null,
    customPort: formattedLoadData?.customPort || null,
    period: formattedLoadData?.period || 5,
    enabled: formattedLoadData?.enabled || true,
    useSecondVm: formattedLoadData?.useSecondVm || false,
    grpcServer: formattedLoadData?.grpcServer || 0,
  };
};

export default format;
