import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  detailsLayout: {
    display: 'flex',
    padding: '0',
  },
  expanded: {
    '&.MuiAccordionSummary-root': {
      borderRadius: '5px 5px 0 0',
      backgroundImage: 'linear-gradient(180deg, #dfdfdf, transparent)',
    },
  },
}));

export default useStyles;
