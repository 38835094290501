import React, { useState } from 'react';
import Loading from '@common/Loading/Loading';

import useAsync from '@services/api/common/useAsync';
import UserForm from './components/newLayout/UserForm';
import { getRolesOptions } from './helpers';

const CreateUser = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const rolesLoading = useAsync(getRolesOptions, setRoleOptions);
  return rolesLoading ? <Loading /> : <UserForm rolesOptions={roleOptions} />;
};

export default CreateUser;
