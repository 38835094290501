import React from 'react';
import PropTypes from 'prop-types';
import DetailsWrapper from '@components/DeviceMonitors/components/Details/DetailsWrapper';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { lightTheme } from '@services/themes/mainTheme';
import ReusableButton from '@common/Button/Button';
import { useRedirect } from 'react-admin';
import { DEVICE_MONITORS } from '@constants/routes';
import useStyles from './styles';

const DetailsTVMode = ({ match }) => {
  const {
    params: { id },
  } = match;
  const theme = createTheme(lightTheme());
  const redirect = useRedirect();

  const classes = useStyles();

  const handleTvMode = () => redirect(`${DEVICE_MONITORS}/${id}/details`);

  return (
    <ThemeProvider theme={theme}>
      <DetailsWrapper id={id}>
        <div className={classes.tvModeBtnContainer}>
          <ReusableButton
            viewType="black"
            type="button"
            classNameWrapper={classes.tvModeBtn}
            size="xl"
            onClick={handleTvMode}
            label="Exit TV Mode"
          />
        </div>
      </DetailsWrapper>
    </ThemeProvider>
  );
};

DetailsTVMode.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default DetailsTVMode;
