import { createAsyncThunk } from '@reduxjs/toolkit';
import { addChat } from '@services/requests/chats';
import { CHATS } from '@store/slices/resources';

export const ThunkAddChat = createAsyncThunk(
  `${CHATS}/addChat`,
  async payload => {
    const res = addChat(payload);
    return res;
  },
);

export default {};
