/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
  Stop as StopIcon,
} from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  ThunkGetTimer,
  ThunkStartTimer,
  ThunkStopTimer,
} from '@store/slices/ticketTimer/thunks';

import ActionButton from '@ui/components/common/buttons/ActionButton/ActionButton';
import AddCWTicketTimeEntryDialog from '../dialogs/AddCWTimeEntry';

const TimeEntriesTimer = () => {
  const [timerStarted, setTimerStarted] = useState(false);
  const [time, setTime] = useState('00:00:00');
  const [timer, setTimer] = useState(null);
  const [startEndTime, setStartEndTime] = useState(null);
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const { crmDefaultId, ticketId } = useParams();
  const dispatch = useDispatch();

  const startTimer = useCallback(
    (initialHours = 0, initialMinutes = 0, initialSeconds = 0) => {
      let hours = initialHours;
      let minutes = initialMinutes;
      let sec = initialSeconds;

      const timerId = setInterval(() => {
        if (minutes === 60) {
          hours++;
          minutes = 0;
        }
        if (sec === 60) {
          minutes++;
          sec = 0;
        }

        sec++;
        const visibleHours = `${
          String(hours).length > 1 ? hours : `0${hours}`
        }`;
        const visibleMinutes = `${
          String(minutes).length > 1 ? minutes : `0${minutes}`
        }`;
        const visibleSeconds = `${String(sec).length > 1 ? sec : `0${sec}`}`;
        setTime(`${visibleHours}:${visibleMinutes}:${visibleSeconds}`);
      }, 1000);
      setTimer(timerId);
    },
    [],
  );

  const stopTimer = useCallback(() => {
    clearInterval(timer);
  }, [timer]);

  const getInitials = useCallback(
    startTime => {
      let seconds = Math.floor(
        moment(new Date()).diff(moment.utc(startTime)) / 1000,
      );
      let minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      seconds %= 60;
      minutes %= 60;

      startTimer(hours, minutes, seconds);
    },
    [startTimer],
  );

  const handleStart = useCallback(() => {
    setTimerStarted(true);
    dispatch(ThunkStartTimer({ payload: { crmDefaultId, ticketId } }));
    startTimer();
  }, [dispatch, crmDefaultId, ticketId, startTimer]);

  const handleStop = useCallback(() => {
    setTimerStarted(false);
    dispatch(ThunkStopTimer({ payload: { crmDefaultId, ticketId } }))
      .unwrap()
      .then(res => {
        setStartEndTime({
          start: res.start,
          end: res.end,
        });
        setOpenAddTimeEntryDialog(true);
      });
    stopTimer();
  }, [dispatch, crmDefaultId, ticketId, stopTimer]);

  const onMount = useCallback(() => {
    dispatch(ThunkGetTimer({ payload: { crmDefaultId, ticketId } }))
      .unwrap()
      .then(res => {
        if (res.id !== 0) {
          setTimerStarted(true);
          getInitials(res.start, res.end);
        }
      });
  }, [dispatch, crmDefaultId, ticketId, getInitials]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div>
      <ActionButton
        handler={handleStart}
        toolTip="Start Timer"
        icon={<PlayCircleOutlineIcon />}
        disabled={timerStarted}
      />
      <ActionButton
        handler={handleStop}
        toolTip="Stop Timer"
        icon={<StopIcon />}
        disabled={!timerStarted}
      />

      {time}

      {openAddTimeEntryDialog && (
        <AddCWTicketTimeEntryDialog
          open={openAddTimeEntryDialog}
          setOpen={setOpenAddTimeEntryDialog}
          crmDefaultId={crmDefaultId}
          ticketId={ticketId}
          setSelectedItem={setStartEndTime}
          initialStartEndTime={startEndTime}
        />
      )}
    </div>
  );
};

TimeEntriesTimer.propTypes = {};

export default TimeEntriesTimer;
