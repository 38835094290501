import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingChannels } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingChannels = createAsyncThunk(
  `${MESSAGING}/getMessagingChannels`,
  async configurationId => {
    const channels = await getMessagingChannels(configurationId);
    return channels;
  },
);

export default {};
