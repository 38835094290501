import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import { typeValues, unrecognizedTypes } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';

export const getUnrecognizedTypes = () =>
  getData(`${appConfig.baseUrl}/TypeValues/all/`);

export const getUnrecognizedTypesById = id =>
  getData(`${appConfig.baseUrl}${unrecognizedTypes}${id}`);

export const submitData = (id, data) =>
  postWithResponse(`${appConfig.baseUrl}${typeValues}${id}/update`, data);
