import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useStyles from '../styles';

const DisplayHookBody = ({ text }) => {
  const classes = useStyles();
  return (
    <div
      className={cx(
        classes.textStyle,
        classes.jsonTextStyle,
        classes.textBorder,
      )}
    >
      <pre>{text}</pre>
    </div>
  );
};

DisplayHookBody.propTypes = {
  text: PropTypes.string,
};

export default DisplayHookBody;
