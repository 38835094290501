import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAvailableUsers } from '@services/requests/chats';
import { CHATS } from '@store/slices/resources';

export const ThunkGetAvailableUsers = createAsyncThunk(
  `${CHATS}/getAvailableUsers`,
  async () => {
    const res = await getAvailableUsers();
    return res;
  },
);

export default {};
