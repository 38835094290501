import React from 'react';
import PropTypes from 'prop-types';
import iconAdd from '@assets/icons/add.svg';
import iconTick from '@assets/icons/tick.svg';
import iconFailed from '@assets/icons/alarm_black.svg';
import iconAlarm from '@assets/icons/failed.svg';
import iconHide from '@assets/icons/hide.svg';
import iconTypes from './iconTypes';

import useStyles from './styles';

const icons = {
  [iconTypes.check]: iconTick,
  [iconTypes.add]: iconAdd,
  [iconTypes.error]: iconFailed,
  [iconTypes.reportProblem]: iconAlarm,
  [iconTypes.ignored]: iconHide,
};

const MiniBox = ({ data, label, iconType }) => {
  const classes = useStyles();

  return (
    <div className={classes.miniItem}>
      <img src={icons[iconType]} alt="icons" />
      <span className={classes.miniItemContent}>
        {data}
        &nbsp;
        {label}
      </span>
    </div>
  );
};

MiniBox.propTypes = {
  data: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  iconType: PropTypes.oneOfType([PropTypes.symbol, PropTypes.string])
    .isRequired,
};

export default MiniBox;
