import React from 'react';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';

import useStyles from '../styles';

const PlanBenefits = ({ planBenefits }) => {
  const classes = useStyles();

  return (
    <div className={classes.billingBenefits}>
      <Typography variant="h3">
        <b>Get Started with MSP Process</b>
      </Typography>

      <div>
        <Typography variant="h5">
          <b>{planBenefits.Header}</b>
        </Typography>
      </div>

      <div>
        {planBenefits.Items.map(i => (
          <div key={i} className={classes.benefit}>
            <CheckIcon />
            <div>{i}</div>
          </div>
        ))}
      </div>

      <div className={classes.card}>
        <div
          style={{
            color: '#012169',
            fontSize: 'larger',
            fontWeight: 'bolder',
            margin: '5px',
          }}
        >
          “Their automations in our workflows are saving us a lot of time!“
        </div>

        <div>
          MSP Process has helped us improve communication with clients using SMS
          when email and phones are not getting us a reply to a ticket.
        </div>

        <div
          style={{
            margin: '5px',
            fontWeight: 'bolder',
          }}
        >
          Hunter Thevis, S1 Technology
        </div>
      </div>
    </div>
  );
};

export default PlanBenefits;
