import React, { useState } from 'react';
import deleteIcon from '@assets/icons/deleteWhite.svg';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useBulkDelete from '@components/Hooks/useBulkDelete';
import { removeNotificationSettings } from '../helpers';

const BulkActionButtons = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const onClickDelete = useBulkDelete(
    removeNotificationSettings,
    setDeleteDialogOpen,
  );
  return (
    <>
      <ReusableButton
        onClick={() => setDeleteDialogOpen(true)}
        viewType="red"
        size="xl"
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          Delete Selected
        </>
      </ReusableButton>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onClickDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Deleting Items"
        content="Are you sure to remove these items? This action can not be undone"
      />
    </>
  );
};

export default BulkActionButtons;
