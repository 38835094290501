import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationCompanies } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationCompanies = createAsyncThunk(
  `${INTEGRATION}/getIntegrationCompanies`,
  async ({ id }) => {
    const companies = await getIntegrationCompanies(id);
    return companies;
  },
);

export default {};
