import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperRoot: {
    width: '-webkit-fill-available',
    padding: '1rem',
    marginTop: '0.5rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  radioLabel: {
    marginRight: '1rem',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: '0.5rem',
    marginLeft: 0,
    '& button:last-child': {
      marginLeft: '0.1rem',
    },
    '& button:first-child': {
      marginRight: '0.1rem',
    },
  },
  alertsLayout: {
    overflow: 'auto',
    maxHeight: '50vh',
  },
  hrGradient: {
    backgroundImage:
      'linear-gradient(90deg, transparent 5%, gray 50%, transparent 95%)',
    border: '0',
    height: '1px',
  },
}));

export default useStyles;
