import { actionTypes } from '../actions';

const defaultState = {
  dataViewId: 0,
  setting: {},
  ticketSettings: {},
  emailSettings: {},
  smsSettings: {},
  webHookSettings: {},
  slackSettings: {},
  teamsSettings: {},
  ftpSettings: {},
  voiceSettings: {},
};

const notificationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_SAVE_DATA_VIEW_ID:
      return { ...state, dataViewId: action.payload };
    case actionTypes.NOTIFICATION_SAVE_SETTING:
      return { ...state, setting: action.payload };
    case actionTypes.NOTIFICATION_SAVE_TICKET_SETTING:
      return { ...state, ticketSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_EMAIL_SETTING:
      return { ...state, emailSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_SMS_SETTING:
      return { ...state, smsSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_WEBHOOK_SETTING:
      return { ...state, webHookSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_SLACK_SETTING:
      return { ...state, slackSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_TEAMS_SETTING:
      return { ...state, teamsSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_FTP_SETTING:
      return { ...state, ftpSettings: action.payload };
    case actionTypes.NOTIFICATION_SAVE_VOICE_SETTING:
      return { ...state, voiceSettings: action.payload };
    case actionTypes.NOTIFICATION_RESET:
      return defaultState;
    default:
      return state;
  }
};

export default notificationsReducer;
