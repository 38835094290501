import { createAsyncThunk } from '@reduxjs/toolkit';
import { addIncidentTimeEntry } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';
import { normalizeTimeEntry } from '../generator';

export const ThunkAddIncidentTimeEntry = createAsyncThunk(
  `${INCIDENT}/addIncidentTimeEntry`,
  async payload => {
    const timeEntry = await addIncidentTimeEntry(payload);
    return normalizeTimeEntry(timeEntry);
  },
);

export default {};
