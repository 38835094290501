import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { setCommonTenantImage } from '@services/requests/common';

export const ThunkSetCommonTenantImage = createAsyncThunk(
  `${COMMON}/setCommonTenantImage`,
  payload => setCommonTenantImage(payload),
);

export default {};
