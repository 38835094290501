import React, { useContext } from 'react';
import { TablePagination as MUITablePagination } from '@material-ui/core';

import { TableContext } from '../context';
import { TablePaginationTypes } from '../constants';

const TablePagination = () => {
  const { totalCount, page, rowsPerPage, setPage, setRowsPerPage } = useContext(
    TableContext,
  );

  return (
    <MUITablePagination
      count={totalCount}
      page={page}
      rowsPerPageOptions={TablePaginationTypes}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={e => {
        if (page * +e.target.value > totalCount) {
          setPage(Math.floor(totalCount / +e.target.value));
        }
        setRowsPerPage(+e.target.value);
      }}
      onPageChange={(e, b) => {
        setPage(b);
      }}
    />
  );
};

export default TablePagination;
