const sanitizeListProps = ({
  defaultFilters,
  defaultSort,
  groupByValue,
  noneSelected,
  ...sanitizeProps
}) => sanitizeProps;

export const getColumnWidth = (column, columnsTableSize) => {
  return columnsTableSize?.[column.key] || column.width;
};

export const getColumnHidden = (column, hiddenTableColumns) => {
  return hiddenTableColumns?.includes(column.key) || column.hidden;
};

export default sanitizeListProps;
