import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  typesIcon: {
    margin: '0.3rem',
    width: '1.8rem',
    height: '1.5rem',
  },
}));

const NotificationIcon = ({ item, record }) => {
  const classes = useStyles();

  return (
    <img
      src={item.icon}
      alt="icon"
      className={classes.typesIcon}
      style={{
        filter: `${record[item.status] ? 'none' : 'grayscale(1)'}`,
      }}
    />
  );
};

NotificationIcon.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string,
    icon: PropTypes.string,
  }),
  record: PropTypes.objectOf(PropTypes.any),
};

export default NotificationIcon;
