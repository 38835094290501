import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #c7c7c7',
    width: '10rem',
    borderRadius: '6px',
    textAlign: 'center',
    margin: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '4px 3px 5px #c7c7c7',
    },
  },
}));

export default useStyles;
