import { makeRequest } from '@services/requests/makeRequest';
import { LIVE_CHAT_CHAT_BOTS } from '../requestResources';

export const getChatBots = () => makeRequest('GET', `${LIVE_CHAT_CHAT_BOTS}`);

export const getChatBotOptions = () =>
  makeRequest('GET', `${LIVE_CHAT_CHAT_BOTS}/options`);

export const createChatBot = payload =>
  makeRequest('POST', `${LIVE_CHAT_CHAT_BOTS}`, payload);

export const updateChatBot = (chatBotId, payload) =>
  makeRequest('PUT', `${LIVE_CHAT_CHAT_BOTS}/${chatBotId}`, payload);

export const deleteChatBot = chatBotId =>
  makeRequest('DELETE', `${LIVE_CHAT_CHAT_BOTS}/${chatBotId}`);
