import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '@utils/index';
import { ListContextProvider, ListView } from 'react-admin';
import { NONE_SELECTED } from '@common/MuiVirtualizedTable/constants';
import MuiVirtualizedListViewBase from '@common/MuiVirtualizedTable/MuiVirtualizedListViewBase';
import sanitizeListProps from '@common/MuiVirtualizedTable/helpers';

import { COUNT_PRODUCT_PER_PAGE } from '@constants/filters';
import useStyles from '@common/MuiVirtualizedTable/styles';
import useControllerTableProps from './hooks/useControllerTableProps';
import withUpdatedColumns from './HOC/withUpdatedColumns';

import { ColumnsContext } from './context/columns';

const MuiVirtualizedTable = props => {
  const {
    rowHeight,
    headerHeight,
    columns: defaultColumns,
    resource,
    actions,
    bulkActions = false,
    filters,
    additionalControllerProps = {},
    defaultFilters,
    defaultSort = { field: 'id', order: 'ASC' },
    classNameWrapper,
    groupByValue,
    exporter,
    rowsWrapping,
    children,
    selectorKey,
  } = props;

  const { columns, setDefaultColumns } = useContext(ColumnsContext);

  useEffect(() => setDefaultColumns(defaultColumns), [
    defaultColumns,
    setDefaultColumns,
  ]);

  const controllerProps = useControllerTableProps({
    resource,
    defaultFilters,
    defaultSort,
    columns,
    groupByValue,
    rowsWrapping,
    selectorKey,
  });

  const {
    setPage,
    page,
    onToggleItem,
    selectedIds,
    rows,
    ids,
    loading,
    total,
    resetSelectedIds,
  } = controllerProps;

  const classes = useStyles({});

  const loadMoreRows = useCallback(
    ({ stopIndex }) => {
      // stopIndex start from 0, need to add 1
      if (
        stopIndex + 1 === rows.length &&
        rows.length < total &&
        rows.length >= COUNT_PRODUCT_PER_PAGE
      ) {
        setPage(page + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows],
  );
  // setPage, page, total
  const loadMoreRowsDebounce = debounce(loadMoreRows, 500);

  const {
    handleColumnResizeDebounce,
    onRefreshList,
    ...listViewProps
  } = controllerProps;

  const finiteControllerProps = {
    ...listViewProps,
    ...additionalControllerProps,
    noneSelected: NONE_SELECTED,
    resetSelectedIds,
  };

  return (
    <div className={classNameWrapper}>
      <ListContextProvider value={finiteControllerProps}>
        {children}
        <ListView
          {...sanitizeListProps(finiteControllerProps)}
          bulkActionButtons={bulkActions}
          exporter={exporter}
          pagination={false}
          filters={filters}
          actions={actions}
          classes={{
            root: classes.root,
            content: classes.tableContent,
          }}
          empty={false}
        >
          <MuiVirtualizedListViewBase
            columns={columns}
            rows={rows}
            loadMoreRowsDebounce={loadMoreRowsDebounce}
            bulkActions={bulkActions}
            filters={filters}
            classNameWrapper={classNameWrapper}
            handleColumnResizeDebounce={handleColumnResizeDebounce}
            onToggleItem={onToggleItem}
            selectedIds={selectedIds}
            ids={ids}
            loading={loading}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            groupByValue={groupByValue}
          />
        </ListView>
      </ListContextProvider>
    </div>
  );
};

MuiVirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      align: PropTypes.string,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  resource: PropTypes.string,
  selectorKey: PropTypes.string,
  actions: PropTypes.node,
  bulkActions: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  filters: PropTypes.node,
  additionalControllerProps: PropTypes.objectOf(PropTypes.any),
  defaultFilters: PropTypes.objectOf(PropTypes.any),
  defaultSort: PropTypes.objectOf(PropTypes.any),
  classNameWrapper: PropTypes.string,
  groupByValue: PropTypes.string,
  rowsWrapping: PropTypes.func,
  children: PropTypes.node,
  exporter: PropTypes.func,
};

export default withUpdatedColumns(MuiVirtualizedTable);
