import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPsaResources } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetPsaResources = createAsyncThunk(
  `${MESSAGING}/getCrmResources`,
  async ({ channelId }) => {
    const users = await getPsaResources(channelId);
    return users;
  },
);

export default {};
