import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import useAsync from '@services/api/common/useAsync';
import { getAuditViewAutocomplete } from '@components/Audit/helpers';
import useStyles from '../../styles';

const SelectDataView = ({ currentView, setCurrentView }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const loading = useAsync(getAuditViewAutocomplete, setOptions);

  return (
    <div className={classes.dataViewSelector}>
      <ViewAutoComplete
        formControlClassNameWrapper={classes.formControl}
        options={options}
        onChange={setCurrentView}
        loading={loading}
        currentView={currentView}
      />
    </div>
  );
};

SelectDataView.propTypes = {
  currentView: PropTypes.objectOf(PropTypes.any),
  setCurrentView: PropTypes.func,
};

export default SelectDataView;
