import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTicketNotesGenericFunctions } from '@components/CrmTicket/Notes/helpers/useTicketNotesGenericFunctions';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

const DeleteNoteDialog = ({ open, setOpen, psaType, noteId }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { crmDefaultId, ticketId } = useParams();
  const { deleteNote } = useTicketNotesGenericFunctions({ psaType });

  const handleSubmit = async () => {
    setProcessing(true);
    await dispatch(
      deleteNote({
        crmDefaultId,
        ticketId,
        noteId,
      }),
    )
      .unwrap()
      .then(() => {
        setOpen(false);
        enqueueSnackbar('Note deleted', { variant: 'success' });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <ConfirmDialog
      open={open}
      setOpen={setOpen}
      onAccept={handleSubmit}
      title="Confirm Action"
      content="Are you sure you want to delete this note?"
      loading={processing}
    />
  );
};

DeleteNoteDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  psaType: PropTypes.string,
  noteId: PropTypes.number,
};
export default DeleteNoteDialog;
