import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaStatuses } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaStatuses = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaStatuses`,
  ({ crmDefaultId }) => getKaseyaStatuses(crmDefaultId),
);

export default {};
