import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmDefaults } from '@services/requests/treeView';
import { TREE_VIEW } from '@store/slices/resources';

export const ThunkGetCrmDefaults = createAsyncThunk(
  `${TREE_VIEW}/getCrmDefaults`,
  async ({ crmId }) => {
    const defaults = await getCrmDefaults(crmId);
    return { [crmId]: defaults };
  },
);

export default {};
