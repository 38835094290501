import React, { useState } from 'react';
import { useListContext } from 'react-admin';

import { FormControlLabel } from '@material-ui/core';

import Switch from '@common/FilterInputs/Switch';

import useStyles from '../styles';

const ClientPortalChatActions = () => {
  const classes = useStyles();

  const [skipClosed, setSkipClosed] = useState({ value: true });
  const { setFilters, filterValues } = useListContext();

  const handleChangeSkipClosed = item => {
    setSkipClosed(item);
    setFilters(
      {
        isClosed: item?.value ? 'false' : '',
      },
      filterValues,
      true,
    );
  };

  return (
    <div className={classes.tableActionsWrapper}>
      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: () => {
                handleChangeSkipClosed({ value: !skipClosed.value });
              },
              value: skipClosed.value,
            }}
            checked={skipClosed.value}
          />
        }
        label="Skip Closed"
        labelPlacement="start"
      />
    </div>
  );
};

export default ClientPortalChatActions;
