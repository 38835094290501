import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { FormHelperText, ListItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import cx from 'classnames';
import {
  TypeInput,
  TypeLabelValue,
  TypeMeta,
  TypeSize,
} from '@common/propTypes/common';
import { axiosInstance } from '@components/Table/axios';
import Loader from '@assets/loader.gif';

import useStyles from '@components/Auth/Common/dropDownStyles';

const AutocompleteWithPagination = ({
  items,
  disabled,
  input,
  size = 'medium',
  classNameWrapper,
  meta,
  getOptionLabel = o => {
    return o.inputValue || o.label || o;
  },
  label,
  variant = 'outlined',
  listItemClassName,
  pageSize = 20,
  resource,
  totalOptionsCount,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const isError = meta && meta.error && meta.touched;

  const onChange = (event, data) => {
    input.onChange(
      typeof data === 'string' ? data : data?.inputValue || data?.value,
    );
  };

  const fetch = useCallback(
    async (currentPage = 0, filtering = {}, isSetupTotalCount = false) => {
      if (!loading) {
        setLoading(true);
      }
      try {
        const { data, headers } = await axiosInstance.request({
          method: 'GET',
          url: resource,
          params: {
            _start: currentPage * pageSize,
            _end: currentPage * pageSize + pageSize,
            ...filtering,
          },
        });
        if (isSetupTotalCount) {
          setTotalCount(+headers.get('x-total-count'));
        }
        return data;
      } catch (e) {
        enqueueSnackbar(e.response?.data?.message || 'Something went wrong', {
          variant: 'error',
        });
        return [];
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, pageSize, resource],
  );

  useEffect(() => {
    if (items) {
      setOptions(items);
    } else {
      fetch(undefined, undefined, true).then(res => {
        setOptions(res);
      });
    }
  }, [fetch, items]);

  useEffect(() => {
    if (totalOptionsCount) {
      setTotalCount(totalOptionsCount);
    }
  }, [totalOptionsCount]);

  return (
    <div className={classes.fieldWrapper}>
      <Autocomplete
        variant="outlined"
        className={cx(
          classes.formControl,
          classes.selectWrapper,
          classNameWrapper,
        )}
        fullWidth
        classes={{ popper: listItemClassName }}
        size={size}
        id="group-by-autocomplete"
        forcePopupIcon
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={params => {
          return (
            <>
              <TextField
                {...params}
                variant={variant}
                label={label}
                error={!!isError}
              />
            </>
          );
        }}
        noOptionsText={
          loading ? (
            <div className={classes.loader}>
              <img src={Loader} className={classes.img} alt="Loading" />
            </div>
          ) : (
            'No options'
          )
        }
        renderOption={option => (
          <>
            <ListItem
              component="div"
              className={cx({ [classes.loadWrapper]: loading })}
            >
              {option.label}
              {option === options[options.length - 1] && loading && (
                <div className={classes.loader}>
                  <img src={Loader} className={classes.img} alt="Loading" />
                </div>
              )}
            </ListItem>
          </>
        )}
        disabled={disabled}
        {...input}
        value={
          options.find(option => option.value === input.value)?.label || ''
        }
        onChange={onChange}
        onInputChange={(event, newInputValue) => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }
          setSearchValue(newInputValue);
          const isBeSearch = totalCount !== options.length;

          if (isBeSearch) {
            setLoading(true);
          }

          const timeoutId = setTimeout(() => {
            if (isBeSearch) {
              fetch(0, { label: newInputValue })
                .then(res => {
                  setOptions(res);
                })
                .then(() => {
                  setPage(0);
                });
            }
          }, 500);

          setDebounceTimeout(timeoutId);
        }}
        ListboxProps={{
          onScroll: event => {
            if (
              Math.floor(event.target.scrollHeight) -
                Math.floor(event.target.scrollTop) -
                Math.floor(event.target.clientHeight + 1) <=
                1 &&
              !loading &&
              totalCount > options.length
            ) {
              fetch(page + 1, { label: searchValue })
                .then(data => setOptions(prevList => [...prevList, ...data]))
                .then(() => {
                  setPage(prevPage => prevPage + 1);
                });
            }
          },
        }}
      />
      {isError && (
        <FormHelperText error={isError} className={classes.helperText}>
          {meta?.error}
        </FormHelperText>
      )}
    </div>
  );
};

AutocompleteWithPagination.propTypes = {
  input: TypeInput,
  meta: TypeMeta,
  size: TypeSize,
  items: PropTypes.arrayOf(TypeLabelValue),
  disabled: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  variant: PropTypes.string,
  listItemClassName: PropTypes.string,
  pageSize: PropTypes.number,
  resource: PropTypes.string,
  totalOptionsCount: PropTypes.number,
};

export default AutocompleteWithPagination;
