import { useState, useEffect } from 'react';

const useDebounce = (value, delay, allowEmptyValue) => {
  const [debouncedValue, setDebouncedValue] = useState(null);

  useEffect(() => {
    let debounceTimeoutId = null;

    if (value || allowEmptyValue) {
      debounceTimeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    }

    return () => {
      if (debounceTimeoutId) {
        clearTimeout(debounceTimeoutId);
      }
    };
  }, [value, delay, debouncedValue, allowEmptyValue]);

  return debouncedValue;
};

export default useDebounce;
