import { createAsyncThunk } from '@reduxjs/toolkit';
import { editConversationTemplate } from '@services/requests/messaging';
import { SMS_CONVERSATIONS } from '@store/slices/resources';

export const ThunkEditConversationTemplate = createAsyncThunk(
  `${SMS_CONVERSATIONS}/editConversationTemplate`,
  async ({ id, payload }) => {
    await editConversationTemplate(id, payload);
    return payload;
  },
);

export default {};
