import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPhoneNumbers } from '@services/requests/phoneNumbers';
import { PHONE_NUMBERS } from '@store/slices/resources';
import { normalizePhoneNumber } from '../generator';

export const ThunkGetPhoneNumbers = createAsyncThunk(
  `${PHONE_NUMBERS}/getPhoneNumbers`,
  async (getAll = false) => {
    const phoneNumbers = await getPhoneNumbers(getAll);

    return phoneNumbers.map(number => normalizePhoneNumber(number));
  },
);

export default {};
