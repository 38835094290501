import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: '45px',
  },
  groupHeader: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontSize: '0.875rem',
    margin: '20px 0 5px',
  },
  cardBlock: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
