import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  noGroupsText: {
    textAlign: 'center',
    fontFamily: "'Montserrat'",
    marginTop: '5rem',
  },
  elementSpace: {
    marginBottom: '2rem',
  },
}));

export default useStyles;
