import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#e7e7e7',
  },
  box: {
    padding: '50px',
    background: 'white',
    borderRadius: '10px',
    boxSizing: 'border-box',
    maxHeight: '500px',
    height: '100%',
    maxWidth: '400px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    gap: '10px',

    '@media (max-width: 1100px)': {
      maxWidth: 'unset',
      maxHeight: 'unset',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  inputContainer: {},
  imageRoot: {
    maxWidth: '350px',
    width: '100%',
    objectFit: 'contain',
    maxHeight: '150px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000000',
  },
  buttons: {},
}));

export default useStyles;
