import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import { notificationGroups, crmUsers, users } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';

export const isGroupExists = name =>
  getData(`${appConfig.baseUrl}${notificationGroups}nameExists?name=${name}`);

export const isEmailExists = (id, email) =>
  getData(
    `${appConfig.baseUrl}${notificationGroups}users/${id}/emailExists?email=${email}`,
  );

export const getCrmUsers = () =>
  getData(`${appConfig.baseUrl}${crmUsers}autocomplete`);

export const getTenantUsers = () =>
  getData(`${appConfig.baseUrl}${users}/autocomplete`);

export const addCrmUsers = data =>
  postWithResponse(
    `${appConfig.baseUrl}${notificationGroups}addCrmUsers`,
    data,
  );

export const addTenantUsers = data =>
  postWithResponse(
    `${appConfig.baseUrl}${notificationGroups}addTenantUsers`,
    data,
  );

export const checkNotifications = id =>
  getData(`${appConfig.baseUrl}${notificationGroups}${id}/checkNotifications`);
