import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalPusherSettings } from '@services/requests/clientPortal';

export const ThunkGetClientPortalPusherSettings = createAsyncThunk(
  `${CLIENT_PORTALS}/getClientPortalPusherSettings`,
  () => getClientPortalPusherSettings(),
);

export default {};
