import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';

import crmSources from '@constants/crmSources';
import { PSA_TECH_VERIFICATION } from '@constants/routes';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import KaseyaTicketTable from '@components/PsaTickets/components/Kaseya/KaseyaTicketTable';
import { TableTypes } from '@components/Table/constants';
import Actions from '@ui/pages/technicianVerification/AssignTicketToTechRequest/components/Actions';
import ConnectWiseTicketSelector from './components/ConnectWiseTicketSelector';

const AssignTicketToTechVerification = () => {
  const [loading, setLoading] = useState(true);
  const [psaIntegrations, setPsaIntegrations] = useState([]);
  const [psaId, setPsaId] = useState('');

  const dispatch = useDispatch();
  const { push } = useHistory();
  const params = useParams();

  const content = useMemo(() => {
    if (psaId) {
      const selectedIntegration = psaIntegrations.find(i => i.crmId === psaId);

      switch (selectedIntegration.type) {
        case crmSources.ConnectWise.idx:
          return (
            <ConnectWiseTicketSelector
              psaId={psaId}
              psaDefaultId={selectedIntegration.defaultId}
              itemId={params.requestId}
            />
          );
        case crmSources.Kaseya.idx:
          return (
            <KaseyaTicketTable
              crmId={psaId}
              defaultId={selectedIntegration.defaultId}
              setColumns={columns => {
                columns.splice(5, 4);
                columns.splice(6, 2);
                columns.push({
                  name: 'Actions',
                  key: TableTypes.actions,
                  type: TableTypes.actions,
                  width: 120,
                  minWidth: 120,
                  accessor: item => (
                    <Actions
                      item={item}
                      psaId={psaId}
                      itemId={params.requestId}
                    />
                  ),
                });
              }}
            />
          );
        default:
          return <></>;
      }
    }
    return <></>;
  }, [params.requestId, psaId, psaIntegrations]);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetCrmOptions())
      .unwrap()
      .then(res => {
        const availableIntegrations = res.filter(
          i =>
            i.type === crmSources.ConnectWise.idx ||
            i.type === crmSources.Kaseya.idx,
        );
        setPsaIntegrations(availableIntegrations);

        if (availableIntegrations.length === 1) {
          setPsaId(res[0].crmId);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '100px 1fr',
        height: 'calc(100vh - 105px)',
        padding: '40px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        gap: '10px',
      }}
    >
      <FieldWrapper
        label="PSA Integration"
        labelSize={12}
        contentSize={12}
        content={
          <DropDown
            input={{
              value: psaId,
              onChange: e => {
                setPsaId(e.target.value);
              },
            }}
            options={psaIntegrations}
            labelName="crmName"
            valueName="crmId"
          />
        }
      />

      {content}

      <div>
        <ReusableButton
          label="Back"
          onClick={() => {
            push(PSA_TECH_VERIFICATION);
          }}
        />
      </div>
    </div>
  );
};

export default AssignTicketToTechVerification;
