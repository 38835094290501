import React, { useState } from 'react';
import useAsync from '@services/api/common/useAsync';
import { apisUsage } from '@components/Settings/TenantStatistic/helpers';
import Loading from '@common/Loading/Loading';
import SourceItemValue from '@components/Settings/TenantStatistic/components/SourceItemValue';

const Apis = () => {
  const [data, setData] = useState({});
  const loading = useAsync(apisUsage, setData);

  if (loading) return <Loading />;
  return (
    <>
      <SourceItemValue label="Total API calls" value={data?.totalCount} />
      <SourceItemValue
        label="Generic integrations"
        value={data?.genericCount}
      />
      <SourceItemValue label="Meraki integration" value={data?.merakiCount} />
      <SourceItemValue label="Sophos integration" value={data?.sophosCount} />
    </>
  );
};

export default Apis;
