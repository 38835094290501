import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateOptInKey } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {string} key
 */
export const ThunkValidateOptInKey = createAsyncThunk(
  `${OPT_IN}/validateOptInKey`,
  async ({ key }) => validateOptInKey(key),
);

export default {};
