import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getLiveChatConfigurations } from '@services/requests/liveChats';

export const ThunkGetLiveChatConfigurations = createAsyncThunk(
  `${LIVE_CHATS}/getLiveChatConfigurations`,
  () => getLiveChatConfigurations(),
);

export default {};
