import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getConnectWiseAgreements } from '@services/requests/createTicket';

export const ThunkGetConnectWiseCompanyAgreements = createAsyncThunk(
  `${CREATE_TICKET}/getConnectWiseCompanyAgreements`,
  ({ psaId, companyId }) => getConnectWiseAgreements(psaId, companyId),
);

export default {};
