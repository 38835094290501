import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  icon: {
    margin: '0 5px',
  },
  hintBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  hintArrow: {
    context: '',
    position: 'absolute',
    display: 'inline-block',
    width: '0.6875rem',
    height: '0.6875rem',
    backgroundColor: 'white',
    borderTop: '0.0625rem solid #BBCCE6',
    borderRight: '0.0625rem solid #BBCCE6',
    boxShadow: '0px 4px 24px rgba(199, 212, 234, 0.45)',
    transform: 'rotate(-45deg)',
    left: '50%',
    marginLeft: '-0.344rem',
    top: '-0.9rem',
  },
  hintTitle: {
    fontStyle: 'Open Sans',
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    color: '#2F2F2F',
  },
  hintUserInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  hintEmail: {
    fontStyle: 'Open Sans',
    fontSize: '0.8125rem',
    color: '#2F2F2F',
  },
}));

export default useStyles;
