import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Loader from '@assets/loader.gif';
import useStyles from './styles';

const Loading = ({
  classNameWrapper,
  customHeader,
  customText,
  customIcon,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.container, classNameWrapper)}>
      <img src={customIcon || Loader} alt="Loading" />
      <h1>{customHeader || 'Loading...'}</h1>
      <div>{customText || 'Loading...'}</div>
    </div>
  );
};

Loading.propTypes = {
  classNameWrapper: PropTypes.string,
  customHeader: PropTypes.string,
  customText: PropTypes.string,
  customIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
export default Loading;
