import React from 'react';
import PropTypes from 'prop-types';

import { getAutotaskTableColumns } from '@components/PsaContacts/constants';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

const AutotaskContacts = ({ psaId, resource, psaName }) => {
  return (
    <Table
      resource={resource}
      columns={getAutotaskTableColumns(psaId)}
      bulkActions={
        <PsaContactsTableBulkActions psaId={psaId} psaName={psaName} />
      }
    />
  );
};

AutotaskContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  psaName: PropTypes.string.isRequired,
};

export default AutotaskContacts;
