import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableContainer: {
    padding: '12px',
    '& tbody': {
      maxHeight: '72vh',
    },
  },
  container: {
    marginTop: '2rem',
    '& > div > div > div': {
      marginTop: '0px !important',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
  },
  ruleRegion: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      margin: '0 5px',
    },
  },
  formContainer: {
    width: '70vw',
    marginTop: '20px',
    height: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > div': {
      justifyContent: 'flex-end',
    },
  },
  bulkContainer: {
    '& > *': { marginLeft: '5px' },
  },
  incidenceFormActions: {
    width: '30%',
    marginLeft: 'auto',
  },
  tableActions: {
    display: 'flex',
    marginBottom: '5px',
    justifyContent: 'space-between',
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '100%',
  },
  switcher: {
    marginRight: '1rem',
  },
  createTicketContainer: {
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  createIncidentDialog: {
    display: 'flex',
    flexDirection: 'column',
    '& > div > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      margin: '5px 0px !important',
    },
  },
  assigneeBox: {
    display: 'flex',
    marginTop: '8px',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '0.5rem',
  },
  tableWrapper: {
    height: '86vh',
    overflowY: 'auto',
    padding: '12px',
  },
  bulkActionsClassName: {
    flexDirection: 'row-reverse',
  },
}));

export default useStyles;
