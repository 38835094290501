import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    gap: '30px',
    padding: '30px 30px',
    boxSizing: 'border-box',
    height: '90%',
    width: '70%',
    margin: '20px auto',
  },
  pricePlan: {
    background: 'rgb(238, 238, 238)',
    color: 'black',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectedPricePlan: {
    background: 'rgb(47, 73, 138)',
    color: 'white',
  },
  disabledPricePlan: {
    cursor: 'not-allowed',
  },
  pricePlans: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
    gap: '10px',
    height: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    padding: '10px 0px',
    boxSizing: 'border-box',
  },
  pricePlanHeader: {
    margin: '5px 0px',
    fontSize: '20px',
    fontWeight: 'bolder',
  },
}));

export default useStyles;
