import React, { useState } from 'react';
import PropTypes from 'prop-types';
import visibleIcon from '@assets/icons/visibility_blue.svg';
import hideIcon from '@assets/icons/hide_blue.svg';
import useStyles from './styles';

const getText = (src, isTransform) =>
  isTransform ? '•'.repeat(src?.length ?? 0) : src;

const KeyField = ({ source, record = {} }) => {
  const classes = useStyles();
  const displayData = record[source];
  const [show, setShow] = useState(false);

  return (
    <div className={classes.container}>
      <span className={classes.monoSpace}>{getText(displayData, !show)}</span>
      <img
        role="presentation"
        onClick={() => setShow(!show)}
        className={classes.icon}
        src={show ? hideIcon : visibleIcon}
        alt="visibleIcon"
      />
    </div>
  );
};

KeyField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
};

export default KeyField;
