import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../styles';

const Block = ({ name, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.block}>
      <div className={classes.blockName}>{name}</div>
      <div>{children}</div>
      <hr className={classes.blockHr} />
    </div>
  );
};

Block.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Block;
