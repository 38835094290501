import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSuperOpsIntegrationTypes } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetSuperOpsIntegrationTypes = createAsyncThunk(
  `${INTEGRATION}/getSuperOpsIntegrationTypes`,
  async ({ crmId }) => {
    const types = await getSuperOpsIntegrationTypes(crmId);
    return types;
  },
);

export default {};
