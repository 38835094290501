import { defaultTheme } from 'react-admin';
import { red } from '@material-ui/core/colors';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

export const setPalette = palette => {
  localStorage.setItem(localStorageConst.PALETTE, palette);
};

export const getPalette = () => localStorage.getItem(localStorageConst.PALETTE);

export const defaultPalette = {
  contentBackground: '#f7f9fb',
  appBarBackground: '#ffffff',
  appBarText: '#353535',
  appBarIcon: '#1b3670',
  sideBarBackground: '#353535',
  sideBarSelected: '#ced6d7',
  sideBarText: '#ffffff',
  sideBarIcon: '#ced6d7',
  listViewBackground: '#f7f9fb',
  listViewHeaderText: '#3a3e45',
  listViewText: '#000000',
  cardBackground: '#ffffff',
  cardHoverBackground: '#efefef',
  cardText: '#000000',
  mailBoxCardText: '#000000',
  mailBoxCardBackground: 'white',
  mailBoxCardIcon: '#1b3670',
  mailBoxCardCopyIcon: 'white',
};

export const lightTheme = () => {
  const palette = getPalette() || defaultPalette;
  return {
    ...defaultTheme,
    palette: {
      info: {
        light: '#C9C7C5',
        main: '#3A3E45',
        dark: '#000000',
      },
      secondary: {
        light: '#F0BD3A',
        main: '#1b3670',
        dark: '#952a16',
      },
      failed: {
        light: '#F16D6D',
        main: '#E15241',
      },
      success: {
        main: '#67AD5B',
      },
      primary: {
        main: '#1b3670',
      },
      textColor: '#000000',
      tabs: {
        background: 'white',
        contentBackground: 'white',
      },
      listView: {
        main: palette.listViewBackground,
        text: palette.listViewText,
        headerText: palette.listViewHeaderText,
        chip: {
          background: '#FFFFFF',
          color: '#333333',
        },
      },
      card: {
        background: palette.cardBackground,
        hoverBackground: palette.cardHoverBackground,
        contentBackground: 'white',
        text: palette.cardText,
        addCard: {
          icon: {
            background: 'rgba(255,239,239,0.16)',
          },
        },
      },
      mailBoxCard: {
        background: palette.mailBoxCardBackground,
        contentBackground: 'white',
        text: palette.mailBoxCardText,
        icon: palette.mailBoxCardIcon,
        copyIcon: palette.mailBoxCardCopyIcon,
        addCard: {
          icon: {
            background: 'rgba(255,239,239,0.16)',
          },
        },
      },
      homePage: {
        dashboard: {
          background: '#fdfdff',
        },
        settings: {
          background: '#fdfdff',
        },
      },
      customTypes: {
        values: {
          background: '#fdfdff',
        },
      },
      tenantSettings: {
        background: 'white',
      },
      yourPlan: {
        progressBar: {
          background: 'rgba(250,250,250)',
        },
      },
      input: {
        background: 'white',
        color: '#C9C7C5',
      },
      menuItem: {
        color: palette.sideBarText,
        icon: palette.sideBarIcon,
      },
      appBar: {
        icon: palette.appBarIcon,
      },
    },
    button: {
      colorPrimary: '#3A3E45',
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ['Nunito'],
      fontStyle: 'normal',
    },
    sidebar: {
      width: '16rem',
      maxHeight: '100%',
      overflowY: 'auto',
      closedWidth: '3.4375rem',
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#1b3670',
        },
        failed: {
          color: '#E15241',
        },
      },
      RaNotification: {
        warning: {
          backgroundColor: '#EC6A4E',
        },
        success: {
          color: '#fff',
        },
      },
      MuiTooltip: {
        tooltip: {
          color: '#3A3E45',
          padding: '0.5rem 1rem',
          boxShadow: '0px 4px 24px rgba(199, 212, 234, 0.45)',
          borderRadius: '0.5rem',
          border: '0.0625rem solid #BBCCE6',
          backgroundColor: 'white',
        },
      },
      RaBulkActionsToolbar: {
        toolbar: {
          height: '3rem',
          backgroundColor: '#646464',
          boxShadow: '0 -5px 7px rgba(0, 0, 0, 0.25)',
          padding: '0 1.25rem',
        },
        icon: {
          display: 'none',
        },
        title: {
          fontSize: '0.8rem',
          fontWeight: 'bold',
          color: 'white',
        },
      },
      RaListToolbar: {
        toolbar: {
          minHeight: '0px',
          '@media (max-width: 767px)': {
            minHeight: '46px',
          },
        },
      },
      RaSidebar: {
        drawerPaper: {
          background: `linear-gradient(180deg, ${palette.sideBarBackground} 0%, #000000 100%)`,
          WebkitBoxSizing: 'border-box',
          MozBoxSizing: 'border-box',
          boxSizing: 'border-box',
        },
      },
      RaMenuItemLink: {
        root: {
          color: palette.sideBarText,
          fontSize: '0.875rem',
          padding: '1rem 0 1rem 1rem',
          '& svg path': {
            stroke: palette.sideBarIcon,
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          },
        },
        active: {
          color: palette.sideBarText,
          background: 'rgba(75, 80, 88, 0.5)',
          boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.15)',
          borderRight: `6px solid ${palette.sideBarSelected}`,

          '& svg path': {
            stroke: palette.sideBarIcon,
          },
        },
      },
      MuiAppBar: {
        colorSecondary: {
          color: palette.appBarText,
          backgroundColor: palette.appBarBackground,
        },
      },
      RaAppBar: {
        menuButton: {
          display: 'flex',
        },
      },
      RaLayout: {
        content: {
          backgroundColor: palette.contentBackground,
          overflow: 'hidden',
          padding: '0',
        },
      },
      RaList: {
        root: {
          padding: '0 1.75rem',
        },
      },
      PrivateTabIndicator: {
        colorPrimary: {
          backgroundColor: '#ffffff00',
        },
      },
      MuiDialog: {
        paperWidthSm: {
          maxWidth: '1100px',
        },
      },
      MuiTab: {
        textColorPrimary: {
          color: '#C9C7C5',
          '&$selected': {
            color: 'white',
            background: '#1A3670',
            fontWeight: 'bold',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          '&.Mui-error': {
            backgroundColor: red['500'],
            color: 'white',
            zIndex: 2,
            right: '0rem',
            padding: '0.0625rem 0.625rem',
            borderRadius: '0rem 0rem 0.5rem 0.5rem',
          },
        },
      },
      MuiTouchRipple: {
        root: {
          color: 'transparent',
        },
      },
    },
  };
};

export const darkTheme = {
  ...defaultTheme,
  palette: {
    type: 'dark',
    info: {
      light: '#3A3E45',
      main: '#C9C7C5',
      dark: '#000000',
    },
    secondary: {
      light: '#F0BD3A',
      main: '#EC6A4E',
      dark: '#952a16',
    },
    failed: {
      light: '#F16D6D',
      main: '#E15241',
    },
    success: {
      main: '#67AD5B',
    },
    primary: {
      main: '#0E96E2',
    },
    textColor: 'white',
    tabs: {
      background: '#181818',
      contentBackground: '#181818',
    },
    listView: {
      main: '#272727',
      chip: {
        background: '#181818',
        color: 'white',
      },
    },
    card: {
      background: '#272727',
      hoverBackground: 'rgba(58,62,69,0.94)',
      contentBackground: 'rgba(58,62,69,0.94)',
      addCard: {
        icon: {
          background: 'rgba(58,62,69,0.94)',
        },
      },
    },
    homePage: {
      dashboard: {
        background: '#181818',
      },
      settings: {
        background: '#181818',
      },
    },
    customTypes: {
      values: {
        background: '#272727',
      },
    },
    yourPlan: {
      progressBar: {
        background: 'rgba(58,62,69,0.94)',
      },
    },
    tenantSettings: {
      background: '#272727',
    },
    input: {
      background: 'rgba(58,62,69,0.94)',
      color: '#C9C7C5',
    },
  },
  button: {
    colorPrimary: '#3A3E45',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Nunito', 'Montserrat', '"Segoe UI"', 'sans-serif'].join(','),
    fontStyle: 'normal',
  },
  sidebar: {
    width: '13rem',
    closedWidth: '3.4375rem',
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        color: '#EC6A4E',
      },
    },
    RaNotification: {
      warning: {
        backgroundColor: '#EC6A4E',
      },
      success: {
        color: '#fff',
      },
    },
    MuiTooltip: {
      tooltip: {
        color: 'white',
        padding: '0.5rem 1rem',
        boxShadow: '0px 4px 24px rgba(199, 212, 234, 0.45)',
        borderRadius: '0.5rem',
        border: '0.0625rem solid #BBCCE6',
        backgroundColor: '#3A3E45',
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        height: '3rem',
        backgroundColor: '#646464',
        boxShadow: '0 -5px 7px rgba(0, 0, 0, 0.25)',
        padding: '0 1.25rem',
      },
      icon: {
        display: 'none',
      },
      title: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        color: 'white',
      },
    },
    RaListToolbar: {
      toolbar: {
        minHeight: '0px',
        '@media (max-width: 767px)': {
          minHeight: '46px',
        },
      },
    },
    RaSidebar: {
      drawerPaper: {
        background: 'linear-gradient(180deg, #3A3E45 0%, #000000 100%)',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box',
      },
    },
    RaMenuItemLink: {
      root: {
        color: 'white',
        fontSize: '0.875rem',
        padding: '1rem 0 1rem 1rem',
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
        },
      },
      active: {
        color: 'white',
        background: 'rgba(75, 80, 88, 0.5)',
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.15)',
        borderRight: '6px solid #EC6A4E',

        '& svg': {
          fill: '#fff',
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: 'white',
        backgroundColor: '#3A3E45',
      },
    },
    RaAppBar: {
      menuButton: {
        display: 'flex',
      },
    },
    RaLayout: {
      content: {
        backgroundColor: '#3A3E45',
        overflow: 'hidden',
        padding: '0',
      },
    },
    RaList: {
      root: {
        padding: '0 1.75rem',
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: '#ffffff00',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '1100px',
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: '#C9C7C5',
        '&$selected': {
          color: '#EC6A4E',
          fontWeight: 'bold',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          backgroundColor: red['500'],
          color: 'white',
          zIndex: 2,
          right: '0rem',
          padding: '0.0625rem 0.625rem',
          borderRadius: '0rem 0rem 0.5rem 0.5rem',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        color: 'transparent',
      },
    },
  },
};
