import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

import mailIcon from '@assets/icons/email_active.svg';
import smsIcon from '@assets/icons/sms.svg';

import { ThunkGetResourceAdmins } from '@store/slices/liveChats/thunks';

import NotificationIcon from '@common/NotificationIcon/NotificationIcon';
import Loading from '@common/Loading/Loading';

import useStyles from '../styles';

const icons = [
  {
    icon: mailIcon,
    status: 'notifyByEmail',
    info: 'emailDeliveryType',
  },
  {
    icon: smsIcon,
    status: 'notifyBySms',
    info: 'smsDeliveryType',
  },
];

const LiveChatDialogBody = ({ users, onSelect, disabled }) => {
  const classes = useStyles();
  return (
    <List classes={{ root: classes.list }}>
      {users.map(user => (
        <ListItem
          disabled={disabled}
          button
          onClick={() => onSelect(user.id)}
          key={user.id}
        >
          <ListItemAvatar>
            <Avatar src={user.imageUri}>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${user.name}; Chats: ${user.activeChatsCount}; ${
              user.isOnline ? 'online' : 'offline'
            }`}
          />
          {icons.map(icon => (
            <Tooltip title={icon.info} key={icon.status}>
              <NotificationIcon item={icon} record={user} />
            </Tooltip>
          ))}
        </ListItem>
      ))}
    </List>
  );
};

const LiveChatAssignDialog = ({
  open,
  resourceId,
  onClose,
  onSelect,
  disabled,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      dispatch(ThunkGetResourceAdmins(resourceId))
        .unwrap()
        .then(p => setUsers(p))
        .finally(() => setLoading(false));
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" className={classes.title}>
        Set Assignee
      </DialogTitle>
      {loading ? (
        <Loading classNameWrapper={classes.loadingWrapper} />
      ) : (
        <LiveChatDialogBody
          users={users}
          onSelect={onSelect}
          disabled={disabled}
        />
      )}
    </Dialog>
  );
};

LiveChatAssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  resourceId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default LiveChatAssignDialog;
