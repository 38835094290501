import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTeamsConnections } from '@services/requests/teamsConnections';
import { TEAMS_CONNECTIONS } from '@store/slices/resources';

export const ThunkGetTeamsConnections = createAsyncThunk(
  `${TEAMS_CONNECTIONS}/getTeamsConnections`,
  async () => {
    const connections = getTeamsConnections();
    return connections;
  },
);

export default {};
