import * as CSV from 'csv-string';
import { convertToHtml } from 'mammoth';
import { createSimpleHtmlDocument } from '@components/Parsers/components/Create/components/DisplayEmail/attachments/helper';
// eslint-disable-next-line import/no-cycle
import {
  createHtmlDocument,
  createMultiTabTable,
  createTableFromArray,
  pdfToTextApi,
} from './helper';

const errorText = '<span>Unable to parse document</span>';

const base64ToArrayBuffer = base64 => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const textType = content => Buffer.from(content, 'base64').toString();

export const csvTsvType = content => {
  const src = Buffer.from(content, 'base64').toString();
  const parsed = CSV.parse(src);
  return createTableFromArray(parsed);
};

export const xlsxType = content => {
  try {
    const bin = base64ToArrayBuffer(content);
    const wb = window.XLSX.read(bin, { type: 'binary' });
    const sheetsCount = wb.SheetNames.length;
    const sheetsData = [];
    for (let i = 0; i < sheetsCount; i++) {
      const wsname = wb.SheetNames[i];
      const ws = wb.Sheets[wsname];
      const json = window.XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: '',
        raw: false,
      });
      sheetsData.push({
        sheetName: wsname,
        data: json,
      });
    }
    return createMultiTabTable(sheetsData);
  } catch {
    return errorText;
  }
};

export const pdfType = async content => {
  try {
    const payload = {
      data: content,
    };
    const result = await pdfToTextApi(payload);
    return createSimpleHtmlDocument(result.data);
  } catch {
    return createSimpleHtmlDocument(errorText);
  }
};

export const docxType = async content => {
  try {
    const arrayBuff = base64ToArrayBuffer(content);
    const result = await convertToHtml({ arrayBuffer: arrayBuff });
    return createHtmlDocument(result.value);
  } catch {
    return createHtmlDocument(errorText);
  }
};
