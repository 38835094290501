import React, { useMemo } from 'react';

import crmSources from '@constants/crmSources';
import ConnectWiseNoteForm from '../componets/ConnectWiseNoteForm';
import AutotaskNoteForm from '../componets/AutotaskNoteForm';
import KaseyaNoteForm from '../componets/KaseyaNoteForm';

export const useTicketNotesFormGenerics = (processing, psaType, noteId) => {
  const { dialogForm } = useMemo(() => {
    switch (psaType) {
      case crmSources.ConnectWise.name:
        return {
          dialogForm: (
            <ConnectWiseNoteForm processing={processing} noteId={noteId} />
          ),
        };
      case crmSources.Autotask.name:
        return {
          dialogForm: <AutotaskNoteForm processing={processing} />,
        };
      case crmSources.Kaseya.name:
        return {
          dialogForm: <KaseyaNoteForm processing={processing} />,
        };
      default:
        return {};
    }
  }, [processing, psaType]);

  return { dialogForm };
};

export default {};
