import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { externalLogin } from '@services/requests/auth';

export const ThunkExternalLogin = createAsyncThunk(
  `${AUTH}/externalLogin`,
  ({ payload }) => externalLogin(payload),
);

export default {};
