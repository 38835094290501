import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableContainer: {
    height: '100%',
    padding: '20px',
    margin: '20px',
    boxSizing: 'border-box',
    borderRadius: '15px',
  },
  actionsColumn: {
    textAlign: 'right',
  },
}));

export default useStyles;
