import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Add } from '@material-ui/icons';
import ReusableButton from '@common/Button/Button';
import { basePermissions } from '@constants/permissions';
import Authorize from '@common/Authorize/Authorize';
import iconRemove from '@assets/icons/remove.svg';
import { DEVICE_MONITORS_EXPORT } from '@constants/routes';
import exportIcon from '@assets/icons/export.svg';
import { useTableContext } from '../../../../hooks/table';

import useStyles from './styles';

const excludedFilterProps = ['groupBy'];

const ListActions = ({
  handleUploadState,
  handleAddRecord,
  children,
  withAuthorize = false,
  handleAddGroup,
}) => {
  const classes = useStyles();
  const { setFilteringObject, filtering } = useTableContext();
  const location = useLocation();
  const history = useHistory();

  const filteredBy = useMemo(() => {
    return Object.entries(filtering)
      .filter(([key, value]) => value && !excludedFilterProps.includes(key))
      .map(([key]) => key)
      .join(', ');
  }, [filtering]);

  const text = `filtered by: ${filteredBy}`;

  const removeQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('id')) {
      queryParams.delete('id');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const removeFilter = () => {
    setFilteringObject({});
    removeQueryParams();
  };

  const createButton = () => (
    <div className={classes.alignPosition}>
      <ReusableButton
        size="md"
        viewType="orange"
        onClick={() => handleUploadState(true)}
        icon={null}
        label="Export"
        classNameWrapper={classes.createBtn}
      >
        <>
          <img src={exportIcon} alt="Upload" />
          Upload
        </>
      </ReusableButton>

      <ReusableButton
        size="md"
        viewType="black"
        onClick={() => history.push(DEVICE_MONITORS_EXPORT)}
        icon={null}
        label="Export"
        classNameWrapper={classes.createBtn}
      >
        <>
          <img src={exportIcon} alt="export" />
          Export
        </>
      </ReusableButton>
      <ReusableButton
        onClick={handleAddRecord}
        classNameWrapper={classes.createBtn}
        size="md"
      >
        <>
          <Add className={classes.plusIcon} />
          Create
        </>
      </ReusableButton>
      {handleAddGroup && (
        <ReusableButton
          onClick={handleAddGroup}
          classNameWrapper={classes.createBtn}
          size="md"
        >
          <>
            <Add className={classes.plusIcon} />
            Create Group
          </>
        </ReusableButton>
      )}
    </div>
  );

  return (
    <div className={classes.listActionContainer}>
      <span
        className={classes.filterTextStyles}
        onClick={removeFilter}
        role="presentation"
      >
        {filteredBy && (
          <>
            {text}
            <img
              src={iconRemove}
              alt="iconRemove"
              className={classes.iconRemove}
            />
          </>
        )}
      </span>

      <div className={classes.buttonsContainer}>
        {children}
        {withAuthorize ? (
          <Authorize
            customResource="deviceMonitors"
            requiredPermissions={[basePermissions.create]}
          >
            {createButton()}
          </Authorize>
        ) : (
          createButton()
        )}
      </div>
    </div>
  );
};

ListActions.propTypes = {
  children: PropTypes.node,
  handleAddRecord: PropTypes.func,
  handleUploadState: PropTypes.func,
  handleAddGroup: PropTypes.func,
  withAuthorize: PropTypes.bool,
};

export default ListActions;
