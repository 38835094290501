import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getLiveChatPusherConfig } from '@services/requests/liveChats';

export const ThunkGetPusherConfig = createAsyncThunk(
  `${LIVE_CHATS}/getLiveChatPusherConfig`,
  () => getLiveChatPusherConfig(),
);

export default {};
