import React from 'react';
import { ReactComponent as NotificationsIcon } from '@assets/icons/notifications.svg';
import NotificationsWrapper from '@components/Ticketing/Notifications/NotificationsWrapper';

const Icon = () => <NotificationsIcon alt="notificationsIcon" />;

export default {
  list: NotificationsWrapper,
  icon: Icon,
};
