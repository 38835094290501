import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChipInputDialog from '@common/ChipDialog/ChipInputDialog';
import LabelValueChipDialog from '@common/ChipDialog/LabelValueChipDialog';
import { customTypeIdMeasure } from '@constants/numericConstants';
import { fieldDataTypes } from '@constants/fieldDataTypes';
import FromToDialog from '@common/Dialog/FromToDialog';
import Loading from '@ui/components/common/Loading/Loading';
import {
  getAllTypeValues,
  getAuditIdentityHints,
  getAuditViewById,
} from './helpers';
import useStyles from './Form/stylesNotificationSettingsForm';

const numberRangeDataType = [fieldDataTypes.integer, fieldDataTypes.float];

const AuditViewForm = ({
  id,
  onDataChange,
  initData,
  onDataChanged,
  auditFormData,
}) => {
  const classes = useStyles();
  const [auditViewData, setAuditViewData] = useState([]);
  const [auditDataHints, setAuditDataHints] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [typeValues, setTypeValues] = useState([]);

  const getValue = useCallback(filter => initData[filter.field] || [], [
    initData,
  ]);

  const setupData = useCallback(
    d => {
      const arr = JSON.parse(d.data);
      setAuditViewData(arr);
      const initSetup = arr.reduce((acc, filter) => {
        return { ...acc, [filter.field]: getValue(filter) };
      }, {});
      if (onDataChanged) onDataChanged(d);
      onDataChange(initSetup);
      setData(initSetup);
    },
    [getValue, onDataChange],
  );

  useEffect(() => {
    if (!id || id === 0) {
      return;
    }
    setLoading(true);
    Promise.all([
      getAuditIdentityHints(id),
      getAllTypeValues(),
      getAuditViewById(id),
    ])
      .then(response => {
        const [auditHints, types, d] = response;
        setAuditDataHints(auditHints);
        setTypeValues(types);
        setupData(d);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    onDataChange(auditFormData);
    setData(auditFormData);
  }, [auditFormData]);

  if (!id || id === 0) {
    return null;
  }

  const handleChange = event => {
    const newData = { ...data };
    newData[event.target.name] = event.target.value;
    onDataChange(newData);
    setData(newData);
  };

  const getInput = item => {
    if (item.type >= customTypeIdMeasure) {
      return (
        <LabelValueChipDialog
          name={item.field}
          fullArray={typeValues
            .filter(tv => tv.typeId === item.type)
            .map(t => ({ label: t.name, value: t.id }))}
          values={data[item.field]}
          setValues={handleChange}
          label={item.name}
        />
      );
    }

    if (numberRangeDataType.includes(item.type)) {
      return (
        <FromToDialog
          name={item.field}
          data={data[item.field]}
          setValues={handleChange}
          label={item.name}
          useDelimiter={item.type === fieldDataTypes.float}
        />
      );
    }
    return (
      <ChipInputDialog
        name={item.field}
        chipData={data[item.field]}
        setChipData={handleChange}
        hints={auditDataHints}
        label={item.name}
      />
    );
  };

  const content = () => (
    <div className={classes.fullWidth}>{auditViewData.map(getInput)}</div>
  );

  return !loading ? content() : <Loading />;
};

AuditViewForm.propTypes = {
  id: PropTypes.number,
  onDataChange: PropTypes.func.isRequired,
  initData: PropTypes.objectOf(PropTypes.any),
  onDataChanged: PropTypes.func,
};

export default AuditViewForm;
