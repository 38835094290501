import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { integrationAutotask, ticketing } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';
import crmSources from '@constants/crmSources';
import { makeRequest } from '@services/requests/makeRequest';

const getCrmOptionsByType = type =>
  getData(`${appConfig.baseUrl}${ticketing}getAsAutocomplete?type=${type}`);

export const getAtOptions = () => getCrmOptionsByType(crmSources.Autotask.name);

export const getCwOptions = () =>
  getCrmOptionsByType(crmSources.ConnectWise.name);

export const createHook = payload =>
  postWithResponse(
    `${appConfig.baseUrl}${integrationAutotask}createHook`,
    payload,
  );

export const getTypeOptions = () =>
  makeRequest('GET', `/Integrations/ConnectWise/hooks/types`);
