import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const MailBoxCardItem = ({ icon, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <div className={classes.label}>{icon}</div>
      <div className={classes.component}>{children}</div>
    </div>
  );
};

MailBoxCardItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default MailBoxCardItem;
