import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '0.625rem',
    paddingLeft: '0.5rem',
  },
  tabTitle: {
    fontSize: '1.5rem',
    textTransform: 'none',
    padding: 0,
    marginRight: '5.625rem',

    '&:last-child': {
      marginRight: 0,
    },
  },
  tabTitleFlooded: {
    textTransform: 'none',
    fontSize: '1.125rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0.5rem 1.3125rem',
    },
    padding: '0.5rem',
    color: theme.palette.info.main,
    borderRadius: '0.5rem 0.5rem 0 0',
    maxWidth: '20rem',
  },
  tabTitleSelected: {
    color: theme.palette.secondary.main,
    fontWeight: '600 !important',
  },
  tabTitleFloodedSelected: {
    backgroundColor: 'rgba(236, 106, 78, 0.2)',
    borderRadius: '0.5rem 0.5rem 0 0',
    fontWeight: '600 !important',
  },
  contentWrapper: {
    backgroundColor: props =>
      props ? 'transparent' : theme.palette.background.default,
    borderRadius: '13px',
    height: '80vh',
    overflowY: 'auto',
  },
  flexContainer: {
    flexWrap: 'wrap',
  },
}));

export default useStyles;
