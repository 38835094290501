import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { createUser } from '@services/requests/user';

export const ThunkCreateUser = createAsyncThunk(
  `${USER}/create`,
  async data => {
    return createUser(data);
  },
);

export default {};
