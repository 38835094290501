import React, { useEffect, useCallback, useMemo } from 'react';
import Pusher from 'pusher-js';
import { useDispatch } from 'react-redux';
import { addClientPortalMessage } from '@store/slices/clientPortalAdmin';
import { CLIENT_PORTAL_CHAT_DETAILS } from '@constants/routes';

const ClientPortalNotifications = ({
  pusherConfig = undefined,
  selectedChat = null,
}) => {
  const handlePusherConnection = useCallback(() => {
    let pusher;
    if (pusherConfig?.key) {
      pusher = new Pusher(pusherConfig.key, { cluster: pusherConfig.cluster });
    }
    return pusher;
  }, [pusherConfig.appId, pusherConfig.key, pusherConfig.cluster]);

  const pusher = useMemo(() => {
    return handlePusherConnection();
  }, [handlePusherConnection]);

  const tenantId = localStorage.getItem('tenantId');
  const dispatch = useDispatch();

  const onNewUserMessage = useCallback((data: INewAdminMessage): void => {
    if (
      window.location.href.includes(
        CLIENT_PORTAL_CHAT_DETAILS.replace(':id', data.chatId),
      ) ||
      Number(selectedChat) === Number(data.chatId)
    ) {
      // ADD Message to Messages
      dispatch(
        addClientPortalMessage({
          id: new Date().getTime(),
          dateTime: data.dateTime,
          message: data.message,
          type: 0,
          metadata: data.metadata,
          resourceName: null,
          contactName: data.userName,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (pusher) {
      const channel = pusher.subscribe(`tenant-${tenantId}`);
      channel.bind('NewUserMessage', onNewUserMessage);
    }
  }, [pusher, tenantId]);

  useEffect(() => {
    return () => {
      if (pusher) {
        pusher.allChannels().forEach(ch => {
          pusher.unsubscribe(ch.name);
        });
        pusher.disconnect();
      }
    };
  }, []);

  return <div />;
};

export default ClientPortalNotifications;
