import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, IconButton } from '@material-ui/core';
import { VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';

import useStyles from './styles';

const NotificationItem = ({ id, text, link, onClose }) => {
  const classes = useStyles();

  const navigate = () => {
    onClose(id);
    window.location.href = link;
  };

  return (
    <div className={classes.div}>
      <MenuItem onClick={navigate}>
        <span>{text}</span>
      </MenuItem>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => onClose(id)}
        style={{ marginRight: '13px' }}
      >
        <VisibilityOffIcon />
      </IconButton>
    </div>
  );
};

NotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationItem;
