import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkIfTicketHasChannel } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkCheckIfTicketHasChannel = createAsyncThunk(
  `${MESSAGING}/ checkIfTicketHasChannel`,
  async ({ ticketId, crmId }) => {
    const res = await checkIfTicketHasChannel(ticketId, crmId);
    return res;
  },
);

export default {};
