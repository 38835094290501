import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';

const TemplateMenu = ({
  anchorEl,
  setAnchorEl,
  form,
  name,
  templateVariables,
  overrideValue = false,
  quill,
}) => {
  const applyValue = i => {
    // If the Quill editor is used
    if (quill) {
      const position =
        typeof quill.getSelection()?.index === 'number'
          ? quill.getSelection()?.index
          : quill.getLength();
      // Insert the text at the cursor position or at the end
      quill.insertText(position, i.value);
    } else {
      // Logic for normal input
      const val = form.getFieldState(name).value || '';
      let newValue;
      if (Array.isArray(val)) {
        newValue = overrideValue ? [i.value] : [...val, i.value];
      } else {
        const textField = document.getElementById(name);
        if (typeof textField?.selectionStart === 'number') {
          const position = textField.selectionStart;
          newValue = overrideValue
            ? i.value
            : val.slice(0, position) + i.value + val.slice(position);
        } else {
          newValue = overrideValue ? i.value : `${val}${i.value}`;
        }
      }
      form.change(name, newValue);
    }

    setAnchorEl(null);
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {templateVariables.map(i => (
        <MenuItem key={i.value} onClick={() => applyValue(i)}>
          {i.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

TemplateMenu.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  setAnchorEl: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  templateVariables: PropTypes.arrayOf(PropTypes.any),
  overrideValue: PropTypes.bool,
  quill: PropTypes.object,
};

export default TemplateMenu;
