import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloSites } from '@services/requests/haloTickets';

export const ThunkGetHaloSites = createAsyncThunk(
  `${HALO_TICKET}/getHaloSites`,
  async ({ crmId, clientId = undefined, useNameOnly = false }) => {
    const sites = await getHaloSites(crmId, clientId, useNameOnly);
    return sites;
  },
);

export default {};
