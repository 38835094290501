import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOptInSmsTemplate } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

export const ThunkGetOptInSmsTemplate = createAsyncThunk(
  `${OPT_IN}/getOptInSmsTemplate`,
  async () => {
    const template = await getOptInSmsTemplate();
    return template;
  },
);

export default {};
