import React from 'react';
import { useRedirect } from 'react-admin';
import PropTypes from 'prop-types';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { TICKETING } from '@constants/routes';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import useStyles from './tileStyles';

const SettingsTile = ({
  disabled,
  icon,
  label,
  ticketSettingId,
  notificationType,
  dataViewSelected,
  additionalHandler,
  readOnly,
  input,
  redirectUrl = `${TICKETING}/${ticketSettingId}/${notificationType}`,
}) => {
  const { checked, onChange } = input;
  const deny = disabled || readOnly;
  const gray = !checked || deny;

  const classes = useStyles({ gray, deny });
  const redirect = useRedirect();

  const onClick = () => onChange(!checked);

  return (
    <div className={classes.container} onClick={onClick} role="presentation">
      <div className={classes.settingsButton}>
        <ActionButton
          disabled={disabled || !dataViewSelected}
          icon={<SettingsIcon />}
          handler={e => {
            e.stopPropagation();
            if (additionalHandler) {
              additionalHandler();
            }
            redirect(redirectUrl);
          }}
        />
      </div>
      <img src={icon} alt="" className={classes.icon} />
      <p>{label}</p>
    </div>
  );
};

SettingsTile.propTypes = {
  label: PropTypes.string.isRequired,
  ticketSettingId: PropTypes.number,
  notificationType: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any),
  readOnly: PropTypes.bool,
  icon: PropTypes.string,
  additionalHandler: PropTypes.func,
  dataViewSelected: PropTypes.bool,
  redirectUrl: PropTypes.string,
};

export default SettingsTile;
