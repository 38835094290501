import resources from './resources';

// Extract from resources elements that should be used as default enabled
const {
  documents: documentsResource,
  profile: profileResource,
  permissions: permissionsResource,
  messages: messagesResource,
  files: filesResource,
  statusBar: statusBarResource,
  chatReadMessages,
  tenants: tenantsResource,
  parsers: parsersResource,
  subscriptions: subscriptionsResource,
  apiKeys: apiKeysResource,
  localCompany: localCompanyResource,
  mailBoxes: mailBoxesResource,
  home: homeResource,
  types: typesResource,
  tenantSettings: tenantSettingsResource,
  customPalette: customPaletteResource,
  ftpConnections: ftpConnectionsResource,
  parsingStatus: parsingStatusResource,
  smsLogs: smsLogsResource,
  dataSourcesIntegrations,
  alertTemplates,
  roadMap,
  webHooks,
  customFields: customFieldsResource,
  demoReferralLinks: demoReferralLinksResource,
  coupons: couponsResource,
  crmTickets: crmTicketsResource,
  crmUsers: crmUsersResource,
  billingLinks: billingLinksResource,
  autotaskHooks: autotaskHooksResource,
  connectWiseHooks: connectWiseHooksResource,
  notificationGroups: notificationGroupsResource,
  genericSources: genericSourceResource,
  chats: chatResources,
  tenantStatistic: tenantStatisticResources,
} = resources;
const getPermissionsList = resourcesList =>
  resourcesList.reduce((obj, id) => ({ ...obj, [id]: { id, name: id } }), {});

// These resources are available for whatever user role
export const defaultPermissions = getPermissionsList([
  documentsResource,
  chatResources,
  profileResource,
  permissionsResource,
  messagesResource,
  filesResource,
  statusBarResource,
  chatReadMessages,
  mailBoxesResource,
  homeResource,
  typesResource,
  roadMap,
  smsLogsResource,
]);

export const adminPermissions = getPermissionsList([
  tenantsResource,
  parsersResource,
  subscriptionsResource,
  parsingStatusResource,
  demoReferralLinksResource,
  couponsResource,
  billingLinksResource,
]);

export const settingsPermissions = getPermissionsList([
  tenantSettingsResource,
  customPaletteResource,
  apiKeysResource,
  localCompanyResource,
  ftpConnectionsResource,
  customFieldsResource,
  crmTicketsResource,
  crmUsersResource,
  notificationGroupsResource,
  tenantStatisticResources,
]);

export const dataSourcesPermissions = getPermissionsList([
  webHooks,
  dataSourcesIntegrations,
  alertTemplates,
  autotaskHooksResource,
  connectWiseHooksResource,
  genericSourceResource,
]);

export const basePermissions = {
  update: 'update',
  create: 'create',
  read: 'read',
  delete: 'delete',
  readMine: 'readMine',
  readAll: 'readAll',
  comments: 'comments',
};
