import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIncident } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';
import { normalizeIncident } from '../generator';

export const ThunkGetIncident = createAsyncThunk(
  `${INCIDENT}/getIncident`,
  async id => {
    const incident = await getIncident(id);
    const logs = incident.logs.split(';');
    return {
      incident: normalizeIncident(incident),
      logs: logs.map(log => ({ text: log })),
    };
  },
);

export default {};
