import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormLabel, RadioGroup } from '@material-ui/core';

import Radio from '@common/Radio/Radio';

const RadioInput = ({ label = '', choices = [], input, meta }) => {
  const isError = meta && meta.error && meta.touched;
  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <div style={isError && { borderBottom: '2px solid red' }}>
        <RadioGroup row value={input.value} onChange={input.onChange}>
          {choices.map(({ choice }) => (
            <FormControlLabel
              key={choice}
              value={choice}
              label={choice}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </div>
    </>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default RadioInput;
