import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmTicketCompanies } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetAutotaskTicketCompanies = createAsyncThunk(
  `${CRM_TICKET}/getCrmTicketCompanies`,
  async crmId => {
    const companies = await getCrmTicketCompanies(crmId);
    return companies;
  },
);

export default {};
