import * as React from 'react';
import PropTypes from 'prop-types';
import { VpnKey as VpnKeyIcon } from '@material-ui/icons';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconEdit from '@assets/icons/edit.svg';
import iconDelete from '@assets/icons/delete.svg';
import iconShare from '@assets/icons/share.svg';

const ActionField = ({
  record = {},
  handleUpdateClick,
  handleDeleteClick,
  isUserAdmin,
  handleMakeGlobal,
}) => {
  return (
    <>
      <ActionButton
        icon={<img src={iconEdit} alt="iconEdit" />}
        handler={() => handleUpdateClick(record)}
      />
      <ActionButton
        icon={<img src={iconDelete} alt="iconDelete" />}
        handler={() => handleDeleteClick(record)}
        disabled={record.isGlobal}
      />
      {isUserAdmin && (
        <ActionButton
          icon={
            !record.isGlobal ? (
              <img src={iconShare} alt="iconShare" />
            ) : (
              <VpnKeyIcon color="primary" />
            )
          }
          handler={() => handleMakeGlobal(record.id, !record.isGlobal)}
          toolTip={record.isGlobal ? 'Make tenant' : 'Make global'}
        />
      )}
    </>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleUpdateClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleMakeGlobal: PropTypes.func.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
};

export default ActionField;
