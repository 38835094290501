import React from 'react';
import PropTypes from 'prop-types';

export const GeneralContext = React.createContext(null);

const GeneralContextProvider = ({ children, ...props }) => (
  <GeneralContext.Provider value={{ ...props }}>
    {children}
  </GeneralContext.Provider>
);

GeneralContextProvider.propTypes = {
  children: PropTypes.node,
};
export default GeneralContextProvider;
