import React from 'react';

import useStyles from './styles';

const PodPlaceholder = () => {
  const classes = useStyles();

  return (
    <div className={classes.textContainer}>
      Support for this Pod will be deprecated on
      <b className={classes.textWraps}>January 31, 2025</b>
      after that time, it will cease to function. Please contact our Support
      team at
      <a
        href="mailto:help@support.mspprocess.com"
        className={classes.textWraps}
      >
        help@support.mspprocess.com
      </a>
      to migrate to the new version of the Pod, so that your team is not
      impacted.
    </div>
  );
};

export default PodPlaceholder;
