import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketAgreements } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketAgreements = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketAgreements`,
  async ({ crmId, companyId }) => {
    const agreements = await getConnectWiseTicketAgreements(crmId, companyId);
    return agreements;
  },
);

export default {};
