import React, { useCallback, useEffect, useMemo, useState } from 'react';

import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';
import { TableTypes } from '@components/Table/constants';
import { customTypeIdMeasure } from '@constants/numericConstants';

import Loading from '@common/Loading/Loading';
import Table from '@components/Table';

import getFormattedDate from '@utils/getFormattedDate';

import ActionFields from '@components/Audit/ActionFields';
import AuditPageTableActions from '@components/Audit/AuditPageTableActions';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import DetailsDialog from '@components/Audit/recordDetails/DetailsDialog';
import BulkActionButtons from '@common/BulkActionButtons';
import { deletePolicyUri } from '@constants/appRoutes';
import {
  getAuditView,
  getAuditViewAutocomplete,
  getHintUsers,
  getTypeValues,
} from './helpers';

import useStyles from './styles';

const AuditPage = () => {
  const [loading, setLoading] = useState(true);
  const [currentView, setCurrentView] = useState({ label: '', value: 0 });
  const [options, setOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [fields, setFields] = useState([]);
  const [typeValues, setTypeValues] = useState([]);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const classes = useStyles();

  const defaultSorting = useMemo(() => {
    const firstIdentifier = fields.find(i => i.identifier);

    return {
      fieldName: firstIdentifier,
      order: 'ASC',
    };
  }, [fields]);

  const columns = useMemo(() => {
    if (!fields.length) return [];

    return fields
      .map(field => {
        return {
          name: field.name,
          key: field.field,
          sortable: true,
          searchable: true,
          isCustomAccessor:
            field.type === 3 || field.type >= customTypeIdMeasure,
          type: field.type >= customTypeIdMeasure ? TableTypes.dropdown : null,
          dropDownValues:
            field.type >= customTypeIdMeasure
              ? typeValues
                  .filter(i => i.typeId === field.type)
                  .map(i => ({ value: i.id, label: i.name }))
              : [],
          filterByAutocomplete: field.field,
          accessor: row => {
            if (field.type === 3) {
              return getFormattedDate(row[field.field]);
            }

            if (field.type >= customTypeIdMeasure) {
              return (
                typeValues.find(i => i.id === row[field.field])?.name ?? ''
              );
            }

            return row[field.field];
          },
        };
      })
      .concat([
        {
          name: 'Assigned',
          key: 'assignment',
          minWidth: 200,
        },
        {
          name: 'Actions',
          key: 'actions',
          isCustomAccessor: true,
          minWidth: 200,
          width: 200,
          accessor: rowData => (
            <ActionFields
              handleUpdateClick={row => {
                setDetailsDialogOpen(row);
              }}
              record={rowData}
              handleRefresh={() => {}}
              users={users}
              dataViewId={currentView.value}
            />
          ),
        },
      ]);
  }, [currentView.value, fields, typeValues, users]);

  const getDataView = useCallback(dataViewId => {
    getAuditView(dataViewId).then(res => {
      const parsedData = Object.values(JSON.parse(res.data));

      if (parsedData.some(i => i.type >= customTypeIdMeasure)) {
        const types = parsedData
          .filter(i => i.type >= customTypeIdMeasure)
          .map(i => `typeIds=${i.type}`)
          .join('&');

        getTypeValues(types).then(r => {
          setTypeValues(r);
        });
      }

      setFields(parsedData);
    });
  }, []);

  const handleAuditViewChange = value => {
    if (value) {
      setCurrentView(value);
      getDataView(value.value);
      localStorage.setItem(localStorageConst.SELECTED_AUDITVIEW, value);
    } else {
      setCurrentView({ label: '', value: 0 });
      localStorage.removeItem(localStorageConst.SELECTED_AUDITVIEW);
    }
  };

  const onMount = useCallback(() => {
    const storageData = localStorage.getItem(
      localStorageConst.SELECTED_AUDITVIEW,
    );

    setLoading(true);

    Promise.all([getAuditViewAutocomplete(), getHintUsers()])
      .then(([dataViewAutocomplete, usersAutocomplete]) => {
        setOptions(dataViewAutocomplete);
        setUsers(usersAutocomplete);

        if (storageData?.value) {
          setCurrentView(storageData);
          getDataView(storageData.value);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={classes.wrap}>
      <Table
        actionsComponent={
          <div className={classes.actions}>
            <ViewAutoComplete
              options={options}
              onChange={handleAuditViewChange}
              currentView={currentView}
            />
            {currentView?.value && (
              <AuditPageTableActions dataViewId={currentView?.value} />
            )}
          </div>
        }
        bulkActions={
          <BulkActionButtons
            users={users}
            url={deletePolicyUri}
            showAssignment
            dataViewId={currentView.value}
          />
        }
        resource={`UserData/grouping/${currentView.value}`}
        isDisplayTable={!!currentView.value && !loading}
        columns={columns ?? []}
        defaultFilters={{ hideAcknowledged: false }}
        defaultSorting={defaultSorting}
      />

      {!!detailsDialogOpen && (
        <DetailsDialog
          isOpen
          onClose={() => {
            setDetailsDialogOpen(false);
          }}
          onRefresh={() => {}}
          record={detailsDialogOpen}
          fields={fields}
        />
      )}
    </div>
  );
};

export default AuditPage;
