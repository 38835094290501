import React, { useCallback, useEffect } from 'react';
import { AppBar as RaAppBar, useAuthProvider, useRedirect } from 'react-admin';
import { IconButton, Toolbar } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { HOME_ROUTE, INVITATION } from '@constants/routes';
import LocalStorage from '@constants/localStorage';

import { getTenantImageFromStorage, parseJwtToken } from '@services/api';
import UserMenu from './UserMenu';

import useStyles from './styles';

const AppBar = props => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const userId = authProvider.getUserId();
  const invitationData = authProvider.getCompanyInvitationData();
  const redirect = useRedirect();
  const logoUrl = sessionStorage.getItem(LocalStorage.BASE_APP_LOGO);
  const userData = useSelector(state => state.user.currentUser);
  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  const handleInvitation = useCallback(() => {
    if (userId && invitationData) {
      redirect(INVITATION);
    }
  }, [userId, invitationData, redirect]);

  useEffect(() => {
    handleInvitation();
  }, [handleInvitation]);

  useEffect(() => {
    if (userData?.id && token) {
      const claims = parseJwtToken(token);

      window.pendo.initialize({
        visitor: {
          id: +userData.id,
          email: userData.email,
          fullName: userData.username,
        },
        account: {
          id: userData.tenantId,
          tenantName: claims.tenantAlias,
        },
      });
    }
  }, [userData?.id, token]);

  return (
    <RaAppBar {...props} className={classes.rootClass} userMenu={<UserMenu />}>
      <Toolbar style={{ width: '100%' }} className={classes.toolbar}>
        <IconButton
          className={classes.button}
          onClick={() => {
            redirect(HOME_ROUTE);
          }}
        >
          <img
            alt="logo"
            src={getTenantImageFromStorage() || logoUrl}
            className={classes.logo}
          />
        </IconButton>
      </Toolbar>
    </RaAppBar>
  );
};

export default AppBar;
