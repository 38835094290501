import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  ThunkGetPsaContactsAutocomplete,
  ThunkGetMessagingConfiguration,
  ThunkGetMessagingTemplates,
  ThunkAddMessagingChannel,
  ThunkGetTemplateVariables,
  ThunkGetPsaCompaniesAutocomplete,
} from '@store/slices/messaging/thunks';
import {
  MessagingPsaCompaniesSelector,
  MessagingPsaContactsSelector,
  MessagingTemplatesSelector,
  MessagingTemplateVariablesSelector,
} from '@store/slices/messaging';

import { MESSAGING_CHANNELS } from '@constants/routes';
import { requiredValidator } from '@utils/validators';

import iconPlus from '@assets/icons/plusGrey.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import DropDown from '@ui/components/Auth/Common/DropDown';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import { maxLengthProps } from '@utils/maxLengthProps';

import useStyles from './styles';

const CreateMessagingChannel = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const psaContacts = useSelector(MessagingPsaContactsSelector);
  const psaCompanies = useSelector(MessagingPsaCompaniesSelector);
  const templates = useSelector(MessagingTemplatesSelector);
  const templateVariables = useSelector(MessagingTemplateVariablesSelector);

  const messagingTemplatesAutocomplete = useMemo(
    () =>
      !loading
        ? templates.map(template => ({
            label: template.subject,
            value: template.id,
          }))
        : [],
    [loading, templates],
  );

  const submit = async values => {
    const payload = {
      messagingConfigurationId: match.params.configurationId,
      psaContact: psaContacts.find(c => c.value === values.psaContactId)
        ?.rawName,
      psaCompany: psaCompanies.find(c => c.value === values.psaCompanyId)
        ?.label,
      ...values,
    };
    setProcessing(true);
    await dispatch(
      ThunkAddMessagingChannel({
        configId: match.params.configurationId,
        payload,
      }),
    )
      .unwrap()
      .then(() => {
        push(MESSAGING_CHANNELS);
        enqueueSnackbar('Successfully added', { variant: 'success' });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const phoneValidation = phone => {
    const regEx = /^[+][0-9]{11}/gm;
    if (!phone) {
      return 'Required';
    }
    if (!regEx.test(phone)) {
      return 'Wrong format';
    }
    return undefined;
  };

  const validate = values => {
    return {
      message: requiredValidator(values.message),
      userPhone: phoneValidation(values.userPhone),
      subject: requiredValidator(values.subject),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      Promise.resolve(
        dispatch(
          ThunkGetPsaCompaniesAutocomplete(match.params.configurationId),
        ),
      ),
      Promise.resolve(dispatch(ThunkGetMessagingTemplates())),
      Promise.resolve(
        dispatch(ThunkGetMessagingConfiguration(match.params.configurationId)),
      ),
      Promise.resolve(dispatch(ThunkGetTemplateVariables())),
    ]);
    setLoading(false);
  }, [match.params.configurationId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handlePsaCompanyChange = useCallback(
    async companyId => {
      dispatch(
        ThunkGetPsaContactsAutocomplete({
          configId: match.params.configurationId,
          companyId,
        }),
      );
    },
    [dispatch],
  );

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.twoColumnsContainer}>
            <FieldWrapper
              label="PSA Company"
              labelSize={12}
              contentSize={12}
              fullWidth
              classNameLabelContainer={classes.dialogLabelContainer}
              classNameLabelInner={classes.dialogLabelText}
              content={
                <Field
                  name="psaCompanyId"
                  id="psaCompanyId"
                  render={AutocompleteFormInput}
                  limitTags={1}
                  items={psaCompanies}
                  loading={processing}
                />
              }
            />

            <OnChange name="psaCompanyId">{handlePsaCompanyChange}</OnChange>

            <FieldWrapper
              label="PSA Contact"
              labelSize={12}
              contentSize={12}
              fullWidth
              classNameLabelContainer={classes.dialogLabelContainer}
              classNameLabelInner={classes.dialogLabelText}
              content={
                <Field
                  name="psaContactId"
                  id="psaContactId"
                  render={AutocompleteFormInput}
                  limitTags={1}
                  items={psaContacts}
                  loading={processing || !values.psaCompanyId}
                />
              }
            />
            <OnChange name="psaContactId">
              {value =>
                form.change(
                  'userPhone',
                  psaContacts.find(i => i.value === value)?.info,
                )
              }
            </OnChange>
          </div>

          <FieldWrapper
            label="Message Template"
            labelSize={12}
            contentSize={12}
            fullWidth
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <Field
                name="messageTemplate"
                id="messageTemplate"
                render={DropDown}
                options={messagingTemplatesAutocomplete}
                disabled={processing}
              />
            }
          />
          <OnChange name="messageTemplate">
            {value => {
              form.change(
                'message',
                templates.filter(item => item.id === value)[0].description,
              );
              form.change(
                'subject',
                templates.filter(item => item.id === value)[0].subject,
              );
            }}
          </OnChange>

          <FieldWrapper
            label="Phone Number"
            labelSize={12}
            contentSize={12}
            fullWidth
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            isRequired
            infoText="Format should look like: +12345678900"
            content={
              <Field
                name="userPhone"
                id="userPhone"
                render={Input}
                type="phone"
                disabled={processing}
                dateProps={{ maxLength: 12 }}
              />
            }
          />

          <FieldWrapper
            label="Subject"
            labelSize={12}
            contentSize={12}
            fullWidth
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            dateProps={{ maxLength: 150 }}
            isRequired
            content={
              <Field
                name="subject"
                id="subject"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Body"
            labelSize={12}
            contentSize={12}
            showLabel
            isRequired
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="message"
                  id="message"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  {...maxLengthProps(values.message)}
                  disabled={processing}
                />
              </div>
            }
          />

          <div className={classes.dialogActions}>
            <ReusableButton
              label="Cancel"
              onClick={() => {
                push(MESSAGING_CHANNELS);
              }}
              disabled={processing}
            />
            <ReusableButton
              label="Submit"
              type="submit"
              disabled={processing}
              loading={processing}
            />
          </div>

          <TemplateMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            form={form}
            name="message"
            templateVariables={templateVariables}
          />
        </form>
      )}
    />
  );
};

CreateMessagingChannel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      configurationId: PropTypes.string,
    }),
  }),
};

export default CreateMessagingChannel;
