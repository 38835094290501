export const dashboardPeriods = Object.freeze([
  { label: 'Last day', value: 0 },
  { label: '3 days', value: 1 },
  { label: '7 days', value: 2 },
  { label: '1 month', value: 3 },
  { label: 'Custom', value: 4 },
]);

export const messagingDashboardTypes = Object.freeze([
  { label: 'Channels', value: 0 },
  { label: 'Messages', value: 1 },
]);

export const dashboardTypes = Object.freeze({
  channels: 0,
  messages: 1,
});
export default {};
