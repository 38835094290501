import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import useStyles from '../styles';

const CrmList = ({
  localCrmList,
  item,
  path,
  change,
  localCompaniesCrmList,
  crmLength,
}) => {
  const classes = useStyles({ count: crmLength });

  const getCrmCompanyId = value =>
    item.bindings.find(i => i.crmId === value)?.crmCompanyId;

  const pathToSaveBinding = crmId => {
    const staticPath = `${path}.bindings`;
    const index = item.bindings.findIndex(d => d.crmId === crmId);
    const currentIndex = index !== -1 ? index : item.bindings.length;
    return `${staticPath}[${currentIndex}]`;
  };

  const getStaticPath = () => {
    const staticPath = `${path}.bindings`;
    return staticPath;
  };

  const getCrmOptions = value =>
    localCompaniesCrmList.find(el => el.crmId === value)?.companyOptions;

  const handleChange = (crmCompanyId, crmId) => {
    const newPath = pathToSaveBinding(crmId);
    if (crmCompanyId) {
      change(newPath, {
        crmId,
        crmCompanyId,
      });
    } else {
      change(
        getStaticPath(),
        item.bindings.filter(i => i.crmId !== crmId),
      );
    }
  };

  return (
    <FieldArray name="localCrmList">
      {({ fields }) =>
        fields.map((piece, index) => {
          const { value } = localCrmList[index];
          const options = getCrmOptions(value);
          const crmCompanyId = getCrmCompanyId(value);

          return (
            <div key={piece} className={classes.rowCell}>
              <Field
                name={piece}
                styleType="main"
                component={ViewAutoComplete}
                textFieldLabel={null}
                currentView={options.find(i => i.value === crmCompanyId)}
                onChange={i => handleChange(i?.value, value)}
                options={options}
                formControlClassNameWrapper={classes.dropDown}
                className={classes.fullWidth}
              />
            </div>
          );
        })
      }
    </FieldArray>
  );
};

CrmList.propTypes = {
  localCompaniesCrmList: PropTypes.arrayOf(PropTypes.object),
  localCrmList: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.shape({
    localCrmList: PropTypes.arrayOf(PropTypes.object),
  }),
  path: PropTypes.string,
  change: PropTypes.func,
  item: PropTypes.shape({
    bindings: PropTypes.arrayOf(
      PropTypes.shape({
        crmId: PropTypes.number,
        crmCompanyId: PropTypes.number,
      }),
    ),
  }),
  crmLength: PropTypes.number,
};

export default CrmList;
