import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, FormSpy } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import HooksTable from '@components/DataSources/AlertForm/components/HooksTable';
import HookCreator from '@components/DataSources/AlertForm/components/HookCreator';
import EmailSelector from '@components/DataSources/AlertForm/components/EmailSelector';
import ChipDialog from '@common/ChipDialog/ChipDialog';
import Checkbox from '@common/Checkbox/FormControlCheckBox';
import RadioInput from '@components/RadioInput/RadioInput';
import ReusableButton from '@common/Button/Button';
import emailsTemplateBehaviourOptions from '@constants/emailsTemplateBehaviourOptions';
import AlertsList from '@components/DataSources/AlertForm/AlertList/AlertsList';
import AccordionItem from '@components/DataSources/AlertForm/components/AccordionItem';

import useStyles from './formStyles';

const choices = Object.values(emailsTemplateBehaviourOptions).map(i => {
  return { choice: i };
});

const tabs = [
  { name: 'hookEntities', caption: 'Alert hook entities' },
  { name: 'generalSettings', caption: 'General alert settings' },
  { name: 'alerts', caption: 'Alerts' },
];

const AlertHookForm = ({
  hooks,
  onHookDelete,
  onHooksSubmit,
  emails,
  setEmails,
  selectedHooks,
  setSelectedHooks,
  onSubmit,
  formInit,
  onCancel,
  setFormValues,
  alerts,
  setAlerts,
  useAsForm = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(tabs[0].name);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <AccordionItem
        name={tabs[0].name}
        expanded={expanded}
        header={tabs[0].caption}
        handleChange={handleChange}
      >
        <Paper className={classes.paperRoot}>
          <span>Generate hooks</span>
          <HooksTable data={hooks} onDelete={onHookDelete} />
          <HookCreator hooks={hooks} onSubmit={onHooksSubmit} />
        </Paper>
      </AccordionItem>
      <AccordionItem
        name={tabs[1].name}
        expanded={expanded}
        header={tabs[1].caption}
        handleChange={handleChange}
      >
        <Paper className={classes.paperRoot}>
          <span>Alert emails</span>
          <EmailSelector data={emails} setData={setEmails} />
          <div className={classes.marginTop}>
            <span>Alert Hooks</span>
            <ChipDialog
              name="Chip"
              values={selectedHooks}
              fullArray={hooks.map(i => i.name)}
              setValues={e => setSelectedHooks(e.target.value)}
            />
          </div>
          <Form
            onSubmit={onSubmit}
            initialValues={formInit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div>
                  <Field
                    id="snmp"
                    name="snmp"
                    label="Use SNMP"
                    type="checkbox"
                    component={Checkbox}
                  />
                  <Field
                    id="allAdmins"
                    name="allAdmins"
                    label="Alert all network admins"
                    type="checkbox"
                    component={Checkbox}
                  />
                </div>
                <div className={classes.radioGroup}>
                  <span className={classes.radioLabel}>
                    Emails template behaviour
                  </span>
                  <Field
                    id="emailsTemplateBehaviour"
                    name="emailsTemplateBehaviour"
                    component={RadioInput}
                    choices={choices}
                  />
                </div>
                <div className={classes.radioGroup}>
                  <span className={classes.radioLabel}>
                    Hooks template behaviour
                  </span>
                  <Field
                    id="hooksTemplateBehaviour"
                    name="hooksTemplateBehaviour"
                    component={RadioInput}
                    choices={choices}
                  />
                </div>
                <FormSpy onChange={form => setFormValues(form.values)} />
              </form>
            )}
          />
        </Paper>
      </AccordionItem>
      <AccordionItem
        name={tabs[2].name}
        expanded={expanded}
        header={tabs[2].caption}
        handleChange={handleChange}
      >
        <Paper className={classes.paperRoot}>
          <AlertsList hooks={hooks} alerts={alerts} setAlerts={setAlerts} />
        </Paper>
      </AccordionItem>
      <div className={classes.buttonWrapper}>
        <ReusableButton
          size="md"
          type="button"
          label="Cancel"
          onClick={onCancel}
          classNameWrapper={classes.button}
        />
        <ReusableButton
          size="md"
          viewType="black"
          type="button"
          label={useAsForm ? 'Apply' : 'Submit'}
          classNameWrapper={classes.button}
          onClick={onSubmit}
        />
      </div>
    </>
  );
};

AlertHookForm.propTypes = {
  hooks: PropTypes.arrayOf(PropTypes.any),
  emails: PropTypes.arrayOf(PropTypes.any),
  selectedHooks: PropTypes.arrayOf(PropTypes.any),
  formInit: PropTypes.objectOf(PropTypes.any),
  onHookDelete: PropTypes.func,
  onHooksSubmit: PropTypes.func,
  setEmails: PropTypes.func,
  setSelectedHooks: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  setFormValues: PropTypes.func,
  alerts: PropTypes.objectOf(PropTypes.any),
  setAlerts: PropTypes.func,
  useAsForm: PropTypes.bool,
};

export default AlertHookForm;
