import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getVerificationSettingsDuration } from '@services/requests/verificationSettings';

export const ThunkGetVerificationDuration = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getVerificationDuration`,
  async () => {
    const res = await getVerificationSettingsDuration();
    return res;
  },
);

export default {};
