import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: 35,
    width: '100%',
    background: theme.palette.yourPlan.progressBar.background,
    borderRadius: 2,
    border: '1px solid #FF6E4F',
  },
  filler: {
    top: '0',
    left: '0',
    position: 'absolute',
    height: '100%',
    width: props => `${props.completed}%`,
    background: 'linear-gradient(157.47deg, #FF8A71 4.14%, #FF4C25 116.22%)',
    borderRadius: props => (props.completed >= 100 ? '1px' : '2px 5px 5px 2px'),
    textAlign: 'right',
  },
  label: {
    top: '0',
    left: '0',
    position: 'absolute',
    paddingTop: 8,
    color: theme.palette.textColor,
    fontWeight: 'bold',
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
}));

export default useStyles;
