import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px 16px 0 16px',
    '& tbody': {
      maxHeight: '71vh',
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
  },
}));

export default useStyles;
