import { createAsyncThunk } from '@reduxjs/toolkit';
import { getKaseyaLocations } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetKaseyaLocation = createAsyncThunk(
  `${CRM_TICKET}/getKaseyaLocations`,
  ({ psaId, companyId }) => getKaseyaLocations(psaId, companyId),
);

export default {};
