import { makeStyles } from '@material-ui/core/styles';

const IFRAME_SIZE = '800px';
const CLOSE_ICON_SIZE = '30px';

const useStyles = makeStyles(() => ({
  iframeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    width: IFRAME_SIZE,
    height: IFRAME_SIZE,
    background: 'white',
    borderRadius: '5px',
  },
  iframe: {
    width: props => (props.loading ? '1px' : '100%'),
    height: props => (props.loading ? '1px' : '100%'),
    borderRadius: '5px',
    border: 'none',
  },
  button: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContent: {
    display: 'flex',
    gap: '5px',
    '& svg': {
      '& path': {
        fill: 'white',
      },
    },
  },
  closeIcon: {
    cursor: 'pointer',
    width: CLOSE_ICON_SIZE,
    height: CLOSE_ICON_SIZE,
    '& path': {
      fill: 'white',
    },
  },
}));

export default useStyles;
