import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFIED_PSA_TECH_BY_SMS } from '@store/slices/resources';
import { getContactTickets } from '@services/requests/psaTechVerification';

export const ThunkGetContactTickets = createAsyncThunk(
  `${VERIFIED_PSA_TECH_BY_SMS}/getContactTickets`,
  async payload => getContactTickets(payload),
);

export default {};
