import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
    '& > img': {
      width: '50px',
      height: '50px',
      animation: `spin 1.6s infinite linear`,
    },
  },
  '@global': {
    '@keyframes spin': {
      '0%': {
        transform: 'rotate( 0deg )',
      },
      '100%': {
        transform: 'rotate( 360deg )',
      },
    },
  },
}));

export default useStyles;
