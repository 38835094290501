export const notificationDataViewSelector = ({ notifications }) =>
  notifications.dataViewId;

export const notificationSettings = ({ notifications }) =>
  notifications.setting;
export const ticketSettings = ({ notifications }) =>
  notifications.ticketSettings;
export const emailSettings = ({ notifications }) => notifications.emailSettings;
export const smsSettings = ({ notifications }) => notifications.smsSettings;
export const webHookSettings = ({ notifications }) =>
  notifications.webHookSettings;
export const slackSettings = ({ notifications }) => notifications.slackSettings;
export const teamsSettings = ({ notifications }) => notifications.teamsSettings;
export const ftpSettings = ({ notifications }) => notifications.ftpSettings;
export const voiceSettings = ({ notifications }) => notifications.voiceSettings;
export const notificationSettingSource = ({ notifications }) =>
  notifications.setting?.notificationSettingSource;
