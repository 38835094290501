import React from 'react';
import PropTypes from 'prop-types';

import InfoBox from '@common/InfoBox/InfoBox';
import useStyles from './ignoreModelStyles';

const IgnoreModalFormElement = ({ label, children, infoText }) => {
  const classes = useStyles();
  return (
    <div className={classes.field}>
      <div className={classes.label}>
        {label}
        <InfoBox text={infoText} />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

IgnoreModalFormElement.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  infoText: PropTypes.string.isRequired,
};

export default IgnoreModalFormElement;
