import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconEdit from '@assets/icons/edit.svg';
import iconDelete from '@assets/icons/delete.svg';

import useStyles from './styles';

const ExpressionItem = ({ item, onItemEdit, onItemDelete }) => {
  const classes = useStyles();

  const onEditClick = () => onItemEdit(item);
  const onDeleteClick = () => onItemDelete(item.id);

  return (
    <div className={classes.containerExpression}>
      <div className={classes.text}>
        <div>{item.name}</div>
        <div className={classes.itemValueContainer}>{item.value}</div>
      </div>
      <div className={classes.icons}>
        <ActionButton
          icon={<img src={iconEdit} alt="iconEdit" />}
          handler={onEditClick}
        />
        <ActionButton
          icon={<img src={iconDelete} alt="iconDelete" />}
          handler={onDeleteClick}
        />
      </div>
    </div>
  );
};

ExpressionItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onItemEdit: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
};

export default ExpressionItem;
