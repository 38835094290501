import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import removeIcon from '@assets/icons/remove.svg';
import acceptIcon from '@assets/icons/accept.svg';
import moment from 'moment';
import { useListContext } from 'react-admin';
import useStyles from './styles';

const TableDatePicker = ({
  setIsOpen,
  isOpen,
  field,
  date,
  dateDispatcher,
}) => {
  const { setFilters, filterValues } = useListContext();

  const classes = useStyles();

  const sortKeys = {
    from: `${field}_From`,
    to: `${field}_To`,
  };
  const onChange = dates => {
    const [start, end] = dates;
    dateDispatcher({ type: 'change', payload: { start, end } });
  };
  const configureFilters = () => {
    const obj = {
      [sortKeys.from]: moment(date.start)
        .minutes(0)
        .hours(0)
        .utc()
        .toISOString(true),
      [sortKeys.to]: moment(date.end)
        .minutes(59)
        .hours(23)
        .utc()
        .toISOString(true),
    };
    setFilters(obj, filterValues, true);
  };

  const dropFilters = () => {
    const obj = {
      [sortKeys.from]: null,
      [sortKeys.to]: null,
    };

    setFilters(obj, filterValues, true);
  };

  const handleClearRange = () => {
    dateDispatcher({ type: 'reset' });
    dropFilters();
    setIsOpen(!isOpen);
  };

  const handleConfirm = () => {
    configureFilters();
    setIsOpen(!isOpen);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DatePicker
        selected={date.start}
        onChange={onChange}
        startDate={date.start}
        endDate={date.end}
        selectsRange
        inline
      >
        <div className={classes.calendarButtons}>
          <ActionFieldItem
            icon={removeIcon}
            alt="removeIcon"
            handler={handleClearRange}
            toolTip="Clear"
          />
          <ActionFieldItem
            icon={acceptIcon}
            alt="acceptIcon"
            handler={handleConfirm}
            toolTip="Select range"
          />
        </div>
      </DatePicker>
    </Dialog>
  );
};

TableDatePicker.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  field: PropTypes.string,
  dateDispatcher: PropTypes.func,
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default TableDatePicker;
