/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { MESSAGING_DASHBOARD } from '../resources';

import {
  ThunkGetMessagingChannelsStatistic,
  ThunkGetRecentlyCreatedChannels,
  ThunkGetMessagingDashboardCharts,
  ThunkGetMessagingCrmStatistic,
} from './thunks';

const initialState = {
  recentChannels: [],
  statistic: {},
  chart: {},
  crmPieStatistic: [],
};

const messagingDashboardSlice = createSlice({
  name: MESSAGING_DASHBOARD,
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetRecentlyCreatedChannels.fulfilled,
        (state, { payload }) => {
          state.recentChannels = payload;
        },
      )

      .addCase(
        ThunkGetMessagingChannelsStatistic.fulfilled,
        (state, { payload }) => {
          state.statistic = payload;
        },
      )

      .addCase(
        ThunkGetMessagingDashboardCharts.fulfilled,
        (state, { payload }) => {
          state.chart = payload;
        },
      )

      .addCase(
        ThunkGetMessagingCrmStatistic.fulfilled,
        (state, { payload }) => {
          state.crmPieStatistic = payload;
        },
      );
  },
});

export default messagingDashboardSlice.reducer;

export const MessagingDashboardChannelsSelector = state =>
  state[MESSAGING_DASHBOARD].recentChannels;
export const MessagingDashboardChannelsStatistic = state =>
  state[MESSAGING_DASHBOARD].statistic;
export const MessagingDashboardChart = state =>
  state[MESSAGING_DASHBOARD].chart;
export const MessagingDashboardCrmPieStatistic = state =>
  state[MESSAGING_DASHBOARD].crmPieStatistic;
