import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    height: '100%',
    maxWidth: '100%',
    padding: '15px',
    maxHeight: '90vh',
    gridTemplateRows: '1fr 1fr',
    overflow: 'auto',
  },
  firstRowContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxHeight: '450px',
    '& > div': {
      background: '#F5F5F5',
      margin: '10px',
      borderRadius: '15px',
    },
  },
  secondRowContainer: {
    maxHeight: '40vh',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& > div': {
      background: '#F5F5F5',
      margin: '10px',
      borderRadius: '15px',
    },
  },
  block: {
    display: 'grid',
    gridTemplateRows: '50px 1fr',
    padding: '15px',
    overflow: 'auto',
  },
  blockHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 10px',
  },
  blockHeaderActions: {
    '& > button': {
      marginLeft: '10px',
    },
  },
  detailsBody: {
    display: 'grid',
    padding: '10px',
  },
  detailsBlockItem: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: '1fr 5fr',
  },
  blockHeaderTitle: {
    fontSize: '18px',
    fontWeight: '800',
  },
  noteBlock: {
    padding: '10px',
  },
  noteItem: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 10fr 1fr',
    background: '#c8c8c852',
    margin: '5px',
    padding: '5px 10px',
    borderRadius: '10px',
  },
  noteItemText: {
    padding: '5px',
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
  },
  noteItemUserName: {
    fontWeight: '800',
  },
  noteItemDate: {
    fontSize: '10px',
    color: '#8a8a8a',
  },
  noteItemActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > svg': {
      color: theme.palette.appBar.icon,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
