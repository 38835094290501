import appConfig from '@configs/appConfig';
import deleteWithResponse from '@services/api/common/deleteWithResponse';
import getData from '@services/api/common/getData';
import postWithResponse from '@services/api/common/postWithResponse';

const resource = 'AutotaskWebHooks';

export const createAutotaskWebhook = payload =>
  postWithResponse(`${appConfig.baseUrl}/${resource}`, payload);

export const deleteAutotaskWebhook = id =>
  deleteWithResponse(`${appConfig.baseUrl}/${resource}/${id}`);

export const getAutotaskIntegrationAutocomplete = () =>
  getData(`${appConfig.baseUrl}/${resource}/getIntegrations`);

export default {};
