import { createAsyncThunk } from '@reduxjs/toolkit';
import { TICKET_CREATION_TEMPLATES } from '@store/slices/resources';
import { editTicketCreationTemplate } from '@services/requests/ticketCreationTemplates';

const generatePayload = values => ({
  ...values,
  data: {
    ...values.data,
    createChannel: values.data.createChannel || false,
  },
});

export const ThunkUpdateTicketCreationTemplate = createAsyncThunk(
  `${TICKET_CREATION_TEMPLATES}/editTicketCreationTemplate`,
  ({ templateId, psaType, payload }) =>
    editTicketCreationTemplate(templateId, psaType, generatePayload(payload)),
);

export default {};
