import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '72vh',
    },
  },
  userGridContainer: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '42vh',
    },
  },
  toolBar: {
    marginRight: '8px',
  },
  buttonGroup: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
  },
  buttonWrapper: {
    margin: '0 0.2rem',
  },
  editForm: {
    padding: '15px',
    margin: '1rem',
    width: '500px',
  },
  editUserDialog: {
    minWidth: '35rem',
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  checkboxWrapper: {
    '&  .MuiFormControl-root': {
      border: 'none',
    },
    '& label': {
      margin: '8px 0',
      justifyContent: 'flex-end',
      '& .MuiTypography-root': {
        fontWeight: 700,
      },
    },
  },
}));

export default useStyles;
