import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getChatMessages } from '@services/requests/liveChats';

export const ThunkGetChatMessages = createAsyncThunk(
  `${LIVE_CHATS}/getChatMessages`,
  ({ id, userOnly = false }) => getChatMessages(id, userOnly),
);

export default {};
