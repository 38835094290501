import { createAsyncThunk } from '@reduxjs/toolkit';
import { startTimer } from '@services/requests/ticketTimer';
import { TICKET_TIMER } from '@store/slices/resources';

export const ThunkStartTimer = createAsyncThunk(
  `${TICKET_TIMER}/startTimer`,
  async ({ payload }) => {
    const res = await startTimer(payload);
    return res;
  },
);

export default {};
