import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Loading from '@common/Loading/Loading';
import Table from '@components/Table';
import { ThunkGetLogRecordTypes } from '@store/slices/logRecords/thunks/getLogRecordTypes';
import { ThunkGetLogsCount } from '@store/slices/logRecords/thunks';
import ReusableButton from '@common/Button/Button';
import getFormattedDate from '@utils/getFormattedDate';
import { TableTypes } from '@components/Table/constants';
import GenericSourcesLogsTableActions from '@components/GenericSources/pages/GenericSourcesLogs/components/GenericSourcesLogsTableActions';

import useStyles from './styles';

const GenericSourceLogs = ({ match }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [logReportTypesOptions, setLogReportTypesOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const logReportTypes = useMemo(
    () =>
      logReportTypesOptions.reduce((accumulator, currentItem) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[currentItem.value] = currentItem.label;
        return accumulator;
      }, {}),
    [logReportTypesOptions],
  );

  const dispatch = useDispatch();

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetLogRecordTypes())
      .unwrap()
      .then(res => {
        setLogReportTypesOptions(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = useMemo(
    () => [
      {
        searchable: false,
        sortable: true,
        name: 'Date and Time',
        key: 'dateTime',
        width: 200,
        isCustomAccessor: true,
        accessor: row => <>{getFormattedDate(row.dateTime)}</>,
      },
      {
        name: 'Log Type',
        key: 'logType',
        width: 175,
        minWidth: 175,
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: logReportTypesOptions,
        isCustomAccessor: true,
        filterByAutocomplete: 'logType',
        accessor: row => <>{logReportTypes[row.logType]}</>,
      },
      {
        searchable: false,
        name: 'Message',
        key: 'message',
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        accessor: row => <GenericSourcesLogsTableActions record={row} />,
      },
    ],
    [logReportTypes, logReportTypesOptions],
  );

  useLayoutEffect(() => {
    if (match.params.id) {
      dispatch(ThunkGetLogsCount(match.params.id))
        .unwrap()
        .then(res => {
          setTotalCount(res);
        });
    }
  }, [match?.params?.id]);

  if (loading) return <Loading />;
  return (
    <div className={classes.tableContainer}>
      <ReusableButton
        label="Refresh"
        onClick={onMount}
        classNameWrapper={classes.button}
      />
      <Table
        defaultSort={{ fieldName: 'dateTime', order: 'DESC' }}
        resource={`LogRecords/getGenericSourceLogs/${match.params.id}`}
        columns={columns}
        totalCount={totalCount}
      />
    </div>
  );
};

GenericSourceLogs.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default GenericSourceLogs;
