import { createAsyncThunk } from '@reduxjs/toolkit';
import { FILE_UPLOAD } from '@store/slices/resources';
import { getAutotaskCompanies } from '@services/requests/fileUpload';

export const ThunkGetAutotaskPsaCompanies = createAsyncThunk(
  `${FILE_UPLOAD}/getAutotaskPsaCompanies`,
  async ({ psaId }) => {
    return getAutotaskCompanies(psaId);
  },
);

export default {};
