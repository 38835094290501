import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { sendUserInvites } from '@services/requests/usersInvite';

export const ThunkSendUsersInvites = createAsyncThunk(
  `${USER}/sendUsersInvite`,
  ({ payload }) => sendUserInvites(payload),
);

export default {};
