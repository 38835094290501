import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteMessagingConfiguration } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkDeleteMessagingConfiguration = createAsyncThunk(
  `${MESSAGING}/deleteMessagingConfiguration`,
  async id => {
    await deleteMessagingConfiguration(id);
    return id;
  },
);

export default {};
