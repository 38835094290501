import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dangerZone: {
    border: '1px solid red',
    borderRadius: '3px',
    minHeight: '5rem',
    fontStyle: 'normal',
    backgroundColor: '#f407071c',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    marginTop: '1rem',
    width: 'auto',
  },

  resultView: {
    minHeight: '25px',
    fontStyle: 'normal',
    textAlign: 'center',
    margin: '10px',
  },
  saveButton: {
    marginTop: '1rem',
    textAlign: 'end',
  },
  padding: {
    padding: '0 15px',
  },
  leftText: {
    padding: '45px 20px',

    fontStyle: 'normal',
  },
  checkboxLabel: {
    '& span:last-child': {
      fontSize: '0.875rem',
    },
  },
  queryBuilderWrapper: {
    width: '100%',
  },
}));

export default useStyles;
