import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import useStyles from '@common/Styles/actionFieldStyles';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

const ActionField = ({ onEdit, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ActionFieldItem handler={onEdit} toolTip="Edit">
        <img src={EditIcon} alt="edit" />
      </ActionFieldItem>
      <ActionFieldItem handler={onDelete} toolTip="Delete">
        <img src={DeleteIcon} alt="delete" />
      </ActionFieldItem>
    </div>
  );
};

ActionField.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionField;
