export const merakiBasic = {
  version: '0.1',
  sharedSecret: 'secret',
  sentAt: '2021-10-07T08:38:40.522804Z',
  organizationId: '2930418',
  organizationName: 'My organization',
  organizationUrl:
    'https://dashboard.meraki.com/o/VjjsAd/manage/organization/overview',
  networkId: 'N_24329156',
  networkName: 'Main Office',
  networkUrl: 'https://n1.meraki.com//n//manage/nodes/list',
  networkTags: [],
  deviceSerial: 'Q234-ABCD-5678',
  deviceMac: '00:11:22:33:44:55',
  deviceName: 'My access point',
  deviceUrl: 'https://n1.meraki.com//n//manage/nodes/new_list/000000000000',
  deviceTags: [],
  deviceModel: 'MR',
  alertId: '0000000000000000',
  alertType: 'APs came up',
  alertTypeId: 'started_reporting',
  alertLevel: 'informational',
  occurredAt: '2018-02-11T00:00:00.123450Z',
  alertData: {},
};

export default {};
