import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { updateKaseyaTicket } from '@services/requests/kaseyaTicket';

export const ThunkUpdateKaseyaTicket = createAsyncThunk(
  `${KASEYA_TICKET}/updateKaseyaTicket`,
  ({ crmDefaultId, payload }) => updateKaseyaTicket(crmDefaultId, payload),
);

export default {};
