import { makeRequest } from '../makeRequest';
import { TICKET_TIMER } from '../requestResources';

export const startTimer = payload =>
  makeRequest('POST', `${TICKET_TIMER}/start`, payload);

export const stopTimer = payload =>
  makeRequest('POST', `${TICKET_TIMER}/stop`, payload);

export const getTimer = payload =>
  makeRequest('POST', `${TICKET_TIMER}/get`, payload);
