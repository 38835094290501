import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  addButton: {
    marginBottom: '8px',
  },
  copyLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    alignItems: 'flex-end',
  },
}));

export default useStyles;
