import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import Checkbox from '@common/Checkbox/FormControlCheckBox';
import InfoBox from '@common/InfoBox/InfoBox';
import ReusableButton from '@common/Button/Button';
import ackTypes from '@constants/ackTypes';
import useStyles from '../styles';

const AckDialog = ({ open, onClose, onSelect }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const onSubmit = () =>
    onSelect(checked ? ackTypes.leaveAsIs : ackTypes.resetAck);
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        Acknowledge confirmation
      </DialogTitle>
      <DialogContent>
        <Checkbox
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label="keep acknowledged"
        />
        <InfoBox text="By default new result will reset acknowledgement" />
      </DialogContent>
      <DialogActions>
        <ReusableButton
          label="Cancel"
          classNameWrapper={classes.button}
          onClick={onClose}
        />
        <ReusableButton
          viewType="black"
          label="Confirm"
          classNameWrapper={classes.button}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

AckDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AckDialog;
