import { requiredValidator } from '@utils/validators';

const handleErrors = values => {
  const url = values.useConnection ? undefined : requiredValidator(values.url);
  const teamsConnectionId = values.useConnection
    ? requiredValidator(values.teamsConnectionId)
    : undefined;
  return {
    url,
    teamsConnectionId,
    template: requiredValidator(values.template),
  };
};
export default handleErrors;
