import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: '80vh',
    width: '60vw',
    overflow: 'auto',
    padding: '2rem',
  },
  baseContainer: {
    justifyContent: 'flex-start',
  },
  imageStyles: {
    maxHeight: '5rem',
  },
}));

export default useStyles;
