import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import crmSources from '@constants/crmSources';
import AutotaskSubForm from './AutotaskSubForm';
import ConnectwiseSubForm from './ConnectwiseSubForm';
import KaseyaSubForm from './KaseyaSubForm';

const SubFormSelector = ({ selectedCrmType }) => {
  const getForm = useCallback(() => {
    switch (selectedCrmType.crmType) {
      case crmSources.Autotask.name:
        return <AutotaskSubForm crmId={selectedCrmType?.crmId} />;
      case crmSources.ConnectWise.name:
        return <ConnectwiseSubForm crmId={selectedCrmType?.crmId} />;
      case crmSources.Kaseya.name:
        return <KaseyaSubForm crmId={selectedCrmType?.crmId} />;
      default:
        return null;
    }
  }, []);

  return getForm(selectedCrmType);
};

SubFormSelector.propTypes = {
  selectedCrmType: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SubFormSelector;
