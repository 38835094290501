/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, isPending } from '@reduxjs/toolkit';
import { ThunkDeletePhoneNumber } from '@store/slices/phoneNumbers/thunks/deletePhoneNumber';
import { PHONE_NUMBERS } from '../resources';
import { ThunkGetPhoneNumbers, ThunkBuyPhoneNumber } from './thunks';
import { normalizePhoneNumber } from './generator';

const initialState = {
  list: [],
  isLoading: true,
};

const phoneNumbersSlice = createSlice({
  name: PHONE_NUMBERS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ThunkBuyPhoneNumber.fulfilled, (state, action) => {
        state.list.push(normalizePhoneNumber(action.payload));
        state.isLoading = false;
      })

      .addCase(ThunkBuyPhoneNumber.rejected, state => {
        state.isLoading = false;
      })

      .addMatcher(isPending(ThunkGetPhoneNumbers), state => {
        state.isLoading = true;
      })

      .addMatcher(isPending(ThunkBuyPhoneNumber), state => {
        state.isLoading = true;
      })
      .addMatcher(
        isAnyOf(
          ThunkGetPhoneNumbers.fulfilled,
          ThunkDeletePhoneNumber.fulfilled,
        ),
        (state, { payload }) => {
          state.list = payload;
          state.isLoading = false;
        },
      );
  },
});

export default phoneNumbersSlice.reducer;

export const PhoneNumbersListSelector = state => state[PHONE_NUMBERS].list;
export const PhoneNumbersLoadingSelector = state =>
  state[PHONE_NUMBERS].isLoading;
