import React from 'react';
import PropTypes from 'prop-types';
import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import {
  sophosCategories,
  sophosProducts,
  sophosSeverity,
  tableKeys,
} from '@common/MuiVirtualizedTable/constants';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import DateAccessor from '@common/MuiVirtualizedTable/components/accessors/DateAccessor';
import LongTextAccessor from '@common/MuiVirtualizedTable/components/accessors/LongTextAccessor';

const columns = [
  {
    key: 'tenantName',
    name: <TableLabel item={{ name: 'Tenant', field: 'tenantName' }} />,
    width: 300,
  },
  {
    key: 'raisedAt',
    name: 'Raised at',
    sort: 'raisedAt',
    accessor: row => <DateAccessor value={row.raisedAt} />,
    width: 150,
  },
  {
    key: 'severity',
    name: (
      <TableDropDown
        item={{ field: 'severity', name: 'severity' }}
        options={sophosSeverity}
        labelHandler={key => sophosSeverity[key].name}
      />
    ),
    width: 150,
  },
  {
    key: 'category',
    name: (
      <TableDropDown
        item={{ field: 'category', name: 'category' }}
        options={sophosCategories}
        labelHandler={key => sophosCategories[key].name}
      />
    ),
    width: 150,
  },
  {
    key: 'product',
    name: (
      <TableDropDown
        item={{ field: 'product', name: 'product' }}
        options={sophosProducts}
        labelHandler={key => sophosProducts[key].name}
      />
    ),
    width: 150,
  },
  {
    key: 'description',
    name: <TableLabel item={{ name: 'description', field: 'description' }} />,
    accessor: row => <LongTextAccessor value={row.description} />,
  },
];

const SophosAlertGrid = ({ data }) => (
  <div style={{ height: 650, width: '100%' }}>
    <MuiVirtualizedListView
      tableKey={tableKeys.sophosDashboard}
      data={data}
      columns={columns}
      defaultSort={{ field: 'raisedAt', order: 'DESC' }}
    />
  </div>
);

SophosAlertGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default SophosAlertGrid;
