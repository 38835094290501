import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import ActionField from '@common/ActionFields/ActionField';
import { Grid } from '@material-ui/core';
import { deleteMicrosoftAuthIntegration } from '@services/requests/securityIntegrations';
import { actions } from '@store/actions';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import useStyles from './styles';

const MicrosoftAuthenticatorSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState({ isOpen: false });

  const deleteHandler = () => {
    deleteMicrosoftAuthIntegration(deleteDialogOpen.id).then(({ error }) => {
      if (!error) {
        dispatch(actions.removeItemFromList({ id: deleteDialogOpen.id }));
        setDeleteDialogOpen({ isOpen: false });
        enqueueSnackbar('Microsoft Auth Configuration was deleted', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(error, { variant: 'error' });
      }
    });
  };

  const columns = [
    {
      name: 'Microsoft Tenant Name',
      key: 'msTenantName',
      sortable: true,
      searchable: true,
      width: 300,
    },
    {
      name: 'Domain',
      key: 'domainName',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Created On',
      key: 'createdOn',
      isCustomAccessor: true,
      sortable: true,
      width: 250,
      accessor: rowData => (
        <div>{moment(rowData.createdOn).format('MM/DD/YYYY hh:mm A')}</div>
      ),
    },
    {
      name: 'End Date',
      key: 'endDateTime',
      isCustomAccessor: true,
      sortable: true,
      width: 250,
      accessor: rowData => (
        <div>{moment(rowData.endDateTime).format('MM/DD/YYYY hh:mm A')}</div>
      ),
    },
    {
      name: 'Actions',
      width: 100,
      key: TableTypes.actions,
      type: TableTypes.actions,
      accessor: rowData => (
        <Grid container justifyContent="center">
          <ActionField
            record={rowData.id}
            onDelete={id => {
              setDeleteDialogOpen({ isOpen: true, id });
            }}
          />
        </Grid>
      ),
    },
  ];

  return (
    <>
      <Table
        resource="/MicrosoftAuthSetupConfiguration"
        columns={columns}
        className={classes.table}
      />
      <ConfirmDialog
        open={deleteDialogOpen.isOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={deleteHandler}
        onCancel={() => setDeleteDialogOpen({ isOpen: false })}
        title="Delete current alert"
        content="Warning! This operation will delete current integration and cannot be undone"
      />
    </>
  );
};

export default MicrosoftAuthenticatorSettings;
