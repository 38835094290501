import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ViewIcon from '@assets/icons/visibility.svg';

import GenericSourcesLogsDetails from '@components/GenericSources/pages/GenericSourcesLogs/components/GenericSourcesLogsDetails';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

const GenericSourcesLogsTableActions = ({ record }) => {
  const [logDetailsOpen, setLogsDetailsOpen] = useState(false);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <ActionFieldItem
        alt="Logs"
        handler={() => setLogsDetailsOpen(true)}
        toolTip="Logs"
        icon={ViewIcon}
      />
      {logDetailsOpen && (
        <GenericSourcesLogsDetails
          open={logDetailsOpen}
          setOpen={setLogsDetailsOpen}
          recordId={record?.id}
        />
      )}
    </div>
  );
};

GenericSourcesLogsTableActions.propTypes = {
  record: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    tenantId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    stackTrace: PropTypes.string,
    requestExecutionDetails: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default GenericSourcesLogsTableActions;
