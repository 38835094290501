import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import { useDispatch, useSelector } from 'react-redux';

import { requiredValidator } from '@utils/validators';
import {
  ThunkAddMessagingTemplate,
  ThunkGetTemplateVariables,
  ThunkUpdateMessagingTemplate,
} from '@store/slices/messaging/thunks';
import { MessagingTemplateVariablesSelector } from '@store/slices/messaging';

import iconPlus from '@assets/icons/plusGrey.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';

import { MAX_SMS_VALUE_COUNT } from '@constants/common';

import useStyles from '../styles';

const AddMessagingTemplate = ({ open, setOpen, item, setItem }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const templateVariables = useSelector(MessagingTemplateVariablesSelector);

  const submit = async values => {
    setProcessing(true);
    if (!item) {
      await dispatch(ThunkAddMessagingTemplate({ payload: values }))
        .unwrap()
        .then(() => {
          setOpen(false);
          setItem(undefined);
        });
    } else {
      await dispatch(
        ThunkUpdateMessagingTemplate({ payload: values, id: item.id }),
      )
        .unwrap()
        .then(() => {
          setOpen(false);
          setItem(undefined);
        });
    }

    setProcessing(false);
  };

  const validate = values => {
    return {
      description: requiredValidator(values.description),
      subject: requiredValidator(values.subject),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetTemplateVariables());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      size="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        {item ? 'Edit ' : 'Add '}
        Template
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            validate={validate}
            initialValues={item || null}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Subject"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelContainer={classes.dialogLabelContainer}
                  classNameLabelInner={classes.dialogLabelText}
                  classNameContainer={classes.dialogTemplateContainer}
                  isRequired
                  content={
                    <Field
                      name="subject"
                      id="subject"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Description"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelContainer={classes.dialogLabelContainer}
                  classNameLabelInner={classes.dialogLabelText}
                  classNameContainer={classes.dialogTemplateContainer}
                  isRequired
                  content={
                    <div className={classes.dialogTemplate}>
                      <ActionButton
                        handler={event => setAnchorEl(event.currentTarget)}
                        icon={<img src={iconPlus} alt="iconPlus" />}
                        toolTip="Add description variable"
                        disabled={processing}
                      />
                      <Field
                        name="description"
                        id="description"
                        render={Input}
                        multiline
                        minRows={3}
                        maxRows={5}
                        dateProps={{ maxLength: MAX_SMS_VALUE_COUNT }}
                        helperText={`${
                          values.description ? values.description.length : 0
                        }/${MAX_SMS_VALUE_COUNT}`}
                        disabled={processing}
                      />
                    </div>
                  }
                />

                <div className={classes.dialogActions}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      setOpen(false);
                      setItem(undefined);
                    }}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Submit"
                    type="submit"
                    loading={processing}
                    disabled={processing}
                  />
                </div>
                <TemplateMenu
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  form={form}
                  name="description"
                  templateVariables={templateVariables}
                />
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AddMessagingTemplate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    template: PropTypes.string,
    subject: PropTypes.string,
  }),
  setItem: PropTypes.func,
};

export default AddMessagingTemplate;
