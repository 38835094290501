import React from 'react';
import { ReactComponent as DashboardIcon } from '@assets/icons/dashboard.svg';
import MainPage from './pages/List';

const Icon = () => <DashboardIcon alt="roadmap" />;

const webHooks = {
  list: MainPage,
  icon: Icon,
};

export default webHooks;
