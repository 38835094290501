import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '30px',
    flexGrow: 1,
    fontStyle: 'normal',
  },
  qrCodeStyle: {
    width: '250px',
    height: '250px',
    border: '1px solid black',
  },
}));

export default useStyles;
