import { createAsyncThunk } from '@reduxjs/toolkit';

import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { exportVerificationHistory } from '@services/requests/verificationHistory';

export const ThunkExportVerificationHistory = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/exportVerificationHistory`,
  ({ type }) => exportVerificationHistory(type),
);

export default {};
