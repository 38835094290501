import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  classNameStatisticItem: {
    display: 'flex',
    alignItems: 'center',
  },
  labelTooltipWeight: {
    fontWeight: 'normal',
  },
  labelTooltipColor: {
    color: theme.palette.secondary.main,
  },
  paperContainer: {
    padding: '1.875rem 0.5rem',
    margin: '0 auto',
    width: '100%',
    borderRadius: 0,
  },
  tooltipContainer: {
    width: 'max-content',
    padding: '5px',
    border: '1px solid #c9c8c7',
    background: 'white',
    fontSize: '.75rem',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    },
    height: '400px',
  },
  innerPaperContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  eventContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    color: '#fff',
    backgroundColor: props => props.color,
  },
  icon: {
    color: '#fff',
  },
  statisticContainer: {
    fontSize: '0.75rem',
    marginRight: '1.25rem',
  },
  statisticContainer_header: {
    backgroundColor: '#e7e7e7',
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #bbbbbb',
  },
  statisticContainer_outerSpacingTop: {
    marginTop: '10px',
  },
  statisticContainer_innerSpacing: {
    padding: '10px',
  },
  bold: {
    fontWeight: 'bold',
  },
  leftSpacing: {
    marginLeft: '.5rem',
  },
  info: {
    color: theme.palette.info.main,
  },
  timeInfo: {
    color: props => props.color,
    fontWeight: 'bold',
    fontSize: '.8rem',
  },
  warning: {
    color: theme.palette.secondary.dark,
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  colorBlock: {
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    color: props => props.statusColor,
  },
  detailsEventsContainer: {
    marginTop: '0.5rem',
  },
}));

export default useStyles;
