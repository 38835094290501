import { makeStyles } from '@material-ui/core';

const getTextColor = hex => {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (rgb) {
    const normalized = rgb
      ? {
          r: parseInt(rgb[1], 16),
          g: parseInt(rgb[2], 16),
          b: parseInt(rgb[3], 16),
        }
      : null;

    const brightness = Math.round(
      (parseInt(normalized.r) * 299 +
        parseInt(normalized.g) * 587 +
        parseInt(normalized.b) * 114) /
        1000,
    );

    return brightness > 125 ? 'black' : 'white';
  }
};

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '15px',
    height: '100%',
    width: '100%',
  },
  formContainer: {
    width: '60%',
    margin: '2px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '15px',
    padding: '25px',
    background: 'white',
    borderRadius: '15px',
    '& div': {
      marginTop: '3px',
    },
    height: '80vh',
    overflow: 'auto',
    paddingBottom: '20px',
  },
  doubledContainer: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      '& div': {
        marginRight: '5px',
      },
    },
  },
  intervalsContainer: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      '& p': {
        margin: '0px 10px',
      },
    },
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px !important',
    '& button': {
      marginRight: '10px',
    },
  },
  scheduleItem: {
    width: '100%',
    borderRadius: '5px',
    paddingLeft: '5px',
    cursor: 'pointer',
    backgroundColor: ({ color }) => color,
    color: ({ color }) => getTextColor(color),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerDropDown: {
    width: '20%',
    display: 'flex',
    marginRight: '15px',
  },
  headerButton: {
    marginLeft: '15px',
  },
  numberContainer: {
    width: '15%',
  },
  dateContainer: {
    width: '15%',
  },
  dialog: {
    width: '100%',
    height: '90vh !important',
    padding: '10px 0px',
  },
  dropDownSelector: {
    width: '100%',
  },
  fixedWidth: {
    width: '100%',
    maxWidth: '119px',
    textAlign: 'left',
  },
  testCalendarWrapper: {
    width: '95%',
    height: '95%',
    margin: '0 auto',
  },
  dialogButtonContainer: {
    width: '95%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '15px',
  },
}));

export default useStyles;
