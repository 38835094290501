import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notificationSettings } from '@store/selectors';
import {
  emailSettings,
  slackSettings,
  smsSettings,
  teamsSettings,
  ticketSettings,
  webHookSettings,
} from '@store/selectors/notifications';
import useAsync from '@services/api/common/useAsync';
import {
  createNotificationGroup,
  getAlertDescription,
  getCompaniesOptions,
} from '@components/Ticketing/Notifications/DmSettings/helpers';
import { actions } from '@store/actions';
import useAllowUpdate from '@components/Ticketing/Notifications/hooks/useAllowUpdate';
import useStyles from '@components/Ticketing/Notifications/DmSettings/stylesDmSettingsForm';
import { Field, Form } from 'react-final-form';
import {
  handleDisabled,
  handleGroupErrors,
  loadGroupData,
} from '@components/Ticketing/Notifications/DmSettings/utils';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@common/Input/Input';
import { deviceThresholdTypes } from '@components/DeviceMonitors/components/helpers';
import cx from 'classnames';
import SwitchComponent from '@common/FilterInputs/Switch';
import tileData from '@components/Ticketing/Notifications/utils';
import SettingsTile from '@components/Ticketing/Notifications/components/SettingsTile';
import { NOTIFICATION_SETTING } from '@constants/routes';
import ReusableButton from '@common/Button/Button';
import iconArrow from '@assets/icons/arrowGrey.svg';
import Paper from '@common/Paper/Paper';
import InputWithAutocomplete from '@ui/components/common/Input/InputWithAutocomplete';
import appConfig from '@configs/appConfig';
import { enqueueSnackbar } from 'notistack';
import { TEXT_CREATE_SUCESS } from '@constants/texts/common';

const DmSettingGroupFormCreate = () => {
  const isAllowedUpdate = useAllowUpdate();
  const [loading, setLoading] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  useAsync(getCompaniesOptions, setCompaniesOptions);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const flexibleDataLoader = (loadedData, storedData) => {
    const data = Object.keys(storedData).length ? storedData : loadedData;
    return loadGroupData(data);
  };

  const inMemorySetting = useSelector(notificationSettings);
  const inMemoryTicketSetting = useSelector(ticketSettings);
  const inMemoryEmailSetting = useSelector(emailSettings);
  const inMemorySmsSetting = useSelector(smsSettings);
  const inMemoryWebHookSetting = useSelector(webHookSettings);
  const inMemorySlackSetting = useSelector(slackSettings);
  const inMemoryTeamsSetting = useSelector(teamsSettings);

  const initData = useMemo(() => flexibleDataLoader(null, inMemorySetting), [
    inMemorySetting,
  ]);

  const handleBack = () => history.goBack();

  const submit = values => {
    const data = {
      ...values,
      ticket: inMemoryTicketSetting,
      email: inMemoryEmailSetting,
      sms: inMemorySmsSetting,
      webHook: inMemoryWebHookSetting,
      slack: inMemorySlackSetting,
      teams: inMemoryTeamsSetting,
      companies: values.companies,
    };
    setLoading(true);
    createNotificationGroup(data)
      .then(() => {
        enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Unable to create ticket default', {
          variant: 'error',
        });
      })
      .finally(() => handleBack());
  };

  const storeDataToRedux = formData => dispatch(actions.saveSetting(formData));

  const handleAllMailBoxesChange = values => {
    return form => {
      if (values.includes(0)) {
        const allIds = companiesOptions.map(mb => mb.value);
        form.change('companies', allIds);
      }
    };
  };

  const companiesOptionArray = useMemo(
    () => [
      { value: 0, label: 'Select All' },
      ...companiesOptions.sort((a, b) => (a.value > b.value ? 1 : -1)),
    ],
    [companiesOptions],
  );

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={handleGroupErrors}
        initialValues={initData}
        render={({ handleSubmit, values, form }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            className={classes.formWrapper}
          >
            <div className={classes.contentFormWrapper}>
              <FieldWrapper
                label="Companies for notification"
                labelSize={false}
                contentSize={12}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                infoText="You should have device monitor with selected company"
                content={
                  <InputWithAutocomplete
                    name="companies"
                    options={companiesOptionArray}
                    loading={loading}
                    disableCloseOnSelect
                    onChange={value => {
                      handleAllMailBoxesChange(value)(form);
                    }}
                    freeSolo={false}
                    getOptionValue={i =>
                      typeof i === 'object'
                        ? i.value
                        : companiesOptions.find(m => m.value === i)?.value
                    }
                    getOptionLabel={i =>
                      typeof i === 'object'
                        ? i.label
                        : companiesOptions.find(m => m.value === i)?.label
                    }
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                  />
                }
              />
              <FieldWrapper
                label="Number of Uptime Checks"
                labelSize={false}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                infoText={`With ${appConfig.projectName} Uptime monitoring you can delay notifications until a specific number of failures has been reached. For example maybe you only want a ticket if the last 5 ping tests failed.`}
                content={
                  <Field
                    id="lastN"
                    name="lastN"
                    placeholder="Last number"
                    styleType="main"
                    inputView="number"
                    min={1}
                    type="number"
                    fullWidth
                    classNameWrapper={classes.inputWrapper}
                    component={Input}
                    disabled={loading}
                  />
                }
              />

              <FieldWrapper
                label="Type"
                labelSize={false}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                infoText={
                  form.getFieldState('type')?.value === '1'
                    ? 'Milliseconds Threshold (ms) monitor allows a user to set a threshold of how many times a monitor is above a certain value. For example if pinging google you may want to alert if the last 3 pings were above 500ms as it may indicate a connectivity issue that should be checked into.'
                    : 'With MSP Process Uptime monitoring you can delay notifications until a specific number of failures has been reached. For example maybe you only want a ticket if the last 5 ping tests failed. '
                }
                content={
                  <Field
                    id="type"
                    name="type"
                    placeholder="Type"
                    options={deviceThresholdTypes}
                    component={DropDown}
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="Value"
                labelSize={false}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                content={
                  <Field
                    name="value"
                    id="value"
                    placeholder="Value"
                    styleType="main"
                    inputView="number"
                    min={1}
                    type="number"
                    fullWidth
                    classNameWrapper={cx(
                      classes.inputWrapper,
                      classes.disabledInput,
                    )}
                    component={Input}
                    disabled={handleDisabled(form)}
                  />
                }
              />
              <FieldWrapper
                label="Notify about recovering"
                classNameLabelInner={classes.classNameLabelInner}
                content={
                  <Field
                    id="notifyAboutRecovering"
                    name="notifyAboutRecovering"
                    placeholder="Notify about recovering"
                    classNameWrapper={classes.inputWrapper}
                    input={{
                      checked: values.notifyAboutRecovering,
                      onChange: (_, value) =>
                        form.change('notifyAboutRecovering', value),
                    }}
                    component={SwitchComponent}
                    disabled={loading}
                  />
                }
              />
              <div className={classes.textDecorator}>
                <span>
                  {getAlertDescription(
                    values.lastN,
                    values.type,
                    values.value,
                    values.notifyAboutRecovering,
                  )}
                </span>
              </div>
              <div className={classes.tilesSpace}>
                {tileData
                  .filter(i => i.id !== 'isFtp')
                  .map(i => (
                    <Field
                      key={i.id}
                      type="checkbox"
                      id={i.id}
                      name={i.id}
                      component={SettingsTile}
                      disabled={loading}
                      label={i.label}
                      ticketSettingId={initData.id}
                      notificationType={i.notifyType}
                      redirectUrl={`${NOTIFICATION_SETTING}/${initData.id}/${i.notifyType}`}
                      dataViewSelected
                      additionalHandler={() => storeDataToRedux(values)}
                      readOnly={!isAllowedUpdate}
                      icon={i.icon}
                    />
                  ))}
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  Back
                </>
              </ReusableButton>
              {isAllowedUpdate && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                >
                  Create
                </ReusableButton>
              )}
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

export default DmSettingGroupFormCreate;
