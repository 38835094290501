import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT } from '@store/slices/resources';
import { getTenantSettingsPhoneNumbers } from '@services/requests/common';

export const ThunkGetTenantSettingsPhoneNumbers = createAsyncThunk(
  `${TENANT}/getTenantSettingsPhoneNumbers`,
  async () => getTenantSettingsPhoneNumbers(),
);

export default {};
