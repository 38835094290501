import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { useListContext, TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import { sortOrders, COUNT_PRODUCT_PER_PAGE } from '@constants/filters';
import useStyles from './style';

const TableLabel = ({
  item: { field, name },
  numbersOnly = false,
  showSortButton = true,
}) => {
  const classes = useStyles();

  const {
    setFilters,
    filterValues,
    currentSort,
    setSort,
    setPerPage: setCountData,
    basePath,
  } = useListContext();

  const isCurrentFieldSorted = currentSort.field === field;
  const filters = JSON.parse(sessionStorage.getItem(basePath)) || {};

  const [inputValue, setInputValue] = useState(
    filterValues[field] || filters[field] || '',
  );

  useEffect(() => {
    if (!filterValues[field] && !filters[field]) {
      setInputValue('');
    }
  }, [filterValues, field, filters]);

  const onChange = e => {
    const { value } = e.target;
    if (numbersOnly && Number.isNaN(+value)) {
      return;
    }
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setInputValue(value);
    setFilters({ [field]: value }, filterValues, true);

    filters[field] = value;
    sessionStorage.setItem(basePath, JSON.stringify(filters));
  };

  const setSorting = () => {
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setSort(field);
    setFilters(filterValues, filterValues, true);
  };

  const classNameForButtonIcon = cx(classes.tableHeaderButtonIcon, {
    [classes.tableHeaderButtonIconRotate]:
      isCurrentFieldSorted && currentSort.order === sortOrders.asc,
  });

  useEffect(() => {
    setFilters(filters, filterValues, true);
  }, []);

  return (
    <Form onSubmit={() => {}} initialValues={{ [field]: inputValue }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.tableHeaderForm}>
          {showSortButton && (
            <Button onClick={setSorting} className={classes.tableHeaderButton}>
              <ArrowUpward className={classNameForButtonIcon} />
            </Button>
          )}
          <TextInput
            className={classes.tableHeaderInput}
            source={field}
            label=""
            placeholder={name}
            onChange={onChange}
            inputProps={{
              'data-lpignore': true,
            }}
            autoComplete="off"
          />
        </form>
      )}
    </Form>
  );
};

TableLabel.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default TableLabel;
