import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_MAPPINGS } from '@store/slices/resources';
import { addClientPortalDefaultMappings } from '@services/requests/clientPortal';

export const ThunkAddClientPortalDefaultsMappings = createAsyncThunk(
  `${CLIENT_PORTAL_MAPPINGS}/addClientPortalDefaultsMappings`,
  ({ id, payload }) => addClientPortalDefaultMappings(id, payload),
);

export default {};
