import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Input from '@common/Input/Input';
import { Field } from 'react-final-form';

const ZendeskForm = ({ classes, loading, useLabels }) => {
  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>URL:</Typography>
      )}
      <Field
        id="uri"
        name="uri"
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="PSA URL"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>User Name:</Typography>
      )}
      <Field
        id="publicKey"
        name="publicKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="UserName"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>API Token:</Typography>
      )}
      <Field
        id="privateKey"
        name="privateKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="API token"
        disabled={loading}
      />
    </>
  );
};

ZendeskForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default ZendeskForm;
