import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateBlockedMessage } from '@services/requests/messaging';
import { SMS_BLOCKED } from '@store/slices/resources';

export const ThunkUpdateBlockedMessage = createAsyncThunk(
  `${SMS_BLOCKED}/updateBlockedMessage`,
  async data => updateBlockedMessage(data),
);

export default {};
