import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../styles';

const ListItem = ({ title, text, color }) => {
  const classes = useStyles();

  return (
    <div className={classes.listItem}>
      <div>{title}</div>
      <div style={{ color: color ?? 'black' }}>{text}</div>
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default ListItem;
