import moment from 'moment';
import { requiredValidator } from '@utils/validators';
import { monthShortNames } from '@constants/monthShortNames';
import { daysOfWeekArray } from '@constants/daysOfWeek';

export const initial = {
  color: '#000000',
  startDate: moment(new Date())
    .set('D', 1)
    .format('YYYY-MM-DD'),
  endDate: moment(new Date())
    .set('D', 1)
    .add(1, 'M')
    .format('YYYY-MM-DD'),
  months: monthShortNames,
  daysOfWeek: daysOfWeekArray,
  weekStart: moment(new Date()).format('YYYY-MM-DD'),
  dayStartWeek: moment(new Date()).format('YYYY-MM-DD'),
  numberOfWeeks: 1,
  numberOfDays: 1,
  weekNumber: 1,
};

export const validate = values => {
  return {
    userId: requiredValidator(values.userId) ? 'Required' : undefined,
    title: requiredValidator(values.title) ? 'Required' : undefined,
    startDate: requiredValidator(values.startDate) ? 'Required' : undefined,
    endDate: requiredValidator(values.endDate) ? 'Required' : undefined,
    timeFrom: requiredValidator(values.timeFrom) ? 'Required' : undefined,
    timeTo: requiredValidator(values.timeTo) ? 'Required' : undefined,
  };
};
