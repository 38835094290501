import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  table: {
    margin: '36px auto 0',
    width: '97%',
    maxHeight: '85%',
  },
  root: {
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
