import appConfig from '@configs/appConfig';

const retriveData = async (url, apiKey, method, body) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('x-api-key', apiKey);
  const request = new Request(url, {
    method,
    body,
    headers,
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    throw new Error('Unauthorized request');
  }
  if (response.status < 200 || response.status >= 300) {
    const errorData = await response.json();
    throw errorData || response.statusText;
  }

  return response.json();
};

const getData = async (url, apiKey) => retriveData(url, apiKey, 'GET', null);

const postData = async (url, apiKey, body) =>
  retriveData(url, apiKey, 'POST', JSON.stringify(body));

export const getBillingEmail = apiKey =>
  getData(`${appConfig.baseUrl}/billingPublic/billingEmail`, apiKey);

export const getBillingCustomer = apiKey =>
  getData(`${appConfig.baseUrl}/billingPublic/billingCustomer`, apiKey);

export const updateEmail = (apiKey, data) =>
  postData(`${appConfig.baseUrl}/billingPublic/billingEmail`, apiKey, data);

export const updateBillingCustomer = (apiKey, data) =>
  postData(`${appConfig.baseUrl}/billingPublic/billingCustomer`, apiKey, data);

export const updateCardInfo = (apiKey, data) =>
  postData(`${appConfig.baseUrl}/billingPublic/cardInfo`, apiKey, data);
