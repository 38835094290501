import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPassword } from '@services/requests/passwords';
import { PASSWORDS } from '@store/slices/resources';

export const ThunkGetPassword = createAsyncThunk(
  `${PASSWORDS}/getPassword`,
  async ({ code, secretPhrase }) => {
    const res = await getPassword(code, secretPhrase);
    return res;
  },
);

export default {};
