import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';
import useStyles from './styles';

const MuiSlider = ({ classNameWrapper, input, ...rest }) => {
  const classes = useStyles();

  return (
    <Slider
      {...rest}
      {...input}
      classes={{
        root: classNameWrapper,
        thumb: classes.sliderThumb,
        mark: classes.slideMark,
        rail: classes.sliderRail,
        track: classes.sliderTrack,
        focusVisible: classes.sliderFocusVisible,
      }}
    />
  );
};

MuiSlider.propTypes = {
  classNameWrapper: PropTypes.string,
};

export default MuiSlider;
