/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, isFulfilled } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_MAPPINGS } from '@store/slices/resources';
import {
  ThunkAddClientPortalDefaultsMappings,
  ThunkGetClientPortalCompanies,
  ThunkGetClientPortalDefaultsMappings,
} from '@store/slices/clientPortalDefaultMapping/thunks';
import { ThunkGetCPDefaultsAutocomplete } from '@store/slices/clientPortalAdmin/thunks';

const clientPortalDefaultsMappingSlice = createSlice({
  name: CLIENT_PORTAL_MAPPINGS,
  initialState: {
    defaults: [],
    companies: [],
    mappings: [],
    invalidItems: [],
    processing: false,
  },
  reducers: {
    updateCompany(state, action) {
      const { id, companyId } = action.payload;
      const newState = state.mappings.map(item =>
        item.id === id ? { ...item, psaCompanyId: companyId } : item,
      );
      state.mappings = [...newState];
    },

    updateDefault(state, action) {
      const { id, defaultId } = action.payload;
      const newState = state.mappings.map(item =>
        item.id === id ? { ...item, clientPortalDefaultId: defaultId } : item,
      );
      state.mappings = [...newState];
    },

    addNew(state) {
      const newId = Math.random() * 100;
      const newItem = { id: newId, psaCompanyId: 0, clientPortalDefaultId: 0 };
      state.mappings.push(newItem);
    },

    remove(state, action) {
      const { id } = action.payload;
      const newState = state.mappings.filter(item => item.id !== id);
      state.mappings = [...newState];
    },

    setError(state, action) {
      const { id, error } = action.payload;
      if (error) {
        state.invalidItems.push({ id, error });
      } else {
        state.invalidItems = state.invalidItems.filter(i => i.id !== id);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetClientPortalDefaultsMappings.fulfilled,
        (state, { payload }) => {
          state.mappings = payload;
        },
      )

      .addCase(
        ThunkGetClientPortalCompanies.fulfilled,
        (state, { payload }) => {
          state.companies = payload;
        },
      )

      .addCase(
        ThunkGetCPDefaultsAutocomplete.fulfilled,
        (state, { payload }) => {
          state.defaults = payload;
        },
      )

      .addMatcher(
        isAnyOf(ThunkAddClientPortalDefaultsMappings.pending),
        state => {
          state.processing = true;
        },
      )

      .addMatcher(isFulfilled(ThunkAddClientPortalDefaultsMappings), state => {
        state.processing = false;
      });
  },
});

export default clientPortalDefaultsMappingSlice.reducer;

export const {
  updateCompany,
  updateDefault,
  addNew,
  remove,
  setError,
} = clientPortalDefaultsMappingSlice.actions;

export const ClientPortalDefaultsSelector = state =>
  state[CLIENT_PORTAL_MAPPINGS].defaults;
export const ClientPortalCompaniesSelector = state =>
  state[CLIENT_PORTAL_MAPPINGS].companies;
export const ClientPortalMappingsSelector = state =>
  state[CLIENT_PORTAL_MAPPINGS].mappings;
export const ClientPortalInvalidItemsSelector = state =>
  state[CLIENT_PORTAL_MAPPINGS].invalidItems;
export const ClientPortalProcessingSelector = state =>
  state[CLIENT_PORTAL_MAPPINGS].processing;
