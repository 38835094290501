import { createAsyncThunk } from '@reduxjs/toolkit';
import { getValidationConfigRequest } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetValidationConfig = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getValidationConfig`,
  async ({ crmId }) => {
    const res = await getValidationConfigRequest(crmId);
    return res;
  },
);

export default {};
