/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CRM } from '../resources';
import {
  ThunkGetCrmUsersByDefaultsId,
  ThunkGetCrmCompaniesByDefaultId,
  ThunkAddCrmUser,
} from './thunks';

const crmSlice = createSlice({
  name: CRM,
  initialState: {
    crmUsers: [],
    crmCompanies: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetCrmUsersByDefaultsId.fulfilled, (state, action) => {
      state.crmUsers = action.payload;
    });

    builder.addCase(
      ThunkGetCrmCompaniesByDefaultId.fulfilled,
      (state, action) => {
        state.crmCompanies = action.payload;
      },
    );

    builder.addCase(ThunkAddCrmUser.fulfilled, (state, { payload }) => {
      state.crmUsers.push(payload);
    });
  },
});

export default crmSlice.reducer;
