import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '@components/Ticketing/Notifications/TicketSettings/components/styles';
import { useSelector } from 'react-redux';
import { notificationDataViewSelector } from '@store/selectors';
import { slackSettings } from '@store/selectors/notifications';
import Loading from '@common/Loading/Loading';
import {
  getSlackSettings,
  postSlackSettings,
  testSlack,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import useDisabled from '@components/Hooks/useDisabled';
import { basePermissions } from '@constants/permissions';
import { actions } from '@store/actions';
import Paper from '@common/Paper/Paper';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import TestArea from '@common/TestArea/TestArea';
import HelpBox from '@common/HelpBox/HelpBox';
import help from '@constants/help';
import useTest from '@components/Hooks/useTest';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import {
  InputWithTemplate,
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import { handleDoWChange } from '../../utils';
import useTicketSetting from '../../hooks/useTicketSetting';
import { slackSettingsValidator } from '../../validators';

const SlackSettings = ({ match }) => {
  const classes = useStyles();

  const dataViewId = useSelector(notificationDataViewSelector);
  const storedData = useSelector(slackSettings);

  const {
    submit,
    loading,
    setLoading,
    handleBack,
    data,
    setData,
  } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveSlackSetting,
    tileKey: 'isSlack',
    apiPostRequest: postSlackSettings,
    apiGetSetting: getSlackSettings,
  });

  const { templateVariables } = useTemplateVariables({
    paramsId: match.params.id,
    dataViewId,
  });

  const onDoWChange = handleDoWChange(setData);

  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testSlack,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={slackSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.contentFormWrapper}>
              <div className={classes.header}>
                <span>Please, create app before integration</span>
                <HelpBox resource={help.slackCreateApp} />
              </div>
              <FieldWrapper
                label="Url"
                labelSize={3}
                content={
                  <Field
                    id="url"
                    name="url"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Message"
                labelSize={3}
                content={
                  <InputWithTemplate
                    name="template"
                    disabled={disabled}
                    form={form}
                    templateVariables={templateVariables}
                    multiline
                    variant="outlined"
                  />
                }
              />
              <TestArea
                onClick={() =>
                  handleTestClick({ url: form.getFieldState('url').value })
                }
                loading={loading}
                testOutput={testOutput}
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

SlackSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default SlackSettings;
