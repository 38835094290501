import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { sortOrders } from '@constants/filters';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import { EMAIL_GROUP_DETAILS } from '@constants/routes';
import { Link } from 'react-router-dom';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import { currentUserDataSelector } from '@store/selectors';
import Roles from '@constants/roles';
import { checkNotifications } from './helpers';
import ActionField from './components/ActionField';
import ListActions from './components/ListActions';
import CreateDialog from './components/CreateDialog';

import useStyles from './styles';

const NotificationGroups = ({ resource = 'notificationGroups' }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const classes = useStyles();
  const currentUser = useSelector(currentUserDataSelector);

  const isTechnician = currentUser?.roleId === Roles.READ_ONLY;

  const [loading, setLoading] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [linkedNotifications, setLinkedNotifications] = useState(null);

  const getHtmlFromLinkedNotifications = useCallback(arr => {
    if (Array.isArray(arr) && arr.length) {
      return (
        <div>
          <p>
            There are notifications which linked to this group, they will work
            incorrectly in case you continue
            {arr.map(i => (
              <div key={i.value}>
                <Link to={`/notificationSettings/${i.value}`} target="_blank">
                  {i.label}
                </Link>
              </div>
            ))}
          </p>
        </div>
      );
    }
    return null;
  }, []);

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: deletedItemId })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: deletedItemId }));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setDeletedItemId(null);
        setDeleteDialogOpen(false);
        setLoading(false);
        setLinkedNotifications(null);
      });
  };

  const columns = useMemo(() => {
    const onDelete = value => {
      checkNotifications(value.id)
        .then(d => {
          setLinkedNotifications(d);
          setDeletedItemId(value.id);
          setDeleteDialogOpen(true);
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
        .finally();
    };
    return [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField
            record={rowData}
            onEdit={value =>
              redirect(`${EMAIL_GROUP_DETAILS.replace(':id', value.id)}`)
            }
            onDelete={onDelete}
          />
        ),
        width: !isTechnician ? 150 : 100,
      },
    ];
  }, [redirect, isTechnician, enqueueSnackbar]);

  return (
    <div className={classes.container}>
      <ListActions
        onCreate={isTechnician ? null : () => setCreateDialogOpen(true)}
      />
      <Table
        columns={columns}
        resource="notificationGroups"
        defaultSort={{
          fieldName: 'name',
          order: sortOrders.asc,
        }}
      />
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete email group?"
        content="This action will delete current email group and it can not be undone."
        htmlContent={() => getHtmlFromLinkedNotifications(linkedNotifications)}
        loading={loading}
      />
    </div>
  );
};

NotificationGroups.propTypes = {
  resource: PropTypes.string,
};

export default NotificationGroups;
