import * as React from 'react';
import PropTypes from 'prop-types';
import { VpnKey as VpnKeyIcon } from '@material-ui/icons';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconShare from '@assets/icons/share.svg';

const ActionField = ({ record = {}, handleMakeGlobal }) => {
  return (
    <>
      <ActionButton
        icon={
          !record.isGlobal ? (
            <img src={iconShare} alt="iconShare" />
          ) : (
            <VpnKeyIcon color="primary" />
          )
        }
        handler={() => handleMakeGlobal(record)}
        toolTip={record.isGlobal ? 'Make tenant' : 'Make global'}
      />
    </>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleMakeGlobal: PropTypes.func.isRequired,
};

export default ActionField;
