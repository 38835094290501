import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, List, ListItem, ListItemText, Link } from '@material-ui/core';
import { partial } from 'lodash';

import OrganizationItem from '@components/DataSources/Integrations/pages/Meraki/OrganizationItem';
import Loading from '@common/Loading/Loading';
import FullScreenLoading from '@common/Loading/FullScreenLoading/FullScreenLoading';
import AlertSettingGrid from '@components/DataSources/Integrations/pages/Meraki/AlertSettingGrid';
import {
  getMerakiOrganizations,
  getMerakiOrganizationNetworks,
  getMerakiNetworkDevices,
  getIntegrationOrganizations,
  selectAllIntegrationOrganizations,
  deselectAllIntegrationOrganizations,
} from '@components/DataSources/helpers';
import { actions } from '@store/actions';
import IntegrationHeader from '@components/DataSources/Integrations/pages/Meraki/IntegrationHeader';
import ExternalLink from '@common/ExternalLink/ExternalLink';
import HookAlertsGrid from '@components/DataSources/Integrations/pages/Meraki/HookAlertsGrid';
import { integrationSources } from '@constants/integrationSources';
import useInfiniteLoader from '@utils/useInfiniteLoader';
import DeviceStatusesGrid from '@components/DataSources/Integrations/pages/Meraki/DeviceStatusesGrid';
import useStyles from '@components/DataSources/Integrations/pages/Meraki/styles';
import MerakiNetworks from './components/MerakiNetworks';

const Meraki = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [filteredOrganization, setFilteredOrganization] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [organizationIntegrations, setOrganizationIntegrations] = useState([]);
  // * Network Data
  const [networks, setNetworks] = useState([]);

  const [activeOrganizationId, setActiveOrganizationId] = useState(null);
  const [devices, setDevices] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(false);
  const [viewType, setViewType] = useState('organizationView');
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);

  const dispatch = useDispatch();
  const ref = useRef();
  const classes = useStyles();

  const onMount = () => {
    setLoading(true);
    Promise.all([
      getMerakiOrganizations(match.params.id, start, end),
      getIntegrationOrganizations(integrationSources.Meraki.name),
    ])
      .then(values => {
        const [organizations, organizationIntegration] = values;
        setFilteredOrganization(organizations);
        setOrganization(organizations);
        setOrganizationIntegrations(organizationIntegration);
      })
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
    return function cleanup() {
      dispatch(actions.resetSelectedNetworks());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleInfiniteScrollForOrganizations = () => {
    setSubLoading(true);
    setStart(start + 20);
    setEnd(end + 20);
    Promise.all([
      getMerakiOrganizations(match.params.id, start + 20, end + 20),
      getIntegrationOrganizations(integrationSources.Meraki.name),
    ])
      .then(values => {
        setFilteredOrganization([...filteredOrganization, ...values[0]]);
        setOrganization([...filteredOrganization, ...values[0]]);
        setOrganizationIntegrations([
          ...organizationIntegrations,
          ...values[1],
        ]);
      })
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
      })
      .finally(() => {
        setSubLoading(false);
      });
  };

  useEffect(() => {
    onMount();
  }, []);

  const updateIntegrationOrganization = () => {
    getIntegrationOrganizations(integrationSources.Meraki.name).then(d =>
      setOrganizationIntegrations(d),
    );
  };

  const getOrganizationNetworks = organizationId => {
    setViewType('organizationView');
    setSubLoading(true);
    setDevices(null);
    setDeviceInfo(null);
    setActiveOrganizationId(organizationId);
    getMerakiOrganizationNetworks(match.params.id, organizationId, 0, 20)
      .then(d => {
        setNetworks(d);
      })
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
        setNetworks(null);
      })
      .finally(() => setSubLoading(false));
  };

  const getNetworkDevices = networkId => {
    setSubLoading(true);
    setDeviceInfo(null);
    getMerakiNetworkDevices(match.params.id, networkId)
      .then(d => {
        setDevices(d);
      })
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
        setDevices(null);
      })
      .finally(() => setSubLoading(false));
  };

  const getOrganizationDeviceStatuses = (organizationId, event) => {
    event.stopPropagation();
    setViewType('deviceStatuses');
    setActiveOrganizationId(organizationId);
  };

  const getOrganizationAlertSettings = (organizationId, event) => {
    event.stopPropagation();
    setViewType('alertSettings');
    setActiveOrganizationId(organizationId);
  };
  const getOrganizationHookAlerts = (id, event) => {
    event.stopPropagation();
    setViewType('hookAlerts');
    setActiveOrganizationId(id);
  };

  useInfiniteLoader(
    'list',
    'item',
    filteredOrganization.length,
    handleInfiniteScrollForOrganizations,
  );

  if (loading) {
    return <Loading />;
  }

  const parserLabel = (label, value) => {
    return (
      {
        url: (
          <Link target="_blank" href={value}>
            {value}
          </Link>
        ),
      }[label] ||
      value ||
      '-'
    );
  };

  const onSelectAll = () => {
    setSubLoading(true);
    const existingIntegrations = organizationIntegrations.map(
      i => i.organizationId,
    );

    const entities = organization
      .filter(i => i.api?.enabled && !existingIntegrations.includes(i.id))
      .map(i => ({
        integrationId: +match.params.id,
        organizationId: i.id,
      }));

    const payload = {
      integrationId: +match.params.id,
      organizationIds: entities.map(i => i.organizationId),
    };

    selectAllIntegrationOrganizations(payload)
      .then(() => {
        const allData = [...organizationIntegrations].concat(entities);
        setOrganizationIntegrations(allData);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setSubLoading(false));
  };

  const onDeselectAll = () => {
    setSubLoading(true);
    deselectAllIntegrationOrganizations(+match.params.id)
      .then(() => {
        setOrganizationIntegrations([]);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setSubLoading(false));
  };

  const organizationView = (
    <>
      <MerakiNetworks
        networks={networks}
        activeNetworkId={match.params.id}
        getNetworkDevices={getNetworkDevices}
        setNetworks={setNetworks}
        activeOrganizationId={activeOrganizationId}
        setSubLoading={setSubLoading}
      />
      <Grid className={classes.column} item xs={3}>
        {devices &&
          devices.map(item => {
            return (
              <button
                type="button"
                key={item.serial}
                className={cx(
                  classes.baseButton,
                  classes.networksButton,
                  item.serial === deviceInfo?.serial &&
                    classes.activeOrganizationButton,
                )}
                onClick={partial(setDeviceInfo, item)}
              >
                <span>
                  {`${item.model} : ${item.serial}`}
                  <ExternalLink url={item.url} />
                </span>
              </button>
            );
          })}
      </Grid>
      <Grid className={classes.column} item xs={3}>
        <List>
          {deviceInfo &&
            Object.keys(deviceInfo).map(item => (
              <ListItem key={item}>
                <ListItemText
                  primary={`${item}:`}
                  secondary={parserLabel(item, deviceInfo[item])}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </>
  );

  const renderContent = type => {
    return {
      hookAlerts: (
        <Grid item xs={9} className={classes.alertsContainer}>
          <HookAlertsGrid
            resource={`/Integrations/Meraki/V2/${match.params.id}/getAlerts/${activeOrganizationId}`}
          />
        </Grid>
      ),
      alertSettings: (
        <Grid item xs={9} className={classes.alertsContainer}>
          <AlertSettingGrid
            organizationName={[
              organization.find(item => item.id === activeOrganizationId)?.name,
            ]}
            resource={`/Integrations/Meraki/V2/${match.params.id}/organizationAlertSettings/${activeOrganizationId}`}
            activeOrganizationId={activeOrganizationId}
            id={match.params.id}
          />
        </Grid>
      ),
      deviceStatuses: (
        <Grid item xs={9} className={classes.alertsContainer}>
          <DeviceStatusesGrid
            resource={`/Integrations/Meraki/V2/${match.params.id}/deviceStatuses?organizationId=${activeOrganizationId}`}
          />
        </Grid>
      ),
      organizationView,
    }[type];
  };

  return (
    <div>
      <IntegrationHeader
        integrationId={match.params.id}
        onSelectAll={onSelectAll}
        onDeselectAll={onDeselectAll}
        setFilteredOrganization={setFilteredOrganization}
        end={end}
        setSubLoading={setSubLoading}
        isMeraki
      />
      <Grid container className={classes.container} spacing={0}>
        <FullScreenLoading display={subLoading} />
        <Grid className={classes.column} item xs={3} name="list">
          {organization &&
            organizationIntegrations &&
            filteredOrganization.map((item, idx) => (
              <OrganizationItem
                key={item.id}
                integrationId={match.params.id}
                organization={item}
                organizationIntegrations={organizationIntegrations}
                handleGetDeviceStatuses={getOrganizationDeviceStatuses}
                handleGetAlertSettings={getOrganizationAlertSettings}
                handleClick={getOrganizationNetworks}
                activeOrganizationId={activeOrganizationId}
                updateIntegrationOrganization={updateIntegrationOrganization}
                handleGetHookAlerts={getOrganizationHookAlerts}
                forwardRef={ref}
                name={`item${idx}`}
              />
            ))}
        </Grid>
        {renderContent(viewType)}
      </Grid>
    </div>
  );
};

Meraki.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Meraki;
