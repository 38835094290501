import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationServiceBoards } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationServiceBoards = createAsyncThunk(
  `${INTEGRATION}/getIntegrationServiceBoards`,
  async ({ id }) => {
    const serviceBoards = await getIntegrationServiceBoards(id);
    return serviceBoards;
  },
);

export default {};
