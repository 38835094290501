import { createAsyncThunk } from '@reduxjs/toolkit';
import { addMessagingChannel } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkAddMessagingChannel = createAsyncThunk(
  `${MESSAGING}/addMessagingChannel`,
  async ({ configId, payload }) => {
    const res = await addMessagingChannel(configId, payload);
    return res;
  },
);

export default {};
