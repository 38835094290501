import { makeRequest } from '../makeRequest';
import {
  CRM_DEFAULTS_TICKETS,
  CRM_SOURCE,
  INTEGRATION,
} from '../requestResources';

export const getCrmSources = () => makeRequest('GET', `${CRM_SOURCE}`);

export const getCrmDefaults = crmId =>
  makeRequest('GET', `${CRM_DEFAULTS_TICKETS}/${crmId}/all`);

export const getCrmDefaultBoards = crmId =>
  makeRequest('GET', `${INTEGRATION}/${crmId}/service-boards`);

export const getCrmBoardStatuses = (crmId, boardId) =>
  makeRequest('GET', `${INTEGRATION}/${crmId}/board-statuses/${boardId}`);

export const getCrmDefaultCompanies = crmId =>
  makeRequest('GET', `${INTEGRATION}/companies/${crmId}`);

export default {};
