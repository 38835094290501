import { createAsyncThunk } from '@reduxjs/toolkit';
import { TICKET_CREATION_TEMPLATES } from '@store/slices/resources';
import { getTicketCreationTemplates } from '@services/requests/ticketCreationTemplates';

export const ThunkGetTicketCreationTemplates = createAsyncThunk(
  `${TICKET_CREATION_TEMPLATES}/getTicketCreationTemplates`,
  ({ psaType }) => getTicketCreationTemplates(psaType),
);

export default {};
