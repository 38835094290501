import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';

const StringArraySelectorItem = ({
  value,
  isActive,
  onClick,
  disabled,
  strLength = 3,
}) => {
  const classes = useStyles({ isActive, disabled });

  const data = useMemo(() => value.substring(0, strLength), [value]);

  return (
    <Grid item>
      <div
        role="presentation"
        className={classes.item}
        onClick={() => (disabled ? null : onClick(value))}
      >
        {data}
      </div>
    </Grid>
  );
};

StringArraySelectorItem.propTypes = {
  value: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  strLength: PropTypes.number,
};

export default memo(StringArraySelectorItem);
