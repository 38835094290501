import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  navBar: {
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  autoComplete: {
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 5px 0 0',
  },
  name: {
    minWidth: '10rem',

    textAlign: 'center',
    color: 'gray',
  },
}));

export default useStyles;
