import React, { useCallback, useMemo, useState } from 'react';
import ActionField from '@components/common/ActionFields/ActionField';
import { sortOrders } from '@constants/filters';
import Chip from '@common/Chip/Chip';
import ListActions from '@common/buttons/ListActionButtons/ListActions';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import resources from '@constants/resources';
import { useDataProvider, useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import { actions as act } from '@store/actions';
import { useDispatch } from 'react-redux';
import { TRIGGER_CREATE, TRIGGER_UPDATE } from '@constants/routes';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import { cloneTrigger, toggleEnabled } from './helpers';
import useStyles from './styles';

const Triggers = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);

  const handleToggleActive = useCallback(
    (id, isActive, row) => {
      toggleEnabled(id, !isActive).then(() => {
        dispatch(act.updateItemInList({ ...row, enabled: !isActive }));
      });
    },
    [dispatch],
  );

  const onDelete = value => {
    setDeletedItemId(value.id);
    setDeleteDialogOpen(true);
  };

  const onClone = record => {
    cloneTrigger(record.id)
      .then(d => dispatch(act.updateItemInList(d)))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const columns = useMemo(
    () => [
      {
        name: 'Trigger name',
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Event message',
        key: 'eventMessage',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Tags',
        key: 'tags',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        accessor: rowData =>
          rowData.tags?.map(i => (
            <Chip style={{ margin: '0 0.2rem' }} label={i} key={i} />
          )),
      },
      {
        name: 'MailBoxes',
        key: 'mailBoxes',
        isCustomAccessor: true,
        accessor: rowData =>
          rowData.mailBoxNames?.map(i => (
            <Chip style={{ margin: '0 0.2rem' }} label={i} key={i} />
          )),
      },
      {
        name: 'Enabled',
        key: 'enabled',
        isCustomAccessor: true,
        accessor: row => (
          <StatusCircle handleClick={handleToggleActive} row={row} />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField
            record={rowData}
            onEdit={() => redirect(TRIGGER_UPDATE.replace(':id', rowData.id))}
            onDelete={onDelete}
            onClone={onClone}
          />
        ),
        width: 150,
      },
    ],
    [handleToggleActive, onClone, redirect],
  );
  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resources.triggers, { id: deletedItemId })
      .then(() => dispatch(act.removeItemFromList({ id: deletedItemId })))
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => {
        setDeletedItemId(null);
        setDeleteDialogOpen(false);
        setLoading(false);
      });
  };

  return (
    <div className={classes.tableContainer}>
      <ListActions onCreate={() => redirect(TRIGGER_CREATE)} />
      <Table
        columns={columns}
        resource={resources.triggers}
        defaultSort={{
          fieldName: 'name',
          order: sortOrders.asc,
        }}
        classNameWrapper={classes.container}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete current trigger?"
        content="This action will delete current trigger with incidents and it can not be undone."
        loading={loading}
      />
    </div>
  );
};

export default Triggers;
