import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { getCrmUserByCrmId } from '@services/requests/smsCampaigns';

export const ThunkGetCrmUserByCrmId = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/getCrmUserByCrmId`,
  async ({ crmId, crmCompanyId }) => getCrmUserByCrmId(crmId, crmCompanyId),
);

export default {};
