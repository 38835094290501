import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  deselectAllIntegrationOrganizations,
  getIntegrationOrganizations,
  selectAllIntegrationOrganizations,
} from '@components/DataSources/helpers';
import IntegrationHeader from '@components/DataSources/Integrations/pages/Meraki/IntegrationHeader';
import { Grid } from '@material-ui/core';
import Loading from '@common/Loading/Loading';
import { integrationSources } from '@constants/integrationSources';
import FullScreenLoading from '@common/Loading/FullScreenLoading/FullScreenLoading';
import {
  getSophosTenants,
  getSophosTenantEndpoints,
  getSophosTenantAlerts,
} from '../../../helpers';
import TenantItem from './TenantItem';
import EndpointItem from './EndpointItem';
import EndpointDetails from './EndpointDetails';
import AlertsGrid from './AlertsGrid';
import useStyles from './styles';

const Sophos = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState(undefined);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [tenants, setTenants] = useState(null);
  const [endpoints, setEndpoints] = useState(null);
  const [tenantIntegrations, setTenantIntegrations] = useState([]);
  const [subLoading, setSubLoading] = useState(false);
  const [activeTenantId, setActiveTenantId] = useState(null);
  const [activeEndpoint, setActiveEndpoint] = useState(null);
  const [viewType, setViewType] = useState('tenantView');
  const [alerts, setAlerts] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getSophosTenants(match.params.id),
      getIntegrationOrganizations(integrationSources.Sophos.name),
    ])
      .then(values => {
        const [tenantValues, tenantIntegrationValues] = values;
        setFilteredTenants(tenantValues);
        setTenants(tenantValues);
        setTenantIntegrations(tenantIntegrationValues);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [enqueueSnackbar]);

  const updateIntegrationOrganization = () => {
    getIntegrationOrganizations(integrationSources.Sophos.name).then(d =>
      setTenantIntegrations(d),
    );
  };

  const getTenantDevices = (tenantId, tenantUrl) => {
    setViewType('tenantView');
    setSubLoading(true);
    setActiveTenantId(tenantId);
    setActiveEndpoint(null);
    getSophosTenantEndpoints(match.params.id, tenantId, tenantUrl)
      .then(d => setEndpoints(d))
      .catch(e => {
        setEndpoints(null);
        enqueueSnackbar(e.message, { variant: 'error' });
      })
      .finally(() => setSubLoading(false));
  };

  const onFilterChange = event => {
    const { value } = event.target;
    setFilterValue(value);
    const newTenants = tenants.filter(i =>
      i.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredTenants(newTenants);
  };

  const onFilterClear = () => {
    setFilterValue('');
    setFilteredTenants(tenants);
  };

  const onSelectAll = () => {
    setSubLoading(true);
    const existingIntegrations = tenantIntegrations.map(i => i.organizationId);

    const entities = tenants
      .filter(
        i => i.status === 'active' && !existingIntegrations.includes(i.id),
      )
      .map(i => ({
        integrationId: +match.params.id,
        organizationId: i.id,
        url: i.apiHost,
      }));

    const payload = {
      integrationId: +match.params.id,
      groupModels: entities.map(i => ({ id: i.organizationId, url: i.url })),
      useGroupedModel: true,
    };

    selectAllIntegrationOrganizations(payload)
      .then(() => {
        const allData = [...tenantIntegrations].concat(entities);
        setTenantIntegrations(allData);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setSubLoading(false));
  };

  const onDeselectAll = () => {
    setSubLoading(true);
    deselectAllIntegrationOrganizations(+match.params.id)
      .then(() => {
        setTenantIntegrations([]);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setSubLoading(false));
  };

  const getAlerts = (tenantId, tenantApiHost, event) => {
    event.stopPropagation();
    setViewType('alerts');
    setSubLoading(true);
    setActiveTenantId(tenantId);
    getSophosTenantAlerts(match.params.id, tenantId, tenantApiHost)
      .then(d => setAlerts(d))
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
        setAlerts(undefined);
      })
      .finally(() => setSubLoading(false));
  };

  const renderContent = type => {
    return {
      alerts: alerts && (
        <Grid item xs={9}>
          <AlertsGrid data={alerts} />
        </Grid>
      ),
      tenantView: (
        <>
          <Grid className={classes.column} item xs={3}>
            {endpoints &&
              endpoints.map(item => (
                <EndpointItem
                  key={item?.id}
                  item={item}
                  activeEndpoint={activeEndpoint}
                  setActiveEndpoint={setActiveEndpoint}
                />
              ))}
          </Grid>
          <Grid className={classes.column} item xs={6}>
            <EndpointDetails item={activeEndpoint} />
          </Grid>
        </>
      ),
    }[type];
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <IntegrationHeader
        integrationId={match.params.id}
        onSelectAll={onSelectAll}
        onDeselectAll={onDeselectAll}
        onFilterChange={onFilterChange}
        onFilterClear={onFilterClear}
        filterValue={filterValue}
      />
      <Grid container className={classes.container} spacing={0}>
        <FullScreenLoading display={subLoading} />
        <Grid className={classes.column} item xs={3}>
          {tenants &&
            tenantIntegrations &&
            filteredTenants.map(item => (
              <TenantItem
                key={item.id}
                integrationId={match.params.id}
                tenant={item}
                tenantIntegrations={tenantIntegrations}
                handleClick={getTenantDevices}
                updateIntegrationTenant={updateIntegrationOrganization}
                activeTenantId={activeTenantId}
                handleGetHookAlerts={getAlerts}
              />
            ))}
        </Grid>
        {renderContent(viewType)}
      </Grid>
    </div>
  );
};

export default Sophos;
