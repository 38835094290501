import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT_MODULES } from '@store/slices/resources';
import { GetAccessModules } from '@services/requests/admin';

export const ThunkGetAccessModules = createAsyncThunk(
  `${TENANT_MODULES}/GetAccessModules`,
  async tenantId => {
    const data = await GetAccessModules(tenantId);
    return data;
  },
);

export default {};
