import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  guttersItem: {
    paddingLeft: '1.5rem',
  },
  title: {
    textAlign: 'center',
    borderBottom: '1px solid #E2E2E2',
  },
  list: {
    maxHeight: '450px',
    overflow: 'scroll',
  },
  listItem: {
    transition: '0.3s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  gutters: {
    paddingLeft: '1.5rem',
  },
}));

export default useStyles;
