import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Card = ({ data, icon }) => {
  const classes = useStyles();

  const href = data?.value || '#';

  return data ? (
    <a href={href} download>
      <div className={classes.base}>
        <div>{icon}</div>
        <div>
          <span>{data?.label}</span>
        </div>
      </div>
    </a>
  ) : null;
};

Card.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  icon: PropTypes.element.isRequired,
};

export default Card;
