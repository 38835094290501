import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusWhite.svg';

const AddGroupActions = ({ handleOnClick }) => {
  return (
    <ReusableButton onClick={handleOnClick} viewType="orange">
      <>
        <img src={iconPlus} alt="iconPlus" />
        Add group
      </>
    </ReusableButton>
  );
};

AddGroupActions.propTypes = {
  handleOnClick: PropTypes.func,
};

export default AddGroupActions;
