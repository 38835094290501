import { createAsyncThunk } from '@reduxjs/toolkit';
import { editConnectWiseTicketTimeEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkEditConnectWiseTicketTimeEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/editConnectWiseTicketTimeEntry`,
  async ({ crmId, timeEntryId, payload }) => {
    const timeEntry = await editConnectWiseTicketTimeEntry(
      crmId,
      timeEntryId,
      payload,
    );
    return timeEntry;
  },
);

export default {};
