import React from 'react';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import iconArrow from '@assets/icons/arrowGrey.svg';
import useStyles from '../styles';

const SubFormButtons = ({ loading, handleBack, editMode }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonWrapper}>
      <ReusableButton
        size="md"
        type="button"
        classNameWrapper={classes.button}
        disabled={loading}
        onClick={handleBack}
      >
        <>
          <img src={iconArrow} alt="iconArrow" />
          Back
        </>
      </ReusableButton>
      {editMode && (
        <ReusableButton
          size="md"
          viewType="black"
          type="submit"
          classNameWrapper={classes.button}
          disabled={loading}
          loading={loading}
        >
          Update
        </ReusableButton>
      )}
    </div>
  );
};

SubFormButtons.propTypes = {
  loading: PropTypes.bool,
  handleBack: PropTypes.func,
  editMode: PropTypes.bool,
};

export default SubFormButtons;
