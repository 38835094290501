import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRedirect } from 'react-admin';
import { Tab, Tabs } from '@material-ui/core';
import useStyles from './styles';

const a11yProps = index => ({
  id: `wrapped-tab-${index}`,
  'aria-controls': `wrapped-tabpanel-${index}`,
});

const TabPanel = ({
  tabs,
  classNameWrapper,
  match,
  viewTypeLink,
  classNameWrapperTabs,
  classNameWrapperTabsContent,
  classFlexContainer,
  onValueChanged,
  isTransparent,
  classNameTabTitle,
  withRedirect = true,
  searchParamName = 'id',
  classNameContentWrapper,
  disabled,
}) => {
  const classes = useStyles(!!isTransparent);
  const redirect = useRedirect();

  const initialStep = useMemo(() => {
    const res =
      match &&
      tabs.findIndex(item => match.params.name === item[searchParamName]);
    return res === -1 ? 0 : res;
  }, [tabs, match]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(initialStep || 0);
  }, [initialStep]);

  const handleChange = (event, newValue) => {
    if (disabled) {
      return;
    }
    if (onValueChanged) onValueChanged(newValue);
    if (tabs[newValue].id && withRedirect) {
      redirect(tabs[newValue].id);
    }
    setStep(newValue);
  };

  const classNamesForLink = {
    main: cx(classes.tabTitle, classNameTabTitle),
    flooded: classes.tabTitleFlooded,
  };

  const classNamesForLinkSelected = {
    main: classes.tabTitleSelected,
    flooded: classes.tabTitleFloodedSelected,
  };

  return (
    <div className={classNameWrapper}>
      <div className={cx(classes.root, classNameWrapperTabs)}>
        <Tabs
          value={step}
          classes={{
            flexContainer: cx(classes.flexContainer, classFlexContainer),
          }}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs.map(({ label }, index) => (
            <Tab
              className={classNamesForLink[viewTypeLink]}
              key={label}
              classes={{
                root: classNamesForLink[viewTypeLink],
                selected: classNamesForLinkSelected[viewTypeLink],
              }}
              label={label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </div>
      <div
        className={classNameWrapperTabsContent}
        role="tabpanel"
        id={`wrapped-tabpanel-${step}`}
        aria-labelledby={`wrapped-tab-${step}`}
      >
        <div className={cx(classes.contentWrapper, classNameContentWrapper)}>
          {tabs[step].value}
        </div>
      </div>
    </div>
  );
};

TabPanel.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      component: PropTypes.node,
    }),
  ),
  classNameWrapper: PropTypes.string,
  classFlexContainer: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  viewTypeLink: PropTypes.oneOf(['main', 'flooded']),
  classNameWrapperTabs: PropTypes.string,
  classNameWrapperTabsContent: PropTypes.string,
  onValueChanged: PropTypes.func,
  classNameTabTitle: PropTypes.string,
  isTransparent: PropTypes.bool,
  withRedirect: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TabPanel;
