/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { OPTIN_SETTINGS } from '@store/slices/resources';
import { ThunkGetOptInSettings } from '@store/slices/optInSettings/thunks/getOptInSettings';

import {
  ThunkAddOptInFormLogo,
  ThunkRemoveOptInLogo,
  ThunkSaveOptInSettings,
} from '@store/slices/optInSettings/thunks';
import { ThunkGetOptInSettingsForForm } from '@store/slices/optIn/thunks';

const initialState = {
  init: {
    sendSms: false,
    sendEmail: false,
    updatePhone: false,
    updateEmail: false,
    createPhone: false,
    logo: undefined,
    styles: '{"bgColor":"white"}',
  },
  loadingSettings: false,
};

const optInSettingsSlice = createSlice({
  name: OPTIN_SETTINGS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetOptInSettings.pending, (state, action) => {
      state.loadingSettings = true;
    });

    builder.addCase(ThunkGetOptInSettings.fulfilled, (state, action) => {
      state.loadingSettings = false;
      state.init = action.payload;
    });

    builder.addCase(ThunkGetOptInSettingsForForm.pending, (state, action) => {
      state.loadingSettings = true;
    });

    builder.addCase(ThunkGetOptInSettingsForForm.fulfilled, (state, action) => {
      state.loadingSettings = false;
      state.init = action.payload;
    });

    builder.addCase(ThunkSaveOptInSettings.fulfilled, (state, action) => {
      state.init = action.payload;
    });

    builder.addCase(ThunkAddOptInFormLogo.fulfilled, (state, action) => {
      state.init.logo = action.payload;
    });

    builder.addCase(ThunkRemoveOptInLogo.fulfilled, state => {
      state.init.logo = null;
    });
  },
});

export default optInSettingsSlice.reducer;

export const OptInSettingsInit = state => state[OPTIN_SETTINGS].init;

export const OptInSettingsLoading = state =>
  state[OPTIN_SETTINGS].loadingSettings;
