import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { getAutotaskPodDataByTicketId } from '@services/requests/psaPods';

export const ThunkGetAutotaskPodDataByTicketId = createAsyncThunk(
  `${CONNECTWISE_POD}/getAutotaskPodDataByTicketId`,
  ({ crmId, ticketId }) => getAutotaskPodDataByTicketId(crmId, ticketId),
);

export default {};
