import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupOptIn } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {Object[]} list - list of table items
 * @param {Object[]} selectedIds - list of selected items ids
 * @param {Object[]} psaId
 */
export const ThunkGroupOptIn = createAsyncThunk(
  `${OPT_IN}/groupOptIn`,
  async ({ payload }) => {
    const res = await groupOptIn(payload);
    return res;
  },
);

export default {};
