import React from 'react';
import { ReactComponent as IconCash } from '@assets/icons/cash.svg';
import PricingPlan from './PricingPlan';

const Icon = () => <IconCash alt="iconCash" />;

export default {
  list: PricingPlan,
  icon: Icon,
};
