import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import copyText from '@utils/copy';
import { actions } from '@store/actions';
import ReusableButton from '@common/Button/Button';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import couponTypes from '@components/Admin/Coupons/couponTypes';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import CouponForm from './CouponForm';
import useStyles from './styles';

const Coupons = ({ resource }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editedEntity, setEditedEntity] = useState(null);

  const onCopy = key => {
    copyText(key);
  };

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const onAddRecord = () => {
    setEditedEntity(null);
    setFormOpen(true);
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: idForDelete })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(handleCancelDelete);
  };

  const handleSubmit = model => {
    setFormOpen(false);
    dispatch(actions.updateItemInList(model));
  };

  return (
    <div className={classes.tableWrapper}>
      <ReusableButton
        size="md"
        label="Add"
        onClick={onAddRecord}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <Table
        columns={[
          {
            name: 'Coupon Code',
            key: 'couponCode',
            sortable: true,
            searchable: true,
            width: 250,
          },
          {
            name: 'Type',
            key: 'type',
            isCustomAccessor: true,
            accessor: rowData =>
              `${rowData.amount} ${
                couponTypes.find(i => i.value === rowData.type).label
              } Off`,
            width: 250,
          },
          {
            name: 'Duration (months)',
            key: 'durationMonth',
            width: 250,
          },
          {
            name: 'Tenant Alias',
            key: 'tenantAlias',
            sortable: true,
            searchable: true,
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: rowData => (
              <ActionField
                label="Actions"
                record={rowData}
                onCopy={onCopy}
                onDelete={onDelete}
                fieldKey="couponCode"
              />
            ),
            width: 100,
          },
        ]}
        resource={resource}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title="Are you sure you want to delete this coupon?"
        content="This operation cannot be undone."
        loading={loading}
      />
      <CouponForm
        open={formOpen}
        onClose={() => setFormOpen(false)}
        onSubmit={handleSubmit}
        data={editedEntity}
      />
    </div>
  );
};

Coupons.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default Coupons;
