import textIcon from '@assets/icons/attachments/types/txt.svg';
import excelIcon from '@assets/icons/attachments/types/excel.svg';
import wordIcon from '@assets/icons/attachments/types/word.svg';
import csvIcon from '@assets/icons/attachments/types/csv.svg';
import unknownIcon from '@assets/icons/attachments/types/unknown.svg';
import emlIcon from '@assets/icons/attachments/types/eml.svg';
// eslint-disable-next-line import/no-cycle
import postWithResponse from '@services/api/common/postWithResponse';
import appConfig from '@configs/appConfig';
import { maxTableRowsCount } from '@constants/numericConstants';
import { supportedTypes, displayInHtmlMode } from './attachmentsProcessor';

export const downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getFileExtension = fileName =>
  /(?:\.([^.]+))?$/.exec(fileName)[1].toLowerCase();

const getIconByMimeType = fileName => {
  const ext = getFileExtension(fileName);
  switch (ext) {
    case 'txt':
      return textIcon;
    case 'xls':
    case 'xlsx':
      return excelIcon;
    case 'doc':
    case 'docx':
    case 'pdf':
      return wordIcon;
    case 'csv':
    case 'tsv':
      return csvIcon;
    case 'eml':
      return emlIcon;
    default:
      return unknownIcon;
  }
};

export default getIconByMimeType;

export const doesTypeSupports = fileName => {
  const ext = getFileExtension(fileName);
  return supportedTypes.includes(ext.toLowerCase());
};

export const displayAsHtml = fileName => {
  const ext = getFileExtension(fileName);
  return displayInHtmlMode.includes(ext.toLowerCase());
};

const simpleTableStyels = `
 <style type="text/css">
    table {border-collapse: collapse}
    tr {border: 1px solid #dfdfdf;}
    td {padding: 5px 10px;}
    </style>
`;

const multiTabTableStyels = `
 <style type="text/css">
    td table {border-collapse: collapse}
    td span {font-style: italic; font-weight: bold}
    tr {border: 1px solid #dfdfdf;}
    td {padding: 5px 10px;}
    </style>
`;

const top = styles => `
    <html><head>
    <META http-equiv="Content-Type" content="text/html; charset=utf-8"/>
   ${styles}
    </head><body>`;
const bottom = '</body></html>';

const createTable = array => {
  const table = document.createElement('table');
  const slicedArray = array.slice(0, maxTableRowsCount);

  slicedArray.forEach(rowData => {
    const row = document.createElement('tr');
    rowData.forEach(cellData => {
      const cell = document.createElement('td');
      cell.appendChild(document.createTextNode(cellData));
      row.appendChild(cell);
    });

    table.appendChild(row);
  });

  if (array.length > maxTableRowsCount) {
    const tableWidth = array[0].length;
    const row = document.createElement('tr');
    const cell = document.createElement('td');
    cell.appendChild(
      document.createTextNode(
        'Warning! We hide some data to prevent freezing. It do not affect parsing results',
      ),
    );
    cell.setAttribute('colspan', tableWidth);
    cell.style.cssText += 'color:red;text-align:center;';
    row.appendChild(cell);
    table.appendChild(row);
  }

  return table;
};

export const createTableFromArray = array => {
  const table = createTable(array);
  return top(simpleTableStyels) + table.outerHTML + bottom;
};

export const createMultiTabTable = data => {
  const table = document.createElement('table');
  data.forEach(i => {
    if (i.data.length) {
      const iRow = document.createElement('tr');
      const iCell = document.createElement('td');
      const sheetName = document.createElement('span');
      sheetName.appendChild(document.createTextNode(i.sheetName));
      iCell.appendChild(sheetName);
      iRow.appendChild(iCell);
      const dRow = document.createElement('tr');
      const dCell = document.createElement('td');
      dCell.appendChild(createTable(i.data));
      dRow.appendChild(dCell);
      table.appendChild(iRow);
      table.appendChild(dRow);
    }
  });
  return top(multiTabTableStyels) + table.outerHTML + bottom;
};

export const createSimpleHtmlDocument = data => top() + data + bottom;

export const createHtmlDocument = data =>
  top(simpleTableStyels) + data + bottom;

export const pdfToTextApi = data =>
  postWithResponse(appConfig.baseUrl.concat('/conversion/pdf-to-html'), data);

export const prepareHeaders = headers => {
  if (!headers || !Array.isArray(headers)) {
    return;
  }
  const data = headers.map(h => ({ name: h.name, value: h.value }));
  let table = `<html><head><META http-equiv="Content-Type" content="text/html; charset=utf-8"/></head><body><div>
  <div style="display: flex;">
    <div style="border: 1px solid; width: 10rem; text-align: center;padding: 0 5px"><b>Name</b></div>
    <div style="border: 1px solid; width: 100%; text-align: center;margin-left: -1px;padding: 0 5px"><b>Value</b></div>
  </div>`;

  const bottomTags = '</div></body></html>';
  data.forEach(row => {
    table += ` <div style="display: flex;">
    <div style="border: 1px solid; width: 10rem; margin-top: -1px;padding: 0 5px">${row.name}</div>
    <div style="border: 1px solid; width: 100%; margin-top: -1px;margin-left: -1px; padding: 0 5px;overflow-wrap: anywhere;">${row.value}</div>
  </div>`;
  });

  return table + bottomTags;
};
