import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON_POD } from '@store/slices/resources';
import { saveToContact } from '@services/requests/psaPods';

export const ThunkPodSaveToContact = createAsyncThunk(
  `${COMMON_POD}/saveToContact`,
  async ({ route, ...payload }) => {
    const res = await saveToContact(route, payload);
    return res;
  },
);

export default {};
