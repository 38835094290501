import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  card: {
    display: 'flex',
    margin: '0 20px',
    maxWidth: 800,
    borderRadius: '25px',
    boxShadow: '3px 3px 25px 2px #545b6b',
    minHeight: '34rem',
  },
  contentWrapper: {
    background: '#fff',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '25px',
    },
    borderRadius: '25px 0 0 25px',
    padding: '2.375rem 3.125rem 3.75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoStyle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '15px',
    width: '100%',
    maxWidth: '100%',
  },
  font: {
    marginTop: '2rem',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '14px',
  },
  bold: {
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
}));

export default styles;
