import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: props => {
      return props.bgColor
        ? `linear-gradient(45deg, ${props.bgColor}, transparent)`
        : 'white';
    },
  },
  checkboxFormContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  formContainer: {
    background: 'white',
    width: '600px',
    maxHeight: '100vh',
    overflowY: 'auto',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px -5px rgba(0,0,0,0.75)',
    '@media (max-width: 1000px)': {
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'unset',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
      overflow: 'auto',
    },
  },
  checkboxFormContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caption: {
    color: '#a2a2a2',
    fontSize: '1rem',
    width: '500px',
    wordBreak: 'break-word',
    '@media (max-width: 1000px)': {
      width: '100%',
      padding: '20px 0px',
      boxSizing: 'border-box',
    },
  },
  greenCheckbox: {
    '&.Mui-checked': {
      color: 'green !important',
    },
    color: 'black !important',
  },
  successContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      fontSize: 'medium',
    },
  },
  successBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    borderRadius: '15px',
    boxShadow: '0px 1px 7px 2px #00000042',
    '@media (max-width: 600px)': {
      boxShadow: 'unset',
      '& *': {
        fontSize: 'small',
      },
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
    '& img': {
      maxWidth: '350px',
      objectFit: 'contain',
    },
  },
  inputContainer: {
    width: 'calc(100% - 2px)',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  required: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1.75),
  },
}));

export default useStyles;
