import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 135px)',
  },
  column: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 0,
  },
  baseButton: {
    cursor: 'pointer',
    width: '100%',
    height: '35pt',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Nunito,"Segoe UI",Arial,sans-serif',
    '&:hover:enabled': {
      opacity: 0.9,
    },
  },
  tenantButton: {
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'rgb(235 194 185)',
      justifyContent: 'center',
    },
  },
  activeTenantButton: {
    borderRight: '6px solid #EC6A4E',
  },
  block: {
    margin: '0.8rem',
    wordBreak: 'break-all',
  },
  blockName: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  blockHr: {
    backgroundImage:
      'linear-gradient(90deg, transparent 5%, gray 50%, transparent 95%)',
    border: '0',
    height: '1px',
  },
}));

export default useStyles;
