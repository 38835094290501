import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ThunkCheckSubscriptionDiscount,
  ThunkGetBaseTechCount,
  ThunkGetBillingEmail,
  ThunkGetMyPlan,
  ThunkGetPricePlans,
  ThunkGetTenantTechCount,
} from '@store/slices/billing/thunks';
import { BillingDataSelector } from '@store/slices/billing';
import { BILLING } from '@constants/routes';

import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import PricePlanForm from './components/PricePlanForm';
import DemoPricePlanForm from './components/DemoPricePlanForm';

import useStyles from './styles';

const EditPricePlan = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const classes = useStyles();
  const billingData = useSelector(BillingDataSelector);
  const { push } = useHistory();

  const handleBack = useCallback(() => {
    push(BILLING);
  }, [push]);

  const form = useMemo(() => {
    switch (billingData?.isDemoSubscription) {
      case true:
        return <DemoPricePlanForm />;
      case false:
        return <PricePlanForm />;
      default:
        return null;
    }
  }, [billingData?.isDemoSubscription]);

  const onMount = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      dispatch(ThunkGetMyPlan()).unwrap(),
      dispatch(ThunkGetPricePlans()).unwrap(),
      dispatch(ThunkGetTenantTechCount()).unwrap(),
      dispatch(ThunkGetBaseTechCount()).unwrap(),
      dispatch(ThunkGetBillingEmail()).unwrap(),
    ])
      .then(res => {
        if (!res[0].isDemoSubscription) {
          dispatch(ThunkCheckSubscriptionDiscount()).unwrap();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div
      className={classes.container}
      style={{ display: 'grid', gridTemplateRows: '50px 1fr', gap: '5px' }}
    >
      <div>
        <ReusableButton label="Back" onClick={handleBack} />
      </div>
      <div>{form}</div>
    </div>
  );
};

export default EditPricePlan;
