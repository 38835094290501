import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPsaContactsAutocomplete } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetPsaContactsAutocomplete = createAsyncThunk(
  `${MESSAGING}/getCrmUsersAutocomplete`,
  async ({ configId, companyId }) => {
    const users = await getPsaContactsAutocomplete(configId, companyId);
    return users.map(item => ({
      value: item.value,
      label: `${item.label} <${item.info}>`,
      info: item.info,
      rawName: item.label,
    }));
  },
);

export default {};
