import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import { getLetterText } from '@components/MailBox/helpers';

const TextDialog = ({ open, onClose, letterId }) => {
  const [text, setText] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getLetterText(letterId)
        .then(r => setText(r.data))
        .finally(() => setLoading(false));
    }
  }, [letterId, open]);

  return (
    loading || (
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <Typography style={{ fontFamily: 'monospace' }}>
            {text}
            TTT
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

TextDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  letterId: PropTypes.string,
};

export default TextDialog;
