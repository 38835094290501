import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import Input from '@common/Input/Input';

import useStyles from './styles';

const DateTimePicker = ({ onChange, defaultValue, input, label, ...rest }) => {
  const classes = useStyles();

  const [date, setDate] = useState(
    input.value
      ? moment(input.value)
          .local()
          .format('YYYY-MM-DD')
      : null,
  );

  const [time, setTime] = useState(
    input.value
      ? moment(input.value)
          .local()
          .format('HH:mm')
      : null,
  );

  const combineDateAndTime = (date1, time1) => {
    if (!date1 || !time1) return null;
    return moment(`${date1}T${time1}`, 'YYYY-MM-DDTHH:mm').toISOString();
  };

  const handleDateChange = e => {
    const newDate = e.target.value;
    setDate(newDate);
    const combined = combineDateAndTime(newDate, time);
    input.onChange(combined);
  };

  const handleTimeChange = e => {
    const newTime = e.target.value;
    setTime(newTime);
    const combined = combineDateAndTime(date, newTime);
    input.onChange(combined);
  };

  return (
    <div>
      <Typography className={classes.inputLabel}>{label}</Typography>
      <div className={classes.deadLineFields}>
        <Input
          {...rest}
          styleType="main"
          inputView="text"
          input={{
            ...input,
            onChange: handleDateChange,
            value: date,
            type: 'date',
          }}
          meta={{
            ...rest.meta,
            error: date ? undefined : rest.meta?.error,
          }}
        />

        <Input
          {...rest}
          inputView="text"
          styleType="main"
          input={{
            ...input,
            onChange: handleTimeChange,
            value: time,
            type: 'time',
          }}
          meta={{
            ...rest.meta,
            error: time ? undefined : rest.meta?.error,
          }}
        />
      </div>
    </div>
  );
};

DateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
};

export default DateTimePicker;
