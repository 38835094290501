import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tabBase: {},
  marginLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2rem',
    },
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: '#2853ff',
    '&:hover': {
      color: '#001597',
    },
  },
  marginTop: {
    marginTop: '1rem',
  },
  darkBrown: {
    color: '#9b0303',
  },
  caption: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5rem',
    },
    fontWeight: 'bold',
  },
  paperWrapper: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    padding: '1rem',
    background: '#ede8e8',
    color: '#6c6c6c',
  },
  liMargin: {
    margin: '5px',
  },
  linkWrapper: {
    width: '80vw',
  },
  code: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default useStyles;
