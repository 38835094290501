import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationTicketCategories } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationTicketCategories = createAsyncThunk(
  `${INTEGRATION}/getIntegrationTicketCategories`,
  async ({ id }) => {
    const ticketCategories = await getIntegrationTicketCategories(id);
    return ticketCategories;
  },
);

export default {};
