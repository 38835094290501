import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserChats } from '@services/requests/chats';
import { CHATS } from '@store/slices/resources';

export const ThunkGetUserChats = createAsyncThunk(
  `${CHATS}/getUserChats`,
  async () => {
    const chats = await getUserChats();
    return chats;
  },
);

export default {};
