import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ExpressionItem from './ExpressionItem';

import useStyles from './styles';

const ParserForm = ({
  displayDataExpr,
  expressions,
  onItemEdit,
  onItemDelete,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        {expressions.map(i => (
          <ExpressionItem
            key={i.id}
            item={i}
            onItemEdit={onItemEdit}
            onItemDelete={onItemDelete}
          />
        ))}
      </div>
      <Button variant="outlined" color="secondary" onClick={displayDataExpr}>
        Select Data
      </Button>
    </div>
  );
};

ParserForm.propTypes = {
  displayDataExpr: PropTypes.func.isRequired,
  expressions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onItemEdit: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
};

export default ParserForm;
