import { actions } from '@store/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTableContext } from '../../../hooks/table';

const useBulkDelete = (apiRequest, setOpenDialog) => {
  const { selectedIds, deselectAllItems } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  return () => {
    const params = selectedIds.map(i => `id=${i}`).join('&');

    return apiRequest(params)
      .then(() => {
        dispatch(actions.removeItemFromList({ selectedIds }));
        deselectAllItems();
        enqueueSnackbar(`Successfully removed`, { variant: 'success' });
      })
      .catch(() =>
        enqueueSnackbar(`elements deleting was failed`, { variant: 'error' }),
      )
      .finally(() => setOpenDialog(false));
  };
};

export default useBulkDelete;
