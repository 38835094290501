import React, { useEffect, useMemo, useState } from 'react';
import { Message as MessageIcon } from '@material-ui/icons';
import { Badge, Tooltip } from '@material-ui/core';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { pusherConfigSelector } from '@store/selectors/pusher';
import {
  ActionSetNotifications,
  ChatsActiveIdSelector,
  ChatsNotificationsSelector,
} from '@store/slices/chats';

import { currentUserDataSelector } from '@store/selectors';
import { PUSHER_EVENTS } from '@constants/pusherEvents';
import { CHAT_TYPES } from '@constants/chatTypes';
import { CHATS } from '@constants/routes';

import useStyles from './styles';

const Messages = () => {
  const [message, setMessage] = useState();
  const [chat, setChat] = useState();
  const { push } = useHistory();

  const currentUser = useSelector(currentUserDataSelector);
  const pusherConfig = useSelector(pusherConfigSelector);
  const chatNotifications = useSelector(ChatsNotificationsSelector);
  const activeChatId = useSelector(ChatsActiveIdSelector);

  const dispatch = useDispatch();

  const classes = useStyles({
    hasNotifications: chatNotifications?.length > 0,
  });
  useEffect(() => {
    const pusher = new Pusher(pusherConfig.key, {
      cluster: pusherConfig.cluster,
    });

    const channel = pusher.subscribe(`${currentUser.id}`);
    channel.bind(PUSHER_EVENTS.newMessage, data => {
      setMessage({ chatId: data.ChatId });
    });

    channel.bind(PUSHER_EVENTS.addToChat, data => {
      setChat({
        id: data.ChatId,
        chatChannel: data.ChatChannel,
        name: data.ChatName,
        type: data.Type === 0 ? CHAT_TYPES.private : CHAT_TYPES.group,
      });
    });

    return () => {
      channel.unbind_all();
      channel.disconnect(`${currentUser.id}`);
      pusher.unsubscribe();
      pusher.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message && Number(message.chatId !== activeChatId)) {
      const newNotificationsForChannel = {
        [message.chatId]:
          chatNotifications &&
          Object.prototype.hasOwnProperty.call(
            chatNotifications,
            message.chatId,
          )
            ? [...chatNotifications[message.chatId], message]
            : [message],
      };
      dispatch(
        ActionSetNotifications({
          ...chatNotifications,
          ...newNotificationsForChannel,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (chat) {
      setMessage({ chatId: chat.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  const handleRedirect = () => {
    if (chatNotifications?.length > 1) {
      push(CHATS);
    }
  };

  const tooltipText = useMemo(() => {
    if (chatNotifications?.length > 1) return `You have new notifications`;
    return `You don't have new notifications`;
  }, [chatNotifications]);

  const notificationCount = useMemo(() => {
    if (chatNotifications) {
      return Object.values(chatNotifications).flat(2).length;
    }
    return 0;
  }, [chatNotifications]);

  return (
    <div>
      <Tooltip title={tooltipText} className={classes.container}>
        <Badge
          overlap="rectangular"
          color="primary"
          onClick={handleRedirect}
          badgeContent={notificationCount}
        >
          <MessageIcon onClick={handleRedirect} />
        </Badge>
      </Tooltip>
      <span />
    </div>
  );
};

export default Messages;
