import { createAsyncThunk } from '@reduxjs/toolkit';
import { postTestEmail } from '@services/requests/testEmail';
import { TEST_EMAIL } from '@store/slices/resources';

export const ThunkTestEmail = createAsyncThunk(
  `${TEST_EMAIL}/testEmail`,
  async payload => {
    await postTestEmail(payload);
  },
);

export default {};
