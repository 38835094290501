/* eslint-disable no-plusplus,no-param-reassign,no-bitwise */
import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import { letterDetails, letters } from '@constants/appRoutes';
import deleteData from '@services/api/common/deleteData';

export const getLetterDetails = id =>
  getData(appConfig.baseUrl.concat(letterDetails).concat(id));

export const removeLetter = id =>
  deleteData(appConfig.baseUrl.concat(letters).concat(id));
