import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles from '../styles';
import AttachmentBadge from './AttachmentBadge';

const Attachments = ({ attachments }) => {
  const classes = useStyles();
  const data = attachments ?? [];
  return (
    <>
      {!!data.length && (
        <div className={cx(classes.flex, classes.flexWrap)}>
          {data.map(d => (
            <AttachmentBadge isMaxContent item={d} key={d.name} />
          ))}
        </div>
      )}
    </>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      ContentId: PropTypes.string,
      content: PropTypes.string,
      contentLength: PropTypes.number,
      contentType: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default Attachments;
