import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingChannelsStatistic } from '@services/requests/messagingDashboard';
import { MESSAGING_DASHBOARD } from '@store/slices/resources';

export const ThunkGetMessagingChannelsStatistic = createAsyncThunk(
  `${MESSAGING_DASHBOARD}/getMessagingChannelsStatistic`,
  async () => {
    const statistic = await getMessagingChannelsStatistic();
    const { openChannels, closedChannels, total } = statistic.reduce(
      (acc, cur) => {
        return {
          openChannels: acc.openChannels + cur.openChannels,
          closedChannels: acc.closedChannels + cur.closedChannels,
          total: acc.total + cur.total,
        };
      },
      { openChannels: 0, closedChannels: 0, total: 0 },
    );
    return {
      configurationCount: statistic.length,
      pieData: [
        { name: 'Open', y: openChannels },
        { name: 'Closed', y: closedChannels },
      ],
      total,
    };
  },
);

export default {};
