import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeChannel } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkCloseChannel = createAsyncThunk(
  `${MESSAGING}/closeChannel`,
  async ({ channelIds, payload }) => {
    await closeChannel(channelIds, payload);
  },
);

export default {};
