import { createAsyncThunk } from '@reduxjs/toolkit';
import { PASSWORDS } from '@store/slices/resources';
import { createPassword } from '@services/requests/passwords';

export const ThunkAddPassword = createAsyncThunk(
  `${PASSWORDS}/addPassword`,
  async payload => {
    const res = await createPassword(payload);
    return res;
  },
);

export default {};
