import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConnectWiseTicketNote } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkDeleteConnectWiseTicketNote = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/deleteConnectWiseTicketNote`,
  async ({ noteId, crmDefaultId, ticketId }) => {
    await deleteConnectWiseTicketNote(crmDefaultId, ticketId, noteId);
    return noteId;
  },
);

export default {};
