import moment from 'moment';

export const convertDurationToTime = (timeFrom, duration) => {
  const date = moment(Date.parse(`1900-01-01T${timeFrom}:00`));
  const hours = Math.floor(duration / 60);
  date.add(hours, 'H');
  date.add(duration - hours * 60, 'minutes');
  return date.format('HH:mm');
};

export default {};
