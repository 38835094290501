import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccessModulesLoadingSelector,
  AccessModulesTemplateSelector,
} from '@store/slices/admin';
import Loading from '@common/Loading/Loading';
import { fields } from '@components/Admin/TenantModules/helpers';
import { Field, Form } from 'react-final-form';
import Checkbox from '@common/Checkbox/Checkbox';
import ReusableButton from '@common/Button/Button';
import {
  ThunkUpdateAccessModuleTemplate,
  ThunkGetAccessModulesTemplate,
} from '@store/slices/admin/thunks';
import useStyles from '@components/Admin/TenantModules/styles';

const TenantModulesTemplate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);

  const model = useSelector(AccessModulesTemplateSelector);
  const loading = useSelector(AccessModulesLoadingSelector);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetAccessModulesTemplate());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const submit = useCallback(
    values => {
      setProcessing(true);
      dispatch(ThunkUpdateAccessModuleTemplate(values))
        .unwrap()
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        initialValues={model}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <span>
              This configuration will be affected during new tenant registration
            </span>
            <div className={classes.checkBoxesGrid}>
              {fields.map(item => (
                <div key={item.name} className={classes.formItem}>
                  <Field
                    name={item.name}
                    id={item.name}
                    type="checkbox"
                    component={Checkbox}
                    disabled={processing}
                  />
                  <span>{item.description}</span>
                </div>
              ))}
            </div>
            <div className={classes.dialogActions}>
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default TenantModulesTemplate;
