import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  flexDisplaying: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldWrapper: {
    margin: '0 10px',
  },
  inputWidth: {
    width: '150px',
  },
  sizesWidth: {
    width: '75px',
  },
  periodWidth: {
    width: '150px',
  },
}));

export default useStyles;
