import {
  incidentStatuses,
  triggerPriorities,
} from '@ui/components/Triggers/components/helpers';

import crmTicketStatuses from '@constants/crmTicketStatuses';
import { crmSourcesEnum } from '@constants/crmSources';

import getFormattedDate from '@utils/getFormattedDate';
import { getDateDifference } from '@utils/getDatesDifference';

export const normalizeIncident = ({
  id,
  dateTriggered,
  triggerName,
  parserName,
  webHookName,
  assigneeName,
  incidentStatus,
  priority,
  tags,
  emailSubject,
  incidentSource,
  triggerId,
  escalated,
}) => ({
  id,
  assigneeName,
  emailSubject,
  triggerId,
  escalated,
  tags: tags.join(', '),
  sourceName: triggerName || parserName || webHookName,
  sourceType: incidentSource,
  dateTriggered: getFormattedDate(dateTriggered),
  incidentStatus: incidentStatuses[incidentStatus]?.label,
  incidentStatusColor: incidentStatuses[incidentStatus]?.color,
  priorityLabel: triggerPriorities[priority]?.label,
  priorityColor: triggerPriorities[priority]?.color,
});

export const normalizeTicket = ({
  ticketId,
  id,
  ticketStatus,
  crmType,
  crmDefaultId,
}) => ({
  id,
  ticketId,
  crmDefaultId,
  status: crmTicketStatuses[ticketStatus]?.name,
  statusColor: crmTicketStatuses[ticketStatus]?.color,
  crmName: crmSourcesEnum[crmType],
});

export const normalizeTimeEntry = ({
  id,
  name,
  note,
  startDateTime,
  endDateTime,
}) => ({
  id,
  note,
  userName: name,
  startTime: getFormattedDate(startDateTime),
  duration: getDateDifference(startDateTime, endDateTime),
});
export default {};
