import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';

import useStyles from './styles';

const ActionButton = ({
  toolTip = '',
  icon,
  handler,
  disabled = false,
  classNameWrapper,
  type = 'button',
  disabledStyling = false,
}) => {
  const classes = useStyles();
  const isDisabled = disabled || disabledStyling;

  return (
    <span style={{ cursor: 'not-allowed' }} className={classNameWrapper}>
      <Tooltip
        title={toolTip}
        PopperProps={{ style: { pointerEvents: 'none' } }}
      >
        <IconButton
          color="primary"
          component="span"
          onClick={handler}
          disabled={disabled}
          style={{ filter: isDisabled ? 'grayscale(1)' : 'none' }}
          type={type}
          classes={{
            root: classes.iconButton,
            colorPrimary: classes.colorPrimary,
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </span>
  );
};

ActionButton.propTypes = {
  toolTip: PropTypes.string,
  icon: PropTypes.element.isRequired,
  handler: PropTypes.func,
  disabled: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button']),
};

export default ActionButton;
