import React from 'react';
import { ReactComponent as TicketIcon } from '@assets/icons/user.svg';
import List from './CrmUsers';

const Icon = () => <TicketIcon alt="smsIcon" />;

export default {
  list: List,
  icon: Icon,
};
