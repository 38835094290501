import { createAsyncThunk } from '@reduxjs/toolkit';
import { getZendeskTicketStatusesByChannelId } from '@services/requests/crmTicket';
import { CRM } from '@store/slices/resources';

export const ThunkGetZendeskTicketStatusesByChannelId = createAsyncThunk(
  `${CRM}/getZendeskTicketStatusesByChannelId`,
  async ticketId => {
    const statuses = await getZendeskTicketStatusesByChannelId(ticketId);
    return statuses;
  },
);

export default {};
