/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useRedirect } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment/moment';
import { MailOutline as MailOutlineIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { defaultDateFormat } from '@constants/formats';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDelete from '@assets/icons/delete.svg';
import iconWebHook from '@assets/icons/webhooks.svg';
import { deleteDataItem, getMailBoxId, getWebHookId } from '../helpers';
import ColumnViewSelector from '../ColumnViewSelector';

import useStyles from './styles';

const isGuid = value =>
  value.match(
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{8,12}/gi,
  );

const PostPanel = ({
  records,
  fields,
  typeValues,
  showActions = true,
  onClose,
  recordId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const classes = useStyles();

  const list = useSelector(listSelector);

  const handleRedirectToMailClick = async id => {
    getMailBoxId(id)
      .then(d => {
        redirect(`mailbox/${d}/letter/${id}`);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const handleRedirectToHookClick = async id => {
    getWebHookId(id)
      .then(d => {
        redirect(`webHooks/results/${d}`);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const handleDeleteRecordClick = mailId => {
    deleteDataItem(recordId, mailId)
      .then(() => {
        const newList = list.map(item =>
          item.id !== recordId
            ? item
            : {
                ...item,
                __data__: item.__data__.filter(
                  itemData => itemData.__m_id !== mailId,
                ),
              },
        );
        dispatch(actions.saveList(newList));
        enqueueSnackbar('Record removed', { variant: 'success' });
        onClose();
      })
      .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const getCell = (id, data, field) => {
    return (
      <TableCell key={`${data.id}${id}`}>
        <ColumnViewSelector
          source={field.field}
          item={field}
          record={data}
          label={field.name ? field.name : field.field}
          typeValues={typeValues}
        />
      </TableCell>
    );
  };

  const getRow = dataRow => {
    return (
      <TableRow key={dataRow.id}>
        {fields.map((field, id) => getCell(id, dataRow, field))}
        {showActions && (
          <TableCell>
            {moment(dataRow.__time).format(defaultDateFormat)}
          </TableCell>
        )}
        {showActions && (
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {dataRow.__mailbox_name && isGuid(dataRow.__mailbox_name) ? (
                <ActionButton
                  icon={
                    <img
                      src={iconWebHook}
                      alt="Download"
                      style={{ filter: 'grayscale(100%)' }}
                    />
                  }
                  handler={() => handleRedirectToHookClick(dataRow.__m_id)}
                  toolTip="Redirect to web hook"
                />
              ) : (
                <ActionButton
                  icon={
                    <MailOutlineIcon classes={{ root: classes.mailWrapper }} />
                  }
                  handler={() => handleRedirectToMailClick(dataRow.__m_id)}
                  toolTip="Redirect to email"
                />
              )}
              <ActionButton
                icon={<img src={iconDelete} alt="iconDelete" />}
                handler={() => handleDeleteRecordClick(dataRow.__m_id)}
                toolTip="Remove result"
              />
            </div>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <div>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {fields.map(f => (
                <TableCell key={f.field}>
                  <b>{f.name}</b>
                </TableCell>
              ))}
              {showActions && (
                <TableCell>
                  <b>Email date & time</b>
                </TableCell>
              )}
              {showActions && (
                <TableCell>
                  <b>actions</b>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{records.map(row => getRow(row))}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

PostPanel.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  typeValues: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  recordId: PropTypes.string,
  showActions: PropTypes.bool,
  records: PropTypes.arrayOf(PropTypes.object),
};

export default PostPanel;
