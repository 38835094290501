import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import iconPlus from '@assets/icons/plusGrey.svg';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
  ThunkAddConversationTemplate,
  ThunkEditConversationTemplate,
  ThunkGetConversationTemplateVariables,
} from '@store/slices/messaging/thunks';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';

import { MAX_SMS_VALUE_COUNT } from '@constants/common';

import useStyles from '../styles';

const AddConversationTemplateDialog = ({ open, setOpen, item = undefined }) => {
  const [processing, setProcessing] = useState(false);
  const [responseAnchorEl, setResponseAnchorEl] = useState(null);
  const [smsTemplateVariables, setSmsTemplateVariables] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ThunkGetConversationTemplateVariables())
      .unwrap()
      .then(p => setSmsTemplateVariables(p));
  }, []);

  const submit = async values => {
    setProcessing(true);
    if (item) {
      await dispatch(
        ThunkEditConversationTemplate({ id: item.id, payload: values }),
      );
    } else {
      await dispatch(ThunkAddConversationTemplate(values));
    }
    setOpen(false);
    setProcessing(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {item ? 'Edit ' : 'Add '}
        Template
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={item || {}}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Name"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                    dateProps={{ maxLength: 30 }}
                  />
                }
              />

              <FieldWrapper
                label="Text"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameContainer={classes.formItem}
                classNameLabelContainer={classes.dialogLabelContainer}
                classNameLabelInner={classes.alignLeft}
                content={
                  <div className={classes.dialogTemplate}>
                    <ActionButton
                      handler={event =>
                        setResponseAnchorEl(event.currentTarget)
                      }
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={processing}
                    />
                    <Field
                      name="text"
                      id="text"
                      render={Input}
                      multiline
                      minRows={3}
                      maxRows={4}
                      dateProps={{ maxLength: MAX_SMS_VALUE_COUNT }}
                      helperText={`${
                        values.text ? values.text.length : 0
                      }/${MAX_SMS_VALUE_COUNT}`}
                      disabled={processing}
                    />
                  </div>
                }
              />

              <div className={classes.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                />
              </div>

              <TemplateMenu
                anchorEl={responseAnchorEl}
                setAnchorEl={setResponseAnchorEl}
                form={form}
                name="text"
                templateVariables={smsTemplateVariables}
              />
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddConversationTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default AddConversationTemplateDialog;
