import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Menu, Paper } from '@material-ui/core';

import Chip from '@common/Chip/Chip';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import useStyles from './styles';

const LabelValueChipDialog = ({
  name,
  values,
  setValues,
  fullArray,
  label,
  disabled,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!values) {
    return null;
  }

  const getMenuItems = () => {
    return fullArray.filter(el => {
      return !values.includes(el.value);
    });
  };

  const handleClick = event => {
    if (getMenuItems().length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleSelect = item => {
    setValues({
      target: {
        name,
        value: [...values, item],
      },
    });
    setAnchorEl(null);
  };
  const handleDelete = value => {
    const newData = [...values].filter(i => i !== value);
    setValues({
      target: {
        name,
        value: newData,
      },
    });
  };
  return (
    <div>
      <Paper className={classes.root}>
        <h5 className={classes.labelWrapper}>{label}</h5>
        <div className={classes.container}>
          <Box className={classes.flexWrapper}>
            {fullArray
              .filter(i => values.includes(i.value))
              .map(data => {
                return (
                  <li key={data.value}>
                    <Chip
                      label={data.label}
                      onDelete={() => handleDelete(data.value)}
                      className={classes.chip}
                      disabled={disabled}
                    />
                  </li>
                );
              })}
          </Box>
          <div style={{ marginRight: '0.625rem' }}>
            <ActionButton
              classNameWrapper={classes.actionButton}
              handler={handleClick}
              icon={<img src={iconPlus} alt="iconPlus" />}
              toolTip="Add item"
              disabled={disabled}
            />
          </div>
        </div>
      </Paper>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {getMenuItems().map(i => (
          <MenuItem key={i.value} onClick={() => handleSelect(i.value)}>
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

LabelValueChipDialog.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.any),
  fullArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  setValues: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default LabelValueChipDialog;
