import localStorage from './localStorage';

const columnsSize = 'columnsSize';
const listViewColumns = 'listViewColumns';

export const setColumnsTableSize = (tableKey, { colunmKey, width }) => {
  const size = localStorage.getItem(columnsSize) || {};
  const newSize = {
    ...size,
    [tableKey]: {
      ...size[tableKey],
      [colunmKey]: width,
    },
  };
  localStorage.setItem(columnsSize, newSize);
};

const getColumnsTableSize = key => resource =>
  localStorage.getItem(key)?.[resource];

export const setColumnsInStorage = (tableKey, { columnsData }) => {
  const columns = localStorage.getItem(listViewColumns) || {};
  const newState = { ...columns };
  newState[tableKey] = columnsData;
  localStorage.setItem(listViewColumns, newState);
};

const getHiddenTableColumns = key => tableKey =>
  localStorage.getItem(key)?.[tableKey]?.hiddenColumns;

const getTableColumnsOrder = key => tableKey =>
  localStorage.getItem(key)?.[tableKey]?.columnsOrder;

export default {
  setColumnsTableSize,
  getColumnsTableSize: getColumnsTableSize(columnsSize),
  setColumnsInStorage,
  getHiddenTableColumns: getHiddenTableColumns(listViewColumns),
  getTableColumnsOrder: getTableColumnsOrder(listViewColumns),
};
