import LocalStorage from '@constants/localStorage';

export const getHeaders = authenticated => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (authenticated)
    headers.set('Authorization', 'Bearer '.concat(localStorage.accessToken));
  return headers;
};

export const clearTokens = () => {
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  localStorage.removeItem(LocalStorage.USER_ID);
  localStorage.removeItem(LocalStorage.ROLE_ID);
  localStorage.removeItem(LocalStorage.ALLOWED_RESOURCES);
  localStorage.removeItem(LocalStorage.TENANT_ID);
  localStorage.removeItem(LocalStorage.TENANT_NAME);
  localStorage.removeItem(LocalStorage.TENANT_IMAGE);
  localStorage.removeItem(LocalStorage.REFRESH_TOKEN);
};

export const hasTokens = () =>
  !!localStorage.accessToken && !!localStorage.username;

export const saveTokens = arg => {
  const data = arg.length > 0 && arg[0] !== undefined ? arg[0] : {};
  const dataKeys = Object.keys(data);
  dataKeys.forEach(key => {
    if (key.toLowerCase().includes('token') && key !== 'refreshToken') {
      localStorage.accessToken = data[key];
      return;
    }

    localStorage[key] = data[key];
  });
};
export default {};
