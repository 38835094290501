import React from 'react';
import { ReactComponent as HookIcon } from '@assets/icons/document.svg';

import List from './HookTemplates';

const Icon = () => <HookIcon alt="keyIcon" />;

export default {
  list: List,
  icon: Icon,
};
