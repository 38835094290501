import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAIPricePlans } from '@services/requests/helpdeskAI';

export const ThunkGetAIPricePlans = createAsyncThunk(
  'helpdeskAI/getAIPricePlans',
  () =>
    getAIPricePlans().then(res =>
      res
        .filter(i => i.isAvailable)
        .map(i => ({
          id: i.id,
          price: i.basePriceCents / 100,
          amountOfMinutes: i.limitInMinutes,
          scopes: i.scopes,
          name: i.name,
        })),
    ),
);

export default {};
