import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import {
  getConnectwiseNewPodLink,
  getConnectwisePodLink,
} from '@services/requests/psaPods';

export const ThunkGetConnectwisePodLink = createAsyncThunk(
  `${CONNECTWISE_POD}/getConnectwisePodLink`,
  async ({ crmId, useNewPodLink = false }) => {
    if (!useNewPodLink) {
      return getConnectwisePodLink(crmId);
    }

    return getConnectwiseNewPodLink(crmId);
  },
);

export default {};
