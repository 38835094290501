import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ThunkAssignResourceToChat,
  ThunkCloseConversation,
} from '@store/slices/liveChats/thunks';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import LiveChatAssignDialog from '@components/LiveChat/dialogs/LiveChatAssignDialog';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import CloseLiveChatWIthTicketDialog from '@components/LiveChat/dialogs/CloseLiveChatWIthTicketDialog';

import ViewIcon from '@assets/icons/visibility.svg';
import UserIcon from '@assets/icons/user.svg';
import CloseIcon from '@assets/icons/cross.svg';
import ReopenIcon from '@assets/icons/refreshItem.svg';

import { LIVE_CHAT_DETAILS } from '@constants/routes';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import LiveChatReopenDialog from '@components/LiveChat/dialogs/LiveChatReopenDialog';
import useStyles from './styles';

const LiveChatTableItemActions = ({
  id,
  selectedResource,
  ticketId,
  isClosed,
  ...rest
}) => {
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [
    openCloseConversationDialog,
    setOpenCloseConversationDialog,
  ] = useState(false);
  const [openReopenDialog, setOpenReopenDialog] = useState(false);

  const classes = useStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const handleDataReload = useCallback(() => {
    setOpenReopenDialog(false);
    dispatch(
      actions.updateItemInList({
        ...rest,
        id,
        ticketId,
        isClosed: false,
      }),
    );
  }, [rest, id, ticketId, dispatch]);

  const handleCloseAction = useCallback(async () => {
    setProcessing(true);
    await dispatch(ThunkCloseConversation({ liveChatId: id }))
      .unwrap()
      .then(() => {
        dispatch(actions.saveList(list.filter(i => +id !== +i.id)));
        setOpenCloseConversationDialog(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [id, dispatch]);

  const handleAssignDialogSelectValue = async value => {
    setProcessing(true);
    await dispatch(
      ThunkAssignResourceToChat({
        chatId: assignDialogOpen,
        assigneeId: value,
      }),
    )
      .unwrap()
      .then(response => {
        const listItem = list.find(p => p.id === assignDialogOpen);
        listItem.assigneeId = response.id.toString();
        listItem.assigneeName = response.value;
        dispatch(actions.updateItemInList(listItem));
      })
      .finally(() => {
        setProcessing(false);
        setAssignDialogOpen(false);
      });
  };

  return (
    <div className={classes.actionsContainer}>
      <ActionFieldItem
        icon={ViewIcon}
        alt="view"
        handler={() => {
          push(LIVE_CHAT_DETAILS.replace(':id', id));
        }}
        toolTip="View"
      />
      <ActionFieldItem
        icon={UserIcon}
        alt="assign"
        handler={() => {
          setAssignDialogOpen(id);
        }}
        toolTip="Assign"
      />

      {isClosed ? (
        <ActionFieldItem
          icon={ReopenIcon}
          alt="reopen"
          handler={() => {
            setOpenReopenDialog(true);
          }}
          toolTip="Reopen"
        />
      ) : (
        <ActionFieldItem
          icon={CloseIcon}
          alt="Close"
          handler={() => {
            setOpenCloseConversationDialog(true);
          }}
          toolTip="Close"
        />
      )}

      {openReopenDialog && (
        <LiveChatReopenDialog
          open={openReopenDialog}
          setOpen={setOpenReopenDialog}
          liveChatId={id}
          ticketId={ticketId}
          handleDataReload={handleDataReload}
        />
      )}

      <LiveChatAssignDialog
        open={assignDialogOpen}
        onSelect={handleAssignDialogSelectValue}
        onClose={() => setAssignDialogOpen(false)}
        disabled={processing}
        resourceId={selectedResource}
      />

      {/* USE Different Dialogs for Closing Chat with and without linked ticket */}
      {!ticketId && openCloseConversationDialog && (
        <ConfirmDialog
          loading={processing}
          open={!!openCloseConversationDialog}
          setOpen={setOpenCloseConversationDialog}
          onAccept={handleCloseAction}
          onCancel={() => setOpenCloseConversationDialog(false)}
          title="Close conversation"
          content="Are you sure you want to close this conversation? This action can not be undone."
        />
      )}
      {ticketId && openCloseConversationDialog && (
        <CloseLiveChatWIthTicketDialog
          setOpen={setOpenCloseConversationDialog}
          liveChatId={id}
          open={openCloseConversationDialog}
        />
      )}
    </div>
  );
};

export default LiveChatTableItemActions;
