import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Radio } from '@material-ui/core';

import GrayIconsTheme from '@services/themes/GrayIconsTheme';
import useStyles from './styles';

const CustomRadio = ({ classNameWrapper, ...rest }) => {
  const classes = useStyles();
  return (
    <GrayIconsTheme>
      <Radio
        classes={{ root: cx(classes.radioWrapper, classNameWrapper) }}
        {...rest}
      />
    </GrayIconsTheme>
  );
};

CustomRadio.propTypes = {
  classNameWrapper: PropTypes.string,
};

export default CustomRadio;
