import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    textAlign: 'center',
    borderRadius: '0.3125rem',
    textTransform: 'none !important',
    fontSize: '0.875rem !important',

    position: 'relative',
    padding: '0.2rem 0',
    height: '35px',
    '& svg': {
      fill: '#000',
    },
    '&:disabled': {
      backgroundColor: '#E2E2E2',
      border: 'none',
      color: '#fff',
      '& svg': {
        fill: '#fff',
      },
    },
  },
  normalText: {
    '& span': {
      fontSize: '1rem',
    },
  },
  miniText: {
    '& span': {
      fontSize: '0.8rem',
    },
  },
  blackButton: {
    background: theme.palette.info.main,
    color: '#fff',

    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
  orangeButton: {
    background: theme.palette.secondary.main,
    color: '#fff',

    '&:hover': {
      background: theme.palette.warning.main,
    },
  },
  whiteButton: {
    border: `0.0625rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    background: 'white',
    '&:hover': {
      background: theme.palette.info.light,
    },
  },
  redButton: {
    background: theme.palette.failed?.light,
    color: '#fff',

    '&:hover': {
      background: theme.palette.failed?.main,
    },
  },
  blueButton: {
    background: 'linear-gradient(111.38deg, #012169 35.94%, #091456 84.77%)',
    color: 'white',
    '&:disabled': {
      backgroundColor: '#E2E2E2',
      border: 'none',
      color: '#fff',
      background: 'unset',
      '& svg': {
        fill: '#fff',
      },
    },
  },
  smallButton: {
    width: '2.5rem',
  },
  mediumButton: {
    width: '10rem',
  },
  largeButton: {
    width: '15rem',
  },
  bigButton: {
    width: '11.875rem',
  },

  flexPosition: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonWrapper: {
    marginTop: '0.1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
    },

    '& span': {
      paddingLeft: 0,
      '& svg': {
        fontSize: '18px',
      },
      '& img': {
        margin: '0 .5rem',
      },
    },
  },
  tooltipWrapper: {
    display: 'inline',
  },
}));

export default useStyles;
