import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingChannelCrmInfo } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingChannelCrmInfo = createAsyncThunk(
  `${MESSAGING}/getMessagingChannelCrmInfo`,
  async id => {
    const crmInfo = getMessagingChannelCrmInfo(id);
    return crmInfo;
  },
);

export default {};
