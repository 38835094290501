import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { notificationSettingSource } from '@store/selectors/notifications';
import { Field } from 'react-final-form';
import FormSwitchWrapper from '@components/Ticketing/Notifications/TicketSettings/components/FormSwitchWrapper';
import FieldWrapper from '@common/form/FieldWrapper';

const IsScheduledSelector = ({ values, disabled }) => {
  const settingSource = useSelector(notificationSettingSource);
  return (
    settingSource === 1 || (
      <FieldWrapper
        label={values.isScheduled ? 'Schedule sending' : 'Instant'}
        labelSize={2}
        isCheckbox
        content={
          <Field
            id="isScheduled"
            name="isScheduled"
            type="checkbox"
            component={FormSwitchWrapper}
            disabled={disabled}
          />
        }
      />
    )
  );
};

IsScheduledSelector.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default IsScheduledSelector;
