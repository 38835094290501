import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { closeClientPortalChat } from '@services/requests/clientPortal';

export const ThunkCloseClientPortalChat = createAsyncThunk(
  `${CLIENT_PORTALS}/closeClientPortalChat`,
  ({ chatId }) => closeClientPortalChat(chatId),
);

export default {};
