import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRedirect } from 'react-admin';
import useAsync from '@services/api/common/useAsync';
import TestBox from '@common/TestBox/TestBox';
import { getData } from '../helpers';

const TestDialog = ({ open, onClose, parser }) => {
  const redirect = useRedirect();
  const [data, setData] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getA = useCallback(() => getData(parser, onClose, redirect), []);

  const loading = useAsync(getA, setData);

  return (
    <TestBox open={open} onClose={onClose} data={data} loading={loading} />
  );
};

TestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TestDialog;
