import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSafeSetState } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { enqueueSnackbar } from 'notistack';

import { TEXT_CREATE_SUCESS } from '@constants/texts/common';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import useStyles from '../styles';
import { createType } from '../../helpers';

const CreateTypeDialog = props => {
  const classes = useStyles();

  const { onSubmit, onClose, open } = props;

  const [loading, setLoading] = useSafeSetState(false);
  const validate = values => {
    const errors = { name: undefined };

    if (!values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  const submit = async value => {
    setLoading(true);
    createType(value)
      .then(res => {
        enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' });
        onSubmit(res);
        onClose();
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Add new custom type</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To indicate data after mail messages parsing please describe your
          custom types. Name should be short and understandable.
        </DialogContentText>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  styleType="main"
                  inputView="text"
                  placeholder="Type name"
                  disabled={loading}
                />
              </div>
              <div style={{ textAlign: 'end' }}>
                <ReusableButton
                  label="Cancel"
                  onClick={onClose}
                  disabled={loading}
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                />
                <ReusableButton
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  loading={loading}
                  label="OK"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateTypeDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateTypeDialog;
