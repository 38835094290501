import React, { useCallback, useMemo } from 'react';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { useField, useForm } from 'react-final-form';

import ArrowDownIcon from '@assets/icons/arrow.svg';
import CloseIcon from '@assets/icons/cross.svg';
import Icon from '@common/Icon/Icon';
import useStyles from './styles';

const CustomPaper = props => {
  return <Paper variant="outlined" square {...props} />;
};

const InputWithAutocomplete = ({
  name,
  label,
  options = [],
  disabled = false,
  disableClearable = false,
  limitTags = 2,
  disableCloseOnSelect = true,
  margin = 'dense',
  freeSolo = false,
  loading = false,
  helperText,
  onInputChange,
  onChange: parentOnChange,
  renderOption,
  getOptionValue,
  getOptionLabel,
  getOptionSelected,
  ...restProps
}) => {
  const classes = useStyles();
  const form = useForm();

  const {
    input: { onChange, value, ...restFieldProps },
    meta: { invalid, touched },
  } = useField(name);

  const errors = useMemo(() => form.getState().errors, [
    invalid,
    touched,
    value,
  ]);

  const fieldValue = useMemo(() => {
    if (!isArray(value)) return [];

    let selectedValue = value;

    if (isFunction(getOptionValue)) {
      selectedValue = value.map(valueOption => {
        return (
          options.find(
            option => getOptionValue(option) === String(valueOption),
          ) || valueOption
        );
      });
    }

    return selectedValue;
  }, [getOptionValue, value, options]);

  const errorProps = useMemo(() => {
    if (!touched) {
      return {
        error: false,
        helperText: null,
      };
    }

    const fieldErrors = get(errors, name);
    if (!fieldErrors)
      return {
        error: invalid,
        helperText: invalid ? 'Invalid value' : helperText,
      };

    if (isArray(fieldErrors))
      return {
        error: invalid,
        helperText: invalid ? (
          <div
            name={name}
            message={compact(fieldErrors.map(({ message }) => message))
              .join(', ')
              .replaceAll(name, label)}
          />
        ) : (
          helperText
        ),
      };

    return {
      error: invalid,
      helperText: invalid ? fieldErrors : helperText,
    };
  }, [errors, name, invalid, helperText, label, touched]);

  const handleInputChange = useCallback(
    (e, data) => {
      if (onInputChange) {
        onInputChange(data);
      }
    },
    [onInputChange],
  );

  const handleChange = useCallback(
    (e, data) => {
      let changedData = data || [];

      if (isFunction(getOptionValue)) {
        changedData = data.map(d => getOptionValue(d));
      }

      onChange(changedData);

      if (parentOnChange) {
        parentOnChange(changedData);
      }
    },
    [getOptionValue, onChange, parentOnChange],
  );

  return (
    <Autocomplete
      fullWidth
      options={options}
      onChange={handleChange}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          {...errorProps}
          label={label}
          margin={margin}
          className={classes.autocompleteInput}
          size="small"
          inputProps={{ ...params?.inputProps, 'data-lpignore': true }}
          autoComplete="off"
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => {
          const getLabel = () => {
            if (loading) return 'loading...';

            return isFunction(getOptionLabel) ? getOptionLabel(option) : option;
          };

          const tagProps = getTagProps({ index });

          return (
            <Chip
              key={option}
              variant="outlined"
              label={getLabel()}
              size="small"
              className={classes.autocompleteChip}
              {...tagProps}
            />
          );
        })
      }
      onInputChange={handleInputChange}
      defaultValue={fieldValue}
      value={fieldValue.filter(i => !!i)}
      disabled={disabled}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      multiple
      freeSolo={freeSolo}
      loading={loading}
      size="small"
      limitTags={limitTags}
      getLimitTagsText={more => `+${more} more`}
      PaperComponent={CustomPaper}
      popupIcon={
        <Icon
          icon={ArrowDownIcon}
          alt="down"
          style={{ width: 20, height: 20 }}
        />
      }
      closeIcon={
        <Icon icon={CloseIcon} alt="close" style={{ width: 15, height: 15 }} />
      }
      {...restFieldProps}
      {...restProps}
    />
  );
};

export default InputWithAutocomplete;
