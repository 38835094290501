import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIncidentTickets } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';
import { normalizeTicket } from '../generator';

export const ThunkGetIncidentTickets = createAsyncThunk(
  `${INCIDENT}/getIncidentTickets`,
  async incidentId => {
    const incidentTickets = await getIncidentTickets(incidentId);
    return incidentTickets.map(ticket => normalizeTicket(ticket));
  },
);

export default {};
