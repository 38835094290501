import { axiosInstance } from '@components/Table/axios';

/*
 * React Admin V3 Data Provider require to have all fields
 *  but on platform we use only
 *  - getOne (with hook useGetOne)
 *  - create
 *  - update
 *  - updateMany
 *  - delete
 *  - getList (in table)
 *  */

const dataProvider = {
  getOne: (resource, { id }) =>
    axiosInstance.request({
      method: 'GET',
      url: resource.endsWith('/') ? `${resource}${id}` : `${resource}/${id}`,
    }),
  create: (resource, { data }) =>
    axiosInstance.request({
      method: 'POST',
      url: resource,
      data,
    }),
  update: (resource, { id, data }) =>
    axiosInstance.request({
      method: 'PUT',
      url: resource.endsWith('/') ? `${resource}${id}` : `${resource}/${id}`,
      data,
    }),
  delete: (resource, { id }) =>
    axiosInstance.request({
      method: 'Delete',
      url: resource.endsWith('/') ? `${resource}${id}` : `${resource}/${id}`,
    }),
  updateMany: (resource, params) => {
    params.ids.forEach(id => {
      axiosInstance.request({
        method: 'PUT',
        url: resource.endsWith('/') ? `${resource}${id}` : `${resource}/${id}`,
        data: null,
      });
    });

    return Promise.resolve({ data: {} });
  },
  getList: async (resource, params) =>
    axiosInstance
      .request({
        method: 'GET',
        url: resource,
        params: {
          ...params.filter,
          _start: (params.pagination.page - 1) * params.pagination.perPage,
          _end:
            (params.pagination.page - 1) * params.pagination.perPage +
            params.pagination.perPage,
          _sort: params.sort.field ?? null,
          _order: params.sort.order ?? null,
        },
      })
      .then(d => ({
        data: d.data,
        total: d.headers['x-total-count'],
      })),
  deleteMany: () => Promise.resolve({ data: {} }),
  getMany: () => Promise.resolve({ data: {} }),
  getManyReference: () => Promise.resolve({ data: {} }),
};
export default dataProvider;
