import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';

import { MAILBOX_SETTINGS } from '@constants/routes';
import Loading from '@common/Loading/Loading';
import ReactQueryBuilder from '@common/ReactQueryBuilder/ReactQueryBuilder';
import { getQuery, setQuery } from '@common/ReactQueryBuilder/helpers';
import useStyles from './styles';

const MessageIgnoreExpression = ({ mailBoxId }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [model, setModel] = useState({});
  const [checked, setChecked] = useState(false);
  const [componentTouched, setComponentTouched] = useState(false);

  const [componentQueryData, setComponentQueryData] = useState('');
  const handleQueryBuilderChange = value => {
    setComponentQueryData(value);
    setComponentTouched(true);
  };

  useEffect(() => {
    dataProvider
      .getOne(MAILBOX_SETTINGS, { id: mailBoxId })
      .then(({ data }) => {
        setLoading(false);
        setModel(data);
        const exprData = data.ignoreExpressions
          ? JSON.parse(data.ignoreExpressions)
          : null;
        setComponentQueryData(getQuery(exprData));
        setChecked(data.enableIgnoring);
      })
      .catch(error => console.error(error.message));
  }, [dataProvider, mailBoxId]);

  const update = () => {
    setSendingData(true);
    const expression = componentTouched
      ? JSON.stringify(setQuery(componentQueryData))
      : model.ignoreExpressions;
    dataProvider
      .update(MAILBOX_SETTINGS, {
        id: model.id,
        data: {
          ...model,
          ignoreExpressions: expression,
          enableIgnoring: checked,
        },
      })
      .then(({ data }) => {
        setModel(data);
      })
      .catch(error => {
        console.log(error.message);
      })
      .finally(() => setSendingData(false));
  };

  const create = () => {
    setSendingData(true);
    const expression = componentTouched
      ? JSON.stringify(setQuery(componentQueryData))
      : model.ignoreExpressions;
    dataProvider
      .create(MAILBOX_SETTINGS, {
        data: {
          ...model,
          ignoreExpressions: expression,
          enableIgnoring: checked,
        },
      })
      .then(({ data }) => {
        setModel(data);
      })
      .catch(error => {
        console.log(error.message);
      })
      .finally(() => setSendingData(false));
  };

  const handleSave = () => {
    if (model.id !== 0) {
      update();
    } else {
      create();
    }
  };

  const handleChange = event => setChecked(event.target.checked);

  if (loading) return <Loading />;
  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div>
              {' '}
              <FormControlLabel
                className={classes.checkboxLabel}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                disabled={sendingData}
                label="Enable message ignored rules"
              />
            </div>
            <div className={classes.queryBuilderWrapper}>
              <ReactQueryBuilder
                data={componentQueryData}
                onChange={handleQueryBuilderChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.saveButton}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSave}
                disabled={sendingData}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MessageIgnoreExpression.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default MessageIgnoreExpression;
