import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  form: {
    width: '35rem',
    padding: '1rem',
  },
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
  item: {
    margin: '1rem 0',
    width: '35rem',
  },
}));

export default useStyles;
