import makeStyles from '@material-ui/core/es/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
    },
  },
}));

export default useStyles;
