import { emailValidator, requiredValidator } from '@utils/validators';

export const userDataValidation = values => ({
  username: requiredValidator(values.username),
  email: emailValidator(values.email),
  companyName: requiredValidator(values.companyName),
  password: requiredValidator(values.password),
});

export default {};
