/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { TRIGGER_UPDATE } from '@constants/routes';

import useStyles from '../../styles';

const DetailsBlockItem = React.memo(({ label, value, color, link }) => {
  const classes = useStyles();
  const { push } = useHistory();

  return (
    <div className={classes.detailsBlockItem}>
      <div>
        <b>{label}</b>
      </div>
      <div>
        <span
          style={{
            color,
            textDecoration: link && 'underline',
            cursor: link && 'pointer',
          }}
          onClick={() => {
            if (link) {
              push(`${TRIGGER_UPDATE.replace(':id', link)}`);
            }
          }}
        >
          {value}
        </span>
      </div>
    </div>
  );
});

DetailsBlockItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  link: PropTypes.number,
};

export default DetailsBlockItem;
