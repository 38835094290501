import { createAsyncThunk } from '@reduxjs/toolkit';
import { addPhoneToPsaContact } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

export const ThunkAddPhoneToPsaContact = createAsyncThunk(
  `${OPT_IN}/addPhoneToPsaContact`,
  ({ payload }) => addPhoneToPsaContact(payload),
);

export default {};
