import React, { useMemo } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const usePhoneInitialData = ({
  phone,
  countries,
  defaultTenantCodes,
}) => {
  return useMemo(() => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    const initialIconCode = phoneNumber?.country
      ? countries
          .find(country => country.code === phoneNumber?.country)
          ?.code?.toLowerCase()
      : null;

    const generateInitialIcon = () => {
      if (!phone || !phoneNumber?.country) {
        return (
          <img
            alt="alt"
            src={`https://flagcdn.com/w20/${defaultTenantCodes.tenantCountryCode.toLowerCase()}.png`}
          />
        );
      }
      if (initialIconCode) {
        return (
          <img
            alt="alt"
            src={`https://flagcdn.com/w20/${initialIconCode}.png`}
          />
        );
      }
      return undefined;
    };

    const generateCountryCode = () => {
      if (!phone || !phoneNumber?.country) {
        return `${defaultTenantCodes.tenantCountryCode} ${defaultTenantCodes.tenantDialCode}`;
      }
      if (phoneNumber?.country) {
        return `${phoneNumber?.country} +${phoneNumber?.countryCallingCode}`;
      }
      return '';
    };

    const formatNationalNumber = () => {
      if (phoneNumber?.countryCallingCode) {
        return phone.replace(`+${phoneNumber.countryCallingCode}`, '');
      }
      return phone.replace('+', '');
    };

    return {
      initialIcon: generateInitialIcon(),
      countryCodeFormValue: generateCountryCode(),
      nationalNumber: formatNationalNumber(),
    };
  }, [countries, defaultTenantCodes, phone]);
};

export default {};
