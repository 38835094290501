import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHAT_CONVERSATIONS } from '@store/slices/resources';
import { getMessagingConversations } from '@services/requests/liveChatConversations';

export const ThunkGetLiveChatConversationTemplates = createAsyncThunk(
  `${LIVE_CHAT_CONVERSATIONS}/getConversationTemplates`,
  async () => {
    const conversations = await getMessagingConversations();
    return conversations;
  },
);

export default {};
