import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { getTenantSettings } from '@services/requests/common';

export const ThunkGetTenantSettings = createAsyncThunk(
  `${COMMON}/getTenantSettings`,
  () => getTenantSettings(),
);

export default {};
