import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { getConnectwiseTicketInfo } from '@services/requests/psaPods';

export const ThunkGetConnectwiseTicketInfo = createAsyncThunk(
  `${CONNECTWISE_POD}/getConnectwiseTicketInfo`,
  async ({ crmId, ticketId }) => {
    const res = await getConnectwiseTicketInfo(crmId, ticketId);
    return res;
  },
);

export default {};
