import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getChatById } from '@services/requests/liveChats';

export const ThunkGetChatById = createAsyncThunk(
  `${LIVE_CHATS}/getChatById`,
  ({ id }) => getChatById(id),
);

export default {};
