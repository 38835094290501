import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import SelectIntegration from '@components/DataSources/HookTemplates/CreateWizard/steps/SelectIntegration';
import Apply from '@components/DataSources/HookTemplates/CreateWizard/steps/Apply';
import SelectNetworks from '@components/DataSources/HookTemplates/CreateWizard/steps/SelectNetworks';
import CircularStatic from '@components/DataSources/Dashboards/CircularProgress';
import {
  applyAlertTemplatesById,
  logTemplateApplying,
} from '@components/DataSources/helpers';
import organizationTemplateState from '@constants/organizationTemplateState';
import useStyles from '../styles';

const steps = [
  'Select integration',
  'Select organizations and networks',
  'Apply template',
];

const CreateWizard = ({ templateId, onClose, onApply }) => {
  const classes = useStyles();
  const [integrationId, setIntegrationId] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [orgSelection, setOrgSelection] = useState({});
  const [fullOrgsList, setFullOrgsList] = useState([]);
  const [orgs, setOrgs] = useState([]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSelectIntegration = id => {
    setIntegrationId(id);
    handleNext();
  };

  const adjustProgress = (current, total) => {
    setProgress((100 * current) / total);
  };

  const handleApply = async () => {
    setLoading(true);
    const organizationStatuses = Object.keys(orgSelection)
      .filter(key => orgSelection[key].total > 0)
      .map(key => ({
        name: key,
        state:
          orgSelection[key].total === orgSelection[key].selected
            ? organizationTemplateState.full
            : organizationTemplateState.partial,
      }));
    const log = {
      templateId,
      networks: selectedNetworks.map(i => i.id).join(','),
      organizations: JSON.stringify(organizationStatuses),
      integrationId,
    };
    await logTemplateApplying(log);
    const models = selectedNetworks.map(i => ({
      networkId: i.id,
      templateId,
    }));
    // eslint-disable-next-line no-plusplus
    for (let s = 0, p = Promise.resolve(); s <= models.length; s++) {
      p = p
        .then(() =>
          s !== models.length
            ? applyAlertTemplatesById(integrationId, models[s])
            : Promise.resolve(null),
        )
        .then(rsp => {
          if (rsp === null) {
            setLoading(false);
            onApply(templateId, models.length);
            onClose();
          }
        })
        .catch(e => console.log(e.message))
        .finally(() => adjustProgress(s + 1, models.length));
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <SelectIntegration onSelect={onSelectIntegration} />;
      case 1:
        return (
          <SelectNetworks
            integrationId={integrationId}
            handleBack={handleBack}
            selectedNetworks={selectedNetworks}
            setSelectedNetworks={setSelectedNetworks}
            handleForward={handleNext}
            orgSelection={orgSelection}
            setOrgSelection={setOrgSelection}
            fullOrgsList={fullOrgsList}
            setFullOrgsList={setFullOrgsList}
            orgs={orgs}
            setOrgs={setOrgs}
          />
        );
      case 2:
        return (
          <Apply
            handleBack={handleBack}
            selectedNetworks={selectedNetworks}
            handleApply={handleApply}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={classes.stepperBody}>
      {!loading ? (
        <>
          <Stepper
            activeStep={activeStep}
            classes={{ root: classes.stepperRoot }}
          >
            {steps.map(label => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>{getStepContent(activeStep)}</div>
        </>
      ) : (
        <CircularStatic progress={progress} customHeader="Applying templates" />
      )}
    </div>
  );
};

CreateWizard.propTypes = {
  templateId: PropTypes.number,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
};

export default CreateWizard;
