import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, TopToolbar, useListContext } from 'react-admin';
import { Add } from '@material-ui/icons';
import ReusableButton from '@common/Button/Button';
import exportIcon from '@assets/icons/export.svg';
import { useHistory } from 'react-router-dom';
import { CREATE_USER } from '@constants/routes';

import useStyles from './styles';

const ListActions = ({
  className,
  maxResults,
  onRefresh,
  createPage,
  hideCreateButton,
  ...rest
}) => {
  const classes = useStyles();

  const {
    currentSort,
    resource,
    filterValues,
    total,
    basePath,
  } = useListContext();
  const { push } = useHistory();

  return (
    <TopToolbar className={cx(className)} {...sanitizeListRestProps(rest)}>
      {!hideCreateButton && (
        <ReusableButton
          size="md"
          resource={resource}
          basePath={basePath}
          icon={null}
          onClick={() => {
            push(CREATE_USER);
          }}
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <Add className={classes.plusIcon} />
            Create
          </>
        </ReusableButton>
      )}
      <ReusableButton
        buttonType="export"
        size="md"
        viewType="black"
        onClick={onRefresh}
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        icon={null}
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={exportIcon} alt="export" />
          Export
        </>
      </ReusableButton>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  exporter: PropTypes.func,
  maxResults: PropTypes.number,
  onRefresh: PropTypes.func,
  createPage: PropTypes.func,
};

export default ListActions;
