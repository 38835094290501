import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { getIntegrationImage } from '../helpers';
import useStyles from '../styles';

const SelectIntegrationCard = ({ item, handleClick, disabled = false }) => {
  const classes = useStyles({ disabled });

  const handleClickEvent = () => {
    if (!disabled) {
      handleClick(item.name);
    }
  };
  return (
    <div
      className={cx(classes.container, classes.cursor, classes.bigContainer)}
      role="presentation"
      style={{ margin: 'auto', marginTop: '20px' }}
      onClick={handleClickEvent}
    >
      <img
        src={getIntegrationImage(item.name)}
        alt=""
        style={{ width: '90%' }}
      />
      {disabled && (
        <div className={classes.disabledTile}>
          <div>Your pricing plan</div>
          <div>doesn&apos;t provide integrations</div>
          <div>
            Scale up your &nbsp;
            <Link to="/pricingPlan" className={classes.hyperlink}>
              plan
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

SelectIntegrationCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectIntegrationCard;
