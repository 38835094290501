import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import DropDown from '@components/Auth/Common/DropDown';
import FileUploader from '@components/LiveChatConfigurations/components/FileUploader';

import crmSources, { crmSourcesByType } from '@constants/crmSources';
import { SelectClientPortalConfigDefaultsOptions } from '@store/slices/clientPortalAdmin/selectors';
import { CrmSourcesSelector } from '@store/slices/treeView/selectors';
import {
  ThunkAddClientPortalLogo,
  ThunkRemoveClientPortalLogo,
} from '@store/slices/clientPortalAdmin/thunks';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';

import useStyles from '../../styles';

const allowedCrmIntegrations = [
  crmSources.ConnectWise,
  crmSources.Autotask,
  crmSources.Halo,
  crmSources.SuperOps,
];

const General = ({ item = undefined, clientPortalId }) => {
  const dispatch = useDispatch();
  const { values } = useFormState({ subscription: { values: true } });
  const form = useForm();
  const classes = useStyles();

  const crmOptions = useSelector(CrmSourcesSelector);
  const defaultsOptions = useSelector(SelectClientPortalConfigDefaultsOptions);

  const getClientPortalDefault = crmId => {
    const selectedCrm = crmOptions.find(i => i.id === crmId);
    if (selectedCrm) {
      const crmName = crmSourcesByType[selectedCrm?.crmType].name;
      return defaultsOptions.filter(i => i.info === crmSources[crmName].idx);
    }
    return [];
  };

  useEffect(() => {
    dispatch(ThunkGetCrmSources());
  }, [dispatch]);

  return (
    <Grid container spacing={2} className={classes.wrapper}>
      <Grid item xs={6}>
        <FieldWrapper
          label="Portal Name"
          labelSize={12}
          contentSize={12}
          fullWidth
          isRequired
          content={
            <Field
              name="portalName"
              id="portalName"
              render={Input}
              dateProps={{ maxLength: 250 }}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          label="Domain"
          labelSize={12}
          contentSize={12}
          fullWidth
          isRequired
          content={
            <div className={classes.domainField}>
              <Field
                name="domain"
                id="domain"
                render={Input}
                dateProps={{ maxLength: 63 }}
              />
              <span className={classes.domainView}>
                Client portal url: https://
                <span className={classes.domainName}>{values.domain}</span>
                .mspprocess.net
              </span>
            </div>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          label="Portal Color Scheme"
          labelSize={12}
          contentSize={12}
          fullWidth
          content={
            <Field
              name="portalColorScheme"
              id="portalColorScheme"
              render={Input}
              type="color"
              dateProps={{ maxLength: 250 }}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          label="Portal Logo"
          labelSize={12}
          contentSize={12}
          fullWidth
          content={
            <FileUploader
              name="portalLogo"
              resourceId={clientPortalId || item?.id}
              handleUpload={ThunkAddClientPortalLogo}
              handleRemove={ThunkRemoveClientPortalLogo}
              onUploadCallBack={async response => {
                let url;
                if (!!response && _.isString(response)) {
                  url = response;
                } else {
                  url = await response.text();
                }
                form.change('portalLogo', url);
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          label="PSA Integration"
          labelSize={12}
          contentSize={12}
          fullWidth
          isRequired
          content={
            <Field
              name="crmId"
              id="crmId"
              render={DropDown}
              options={crmOptions.filter(p =>
                allowedCrmIntegrations.some(i => i.name === p.crmType),
              )}
              labelName="name"
              valueName="id"
              isFullValue
              onInputChange={(val, option) => {
                form.change('defaultId', null);
                form.change('crmName', option.props.children[1]);
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          label="Default Ticket Properties"
          labelSize={12}
          contentSize={12}
          fullWidth
          isRequired
          content={
            <Field
              name="defaultId"
              id="defaultId"
              render={DropDown}
              options={getClientPortalDefault(values.crmId)}
              onInputChange={(val, option) => {
                form.change('defaultName', option.props.children[1]);
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          label="Default Issue Type Name"
          labelSize={12}
          contentSize={12}
          fullWidth
          isRequired
          content={
            <Field
              name="defaultIssueTypeName"
              id="defaultIssueTypeName"
              render={Input}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

General.propTypes = {
  item: PropTypes.shape({
    domain: PropTypes.string,
    psaId: PropTypes.number,
  }),
  clientPortalId: PropTypes.number,
};

export default General;
