import * as React from 'react';
import PropTypes from 'prop-types';

import envelopeIcon from '@assets/icons/attachments/envelope.svg';
import envelopeClipIcon from '@assets/icons/attachments/envelope_clip.svg';

import useStyles from './styles';

const SubjectField = ({ source, record = {}, onClick }) => {
  const classes = useStyles();

  const icon = record?.hasAttachments ? envelopeClipIcon : envelopeIcon;
  const handleClick = () => onClick(record);
  return (
    <div
      onClick={handleClick}
      className={classes.container}
      role="presentation"
    >
      <img src={icon} alt="letter" className={classes.margin} />
      {record[source]}
    </div>
  );
};

SubjectField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SubjectField;
