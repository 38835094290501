import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateCrmUserPhoneNumber } from '@services/requests/crm';
import { CRM } from '@store/slices/resources';

export const ThunkUpdateCrmUserPhoneNumber = createAsyncThunk(
  `${CRM}/updateCrmUserPhoneNumber`,
  async ({ crmDefaultId, payload }) => {
    const user = await updateCrmUserPhoneNumber(crmDefaultId, payload);
    return user;
  },
);

export default {};
