import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import { dataSizeOptions, longPeriodOptions } from './options';
import useStyles from './styles';

const UsageAlert = () => {
  const classes = useStyles();
  return (
    <div className={classes.flexDisplaying}>
      <span>Network usage exceeds</span>
      <Field
        id="threshold"
        name="threshold"
        styleType="main"
        type="number"
        inputView="number"
        classNameWrapper={cx(classes.fieldWrapper, classes.inputWidth)}
        component={Input}
      />
      <Field
        id="size"
        name="size"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.sizesWidth)}
        component={DropDown}
        options={dataSizeOptions}
      />
      <span>in</span>
      <Field
        id="period"
        name="period"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
        component={DropDown}
        options={longPeriodOptions}
      />
    </div>
  );
};

export default UsageAlert;
