import React from 'react';
import { ReactComponent as TenantsIcon } from '@assets/icons/tenants.svg';
import List from './List';

const Icon = () => <TenantsIcon alt="tenantsIcon" />;

export default {
  list: List,
  icon: Icon,
};
