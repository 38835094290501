import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getHaloWebhookLink } from '@services/requests/verificationSettings';

export const ThunkGetHaloWebhookLink = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getHaloWebhookLink`,
  async ({ payload }) => getHaloWebhookLink(payload),
);

export default {};
