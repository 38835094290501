import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '60vw',
    alignSelf: 'center',
    marginTop: '2rem',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': { marginLeft: '5px' },
  },
  label: {
    height: '53px',
    alignItems: 'center',
    display: 'flex',
  },
  sourceWrapper: {
    position: 'relative',
  },
  sourceType: {
    fontSize: '11px',
    margin: 0,
    textAlign: 'center',
    color: props => props.color,
  },
}));

export default useStyles;
