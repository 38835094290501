import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Menu, MenuItem, Paper, Modal, Box } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import Chip from '@common/Chip/Chip';
import ReusableButton from '@common/Button/Button';
import Input from '@common/Input/Input';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ActionButtonLight from '@common/buttons/ActionButton/ActionButtonLight';
import iconPlus from '@assets/icons/plusGrey.svg';
import ArrowIcon from '@assets/icons/iconmonstr-arrow-24.svg';
import useStyles from './styles';

const ChipInputDialog = ({ name, chipData, setChipData, hints, label }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [modalStyle, setModelStyle] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const notifyParent = value => {
    setChipData({
      target: {
        name,
        value,
      },
    });
  };

  const getHints = () => (!!hints && name in hints ? hints[name] : []);

  const handleDelete = chipToDelete => {
    const newChips = chipData.filter(chip => chip !== chipToDelete);
    notifyParent(newChips);
  };

  const addRecord = () => {
    if (modalText) {
      if (chipData.indexOf(modalText) > -1) {
        enqueueSnackbar('recordExists', { variant: 'warning' });
      } else {
        const newChips = [...chipData];
        newChips.push(modalText);
        notifyParent(newChips);
        setModalText('');
        setModalOpen(false);
      }
    }
  };

  const handleEnterKeyPressed = event => {
    if (event.keyCode === 13 && event.target.value) {
      addRecord();
    }
  };

  const setModalPosition = () => {
    setModelStyle({
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3px',
    });
  };

  const handleAddClick = () => {
    setModalPosition();
    setModalOpen(true);
  };

  const handleHintSelect = hint => {
    setModalText(hint);
    setAnchorEl(null);
  };

  if (!chipData) {
    return null;
  }

  return (
    <Form onSubmit={() => {}}>
      {() => (
        <>
          <Paper className={classes.root}>
            <p className={classes.labelWrapper}>{label}</p>
            <div className={classes.container}>
              <Box className={classes.flexWrapper}>
                {chipData.map(d => {
                  return (
                    <li key={d}>
                      <Chip
                        label={d}
                        onDelete={() => handleDelete(d)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </Box>
              <div style={{ marginRight: '0.625rem' }}>
                <ActionButton
                  classNameWrapper={classes.actionButton}
                  handler={handleAddClick}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add item"
                />
              </div>
            </div>
          </Paper>
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle}>
              {getHints().length === 0 || (
                <ActionButtonLight
                  handler={event => setAnchorEl(event.currentTarget)}
                  toolTip="Add value"
                  icon={<img src={iconPlus} alt="iconPlus" />}
                />
              )}

              <Field
                name="modalText"
                placeholder="Enter item"
                inputView="text"
                styleType="main"
                input={{
                  value: modalText,
                  onChange: e => setModalText(e.target.value),
                  onKeyDown: handleEnterKeyPressed,
                }}
                component={Input}
              />
              <ReusableButton
                classNameWrapper={classes.btnWrapper}
                onClick={addRecord}
                viewType="black"
              >
                <img src={ArrowIcon} alt="" />
              </ReusableButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {getHints().map(i => (
                  <MenuItem key={i} onClick={() => handleHintSelect(i)}>
                    {i}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Modal>
        </>
      )}
    </Form>
  );
};

ChipInputDialog.propTypes = {
  name: PropTypes.string.isRequired,
  chipData: PropTypes.arrayOf(PropTypes.string),
  setChipData: PropTypes.func.isRequired,
  hints: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
};

export default ChipInputDialog;
