import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { shortDateFormat } from '@constants/formats';
import getFormattedDate from '@utils/getFormattedDate';

import { ThunkGetChatMessages } from '@store/slices/liveChats/thunks';
import Loading from '@common/Loading/Loading';

import { Paper } from '@material-ui/core';
import useStyles from '../styles';

const UserMessage = ({ dateTime, message, technicianName, userName }) => {
  return (
    <div style={{ color: `${technicianName ? 'darkgreen' : 'blue'}` }}>
      <span>[</span>
      <span>{getFormattedDate(dateTime, shortDateFormat)}</span>
      <span>&nbsp;</span>
      <span>{technicianName ?? userName}</span>
      <span>]</span>
      <span>&nbsp;</span>
      <span>{message}</span>
    </div>
  );
};

const UserMessages = ({ chatId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetChatMessages({ id: chatId, userOnly: false }))
      .unwrap()
      .then(d => setMessages(d))
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <Paper className={classes.userMessagesContainer}>
      <div className={classes.userMessagesHeader}>
        Messaging with&nbsp;
        {messages.userName}
      </div>
      <div>
        {messages?.messages?.map(m => (
          <UserMessage
            key={m.id}
            id={m.id}
            dateTime={m.dateTime}
            message={m.message}
            technicianName={m.technicianName}
            userName={m.userName}
          />
        ))}
      </div>
    </Paper>
  );
};

UserMessages.propTypes = {
  chatId: PropTypes.number.isRequired,
};

export default UserMessages;
