import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationSyncSettings } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetCrmSyncSettings = createAsyncThunk(
  `${INTEGRATION}/getCrmSyncSettings`,
  async crmId => {
    const users = await getIntegrationSyncSettings(crmId);
    return users;
  },
);

export default {};
