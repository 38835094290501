import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkGetCrmCompaniesByDefaultId,
  ThunkGetCrmUsersByDefaultsId,
} from '@store/slices/crm/thunks';

import EditIcon from '@assets/icons/edit.svg';
import Loading from '@ui/components/common/Loading/Loading';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import Input from '@ui/components/Auth/Common/Input';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ReusableButton from '@ui/components/common/Button/Button';
import EditPhoneDialog from './EditPhoneDialog';
import AddContactDialog from './AddContactDialog';

import useStyles from '../styles';

const ContactsDialog = ({ open, setOpen, crmDefaultId }) => {
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [openEdiPhoneDialog, setOpenEditPhoneDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [openAddContactDialog, setOpenAddContactDialog] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const { crmUsers, crmCompanies } = useSelector(state => state.crmData);

  const handleEditPhoneClick = useCallback(
    id => {
      setOpenEditPhoneDialog(true);
      setSelectedUser(filteredUsers.find(i => i.id === id));
    },
    [filteredUsers],
  );

  const handleCompaniesDropdownChange = useCallback(
    async companyId => {
      setProcessing(true);
      if (companyId !== -1) {
        await dispatch(
          ThunkGetCrmUsersByDefaultsId({ crmDefaultId, companyId }),
        ).unwrap();
      } else {
        await dispatch(ThunkGetCrmUsersByDefaultsId({ crmDefaultId })).unwrap();
      }
      setSelectedCompany(companyId);
      setProcessing(false);
    },
    [crmDefaultId, dispatch],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      dispatch(ThunkGetCrmUsersByDefaultsId({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetCrmCompaniesByDefaultId({ crmDefaultId })).unwrap(),
    ]);
    setLoading(false);
  }, [dispatch, crmDefaultId]);

  useEffect(() => {
    if (name === '') {
      setFilteredUsers(crmUsers);
    } else {
      const filtered = crmUsers.filter(i =>
        i.name.toLowerCase().includes(name.toLowerCase()),
      );
      setFilteredUsers(filtered);
    }
  }, [crmUsers, name]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      style={{ margin: '0 auto', minWidth: '550px' }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div>Contacts</div>
          <div>
            {processing && (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            )}
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={
          loading
            ? {
                height: '50vh',
                width: '650px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }
            : { height: '50vh', width: '650px' }
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={classes.dialogHeader}>
              <div>
                Companies
                <AutocompleteFormInput
                  items={crmCompanies}
                  input={{
                    onChange: e => {
                      handleCompaniesDropdownChange(e);
                    },
                    value: selectedCompany,
                  }}
                />
              </div>
              <div>
                <ReusableButton
                  label="Add Contact"
                  onClick={() => {
                    setOpenAddContactDialog(true);
                  }}
                  size="md"
                />
              </div>
            </div>
            <TableContainer
              style={{ height: '80%', width: '650px' }}
              className={classes.dialogTable}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Input
                        input={{
                          onChange: e => {
                            setName(e.target.value);
                          },
                          value: name,
                        }}
                        placeholder="Name"
                        meta={{ touched: false }}
                      />
                    </TableCell>
                    <TableCell align="right">Company</TableCell>
                    <TableCell align="right">Phone</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.company}</TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell
                        align="right"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <ActionFieldItem
                          icon={EditIcon}
                          alt="edit"
                          toolTip="Edit Phone"
                          handler={() => {
                            handleEditPhoneClick(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {openEdiPhoneDialog && (
          <EditPhoneDialog
            open={openEdiPhoneDialog}
            setOpen={setOpenEditPhoneDialog}
            selectedUser={selectedUser}
            crmDefaultId={crmDefaultId}
            onClose={() => {
              setSelectedUser(undefined);
            }}
            onSuccess={user => {
              setFilteredUsers(
                filteredUsers.map(i =>
                  i.id === user.userId
                    ? {
                        id: user.userId,
                        name: user.userName,
                        company: user.userCompany,
                        phone: user.userPhone,
                      }
                    : i,
                ),
              );
            }}
          />
        )}
        {openAddContactDialog && (
          <AddContactDialog
            open={openAddContactDialog}
            setOpen={setOpenAddContactDialog}
            crmDefaultId={crmDefaultId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

ContactsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  crmDefaultId: PropTypes.number,
};

export default ContactsDialog;
