import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAddTechnicianNumber,
  ThunkGetPsaResources,
} from '@store/slices/messaging/thunks';
import { MessagingChannelSelector } from '@store/slices/messaging';
import phoneValidation from '@constants/phoneValidation';

import Input from '@ui/components/Auth/Common/Input';
import maxLengthValidator from '@utils/validators/maxLengthValidator';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';

import ReusableButton from '@ui/components/common/Button/Button';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import { OnChange } from 'react-final-form-listeners';
import useStyles from '../styles';

const AddTechnicianNumberDialog = ({ open, setOpen }) => {
  const [processing, setProcessing] = useState(false);
  const [psaResources, setPsaResources] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const channel = useSelector(MessagingChannelSelector);

  useEffect(() => {
    if (open) {
      setProcessing(true);
      dispatch(ThunkGetPsaResources({ channelId: channel.id }))
        .unwrap()
        .then(data => {
          const resources = data.map(item => ({
            value: item.id,
            label: item.name,
            phone: item.phone,
          }));
          setPsaResources(resources);
        })
        .finally(() => setProcessing(false));
    }
  }, [open, channel]);

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      ThunkAddTechnicianNumber({ channelId: channel.id, payload: values }),
    );
    setProcessing(false);
    setOpen(false);
  };

  const validate = values => {
    return {
      phoneNumber: phoneValidation(values.phoneNumber),
      name: maxLengthValidator(values.name, 30),
    };
  };

  const handlePsaResourceChange = (value, form) => {
    const item = psaResources.find(p => p.value === value);
    form.change('name', item?.label);
    form.change('phoneNumber', item?.phone);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Add Technician Number</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="PSA Resource"
                labelSize={12}
                contentSize={12}
                fullWidth
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="psaResourceId"
                    id="psaResourceId"
                    render={AutocompleteFormInput}
                    limitTags={1}
                    items={psaResources}
                    disabled={processing}
                  />
                }
              />

              <OnChange name="psaResourceId">
                {value => handlePsaResourceChange(value, form)}
              </OnChange>

              <FieldWrapper
                label="Technician name"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    id="name"
                    name="name"
                    fullWidth
                    variant="outlined"
                    component={Input}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Phone Number"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.alignLeft}
                infoText="Format should look like: +12345678900"
                content={
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    fullWidth
                    variant="outlined"
                    component={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={classes.contactDialogActions}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddTechnicianNumberDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddTechnicianNumberDialog;
