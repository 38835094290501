import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Refresh } from '@material-ui/icons';
import { Tooltip, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import {
  ThunkGetAIPricePlans,
  ThunkGetTenantAIUsage,
} from '@store/slices/helpdeskAI/thunks';

import PricePlan from '@ui/pages/billing/AIPricePlan/components/PricePlan';
import Loading from '@common/Loading/Loading';

import useStyles from './styles';

const AIPricePlan = () => {
  const [loading, setLoading] = useState(true);
  const [pricePlans, setPricePlans] = useState([]);
  const [usage, setUsage] = useState(0);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { helpdeskAiPricePlanId, helpdeskAiLimit } = useSelector(
    state => state.pricePlan.plan,
  );

  const handleRefreshUsage = () => {
    if (processing) return;

    setProcessing(true);
    dispatch(ThunkGetTenantAIUsage())
      .unwrap()
      .then(({ data }) => {
        setUsage(data);
      })
      .finally(() => {
        enqueueSnackbar('Usage updated successfully', {
          variant: 'success',
        });
        setProcessing(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);

    Promise.all([
      dispatch(ThunkGetAIPricePlans()).unwrap(),
      dispatch(ThunkGetTenantAIUsage()).unwrap(),
    ])
      .then(([pricingPlans, { data }]) => {
        setPricePlans(pricingPlans);
        setUsage(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <h1>AI Voice Assistant Plans</h1>
        <p>
          Empower your business with our AI VoiceAssistant Module. Choose from
          flexible plans designed to fit your specific requirements.
        </p>
      </div>

      {helpdeskAiPricePlanId && (
        <div className={classes.usage}>
          <div className={classes.usage_box}>
            <div className={classes.usage_text}>
              Usage
              <Tooltip title="Update usage info">
                <Refresh
                  onClick={handleRefreshUsage}
                  style={{
                    cursor: processing ? 'not-allowed' : 'pointer',
                    opacity: processing ? 0.5 : 1,
                  }}
                />
              </Tooltip>
            </div>
            <div>{`${usage}/${helpdeskAiLimit} (min)`}</div>
          </div>
          <LinearProgress
            variant="determinate"
            value={(usage / helpdeskAiLimit) * 100}
          />
        </div>
      )}

      <div className={classes.pricePlansList}>
        {pricePlans.map(plan => (
          <PricePlan
            key={plan.id}
            id={plan.id}
            name={plan.name}
            monthlyPrice={plan.price}
            amountOfMinutes={plan.amountOfMinutes}
            isSelected={helpdeskAiPricePlanId === plan.id}
            scopes={plan.scopes}
          />
        ))}
      </div>
    </div>
  );
};

export default AIPricePlan;
