import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    padding: '20px',
    background: 'white',
    borderRadius: '15px',
  },
  buttonMargin: {
    margin: '0 0.3rem',
  },
  formGrid: {
    width: '60rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
