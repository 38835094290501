import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import getFormattedDate from '@utils/getFormattedDate';

import { ConnectWiseTicketScheduleEntriesSelector } from '@store/slices/connectWiseTicket';
import {
  ThunkDeleteConnectWiseTicketScheduleEntry,
  ThunkGetConnectWiseTicketScheduleEntries,
} from '@store/slices/connectWiseTicket/thunks';

import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import { ReactComponent as AcceptIcon } from '@assets/icons/accept.svg';
import SimpleTable from '@ui/components/Triggers/pages/Incident/components/SimpleTable';
import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import AddScheduleEntryDialog from '../dialogs/AddScheduleEntry';

import useSharedStyles from '../sharedStyles';

const ScheduleEntries = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [openAddScheduleEntryDialog, setOpenAddScheduleEntryDialog] = useState(
    false,
  );
  const [
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
  ] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();

  const handleDeleteItem = async () => {
    setProcessing(true);
    await dispatch(
      ThunkDeleteConnectWiseTicketScheduleEntry({
        scheduleEntryId: selectedItem.id,
        crmId: match.params.crmDefaultId,
      }),
    );
    setSelectedItem(undefined);
    setProcessing(false);
    setOpenDeleteConfirmationDialog(false);
  };

  const columns = [
    {
      label: 'Member',
      value: 'member',
    },
    {
      label: 'Where',
      value: 'where',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Start Time',
      handler: item => <>{getFormattedDate(item.timeStart)}</>,
    },
    {
      label: 'End Time',
      handler: item => <>{getFormattedDate(item.timeEnd)}</>,
    },
    {
      label: 'Hours',
      value: 'hours',
    },
    {
      label: 'Done',
      handler: item => <>{item.done && <AcceptIcon />}</>,
    },
    {
      label: 'Acknowledged',
      handler: item => <>{item.acknowledged && <AcceptIcon />}</>,
    },
    {
      label: 'Action',
      handler: item => (
        <div>
          <ActionFieldItem
            icon={EditIcon}
            alt="Edit"
            handler={() => {
              setSelectedItem(item);
              setOpenAddScheduleEntryDialog(true);
            }}
            toolTip="Edit"
          />

          <ActionFieldItem
            icon={DeleteIcon}
            alt="Delete"
            handler={() => {
              setSelectedItem(item);
              setOpenDeleteConfirmationDialog(true);
            }}
            toolTip="Delete"
          />
        </div>
      ),
    },
  ];

  const onMount = useCallback(async () => {
    if (match.params.crmDefaultId && match.params.ticketId) {
      setLoading(true);
      await dispatch(
        ThunkGetConnectWiseTicketScheduleEntries({
          crmId: match.params.crmDefaultId,
          ticketId: match.params.ticketId,
        }),
      );
      setLoading(false);
    }
  }, [dispatch, match.params.crmDefaultId, match.params.ticketId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={sharedStyles.container}>
      <div className={sharedStyles.containerActions}>
        <ReusableButton
          label="Add Schedule Entry"
          onClick={() => {
            setSelectedItem(undefined);
            setOpenAddScheduleEntryDialog(true);
          }}
        />
      </div>
      <SimpleTable
        selector={ConnectWiseTicketScheduleEntriesSelector}
        columns={columns}
      />

      {openAddScheduleEntryDialog && (
        <AddScheduleEntryDialog
          open={openAddScheduleEntryDialog}
          setOpen={setOpenAddScheduleEntryDialog}
          crmDefaultId={match.params.crmDefaultId}
          ticketId={match.params.ticketId}
          scheduleEntryId={selectedItem?.id}
          setSelectedItem={setSelectedItem}
        />
      )}

      {openDeleteConfirmationDialog && (
        <ConfirmDialog
          open={openDeleteConfirmationDialog}
          setOpen={setOpenDeleteConfirmationDialog}
          onCancel={() => {
            setOpenDeleteConfirmationDialog(false);
            setSelectedItem(undefined);
          }}
          title="Confirm action"
          content="Are you sure you want to delete this item?"
          onAccept={() => {
            handleDeleteItem();
          }}
          loading={processing}
        />
      )}
    </div>
  );
};

ScheduleEntries.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      crmDefaultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default ScheduleEntries;
