import crmSources from '@constants/crmSources';
import { validateRequired } from '@common/functions/validators';

export const validateFormData = (values, selectedCrmType) => {
  const validationResult = {
    name: validateRequired(values.name),
    defaultCompany: validateRequired(values.defaultCompany),
  };

  if (selectedCrmType === crmSources.SyncroMsp.name) {
    validationResult.typeId = validateRequired(values.typeId);
  }

  if (selectedCrmType !== crmSources.SyncroMsp.name) {
    validationResult.ticketPriority = validateRequired(values.ticketPriority);
    validationResult.defaultBoardId = validateRequired(values.defaultBoardId);
  }
  if (selectedCrmType === crmSources.Autotask.name) {
    validationResult.ticketCategoryId = validateRequired(
      values.ticketCategoryId,
    );

    validationResult.defaultBoardId =
      values.defaultBoardId || values?.crmSpecificSettings?.assignedResourceId
        ? undefined
        : 'Please specify a value for the Queue field and/or the Primary Resource field.';
    validationResult.crmSpecificSettings = {
      assignedResourceId:
        values.defaultBoardId || values?.crmSpecificSettings?.assignedResourceId
          ? undefined
          : 'Please specify a value for the Queue field and/or the Primary Resource field.',
    };
  }
  return validationResult;
};

export const getPayload = (data, values, state, boardsOptions) => {
  const type = state.boardTypesOptions.find(
    i => i.boardId === values.defaultBoardId && i.value === values.typeId,
  );
  const subType = type?.subTypes.find(i => i.value === values.subTypeId);

  return {
    id: data ? data.id : 0,
    name: values.name,
    itemId: subType?.items.find(i => i.value === values.itemId)
      ? values.itemId
      : null,
    defaultCompany: values.defaultCompany,
    ticketPriority: values.ticketPriority,
    defaultBoardId: values.defaultBoardId,
    defaultBoard:
      boardsOptions.find(i => i.id === values.defaultBoardId)?.name ||
      data?.defaultBoard,
    sourceId: values.sourceId,
    typeId: type ? values.typeId : null,
    subTypeId: subType ? values?.subTypeId : null,
    openBoardStatusId: state.statusesOptions.some(
      i => i.value === values.openBoardStatusId,
    )
      ? values.openBoardStatusId
      : null,
    acknowledgedBoardStatusId: state.statusesOptions.some(
      i => i.value === values.acknowledgedBoardStatusId,
    )
      ? values.acknowledgedBoardStatusId
      : null,
    smsReceivedBoardStatusId: state.statusesOptions.some(
      i => i.value === values.smsReceivedBoardStatusId,
    )
      ? values.smsReceivedBoardStatusId
      : null,
    closedBoardStatusId: state.statusesOptions.some(
      i => i.value === values.closedBoardStatusId,
    )
      ? values.closedBoardStatusId
      : null,
    ignoreStatusIds: state.ignoredStatuses.map(i => i.value),
    ticketCategoryId: values.ticketCategoryId,
    crmSpecificSettings: values.crmSpecificSettings,
  };
};

export const getInitFormData = data => {
  return {
    name: data ? data.name : null,
    defaultCompany: data ? data.defaultCompany : null,
    defaultBoard: data ? data.defaultBoard : null,
    ticketPriority: data ? data.ticketPriority : null,
    defaultBoardId: data ? data.defaultBoardId : 0,
    sourceId: data ? data.sourceId : 0,
    typeId: data ? data.typeId : 0,
    subTypeId: data ? data.subTypeId : 0,
    itemId: data ? data.itemId : 0,
    openBoardStatusId: data ? data.openBoardStatusId : 0,
    acknowledgedBoardStatusId: data ? data.acknowledgedBoardStatusId : 0,
    smsReceivedBoardStatusId: data ? data.smsReceivedBoardStatusId : 0,
    closedBoardStatusId: data ? data.closedBoardStatusId : 0,
    ticketCategoryId: data ? data.ticketCategoryId : 0,
    crmSpecificSettings: data ? data.crmSpecificSettings : {},
    ticketTimeZone: data ? data.ticketTimeZone : null,
  };
};
