import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import AttachmentBadge from '../Parsers/components/Create/components/DisplayEmail/attachments/AttachmentBadge';

const Attachments = ({ attachments }) => {
  const classes = useStyles();

  if (!attachments.length) {
    return <span>-</span>;
  }

  return (
    <div className={classes.attachmentContainer}>
      {attachments.map(attachment => (
        <AttachmentBadge key={attachment.ContentID} item={attachment} />
      ))}
    </div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.any),
};

export default Attachments;
