import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingConfigurationsAutocomplete } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingConfigurationsAutocomplete = createAsyncThunk(
  `${MESSAGING}/getMessagingConfigurationsAutocomplete`,
  async () => {
    const res = await getMessagingConfigurationsAutocomplete();
    return res;
  },
);

export default {};
