import { getChatBotOptions } from '@services/requests/chatBots';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CHATS_BOTS } from '../../resources';

export const ThunkGetChatBotOptions = createAsyncThunk(
  `${CHATS_BOTS}/getChatBotOptions`,
  () => getChatBotOptions(),
);

export default {};
