import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationBoardTypes } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationBoardTypes = createAsyncThunk(
  `${INTEGRATION}/getIntegrationBoardTypes`,
  async ({ id, boardId }) => {
    const boardTypes = await getIntegrationBoardTypes(id, boardId);
    return boardTypes;
  },
);

export default {};
