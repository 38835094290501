import composeValidators from './composeValidators';

const minPasswordLength = 8;
// const requiredCharCombination = /^(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+)(?=.*[\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\xFE]+)[a-zA-Z\d\x20-\x2F\x3A-\x40\x5B-\x60\x7B-\xFE\S]+$/;

const passwordLength = value => {
  if (value.length < minPasswordLength) {
    return 'validation.password.length';
  }

  return '';
};

const passwordRequired = value => {
  if (!value) {
    return 'validation.password.required';
  }

  return '';
};

// const passwordCharCombination = value => {
//   if (!requiredCharCombination.test(value)) {
//     return 'validation.password.requiredCharCombination';
//   }
//
//   return '';
// };

const passwordValidator = value =>
  composeValidators(
    passwordRequired,
    passwordLength,
    // passwordCharCombination,
  )(value);

export const confirmPasswordValidator = (
  value,
  allValues,
  key = 'password',
) => {
  if (value !== allValues?.[key]) {
    return 'validation.password.unmatchedPasswords';
  }

  return '';
};

export default passwordValidator;
