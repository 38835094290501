import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    fontStyle: 'normal',
    width: '6rem',
    height: '8rem',
    border: '1px solid #bdbdbd',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem',
    '&:hover': {
      transform: props => (props.deny ? 'none' : 'scale(1.05)'),
      cursor: props => (props.deny ? 'auto' : 'pointer'),
      background: props => (props.deny ? 'none' : '#efefef'),
      boxShadow: props =>
        props.deny ? 'none' : '4px 4px 8px 0px rgba(34, 60, 80, 0.2)',
    },
    filter: props => (props.gray ? 'grayscale(1)' : 'none'),
  },
  settingsButton: {
    textAlign: 'end',
    width: '90%',
  },
  icon: {
    maxWidth: '3.5rem',
    width: '100%',
  },
}));

export default useStyles;
