import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteLiveChatConversationTemplate } from '@services/requests/messaging';
import { LIVE_CHAT_CONVERSATIONS } from '@store/slices/resources';

export const ThunkDeleteLiveChatConversationTemplate = createAsyncThunk(
  `${LIVE_CHAT_CONVERSATIONS}/deleteLiveChatConversation`,
  async id => {
    await deleteLiveChatConversationTemplate(id);
    return id;
  },
);

export default {};
