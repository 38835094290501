import { getHeaders } from '@utils/transfered';

const putWithResponse = async (url, data) => {
  const request = new Request(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    window.location.href = '#/login';
    return;
  }
  if (response.status < 200 || response.status >= 300) {
    const errorData = await response.json();
    const message = errorData || response.statusText;
    throw message;
  }

  return await response.json();
};

export default putWithResponse;
