import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { OnChange } from 'react-final-form-listeners';

import {
  ThunkGetAutotaskTicketCompanies,
  ThunkGetAutotaskTicketContacts,
  ThunkGetAutotaskTicketOptions,
  ThunkGetAutotaskTicketResources,
  ThunkGetTicketInfo,
  ThunkUpdateAutotaskTicket,
} from '@store/slices/crmTicket/thunks';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import DropDown from '@ui/components/Auth/Common/DropDown';
import Loading from '@ui/components/common/Loading/Loading';

import TicketNotes from '@components/CrmTicket/Notes/TicketNotes';
import crmSources from '@constants/crmSources';
import { isRequiredValidation } from '@components/Helpers/valudationHelpers';

import useStyles from '../styles';

const autotaskOptions = [
  'ticketType',
  'issueType',
  'queueID',
  'priority',
  'source',
  'status',
  'ticketCategory',
];

const EditAutotaskInfoForm = ({
  crmDefaultId,
  ticketId,
  handleBackRedirect,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [ticketInfo, setTicketInfo] = useState(undefined);
  const [companies, setCompanies] = useState([]);
  const [options, setOptions] = useState({
    issueType: [],
    status: [],
    priority: [],
    queueID: [],
    source: [],
    ticketCategory: [],
    ticketType: [],
  });
  const [resources, setResources] = useState([]);
  const [contacts, setContacts] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCompanyChange = async value => {
    setProcessing(true);
    await dispatch(
      ThunkGetAutotaskTicketContacts({ crmId: crmDefaultId, companyId: value }),
    )
      .unwrap()
      .then(res => {
        setContacts(res);
      });
    setProcessing(false);
  };

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      ThunkUpdateAutotaskTicket({
        crmId: crmDefaultId,
        payload: values,
      }),
    )
      .unwrap()
      .then(() => {
        handleBackRedirect();
      });

    setProcessing(false);
  };

  const onMount = useCallback(async () => {
    setLoading(true);

    const ticketRequestInfo = await dispatch(
      ThunkGetTicketInfo({
        crmId: crmDefaultId,
        ticketId,
      }),
    ).unwrap();

    setTicketInfo(ticketRequestInfo);

    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetAutotaskTicketCompanies(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetAutotaskTicketOptions({
              crmId: crmDefaultId,
              options: autotaskOptions,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetAutotaskTicketResources({ crmId: crmDefaultId }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetAutotaskTicketContacts({
              crmId: crmDefaultId,
              companyId: ticketRequestInfo?.companyId,
            }),
          ).unwrap(),
        ),
      ),
    ]).then(res => {
      setCompanies(res[0]);
      setOptions(res[1]);
      setResources(res[2]);
      setContacts(res[3]);
    });

    setLoading(false);
  }, [dispatch, crmDefaultId, ticketId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const validate = values => ({
    companyId: isRequiredValidation(values.companyId),
    queueId:
      values.queueId || values.assignedResource
        ? undefined
        : 'Please specify a value for the Queue field and/or the Primary Resource field.',
    assignedResource:
      values.queueId || values.assignedResource
        ? undefined
        : 'Please specify a value for the Queue field and/or the Primary Resource field.',
  });

  if (loading) return <Loading />;
  return (
    <div className={classes.withNotesContainer}>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={ticketInfo}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h5">Edit Ticket Information</Typography>
            <div className={classes.twoColumns}>
              <FieldWrapper
                label="Company"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                isRequired
                content={
                  <Field
                    id="companyId"
                    name="companyId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={companies}
                    loading={processing}
                  />
                }
              />

              <FieldWrapper
                label="Contact"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="contact"
                    name="contact"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    loading={processing || !values.companyId}
                    items={contacts}
                  />
                }
              />

              <FieldWrapper
                label="Queue"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="queueId"
                    name="queueId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    loading={processing}
                    items={options.queueID}
                  />
                }
              />

              <FieldWrapper
                label="Primary Resource"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="assignedResource"
                    name="assignedResource"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    loading={processing}
                    items={resources}
                  />
                }
              />

              <FieldWrapper
                label="Issue Type"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="issueType"
                    name="issueType"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    loading={processing}
                    items={options.issueType}
                  />
                }
              />

              <FieldWrapper
                label="Priority"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="priority"
                    name="priority"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    loading={processing}
                    items={options.priority}
                  />
                }
              />

              <OnChange name="companyId">
                {value => {
                  handleCompanyChange(value);
                }}
              </OnChange>

              <FieldWrapper
                label="Status"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="status"
                    name="status"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={options.status}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Source"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="source"
                    name="source"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={options.source}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Ticket Category"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="ticketCategory"
                    name="ticketCategory"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={options.ticketCategory}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Ticket Type"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="ticketType"
                    name="ticketType"
                    fullWidth
                    variant="outlined"
                    component={DropDown}
                    options={options.ticketType}
                    disabled={processing}
                  />
                }
              />
            </div>

            <FieldWrapper
              label="Title"
              labelSize={12}
              contentSize={12}
              classNameLabelInner={classes.deleteDialogFieldLabelInner}
              classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
              content={
                <Field
                  id="title"
                  name="title"
                  fullWidth
                  variant="outlined"
                  component={Input}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Description"
              labelSize={12}
              contentSize={12}
              classNameLabelInner={classes.deleteDialogFieldLabelInner}
              classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
              content={
                <Field
                  id="description"
                  name="description"
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={3}
                  maxRows={5}
                  component={Input}
                  disabled={processing}
                />
              }
            />

            <div className={classes.deleteDialogActions}>
              <ReusableButton
                label="Cancel"
                onClick={() => {
                  handleBackRedirect();
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
                loading={processing}
              />
            </div>
          </form>
        )}
      />
      <TicketNotes psaType={crmSources.Autotask.name} />
    </div>
  );
};

EditAutotaskInfoForm.propTypes = {
  crmDefaultId: PropTypes.string.isRequired,
  ticketId: PropTypes.string.isRequired,
  handleBackRedirect: PropTypes.func.isRequired,
};

export default EditAutotaskInfoForm;
