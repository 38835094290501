import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';

import {
  ThunkGetMessagingConfiguration,
  ThunkGetMessagingChannel,
} from '@store/slices/messaging/thunks';

import ChannelDetails from './components/ChannelDetails';
import History from './components/History';
import Loading from '../../common/Loading/Loading';
import CrmSpecificInfo from './components/CrmSpecificInfo';

import useStyles from './styles';

const MessagingChannel = React.memo(({ match }) => {
  const [loading, setLoading] = useState(true);
  const [hideInfo, setHideInfo] = useState(false);

  const classes = useStyles({ hideInfo });
  const dispatch = useDispatch();

  const handleInfoHide = useCallback((state = true) => {
    setHideInfo(state);
  }, []);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(
      ThunkGetMessagingConfiguration(match.params.configurationId),
    );
    await dispatch(ThunkGetMessagingChannel(match.params.channelId));

    setLoading(false);
    // Remove warning due to multiple API call IF match is on dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.wrapper}>
      <Grid container>
        <Grid md={6} xs={12} item>
          {!hideInfo && (
            // className={classes.column}
            <div>
              <ChannelDetails />
              <CrmSpecificInfo
                channelId={match.params.channelId}
                configurationId={match.params.configurationId}
              />
            </div>
          )}
        </Grid>
        {/* className={classes.secondColumn} */}
        <Grid item md={!hideInfo ? 6 : 12} xs={12}>
          <History
            channelId={match.params.channelId}
            handleInfoHide={handleInfoHide}
            hideInfo={hideInfo}
          />
        </Grid>
      </Grid>
    </div>
  );
});

MessagingChannel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      channelId: PropTypes.string,
      configurationId: PropTypes.string,
    }),
  }),
};

export default MessagingChannel;
