import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  menuItem: {
    background: 'transparent !important',
    '&:focus': {
      background: 'transparent',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}));

export default useStyles;
