import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    padding: '20px',
    background: 'white',
    borderRadius: '15px',
    height: '83vh',
    overflow: 'auto',
  },
  dialogActions: {
    margin: '10px 5px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
      marginLeft: '5px',
    },
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      margin: '0px 5px',
    },
  },
  alignLeft: {
    textAlign: 'start',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dialogContainer: {
    minWidth: '350px',
    '& > div': {
      height: '100%',
      minWidth: '100%',
    },
  },
  logsDialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  configurationFormGrid: {
    width: '60rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    '& .MuiGrid-container': {
      marginBottom: '16px',
    },
  },
  icon: {
    height: '12px',
    width: '19px',
  },
  initialMessage: {
    '& fieldset legend': {
      maxWidth: '0px',
    },
  },
  noOptionText: {
    marginBottom: '16px',
  },
  phoneNumberField: {
    marginBottom: '0 !important',
  },
}));

export default useStyles;
