import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  ThunkEditAutotaskTicketTimeEntry,
  ThunkGetAutotaskTicketResources,
} from '@store/slices/crmTicket/thunks';
import { ThunkAddATTicketTimeEntry } from '@store/slices/crmTicket/thunks/addATTicketTimeEntry';
import { currentUserDataSelector } from '@store/selectors';
import { requiredValidator } from '@utils/validators';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import Loading from '@ui/components/common/Loading/Loading';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../sharedStyles';

const AddATTimeEntryDialog = ({
  open,
  setOpen,
  ticketId,
  defaultId,
  selectedItem,
  setSelectedItem = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [resources, setResources] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setSelectedItem(undefined);
    setOpen(false);
  };

  const { autotaskLinkedUser } = useSelector(currentUserDataSelector);

  const initialFormData = {
    startDateTime: moment().format('YYYY-MM-DDTHH:mm'),
    endDateTime: moment()
      .add(30, 'm')
      .format('YYYY-MM-DDTHH:mm'),
    resourceId: autotaskLinkedUser?.userId,
  };

  const submit = async values => {
    setProcessing(true);
    const payload = {
      ...values,
      ticketId,
      startDateTime: moment(values.startDateTime)
        .utc()
        .toISOString(),
      endDateTime: moment(values.endDateTime)
        .utc()
        .toISOString(),
      resource: resources.find(item => item.value === values.resourceId).label,
    };

    if (selectedItem) {
      // * EDIT
      await dispatch(
        ThunkEditAutotaskTicketTimeEntry({
          crmId: defaultId,
          payload,
          timeEntryId: selectedItem.id,
        }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar('Successfully edited', { variant: 'success' });
          handleClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      // * ADD
      await dispatch(
        ThunkAddATTicketTimeEntry({ crmId: defaultId, ticketId, payload }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar('Successfully added', { variant: 'success' });
          handleClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const validation = values => {
    return {
      resourceId: requiredValidator(values.resourceId),
      startDateTime: requiredValidator(values.startDateTime),
      endDateTime: requiredValidator(values.endDateTime),
      summaryNotes: requiredValidator(values.summaryNotes),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetAutotaskTicketResources({ crmId: defaultId }))
      .unwrap()
      .then(res => {
        setResources(res);
      });
    setLoading(false);
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {selectedItem ? 'Edit ' : 'Add '}
        Time Entry
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            initialValues={
              selectedItem
                ? {
                    resourceId: selectedItem.resourceId,
                    summaryNotes: selectedItem.summaryNotes,
                    startDateTime: moment(selectedItem.startDateTime).format(
                      'YYYY-MM-DDTHH:mm',
                    ),
                    endDateTime: moment(selectedItem.endDateTime).format(
                      'YYYY-MM-DDTHH:mm',
                    ),
                  }
                : initialFormData
            }
            validate={validation}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Resources"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="resourceId"
                      id="resourceId"
                      render={AutocompleteFormInput}
                      disabled={processing}
                      items={resources}
                    />
                  }
                />

                <FieldWrapper
                  label="Start Time"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="startDateTime"
                      id="startDateTime"
                      render={Input}
                      disabled={processing}
                      type="datetime-local"
                    />
                  }
                />

                <FieldWrapper
                  label="End Time"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="endDateTime"
                      id="endDateTime"
                      render={Input}
                      disabled={processing}
                      type="datetime-local"
                    />
                  }
                />

                <FieldWrapper
                  label="Summary Notes"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="summaryNotes"
                      id="summaryNotes"
                      render={Input}
                      disabled={processing}
                      multiline
                      rows={3}
                    />
                  }
                />

                <div className={classes.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={handleClose}
                    disabled={processing}
                  />

                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AddATTimeEntryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  ticketId: PropTypes.string,
  defaultId: PropTypes.string,
  selectedItem: PropTypes.shape({
    resourceId: PropTypes.number,
    summaryNotes: PropTypes.string,
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
    id: PropTypes.number,
  }),
  setSelectedItem: PropTypes.func,
};

export default AddATTimeEntryDialog;
