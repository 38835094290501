import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloClientsByCrmId } from '@services/requests/haloTickets';

export const ThunkGetHaloClientsCrmId = createAsyncThunk(
  `${HALO_TICKET}/getHaloClients`,
  async crmId => {
    const clients = await getHaloClientsByCrmId(crmId);
    return clients;
  },
);

export default {};
