/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { EXTRA_RESOURCES } from '../resources';
import {
  ThunkGetAvailableExtraResources,
  ThunkGetMyPlan,
  ThunkGetPricePlans,
  ThunkGetTenantTechCount,
  ThunkGetBillingEmail,
  ThunkGetLastInvoices,
  ThunkGetBaseTechCount,
  ThunkCheckSubscriptionDiscount,
  ThunkUpdateBillingEmail,
  ThunkGetBillingAddress,
  ThunkUpdateBillingAddress,
} from './thunks';

const extraResourcesSlice = createSlice({
  name: EXTRA_RESOURCES,
  initialState: {
    extraOptions: [],
    billingData: {},
    pricePlans: [],
    techCount: undefined,
    billingEmail: '',
    invoices: [],
    minTechCount: undefined,
    discountData: 'discountData',
    billingAddress: undefined,
  },
  reducers: {
    updateDiscountCode: (state, action) => {
      state.discountData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      ThunkGetAvailableExtraResources.fulfilled,
      (state, action) => {
        state.extraOptions = action.payload;
      },
    );

    builder.addCase(ThunkGetMyPlan.fulfilled, (state, action) => {
      state.billingData = action.payload;
    });

    builder.addCase(ThunkGetPricePlans.fulfilled, (state, action) => {
      state.pricePlans = action.payload;
    });

    builder.addCase(ThunkGetTenantTechCount.fulfilled, (state, action) => {
      state.techCount = action.payload.count;
    });

    builder.addCase(ThunkGetBillingEmail.fulfilled, (state, action) => {
      state.billingEmail = action.payload.email;
    });

    builder.addCase(ThunkGetLastInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload;
    });

    builder.addCase(ThunkGetBaseTechCount.fulfilled, (state, action) => {
      state.minTechCount = action.payload.count;
    });

    builder.addCase(ThunkUpdateBillingEmail.fulfilled, (state, action) => {
      state.billingEmail = action.payload.email;
    });

    builder.addCase(ThunkGetBillingAddress.fulfilled, (state, action) => {
      state.billingAddress = action.payload;
    });

    builder.addCase(ThunkUpdateBillingAddress.fulfilled, (state, action) => {
      state.billingAddress = action.payload;
    });

    builder.addCase(
      ThunkCheckSubscriptionDiscount.fulfilled,
      (state, action) => {
        if (action.payload.data) {
          state.discountData = action.payload;
        }
      },
    );
  },
});

export default extraResourcesSlice.reducer;
export const { updateDiscountCode } = extraResourcesSlice.actions;

export const ExtraResourcesSelector = state =>
  state[EXTRA_RESOURCES].extraOptions;

export const BillingDataSelector = state => state[EXTRA_RESOURCES].billingData;
export const PricePlansSelector = state => state[EXTRA_RESOURCES].pricePlans;
export const TechCountSelector = state => state[EXTRA_RESOURCES].techCount;
export const BillingEmailSelector = state =>
  state[EXTRA_RESOURCES].billingEmail;

export const LastInvoicesSelector = state => state[EXTRA_RESOURCES].invoices;
export const MinTechCountSelectorSelector = state =>
  state[EXTRA_RESOURCES].minTechCount;
export const DiscountDataSelector = state =>
  state[EXTRA_RESOURCES].discountData;
export const BillingAddressSelector = state =>
  state[EXTRA_RESOURCES].billingAddress;
