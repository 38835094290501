const baseColors = [
  {
    title: '#7D7D7D',
    gradient: '#513630',
    priceBlock: '#B1B1B1',
    fontColor: '#FFFFFF',
  },
  {
    title: '#FF8A71',
    gradient: '#FF4C25',
    priceBlock: '#FF9A84',
    fontColor: '#FFFFFF',
  },
  {
    title: '#E26C17',
    gradient: '#CD861B',
    priceBlock: '#FFAF66',
    fontColor: '#FFFFFF',
  },
  {
    title: '#FFF171',
    gradient: '#FFA825',
    priceBlock: '#FFE071',
    fontColor: '#FFFFFF',
  },
  {
    title: '#E0AC8E80',
    gradient: '#B26B0080',
    priceBlock: '#EBC39080',
    fontColor: '#78530F',
  },
  {
    title: '#E0AC8E99',
    gradient: '#B26B0099',
    priceBlock: '#EBC39099',
    fontColor: '#78530F',
  },
  {
    title: '#E0AC8EB3',
    gradient: '#B26B00B3',
    priceBlock: '#EBC390B3',
    fontColor: '#78530F',
  },
  {
    title: '#E0AC8ECC',
    gradient: '#B26B00CC',
    priceBlock: '#EBC390CC',
    fontColor: '#78530F',
  },
  {
    title: '#E0AC8EE6',
    gradient: '#B26B00E6',
    priceBlock: '#EBC390E6',
    fontColor: '#78530F',
  },
];

export default baseColors;
