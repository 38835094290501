import React from 'react';
import { TextField } from '@material-ui/core';

import useStyles from './inputStyles';

const NumberInput = ({
  meta: { touched, error },
  input: inputProps,
  min,
  max,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      type="number"
      InputProps={{
        inputProps: {
          max,
          min,
        },
      }}
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
      variant="outlined"
      size="small"
      classes={classes}
    />
  );
};
export default NumberInput;
