import React, { useState } from 'react';
import PropTypes from 'prop-types';
import iconView from '@assets/icons/visibility_blue.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import DetailsDialog from './DetailsDialog';

const EndpointDetailsAccessor = ({ record = {} }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionButton
        icon={<img src={iconView} alt="Details" />}
        handler={() => setOpen(true)}
        toolTip="Details"
      />
      <DetailsDialog open={open} onClose={() => setOpen(false)} item={record} />
    </>
  );
};

EndpointDetailsAccessor.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

export default EndpointDetailsAccessor;
