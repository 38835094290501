import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '83vh',
    margin: '20px',
    borderRadius: '15px',
    padding: '20px',
    background: 'white',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  tableActionContainer: {
    width: '25%',
    margin: '5px 26px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '10px 0px',
  },
  dialogLabelText: {
    width: '100%',
    textAlign: 'start',
  },
  dialogLabelContainer: {
    width: '100%',
  },
  dialogActions: {
    margin: '25px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '10px',
    },
  },
  dialogContainer: {
    minHeight: '500px',
    '& > div': {
      height: '100%',
      minWidth: '100%',
      minHeight: '500px',
    },
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  },
  form: {
    width: '60%',
    margin: '20px auto',
    height: '87vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: '10px',
  },
  twoColumnsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
}));

export default useStyles;
