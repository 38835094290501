import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch } from 'react-redux';

import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';

import {
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkGetIntegrationPriorities,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
  ThunkGetIntegrationTicketCategories,
} from '@store/slices/integration/thunks';

import useStyles from '@components/CreateTicket/styles';

const AutotaskClientPortalForm = ({ values, form, crmId: psaId, item }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  const [serviceBoards, setServiceBoards] = useState([]);
  const [sources, setSources] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [ticketCategories, setTicketCategories] = useState([]);

  // Board related options
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardSubTypes, setSubTypes] = useState({});

  const handleBoardIdChange = useCallback(
    async (value, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change('default.IssueTypeId', '');
          form.change('default.SubIssueTypeId', '');
          form.change('default.OpenStatusTicketId', '');
          form.change('default.ClosedTicketStatusId', '');
        });
      }

      setProcessing(true);
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(res => {
        setBoardStatuses(
          res[0].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
        setBoardTypes(res[1]);
        setSubTypes(
          res[1].reduce((acc, cur) => {
            acc[cur.id] = cur.boardSubTypes;
            return acc;
          }, {}),
        );
      });

      setProcessing(false);
    },
    [dispatch, psaId],
  );

  const onMount = useCallback(async () => {
    setProcessing(true);
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationSources({
              id: psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationServiceBoards({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationPriorities({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetIntegrationTicketCategories({ id: psaId })).unwrap(),
        ),
      ),
    ])
      .then(res => {
        setSources(res[0]);
        setServiceBoards(res[1]);
        setPriorities(res[2]);
        setTicketCategories(res[3]);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (item) {
      handleBoardIdChange(item.default.QueueId, true);
    }
  }, []);

  return (
    <>
      <FieldWrapper
        label="Queue"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.QueueId"
            name="default.QueueId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={serviceBoards}
            disabled={processing}
          />
        }
      />

      <OnChange name="default.QueueId">
        {value => {
          handleBoardIdChange(value, form);
        }}
      </OnChange>

      <FieldWrapper
        label="Ticket Category"
        labelSize={12}
        contentSize={12}
        isRequired
        classNameLabelContainer={classes.removePadding}
        content={
          <Field
            id="default.CategoryId"
            name="default.CategoryId"
            size="small"
            label="Ticket Category"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={ticketCategories}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Issue Type"
        labelSize={12}
        contentSize={12}
        content={
          <Field
            id="default.IssueTypeId"
            name="default.IssueTypeId"
            size="small"
            label="Ticket Type"
            suppressInlineLabel
            component={DropDown}
            labelName="name"
            valueName="id"
            allowEmpty
            options={boardTypes}
            disabled={processing || !values?.default?.QueueId}
          />
        }
      />
      <FieldWrapper
        label="Issue Sub Type"
        labelSize={12}
        contentSize={12}
        content={
          <Field
            id="default.SubIssueTypeId"
            name="default.SubIssueTypeId"
            size="small"
            label="Ticket SubType"
            labelName="name"
            valueName="id"
            suppressInlineLabel
            component={DropDown}
            allowEmpty
            options={boardSubTypes[values?.default?.IssueTypeId] || []}
            disabled={processing || !values?.default?.IssueTypeId}
          />
        }
      />
      <FieldWrapper
        label="Open Ticket Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.OpenStatusTicketId"
            name="default.OpenStatusTicketId"
            size="small"
            label="New Ticket Status"
            suppressInlineLabel
            component={DropDown}
            options={boardStatuses}
            disabled={processing || !values?.default?.QueueId}
          />
        }
      />

      <FieldWrapper
        label="Closed Ticket Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.ClosedTicketStatusId"
            name="default.ClosedTicketStatusId"
            size="small"
            label="New Ticket Status"
            suppressInlineLabel
            component={DropDown}
            options={boardStatuses}
            disabled={processing || !values?.default?.QueueId}
          />
        }
      />

      <FieldWrapper
        label="Ticket Message Received Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketOnMessageStatusId"
            name="default.TicketOnMessageStatusId"
            size="small"
            label="New Message Ticket Status"
            suppressInlineLabel
            component={DropDown}
            options={boardStatuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Priority"
        labelSize={12}
        contentSize={12}
        isRequired
        classNameLabelContainer={classes.removePadding}
        content={
          <Field
            id="default.PriorityId"
            name="default.PriorityId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={priorities}
            disabled={processing}
          />
        }
      />
      <FieldWrapper
        label="Ticket Source"
        labelSize={12}
        contentSize={12}
        isRequired
        classNameLabelContainer={classes.removePadding}
        content={
          <Field
            id="default.SourceId"
            name="default.SourceId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={sources}
            disabled={processing}
          />
        }
      />
    </>
  );
};

export default AutotaskClientPortalForm;
