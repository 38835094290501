import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  layoutWrapper: {
    '& > div': {
      flex: 1,
      marginTop: theme.mixins.toolbar.minHeight,
      overflow: 'hidden',
    },
    '& .RaSidebar-paper-26': {
      boxSizing: 'border-box',
    },
    '& .RaSidebar-drawerPaper': {
      width: '25rem',
      closedWidth: '3.4375rem',
    },
  },
  layoutOverride: {
    '& .MuiDrawer-paper': {
      height: 'calc(100vh - 40px)',
      paddingBottom: '15px',
    },
    overflowX: 'clip',
    overflowY: 'auto',
  },
  breadcrumbs: {
    marginTop: '16px',
    marginLeft: '26px',
  },
  fontWeight700: {
    fontWeight: 700,
  },
  contentWrapper: {
    height: '93vh',
    overflowY: 'auto',
  },
}));

export default useStyles;
