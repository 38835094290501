/* eslint-disable no-unused-expressions */
import { useContext, useEffect } from 'react';
import { SignalRContext } from '../contexts/signalR';

export const useSignalREvent = (event, callback) => {
  const { connection, setMethods, methods } = useContext(SignalRContext);

  useEffect(() => {
    if (!methods.includes(event)) {
      connection?.on(event, callback);
      setMethods(event);
    } else {
      connection?.off(event);

      connection?.on(event, callback);
    }
  }, [connection, callback]);
};

export default {};
