import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAddExtraResource,
  ThunkGetAvailableExtraResources,
} from '@store/slices/billing/thunks';
import { ExtraResourcesSelector } from '@store/slices/billing';
import ExtraResourceItem from '@components/PricingPlan/components/Usage/components/extraResources/ExtraResourceItem';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import resourceTypes from '@constants/resourceTypes';
import useStyles from './styles';

const PurchaseExtraResources = ({ onPurchase }) => {
  const classes = useStyles();
  const [processing, setProcessing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const options = useSelector(ExtraResourcesSelector);

  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetAvailableExtraResources());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onClick = value => {
    setSelectedItem(value);
  };

  const handleSubmit = () => {
    setProcessing(true);
    dispatch(ThunkAddExtraResource({ code: selectedItem?.value }))
      .unwrap()
      .finally(() => {
        setProcessing(false);
        setSelectedItem(false);
        onPurchase();
      });
  };

  const text = useMemo(() => {
    if (!selectedItem) return null;
    return `You are goeing to purchase extra ${selectedItem.info.quantity} ${
      resourceTypes[selectedItem.info.type]
    } till the end of the billing period. It will costs ${selectedItem.info
      .priceCents / 100}$. Invoice will be generated after submit`;
  }, [selectedItem]);

  return (
    <>
      <div className={classes.container}>
        {options.map(i => (
          <ExtraResourceItem
            key={i.value}
            label={i.label}
            onClick={() => onClick(i)}
          />
        ))}
      </div>
      <ConfirmDialog
        open={selectedItem}
        setOpen={setSelectedItem}
        title="Purchase extra items"
        content={text}
        onAccept={handleSubmit}
        loading={processing}
      />
    </>
  );
};

PurchaseExtraResources.propTypes = {
  onPurchase: PropTypes.func,
};

export default PurchaseExtraResources;
