import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';

const MerakiForm = ({ classes, loading, useLabels }) => {
  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>Api key:</Typography>
      )}
      <Field
        id="apiKey"
        name="apiKey"
        styleType="main"
        fullWidth
        inputView="text"
        component={Input}
        type="password"
        autoComplete="new-password"
        classNameWrapper={classes.inputWrapper}
        placeholder="Api key"
        disabled={loading}
      />
    </>
  );
};

MerakiForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default MerakiForm;
