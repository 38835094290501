import { res } from '@components/Layout/SideBar/sideBarItems';
import {
  AT_HOOKS,
  AUTOTASK_SMS,
  CHATS,
  CW_HOOKS,
  DATA_SOURCES_INTEGRATION,
  DEVICE_MONITORS,
  DOCUMENTS,
  GENERIC_SOURCES,
  INCIDENTS,
  MAIL_BOXES,
  MESSAGING_DASHBOARD,
  TRIGGER,
  WEB_HOOKS,
} from '@constants/routes';

import { ReactComponent as MailboxesIcon } from '@assets/icons/new/mailBoxes.svg';
import { ReactComponent as WebhookIcon } from '@assets/icons/new/webhook.svg';
import { ReactComponent as GenericSourcesIcon } from '@assets/icons/new/genericSources.svg';
import { ReactComponent as InboundDocumentsIcon } from '@assets/icons/new/inboundDocuments.svg';
import { ReactComponent as DeviceMonitorsIcon } from '@assets/icons/new/deviceMonitor.svg';
import { ReactComponent as TriggersIcon } from '@assets/icons/new/triggers.svg';
import { ReactComponent as AlertsIcon } from '@assets/icons/new/alarm.svg';
import { ReactComponent as AlertSystemsIcon } from '@assets/icons/new/alertSystems.svg';
import { ReactComponent as AutotaskWebHooksIcon } from '@assets/icons/new/webHooksAT.svg';
import { ReactComponent as ConnectWiseWebHooksIcon } from '@assets/icons/new/webHooksCW.svg';
import { ReactComponent as SMSMessaging } from '@assets/icons/new/smsMessages.svg';
import { ReactComponent as ChatsIcons } from '@assets/icons/new/chat.svg';
import { ReactComponent as NotesIcon } from '@assets/icons/new/notes.svg';

const homePageItems = [
  {
    label: 'Parsing',
    items: [
      {
        icon: MailboxesIcon,
        title: 'Mail Boxes',
        description: 'All incoming mail',
        permission: res.mailBoxes,
        path: MAIL_BOXES,
      },
      {
        icon: WebhookIcon,
        title: 'Web Hooks',
        description: 'Automated notifications for events',
        permission: res.webHooks,
        path: WEB_HOOKS,
      },
      {
        icon: GenericSourcesIcon,
        title: 'Generic Sources',
        description: 'Versatile data origins',
        permission: res.mailBoxes,
        path: GENERIC_SOURCES,
      },
      {
        icon: InboundDocumentsIcon,
        title: 'Inbound Documents',
        description: 'Documents received externally',
        permission: res.mailBoxes,
        path: DOCUMENTS,
      },
    ],
  },
  {
    label: 'Alerts',
    items: [
      {
        icon: DeviceMonitorsIcon,
        title: 'Device Monitors',
        // description: 'All incoming mail',
        permission: res.uptimeMonitors,
        path: DEVICE_MONITORS,
      },
      {
        icon: TriggersIcon,
        title: 'Trigger',
        // description: 'All incoming mail',
        permission: res.triggers,
        path: TRIGGER,
      },
      {
        icon: AlertsIcon,
        title: 'Alerts',
        // description: 'All incoming mail',
        permission: res.triggers,
        path: INCIDENTS,
      },
    ],
  },
  {
    label: 'Messaging',
    items: [
      {
        icon: SMSMessaging,
        title: 'SMS Messaging',
        // description: 'All incoming mail',
        permission: res.smsMessaging,
        path: MESSAGING_DASHBOARD,
      },
      {
        icon: ChatsIcons,
        title: 'Chats',
        // description: 'All incoming mail',
        permission: res.chats,
        path: CHATS,
      },
      {
        icon: NotesIcon,
        title: 'Autotask Notes',
        // description: 'All incoming mail',
        permission: res.autotaskNoteToSms,
        path: AUTOTASK_SMS,
      },
    ],
  },
  {
    label: 'Integrations',
    items: [
      {
        icon: AlertSystemsIcon,
        title: 'Alert Systems',
        // description: 'All incoming mail',
        permission: res.alertSystemIntegrations,
        path: DATA_SOURCES_INTEGRATION,
      },
      {
        icon: AutotaskWebHooksIcon,
        title: 'Autotask Web Hooks',
        // description: 'All incoming mail',
        permission: res.webHookIntegrations,
        path: AT_HOOKS,
      },
      {
        icon: ConnectWiseWebHooksIcon,
        title: 'Connect Wise Web Hooks',
        // description: 'All incoming mail',
        permission: res.webHookIntegrations,
        path: CW_HOOKS,
      },
    ],
  },
];

export default homePageItems;
