import React from 'react';
import PropTypes from 'prop-types';
import HtmlInput from '@common/HtmlInput';
import FieldWrapper from '@common/form/FieldWrapper';

const CustomHtmlBody = ({ form, values, templateVariables }) => {
  return (
    !values.useCustomBody || (
      <FieldWrapper
        label="Body"
        labelSize={3}
        content={
          <HtmlInput
            name="body"
            form={form}
            templateVariables={templateVariables}
          />
        }
      />
    )
  );
};

CustomHtmlBody.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  values: PropTypes.objectOf(PropTypes.any),
  templateVariables: PropTypes.arrayOf(PropTypes.any),
};

export default CustomHtmlBody;
