const expirationPeriodOptions = {
  day: { label: '1 day', value: 0 },
  sevenDays: { label: '7 days', value: 6 },
  tenDays: { label: '10 days', value: 1 },
  month: { label: '1 month', value: 2 },
  year: { label: '1 year', value: 3 },
  custom: { label: 'Custom value', value: 4 },
  never: { label: 'Never expires', value: 5 },
};

export const fileExpirationOptions = [
  { label: '1 day', value: 0 },
  { label: '7 days', value: 6 },
  { label: '10 days', value: 1 },
  { label: '1 month', value: 2 },
];
export default expirationPeriodOptions;
