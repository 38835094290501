import React, { useContext } from 'react';
import cx from 'classnames';
import { TableRow } from '@material-ui/core';
import TableHeadCell from './TableHeadCell';
import { TableContext } from '../../context';

import useStyles from '../../styles';

const TableHead = () => {
  const classes = useStyles();

  const { columns, refArray, selectedIds } = useContext(TableContext);

  return (
    <TableRow
      className={cx(classes.tableRow, classes.sticky, {
        [classes.headerStickyPosition]: Boolean(selectedIds.length),
      })}
    >
      {columns.map((i, index) => (
        <TableHeadCell
          item={i}
          index={index}
          key={i.key}
          headRef={refArray[index]}
        />
      ))}
    </TableRow>
  );
};

export default TableHead;
