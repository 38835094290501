import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
      margin: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1.875rem 1rem',
      margin: '10px',
    },
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '1.875rem 2.5rem',
    border: '0.0625rem solid #EBEBEB',
    backgroundColor: theme.palette.tabs.contentBackground,
    margin: '20px auto',
    borderRadius: '0.8125rem',
    width: '50rem',
  },
  block: {
    width: '100%',
  },
}));

export default useStyles;
