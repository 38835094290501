import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import deleteData from '@services/api/common/deleteData';
import {
  mailBoxes,
  mailBoxStat,
  letters,
  parsers,
  letterDetailsById,
  unrecognizedTypes,
  mailBoxSettings,
  testLeter,
  assignParser,
  mailBoxesExists,
} from '@constants/appRoutes';
import putData from '@services/api/common/putData';
import putWithResponse from '@services/api/common/putWithResponse';
import postWithResponse from '@services/api/common/postWithResponse';

export const checkMailBoxName = value =>
  getData(`${appConfig.baseUrl}${mailBoxesExists}?boxName=${value}`);

export const getMailBoxStat = id =>
  getData(appConfig.baseUrl.concat(mailBoxStat).concat(id));

export const removeLetter = id =>
  deleteData(appConfig.baseUrl.concat(letters).concat(id));

export const removeLetters = params =>
  deleteData(`${appConfig.baseUrl}${letters}bulkDelete?${params}`);

export const reParseMessage = id =>
  putData(`${appConfig.baseUrl}${letters}${id}`);

export const deleteParser = id =>
  deleteData(appConfig.baseUrl.concat(parsers).concat(id));

export const toggleEnabled = (id, mailBoxId, data) =>
  putData(
    `${appConfig.baseUrl.concat(
      parsers,
    )}${id}/makeEnabled?mailBoxId=${mailBoxId}`,
    data,
  );

export const toggleGlobal = (id, data) =>
  putWithResponse(`${appConfig.baseUrl.concat(parsers)}${id}/makeGlobal`, data);

export const deleteMailBox = id =>
  deleteData(appConfig.baseUrl.concat(mailBoxes).concat(id));

export const getLetterById = id =>
  getData(`${appConfig.baseUrl}${letterDetailsById(id)}`);

export const getUnrecognizedTypes = id =>
  getData(`${appConfig.baseUrl}${unrecognizedTypes}${id}`);

export const addIgnoreRules = data =>
  postWithResponse(
    `${appConfig.baseUrl}${mailBoxSettings}addIgnoreRules`,
    data,
  );

export const addBulkIgnoreRules = data =>
  postWithResponse(
    `${appConfig.baseUrl}${mailBoxSettings}addBulkIgnoreRules`,
    data,
  );

export const testMessage = (messageId, mailBoxId) =>
  postWithResponse(
    `${appConfig.baseUrl}${testLeter(messageId, mailBoxId)}`,
    null,
  );

export const getParsersAutocomplete = () =>
  getData(
    `${appConfig.baseUrl}/Parsers/getAsAutocomplete?addEmptyItem=false&orderBy=label`,
  );

export const assignParserToLetter = (parserId, letterId) =>
  putWithResponse(
    `${appConfig.baseUrl}${assignParser(parserId, letterId)}`,
    null,
  );

export const dashboardDataTransformer = value => {
  const arr = value.split('/');
  return `20${arr[2]}-${arr[0]}-${arr[1]}`;
};

export const getLetterText = id =>
  getData(`${appConfig.baseUrl}${letters}${id}/get-as-text`);
