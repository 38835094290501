import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './bannerStyles';

const Banner = ({ text, icon }) => {
  const bannerStyles = useStyles();
  return (
    <div
      className={`${bannerStyles.bannerCommon} ${bannerStyles.bannerBackground}`}
    >
      <div className={bannerStyles.bannerText}>Hello!</div>
      <div
        className={`${bannerStyles.circle1} ${bannerStyles.commonCircleStyles}`}
      >
        <div
          className={`${bannerStyles.circle2} ${bannerStyles.commonCircleStyles}`}
        >
          <div
            className={`${bannerStyles.circle3} ${bannerStyles.commonCircleStyles}`}
          >
            <img alt="" src={icon} className={bannerStyles.envelope} />
          </div>
        </div>
      </div>
      <div className={bannerStyles.bannerText}>{text}</div>
    </div>
  );
};

Banner.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default Banner;
