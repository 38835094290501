import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    cursor: 'pointer',
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

export default useStyles;
