import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Field, Form } from 'react-final-form';
import { TextField } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { OnChange } from 'react-final-form-listeners';
import { useHistory } from 'react-router-dom';

import {
  ThunkGetAutotaskCompanies,
  ThunkGetConnectWiseCompanies,
  ThunkGetKaseyaCompanies,
} from '@store/slices/createTicket/thunks';
import { ThunkGetHaloClientsCrmId } from '@store/slices/HaloTickets/thunks';
import {
  ThunkAddUsersToSmsCampaignGroup,
  ThunkGetCrmUserByCrmId,
} from '@store/slices/smsCampaigns/thunks';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';

import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';

import crmSources from '@constants/crmSources';
import { SMS_CAMPAIGN_USER_TYPES } from '@constants/smsCampaign';
import { SMS_CAMPAIGNS } from '@constants/routes';

import FormControlCheckBox from '@common/Checkbox/FormControlCheckBox';
import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { ThunkGetZendeskCompanies } from '@store/slices/Zendesk/thunks';
import { ThunkGetSuperOpsCompanies } from '@store/slices/SuperOps/thunks';

const SmsGroupsImportFromPSA = ({ match: { params } }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [search, setSearch] = useState('');

  const [psaOptions, setPsaOptions] = useState([]);
  const [psaCompanies, setPsaCompanies] = useState([]);
  const [psaUsers, setPsaUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [userPhones, setUserPhones] = useState([]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const list = useSelector(listSelector);
  const sharedStyles = useSharedStyles();
  const { push } = useHistory();

  const getCompaniesThunk = crmType => {
    switch (crmType) {
      case crmSources.ConnectWise.name:
        return ThunkGetConnectWiseCompanies;
      case crmSources.Autotask.name:
        return ThunkGetAutotaskCompanies;
      case crmSources.Kaseya.name:
        return ThunkGetKaseyaCompanies;
      case crmSources.Halo.name:
        return ThunkGetHaloClientsCrmId;
      case crmSources.Zendesk.name:
        return ThunkGetZendeskCompanies;
      case crmSources.SuperOps.name:
        return ThunkGetSuperOpsCompanies;
      default:
        return () => {};
    }
  };

  const handleCrmChange = useCallback(
    crmId => {
      setProcessing(true);

      const selectedCrm = psaOptions.find(i => i.id === crmId);

      const thunk = getCompaniesThunk(selectedCrm.crmType);

      if (selectedCrm.crmType === crmSources.Halo.name) {
        dispatch(thunk(crmId))
          .unwrap()
          .then(res => {
            setPsaCompanies(res);
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        dispatch(thunk({ psaId: crmId }))
          .unwrap()
          .then(res => {
            setPsaCompanies(res);
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [dispatch, psaOptions],
  );

  const handleCrmCompanyChange = useCallback(
    crmId => crmCompanyId => {
      if (crmCompanyId) {
        setProcessing(true);

        dispatch(ThunkGetCrmUserByCrmId({ crmId, crmCompanyId }))
          .unwrap()
          .then(res => {
            setPsaUsers(res);
            setUsers(res);
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        setPsaUsers([]);
      }
    },
    [dispatch],
  );

  const submit = () => {
    setProcessing(true);

    const payload = {
      type: SMS_CAMPAIGN_USER_TYPES.PSA,
      smsCampaignGroupId: params.smsGroupId,
      contacts: userPhones.map(i => ({ name: i.name, phone: i.phone })),
    };

    dispatch(ThunkAddUsersToSmsCampaignGroup({ payload }))
      .unwrap()
      .then(res => {
        dispatch(actions.saveList([...list, ...res]));
        enqueueSnackbar('Successfully added', { variant: 'success' });

        push(SMS_CAMPAIGNS);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const addOrRemovePhoneNumber = values => {
    const userPhone = userPhones.find(
      i => i.id === values.id && i.phone === values.phone,
    );
    if (userPhone) {
      setUserPhones(
        userPhones.filter(
          i => !(i.id === values.id && i.phone === values.phone),
        ),
      );
    } else {
      setUserPhones([...userPhones, values]);
    }
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetCrmSources())
      .unwrap()
      .then(res => {
        setPsaOptions(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (psaOptions.length === 1) {
      handleCrmChange(psaOptions[0]?.id);
    }
  }, [handleCrmChange, psaOptions]);

  if (loading) return <Loading />;
  return (
    <div
      style={{
        padding: '20px',
        background: 'white',
        boxSizing: 'border-box',
        margin: '40px',
        borderRadius: '10px',
      }}
    >
      <Form
        onSubmit={submit}
        initialValues={{
          psaId: psaOptions?.length === 1 ? psaOptions[0]?.id : '',
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} style={{ minWidth: '650px' }}>
            <FieldWrapper
              label="PSA"
              labelSize={12}
              contentSize={12}
              content={
                <Field
                  id="psaId"
                  name="psaId"
                  showLabel
                  component={DropDown}
                  options={psaOptions}
                  disabled={processing}
                  labelName="name"
                  valueName="id"
                />
              }
            />
            <OnChange name="psaId">{handleCrmChange}</OnChange>
            <FieldWrapper
              label="PSA Companies"
              labelSize={12}
              contentSize={12}
              content={
                <Field
                  name="psaCompany"
                  id="psaCompany"
                  component={AutocompleteFormInput}
                  loading={processing || !values?.psaId}
                  items={psaCompanies}
                />
              }
            />
            <OnChange name="psaCompany">
              {handleCrmCompanyChange(values?.psaId)}
            </OnChange>
            <div
              style={{
                maxHeight: '500px',
                overflow: 'auto',
                margin: '10px 0px',
              }}
            >
              {values?.psaCompany && (
                <div
                  style={{
                    width: '100%',
                    padding: '20px',
                    boxSizing: 'border-box',
                  }}
                >
                  <TextField
                    placeholder="Search PSA Users"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <SearchRounded style={{ color: 'grey' }} />
                      ),
                    }}
                    fullWidth
                    value={search}
                    onChange={e => {
                      setSearch(e.target.value);
                      if (e.target.value !== '') {
                        setUsers(
                          psaUsers.filter(i =>
                            i.name
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase()),
                          ),
                        );
                      } else {
                        setUsers(psaUsers);
                      }
                    }}
                  />
                </div>
              )}
              {!!users &&
                users.map(user => (
                  <div
                    key={user.id}
                    style={{
                      display: 'grid',
                      alignItems: 'center',
                      gridTemplateColumns: '250px 1fr',
                    }}
                  >
                    <div>
                      <b>{user.name}</b>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {user.phones.map((i, idx) => (
                        <div
                          key={i.value + i.name}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Field
                            name={`users.id-${user.id}.${idx}`}
                            id={`users.id-${user.id}.${idx}`}
                            type="checkbox"
                            component={FormControlCheckBox}
                            label={`[${i.value}] ${i.name}`}
                            disabled={processing}
                            onClick={() =>
                              addOrRemovePhoneNumber({
                                id: `users.id-${user.id}.${idx}`,
                                name: user.name,
                                phone: i.value,
                              })
                            }
                          />
                        </div>
                      ))}

                      {user?.phones?.length === 0 && (
                        <div style={{ color: 'red', margin: '0px 15px' }}>
                          Phones are not provided on PSA.
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>

            <div className={sharedStyles.dialogActionsContainer}>
              <ReusableButton label="Add" type="submit" disabled={processing} />
            </div>
          </form>
        )}
      />
    </div>
  );
};

SmsGroupsImportFromPSA.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      smsGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default SmsGroupsImportFromPSA;
