import React, { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { OnBlur } from 'react-final-form-listeners';
import PropTypes from 'prop-types';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';

import useStyles from '../styles';

const TechnicianCount = ({
  loading,
  values,
  form,
  pricePlansOptions,
  minTechCount,
  discountData,
}) => {
  const classes = useStyles();

  const getPrice = useCallback(price => (price / 100).toFixed(2), []);

  const getPriceFromPlan = selectedPricePlan =>
    selectedPricePlan.basePriceCents +
    (values.techCount - selectedPricePlan.minUsers) *
      selectedPricePlan.pricePerTechCents;

  const price = useMemo(() => {
    if (!Number(values.techCount)) return 'Invalid Value';

    const selectedPricePlan = pricePlansOptions.find(
      i => i.id === values?.pricingPlan,
    );
    if (selectedPricePlan) {
      const priceFromPricePlan = getPriceFromPlan(selectedPricePlan);

      return `${getPrice(priceFromPricePlan)} $/month`;
    }
    return '';
  }, [pricePlansOptions, values.pricingPlan, values.techCount]);

  const discountedPrice = useMemo(() => {
    if (!Number(values.techCount)) return 'Invalid Value';

    const selectedPricePlan = pricePlansOptions.find(
      i => i.id === values?.pricingPlan,
    );

    if (selectedPricePlan && discountData) {
      const priceFromPricePlan = getPriceFromPlan(selectedPricePlan) / 100;

      if (discountData.type === 0) {
        const discounted =
          (+priceFromPricePlan * (100 - +discountData.amount)) / 100;

        return `first ${
          discountData.durationMonth
        } month(s) - $${discounted.toFixed(2)} after $${priceFromPricePlan}`;
      }
      // Solid discount
      return `first ${
        discountData.durationMonth
      } month(s) - $${priceFromPricePlan -
        discountData.amount} after $${priceFromPricePlan}`;
    }

    return '';
  }, [
    discountData,
    getPriceFromPlan,
    pricePlansOptions,
    values?.pricingPlan,
    values?.techCount,
  ]);

  if (!minTechCount) return null;

  return (
    <>
      <FieldWrapper
        label="Technician Licenses Count"
        labelSize={8}
        contentSize={4}
        fullWidth
        classNameContainer={classes.noWrap}
        content={
          <Field
            id="techCount"
            name="techCount"
            fullWidth
            component={Input}
            disabled={loading}
            type="number"
            dateProps={{
              min: minTechCount,
              style: { minWidth: '70px' },
            }}
          />
        }
      />
      <OnBlur name="techCount">
        {() => {
          if (
            Number(values.techCount) < minTechCount ||
            !Number(values.techCount)
          ) {
            form.change('techCount', minTechCount);
          }
        }}
      </OnBlur>

      {!discountData?.amount && <div>{`Total Price: ${price}`}</div>}

      {discountData?.description && (
        <div>{`Discount: ${discountData?.description}`}</div>
      )}
      {discountData?.amount && <div>{`Total Price: ${discountedPrice}`}</div>}
    </>
  );
};

TechnicianCount.propTypes = {
  loading: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    pricingPlan: PropTypes.string,
    techCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  form: PropTypes.shape({ change: PropTypes.func }),
  pricePlansOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      pricePerTech: PropTypes.number,
    }),
  ),
  minTechCount: PropTypes.number.isRequired,
};
export default TechnicianCount;
