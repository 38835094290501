import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import homePageItems from '@components/HomePage/homePageItems';
import CategoryItem from '@components/HomePage/components/CategoryItem';

import { currentUserDataSelector } from '@store/selectors';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

import SetupWizard from '../SetupWizard';
import useStyles from './styles';

const HomePage = () => {
  const classes = useStyles();
  const [pageItems, setPageItems] = useState([]);
  const [openWizard, setOpenWizard] = useState(false);
  const userData = useSelector(currentUserDataSelector);

  useEffect(() => {
    const allowedResources = localStorage.tryGetItem(
      localStorageConst.ALLOWED_RESOURCES,
    );
    if (allowedResources) {
      homePageItems.forEach(chapter => {
        chapter.items = chapter.items.filter(i =>
          allowedResources.includes(i.permission),
        );
      });
    }
    setPageItems(homePageItems);
  }, []);

  useEffect(() => {
    if (userData && userData?.displayStartupWizard) {
      setOpenWizard(true);
    }
  }, [userData]);

  return (
    <div className={classes.container}>
      {pageItems.map(item => (
        <CategoryItem key={item.label} item={item} />
      ))}

      {/* {openWizard && userData && userData.displayStartupWizard && (
        <SetupWizard open={openWizard} setOpen={setOpenWizard} />
      )} */}
      {openWizard && <SetupWizard open={openWizard} setOpen={setOpenWizard} />}
    </div>
  );
};

export default HomePage;
