import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  div: {
    padding: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',

    fontStyle: 'normal',
  },
  plusIcon: {
    fill: theme.palette.info.main,
    marginRight: '0.5rem',
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconImage: {
    transition: '0.3s',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  buttonAddWrapper: {
    margin: '0.625rem 0.625rem 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0.625rem 0',
    },
  },
  buttonCancel: {
    [theme.breakpoints.down('xs')]: {
      margin: '0',
    },
  },
  buttonSubmit: {
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
    },
  },

  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  dropDown: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    width: 'auto',
    minWidth: '6.25rem',
  },
  label: {
    margin: '0 15px',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0 5px 0',
    },
  },
  inputDateViewName: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
