import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { preCheck } from '@services/requests/auth';

export const ThunkPreCheck = createAsyncThunk(
  `${AUTH}/preCheck`,
  ({ payload }) => preCheck(payload),
);

export default {};
