import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Input from '@common/Input/Input';
import { Field, Form } from 'react-final-form';
import useStyles from './styles';

const modelTemplate = {
  from: '',
  to: '',
};

const maxNumberLength = 12;

const FromToDialog = ({ data, name, setValues, label, useDelimiter }) => {
  const regex = useDelimiter ? /^-?\d+[.,]?\d*$/ : /^-?\d+$/;
  const initialData = data && typeof data === 'object' ? data : modelTemplate;
  const classes = useStyles();
  const [model, setModel] = useState(initialData);

  const onDataChange = event => {
    let { value } = event.target;
    if ((regex.test(value) || value === '') && value.length < maxNumberLength) {
      // no comma in the JSON
      if (value.includes(',')) {
        value = value.replace(',', '.');
      }
      const newModel = { ...model };
      newModel[event.target.name] = value;
      setModel(newModel);
      setValues({
        target: {
          name,
          value: newModel,
        },
      });
    }
  };

  const fields = [{ name: 'from' }, { name: 'to' }];

  return (
    <div>
      <Paper className={classes.root}>
        <h5 className={classes.labelWrapper}>{label}</h5>
        <div className={classes.container}>
          <Form onSubmit={() => {}}>
            {() => (
              <>
                {fields.map(field => (
                  <div key={field.name} className={classes.item}>
                    <span className={classes.label}>
                      {field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                    </span>
                    <Field
                      name={field.name}
                      id="outlined-basic"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      input={{
                        name: field.name,
                        value: model[field.name],
                        onChange: onDataChange,
                      }}
                      component={Input}
                    />
                  </div>
                ))}
              </>
            )}
          </Form>
        </div>
      </Paper>
    </div>
  );
};

FromToDialog.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  setValues: PropTypes.func,
  label: PropTypes.string,
  useDelimiter: PropTypes.bool,
};

export default FromToDialog;
