import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textContainer: {
    height: '100vh',
    padding: '20px',
    boxSizing: 'border-box',
    fontSize: '1.5rem',
    margin: '10px auto',
  },
  textWraps: {
    margin: '5px',
  },
}));

export default useStyles;
