import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketSites } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketSites = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketSites`,
  async ({ crmId, companyId }) => {
    const sites = await getConnectWiseTicketSites(crmId, companyId);
    return sites;
  },
);

export default {};
