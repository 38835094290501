import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getCompaniesVerificationSettings } from '@services/requests/verificationSettings';

export const ThunkGetCompaniesVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getCompaniesVerificationSettings`,
  ({ psaId }) => getCompaniesVerificationSettings(psaId),
);

export default {};
