import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  div: {
    height: '90vh',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '20px 10px 0',
    flexWrap: 'wrap',
  },
  cursor: {
    cursor: 'pointer',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '310px',
    height: '280px',
    padding: '0px',
    margin: '20px',
    borderRadius: '13px',
    background: theme.palette.card.background,
    fontStyle: 'normal',
    boxShadow: '0px 4px 24px 0px #00000026',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.card.hoverBackground,
    },
  },
  text: {
    color: theme.palette.card.text,
  },
  imageStyle: {
    width: '90%',
    objectFit: 'scale-down',
    height: '50%',
  },
  addCrmCardContainer: {
    display: 'block',
  },
  block: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    justifyContent: 'center',
  },
  btnWrapper: {
    padding: '5px',
    borderRadius: '3px',
    boxShadow: '0 20 84 -20 #083F7340',
  },
  iconWrapper: {
    width: '120px',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    background: theme.palette.card.addCard.icon.background,
  },

  headerContainer: {
    height: '50px',
    background: '#000',
    color: '#fff',
    borderRadius: '13px 13px 0 0',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '1.12em',
    color: '#3A3E45',
  },
  actions: {
    width: '100%',
    textAlign: 'end',
  },
  truncate: {
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '1.25em',
  },
  noIntegrations: {
    textAlign: 'center',
    fontSize: '2rem',
    marginTop: '2rem',
  },
  hyperlink: {
    color: '#2853ff',
    '&:hover': {
      color: '#001597',
    },
  },
  disabledTile: {
    position: 'absolute',
    marginTop: '12rem',
    textAlign: 'center',
    color: '#aba8a8',
  },
  smallCard: {
    width: '250px',
    height: '150px',
  },
}));

export default useStyles;
