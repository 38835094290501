import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeTriageTickets } from '@services/requests/triage';
import { TRIAGE } from '@store/slices/resources';

export const ThunkCloseTriageTickets = createAsyncThunk(
  `${TRIAGE}/closeTriageTickets`,
  async ids => {
    await closeTriageTickets(ids);
    return ids;
  },
);

export default {};
