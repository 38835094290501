import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  base: {
    width: '120px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem auto',
    },
    margin: '1rem 3rem',
    cursor: 'pointer',
    color: 'gray',
    padding: '5px',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

export default useStyles;
