import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkExportVerificationHistory } from '@store/slices/verificationHistory/thunks';

import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const VerificationHistoryActions = () => {
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleExport = type => {
    setProcessing(true);
    dispatch(ThunkExportVerificationHistory({ type }))
      .unwrap()
      .then(async res => {
        const blob = await res.blob();

        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.setAttribute('href', objectUrl);
        link.setAttribute('download', `data.${type}`);
        link.style.display = 'none';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div className={classes.actionsContainer}>
      <ReusableButton
        onClick={() => {
          handleExport('csv');
        }}
        label="Export CSV"
        disabled={processing}
      />

      <ReusableButton
        onClick={() => {
          handleExport('xlsx');
        }}
        label="Export XLSX"
        disabled={processing}
      />

      <ReusableButton
        onClick={() => {
          handleExport('json');
        }}
        label="Export JSON"
        disabled={processing}
      />
    </div>
  );
};

export default VerificationHistoryActions;
