import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import SetupWizard from '@components/SetupWizard';
import { HOME_ROUTE } from '@constants/routes';

const SetupWizardPage = () => {
  const [open, setOpen] = useState(true);

  const { push } = useHistory();

  return (
    <div>
      <SetupWizard
        setOpen={() => {
          push(HOME_ROUTE);
          setOpen(false);
        }}
        open={open}
        showCloseForeverCheckbox={false}
      />
    </div>
  );
};

export default SetupWizardPage;
