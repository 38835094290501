import { createSlice } from '@reduxjs/toolkit';
import { COUNTRIES } from '../resources';
import { ThunkGetCountries } from './thunks/getCountries';

const initialState = {
  countries: [],
  loading: false,
};

const phoneNumbersSlice = createSlice({
  name: COUNTRIES,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetCountries.pending, state => {
      state.loading = true;
    });
    builder.addCase(ThunkGetCountries.fulfilled, (state, { payload }) => {
      state.countries = payload;
      state.loading = false;
    });
  },
});

export default phoneNumbersSlice.reducer;

export const countryCodesListSelector = state => state[COUNTRIES];
