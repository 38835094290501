import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmDefaultCompanies } from '@services/requests/treeView';
import { TREE_VIEW } from '@store/slices/resources';

export const ThunkGetCrmDefaultCompanies = createAsyncThunk(
  `${TREE_VIEW}/getCrmDefaultCompanies`,
  async ({ crmId }) => {
    const companies = await getCrmDefaultCompanies(crmId);
    return { [crmId]: companies };
  },
);

export default {};
