import { actionTypes } from '../actions';

const initialState = {};

const selectedNetworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_NETWORKS: {
      const { entityType, value } = action.payload;
      const newState = { ...state };
      newState[entityType] = value;
      return newState;
    }
    case actionTypes.RESET_SELECTED_NETWORKS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default selectedNetworkReducer;
