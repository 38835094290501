import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveOptInForm } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 *
 * @param {string} key
 * @param {Object} payload
 * @param {Object} payload.id
 * @param {string} payload.contactName
 * @param {string} payload.contactPhoneNumber
 * @param {string} payload.crmType
 * @param {boolean} payload.allowMessaging
 * @param {boolean} payload.updatePsaContact
 */
export const ThunkSaveOptInForm = createAsyncThunk(
  `${OPT_IN}/saveOptInForm`,
  async ({ key, payload }) => {
    const res = await saveOptInForm(key, payload);
    return res;
  },
);

export default {};
