import localStorage from '@services/localStorage';
import appConfig from '@configs/appConfig';
import { authUrl } from '@constants/appRoutes';
import { getTenantImage } from '@components/Settings/TenantSettings/helpers';
import { getHeaders, clearTokens, saveTokens } from '@utils/transfered';

import { loginWithAuthObject } from '@ui/pages/auth/Login/helpers';
import {
  getUserId,
  getAccessToken,
  getRefreshToken,
  getRoleIdFromStorage,
  getPermissionsFromStorage,
  setPermissionsToStorage,
  parseJwtToken,
  setTenantIdToStorage,
  setRoleIdToStorage,
  setUserImageUrlToStorage,
  setTenantImageUrlToStorage,
  setAllowedResources,
} from '../api';

const urls = {
  authorization: appConfig.baseUrl.concat(authUrl),
  base: appConfig.baseUrl,
  forgotPassword: appConfig.baseUrl.concat(
    '/Account/send-forgot-password-email',
  ),
  changePassword: appConfig.baseUrl.concat('/Account/change-password'),
  signUp: 'Account/register',
  reset: appConfig.baseUrl.concat('/Account/reset-password'),
  permissions: appConfig.baseUrl.concat('/AccessRights/'),
  mfaAuth: appConfig.baseUrl.concat('/User/MultiFactorLogin/'),
  thirdPartyAuth: appConfig.baseUrl.concat('/User/ExternalLogin'),
  loginAs: appConfig.baseUrl.concat('/User/LoginAs/'),
  checkMfa: appConfig.baseUrl.concat('/User/user-mfa/'),
  verifyCode: appConfig.baseUrl.concat('/Account/verifyCode/'),
};

const errorMessages = {
  login: 'LOGIN_ERROR_MESSAGE',
  change: 'CHANGE_ERROR_MESSAGE',
  forgot: 'FORGOT_ERROR_MESSAGE',
  signUp: 'SIGN_UP_ERROR_MESSAGE',
  reset: 'RESET_ERROR_MESSAGE',
};

const roleClaim =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

const populateClaimValues = claims => {
  setTenantIdToStorage(claims.tenantId);
  setRoleIdToStorage(claims[roleClaim]);
  setUserImageUrlToStorage(claims.imageUrl);
  setAllowedResources(claims.allowedObjects);
};

const loginAs = async (url, userId) => {
  const request = new Request(`${url}${userId}`, {
    method: 'GET',
    headers: getHeaders(true),
  });
  const response = await fetch(request);

  if (response.status === 401 || response.status === 403) {
    const data = await response.json();
    throw new Error(data.message);
  }
  if (response.status < 200 || response.status >= 300) {
    throw response.statusText;
  }
  const data = await response.json();
  const claims = parseJwtToken(data.accessToken);
  populateClaimValues(claims);
  loginWithAuthObject(data);
};

const mfaLogin = async (url, params, saveTokens, loginErrorMessage) => {
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(),
  });

  const response = await fetch(request);

  if (response.status >= 400 && response.status <= 403) {
    const data = await response.json();
    throw new Error(data.message);
  }
  if (response.status < 200 || response.status >= 300) {
    throw response.statusText;
  }
  const data = await response.json();
  const claims = parseJwtToken(data.accessToken);
  populateClaimValues(claims);
  saveTokens(data);
};

const login = async (url, params, saveTokens, loginErrorMessage) => {
  const body = {
    username: params.email,
    password: params.password,
  };

  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: getHeaders(),
  });

  const response = await fetch(request);

  if (response.status === 401 || response.status === 403) {
    const data = await response.json();
    throw new Error(data.message);
  }
  if (response.status < 200 || response.status >= 300) {
    const errorData = await response.json();
    throw errorData || response.statusText;
  }

  const data = await response.json();
  const claims = parseJwtToken(data.accessToken);
  populateClaimValues(claims);
  saveTokens(data);
};

const authLogin = async (url, params, saveTokens, loginErrorMessage) => {
  if (params.loginAs) {
    await loginAs(urls.loginAs, params.loginAs);
    return;
  }
  if (params.token) {
    await mfaLogin(urls.thirdPartyAuth, params, saveTokens, loginErrorMessage);
    return;
  }
  if (params.mfaCode) {
    await mfaLogin(urls.mfaAuth, params, saveTokens, loginErrorMessage);
  } else {
    await login(url, params, saveTokens, loginErrorMessage);
  }
};

const fetchPermissions = async (url, clearTokens) => {
  const request = new Request(url, {
    method: 'GET',
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status < 200 || response.status >= 300) {
    clearTokens();
    throw new Error(response.statusText);
  }
  const data = await response.json();
  const permissions = !!data && data.length > 0 ? data[0] : {};
  setPermissionsToStorage(JSON.parse(permissions.properties));
  return getPermissionsFromStorage();
};

const checkMfaSetupNeeded = async (url, clearTokens) => {
  const request = new Request(url, {
    method: 'GET',
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status < 200 || response.status >= 300) {
    clearTokens();
    throw new Error(response.statusText);
  }
  return response.json();
};

const refreshToken = () => {
  const body = {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  };

  if (!body.accessToken || !body.refreshToken) {
    return;
  }

  const request = new Request(urls.authorization, {
    method: 'PUT',
    headers: getHeaders(true),
    body: JSON.stringify(body),
  });

  fetch(request)
    .then(response => {
      if (!response.ok) {
        throw new Error('Unable to update refresh token');
      }
      return response.json();
    })
    .then(data => {
      saveTokens(data);
    })
    .catch(error => {
      clearTokens();
      throw error.message;
    });
};

let timerIdentifier;
const startTimer = () => {
  timerIdentifier = setInterval(refreshToken, 1800000);
};
const stopTimer = () => {
  if (timerIdentifier) {
    clearInterval(timerIdentifier);
  }
};

// const empeekAp = empeekAuthProvider(
//   {
//     saveTokens,
//     clearTokens,
//     hasTokens,
//     getPermissions: fetchPermissions,
//   },
//   urls,
//   errorMessages,
//   methods,
// );

const authProvider = {
  login: async params => {
    await authLogin(
      urls.authorization,
      params,
      saveTokens,
      errorMessages.login,
    );
    const [tenantImage] = await Promise.all([
      getTenantImage(),
      fetchPermissions(urls.permissions, clearTokens),
    ]);
    setTenantImageUrlToStorage(tenantImage?.tenantImageUrl);
    startTimer();
    if (params.loginAs) {
      document.location.reload();
    }
    return checkMfaSetupNeeded(urls.checkMfa, clearTokens);
  },

  logout: async () => {
    localStorage.removeItem('accessToken');
    stopTimer();
  },

  checkAuth: () =>
    getAccessToken()
      ? Promise.resolve()
      : Promise.reject({
          message: false,
          redirectTo: '/Login',
        }),

  checkError: error => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('accessToken');
      return Promise.reject({ message: false });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  getPermissions: async () => {
    return getPermissionsFromStorage() ? getPermissionsFromStorage() : {};
  },

  signUp: async () => {},

  signInWithGoogle: async () => {},

  signInWIthFacebook: async () => {},

  getUserId,

  getCurrentCompanyId: () => null,
  setCurrentCompanyId: () => null,
  updateCurrentCompanyId: () => null,
  startRefreshTimer: () => startTimer(),
  getUserRole: () => getRoleIdFromStorage(),

  changePassword: async passwords => {
    const request = new Request(urls.changePassword, {
      method: 'PATCH',
      headers: getHeaders(true),
      body: JSON.stringify(passwords),
    });

    const response = await fetch(request);

    if (response.status === 401 || response.status === 403) {
      const data = await response.json();
      throw new Error(data.message);
    }
    if (response.status < 200 || response.status >= 300) {
      const errorData = await response.json();
      throw errorData || response.statusText;
    }
  },
  forgotPassword: async email => {
    const request = new Request(urls.forgotPassword, {
      method: 'PATCH',
      headers: getHeaders(false),
      body: JSON.stringify({
        email,
        redirectPrefix: ''
          .concat(window.location.origin, '/#/')
          .concat('email-handler?mode=resetPassword&oobCode='),
      }),
    });

    const response = await fetch(request);

    if (response.status === 401 || response.status === 403) {
      const data = await response.json();
      throw new Error(data.message);
    }
    if (response.status < 200 || response.status >= 300) {
      const errorData = await response.json();
      throw errorData || response.statusText;
    }
  },

  resetPassword: async ({ queryParam, password }) => {
    const request = new Request(urls.reset, {
      method: 'PATCH',
      headers: getHeaders(false),
      body: JSON.stringify({
        password,
        token: queryParam,
      }),
    });

    const response = await fetch(request);

    if (response.status === 401 || response.status === 403) {
      const data = await response.json();
      throw new Error(data.message);
    }
    if (response.status < 200 || response.status >= 300) {
      const errorData = await response.json();
      throw errorData || response.statusText;
    }
  },

  verifyResetPasswordCode: async code => {
    const url = `${urls.verifyCode}?verificationCode=${code}`;
    const request = new Request(url, {
      method: 'GET',
      headers: getHeaders(false),
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      const data = await response.json();
      if (data) {
        throw new Error(data.message);
      }
      throw response.statusText;
    }
    clearTokens();
  },

  getCompanyInvitationData: () => null,
};

export default authProvider;
