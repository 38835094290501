import { createAsyncThunk } from '@reduxjs/toolkit';
import { addMessagingTemplate } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkAddMessagingTemplate = createAsyncThunk(
  `${MESSAGING}/addMessagingTemplate`,
  async ({ payload }) => {
    const template = await addMessagingTemplate(payload);
    return template;
  },
);

export default {};
