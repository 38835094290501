import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConnectWiseTicketNote } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkAddConnectWiseTicketNote = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/addConnectWiseTicketNote`,
  async ({ crmDefaultId, ticketId, payload }) => {
    const note = await addConnectWiseTicketNote(
      crmDefaultId,
      ticketId,
      payload,
    );
    return note;
  },
);

export default {};
