import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    width: '50%',
    marginTop: '2rem',
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogActions: {
    textAlign: 'center',
    marginTop: '0.5rem',
  },
}));

export default useStyles;
