import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, Select, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import { TableContext } from '@components/Table/context';
import { TypeLabelValue } from '@common/propTypes/common';

import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import AutocompleteWithPagination from '@common/AutocompleteWithPagination/AutocompleteWithPagination';
import useStyles from '../styles';

const TableDropDown = ({
  options,
  name,
  placeholder,
  filterByAutocomplete,
  isMultiple,
  limitTags,
  filterCallBack,
  optionsResource,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(isMultiple ? [] : '');
  const { filtering, setFilteringObject } = useContext(TableContext);

  useEffect(() => {
    if (name && !filterByAutocomplete) {
      setFilteringObject(filtering, filterByAutocomplete || name, value);
    }
  }, [value]);

  const AutocompleteComponent = optionsResource
    ? AutocompleteWithPagination
    : AutocompleteFormInput;

  return (
    <>
      {filterByAutocomplete ? (
        <AutocompleteComponent
          input={{
            value,
            onChange: e => {
              setValue(e);
              if (filterCallBack) {
                filterCallBack(e, filtering, setFilteringObject);
              } else {
                setFilteringObject(filtering, filterByAutocomplete, e);
              }
            },
          }}
          multiple={isMultiple}
          limitTags={limitTags}
          items={optionsResource ? undefined : options}
          pageSize={optionsResource ? 100 : undefined}
          label={placeholder}
          resource={optionsResource}
          size="small"
          variant="standard"
          listItemClassName={classes.listItem}
        />
      ) : (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 20px' }}>
          <TextField
            fullWidth
            value={value}
            onChange={({ target }) => {
              setValue(target.value);
            }}
            placeholder={placeholder}
          />

          <Select value="" className={classes.select}>
            {options.map(i => (
              <MenuItem
                value={i.value}
                key={i.value}
                onClick={() => {
                  setValue(i.value);
                }}
              >
                {i.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </>
  );
};

TableDropDown.propTypes = {
  options: PropTypes.arrayOf(TypeLabelValue),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  filterByAutocomplete: PropTypes.string,
  optionsResource: PropTypes.string,
  filterCallBack: PropTypes.func,
  isMultiple: PropTypes.bool,
  limitTags: PropTypes.number,
};

export default TableDropDown;
