import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Radio } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';
import { ThunkGetVerificationSettings } from '@store/slices/verificationSettings/thunks';
import { ThunkGetContactByPhoneNumber } from '@store/slices/psaTechVerification/thunks/getContactByPhoneNumber';
import { ThunkGetContactTickets } from '@store/slices/psaTechVerification/thunks/getContactTickets';
import { ThunkConfirmVerifiedPsaTechBySms } from '@store/slices/psaTechVerification/thunks/confirmVerifiedPsaTechBySms';
import {
  ThunkGetATContactData,
  ThunkGetCWContactData,
  ThunkGetHaloContactData,
  ThunkGetKaseyaContactData,
  ThunkGetSuperOpsContactData,
  ThunkGetZendeskContactData,
} from '@store/slices/psaPods/thunks';
import { actions } from '@store/actions';

import crmSources from '@constants/crmSources';

import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@common/Loading/Loading';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import getFormattedDate from '@utils/getFormattedDate';
import ReusableButton from '@common/Button/Button';
import Checkbox from '@common/Checkbox/FormControlCheckBox';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import useStyles from '../styles';

const VerifyTechDialog = ({
  open,
  onClose,
  from,
  verifiedPsaTechBySmsId,
  code,
  contactId = null,
  crmIdFromRequest,
  type,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [crmOptions, setCrmOptions] = useState([]);
  const [smsText, setSmsText] = useState('');
  const [crm, setCrm] = useState('');
  const [tickets, setTickets] = useState([]);
  const [contact, setContact] = useState(undefined);
  const [selectedTicket, setSelectedTicket] = useState(undefined);
  const [error, setError] = useState('');
  const [codeConfirmed, setCodeConfirmed] = useState(!code);

  const classes = useStyles();
  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getContactThunk = crmType => {
    switch (crmType) {
      case crmSources.ConnectWise.idx:
        return ThunkGetCWContactData;
      case crmSources.Autotask.idx:
        return ThunkGetATContactData;
      case crmSources.Halo.idx:
        return ThunkGetHaloContactData;
      case crmSources.Kaseya.idx:
        return ThunkGetKaseyaContactData;
      case crmSources.Zendesk.idx:
        return ThunkGetZendeskContactData;
      case crmSources.SuperOps.idx:
        return ThunkGetSuperOpsContactData;
      default:
        return () => {};
    }
  };

  const handleTicketIdClick = async ticketLink => {
    window.open(ticketLink, '_blank');
  };

  const onSubmit = () => {
    setProcessing(true);

    if (!smsText && !contactId) {
      setError('Required');
      setProcessing(false);
      return;
    }

    const payload = {
      verifiedPsaTechBySmsId,
      type,
      crmId: crm,
      messageText: smsText,
      ticketNumber: selectedTicket,
      contactId: contact?.userId,
      contactName: contact?.userName,
      contactCompanyId: contact?.companyId,
      contactCompanyName: contact?.companyName,
    };

    dispatch(ThunkConfirmVerifiedPsaTechBySms(payload))
      .unwrap()
      .then(res => {
        dispatch(actions.updateItemInList(res));
        enqueueSnackbar('Successfully confirmed', { variant: 'success' });
      })
      .finally(() => {
        setProcessing(false);
        onClose();
      });

    setError('');
  };

  const handleCrmChange = useCallback(
    crmId => {
      setLoading(true);
      if (!contactId) {
        // * technician request via SMS
        dispatch(ThunkGetContactByPhoneNumber({ crmId, phone: from }))
          .unwrap()
          .then(res => {
            if (res?.userId) {
              setContact(res);
              dispatch(
                ThunkGetContactTickets({
                  contactId: res.userId,
                  crmId,
                }),
              )
                .unwrap()
                .then(psaTickets => {
                  setTickets(psaTickets);
                  setLoading(false);
                });
            } else {
              setContact(null);
              setTickets([]);
              setLoading(false);
            }
          });
      } else {
        // technician verification request via CLIENT PORTAL
        const selectedCrm = crmOptions.find(i => +i.crmId === +crmId);
        if (selectedCrm?.type !== undefined) {
          dispatch(
            getContactThunk(selectedCrm?.type)({
              crmId,
              contactId,
            }),
          )
            .unwrap()
            .then(res => {
              setContact({
                userId: contactId,
                userName: res.name,
                companyId: res.companyId,
                companyName: res.companyName,
              });
              dispatch(
                ThunkGetContactTickets({
                  contactId,
                  crmId,
                }),
              )
                .unwrap()
                .then(psaTickets => {
                  setTickets(psaTickets);
                })
                .finally(() => {
                  setLoading(false);
                });
            })
            .catch(() => {
              setContact(null);
              setTickets([]);
              setLoading(false);
            });
        }
      }
    },
    [contactId, crmOptions, dispatch, from],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetCrmOptions())
      .unwrap()
      .then(res => {
        setCrmOptions(res);

        if (res?.length === 1) {
          setCrm(res[0]?.crmId);
          handleCrmChange(res[0]?.crmId);
        }
      });
    await dispatch(ThunkGetVerificationSettings())
      .unwrap()
      .then(res => {
        setSmsText(res.techResponseTemplate);
        if (!crmIdFromRequest) {
          setLoading(false);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    onMount().then(() => {});
  }, [onMount]);

  useEffect(() => {
    if (crmIdFromRequest && !!crmOptions?.length) {
      setCrm(crmIdFromRequest);
      handleCrmChange(crmIdFromRequest);
    }
  }, [crmIdFromRequest, handleCrmChange, crmOptions]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Verify PSA Tech</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.dialogContent}>
            {code && (
              <div className={classes.codeContainer}>
                {`Please, provide this code to client: ${code}`}
              </div>
            )}

            {!crmIdFromRequest && (
              <div
                className={classes.dropDownContainer}
                style={{ width: '500px' }}
              >
                <DropDown
                  label="PSA integration"
                  input={{
                    onChange: e => {
                      setCrm(e.target.value);
                      if (e.target.value) {
                        handleCrmChange(e.target.value);
                      }
                    },
                    value: crm,
                  }}
                  options={crmOptions}
                  labelName="crmName"
                  valueName="crmId"
                  disabled={processing}
                />
              </div>
            )}

            {!processing &&
              crm &&
              (contact ? (
                <div>
                  {contact.userName +
                    (contact.companyName && ` from ${contact.companyName}`)}
                </div>
              ) : (
                <div>No contact found</div>
              ))}

            {!processing && crm && contact && !tickets?.length && (
              <div>No tickets found</div>
            )}

            {!!tickets?.length && (
              <div className={classes.ticketsContainer}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr 3fr 3fr 3fr 3fr 3fr',
                    alignItems: 'center',
                  }}
                >
                  <div className={classes.cell} />
                  <div className={classes.cell}>Ticket Number</div>
                  <div className={classes.cell}>Status</div>
                  <div className={classes.cell}>Summary</div>
                  <div className={classes.cell}>Company</div>
                  <div className={classes.cell}>Resource</div>
                  <div className={classes.cell}>Create Date</div>
                </div>

                {tickets.map(i => (
                  <div
                    key={i.ticketNumber}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr 3fr 3fr 3fr 3fr 3fr',
                      alignItems: 'center',
                    }}
                  >
                    <div className={classes.cell}>
                      <Radio
                        checked={i.ticketNumber === selectedTicket}
                        onChange={() => {
                          setSelectedTicket(i.ticketNumber);
                        }}
                      />
                    </div>
                    <div
                      className={`${classes.link} ${classes.cell}`}
                      onClick={() => {
                        handleTicketIdClick(i.ticketLink);
                      }}
                      role="presentation"
                    >
                      {i.ticketNumber}
                    </div>
                    <div className={classes.cell}>{i.statusName}</div>
                    <div className={classes.cell}>{i.summary}</div>
                    <div className={classes.cell}>{i.companyName}</div>
                    <div className={classes.cell}>{i.assignedResource}</div>
                    <div className={classes.cell}>
                      {getFormattedDate(i.createdDateTime)}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!crmIdFromRequest && (
              <div>
                <FieldWrapper
                  label="Message"
                  contentSize={12}
                  labelSize={12}
                  content={
                    <Input
                      input={{
                        value: smsText,
                        onChange: e => {
                          setSmsText(e.target.value);
                        },
                      }}
                      meta={{ touched: true, error }}
                      multiline
                      minRows={4}
                      disabled={processing}
                    />
                  }
                />
              </div>
            )}

            {code && (
              <div className={classes.codeConfirm}>
                <span
                  style={{ cursor: 'pointer' }}
                  role="presentation"
                  onClick={() => setCodeConfirmed(!codeConfirmed)}
                >
                  Are you sure you provided the code to your client?
                </span>

                <Checkbox
                  checked={codeConfirmed}
                  onChange={() => setCodeConfirmed(!codeConfirmed)}
                />
              </div>
            )}

            <div className={sharedStyles.dialogActionsContainer}>
              <ReusableButton
                label="Cancel"
                onClick={onClose}
                disabled={processing}
              />
              <ReusableButton
                label="Send"
                viewType="blue"
                onClick={onSubmit}
                disabled={processing || !codeConfirmed}
                loading={processing}
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

VerifyTechDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  verifiedPsaTechBySmsId: PropTypes.number.isRequired,
  code: PropTypes.number,
  contactId: PropTypes.string,
  crmIdFromRequest: PropTypes.string,
  type: PropTypes.string,
};

export default VerifyTechDialog;
