import React, { Fragment, useState } from 'react';
import {
  Confirm,
  useListContext,
  useRefresh,
  useUpdateMany,
} from 'react-admin';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';

import refreshIcon from '@assets/icons/refresh.svg';
import deleteIcon from '@assets/icons/deleteWhite.svg';
import hideIcon from '@assets/icons/hide.svg';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useBulkDelete from '@components/Hooks/useBulkDelete';
import { removeLetters } from '../../../helpers';
import BulkIgnoreModal from './BulkIgnoreModal';

import useStyles from './styles';

const BulkActions = ({ mailBoxId, displayIgnore = true }) => {
  const classes = useStyles();

  const { selectedIds, onUnselectItems } = useListContext();
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bulkIgnoreOpen, setBulkIgnoreOpen] = useState(false);
  const refresh = useRefresh();

  const [updateMany, { loading }] = useUpdateMany(
    'letters',
    selectedIds,
    null,
    {
      onSuccess: () => {
        refresh();
        enqueueSnackbar('Reprocessing scheduled', { variant: 'success' });
        onUnselectItems();
      },
      onFailure: () =>
        enqueueSnackbar('Letters not processed', { variant: 'error' }),
    },
  );

  const onClickDelete = useBulkDelete(removeLetters, setDeleteDialogOpen);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  const handleBulkIgnore = () => onUnselectItems();

  return (
    <Fragment>
      <ReusableButton
        onClick={() => setDeleteDialogOpen(true)}
        viewType="red"
        size="xl"
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          Delete selected
        </>
      </ReusableButton>

      <ReusableButton
        onClick={() => setOpen(true)}
        size="md"
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={refreshIcon} alt="refreshIcon" />
          Re-Parse
        </>
      </ReusableButton>

      {displayIgnore && (
        <ReusableButton
          label="Ignore"
          onClick={() => setBulkIgnoreOpen(true)}
          viewType="orange"
          size="md"
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={hideIcon} alt="hideIcon" />
            Ignore
          </>
        </ReusableButton>
      )}

      <Confirm
        isOpen={open}
        loading={loading}
        title="Reprocess selected emails"
        content="Are you sure you want to reprocess selected emails?"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onClickDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete selected"
        content="Are you sure to remove these items? This action can not be undone."
      />
      <BulkIgnoreModal
        open={bulkIgnoreOpen}
        close={() => setBulkIgnoreOpen(false)}
        onSubmit={handleBulkIgnore}
        selectedIds={selectedIds}
        mailBoxId={+mailBoxId}
      />
    </Fragment>
  );
};

BulkActions.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
  displayIgnore: PropTypes.bool,
};

export default BulkActions;
