import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '50%',
    margin: '15px auto',
    background: 'white',
    padding: '25px',
    borderRadius: '15px',
  },
  alignLeft: {
    textAlign: 'left',
  },
  actions: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
