import { regionsArray } from '@constants/regions';

export const normalizePhoneNumber = ({
  id,
  phoneNumber,
  region,
  isMain,
  countryCode,
}) => ({
  id,
  phoneNumber,
  countryCode,
  region: regionsArray[region],
  role: isMain ? 'Main' : 'Channel',
});

export default {};
