import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: '1.25em',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipFieldWrapper: {
    '& input': {
      paddingRight: '1.5rem',
    },
  },
  spacingLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '100px',
    },
    marginLeft: '10px',
  },
  colorPicker: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  colorSelectorContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  colorBlock: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.750em',
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0 5px ',
    },
    fontWeight: 'bold',
  },
  exampleBlock: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '30px',
    },
    borderRadius: '5px',
    padding: '3px 14px',
    fontWeight: 'bold',
  },
  horizontalCentered: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDown: {
    cursor: 'pointer',
    height: '37px',
    position: 'absolute',
    color: '#333333',
    right: '2px',
  },
  modalButtonContainer: {
    textAlign: 'end',
    marginTop: '1.25em',
  },
  modalCancelButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 5px',
    },
  },
  deleteIcon: {
    margin: '0 5px',
    cursor: 'pointer',
    color: '#333333',
  },
  dataSelectorWrapper: {
    width: '100%',
    position: 'relative',
    marginTop: '1em',
  },
  chipsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: '10px 0',
  },
  plusIcon: {
    fill: theme.palette.info.main,
    marginRight: '0.5rem',
  },
  buttonAddWrapper: {
    marginTop: '0.875rem',
  },
  buttonWrapper: {
    width: '95px',
    height: '35px',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '13px 10px 15px',
  },
  paperDefaultWidth: {
    [theme.breakpoints.up('lg')]: {
      width: '390px',
    },
  },
  containerPaper: {
    [theme.breakpoints.up('lg')]: {
      margin: '25px auto',
    },
    height: 'min-content',
    borderRadius: '13px',
    boxShadow: '0 4px 24px 0 #A8B5D840',
    border: '1px solid #EBEBEB',
    margin: '25px 20px',
    padding: '0 5px',
  },
  typeListTitle: {
    textAlign: 'left',
    margin: '20px 10px 15px',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  typeValueListTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '18px 10px 15px',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  containerTypeItem: {
    display: 'flex',
    minHeight: '25px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    margin: '10px',
    paddingTop: '6px',
    paddingLeft: '5px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.textColor,
    width: '87%',
  },
  icons: {
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    },
    textAlign: 'end',
  },

  containerTypeValueItem: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    minHeight: '25px',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    margin: '10px',
    padding: '2px 6px',
  },
  textValueItem: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: '87%',
    },
    alignItems: 'center',
  },
  name: {
    overflow: 'hidden',
    padding: '0.3125rem 1rem',
    borderRadius: '2px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  dataSpan: {
    margin: '0 2px',
    backgroundColor: theme.palette.customTypes.values.background,
    border: '1px solid #E5E5E5',
    padding: '6px',
    borderRadius: '5px',
  },
  tagContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
    display: 'flex',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
  editNameIcon: {
    margin: '0 5px',
    color: '#0e96e2 !important',
  },
  colorPicketInput: {
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& div > input': {
      width: '35px',
      height: '35px',
    },
  },
  typesListContainer: {
    padding: '20px 10px',
  },
  typesListHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typesListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px',
    padding: '0px 10px',
    height: '50px',
    borderRadius: '5px',
    border: '1px solid #b4b4b4',
    background: '#dbdbdb2b',
  },
  typeListItemActions: {
    display: 'flex',
    alignItems: 'center',
  },
  typeListItemIcons: {
    '& > button': {
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
  },
  viewIcon: {
    '& > path': {
      stroke: 'green',
    },
  },
  typeValuesListContainer: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  typeValuesItemContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 7fr 1fr',
    border: '1px solid #ded9d9',
    margin: '5px',
    borderRadius: '5px',
    padding: '5px 10px',
  },
  circleColor: {
    width: '24px',
    height: '24px',
    borderRadius: '50px',
    marginRight: '5px',
    background: props => props.circleBgColor,
    border: props => `1px solid ${props.circleBorderColor}`,
  },
  circleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  typeValuesItemName: {
    padding: '5px 10px',
    borderRadius: '10px',
    background: props => props.backgroundColor || '#fff',
    color: props => props.fontColor || '#000',
  },
  typeValuesItemActions: {
    marginLeft: 'auto',
    '& > svg': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
  },
  typeValuesItemValues: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > div': {
      margin: '5px 5px',
      padding: '5px 10px',
      borderRadius: '10px',
      background: '#e4e4e4',
      color: '#000',
    },
  },
  typeValuesLabelsContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '190px',
  },
  typeValuesListHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: '18px',
      marginLeft: '5px',
    },
    '& > svg': {
      marginLeft: '10px',
      cursor: 'pointer',
    },
    '& > button': {
      marginLeft: 'auto',
      marginRight: '5px',
      '& > span': {
        '& > svg': {
          marginLeft: '5px',
          '& > path': {
            stroke: '#000',
          },
        },
      },
    },
  },
}));

export default useStyles;
