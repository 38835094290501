import { createAsyncThunk } from '@reduxjs/toolkit';
import { FILE_UPLOAD } from '@store/slices/resources';
import appConfig from '@configs/appConfig';
import { AUTOTASK_FILE_UPLOAD } from '@services/requests/requestResources';
import { sendFormData } from '@services/api';

export const ThunkUploadAutotaskFile = createAsyncThunk(
  `${FILE_UPLOAD}/uploadAutotaskFile`,
  async ({ psaId, configurationItemsId, formData }) => {
    sendFormData(
      `${appConfig.baseUrl}${AUTOTASK_FILE_UPLOAD}/${psaId}/upload?configItemId=${configurationItemsId}`,
      formData,
    );
  },
);

export default {};
