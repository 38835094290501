import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ButtonGroup = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.buttonGroup}>{children}</div>;
};

ButtonGroup.propTypes = {
  children: PropTypes.node,
};

export default ButtonGroup;
