import { makeRequest } from '../makeRequest';
import {
  MESSAGING_CONFIGURATIONS,
  MESSAGING_BOARD,
  MESSAGING_TEMPLATES,
  TRIAGE,
  SEND_SMS,
  SMS_CONVERSATIONS,
  LIVE_CHAT_CONVERSATIONS,
  SMS_BLOCKED,
  MESSAGING_CONFIGURATIONS_DEFAULT_VALUES,
} from '../requestResources';

export const getMessagingConfigurations = () =>
  makeRequest('GET', `${MESSAGING_CONFIGURATIONS}`);

export const getMessagingConfiguration = id =>
  makeRequest('GET', `${MESSAGING_CONFIGURATIONS}/${id}`);

export const getMessagingConfigurationDefaultValues = () =>
  makeRequest('GET', `${MESSAGING_CONFIGURATIONS_DEFAULT_VALUES}`);

export const addMessagingConfiguration = payload =>
  makeRequest('POST', `${MESSAGING_CONFIGURATIONS}`, payload);

export const updateMessagingConfiguration = (id, payload) =>
  makeRequest('PUT', `${MESSAGING_CONFIGURATIONS}/${id}`, payload);

export const deleteMessagingConfiguration = id =>
  makeRequest('DELETE', `${MESSAGING_CONFIGURATIONS}/${id}`);

export const getMessagingConfigurationsAutocomplete = () =>
  makeRequest('GET', `${MESSAGING_BOARD}/configurationsAutocomplete`);

export const getMessagingChannels = configurationId =>
  makeRequest('GET', `${MESSAGING_BOARD}/config/${configurationId}/channels`);

export const getMessagingChannel = channelId =>
  makeRequest('GET', `${MESSAGING_BOARD}/channels/${channelId}`);

export const getChannelHistory = (channelId, userOnly, orderDesc) =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/channels/${channelId}/channelHistory?userOnly=${userOnly}&orderDesc=${orderDesc}`,
  );

export const sendChannelMessage = (channelId, payload) =>
  makeRequest('POST', `${MESSAGING_BOARD}/channels/${channelId}/send`, payload);

export const updateChannelUserName = (channelId, payload) =>
  makeRequest(
    'PUT',
    `${MESSAGING_BOARD}/channels/${channelId}/updateUserName`,
    { userName: payload },
  );

export const getChannelLogs = (configurationId, channelId = null) =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/config/${configurationId}/logs${
      channelId ? `?channelId=${channelId}` : ''
    }`,
  );

export const closeChannel = (channelIds, payload) =>
  makeRequest(
    'POST',
    `${MESSAGING_BOARD}/channels/close?${channelIds
      .map(i => `channelId=${i}&`)
      .join('')}`,
    payload,
  );

export const getMessagingTemplates = () =>
  makeRequest('GET', `${MESSAGING_TEMPLATES}`);

export const addMessagingTemplate = payload =>
  makeRequest('Post', `${MESSAGING_TEMPLATES}`, payload);

export const deleteMessagingTemplate = id =>
  makeRequest('DELETE', `${MESSAGING_TEMPLATES}/${id}`);

export const updateMessagingTemplate = (id, payload) =>
  makeRequest('PUT', `${MESSAGING_TEMPLATES}/${id}`, payload);

export const getResourceAssignedTemplates = () =>
  makeRequest('GET', `${MESSAGING_TEMPLATES}/resourceAssignedTemplate`);

export const getResourceAssignedTemplateOptions = () =>
  makeRequest(
    'GET',
    `${MESSAGING_TEMPLATES}/resourceAssignedTemplate/templates`,
  );

export const editResourceAssignedTemplates = payload =>
  makeRequest(
    'Post',
    `${MESSAGING_TEMPLATES}/resourceAssignedTemplate`,
    payload,
  );

export const getPsaContactsAutocomplete = (configId, companyId) =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/config/${configId}/psaContacts?companyId=${companyId}`,
  );

export const getPsaResources = channelId =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/channels/${channelId}/getPsaResources`,
  );

export const getPsaCompaniesAutocomplete = id =>
  makeRequest('GET', `${MESSAGING_BOARD}/config/${id}/psaCompanies`);

export const addMessagingChannel = (configId, payload) =>
  makeRequest(
    'POST',
    `${MESSAGING_BOARD}/config/${configId}/createChannel`,
    payload,
  );

export const getTemplateVariables = () =>
  makeRequest('GET', `${MESSAGING_BOARD}/getTemplateVariables`);

export const cloneMessagingTemplate = templateId =>
  makeRequest('POST', `${MESSAGING_TEMPLATES}/clone/${templateId}`);

export const getMessagingChannelCrmInfo = id =>
  makeRequest('GET', `${MESSAGING_BOARD}/channels/${id}/getCrmSpecificInfo`);

export const getMessagingConfigurationsByCrmType = crmType =>
  makeRequest('GET', `${TRIAGE}/getMessagingConfigurations?crmType=${crmType}`);
export default {};

export const sendSms = payload => makeRequest('POST', `${SEND_SMS}`, payload);

export const getPhoneNumbers = () => makeRequest('GET', `${SEND_SMS}/phones`);

export const addConversationTemplate = payload =>
  makeRequest('POST', `${SMS_CONVERSATIONS}`, payload);

export const deleteConversationTemplate = id =>
  makeRequest('DELETE', `${SMS_CONVERSATIONS}/${id}`);

export const editConversationTemplate = (id, payload) =>
  makeRequest('PUT', `${SMS_CONVERSATIONS}/${id}`, payload);

export const addLiveChatConversationTemplate = payload =>
  makeRequest('POST', `${LIVE_CHAT_CONVERSATIONS}`, payload);

export const deleteLiveChatConversationTemplate = id =>
  makeRequest('DELETE', `${LIVE_CHAT_CONVERSATIONS}/${id}`);

export const editLiveChatConversationTemplate = (id, payload) =>
  makeRequest('PUT', `${LIVE_CHAT_CONVERSATIONS}/${id}`, payload);

export const checkIfTicketHasChannel = (ticketId, crmId) =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/checkTicketChannels?ticketId=${ticketId}&crmId=${crmId}`,
  );

export const updateBlockedMessage = payload =>
  makeRequest('PUT', `/${SMS_BLOCKED}/${payload.id}`, payload);

export const reprocessBlockedMessage = id =>
  makeRequest('POST', `/${SMS_BLOCKED}/reprocess/${id}`);

export const createContactFromBlockedSms = (blockedMessageId, payload) =>
  makeRequest(
    'POST',
    `/${SMS_BLOCKED}/${blockedMessageId}/create-contact`,
    payload,
  );
