import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    margin: '20px',
    paddingTop: '50px',
  },
  element: {
    margin: '20px',
  },
  baseFont: {
    fontStyle: 'normal',
  },
  header: {
    color: '#FF6E4F',
    fontSize: '3em',
  },
  subHeader: {
    color: '#393B3E',
    fontSize: '0.875em',
  },
  subHeader2: {
    color: '#393B3E',
    fontSize: '1.5em',
  },
  domain: {
    color: '#A6A6A6',
    fontSize: '22pt',
  },
  button: {
    backgroundColor: '#3A3E45',
    borderRadius: '3px',
    fontStyle: 'normal',
    width: '100%',
  },
  fieldWrapper: {
    width: '333px',
    backgroundColor: '#fff',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
  },
  buttonContainerText: {
    width: '100%',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'capitalize',
  },
  buttonContainerIcon: {
    color: '#fff',
  },
  elementSpace: {
    marginTop: '50px',
  },
}));

export default useStyles;
