import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  createMessage: {
    maxWidth: '70%',
    margin: '10px auto',
    padding: '30px',
    background: 'white',
    borderRadius: '15px',
  },
  createMessageButtons: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  recipients: {
    padding: '30px',
    boxSizing: 'border-box',
    maxWidth: '70%',
    background: 'white',
    margin: '10px auto',
    height: '80vh',
    display: 'grid',
    gridTemplateRows: '100px 1fr 40px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  header: {
    fontWeight: 800,
    fontSize: 'large',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    gap: '10px',
  },
  overflow: {
    overflowY: 'auto',
  },
  headerExtended: {
    fontWeight: 800,
    fontSize: 'large',
    margin: '10px 0px',
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  bold: {
    fontWeight: '800',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
