import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    padding: '20px',
    background: 'white',
    margin: '20px',
    boxSizing: 'border-box',
    borderRadius: '15px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepsHolderList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '10px',
  },
  stepsHolderItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px',
    border: '1px solid #0000002b',
    borderRadius: '10px',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
