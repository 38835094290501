import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';

import UnrecognizedTypes from '@ui/components/UnrecognizedTypes/UnrecognizedTypes';
import ReusableButton from '../Button/Button';
import useStyles from './styles';

const TestBox = ({
  open,
  onClose,
  data,
  loading,
  displayParsedData = true,
}) => {
  const classes = useStyles();
  const [openUnrecognizedValueDialog, setUnrecognizedValueDialog] = useState(
    false,
  );
  const content =
    loading || !data ? (
      <div>Loading...</div>
    ) : (
      <div>
        <span>
          Is recognized:
          {data.isTypeRecognized ? ' true' : ' false'}
        </span>
        <br />
        {data && !data.isTypeRecognized && (
          <ul className={classes.identifierErrors}>
            {data.identifierErrors &&
              data.identifierErrors.map(e => (
                <li key={e}>
                  <pre>{e}</pre>
                </li>
              ))}
          </ul>
        )}
        {displayParsedData && (
          <>
            <span>Parsed Data:</span>
            <br />
            <pre>{data.parsedData}</pre>
          </>
        )}
        <br />
        {data.results && data.results.length > 0 ? (
          <span>
            <span>Parsed Results:</span>
            <span>
              {data.results.map(value => (
                <pre key={value}>{value}</pre>
              ))}
            </span>
          </span>
        ) : null}
        {data.errors && data.errors.length > 0 ? (
          <>
            <span>
              <span>Errors:</span>
              <span>
                {data.errors.map(value => (
                  <pre key={value}>{value}</pre>
                ))}
              </span>
            </span>
          </>
        ) : null}

        {data.unrecognizedValues && data.unrecognizedValues.length > 0 && (
          <ReusableButton
            label="Resolve"
            onClick={() => {
              setUnrecognizedValueDialog(true);
            }}
          />
        )}
      </div>
    );
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Results of parsing</DialogTitle>
        <div className={classes.testBox}>{content}</div>
      </Dialog>
      {openUnrecognizedValueDialog && (
        <Dialog
          open={openUnrecognizedValueDialog}
          onClose={() => {
            setUnrecognizedValueDialog(false);
          }}
          size="lg"
          fullWidth
          aria-labelledby="simple-dialog-title"
        >
          <UnrecognizedTypes
            match={{ params: { mailBoxId: 'unset' } }}
            showButtons={false}
            unrecognizedValues={data.unrecognizedValues}
            onClose={() => {
              setUnrecognizedValueDialog(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
};

TestBox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  displayParsedData: PropTypes.bool,
};

export default TestBox;
