import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CONVERSATIONS } from '@store/slices/resources';
import { getTemplateVariables } from '@services/requests/smsConversations';

export const ThunkGetConversationTemplateVariables = createAsyncThunk(
  `${SMS_CONVERSATIONS}/getConversationTemplatesVariables`,
  async () => {
    const variables = await getTemplateVariables();
    return variables;
  },
);

export default {};
