import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '@components/Ticketing/Notifications/TicketSettings/components/styles';
import { useSelector } from 'react-redux';
import { notificationDataViewSelector } from '@store/selectors';
import { ftpSettings } from '@store/selectors/notifications';
import Loading from '@common/Loading/Loading';
import {
  getFtpConnections,
  getFtpSettings,
  postFtpSettings,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import useDisabled from '@components/Hooks/useDisabled';
import { basePermissions } from '@constants/permissions';
import { actions } from '@store/actions';
import Paper from '@common/Paper/Paper';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import useAsync from '@services/api/common/useAsync';
import DropDown from '@components/Auth/Common/DropDown';
import RadioInput from '@components/RadioInput/RadioInput';
import outputDataFormats from '@constants/outputDataFormats';
import {
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import useTicketSetting from '../../hooks/useTicketSetting';
import { handleDoWChange } from '../../utils';

const FtpSettings = ({ match }) => {
  const classes = useStyles();

  const dataViewId = useSelector(notificationDataViewSelector);
  const storedData = useSelector(ftpSettings);

  const [ftpOption, setFtpOptions] = useState([]);
  useAsync(getFtpConnections, setFtpOptions);

  const { submit, loading, handleBack, setData, data } = useTicketSetting({
    storedData,
    dataViewId,
    paramsId: match.params.id,
    tileKey: 'isFtp',
    action: actions.saveFtpSetting,
    apiPostRequest: postFtpSettings,
    apiGetSetting: getFtpSettings,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const validate = values => ({
    ftpConnectionId: !values.ftpConnectionId ? 'Required' : undefined,
    dataFormat: !values.dataFormat ? 'Required' : undefined,
  });

  const dataLoaded = typeof data.id !== 'undefined';

  const onDoWChange = handleDoWChange(setData);

  const choices = Object.values(outputDataFormats).map(i => {
    return { choice: i };
  });

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={data}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.contentFormWrapper}>
              <FieldWrapper
                label="FTP Connection"
                labelSize={3}
                content={
                  <Field
                    id="ftpConnectionId"
                    name="ftpConnectionId"
                    component={DropDown}
                    options={ftpOption}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Output Format"
                labelSize={3}
                content={
                  <Field
                    id="dataFormat"
                    name="dataFormat"
                    component={RadioInput}
                    choices={choices}
                    options={ftpOption}
                    disabled={disabled}
                  />
                }
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

FtpSettings.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default FtpSettings;
