import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import postData from '@services/api/common/postData';
import { tenantPalette } from '@constants/appRoutes';

export const getTenantPalettes = () =>
  getData(`${appConfig.baseUrl}${tenantPalette}`);

export const postTenantPalettes = palette =>
  postData(`${appConfig.baseUrl}${tenantPalette}`, palette);
