import moment from 'moment';
import forEach from 'lodash/forEach';

export const chartStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

export const pieConfig = theme => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  tooltip: { style: { padding: '10px', fontWeight: 'bold' } },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      center: ['50%', '50%'],
      size: '100%',
      cursor: 'pointer',
      depth: 10,
      dataLabels: {
        enabled: true,
      },
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
    style: {
      fontSize: '16px',
    },
  },
  yAxis: { title: { text: '' } },
  series: [
    {
      name: 'Total',
      data: [],
      size: '100%',
      dataLabels: {
        distance: -30,
        color: theme.palette.text.primary,
        ...(theme.palette.type === 'light'
          ? {
              style: {
                textOutline: '1',
              },
            }
          : {
              style: {
                textOutline: '0',
              },
            }),
      },
      type: 'pie',
    },
  ],
});

export const barConfig = theme => ({
  chart: {
    type: 'bar',
    backgroundColor: '#FFFFFF00',
  },
  title: {
    text: '',
    style: {
      fontSize: '16px',
      color: theme.palette.text.primary,
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'bottom',
    itemStyle: {
      color: theme.palette.text.primary,
    },
    navigation: {
      inactiveColor: theme.palette.type === 'light' ? '#0000001f' : '#ffffff1f',
      activeColor: theme.palette.primary.main,
      style: {
        color: theme.palette.text.primary,
      },
    },
  },
  credits: { enabled: false },
  xAxis: {
    categories: [],
    labels: {
      style: { color: theme.palette.text.secondary },
    },
    title: {
      text: null,
    },
    visible: false,
  },
  yAxis: {
    title: {
      text: 'Total',
      style: { color: theme.palette.text.secondary },
    },
    allowDecimals: false,
    min: 0,
    labels: {
      style: { color: theme.palette.text.secondary },
    },
  },
  tooltip: {},
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    bar: {
      dataLabels: {
        enabled: true,
        format: '{y}',
      },
    },
  },
  series: [],
});

export const columnConfig = theme => ({
  chart: {
    type: 'column',
    backgroundColor: '#FFFFFF00',
  },
  title: {
    text: '',
    style: {
      fontSize: '16px',
      color: theme.palette.text.primary,
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'bottom',
    itemStyle: {
      color: theme.palette.text.primary,
    },
    navigation: {
      inactiveColor: theme.palette.type === 'light' ? '#0000001f' : '#ffffff1f',
      activeColor: theme.palette.primary.main,
      style: {
        color: theme.palette.text.primary,
      },
    },
  },
  credits: { enabled: false },
  xAxis: {
    categories: [],
    labels: {
      style: { color: theme.palette.text.secondary },
    },
    title: {
      text: null,
    },
    visible: false,
  },
  yAxis: {
    title: {
      text: 'Total',
      style: { color: theme.palette.text.secondary },
    },
    allowDecimals: false,
    min: 0,
    labels: {
      style: { color: theme.palette.text.secondary },
    },
  },
  tooltip: {},
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{y}',
      },
    },
    bar: {
      stacking: 'normal',
    },
  },
  series: [],
});

export const histogramConfig = theme => ({
  chart: {
    type: 'areaspline',
    backgroundColor: '#FFFFFF00',
    marginBottom: 55,
    renderTo: 'container',
  },
  legend: {
    enabled: false,
  },
  credits: { enabled: false },
  plotOptions: {
    column: {
      groupPadding: 0,
      pointPadding: 0,
      borderWidth: 0,
    },
    areaspline: {
      fillOpacity: 0.8,
      cursor: 'pointer',
    },
  },
  title: { text: '' },
  xAxis: {
    type: 'datetime',
    labels: {
      style: { color: theme.palette.text.secondary },
      useHTML: true,
      formatter() {
        return moment(this.value).format('DD.MMM');
      },
    },
  },
  yAxis: {
    title: {
      text: 'Total',
      style: { color: theme.palette.text.secondary },
    },
    labels: {
      style: { color: theme.palette.text.secondary },
    },
  },
  tooltip: {
    shared: true,
    valueSuffix: ' jobs',
    style: { padding: '10px', fontWeight: 'bold' },
    formatter() {
      const date = moment(this.x);
      let format = date.format('dddd, MMM DD, YYYY');

      forEach(this.points, value => {
        // @ts-ignore
        format += `<br/><span style="color:${value.series.color}">\u25CF</span> Total: ${value.y}`;
      });
      return format;
    },
  },
  series: [],
});
