import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '0.9rem',
  },
  iconButton: {
    height: '20px',
    transition: '0.3s',
    marginLeft: '0.3rem',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
}));

export default useStyles;
