import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  integrationBox: {
    marginTop: '1rem',
  },
  marginLeft: {
    marginLeft: '0.5rem',
  },
}));

export default useStyles;
