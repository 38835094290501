import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import iconPlus from '@assets/icons/plusGrey.svg';
import useStyles from './styles';

const HtmlInput = ({
  name,
  form,
  disabled = false,
  templateVariables = [],
  overrideValue = false,
  reactQuillClassName,
}) => {
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = useState();

  const quillRef = useRef(null);

  const classes = useStyles();

  useEffect(() => {
    const formValue = form.getFieldState(name)?.value ?? '';
    setValue(formValue);
  }, []);

  useEffect(() => {
    form.change(name, value);
  }, [value]);

  useEffect(() => {
    form.getFieldState(name).value !== value &&
      setValue(form.getFieldState(name).value);
  }, [form]);

  return (
    <Grid container className={classes.container}>
      {!!templateVariables.length && (
        <ActionButton
          handler={event => setAnchorEl(event.currentTarget)}
          icon={<img src={iconPlus} alt="iconPlus" />}
          toolTip="Add template variable"
          disabled={disabled}
        />
      )}
      <Field name={name} id={name} component={() => <></>} />
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={value}
        onChange={setValue}
        className={reactQuillClassName}
      />
      {!!templateVariables.length && (
        <TemplateMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          quill={quillRef?.current?.getEditor()}
          form={form}
          name={name}
          templateVariables={templateVariables}
          overrideValue={overrideValue}
        />
      )}
    </Grid>
  );
};

export default HtmlInput;
