import files from './files';
import user from './user';
import theme from './theme';
import list from './lists';
import notifications from './notifications';
import parsers from './parsers';
import pricePlan from './pricePlan';
import crm from './crmIntegration';
import signalR from './signalR';
import selectedNetworks from './selectedNetworks';
import dashboard from './dashboard';
import smsList from './smsList';
import crmUsers from './crmUsers';
import pusher from './pusher';

export default {
  files,
  user,
  theme,
  list,
  notifications,
  parsers,
  pricePlan,
  crm,
  signalR,
  selectedNetworks,
  dashboard,
  smsList,
  crmUsers,
  pusher,
};
