import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import BagelChart from '@common/Bagels/BagelChart';

import useStyles from './styles';

const BagelsContainer = ({ data, field, setStatusFilter, items }) => {
  const classes = useStyles();
  const [stat, setStat] = useState(items);

  useMemo(() => {
    const total = data.length;
    Object.keys(items).forEach(
      // eslint-disable-next-line no-return-assign
      i =>
        // eslint-disable-next-line no-param-reassign
        (items[i] = {
          ...items[i],
          total,
          count: data.filter(d => d[field] === `${i}`).length,
        }),
    );

    setStat(items);
  }, [data]);

  return (
    <div className={classes.bagelContainer}>
      <Grid container className={classes.bagelGridContainer}>
        {Object.values(stat).map(item => (
          <Grid
            key={item.title}
            className={classes.bagelGrid}
            item
            onClick={() => setStatusFilter(item.title)}
          >
            <BagelChart
              allCount={item.total}
              title={item.title}
              color={item.color}
              circleColor="rgb(195 195 195)"
              textColor="black"
              count={item.count}
              useLineSeparator={false}
              customHandler={() => null}
            />
          </Grid>
        ))}
      </Grid>
      <Tooltip title="Clear filter">
        <IconButton
          className={classes.filterClearButton}
          onClick={() => setStatusFilter()}
        >
          <Clear />
        </IconButton>
      </Tooltip>
    </div>
  );
};

BagelsContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  field: PropTypes.string.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  items: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BagelsContainer;
