import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AlertHookForm from '@components/DataSources/AlertForm/AlertHookForm';
import { convertArrayToObject } from '@components/DataSources/helpers';
import marakiAlertFormInitial from '../../AlertForm/formInitial';

const FormWrapper = ({ onSubmit, onCancel, data }) => {
  const [currentId, setCurrentId] = useState(null);
  const [hooks, setHooks] = useState([]);
  const [emails, setEmails] = useState([]);
  const [selectedHooks, setSelectedHooks] = useState([]);
  const [formInit, setFormInit] = useState(marakiAlertFormInitial);
  const [formValues, setFormValues] = useState({});
  const [alerts, setAlerts] = useState({});

  useEffect(() => {
    if (data) {
      setCurrentId(data.id);
      setEmails(data.emails);
      setFormInit({
        snmp: data.snmp,
        allAdmins: data.allAdmins,
        emailsTemplateBehaviour: data.emailsTemplateBehaviour,
        hooksTemplateBehaviour: data.hooksTemplateBehaviour,
        lastApplyDateTime: data.lastApplyDateTime,
      });
      const newHooks = data.hookEntities.map((i, idx) => ({
        id: idx,
        ...i,
      }));
      setHooks(newHooks);
      setSelectedHooks(data.httpServerIds);
      const alertsObject = data.alerts
        ? convertArrayToObject(data.alerts, 'type')
        : {};
      setAlerts(alertsObject);
    }
  }, [data]);

  const onHookDelete = id => {
    const newHooks = [...hooks];
    setHooks(newHooks.filter(i => i.id !== id));
  };

  const onHooksSubmit = value => {
    const newHooks = [...hooks];
    newHooks.push(value);
    setHooks(newHooks.map((i, k) => ({ ...i, id: k })));
  };

  const onFormSubmit = () => {
    const payload = {
      emails,
      emailsTemplateBehaviour: formValues.emailsTemplateBehaviour,
      hooksTemplateBehaviour: formValues.hooksTemplateBehaviour,
      snmp: formValues.snmp,
      allAdmins: formValues.allAdmins,
      httpServerIds: selectedHooks,
      hookEntities: hooks,
      alerts: Object.keys(alerts).map(key => alerts[key]),
      lastApplyDateTime: formValues.lastApplyDateTime,
    };
    onSubmit(payload, currentId);
  };

  return (
    <>
      <AlertHookForm
        hooks={hooks}
        onSubmit={onFormSubmit}
        emails={emails}
        formInit={formInit}
        onCancel={onCancel}
        onHookDelete={onHookDelete}
        onHooksSubmit={onHooksSubmit}
        selectedHooks={selectedHooks}
        setEmails={setEmails}
        setFormValues={setFormValues}
        setSelectedHooks={setSelectedHooks}
        alerts={alerts}
        setAlerts={setAlerts}
      />
    </>
  );
};

FormWrapper.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
};

export default FormWrapper;
