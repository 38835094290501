import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalDefaultsAutocomplete } from '@services/requests/clientPortal';

export const ThunkGetCPDefaultsAutocomplete = createAsyncThunk(
  `${CLIENT_PORTALS}/getCPDefaultsAutocomplete`,
  () => getClientPortalDefaultsAutocomplete(),
);

export default {};
