import React from 'react';
import PropTypes from 'prop-types';
import TabPanel from '@common/TabPanel/TabPanel';
import DmSettings from '@components/Ticketing/Notifications/DmSettings/DmSettings';
import useSearch from '@components/Ticketing/Notifications/hooks/useSearch';
import NotificationSettings from './TicketSettings/NotificationSettings';
import useStyles from './styles';

const Notifications = props => {
  const { match } = props;
  const classes = useStyles();

  const notificationSettingsIdFromRoute = useSearch();

  const tabsData = [
    {
      label: 'Data Notifications',
      id: 'data',
      value: <NotificationSettings {...props} />,
    },
    {
      label: 'Uptime Monitor Notifications',
      id: 'monitor',
      value: <DmSettings id={notificationSettingsIdFromRoute} />,
    },
  ];
  return (
    <TabPanel
      classNameWrapperTabs={classes.tabsWrapper}
      match={match}
      tabs={tabsData}
      viewTypeLink="flooded"
      withRedirect={false}
    />
  );
};

Notifications.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Notifications;
