import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateChannelUserName } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkUpdateChannelUserName = createAsyncThunk(
  `${MESSAGING}/updateChannelUserName`,
  async ({ channelId, payload }) => {
    await updateChannelUserName(channelId, payload);
    return payload;
  },
);

export default {};
