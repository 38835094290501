import { createAsyncThunk } from '@reduxjs/toolkit';
import { reprocessBlockedMessage } from '@services/requests/messaging';
import { SMS_BLOCKED } from '@store/slices/resources';

export const ThunkReprocessBlockedMessage = createAsyncThunk(
  `${SMS_BLOCKED}/reprocessBlockedMessage`,
  async id => {
    const template = await reprocessBlockedMessage(id);
    return template;
  },
);

export default {};
