import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listContainer: {
    marginTop: '1rem',
  },
  dmSettingsContainer: {
    margin: '1rem 16px 0',
    '& tbody': {
      maxHeight: '65vh',
    },
  },
}));

export default useStyles;
