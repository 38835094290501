import { createAsyncThunk } from '@reduxjs/toolkit';
import { getATTicketTimeEntries } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetATTicketTimeEntries = createAsyncThunk(
  `${CRM_TICKET}/getATTicketTimeEntries`,
  async ({ crmId, ticketId }) => {
    const timeEntries = await getATTicketTimeEntries(crmId, ticketId);
    return timeEntries;
  },
);

export default {};
