import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { notificationSettings } from '@store/selectors';
import { actions } from '@store/actions';
import { useHistory } from 'react-router-dom';

const useTicketSetting = ({
  paramsId,
  action,
  tileKey,
  apiPostRequest,
  apiGetSetting,
  storedData,
  additionalHandlers = [],
  preventOnGettingSetting = false,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const inMemorySetting = useSelector(notificationSettings);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isCreate = paramsId === '0';
  useEffect(() => {
    if (!preventOnGettingSetting) {
      apiGetSetting(paramsId)
        .then(serverData => {
          if (isCreate && storedData && Object.keys(storedData).length !== 0) {
            setData(storedData);
          } else {
            setData(serverData);
          }
        })
        .catch(() =>
          enqueueSnackbar('Unable to fetch defaults options', {
            variant: 'error',
          }),
        );
    }
  }, [
    isCreate,
    paramsId,
    preventOnGettingSetting,
    storedData,
    apiGetSetting,
    enqueueSnackbar,
  ]);

  const handleBack = () => history.goBack();

  const saveSetting = () => {
    if (!inMemorySetting[tileKey]) {
      dispatch(
        actions.saveSetting({
          ...inMemorySetting,
          [tileKey]: true,
        }),
      );
    }
  };

  const submit = async values => {
    if (isCreate) {
      dispatch(action(values));
      saveSetting();
      handleBack();
      return;
    }

    const result = additionalHandlers.reduce(
      (acc, handler) => handler(data),
      {},
    );

    try {
      const payload = {
        ...values,
        ...result,
      };
      setLoading(true);
      if (!(await apiPostRequest(paramsId, payload))) {
        setLoading(false);
        enqueueSnackbar('Unable to update ticket settings', {
          variant: 'error',
        });
        return;
      }
      setLoading(false);
      enqueueSnackbar('Record updated', { variant: 'success' });
      saveSetting();
      handleBack();
    } catch (e) {
      console.error(e.message);
    }
  };

  return {
    submit,
    loading,
    setLoading,
    handleBack,
    anchorEl,
    setAnchorEl,
    data,
    setData,
  };
};

export default useTicketSetting;
