import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, TableBody } from '@material-ui/core';

import AccordionWrapper from '@components/UploadFileDialog/AccordionWrapper';
import DetailsTableHeader from './DetailsTableHeader';
import DetailsTableItem from './DetailsTableItem';

const DetailsDialogItem = ({ row }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="center">{row.status}</TableCell>
      <TableCell align="center">{row.obj.company}</TableCell>
      <TableCell align="center">{row.obj.deviceName}</TableCell>
      <TableCell align="center">{row.obj.emailRecipient}</TableCell>
      <TableCell align="center">{row.obj.emailSubject}</TableCell>
      <TableCell align="center">{row.obj.type}</TableCell>
      <TableCell align="center">{row.obj.lastN}</TableCell>
      <TableCell align="center">{row.obj.value}</TableCell>
      <TableCell align="center">{row.obj.errors?.join(', ')}</TableCell>
      <TableCell align="center">
        <AccordionWrapper summary="Details">
          <DetailsTableHeader contentFirstCell="Enabled" />
          <TableBody>
            <DetailsTableItem contentFirstCell={row.obj.isEmail} />
          </TableBody>
        </AccordionWrapper>
      </TableCell>
      <TableCell align="center">
        <AccordionWrapper summary="Details">
          <DetailsTableHeader
            contentFirstCell="Enabled"
            contentSecondCell="Url"
          />
          <TableBody>
            <DetailsTableItem
              contentFirstCell={row.obj.isEmail}
              contentSecondCell={row.obj.webhookUrl}
            />
          </TableBody>
        </AccordionWrapper>
      </TableCell>
      <TableCell align="center">
        <AccordionWrapper summary="Details">
          <DetailsTableHeader
            contentFirstCell="Enabled"
            contentSecondCell="Template"
            contentThirdCell="Recover Template"
            contentFourthCell="Url"
          />
          <TableBody>
            <DetailsTableItem
              contentFirstCell={row.obj.isSlack}
              contentSecondCell={row.obj.slackTemplate}
              contentThirdCell={row.obj.slackRecoverTemplate}
              contentFourthCell={row.obj.slackUrl}
            />
          </TableBody>
        </AccordionWrapper>
      </TableCell>
      <TableCell align="center">
        <AccordionWrapper summary="Details">
          <DetailsTableHeader
            contentFirstCell="Enabled"
            contentSecondCell="Auto Close"
            contentThirdCell="PSA Default Name"
            contentFourthCell="Subject"
          />
          <TableBody>
            <DetailsTableItem
              contentFirstCell={row.obj.isSlack}
              contentSecondCell={row.obj.slackTemplate}
              contentThirdCell={row.obj.slackRecoverTemplate}
              contentFourthCell={row.obj.slackUrl}
            />
          </TableBody>
        </AccordionWrapper>
      </TableCell>
      <TableCell align="center">
        <AccordionWrapper summary="Details">
          <DetailsTableHeader
            contentFirstCell="Enabled"
            contentSecondCell="Recipient"
            contentThirdCell="Recover Template"
            contentFourthCell="Template"
          />
          <TableBody>
            <DetailsTableItem
              contentFirstCell={row.obj.isSms}
              contentSecondCell={row.obj.smsRecipient}
              contentThirdCell={row.obj.smsRecoverTemplate}
              contentFourthCell={row.obj.smsTemplate}
            />
          </TableBody>
        </AccordionWrapper>
      </TableCell>
      <TableCell align="center">
        <AccordionWrapper summary="Details">
          <DetailsTableHeader
            contentFirstCell="Enabled"
            contentSecondCell="Template"
            contentThirdCell="Recover Template"
            contentFourthCell="Url"
          />
          <TableBody>
            <DetailsTableItem
              contentFirstCell={row.obj.isTeams}
              contentSecondCell={row.obj.teamsTemplate}
              contentThirdCell={row.obj.teamsRecoverTemplate}
              contentFourthCell={row.obj.teamsUrl}
            />
          </TableBody>
        </AccordionWrapper>
      </TableCell>
    </TableRow>
  );
};

DetailsDialogItem.propTypes = {
  row: PropTypes.shape({
    status: PropTypes.string,
    obj: PropTypes.shape({
      company: PropTypes.string,
      deviceName: PropTypes.string,
      emailRecipient: PropTypes.string,
      emailSubject: PropTypes.string,
      type: PropTypes.string,
      lastN: PropTypes.string,
      value: PropTypes.string,
      isSlack: PropTypes.bool,
      slackTemplate: PropTypes.string,
      slackRecoverTemplate: PropTypes.string,
      slackUrl: PropTypes.string,
      isTicket: PropTypes.bool,
      ticketAutoClose: PropTypes.bool,
      ticketCrmDefaultName: PropTypes.string,
      ticketSubject: PropTypes.string,
      isSms: PropTypes.bool,
      smsRecipient: PropTypes.string,
      smsRecoverTemplate: PropTypes.string,
      smsTemplate: PropTypes.string,
      isTeams: PropTypes.bool,
      teamsTemplate: PropTypes.string,
      teamsRecoverTemplate: PropTypes.string,
      teamsUrl: PropTypes.string,
      isEmail: PropTypes.node,
      isWebHook: PropTypes.bool,
      webhookUrl: PropTypes.string,
    }),
  }),
};

export default DetailsDialogItem;
