import React from 'react';
import PropTypes from 'prop-types';

import HyperlinkAccessor from '@common/MuiVirtualizedTable/components/accessors/HyperlinkAccessor';
import TitledValueAccessor from '@common/MuiVirtualizedTable/components/accessors/TitledValueAccessor';
import Table from '@components/Table';

const HookAlertsGrid = ({ resource }) => {
  const columns = [
    {
      name: 'Network',
      key: 'networkName',
      minWidth: 250,
      width: 250,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <HyperlinkAccessor href={row.networkUrl} value={row.networkName} />
      ),
    },
    {
      key: 'deviceName',
      name: 'Devices',
      minWidth: 200,
      width: 200,
      sortable: true,
      isCustomAccessor: true,
      accessor: row => (
        <HyperlinkAccessor href={row.deviceUrl} value={row.deviceName} />
      ),
    },
    {
      key: 'category',
      sortable: true,
      searchable: true,
      name: 'Category',
      width: 200,
    },
    {
      key: 'type',
      name: 'Type',
      width: 300,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => <TitledValueAccessor value={row.type} />,
    },
    {
      key: 'severity',
      name: 'Severity',
      sortable: true,
      searchable: true,
      width: 150,
    },
  ];
  return (
    <Table
      resource={resource}
      columns={columns}
      defaultSort={{ fieldName: 'deviceName', order: 'DESC' }}
    />
  );
};

HookAlertsGrid.propTypes = {
  resource: PropTypes.string,
};

export default HookAlertsGrid;
