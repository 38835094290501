import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT_MODULES } from '@store/slices/resources';
import {
  GetAccessModules,
  GetAccessModulesTemplate,
} from '@services/requests/admin';

export const ThunkGetAccessModulesTemplate = createAsyncThunk(
  `${TENANT_MODULES}/GetAccessModulesTemplate`,
  async () => {
    const data = await GetAccessModulesTemplate();
    return data;
  },
);

export default {};
