import { makeRequest } from '../makeRequest';
import { BILLING, SUBSCRIPTIONS } from '../requestResources';

export const getExtraResources = () =>
  makeRequest('GET', `${SUBSCRIPTIONS}/getExtraResources`);

export const addExtraResource = code =>
  makeRequest('POST', `${SUBSCRIPTIONS}/addExtraResource/${code}`);

export const checkSubscriptionDiscount = () =>
  makeRequest('GET', `${BILLING}/discount/check`);

export const addSubscriptionDiscount = code =>
  makeRequest('POST', `${BILLING}/discount/add?code=${code}`);

export default {};
