import { CLIENT_PORTALS } from '@store/slices/resources';

export const SelectClientPortalConfigurations = state =>
  state[CLIENT_PORTALS].configurationsOptions;
export const SelectClientPortalConfigurationsLoading = state =>
  state[CLIENT_PORTALS].configurationsLoading;
export const SelectClientPortalConfigDefaultsOptions = state =>
  state[CLIENT_PORTALS].configurationDefaultsOptions;

export const SelectClientPortalConfigurationOptions = state =>
  state[CLIENT_PORTALS].configOptions;
export const SelectClientPortalConfigurationOptionsLoading = state =>
  state[CLIENT_PORTALS].configOptionsLoading;

export const SelectClientPortalMessages = state =>
  state[CLIENT_PORTALS].messages;

export const SelectClientPortalPusherSettings = state =>
  state[CLIENT_PORTALS].pusherSettings;
