import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  itemStyle: {
    color: props => props.color,
    fontWeight: 'bold',
  },
  form: { minWidth: '400px', marginBottom: '8px' },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '24px',
  },
}));

export default useStyles;
