import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    minWidth: '50rem',
    '& > div': {
      height: '100%',
      minWidth: '100%',
    },
  },
}));

export default useStyles;
