import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAddCrmUser,
  ThunkGetCrmCompaniesByDefaultId,
} from '@store/slices/crm/thunks';
import { ThunkGetChannelHistory } from '@store/slices/messaging/thunks';
import { MessagingChannelSelector } from '@store/slices/messaging';

import { requiredValidator } from '@utils/validators';
import phoneValidation from '@constants/phoneValidation';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import HistorySubList from '../components/HistorySubList';

import useStyles from '../styles';

const AddContactDialog = ({ open, setOpen, crmDefaultId }) => {
  const [processing, setProcessing] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { crmCompanies } = useSelector(state => state.crmData);
  const channel = useSelector(MessagingChannelSelector);

  const submit = async values => {
    setProcessing(true);
    const selectedCompany = crmCompanies.find(
      i => i.value === values.companyId,
    );
    await dispatch(
      ThunkAddCrmUser({
        crmDefaultId,
        payload: values,
        companyName: selectedCompany.label,
      }),
    );
    setProcessing(false);
    setOpen(false);
  };

  const validate = values => {
    return {
      firstName: requiredValidator(values.firstName),
      lastName: requiredValidator(values.lastName),
      emailAddress: requiredValidator(values.emailAddress),
      companyId: requiredValidator(values.companyId),
      phone: phoneValidation(values.phone),
    };
  };

  const onMount = useCallback(async () => {
    dispatch(ThunkGetCrmCompaniesByDefaultId({ crmDefaultId }));
    setMessagesLoading(true);
    await dispatch(
      ThunkGetChannelHistory({
        channelId: channel.id,
        userOnly: true,
      }),
    );
    setMessagesLoading(false);
  }, [crmDefaultId, dispatch, channel]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Add Contact</DialogTitle>
      <DialogContent style={{ margin: '0 auto', minWidth: '550px' }}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.twoColumns}>
                <div>
                  <FieldWrapper
                    label="First Name"
                    labelSize={12}
                    contentSize={12}
                    showLabel
                    classNameLabelInner={classes.alignLeft}
                    content={
                      <Field
                        name="firstName"
                        id="firstName"
                        render={Input}
                        disabled={processing}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Last Name"
                    labelSize={12}
                    contentSize={12}
                    showLabel
                    classNameLabelInner={classes.alignLeft}
                    content={
                      <Field
                        name="lastName"
                        id="lastName"
                        render={Input}
                        disabled={processing}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Phone"
                    labelSize={12}
                    contentSize={12}
                    showLabel
                    classNameLabelInner={classes.alignLeft}
                    infoText="Format should look like: +12345678900"
                    content={
                      <Field
                        name="phone"
                        id="phone"
                        render={Input}
                        disabled={processing}
                        dateProps={{ maxLength: 13 }}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Email"
                    labelSize={12}
                    contentSize={12}
                    showLabel
                    classNameLabelInner={classes.alignLeft}
                    content={
                      <Field
                        name="emailAddress"
                        id="emailAddress"
                        type="email"
                        render={Input}
                        disabled={processing}
                      />
                    }
                  />

                  <FieldWrapper
                    label="Company"
                    labelSize={12}
                    contentSize={12}
                    showLabel
                    classNameLabelInner={classes.alignLeft}
                    content={
                      <Field
                        name="companyId"
                        id="companyId"
                        render={AutocompleteFormInput}
                        loading={processing}
                        items={crmCompanies}
                      />
                    }
                  />
                </div>

                <div>
                  <HistorySubList messagesLoading={messagesLoading} />
                </div>
              </div>

              <div className={classes.contactDialogActions}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  crmDefaultId: PropTypes.number,
};

export default AddContactDialog;
