import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useListContext } from 'react-admin';
import { Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import DropDown from '@components/Auth/Common/DropDown';
import { COUNT_PRODUCT_PER_PAGE, sortOrders } from '@constants/filters';
import useStyles from './styles';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const noneOption = { value: 'None', label: '' };

const TableDropDown = ({
  item: { field, name },
  options,
  valueHandler = () => {},
  labelHandler = () => {},
  hideSorting = false,
}) => {
  const classes = useStyles();

  const {
    setSort,
    setFilters,
    filterValues,
    currentSort,
    setPerPage: setCountData,
    noneSelected,
  } = useListContext();

  const isCurrentFieldSorted = currentSort.field === field;

  const items = useMemo(() => {
    return Object.keys(options).map(key => ({
      value: valueHandler(key) || capitalize(key),
      label: labelHandler(key) || capitalize(key),
    }));
  }, [labelHandler, valueHandler, options]);

  const onChange = (e, form) => {
    const { value } = e.target;

    setCountData(COUNT_PRODUCT_PER_PAGE);

    if (value === noneSelected) form.reset();

    setFilters(
      { [field]: value !== noneSelected ? value : '' },
      filterValues,
      true,
    );
  };

  const setSorting = () => {
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setSort(field);
    setFilters({}, filterValues, true);
  };

  const classNameForButtonIcon = cx(classes.tableHeaderButtonIcon, {
    [classes.tableHeaderButtonIconRotate]:
      isCurrentFieldSorted && currentSort.order === sortOrders.asc,
  });

  return (
    <Form onSubmit={() => {}}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.tableHeaderForm}>
          {!hideSorting && (
            <Button onClick={setSorting} className={classes.tableHeaderButton}>
              <ArrowUpward className={classNameForButtonIcon} />
            </Button>
          )}
          <Field
            id={field}
            name={field}
            label={name}
            fullWidth
            component={({ input, ...rest }) => (
              <DropDown
                {...rest}
                input={{
                  value: input.value,
                  onChange: e => {
                    input.onChange(e);
                    onChange(e, form);
                  },
                }}
              />
            )}
            classNameWrapper={classes.inputSmallWrapper}
            options={[noneOption, ...items]}
          />
          }
        </form>
      )}
    </Form>
  );
};

TableDropDown.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
  valueHandler: PropTypes.func,
  labelHandler: PropTypes.func,
  hideSorting: PropTypes.bool,
  item: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default TableDropDown;
