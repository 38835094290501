import { makeRequest } from '../makeRequest';
import {
  COMPANY_VERIFICATION_SETTINGS,
  VERIFICATION_SETTINGS,
} from '../requestResources';

export const getVerificationSettings = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}`);

export const getVerificationSettingsVariables = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}/templateVariables`);

export const getVerificationExpiredTicketNoteVariables = () =>
  makeRequest(
    'GET',
    `${VERIFICATION_SETTINGS}/verificationExpiredTicketNoteTemplateVariables`,
  );

export const getVerificationSettingsEmailSubjectVariables = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}/emailSubjectTemplateVariables`);

export const getTechResponseSettingsVariables = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}/techResponseTemplateVariables`);

export const getVerificationAutoResponseSettingsVariables = () =>
  makeRequest(
    'GET',
    `${VERIFICATION_SETTINGS}/verificationAutoResponseTemplateVariables`,
  );

export const getVerificationSettingsDuration = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}/duration`);

export const saveVerificationSettings = payload =>
  makeRequest('POST', `${VERIFICATION_SETTINGS}`, payload);

export const removeVerificationScreenLogo = () =>
  makeRequest('POST', `${VERIFICATION_SETTINGS}/remove-logo`);

export const getHaloWebhookLink = payload =>
  makeRequest('POST', `${VERIFICATION_SETTINGS}/get-halo-link`, payload);
export default {};

export const getVerificationPhone = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}/verificationPhone`);

export const addVerificationPhoneNumber = bufferNumberId =>
  makeRequest(
    'POST',
    `${VERIFICATION_SETTINGS}/verificationPhone/select/${bufferNumberId}`,
  );

export const checkIfTenantHasVerificationPhone = () =>
  makeRequest('GET', `${VERIFICATION_SETTINGS}/verificationPhone`);

export const getCompaniesVerificationSettings = crmId =>
  makeRequest(
    'GET',
    `${COMPANY_VERIFICATION_SETTINGS}${crmId ? `?crmId=${crmId}` : ''}`,
  );

export const createCompaniesVerificationSettings = payload =>
  makeRequest('POST', `${COMPANY_VERIFICATION_SETTINGS}`, payload);

export const updateCompaniesVerificationSettings = payload =>
  makeRequest('PUT', `${COMPANY_VERIFICATION_SETTINGS}`, payload);

export const deleteVerificationScreenSettings = id =>
  makeRequest('DELETE', `${COMPANY_VERIFICATION_SETTINGS}/${id}`);

export const resetVerificationScreenSettings = () =>
  makeRequest(
    'POST',
    `${VERIFICATION_SETTINGS}/resetVerificationScreenSettings`,
  );

export const resetTechVerificationSettings = () =>
  makeRequest('POST', `${VERIFICATION_SETTINGS}/resetTechVerificationSettings`);

export const getCompanyVerificationSettings = (crmId, companyId) =>
  makeRequest(
    'GET',
    `/CompanyVerificationSettings/${crmId}/company/${companyId}`,
  );
