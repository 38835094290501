import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEmailTestingTypes } from '@services/requests/testEmail';
import { TEST_EMAIL } from '@store/slices/resources';

export const ThunkGetEmailTestingTypes = createAsyncThunk(
  `${TEST_EMAIL}/getEmailTestingTypes`,
  async () => {
    const types = await getEmailTestingTypes();
    return types;
  },
);

export default {};
