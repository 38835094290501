import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { checkDealerCode } from '@services/requests/auth';

export const ThunkCheckDealerCode = createAsyncThunk(
  `${AUTH}/checkDealerCode`,
  ({ code }) => checkDealerCode(code),
);

export default {};
