import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';

const SyncroMspForm = ({ classes, loading, useLabels }) => (
  <>
    {useLabels && (
      <Typography className={classes.inputLabel}>Domain:</Typography>
    )}
    <Field
      id="uri"
      name="uri"
      styleType="main"
      fullWidth
      inputView="text"
      type="text"
      component={Input}
      classNameWrapper={classes.inputWrapper}
      placeholder="Domain"
      disabled={loading}
    />
    {useLabels && (
      <Typography className={classes.inputLabel}>Password:</Typography>
    )}
    <Field
      id="privateKey"
      name="privateKey"
      styleType="main"
      fullWidth
      inputView="text"
      type="password"
      component={Input}
      classNameWrapper={classes.inputWrapper}
      placeholder="Password"
      disabled={loading}
    />
  </>
);

SyncroMspForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default SyncroMspForm;
