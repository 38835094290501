import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableHeaderButtonIcon: {
    transition: '0.3s',
    fill: theme.palette.warning.main,
  },
  tableHeaderButtonIconRotate: {
    transform: 'rotate(-180deg)',
  },
  tableHeaderForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  tableHeaderButton: {
    position: 'absolute',
    zIndex: 2,
    top: '0.9rem',
    left: '0.1875rem',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 'auto',
    padding: 0,

    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  marginForText: {
    marginRight: '4px',
  },
  tableHeaderInput: {
    '& input': {
      padding: '5px 31px',
      borderRadius: '0.3125rem',
      border: '0.0625rem solid #ebc7c0',
      background: theme.palette.input.background,
    },

    '& div': {
      marginTop: '3px',
      border: 'none',
    },

    '& .MuiFilledInput-underline:before': {
      display: 'none',
    },
  },
}));

export default useStyles;
