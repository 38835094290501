import React from 'react';
import PropTypes from 'prop-types';
import NoteIcon from '@assets/icons/test.svg';
import EmailIcon from '@assets/icons/email_active.svg';
import CloseIcon from '@assets/icons/cross.svg';
import RefreshIcon from '@assets/icons/refresh.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';

const ChatActions = ({
  handlePushToTicket,
  handlePushToEmail,
  handleCloseChat,
  handleReopenChat,
  isClosed = false,
}) => {
  return (
    <div>
      <ActionButton
        icon={<img src={NoteIcon} alt="noteIcon" />}
        handler={handlePushToTicket}
        toolTip="Push Messages to Ticket Note"
      />

      <ActionButton
        icon={<img src={EmailIcon} alt="emailIcon" />}
        handler={handlePushToEmail}
        toolTip="Send Chat Logs to Email"
      />

      <ActionButton
        icon={<img src={CloseIcon} alt="closeIcon" />}
        handler={handleCloseChat}
        toolTip="Close Chat"
        disabled={isClosed}
      />

      <ActionButton
        icon={<img src={RefreshIcon} alt="reopenIcon" />}
        handler={handleReopenChat}
        toolTip="Re-Open Chat"
        disabled={!isClosed}
      />
    </div>
  );
};

ChatActions.propTypes = {
  handlePushToTicket: PropTypes.func.isRequired,
  handlePushToEmail: PropTypes.func.isRequired,
  handleCloseChat: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
};

export default ChatActions;
