import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION } from '@store/slices/resources';
import { getIntegrationSyncroTypes } from '@services/requests/issueTypes';

export const ThunkGetIntegrationSyncroIssueTypes = createAsyncThunk(
  `${INTEGRATION}/getIntegrationSyncroIssueTypes`,
  async ({ id }) => {
    const issueTypes = await getIntegrationSyncroTypes(id);
    return issueTypes;
  },
);

export default {};
