import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import 'material-ui-rc-color-picker/assets/index.css';
import { enqueueSnackbar } from 'notistack';

import { TEXT_CREATE_SUCESS } from '@constants/texts/common';
import {
  defaultBgColor,
  defaultCircleBgColor,
  defaultCircleBorderColor,
  defaultFontColor,
} from '@constants/defaultBageColors';
import ReusableButton from '@common/Button/Button';
import Input from '@common/Input/Input';
import useValueTypeDialog from '../hook/useValueTypeDialog';
import useStyles from '../styles';
import { splitCsl, updateTypeValue } from '../../helpers';
import ColorSelector from '../subComponents/ColorSelector';
import DataSelector from '../subComponents/DataSelector';
import CircleSelector from '../subComponents/CircleSelector';

const EditTypeValueDialog = ({ onSubmit, onClose, open, item }) => {
  const classes = useStyles();
  const {
    circleBorderColor,
    setCircleBorderColor,
    circleBgColor,
    setCircleBgColor,
    icon,
    setIcon,
    bgColor,
    setBgColor,
    textColor,
    setTextColor,
    loading,
    setLoading,
    name,
    setName,
    chipData,
    setChipData,
    inputTextRef,
    handleArrowClick,
    handleChipsChange,
  } = useValueTypeDialog(item);

  const setItemValues = useCallback(() => {
    if (item) {
      setChipData(item.values ? splitCsl(item.values) : []);
      setName(item.name);
      setTextColor(item.fontColor ? item.fontColor : defaultFontColor);
      setBgColor(item.backgroundColor ? item.backgroundColor : defaultBgColor);
      setCircleBgColor(
        item.circleBgColor ? item.circleBgColor : defaultCircleBgColor,
      );
      setCircleBorderColor(
        item.circleBorderColor
          ? item.circleBorderColor
          : defaultCircleBorderColor,
      );
      setIcon(item.icon ? item.icon : '');
    }
  }, [
    setBgColor,
    item,
    setChipData,
    setCircleBgColor,
    setName,
    setIcon,
    setCircleBorderColor,
    setTextColor,
  ]);

  useEffect(() => {
    setItemValues();
  }, [setItemValues]);

  const submit = async () => {
    if (!name) {
      enqueueSnackbar('Name can not be empty!', { variant: 'error' });
      return;
    }
    const chipTextFieldValue = inputTextRef.current.value
      ? `,${inputTextRef.current.value}`
      : ' ';

    const data = {
      name,
      values:
        chipData.length > 0
          ? chipData
              .join(',')
              .concat(chipTextFieldValue)
              .trimEnd()
          : '',
      fontColor: textColor,
      backgroundColor: bgColor,
      circleBgColor,
      circleBorderColor,
      icon,
    };

    setLoading(true);
    updateTypeValue(item.id, data)
      .then(res => {
        enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' });
        onSubmit({ ...res, id: item.id });
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = chipToDelete =>
    setChipData(chips => chips.filter(chip => chip !== chipToDelete));

  const handleNameChange = event => setName(event.target.value);

  if (!item) return null;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Edit type value</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To indicate data after mail messages parsing please describe your
          custom type values. Name should be short and understandable.
        </DialogContentText>
        <Form onSubmit={() => {}}>
          {() => (
            <>
              <Field
                name="name"
                placeholder="Value name"
                inputView="text"
                styleType="main"
                fullWidth
                helperText={false}
                input={{
                  value: name,
                  onChange: handleNameChange,
                }}
                component={Input}
              />

              <DataSelector
                chipData={chipData}
                handleArrowClick={handleArrowClick}
                handleChipsChange={handleChipsChange}
                handleDelete={handleDelete}
                inputRef={inputTextRef}
              />
              <ColorSelector
                textColor={textColor}
                setTextColor={setTextColor}
                bgColor={bgColor}
                setBgColor={setBgColor}
              />
              <CircleSelector
                bgColor={circleBgColor}
                setBgColor={setCircleBgColor}
                borderColor={circleBorderColor}
                setBorderColor={setCircleBorderColor}
              />
              <div className={classes.modalButtonContainer}>
                <ReusableButton
                  label="Cancel"
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                  disabled={loading}
                  onClick={onClose}
                />

                <ReusableButton
                  viewType="black"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  onClick={submit}
                  loading={loading}
                  label="OK"
                />
              </div>
            </>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

EditTypeValueDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.objectOf(PropTypes.any),
};

export default EditTypeValueDialog;
