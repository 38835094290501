import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../styles';

const SourceItemValue = ({ label, value, endOfLine = ';' }) => {
  const classes = useStyles();
  return (
    <div className={classes.sourceItem}>
      <span className={classes.itemValue}>{label}</span>
      <span className={classes.itemValue}>:&nbsp;</span>
      <span>{value}</span>
      <span>{endOfLine}</span>
    </div>
  );
};

SourceItemValue.propTypes = {
  label: PropTypes.string.isRequired,
  endOfLine: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SourceItemValue;
