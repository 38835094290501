import { useState } from 'react';

const useTest = ({ apiRequest, setLoading }) => {
  const [testOutput, setTestOutput] = useState({ isOk: false, message: '' });

  const handleClick = model => {
    setLoading(true);
    apiRequest(model)
      .then(response => setTestOutput(response))
      .catch(e => setTestOutput({ isOk: false, message: e.message }))
      .finally(() => setLoading(false));
  };
  return { handleTestClick: handleClick, testOutput };
};

export default useTest;
