import React from 'react';
import PropTypes from 'prop-types';
import { BooleanField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  icon: {
    '& .MuiSvgIcon-root': {
      color: props =>
        props.value && props.useMultiColor
          ? theme.palette.success.dark
          : theme.overrides.MuiSvgIcon.failed.color,
    },
  },
}));

const BooleanAccessor = ({ value, useMultiColor = false }) => {
  const classes = useStyles({ value, useMultiColor });
  return (
    <div className={classes.container}>
      <BooleanField
        record={{ item: value }}
        source="item"
        className={classes.icon}
      />
    </div>
  );
};

BooleanAccessor.propTypes = {
  value: PropTypes.bool,
  useMultiColor: PropTypes.bool,
};

export default BooleanAccessor;
