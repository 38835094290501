import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_INTEGRATIONS } from '@store/slices/resources';
import { deleteIntegration } from '@services/requests/securityIntegrations';

export const ThunkDeleteSecurityIntegration = createAsyncThunk(
  `${SECURITY_INTEGRATIONS}/deleteIntegration`,
  async id => {
    const data = await deleteIntegration(id);
    return data;
  },
);

export default {};
