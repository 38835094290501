import React, { memo, useContext, useMemo } from 'react';
import { TableRow } from '@material-ui/core';
import cx from 'classnames';

import { TableContext } from '../../context';
import TableCellContent from './TableCellContent';

import useStyles from '../../styles';

const TableBodyContent = memo(() => {
  const classes = useStyles();
  const { data, loading, columns, rowsPerPage } = useContext(TableContext);

  const loadingArray = useMemo(() => {
    return new Array(rowsPerPage).fill(1);
  }, [rowsPerPage]);

  return (
    <>
      {(loading ? loadingArray : data).map(d => (
        <TableRow
          key={d.id || crypto.randomUUID()}
          className={cx(classes.tableRow, classes.tableRowAligned)}
        >
          {columns.map(c => (
            <TableCellContent
              key={d.id + c.key}
              data={d[c.key]}
              type={c.type}
              accessor={c.accessor}
              isCustomAccessor={c.isCustomAccessor}
              maxWidth={c?.width}
              minWidth={c?.minWidth}
              id={d.id}
              rowData={d}
              isUpperCase={c.isUpperCase}
              loading={loading}
            />
          ))}
        </TableRow>
      ))}
    </>
  );
});

export default TableBodyContent;
