const breakpoints = Object.freeze({
  xs: 0,
  sm: 608,
  md: 788,
  lg: 1086,
  xl: 1920,
});

export const DashboardChangedLayoutType = Object.freeze({
  Resize: 'resize',
  Refresh: 'refresh',
});

export const DRAGGABLE_HANDLE_CLASS = 'tile-drag-and-drop-handler';
export const DRAGGABLE_CANCEL_CLASS = 'tile-drag-and-drop-cancel';

export const GRID_LAYOUT_OPTIONS = {
  className: 'layout',
  breakpoints: {
    xs: breakpoints.sm - 1,
    xxs: 0,
  },
  cols: { xs: 12, xxs: 2 },
  margin: {
    xs: [18, 18],
    xxs: [12, 12],
  },
  draggableHandle: `.${DRAGGABLE_HANDLE_CLASS}`,
  draggableCancel: `.${DRAGGABLE_CANCEL_CLASS}`,
};
