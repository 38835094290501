import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '@components/Settings/LocaleCompany/components/importWizard/styles';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

const SelectableItem = ({ name, list, onClick }) => {
  const classes = useStyles();
  const checked = list.some(i => i === name);

  return (
    <div
      role="presentation"
      className={classes.networkColumnItem}
      onClick={() => onClick(name, checked)}
    >
      <Checkbox
        style={{ height: '25px' }}
        checked={checked}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span>{name}</span>
    </div>
  );
};

SelectableItem.propTypes = {
  name: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

export default SelectableItem;
