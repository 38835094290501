import { useEffect, useState, useMemo } from 'react';

import { getFilterData, hasParams } from './utils';

function useFilter({ data, search, filters, sort }) {
  const [result, setResult] = useState([]);

  const isHavingParams = useMemo(() => hasParams(search, filters, sort), [
    search,
    filters,
    sort,
  ]);

  useEffect(() => {
    if (isHavingParams) {
      setResult(getFilterData({ data, search, filters, sort }));
    } else {
      setResult(data);
    }
  }, [search, filters, sort, data, isHavingParams]);

  return { loading: false, data: isHavingParams ? result : data };
}

export default useFilter;
