import { makeRequest } from '@services/requests/makeRequest';
import { AUTOTASK_FILE_UPLOAD } from '../requestResources';

export const getAutotaskCompanies = psaId =>
  makeRequest('GET', `${AUTOTASK_FILE_UPLOAD}/${psaId}/companies`);

export const getAutotaskConfigurationItems = (psaId, companyId) =>
  makeRequest(
    'GET',
    `${AUTOTASK_FILE_UPLOAD}/${psaId}/configurationItems?companyId=${companyId}`,
  );

export const uploadAutotaskFile = (psaId, configurationItemId, formData) =>
  makeRequest(
    'POST',
    `${AUTOTASK_FILE_UPLOAD}/${psaId}/upload?configItemId=${configurationItemId}`,
    formData,
  );
