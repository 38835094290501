import { makeRequest } from '../makeRequest';
import { TEAMS_CONNECTIONS } from '../requestResources';

export const addNewTeamsConnections = payload =>
  makeRequest('POST', `${TEAMS_CONNECTIONS}`, payload);

export const deleteTeamsConnection = id =>
  makeRequest('DELETE', `${TEAMS_CONNECTIONS}/${id}`);

export const editTeamsConnection = (id, payload) =>
  makeRequest('PUT', `${TEAMS_CONNECTIONS}/${id}`, payload);

export const getTeamsConnections = () =>
  makeRequest('GET', `${TEAMS_CONNECTIONS}`);
export default {};
