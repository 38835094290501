import { createAsyncThunk } from '@reduxjs/toolkit';
import { getZendeskContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetZendeskContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getZendeskContactData`,
  async ({ crmId, contactId }) => getZendeskContactData(crmId, contactId),
);

export default {};
