import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  resultsText: {
    textAlign: 'center',
    marginTop: '15px',

    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '0.5rem',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  mailWrapper: {
    color: '#0E96E2',
  },
}));

export default useStyles;
