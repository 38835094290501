import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import iconHelp from '@assets/icons/help.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '@common/HelpBox/CodeBlock';
import remarkGfm from 'remark-gfm';
import Dialog from '@material-ui/core/Dialog/Dialog';
import useStyles from './styles';

const HelpDialog = ({ open, setOpen, data }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogContent dividers>
        <ReactMarkdown
          components={{
            code(props) {
              return <CodeBlock {...props} />;
            },
          }}
          remarkPlugins={[remarkGfm]}
        >
          {data}
        </ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
};

const PipelineHelp = ({ form, pipelineOptions, fieldName = 'pipelineId' }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { text, enabled } = useMemo(() => {
    const pipelineId = form.getFieldState(fieldName)?.value || false;
    const helpText = pipelineOptions.find(i => i.value === pipelineId)?.info;
    return {
      text: helpText,
      enabled: !!pipelineId,
    };
  }, [form, pipelineOptions]);

  return (
    <div className={classes.marginLeft}>
      <ActionButton
        handler={() => setOpen(true)}
        icon={<img src={iconHelp} alt="help" />}
        toolTip="Edit"
        disabled={!enabled}
      />
      <HelpDialog open={open} setOpen={() => setOpen(false)} data={text} />
    </div>
  );
};

PipelineHelp.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  pipelineOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  fieldName: PropTypes.string,
};

export default PipelineHelp;
