import { makeRequest } from '@services/requests/makeRequest';

export const getZendeskCompanies = psaId =>
  makeRequest('GET', `/TicketCreate/${psaId}/zendesk/companies`);

export const getZendeskStatuses = crmId =>
  makeRequest('GET', `/TicketUpdate/zendesk/${crmId}/statuses`);

export const getZendeskPriorities = crmId =>
  makeRequest('GET', `/TicketUpdate/zendesk/${crmId}/priorities`);

export const getZendeskTypes = crmId =>
  makeRequest('GET', `/TicketUpdate/zendesk/${crmId}/types`);
