import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import ReusableButton from '@ui/components/common/Button/Button';
import usePodSaveContactRoute from '@components/Hooks/usePodSaveContactRoute';
import PhoneField from '@common/PhoneField';
import { validateRequired } from '@common/functions/validators';
import { actions } from '@store/actions';
import { ThunkPodSaveToContact } from '@store/slices/psaPods/thunks';
import { ThunkGetTenantCountry } from '@store/slices/tenant/thunks';
import phoneValidator from '@utils/validators/phoneValidator';
import { phoneWithCharactersValidator } from '@utils/validators';
import { joinCodeWithPhone } from '@ui/pages/optIn/OptInForm/helpers';
import Loading from '@common/Loading/Loading';
import useSharedStyles from '@ui/components/CrmTicket/sharedStyles';
import { usePhoneInitialData } from '../../../../hooks/usePhoneInitialData';
import { useCountryCodesOptions } from '../../../../hooks/useCountryCodesOptions';

import useStyles from '../styles';

const EditContactDialogV2 = ({
  open,
  setOpen,
  psaType,
  record,
  psaId,
  contactId,
}) => {
  const { countryCodesOptions, countries, loading } = useCountryCodesOptions();

  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [countryTenantCode, setCountryTenantCode] = useState(null);
  const [processing, setProcessing] = useState(false);

  const defaultTenantCodes = useMemo(() => {
    const currentCountry = countries.find(c => c.code === countryTenantCode);
    return {
      tenantCountryCode: currentCountry?.code || 'US',
      tenantDialCode: currentCountry?.dialCode || '+1',
    };
  }, [countryTenantCode, countries]);

  const {
    initialIcon,
    countryCodeFormValue,
    nationalNumber,
  } = usePhoneInitialData({
    phone: record.phone?.toString() || '',
    countries,
    defaultTenantCodes,
  });

  const saveContactRoute = usePodSaveContactRoute({ psaType });

  const submit = values => {
    setProcessing(true);
    const phone = joinCodeWithPhone(values.countryCode, values.phone || '');
    const payload = {
      route: saveContactRoute,
      type: 'Phone',
      value: phone,
      crmId: psaId,
      contactId,
    };
    dispatch(ThunkPodSaveToContact(payload))
      .unwrap()
      .then(() => {
        setOpen(false);
        dispatch(
          actions.updateItemInList({
            ...record,
            phone,
          }),
        );
        enqueueSnackbar('Mobile number was updated', { variant: 'success' });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = useCallback(
    values => ({
      phone:
        phoneValidator(values.phone, values.countryCode) ||
        phoneWithCharactersValidator(values.phone, 1),
      countryCode: validateRequired(values.countryCode),
    }),
    [],
  );

  const onMount = useCallback(() => {
    dispatch(ThunkGetTenantCountry())
      .unwrap()
      .then(p => setCountryTenantCode(p.data));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Edit Contact</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            ...record,
            phone: nationalNumber,
            countryCode: countryCodeFormValue,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.formWrapper}>
              {loading ? (
                <Loading classNameWrapper={classes.loader} />
              ) : (
                <PhoneField
                  label="Mobile Number"
                  phoneName="phone"
                  phoneCodeName="countryCode"
                  countryCodesOptions={countryCodesOptions}
                  initialIcon={initialIcon}
                />
              )}
              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditContactDialogV2.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  psaType: PropTypes.string.isRequired,
  record: PropTypes.shape({
    phone: PropTypes.string,
  }).isRequired,
  psaId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};

export default EditContactDialogV2;
