import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableHeaderForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  tableHeaderButton: {
    position: 'absolute',
    zIndex: 2,
    top: '0.9rem',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 'auto',
    padding: 0,

    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  tableHeaderButtonIconRotate: {
    transform: 'rotate(-180deg)',
  },
  tableHeaderButtonIcon: {
    transition: '0.3s',
  },
  inputSmallWrapper: {
    marginTop: '0.69rem',
    border: 'none',
    '&:placeholder': {
      color: 'red',
    },
    '& .MuiSelect-select': {
      padding: '0.33rem 1.6rem',
      borderRadius: '0.1875rem',
      background: theme.palette.input.background,
      border: '0.0625rem solid #ebc7c0',
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.input.color,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(1.6rem, -0.2rem) scale(1)',
    },
  },
}));
export default useStyles;
