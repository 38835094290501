import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logsDialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noteDialogInput: {
    minWidth: '450px',
  },
  noteDialogAction: {
    margin: '5px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '5px',
    },
  },
  logsDialogFailed: {
    color: 'red',
  },
}));

export default useStyles;
