const getField = obj => {
  if (obj.parameter) {
    return {
      field: obj.parameter,
      value: obj.value,
      operator: obj.operator,
    };
  }
  if (obj.operation) {
    return getQuery(obj);
  }
  return null;
};

export const getQuery = value => {
  if (!value?.expressions.length) {
    return '';
  }
  const result = {
    combinator: value.operation,
    rules: [],
  };
  value.expressions.forEach(i => {
    const newField = getField(i);
    if (newField) {
      result.rules.push(newField);
    }
  });
  return result;
};

const setField = obj => {
  if (obj.field) {
    return {
      parameter: obj.field,
      operator: obj.operator,
      value: obj.value,
    };
  }
  if (obj.combinator) {
    return setQuery(obj);
  }
  return null;
};

export const setQuery = value => {
  if (!value?.rules?.length) {
    return null;
  }
  const result = {
    operation: value.combinator,
    expressions: [],
  };
  value.rules.forEach(i => {
    const newField = setField(i);
    if (newField) {
      result.expressions.push(newField);
    }
  });
  return result;
};
