import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import TicketLogs from '@components/Triggers/pages/Incident/components/TicketLogs';

const LogsDialog = ({ open, onClose, logs }) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent dividers>
        <TicketLogs logString={logs} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LogsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logs: PropTypes.string,
};

export default LogsDialog;
