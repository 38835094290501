import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import Paper from '@common/Paper/Paper';
import { webHookSettings } from '@store/selectors/notifications';
import { actions } from '@store/actions';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components/Hooks/useDisabled';
import DropDown from '@components/Auth/Common/DropDown';
import httpRequestMethods from '@constants/httpRequestMethods';
import TestArea from '@common/TestArea/TestArea';
import useTest from '@components/Hooks/useTest';
import PropTypes from 'prop-types';
import { handleDoWChange } from '../../utils';
import {
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import {
  getWebHookSettings,
  postWebHooksSettings,
  testWebHook,
} from '../helpers';
import useTicketSetting from '../../hooks/useTicketSetting';
import { webHookSettingsValidator } from '../../validators';

const WebHookSettings = ({ match }) => {
  const storedData = useSelector(webHookSettings);

  const {
    submit,
    loading,
    setLoading,
    handleBack,
    data,
    setData,
  } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveWebHookSetting,
    tileKey: 'isWebHook',
    apiPostRequest: postWebHooksSettings,
    apiGetSetting: getWebHookSettings,
  });

  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testWebHook,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const onDoWChange = handleDoWChange(setData);

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={webHookSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <FieldWrapper
                label="Url"
                labelSize={3}
                content={
                  <Field
                    id="url"
                    name="url"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Request type"
                labelSize={3}
                content={
                  <Field
                    id="httpRequestMethod"
                    name="httpRequestMethod"
                    options={httpRequestMethods}
                    component={DropDown}
                    disabled={disabled}
                  />
                }
              />
              <TestArea
                onClick={() =>
                  handleTestClick({
                    url: form.getFieldState('url').value,
                    method: form.getFieldState('httpRequestMethod').value,
                  })
                }
                loading={loading}
                testOutput={testOutput}
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

WebHookSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default WebHookSettings;
