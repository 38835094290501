import { makeRequest } from '../makeRequest';
import { MESSAGING_DASHBOARD } from '../requestResources';

export const getRecentlyCreatedChannels = () =>
  makeRequest('GET', `${MESSAGING_DASHBOARD}/recentBoards?count=7`);

export const getMessagingChannelsCharts = ({ startDate, endDate }) =>
  makeRequest(
    'GET',
    `${MESSAGING_DASHBOARD}/channelsChartData?startDate=${startDate}&endDate=${endDate}`,
  );

export const getMessagingMessagesCharts = ({ startDate, endDate }) =>
  makeRequest(
    'GET',
    `${MESSAGING_DASHBOARD}/messagesChartData?startDate=${startDate}&endDate=${endDate}`,
  );

export const getMessagingCrmStatistic = () =>
  makeRequest('GET', `${MESSAGING_DASHBOARD}/crmStatistic`);

export const getMessagingChannelsStatistic = () =>
  makeRequest('GET', `${MESSAGING_DASHBOARD}/channelsStatistic`);

export default {};
