import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteOptInItem } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {string} id
 */
export const ThunkDeleteOptIn = createAsyncThunk(
  `${OPT_IN}/deleteOptIn`,
  async ({ id }) => {
    await deleteOptInItem(id);
    return id;
  },
);

export default {};
