import { getHeaders } from '@utils/transfered';
import appConfig from '@configs/appConfig';

export const getTestData = async payload => {
  const url = `${appConfig.baseUrl}/parsers/test`;
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    window.location.href = '#/login';
    return Promise.reject({ message: false });
  }
  if (response.status < 200 || response.status >= 300) {
    const errorData = await response.json();
    const message = errorData || response.statusText;
    throw message;
  }
  return response.json();
};

export const checkAnswer = (data, model, errorsArray) => {
  const ft = model.fieldTransformer ? JSON.parse(model.fieldTransformer) : [];
  if (ft.every(i => !i.identifier)) {
    errorsArray.push('No ID fields selected. Parsing impossible');
  }

  if (!data.isTypeRecognized) {
    errorsArray.push({
      label: 'Letter is not recognized',
      errors: data.identifierErrors,
    });
  }

  if (data.parsedData === '{}') {
    errorsArray.push(
      'No parsed data for this parser. Setup data expressions properly',
    );
  }

  if (data.results.length === 0) {
    errorsArray.push(
      'No result data for this parser. Setup field transformers properly',
    );
  }

  if (!model.name) {
    errorsArray.push('Parser name required');
  }
};
