import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import helpIcon from '@assets/icons/help.svg';
import CodeBlock from '@common/HelpBox/CodeBlock';
import useStyles from './styles';

const HelpBox = ({ resource }) => {
  const classes = useStyles();
  const [data, setData] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    import(`@help/${resource.fileName}.md`)
      .then(module => fetch(module.default))
      .then(resp => resp.text())
      .then(setData)
      .catch(e => console.error(e.message));
  }, [resource]);

  return (
    <>
      <img
        src={helpIcon}
        alt="helpIcon"
        className={classes.iconButton}
        onClick={() => setOpen(!open)}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogContent dividers className={classes.imgResize}>
          <ReactMarkdown
            components={{
              code(props) {
                return <CodeBlock {...props} />;
              },
            }}
            remarkPlugins={[remarkGfm]}
          >
            {data}
          </ReactMarkdown>
        </DialogContent>
      </Dialog>
    </>
  );
};

HelpBox.propTypes = {
  resource: PropTypes.shape({
    fileName: PropTypes.string,
  }),
};

export default HelpBox;
