import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  ticketsContainer: {
    minWidth: '30rem',
    minHeight: '15rem',
  },
}));

export default useStyles;
