import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useListContext } from 'react-admin';

import { ThunkCloseTriageTickets } from '@store/slices/triage/thunks/closeTriageTickets';
import { ThunkSoftDeleteTriages } from '@store/slices/triage/thunks/softDeleteTriages';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../styles';

const TableBulkActions = () => {
  const [
    openSoftDeleteConfirmationDialog,
    setOpenSoftDeleteConfirmationDialog,
  ] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { onUnselectItems, selectedIds } = useListContext();
  const list = useSelector(listSelector);

  const handleTicketsClose = useCallback(() => {
    dispatch(ThunkCloseTriageTickets(selectedIds))
      .unwrap()
      .then(ids => {
        const updatedList = list.map(item =>
          ids.includes(item.id) ? { ...item, ticketStatus: 2 } : item,
        );
        dispatch(actions.saveList(updatedList));
      });
  }, [dispatch, selectedIds, list]);

  const handleTriagesSoftDelete = useCallback(() => {
    dispatch(ThunkSoftDeleteTriages(selectedIds))
      .unwrap()
      .then(ids => {
        const updatedList = list.filter(item => !ids.includes(item.id));
        dispatch(actions.saveList(updatedList));
        setOpenSoftDeleteConfirmationDialog(false);
        onUnselectItems();
      });
  }, [dispatch, selectedIds, list, onUnselectItems]);

  return (
    <div className={classes.tableBulkActions}>
      <ReusableButton label="Close" onClick={handleTicketsClose} />

      <ReusableButton
        label="Delete"
        onClick={() => {
          setOpenSoftDeleteConfirmationDialog(true);
        }}
      />

      {openSoftDeleteConfirmationDialog && (
        <ConfirmDialog
          open={openSoftDeleteConfirmationDialog}
          setOpen={setOpenSoftDeleteConfirmationDialog}
          onCancel={() => {
            setOpenSoftDeleteConfirmationDialog(false);
          }}
          onAccept={handleTriagesSoftDelete}
          title="Confirm action"
          content="This action only deletes the triage instance; the PSA ticket remains. Are you sure you want to proceed?"
        />
      )}
    </div>
  );
};

export default TableBulkActions;
