import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '12.5rem',
    padding: '15px',
    margin: '1.875rem',
    borderRadius: '4px',
    border: '1px solid #E2E2E2',
    background: props =>
      `linear-gradient(157.47deg, ${props.palette.gradientA} 4.14%, ${props.palette.gradientB} 116.22%)`,
    cursor: props => props.palette.cursor,
  },
  price: {
    fontSize: '1.5rem',
    color: props => props.palette.font,
    margin: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  period: {
    fontSize: '0.875rem',
    color: props => props.palette.font,
    margin: 0,
    paddingBottom: '0.25rem',
  },
}));

export default useStyles;
