import { makeStyles } from '@material-ui/core/styles';

const selectColor = (theme, name) => {
  const colors = {
    Unrecognized: 'rgb(107 112 116)',
    Processed: theme.palette.success.main,
    Failed: theme.palette.failed.main,
  };

  return colors[name] || theme.palette.failed.main;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '0 7px',
    backgroundColor: props => selectColor(theme, props.name),
    color: '#ffffff',
    width: '164px',
    height: '21px',
    borderRadius: '0.125rem',

    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
