import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { registerDemoUser } from '@services/requests/auth';

export const ThunkRegisterDemoUser = createAsyncThunk(
  `${AUTH}/registerDemoUser`,
  ({ authKey, payload }) => registerDemoUser(authKey, payload),
);

export default {};
