import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGroupOptInData } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

export const ThunkGetGroupOptInData = createAsyncThunk(
  `${OPT_IN}/getGroupOptInData`,
  async ({ crmId, ids }) => getGroupOptInData(crmId, ids),
);

export default {};
