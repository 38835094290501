import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';

import {
  MessagingConfigurationsSelector,
  MessagingLoadingSelector,
} from '@store/slices/messaging';
import {
  ThunkDeleteMessagingConfiguration,
  ThunkGetMessagingConfigurations,
} from '@store/slices/messaging/thunks';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as ViewIcon } from '@assets/icons/visibility.svg';

import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import Loading from '../../common/Loading/Loading';
import SimpleTable from '../../Triggers/pages/Incident/components/SimpleTable';
import AddConfigurationDialog from './dialogs/AddConfigurationDialog';
import ReusableButton from '../../common/Button/Button';
import ConfirmDialog from '../../common/ConfirmDialog/ConfirmDialog';
import ConfigurationLogsDialog from './dialogs/ConfigurationLogsDialog';

import useStyles from './styles';

const MessagingConfigurations = () => {
  const [openAddConfigurationDialog, setOpenAddConfigurationDialog] = useState(
    false,
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [isNewConfiguration, setIsNewConfiguration] = useState(false);

  const [selectedConfiguration, setSelectedConfiguration] = useState(undefined);

  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(MessagingLoadingSelector);

  const handleDelete = useCallback(
    id => {
      dispatch(ThunkDeleteMessagingConfiguration(id))
        .unwrap()
        .then(() => {
          setOpenDeleteDialog(false);
          setSelectedConfiguration(undefined);
        });
    },
    [dispatch],
  );

  const handleDeleteClick = useCallback(item => {
    setSelectedConfiguration(item);
    setOpenDeleteDialog(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'PSA',
        value: 'crmName',
      },
      {
        label: 'PSA Default',
        value: 'crmDefaultName',
      },
      {
        label: 'Phone Number',
        value: 'mainPhoneNumber',
        accessor: mainPhoneNumber => (
          <>{nationalFormatPhoneNumber(mainPhoneNumber)}</>
        ),
      },
      {
        label: '',
        handler: item => (
          <div className={classes.tableActions}>
            <Tooltip title="Edit" style={{ cursor: 'pointer' }}>
              <EditIcon
                onClick={() => {
                  setSelectedConfiguration(item);
                  setOpenAddConfigurationDialog(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Logs" style={{ cursor: 'pointer' }}>
              <ViewIcon
                onClick={() => {
                  setSelectedConfiguration(item);
                  setOpenLogsDialog(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete" style={{ cursor: 'pointer' }}>
              <DeleteIcon
                onClick={() => {
                  handleDeleteClick(item);
                }}
              />
            </Tooltip>
          </div>
        ),
      },
    ],
    [classes, handleDeleteClick],
  );

  const onMount = useCallback(() => {
    dispatch(ThunkGetMessagingConfigurations());
  }, [dispatch, isNewConfiguration]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (isLoading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.actionsContainer}>
        <ReusableButton
          label="Add"
          onClick={() => {
            setOpenAddConfigurationDialog(true);
          }}
        />
      </div>
      <div>
        <SimpleTable
          columns={columns}
          selector={MessagingConfigurationsSelector}
        />
      </div>
      {openAddConfigurationDialog && (
        <AddConfigurationDialog
          open={openAddConfigurationDialog}
          setOpen={setOpenAddConfigurationDialog}
          item={selectedConfiguration}
          setItem={setSelectedConfiguration}
          setIsNewConfiguration={setIsNewConfiguration}
        />
      )}

      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          onCancel={() => {
            setOpenDeleteDialog(false);
            setSelectedConfiguration(undefined);
          }}
          title="Confirm action"
          content="Are you sure you want to delete this item?"
          onAccept={() => {
            handleDelete(selectedConfiguration.id);
          }}
        />
      )}

      {openLogsDialog && (
        <ConfigurationLogsDialog
          open={openLogsDialog}
          setOpen={setOpenLogsDialog}
          item={selectedConfiguration}
        />
      )}
    </div>
  );
};

MessagingConfigurations.propTypes = {};

export default MessagingConfigurations;
