import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHaloContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetHaloContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getHaloContactData`,
  async ({ crmId, contactId }) => getHaloContactData(crmId, contactId),
);

export default {};
