import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { user } from '@constants/appRoutes';

export const sanitizeProps = ({
  createPage,
  currentSort,
  defaultTitle,
  displayedFilters,
  hideFilter,
  syncWithLocation,
  ...sanitizedProps
}) => sanitizedProps;

export const getUserData = id => getData(`${appConfig.baseUrl}${user}${id}`);

export const getRolesOptions = () =>
  getData(`${appConfig.baseUrl}${user}roles`);
