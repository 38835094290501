import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    height: '25px',
    alignItems: 'center',
    '& > button': {
      marginLeft: '10px',
    },
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
  dialogUserInfoContainer: {
    margin: '5px 0px',
    '& > div': {
      '& > b': {
        marginRight: '5px',
      },
      '& > span': {
        marginLeft: '10px',
        cursor: 'pointer',
      },
    },
  },
  form: {
    width: '80%',
    height: '87vh',
    overflowY: 'auto',
    margin: '20px auto',
    overflowX: 'hidden',
    padding: '10px 30px',
  },
  twoRows: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    '@media (max-width: 767px)': {
      gridTemplateColumns: 'unset',
      gridTemplateRows: '1fr 1fr',
      rowGap: '10px',
      padding: '10px',
    },
  },
}));

export default useStyles;
