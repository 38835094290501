import React from 'react';
import { Typography, Box } from '@material-ui/core';

import iconAlarm from '@assets/icons/alarm.svg';
import useStyles from '../styles';

const NoData = () => {
  const classes = useStyles();
  return (
    <Box className={classes.notFoundDataBlock}>
      <div className={classes.imgTextWrapper}>
        <img
          src={iconAlarm}
          className={classes.notFoundDataBlockImage}
          alt="iconAlarm"
        />
        <Typography className={classes.notFoundDataBlockText}>
          no data
        </Typography>
      </div>
    </Box>
  );
};

export default NoData;
