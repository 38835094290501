import React from 'react';
import { useRedirect } from 'react-admin';
import PropTypes from 'prop-types';
import { INTEGRATIONS } from '@constants/routes';
import { getCrmImage } from '../helpers';
import useStyles from '../styles';

const CrmCard = ({ item }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleClick = event => {
    if (event.target.type !== 'checkbox') {
      redirect(`${INTEGRATIONS}/${item.id}`);
    }
  };

  return (
    <div
      className={classes.container}
      role="presentation"
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <img
        src={getCrmImage(item.crmType)}
        className={classes.imageStyle}
        alt=""
      />
      <div>
        <b className={classes.text}>{item.name}</b>
      </div>
      <div className={classes.truncate} />
    </div>
  );
};

CrmCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CrmCard;
