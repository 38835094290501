import { actionTypes } from '../actions';

const defaultState = {
  subscribed: false,
};

const signalRSubscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SIGNAL_R_SUBSCRIPTION:
      return { subscribed: action.payload };
    default:
      return state;
  }
};

export default signalRSubscriptionReducer;
