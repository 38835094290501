import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChannelLogs } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';
import getFormattedDate from '@utils/getFormattedDate';

export const ThunkGetChannelLogs = createAsyncThunk(
  `${MESSAGING}/getChannelLogs`,
  async ({ channelId, configurationId }) => {
    const logs = await getChannelLogs(configurationId, channelId);
    return logs.map(({ dateTime, message, isSuccess, id }) => ({
      text: `[${getFormattedDate(dateTime)}]: ${message}`,
      color: isSuccess ? 'black' : 'red',
      id,
    }));
  },
);

export default {};
