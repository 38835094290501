import { merakiBasic } from '@constants/webHookTemplatesData';

const webHookTemplates = [
  {
    name: 'Cisco Meraki',
    items: [{ label: 'Basic template', value: merakiBasic }],
  },
  {
    name: 'Autotask',
    items: [{ label: 'Ticket hook', dialog: 'atTicketDialog' }],
  },
];

export default webHookTemplates;
