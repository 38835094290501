import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { TextField } from '@material-ui/core';
import {
  deleteTemplateData,
  getTemplateOptions,
} from '@components/DataSources/helpers';
import useAsync from '@services/api/common/useAsync';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import iconDelete from '@assets/icons/delete.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

import useStyles from '../styles';

const TemplateEditor = ({
  loadSettings,
  updateSettings,
  resetSettings,
  createSettings,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [templateSelected, setTemplateSelected] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  useAsync(getTemplateOptions, setOptions);

  const onDropdownChange = (_, item) => {
    if (item) {
      loadSettings(item.value);
    }
    setSelectedValue(item);
    setTemplateSelected(!!item);
  };

  const onSubmit = values => {
    if (templateSelected) {
      updateSettings(selectedValue.value);
    } else {
      if (!values.name) {
        enqueueSnackbar('Template name required', { variant: 'warning' });
        return;
      }
      createSettings(values);
    }
  };

  const onReset = form => {
    form.resetFieldState('name');
    form.reset();
    setTemplateSelected(false);
    setSelectedValue(0);
    resetSettings();
  };

  const handleDelete = () => {
    deleteTemplateData(selectedValue.value)
      .then(() => {
        const newOptions = [...options].filter(
          i => i.value !== selectedValue.value,
        );
        setOptions(newOptions);
        setSelectedValue(0);
        setTemplateSelected(false);
        enqueueSnackbar('Template erased', { variant: 'success' });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  return (
    <div className={classes.radioGroup}>
      <Autocomplete
        size="small"
        className={classes.dropDown}
        options={options}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField {...params} variant="outlined" label="Select Template" />
        )}
        value={selectedValue}
        onChange={onDropdownChange}
        disabled={!options.length}
      />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.radioGroup}>
              <Field
                id="name"
                name="name"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                placeholder="Input template name"
                disabled={templateSelected}
              />
              <ReusableButton
                size="md"
                viewType="black"
                type="submit"
                label="Save"
                classNameWrapper={classes.submitButton}
              />
              <ReusableButton
                size="md"
                type="button"
                label="Reset"
                onClick={() => onReset(form)}
                classNameWrapper={classes.submitButton}
              />
            </div>
          </form>
        )}
      />
      <ActionButton
        icon={<img src={iconDelete} alt="iconDelete" />}
        handler={handleDelete}
        disabled={!templateSelected}
      />
    </div>
  );
};

TemplateEditor.propTypes = {
  loadSettings: PropTypes.func,
  updateSettings: PropTypes.func,
  resetSettings: PropTypes.func,
  createSettings: PropTypes.func,
};

export default TemplateEditor;
