import React from 'react';
import PropTypes from 'prop-types';
import { integrationSources } from '@constants/integrationSources';
import MerakiForm from './MerakiForm';
import SophosForm from './SophosForm';

const FormSelector = ({ type, classes, loading, useLabels }) =>
  ({
    [integrationSources.Meraki.name]: (
      <MerakiForm loading={loading} classes={classes} useLabels={useLabels} />
    ),
    [integrationSources.Sophos.name]: (
      <SophosForm loading={loading} classes={classes} useLabels={useLabels} />
    ),
  }[type] || null);

FormSelector.propTypes = {
  type: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default FormSelector;
