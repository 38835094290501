import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@material-ui/core';
import { Mail as MailIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { addHistory } from '@store/slices/messaging';
import { actions } from '@store/actions';
import signalRMessageTypes from '@constants/signalRMessageTypes';
import { getPredefinedEvent } from '@constants/signalRMessageEvents';
import eventBus from '@services/eventBus';
import { socketMessageEvents as events } from '@constants/events';
import { getTenantIdFromStorage } from '@services/api';
import NotificationItem from './NotificationItem';

import useStyles from './styles';
import { useSignalREvent } from '../../../../../../hooks/signalR';

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const anchorRef = useRef(null);

  const handleHideNotification = e => {
    const items = [...menuItems];
    setMenuItems(items.filter(i => i.id !== e));
    setMenuOpen(false);
  };

  const onMessageHandler = useCallback(
    message => {
      const menuItemData = getPredefinedEvent(message);
      if (menuItemData && menuItemData.tenantId === getTenantIdFromStorage()) {
        eventBus.dispatch(events.SocketMessageReceived, message);
        if (!menuItems.some(e => e.text === menuItemData.text)) {
          const items = [...menuItems, menuItemData];
          setMenuItems(items);
        }
      }
    },
    [menuItems],
  );

  const onMessagingChannelCreation = useCallback(
    msg => {
      const tenantId = localStorage.getItem('tenantId');
      const { ConfigId, ChannelId, TenantId } = JSON.parse(msg);
      if (Number(tenantId) === Number(TenantId)) {
        const items = [
          ...menuItems,
          {
            id: ChannelId,
            text: `New Messaging Channel`,
            link: `#/messagingChannels/${ConfigId}/${ChannelId}`,
          },
        ];
        setMenuItems(items);
      }
    },
    [menuItems],
  );

  const onPsaTechVerificationSmsRequest = useCallback(
    msg => {
      const tenantId = localStorage.getItem('tenantId');
      const {
        TenantId,
        Id,
        From,
        To,
        CreatedDate,
        Code,
        ContactId,
        Type,
        CrmId,
        ContactName,
        IsVerified,
        TicketId,
        TechAccountId,
        TechAccountName,
      } = JSON.parse(msg);
      if (Number(tenantId) === Number(TenantId)) {
        if (window.location.hash === `#/psaTechVerification`) {
          dispatch(
            actions.updateItemInList({
              id: Id,
              from: From,
              to: To,
              createdDate: CreatedDate,
              isVerified: IsVerified ?? undefined,
              code: Code ?? undefined,
              contactId: ContactId ?? undefined,
              type: Type,
              crmId: CrmId ?? undefined,
              contactName: ContactName ?? undefined,
              ticketId: TicketId ?? undefined,
              techAccountId: TechAccountId ?? undefined,
              techAccountName: TechAccountName ?? undefined,
            }),
          );
        } else {
          setMenuItems([
            ...menuItems,
            {
              text: `New Tech Verification Request`,
              link: `#/psaTechVerification`,
            },
          ]);
        }
      }
    },
    [menuItems],
  );

  const onMessagingChannelMessage = useCallback(
    msg => {
      const {
        ConfigId,
        ChannelId,
        From,
        To,
        Message,
        MediaUrls = [],
      } = JSON.parse(msg);

      if (
        window.location.hash === `#/messagingChannels/${ConfigId}/${ChannelId}`
      ) {
        dispatch(
          addHistory({
            from: From,
            to: To,
            body: Message,
            dateTimeUtc: new Date().toISOString(),
            mediaUrls: MediaUrls || [],
            id: new Date().getTime(),
            messageSid: new Date().getTime(),
          }),
        );
      } else {
        const link = `#/messagingChannels/${ConfigId}/${ChannelId}`;

        if (menuItems.find(item => item.id === ChannelId)) {
          const textArray = menuItems
            .find(item => item.id === ChannelId)
            .text.split(' ');
          setMenuItems(
            menuItems.map(i =>
              i.id === ChannelId
                ? {
                    id: ChannelId,
                    text: `${textArray[0]++} new messages`,
                    link,
                  }
                : i,
            ),
          );
        } else {
          const items = [
            ...menuItems,
            {
              id: ChannelId,
              text: `1 new message`,
              link,
            },
          ];
          setMenuItems(items);
        }
      }
    },
    [dispatch, menuItems],
  );

  useSignalREvent(
    signalRMessageTypes.psaTechVerificationSmsRequestEvent,
    onPsaTechVerificationSmsRequest,
  );

  useSignalREvent(signalRMessageTypes.event, onMessageHandler);

  useSignalREvent(
    signalRMessageTypes.messagingChannelCreated,
    onMessagingChannelCreation,
  );
  useSignalREvent(
    signalRMessageTypes.messagingChannelMessage,
    onMessagingChannelMessage,
  );

  const handleToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  const prevOpen = useRef(menuOpen);
  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  return (
    <div className={classes.container}>
      <Tooltip title="Notifications">
        <Badge
          overlap="rectangular"
          badgeContent={menuItems.length}
          color="primary"
          ref={anchorRef}
          aria-controls={menuOpen ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MailIcon />
        </Badge>
      </Tooltip>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  {menuItems.map(i => (
                    <NotificationItem
                      key={i.id}
                      id={i.id}
                      link={i.link}
                      text={i.text}
                      onClose={handleHideNotification}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Notifications;
