import { createAsyncThunk } from '@reduxjs/toolkit';
import { addNewTeamsConnections } from '@services/requests/teamsConnections';
import { TEAMS_CONNECTIONS } from '@store/slices/resources';

export const ThunkAddTeamsConnections = createAsyncThunk(
  `${TEAMS_CONNECTIONS}/addNewTeamsConnections`,
  async payload => {
    const res = await addNewTeamsConnections(payload);
    return res;
  },
);

export default {};
