import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRICE_PLANS } from '@store/slices/resources';
import { getPricePlan } from '@services/requests/billing';

export const ThunkGetPricePlans = createAsyncThunk(
  `${PRICE_PLANS}/getPricePlan`,
  () => getPricePlan(),
);

export default {};
