import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    background: theme.palette.homePage.dashboard.background,
    padding: '1.9375rem 2.3125rem 0.9375rem 0',
    border: '0.0625rem solid #EBEBEB',
    borderRadius: '0.8125rem',
  },
  chartWrapper: {
    '& .recharts-legend-item': {
      marginRight: '1.875rem !important',

      '&:last-child': {
        marginRight: '0 !important',
      },
    },

    '& .recharts-surface': {
      marginRight: '0.375rem !important',
      marginBottom: '0.125rem',
    },
  },
}));

export default useStyles;
