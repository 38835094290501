const extractRequiredFields = fieldsArray =>
  fieldsArray.reduce((acc, element) => {
    acc.push(element);
    if (element.groupFields) {
      element.groupFields.forEach(groupField => {
        acc.push(groupField);
      });
    }
    return acc;
  }, []);

export default extractRequiredFields;
