const statusColors = {
  online: '#73c766',
  alerting: '#ff0000',
  offline: '#8b8b8b',
  dormant: '#444444',
  high: '#f00016',
  medium: '#ece837',
  low: '#a9ec80',
};

export default statusColors;
