import { AUTH } from '@store/slices/resources';
import { createSlice } from '@reduxjs/toolkit';
import {
  ThunkExternalLogin,
  ThunkLogin,
  ThunkMfaLogin,
} from '@store/slices/auth/thunks';

const authSlice = createSlice({
  name: AUTH,
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkMfaLogin.fulfilled, (_, action) => {
      if (action.payload?.userId) {
        const keys = Object.keys(action.payload);
        keys.forEach(i => {
          localStorage.setItem(i, action.payload[i]);
        });
      }
    });

    builder.addCase(ThunkExternalLogin.fulfilled, (_, action) => {
      if (action.payload?.userId) {
        const keys = Object.keys(action.payload);
        keys.forEach(i => {
          localStorage.setItem(i, action.payload[i]);
        });
      }
    });
  },
});

export default authSlice.reducer;
