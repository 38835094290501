import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0.5rem 16px 0 16px',
    '& tbody': {
      maxHeight: '68vh',
    },
  },
  height: {
    '& tbody': {
      maxHeight: '78vh',
    },
  },
  tableWrapper: {
    marginTop: '0.5rem',
  },
  activeFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
  marginTop: {
    marginTop: '1rem',
  },
  buttonWrapper: {
    margin: '0 0 5px 25px',
  },
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
  },
  copyLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    alignItems: 'flex-end',
  },
  ftpTableContainer: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  ftpActionButton: {
    marginBottom: '8px',
  },
  subscriptionsTable: {
    margin: '0.5rem 16px 0',
    '& tbody': {
      maxHeight: '79vh',
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
  },
  tenantContainer: {
    margin: '0.5rem 16px 0 16px',
    '& tbody': {
      maxHeight: '79vh',
    },
  },
}));

export default useStyles;
