import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  ThunkAddConnectWiseTicketScheduleEntry,
  ThunkEditConnectWiseTicketScheduleEntry,
  ThunkGetConnectWiseTicketLocations,
  ThunkGetConnectWiseTicketMembers,
  ThunkGetConnectWiseTicketScheduleEntry,
  ThunkGetConnectWiseTicketScheduleStatuses,
} from '@store/slices/connectWiseTicket/thunks';
import { currentUserDataSelector } from '@store/selectors';

import { requiredValidator } from '@utils/validators';

import Loading from '@ui/components/common/Loading/Loading';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import ReusableButton from '@ui/components/common/Button/Button';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import Input from '@ui/components/Auth/Common/Input';
import DropDown from '@ui/components/Auth/Common/DropDown';

import useSharedStyles from '../sharedStyles';

const AddScheduleEntryDialog = ({
  open,
  setOpen,
  crmDefaultId,
  ticketId,
  scheduleEntryId = undefined,
  setSelectedItem = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [scheduleEntry, setScheduleEntry] = useState(undefined);

  const dispatch = useDispatch();
  const classes = useSharedStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { connectwiseLinkedUser } = useSelector(currentUserDataSelector);

  // * form states
  const [members, setMembers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [scheduleStatuses, setScheduleStatuses] = useState([]);

  const initialFormData = {
    dateStart: moment().format('YYYY-MM-DDTHH:mm'),
    dateEnd: moment()
      .add(30, 'm')
      .format('YYYY-MM-DDTHH:mm'),
    memberId:
      +crmDefaultId === connectwiseLinkedUser?.crmDefaultId
        ? connectwiseLinkedUser?.userId
        : '',
  };

  const submit = async values => {
    setProcessing(true);
    const payload = {
      ...values,
      ticketId,
      dateStart: moment(values.dateStart)
        .utc()
        .toISOString(),
      dateEnd: moment(values.dateEnd)
        .utc()
        .toISOString(),
    };

    if (scheduleEntryId) {
      // * EDIT
      await dispatch(
        ThunkEditConnectWiseTicketScheduleEntry({
          crmId: crmDefaultId,
          payload,
          scheduleEntryId,
        }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar('Successfully edited', { variant: 'success' });
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      // * ADD
      await dispatch(
        ThunkAddConnectWiseTicketScheduleEntry({
          crmId: crmDefaultId,
          payload,
        }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar('Successfully added', { variant: 'success' });
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const validate = values => {
    return {
      memberId: requiredValidator(values.memberId),
      locationId: requiredValidator(values.locationId),
      dateStart: requiredValidator(values.dateStart),
      dateEnd: requiredValidator(values.dateEnd),
      scheduleStatusId: requiredValidator(values.scheduleStatusId),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);

    if (scheduleEntryId) {
      const scheduleEntryData = await dispatch(
        ThunkGetConnectWiseTicketScheduleEntry({
          crmId: crmDefaultId,
          scheduleEntryId,
        }),
      ).unwrap();
      setScheduleEntry(scheduleEntryData);
    }

    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketMembers(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetConnectWiseTicketLocations(crmDefaultId)).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketScheduleStatuses(crmDefaultId),
          ).unwrap(),
        ),
      ),
    ]).then(res => {
      setMembers(res[0]);
      setLocations(res[1]);
      setScheduleStatuses(res[2]);
    });

    setLoading(false);
  }, [dispatch, crmDefaultId, scheduleEntryId]);

  useEffect(() => {
    onMount();
  }, [onMount]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setSelectedItem(undefined);
      }}
    >
      <DialogTitle>
        {scheduleEntryId ? 'Edit ' : 'Add '}
        Schedule Entry
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            validate={validate}
            initialValues={scheduleEntryId ? scheduleEntry : initialFormData}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Member"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="memberId"
                      id="memberId"
                      render={AutocompleteFormInput}
                      items={members}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Location"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="locationId"
                      id="locationId"
                      render={AutocompleteFormInput}
                      items={locations}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Start Time"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="dateStart"
                      id="dateStart"
                      render={Input}
                      disabled={processing}
                      type="datetime-local"
                    />
                  }
                />

                <FieldWrapper
                  label="End Time"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="dateEnd"
                      id="dateEnd"
                      render={Input}
                      disabled={processing}
                      type="datetime-local"
                    />
                  }
                />

                <FieldWrapper
                  label="Status"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="scheduleStatusId"
                      id="scheduleStatusId"
                      render={DropDown}
                      options={scheduleStatuses}
                      disabled={processing || scheduleEntryId}
                    />
                  }
                />

                <div className={classes.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      setOpen(false);
                      setSelectedItem(undefined);
                    }}
                    disabled={processing}
                  />

                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
AddScheduleEntryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  crmDefaultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scheduleEntryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedItem: PropTypes.func,
};
export default AddScheduleEntryDialog;
