import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_BLACK_LIST } from '@store/slices/resources';
import { addSmsBlackListNumber } from '@services/requests/smsBlackList';

export const ThunkAddSmsBlackListNumber = createAsyncThunk(
  `${SMS_BLACK_LIST}/addSmsBlackListNumber`,
  async phoneNumber => {
    const data = await addSmsBlackListNumber(phoneNumber);
    return data;
  },
);

export default {};
