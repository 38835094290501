import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  tableContainer: {
    margin: '1rem 16px 0',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  button: {
    float: 'right',
    marginBottom: '8px',
  },
  logDetailHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  item: {
    background: '#c9c9c9',
    padding: '10px',
    borderRadius: '5px',
    boxSizing: 'border-box',
    color: props => (props.error ? 'red' : 'unset'),
    flex: 1,
  },
  object: {
    marginLeft: '10px',
    background: '#c9c9c9',
    marginTop: '10px',
    padding: '10px',
    boxSizing: 'border-box',
  },
  detailsItems: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    gap: '5px',
  },
}));

export default useStyles;
