import React, { useMemo, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';

import CreateSmsMessage from './components/CreateSmsMessage';
import SelectRecipients from './components/SelectRecipients';

const SmsBroadcast = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [message, setMessage] = useState('');

  const steps = [
    { id: 0, label: 'Create Message' },
    { id: 1, label: 'Select Recipient' },
  ];

  const handleNext = text => {
    setMessage(text);
    setActiveStep(activeStep + 1);
  };

  const content = useMemo(() => {
    switch (activeStep) {
      case 0:
        return <CreateSmsMessage handleNext={handleNext} />;
      case 1:
        return <SelectRecipients message={message} />;
      default:
        return <CreateSmsMessage handleNext={handleNext} />;
    }
  }, [activeStep, handleNext]);

  return (
    <div>
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(({ label, id }) => (
            <Step key={id}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div>{content}</div>
    </div>
  );
};

export default SmsBroadcast;
