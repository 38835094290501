import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { createCompaniesVerificationSettings } from '@services/requests/verificationSettings';

export const ThunkCreateCompaniesVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/createCompaniesVerificationSettings`,
  companySettingId => createCompaniesVerificationSettings(companySettingId),
);

export default {};
