export const ISSUE_TYPE_ITEMS_NAME = 'questions';

/*
 * Input Types
 * 1 - text
 * 2 - text input
 * 3 - checkboxes
 * */
export const defaultQuestion = Object.freeze({
  inputType: 1,
  title: 'New Question',
  required: false,
});

export const inputTypes = Object.freeze([
  { label: 'Label', value: 1 },
  { label: 'Input', value: 2 },
  {
    label: 'Checkbox',
    value: 3,
  },
  {
    label: 'Multi-Select',
    value: 4,
  },
]);
