import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Input from '@common/Input/Input';
import appConfig from '@configs/appConfig';
import useAsync from '@services/api/common/useAsync';
import getData from '@services/api/common/getData';
import { greenColor, redColor } from '@constants/palette';
import useDebounce from '@utils/useDebounce';
import ReusableButton from '@common/Button/Button';
import InfoBox from '@common/InfoBox/InfoBox';
import { getQuery, setQuery } from '@common/ReactQueryBuilder/helpers';
import { actions } from '@store/actions';
import { useDispatch } from 'react-redux';
import ReactQueryBuilder from '@common/ReactQueryBuilder/ReactQueryBuilder';
import Accordion from './component/Accordion';
import AfterSubmitActions from '../AfterSubmitActions/AfterSubmitActions';
import FieldTransformer from '../FieldTransformer/FieldTransformer';
import ParserForm from '../ParserForm/ParserForm';
import DataExpression from '../DataExpression/DataExpression';
import IncidentForm from './component/IncidentForm';
import useStyles from './styles';

const parserElement = 'parserElement';

const incidentInitial = {
  createIncident: false,
  priority: 0,
  createIfSubjectIsUnique: false,
  triggerRule: 1,
  periodSize: '2',
  periodType: 2,
};

const initialData = {
  name: '',
  fieldTransformers: [],
  expressions: [],
  identifierData: {
    rules: [{ field: 'Subject', value: '', operator: 'Equals' }],
    combinator: 'Or',
    not: false,
  },
  identFilled: false,
  incidentField: incidentInitial,
};

const ParserRootComponent = ({
  onSubmit,
  parser,
  onTest,
  selectableMode,
  setSelectableSelMode,
  onChange,
  afterSubmitAction,
  setAfterSubmitAction,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getExpressionName = value => {
    const match = value.match(/\?<(.*?)>/g)[0];
    return match.substring(2, match.length - 1);
  };

  const initData = useMemo(() => {
    if (parser) {
      const parserData = JSON.parse(parser.parserData);
      const fieldTransformer = JSON.parse(parser.fieldTransformer);
      const identifierQuery = getQuery(parserData.identifier);
      dispatch(
        actions.setSelectedExpressionNames(
          parserData.data.map(i => getExpressionName(i.value)),
        ),
      );
      return {
        identFilled: !!identifierQuery?.rules.length,
        name: parser.name,
        identifierData: identifierQuery,
        expressions: parserData.data,
        fieldTransformers: fieldTransformer,
        incidentField: {
          createIncident: parser.createIncident,
          priority: parser.priority,
          createIfSubjectIsUnique: parser.createIfSubjectIsUnique,
          triggerRule: parser.triggerRule,
          periodSize: parser.periodSize,
          periodType: parser.periodType,
        },
      };
    }
    return initialData;
  }, [dispatch, parser]);

  const [fieldTransformers, setFieldTransformers] = useState(
    initData.fieldTransformers,
  );

  const [nameInvalid, setNameInvalid] = useState(false);
  const [name, setName] = useState(initData.name);
  const [expressions, setExpressions] = useState(initData.expressions);

  const [showDataExpr, setShowDataExpr] = useState(false);
  const [currentDataExpr, setCurrentDataExpr] = useState(null);
  const [queryBuilderTouched, setQueryBuilderTouched] = useState(false);
  const [types, setTypes] = useState([]);
  const [incidentInfo, setIncidentInfo] = useState(initData.incidentField);

  const [identityQueryData, setIdentityQueryData] = useState(
    initData.identifierData,
  );
  const [identifierFilled, setIdentifierFilled] = useState(
    initData.identFilled,
  );

  const getTypes = useCallback(
    () => getData(appConfig.baseUrl.concat('/Types/allExtended')),
    [],
  );
  useAsync(getTypes, setTypes);

  const hideDataExpr = () => setShowDataExpr(false);
  const displayDataExpr = () => setShowDataExpr(true);

  const onIdentifierChange = data => {
    setIdentifierFilled(!!data?.rules.length);
    onChange();
    setQueryBuilderTouched(true);
    setIdentityQueryData(data);
  };

  const getIdentifier = () => {
    if (parser && !queryBuilderTouched) {
      const parserData = JSON.parse(parser.parserData);
      return parserData.identifier;
    }

    return setQuery(identityQueryData);
  };

  const onDataExprCreate = () => {
    onChange();
    setCurrentDataExpr(null);
    displayDataExpr();
  };

  const onDataExprSubmit = (value, addNext = false) => {
    onChange();
    const expr = [...expressions];
    if (value.id === 0) {
      const ids = expr.map(i => i.id);
      const maxId = ids.length > 0 ? Math.max(...ids) : 0;
      const newData = {
        ...value,
        id: maxId + 1,
      };
      expr.push(newData);
    } else {
      const index = expr.findIndex(i => i.id === value.id);
      expr[index] = value;
    }
    dispatch(
      actions.setSelectedExpressionNames(
        expr.map(i => getExpressionName(i.value)),
      ),
    );
    setExpressions(expr);
    if (!addNext) {
      hideDataExpr();
    } else {
      setCurrentDataExpr(null);
    }
  };

  const onDataExpressionEdit = value => {
    onChange();
    setCurrentDataExpr(value);
    displayDataExpr(true);
  };

  const onDataExpressionDelete = value => {
    onChange();
    const newExpressions = expressions.filter(p => {
      if (p.id === value) {
        dispatch(
          actions.deleteSelectedExpressionName(getExpressionName(p.value)),
        );
      }
      return p.id !== value;
    });
    setExpressions(newExpressions);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetSelectedExpressionNames());
    };
  }, [dispatch]);

  const debounceName = useDebounce(name, 500);

  useEffect(() => {
    if (debounceName && debounceName !== initData.name) {
      getData(`${appConfig.baseUrl}/Parsers/isExists?parserName=${name}`)
        .then(response => {
          setNameInvalid(response);
          if (response) {
            enqueueSnackbar(
              `Parser with the ${name} name already exists, choose another one`,
              { variant: 'warning' },
            );
          }
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
      onChange();
    }
  }, [initData.name, name, onChange, debounceName, enqueueSnackbar]);

  const collectData = () => {
    return {
      name,
      identifier: getIdentifier(),
      data: expressions,
      fieldTransformer: JSON.stringify(fieldTransformers.filter(p => p.name)),
      ...incidentInfo,
    };
  };

  const handleSubmit = () => onSubmit(collectData());
  const handleTest = () => onTest(collectData());

  const getContent = () =>
    showDataExpr ? (
      <DataExpression
        onCancel={hideDataExpr}
        onSubmit={onDataExprSubmit}
        externalData={currentDataExpr}
        selectableMode={selectableMode}
        setSelectableSelMode={setSelectableSelMode}
      />
    ) : (
      <ParserForm
        displayDataExpr={onDataExprCreate}
        expressions={expressions}
        onItemEdit={onDataExpressionEdit}
        onItemDelete={onDataExpressionDelete}
      />
    );

  return (
    <div className={classes.spacing} id={parserElement}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form onSubmit={() => {}}>
            {() => (
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                style={{ width: '100%' }}
              >
                <Field
                  name="name"
                  id="outlined-basic"
                  placeholder="Parser name"
                  styleType="main"
                  fullWidth
                  input={{
                    onChange: e => setName(e.target.value),
                    value: name,
                  }}
                  inputView="text"
                  component={Input}
                />
              </form>
            )}
          </Form>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            details={
              <ReactQueryBuilder
                data={identityQueryData}
                onChange={onIdentifierChange}
              />
            }
          >
            <span className={classes.heading}>
              <div
                className={classes.circle}
                style={
                  !identifierFilled
                    ? { backgroundColor: `${redColor}` }
                    : { backgroundColor: `${greenColor}` }
                }
              />
              Identifier
            </span>
            <span className={classes.secondaryHeading}>
              Select message identifier
              <InfoBox text="Define the rules to identify this type of letter" />
            </span>
          </Accordion>
          <Accordion details={getContent()}>
            <span className={classes.heading}>
              <div
                className={classes.circle}
                style={
                  expressions.length === 0
                    ? { backgroundColor: `${redColor}` }
                    : { backgroundColor: `${greenColor}` }
                }
              />
              Data Expressions
            </span>
            <span className={classes.secondaryHeading}>
              Define data selectors
              <InfoBox text="Create regular expressions to parse certain information from the letter" />
            </span>
          </Accordion>
          <Accordion
            details={
              <FieldTransformer
                fld={fieldTransformers}
                setFld={setFieldTransformers}
                expressions={expressions}
                typesOptions={types}
              />
            }
          >
            <span className={classes.heading}>
              <div
                className={classes.circle}
                style={
                  fieldTransformers.length === 0
                    ? { backgroundColor: `${redColor}` }
                    : { backgroundColor: `${greenColor}` }
                }
              />
              Field transformer
            </span>
            <span className={classes.secondaryHeading}>
              Select fields to extract
              <InfoBox text="Define field names and types of parsed values to be stored" />
            </span>
          </Accordion>
          <Accordion
            details={
              <IncidentForm values={incidentInfo} setValues={setIncidentInfo} />
            }
          >
            <span className={classes.heading}>
              <div
                className={classes.circle}
                style={{ backgroundColor: `${greenColor}` }}
              />
              Incidents
            </span>
            <span className={classes.secondaryHeading}>
              Create incident
              <InfoBox text="Create incident after message parsing" />
            </span>
          </Accordion>
          {parser && (
            <AfterSubmitActions
              value={afterSubmitAction}
              setValue={setAfterSubmitAction}
            />
          )}
          <div className={classes.spacing}>
            <ReusableButton
              viewType="black"
              classNameWrapper={classes.createParserButtonWrapper}
              onClick={handleSubmit}
              label={parser && parser.isGlobal ? 'Save As' : 'Submit'}
              disabled={nameInvalid || !identifierFilled}
            />
            <ReusableButton
              classNameWrapper={classes.createParserButtonWrapper}
              onClick={handleTest}
              label="Test"
              disabled={!identifierFilled}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ParserRootComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onTest: PropTypes.func.isRequired,
  parser: PropTypes.objectOf(PropTypes.any),
  selectableMode: PropTypes.bool.isRequired,
  setSelectableSelMode: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  afterSubmitAction: PropTypes.string,
  setAfterSubmitAction: PropTypes.func,
};

export default ParserRootComponent;
