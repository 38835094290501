import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ProgressBar = ({ completed, total, amount, unit }) => {
  const classes = useStyles({ completed });
  return (
    <div className={classes.container}>
      <div className={classes.filler} />
      <span className={classes.label}>{`${amount} / ${total} ${unit}`}</span>
    </div>
  );
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number,
  amount: PropTypes.number,
  unit: PropTypes.string.isRequired,
};

export default ProgressBar;
