import { createAsyncThunk } from '@reduxjs/toolkit';
import { linkCrmContactToClientPortal } from '@services/requests/crm';
import { CLIENT_PORTAL_USERS } from '@store/slices/resources';

export const ThunkLinkClientPortalUser = createAsyncThunk(
  `${CLIENT_PORTAL_USERS}/linkClientPortalUser`,
  async payload => {
    const result = await linkCrmContactToClientPortal(payload);
    return result;
  },
);

export default {};
