import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    cursor: 'pointer',
    width: '1.5rem',
    margin: '0 5px',
  },
  monoSpace: {
    fontFamily: 'monospace',
  },
}));

export default useStyles;
