import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaContacts } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaContacts = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaContacts`,
  ({ crmDefaultId, accountId }) => getKaseyaContacts(crmDefaultId, accountId),
);

export default {};
