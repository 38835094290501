import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import ReusableButton from '@ui/components/common/Button/Button';

import { ThunkGetChannelLogs } from '@store/slices/messaging/thunks';
import {
  MessagingChannelSelector,
  MessagingConfigurationSelector,
} from '@store/slices/messaging';
import useStyles from './styles';

const LogsDialog = React.memo(({ open, setOpen, selector }) => {
  const classes = useStyles();
  const logs = useSelector(selector);
  const dispatch = useDispatch();
  const channel = useSelector(MessagingChannelSelector);
  const configuration = useSelector(MessagingConfigurationSelector);

  useEffect(() => {
    if (open && channel && configuration) {
      dispatch(
        ThunkGetChannelLogs({
          channelId: channel.id,
          configurationId: configuration.id,
        }),
      );
    }
  }, [open, channel, configuration, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        <div className={classes.logsDialogHeader}>
          Logs
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          {logs.map(log => (
            <div key={log.id} style={{ color: log.color || 'black' }}>
              {log.id ? `${log.id}:` : null}
              {log.text}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
});

LogsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selector: PropTypes.func,
};
export default LogsDialog;
