import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReusableButton from '@common/Button/Button';
import cx from 'classnames';
import useStyles from '../../styles';

const NetworkItem = ({ id, name, orgName }) => {
  const classes = useStyles();

  return (
    <div
      role="presentation"
      className={cx(
        classes.columnItem,
        classes.networkColumnItem,
        classes.selectIntegrationContainer,
      )}
    >
      <div>
        <span className={classes.grayColor}>Organization:&nbsp;</span>
        <span>
          {orgName}
          &nbsp;
        </span>
      </div>
      <div>
        <span className={classes.grayColor}>Network:&nbsp;</span>
        <span>
          {name}
          &nbsp;
        </span>
      </div>
      <div>
        <span className={classes.grayColor}>Id:&nbsp;</span>
        <span>
          {id}
          &nbsp;
        </span>
      </div>
    </div>
  );
};

const Apply = ({ handleBack, selectedNetworks, handleApply }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.font, classes.marginTop)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.column}>
            {selectedNetworks.map(nw => (
              <NetworkItem
                key={nw.id}
                id={nw.id}
                name={nw.name}
                orgName={nw.orgName}
              />
            ))}
          </div>
        </Grid>
      </Grid>
      <div className={classes.buttonBlock}>
        <ReusableButton
          size="md"
          label="Back"
          onClick={handleBack}
          classNameWrapper={classes.buttonWrapper}
        />
        <ReusableButton
          size="md"
          viewType="black"
          label="Apply"
          onClick={handleApply}
          classNameWrapper={classes.buttonWrapper}
        />
      </div>
    </div>
  );
};

Apply.propTypes = {
  handleBack: PropTypes.func.isRequired,
  selectedNetworks: PropTypes.arrayOf(PropTypes.any),
  handleApply: PropTypes.func.isRequired,
};

export default Apply;
