import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '3rem 20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0px 5px 0px',
    boxSizing: 'border-box',
    '& h1': {
      margin: '0',
    },
    '& p': {
      fontWeight: '300',
      margin: '0',
    },
  },
  pricePlan: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    border: '1px solid #1b36703b',
    borderRadius: '10px',
    padding: '40px 20px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 10px 0px #1b36703b',
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: '0px 0px 10px 0px #1b3670',
      cursor: 'pointer',
    },
  },
  pricePlan_name: {
    margin: '0',
    fontWeight: '600',
    fontSize: '20px',
    color: '#1b3670',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pricePlan_price: {
    margin: '20px 0px',
    color: 'black',
    fontSize: '1rem',
    '& b': {
      fontSize: '1.5rem',
      margin: '5px',
    },
  },
  pricePlan_selected: {
    fontWeight: '400',
    fontSize: 'small',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  pricePlansList: {
    display: 'flex',
    gap: '10px',
    padding: '20px 10px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  benefitsList: {
    margin: '20px 0px',
    fontWeight: '400',
    color: 'grey',
  },
  pricePlan_active: {
    border: '1px solid #1b3670',
  },
  usageContainer: {
    position: 'relative',
    width: '300px',
    height: '300px',
    border: '10px solid #1b3670',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  usageFill: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    clip: 'rect(0, 300px, 300px, 150px)',
  },
  usageFillBefore: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    clip: 'rect(0, 150px, 300px, 0)',
    border: '10px solid #1b3670',
    transform: 'rotate(0deg)',
    transformOrigin: 'center',
  },
  usage: {
    width: '45%',
    padding: '10px',
    boxSizing: 'border-box',
    margin: '0px auto',
    minWidth: '400px',
  },
  usage_box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0px',
    boxSizing: 'border-box',
  },
  usage_text: {
    fontWeight: '700',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    '& svg': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
