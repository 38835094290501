import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getConnectWiseCompanies } from '@services/requests/createTicket';

export const ThunkGetConnectWiseCompanies = createAsyncThunk(
  `${CREATE_TICKET}/getConnectWiseCompanies`,
  ({ psaId }) => getConnectWiseCompanies(psaId),
);

export default {};
