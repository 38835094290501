import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import FormSwitch from '@common/FilterInputs/Switch';

const EmailBodySelector = ({ values, disabled }) => {
  return (
    <FieldWrapper
      label={values.useCustomBody ? 'Custom body' : 'Send parsed data'}
      labelSize={3}
      content={
        <Field
          id="useCustomBody"
          name="useCustomBody"
          type="checkbox"
          viewType="selector"
          component={FormSwitch}
          disabled={disabled}
        />
      }
    />
  );
};

EmailBodySelector.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default EmailBodySelector;
