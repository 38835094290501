import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  BillingDataSelector,
  LastInvoicesSelector,
} from '@store/slices/billing';

import getFormattedDate from '@utils/getFormattedDate';
import { Tooltip } from '@material-ui/core';
import { Link, PictureAsPdfRounded } from '@material-ui/icons';
import ReusableButton from '@common/Button/Button';
import { useHistory } from 'react-router-dom';
import { INVOICES } from '@constants/routes';
import useStyles from '../styles';

const InvoicesList = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const billingData = useSelector(BillingDataSelector);
  const invoices = useSelector(LastInvoicesSelector);

  const openExternalLink = useCallback(
    link => () => {
      window.open(link, '_blank');
    },
    [],
  );

  const redirectToInvoicesTable = useCallback(() => {
    push(INVOICES);
  }, [push]);

  return (
    <div className={classes.invoicesContainer}>
      <div className={classes.header}>Invoices</div>

      {billingData?.isDemoSubscription && (
        <div>Active Subscription to see Invoices</div>
      )}

      {!billingData?.isDemoSubscription && !invoices.length && (
        <div>There are no invoices</div>
      )}

      {!billingData?.isDemoSubscription && invoices.length && (
        <div className={classes.invoicesList}>
          {invoices.map(i => (
            <div key={i.id} className={classes.invoice}>
              <div className={classes.dataRow}>
                <div>
                  <b>{getFormattedDate(i.created)}</b>
                </div>
                <div>
                  <b>
                    {`${
                      i.currency === 'usd' ? '$' : `${i.currency.toUpperCase()}`
                    }${Number(i.total / 100).toFixed(2)}`}
                  </b>
                </div>
              </div>

              <div>{i.description}</div>

              <div className={classes.dataRow}>
                <div className={classes.invoiceNumber}>{`#${i.number}`}</div>

                <div className={classes.icons}>
                  <Tooltip title="Stripe Payment">
                    <Link onClick={openExternalLink(i.receiptUrl)} />
                  </Tooltip>

                  <Tooltip title="Download Invoice">
                    <PictureAsPdfRounded
                      onClick={openExternalLink(i.invoicePdf)}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}

          <div className={classes.seeAll}>
            <ReusableButton
              label="See All"
              viewType="blue"
              onClick={redirectToInvoicesTable}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicesList;
