import { createAsyncThunk } from '@reduxjs/toolkit';
import { assignAutotaskTicketResource } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkAssignAutotaskTicketResource = createAsyncThunk(
  `${CRM_TICKET}/assignAutotaskTicketResource`,
  async ({ crmId, ticketId, resourceId }) => {
    await assignAutotaskTicketResource(crmId, ticketId, resourceId);
    return true;
  },
);

export default {};
