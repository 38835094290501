import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import CustomChip from '@common/Chip/Chip';
import Input from '@common/Input/Input';
import useStyles from '../styles';

const DataSelector = ({
  chipData,
  handleChipsChange,
  handleArrowClick,
  handleDelete,
  inputRef,
  label = 'New identity value',
  setEnteredValue,
}) => {
  const classes = useStyles();
  const handleChange = (form, event) => {
    const { value } = event.target;
    form.change('value', value);
    if (setEnteredValue) {
      setEnteredValue(value);
    }
  };

  const getFormValue = form => form.getState().values.value;

  return (
    <Paper>
      <Form onSubmit={() => {}} initialValues={{ value: '' }}>
        {({ form }) => (
          <>
            <div className={classes.dataSelectorWrapper}>
              <Field
                name="value"
                placeholder={label}
                inputView="text"
                styleType="main"
                helperText={false}
                inputRef={inputRef}
                fullWidth
                classNameWrapper={classes.chipFieldWrapper}
                input={{
                  onChange: event => handleChange(form, event),
                  onKeyPress: event => handleChipsChange(form, event),
                  value: getFormValue(form),
                }}
                component={Input}
              />
              <AddIcon
                onClick={() => handleArrowClick(form)}
                className={classes.iconDown}
              />
            </div>
            <div className={classes.chipsWrapper}>
              {chipData.map(data => {
                return (
                  <li key={data}>
                    <CustomChip
                      label={data.replace('\\', '')}
                      onDelete={() => handleDelete(data)}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </div>
          </>
        )}
      </Form>
    </Paper>
  );
};

DataSelector.propTypes = {
  chipData: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChipsChange: PropTypes.func.isRequired,
  handleArrowClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  inputRef: PropTypes.objectOf(PropTypes.any),
};

export default DataSelector;
