import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { editSmsCampaignGroupItem } from '@services/requests/smsCampaigns';

export const ThunkEditSmsCampaignGroupItem = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/editSmsCampaignGroupItem`,
  async ({ id, payload }) => {
    const data = await editSmsCampaignGroupItem(id, payload);
    return data;
  },
);

export default {};
