import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableContainer,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import useStyles from './styles';

const AccordionWrapper = ({
  summary,
  ariaControls,
  id,
  children,
  defaultExpanded = false,
}) => {
  const classes = useStyles();

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={ariaControls}
        id={id}
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">{children}</Table>
          </TableContainer>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

AccordionWrapper.propTypes = {
  summary: PropTypes.string,
  id: PropTypes.string,
  ariaControls: PropTypes.string,
  children: PropTypes.node,
};

export default AccordionWrapper;
