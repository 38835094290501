import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT_MODULES } from '@store/slices/resources';
import { UpdateAccessOptions } from '@services/requests/admin';

export const ThunkUpdateAccessModules = createAsyncThunk(
  `${TENANT_MODULES}/UpdateAccessModules`,
  async data => {
    const result = await UpdateAccessOptions(data.tenantId, data.values);
    return result;
  },
);

export default {};
