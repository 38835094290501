import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteIncidentNote } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';

export const ThunkDeleteIncidentNote = createAsyncThunk(
  `${INCIDENT}/deleteIncidentNote`,
  async noteId => {
    await deleteIncidentNote(noteId);
    return { noteId };
  },
);

export default {};
