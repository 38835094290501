import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketPriorities } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketPriorities = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketPriorities`,
  async crmId => {
    const priorities = await getConnectWiseTicketPriorities(crmId);
    return priorities;
  },
);

export default {};
