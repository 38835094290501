import clone from 'lodash/clone';
import forEach from 'lodash/forEach';
import { chartTypes } from '@components/Dashboard/Modals/Tile/form';
import {
  barConfig,
  pieConfig,
  columnConfig,
  histogramConfig,
} from './constants';

const colorScheme = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
];

function* generateColors() {
  yield colorScheme[0];
  yield colorScheme[1];
  yield colorScheme[2];
  yield colorScheme[3];
  yield colorScheme[4];
  yield colorScheme[5];
  yield colorScheme[6];
  yield colorScheme[7];
  yield colorScheme[8];
  yield* generateColors();
}

export const getPieConfig = (data, theme) => {
  const chartConfig = clone(pieConfig(theme));
  const colorProvider = generateColors();
  const totalData = [];

  forEach(data.pie, pieItem => {
    totalData.push({
      name: pieItem.title === 'BsonNull' ? '_empty' : pieItem.title,
      y: pieItem.value,
      color: colorProvider.next().value || '#434343',
    });
  });

  // @ts-ignore
  chartConfig.series[0].data = totalData;

  return chartConfig;
};

export const getBarConfig = (data, theme) => {
  const chartConfig = clone(barConfig(theme));
  const colorProvider = generateColors();
  const series = [];
  forEach(data.pie, pieItem => {
    const name = pieItem.title === 'BsonNull' ? '_empty' : pieItem.title;
    series.push({
      name,
      data: [pieItem.value],
      color: colorProvider.next().value,
    });
  });
  chartConfig.xAxis.categories = [data.groupedBy];
  chartConfig.series = series;

  return chartConfig;
};

export const getColumnConfig = (data, theme) => {
  const chartConfig = clone(columnConfig(theme));
  const colorProvider = generateColors();
  const series = [];
  forEach(data.pie, pieItem => {
    const name = pieItem.title === 'BsonNull' ? '_empty' : pieItem.title;
    series.push({
      name,
      data: [pieItem.value],
      color: colorProvider.next().value,
    });
  });
  chartConfig.xAxis.categories = [data.groupedBy];
  chartConfig.series = series;

  return chartConfig;
};

export const getHistogramConfig = (data, theme) => {
  const chartConfig = clone(histogramConfig(theme));
  const series = [];
  const categories = [];
  forEach(data.histogram, item => {
    categories.push(item.date);
    series.push(item.count);
  });
  chartConfig.xAxis.categories = categories;
  chartConfig.series = [{ data: series }];
  return chartConfig;
};

export const selectChartConfig = type => {
  switch (type) {
    case chartTypes.pie:
      return getPieConfig;
    case chartTypes.bar:
      return getBarConfig;
    case chartTypes.column:
      return getColumnConfig;
    case chartTypes.histogram:
      return getHistogramConfig;
    default:
      return () => null;
  }
};
