import { createAsyncThunk } from '@reduxjs/toolkit';
import { assignAutotaskTicketContact } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkAssignAutotaskTicketContact = createAsyncThunk(
  `${CRM_TICKET}/assignAutotaskTicketContact`,
  async ({ crmId, ticketId, contactId }) => {
    await assignAutotaskTicketContact(crmId, ticketId, contactId);
    return true;
  },
);

export default {};
