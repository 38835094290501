import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_CHATS } from '@store/slices/resources';
import { getClientPortalConfigurationOptions } from '@services/requests/clientPortal';

export const ThunkGetClientPortalConfigurationOptions = createAsyncThunk(
  `${CLIENT_PORTAL_CHATS}/getClientPortalConfigurationOptions`,
  () => getClientPortalConfigurationOptions(),
);

export default {};
