import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaAssignees } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaAssignees = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaAssignees`,
  ({ crmDefaultId }) => getKaseyaAssignees(crmDefaultId),
);

export default {};
