/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CRM_TICKET } from '../resources';
import {
  ThunkGetATTicketTimeEntries,
  ThunkAddATTicketTimeEntry,
  ThunkEditAutotaskTicketTimeEntry,
  ThunkDeleteAutotaskTicketTimeEntry,
} from './thunks';

const crmTicketSlice = createSlice({
  name: CRM_TICKET,
  initialState: {
    timeEntries: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ThunkGetATTicketTimeEntries.fulfilled, (state, { payload }) => {
        state.timeEntries = payload;
      })

      .addCase(ThunkAddATTicketTimeEntry.fulfilled, (state, { payload }) => {
        state.timeEntries.push(payload);
      })

      .addCase(
        ThunkEditAutotaskTicketTimeEntry.fulfilled,
        (state, { payload }) => {
          state.timeEntries = state.timeEntries.map(item =>
            item.id === payload.id ? payload : item,
          );
        },
      )

      .addCase(
        ThunkDeleteAutotaskTicketTimeEntry.fulfilled,
        (state, { payload }) => {
          state.timeEntries = state.timeEntries.filter(
            item => item.id !== payload,
          );
        },
      );
  },
});

export default crmTicketSlice.reducer;

export const ATTicketTimeEntriesSelector = state =>
  state[CRM_TICKET].timeEntries;
