import { createAsyncThunk } from '@reduxjs/toolkit';
import { getResourceAssignedTemplates } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetResourceAssignedTemplates = createAsyncThunk(
  `${MESSAGING}/resourceAssignedTemplate`,
  async () => {
    const templates = await getResourceAssignedTemplates();
    return templates;
  },
);

export default {};
