import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '30px',
    padding: '30px 30px',
    boxSizing: 'border-box',
    height: '90vh',
    width: '70%',
    margin: '20px auto',
  },
  header: {
    fontWeight: '800',
    fontSize: '17px',
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > svg': {
      cursor: 'pointer',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: '90%',
  },
  action: {
    color: 'blue',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  column: {
    display: 'grid',
    gap: '20px',
    justifyContent: 'stretch',
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  invoicesContainer: {
    width: '100%',
    height: '100%',
    border: '1px solid #0f0f0f17',
    padding: '30px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    overflowY: 'auto',
  },
  invoicesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  invoice: {
    boxShadow: '0px 1px 2px 1px #B4B4B4',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  dataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  invoiceNumber: {
    color: '#B4B4B4',
  },
  icons: {
    display: 'flex',
    gap: '10px',
    '& > svg': {
      cursor: 'pointer',
    },
  },
  seeAll: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
