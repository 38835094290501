import { getHeaders } from '@utils/transfered';
import localStorage from '@services/localStorage';

const putData = async (url, data) => {
  const request = new Request(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('accessToken');
    window.location.href = '#/login';
    return;
  }
  return response.status >= 200 && response.status < 300;
};

export default putData;
