import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import putData from '@services/api/common/putData';
import {
  escalationRules,
  notificationGroups,
  users,
} from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';

export const getNotificationGroupAutocomplete = () =>
  getData(`${appConfig.baseUrl}${notificationGroups}groups`);

export const getNotificationUsersAutocomplete = () =>
  getData(`${appConfig.baseUrl}${users}/autocomplete?useEmail=false`);

export const toggleIsActive = (id, data) =>
  putData(`${appConfig.baseUrl}${escalationRules}${id}/toggleEnabled`, data);

export const cloneEntity = id =>
  postWithResponse(`${appConfig.baseUrl}${escalationRules}clone/${id}`, null);

export const getTemplateVariables = () =>
  getData(`${appConfig.baseUrl}${escalationRules}getTemplateVariables`);
