import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
}));

export default useStyles;
