import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    minHeight: 25,
    position: 'relative',
    '&:hover': {
      cursor: 'move',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  removeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  editButton: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  tileContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  errorTile: {
    color: 'red',
    textAlign: 'center',
  },
  refreshArea: {
    display: 'flex',
    minHeight: 26,
  },
  grayColor: {
    color: 'gray',
  },
  updatedAgo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
