import React, { useEffect, useState } from 'react';
import Input from '@components/Auth/Common/Input';

const IssueTypeInput = ({
  updateTitle,
  title,
  disabled,
  multiline,
  maxRows,
}) => {
  const [inputTitle, setInputTitle] = useState('');

  useEffect(() => {
    setInputTitle(title);
  }, []);

  return (
    <div>
      <Input
        input={{
          value: inputTitle,
          onChange: e => {
            e.stopPropagation();
            setInputTitle(e.target.value);
          },
          onBlur: () => {
            updateTitle(inputTitle || '');
          },
        }}
        multiline={multiline}
        maxRows={maxRows}
        meta={{ touched: true, error: null }}
        disabled={disabled}
      />
    </div>
  );
};

export default IssueTypeInput;
