import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketBoards } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketBoards = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketBoards`,
  async crmId => {
    const boards = await getConnectWiseTicketBoards(crmId);
    return boards;
  },
);

export default {};
