/* eslint-disable no-param-reassign */
import { getThreshold, getThresholdAndSize } from './helpers';
import alertTypes from './alertTypes';

const groupBy = (arr, key) => {
  const initialValue = {};
  return arr.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};

const merakiAlerts = [
  {
    name: 'A rogue AP is detected',
    type: alertTypes.rogueAp,
    class: 'Network-wide',
  },
  {
    name: 'Configuration settings are changed',
    type: alertTypes.settingsChanged,
    class: 'Network-wide',
  },
  {
    name: 'Network usage exceeds',
    type: alertTypes.usageAlert,
    class: 'Network-wide',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          const size = getThresholdAndSize(+model.filters.threshold);
          form.threshold = size.threshold;
          form.size = size.size;
          form.period = model.filters.period;
        } else {
          form.threshold = 10;
          form.size = 2;
          form.period = 1200;
        }
      },
      formToModel: (form, model) => {
        const threshold = getThreshold(form.threshold, form.size);
        model.filters = {
          threshold,
          period: form.period,
        };
      },
    },
  },
  {
    name: 'A VPN connection comes up or goes down',
    type: alertTypes.vpnConnectivityChange,
    class: 'Network-wide',
    info:
      "This alert is sent when a device's teleworker or site-to-site VPN connection comes up or goes down.",
  },
  {
    name: 'Gateway goes offline',
    type: alertTypes.gatewayDown,
    class: 'Wireless',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.timeout = +model.filters.timeout;
        } else {
          form.timeout = 60;
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          timeout: form.timeout,
        };
      },
    },
  },
  {
    name: 'Repeater goes offline',
    type: alertTypes.repeaterDown,
    class: 'Wireless',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.timeout = +model.filters.timeout;
        } else {
          form.timeout = 60;
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          timeout: form.timeout,
        };
      },
    },
  },
  {
    name: 'Gateway becomes a repeater',
    type: alertTypes.gatewayToRepeater,
    class: 'Wireless',
    info:
      'When an access point loses its wired connection, it becomes a repeater if it is in range of other Meraki access points.',
  },
  {
    name: 'Clients have poor signal strength',
    type: alertTypes.snr,
    class: 'Wireless',
    filterText: 'signal quality (SNR) for more than',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters && model.filters.selector) {
          const selector = JSON.parse(model.filters.selector);
          if (selector.configs.length) {
            const config = selector.configs[0];
            form.duration = +config.duration;
            form.threshold = config.threshold;
            form.ssidNum = 0;
          } else {
            form.duration = 30;
            form.threshold = 'medium';
            form.ssidNum = 0;
          }
        } else {
          form.duration = 30;
          form.threshold = 'medium';
          form.ssidNum = 0;
        }
      },
      formToModel: (form, model) => {
        const selector = {
          configs: [
            {
              duration: form.duration,
              threshold: form.threshold,
              ssidNum: null,
            },
          ],
        };
        model.filters = {
          selector: JSON.stringify(selector),
        };
      },
    },
  },
  {
    name: 'Clients with high bandwidth usage',
    type: alertTypes.highWirelessUsage,
    class: 'Wireless',
    filterText: 'usage for more than',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters && model.filters.selector) {
          const selector = JSON.parse(model.filters.selector);
          if (selector.configs.length) {
            const config = selector.configs[0];
            form.duration = +config.duration;
            form.threshold = config.threshold;
            form.ssidNum = 0;
          } else {
            form.duration = 120;
            form.threshold = 'medium';
            form.ssidNum = 0;
          }
        } else {
          form.duration = 120;
          form.threshold = 'medium';
          form.ssidNum = 0;
        }
      },
      formToModel: (form, model) => {
        const selector = {
          configs: [
            {
              duration: form.duration,
              threshold: form.threshold,
              ssidNum: null,
            },
          ],
        };
        model.filters = {
          selector: JSON.stringify(selector),
        };
      },
    },
  },
  {
    name: 'Clients fail to connect to the wireless network',
    type: alertTypes.onboarding,
    class: 'Wireless',
    filter: {
      modelToForm: (model, form) => {
        form.smartSensitivity = 'medium';
        form.eventReminderPeriodSecs = 10800;
        form.ssidNum = 0;
        if (model && model.filters && model.filters.selector) {
          const selector = JSON.parse(model.filters.selector);
          form.smartEnabled = selector.smartEnabled;
          if (selector.configs.length) {
            const config = selector.configs[0];
            form.duration = config.duration;
            form.threshold = config.threshold;
            form.assoc = config.assoc;
            form.auth = config.auth;
            form.dhcp = config.dhcp;
            form.dns = config.dns;
          } else {
            form.duration = 15;
            form.threshold = 'medium';
            form.ssidNum = 0;
            form.assoc = true;
            form.auth = true;
            form.dhcp = true;
            form.dns = true;
          }
        } else {
          form.smartEnabled = false;
          form.duration = 15;
          form.threshold = 'medium';
          form.ssidNum = 0;
          form.assoc = true;
          form.auth = true;
          form.dhcp = true;
          form.dns = true;
        }
      },
      formToModel: (form, model) => {
        const seleector = {
          smartSensitivity: 'medium',
          eventReminderPeriodSecs: 10800,
          smartEnabled: form.smartEnabled,
          configs: [
            {
              duration: form.duration,
              threshold: form.threshold,
              ssidNum: form.ssidNum,
              assoc: form.assoc,
              auth: form.auth,
              dhcp: form.dhcp,
              dns: form.dns,
            },
          ],
        };
        model.filters = {
          selector: JSON.stringify(seleector),
        };
      },
    },
  },
  {
    name: 'A security appliance goes offline',
    type: alertTypes.applianceDown,
    class: 'Security appliance',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.timeout = +model.filters.timeout;
        } else {
          form.timeout = 60;
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          timeout: form.timeout,
        };
      },
    },
  },
  {
    name: 'The primary uplink status changes',
    type: alertTypes.failoverEvent,
    class: 'Security appliance',
    info:
      'This alert will be triggered if the appliance is configured with redundant Internet uplinks and the primary uplink fails. Another alert will occur when the connection is restored.',
  },
  {
    name: 'The DHCP lease pool is exhausted',
    type: alertTypes.dhcpNoLeases,
    class: 'Security appliance',
    info:
      'If the appliance has issued DHCP leases for every IP address on a subnet, new clients will not be able to get online. This alert will fire if a client requests an IP address and none are available.',
  },
  {
    name: 'An IP conflict is detected',
    type: alertTypes.ipConflict,
    class: 'Security appliance',
    info:
      'This alert will be triggered if the appliance detects that two devices on the LAN are trying to use the same IP address. IP conflicts generally result in connectivity issues for both devices.',
  },
  {
    name: 'Cellular connection state changes',
    type: alertTypes.cellularUpDown,
    class: 'Security appliance',
    info:
      'This alert will fire when the appliance establishes or loses its connection to the cellular network.',
  },
  {
    name: 'A rogue DHCP server is detected',
    type: alertTypes.rogueDhcp,
    class: 'Security appliance',
    info:
      'This alert will be triggered if the appliance detects another DHCP server on a subnet that the appliance is configured to respond to DHCP requests on.',
  },
  {
    name: 'A warm spare failover occurs',
    type: alertTypes.vrrp,
    class: 'Security appliance',
    info:
      'This alert will be triggered when the appliance has a warm spare configured and a failover occurs. That is, when the primary disconnects and the spare becomes the master, or when the primary reconnects and resumes acting as the master.',
  },
  {
    name: 'Malware is blocked',
    type: alertTypes.ampMalwareBlocked,
    class: 'Security appliance',
    info:
      'This alert will be triggered when a malicious file download is blocked.',
  },
  {
    name: 'Malware is downloaded',
    type: alertTypes.ampMalwareDetected,
    class: 'Security appliance',
    info:
      'This alert will be triggered when a file previously downloaded on your network is determined to be malicious in retrospect.',
  },
  {
    name: 'A switch goes offline',
    type: alertTypes.switchDown,
    class: 'Switch',
    info:
      'This alert will be triggered when a file previously downloaded on your network is determined to be malicious in retrospect.',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.timeout = +model.filters.timeout;
        } else {
          form.timeout = 60;
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          timeout: form.timeout,
        };
      },
    },
  },
  {
    name: 'New DHCP server is detected',
    type: alertTypes.newDhcpServer,
    class: 'Switch',
    info:
      "An email will be sent when a new DHCP server (or one that hasn't been seen in the last month) is seen.",
  },
  {
    name: 'Port goes down',
    type: alertTypes.portDown,
    class: 'Switch',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.timeout = +model.filters.timeout;
          form.selector = model.filters.selector;
        } else {
          form.timeout = 60;
          form.selector = 'any port';
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          timeout: form.timeout,
          selector: form.selector,
        };
      },
    },
  },
  {
    name: 'Cable error is detected',
    type: alertTypes.portError,
    class: 'Switch',
    filterText: 'detect a cable error',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.selector = model.filters.selector;
        } else {
          form.selector = 'any port';
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          selector: form.selector,
        };
      },
    },
  },
  {
    name: 'Port changes link speed',
    type: alertTypes.portSpeed,
    class: 'Switch',
    filterText: 'changes link speed',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.selector = model.filters.selector;
        } else {
          form.selector = 'any port';
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          selector: form.selector,
        };
      },
    },
  },
  {
    name: 'A power supply goes down',
    type: alertTypes.powerSupplyDown,
    class: 'Switch',
  },
  {
    name: 'A redundant power supply is powering a switch',
    type: alertTypes.rpsBackup,
    class: 'Switch',
    info:
      'This alert will be triggered if redundant (not modular) power supplies are providing power to a switch',
  },
  {
    name: 'Unidirectional link detection (UDLD) errors exist on a port',
    type: alertTypes.udldError,
    class: 'Switch',
  },
  {
    name: 'A cellular gateway goes offline',
    type: alertTypes.cellularGatewayDown,
    class: 'Cellular gateway',
    filter: {
      modelToForm: (model, form) => {
        if (model && model.filters) {
          form.timeout = +model.filters.timeout;
        } else {
          form.timeout = 60;
        }
      },
      formToModel: (form, model) => {
        model.filters = {
          timeout: form.timeout,
        };
      },
    },
  },
];

export const groupedAlerst = groupBy(merakiAlerts, 'class');

export default merakiAlerts;
