import React from 'react';
import { ReactComponent as IconHome } from '@assets/icons/home.svg';
import MailBoxesPage from './MailBoxesPage';

const Icon = () => <IconHome alt="iconHome" />;

export default {
  list: MailBoxesPage,
  icon: Icon,
};
