import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import phoneValidation from '@constants/phoneValidation';
import { ThunkUpdateCrmUserPhoneNumber } from '@store/slices/crm/thunks';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import ReusableButton from '@ui/components/common/Button/Button';
import Input from '@ui/components/Auth/Common/Input';

import useStyles from '../styles';

const EditPhoneDialog = ({
  open,
  setOpen,
  selectedUser,
  onClose,
  crmDefaultId,
  onSuccess,
}) => {
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const validate = values => {
    return {
      userPhone: phoneValidation(values.userPhone),
    };
  };

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      ThunkUpdateCrmUserPhoneNumber({ crmDefaultId, payload: values }),
    );
    onSuccess(values);
    setOpen(false);
    onClose();
    setProcessing(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
    >
      <DialogTitle>Edit Phone</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            userPhone: selectedUser.phone,
            userName: selectedUser.name,
            userCompany: selectedUser.company,
            userId: selectedUser.id,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Phone"
                labelSize={12}
                contentSize={12}
                showLabel
                classNameLabelInner={classes.alignLeft}
                infoText="Format should look like: +12345678900"
                content={
                  <Field
                    name="userPhone"
                    id="userPhone"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={classes.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                    onClose();
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditPhoneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  crmDefaultId: PropTypes.string,
};

export default EditPhoneDialog;
