import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketSeverities } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketSeverities = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketSeverities`,
  async crmId => {
    const severities = await getConnectWiseTicketSeverities(crmId);
    return severities;
  },
);

export default {};
