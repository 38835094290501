import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';
import { ThunkGetMicrosoft365CompaniesCompanies } from '@store/slices/integration/thunks/getMicrosoft365Companies';
import { TableTypes } from '@components/Table/constants';
import Microsoft365ContactsTableActions from './Microsoft365ContactsTableActions';

const Microsoft365Contacts = ({ psaId, resource, psaName }) => {
  const [companies, setCompanies] = useState([]);

  const dispatch = useDispatch();

  const onMount = useCallback(() => {
    dispatch(ThunkGetMicrosoft365CompaniesCompanies({ crmId: psaId }))
      .unwrap()
      .then(res => {
        setCompanies(res);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Company',
        key: 'companyName',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: companies,
        filterByAutocomplete: 'companyId',
        minWidth: 200,
      },
      {
        name: 'Mobile number',
        key: 'phone',
        searchable: true,
        type: TableTypes.phoneNumber,
        minWidth: 150,
      },
      {
        name: 'Email',
        key: 'email',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Is Verified',
        key: 'isVerified',
        width: 150,
        minWidth: 150,
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Is Opted-in',
        key: 'isOptedIn',
        width: 150,
        minWidth: 150,
        type: TableTypes.boolean,
        labelAlignment: 'center',
      },
      {
        name: 'Actions',
        width: 175,
        minWidth: 175,
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: item => (
          <Microsoft365ContactsTableActions record={item} psaId={psaId} />
        ),
      },
    ],
    [companies, psaId],
  );

  return (
    <Table
      resource={resource}
      columns={columns}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={psaName}
          isClientPortalButton={false}
        />
      }
    />
  );
};

Microsoft365Contacts.propTypes = {
  psaId: PropTypes.number.isRequired,
  resource: PropTypes.string.isRequired,
  psaName: PropTypes.string.isRequired,
};

export default Microsoft365Contacts;
