import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getCrmImage } from '../../helpers';
import useStyles from '../../styles';

const SelectCrmCard = ({ item, handleClick, showSmallCrmIcon }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.container,
        classes.cursor,
        showSmallCrmIcon && classes.smallCard,
      )}
      role="presentation"
      onClick={() => {
        handleClick(item.name);
      }}
    >
      <img src={getCrmImage(item.name)} alt="" style={{ width: '90%' }} />
    </div>
  );
};

SelectCrmCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SelectCrmCard;
