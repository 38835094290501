import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOptInUrl } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {Object} payload - crmId:{number}, ticketId:{number}, contactId:{number}
 */
export const ThunkGetOptInUrl = createAsyncThunk(
  `${OPT_IN}/getOptInUrl`,
  async ({ payload }) => {
    const res = await getOptInUrl(payload);
    return res;
  },
);

export default {};
