const alertTypes = Object.freeze({
  rogueAp: 'rogueAp',
  settingsChanged: 'settingsChanged',
  usageAlert: 'usageAlert',
  vpnConnectivityChange: 'vpnConnectivityChange',
  gatewayDown: 'gatewayDown',
  repeaterDown: 'repeaterDown',
  gatewayToRepeater: 'gatewayToRepeater',
  snr: 'snr',
  highWirelessUsage: 'highWirelessUsage',
  onboarding: 'onboarding',
  applianceDown: 'applianceDown',
  failoverEvent: 'failoverEvent',
  dhcpNoLeases: 'dhcpNoLeases',
  ipConflict: 'ipConflict',
  cellularUpDown: 'cellularUpDown',
  rogueDhcp: 'rogueDhcp',
  vrrp: 'vrrp',
  ampMalwareBlocked: 'ampMalwareBlocked',
  ampMalwareDetected: 'ampMalwareDetected',
  switchDown: 'switchDown',
  newDhcpServer: 'newDhcpServer',
  portDown: 'portDown',
  portError: 'portError',
  portSpeed: 'portSpeed',
  powerSupplyDown: 'powerSupplyDown',
  rpsBackup: 'rpsBackup',
  udldError: 'udldError',
  cellularGatewayDown: 'cellularGatewayDown',
});

export default alertTypes;
