import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@common/Checkbox/FormControlCheckBox';

const CheckBoxArraySelectorItem = ({ value, checked, onClick, disabled }) => (
  <Grid item>
    <Checkbox
      checked={checked}
      onChange={() => onClick(value.value)}
      inputProps={{ 'aria-label': 'primary checkbox' }}
      label={value.label}
      disabled={disabled}
    />
  </Grid>
);

CheckBoxArraySelectorItem.propTypes = {
  value: PropTypes.objectOf(PropTypes.any),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CheckBoxArraySelectorItem;
