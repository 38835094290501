import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { bulkSendUserInvites } from '@services/requests/usersInvite';

export const ThunkBulkSendUsersInvites = createAsyncThunk(
  `${USER}/bulkSendUserInvites`,
  ({ payload }) => bulkSendUserInvites(payload),
);

export default {};
