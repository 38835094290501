import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFormattedDate from '@utils/getFormattedDate';

import useStyles from '../styles';

const ChatMessage = ({
  message,
  extraMessageText = undefined,
  extraMessageAction = () => {},
}) => {
  const classes = useStyles();
  const userId = localStorage.getItem('userId');

  const isSendByCurrentUser = useMemo(
    () => message.senderId === Number(userId) || message?.technicianName,
    [userId, message],
  );

  const getInfoContent = useMemo(() => {
    if (message.isLoading) {
      return 'Sending...';
    }
    if (message.hasError) {
      return 'Not delivered';
    }
    return (
      <>
        {!isSendByCurrentUser && !message?.technicianName
          ? `${message.senderName}, `
          : message?.technicianName
          ? `${message?.technicianName}, `
          : ''}
        {getFormattedDate(message.dateTime)}
      </>
    );
  }, [message, isSendByCurrentUser]);

  const getDecodedMessage = useMemo(() => {
    const messageParts = message.message.trim().split('\n');
    return messageParts.map(i => {
      const uniqueIdPart1 = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
      return (
        <span key={i + uniqueIdPart1}>
          {i}
          <br />
        </span>
      );
    });
  }, [message]);

  return (
    <div
      className={cx(classes.chatMessage, {
        [classes.chatMessageAlignRight]: isSendByCurrentUser,
        [classes.chatMessageError]: message.hasError,
      })}
      key={message.id}
    >
      <span
        className={cx(classes.chatMessageDate, {
          [classes.chatMessageAlignRightDate]: isSendByCurrentUser,
        })}
      >
        {getInfoContent}
      </span>
      <p className={cx(classes.chatMessageText)}>{getDecodedMessage}</p>

      {extraMessageText && (
        <div
          className={classes.addMessageToNote}
          role="presentation"
          onClick={() => {
            extraMessageAction(message);
          }}
        >
          {extraMessageText}
        </div>
      )}
    </div>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.any),
};

export default ChatMessage;
