import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pusher from 'pusher-js';

import { ChatsActiveIdSelector } from '@store/slices/chats';
import { ThunkGetChatMessages } from '@store/slices/chats/thunks';
import { pusherConfigSelector } from '@store/selectors/pusher';

import Loading from '@ui/components/common/Loading/Loading';
import ChatBody from './components/ChatBody';

import useStyles from './styles';

const Chat = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const activeChatId = useSelector(ChatsActiveIdSelector);
  const pusherConfig = useSelector(pusherConfigSelector);

  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    if (activeChatId) {
      setLoading(true);
      await dispatch(ThunkGetChatMessages(activeChatId));
      setLoading(false);
    }
  }, [activeChatId, dispatch]);

  useEffect(() => {
    onMount();
  }, [activeChatId, onMount]);

  const pusher = useMemo(() => {
    if (pusherConfig)
      return new Pusher(pusherConfig.key, {
        cluster: pusherConfig.cluster,
      });
    return {};
  }, [pusherConfig]);

  const pageContent = useMemo(() => {
    if (loading) {
      return <Loading />;
    }
    if (activeChatId && pusherConfig) {
      return <ChatBody pusher={pusher} activeChatId={activeChatId} />;
    }
    return 'Select a chat to display';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, activeChatId, pusherConfig]);

  return <div className={classes.chatContainer}>{pageContent}</div>;
};

export default Chat;
