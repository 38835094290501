import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  accordionDetailsRoot: {
    display: 'block',
  },
  cell: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: '#ddd',
  },
  downLoadWrapper: {
    alignItems: 'center',
  },
  dialogActionsRoot: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  confirmBtnContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  spacingWrapper: {
    [theme.breakpoints.down('xs')]: {
      '& :not(:first-child)': {
        marginLeft: '0',
      },
    },
  },
}));

export default useStyles;
