import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import DropDown from '@components/Auth/Common/DropDown';
import {
  getAutotaskResources,
  getAutotaskRoles,
  getAutotaskResourceRoles,
  baseFieldName,
} from './helper';
import useStyles from '../../styles';

const requiredFields = {
  resourceId: 'resourceId',
  roleId: 'roleId',
};

const AutotaskSubForm = ({ crmId }) => {
  const classes = useStyles();
  const [resourcesOptions, setResourcesOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [resourceRolesOptions, setResourceRolesOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState([]);
  const {
    input: { value },
  } = useField(baseFieldName + requiredFields.resourceId);

  useEffect(() => {
    if (value) {
      const roleIds = resourceRolesOptions[value];
      const roles = roleIds
        ? rolesOptions.filter(r => roleIds.includes(r.value))
        : [];
      setAllowedRoles(roles);
    } else {
      setAllowedRoles(rolesOptions);
    }
  }, [value, resourceRolesOptions]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAutotaskResources(crmId),
      getAutotaskRoles(crmId),
      getAutotaskResourceRoles(crmId),
    ])
      .then(resp => {
        setResourcesOptions(resp[0]);
        setRolesOptions(resp[1]);
        setResourceRolesOptions(resp[2]);
      })
      .catch(e => console.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <div>
        <Typography className={classes.inputLabel}>
          Time Entry Resource
        </Typography>
        <Field
          id={baseFieldName + requiredFields.resourceId}
          name={baseFieldName + requiredFields.resourceId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={resourcesOptions}
          fullWidth
          disabled={loading}
        />
      </div>
      <div>
        <Typography className={classes.inputLabel}>Time Entry Role</Typography>
        <Field
          id={baseFieldName + requiredFields.roleId}
          name={baseFieldName + requiredFields.roleId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={allowedRoles}
          fullWidth
          disabled={loading}
        />
      </div>
    </>
  );
};

AutotaskSubForm.propTypes = {
  crmId: PropTypes.number.isRequired,
};

export default AutotaskSubForm;
