import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalConfigurations } from '@services/requests/clientPortal';

export const ThunkGetClientPortalConfigurations = createAsyncThunk(
  `${CLIENT_PORTALS}/getClientPortalConfigurations`,
  () => getClientPortalConfigurations(),
);

export default {};
