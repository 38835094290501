/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_DEFAULT } from '@store/slices/resources';
import {
  ThunkCreateClientPortalDefault,
  ThunkDeleteClientPortalDefault,
  ThunkGetClientPortalDefaults,
  ThunkUpdateClientPortalDefault,
} from '@store/slices/clientPortalDefaults/thunks';

const clientPortalDefaultsSlice = createSlice({
  name: CLIENT_PORTAL_DEFAULT,
  initialState: {
    defaults: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetClientPortalDefaults.fulfilled, (state, action) => {
      state.defaults = action.payload;
    });

    builder.addCase(
      ThunkCreateClientPortalDefault.fulfilled,
      (state, action) => {
        state.defaults.push(action.payload);
      },
    );

    builder.addCase(
      ThunkUpdateClientPortalDefault.fulfilled,
      (state, action) => {
        state.defaults = state.defaults.map(i =>
          +i.id === +action.payload.id ? action.payload : i,
        );
      },
    );

    builder.addCase(
      ThunkDeleteClientPortalDefault.fulfilled,
      (state, action) => {
        state.defaults = state.defaults.filter(
          i => +i.id !== +action.payload,
        );
      },
    );
  },
});

export default clientPortalDefaultsSlice.reducer;

export const ClientPortalDefaultsSelector = state =>
  state[CLIENT_PORTAL_DEFAULT].defaults;
