import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = makeStyles(theme => ({
  fileUploader: {
    width: '94%',
  },
  hidden: {
    display: 'none',
  },
  inputClassNameWrapper: {
    width: '100%',
  },
  tabsWrapper: {
    width: '100%',
    backgroundColor: 'white',
    paddingTop: '1.75rem',
    marginTop: '0 !important',
    borderBottom: '0.0625rem solid #D9D5D5',
  },
  switcher: {
    height: '0',
  },
  fieldLabelOuter: {
    display: 'block',
    textAlign: 'left',
    height: 'inherit',
  },

  editProfileButton: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  userInfoWrapper: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    backgroundColor: 'white',
  },
  userInfo: {
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
    padding: '0 1rem 1rem 1rem',
    borderRadius: '0.8125rem',
  },
  contentWrapper: {
    width: '100%',
  },
  imageWrapper: {
    '& img': {
      width: '100%',
      maxHeight: '100%',
    },

    '& label': {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.info.main,
    },
  },

  textWrapper: {
    width: '100%',

    '& label': {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.info.main,
    },
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.25rem',
    [theme.breakpoints.up('md')]: {
      margin: '1.25rem auto',
    },
  },

  boxWrapper: {
    marginTop: '1rem',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  linkedUserWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  disabled: {
    '& :disabled': {
      backgroundColor: '#f3f0f0',
    },
  },

  form: {
    padding: '1rem 0',
    borderRadius: '1.25rem',
  },
  buttonContainer: {
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'inline-block',
    },

    [theme.breakpoints.between('md', 'lg')]: {
      display: 'block',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
    },
    display: 'inline-block',

    marginTop: '10px',
    marginRight: '5px',
  },
  root: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
    '& span:last-child': {
      paddingLeft: 0,
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
    },
  },
  buttonDeleteWrapper: {
    marginLeft: '0.9375rem',
  },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  dialogWith: {
    width: '30rem',
  },
  checkboxContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  displayBlock: {
    marginRight: '1rem',
  },
  halfWidth: {
    width: '50%',
  },
}));

export default styles;
