import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import TextEllipsis from 'react-text-ellipsis';

const LongTextAccessor = ({ value }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  if (!value) return null;
  const textEllipsis = (
    <div
      style={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '21px',
        whiteSpace: 'normal',
        width: '100%',
      }}
    >
      <TextEllipsis
        lines={2}
        ellipsisChars="..."
        onResult={result => {
          if (result === TextEllipsis.RESULT.TRUNCATED) setIsTruncated(true);
        }}
      >
        {value}
      </TextEllipsis>
    </div>
  );

  if (!isTruncated) return textEllipsis;

  return (
    <Tooltip title={<>{value}</>} placement="bottom-start">
      {textEllipsis}
    </Tooltip>
  );
};

LongTextAccessor.propTypes = {
  value: PropTypes.string,
};

export default LongTextAccessor;
