import {
  CREATE_TICKET,
  CRM_TICKETS,
  MESSAGING_BOARD,
  TICKET_UPDATE,
  TRIAGE,
} from '../requestResources';
import { makeRequest } from '../makeRequest';

export const getTicketLink = (ticketId, crmId, crmDefaultId) => {
  let baseUrl = `${CRM_TICKETS}/getLink?ticketId=${ticketId}&`;
  if (crmId) baseUrl = baseUrl.concat(`crmId=${crmId}`);
  if (crmDefaultId) baseUrl = baseUrl.concat(`crmDefaultId=${crmDefaultId}`);
  return makeRequest('GET', baseUrl);
};

export const getTicketStatusesByChannelId = channelId =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/channels/${channelId}/getTicketStatuses`,
  );

export const getZendeskTicketStatusesByChannelId = channelId =>
  makeRequest(
    'GET',
    `${MESSAGING_BOARD}/zendesk/channels/${channelId}/getTicketStatuses`,
  );

export const getTicketInfo = (crmId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/crmDefault/${crmId}?ticketId=${ticketId}`,
  );

export const getCrmTicketCompanies = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/autotask/${crmId}/companiesOptions`);

export const getCrmTicketOptions = (crmId, options) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/autotask/${crmId}/getOptions?${options
      .map(option => `option=${option}&`)
      .join('')}`,
  );

export const updateAutotaskTicket = (crmId, payload) =>
  makeRequest('PUT', `${TICKET_UPDATE}/autotask/${crmId}`, payload);

export const updateConnectWiseTicket = (crmId, payload) =>
  makeRequest('PUT', `${TICKET_UPDATE}/connectwise/${crmId}`, payload);

export const getCrmTicketPhoneNumber = (crmId, ticketId) =>
  makeRequest('GET', `${TRIAGE}/${crmId}/tickets/${ticketId}/userPhone`);

export const getCrmOptions = () =>
  makeRequest('GET', `${TICKET_UPDATE}/getCrmOptionsDetailed`);

export const createMessagingChannelFromTicket = (crmId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/crmDefault/${crmId}/tickets/createChannel`,
    payload,
  );

export const createMessagingChannelFromTicketByCrmId = (crmId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/crm/${crmId}/tickets/createChannel`,
    payload,
  );

export const checkExistingMessagingChannelFromTicket = (crmId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/crmDefault/${crmId}/tickets/${ticketId}/checkIsExistsChannel`,
  );

export const getAutotaskTicketResources = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/autotask/${crmId}/resourcesOptions`);

export const getAutotaskTicketContacts = (crmId, companyId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/autotask/${crmId}/contactsOptions${
      companyId ? `?companyId=${companyId}` : ''
    }`,
  );

export const assignAutotaskTicketResource = (crmId, ticketId, resourceId) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/autotask/${crmId}/tickets/${ticketId}/assignResource/${resourceId}`,
  );

export const assignAutotaskTicketContact = (crmId, ticketId, contactId) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/autotask/${crmId}/tickets/${ticketId}/assignContact/${contactId}`,
  );

export const getATTicketTimeEntries = (crmId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/autotask/${crmId}/tickets/${ticketId}/timeEntries`,
  );

export const addATTicketTimeEntry = (crmId, ticketId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/autotask/${crmId}/tickets/${ticketId}/timeEntries`,
    payload,
  );

export const editAutotaskTicketTimeEntry = (crmId, timeEntryId, payload) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/autotask/${crmId}/timeEntries/${timeEntryId}`,
    payload,
  );

export const deleteAutotaskTicketTimeEntry = (crmId, timeEntryId) =>
  makeRequest(
    'DELETE',
    `${TICKET_UPDATE}/autotask/${crmId}/timeEntries/${timeEntryId}`,
  );

export const getKaseyaLocations = (psaId, companyId) =>
  makeRequest(
    'GET',
    `${CREATE_TICKET}/${psaId}/kaseya/locations?accountId=${companyId}`,
  );

export default {};
