import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const FormMultiselect = ({
  options,
  label,
  placeholder,
  id,
  input,
  meta,
  disabled,
}) => {
  return (
    <Autocomplete
      multiple
      id={id}
      label={label}
      placeholder={placeholder}
      options={options}
      getOptionLabel={option => {
        return (
          options.find(labelOption => labelOption.value === option)?.label ??
          option?.toString()
        );
      }}
      disableCloseOnSelect
      variant="outlined"
      size="small"
      value={input.value || []}
      getOptionSelected={(option, value) => {
        return option.value === value;
      }}
      onChange={(e, data) => {
        input.onChange(data.map(i => i?.value ?? i));
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
          disabled={disabled}
        />
      )}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.label}
        </>
      )}
    />
  );
};

FormMultiselect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default FormMultiselect;
