import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: '0.3125rem',
    transition: '0.3s',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },

  colorPrimary: {
    color: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actionButton: {
    borderRadius: '20px',
    width: '28px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      background: '#efefef',
    },
  },
}));

export default useStyles;
