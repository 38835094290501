import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { getTimeZones } from '@services/requests/common';

export const ThunkGetTimeZones = createAsyncThunk(
  `${COMMON}/timeZones`,
  async () => {
    const res = await getTimeZones();
    return res;
  },
);

export default {};
