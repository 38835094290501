import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { deleteUserInvite } from '@services/requests/usersInvite';

export const ThunkDeleteUserInvite = createAsyncThunk(
  `${USER}/deleteUserInvite`,
  async id => deleteUserInvite(id),
);

export default {};
