import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '72vh',
    },
  },
  inputContainer: {
    width: '300px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  filtersContainer: {
    margin: '10px 0px',
  },
  tableActions: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  optInDialogItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '10px',
    '& > div': {
      textAlign: 'left',
    },
  },
  optInDialogItemsList: {
    overflowY: 'auto',
    minHeight: '10vh',
    maxHeight: '50vh',
  },
  optDialogItemsListHeader: {
    '& > div': {
      fontWeight: '700',
    },
  },
  optInDialogResultsHeader: {
    display: 'flex',
    gap: '10px',
  },
  optInDialogResultsList: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr 1fr 6fr',
    gap: '10px',
  },
  textCentered: {
    textAlign: 'center',
  },
  tableBulk: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  statsContainer: {
    display: 'flex',
    gap: '10px',
    '& b': {
      width: '100px',
    },
  },
  successStat: {
    color: 'green',
  },
  failedStat: {
    color: 'red',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsRegion: {
    display: 'flex',
    gap: '8px',
  },
  formWrapper: {
    width: '500px',
  },
  loader: {
    height: '143px',
    '& img': {
      marginTop: '25px',
      height: '24px',
      width: '24px',
    },
    '& h1': {
      fontSize: '14px',
      margin: '8px',
    },
    '& div': {
      fontSize: '12px',
    },
  },
}));

export default useStyles;
