import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRICE_PLANS } from '@store/slices/resources';
import { updateBillingAddress } from '@services/requests/billing';

export const ThunkUpdateBillingAddress = createAsyncThunk(
  `${PRICE_PLANS}/updateBillingAddress`,
  payload => updateBillingAddress(payload),
);

export default {};
