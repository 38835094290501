import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  usageContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0px',
    margin: '20px auto',
  },
  chartContainer: {
    marginTop: '35px',
  },
}));

export default useStyles;
