import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { pricePlanInfo } from '@store/selectors';

const CurrentPlanNew = () => {
  const plan = useSelector(pricePlanInfo);
  const getPrice = useCallback(price => (price / 100).toFixed(2), []);

  if (!plan) return null;

  return (
    <div>
      <div>
        <span>Current plan:&nbsp;</span>
        <span>{plan.name}</span>
      </div>
      <div>
        <span>Is DEMO:&nbsp;</span>
        <span>{plan.isDemoSubscription ? 'true' : 'false'}</span>
      </div>
      <div>
        <span>Base price:&nbsp;</span>
        <span>${getPrice(plan.basePriceCents)}</span>
      </div>
      <div>
        <span>Price per user over limit:&nbsp;</span>
        <span>${getPrice(plan.pricePerTechCents)}</span>
      </div>
    </div>
  );
};

export default CurrentPlanNew;
