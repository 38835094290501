import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendOptInUrl } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {Object} payload
 * @param {string} payload.contactEmail
 * @param {string} payload.contactPhone
 * @param {string} payload.url
 * @param {boolean} payload.addTicketNote
 * @param {boolean} payload.updateContactPhone
 */
export const ThunkSendOptInUrl = createAsyncThunk(
  `${OPT_IN}/sendOptInUrl`,
  async ({ payload }) => {
    const res = await sendOptInUrl(payload);
    return res;
  },
);

export default {};
