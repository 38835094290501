import React, { useState } from 'react';

import storage from '@constants/localStorage';
import Banner from '@common/Banners/Banner';
import ForgotPasswordForm from './ForgotPasswordForm';
import InfoBox from './InfoBox';
import useStyles from '../styles';

const ForgotPassword = () => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(true);
  const logoUrl = sessionStorage.getItem(storage.BASE_APP_LOGO);

  const onFormSubmit = () => setShowForm(false);

  return (
    <div className={classes.card}>
      <div className={classes.contentWrapper}>
        <img alt="logo" src={logoUrl} className={classes.logoStyle} />
        {showForm ? (
          <ForgotPasswordForm onSubmit={onFormSubmit} />
        ) : (
          <InfoBox
            title="Password reset successfully"
            text="Please, check your email and follow the instructions"
          />
        )}
      </div>
      <Banner />
    </div>
  );
};

export default ForgotPassword;
