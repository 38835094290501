import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getKaseyaCompanies } from '@services/requests/createTicket';

export const ThunkGetKaseyaCompanies = createAsyncThunk(
  `${CREATE_TICKET}/getKaseyaCompanies`,
  ({ psaId }) => getKaseyaCompanies(psaId),
);

export default {};
