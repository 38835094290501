import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { lightTheme } from '@services/themes/mainTheme';
import ErrorProvider from '@components/ErrorProvider/ErrorProvider';

const theme = createTheme(lightTheme());

const UnauthorizedProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorProvider>{children}</ErrorProvider>
    </ThemeProvider>
  );
};

export default UnauthorizedProvider;
