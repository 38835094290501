import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRICE_PLANS } from '@store/slices/resources';
import { getTenantTechnicianCount } from '@services/requests/billing';

export const ThunkGetTenantTechCount = createAsyncThunk(
  `${PRICE_PLANS}/getTenantTechCount`,
  () => getTenantTechnicianCount(),
);

export default {};
