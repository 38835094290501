import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { getSmsCampaignGroups } from '@services/requests/smsCampaigns';

export const ThunkGetSmsCampaignGroups = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/getSmsCampaigns`,
  async () => {
    const data = await getSmsCampaignGroups();
    return data;
  },
);

export default {};
