import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1.875rem',
      width: '96%',
    },
    flexGrow: 1,
    backgroundColor: theme.palette.homePage.settings.background,
    padding: '1.25rem 2.0625rem 1.25rem 1.25rem',
    borderRadius: '0.8125rem',
  },
  heading: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    flexBasis: '33.33%',
    flexShrink: 0,
    color: theme.palette.textColor,
  },
  secondaryHeadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.9375rem',
  },
  secondaryHeading: {
    fontSize: '1rem',
    color: theme.palette.textColor,
  },
}));

export default useStyles;
