import { makeRequest } from '@services/requests/makeRequest';
import { LOG_RECORDS } from '@services/requests/requestResources';

export const getGetLogRecordTypes = () =>
  makeRequest('GET', `${LOG_RECORDS}/getLogRecordTypes`);

export const getLogsCount = id =>
  makeRequest('GET', `${LOG_RECORDS}/getGenericSourceLogs/${id}/count`);

export const getLogsDetails = id =>
  makeRequest('GET', `${LOG_RECORDS}/getLogRecords/${id}`);
