import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';

import ReusableButton from '@common/Button/Button';

import {
  defaultQuestion,
  ISSUE_TYPE_ITEMS_NAME,
} from '@ui/pages/clientPortal/IssueType/constants';

import useStyles from '../styles';

const IssueTypeButtonBar = React.memo(({ disabled }) => {
  const { change, ...form } = useForm();
  const classes = useStyles();

  const addNewQuestion = useCallback(() => {
    const { values } = form.getState();
    const existingQuestions = values[ISSUE_TYPE_ITEMS_NAME];

    if (existingQuestions) {
      change(ISSUE_TYPE_ITEMS_NAME, [
        ...existingQuestions,
        { innerId: new Date().getTime(), ...defaultQuestion },
      ]);
    } else {
      change(ISSUE_TYPE_ITEMS_NAME, [
        { innerId: new Date().getTime(), ...defaultQuestion },
      ]);
    }
  }, []);

  return (
    <div className={classes.buttons}>
      <ReusableButton
        label="Add Question"
        onClick={addNewQuestion}
        disabled={disabled}
      />
      <ReusableButton
        label="Submit"
        type="submit"
        loading={disabled}
        disabled={disabled}
      />
    </div>
  );
});

export default IssueTypeButtonBar;
