import React from 'react';
import { ReactComponent as RoadMapIcon } from '@assets/icons/roadmap.svg';
import RoadMap from './RoadMap';

const Icon = () => <RoadMapIcon alt="roadmap" />;

export default {
  list: RoadMap,
  icon: Icon,
};
