import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FileUploader from '@common/FileUploader/FileUploader';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { addClientPortalMessage } from '@store/slices/clientPortalAdmin';
import { useDispatch } from 'react-redux';

const UploadChatAttachmentDialog = ({
  open,
  setOpen,
  chatId,
  toClientPortal = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleEvent = remove => (message, type) => {
    remove();
    enqueueSnackbar(message, { variant: type });
  };

  const handleUpload = async (file, remove) => {
    const handleResponse = handleEvent(remove);
    try {
      const formData = new FormData();
      formData.append('file', file);
      let response;
      if (!toClientPortal) {
        response = await sendFormData(
          `${appConfig.baseUrl}/LiveChatAdmin/chats/${chatId}/messages/attachment`,
          formData,
        );
      } else {
        response = await sendFormData(
          `${appConfig.baseUrl}/ClientPortalChats/chats/${chatId}/messages/attachment`,
          formData,
        );
      }

      if (response.ok) {
        handleResponse(`File ${file.name} uploaded successfully`, 'info');
        if (toClientPortal) {
          const msg = await response.json().then(res => res);
          dispatch(addClientPortalMessage(msg));
        }
        setOpen(false);
      } else {
        handleResponse(
          `File is damaged, you can't upload ${file.name} file`,
          'error',
        );
      }
    } catch (e) {
      handleResponse(e.message, 'error');
    }
  };

  return (
    <>
      <MuiDialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Upload files</DialogTitle>
        <DialogContent>
          <FileUploader
            handleUpload={handleUpload}
            format="*"
            maxFiles={20}
            maxSizeBytes={10000000}
          />
        </DialogContent>
      </MuiDialog>
    </>
  );
};

UploadChatAttachmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  chatId: PropTypes.number.isRequired,
};

export default UploadChatAttachmentDialog;
