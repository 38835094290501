import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { sendLiveChatLogsToEmail } from '@services/requests/liveChats';

export const ThunkSendLiveChatLogsToEmail = createAsyncThunk(
  `${LIVE_CHATS}/sendLiveChatLogsToEmail`,
  ({ chatId, payload }) => sendLiveChatLogsToEmail(chatId, payload),
);

export default {};
