import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { updateCompaniesVerificationSettings } from '@services/requests/verificationSettings';

export const ThunkUpdateCompaniesVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/updateCompaniesVerificationSettings`,
  payload => updateCompaniesVerificationSettings(payload),
);

export default {};
