import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '90vh',
    padding: '30px',
    overflowY: 'auto',
    '@media (max-width: 820px)': {
      height: '93vh',
      padding: '0px',
      background: 'white',
      borderRadius: '0px',
      overflowY: 'auto',
      boxSizing: 'border-box',
    },
  },
  ddContainer: {
    width: '100%',
    background: 'white',
    borderRadius: props => (!props.psaSelected ? '15px' : '15px 15px 0px 0px'),
    padding: props => (!props.psaSelected ? '25px' : '25px 25px 5px 25px'),
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
    gap: '20px',
    '@media (max-width: 820px)': {
      padding: '25px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      borderRadius: '0px !important',
    },
  },
  formContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(300px, 1fr))',
    width: '100%',
    background: 'white',
    padding: '25px',
    boxSizing: 'border-box',
    gap: '5px 30px',
    margin: '15px 0px',
    borderRadius: '15px',
    alignItems: 'center',
    '@media (max-width: 820px)': {
      gridTemplateColumns: 'repeat(1, minmax(300px, 1fr))',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '20px 0px',
    width: 'calc(100% - 5%)',
  },
  templatesContainer: {
    display: 'flex',
    width: '100%',
    gap: '20px',
    alignItems: 'flex-end',
    '& > button': {
      margin: '5px',
    },
    '@media (max-width: 820px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '10px',
      '& > button': {
        width: '100%',
      },
    },
  },
  removePadding: {
    padding: '0px !important',
  },
  templatesBox: {
    width: '100%',
    background: 'white',
    padding: '0px 25px 25px 25px',
    boxSizing: 'border-box',
    borderRadius: '15px',
    display: 'flex',
    margin: '1rem 0',
    gap: '20px',
    border: '1px solid #cbcbcb',
    clipPath: 'inset(0px -10px -10px -10px)',
    '@media (max-width: 820px)': {
      margin: '10px auto',
      padding: '0px 25px',
      flexWrap: 'wrap',
    },
  },
  boxShadow: {
    boxShadow: 'unset',
  },
  refreshBtn: {
    alignSelf: 'center',
    marginTop: '3rem',
  },
}));

export default useStyles;
