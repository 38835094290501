import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import useAsync from '@services/api/common/useAsync';
import Loading from '@common/Loading/Loading';
import { getMailBoxes } from './helpers';
import MailBoxCard from './components/MailBoxCard/MailBoxCard';
import CreateMailBoxCard from './components/MailBoxCard/CreateMailBoxCard';

import useStyles from './styles';

const MailBoxesPage = () => {
  const classes = useStyles();
  const [data, setData] = useState({});
  useAsync(getMailBoxes, setData);

  if (Array.isArray(data) && !data.length) {
    return <Redirect to="/firstMailBox" />;
  }

  const idDataLoaded = Array.isArray(data);

  const handleReload = async () => {
    const newBoxes = await getMailBoxes();
    setData(newBoxes);
  };

  const getData = () => {
    if (idDataLoaded) {
      return (
        <div className={classes.container}>
          {data.map(i => (
            <MailBoxCard key={i.id} item={i} />
          ))}
          <CreateMailBoxCard
            onSubmit={handleReload}
            mailBoxesCount={data.length}
          />
        </div>
      );
    }
    return <Loading />;
  };

  return getData();
};

export default MailBoxesPage;
