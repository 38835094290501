import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  input: {
    border: '1px solid #e2e2e2',
    borderRadius: '0.3125rem',
    '& > input': {
      padding: '0.5rem 0.9375rem',
    },
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: props => (!props.hasError ? 'none' : 'block'),
    },
  },
}));

export default useStyles;
