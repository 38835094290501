import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 16px',
    '& tbody': {
      maxHeight: '76vh',
    },
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
  },
  bulkActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  actionButtons: {
    textAlign: 'center',
    marginTop: '1rem',
    '& > button': {
      marginLeft: '10px',
    },
  },
}));

export default useStyles;
