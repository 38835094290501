import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { sendCheckCode } from '@services/requests/psaPods';

export const ThunkSendCheckCode = createAsyncThunk(
  `${CONNECTWISE_POD}/sendCheckCode`,
  async payload => {
    const res = await sendCheckCode(payload);
    return res;
  },
);

export default {};
