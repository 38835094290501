import { createAsyncThunk } from '@reduxjs/toolkit';
import { FILE_UPLOAD } from '@store/slices/resources';
import { getAutotaskConfigurationItems } from '@services/requests/fileUpload';

export const ThunkGetAutotaskConfigurationItems = createAsyncThunk(
  `${FILE_UPLOAD}/getAutotaskConfigurationItems`,
  async ({ psaId, companyId }) => {
    return getAutotaskConfigurationItems(psaId, companyId);
  },
);

export default {};
