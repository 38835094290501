import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getContactData`,
  async ({ crmId, contactId }) => getContactData(crmId, contactId),
);

export default {};
