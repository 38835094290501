import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '79vh',
    },
  },
}));

export default useStyles;
