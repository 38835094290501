import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 10px 0',
  },
  deviceMonitorLabel: {
    fontSize: '1rem',
    marginRight: '0.5rem',
  },
  tvModeBtnContainer: {
    textAlign: 'right',
  },
  tvModeBtn: {
    margin: '1rem',
  },
  listContainer: {
    marginTop: '1rem',
  },
  autoRefreshContainer: {
    margin: '0 10px',
  },
  displayFlex: {
    display: 'flex',
  },
}));

export default useStyles;
