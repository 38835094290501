import { createAsyncThunk } from '@reduxjs/toolkit';
import { editAutotaskTicketTimeEntry } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkEditAutotaskTicketTimeEntry = createAsyncThunk(
  `${CRM_TICKET}/editAutotaskTicketTimeEntry`,
  async ({ crmId, timeEntryId, payload }) => {
    const { resource, ...rest } = payload;
    const timeEntry = await editAutotaskTicketTimeEntry(
      crmId,
      timeEntryId,
      rest,
    );
    return { ...timeEntry, resource };
  },
);

export default {};
