import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  container: {
    margin: '1.5rem 16px 0',
    '& tbody': {
      maxHeight: '78vh',
    },
  },
}));

export default styles;
