import { thresholdValidator } from '@components/DeviceMonitors/validators';
import { requiredValidator } from '@utils/validators';
import moment from 'moment';
import format from './Details/helpers';

const loadData = (data, monitorId) => {
  return {
    id: data?.id || 0,
    lastN: data?.lastN || 1,
    type: (data?.type !== undefined && String(data.type)) || null,
    value: data?.value || 1,
    deviceMonitorId: data?.deviceMonitorId || monitorId,
    notifyAboutRecovering: data?.notifyAboutRecovering || false,
  };
};

export const handleErrors = values => {
  return {
    lastN: thresholdValidator(values.lastN),
    value: thresholdValidator(values.value, values.type),
    type: requiredValidator(values.type),
  };
};

export const handleDisabled = form => {
  if (form.getFieldState('type')?.value === '0') {
    form.change('value', null);
    return true;
  }
  return false;
};

export const calculateDurationEventTime = item => {
  const time = moment.utc(item?.dateTime).valueOf();
  const duration = moment.utc().valueOf() - time;

  return { ...item, duration: format(Math.floor(duration / 1000 / 60) || 0) };
};

export const calculateDurationEventsTime = data => {
  return data.map((item, index, arr) => {
    const time = moment.utc(item.dateTime).valueOf();

    const duration = index
      ? moment.utc(arr[index - 1].dateTime).valueOf() - time
      : moment.utc().valueOf() - time;

    return { ...item, duration: format(Math.floor(duration / 1000 / 60) || 0) };
  });
};

export default loadData;
