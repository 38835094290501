const deviceMonitorTypes = Object.freeze({
  TcpPing: {
    name: 'PORT',
    id: '0',
    description: 'Ping over TCP connection. Requires port (80 as default)',
    value: 'TcpPing',
  },
  Api: {
    name: 'HTTP(S)',
    id: '1',
    description: 'Send HTTP GET request and estimate HTTP response.',
    value: 'Api',
  },
  Ping: {
    name: 'Ping',
    id: '2',
    description: 'Ping using ICMP protocol (from Central US region)',
    value: 'Ping',
  },
});

export const deviceMonitorRegions = Object.freeze({
  CentralUs: {
    id: 0,
    name: 'Central US',
  },
  WestEurope: {
    id: 1,
    name: 'West Europe',
  },
});

export const getMonitorTypeDescription = type => {
  switch (String(type)) {
    case deviceMonitorTypes.TcpPing.id:
      return deviceMonitorTypes.TcpPing.description;
    case deviceMonitorTypes.Api.id:
      return deviceMonitorTypes.Api.description;
    case deviceMonitorTypes.Ping.id:
      return deviceMonitorTypes.Ping.description;
    default:
      return 'Select value';
  }
};

export const ports = [
  { label: 'HTTP (80)', value: '80' },
  { label: 'HTTPS (443)', value: '443' },
  { label: 'FTP (21)', value: '21' },
  { label: 'SMTP (25)', value: '25' },
  { label: 'POP3 (110)', value: '110' },
  { label: 'IMAP (143)', value: '143' },
];

export const types = [
  {
    label: deviceMonitorTypes.TcpPing.name,
    value: deviceMonitorTypes.TcpPing.id,
  },
  { label: deviceMonitorTypes.Api.name, value: deviceMonitorTypes.Api.id },
  { label: deviceMonitorTypes.Ping.name, value: deviceMonitorTypes.Ping.id },
];

export default deviceMonitorTypes;
