import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox as SimpleCheckBox, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Checkbox from '@common/Checkbox/Checkbox';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ExternalLink from '@common/ExternalLink/ExternalLink';

import getFormattedDate from '@utils/getFormattedDate';
import { checkedIntegrationOrganizations } from '@components/DataSources/helpers';
import organizationTemplateState from '@constants/organizationTemplateState';

import setting from '@assets/icons/setting.svg';
import alertsIcon from '@assets/icons/dangerTriangle.svg';
import iconDevice from '@assets/icons/treshold.svg';

import useStyles from '@components/DataSources/Integrations/pages/Meraki/styles';

const OrganizationItem = ({
  integrationId,
  organization,
  organizationIntegrations,
  handleClick,
  handleGetDeviceStatuses,
  handleGetAlertSettings,
  handleGetHookAlerts,
  activeOrganizationId,
  updateIntegrationOrganization,
  name,
}) => {
  const classes = useStyles();
  const readOnly = !organization.api.enabled;
  const isChecked = Boolean(
    organizationIntegrations.find(
      item =>
        item.organizationId === organization.id &&
        item.integrationId === +integrationId,
    ),
  );
  const { push } = useHistory();

  const integrationHandler = event => {
    event.stopPropagation();
    checkedIntegrationOrganizations(!isChecked, {
      integrationId,
      organizationId: organization.id,
    }).then(() => updateIntegrationOrganization());
  };

  const trimName = (value, len) =>
    value.length > len ? `${value.substr(0, len)}...` : value;

  return (
    <button
      key={organization.id}
      className={cx(
        classes.baseButton,
        classes.organizationButton,
        organization.id === activeOrganizationId &&
          classes.activeOrganizationButton,
      )}
      disabled={readOnly}
      onClick={() => handleClick(organization.id)}
      name={name}
    >
      {!readOnly && (
        <div onClick={e => e.stopPropagation()}>
          <Checkbox
            color="primary"
            style={{ color: '#FF8A71' }}
            checked={isChecked}
            onChange={event => integrationHandler(event)}
          />
        </div>
      )}
      <span>
        {trimName(organization.name, 25)}
        <ExternalLink url={organization.url} />
      </span>
      {!readOnly && (
        <div>
          {organization.templated !== organizationTemplateState.none && (
            <Tooltip
              title={
                organization.templated === organizationTemplateState.none ? (
                  ''
                ) : (
                  <>
                    {organization.templateApplier}
                    <br />
                    {getFormattedDate(organization.templateApplyDate)}
                  </>
                )
              }
            >
              <SimpleCheckBox
                indeterminate={
                  organization.templated === organizationTemplateState.partial
                }
                checked={
                  organization.templated === organizationTemplateState.full
                }
                style={{
                  padding: '0px',
                  margin: '0px',
                }}
                onClick={() => {
                  push(`/alertTemplates?id=${organization.templateId}`);
                }}
              />
            </Tooltip>
          )}

          <ActionButton
            icon={<img src={alertsIcon} alt="alertsIcon" />}
            handler={event => handleGetHookAlerts(organization.id, event)}
            toolTip="Alerts"
          />
          <ActionButton
            icon={<img src={setting} alt="iconSetting" />}
            handler={event => handleGetAlertSettings(organization.id, event)}
            toolTip="Alert setting"
          />
          <ActionButton
            icon={<img src={iconDevice} alt="iconEdit" />}
            handler={event => handleGetDeviceStatuses(organization.id, event)}
            toolTip="Device statuses"
          />
        </div>
      )}
    </button>
  );
};

OrganizationItem.propTypes = {
  organization: PropTypes.objectOf(PropTypes.any).isRequired,
  integrationId: PropTypes.string,
  organizationIntegrations: PropTypes.arrayOf(PropTypes.any),
  handleClick: PropTypes.func,
  handleGetDeviceStatuses: PropTypes.func,
  activeOrganizationId: PropTypes.string,
  updateIntegrationOrganization: PropTypes.func,
  handleGetHookAlerts: PropTypes.func,
};

export default OrganizationItem;
