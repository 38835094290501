import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Count = ({ data }) => {
  return (
    <>
      <Typography variant="h3">{data?.count}</Typography>
    </>
  );
};

Count.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default Count;
