import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';

const getFormattedDate = (date, format = defaultDateFormat) =>
  date
    ? moment
        .utc(date)
        .local()
        .format(format)
    : '';

export default getFormattedDate;
