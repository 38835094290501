import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ConversationTemplatesSelector } from '@store/slices/messaging';
import { ThunkGetConversationTemplates } from '@store/slices/messaging/thunks';

import Loading from '../common/Loading/Loading';
import SimpleTable from '../Triggers/pages/Incident/components/SimpleTable';
import ConversationTemplatesTableActions from './components/ConversationTemplatesTableActions';

import useStyles from './styles';
import ConversationTemplatesTableItemActions from './components/ConvesationTemplatesTableItemActions';

const ConversationTemplates = () => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    { label: 'Name', value: 'name' },
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Actions',
      handler: item => <ConversationTemplatesTableItemActions record={item} />,
    },
  ];

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetConversationTemplates());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <ConversationTemplatesTableActions />
      <SimpleTable selector={ConversationTemplatesSelector} columns={columns} />
    </div>
  );
};

export default ConversationTemplates;
