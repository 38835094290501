import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAssignAutotaskTicketContact,
  ThunkAssignHaloTicketContact,
  ThunkGetAutotaskTicketContacts,
  ThunkGetHaloTicketContacts,
} from '@store/slices/crmTicket/thunks';
import {
  ThunkAssignConnectWiseTicketContact,
  ThunkGetConnectWiseTicketContacts,
} from '@store/slices/connectWiseTicket/thunks';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';
import crmSources from '@constants/crmSources';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../styles';

const AssignContactDialog = ({
  open,
  setOpen,
  record,
  crmDefaultId,
  crmName,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [contacts, setContacts] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const getAssignContactAction = useMemo(() => {
    switch (crmName) {
      case crmSources.ConnectWise.label:
        return ThunkAssignConnectWiseTicketContact;
      case crmSources.Autotask.label:
        return ThunkAssignAutotaskTicketContact;
      case crmSources.Halo.label:
        return ThunkAssignHaloTicketContact;
      default:
        return () => null;
    }
  }, [crmName]);

  const getRequestContactAction = useMemo(() => {
    switch (crmName) {
      case crmSources.ConnectWise.label:
        return ThunkGetConnectWiseTicketContacts;
      case crmSources.Autotask.label:
        return ThunkGetAutotaskTicketContacts;
      case crmSources.Halo.label:
        return ThunkGetHaloTicketContacts;
      default:
        return () => null;
    }
  }, [crmName]);

  const submit = async values => {
    setProcessing(true);
    const contact = contacts.find(item => item.value === values.contactId);
    const updatedList = list.map(item =>
      item.id === record.id
        ? { ...record, contact: contact.label, contactId: values.contactId }
        : item,
    );
    await dispatch(
      getAssignContactAction({
        crmId: crmDefaultId,
        ticketId: record.id,
        contactId: values.contactId,
      }),
    ).unwrap();

    dispatch(actions.saveList(updatedList));
    setProcessing(false);
    handleClose();
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    const contactsData = await dispatch(
      getRequestContactAction({
        crmId: crmDefaultId,
        companyId: record.companyId,
        withPhone: true,
      }),
    ).unwrap();

    setContacts(contactsData);
    setLoading(false);
  }, [dispatch, crmDefaultId, record.companyId, getRequestContactAction]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Assign Contact</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            initialValues={{
              contactId: +record.contactId,
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <FieldWrapper
                  label="Contact"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      name="contactId"
                      id="contactId"
                      render={AutocompleteFormInput}
                      items={contacts}
                      disabled={processing}
                    />
                  }
                  classNameLabelInner={classes.alignLeft}
                />
                <div className={classes.formActions}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      handleClose();
                    }}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AssignContactDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  crmDefaultId: PropTypes.number,
  record: PropTypes.shape({
    contactId: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    companyId: PropTypes.number,
  }),
  crmName: PropTypes.string.isRequired,
};

export default AssignContactDialog;
