import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaIssueSubTypes } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaIssueSubTypes = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaIssueSubTypes`,
  ({ crmDefaultId, issueTypeId }) =>
    getKaseyaIssueSubTypes(crmDefaultId, issueTypeId),
);

export default {};
