import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chipWrapper: {
    border: '1px solid #EC6A4E',
    backgroundColor: theme.palette.listView.chip.background,
    maxWidth: '125px',
    color: theme.palette.listView.chip.color,
    height: '30px',
  },
  iconWrapper: {
    color: '#CACACA',
    width: '16px',
    height: '16px',
  },
  editIcon: {
    margin: theme.spacing(0.5),
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
  chipLabel: {
    paddingRight: 0,
  },
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default useStyles;
