import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    maxWidth: '700px',
    justifyContent: 'space-between',
  },
  font: {
    fontStyle: 'normal',
  },
  field: {
    alignSelf: 'center',
  },
  indicatorField: {
    width: '48px',
    textAlign: 'center',
  },
  valueField: {
    width: '45%',
    textAlign: 'center',
  },
  indicatorParent: {
    position: 'relative',
    width: '24px',
    height: '24px',
  },
  indicatorIcon: {
    position: 'absolute',
  },
  modalButtonContainer: {
    textAlign: 'center',
    marginTop: '1.25em',
  },
  modalCancelButtonContainer: {
    margin: '0 5px',
  },
  button: {
    '&:last-child': {
      marginLeft: '1.875rem',
    },
  },
  center: {
    textAlign: 'center',
  },
}));

export default useStyles;
