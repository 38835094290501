import { makeRequest } from '@services/requests/makeRequest';
import {
  VERIFIED_PSA_TECH_BY_SMS,
  PSA_CONTACTS,
} from '@services/requests/requestResources';

export const confirmVerifiedPsaTechBySms = payload =>
  makeRequest('POST', `${VERIFIED_PSA_TECH_BY_SMS}`, payload);

export const getContactByPhoneNumber = payload => {
  return makeRequest('POST', `/${PSA_CONTACTS}/contacts/checkByPhone`, payload);
};

export const getContactTickets = payload => {
  const { crmId, contactId } = payload;
  return makeRequest(
    'GET',
    `${VERIFIED_PSA_TECH_BY_SMS}/crm/${crmId}/tickets/${contactId}`,
  );
};

export const assignTicketToTechVerification = payload =>
  makeRequest(
    'POST',
    '/VerifiedPsaTechBySms/link-tech-verification-request',
    payload,
  );
