import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& span + div': {
      width: '100%',
    },
  },
  headerWrapper: {
    backgroundColor: theme.palette.tabs.background,
    padding: '1.25rem 0 0.875rem 1.4rem',
  },
  emailWrapper: {
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
  },
  dropDownWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: '#ededee',
  },

  selectWrapper: {
    border: 'none',
  },
  iconButtonWrapper: {
    position: 'absolute',
    right: 0,
    top: '0.1rem',
  },
  space: {
    height: '35px',
  },
  tabsWrapper: {
    marginTop: 0,
    display: 'flex',
    borderBottom: '0.0625rem solid #D9D5D5',
    backgroundColor: theme.palette.tabs.background,
  },
}));

export default useStyles;
