import { makeRequest } from '../makeRequest';
import { CHATS } from '../requestResources';

export const getUserChats = () => makeRequest('GET', `${CHATS}/userChats`);

export const getChatMessages = id =>
  makeRequest('GET', `${CHATS}/${id}/chatMessages`);

export const getAvailableUsers = () =>
  makeRequest('GET', `${CHATS}/getAvailableUsers`);

export const addChat = payload =>
  makeRequest('POST', `${CHATS}/createChat`, payload);

export const sendChatMessage = payload =>
  makeRequest('POST', `${CHATS}/sendMessage`, payload);

export default {};
