import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { SignInContext } from '../context';
import useStyles from '../styles';

const CardSummary = ({
  cardNumber = '**** **** **** ****',
  expirationMonth = 'MM',
  expirationYear = 'YY',
  name = 'John Dou Jr.',
  pricePlans,
  discountData = undefined,
}) => {
  const { technicianCount, selectedPlanId, initTechnicianCount } = useContext(
    SignInContext,
  );
  const classes = useStyles();

  const {
    name: selectedPlan,
    pricePerTechCents,
    basePriceCents,
  } = useMemo(() => {
    return (
      pricePlans.find(i => i?.id === selectedPlanId) ?? {
        name: 'Selected Plan',
        pricePerTechCent: 10,
      }
    );
  }, [pricePlans, selectedPlanId]);

  const totalPrice = useMemo(() => {
    const price =
      (basePriceCents +
        (technicianCount - initTechnicianCount) * pricePerTechCents) /
      100;
    if (discountData) {
      // Percent Discount
      if (discountData.type === 0) {
        const discounted = (+price * (100 - +discountData.amount)) / 100;

        return `first ${
          discountData.durationMonth
        } month(s) - $${discounted.toFixed(2)} after $${price}`;
      }
      // Solid discount
      return `first ${discountData.durationMonth} month(s) - $${price -
        discountData.amount} after $${price}`;
    }
    return `$${Number(price).toFixed(2)}`;
  }, [discountData, pricePerTechCents, technicianCount]);

  return (
    <div className={classes.cardsData}>
      <div className={classes.cardContainer}>
        <div className={classes.cardsTitle}>{cardNumber}</div>

        <div className={classes.rowAlign}>
          <div>
            <div className={classes.cardHolder}>Card Holder Name</div>
            <div className={classes.cardsTitle}>{name}</div>
          </div>

          <div>
            <div className={classes.cardHolder}>Expiry Date</div>
            <div className={classes.cardsTitle}>
              {`${expirationMonth}/${expirationYear}`}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.summary}>
        <h3>Summary</h3>

        <div className={classes.summaryBody}>
          <div className={classes.rowAlign}>
            <div>
              <b>Plan:</b>
            </div>
            <div>{selectedPlan}</div>
          </div>

          <div className={classes.rowAlign}>
            <div>
              <b>Base Price:</b>
            </div>
            <div>
              {`$${Number(basePriceCents / 100).toFixed(
                2,
              )} for first ${initTechnicianCount} licenses`}
            </div>
          </div>

          <div className={classes.rowAlign}>
            <div>
              <b>Plan Price:</b>
            </div>
            <div>
              {`$${Number(pricePerTechCents / 100).toFixed(
                2,
              )} per Technician License over ${initTechnicianCount}`}
            </div>
          </div>

          <div className={classes.rowAlign}>
            <div>
              <b>Technician Licenses Count:</b>
            </div>
            <div>{technicianCount}</div>
          </div>

          {discountData?.description && (
            <div className={classes.rowAlign}>
              <div>
                <b>Discount:</b>
              </div>
              <div>{discountData?.description}</div>
            </div>
          )}
        </div>

        <h4 className={classes.total}>
          <div>Total:</div>
          <div>{totalPrice}</div>
        </h4>
      </div>
    </div>
  );
};

CardSummary.propTypes = {
  cardNumber: PropTypes.string,
  expirationMonth: PropTypes.string,
  expirationYear: PropTypes.string,
  name: PropTypes.string,
  pricePlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  discountData: PropTypes.shape({
    type: PropTypes.number,
    durationMonth: PropTypes.number,
    amount: PropTypes.number,
  }),
};
export default CardSummary;
