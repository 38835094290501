import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import BooleanAccessor from '@ui/components/common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ReusableButton from '@ui/components/common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';

const InviteUsersResult = ({ items, handleForward }) => {
  const [open, setOpen] = useState(true);

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  useEffect(() => {
    setOpen(true);
  }, [items]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        handleForward();
      }}
    >
      <DialogTitle>Invite result</DialogTitle>
      <DialogContent>
        <div>
          <div className={classes.inviteDialogResultsHeader}>
            <div>{`Total Processed: ${items.length}`}</div>
            <div>{`Success: ${items.filter(i => i.sent).length}`}</div>
            <div>{`Errors: ${items.filter(i => !i.sent).length}`}</div>
          </div>
          <div className={classes.inviteDialogItemsList}>
            <div
              className={cx(
                classes.inviteDialogResultsList,
                classes.inviteDialogItemsListHeader,
                items.filter(i => !i.sent).length > 0
                  ? classes.inviteDialogResultsHasErrors
                  : null,
              )}
            >
              <div>Email</div>
              <div>Result</div>
              <div>Errors</div>
            </div>
            {items.map(item => (
              <div
                key={item.id}
                className={cx(
                  classes.inviteDialogResultsList,
                  items.filter(i => !i.sent).length > 0
                    ? classes.inviteDialogResultsHasErrors
                    : null,
                )}
              >
                <div>{item.data.email}</div>
                <div>
                  <BooleanAccessor value={item.sent} useMultiColor />
                </div>
                <div>{item.message}</div>
              </div>
            ))}
          </div>
          <div className={sharedClasses.dialogActionsContainer}>
            <ReusableButton
              label="Back"
              onClick={() => {
                setOpen(false);
              }}
            />
            <ReusableButton
              label="Close"
              onClick={() => {
                setOpen(false);
                handleForward();
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUsersResult;
