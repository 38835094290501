import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    backgroundColor: props => (props.isActive ? '#7ed572' : '#d5d5d5'),
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',

    fontWeight: 'bold',
    fontSize: '12px',
    marginRight: '8px',
    cursor: props => (props.disabled ? 'not-allowed' : 'pointer'),
  },
}));

export default useStyles;
