import * as React from 'react';
import PropTypes from 'prop-types';

import iconDelete from '@assets/icons/delete.svg';

import useStyles from './styles';

const ActionField = ({ record = {}, handleDeleteClick }) => {
  const classes = useStyles();
  const onDelete = () => handleDeleteClick(record);
  return (
    <div className={classes.container}>
      <span className={classes.icon} onClick={onDelete} role="presentation">
        <img src={iconDelete} alt="iconDelete" />
      </span>
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleDeleteClick: PropTypes.func.isRequired,
};

export default ActionField;
