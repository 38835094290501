import { makeRequest } from '../makeRequest';
import { TEST_EMAIL } from '../requestResources';

export const getEmailTestingTypes = () =>
  makeRequest('GET', `${TEST_EMAIL}/getTypesAutocomplete`);

export const postTestEmail = payload =>
  makeRequest('POST', `${TEST_EMAIL}/test`, payload);

export default {};
