import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  MenuItem,
  Select,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import { darkGreenColor, orangeColor } from '@constants/palette';
import unrecognizedTypeMode from '@constants/unrecognizedTypeMode';
import useStyles from './styles';

const UnrecognizedTypeItem = ({ item, onChange, options }) => {
  const classes = useStyles();
  const [mode, setMode] = useState(unrecognizedTypeMode.existing);
  const [typeValue, setTypeValue] = useState(-1);
  const [newTypeValue, setNewTypeValue] = useState('');
  const handleModeToggle = () => {
    const newMode =
      mode === unrecognizedTypeMode.existing
        ? unrecognizedTypeMode.new
        : unrecognizedTypeMode.existing;
    setMode(newMode);
    setTypeValue(-1);
    setNewTypeValue('');
  };

  let model = {
    id: item.id,
    typeId: item.typeId,
    value: item.value,
    typeValueId: -1,
    typeValueName: '',
  };

  const handleDropdownChange = event => {
    setNewTypeValue('');
    setTypeValue(event.target.value);
    model = {
      ...model,
      typeValueId: event.target.value,
      typeValueName: '',
    };
    onChange(model);
  };

  const handleInputChange = event => {
    setTypeValue(-1);
    setNewTypeValue(event.target.value);
    model = {
      ...model,
      typeValueId: -1,
      typeValueName: event.target.value,
    };
    onChange(model);
  };

  const isValueSet = () => Boolean(typeValue !== -1 || newTypeValue);

  const inputElement = (
    <Paper component="form" className={classes.root} style={{ width: '270px' }}>
      <Tooltip
        title={
          mode !== unrecognizedTypeMode.new ? 'Select existing' : 'Create new'
        }
        placement="left"
      >
        <IconButton
          className={classes.iconButton}
          aria-label="menu"
          onClick={handleModeToggle}
        >
          {mode === unrecognizedTypeMode.new ? (
            <SearchIcon />
          ) : (
            <AddCircleOutlineIcon />
          )}
        </IconButton>
      </Tooltip>
      {mode === unrecognizedTypeMode.new ? (
        <InputBase
          className={classes.input}
          placeholder="New Type Value"
          value={newTypeValue}
          onChange={handleInputChange}
        />
      ) : (
        <Select
          value={typeValue}
          onChange={handleDropdownChange}
          style={{ width: '210px' }}
        >
          <MenuItem value="-1" disabled>
            Select Existing Value
          </MenuItem>
          {options.map(i => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Paper>
  );

  return (
    <Paper className={`${classes.container} ${classes.font}`}>
      <div className={`${classes.field} ${classes.indicatorField}`}>
        <div className={classes.indicatorParent}>
          <Fade
            className={classes.indicatorIcon}
            in={!isValueSet()}
            timeout={500}
          >
            <ErrorOutlineIcon style={{ color: `${orangeColor}` }} />
          </Fade>
          <Fade
            className={classes.indicatorIcon}
            in={isValueSet()}
            timeout={500}
          >
            <DoneOutlineIcon style={{ color: `${darkGreenColor}` }} />
          </Fade>
        </div>
      </div>
      <div className={`${classes.field} ${classes.valueField}`}>
        <div>
          <b>{item.typeName}</b>
        </div>
        <div>{item.value}</div>
      </div>
      <div className={classes.field}>{inputElement}</div>
    </Paper>
  );
};

UnrecognizedTypeItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.objectOf(PropTypes.any),
};

export default UnrecognizedTypeItem;
