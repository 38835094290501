import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from '@common/Loading/Loading';
import useAsync from '@services/api/common/useAsync';
import DmSettingsForm from './DmSettingsForm';
import { getDmNotificationSettingsById } from './helpers';

const DmSettingsFormUpdate = ({
  match: {
    params: { id },
  },
}) => {
  const [data, setData] = useState(null);
  const getData = useCallback(() => getDmNotificationSettingsById(id), [id]);
  const loading = useAsync(getData, setData);

  return <>{loading ? <Loading /> : <DmSettingsForm data={data} />}</>;
};

DmSettingsFormUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default DmSettingsFormUpdate;
