import { useEffect, useState } from 'react';

const useCurrentBody = body => {
  const [currentBody, setCurrentBody] = useState(null);
  useEffect(() => {
    (async () => {
      setCurrentBody({ body: await body.body });
    })();
  }, [body]);

  return currentBody;
};

export default useCurrentBody;
