import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import copyText from '@utils/copy';

import { actions } from '@store/actions';

import ReusableButton from '@common/Button/Button';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import CreateDialog from './CreateDialog';

import useStyles from './styles';

const getFullLink = key =>
  `${window.location.origin}/#/demoRegistration?auth-key=${key}`;

const DemoReferralLinks = ({ resource }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCopy = key => copyText(getFullLink(key));
  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: idForDelete })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(handleCancelDelete);
  };

  const handleAddKey = model => {
    setCreateDialogOpen(false);
    dispatch(actions.updateItemInList(model));
  };

  return (
    <div className={classes.tableWrapper}>
      <ReusableButton
        size="md"
        label="Add"
        onClick={() => setCreateDialogOpen(true)}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <Table
        columns={[
          {
            name: 'Link Name',
            key: 'name',
          },
          {
            name: 'Link',
            key: 'link',
          },
          {
            name: 'Consumed by',
            key: 'consumer',
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: rowData => (
              <ActionField
                label="Actions"
                record={rowData}
                onCopy={onCopy}
                onDelete={onDelete}
                fieldKey="link"
              />
            ),
            width: 100,
          },
        ]}
        resource={resource}
        defaultSort={{ fieldName: 'id', order: 'DESC' }}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title="Are you sure you want to delete this link?"
        content="This action cannot be undone."
        loading={loading}
      />
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreate={handleAddKey}
      />
    </div>
  );
};

DemoReferralLinks.propTypes = {
  resource: PropTypes.string,
};

export default DemoReferralLinks;
