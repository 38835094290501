import crmSources from '@constants/crmSources';
import {
  ThunkGetAutotaskCompanies,
  ThunkGetConnectWiseCompanies,
  ThunkGetKaseyaCompanies,
} from '@store/slices/createTicket/thunks';
import { ThunkGetHaloClientsCrmId } from '@store/slices/HaloTickets/thunks';
import { ThunkGetZendeskCompanies } from '@store/slices/Zendesk/thunks';
import { ThunkGetSuperOpsCompanies } from '@store/slices/SuperOps/thunks';

/*
 * Kaseya, Autotask and CW use ({ psaId }) payload
 *
 * Halo use crmId payload
 */
export const getCompaniesThunk = crmType => {
  switch (crmType) {
    case crmSources.ConnectWise.name:
      return ThunkGetConnectWiseCompanies;
    case crmSources.Autotask.name:
      return ThunkGetAutotaskCompanies;
    case crmSources.Kaseya.name:
      return ThunkGetKaseyaCompanies;
    case crmSources.Halo.name:
      return ThunkGetHaloClientsCrmId;
    case crmSources.Zendesk.name:
      return ThunkGetZendeskCompanies;
    case crmSources.SuperOps.name:
      return ThunkGetSuperOpsCompanies;
    default:
      return () => {};
  }
};

export default {};
