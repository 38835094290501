/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TICKET_CREATION_TEMPLATES } from '@store/slices/resources';
import {
  ThunkCreateTicketCreationTemplate,
  ThunkDeleteTicketCreationTemplate,
  ThunkGetTicketCreationTemplate,
  ThunkGetTicketCreationTemplates,
  ThunkUpdateTicketCreationTemplate,
} from './thunks';

const initialState = {
  ticketCreationTemplates: [],
  ticketCreationTemplate: {},
};

const ticketCreationTemplatesSlice = createSlice({
  name: TICKET_CREATION_TEMPLATES,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      ThunkGetTicketCreationTemplates.fulfilled,
      (state, action) => {
        state.ticketCreationTemplates = action.payload;
      },
    );

    builder.addCase(
      ThunkGetTicketCreationTemplate.fulfilled,
      (state, action) => {
        state.ticketCreationTemplate = action.payload;
      },
    );

    builder.addCase(
      ThunkCreateTicketCreationTemplate.fulfilled,
      (state, action) => {
        state.ticketCreationTemplates.push(action.payload);
      },
    );

    builder.addCase(
      ThunkUpdateTicketCreationTemplate.fulfilled,
      (state, { payload }) => {
        state.ticketCreationTemplates = state.ticketCreationTemplates.map(i =>
          i.id === payload.id ? payload : i,
        );
      },
    );

    builder.addCase(
      ThunkDeleteTicketCreationTemplate.fulfilled,
      (state, { payload }) => {
        state.ticketCreationTemplates = state.ticketCreationTemplates.filter(
          i => i.value !== payload.id,
        );
      },
    );
  },
});

export default ticketCreationTemplatesSlice.reducer;
export const ticketCreationTemplatesSelector = state =>
  state[TICKET_CREATION_TEMPLATES].ticketCreationTemplates;
