import { makeRequest } from '@services/requests/makeRequest';
import { SECURE_DATA } from '@services/requests/requestResources';

export const generatePassword = params =>
  makeRequest('GET', `${SECURE_DATA}/generate?${params}`);

export const getSecureLink = payload =>
  makeRequest('POST', `${SECURE_DATA}`, payload);

export const sendSecureLink = payload =>
  makeRequest('POST', `${SECURE_DATA}/send`, payload);

export const checkSecureLink = code =>
  makeRequest('GET', `${SECURE_DATA}/check?code=${code}`);

export const getSecureLinkPageContent = code =>
  makeRequest('GET', `${SECURE_DATA}/pageContent?code=${code}`);
