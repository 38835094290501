import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getConnectWiseSites } from '@services/requests/createTicket';

export const ThunkGetConnectWiseSites = createAsyncThunk(
  `${CREATE_TICKET}/getConnectWiseSites`,
  ({ psaId, companyId }) => getConnectWiseSites(psaId, companyId),
);

export default {};
