import React, { useMemo, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { lightTheme } from '@services/themes/mainTheme';
import Letter from '@components/Letter/Letter';
import Loading from '@common/Loading/Loading';
import ErrorIcon from '@assets/icons/dangerTriangle.svg';
import { useParams } from 'react-router-dom';

import useStyles from './styles';

const theme = createTheme(lightTheme());

const SharedEmail = () => {
  const classes = useStyles();
  const params = useParams();
  const [error, setError] = useState(false);
  const match = useMemo(() => ({ params }), [params]);

  if (error) {
    return (
      <Loading
        customHeader="Error 404"
        customText="Document not found"
        customIcon={ErrorIcon}
        classNameWrapper={classes.center}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Letter
          match={match}
          unauthorized
          errorHandler={() => setError(true)}
        />
      </div>
    </ThemeProvider>
  );
};

export default SharedEmail;
