import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Tooltip,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

import NotificationIcon from '@common/NotificationIcon/NotificationIcon';
import mailIcon from '@assets/icons/email_active.svg';
import smsIcon from '@assets/icons/sms.svg';
import useStyles from './styles';

const icons = [
  {
    icon: mailIcon,
    status: 'notifyByEmail',
    info: 'emailDeliveryType',
  },
  {
    icon: smsIcon,
    status: 'notifyBySms',
    info: 'smsDeliveryType',
  },
];

const AssignDialog = ({ open, users, onClose, onSelect, disabled }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.title} id="simple-dialog-title">
        Set Assignee
      </DialogTitle>
      <List classes={{ root: classes.list }}>
        {users.map(user => (
          <ListItem
            disabled={disabled}
            className={classes.listItem}
            classes={{ gutters: classes.gutters }}
            button
            onClick={() => onSelect(user.id)}
            key={user.id}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar} src={user.imageUri}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} />
            {icons.map(icon => (
              <Tooltip title={icon.info} key={icon.status}>
                <NotificationIcon item={icon} record={user} />
              </Tooltip>
            ))}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

AssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
};

export default AssignDialog;
