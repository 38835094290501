import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  theme =>
    createStyles({
      queryBuilder: {
        width: '100%',
      },
      header: {
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: theme.spacing(2),
      },
      ruleGroup: {
        padding: theme.spacing(2),
        border: '2px solid #afafaf',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 10px rgba(102, 124, 158, 0.3)',
        borderRadius: theme.spacing(0.5),
        background: 'transparent',
        marginTop: theme.spacing(2),
        textAlign: 'end',
      },
      rule: {
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: '12px',
        marginTop: theme.spacing(2),
      },
      combinators: {
        border: '0.0625rem solid #E2E2E2',
        borderRadius: '0.3125rem',
        fontSize: '0.875rem',

        width: '4rem',
      },
      fields: {
        [theme.breakpoints.up('sm')]: {
          width: '7rem',
        },
        minHeight: '2rem',
        fontSize: '0.875rem',

        border: '0.0625rem solid #E2E2E2',
        borderRadius: '0.3125rem',
      },
      operators: {
        minHeight: '2rem',
        fontSize: '0.875rem',

        [theme.breakpoints.up('sm')]: {
          width: '7rem',
        },
        border: '0.0625rem solid #E2E2E2',
        borderRadius: '0.3125rem',
      },
      value: {
        [theme.breakpoints.up('sm')]: {
          fontSize: '0.875rem',
          width: '22.5rem',
        },
        padding: '0.3rem 0.625rem 0.38rem',
        fontFamily: 'Nunito, sans-serif',
        color: '#2F2F2F',
        boxShadow: '0 20px 84px -20px rgba(8, 63, 115, 0.25)',
        borderRadius: '0.3125rem',
        border: '1px solid #E2E2E2',
      },
      notToggle: { marginRight: theme.spacing(2) },
    }),
  {
    name: 'rulesInput',
  },
);
