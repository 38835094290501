import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import StringArraySelectorItem from './StringArraySelectorItem';

const StringArraySelector = ({
  input: { value, onChange },
  itemsList,
  disabled,
  strLength = 3,
}) => {
  const isActive = useCallback(val => value.includes(val), [value]);

  const onItemClick = itemValue => {
    if (value.includes(itemValue)) {
      onChange(value.filter(v => v !== itemValue));
    } else {
      onChange([...value, itemValue]);
    }
  };

  return (
    <Grid container>
      {itemsList.map(v => (
        <StringArraySelectorItem
          key={v}
          value={v}
          isActive={isActive(v)}
          onClick={onItemClick}
          disabled={disabled}
          strLength={strLength}
        />
      ))}
    </Grid>
  );
};

StringArraySelector.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
  }).isRequired,
  itemsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  strLength: PropTypes.number,
};

export default StringArraySelector;
