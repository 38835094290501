import React from 'react';
import PropTypes from 'prop-types';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import {
  sophosEndpointTypes,
  tableKeys,
  sophosStatuses,
} from '@common/MuiVirtualizedTable/constants';
import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import EndpointDetailsAccessor from './EndpointDetailsAccessor';

const columns = [
  {
    key: 'tenantName',
    name: <TableLabel item={{ name: 'Tenant', field: 'tenantName' }} />,
  },
  {
    key: 'type',
    name: (
      <TableDropDown
        item={{ field: 'type', name: 'Device Type' }}
        options={sophosEndpointTypes}
        labelHandler={key => sophosEndpointTypes[key].name}
      />
    ),
  },
  {
    key: 'hostname',
    name: <TableLabel item={{ name: 'Host Name', field: 'hostname' }} />,
  },
  {
    key: 'overall',
    name: (
      <TableDropDown
        item={{ field: 'overall', name: 'Overall Status' }}
        options={sophosStatuses}
        labelHandler={key => sophosStatuses[key].name}
      />
    ),
  },
  {
    key: 'threats',
    name: (
      <TableDropDown
        item={{ field: 'threats', name: 'Threats Status' }}
        options={sophosStatuses}
        labelHandler={key => sophosStatuses[key].name}
      />
    ),
  },
  {
    key: 'services',
    name: (
      <TableDropDown
        item={{ field: 'services', name: 'Services Status' }}
        options={sophosStatuses}
        labelHandler={key => sophosStatuses[key].name}
      />
    ),
  },
  {
    key: 'actions',
    name: 'actions',
    accessor: rowData => <EndpointDetailsAccessor record={rowData} />,
    width: 100,
  },
];

const SophosGrid = ({ data }) => (
  <div style={{ height: 650, width: '100%' }}>
    <MuiVirtualizedListView
      tableKey={tableKeys.sophosDashboard}
      data={data}
      columns={columns}
    />
  </div>
);

SophosGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default SophosGrid;
