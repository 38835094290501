import { sourceOptions } from '../ui/components/Parsers/components/Create/components/DataExpression/options';
import { mailBodyView } from '../ui/components/Letter/params';

const prefix = 'selectionSrc_';

export const selectionSource = Object.freeze({
  From: `${prefix}From`,
  FromName: `${prefix}FromName`,
  To: `${prefix}To`,
  Subject: `${prefix}Subject`,
  Body: `${prefix}Body`,
  ReceivedAt: `${prefix}ReceivedAt`,
  Headers: `${prefix}Headers`,
});

export const getClearName = value => value.substring(prefix.length);

export const oneClickElements = [
  selectionSource.From,
  selectionSource.FromName,
  selectionSource.To,
  selectionSource.Subject,
];

export const getSelectionSource = (value, type) => {
  if (value.includes(selectionSource.FromName)) return sourceOptions[4];
  if (value.includes(selectionSource.From)) return sourceOptions[3];
  if (value.includes(selectionSource.To)) return sourceOptions[5];
  if (value.includes(selectionSource.Subject)) return sourceOptions[2];
  if (value.includes(selectionSource.ReceivedAt)) return sourceOptions[10];
  if (type.startsWith('attachment_')) return sourceOptions[12];
  if (type === mailBodyView[0].value) return sourceOptions[0];
  if (type === mailBodyView[3].value) return sourceOptions[13];
  return sourceOptions[1];
};
