import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Loading from '@common/Loading/Loading';

import FieldWrapper from '@common/form/FieldWrapper';
import Paper from '@common/Paper/Paper';
import {
  notificationDataViewSelector,
  smsSettings,
} from '@store/selectors/notifications';
import { actions } from '@store/actions';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components//Hooks/useDisabled';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import DateFormatSelector from '@components/Ticketing/Notifications/TicketSettings/components/subComponents/DateFormatSelector';
import {
  InputWithTemplate,
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import { getSmsSettings, postSmsSettings } from '../helpers';
import useTicketSetting from '../../hooks/useTicketSetting';
import { smsSettingsValidator } from '../../validators';
import { handleDoWChange } from '../../utils';
import PhoneInput from './subComponents/PhoneInput';
import useStyles from './styles';

const SmsSettings = ({ match }) => {
  const classes = useStyles();

  const dataViewId = useSelector(notificationDataViewSelector);
  const storedData = useSelector(smsSettings);

  const { submit, loading, handleBack, data, setData } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveSmsSetting,
    tileKey: 'isSms',
    apiPostRequest: postSmsSettings,
    apiGetSetting: getSmsSettings,
  });

  const { templateVariables } = useTemplateVariables({
    paramsId: match.params.id,
    dataViewId,
  });

  const onDoWChange = handleDoWChange(setData);

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={smsSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <FieldWrapper
                label="Phone number"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <PhoneInput
                      form={form}
                      disabled={disabled}
                      templateVariables={templateVariables}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label="Message"
                labelSize={3}
                content={
                  <InputWithTemplate
                    name="template"
                    disabled={disabled}
                    form={form}
                    templateVariables={templateVariables}
                  />
                }
              />
              <DateFormatSelector
                data={data}
                values={values}
                disabled={disabled}
                form={form}
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

SmsSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default SmsSettings;
