import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import useStyles from './styles';

const TableCellWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.cell} align="center">
      {children}
    </TableCell>
  );
};

const DetailsTableHeader = ({
  contentFirstCell,
  contentSecondCell,
  contentThirdCell,
  contentFourthCell,
}) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.header}>
      <TableRow>
        {contentFirstCell && (
          <TableCellWrapper>{contentFirstCell}</TableCellWrapper>
        )}
        {contentSecondCell && (
          <TableCellWrapper>{contentSecondCell}</TableCellWrapper>
        )}
        {contentThirdCell && (
          <TableCellWrapper>{contentThirdCell}</TableCellWrapper>
        )}
        {contentFourthCell && (
          <TableCellWrapper>{contentFourthCell}</TableCellWrapper>
        )}
      </TableRow>
    </TableHead>
  );
};

DetailsTableHeader.propTypes = {
  contentFirstCell: PropTypes.bool,
  contentSecondCell: PropTypes.string,
  contentThirdCell: PropTypes.string,
  contentFourthCell: PropTypes.string,
};

TableCellWrapper.propTypes = {
  children: PropTypes.node,
};

export default DetailsTableHeader;
