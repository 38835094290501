import React from 'react';
import { ReactComponent as CouponIcon } from '@assets/icons/bill.svg';
import List from './Coupons';

const Icon = () => <CouponIcon alt="linkIcon" />;

export default {
  list: List,
  icon: Icon,
};
