import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { deleteSmsCampaignGroupItem } from '@services/requests/smsCampaigns';

export const ThunkDeleteSmsCampaignGroupItems = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/deleteSmsCampaignGroupItem`,
  async ids => deleteSmsCampaignGroupItem(ids),
);

export default {};
