import putData from '@services/api/common/putData';
import appConfig from '@configs/appConfig';
import { tenants } from '@constants/appRoutes';
import deleteWithResponse from '@services/api/common/deleteWithResponse';

export const toggleEnabled = (id, data) =>
  putData(`${appConfig.baseUrl}${tenants}${id}/makeEnabled`, data);

export const deleteTenant = (id, key) =>
  deleteWithResponse(`${appConfig.baseUrl}${tenants}${id}/delete?key=${key}`);
