import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from './styles';

const ViewAutoComplete = ({
  currentView,
  options,
  optionLabel = 'label',
  onChange,
  loading = false,
  textFieldLabel = 'Data view',
  formControlClassNameWrapper,
  disablePortal = false,
  meta,
  disabled,
}) => {
  const classes = useStyles();

  const handleChange = (_, value) => onChange(value);

  return (
    <FormControl
      variant="outlined"
      className={cx(classes.formControl, formControlClassNameWrapper)}
    >
      <Autocomplete
        size="small"
        className={classes.selectWrapper}
        classes={{ root: classes.rootAutocomplete }}
        options={options}
        getOptionLabel={option => option[optionLabel]}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={textFieldLabel}
            error={!!(meta?.touched && meta?.error)}
            helperText={meta?.touched && meta?.error}
          />
        )}
        value={currentView}
        disablePortal={disablePortal}
        onChange={handleChange}
        disabled={loading || options.length === 0 || disabled}
      />
    </FormControl>
  );
};

ViewAutoComplete.propTypes = {
  currentView: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  options: PropTypes.arrayOf(PropTypes.object),
  optionLabel: PropTypes.string,
  textFieldLabel: PropTypes.string,
  formControlClassNameWrapper: PropTypes.string,
  loading: PropTypes.bool,
  disablePortal: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ViewAutoComplete;
