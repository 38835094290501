import { actionTypes } from '@store/actions';

const defaultState = {
  tenantOptions: [],
  info: {},
};

const smsListReduces = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SMSLIST_SET_TENANT_OPTIONS:
      return { ...state, tenantOptions: action.payload };
    case actionTypes.SMSLIST_SET_INFO:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};

export default smsListReduces;
