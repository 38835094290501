import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';

import { ThunkGetMessagingTemplates } from '@store/slices/messaging/thunks';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';

import { requiredValidator } from '@utils/validators';
import { maxLengthProps } from '@utils/maxLengthProps';

import useStyles from '../styles';

const CreateSmsMessage = ({ handleNext }) => {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleTemplateChange = form => value => {
    const selectedTemplate = templates.find(i => i.id === value);
    form.change('message', selectedTemplate.description);
  };

  const validate = values => {
    return {
      message: requiredValidator(values.message),
    };
  };

  const submit = values => {
    handleNext(values.message);
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetMessagingTemplates())
      .unwrap()
      .then(res => {
        setTemplates(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} className={classes.createMessage}>
            <FieldWrapper
              label="Conversation Templates"
              labelSize={12}
              contentSize={12}
              showLabel
              content={
                <Field
                  name="description"
                  id="description"
                  render={DropDown}
                  options={templates}
                  labelName="subject"
                  valueName="id"
                />
              }
            />

            <OnChange name="description">{handleTemplateChange(form)}</OnChange>

            <FieldWrapper
              label="Message"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="message"
                  id="message"
                  render={Input}
                  multiline
                  {...maxLengthProps(values.message)}
                />
              }
            />

            <div className={classes.createMessageButtons}>
              <ReusableButton label="Next" type="submit" viewType="blue" />
            </div>
          </form>
        )}
      />
    </div>
  );
};

CreateSmsMessage.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default CreateSmsMessage;
