import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@common/Chip/Chip';
import useStyles from './styles';

const ChipField = ({ source, record = {} }) => {
  const classes = useStyles();
  const data = record[source];
  if (!data) {
    return null;
  }
  return data ? (
    <div className={classes.root}>
      {data.map(i => (
        <Chip label={i} key={i} />
      ))}
    </div>
  ) : null;
};

ChipField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
};

export default ChipField;
