import { createAsyncThunk } from '@reduxjs/toolkit';
import { toggleConnectWiseTicketIssue } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkToggleConnectWiseTicketIssue = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/toggleConnectWiseTicketIssue`,
  async ({ crmDefaultId, ticketId, noteId, issueState }) => {
    const res = await toggleConnectWiseTicketIssue(
      crmDefaultId,
      ticketId,
      noteId,
      issueState,
    );
    return res;
  },
);

export default {};
