import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthProvider, useSafeSetState } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { createTheme } from '@material-ui/core';

import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import { lightTheme } from '@services/themes/mainTheme';
import emailValidator from '@utils/validators/emailValidator';
import useStyles from '../loginStyles';

const theme = createTheme(lightTheme());

const ForgotPasswordForm = ({ onSubmit }) => {
  const classes = useStyles();
  const auth = useAuthProvider();

  const history = useHistory();

  const [loading, setLoading] = useSafeSetState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const validate = values => {
    const errors = { email: undefined };

    if (!values.email) {
      errors.email = 'Required';
    }

    const emailErrors = emailValidator(values.email);
    errors.email = emailErrors || undefined;

    return errors;
  };

  const submit = ({ email }) => {
    setLoading(true);
    auth
      .forgotPassword(email)
      .then(onSubmit)
      .catch(e => setErrorMessage(e.message))
      .finally(() => setLoading(false));
  };

  const onCancel = () => history.goBack();

  const errorTextArea = () => (
    <div className={classes.errorTextArea}>
      <div className={classes.errorCaption}>{!!errorMessage && 'Error'}</div>
      <div>{errorMessage}</div>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.fieldsContainer}>
              <span className={classes.font}>
                Enter your e-mail address and we&apos;ll send you a link to
                reset your password
              </span>
              <Field
                id="email"
                name="email"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                placeholder="Email"
                disabled={loading}
                classNameWrapper={classes.inputWrapper}
              />
            </div>
            {errorTextArea()}
            <ReusableButton
              size="xl"
              viewType="black"
              type="submit"
              classNameWrapper={classes.button}
              disabled={loading}
              loading={loading}
              label="Reset"
            />
            <ReusableButton
              size="xl"
              type="button"
              disabled={loading}
              classNameWrapper={classes.secondButton}
              label="Cancel"
              onClick={onCancel}
            />
          </form>
        )}
      />
    </ThemeProvider>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
