import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingChannel } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingChannel = createAsyncThunk(
  `${MESSAGING}/getMessagingChannel`,
  async channelId => {
    const channel = await getMessagingChannel(channelId);
    return channel;
  },
);

export default {};
