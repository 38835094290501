import { createAsyncThunk } from '@reduxjs/toolkit';
import { addTechnicianNumber } from '@services/requests/crm';
import { MESSAGING } from '@store/slices/resources';

export const ThunkAddTechnicianNumber = createAsyncThunk(
  `${MESSAGING}/addTechnicianNumber`,
  async ({ channelId, payload }) => {
    await addTechnicianNumber(channelId, payload);
    return payload;
  },
);

export default {};
