import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { actions } from '@store/actions';

import Checkbox from '@ui/components/common/Checkbox';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';
import PasswordInput from '@components/Auth/Common/PasswordInput';
import {
  ThunkGetClientPortals,
  ThunkLinkClientPortalUser,
} from '@store/slices/clientPortalUsers/thunks';
import { emailValidator, requiredValidator } from '@utils/validators';

import useStyles from './styles';

const ClientPortalAssignDialog = ({
  open,
  setOpen,
  record,
  psaId,
  contactId,
  contactName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [clientPortals, setClientPortals] = useState([]);

  const submit = values => {
    setProcessing(true);
    const payload = { ...values, crmId: psaId, contactId, contactName };
    dispatch(ThunkLinkClientPortalUser(payload))
      .unwrap()
      .then(() => {
        dispatch(
          actions.updateItemInList({ ...record, hasClientPortal: true }),
        );
      })
      .finally(() => setOpen(false));
  };

  const validate = values => {
    return {
      login: emailValidator(values.login),
      clientPortalId: requiredValidator(values.clientPortalId),
    };
  };

  const onMount = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkGetClientPortals(psaId))
      .unwrap()
      .then(res => {
        setClientPortals(res);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        <span>Adjust </span>
        <span>{record.name}</span>
        <span> as client portal user</span>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            login: record.email,
            sendInvitationEmail: true,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Client Portal"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                content={
                  <Field
                    name="clientPortalId"
                    id="clientPortalId"
                    render={DropDown}
                    disabled={processing}
                    options={clientPortals}
                  />
                }
              />

              <FieldWrapper
                label="Login"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                content={
                  <Field
                    name="login"
                    id="login"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Password"
                labelSize={12}
                contentSize={12}
                showLabel
                infoText="Left blank to allow user to change password"
                content={
                  <Field
                    name="password"
                    id="password"
                    render={PasswordInput}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Send Invitation Email"
                labelSize={6}
                contentSize={1}
                fullWidth
                content={
                  <Field
                    name="sendInvitationEmail"
                    id="sendInvitationEmail"
                    checked={values.sendInvitationEmail}
                    render={Checkbox}
                    disabled={processing}
                  />
                }
              />

              {!record.email && (
                <div className={classes.invalidEmailText}>
                  Invalid contact email, configure it first
                </div>
              )}

              <div className={classes.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing || !record.email}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ClientPortalAssignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  record: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  psaId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};

export default ClientPortalAssignDialog;
