import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTicketInfo } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetTicketInfo = createAsyncThunk(
  `${CRM_TICKET}/getTicketInfo`,
  async ({ crmId, ticketId }) => {
    const ticketInfo = await getTicketInfo(crmId, ticketId);
    return ticketInfo;
  },
);

export default {};
