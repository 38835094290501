import React from 'react';
import PropTypes from 'prop-types';

import Table from '@components/Table';
import HyperlinkAccessor from '@common/MuiVirtualizedTable/components/accessors/HyperlinkAccessor';
import TitledValueAccessor from '@common/MuiVirtualizedTable/components/accessors/TitledValueAccessor';
import DetailedViewAccessor from '@common/MuiVirtualizedTable/components/accessors/DetailedViewAccessor';
import DateAccessor from '@common/MuiVirtualizedTable/components/accessors/DateAccessor';
import { statusesOptions } from '@common/MuiVirtualizedTable/constants';
import { TableTypes } from '@components/Table/constants';
import statusColors from '@components/DataSources/Dashboards/statusColors';

const DeviceStatusesGrid = ({ resource }) => {
  const circleStyle = value => {
    return value
      ? {
          width: '10px',
          height: '10px',
          display: 'inline-block',
          margin: '0 10px',
          borderRadius: '50%',
          backgroundColor: statusColors[value],
        }
      : null;
  };

  const columns = [
    {
      key: 'organizationName',
      name: 'Organization',
      sortable: true,
      searchable: true,
      minWidth: 200,
      isCustomAccessor: true,
      accessor: row => (
        <HyperlinkAccessor
          href={row.organizationUrl}
          value={row.organizationName}
        />
      ),
    },
    {
      key: 'networkName',
      name: 'Network',
      minWidth: 200,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <HyperlinkAccessor href={row.networkUrl} value={row.networkName} />
      ),
    },
    {
      key: 'name',
      name: 'Device Name',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      key: 'model',
      name: 'Model',
      sortable: true,
      searchable: true,
      minWidth: 150,
    },
    {
      key: 'serial',
      name: 'Serial Number',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      key: 'mac',
      name: 'MAC address',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      key: 'status',
      name: 'Device Status',
      sortable: true,
      searchable: true,
      type: TableTypes.dropdown,
      dropDownValues: statusesOptions,
      filterByAutocomplete: 'status',
      minWidth: 200,
      isCustomAccessor: true,
      accessor: row => {
        return (
          <>
            <div style={circleStyle(row.status)} />
            <span>{row.status}</span>
          </>
        );
      },
    },
    {
      key: 'lastReportedAt',
      name: 'Last Reported Date',
      sortable: true,
      isCustomAccessor: true,
      minWidth: 200,
      accessor: row => <DateAccessor value={row.lastReportedAt} />,
    },
    {
      name: 'Product type',
      key: 'productType',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      name: 'Tags',
      key: 'tags',
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => <TitledValueAccessor value={row.tags} />,
      minWidth: 150,
    },
    {
      name: 'Lan IP',
      key: 'lanIp',
      sortable: true,
      searchable: true,
      minWidth: 150,
    },
    {
      name: 'Wan IPs',
      key: 'WanIps',
      minWidth: 200,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <DetailedViewAccessor value={[row.publicIp, row.wan1Ip, row.wan2Ip]} />
      ),
    },
    {
      name: 'Wan Gateways',
      key: 'wanGateways',
      type: TableTypes.actions,
      minWidth: 200,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <DetailedViewAccessor value={[[row.wan1Gateway, row.wan2Gateway]]} />
      ),
    },
  ];

  return <Table resource={resource} columns={columns} />;
};

DeviceStatusesGrid.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default DeviceStatusesGrid;
