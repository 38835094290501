import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputGroup: {
    width: '103%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '20px 0',
  },
  font: {
    fontStyle: 'normal',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
  buttonArrowButton: {
    marginTop: '1rem',
  },
  iconArrow: {
    transform: 'rotate(180deg)',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '2.5rem',
    marginLeft: 0,
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:last-child': {
        marginLeft: '1.875rem',
      },
    },
  },
  input: {
    marginTop: '0.625rem',
  },
  div: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '0 10px',
    flexWrap: 'wrap',
  },
  monospace: {
    fontFamily: 'Monospace',
  },
}));

export default useStyles;
