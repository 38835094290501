import { TICKET_UPDATE } from '@services/requests/requestResources';
import { makeRequest } from '../makeRequest';

export const getAutotaskNotes = (crmDefaultId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/autotask/${crmDefaultId}/tickets/${ticketId}/notes`,
  );

export const createAutotaskNote = (crmDefaultId, ticketId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/autotask/${crmDefaultId}/tickets/${ticketId}/notes`,
    payload,
  );

export const updateAutotaskNote = (crmDefaultId, ticketId, noteId, payload) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/autotask/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}`,
    payload,
  );
