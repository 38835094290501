import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import ReusableButton from '@common/Button/Button';
import useStyles from './styles';

const ConfirmDialog = ({
  open,
  setOpen,
  onAccept,
  onCancel,
  title,
  content,
  loading,
  htmlContent = null,
}) => {
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setOpen(false);
    }
  };

  return (
    <MuiDialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
          {htmlContent != null ? htmlContent() : null}
        </DialogContentText>
        <DialogActions
          classes={{
            root: classes.dialogActionsRoot,
            spacing: classes.spacingWrapper,
          }}
        >
          <ReusableButton
            label="Cancel"
            onClick={handleCancel}
            classNameWrapper={classes.confirmBtnContainer}
            size="md"
            disabled={loading}
          />

          <ReusableButton
            viewType="black"
            autoFocus
            onClick={onAccept}
            classNameWrapper={classes.confirmBtnContainer}
            size="md"
            disabled={loading}
            loading={loading}
            label="Confirm"
          />
        </DialogActions>
      </DialogContent>
    </MuiDialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmDialog;
