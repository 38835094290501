export const fields = [
  { description: 'Chats', name: 'chats' },
  { description: 'Mail Boxes', name: 'mailBoxes' },
  { description: 'Web Hooks', name: 'webHooks' },
  { description: 'Generic Sources', name: 'genericSources' },
  { description: 'Triggers', name: 'triggers' },
  { description: 'Uptime Monitors', name: 'uptimeMonitors' },
  { description: 'SMS Messaging', name: 'smsMessaging' },
  { description: 'Alert Systems', name: 'alertSystemIntegrations' },
  { description: 'WebHook Integrations', name: 'webHookIntegrations' },
  { description: 'Autotask Note to SMS', name: 'autotaskNoteToSms' },
];
