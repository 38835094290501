import { createAsyncThunk } from '@reduxjs/toolkit';
import { createConnectWiseTicket } from '@services/requests/createTicket';
import { CREATE_TICKET } from '@store/slices/resources';

const generatePayload = values => ({
  companyId: +values.companyId,
  boardId: +values.defaultBoardId,
  statusId: +values.openBoardStatusId,
  ticketTypeId: +values.typeId,
  sourceId: +values.sourceId,
  priorityId: +values.ticketPriority,
  summary: values.summary,
  initialDescription: values.initialDescription,
  createChannel: values.createChannel || false,
  messagingConfigurationId: values?.messagingConfigurationId,
  userPhone: values?.userPhone,
  contactId: +values?.contactId ?? null,
  siteId: +values?.siteId ?? null,
  agreementId: +values?.agreementId ?? null,
});

// * used to create ticket without psa default id, only with psa integration id
export const ThunkCreateConnectWiseTicket = createAsyncThunk(
  `${CREATE_TICKET}/createConnectWiseTicket`,
  ({ psaId, payload }) =>
    createConnectWiseTicket(psaId, generatePayload(payload)),
);

export default {};
