import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalChatMessage } from '@services/requests/clientPortal';

export const ThunkGetClientPortalChatMessages = createAsyncThunk(
  `${CLIENT_PORTALS}/getClientPortalChatMessage`,
  ({ chatId }) => getClientPortalChatMessage(chatId),
);

export default {};
