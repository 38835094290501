import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { crmAutocomplete, notificationGroups } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';

export const getCrmOptions = () =>
  getData(`${appConfig.baseUrl}${crmAutocomplete}`);

export const getGroupsOptions = () =>
  getData(`${appConfig.baseUrl}${notificationGroups}groups`);

export const addToGroup = data =>
  postWithResponse(`${appConfig.baseUrl}${notificationGroups}users`, data);
