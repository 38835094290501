import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationSources } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationSources = createAsyncThunk(
  `${INTEGRATION}/getIntegrationSources`,
  async ({ id }) => {
    const sources = await getIntegrationSources(id);
    return sources;
  },
);

export default {};
