export const ackOptions = Object.freeze([
  { value: 'None', label: 'Does not matter' },
  { value: 'Acknowledged', label: 'Somebody assigned' },
  { value: 'NotAcknowledged', label: 'Nobody assigned' },
]);

export const initial = {
  id: 0,
  isActive: true,
  name: '',
  query: {
    statuses: [],
    priorities: [],
    isAcknowledge: ackOptions[0].value,
    pastDueMinutes: 0,
    reEscalationRules: [],
  },
  isSms: false,
  smsText: null,
  isEmail: false,
  emailText: null,
  emailSubject: null,
  notificationGroupIds: [],
  notificationUserIds: [],
};
