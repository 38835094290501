import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaIssueTypes } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaIssueTypes = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaIssueTypes`,
  ({ crmDefaultId }) => getKaseyaIssueTypes(crmDefaultId),
);

export default {};
