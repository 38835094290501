import { createAsyncThunk } from '@reduxjs/toolkit';
import { identifyLiveChatUser } from '@services/requests/liveChats';
import { LIVE_CHATS } from '@store/slices/resources';

export const ThunkIdentifyLiveChatUser = createAsyncThunk(
  `${LIVE_CHATS}/identifyLiveChatUser`,
  ({ liveChatId, payload }) => identifyLiveChatUser(liveChatId, payload),
);

export default {};
