import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  itemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1px',
    padding: '1px',
  },
  itemName: {
    cursor: 'pointer',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '960px',
    transform: 'translate(-50%, -50%)',
  },
  paperRoot: {
    padding: '1rem',
  },
  button: {
    margin: '0.5rem',
  },
  filters: {
    padding: '10px',
  },
  center: {
    textAlign: 'center',
  },
}));

export default useStyles;
