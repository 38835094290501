import React from 'react';
import { Grid } from '@material-ui/core';

import useStyles from '../styles';

const WebHookResultsHeader = ({
  recordId,
  recordStatus,
  recordColor,
  recordDate,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.blockWrapper}>
      <div className={classes.headerWrapper}>
        <div>
          <h3>
            ID:
            {recordId}
          </h3>
        </div>
        <div className={classes.dateBlock}>
          <h3>
            Date:
            {recordDate}
          </h3>
        </div>
        <div className={classes.statusBlock}>
          <p>Status</p>
          <p
            style={{
              backgroundColor: recordColor,
              color: 'white',
              padding: '5px',
            }}
          >
            {recordStatus}
          </p>
        </div>
      </div>
    </Grid>
  );
};

export default WebHookResultsHeader;
