import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { currentUserDataSelector } from '@store/selectors';

import AccordionSummaryRequired from '@components/SetupWizard/components/AccordionSummaryRequired';
import MultiFactorSetup from '@components/MultiFactorSetup/MultiFactorSetup';

const Mfa = ({ refetch }) => {
  const userData = useSelector(currentUserDataSelector);

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummaryRequired
        title="MFA Setup"
        isChecked={userData.useMfa}
        hideRequired={userData.microsoftLinked || userData.googleLinked}
      />
      <AccordionDetails>
        <MultiFactorSetup refetch={refetch} />
      </AccordionDetails>
    </Accordion>
  );
};

Mfa.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default Mfa;
