import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCWContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetCWContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getCWContactData`,
  async ({ crmId, contactId }) => getCWContactData(crmId, contactId),
);

export default {};
