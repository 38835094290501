import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: '80vh',
    overflowY: 'auto',
    background: 'white',
    margin: '30px',
    boxSizing: 'border-box',
    padding: '30px',
  },
  listItem: {
    padding: '20px',
    boxSizing: 'border-box',
    margin: '10px',
    background: 'rgba(238,238,238,0.08)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    display: 'grid',
    gap: '20px',
    alignItems: 'center',
    border: '1px solid #a9a6a6',
  },
  dropDownContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 3fr 0fr',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 800,
    margin: '10px 0px',
  },
  listItem: {
    padding: '20px',
    boxSizing: 'border-box',
    margin: '10px',
    background: 'rgba(238,238,238,0.08)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    display: 'grid',
    gap: '20px',
    alignItems: 'center',
    border: '1px solid #a9a6a6',
  },
  listItemField: {
    alignItems: 'center',
    fontWeight: 800,
    display: 'grid',
    gridTemplateColumns: '0.8fr 1fr',
  },
}));

export default useStyles;
