import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA } from '@store/slices/resources';
import { getSecureLink } from '@services/requests/secureData';

export const ThunkGetSecureLink = createAsyncThunk(
  `${SECURE_DATA}/getSecureLink`,
  ({ payload }) => getSecureLink(payload),
);

export default {};
