import { notificationTypes } from '@constants/notificationTypes';
import ticketIcon from '@assets/icons/ticket.svg';
import emailIcon from '@assets/icons/email_active.svg';
import smsIcon from '@assets/icons/sms.svg';
import webhookIcon from '@assets/icons/webhooks.svg';
import slackIcon from '@assets/icons/slack.svg';
import teamsIcon from '@assets/icons/teams.svg';
import ftpIcon from '@assets/icons/ftp.svg';
import voiceIcon from '@assets/icons/voice.svg';

const tileData = [
  {
    id: 'isTicket',
    label: 'Ticketing',
    notifyType: notificationTypes.Ticket,
    icon: ticketIcon,
  },
  {
    id: 'isEmail',
    label: 'Email',
    notifyType: notificationTypes.Email,
    icon: emailIcon,
  },
  {
    id: 'isSms',
    label: 'SMS',
    notifyType: notificationTypes.Sms,
    icon: smsIcon,
  },
  {
    id: 'isWebHook',
    label: 'WebHook',
    notifyType: notificationTypes.WebHook,
    icon: webhookIcon,
  },
  {
    id: 'isSlack',
    label: 'Slack',
    notifyType: notificationTypes.Slack,
    icon: slackIcon,
  },
  {
    id: 'isTeams',
    label: 'MS teams',
    notifyType: notificationTypes.Teams,
    icon: teamsIcon,
  },
  {
    id: 'isFtp',
    label: 'FTP',
    notifyType: notificationTypes.Ftp,
    icon: ftpIcon,
  },
  {
    id: 'isVoice',
    label: 'Voice',
    notifyType: notificationTypes.Voice,
    icon: voiceIcon,
  },
];

export const handleDoWChange = setData => (event, values) => {
  const newData = { ...values };
  newData[event.target.name] = event.target.value;
  setData(newData);
};

export default tileData;
