import React from 'react';
import { Grid } from '@material-ui/core';

import FieldWrapper from '@common/form/FieldWrapper';
import { Field } from 'react-final-form';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Input from '@components/Auth/Common/Input';
import { TypeLabelValue } from '@common/propTypes/common';
import PropTypes from 'prop-types';
import useStyles from './styles';

const PhoneField = ({
  countryCodesOptions,
  phoneName,
  phoneCodeName,
  initialIcon,
  label = 'Mobile Phone Number',
  isRequired,
}) => {
  const classes = useStyles();

  return (
    <FieldWrapper
      label={label}
      labelSize={12}
      contentSize={12}
      fullWidth
      classNameLabelInner={classes.alignLeft}
      isRequired={isRequired}
      content={
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Field
              name={phoneCodeName}
              id={phoneCodeName}
              render={AutocompleteFormInput}
              items={countryCodesOptions}
              isIconShow
              classNameWrapper={classes.countryCode}
              size="small"
              initialIcon={initialIcon}
            />
          </Grid>
          <Grid item xs={8}>
            <Field name={phoneName} id={phoneName} render={Input} />
          </Grid>
        </Grid>
      }
    />
  );
};

PhoneField.propTypes = {
  countryCodesOptions: TypeLabelValue,
  phoneName: PropTypes.string.isRequired,
  phoneCodeName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  initialIcon: PropTypes.node,
};

export default PhoneField;
