import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  displayFlex: {
    display: 'flex',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifySpace: {
    justifyContent: 'space-between',
  },
  flexDirColumn: {
    flexDirection: 'column',
  },
}));

export default useStyles;
