import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';
import useAsync from '@services/api/common/useAsync';
import {
  createHook,
  getAtOptions,
} from '@components/WebHooks/components/dialogs/helpers';
import Loading from '@common/Loading/Loading';
import { validateRequired } from '@common/functions/validators';
import LabelValueChipDialog from '@common/ChipDialog/LabelValueChipDialog';
import useStyles from './styles';
import { triggers } from './data';

const AtTicketDialog = ({ open, onClose, onSubmit, url }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [triggerIds, setTriggerIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataLoading = useAsync(getAtOptions, setOptions);

  const submit = values => {
    if (!triggerIds.length) {
      enqueueSnackbar('Select at least one trigger', { variant: 'warning' });
      return;
    }
    const payload = {
      name: values.name,
      crmSourceId: values.crmSourceId,
      url,
      triggers: triggerIds.map(tr => ({
        value: tr,
        label: triggers.find(i => i.value === tr).label,
      })),
    };
    setLoading(true);
    createHook(payload)
      .then(d => onSubmit(d))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const validate = values => {
    return {
      name: validateRequired(values.name),
      crmSourceId: validateRequired(values.crmSourceId),
    };
  };

  const content = (
    <>
      <div className={classes.item}>
        <Field
          id="crmSourceId"
          name="crmSourceId"
          fullWidth
          component={DropDown}
          options={options}
          label="Autotask PSA integration"
          disabled={loading}
        />
      </div>
      <div className={classes.item}>
        <Field
          id="name"
          name="name"
          fullWidth
          component={Input}
          placeholder="Hook name"
          disabled={loading}
        />
      </div>
      <div className={classes.item}>
        <LabelValueChipDialog
          fullArray={triggers}
          values={triggerIds}
          setValues={e => setTriggerIds(e.target.value)}
          label="Trigger fields"
          name="triggers"
          disabled={loading}
        />
      </div>
    </>
  );

  const getContent = () => (dataLoading ? <Loading /> : content);

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <DialogTitle id="simple-dialog-title">
              Generate Autotask hook
            </DialogTitle>
            {getContent()}
            <DialogActions>
              <ReusableButton
                label="Cancel"
                onClick={onClose}
                disabled={loading}
                loading={loading}
              />
              <ReusableButton
                type="submit"
                viewType="black"
                label="Confirm"
                loading={loading}
                disabled={loading}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

AtTicketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default AtTicketDialog;
