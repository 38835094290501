import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import useStyles from '@components/Auth/Common/ToggleButtons/styles';
import useSharedStyles from '@components/CrmTicket/sharedStyles';
import ReusableButton from '@common/Button/Button';

const ShowAllBenefitsDialog = ({
  open,
  onClose,
  scopes,
  planName,
  scopeDescriptions = '',
}) => {
  const classes = useStyles();
  const sharedStyles = useSharedStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${planName} Plan Benefits`}</DialogTitle>
      <DialogContent>
        {scopeDescriptions && <div>{scopeDescriptions}</div>}
        <div className={classes.scopes}>
          {scopes.map(i => (
            <div key={i} className={classes.scope}>
              <div className={classes.dot} />
              {i}
            </div>
          ))}
        </div>

        <div className={sharedStyles.dialogActionsContainer}>
          <ReusableButton label="Close" viewType="blue" onClick={onClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShowAllBenefitsDialog;
