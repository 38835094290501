import { INCIDENT } from '../resources';

export const SelectCurrentIncident = state => state[INCIDENT].current;
export const SelectIncidentPending = state => state[INCIDENT].isPending;
export const SelectIncidentLogs = state => state[INCIDENT].logs;
export const SelectIncidentProcessing = state => state[INCIDENT].isProcessing;
export const SelectIncidentNotes = state => state[INCIDENT].notes;
export const SelectIncidentTickets = state => state[INCIDENT].tickets;
export const SelectIncidentTimeEntry = state => state[INCIDENT].timeEntries;

export default {};
