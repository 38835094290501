import { createSlice } from '@reduxjs/toolkit';
import { OPT_IN } from '../resources';

const optInSlice = createSlice({
  name: OPT_IN,
  initialState: {},
  reducers: {},
});

export default optInSlice.reducer;
