import { useCallback, useState } from 'react';
import useAsync from '@services/api/common/useAsync';

const useLoadingData = ({ apiRequest, initialValue = null, id }) => {
  const [data, setData] = useState(initialValue);
  const getData = useCallback(() => apiRequest(id), [id, apiRequest]);
  const loading = useAsync(getData, setData);
  return { loading, data, setData };
};

export default useLoadingData;
