import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import { Select, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import Switch from '@common/FilterInputs/Switch';

import FormElement from '@common/form/FormElement';
import eventBus from '@services/eventBus';
import { regionSelectionEvents as events } from '@constants/events';
import { selectedViewType } from '@store/selectors/pasers';
import useStyles from './styles';
import {
  occurenceOptions,
  regexOptions as rOptions,
  sourceOptions,
} from './options';

const elementValueKey = 'all';

const infoText =
  '<b>All occurrences</b> - get data from all of the Source<br/>' +
  '<b>Selected element</b> - get data from selected DOM element only<br/>' +
  '<b>First occurrence</b> - get only first occurrence in Source';

const advancedSettingsModel = {
  on: {
    labelText: 'hide',
    style: 'contents',
    visible: true,
    arrow: '&#11165;',
  },
  off: {
    labelText: 'show',
    style: 'none',
    visible: false,
    arrow: '&#11167;',
  },
};

const DataExpression = ({
  onSubmit,
  onCancel,
  externalData,
  selectableMode,
  setSelectableSelMode,
}) => {
  const viewType = useSelector(selectedViewType);

  const data = !externalData
    ? {
        id: 0,
        name: '',
        value: '',
        elementPath: '',
        headerContent: '',
        headerPosition: '',
        regexOptions: [],
        source: sourceOptions[0].value,
        occurrences: occurenceOptions[0].value,
        attachmentFileName: '',
      }
    : externalData;

  const classes = useStyles();

  const [source, setSource] = useState(data.source);
  const [occurrences, setOccurrences] = useState(data.occurrences);

  const [regexOptions, setRegexOptions] = useState(data.regexOptions);

  const [value, setValue] = useState(data.value);
  const [elementPath, setElementPath] = useState(data.elementPath);
  const [headerContent, setHeaderContent] = useState(data.headerContent);
  const [headerPosition, setHeaderPosition] = useState(data.headerPosition);
  const [attachmentFileName, setAttachmentFileName] = useState(
    data.attachmentFileName,
  );
  const [advancedShow, setAdvancedShow] = useState(advancedSettingsModel.off);

  const resetComponent = () => {
    setSource(data.source);
    setOccurrences(data.occurrences);
    setRegexOptions(data.regexOptions);
    setValue(data.value);
    setElementPath(data.elementPath);
    setHeaderContent(data.headerContent);
    setHeaderPosition(data.headerPosition);
    setAttachmentFileName(data.attachmentFileName);
  };

  const toggleAdvancedShow = () =>
    setAdvancedShow(
      advancedShow.visible
        ? advancedSettingsModel.off
        : advancedSettingsModel.on,
    );

  const toggleSelectableMode = useCallback(
    isSet => {
      // deny to select from JSON source
      if (viewType !== 2) setSelectableSelMode(isSet);
    },
    [setSelectableSelMode, viewType],
  );

  const handleChangeRegexOptions = event => {
    const { options } = event.target;
    const optionsList = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        optionsList.push(options[i].value);
      }
    }
    setRegexOptions(optionsList);
  };

  const selectionIncomingHandler = ({ detail }) => {
    setSource(detail.sourceType);
    setElementPath(detail.elementPath);
    setHeaderContent(detail.headerContent);
    setHeaderPosition(detail.headerPosition);
    setAttachmentFileName(
      detail.useFullAttachmentName
        ? detail.attachmentFileName
        : detail.attachmentFileName?.replace(/^.*(?=\.)/g, '*'),
    );
    const newExpression = detail.expression
      ? detail.expression.replace(/\s+/g, '\\s+')
      : '';
    setValue(newExpression);

    const selectedElement = occurenceOptions.find(
      item => item.value === elementValueKey,
    );

    setOccurrences(selectedElement.value);
    setRegexOptions(detail.regexOptions);
  };

  const handleSwitchToggle = () => {
    toggleSelectableMode(!selectableMode);
    eventBus.dispatch(events.ClearSelection, null);
  };

  useEffect(() => {
    eventBus.on(events.Selection, selectionIncomingHandler);
    toggleSelectableMode(true);
    return () => {
      eventBus.remove(events.Selection, selectionIncomingHandler);
    };
  }, [toggleSelectableMode]);

  const handleSubmit = () => {
    const result = {
      id: data.id,
      value,
      elementPath,
      headerContent,
      headerPosition,
      regexOptions,
      source,
      occurrences,
      attachmentFileName,
    };
    toggleSelectableMode(false);
    eventBus.dispatch(events.ClearSelection, null);
    onSubmit(result);
  };

  const handleCancelButton = () => {
    toggleSelectableMode(false);
    eventBus.dispatch(events.ClearSelection, null);
    onCancel();
  };

  const handleAddNew = () => {
    const result = {
      id: data.id,
      value,
      elementPath,
      headerContent,
      headerPosition,
      regexOptions,
      source,
      occurrences,
      attachmentFileName,
    };
    eventBus.dispatch(events.ClearSelection, null);
    resetComponent();
    onSubmit(result, true);
    toggleSelectableMode(true);
  };

  return (
    <div className={classes.container}>
      <Form onSubmit={() => {}}>
        {() => (
          <>
            <FormElement label="Selection mode">
              <Switch
                checked={selectableMode}
                input={{
                  value: selectableMode,
                  onChange: handleSwitchToggle,
                }}
              />
            </FormElement>
            {/* Source type */}
            <FormElement label="Source" infoText="The part where data get from">
              <DropDown
                size="small"
                options={sourceOptions}
                input={{
                  value: source,
                  onChange: e => setSource(e.target.value),
                }}
              />
            </FormElement>
            {/* Attachment file name */}
            {source === 'Attachment' && (
              <FormElement
                label="File mask"
                infoText="* - any symbols sequence, ? - one character"
              >
                <Field
                  name="attachmentFileName"
                  id="outlined-basic"
                  placeholder="File name like"
                  styleType="main"
                  inputView="text"
                  fullWidth
                  input={{
                    value: attachmentFileName,
                    onChange: e => setAttachmentFileName(e.target.value),
                  }}
                  component={Input}
                />
              </FormElement>
            )}
            {/* Occurrences */}
            <FormElement label="Occurrences" infoText={infoText}>
              <DropDown
                size="small"
                options={occurenceOptions}
                input={{
                  value: occurrences,
                  onChange: e => setOccurrences(e.target.value),
                }}
              />
            </FormElement>
            <span
              className={classes.link}
              onClick={toggleAdvancedShow}
              role="presentation"
            >
              {advancedShow.labelText}
              &nbsp;advanced settings&nbsp;
              <span dangerouslySetInnerHTML={{ __html: advancedShow.arrow }} />
            </span>
            <div style={{ display: advancedShow.style }}>
              {/* Expression */}
              <FormElement label="Expression value">
                <Field
                  name="name"
                  id="outlined-basic"
                  placeholder="Expression Value"
                  styleType="main"
                  inputView="text"
                  fullWidth
                  input={{
                    value,
                    onChange: e => setValue(e.target.value),
                  }}
                  component={Input}
                />
              </FormElement>
              {/* Regex options */}
              <FormElement label="Regex options">
                <FormControl className={classes.formControl}>
                  <Select
                    native
                    multiple
                    value={regexOptions}
                    onChange={handleChangeRegexOptions}
                    classes={{ root: classes.rootSelect }}
                  >
                    {rOptions.map(i => (
                      <option key={i.value} value={i.value}>
                        {i.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </FormElement>
            </div>
            <div
              className={classes.field}
              style={{ justifyContent: 'space-around' }}
            >
              <ReusableButton onClick={handleCancelButton} label="Cancel" />
              <ReusableButton
                viewType="black"
                onClick={handleAddNew}
                label="Add New"
                disabled={!value}
              />
              <ReusableButton
                viewType="black"
                onClick={handleSubmit}
                label="Submit"
                disabled={!value}
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

DataExpression.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  externalData: PropTypes.objectOf(PropTypes.any),
  selectableMode: PropTypes.bool.isRequired,
  setSelectableSelMode: PropTypes.func.isRequired,
};

export default DataExpression;
