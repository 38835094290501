import moment from 'moment';

export const getTimeDiff = (timeFrom, timeTo) => {
  if (
    !/^[0-2]\d:[0-5]\d$/g.test(timeFrom) ||
    !/^[0-2]\d:[0-5]\d$/g.test(timeTo)
  )
    return 0;
  const date1 = moment(Date.parse(`1900-01-01T${timeFrom}:00`));
  const date2 = moment(Date.parse(`1900-01-01T${timeTo}:00`));
  const difDirection =
    date2 > date1 ? date2.diff(date1) : date2.add(1, 'days').diff(date1);
  const dif = moment.duration(difDirection);
  return dif.asMinutes();
};

export default {};
