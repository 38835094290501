import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_INTEGRATIONS } from '@store/slices/resources';
import { getIntegrations } from '@services/requests/securityIntegrations';

export const ThunkGetSecurityIntegrations = createAsyncThunk(
  `${SECURITY_INTEGRATIONS}/getIntegrations`,
  async () => {
    const data = await getIntegrations();
    return data;
  },
);

export default {};
