import { createAsyncThunk } from '@reduxjs/toolkit';
import { addMessagingConfiguration } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkAddMessagingConfiguration = createAsyncThunk(
  `${MESSAGING}/addMessagingConfiguration`,
  async payload => {
    const res = await addMessagingConfiguration(payload);
    return res;
  },
);

export default {};
