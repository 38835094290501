import { getChatBots } from '@services/requests/chatBots';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CHATS_BOTS } from '../../resources';

export const ThunkGetChatBots = createAsyncThunk(
  `${CHATS_BOTS}/getChatBots`,
  () => getChatBots(),
);

export default {};
