/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  ThunkGetAutotaskPodDataByTicketId,
  ThunkGetConnectWisePodDataByContactId,
  ThunkGetConnectwiseTicketInfo,
  ThunkGetHaloPodDataByTicketId,
} from '@store/slices/psaPods/thunks';
import { CONNECTWISE_POD } from '../resources';

const phoneIncludes = (statePhone, inboundPhone) => {
  let statePhoneDigits = statePhone.replace(/\D+/g, '');
  if (statePhoneDigits.charAt(0) === '0') {
    statePhoneDigits = statePhoneDigits.slice(1);
  }
  const inboundPhoneDigits = inboundPhone.replace(/\D+/g, '');
  return inboundPhoneDigits.includes(statePhoneDigits);
};

const initialState = {
  isPodPageOpened: false,
  podData: {},
  contactData: [],
  verificationHistory: [],
  data: {},
};

const podSlice = createSlice({
  name: CONNECTWISE_POD,
  reducers: {
    changeStateOfPodPageFlag: (state, action) => {
      state.isPodPageOpened = action.payload;
    },
    setPodData: (state, actions) => {
      state.podData = actions.payload;
    },
    setVerificationData: (state, action) => {
      state.contactData = action.payload.Data;
      state.verificationHistory = action.payload.History;
      state.data = action.payload;
    },
    changeVerificationData: (state, action) => {
      state.data.history.unshift({
        date: new Date().toISOString(),
        item: action.payload.value,
        type: action.payload.validationType,
        inspector: action.payload.inspector,
        notes: action.payload.note,
      });

      state.data.isVerified = true;

      state.data.communicationItems = state.data.communicationItems.map(i => {
        const itemType = i?.type || i?.Type;
        if (itemType !== action.payload.type) return i;
        const itemValue = i.Value || i.value;
        if (
          action.payload.type === 'email' &&
          itemValue === action.payload.value
        ) {
          return {
            ...i,
            isVerified: true,
          };
        }
        if (
          action.payload.type === 'phone' &&
          phoneIncludes(itemValue, action.payload.value)
        ) {
          return {
            ...i,
            isVerified: true,
          };
        }

        return i;
      });
    },
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(
      ThunkGetConnectwiseTicketInfo.fulfilled,
      (state, action) => {
        state.contactData = action.payload.Data;
        state.verificationHistory = [];
        state.data = action.payload;

        if (action.payload.history?.length > 0) {
          state.verificationHistory = action.payload.history.map(i => ({
            date: i.Date,
            item: i.Item,
            type: i.Type,
            inspector: i.Inspector,
            notes: i.notes,
          }));
        }
      },
    );

    builder.addCase(
      ThunkGetAutotaskPodDataByTicketId.fulfilled,
      (state, action) => {
        state.contactData = action.payload.Data;
        state.verificationHistory = [];
        state.data = action.payload;

        if (action.payload.history?.length > 0) {
          state.verificationHistory = action.payload.history.map(i => ({
            date: i.Date,
            item: i.Item,
            type: i.Type,
            inspector: i.Inspector,
            notes: i.notes,
          }));
        }
      },
    );

    builder.addCase(
      ThunkGetHaloPodDataByTicketId.fulfilled,
      (state, action) => {
        state.contactData = action.payload.Data;
        state.verificationHistory = [];
        state.data = action.payload;

        if (action.payload.history.length > 0) {
          state.verificationHistory = action.payload.history.map(i => ({
            date: i.Date,
            item: i.Item,
            type: i.Type,
            inspector: i.Inspector,
            notes: i.notes,
          }));
        }
      },
    );

    builder.addCase(
      ThunkGetConnectWisePodDataByContactId.fulfilled,
      (state, action) => {
        state.contactData = action.payload.Data;
        state.verificationHistory = [];
        state.data = action.payload;

        if (action.payload.history.length > 0) {
          state.verificationHistory = action.payload.history.map(i => ({
            date: i.Date,
            item: i.Item,
            type: i.Type,
            inspector: i.Inspector,
            notes: i.notes,
          }));
        }
      },
    );
  },
});

export default podSlice.reducer;
export const {
  changeStateOfPodPageFlag,
  setPodData,
  changeVerificationData,
  setVerificationData,
} = podSlice.actions;
