import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAddOptInFormLogo,
  ThunkRemoveOptInLogo,
  ThunkSaveOptInSettings,
} from '@store/slices/optInSettings/thunks';
import { OptInSettingsInit } from '@store/slices/optInSettings';

import FieldWrapper from '@common/form/FieldWrapper';
import FileUploader from '@components/LiveChatConfigurations/components/FileUploader';
import ReusableButton from '@common/Button/Button';

import { getTenantIdFromStorage } from '@services/api';

import Input from '@components/Auth/Common/Input';

const OptInBrandingSettings = () => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const initialValues = useSelector(OptInSettingsInit);

  const submit = ({ bgColor }) => {
    const styles = JSON.stringify({ bgColor });

    setProcessing(true);
    dispatch(ThunkSaveOptInSettings({ ...initialValues, styles }))
      .unwrap()
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Opt-in Page Branding Settings
      </AccordionSummary>

      <AccordionDetails>
        <Form
          onSubmit={submit}
          initialValues={{
            logo: initialValues?.logo ?? null,
            bgColor: JSON.parse(initialValues?.styles)?.bgColor ?? '#000',
          }}
          render={({ handleSubmit }) => (
            <div style={{ width: '100%' }}>
              <FieldWrapper
                label="Logo"
                labelSize={12}
                contentSize={12}
                fullWidth
                isRequired
                content={
                  <FileUploader
                    name="logo"
                    resourceId={getTenantIdFromStorage()}
                    handleUpload={ThunkAddOptInFormLogo}
                    handleRemove={ThunkRemoveOptInLogo}
                    imageWidth="300px"
                  />
                }
              />

              <FieldWrapper
                label="Gradient Background Color"
                labelSize={12}
                contentSize={12}
                fullWidth
                isRequired
                content={
                  <Field
                    name="bgColor"
                    id="bgColor"
                    render={Input}
                    type="color"
                    disabled={processing}
                  />
                }
              />

              <ReusableButton
                onClick={handleSubmit}
                label="Update"
                disabled={processing}
              />
            </div>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OptInBrandingSettings;
