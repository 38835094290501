import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { beautifyPhoneNumbers } from '@services/requests/common';

export const ThunkBeautifyPhoneNumbers = createAsyncThunk(
  `${COMMON}/beautifyPhoneNumbers`,
  async value => beautifyPhoneNumbers(value),
);

export default {};
