import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';

import {
  ThunkGetClientPortalRoles,
  ThunkGetClientPortalUser,
  ThunkUpdateClientPortalUser,
  ThunkGetClientPortalCrmCompanies,
} from '@store/slices/clientPortalAdmin/thunks';

import { CLIENT_PORTAL_USERS } from '@constants/routes';
import { requiredValidator } from '@utils/validators';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';

import useStyles from './styles';

const AdminRole = 3;
const ManagerRole = 2;
const AccountingRole = 1;
const MultipleCompaniesRoles = [AdminRole, ManagerRole, AccountingRole];

const EditClientPortalUser = () => {
  const [loading, setLoading] = useState(true);

  const [userData, setUserData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesIsLoading, setCompaniesIsLoading] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { push } = useHistory();
  const { userId, psaId } = useParams();

  const loadCompanies = useCallback(() => {
    setCompaniesIsLoading(true);
    dispatch(ThunkGetClientPortalCrmCompanies(psaId))
      .unwrap()
      .then(res => {
        setCompanies(res);
      })
      .finally(() => {
        setCompaniesIsLoading(false);
      });
  }, [dispatch, psaId]);

  const submit = values => {
    setLoading(true);
    dispatch(
      ThunkUpdateClientPortalUser({
        ...values,
        companies: values.companies?.map(i => ({ companyId: i })),
      }),
    )
      .unwrap()
      .then(() => {
        push(CLIENT_PORTAL_USERS);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = useCallback(values => {
    return {
      role: requiredValidator(values.role),
      login: requiredValidator(values.login),
      companies: MultipleCompaniesRoles.includes(values.roleId)
        ? requiredValidator(values.companies)
        : undefined,
    };
  }, []);

  const onMount = useCallback(async () => {
    setLoading(true);
    if (userId) {
      Promise.all([
        dispatch(ThunkGetClientPortalUser(userId)).unwrap(),
        dispatch(ThunkGetClientPortalRoles()).unwrap(),
      ])
        .then(res => {
          setUserData(res[0]);
          setRoles(res[1]);
          loadCompanies();
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  }, [dispatch, loadCompanies, userId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      initialValues={{
        ...userData,
        companies: userData?.companies?.map(i => i.companyId),
      }}
      validate={validate}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <FieldWrapper
            label="Login:"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={<Field name="login" id="login" render={Input} />}
          />

          <FieldWrapper
            label="Role:"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                name="roleId"
                id="roleId"
                render={DropDown}
                options={roles}
              />
            }
          />

          {MultipleCompaniesRoles.includes(values.roleId) && (
            <FieldWrapper
              label="Companies:"
              labelSize={12}
              contentSize={12}
              fullWidth
              loading={companiesIsLoading}
              content={
                <InputWithAutocomplete
                  name="companies"
                  options={companies}
                  disabled={loading}
                  disableCloseOnSelect
                  limitTags={5}
                  getOptionValue={i => i.value}
                  getOptionLabel={i => i.label}
                  getOptionSelected={(options, value) =>
                    options.value === value.value
                  }
                  popupIcon={undefined}
                  closeIcon={undefined}
                />
              }
            />
          )}

          <div>
            <ReusableButton label="Update" type="submit" />
          </div>
        </form>
      )}
    />
  );
};

export default EditClientPortalUser;
