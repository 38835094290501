import { createAsyncThunk } from '@reduxjs/toolkit';
import { hetHaloUsers } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetHaloUsers = createAsyncThunk(
  `${INTEGRATION}/halo/getusers`,
  async ({ crmId, clientId, siteId }) => {
    const res = await hetHaloUsers(crmId, clientId, siteId);
    return res;
  },
);

export default {};
