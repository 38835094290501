import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFIED_PSA_TECH_BY_SMS } from '@store/slices/resources';
import { assignTicketToTechVerification } from '@services/requests/psaTechVerification';

export const ThunkAssignTicketToTechVerification = createAsyncThunk(
  `${VERIFIED_PSA_TECH_BY_SMS}/assignTicketToTechVerification`,
  async payload => assignTicketToTechVerification(payload),
);

export default {};
