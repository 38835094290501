const sysInfoKey = 'addSysInfo=true';

const appendSysInfoKey = (url, key) => {
  let qmark = '';
  if (!url.includes('?')) {
    qmark = '?';
  }
  return `${url}${qmark}${key}`;
};

const removeSysInfoKey = (url, key) => {
  let newStr = url.replace(key, '');
  if (newStr.endsWith('?')) {
    newStr = newStr.substring(0, newStr.length - 1);
  }
  return newStr;
};

const toggleSysInfoKey = (linksObject, useParam) => {
  const newObj = { ...linksObject };
  if (useParam) {
    newObj.csv.value = appendSysInfoKey(newObj.csv.value, sysInfoKey);
    newObj.json.value = appendSysInfoKey(newObj.json.value, sysInfoKey);
    newObj.xlsx.value = appendSysInfoKey(newObj.xlsx.value, sysInfoKey);
  } else {
    newObj.csv.value = removeSysInfoKey(newObj.csv.value, sysInfoKey);
    newObj.json.value = removeSysInfoKey(newObj.json.value, sysInfoKey);
    newObj.xlsx.value = removeSysInfoKey(newObj.xlsx.value, sysInfoKey);
  }
  return newObj;
};

export default toggleSysInfoKey;
