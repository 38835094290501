import { makeRequest } from '../makeRequest';
import {
  CONNECTWISE_POD,
  AUTOTASK_POD,
  VERIFICATION_SETTINGS,
  PSA_CONTACTS,
  PSA_CONTACT_VALIDATION,
  HALO_POD,
} from '../requestResources';

export const getConnectwiseTicketInfo = (crmId, ticketId) =>
  makeRequest('GET', `${CONNECTWISE_POD}/${crmId}/ticket/${ticketId}`);

export const getConnectwisePodLink = crmId =>
  makeRequest('GET', `${CONNECTWISE_POD}/getPodLink?crmId=${crmId}`);

export const getConnectwiseNewPodLink = crmId =>
  makeRequest('GET', `${CONNECTWISE_POD}/getNewPodLink?crmId=${crmId}`);

export const getHaloPodLink = crmId =>
  makeRequest('GET', `${HALO_POD}/getPodLink?crmId=${crmId}`);
export const getHaloNewPodLink = crmId =>
  makeRequest('GET', `${HALO_POD}/getNewPodLink?crmId=${crmId}`);

export const sendCheckCode = payload =>
  makeRequest('POST', `${PSA_CONTACT_VALIDATION}/send`, payload);

export const sendDuoPush = payload =>
  makeRequest('POST', `${PSA_CONTACT_VALIDATION}/sendDuoPush`, payload);

export const sendMicrosoftPush = payload =>
  makeRequest('POST', `${PSA_CONTACT_VALIDATION}/sendMicrosoftPush`, payload);

export const checkCodeRequest = payload =>
  makeRequest('POST', `${PSA_CONTACT_VALIDATION}/check`, payload);

export const getValidationConfigRequest = crmId =>
  makeRequest(
    'GET',
    `${VERIFICATION_SETTINGS}/getValidationScreenConfig/${crmId}`,
  );

export const saveToContact = (route, payload) =>
  makeRequest('POST', `/${route}Pod/saveToContact`, payload);

export const autotaskSendCode = payload =>
  makeRequest('POST', `${AUTOTASK_POD}/send`, payload);

export const autotaskCheckCodeRequest = payload =>
  makeRequest('POST', `${AUTOTASK_POD}/check`, payload);

export const getConnectwisePodDataByContactId = (crmId, contactId) =>
  makeRequest('GET', `${CONNECTWISE_POD}/${crmId}/contact/${contactId}`);

export const getCWContactData = (crmId, contactId) =>
  makeRequest(
    'GET',
    `/${PSA_CONTACTS}/connectwise/${crmId}/contacts/${contactId}`,
  );

export const getATContactData = (crmId, contactId) =>
  makeRequest(
    'GET',
    `/${PSA_CONTACTS}/autotask/${crmId}/contacts/${contactId}`,
  );

export const getKaseyaContactData = (crmId, contactId) =>
  makeRequest('GET', `/${PSA_CONTACTS}/kaseya/${crmId}/contacts/${contactId}`);

export const getContactData = (crmId, contactId) =>
  makeRequest('GET', `/${PSA_CONTACTS}/${crmId}/contacts/${contactId}`);

export const getHaloContactData = (crmId, contactId) =>
  makeRequest('GET', `/${PSA_CONTACTS}/halo/${crmId}/contacts/${contactId}`);

export const getZendeskContactData = (crmId, contactId) =>
  makeRequest('GET', `/${PSA_CONTACTS}/zendesk/${crmId}/contacts/${contactId}`);

export const getSuperOpsContactData = (crmId, contactId) =>
  makeRequest(
    'GET',
    `/${PSA_CONTACTS}/superOps/${crmId}/contacts/${contactId}`,
  );

export const getSyncroContactData = (crmId, contactId) =>
  makeRequest('GET', `/${PSA_CONTACTS}/syncro/${crmId}/contacts/${contactId}`);

export const getCurrentContactData = (crmId, contactId, psaType) =>
  makeRequest(
    'GET',
    `/${PSA_CONTACTS}/${psaType}/${crmId}/contacts/${contactId}`,
  );

export const getHaloPodDataByTicketId = (crmId, ticketId) =>
  makeRequest('GET', `${HALO_POD}/${crmId}/ticket/${ticketId}`);

export const getAutotaskPodDataByTicketId = (crmId, ticketId) =>
  makeRequest('GET', `${AUTOTASK_POD}/${crmId}/ticket/${ticketId}`);
export default {};
