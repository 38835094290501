import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  TextInput,
} from 'react-admin';
import useStyles from './styles';

const inputs = {
  text: TextInput,
  date: DateInput,
  autocomplete: AutocompleteInput,
  number: NumberInput,
};

const Input = ({ styleType, classNameWrapper, inputView, ...rest }) => {
  const classes = useStyles();

  const inputClasses = {
    main: classes.mainInput,
    filter: classes.filterInput,
  };

  const InputComponent = inputs[inputView];

  return (
    <InputComponent
      autoComplete="off"
      label=""
      className={cx(classes.input, inputClasses[styleType], classNameWrapper)}
      {...rest}
    />
  );
};

Input.propTypes = {
  styleType: PropTypes.oneOf(['main', 'filter']),
  classNameWrapper: PropTypes.string,
  inputView: PropTypes.oneOf(['text', 'number', 'autocomplete', 'date']),
};

export default Input;
