import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { pushMessagesToTicket } from '@services/requests/clientPortal';

export const ThunkPushMessagesToNote = createAsyncThunk(
  `${CLIENT_PORTALS}/pushMessagesToTicket`,
  ({ clientChatId }) => pushMessagesToTicket(clientChatId),
);

export default {};
