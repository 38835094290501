/*
  This is an util for creation actionTypes object based on the list of keys

  @example
  createActionTypes(['ACTION, SOME_ANOTHER_ACTION'])

  {
    ACTION: 'ACTION',
    SOME_ANOTHER_ACTION: 'SOME_ANOTHER_ACTION'
  }
*/

const createActionTypes = list =>
  list.reduce((prev, type) => ({ ...prev, [type]: type }), {});

export default createActionTypes;
