import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import CheckBox from '@common/Checkbox/Checkbox';
import EmailSelector from '@components/DataSources/AlertForm/components/EmailSelector';
import AlertFilters from '@components/DataSources/AlertForm/AlertList/components/AlertFilters';
import ChipDialog from '@common/ChipDialog/ChipDialog';
import Checkbox from '@common/Checkbox/FormControlCheckBox';
import ReusableButton from '@common/Button/Button';
import InfoBox from '@common/InfoBox/InfoBox';

import useStyles from './alertItemStyles';

const formToModel = (form, filter) => {
  const model = {
    type: form.type,
    enabled: form.enabled,
    alertDestinations: {
      emails: form.emails,
      snmp: form.snmp,
      allAdmins: form.allAdmins,
      httpServerIds: form.selectedHooks,
    },
  };
  if (filter) {
    filter.formToModel(form, model);
  }
  return model;
};

const modelToForm = (model, type, filter) => {
  const formModel = {
    snmp: model ? model.alertDestinations.snmp : false,
    allAdmins: model ? model.alertDestinations.allAdmins : false,
    enabled: model ? model.enabled : false,
    emails: model ? model.alertDestinations.emails : [],
    selectedHooks: model ? model.alertDestinations.httpServerIds : [],
    type,
  };
  if (filter) {
    filter.modelToForm(model, formModel);
  }
  return formModel;
};

const AlertItem = ({ item, hooks, data, onUpdate, info, filter }) => {
  const classes = useStyles();
  const initData = modelToForm(data, item.type, filter);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(data && data.enabled);
  }, [data]);

  const onSubmit = values => {
    setOpen(false);
    onUpdate(formToModel(values, filter));
  };

  return (
    <div className={classes.itemContainer}>
      <CheckBox checked={!!checked} readOnly />
      <span
        role="presentation"
        className={classes.itemName}
        onClick={() => setOpen(true)}
      >
        {item.name}
        {info && <InfoBox text={info} />}
      </span>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={classes.modalBox}>
          <Paper className={classes.paperRoot}>
            <Form
              onSubmit={onSubmit}
              initialValues={initData}
              render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Field
                    id="enabled"
                    name="enabled"
                    label="Enable alert"
                    type="checkbox"
                    component={Checkbox}
                  />
                  <div>
                    <span>Alert emails</span>
                  </div>
                  <EmailSelector
                    data={form.getState().values.emails}
                    setData={values => form.change('emails', values)}
                  />
                  <div className={classes.marginTop}>
                    <span>Alert Hooks</span>
                    <ChipDialog
                      name="Chip"
                      values={form.getState().values.selectedHooks}
                      fullArray={hooks.map(i => i.name)}
                      setValues={e =>
                        form.change('selectedHooks', e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Field
                      id="snmp"
                      name="snmp"
                      label="Use SNMP"
                      type="checkbox"
                      component={Checkbox}
                    />
                    <Field
                      id="allAdmins"
                      name="allAdmins"
                      label="Alert all network admins"
                      type="checkbox"
                      component={Checkbox}
                    />
                  </div>
                  {filter && (
                    <Paper className={classes.filters}>
                      <span>
                        <b>Filters</b>
                      </span>
                      <AlertFilters
                        alertType={item.type}
                        filterText={item.filterText}
                        form={form}
                      />
                    </Paper>
                  )}
                  <div className={classes.center}>
                    <ReusableButton
                      size="md"
                      type="button"
                      label="Cancel"
                      onClick={() => setOpen(false)}
                      classNameWrapper={classes.button}
                    />
                    <ReusableButton
                      size="md"
                      viewType="black"
                      type="submit"
                      label="Submit"
                      classNameWrapper={classes.button}
                    />
                  </div>
                </form>
              )}
            />
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

AlertItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  data: PropTypes.objectOf(PropTypes.any),
  onUpdate: PropTypes.func,
  info: PropTypes.string,
  filter: PropTypes.objectOf(PropTypes.any),
};

export default AlertItem;
