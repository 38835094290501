import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '1.5rem',
  },
  fullscreen: {
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
  },
  wrapper: {
    height: '90vh',
    overflowY: 'auto',
  },
}));

export default useStyles;
