import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getNewChatTeamsMessageVariables } from '@services/requests/clientPortal';

export const ThunkGetNewChatTeamsMessageVariables = createAsyncThunk(
  `${CLIENT_PORTALS}/getNewChatTeamsMessageVariables`,
  () => getNewChatTeamsMessageVariables(),
);

export default {};
