import makeStyles from '@material-ui/core/es/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
