import React from 'react';
import { SwapVert } from '@material-ui/icons';
import PropTypes from 'prop-types';
import cx from 'classnames';

import QuickSearchToolbar from '@ui/components/common/MuiVirtualizedTable/components/QuickSearchToolbar';
import useStyles from '../styles';

const CrmHeader = ({ crmList, search, setSearch, reverseOrder, crmLength }) => {
  const classes = useStyles({ count: crmLength });
  return (
    <div className={classes.row}>
      <div className={cx(classes.rowCell, classes.inputStyles)}>
        <div className={classes.searchContainer}>
          <QuickSearchToolbar
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            clearSearch={() => {
              setSearch('');
            }}
            placeholder="Local"
          />
          <button
            onClick={() => {
              reverseOrder();
            }}
            type="button"
            className={classes.reverserButton}
          >
            <SwapVert />
          </button>
        </div>
      </div>
      {crmList.map(i => (
        <div key={i.value} className={classes.rowCell}>
          <div>{i.label}</div>
        </div>
      ))}
    </div>
  );
};

CrmHeader.propTypes = {
  crmList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  search: PropTypes.string,
  setSearch: PropTypes.func,
  reverseOrder: PropTypes.func,
  crmLength: PropTypes.number,
};

export default CrmHeader;
