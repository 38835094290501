import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateConnectWiseTicket } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkUpdateConnectWiseTicket = createAsyncThunk(
  `${CRM_TICKET}/updateConnectWiseTicket`,
  async ({ crmId, payload }) => {
    const ticket = await updateConnectWiseTicket(crmId, payload);
    return ticket;
  },
);

export default {};
