import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import {
  ThunkGetGroupOptInData,
  ThunkGroupOptIn,
} from '@store/slices/optIn/thunks';
import { ThunkBeautifyPhoneNumbers } from '@store/slices/common/thunks';

import Checkbox from '@common/Checkbox/FormControlCheckBox';
import ReusableButton from '@ui/components/common/Button/Button';
import GroupOptInDialogItem from '../components/GroupOptInDialogItem';
import GroupOptInDialogResult from '../components/GroupOptInDialogResult';

import useSharedStyles from '../../CrmTicket/sharedStyles';
import useStyles from '../styles';

import { useTableContext } from '../../../../hooks/table';

const GroupOptInDialog = ({ onClose, psaId }) => {
  const [list, setList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [isResultSuccessfully, setIsResultSuccessfully] = useState(false);
  const [requestResult, setRequestResult] = useState([]);
  const [phoneChecked, setPhoneChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(true);

  const sharedStyles = useSharedStyles();
  const classes = useStyles();

  const { selectedIds } = useTableContext();

  const dispatch = useDispatch();

  const toggleListSendSmsListState = state =>
    setList(list.map(i => (i.phone ? { ...i, sendSms: state } : i)));

  const toggleListSendEmailListState = state =>
    setList(list.map(i => (i.email ? { ...i, sendEmail: state } : i)));

  const handleGroupOpIn = useCallback(async () => {
    setProcessing(true);
    const sendSmsContacts = list.filter(i => i.sendSms);
    const sendOnlyEmailContacts = list.filter(i => i.sendEmail && !i.sendSms);
    let phoneNumbers = [];

    await dispatch(
      ThunkBeautifyPhoneNumbers(
        sendSmsContacts.filter(i => i.phone).map(x => x.phone),
      ),
    )
      .unwrap()
      .then(res => {
        phoneNumbers = res.data;
      });

    const items = sendSmsContacts.map((i, idx) => ({
      ...i,
      phone: phoneNumbers[idx],
    }));

    const payload = {
      items: [...items, ...sendOnlyEmailContacts],
      crmId: psaId,
    };

    await dispatch(
      ThunkGroupOptIn({
        payload,
      }),
    )
      .unwrap()
      .then(res => {
        setIsResultSuccessfully(true);
        setRequestResult(res);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, list, psaId]);

  const changeSmsOption = id =>
    setList(
      list.map(i => (i.contactId === id ? { ...i, sendSms: !i.sendSms } : i)),
    );

  const changeEmailOption = id =>
    setList(
      list.map(i =>
        i.contactId === id ? { ...i, sendEmail: !i.sendEmail } : i,
      ),
    );

  const onMount = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkGetGroupOptInData({ crmId: psaId, ids: selectedIds }))
      .unwrap()
      .then(res => {
        setList(
          res.map(({ mobilePhone, ...rest }) => ({
            ...rest,
            phone: mobilePhone,
            sendEmail: true,
          })),
        );
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
      });
  }, [dispatch, psaId, selectedIds]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open
      onClose={() => {
        onClose(false);
      }}
    >
      <DialogTitle>Opt-in</DialogTitle>
      <DialogContent>
        {isResultSuccessfully && !processing ? (
          <GroupOptInDialogResult {...requestResult} />
        ) : (
          <div className={classes.optInDialogItemsList}>
            <div
              className={cx(
                classes.optInDialogItem,
                classes.optDialogItemsListHeader,
              )}
            >
              <div className={classes.flexBox}>Contact Name</div>

              <div className={classes.flexBox}>
                <Checkbox
                  checked={phoneChecked}
                  onChange={() => {
                    setPhoneChecked(!phoneChecked);
                    toggleListSendSmsListState(!phoneChecked);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Phone
              </div>

              <div className={classes.flexBox}>
                <Checkbox
                  checked={emailChecked}
                  onChange={() => {
                    setEmailChecked(!emailChecked);
                    toggleListSendEmailListState(!emailChecked);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Email
              </div>
            </div>
            {list.map(record => (
              <GroupOptInDialogItem
                key={record.contactId}
                {...record}
                toggleSendSms={changeSmsOption}
                toggleSendEmail={changeEmailOption}
              />
            ))}
          </div>
        )}

        <div className={sharedStyles.dialogActionsContainer}>
          <ReusableButton
            label="Close"
            onClick={() => {
              onClose(false);
            }}
            disabled={processing}
          />
          {!isResultSuccessfully && (
            <ReusableButton
              label="Submit"
              onClick={() => {
                handleGroupOpIn();
              }}
              disabled={processing}
              loading={processing}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

GroupOptInDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  psaId: PropTypes.string,
  psaName: PropTypes.string,
};

export default GroupOptInDialog;
