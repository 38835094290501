import { createContext } from "react";


const tableContext = createContext<{} | undefined>(undefined);

export const TableContextProvider = ({ children, gridWidth }) =>{

  return <>
    <tableContext.Provider value={{width: gridWidth}}>
      {children}
    </tableContext.Provider>
  </>
}

export default tableContext
