import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #ededed',
    margin: '5px',
    borderRadius: '3px',
    boxShadow: '3px 3px 4px -1px rgb(34 60 80 / 20%)',
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  formControl: {
    width: '140px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  div: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  iconDelete: {
    margin: '-3px',
  },
  badge: {
    color: 'white',
    width: '5rem',
    display: 'flex',
    padding: '0.3125rem 0',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    borderRadius: '0.125rem',
    textTransform: 'uppercase',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.light,
  },
  aggregate: {
    margin: '0 10px 0 10px',
  },
  aggrControls: {
    display: 'flex',
    alignItems: 'center',
  },
  draggableIcon: {
    marginLeft: 'auto',
  },
}));

export default useStyles;
