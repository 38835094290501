import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
  ThunkGetCrmCompaniesByDefaultId,
  ThunkGetCrmDefaultsAutocomplete,
} from '@store/slices/crm/thunks';
import { ThunkGetCrmUsersByDefaultsId } from '@store/slices/crm/thunks/getCrmUsersByDefaultsId';
import { ThunkCreateTriageTicket } from '@store/slices/triage/thunks';
import { MessagingTemplatesSelector } from '@store/slices/messaging';
import {
  ThunkGetMessagingConfigurationByCrmType,
  ThunkGetMessagingTemplates,
} from '@store/slices/messaging/thunks';

import crmSources from '@constants/crmSources';
import { availableCrmOptions } from '@components/Messaging/helpers';
import { requiredValidator } from '@utils/validators';
import { MESSAGING_CHANNEL, TRIAGE_TICKETS } from '@constants/routes';
import phoneValidation from '@constants/phoneValidation';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import DropDown from '@ui/components/Auth/Common/DropDown';
import ReusableButton from '@ui/components/common/Button/Button';
import Input from '@ui/components/Auth/Common/Input';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import FormSwitch from '@ui/components/Auth/Common/FormSwitch';
import { ThunkGetConnectWiseTicketAgreements } from '@store/slices/connectWiseTicket/thunks';
import EditUserInfoDialog from './dialogs/EditUserInfoDialog';

import useStyles from './styles';
import useSharedStyles from '../../CrmTicket/sharedStyles';

// * Form Relation
// * crmId -> crmDefaultId
// * crmDefaultId -> companyId
// * companyId -> contactId

const CreateTriageTicket = () => {
  const [processing, setProcessing] = useState(false);
  const [openEditUserInfoDialog, setOpenEditUserInfoDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const [crmDefaults, setCrmDefaults] = useState([]); // * holds ALL crm defaults
  const [filteredCrmDefault, setFilteredCrmDefaults] = useState([]); // * hold CRM SPECIFIC defaults
  const [configurations, setConfigurations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [agreements, setAgreements] = useState([]);

  const crmOptions = Object.values(crmSources)
    .filter(crm => _.includes(availableCrmOptions, crm.idx))
    .map(i => ({
      label: i.label,
      value: i.idx,
    }));

  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const triageTemplates = useSelector(MessagingTemplatesSelector);

  const handleCrmChange = useCallback(
    async (crmId, formChange) => {
      setProcessing(true);
      // * using crmId to get FILTERED defaults list
      // * crmDefault is preloaded in onMount function
      const filteredDefaultsList = crmDefaults.filter(
        option => option.info === crmId,
      );
      setFilteredCrmDefaults(filteredDefaultsList);

      // * IF filtered list contain only one options auto set as crmDefaultId value
      if (filteredDefaultsList.length === 1) {
        formChange('crmDefaultId', filteredDefaultsList[0].value);
        formChange('crmCompany', undefined);
      } else {
        formChange('crmDefaultId', undefined);
      }

      // * getting Configuration for Messaging Channel Creation
      // * pre-select IF only one configuration available
      dispatch(ThunkGetMessagingConfigurationByCrmType(crmId))
        .unwrap()
        .then(res => {
          setConfigurations(res);
          if (res.length === 1) {
            formChange('messagingConfigurationId', res[0].value);
          }
        });

      setProcessing(false);
    },
    [crmDefaults, crmOptions, dispatch],
  );

  const handleCrmDefaultChange = useCallback(
    async (crmDefaultId, formChange) => {
      setProcessing(true);
      if (crmDefaultId) {
        await dispatch(ThunkGetCrmCompaniesByDefaultId({ crmDefaultId }))
          .unwrap()
          .then(data => {
            setCompanies(data);
          });
      } else {
        // * form cleaning
        formChange('companyId', undefined);
      }
      setProcessing(false);
    },
    [dispatch],
  );

  const handleCompanyChange = useCallback(
    async (companyId, crmDefaultId, formChange, crmId) => {
      setProcessing(true);

      if (crmId === crmSources.ConnectWise.idx && !!companyId) {
        dispatch(
          ThunkGetConnectWiseTicketAgreements({
            crmId: crmDefaultId,
            companyId,
          }),
        )
          .unwrap()
          .then(data => setAgreements(data));
      }

      if (companyId) {
        await dispatch(
          ThunkGetCrmUsersByDefaultsId({ crmDefaultId, companyId }),
        )
          .unwrap()
          .then(data => {
            setContacts(
              data.map(({ id, name, ...rest }) => ({
                label: name,
                value: id,
                ...rest,
              })),
            );
            setSelectedUser(undefined);
            formChange('userId', undefined);
            formChange('agreementId', undefined);
          });
      } else {
        // * form cleaning
        formChange('userId', undefined);
        formChange('agreementId', undefined);
      }
      setProcessing(false);
    },
    [dispatch],
  );

  const handleTemplateChange = useCallback(
    (templateId, formChange) => {
      setProcessing(true);
      const { subject, template } = triageTemplates.filter(
        triageTemplate => triageTemplate.id === templateId,
      )[0];
      formChange('subject', subject);
      formChange('body', template);
      setProcessing(false);
    },
    [triageTemplates],
  );

  const handleCrmUserChange = useCallback(
    (userId, formChange) => {
      if (userId) {
        // * Getting selected user by UserId from CrmUsers list
        const selectedContact = contacts.find(user => user?.value === userId);

        formChange('userPhone', selectedContact?.phone);
        setSelectedUser(selectedContact);
      } else {
        setSelectedUser(undefined);
      }
    },
    [contacts],
  );

  const handleEditPhoneClick = useCallback(
    userId => {
      const selectedContact = contacts.find(user => user?.value === userId);

      setSelectedUser(selectedContact);
      setOpenEditUserInfoDialog(true);
    },
    [contacts],
  );

  const handleUserInfoChange = useCallback(
    res => {
      const user = { ...selectedUser, phone: res.phone };
      setContacts(
        contacts.filter(contact =>
          contact.value === selectedUser.value ? user : contact,
        ),
      );
      setSelectedUser(user);
    },
    [selectedUser, contacts],
  );

  const submit = useCallback(
    async ({
      crmDefaultId,
      subject,
      body,
      userId,
      crmCompany,
      crmId,
      ...rest
    }) => {
      setProcessing(true);
      const payload = {
        crmDefaultId,
        subject,
        body,
        userId,
        userName: contacts.filter(user => user.value === userId)[0]?.label,
        companyName: companies.filter(
          company => company.value === crmCompany,
        )[0]?.label,
        crmType: crmId,
        ...rest,
      };
      await dispatch(ThunkCreateTriageTicket(payload))
        .unwrap()
        .then(res => {
          if (rest.createChannel) {
            window.open(
              `#${MESSAGING_CHANNEL.replace(
                ':configurationId',
                res.messagingConfigurationId,
              ).replace(':channelId', res.messagingChannelId)}`,
              '_blank',
            );
          }
          push(TRIAGE_TICKETS);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [contacts, dispatch, push, companies],
  );

  const validate = values => {
    return {
      crmDefaultId: requiredValidator(values.crmDefaultId),
      subject: requiredValidator(values.subject),
      body: requiredValidator(values.body),
      crmId: requiredValidator(values.crmId, true),
      messagingConfigurationId: values.createChannel
        ? requiredValidator(values.messagingConfigurationId)
        : undefined,
      userPhone: values.createChannel
        ? phoneValidation(values.userPhone)
        : undefined,
    };
  };

  const onMount = useCallback(async () => {
    // * getting Messaging Templates
    dispatch(ThunkGetMessagingTemplates());

    // * getting ALL crm defaults
    await dispatch(ThunkGetCrmDefaultsAutocomplete())
      .unwrap()
      .then(data => {
        setCrmDefaults(data);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.twoRows}>
            <FieldWrapper
              label="PSA"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              classNameLabelInner={classes.alignLeft}
              content={
                <Field
                  name="crmId"
                  id="crmId"
                  render={DropDown}
                  options={crmOptions}
                  disabled={processing}
                />
              }
            />

            <OnChange name="crmId">
              {value => handleCrmChange(value, form.change)}
            </OnChange>

            <FieldWrapper
              label="PSA Default"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              classNameLabelInner={classes.alignLeft}
              content={
                <Field
                  name="crmDefaultId"
                  id="crmDefaultId"
                  render={DropDown}
                  options={filteredCrmDefault}
                  disabled={(!values.crmId && values.crmId !== 0) || processing}
                />
              }
            />

            <OnChange name="crmDefaultId">
              {value => handleCrmDefaultChange(value, form.change)}
            </OnChange>
          </div>

          <div className={classes.twoRows}>
            <FieldWrapper
              label="PSA Company"
              labelSize={12}
              contentSize={12}
              showLabel
              classNameLabelInner={classes.alignLeft}
              content={
                <Field
                  name="crmCompany"
                  id="crmCompany"
                  render={AutocompleteFormInput}
                  items={companies}
                  loading={processing || !values.crmDefaultId}
                />
              }
            />
            <OnChange name="crmCompany">
              {companyId => {
                handleCompanyChange(
                  companyId,
                  values.crmDefaultId,
                  form.change,
                  values.crmId,
                );
              }}
            </OnChange>
            <FieldWrapper
              label="PSA Contact"
              labelSize={12}
              contentSize={12}
              showLabel
              classNameLabelInner={classes.alignLeft}
              content={
                <Field
                  name="userId"
                  id="userId"
                  render={AutocompleteFormInput}
                  items={contacts}
                  loading={processing || !values.crmCompany}
                />
              }
            />
            <OnChange name="userId">
              {value => {
                handleCrmUserChange(value, form.change);
              }}
            </OnChange>

            {values.crmId === crmSources.ConnectWise.idx &&
              !!values.crmCompany && (
                <FieldWrapper
                  label="Agreement"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="agreementId"
                      id="agreementId"
                      render={AutocompleteFormInput}
                      items={agreements}
                      loading={processing}
                    />
                  }
                />
              )}
          </div>

          {values.userId && (
            <div className={classes.dialogUserInfoContainer}>
              <div>
                <b>Phone:</b>
                {selectedUser?.phone}
                <span>
                  <EditIcon
                    onClick={() => {
                      handleEditPhoneClick(values.userId);
                    }}
                  />
                </span>
              </div>
            </div>
          )}

          <FieldWrapper
            label="Templates"
            labelSize={12}
            contentSize={12}
            showLabel
            classNameLabelInner={classes.alignLeft}
            content={
              <Field
                name="template"
                id="template"
                render={DropDown}
                options={triageTemplates.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                disabled={processing}
              />
            }
          />

          <OnChange name="template">
            {value => {
              handleTemplateChange(value, form.change);
            }}
          </OnChange>

          <FieldWrapper
            label="Subject"
            labelSize={12}
            contentSize={12}
            showLabel
            classNameLabelInner={classes.alignLeft}
            isRequired
            content={
              <Field
                name="subject"
                id="subject"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Body"
            labelSize={12}
            contentSize={12}
            showLabel
            classNameLabelInner={classes.alignLeft}
            isRequired
            content={
              <Field
                name="body"
                id="body"
                render={Input}
                disabled={processing}
                rows={4}
                multiline
              />
            }
          />

          <FieldWrapper
            label="Create Messaging Channel"
            labelSize={12}
            contentSize={12}
            showLabel
            classNameLabelInner={classes.alignLeft}
            content={
              <Field
                name="createChannel"
                id="createChannel"
                render={FormSwitch}
                disabled={(!values.crmId && values.crmId !== 0) || processing}
              />
            }
          />
          {values.createChannel && (
            <>
              <FieldWrapper
                label="Messaging Configuration"
                labelSize={12}
                contentSize={12}
                showLabel
                classNameLabelInner={classes.alignLeft}
                isRequired
                content={
                  <Field
                    name="messagingConfigurationId"
                    id="messagingConfigurationId"
                    render={DropDown}
                    options={configurations}
                    disabled={processing}
                    rows={4}
                    multiline
                  />
                }
              />

              <FieldWrapper
                label="Phone Number"
                labelSize={12}
                contentSize={12}
                showLabel
                classNameLabelInner={classes.alignLeft}
                isRequired
                content={
                  <Field
                    name="userPhone"
                    id="userPhone"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
            </>
          )}

          <div className={sharedClasses.dialogActionsContainer}>
            <ReusableButton
              label="Cancel"
              onClick={() => {
                push(TRIAGE_TICKETS);
              }}
              disabled={processing}
            />

            <ReusableButton
              label="Submit"
              type="submit"
              disabled={processing}
            />
          </div>

          {openEditUserInfoDialog && (
            <EditUserInfoDialog
              open={openEditUserInfoDialog}
              setOpen={setOpenEditUserInfoDialog}
              userInfo={selectedUser}
              crmDefaultId={values.crmDefaultId}
              crmCompanyName={
                companies.find(company => company.value === values.crmCompany)
                  ?.label
              }
              handleUserInfoChange={handleUserInfoChange}
            />
          )}
        </form>
      )}
    />
  );
};

export default CreateTriageTicket;
