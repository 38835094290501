import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { closeConversation } from '@services/requests/liveChats';

export const ThunkCloseConversation = createAsyncThunk(
  `${LIVE_CHATS}/closeConversation`,
  ({ liveChatId, payload = {} }) => closeConversation(liveChatId, payload),
);

export default {};
