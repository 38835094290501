import { createAsyncThunk } from '@reduxjs/toolkit';
import { addLiveChatConversationTemplate } from '@services/requests/messaging';
import { LIVE_CHAT_CONVERSATIONS } from '@store/slices/resources';

export const ThunkAddLiveChatConversationTemplate = createAsyncThunk(
  `${LIVE_CHAT_CONVERSATIONS}/addLiveChatConversationTemplate`,
  async payload => {
    const res = await addLiveChatConversationTemplate(payload);
    return res;
  },
);

export default {};
