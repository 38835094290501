import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  chatContainer: {
    position: 'absolute',
    width: '70%',
    height: '93vh',
    overflow: 'hidden',
    right: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatBody: {
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
  },
  chatMessages: {
    maxWidth: '100%',
    height: '88%',
    overflowY: 'auto',
    overflowX: 'clip',
  },
  chatMessage: {
    width: '100%',
    margin: '10px 0px',
    display: ' flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  chatMessageText: {
    maxWidth: '60%',
    width: 'fit-content',
    padding: '10px',
    background: '#848484',
    color: 'white',
    borderRadius: '15px',
    margin: '3px 10px',
  },
  chatMessageAlignRight: {
    alignItems: 'flex-end !important',
    '& > p': {
      background: '#356d9e',
    },
  },
  chatMessageDate: {
    margin: '0px',
    color: 'grey',
    position: 'relative',
    top: '0px',
    left: '15px',
    fontSize: '10px',
  },
  chatMessageAlignRightDate: {
    left: 'unset',
    right: '15px',
  },
  chatInput: {
    width: '100%',
    height: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    overflowX: 'clip',
    position: 'relative',
    bottom: '0px',
    '& .MuiSvgIcon-root': {
      margin: '0px 10px',
      color: '#356d9e',
      cursor: 'pointer',
    },
  },
  chatTextInput: {
    width: '80%',
    height: '90%',
    justifyContent: 'center',
  },
  chatMessageError: {
    '& span': {
      color: 'red !important',
    },
  },
  addMessageToNote: {
    fontSize: '12px',
    color: '#0000004a',
    padding: '0 10px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  attachment: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },

    '& img': {
      maxWidth: '300px',
    },
  },
}));

export default useStyles;
