import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { registerUser } from '@services/requests/auth';

export const ThunkRegisterUser = createAsyncThunk(
  `${AUTH}/registerUser`,
  ({ payload }) => registerUser(payload),
);

export default {};
