import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DropDown from '@components/Auth/Common/DropDown';
import { portOptions } from './options';
import useStyles from './styles';

const PortAlert = ({ filterText }) => {
  const classes = useStyles();

  return (
    <div className={classes.flexDisplaying}>
      <Field
        id="selector"
        name="selector"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
        component={DropDown}
        options={portOptions}
      />
      <span>{filterText}</span>
    </div>
  );
};

PortAlert.propTypes = {
  filterText: PropTypes.string,
};

export default PortAlert;
