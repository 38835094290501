import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_POD } from '@store/slices/resources';
import { getHaloNewPodLink, getHaloPodLink } from '@services/requests/psaPods';

export const ThunkGetHaloPodLink = createAsyncThunk(
  `${HALO_POD}/getHaloPodLink`,
  async ({ crmId, useNewPodLink = false }) => {
    if (!useNewPodLink) {
      return getHaloPodLink(crmId);
    }

    return getHaloNewPodLink(crmId);
  },
);

export default {};
