import React, { useState } from 'react';
import { VpnKey as VpnKeyIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useLogin } from 'react-admin';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { getRoleIdFromStorage, getUserId } from '@services/api';
import roles from '@constants/roles';
import useStyles from './styles';

const LoginAsButton = ({ record }) => {
  const classes = useStyles();
  const login = useLogin();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentUserId = getUserId();
  const currentRoleId = getRoleIdFromStorage();

  if (currentUserId === record.id || currentRoleId !== roles.SUPER_ADMIN) {
    return null;
  }

  const loginAsHandler = () => {
    login({ loginAs: record.id }).catch(error => {
      enqueueSnackbar(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'Something went wrong.'
          : error.message,
        { variant: 'warning' },
      );
    });
  };

  return (
    <div>
      <ActionButton
        icon={<VpnKeyIcon className={classes.vpnIcon} />}
        handler={() => setDialogOpen(true)}
        toolTip="Login as..."
      />
      <ConfirmDialog
        open={!!dialogOpen}
        setOpen={setDialogOpen}
        onAccept={loginAsHandler}
        onCancel={() => setDialogOpen(false)}
        title={`Login as ${record.name} with role: ${record.role} of tenant: ${record.tenant}`}
        content="You will be redirected to the main page. To login back use logout button"
      />
    </div>
  );
};

LoginAsButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    tenant: PropTypes.string,
  }),
};

export default LoginAsButton;
