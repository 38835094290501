import { useMemo } from 'react';

// GET NOTES THUNKS
import {
  ThunkAddConnectWiseTicketNote,
  ThunkDeleteConnectWiseTicketNote,
  ThunkEditConnectWiseTicketNote,
  ThunkGetConnectWiseTicketNotes,
} from '@store/slices/connectWiseTicket/thunks';
import {
  ThunkCreateAutotaskNote,
  ThunkGetAutotaskNotes,
  ThunkUpdateAutotaskNote,
} from '@store/slices/autotaskTicket/thunks';
import {
  ThunkCreateKaseyaTicketNote,
  ThunkDeleteKaseyaTicketNote,
  ThunkGetKaseyaTicketNotes,
  ThunkUpdateKaseyaTicketNote,
} from '@store/slices/kaseyaTicket/thunks';

// NOTES SELECTORS
import { ConnectWiseTicketNotes } from '@store/slices/connectWiseTicket';
import { AutotaskTicketNotesSelector } from '@store/slices/autotaskTicket';
import { KaseyaTicketNotesSelector } from '@store/slices/kaseyaTicket';

import crmSources from '@constants/crmSources';

const BLUE_COLOR = `#0019FF`;
const RED_COLOR = '#FF0000';
const GREEN_COLOR = '#00FF19';

const convertAutotaskNote = note => ({
  id: note.id,
  createdBy: note.createdBy,
  description: note.description,
  labels: note.isPrivate ? [{ text: 'private', color: BLUE_COLOR }] : [],
  title: note.title,
  dateCreated: note.dateCreated,
  actions: ['edit'],
});

const convertKaseyaNote = note => ({
  id: note.id,
  createdBy: note.createdByName,
  description: note.details,
  labels: note.isInternal ? [{ text: 'internal', color: BLUE_COLOR }] : [],
  dateCreated: note.createdOn,
  actions: ['edit', 'delete'],
});

const convertConnectWiseNote = note => ({
  id: note.id,
  createdBy: note.createdBy,
  description: note.text,
  labels: [
    note.internalFlag && { text: 'internal', color: BLUE_COLOR },
    note.issueFlag && { text: 'issue', color: RED_COLOR },
    note.resolutionFlag && { text: 'resolution', color: GREEN_COLOR },
  ].filter(item => item),
  dateCreated: note.dateCreated,
  actions: ['edit', 'delete', 'context'],
});

export const useTicketNotesGenericFunctions = ({ psaType }) => {
  const {
    getNotes,
    notesSelector,
    convertNote,
    createNote,
    editNote,
    deleteNote,
  } = useMemo(() => {
    switch (psaType) {
      case crmSources.ConnectWise.name:
        return {
          getNotes: ThunkGetConnectWiseTicketNotes,
          notesSelector: ConnectWiseTicketNotes,
          createNote: ThunkAddConnectWiseTicketNote,
          editNote: ThunkEditConnectWiseTicketNote,
          deleteNote: ThunkDeleteConnectWiseTicketNote,
          convertNote: convertConnectWiseNote,
        };
      case crmSources.Autotask.name:
        return {
          getNotes: ThunkGetAutotaskNotes,
          notesSelector: AutotaskTicketNotesSelector,
          createNote: ThunkCreateAutotaskNote,
          editNote: ThunkUpdateAutotaskNote,
          convertNote: convertAutotaskNote,
        };
      case crmSources.Kaseya.name:
        return {
          getNotes: ThunkGetKaseyaTicketNotes,
          notesSelector: KaseyaTicketNotesSelector,
          convertNote: convertKaseyaNote,
          createNote: ThunkCreateKaseyaTicketNote,
          editNote: ThunkUpdateKaseyaTicketNote,
          deleteNote: ThunkDeleteKaseyaTicketNote,
        };
      default:
        return {
          getNotes: undefined,
          notesSelector: undefined,
          convertNote: undefined,
          editNote: () => {},
          deleteNote: () => {},
        };
    }
  }, [psaType]);

  return {
    getNotes,
    notesSelector,
    convertNote,
    createNote,
    editNote,
    deleteNote,
  };
};

export default {};
