import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  modalCancelButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 5px',
    },
  },
  modalButtonContainer: {
    textAlign: 'end',
    marginTop: '1.25em',
    minWidth: '20rem',
  },
  container: {
    width: '35rem',
  },
  rounded: {
    fontStyle: 'normal',
    borderRadius: '0.3125rem',
    marginBottom: '1rem',
  },
}));

export default useStyles;
