export const SMS_CAMPAIGN_USER_TYPES = {
  MANUAL: 'Manual',
  PSA: 'PsaContact',
  TENANT: 'TenantUser',
};

export const SMS_CAMPAIGN_SOURCES = {
  PSA: 'PSA',
  MANUAL: 'Manual',
  Tenant: 'Tenant',
};

export default {};
