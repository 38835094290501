import React from 'react';
import PropTypes from 'prop-types';

const Icon = React.memo(function Icon({ icon, alt, classes = '', style = {} }) {
  return <img src={icon} alt={alt} style={style} className={classes} />;
});

Icon.propTypes = {
  icon: PropTypes.string,
  alt: PropTypes.string,
  classes: PropTypes.string,
};

export default Icon;
