import React, { useCallback, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

import ReusableButton from '@common/Button/Button';
import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { MESSAGING_CHANNEL } from '@constants/routes';

const TicketLinkDialog = ({
  open,
  onClose,
  link,
  ticketId,
  channelCreationResponse = undefined,
}) => {
  const sharedStyles = useSharedStyles();

  const handleLinkClick = () => {
    window.open(link, '_blank');
  };

  const handleChannelClick = () => {
    window.open(
      `#${MESSAGING_CHANNEL.replace(
        ':channelId',
        channelCreationResponse?.channelId,
      ).replace(':configurationId', channelCreationResponse?.configId)}`,
      '_blank',
    );
  };

  const onMount = useCallback(() => {
    if (channelCreationResponse && channelCreationResponse?.channelCreated) {
      handleChannelClick();
    }
  }, [channelCreationResponse]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Ticket Created</DialogTitle>
      <DialogContent>
        <div>
          TicketID:
          {ticketId}
        </div>
        <span
          onClick={handleLinkClick}
          role="presentation"
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
          }}
        >
          Click here to open ticket on PSA.
        </span>

        <br />

        {channelCreationResponse?.channelCreated && (
          <div>
            {channelCreationResponse
              ? 'Messaging channel created'
              : 'Something went wrong when creating messaging channel'}
          </div>
        )}
        {channelCreationResponse?.channelId &&
          channelCreationResponse?.configId && (
            <span
              onClick={handleChannelClick}
              role="presentation"
              style={{
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              Click here to open messaging channel.
            </span>
          )}

        <div className={sharedStyles.dialogActionsContainer}>
          <ReusableButton
            viewType="white"
            buttonType="simple"
            label="Close"
            onClick={onClose}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

TicketLinkDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  ticketId: PropTypes.string.isRequired,
  channelCreationResponse: PropTypes.shape({
    configId: PropTypes.number,
    channelId: PropTypes.number,
    channelCreated: PropTypes.bool,
  }),
};
export default TicketLinkDialog;
