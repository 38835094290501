import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { sendGroupMessage } from '@services/requests/smsCampaigns';

export const ThunkSendGroupMessage = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/sendGroupMessage`,
  async ({ payload }) => sendGroupMessage(payload),
);

export default {};
