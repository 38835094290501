import React from 'react';
import PropTypes from 'prop-types';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import ApplyIcon from '@assets/icons/audit.svg';
import useStyles from '../styles';

const ActionField = ({ record, onEdit, onDelete, onApply }) => {
  const classes = useStyles();
  return (
    <div className={classes.actionContainer}>
      <ActionFieldItem handler={() => onEdit(record.id)} toolTip="Edit">
        <img src={EditIcon} alt="edit" />
      </ActionFieldItem>
      <ActionFieldItem
        handler={() => onApply(record.id)}
        toolTip="Apply template"
      >
        <img src={ApplyIcon} alt="apply" />
      </ActionFieldItem>
      <ActionFieldItem handler={() => onDelete(record.id)} toolTip="Delete">
        <img src={DeleteIcon} alt="delete" />
      </ActionFieldItem>
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onApply: PropTypes.func,
};

export default ActionField;
