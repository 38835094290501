import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';

import integrationsIcon from '@assets/icons/integrations.svg';
import useStyles from './styles';

const InfoBox = ({ text, classNameWrapper }) => {
  const classes = useStyles();

  return (
    text && (
      <>
        <Tooltip
          title={
            <span
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          }
          interactive
        >
          <img
            src={integrationsIcon}
            alt="integrationsIcon"
            className={cx(classes.iconButton, classNameWrapper)}
          />
        </Tooltip>
      </>
    )
  );
};

InfoBox.propTypes = {
  text: PropTypes.string,
  classNameWrapper: PropTypes.string,
};

export default InfoBox;
