import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Input from '@components/Auth/Common/Input';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDelete from '@assets/icons/delete.svg';
import { getOnlyLetterNumberText } from '@components/Parsers/components/Create/components/helpers';

import useStyles from '../styles';

const CustomFieldItem = ({ value, onDelete, onChange, loading }) => {
  const classes = useStyles();

  const handleChange = event => {
    switch (event.target.name) {
      case 'name':
        onChange({ ...value, name: event.target.value });
        break;
      case 'value':
        onChange({
          ...value,
          value: getOnlyLetterNumberText(event.target.value),
        });
        break;
      default:
        break;
    }
  };

  return (
    <Paper className={classes.display}>
      <span>Name:</span>
      <Input
        name="name"
        input={{
          value: value.name,
          onChange: handleChange,
        }}
        meta={{ touched: false, error: '' }}
        className={classes.inputStyle}
        disabled={loading}
      />
      <span>Value:</span>
      <Input
        name="value"
        input={{
          value: value.value,
          onChange: handleChange,
        }}
        meta={{ touched: false, error: '' }}
        className={classes.inputStyle}
        disabled={loading}
      />
      <ActionButton
        icon={<img src={iconDelete} alt="iconDelete" />}
        handler={() => onDelete(value.id)}
        disabled={loading}
      />
    </Paper>
  );
};

CustomFieldItem.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default CustomFieldItem;
