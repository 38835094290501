import React, { useState, useCallback, useEffect } from 'react';
import { useRedirect } from 'react-admin';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import useAsync from '@services/api/common/useAsync';
import { unrecognizedTypes } from '@constants/appRoutes';
import ReusableButton from '@common/Button/Button';
import { getUnrecognizedTypes } from '../../helpers';
import useStyles from './styles';

const getMessage = item => (
  <p key={item.id}>
    Unresolved&nbsp;
    <b>
      <i>{item.typeName}</i>
    </b>
    &nbsp;value:&nbsp;
    <b>
      <i>{item.value}</i>
    </b>
    .&nbsp;
  </p>
);

const WarningMessage = ({ mailboxId, setWarningIsOpen }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const [errors, setErrors] = useState([]);
  const getData = useCallback(() => getUnrecognizedTypes(mailboxId), [
    mailboxId,
  ]);
  useAsync(getData, setErrors);

  useEffect(() => {
    if (errors.length !== 0) {
      setWarningIsOpen(true);
    } else {
      setWarningIsOpen(false);
    }
  }, [errors]);

  if (errors.length === 0) return null;

  return (
    <div className={classes.container}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.baseColor} />}
        >
          <div className={classes.column}>
            <Typography className={cx(classes.heading, classes.baseColor)}>
              <b>Attention Required</b>
            </Typography>
          </div>
          <div className={classes.column2}>
            <Typography className={cx(classes.heading, classes.baseColor)}>
              There are a few errors during parsing process execution
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.heading}>
            <Typography variant="caption">
              {errors.map(i => getMessage(i))}
            </Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <ReusableButton
            label="Resolve"
            viewType="black"
            onClick={() => redirect(`${unrecognizedTypes}${mailboxId}`)}
          />
        </AccordionActions>
      </Accordion>
    </div>
  );
};

WarningMessage.propTypes = {
  mailboxId: PropTypes.string.isRequired,
  setWarningIsOpen: PropTypes.func,
};

export default WarningMessage;
