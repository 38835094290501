import { createAsyncThunk } from '@reduxjs/toolkit';
import { EMAIL_SETTINGS } from '@store/slices/resources';
import { getInviteClientPortalTemplateVariables } from '@services/requests/emailSettings';

export const ThunkGetInviteClientPortalTemplateVariables = createAsyncThunk(
  `${EMAIL_SETTINGS}/getInviteClientPortalTemplateVariables`,
  () => getInviteClientPortalTemplateVariables(),
);

export default {};
