import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box, Checkbox, FormControl, Grid, MenuItem } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { Draggable } from 'react-beautiful-dnd';
import { MoreVert } from '@material-ui/icons';

import Input from '@common/Input/Input';
import displayTypes from '@constants/displayTypes';
import Circle from '@common/Circle/Circle';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import MuiSlider from '@common/MuiSlider/MuiSlider';
import DropDown from '@components/Auth/Common/DropDown';
import iconDelete from '@assets/icons/delete.svg';
import { customTypeIdMeasure } from '@constants/numericConstants';

import useStyles from './dataViewItemStyles';

const DataViewItem = ({ item, fieldsOptions, onChange, onDelete, index }) => {
  const classes = useStyles();
  const [field, setField] = useState(item.field);
  const [name, setName] = useState(item.name ? item.name : '');
  const [type, setType] = useState(item.type ? item.type : 0);
  const [displayType, setDisplayType] = useState(
    item.displayType ? item.displayType : displayTypes.Text,
  );
  const [isIdentifier, setIsIdentifier] = useState(
    item.identifier ? item.identifier : false,
  );

  const [isCombine, setIsCombine] = useState(
    item.isCombine ? item.isCombine : false,
  );
  const [combCount, setCombCount] = useState(
    item.combCount ? item.combCount : 1,
  );

  const handleChange = (
    newField,
    newName,
    newType,
    newDisplayType,
    isId,
    isCombined,
    combCounter,
  ) => {
    onChange({
      id: item.id,
      field: newField,
      name: newName,
      type: newType,
      displayType: newDisplayType,
      identifier: isId,
      isCombine: isCombined,
      combCount: combCounter,
    });
  };

  const handleFieldChange = event => {
    const record = fieldsOptions.filter(i => i.name === event.target.value)[0];
    setField(record.name);
    setType(record.type);
    setIsIdentifier(record.identifier);
    const newName = record.name
      .replace(/([a-z\d])([A-Z])|([\d])([a-zA-Z])/g, '$1$3 $2$4')
      .replace(/\w+/g, w => w[0].toUpperCase() + w.slice(1).toLowerCase());
    setName(newName);
    handleChange(
      record.name,
      newName,
      record.type,
      displayType,
      record.identifier,
      isCombine,
      combCount,
    );
  };

  const handleNameChange = event => {
    setName(event.target.value);
    handleChange(
      field,
      event.target.value,
      type,
      displayType,
      isIdentifier,
      isCombine,
      combCount,
    );
  };

  const handleSetDisplayType = event => {
    setDisplayType(event.target.value);
    handleChange(
      field,
      name,
      type,
      event.target.value,
      isIdentifier,
      isCombine,
      combCount,
    );
  };

  const handleIsAggregateChange = event => {
    setIsCombine(event.target.checked);
    handleChange(
      field,
      name,
      type,
      displayType,
      isIdentifier,
      event.target.checked,
      combCount,
    );
  };

  const handleCombineCountChange = (event, newValue) => {
    setCombCount(newValue);
    handleChange(
      field,
      name,
      type,
      displayType,
      isIdentifier,
      isCombine,
      newValue,
    );
  };

  const aggregatePolicy = () => {
    return displayType === displayTypes.Circle && !isIdentifier ? (
      <div className={classes.aggregate}>
        <span>Combine results</span>
        <div className={classes.aggrControls}>
          <Checkbox
            onChange={handleIsAggregateChange}
            checked={isCombine}
            size="small"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <MuiSlider
            onChange={handleCombineCountChange}
            disabled={!isCombine}
            defaultValue={1}
            step={1}
            marks
            min={1}
            max={5}
            value={combCount}
          />
        </div>
      </div>
    ) : null;
  };

  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={12}
          className={classes.container}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Form onSubmit={() => {}}>
            {() => (
              <>
                <div className={classes.div}>
                  <span>Field:&nbsp;</span>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                  >
                    <DropDown
                      input={{
                        value: field,
                        onChange: handleFieldChange,
                      }}
                      options={fieldsOptions}
                      labelName="name"
                      valueName="name"
                    />
                  </FormControl>
                </div>
                <div className={classes.div}>
                  <span>Name:&nbsp;</span>
                  <Field
                    name="name"
                    component={Input}
                    classNameWrapper={classes.formControl}
                    styleType="main"
                    inputView="text"
                    input={{
                      value: name,
                      onChange: handleNameChange,
                    }}
                  />
                </div>
                <div className={classes.div}>
                  <span>DisplayType:&nbsp;</span>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                  >
                    <DropDown
                      disabled={item.type < customTypeIdMeasure}
                      input={{
                        value: displayType,
                        onChange: handleSetDisplayType,
                      }}
                    >
                      <MenuItem value={displayTypes.Text}>
                        {displayTypes.Text}
                      </MenuItem>
                      <MenuItem value={displayTypes.Badge}>
                        <span className={classes.badge}>
                          {displayTypes.Badge}
                        </span>
                      </MenuItem>
                      <MenuItem value={displayTypes.Circle}>
                        <Circle bgColor="#00B500" borderColor="#fff" />
                        <span style={{ marginLeft: '10px' }}>
                          {displayTypes.Circle}
                        </span>
                      </MenuItem>
                    </DropDown>
                  </FormControl>
                </div>
                {aggregatePolicy()}
                <div className={cx(classes.div, classes.iconDelete)}>
                  <ActionButton
                    handler={() => onDelete(item)}
                    icon={<img src={iconDelete} alt="iconDelete" />}
                    toolTip="Add template variable"
                  />
                </div>
                <div>{isIdentifier ? '[ID]' : ' '}</div>
                <Box className={classes.draggableIcon}>
                  <MoreVert />
                </Box>
              </>
            )}
          </Form>
        </Grid>
      )}
    </Draggable>
  );
};

DataViewItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  fieldsOptions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DataViewItem;
