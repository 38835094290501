import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import crmSources from '@constants/crmSources';

import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@common/Loading/Loading';
import AutotaskFileUploadForm from '@components/FileUpload/components/AutotaskFileUploadForm';

import useStyles from './styles';

const FileUpload = () => {
  const [loading, setLoading] = useState(true);
  const [selectedPsa, setSelectedPsa] = useState(0);
  const [crmOptions, setCrmOptions] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();

  const form = useMemo(() => {
    const selected = crmOptions.find(option => option.id === selectedPsa);

    if (selected) {
      switch (selected.crmType) {
        case crmSources.ConnectWise.name:
          return <>CW</>;
        case crmSources.Autotask.name:
          return <AutotaskFileUploadForm psaId={selected.id} />;
        default:
          return <></>;
      }
    }
    return <></>;
  }, [crmOptions, selectedPsa]);

  const onMount = useCallback(async () => {
    setLoading(true);
    const options = await dispatch(ThunkGetCrmSources()).unwrap();
    setCrmOptions(
      options.filter(
        i =>
          // i.crmType === crmSources.ConnectWise.name ||
          i.crmType === crmSources.Autotask.name,
      ),
    );
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div
      className={classes.pageContent}
      style={{
        width: '100%',
        height: '93vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          width: '40%',
          margin: '20px auto',
        }}
      >
        <DropDown
          input={{
            value: selectedPsa,
            onChange: e => {
              setSelectedPsa(e.target.value);
            },
          }}
          options={crmOptions}
          valueName="id"
          labelName="name"
        />
      </div>

      <div>{form}</div>
    </div>
  );
};

export default FileUpload;
