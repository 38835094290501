export const phoneValidation = phone => {
  const regEx = /^[+][0-9]{9,11}/gm;
  if (!phone) {
    return 'Required';
  }
  if (!regEx.test(phone)) {
    return 'Wrong format';
  }
  return undefined;
};

export default phoneValidation;
