import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  btnWrapper: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-evenly',
    },
    display: 'flex',
    border: '1px solid black',
  },
}));

export default useStyles;
