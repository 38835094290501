import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { sendDuoPush } from '@services/requests/psaPods';

export const ThunkSendDuoPush = createAsyncThunk(
  `${CONNECTWISE_POD}/sendDuoPush`,
  async payload => {
    const res = await sendDuoPush(payload);
    return res;
  },
);

export default {};
