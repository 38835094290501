import { createAsyncThunk } from '@reduxjs/toolkit';
import { getZendeskIntegrationTypes } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetZendeskIntegrationTypes = createAsyncThunk(
  `${INTEGRATION}/getZendeskIntegrationTypes`,
  async ({ crmId }) => {
    const types = await getZendeskIntegrationTypes(crmId);
    return types;
  },
);

export default {};
