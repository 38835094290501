import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import useStyles from './dialogStyles';

const TemplateDialog = ({ open, setOpen, content, title }) => {
  const classes = useStyles();
  return (
    <Dialog
      size="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

TemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
};

export default TemplateDialog;
