import { createAsyncThunk } from '@reduxjs/toolkit';
import { linkTriageToTicket } from '@services/requests/triage';
import { TRIAGE } from '@store/slices/resources';

export const ThunkLinkTriageToTicket = createAsyncThunk(
  `${TRIAGE}/linkTriageToTicket`,
  async payload => {
    const ticket = await linkTriageToTicket(payload);
    return ticket;
  },
);

export default {};
