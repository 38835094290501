import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { linkCrmUserToAccount } from '@services/requests/user';

export const ThunkLinkCrmUsersToAccount = createAsyncThunk(
  `${USER}/linkCrmUsersToAccount`,
  async payload => {
    await linkCrmUserToAccount(payload);
    return true;
  },
);

export default {};
