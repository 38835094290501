import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getAutotaskCompanies } from '@services/requests/createTicket';

export const ThunkGetAutotaskCompanies = createAsyncThunk(
  `${CREATE_TICKET}/getAutotaskCompanies`,
  ({ psaId }) => getAutotaskCompanies(psaId),
);

export default {};
