const signalRMessageTypes = Object.freeze({
  status: 'Status',
  event: 'Event',
  tenantSubscription: 'TenantSubscription',
  webHook: 'WebHook',
  messagingChannelCreated: 'MessagingChannelCreated',
  messagingChannelMessage: 'MessagingSmsToChannel',
  contactVerificationEvent: 'ContactVerificationPassed',
  psaTechVerificationSmsRequestEvent: 'PsaTechVerificationSmsRequest',
});

export default signalRMessageTypes;
