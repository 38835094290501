import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BooleanAccessor from '@ui/components/common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import { optInResults } from '@constants/optInResults';

import useStyles from '../styles';

const GroupOptInDialogResult = ({ errored, scheduled, processedItems }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.optInDialogResultsHeader}>
        <div>{`Total Processed: ${processedItems.length}`}</div>
        <div>{`Success: ${scheduled}`}</div>
        <div>{`Errors: ${errored}`}</div>
      </div>

      <div className={classes.optInDialogItemsList}>
        <div
          className={cx(
            classes.optInDialogResultsList,
            classes.optDialogItemsListHeader,
          )}
        >
          <div>Result</div>
          <div>Contact Name</div>
          <div className={classes.textCentered}>Sms</div>
          <div className={classes.textCentered}>Email</div>
          <div>Errors</div>
        </div>
        {processedItems.map(item => (
          <div key={item.contactId} className={classes.optInDialogResultsList}>
            {/* TODO add contact name here */}
            <div style={{ color: item.result === 1 ? 'green' : 'red' }}>
              {optInResults[item.result]}
            </div>
            <div>{item.contactName}</div>
            <div>
              <BooleanAccessor value={item.smsScheduled} useMultiColor />
            </div>
            <div>
              <BooleanAccessor value={item.emailScheduled} useMultiColor />
            </div>
            <div>{item.errors}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

GroupOptInDialogResult.propTypes = {
  errored: PropTypes.number.isRequired,
  scheduled: PropTypes.number.isRequired,
  processedItems: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number,
      emailScheduled: PropTypes.bool,
      errors: PropTypes.string,
      result: PropTypes.number,
      smsScheduled: PropTypes.bool,
    }),
  ),
};

export default GroupOptInDialogResult;
