import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import iconEdit from '@assets/icons/edit.svg';
import iconDelete from '@assets/icons/delete.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import useStyles from '../styles';

const Card = ({
  item,
  img,
  getImage,
  readOnly,
  handleClick,
  deleteHandler,
  editHandler,
}) => {
  const classes = useStyles();

  const actionButtons = (
    <div className={classes.buttonGroups}>
      {editHandler && (
        <ActionButton
          icon={<img src={iconEdit} alt="iconEdit" />}
          handler={editHandler}
        />
      )}
      {deleteHandler && (
        <ActionButton
          icon={<img src={iconDelete} alt="iconDelete" />}
          e
          handler={deleteHandler}
        />
      )}
    </div>
  );
  return (
    <div
      className={cx(classes.container, classes.smallContainer)}
      role="presentation"
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <img
        src={img || getImage(item.type)}
        className={classes.imageStyle}
        alt=""
      />
      <div>
        <b className={classes.text}>{item.name}</b>
      </div>
      {!readOnly && actionButtons}
      <div className={classes.truncate} />
    </div>
  );
};

Card.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func,
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  getImage: PropTypes.func,
};

export default Card;
