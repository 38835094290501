import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import cx from 'classnames';

import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@common/Checkbox/Checkbox';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';

import eventBus from '@services/eventBus';
import { fieldTransformersSelectionEvents as events } from '@constants/events';
import useStyles from './styles';
import { getOnlyLetterNumberText } from '../helpers';

const FieldTransformerRecord = ({ item, onChange, typesOptions }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(item.checked);
  const [idChecked, setIdChecked] = useState(item.identifier);
  const [name, setName] = useState(item.name);
  const [type, setType] = React.useState(item.type);
  const array = item.path.split('.');
  const activeColor = '#8ad8fe';
  const inactiveColor = '#d2d2d2';
  const data = {
    element: [...array].slice(-1)[0],
    path: array.length === 1 ? [] : [...array].slice(0, -1),
  };

  const handleChange = useCallback(
    (newName, newChecked, newIdentifier, newType) => {
      onChange({
        id: item.id,
        name: newName,
        checked: newChecked,
        path: item.path,
        identifier: newIdentifier,
        type: newType,
      });
    },
    [item, onChange],
  );

  const onEventHandler = useCallback(() => {
    if (!checked) {
      setChecked(true);
      handleChange(name, true, idChecked, type);
    }
  }, [checked, handleChange, name, idChecked, type]);

  useEffect(() => {
    eventBus.on(events.SelectAll, onEventHandler);

    return () => eventBus.remove(events.SelectAll, onEventHandler);
  }, [onEventHandler]);

  const getText = value => value.replace('[', '').replace(']', '');

  const handleChecked = event => {
    setChecked(event.target.checked);
    handleChange(name, event.target.checked, idChecked, type);
  };
  const handleNameChange = event => {
    const newName = getOnlyLetterNumberText(event.target.value);
    setName(newName);
    handleChange(newName, checked, idChecked, type);
  };

  const handleIdChecked = event => {
    setIdChecked(event.target.checked);
    handleChange(name, checked, event.target.checked, type);
  };

  const handleTypeChange = event => {
    setType(event.target.value);
    handleChange(name, checked, idChecked, event.target.value);
  };

  return (
    <div className={classes.container} style={{ margin: '10px 0' }}>
      <Form onSubmit={() => {}}>
        {() => {
          return (
            <>
              <div style={{ marginRight: '15px', display: 'flex' }}>
                <Checkbox
                  checked={checked}
                  onChange={handleChecked}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Field
                  id={item.path}
                  name="name"
                  disabled={!checked}
                  styleType="main"
                  inputView="text"
                  fullWidth
                  input={{
                    value: name,
                    onChange: handleNameChange,
                  }}
                  component={Input}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={idChecked}
                    onChange={handleIdChecked}
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={!checked}
                  />
                }
                label="Id"
              />
              <div className={classes.typeSelector}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <DropDown
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    options={typesOptions}
                    input={{
                      value: type,
                      onChange: handleTypeChange,
                    }}
                  />
                </FormControl>
              </div>
              {data.path.map(i => (
                <div className={classes.container} key={i}>
                  <div
                    className={`${classes.item} ${classes.borderRoot}`}
                    style={
                      checked
                        ? { backgroundColor: `${activeColor}` }
                        : { backgroundColor: `${inactiveColor}` }
                    }
                  >
                    <div className={classes.inline}>{getText(i)}</div>
                  </div>
                  <div
                    className={classes.angle}
                    style={
                      checked
                        ? { borderLeft: `20px solid ${activeColor}` }
                        : { borderLeft: `20px solid ${inactiveColor}` }
                    }
                  />
                </div>
              ))}
              <div
                className={`${classes.item} ${classes.borderItem}`}
                style={
                  checked
                    ? { backgroundColor: `${activeColor}` }
                    : { backgroundColor: `${inactiveColor}` }
                }
              >
                <div className={cx(classes.inline, classes.overFlow)}>
                  {getText(data.element)}
                </div>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

FieldTransformerRecord.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  typesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FieldTransformerRecord;
