import { useContext } from 'react';
import { TableContext } from '@components/Table/context';
import { useListContext } from 'react-admin';
import { useSelector } from 'react-redux';

export const useTableContext = () => {
  const context = useContext(TableContext);

  const list = useSelector(state => state.list.list);

  const { selectedIds: oldTableIds, data, onUnselectItems } = useListContext();

  return {
    selectedIds: context ? context?.selectedIds : oldTableIds,
    data: context ? context?.data : data,
    list,
    deselectAllItems: context ? context.deselectAllItems : onUnselectItems,
    filtering: context?.filtering,
    setFilteringObject: context?.setFilteringObject,
  };
};

export default {};
