import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalChat } from '@services/requests/clientPortal';

export const ThunkGetClientPortalChat = createAsyncThunk(
  `${CLIENT_PORTALS}/getClientPortalChat`,
  ({ chatId }) => getClientPortalChat(chatId),
);

export default {};
