import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    margin: '4rem 16px 0 11px',
    '& tbody': {
      maxHeight: '70vh',
    },
  },
  buttonWrapper: {
    marginBottom: '10px',
  },
  booleanFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '65vw',
    transform: 'translate(-50%, -50%)',
  },
  wizardModalBox: {
    width: '70vw',
  },
  boxField: {
    display: 'flex',
  },
  stepperRoot: {
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
  },
  stepperBody: {
    borderRadius: '5px',
    backgroundColor: 'white',
    height: '70vh',
    padding: '1rem',
  },
  selectIntegrationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  column: {
    height: 'calc(70vh - 150px)',
    overflowY: 'scroll',
    padding: 0,
  },
  columnItem: {
    border: '1px solid gray',
    margin: '0.3rem 5rem 0.3rem 0',
    borderRadius: '5px',
    padding: '4px',
  },
  orgColumnItem: {
    backgroundColor: props => (props.enabled ? 'none' : '#ffc2c2'),
    cursor: props => (props.enabled ? 'pointer' : 'no-drop'),
    '&:hover': {
      color: props => (props.enabled ? '#b1b0ad' : '#000'),
    },
  },
  networkColumnItem: {
    cursor: 'pointer',
    '&:hover': {
      color: '#b1b0ad',
    },
  },
  font: {},
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  grayColor: {
    color: 'gray',
  },
  marginTop: {
    marginTop: '40px',
  },
  smallPadding: {
    padding: '10px',
  },
  networksMargin: {
    marginTop: '40px',
  },
  applyTemplateFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '6rem',
    alignItems: 'center',
  },
}));

export default useStyles;
