import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import { currentUserDataSelector } from '@store/selectors';

import { SCHEDULE_GROUP } from '@constants/routes';
import Roles from '@constants/roles';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';
import CalendarIcon from '@assets/icons/updated/calendar.svg';

import useStyles from '../styles';

const ActionField = ({ record, onEdit, onDelete }) => {
  const currentUser = useSelector(currentUserDataSelector);
  const { push } = useHistory();
  const classes = useStyles();

  const isTechnician = currentUser?.roleId === Roles.READ_ONLY;

  return (
    <div
      className={cx({
        [classes.alignCenter]: isTechnician,
      })}
    >
      <ActionFieldItem
        handler={() => {
          push(`${SCHEDULE_GROUP}?groupId=${record.id}`);
        }}
        toolTip="Calendar"
      >
        <img src={CalendarIcon} alt="calendar" />
      </ActionFieldItem>

      {!isTechnician && (
        <>
          <ActionFieldItem handler={() => onEdit(record)} toolTip="Edit">
            <img src={EditIcon} alt="edit" />
          </ActionFieldItem>
          <ActionFieldItem handler={() => onDelete(record)} toolTip="Delete">
            <img src={DeleteIcon} alt="delete" />
          </ActionFieldItem>
        </>
      )}
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionField;
