import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_MAPPINGS } from '@store/slices/resources';
import { getClientPortalCompanies } from '@services/requests/clientPortal';

export const ThunkGetClientPortalCompanies = createAsyncThunk(
  `${CLIENT_PORTAL_MAPPINGS}/getClientPortalCompanies`,
  ({ id }) => getClientPortalCompanies(id),
);

export default {};
