import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { deleteLiveChatConfigurations } from '@services/requests/liveChats';

export const ThunkDeleteLiveChatConfigurations = createAsyncThunk(
  `${LIVE_CHATS}/deleteLiveChatConfigurations`,
  async configurationId => {
    await deleteLiveChatConfigurations(configurationId);
    return configurationId;
  },
);

export default {};
