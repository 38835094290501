import React from 'react';
import { FormControlLabel, FormControl, Switch } from '@material-ui/core';

import useStyles from './dropDownStyles';

const FormSwitch = ({
  disabled,
  input,
  label,
  wrapperStyles = {},
  labelPlacement,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.formControl}
      disabled={disabled}
      style={wrapperStyles}
    >
      <FormControlLabel
        control={<Switch {...input} {...props} />}
        label={label}
        disabled={disabled}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  );
};

export default FormSwitch;
