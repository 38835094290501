import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from '@common/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const IncludeServiceInfoCheckBox = ({ checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          input={{
            checked,
            onChange,
          }}
        />
      }
      label="Include system information (like Id, acknowledge, assignment, etc...)"
    />
  );
};

IncludeServiceInfoCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IncludeServiceInfoCheckBox;
