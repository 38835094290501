import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,

    fontStyle: 'normal',
  },
  tableCellRoot: {
    border: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dataGridWrapper: {
    height: '147px',
    '& div[role=columnheader]': {
      borderRight: 'none !important',
    },
  },
  jsonTextStyle: {
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('md')]: {
      maxHeight: '24vw',
      maxWidth: '82.5vw',
    },
  },
  mailBody: {
    height: '48vh',
    overflow: 'auto',
  },
  switcherButtons: {
    padding: '0.5rem 0.5rem 0.5rem 2.6875rem !important',
  },
  iconBlack: {
    fill: theme.palette.info.main,
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 8px',
    },
  },
  backButtonWrapper: {
    margin: '0',
    padding: '0',
    border: 'none',
    justifyContent: 'flex-start',
    width: 'max-content',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:last-child': {
      marginLeft: '0',
    },
    '& span': {
      textTransform: 'uppercase',
    },
  },
  badgeContainer: {
    margin: '10px 0',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-evenly',
      marginTop: '20px',
    },
  },
  headerWrapper: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },

  btnStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  tabsHeaderWrapper: {
    borderBottom: `0.0625rem solid ${theme.palette.info.light}`,
    backgroundColor: '#fff',
    marginBottom: 0,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      height: '70px',
    },
    alignItems: 'flex-end',
  },
  header: {
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
  tableHeader: {
    fontSize: '1em',
    fontWeight: 'bold',
  },
  containerSpacing: {
    [theme.breakpoints.up('sm')]: {
      padding: '15px 30px',
    },
    padding: '15px 10px',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  tabsContentWrapper: {
    padding: '20px',
    boxShadow: '0 4px 24px 0 #A8B5D840',
  },
  noPadding: {
    paddingLeft: '0',
  },
  noBullet: {
    listStyleType: 'none',
  },
  attachmentContainer: {
    overflow: 'auto',
    height: '100%',
  },
  unauthorizedLoader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  copyButton: {
    position: 'absolute',
    right: '45px',
  },
}));

export default useStyles;
