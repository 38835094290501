import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import iconPlus from '@assets/icons/plus.svg';
import IconButton from '@material-ui/core/IconButton';
import { getTileItemData } from '@components/Dashboard/helper';
import ClockIcon from '@assets/icons/clock.svg';
import { actions } from '@store/actions';
import Count from '@components/Dashboard/GridLayout/Tile/Layouts/Count';
import { dashboardItemData } from '@store/selectors/dashboard';
import CircularProgress from '@material-ui/core/CircularProgress';
import { chartTypes } from '@components/Dashboard/Modals/Tile/form';
import Chart from '@components/Dashboard/GridLayout/Tile/Layouts/Chart';
import UpdatedAgo from '@components/Dashboard/GridLayout/Tile/UpdatedAgo';
import useStyles from './styles';

const TileContent = ({ tile, isNew, onEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tileData = useSelector(dashboardItemData(tile.dashboardItemId));
  const [loading, setLoading] = useState(false);

  const loadData = () => {
    if (isNew) return;
    setLoading(true);
    getTileItemData(tile.dashboardItemId)
      .then(data => dispatch(actions.dashboard_addItem(data)))
      .catch(() => null)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, [isNew, tile.dashboardItemId]);

  const renderAddButton = () => {
    return (
      <IconButton aria-label="add tile" title="Add tile" onClick={onEdit}>
        <img src={iconPlus} alt="iconEdit" style={{ width: 60, height: 60 }} />
      </IconButton>
    );
  };

  const renderTileData = () => {
    switch (tile.item.type) {
      case chartTypes.count:
        return <Count data={tileData?.data} />;
      case chartTypes.pie:
      case chartTypes.bar:
      case chartTypes.column:
      case chartTypes.histogram:
        return (
          <Chart
            data={tileData?.data}
            tileId={tile.id}
            chartType={tile.item.type}
          />
        );
      default:
        return null;
    }
  };

  const handleRefresh = () => {
    loadData();
  };

  return loading ? (
    <div className={classes.tileContent}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <div className={classes.tileContent}>
        {isNew ? renderAddButton() : renderTileData()}
      </div>
      <div className={classes.refreshArea}>
        <IconButton
          aria-label="refresh tile"
          title="Refresh"
          size="small"
          disabled={isNew}
          onClick={handleRefresh}
        >
          <img
            src={ClockIcon}
            alt="Refresh"
            style={{ width: '20px', height: '20px', filter: 'grayscale(1)' }}
          />
        </IconButton>
        <div className={classes.updatedAgo}>
          <UpdatedAgo isNew={isNew} updatedAgo={tileData?.data.date} />
        </div>
      </div>
    </>
  );
};

TileContent.propTypes = {
  tile: PropTypes.objectOf(PropTypes.any),
  isNew: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default TileContent;
