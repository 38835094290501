import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';

const GrayIconsTheme = ({ children }) => {
  return (
    <div>
      <ThemeProvider
        theme={outherTheme => ({
          ...outherTheme,
          overrides: {
            MuiSvgIcon: {
              root: {
                color: 'inherit',
              },
            },
          },
        })}
      >
        {children}
      </ThemeProvider>
    </div>
  );
};

GrayIconsTheme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GrayIconsTheme;
