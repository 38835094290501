import { dashboardPeriods, dashboardTypes } from '@constants/dashboardFilters';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMessagingChannelsCharts,
  getMessagingMessagesCharts,
} from '@services/requests/messagingDashboard';
import { MESSAGING_DASHBOARD } from '@store/slices/resources';
import moment from 'moment';

const getTimePeriod = (period, startDate, endDate) => {
  let res;
  switch (period) {
    case dashboardPeriods[0].value:
      res = {
        endDate: new Date().toISOString(),
        startDate: moment(new Date())
          .subtract(1, 'day')
          .toISOString()
          .split('T')[0],
      };
      break;
    case dashboardPeriods[1].value:
      res = {
        endDate: new Date().toISOString(),
        startDate: moment(new Date())
          .subtract(3, 'day')
          .toISOString()
          .split('T')[0],
      };
      break;
    case dashboardPeriods[2].value:
      res = {
        endDate: new Date().toISOString(),
        startDate: moment(new Date())
          .subtract(7, 'day')
          .toISOString()
          .split('T')[0],
      };
      break;
    case dashboardPeriods[3].value:
      res = {
        endDate: new Date().toISOString(),
        startDate: moment(new Date())
          .subtract(1, 'month')
          .toISOString()
          .split('T')[0],
      };
      break;
    case dashboardPeriods[4].value:
      res = {
        endDate,
        startDate,
      };
      break;
    default:
      break;
  }

  return res;
};

export const ThunkGetMessagingDashboardCharts = createAsyncThunk(
  `${MESSAGING_DASHBOARD}/getMessagingChannelsCharts`,
  async ({ type, period, startDate, endDate }) => {
    if (type === dashboardTypes.channels) {
      const chart = await getMessagingChannelsCharts(
        getTimePeriod(period, startDate, endDate),
      );
      return { date: new Date(), ...chart };
    }
    const chart = await getMessagingMessagesCharts(
      getTimePeriod(period, startDate, endDate),
    );
    return { date: new Date(), ...chart };
  },
);

export default {};
