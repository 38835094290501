import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dataGridWrapper: ({ tableHeight }) => ({
    /**
     * we should override style due to "!important", for removing borders
     */
    '& div[role=columnheader]': {
      borderRight: 'none !important',
    },
    /**
     * set additional "2" to height help us to avoid unnecessary horizontal scroll;
     */
    height: tableHeight + 2,
  }),
  runningStateContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  runningStateIcon: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
  infoBox: {
    position: 'absolute',
    top: '10px',
    right: '-30px',
  },
  inputWrapper: {
    marginBottom: '0.625rem',
  },
  sliderWrapper: {
    minWidth: '200px',
  },
  disabledInput: {
    '& :disabled': {
      backgroundColor: '#e5e5e5',
    },
  },
  classNameLabelInner: {
    maxWidth: 'inherit',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:last-child': {
        marginLeft: '1.875rem',
      },
    },
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: '2.5rem',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    padding: '0 7px',
    backgroundColor: ({ hex }) => hex,
    width: '164px',
    height: '21px',
    borderRadius: '0.125rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listActionContainer: {
    width: '99%',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: '10px 0 10px 10px',
  },
  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
    },
  },

  alignPosition: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },

  filterTextStyles: {
    color: theme.palette.secondary.main,
  },

  plusIcon: {
    fill: theme.palette.info.main,
    marginRight: '0.5rem',
  },
  createBtn: {
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '1rem',
    },
  },
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  iconBlack: {
    fill: theme.palette.info.main,
  },
  backButtonWrapper: {
    margin: '0',
    padding: '0',
    border: 'none',
    justifyContent: 'flex-start',
    width: 'max-content',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:last-child': {
      marginLeft: '0',
    },
    '& span': {
      textTransform: 'uppercase',
    },
  },

  bagelContainer: {
    backgroundColor: '#373b3e',
    [theme.breakpoints.up('sm')]: {
      margin: '0.5rem 2rem',
    },
    borderRadius: '10px',
  },
  bagelGridContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  bagelGrid: {
    backgroundColor: '#323538',
  },
  bagelChartTitle: {
    color: '#fff',
    borderBottom: '1px solid #222',
    display: 'flex',
    minHeight: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      marginLeft: '1rem',
    },
  },
  bagelChartCount: {
    cursor: 'pointer',
    color: '#fff',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '40px',
  },
  canvasContainer: {
    position: 'relative',
  },
  iconRemove: {
    margin: '0 5px',
    height: '15px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  testArea: {
    justifyContent: 'center',
  },
  form: {
    overflowY: 'auto',
    height: '80vh',
    overflowX: 'hidden',
    padding: '20px',
    boxSizing: 'border-box',
  },
}));

export default useStyles;
