import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmDefaultExtended } from '@services/requests/crm';
import { CRM } from '@store/slices/resources';

export const ThunkGetCrmDefaultsExtended = createAsyncThunk(
  `${CRM}/getCrmDefaultExtended`,
  () => getCrmDefaultExtended(),
);

export default {};
