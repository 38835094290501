import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Field, Form } from 'react-final-form';
import { Done as DoneIcon, Clear as ClearIcon } from '@material-ui/icons';

import Input from '@common/Input/Input';
import Chip from '@common/Chip/Chip';
import useStyles from '../styles';

const EditChipComponent = ({ data, handleCancel, handleUpdate }) => {
  const classes = useStyles();

  return (
    <Form onSubmit={handleUpdate} initialValues={data}>
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.inputEditContainer}>
              <Field
                name="value"
                styleType="main"
                inputView="text"
                component={Input}
              />
              <div>
                <button
                  type="submit"
                  className={classes.inputEditContainer_button}
                >
                  <DoneIcon classes={{ root: classes.iconWrapper }} />
                </button>
                <ClearIcon
                  classes={{ root: classes.iconWrapper }}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

const ChipForBulkIgnore = ({ chipData, setChipData }) => {
  const classes = useStyles();

  const handleDelete = chipToDelete => {
    setChipData(chips =>
      chips.filter(chip => chip.value !== chipToDelete.value),
    );
  };

  const handleEditMode = (flag, chip, to, from, search) => {
    const chipDataClone = [...chipData];
    const index = chipDataClone.findIndex(item => item.value === chip[search]);
    if (index !== -1) {
      const element = chipDataClone[index];
      element.editMode = flag;
      element[to] = chip[from];
      setChipData(chipDataClone);
    }
  };

  const handleEdit = chipToEdit =>
    handleEditMode(true, chipToEdit, 'copyValue', 'value', 'value');

  const handleCancel = chipCancelEdit =>
    handleEditMode(false, chipCancelEdit, 'value', 'copyValue', 'value');

  const handleUpdate = updatedChip => {
    handleEditMode(false, updatedChip, 'value', 'value', 'copyValue');
  };

  return (
    <Paper component="ul" className={classes.chipRoot}>
      {chipData.map(data => {
        return (
          <li key={data.value}>
            {data.editMode ? (
              <EditChipComponent
                handleCancel={() => handleCancel(data)}
                handleUpdate={handleUpdate}
                data={data}
              />
            ) : (
              <Chip
                label={data.value}
                onEdit={() => handleEdit(data)}
                onDelete={() => handleDelete(data)}
                className={classes.chip}
              />
            )}
          </li>
        );
      })}
    </Paper>
  );
};

EditChipComponent.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  handleCancel: PropTypes.func,
  handleUpdate: PropTypes.func,
};

ChipForBulkIgnore.propTypes = {
  chipData: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChipData: PropTypes.func.isRequired,
};

export default ChipForBulkIgnore;
