import { createAsyncThunk } from '@reduxjs/toolkit';
import { editLiveChatConversationTemplate } from '@services/requests/messaging';
import { LIVE_CHAT_CONVERSATIONS } from '@store/slices/resources';

export const ThunkEditLiveChatConversationTemplate = createAsyncThunk(
  `${LIVE_CHAT_CONVERSATIONS}/editConversationTemplate`,
  async ({ id, payload }) => {
    await editLiveChatConversationTemplate(id, payload);
    return payload;
  },
);

export default {};
