import { integrationSources } from '@constants/integrationSources';

const MerakiInitialValues = data => ({
  name: data.name || null,
  apiKey: data.apiKey || null,
});

const SophosInitialValues = data => ({
  name: data.name || null,
  publicKey: data.publicKey || null,
  privateKey: data.privateKey || null,
});

const getInitialValues = (data, type) =>
  ({
    [integrationSources.Meraki.name]: MerakiInitialValues(data),
    [integrationSources.Sophos.name]: SophosInitialValues(data),
  }[type] || null);

export default getInitialValues;
