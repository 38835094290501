import React from 'react';
import PropTypes from 'prop-types';
import ResetPassword from '@components/ResetPassword';
import UnauthorizedLayout from '@components/UnauthorizedLayout';
import ForgotPassword from '@components/ResetPassword/forgotPassword/ForgotPassword';

const allowedServices = ['resetPassword', 'forgotPassword'];

const EmailHandler = props => {
  const {
    location: { search },
  } = props;
  const urlParams = new URLSearchParams(search);
  const mode = urlParams.get('mode');
  const oobCode = urlParams.get('oobCode');

  // todo remove it after finishing all forms
  if (!allowedServices.includes(mode)) {
    window.location.href = '#/login';
  }

  return (
    <UnauthorizedLayout>
      {
        {
          forgotPassword: <ForgotPassword {...props} />,
          resetPassword: <ResetPassword {...props} oobCode={oobCode} />,
        }[mode]
      }
    </UnauthorizedLayout>
  );
};

EmailHandler.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }),
};

export default EmailHandler;
