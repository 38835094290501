import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import uploadFileStatues from '@constants/uploadFileStatuses';
import Input from './Input';
import fileFormat from './fileFormats';
import useStyles from './styles';

const MAX_FILES = 1;
const MAX_SIZE_BYTES = 800000;

const FileUploader = ({
  maxFiles = MAX_FILES,
  maxSizeBytes = MAX_SIZE_BYTES,
  classNameWrapper,
  handleUpload,
  format = 'images',
  type,
  ...rest
}) => {
  const classes = useStyles();

  const handleChangeStatus = ({ file, remove }, status) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case uploadFileStatues.sizeError: {
        remove();
        break;
      }
      case uploadFileStatues.done: {
        handleUpload(file, remove);
        break;
      }
    }
  };

  return (
    <div className={cx(classes.dropzoneWrapper, classNameWrapper)}>
      <Dropzone
        onChangeStatus={handleChangeStatus}
        maxFiles={maxFiles}
        inputWithFilesContent={() => null}
        InputComponent={props => <Input {...props} type={type} />}
        maxSizeBytes={maxSizeBytes}
        accept={fileFormat[format]}
        {...rest}
      />
    </div>
  );
};

FileUploader.propTypes = {
  maxFiles: PropTypes.number,
  maxSizeBytes: PropTypes.number,
  classNameWrapper: PropTypes.string,
  handleUpload: PropTypes.func,
  type: PropTypes.oneOf(['folder', 'file']),
  format: PropTypes.string,
};

export default FileUploader;
