import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIncidentNotes } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';

export const ThunkGetIncidentNotes = createAsyncThunk(
  `${INCIDENT}/getIncidentNotes`,
  async incidentId => {
    const incidentNotes = await getIncidentNotes(incidentId);
    return incidentNotes;
  },
);

export default {};
