import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  bannerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (max-width: 1100px)': {
      display: 'none',
    },
  },
  backdrop: { width: '100%' },
  logo: {
    position: 'absolute',
  },
  slack: {
    transform: `translate(-200%, -200%)`,
  },
  google: {
    transform: 'translate(150%, -200%)',
  },
  datto: {
    transform: 'translate(300%, -150%)',
  },
  kaseya: {
    transform: 'translate(200%, 220%)',
  },
  teams: {
    transform: 'translate(30%, 330%)',
  },
  cw: {
    transform: 'translate(-170%, 280%)',
  },
}));

export default useStyles;
