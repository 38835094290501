import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { localCompanies } from '@constants/appRoutes';
import postData from '@services/api/common/postData';

export const getLocalCompanies = () =>
  getData(`${appConfig.baseUrl}${localCompanies}`);

export const postLocaleCompanies = data =>
  postData(`${appConfig.baseUrl}${localCompanies}`, data);

export const getLocalCrmList = () =>
  getData(`${appConfig.baseUrl}${localCompanies}crm-list`);

export const getLocalCompaniesCrmList = () =>
  getData(`${appConfig.baseUrl}${localCompanies}crm-companies-list`);

export const getIntegrationCompaniesNames = (id, type) =>
  getData(`${appConfig.baseUrl}/Integrations/${type}/${id}/getOrgNames`);

export const getDataViewData = id =>
  getData(`${appConfig.baseUrl}/UserData/${id}/uniqueIdentityData`);
