import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldTransformerRecord from '@components/Parsers/components/Create/components/FieldTransformer/FieldTransformerRecord';
import ReusableButton from '@common/Button/Button';
import TestBox from '@common/TestBox/TestBox';
import Input from '@components/Auth/Common/Input';
import Accordion from '@components/Parsers/components/Create/components/ParserRootComponent/component/Accordion';
import IncidentForm from '@components/Parsers/components/Create/components/ParserRootComponent/component/IncidentForm';
import InfoBox from '@common/InfoBox/InfoBox';
import useStyles from '../styles';
import { getTypes, test } from '../helpers';

const DisplayFieldTransformers = ({
  transformersData,
  hookData,
  nameControl,
  setNameControl,
  onSubmit,
  editMode = false,
  incidentControl,
  setIncidentControl,
}) => {
  const classes = useStyles();
  const [transformers, setTransformers] = useState([]);
  const [types, setTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [testData, setTestData] = useState({});

  useEffect(() => {
    getTypes()
      .then(response => setTypes(response))
      .catch(() => setTypes([]));
    setTransformers(transformersData);
  }, [transformersData]);

  const onChange = useCallback(
    item => {
      const newTransformers = [...transformers];
      const idx = newTransformers.findIndex(i => i.id === item.id);
      newTransformers[idx] = item;
      setTransformers(newTransformers);
    },
    [transformers],
  );

  const getDataEntities = () => {
    return transformers
      .filter(t => t.checked)
      .map(t => {
        return {
          name: t.name,
          path: t.path,
          identifier: t.identifier,
          type: t.type,
        };
      });
  };

  const getPayload = () => {
    return {
      hookData,
      fieldTransformer: JSON.stringify(getDataEntities()),
      name: nameControl.name,
    };
  };

  const testHandler = () => {
    test(getPayload(), editMode)
      .then(d => {
        setTestData(d);
        setOpen(true);
      })
      .catch(e => console.log(e.message));
  };

  const onNameChange = value =>
    setNameControl({ name: value, touched: true, error: undefined });

  return (
    <div className={classes.textBorder}>
      <div style={{ display: 'block', paddingTop: '5px' }}>
        <Accordion
          details={
            <IncidentForm
              values={incidentControl}
              setValues={setIncidentControl}
              useSubject={false}
              labelSize={3}
              contentSize={9}
            />
          }
        >
          <span className={classes.heading}>Incident</span>
          <span className={classes.secondaryHeading}>
            Create Incident
            <InfoBox text="Create incident after message parsing" />
          </span>
        </Accordion>
        <div className={classes.topMargin}>
          <Input
            label="Integration Name"
            input={{
              value: nameControl.name,
              onChange: e => onNameChange(e.target.value),
            }}
            meta={{
              touched: nameControl.touched,
              error: nameControl.error,
            }}
          />
        </div>
        <ReusableButton
          size="md"
          viewType="black"
          label="Submit"
          onClick={() => onSubmit(getPayload())}
          classNameWrapper={classes.buttonMargin}
        />
        <ReusableButton size="md" label="Test" onClick={testHandler} />
      </div>
      {transformers.map(t => (
        <FieldTransformerRecord
          key={t.id}
          item={t}
          typesOptions={types}
          onChange={onChange}
        />
      ))}
      <TestBox
        open={open}
        onClose={() => setOpen(false)}
        data={testData}
        loading={false}
        displayParsedData={false}
      />
    </div>
  );
};

DisplayFieldTransformers.propTypes = {
  transformersData: PropTypes.objectOf(PropTypes.any),
  hookData: PropTypes.string,
  nameControl: PropTypes.objectOf(PropTypes.any),
  setNameControl: PropTypes.func,
  onSubmit: PropTypes.func,
  editMode: PropTypes.bool,
};

export default DisplayFieldTransformers;
