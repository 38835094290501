const validator = schema => async values => {
  try {
    await schema.validate(values, { abortEarly: false });

    return null;
  } catch (e) {
    return e.inner.reduce(
      (errors, err) => ({
        ...errors,
        [err.path]: err.message,
      }),
      {},
    );
  }
};

export default validator;
