import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TextMask from '@components/Auth/Common/creditCardInput';
import Input from '@components/Auth/Common/Input';

const CreditCardForm = ({ loading }) => {
  return (
    <div
      style={{
        margin: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Field
        id="cardHolder"
        name="cardHolder"
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        placeholder="Name of credit card"
        disabled={loading}
      />
      <Field
        id="cardNumber"
        name="cardNumber"
        inputView="text"
        styleType="main"
        fullWidth
        component={Input}
        InputProps={{ inputComponent: TextMask }}
        placeholder="Credit card number"
        disabled={loading}
      />
      <div style={{ display: 'flex' }}>
        <Field
          id="cardExpMonth"
          name="cardExpMonth"
          styleType="main"
          inputView="text"
          fullWidth
          component={Input}
          placeholder="Month"
          disabled={loading}
        />
        <Field
          id="cardExpYear"
          name="cardExpYear"
          styleType="main"
          inputView="text"
          fullWidth
          component={Input}
          placeholder="Year"
          disabled={loading}
        />
        <Field
          id="cardCvc"
          name="cardCvc"
          styleType="main"
          inputView="text"
          fullWidth
          component={Input}
          placeholder="CVV"
          disabled={loading}
        />
      </div>
      <Field
        id="billingEmail"
        name="billingEmail"
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        placeholder="Billing email"
        disabled={loading}
      />
    </div>
  );
};

CreditCardForm.propTypes = {
  loading: PropTypes.objectOf(PropTypes.any),
};

export default CreditCardForm;
