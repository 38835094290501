import React from 'react';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import PropTypes from 'prop-types';
import Actions from '@ui/pages/technicianVerification/AssignTicketToTechRequest/components/Actions';

const ConnectWiseTicketSelector = ({ psaDefaultId, psaId, itemId }) => {
  return (
    <Table
      resource={`/TicketUpdate/connectWise/${psaDefaultId}/tickets`}
      columns={[
        {
          name: 'ID',
          key: 'id',
          sortable: true,
          searchable: true,
          width: 80,
        },
        { name: 'Summary', key: 'summary', searchable: true },
        { name: 'Board', key: 'board', sortable: true },
        { name: 'Status', key: 'status', sortable: true },
        { name: 'Contact', key: 'contact', searchable: true },
        {
          name: 'Actions',
          key: TableTypes.actions,
          type: TableTypes.actions,
          width: 120,
          minWidth: 120,
          labelAlignment: 'center',
          accessor: item => (
            <Actions item={item} psaId={psaId} itemId={itemId} />
          ),
        },
      ]}
    />
  );
};

ConnectWiseTicketSelector.propTypes = {
  psaDefaultId: PropTypes.number,
  psaId: PropTypes.number,
  itemId: PropTypes.number,
};

export default ConnectWiseTicketSelector;
