import { validateRequired } from '@common/functions/validators';
import { integrationSources } from '@constants/integrationSources';

const MirakivalidateRequired = values => ({
  name: validateRequired(values.name),
  apiKey: validateRequired(values.apiKey),
});

const SophosValidateRequired = values => ({
  name: validateRequired(values.name),
  publicKey: validateRequired(values.publicKey),
  privateKey: validateRequired(values.privateKey),
});

const getValidation = (values, type) =>
  ({
    [integrationSources.Meraki.name]: MirakivalidateRequired(values),
    [integrationSources.Sophos.name]: SophosValidateRequired(values),
  }[type] || null);

export default getValidation;
