import React from 'react';
import PropTypes from 'prop-types';
import { checkedIntegrationOrganizations } from '@components/DataSources/helpers';
import cx from 'classnames';
import { Checkbox } from '@material-ui/core';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import alertsIcon from '@assets/icons/dangerTriangle.svg';
import useStyles from './styles';

const TenantItem = ({
  integrationId,
  tenant,
  tenantIntegrations,
  handleClick,
  handleGetHookAlerts,
  activeTenantId,
  updateIntegrationTenant,
}) => {
  const classes = useStyles();
  const readOnly = tenant?.status !== 'active';

  const isChecked = Boolean(
    tenantIntegrations.find(
      item =>
        item.organizationId === tenant.id &&
        item.integrationId === +integrationId,
    ),
  );

  const integrationHandler = event => {
    event.stopPropagation();
    checkedIntegrationOrganizations(!isChecked, {
      integrationId,
      organizationId: tenant.id,
      apiHost: tenant.apiHost,
    }).then(() => updateIntegrationTenant());
  };

  const trimName = (value, len) =>
    value.length > len ? `${value.substr(0, len)}...` : value;

  return (
    <button
      type="button"
      key={tenant.id}
      className={cx(
        classes.baseButton,
        classes.tenantButton,
        tenant.id === activeTenantId && classes.activeTenantButton,
      )}
      disabled={readOnly}
      onClick={() => handleClick(tenant.id, tenant.apiHost)}
    >
      {!readOnly && (
        <div onClick={e => e.stopPropagation()} role="presentation">
          <Checkbox
            color="primary"
            style={{ color: '#FF8A71' }}
            checked={isChecked}
            onChange={event => integrationHandler(event)}
          />
        </div>
      )}
      <span>{trimName(tenant.name, 30)}</span>
      {!readOnly && (
        <div>
          <ActionButton
            icon={<img src={alertsIcon} alt="alertsIcon" />}
            handler={event =>
              handleGetHookAlerts(tenant.id, tenant.apiHost, event)
            }
            toolTip="Alerts"
          />
        </div>
      )}
    </button>
  );
};

TenantItem.propTypes = {
  tenant: PropTypes.objectOf(PropTypes.any).isRequired,
  integrationId: PropTypes.string,
  tenantIntegrations: PropTypes.arrayOf(PropTypes.any),
  handleClick: PropTypes.func,
  activeTenantId: PropTypes.string,
  updateIntegrationTenant: PropTypes.func,
  handleGetHookAlerts: PropTypes.func,
};

export default TenantItem;
