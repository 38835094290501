import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@common/Checkbox/FormControlCheckBox';
import useStyles from '../styles';

const GroupOptInDialogItem = React.memo(
  ({
    contactId,
    contactName,
    phone,
    email,
    sendSms = false,
    toggleSendSms,
    sendEmail = true,
    toggleSendEmail,
  }) => {
    const classes = useStyles();
    return (
      <div className={classes.optInDialogItem}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {contactName}
        </div>

        <div>
          <Checkbox
            checked={sendSms}
            onChange={() => toggleSendSms(contactId)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={!phone}
          />
          <span>{phone || 'No Phone'}</span>
        </div>

        <div>
          <Checkbox
            checked={sendEmail}
            onChange={() => toggleSendEmail(contactId)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={!email}
          />
          <span>{email || 'No Email'}</span>
        </div>
      </div>
    );
  },
);

GroupOptInDialogItem.propTypes = {
  contactId: PropTypes.number.isRequired,
  contactName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sendSms: PropTypes.bool.isRequired,
  toggleSendSms: PropTypes.func.isRequired,
  sendEmail: PropTypes.bool.isRequired,
  toggleSendEmail: PropTypes.func.isRequired,
};

export default GroupOptInDialogItem;
