import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { SelectCompanies, SelectIntegration } from './steps';
import useStyles from '../styles';

const steps = ['Select integration', 'Select items'];

const ImportCrmNamesWizard = ({ onSelect, open, setOpen }) => {
  const classes = useStyles();
  const [selectedIntegration, setSelectedIntegration] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (open) setActiveStep(0);
  }, [open]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSelectIntegration = id => {
    setSelectedIntegration(id);
    handleNext();
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <SelectIntegration onSelect={onSelectIntegration} />;
      case 1:
        return (
          <SelectCompanies
            integration={selectedIntegration}
            handleBack={handleBack}
            handleSubmit={onSelect}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={classes.wizardModalBox}>
        <div className={classes.stepperBody}>
          <Stepper
            activeStep={activeStep}
            classes={{ root: classes.stepperRoot }}
          >
            {steps.map(label => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {getStepContent(activeStep)}
        </div>
      </div>
    </Modal>
  );
};

ImportCrmNamesWizard.propTypes = {
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ImportCrmNamesWizard;
