import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloTicketTypes } from '@services/requests/haloTickets';

export const ThunkGetHaloTicketTypes = createAsyncThunk(
  `${HALO_TICKET}/getHaloTicketTypes`,
  async crmId => {
    const ticketTypes = await getHaloTicketTypes(crmId);
    return ticketTypes;
  },
);

export default {};
