import { useMemo } from 'react';
import { usePermissions } from 'react-admin';
import { basePermissions } from '@constants/permissions';

const useAllowUpdate = () => {
  const { permissions } = usePermissions();
  return useMemo(
    () =>
      permissions && permissions.notificationSettings[basePermissions.update],
    [permissions],
  );
};

export default useAllowUpdate;
