import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketLocations } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketLocations = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicket`,
  async crmId => {
    const locations = await getConnectWiseTicketLocations(crmId);
    return locations;
  },
);

export default {};
