import { createAsyncThunk } from '@reduxjs/toolkit';
import { editTeamsConnection } from '@services/requests/teamsConnections';
import { TEAMS_CONNECTIONS } from '@store/slices/resources';

export const ThunkEditTeamsConnections = createAsyncThunk(
  `${TEAMS_CONNECTIONS}/editTeamsConnection`,
  async ({ id, payload }) => {
    await editTeamsConnection(id, payload);
    return id;
  },
);

export default {};
