import React from 'react';
import { usePermissions, useResourceContext } from 'react-admin';

const Authorize = props => {
  const {
    requiredPermissions,
    children,
    hasShow,
    hasEdit,
    hasList,
    hasCreate,
    customRestrictions,
    customResource,
    ...restProps
  } = props;
  const { permissions } = usePermissions();
  const resource = useResourceContext({}) || customResource;
  if (!permissions || !permissions[resource]) {
    return null;
  }
  return requiredPermissions.some(x => permissions[resource][x]) &&
    !customRestrictions
    ? React.cloneElement(children, { ...restProps })
    : null;
};

export default Authorize;
