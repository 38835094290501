export const detailsBody = Object.freeze([
  {
    label: 'Source Name',
    value: 'sourceName',
    link: 'triggerId',
  },
  {
    label: 'Source Type',
    value: 'sourceType',
  },
  {
    label: 'Status',
    value: 'incidentStatus',
    color: 'incidentStatusColor',
  },
  {
    label: 'Date Triggered',
    value: 'dateTriggered',
  },
  {
    label: 'Assign To',
    value: 'assigneeName',
  },
  {
    label: 'Priority',
    value: 'priorityLabel',
    color: 'priorityColor',
  },
  {
    label: 'Tags',
    value: 'tags',
  },
  {
    label: 'Email Subject',
    value: 'emailSubject',
  },
]);

export default {};
