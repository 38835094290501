import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import statusColors from '@components/DataSources/Dashboards/statusColors';
import DeviceStatusesGrid from '@components/DataSources/Integrations/pages/Meraki/DeviceStatusesGrid';

import BagelChart from '@common/Bagels/BagelChart';
import Loading from '@common/Loading/Loading';

import { getMerakiDeviceStatusesTotalCount } from '../../helpers';

import useStyles from './styles';

const items = {
  online: { title: 'ONLINE', color: statusColors.online },
  offline: {
    title: 'OFFLINE',
    color: statusColors.offline,
  },
  alerting: {
    title: 'ALERTING',
    color: statusColors.alerting,
  },
  dormant: {
    title: 'DORMANT',
    color: statusColors.dormant,
  },
};

const MerakiDashboard = ({ match }) => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const deviceStatusesTotalCount = useMemo(
    () =>
      data.reduce((acc, element) => {
        // eslint-disable-next-line no-param-reassign
        acc += element.count;
        return acc;
      }, 0),
    [data],
  );

  useEffect(() => {
    setLoading(true);
    getMerakiDeviceStatusesTotalCount(match.params.id)
      .then(res => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match.params.id]);

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.container}>
      <Grid container justifyContent="space-around">
        {data.map(item => (
          <Grid item xs={3} key={item.name} className={classes.chartContainer}>
            <BagelChart
              allCount={deviceStatusesTotalCount}
              title={item.name}
              color={items[item.name.toLowerCase()]?.color}
              circleColor="rgb(195 195 195)"
              textColor="black"
              count={item.count}
              useLineSeparator={false}
              customHandler={() => null}
            />
          </Grid>
        ))}
      </Grid>

      <DeviceStatusesGrid
        resource={`/Integrations/Meraki/V2/${match.params.id}/deviceStatuses`}
      />
    </div>
  );
};

MerakiDashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default MerakiDashboard;
