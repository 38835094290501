import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../styles';

const InfoBox = ({ title, text, error }) => {
  const classes = useStyles();

  return (
    <div className={classes.font}>
      <div className={classes.bold}>{title}</div>
      <div>{text}</div>
      <pre>
        <code>{error}</code>
      </pre>
    </div>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default InfoBox;
