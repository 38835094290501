import React from 'react';
import PropTypes from 'prop-types';
import SelectableItem from './SelectableItem';
import useStyles from '../styles';

const ItemsBlock = ({ names, selectedNames, onCheckBoxClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.overflow}>
      <div className={classes.checkboxesArea}>
        {names.map(i => (
          <SelectableItem
            key={i}
            name={i}
            list={selectedNames}
            onClick={onCheckBoxClick}
          />
        ))}
      </div>
    </div>
  );
};

ItemsBlock.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  selectedNames: PropTypes.arrayOf(PropTypes.string),
  onCheckBoxClick: PropTypes.func.isRequired,
};

export default ItemsBlock;
