import { createAsyncThunk } from '@reduxjs/toolkit';
import { hetHaloTeams } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetHaloTeams = createAsyncThunk(
  `${INTEGRATION}/halo/getTeams`,
  async id => {
    const res = await hetHaloTeams(id);
    return res;
  },
);

export default {};
