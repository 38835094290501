import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { createKaseyaTicketNote } from '@services/requests/kaseyaTicket';

export const ThunkCreateKaseyaTicketNote = createAsyncThunk(
  `${KASEYA_TICKET}/createKaseyaTicketNote`,
  ({ crmDefaultId, ticketId, payload }) =>
    createKaseyaTicketNote(crmDefaultId, ticketId, payload),
);

export default {};
