import { createSlice } from '@reduxjs/toolkit';
import { SECURE_DATA } from '../resources';

const secureDataSlice = createSlice({
  name: SECURE_DATA,
  reducers: {},
  initialState: {},
});

export default secureDataSlice.reducer;
