import { createAsyncThunk } from '@reduxjs/toolkit';
import { EMAIL_SETTINGS } from '@store/slices/resources';
import { saveEmailTemplatesSettings } from '@services/requests/emailSettings';

export const ThunkSaveEmailTemplatesSettings = createAsyncThunk(
  `${EMAIL_SETTINGS}/saveEmailTemplatesSettings`,
  ({ payload }) => saveEmailTemplatesSettings(payload),
);

export default {};
