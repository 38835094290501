import { createAsyncThunk } from '@reduxjs/toolkit';
import { OUTGOING_MAIL } from '@store/slices/resources';
import { axiosInstance } from '@components/Table/axios';

export const ThunkGetOutgoingMailboxUsers = createAsyncThunk(
  `${OUTGOING_MAIL}/getOutGoingMailboxUsers`,
  async () => {
    const { data, headers } = await axiosInstance.request({
      method: 'GET',
      url: '/OutboundMailbox/setup/users',
      params: {
        _start: 0,
        _end: 20,
      },
    });
    return { data, totalOptionsCount: +headers.get('x-total-count') };
  },
);

export default {};
