import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { Field } from 'react-final-form';

import InfoBox from '@ui/components/common/InfoBox/InfoBox';
import DropDown from '@components/Auth/Common/DropDown';

import useStyles from '../../styles';

const emptyFilterBox = '_';

const DataViewFormSelectors = ({
  form,
  loading,
  filterMailBoxesOptions,
  filterParsersOptions,
  filterWebHookOptions,
  genericSourcesOptions,
}) => {
  const classes = useStyles();

  const onChange = (e, form) => {
    switch (e.target.name) {
      case 'sourceWebHook':
        form.change('sourceMailBox', emptyFilterBox);
        form.change('sourceParserId', 0);
        form.change('sourceGeneric', 'All');
        break;
      case 'sourceMailBox':
        form.change('sourceWebHook', emptyFilterBox);
        form.change('sourceParserId', 0);
        form.change('sourceGeneric', 'All');
      case 'sourceParserId':
        form.change('sourceWebHook', emptyFilterBox);
        form.change('sourceGeneric', 'All');
        break;
      case 'sourceGeneric':
        form.change('sourceMailBox', emptyFilterBox);
        form.change('sourceParserId', 0);
        form.change('sourceWebHook', emptyFilterBox);
      default:
        break;
    }
  };

  return (
    <Grid item xs={12} sm={12}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Selectors
          <InfoBox text="Allows to retrieve data from selected sources only" />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            item
            xs={12}
            sm={12}
            className={classes.row}
          >
            <Grid item xs={12} sm={12} md={3}>
              <span>Source MailBox</span>
              <Field
                id="sourceMailBox"
                name="sourceMailBox"
                component={({ input, ...rest }) => (
                  <DropDown
                    {...rest}
                    input={{
                      name: 'sourceMailBox',
                      value: input.value,
                      onChange: e => {
                        input.onChange(e);
                        onChange(e, form);
                      },
                    }}
                  />
                )}
                options={filterMailBoxesOptions}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <span>Source Parser</span>
              <Field
                id="sourceParserId"
                name="sourceParserId"
                component={({ input, ...rest }) => (
                  <DropDown
                    {...rest}
                    input={{
                      name: 'sourceParserId',
                      value: input.value,
                      onChange: e => {
                        input.onChange(e);
                        onChange(e, form);
                      },
                    }}
                  />
                )}
                options={filterParsersOptions}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <span>Source WebHook</span>
              <Field
                id="sourceWebHook"
                name="sourceWebHook"
                component={({ input, ...rest }) => (
                  <DropDown
                    {...rest}
                    input={{
                      name: 'sourceWebHook',
                      value: input.value,
                      onChange: e => {
                        input.onChange(e);
                        onChange(e, form);
                      },
                    }}
                  />
                )}
                options={filterWebHookOptions}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <span>Generic Source</span>
              <Field
                id="sourceGeneric"
                name="sourceGeneric"
                component={({ input, ...rest }) => (
                  <DropDown
                    {...rest}
                    input={{
                      name: 'sourceGeneric',
                      value: input.value,
                      onChange: e => {
                        input.onChange(e);
                        onChange(e, form);
                      },
                    }}
                  />
                )}
                options={genericSourcesOptions.map(p => ({
                  value: p.info || 'All',
                  label: p.label,
                }))}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default DataViewFormSelectors;
