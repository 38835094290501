import React from 'react';
import { TopToolbar } from 'react-admin';
import ReusableButton from '@common/Button/Button';
import addIcon from '@assets/icons/add_blue.svg';
import PropTypes from 'prop-types';
import MakeCopyDialog from '@components/Ticketing/Notifications/TicketSettings/components/dialogs/MakeCopyDialog';
import useStyles from './styles';

const ListActions = ({ onCreate, size = 'md', genericButton }) => {
  const classes = useStyles();
  const { name, icon, handler } = genericButton || {};
  return (
    <TopToolbar classes={{ root: classes.root }} className={classes.toolBar}>
      {onCreate && (
        <ReusableButton
          size={size}
          onClick={onCreate}
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={addIcon} alt="add" />
            <span>Add New</span>
          </>
        </ReusableButton>
      )}
      {genericButton && (
        <ReusableButton
          size={size}
          onClick={handler}
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={icon} alt={name} />
            <span>{name}</span>
          </>
        </ReusableButton>
      )}
    </TopToolbar>
  );
};

MakeCopyDialog.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default ListActions;
