import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT } from '@store/slices/resources';
import { updateTenantCountry } from '@services/requests/tenant';

export const ThunkUpdateTenantCountry = createAsyncThunk(
  `${TENANT}/updateTenantCountry`,
  async code => {
    const response = await updateTenantCountry(code);
    return response;
  },
);

export default {};
