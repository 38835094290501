import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  formStyle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
  },
  sendButtonStyle: {
    marginLeft: '1rem',
  },
  closeButton: {
    justifyContent: 'center',
  },
  expirationMessage: {
    color: 'red',
  },
  textBlock: {
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  formContainer: {
    flexWrap: 'nowrap',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
  },
  checkboxContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  contactButton: {
    display: 'flex',
  },
  form: {
    height: 'unset !important',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  warningText: {
    fontFamily: 'monospace',
  },
  disabledTextBlock: {
    color: 'grey',
    cursor: 'not-allowed',
  },
  loadingWrapper: {
    width: '535px',
    height: '475px',
  },
}));

export default useStyles;
