import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@constants/routes';
import { getSyncroContactData } from '@services/requests/psaPods';

export const ThunkGetSyncroContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getSyncroContactData`,
  async ({ crmId, contactId }) => getSyncroContactData(crmId, contactId),
);

export default {};
