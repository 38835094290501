export const transformToDataGrid = ({ row, col, sizeX, sizeY }) => ({
  x: col,
  y: row,
  w: sizeX,
  h: sizeY,
});

export const transformFromDataGrid = ({ x, y, w, h, i }) => ({
  row: y,
  col: x,
  sizeX: w,
  sizeY: h,
  id: Number(i),
});
