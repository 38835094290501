import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkUpdateCrmUserPhoneNumber } from '@store/slices/crm/thunks';
import { phoneNumberFormat } from '@constants/phoneNumber';
import phoneValidation from '@constants/phoneValidation';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import ReusableButton from '@ui/components/common/Button/Button';
import FormattedInputs from '@ui/components/common/MaskedInput';

import useStyles from '../styles';

const EditUserInfoDialog = ({
  open,
  setOpen,
  userInfo,
  crmDefaultId,
  handleUserInfoChange,
  crmCompanyName,
}) => {
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const submit = async values => {
    setProcessing(true);
    const payload = {
      userId: userInfo.value,
      userPhone: values.userPhone
        .split(' ')
        .join('')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .split('-')
        .join(''),
      userCompany: crmCompanyName,
      userName: userInfo.label,
    };

    await dispatch(ThunkUpdateCrmUserPhoneNumber({ crmDefaultId, payload }))
      .unwrap()
      .then(res => {
        handleUserInfoChange(res, crmDefaultId);
        setOpen(false);
      });
    setProcessing(false);
  };

  const validation = values => {
    return {
      userPhone: phoneValidation(values.userPhone),
    };
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Edit User Information</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={{ userPhone: userInfo ? userInfo.phone : '' }}
          validate={validation}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Typography variant="h6">
                {`${userInfo.label} ${
                  crmCompanyName ? `(${crmCompanyName})` : ''
                }`}
              </Typography>
              <FieldWrapper
                label="User Phone"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="userPhone"
                    id="userPhone"
                    render={FormattedInputs}
                    disabled={processing}
                    mask={phoneNumberFormat}
                  />
                }
              />

              <div className={classes.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditUserInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    value: PropTypes.number,
    company: PropTypes.string,
    phone: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  crmDefaultId: PropTypes.number.isRequired,
  handleUserInfoChange: PropTypes.func.isRequired,
  crmCompanyName: PropTypes.string.isRequired,
};

export default EditUserInfoDialog;
