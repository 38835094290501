import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CONVERSATIONS } from '@store/slices/resources';
import { getMessagingConversations } from '@services/requests/smsConversations';

export const ThunkGetConversationTemplates = createAsyncThunk(
  `${SMS_CONVERSATIONS}/getConversationTemplates`,
  async (channelId = null) => {
    const conversations = await getMessagingConversations(channelId);
    return conversations;
  },
);

export default {};
