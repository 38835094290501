import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { assignKaseyaTicketAssignee } from '@services/requests/kaseyaTicket';

export const ThunkAssignKaseyaTicketAssignee = createAsyncThunk(
  `${KASEYA_TICKET}/assignKaseyaTicketAssignee`,
  ({ crmDefaultId, ticketId, assigneeId }) =>
    assignKaseyaTicketAssignee(crmDefaultId, ticketId, assigneeId),
);

export default {};
