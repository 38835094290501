import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import useAsync from '@services/api/common/useAsync';
import {
  getCwOptions,
  getTypeOptions,
} from '@components/WebHooks/components/dialogs/helpers';
import DropDown from '@components/Auth/Common/DropDown';
import { deleteCwHooks } from '@components/DataSources/helpers';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import { useDispatch } from 'react-redux';
import { actions } from '@store/actions';
import useStyles from './styles';

const ConnectWiseHooks = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [dropDawnOptions, setDropDawnOptions] = useState({
    typesOptions: [],
    levelsOptions: {},
  });
  const [typeFilterValue, setTypeFilterValue] = useState('');
  const [selectedAtIntegration, setSelectedAtIntegration] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [deleteProcess, setDeleteProcess] = useState(false);
  useAsync(getCwOptions, setOptions);

  const onMount = useCallback(() => {
    getTypeOptions().then(res => {
      setDropDawnOptions(
        res.reduce(
          (acc, option) => {
            acc.typesOptions.push({ label: option.type, value: option.type });
            // eslint-disable-next-line no-param-reassign
            acc.levelsOptions[option.type] = option.levels.map(level => ({
              label: level,
              value: level,
            }));
            return acc;
          },
          { typesOptions: [], levelsOptions: {} },
        ),
      );
    });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onDelete = item => {
    setIdForDelete(item.id);
    setDisplayDeleteDialog(true);
  };

  const handleDelete = () => {
    setDeleteProcess(true);
    deleteCwHooks(selectedAtIntegration, idForDelete)
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
        setIdForDelete(null);
        setDisplayDeleteDialog(false);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setDeleteProcess(false));
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'description',
        sortable: true,
        searchable: true,
        minWidth: 100,
      },
      {
        name: 'Type',
        key: 'type',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: dropDawnOptions.typesOptions,
        filterCallBack: (value, filtering, setFilteringObject) => {
          setTypeFilterValue(value);
          const { level, ...filters } = filtering;
          setFilteringObject(filters, 'type', value);
        },
        filterByAutocomplete: 'type',
        minWidth: 100,
      },
      {
        name: 'Level',
        key: 'level',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: dropDawnOptions.levelsOptions[typeFilterValue] || [],
        filterByAutocomplete: 'level',
        minWidth: 100,
      },
      {
        name: 'URL',
        key: 'url',
        sortable: true,
        searchable: false,
      },
      {
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 75,
        minWidth: 75,
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            onDelete={onDelete}
            fieldKey="link"
          />
        ),
      },
    ],
    [dropDawnOptions, typeFilterValue],
  );

  return (
    <div className={classes.container}>
      <DropDown
        options={options}
        classNameWrapper={classes.selectWrapper}
        input={{
          value: selectedAtIntegration,
          onChange: e => setSelectedAtIntegration(e.target.value),
        }}
      />
      {selectedAtIntegration && (
        <Table
          resource={`/Integrations/ConnectWise/${selectedAtIntegration}/getHooks`}
          columns={columns}
          defaultSort={{ fieldName: 'description', order: 'ASC' }}
        />
      )}
      <ConfirmDialog
        open={displayDeleteDialog}
        title="Delete from ConnectWise"
        content="This operation will remove ConnectWise web hook. It cannot be undone"
        onAccept={handleDelete}
        setOpen={() => setDisplayDeleteDialog(false)}
        loading={deleteProcess}
      />
    </div>
  );
};

export default ConnectWiseHooks;
