import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA_SETTINGS } from '@store/slices/resources';
import { getSecureDataSettings } from '@services/requests/secureDataSettings';

export const ThunkGetSecureDataSettings = createAsyncThunk(
  `${SECURE_DATA_SETTINGS}/getSecureDataSettings`,
  async () => getSecureDataSettings(),
);

export default {};
