import { makeRequest } from '../makeRequest';
import {
  INCIDENTS_NOTES,
  CRM_TICKETS,
  INCIDENTS,
  INCIDENT_TIME_ENTRY,
} from '../requestResources';

export const getIncident = id => makeRequest('GET', `${INCIDENTS}/${id}`);

export const resolveIncident = id =>
  makeRequest('PUT', `${INCIDENTS}/resolve?id=${id}`);

export const reOpenIncident = id =>
  makeRequest('PUT', `${INCIDENTS}/reopen?id=${id}`);

export const addAssigneeForIncident = (assigneeId, incidentId) =>
  makeRequest(
    'PUT',
    `${INCIDENTS}/addAssignee?assigneeId=${assigneeId}&incidentId=${incidentId}`,
  );

export const getIncidentNotes = incidentId =>
  makeRequest('GET', `${INCIDENTS_NOTES}?incidentId=${incidentId}`);

export const addIncidentNote = data =>
  makeRequest('POST', `${INCIDENTS_NOTES}`, data);

export const editIncidentNote = (data, noteId) =>
  makeRequest('PUT', `${INCIDENTS_NOTES}/${noteId}`, data);

export const deleteIncidentNote = noteId =>
  makeRequest('DELETE', `${INCIDENTS_NOTES}/${noteId}`);

export const getIncidentTickets = incidentId =>
  makeRequest('GET', `${CRM_TICKETS}/getTicketsByIncidentId?id=${incidentId}`);

export const getIncidentTimeEntries = incidentId =>
  makeRequest('GET', `${INCIDENT_TIME_ENTRY}/${incidentId}`);

export const addIncidentTimeEntry = payload =>
  makeRequest('POST', `${INCIDENT_TIME_ENTRY}`, payload);

export default {};
