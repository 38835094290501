import { actionTypes } from '../actions';

const defaultState = {
  plan: {},
};

const pricePlanReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PRICE_PLAN:
      return { plan: action.payload };
    default:
      return state;
  }
};

export default pricePlanReducer;
