import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ThunkCheckSubscriptionDiscount,
  ThunkGetBaseTechCount,
  ThunkGetBillingAddress,
  ThunkGetBillingEmail,
  ThunkGetLastInvoices,
  ThunkGetMyPlan,
  ThunkGetPricePlans,
  ThunkGetTenantTechCount,
} from '@store/slices/billing/thunks';

import Loading from '@common/Loading/Loading';
import BillingActions from '@ui/pages/billing/Billing/components/BillingActions';
import SubscriptionDetails from '@ui/pages/billing/Billing/components/SubscriptionDetails';
import InvoicesList from '@ui/pages/billing/Billing/components/InvoicesList';

import useStyles from './styles';

const Billing = () => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      dispatch(ThunkGetMyPlan()).unwrap(),
      dispatch(ThunkGetPricePlans()).unwrap(),
      dispatch(ThunkGetTenantTechCount()).unwrap(),
      dispatch(ThunkGetLastInvoices()).unwrap(),
      dispatch(ThunkGetBaseTechCount()).unwrap(),
      dispatch(ThunkGetBillingEmail()).unwrap(),
      dispatch(ThunkGetBillingAddress()).unwrap(),
    ])
      .then(res => {
        if (!res[0].isDemoSubscription) {
          dispatch(ThunkCheckSubscriptionDiscount()).unwrap();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount().then(() => {
      setLoading(false);
    });
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <BillingActions />
        <SubscriptionDetails />

        {/* <Usage /> */}
      </div>

      <InvoicesList />
    </div>
  );
};

export default Billing;
