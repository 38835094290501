import { createSlice } from '@reduxjs/toolkit';
import { TEAMS_CONNECTIONS } from '../resources';

const teamsConnectionsSlice = createSlice({
  name: TEAMS_CONNECTIONS,
  reducers: {},
  initialState: {},
});

export default teamsConnectionsSlice.reducer;
