export const getThresholdAndSize = value => {
  if (value > 0 && value < 1023) {
    return { threshold: value, size: 0 };
  }

  if (value > 1023 && value < 1048575) {
    const newValue = Math.floor(value / 1024);
    return { threshold: newValue, size: 1 };
  }

  if (value > 1048575 && value < 1073741823) {
    const newValue = Math.floor(value / 1048576);
    return { threshold: newValue, size: 2 };
  }

  if (value > 1073741823) {
    const newValue = Math.floor(value / 1073741823);
    return { threshold: newValue, size: 3 };
  }

  return { threshold: 0, size: 0 };
};

export const getThreshold = (value, size) => {
  switch (size) {
    case 0:
      return value;
    case 1:
      return value * 1024;
    case 2:
      return value * 1048576;
    case 3:
      return value * 1073741824;
    default:
      return value;
  }
};
