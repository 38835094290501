import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import CheckBoxArraySelectorItem from './CheckBoxArraySelectorItem';

const ArrayCheckBoxesSelector = ({
  input: { value, onChange },
  itemsList,
  disabled,
}) => {
  const isChecked = useCallback(val => value.includes(val), [value]);

  const onItemClick = itemValue => {
    if (value.includes(itemValue)) {
      onChange(value.filter(v => v !== itemValue));
    } else {
      onChange([...value, itemValue]);
    }
  };

  return (
    <Grid container>
      {itemsList.map(v => (
        <CheckBoxArraySelectorItem
          key={v.value}
          value={v}
          checked={isChecked(v.value)}
          onClick={onItemClick}
          disabled={disabled}
        />
      ))}
    </Grid>
  );
};

ArrayCheckBoxesSelector.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
  }).isRequired,
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
};

export default ArrayCheckBoxesSelector;
