import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import useStyles from '../styles';

const InputWithTemplate = ({
  name,
  disabled,
  form,
  templateVariables,
  overrideValue,
  multiline = false,
  variant,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  return (
    <div className={classes.ticketSubject}>
      <ActionButton
        handler={event => setAnchorEl(event.currentTarget)}
        icon={<img src={iconPlus} alt="iconPlus" />}
        toolTip="Add template variable"
        disabled={disabled}
      />
      <Field
        id={name}
        name={name}
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        disabled={disabled}
        multiline={multiline}
        variant={variant}
        rows={4}
      />
      <TemplateMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        form={form}
        name={name}
        templateVariables={templateVariables}
        overrideValue={overrideValue}
      />
    </div>
  );
};

InputWithTemplate.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  templateVariables: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  overrideValue: PropTypes.bool,
  multiline: PropTypes.bool,
};

export default InputWithTemplate;
