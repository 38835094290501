/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CHATS_BOTS } from '@store/slices/resources';
import {
  ThunkCreateChatBot,
  ThunkDeleteChatBot,
  ThunkGetChatBotOptions,
  ThunkGetChatBots,
  ThunkUpdateChatBot,
} from '@store/slices/chatBots/thunks';

const chatBotsSlice = createSlice({
  name: CHATS_BOTS,
  initialState: {
    chatBots: [],
    chatBotOptions: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetChatBots.fulfilled, (state, { payload }) => {
      state.chatBots = payload;
    });

    builder.addCase(ThunkCreateChatBot.fulfilled, (state, { payload }) => {
      state.chatBots.push(payload);
    });

    builder.addCase(ThunkUpdateChatBot.fulfilled, (state, { payload }) => {
      state.chatBots = state.chatBots.map(i =>
        i.id === payload.id ? payload : i,
      );
    });

    builder.addCase(ThunkDeleteChatBot.fulfilled, (state, { payload }) => {
      state.chatBots = state.chatBots.filter(i => i.id !== payload.id);
    });

    builder.addCase(ThunkGetChatBotOptions.fulfilled, (state, { payload }) => {
      state.chatBotOptions = payload;
    });
  },
});

export default chatBotsSlice.reducer;

export const ChatBotsSelector = state => state[CHATS_BOTS].chatBots;
export const ChatBotOptionsSelector = state => state[CHATS_BOTS].chatBotOptions;
