import React from 'react';
import PropTypes from 'prop-types';
import SwitchComponent from '@common/FilterInputs/Switch';
import DropDown from '@components/Auth/Common/DropDown';
import {
  periodType,
  triggerOptions,
  triggerPriorities,
} from '@components/Triggers/components/helpers';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import useStyles from '../styles';

const IncidentForm = ({
  values,
  setValues,
  useSubject = true,
  labelSize = 2,
  contentSize = 10,
}) => {
  const classes = useStyles();

  const onChange = event => {
    const newValues = { ...values };
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    newValues[event.target.name] = value;
    setValues(newValues);
  };

  return (
    <div style={{ width: '100%' }}>
      <FieldWrapper
        label="Enable"
        labelSize={labelSize}
        contentSize={contentSize}
        content={
          <SwitchComponent
            name="createIncident"
            checked={values.createIncident}
            onChange={onChange}
            color="primary"
            disabled={false}
          />
        }
      />
      <FieldWrapper
        label="Priority"
        labelSize={labelSize}
        contentSize={contentSize}
        content={
          <DropDown
            options={triggerPriorities}
            input={{
              value: values.priority,
              onChange: event => {
                event.target.name = 'priority';
                onChange(event);
              },
            }}
          />
        }
      />
      {useSubject && (
        <FieldWrapper
          label=""
          labelSize={labelSize}
          contentSize={contentSize}
          content={
            <div>
              <SwitchComponent
                name="createIfSubjectIsUnique"
                checked={values.createIfSubjectIsUnique}
                onChange={onChange}
                color="primary"
                disabled={!useSubject}
              />
              <span>Create New Incident if Email subject is Unique</span>
            </div>
          }
        />
      )}
      <FieldWrapper
        label="Creation rule"
        labelSize={labelSize}
        contentSize={contentSize}
        content={
          <div className={classes.flex}>
            <DropDown
              options={triggerOptions}
              input={{
                value: values.triggerRule,
                onChange: event => {
                  event.target.name = 'triggerRule';
                  onChange(event);
                },
              }}
            />
            {values.triggerRule === triggerOptions[2].value && (
              <>
                <Input
                  name="periodSize"
                  type="number"
                  input={{
                    value: values.periodSize,
                    onChange,
                  }}
                  meta={{
                    touched: false,
                    error: undefined,
                  }}
                />
                <DropDown
                  options={periodType}
                  input={{
                    value: values.periodType,
                    onChange: event => {
                      event.target.name = 'periodType';
                      onChange(event);
                    },
                  }}
                />
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

IncidentForm.propTypes = {
  values: PropTypes.shape({
    createIncident: PropTypes.bool,
    priority: PropTypes.number,
    createIfSubjectIsUnique: PropTypes.bool,
    triggerRule: PropTypes.number,
    periodSize: PropTypes.number,
    periodType: PropTypes.number,
    tags: PropTypes.string,
    useSubject: PropTypes.bool,
    labelSize: PropTypes.number,
    contentSize: PropTypes.number,
  }),
};

export default IncidentForm;
