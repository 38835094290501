import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'none',
    justifyContent: 'space-around',
    marginTop: '4rem',
  },
  fieldsContainer: {
    width: '100%',
  },
  button: {
    marginTop: '1rem',
  },
  secondButton: {
    marginTop: '1rem',
  },
  inputWrapper: {
    marginTop: '0.625rem',
    minWidth: '17rem',
  },
  errorTextArea: {
    textAlign: 'center',
    marginTop: '2rem',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '14px',
    minHeight: '55px',
  },
  errorCaption: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
}));

export default useStyles;
