import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useAsync from '@services/api/common/useAsync';
import { getAtOptions } from '@components/WebHooks/components/dialogs/helpers';
import DropDown from '@components/Auth/Common/DropDown';
import { tableKeys } from '@common/MuiVirtualizedTable/constants';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import { getAtHooks, deleteAtHooks } from '@components/DataSources/helpers';
import Loading from '@common/Loading/Loading';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useStyles from './styles';

const AutotaskHooks = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState([]);
  const [selectedAtIntegration, setSelectedAtIntegration] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteProcess, setDeleteProcess] = useState(false);
  const dataLoading = useAsync(getAtOptions, setOptions);

  useEffect(() => {
    if (selectedAtIntegration) {
      setLoading(true);
      getAtHooks(selectedAtIntegration)
        .then(d => setData(d))
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
        .finally(() => setLoading(false));
    }
  }, [selectedAtIntegration]);

  const onDelete = item => {
    setIdForDelete(item.id);
    setDisplayDeleteDialog(true);
  };

  const handleDelete = () => {
    setDeleteProcess(true);
    deleteAtHooks(selectedAtIntegration, idForDelete)
      .then(() => {
        const newData = data.filter(i => i.id !== idForDelete);
        setData(newData);
        setIdForDelete(null);
        setDisplayDeleteDialog(false);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setDeleteProcess(false));
  };

  const columns = [
    {
      key: 'name',
      name: <TableLabel item={{ name: 'Name', field: 'name' }} />,
    },
    {
      key: 'webhookGuid',
      name: <TableLabel item={{ name: 'Guid', field: 'webhookGuid' }} />,
    },
    {
      key: 'ownerName',
      name: <TableLabel item={{ name: 'Owner', field: 'ownerName' }} />,
    },
    {
      key: 'webhookUrl',
      name: <TableLabel item={{ name: 'URL', field: 'webhookUrl' }} />,
    },
    {
      key: 'actions',
      name: 'Actions',
      width: 150,
      accessor: rowData => (
        <ActionField
          label="Actions"
          record={rowData}
          onDelete={onDelete}
          fieldKey="link"
        />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <DropDown
        options={options}
        classNameWrapper={classes.selectWrapper}
        input={{
          value: selectedAtIntegration,
          onChange: e => setSelectedAtIntegration(e.target.value),
        }}
      />
      {loading || dataLoading ? (
        <Loading />
      ) : (
        <MuiVirtualizedListView
          tableKey={tableKeys.autotaskHooks}
          data={data}
          columns={columns}
          displaySearch={false}
        />
      )}
      <ConfirmDialog
        open={displayDeleteDialog}
        title="Delete from Autotask"
        content="This operation will remove Autotask web hook. It cannot be undone"
        onAccept={handleDelete}
        setOpen={() => setDisplayDeleteDialog(false)}
        loading={deleteProcess}
      />
    </div>
  );
};

export default AutotaskHooks;
