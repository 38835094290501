import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import useStyles from '../styles';

const Cell = ({ row, column }) => {
  const {
    accessor,
    align,
    cropCell,
    key,
    fontSize,
    isUpperCase,
    isPhoneNumber,
  } = column;
  const classes = useStyles();

  const alignClasses = {
    center: classes.alignCellCenter,
    left: classes.alignCellLeft,
    right: classes.alignCellRight,
  };

  const fontSizeClasses = {
    standard: classes.standard,
    small: classes.small,
  };

  const classNames = cx(
    classes.tableCell,
    alignClasses[align || 'center'],
    fontSizeClasses[fontSize || 'small'],
    {
      [classes.cropCell]: cropCell,
    },
    {
      [classes.upperCase]: isUpperCase,
    },
  );

  const accessorResult = accessor && accessor(row);

  const content = useMemo(() => {
    if (accessorResult) {
      return accessorResult;
    }

    let rowText = row[key];
    if (isPhoneNumber) {
      rowText = nationalFormatPhoneNumber(row[key]);
    }
    return rowText;
  }, [accessorResult, isPhoneNumber, key, row]);

  return <div className={classNames}>{content}</div>;
};

Cell.propTypes = {
  column: PropTypes.shape({
    accessor: PropTypes.func,
    align: PropTypes.string,
    key: PropTypes.string,
    fontSize: PropTypes.string,
    cropCell: PropTypes.bool,
    isUpperCase: PropTypes.bool,
    isPhoneNumber: PropTypes.bool,
  }),
  row: PropTypes.objectOf(PropTypes.any),
};

export default Cell;
