import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ThunkGetSuperOpsIntegrationTypes,
  ThunkGetSuperOpsIntegrationStatuses,
} from '@store/slices/integration/thunks';
import { Field } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';

const SuperOpsClientPortalForm = ({ crmId }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const [statuses, setStatuses] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);

  const onMount = useCallback(async () => {
    setProcessing(true);
    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetSuperOpsIntegrationStatuses({ crmId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetSuperOpsIntegrationTypes({ crmId })).unwrap()),
      ),
    ])
      .then(res => {
        setStatuses(res[0]);
        setTicketTypes(res[1]);
      })
      .finally(() => setProcessing(false));
  }, [dispatch, crmId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <FieldWrapper
        label="Open Ticket Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketOpenStatusId"
            name="default.TicketOpenStatusId"
            size="small"
            suppressInlineLabel
            labelName="label"
            valueName="value"
            component={DropDown}
            options={statuses}
            disabled={processing}
          />
        }
      />
      <FieldWrapper
        label="Closed Ticket Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketCloseStatusId"
            name="default.TicketCloseStatusId"
            size="small"
            suppressInlineLabel
            labelName="label"
            valueName="value"
            component={DropDown}
            options={statuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Message Received Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketOnMessageStatusId"
            name="default.TicketOnMessageStatusId"
            size="small"
            suppressInlineLabel
            labelName="label"
            valueName="value"
            component={DropDown}
            options={statuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Type"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketTypeId"
            name="default.TicketTypeId"
            size="small"
            suppressInlineLabel
            labelName="label"
            valueName="value"
            component={DropDown}
            options={ticketTypes}
            disabled={processing}
          />
        }
      />
    </>
  );
};

export default SuperOpsClientPortalForm;
