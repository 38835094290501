import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRICE_PLANS } from '@store/slices/resources';
import { getBaseTechnicianCount } from '@services/requests/billing';

export const ThunkGetBaseTechCount = createAsyncThunk(
  `${PRICE_PLANS}/getBaseTechCount`,
  () => getBaseTechnicianCount(),
);

export default {};
