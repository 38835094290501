import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingConfigurations } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingConfigurations = createAsyncThunk(
  `${MESSAGING}/getMessagingConfigurations`,
  async () => {
    const res = await getMessagingConfigurations();
    return res;
  },
);

export default {};
