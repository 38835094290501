import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  autocompleteInput: {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'wrap',

      fontSize: '0.875rem',
      borderRadius: '0.3125rem',
      border: '0.0625rem solid #E2E2E2',
      minHeight: '34px',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-1.25rem',
      marginLeft: '14px',
      marginRight: '14px',
    },
  },
  autocompleteChip: {
    '& .MuiChip-outlinedPrimary': {
      color: 'black',
      border: '0.0625rem solid #E2E2E2',
    },
  },
  input: {
    margin: 0,
    position: 'relative',

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-1.25rem',
    },

    '& label.Mui-focused': {
      visibility: 'hidden !important',
    },

    '& div': {
      border: 'none',

      '&:before': {
        display: 'none',
      },
    },

    '& input': {
      fontSize: '0.875rem',
      borderRadius: '0.3125rem',
    },

    '& textArea': {
      fontSize: '0.875rem',
    },
  },
  mainInput: {
    '& label': {
      transform: 'translate(0.5rem, 0.6rem) scale(1) !important',
      color: '#a6a6a6',
    },
    '& input': {
      border: '0.0625rem solid #E2E2E2',
      color: theme.palette.info.main,
      padding: '0.5rem 0.9375rem',
    },
    '& div': {
      background: 'transparent !important',
    },
  },
  filterInput: {
    '& label': {
      transform: 'translate(1.125rem, 0.6875rem) scale(1) !important',
      color: theme.palette.info.main,
    },

    '& input': {
      border: `0.0625rem solid ${theme.palette.info.main}`,
      color: theme.palette.info.main,
      padding: '0.5rem 1.25rem',
    },
  },
}));

export default useStyles;
