const webHookStatuses = Object.freeze([
  {
    name: 'Unrecognized',
    hex: 'rgb(107 112 116)',
    label: 'Unrecognized',
    value: 0,
  },
  {
    name: 'Attention Required',
    hex: '#ff8f00',
    label: 'Attention Required',
    value: 1,
  },
  {
    name: 'Processed',
    hex: '#67AD5B',
    type: 'processed',
    label: 'Processed',
    value: 2,
  },
  { name: 'Failed', hex: '#d32f2f', label: 'Failed', value: 3 },
  { name: 'Quota Exceeded', hex: '#283593', label: 'Quota Exceeded', value: 4 },
]);

export default webHookStatuses;
