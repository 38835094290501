import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmTicketTimeEntries } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketTimeEntries = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getCrmTicketTimeEntries`,
  async ({ crmId, ticketId }) => {
    const timeEntries = await getCrmTicketTimeEntries(crmId, ticketId);
    return timeEntries;
  },
);

export default {};
