import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkCode } from '@services/requests/passwords';
import { PASSWORDS } from '@store/slices/resources';

export const ThunkCheckCode = createAsyncThunk(
  `${PASSWORDS}/checkCode`,
  async ({ code, password = null }) => {
    const res = await checkCode(code, password);
    return res;
  },
);

export default {};
