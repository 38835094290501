import { createAsyncThunk } from '@reduxjs/toolkit';
import { getATContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetATContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getATContactData`,
  async ({ crmId, contactId }) => getATContactData(crmId, contactId),
);

export default {};
