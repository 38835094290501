/* eslint-disable no-template-curly-in-string */
export const helpdeskLanguages = [
  {
    label: 'English',
    value: 0,
  },
  {
    label: 'French',
    value: 1,
  },
  {
    label: 'Spanish',
    value: 2,
  },
];

export const helpdeskGenders = [
  {
    label: 'Male',
    value: 0,
  },
  {
    label: 'Female',
    value: 1,
  },
];

export const notesTypes = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Internal Note',
    value: 1,
  },
  { label: 'External Note', value: 2 },
];

export const aiMessageVariables = [
  { label: 'Agent Name', value: '${agentName}' },
  { label: 'Company Name', value: '${companyName}' },
  { label: 'First Name', value: '${firstName}' },
];

export const voiceAssistDefaultMessages = {
  intro:
    'Hi ${firstName}, thank you for calling ${companyName}. This is ${agentName}. How may I help you?',
  outro:
    'Someone from our team will reach out shortly. You may hang up to end this call, or if you need urgent assistance, I can transfer you to a live agent.',
};

export default {};
