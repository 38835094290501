import crmDefaultFields from './constants';

export const connectWiseFields = [
  crmDefaultFields.defaultCompany,
  crmDefaultFields.defaultBoard,
  crmDefaultFields.ticketPriority,
  crmDefaultFields.defaultBoardId,
  crmDefaultFields.sourceId,
  crmDefaultFields.typeId,
  crmDefaultFields.subTypeId,
  crmDefaultFields.itemId,
  crmDefaultFields.openBoardStatusId,
  crmDefaultFields.acknowledgedBoardStatusId,
  crmDefaultFields.smsReceivedBoardStatusId,
  crmDefaultFields.closedBoardStatusId,
  crmDefaultFields.ignoredStatuses,
];

export const autoTaskFields = [
  crmDefaultFields.defaultCompany,
  crmDefaultFields.defaultBoard,
  crmDefaultFields.ticketPriority,
  crmDefaultFields.defaultBoardId,
  crmDefaultFields.sourceId,
  crmDefaultFields.typeId,
  crmDefaultFields.subTypeId,
  crmDefaultFields.openBoardStatusId,
  crmDefaultFields.acknowledgedBoardStatusId,
  crmDefaultFields.smsReceivedBoardStatusId,
  crmDefaultFields.closedBoardStatusId,
  crmDefaultFields.ignoredStatuses,
  crmDefaultFields.ticketCategoryId,
];

export const kaseyaFields = [
  crmDefaultFields.defaultCompany,
  crmDefaultFields.defaultBoard,
  crmDefaultFields.ticketPriority,
  crmDefaultFields.defaultBoardId,
  crmDefaultFields.typeId,
  crmDefaultFields.subTypeId,
  crmDefaultFields.openBoardStatusId,
  crmDefaultFields.acknowledgedBoardStatusId,
  crmDefaultFields.smsReceivedBoardStatusId,
  crmDefaultFields.closedBoardStatusId,
  crmDefaultFields.ignoredStatuses,
  crmDefaultFields.ticketCategoryId,
];

export const syncroMspFields = [
  crmDefaultFields.defaultCompany,
  crmDefaultFields.openBoardStatusId,
  crmDefaultFields.closedBoardStatusId,
  crmDefaultFields.ticketCategoryId,
];

export const serviceNowFields = [
  crmDefaultFields.defaultCompany,
  crmDefaultFields.defaultBoard,
  crmDefaultFields.ticketPriority,
  crmDefaultFields.defaultBoardId,
  crmDefaultFields.sourceId,
  crmDefaultFields.typeId,
  crmDefaultFields.subTypeId,
  crmDefaultFields.itemId,
  crmDefaultFields.openBoardStatusId,
  crmDefaultFields.closedBoardStatusId,
  crmDefaultFields.ignoredStatuses,
  crmDefaultFields.category,
  crmDefaultFields.subcategory,
  crmDefaultFields.urgency,
  crmDefaultFields.impact,
  crmDefaultFields.group,
  crmDefaultFields.caller,
];
