import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ThunkGetOutgoingMailbox } from '@store/slices/outgoingMail/thunks/getOutGoingMailbox';
import { ThunkGetOutgoingMailboxUsers } from '@store/slices/outgoingMail/thunks/getOutGoingMailboxUsers';
import { ThunkUpdateOutgoingMailbox } from '@store/slices/outgoingMail/thunks/updateOutGoingMailbox';
import { ThunkDeleteOutgoingMailbox } from '@store/slices/outgoingMail/thunks/deleteOutGoingMailbox';
import { ThunkSetupOutgoingMailbox } from '@store/slices/outgoingMail/thunks/setupOutGoingMailbox';
import { OUTGOING_MAIL } from '../resources';

const outgoingMailSlice = createSlice({
  name: OUTGOING_MAIL,
  initialState: {
    outboundMailbox: { msEmail: null, msTenantId: null },
    loading: false,
    disabled: false,
    users: [],
    totalOptionsCount: 0,
  },
  reducers: {
    updateDiscountCode: (state, action) => {
      state.discountData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(ThunkGetOutgoingMailbox.fulfilled, (state, action) => {
      state.outboundMailbox = action.payload;
      state.loading = false;
    });
    builder.addCase(ThunkGetOutgoingMailboxUsers.fulfilled, (state, action) => {
      state.users = action.payload.data;
      state.totalOptionsCount = action.payload.totalOptionsCount;
      state.loading = false;
    });
    builder.addCase(ThunkUpdateOutgoingMailbox.fulfilled, (state, action) => {
      state.outboundMailbox = action.payload;
      state.disabled = false;
    });
    builder.addCase(ThunkDeleteOutgoingMailbox.fulfilled, state => {
      state.outboundMailbox = { msEmail: null, msTenantId: null };
      state.disabled = false;
    });
    builder.addMatcher(
      isAnyOf(
        ThunkSetupOutgoingMailbox.pending,
        ThunkUpdateOutgoingMailbox.pending,
        ThunkDeleteOutgoingMailbox.pending,
      ),
      state => {
        state.disabled = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        ThunkGetOutgoingMailbox.pending,
        ThunkGetOutgoingMailboxUsers.pending,
      ),
      state => {
        state.loading = true;
      },
    );
  },
});

export default outgoingMailSlice.reducer;

export const outgoingMailSelector = state => state[OUTGOING_MAIL];
