import React, { useCallback, useMemo, useState } from 'react';
import { useRedirect, useRefresh } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import TableLabel from '@common/TableLabel/TableLabel';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import DateTimeField from '@common/TableComponents/DateTimeField';
import ActionField from './ActionField';
import { removeLetter } from '../../helpers';
import ListActions from '../Common/ListActions';
import SubjectField from '../Common/SubjectField';
import BulkActions from '../Documents/BulkActions/BulkActions';
import useStyles from './styles';

const IgnoredList = ({ resource, mailBoxId }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const classes = useStyles();
  const refresh = useRefresh();
  const { enqueueSnackbar } = useSnackbar();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const list = useSelector(listSelector);

  const onRefreshClick = value => console.log(value);
  const onDeleteClick = value => {
    setDeleteId(value.id);
    setDeleteDialogOpen(true);
  };

  const onDeleteLetterHandler = async () => {
    const result = await removeLetter(deleteId);
    if (!result) {
      enqueueSnackbar('Unable to remove record', { variant: 'warning' });
    } else {
      const newList = list.filter(item => item.id !== deleteId);
      dispatch(actions.saveList(newList));
      refresh();
    }
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onCancelDeleteHandler = () => {
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onLetterClick = useCallback(
    value => {
      const path = '/mailbox/'
        .concat(mailBoxId)
        .concat('/letter/')
        .concat(value.messageId);
      redirect(path);
    },
    [mailBoxId, redirect],
  );

  const columns = useMemo(
    () => [
      {
        name: <TableLabel item={{ name: 'Sender', field: 'mailFrom' }} />,
        key: 'mailFrom',
        width: 321,
      },
      {
        name: <TableLabel item={{ name: 'Subject', field: 'subject' }} />,
        key: 'subject',
        accessor: rowData => (
          <SubjectField
            record={rowData}
            key="subject"
            source="subject"
            label="Subject"
            onClick={onLetterClick}
            sortable
          />
        ),
        width: 385,
      },
      {
        name: 'Received date',
        key: 'dateTime',
        sort: 'dateTime',
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="dateTime"
            source="dateTime"
            label="Received date"
            sortable
          />
        ),
        width: 276,
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <ActionField
            record={rowData}
            handleUpdateClick={onRefreshClick}
            handleDeleteClick={onDeleteClick}
          />
        ),
      },
    ],
    [onLetterClick],
  );

  return (
    <div className={classes.tableWrapper}>
      <MuiVirtualizedTable
        bulkActions={
          <BulkActions mailBoxId={mailBoxId} displayIgnore={false} />
        }
        actions={<ListActions onRefresh={refresh} />}
        columns={columns}
        resource={resource}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteLetterHandler}
        onCancel={onCancelDeleteHandler}
        title="Deleting the letter"
        content="Are you sure to remove the letter. This action will not affect total statistics."
      />
    </div>
  );
};

IgnoredList.propTypes = {
  resource: PropTypes.string,
  mailBoxId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IgnoredList;
