import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSuperOpsContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetSuperOpsContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getSuperOpsContactData`,
  async ({ crmId, contactId }) => getSuperOpsContactData(crmId, contactId),
);

export default {};
