const initialValue = {
  id: 0,
  name: '',
  eventMessage: '',
  identifierRules: '',
  mailBoxIds: [],
  notificationIds: [],
  enabled: true,
  triggerRule: 0,
  periodSize: 0,
  periodType: 0,
};

export const triggerPriorities = Object.freeze([
  { label: 'P5 - Low', value: 0, name: 'P5 - Low', color: '#00A0D5' },
  { label: 'P4 - Medium', value: 1, name: 'P4 - Medium', color: '#69A1C7' },
  { label: 'P3 - High', value: 2, name: 'P3 - High', color: '#A773A5' },
  {
    label: 'P2 - Very High',
    value: 3,
    name: 'P2 - Very High',
    color: '#E44E65',
  },
  { label: 'P1 - Critical', value: 4, name: 'P1 - Critical', color: '#DD030A' },
]);

export const incidentStatuses = Object.freeze([
  { label: 'Open', value: 0, name: 'Open', color: '#C91118' },
  { label: 'Acknowledged', value: 1, name: 'Acknowledged', color: '#35C91F' },
  { label: 'Resolved', value: 2, name: 'Resolved', color: '#1833A9' },
]);

export const getIncidentStatusName = id =>
  id < 3 ? incidentStatuses[id].label : 'Undefined';

export const getInitialValue = item => ({
  id: item && item.id ? item.id : initialValue.id,
  name: item && item.name ? item.name : initialValue.name,
  eventMessage:
    item && item.eventMessage ? item.eventMessage : initialValue.eventMessage,
  identifierRules:
    item && item.identifierRules
      ? item.identifierRules
      : initialValue.identifierRules,
  mailBoxIds:
    item && item.mailBoxIds && item.mailBoxIds.length
      ? item.mailBoxIds
      : initialValue.mailBoxIds,
  notificationIds:
    item && item.notificationIds && item.notificationIds.length
      ? item.notificationIds
      : initialValue.notificationIds,
  enabled: item && item.enabled ? item.enabled : initialValue.enabled,
  triggerRule:
    item && item.triggerRule ? item.triggerRule : initialValue.triggerRule,
  periodSize:
    item && item.periodSize ? item.periodSize : initialValue.periodSize,
  periodType:
    item && item.periodType ? item.periodType : initialValue.periodType,
  priority: item && item.priority ? item.priority : triggerPriorities[0].value,
  createIfSubjectIsUnique:
    item && item.createIfSubjectIsUnique ? item.createIfSubjectIsUnique : false,
  tags: item && item.tags ? item.tags : [],
});

export const triggerOptions = [
  { label: 'Always', value: 0 },
  { label: 'Once', value: 1 },
  { label: 'If no incidents last', value: 2 },
  { label: 'Never', value: 3 },
];

export const periodType = [
  { label: 'Minutes', value: 0 },
  { label: 'Hours', value: 1 },
  { label: 'Days', value: 2 },
];
