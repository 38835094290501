import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  submitBtn: {
    marginTop: '10px',
    marginLeft: '10px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  createParserButtonWrapper: {
    marginTop: '1.25rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',

    '& span': {
      paddingLeft: 0,
      textTransform: 'uppercase',
    },

    '&:last-child': {
      marginLeft: '1.25rem',
    },
  },
  circle: {
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
  },
  spacing: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
    },
    height: '90vh',
    overflowY: 'auto',
  },
  flex: {
    display: 'flex',
  },
}));

export default useStyles;
