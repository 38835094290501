import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { pushNoteToTicket } from '@services/requests/liveChats';

export const ThunkPushNoteToTicket = createAsyncThunk(
  `${LIVE_CHATS}/pushNoteToTicket`,
  ({ chatId }) => pushNoteToTicket(chatId),
);

export default {};
