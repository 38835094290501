import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListContextProvider } from 'react-admin';
import MuiVirtualizedListViewBase from '@common/MuiVirtualizedTable/MuiVirtualizedListViewBase';
import QuickSearchToolbar from '@common/MuiVirtualizedTable/components/QuickSearchToolbar';
import {
  COLUMN_HEADER_HEIGHT,
  NONE_SELECTED,
  ROW_HEIGHT,
} from '@common/MuiVirtualizedTable/constants';

import useControllerListViewProps from '@common/MuiVirtualizedTable/hooks/useControllerListViewProps';

import cx from 'classnames';
import useStyles from '@common/MuiVirtualizedTable/styles';
import { ColumnsContext } from './context/columns';
import withUpdatedColumns from './HOC/withUpdatedColumns';

export const MuiVirtualizedListView = props => {
  const {
    columns: defaultColumns,
    data,
    rowHeight = ROW_HEIGHT,
    headerHeight = COLUMN_HEADER_HEIGHT,
    tableKey,
    actions,
    bulkActions = false,
    filters,
    additionalControllerProps = {},
    defaultFilters,
    defaultSort = { field: 'id', order: 'ASC' },
    classNameWrapper,
    groupByValue,
    rowsWrapping,
    children,
    onToggleItem,
    selectedIds,
    displaySearch = true,
  } = props;

  const classes = useStyles({});

  const [searchText, setSearchText] = React.useState('');

  const { columns, setDefaultColumns } = useContext(ColumnsContext);

  useEffect(() => setDefaultColumns(defaultColumns), [
    defaultColumns,
    setDefaultColumns,
  ]);

  const controllerProps = useControllerListViewProps({
    tableKey,
    defaultFilters,
    data,
    columns,
    defaultSort,
    searchText,
    groupByValue,
    rowsWrapping,
  });

  const {
    rows,
    onToggleItem: onToggleItemDefault,
    selectedIds: selectedIdsDefault,
    ids,
    resetSelectedIds,
  } = controllerProps;

  const {
    handleColumnResizeDebounce,
    onRefreshList,
    ...listViewProps
  } = controllerProps;

  const finiteControllerProps = {
    ...listViewProps,
    ...additionalControllerProps,
    noneSelected: NONE_SELECTED,
    resetSelectedIds,
  };

  const selectedIdsMemo = useMemo(() => selectedIds || selectedIdsDefault, [
    selectedIds,
    selectedIdsDefault,
  ]);

  const onToggleItemDefaultCallback = useCallback(
    selectedId => onToggleItemDefault(selectedId),
    [onToggleItemDefault],
  );

  const handleOnToggle = useCallback(
    selectedId => {
      return onToggleItem
        ? onToggleItem(selectedId)
        : onToggleItemDefaultCallback(selectedId);
    },
    [onToggleItem, onToggleItemDefaultCallback],
  );

  return (
    <div className={cx(classNameWrapper, classes.container)}>
      <ListContextProvider value={finiteControllerProps}>
        {children}
        <MuiVirtualizedListViewBase
          dateFromState
          columns={columns}
          rows={rows}
          actions={actions}
          bulkActions={bulkActions}
          filters={filters}
          classNameWrapper={classNameWrapper}
          handleColumnResizeDebounce={handleColumnResizeDebounce}
          onToggleItem={handleOnToggle}
          selectedIds={selectedIdsMemo}
          ids={ids}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          groupByValue={groupByValue}
          searchToolbar={
            <QuickSearchToolbar
              value={searchText}
              onChange={event => setSearchText(event.target.value)}
              clearSearch={() => setSearchText('')}
            />
          }
          displaySearch={displaySearch}
        />
      </ListContextProvider>
    </div>
  );
};

MuiVirtualizedListView.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.func,
      ]),
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  tableKey: PropTypes.string,
  actions: PropTypes.node,
  bulkActions: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  filters: PropTypes.node,
  additionalControllerProps: PropTypes.objectOf(PropTypes.any),
  defaultFilters: PropTypes.objectOf(PropTypes.any),
  defaultSort: PropTypes.objectOf(PropTypes.any),
  classNameWrapper: PropTypes.string,
  groupByValue: PropTypes.string,
  rowsWrapping: PropTypes.func,
  children: PropTypes.node,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  onToggleItem: PropTypes.func,
  displaySearch: PropTypes.bool,
};

export default withUpdatedColumns(MuiVirtualizedListView);
