import { createAsyncThunk } from '@reduxjs/toolkit';
import { BUFFER_PHONE_NUMBERS } from '@store/slices/resources';
import { getPhoneNumbersCountries } from '@services/requests/phoneNumbers';

export const ThunkGetPhoneNumbersCountries = createAsyncThunk(
  `${BUFFER_PHONE_NUMBERS}/getPhoneNumbersCountries`,
  async () => {
    const res = await getPhoneNumbersCountries();
    return res;
  },
);

export default {};
