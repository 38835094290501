import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmCompaniesByDefaultId } from '@services/requests/crm';
import { CRM } from '@store/slices/resources';

export const ThunkGetCrmCompaniesByDefaultId = createAsyncThunk(
  `${CRM}/getCrmCompaniesByDefaultId`,
  async ({ crmDefaultId }) => {
    const companies = await getCrmCompaniesByDefaultId(crmDefaultId);
    return companies;
  },
);

export default {};
