import React, { useCallback, useEffect, useState } from 'react';
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons/';
import { TreeItem, TreeView } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkGetCrmBoardStatuses,
  ThunkGetCrmDefaultBoards,
  ThunkGetCrmDefaultCompanies,
  ThunkGetCrmDefaults,
  ThunkGetCrmSources,
} from '@store/slices/treeView/thunks';
import {
  CrmBoardStatusesSelector,
  CrmDefaultBoardsSelector,
  CrmDefaultCompaniesSelector,
  CrmDefaultsSelector,
  CrmSourcesSelector,
} from '@store/slices/treeView/selectors';
import crmSources from '@constants/crmSources';
import { Draggable } from 'react-beautiful-dnd';
import Loading from '@ui/components/common/Loading/Loading';

const CrmTreeView = () => {
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState([]);

  const companies = useSelector(CrmDefaultCompaniesSelector);
  const statuses = useSelector(CrmBoardStatusesSelector);
  const defaults = useSelector(CrmDefaultsSelector);
  const boards = useSelector(CrmDefaultBoardsSelector);
  const sources = useSelector(CrmSourcesSelector);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const simplyExpand = useCallback(
    item => {
      if (!expanded.includes(String(item.id))) {
        setExpanded([...expanded, String(item.id)]);
      } else {
        setExpanded(expanded.filter(e => e !== String(item.id)));
      }
    },
    [expanded],
  );

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Successfully copy to clipboard', { variant: 'success' });
  };

  const handleCompaniesClick = useCallback(
    async (crmId, nodeId) => {
      if (!expanded.includes(String(nodeId))) {
        const arr = [...expanded, String(nodeId)];
        setExpanded(arr);
        await dispatch(ThunkGetCrmDefaultCompanies({ crmId }));
      } else {
        setExpanded(expanded.filter(i => i !== String(nodeId)));
      }
    },
    [dispatch, expanded],
  );

  const handleStatusesClick = useCallback(
    async (crmId, boardId, nodeId) => {
      if (!expanded.includes(String(nodeId))) {
        const arr = [...expanded, String(nodeId)];
        setExpanded(arr);
        await dispatch(ThunkGetCrmBoardStatuses({ crmId, boardId }));
      } else {
        setExpanded(expanded.filter(i => i !== String(nodeId)));
      }
    },
    [dispatch, expanded],
  );

  const handleBoardClick = useCallback(
    async (crmId, nodeId) => {
      if (!expanded.includes(String(nodeId))) {
        const arr = [...expanded, String(nodeId)];
        setExpanded(arr);
        await dispatch(ThunkGetCrmDefaultBoards({ crmId }));
      } else {
        setExpanded(expanded.filter(i => i !== String(nodeId)));
      }
    },
    [dispatch, expanded],
  );

  const handleCrmDefaultLoad = useCallback(
    async crmId => {
      if (!expanded.includes(String(crmId))) {
        const arr = [...expanded, String(crmId)];
        setExpanded(arr);
        await dispatch(ThunkGetCrmDefaults({ crmId }));
      } else {
        setExpanded(expanded.filter(i => i !== String(crmId)));
      }
    },
    [dispatch, expanded],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetCrmSources());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <>
      <TreeView
        aria-label="psa system"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
      >
        {sources
          .filter(
            i =>
              i.crmType === crmSources.ConnectWise.name ||
              i.crmType === crmSources.Autotask.name,
          )
          .map(crm => (
            <TreeItem
              key={crm.id}
              nodeId={String(crm.id)}
              label={crm.name}
              onClick={() => {
                handleCrmDefaultLoad(crm.id);
              }}
            >
              {!!defaults[crm.id]?.length &&
                defaults[crm.id].map((crmDefault, index) => (
                  <TreeItem
                    key={crmDefault.id}
                    nodeId={`${crm.id}${crmDefault.id}Default`}
                    label={crmDefault.name}
                    onClick={() => {
                      simplyExpand({ id: `${crm.id}${crmDefault.id}Default` });
                    }}
                  >
                    <TreeItem
                      nodeId={String(
                        `${crm.id}${crmDefault.id}1000001${index}`,
                      )}
                      label="Boards"
                      onClick={() => {
                        handleBoardClick(
                          crm.id,
                          String(`${crm.id}${crmDefault.id}1000001${index}`),
                        );
                      }}
                    >
                      {!!boards[crm.id]?.length &&
                        boards[crm.id].map((board, idx) => (
                          <TreeItem
                            key={board.id}
                            nodeId={String(
                              `${crm.id}${crmDefault.id}board${board.id}${idx}`,
                            )}
                            label={board.name}
                            onClick={() => {
                              simplyExpand({
                                id: `${crm.id}${crmDefault.id}board${board.id}${idx}`,
                              });
                            }}
                          >
                            <TreeItem
                              nodeId={`${crm.id}${crmDefault.id}${board.id}statuses`}
                              label="Statuses"
                              onClick={() => {
                                handleStatusesClick(
                                  crm.id,
                                  board.id,
                                  `${crm.id}${crmDefault.id}${board.id}statuses`,
                                );
                              }}
                            >
                              {!!statuses[board.id]?.length &&
                                statuses[board.id].map((status, i) => (
                                  <Draggable
                                    key={`${crm.id}${crmDefault.id}${board.id}${status.id}status`}
                                    draggableId={`${status.id}/${status.name}`}
                                    index={i}
                                  >
                                    {provided => (
                                      <TreeItem
                                        nodeId={`${crm.id}${crmDefault.id}${board.id}${status.id}status`}
                                        label={status.name}
                                        endIcon={
                                          <FileCopyIcon
                                            onClick={() => {
                                              copyToClipboard(status.name);
                                            }}
                                          />
                                        }
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                      />
                                    )}
                                  </Draggable>
                                ))}
                            </TreeItem>
                          </TreeItem>
                        ))}
                    </TreeItem>
                    <TreeItem
                      nodeId={String(`${crm.id}${crmDefault.id}1000002`)}
                      label="Companies"
                      onClick={() => {
                        handleCompaniesClick(
                          crm.id,
                          String(`${crm.id}${crmDefault.id}1000002`),
                        );
                      }}
                    >
                      {!!companies[crm.id]?.length &&
                        companies[crm.id].map((company, companiesIndex) => (
                          <Draggable
                            key={`${crm.id}${crmDefault.id}${company.value}`}
                            draggableId={`${company.value}/${company.label}`}
                            index={companiesIndex}
                          >
                            {provided => (
                              <TreeItem
                                ref={provided.innerRef}
                                key={`${crm.id}${crmDefault.id}${company.value}`}
                                nodeId={`${crm.id}${crmDefault.id}${company.value}`}
                                label={company.label}
                                endIcon={
                                  <FileCopyIcon
                                    onClick={() => {
                                      copyToClipboard(company.label);
                                    }}
                                  />
                                }
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              />
                            )}
                          </Draggable>
                        ))}
                    </TreeItem>
                  </TreeItem>
                ))}
            </TreeItem>
          ))}
      </TreeView>
    </>
  );
};

export default CrmTreeView;
