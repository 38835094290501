import React from 'react';
import { ReactComponent as BorderOuterIcon } from '@assets/icons/borderOuter.svg';
import List from './List';

const Icon = () => <BorderOuterIcon alt="parsers" />;

export default {
  list: List,
  icon: Icon,
};
