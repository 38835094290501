import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'start': {
      return { imageUri: '', showLoader: true };
    }
    case 'finish': {
      return { showLoader: false, imageUri: action.payload };
    }
    case 'setImage': {
      return { ...state, imageUri: action.payload };
    }
    case 'removeImage': {
      return { ...state, imageUri: '' };
    }
    default: {
      return state;
    }
  }
}

const useUploadImage = () => {
  const [state, dispatch] = useReducer(reducer, {
    showLoader: false,
  });

  return {
    ...state,
    startLoading: () => dispatch({ type: 'start' }),
    removeImage: () => dispatch({ type: 'removeImage' }),
    finishLoading: payload => dispatch({ type: 'finish', payload }),
    setImage: payload => dispatch({ type: 'setImage', payload }),
  };
};

export default useUploadImage;
