import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

const DetailsDialogItem = ({ row }) => {
  const errorMsg = useMemo(
    () => (row.obj.errors ? row.obj.errors?.join(', ') : row.message),
    [row],
  );
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="center">{row.status}</TableCell>
      <TableCell align="center">{row.obj.company}</TableCell>
      <TableCell align="center">{row.obj.deviceName}</TableCell>
      <TableCell align="center">{row.obj.deviceUrl}</TableCell>
      <TableCell align="center">{row.obj.deviceType}</TableCell>
      <TableCell align="center">{row.obj.site}</TableCell>
      <TableCell align="center">{row.obj.region}</TableCell>
      <TableCell align="center">{row.obj.periodMin}</TableCell>
      <TableCell align="center">{errorMsg}</TableCell>
    </TableRow>
  );
};

DetailsDialogItem.propTypes = {
  row: PropTypes.shape({
    status: PropTypes.string,
    obj: PropTypes.shape({
      company: PropTypes.string,
      deviceName: PropTypes.string,
      deviceUrl: PropTypes.string,
      deviceType: PropTypes.string,
      site: PropTypes.string,
      region: PropTypes.string,
      periodMin: PropTypes.string,
      errors: PropTypes.object,
    }),
  }),
};

export default DetailsDialogItem;
