import { createAsyncThunk } from '@reduxjs/toolkit';
import { EXTRA_RESOURCES } from '@store/slices/resources';
import { addExtraResource } from '@services/requests/subscriptions';

export const ThunkAddExtraResource = createAsyncThunk(
  `${EXTRA_RESOURCES}/addExtraResource`,
  async ({ code }) => {
    const res = await addExtraResource(code);
    return res;
  },
);

export default {};
