import { makeRequest } from '@services/requests/makeRequest';
import { SECURE_DATA_SETTINGS } from '@services/requests/requestResources';

export const getSecureDataSettings = () =>
  makeRequest('GET', `${SECURE_DATA_SETTINGS}`);

export const updateSecureDataSettings = payload =>
  makeRequest('POST', `${SECURE_DATA_SETTINGS}`, payload);

export const getSecureDataCountries = () =>
  makeRequest('GET', '/SecureData/fileLocations');
