import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    height: '100%',
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0.25rem',
    },
    padding: '1.25rem',
    backgroundColor: theme.palette.tenantSettings.background,
    borderRadius: '0.8125rem',
  },
  template: {
    width: '80rem',
    height: '30rem',
    backgroundColor: props => props.colors.contentBackground,
  },
  appBar: {
    '& svg': {
      width: '1em',
      height: '1em',
      color: props => props.colors.appBarIcon,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    columnGap: '0.5rem',
    backgroundColor: props => props.colors.appBarBackground,
    width: '100%',
    height: '10%',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  userName: {
    color: props => props.colors.appBarText,
  },
  sideBar: {
    background: props =>
      `linear-gradient(180deg, ${props.colors.sideBarBackground} 0%, #000000 100%)`,
    width: '20%',
    height: '90%',
  },
  sideBarItem: {
    columnGap: '1rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    padding: '1rem 0 1rem 1rem',
    '& svg': {
      '& path': {
        stroke: props => props.colors.sideBarIcon,
      },
      '& circle': {
        stroke: props => props.colors.sideBarIcon,
      },
    },
    '& span': {
      color: props => props.colors.sideBarText,
    },
  },
  selectedItem: {
    borderRight: props => `6px solid ${props.colors.sideBarSelected}`,
  },
  table: {
    border: '1px solid #C0C0C0',
    borderCollapse: 'collapse',
    backgroundColor: props => props.colors.listViewBackground,
    padding: '5px',
    height: '3rem',
    margin: '10px',
    '& tr': {
      height: '15px',
    },
    '& th': {
      border: '1px solid #C0C0C0',
      padding: '5px',
      color: props => props.colors.listViewHeaderText,
    },
    '& td': {
      border: '1px solid #C0C0C0',
      padding: '5px',
      color: props => props.colors.listViewText,
    },
  },
  card: {
    '&> div': {
      background: props => props.colors.cardBackground,
      '&:hover': {
        background: props => props.colors.cardHoverBackground,
      },
    },
    '& b': {
      color: props => props.colors.cardText,
    },
  },
  mailBoxCard: {
    '&> div': {
      background: props => props.colors.mailBoxCardBackground,
      '&> div': {
        '&:first-child': {
          '& svg': {
            '& path': {
              stroke: props => props.colors.mailBoxCardCopyIcon,
            },
            '& circle': {
              stroke: props => props.colors.mailBoxCardCopyIcon,
            },
          },
        },
        '&:last-child': {
          '& svg': {
            '& path': {
              stroke: props => props.colors.mailBoxCardIcon,
            },
            '& circle': {
              stroke: props => props.colors.mailBoxCardIcon,
            },
          },
        },
      },
    },
    '& .link': {
      color: 'blue',
    },
    '& span': {
      display: 'flex',
      justifyContent: 'left',
      color: props => props.colors.mailBoxCardText,
    },
  },
}));

export default useStyles;
