import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import {
  getTechResponseSettingsVariables,
  getVerificationAutoResponseSettingsVariables,
} from '@services/requests/verificationSettings';

export const ThunkGetTechResponseSettingsVariables = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getTechResponseSettingsVariables`,
  async () => {
    const res = await getTechResponseSettingsVariables();
    return res;
  },
);

export const ThunkGetVerificationAutoResponseSettingsVariables = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getVerificationAutoResponseSettingsVariables`,
  async () => {
    const res = await getVerificationAutoResponseSettingsVariables();
    return res;
  },
);

export default {};
