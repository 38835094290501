import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { GeneralContext } from '@components/Context/general';

function useWindowSize(bottomMargin = 20) {
  const [size, setSize] = useState({ height: 0, width: 0 });
  const props = useContext(GeneralContext);
  const ref = useRef();

  useLayoutEffect(() => {
    const updateSize = () => {
      const { element } = ref.current;
      const rect = element.getBoundingClientRect();
      const height = window.innerHeight - rect.top - bottomMargin;
      setSize({ width: window.innerWidth, height });
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [bottomMargin, props]);
  return { dimensions: size, ref };
}
export default useWindowSize;
