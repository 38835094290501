import { actionTypes } from '../actions';

const handlePayload = (acc, [metadata, blob]) => ({
  ...acc,
  [metadata.name]: {
    blob,
    name: metadata.originName || metadata.name,
    fileId: metadata.name,
  },
});

const filesReducer = (state = { files: {}, isLoading: false }, action) => {
  switch (action.type) {
    case actionTypes.GET_FILES_LOADING: {
      return { ...state, isLoading: true };
    }
    case actionTypes.GET_FILES_SUCCESS: {
      return {
        files: {
          ...state.files,
          ...action.payload.reduce(handlePayload, { ...state.files }),
        },
        isLoading: false,
      };
    }
    case actionTypes.REMOVE_FILES: {
      return {
        ...state,
        files: Object.keys(state.files)
          .filter(fileId => !action.ids.includes(fileId))
          .reduce(
            (acc, fileId) => ({ ...acc, [fileId]: state.files[fileId] }),
            {},
          ),
      };
    }
    default:
      return state;
  }
};

export default filesReducer;
