import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AtTicketDialog from '@components/WebHooks/components/dialogs/AtTicketDialog';

const dialogs = {
  atTicketDialog: false,
};
const TemplateItem = ({ item, onSelect, url }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogsOpen, setDialogsOpen] = useState(dialogs);

  const setDialogOpen = (name, state) => {
    const newDialogsOpen = { ...dialogsOpen };
    newDialogsOpen[name] = state;
    setDialogsOpen(newDialogsOpen);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = option => {
    setAnchorEl(null);
    if (option.value) {
      onSelect(option.value);
    }
    if (option.dialog) {
      setDialogOpen(option.dialog, true);
    }
  };

  return (
    <>
      <Chip
        clickable
        onClick={handleClick}
        label={item.name}
        style={{
          width: '10rem',
          cursor: 'pointer',
          margin: '0 5px',
        }}
      />
      <Menu
        id="simple-menu"
        elevation={0}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {item.items.map(i => (
          <MenuItem key={i.label} onClick={() => handleSelect(i)}>
            {i.label}
          </MenuItem>
        ))}
      </Menu>
      <AtTicketDialog
        open={dialogsOpen.atTicketDialog}
        onClose={() => setDialogOpen('atTicketDialog', false)}
        onSubmit={onSelect}
        url={url}
      />
    </>
  );
};

TemplateItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  url: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
};

export default TemplateItem;
