import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketScheduleEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';
import moment from 'moment';

export const ThunkGetConnectWiseTicketScheduleEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketScheduleEntry`,
  async ({ crmId, scheduleEntryId }) => {
    const scheduleEntry = await getConnectWiseTicketScheduleEntry(
      crmId,
      scheduleEntryId,
    );

    return {
      ...scheduleEntry,
      dateStart: moment(scheduleEntry.dateStart).format('YYYY-MM-DDThh:mm'),
      dateEnd: moment(scheduleEntry.dateEnd).format('YYYY-MM-DDThh:mm'),
    };
  },
);

export default {};
