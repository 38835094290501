import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/accept.svg';

import Input from '@components/Auth/Common/Input';

import useStyles from '../styles';
import AddIcon from '@material-ui/icons/Add';

const IssueTypeMultiSelect = ({
  title,
  updateTitle,
  disabled,
  values,
  updateMultiselectOptions,
}) => {
  const [inputTitle, setInputTitle] = useState('');
  const [options, setOptions] = useState([]);
  const [newOptionInput, setNewOptionInput] = useState('New Option');
  const [showAddOptions, setShowAddOptions] = useState(false);

  const classes = useStyles();

  const removeOption = optionIndex => {
    const arr = options.filter((val, index) => index !== optionIndex);

    setOptions(arr);
    updateMultiselectOptions(arr);
  };

  const handleInputClose = () => {
    setShowAddOptions(false);
    setNewOptionInput('New Option');
  };

  const handleOptionAdd = () => {
    let newOptions;

    if (options?.length > 0) {
      newOptions = [...options, newOptionInput];
    } else {
      newOptions = [newOptionInput];
    }

    setOptions(newOptions);
    setShowAddOptions(false);
    setNewOptionInput('New Option');

    updateMultiselectOptions(newOptions);
  };

  useEffect(() => {
    setInputTitle(title);
    setOptions(values);
  }, []);

  return (
    <div>
      <Input
        input={{
          value: inputTitle,
          onChange: e => {
            e.stopPropagation();
            setInputTitle(e.target.value);
          },
          onBlur: () => {
            updateTitle(inputTitle || '');
          },
        }}
        meta={{ touched: true, error: null }}
        disabled={disabled}
      />
      <div className={classes.options}>
        {!!options?.length &&
          options.map((i, index) => (
            <div key={i} className={classes.option}>
              <div>{i}</div>
              <div>
                <CrossIcon
                  onClick={() => {
                    removeOption(index);
                  }}
                  className={classes.icon}
                />
              </div>
            </div>
          ))}

        <div>
          {!showAddOptions && (
            <div
              className={classes.icon}
              onClick={() => {
                setShowAddOptions(true);
              }}
              role="presentation"
            >
              <AddIcon /> Add option
            </div>
          )}

          {showAddOptions && (
            <div className={classes.optionInput}>
              <Input
                input={{
                  value: newOptionInput,
                  onChange: e => {
                    e.stopPropagation();
                    setNewOptionInput(e.target.value);
                  },
                  onBlur: () => {
                    updateTitle(inputTitle || '');
                  },
                }}
                meta={{ touched: true, error: null }}
                disabled={disabled}
              />

              <div className={classes.iconsBlock}>
                <div>
                  <CheckIcon
                    className={classes.icon}
                    onClick={handleOptionAdd}
                  />
                </div>
                <div>
                  <DeleteIcon
                    className={classes.icon}
                    onClick={handleInputClose}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

IssueTypeMultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  updateTitle: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateMultiselectOptions: PropTypes.func.isRequired,
};

export default IssueTypeMultiSelect;
