import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SelectIncidentTimeEntry } from '@store/slices/incident/selectors';

import ReusableButton from '@ui/components/common/Button/Button';
import AddTimeEntryDialog from '../dialogs/AddTimeEntryDialog';
import SimpleTable from '../SimpleTable';

import useStyles from '../../styles';

const TimeEntry = ({ incidentId }) => {
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const classes = useStyles();

  const columns = Object.freeze([
    { label: 'User Name', value: 'userName' },
    { label: 'Note', value: 'note' },
    { label: 'Start', value: 'startTime' },
    { label: 'Duration', value: 'duration' },
  ]);

  return (
    <div className={classes.block}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderTitle}>Time Entry</div>
        <div>
          <ReusableButton
            label="Add Time Entry"
            onClick={() => {
              setOpenAddTimeEntryDialog(true);
            }}
          />
        </div>
      </div>

      <div>
        <SimpleTable columns={columns} selector={SelectIncidentTimeEntry} />
      </div>

      {openAddTimeEntryDialog && (
        <AddTimeEntryDialog
          open={openAddTimeEntryDialog}
          onClose={() => {
            setOpenAddTimeEntryDialog(false);
          }}
          incidentId={incidentId}
        />
      )}
    </div>
  );
};

TimeEntry.propTypes = {
  incidentId: PropTypes.number,
};
export default TimeEntry;
