import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { getDuoAuthUrl } from '@services/requests/user';

export const ThunkGetDuoAuthUrl = createAsyncThunk(
  `${USER}/getDuoAuthUrl`,
  async () => {
    return getDuoAuthUrl();
  },
);

export default {};
