import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_INTEGRATIONS } from '@store/slices/resources';
import { getIntegration } from '@services/requests/securityIntegrations';

export const ThunkGetSecurityIntegrationById = createAsyncThunk(
  `${SECURITY_INTEGRATIONS}/getIntegration`,
  async id => {
    const data = await getIntegration(id);
    return data;
  },
);

export default {};
