import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 135px)',
  },
  baseButton: {
    cursor: 'pointer',
    width: '100%',
    height: '35pt',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Nunito,"Segoe UI",Arial,sans-serif',
    '&:hover:enabled': {
      opacity: 0.9,
    },
  },
  organizationButton: {
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'rgb(235 194 185)',
      justifyContent: 'center',
    },
  },
  networksButton: {},
  activeOrganizationButton: {
    borderRight: '6px solid #EC6A4E',
  },
  column: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 0,
  },
  organizationHeader: {
    color: 'gray',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  headerContainer: {
    margin: '0.5rem',
  },
  headerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  alertsContainer: {
    padding: '0 8px',
    '& tbody': {
      maxHeight: '70vh',
    },
    '& > div': {
      height: 'auto',
    },
  },
}));

export default useStyles;
