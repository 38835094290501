import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { groupBy } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import DataGrid from '@lib/bundle';

import getCheckboxCell from '@common/MuiVirtualizedTable/components/getCheckboxCell';
import {
  COLUMN_HEADER_HEIGHT,
  NONE_SELECTED,
  ROW_HEIGHT,
} from '@common/MuiVirtualizedTable/constants';
import MenuComponent from './components/Menu';
import NoData from './components/NoData';

import useWindowSize from './hooks/useWindowSize';

import useStyles from './styles';

const MuiVirtualizedListViewBase = props => {
  const {
    columns,
    rows,
    loadMoreRowsDebounce,
    rowHeight = ROW_HEIGHT,
    headerHeight = COLUMN_HEADER_HEIGHT,
    bulkActions = false,
    loading,
    groupByValue,
    handleColumnResizeDebounce,
    selectedIds,
    onToggleItem,
    ids,
    searchToolbar,
    dateFromState = false,
    displaySearch = true,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const groupByList = useMemo(() => {
    return groupByValue && groupByValue !== NONE_SELECTED ? [groupByValue] : [];
  }, [groupByValue]);

  const expandedGroupIds = useMemo(() => {
    return new Set(rows.map(r => r[groupByValue]));
  }, [rows, groupByValue]);

  const length = useMemo(() => {
    if (groupByValue && groupByValue !== NONE_SELECTED) {
      return rows.length + expandedGroupIds.size;
    }
    return rows.length;
  }, [expandedGroupIds, groupByValue, rows]);

  const {
    ref,
    dimensions: { height: tableMaxHeight },
  } = useWindowSize();

  const borderHeight = 2;
  const calculationTableHeight =
    rowHeight * length + headerHeight + borderHeight;
  const tableHeight =
    calculationTableHeight < 400 ? 400 : calculationTableHeight;

  const classes = useStyles({
    tableHeight,
    headerHeight,
    tableMaxHeight,
  });

  const updatedColumns = () => {
    const currentColumns = [...columns];
    if (bulkActions) {
      currentColumns.unshift(
        getCheckboxCell({ classes, selectedIds, onToggleItem, ids, rows }),
      );
    }
    return currentColumns;
  };

  const getRowClassName = () => classes.tableRow;
  const handleScroll = event => {
    if (dateFromState) return;
    const { scrollTop, clientHeight } = event.target;
    const scrollPosition = Math.floor(scrollTop + clientHeight + rowHeight);
    if (scrollPosition >= tableHeight) {
      loadMoreRowsDebounce({ stopIndex: rows.length - 1 });
    }
  };

  return (
    <>
      {displaySearch && searchToolbar}
      <div className={classes.tableWrapper}>
        <button
          type="button"
          className={classes.vertIconContainer}
          onClick={e => setAnchorEl(e.target)}
        >
          <MoreVertIcon className={classes.vertIcon} />
        </button>

        <MenuComponent
          anchorEl={anchorEl}
          setAnchorEl={() => setAnchorEl(null)}
        />

        <DndProvider context={window} backend={HTML5Backend}>
          <DataGrid
            rows={rows}
            rowsCount={rows.length}
            rowGetter={({ index }) => rows[index] || {}}
            rowKeyGetter={row => row.id}
            headerRowHeight={headerHeight}
            rowHeight={rowHeight}
            ref={ref}
            onColumnResize={handleColumnResizeDebounce}
            onScroll={handleScroll}
            groupBy={groupByList}
            rowGrouper={groupBy}
            expandedGroupIds={expandedGroupIds}
            rowClass={getRowClassName}
            enableVirtualization
            className={cx('rdg-light', classes.dataGridWrapper)}
            columns={updatedColumns().filter(c => !c?.hidden)}
            emptyRowsRenderer={NoData}
          />
        </DndProvider>
      </div>
      {loading && <LinearProgress className={classes.linearProgress} />}
    </>
  );
};

MuiVirtualizedListViewBase.propTypes = {
  dateFromState: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      align: PropTypes.string,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.any),
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  bulkActions: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  groupByValue: PropTypes.string,
  searchToolbar: PropTypes.node,
  loading: PropTypes.bool,
  handleColumnResizeDebounce: PropTypes.func,
  loadMoreRowsDebounce: PropTypes.func,
  onToggleItem: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  ids: PropTypes.arrayOf(PropTypes.any),
  displaySearch: PropTypes.bool,
};

export default MuiVirtualizedListViewBase;
