import importTypes from '@constants/importTypes';

const help = {
  slackCreateApp: { fileName: 'slack/createApp' },
  teamsCreateApp: { fileName: 'teams/createApp' },
  dmImport: { fileName: 'Import/DeviceMonitor' },
  dmNotificationImport: { fileName: 'Import/DeviceNotification' },
  noHelp: { fileName: 'under-construction' },
  integrationCwCreatePod: { fileName: 'integrations/connectwise/createPod' },
  integrationHaloCreatePod: { fileName: 'integrations/halo/createPod' },
  commonDateFormat: { fileName: 'Common/DateTimeFormat' },
  haloCreateHook: { fileName: 'integrations/halo/createVerificationHook' },
};

export const getImportHelp = contentTitle => {
  switch (contentTitle) {
    case importTypes.DeviceMonitor:
      return help.dmImport;
    case importTypes.DeviceNotification:
      return help.dmNotificationImport;
    default:
      return help.noHelp;
  }
};

export default help;
