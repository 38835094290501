import { createAsyncThunk } from '@reduxjs/toolkit';
import { buyPhoneNumber } from '@services/requests/phoneNumbers';
import { PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkBuyPhoneNumber = createAsyncThunk(
  `${PHONE_NUMBERS}/buyPhoneNumber`,
  async data => {
    const res = await buyPhoneNumber(data);
    return res;
  },
);

export default {};
