import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkGetTicketLink } from '@store/slices/crmTicket/thunks';
import crmTicketStatuses from '@constants/crmTicketStatuses';
import { crmSourcesByType } from '@constants/crmSources';
import { MESSAGING_CHANNEL } from '@constants/routes';

import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import TableDropDown from '@ui/components/common/TableDropDown/TableDropDown';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import TableLabel from '../../common/TableLabel/TableLabel';
import TableActions from './components/TableActions';
import TableItemActions from './components/TableItemActions';
import TableBulkActions from './components/TableBulkActions';

import useStyles from './styles';

const valueHandlerStatus = key => crmTicketStatuses[key].name;

const TriageTickets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleTicketLinkClick = ({ ticketId, crmDefaultId }) => {
    dispatch(ThunkGetTicketLink({ ticketId, crmDefaultId }))
      .unwrap()
      .then(res => {
        window.open(res.data, '_blank');
      });
  };

  const handleChannelRedirect = ({
    messagingChannelId,
    messagingConfigurationId,
  }) => {
    push(
      MESSAGING_CHANNEL.replace(
        ':configurationId',
        messagingConfigurationId,
      ).replace(':channelId', messagingChannelId),
    );
  };

  const columns = [
    {
      name: (
        <TableLabel
          item={{
            name: 'User Name',
            field: 'userName',
          }}
        />
      ),
      key: 'userName',
    },
    {
      name: (
        <TableLabel
          item={{
            name: 'Body',
            field: 'body',
          }}
        />
      ),
      key: 'body',
    },
    {
      name: (
        <TableLabel
          item={{
            name: 'Subject',
            field: 'subject',
          }}
        />
      ),
      key: 'subject',
    },
    {
      name: 'PSA',
      key: 'crmType',
      accessor: item => <div>{crmSourcesByType[item.crmType].name}</div>,
    },
    {
      name: (
        <TableLabel
          item={{
            name: 'Ticket ID',
            field: 'ticketId',
          }}
        />
      ),
      key: 'ticketId',
      accessor: item => (
        <div>
          <span
            className={classes.tableLink}
            onClick={() => {
              handleTicketLinkClick(item);
            }}
            onKeyDown={() => {}}
            role="presentation"
          >
            {item.ticketId}
          </span>
        </div>
      ),
    },
    {
      name: (
        <TableDropDown
          item={{
            name: 'Status',
            field: 'ticketStatus',
          }}
          options={crmTicketStatuses}
          labelHandler={valueHandlerStatus}
        />
      ),
      key: 'ticketStatus',
      accessor: item => (
        <div style={{ color: crmTicketStatuses[item.ticketStatus].color }}>
          {valueHandlerStatus(item.ticketStatus)}
        </div>
      ),
    },
    {
      name: 'Channel',
      accessor: item => (
        <div>
          {item.messagingChannelId && item.messagingConfigurationId && (
            <LinkIcon
              onClick={() => {
                handleChannelRedirect(item);
              }}
              style={{ cursor: 'pointer' }}
              title="Open channel"
            />
          )}
        </div>
      ),
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: item => <TableItemActions record={item} />,
    },
  ];

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        actions={<TableActions />}
        columns={columns}
        bulkActions={<TableBulkActions />}
        resource="Triage"
      />
    </div>
  );
};

export default TriageTickets;
