import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketSources } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketSources = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketSources`,
  async crmId => {
    const sources = await getConnectWiseTicketSources(crmId);
    return sources;
  },
);

export default {};
