import { makeRequest } from '@services/requests/makeRequest';
import {
  CLIENT_PORTAL_ADMIN,
  CLIENT_PORTAL_DEFAULTS,
  CLIENT_PORTAL_CHATS,
  CLIENT_PORTAL_USERS,
} from '@services/requests/requestResources';

export const getClientPortalConfigurations = () =>
  makeRequest('GET', `${CLIENT_PORTAL_ADMIN}/configurations`);

export const getClientPortalConfiguration = id =>
  makeRequest('GET', `${CLIENT_PORTAL_ADMIN}/configurations/${id}`);

export const addClientPortalConfigurations = payload =>
  makeRequest('POST', `${CLIENT_PORTAL_ADMIN}/configurations`, payload);

export const editClientPortalConfigurations = payload =>
  makeRequest(
    'PUT',
    `${CLIENT_PORTAL_ADMIN}/configurations/${payload.id}`,
    payload,
  );

export const deleteClientPortalConfigurations = id =>
  makeRequest('DELETE', `${CLIENT_PORTAL_ADMIN}/configurations/${id}`);

export const removeClientPortalLogo = configurationId =>
  makeRequest(
    'POST',
    `${CLIENT_PORTAL_ADMIN}/configurations/${configurationId}/remove-logo`,
  );

export const getClientPortalDefaultsAutocomplete = () =>
  makeRequest('GET', `${CLIENT_PORTAL_DEFAULTS}/autocomplete`);

export const getClientPortalDefault = () =>
  makeRequest('GET', `${CLIENT_PORTAL_DEFAULTS}`);

export const createClientPortalDefault = payload =>
  makeRequest('POST', `${CLIENT_PORTAL_DEFAULTS}`, payload);

export const updateClientPortalDefault = (defaultId, payload) =>
  makeRequest('PUT', `${CLIENT_PORTAL_DEFAULTS}/${defaultId}`, payload);

export const deleteClientPortalDefault = defaultId =>
  makeRequest('DELETE', `${CLIENT_PORTAL_DEFAULTS}/${defaultId}`);

export const getNewChatTeamsMessageVariables = () =>
  makeRequest('GET', `${CLIENT_PORTAL_CHATS}/newChat/templateVariables`);

export const getClientPortalConfigurationOptions = () =>
  makeRequest('GET', `${CLIENT_PORTAL_CHATS}/configurations/autocomplete`);

export const getClientPortalChatMessage = chatId =>
  makeRequest('GET', `${CLIENT_PORTAL_CHATS}/chats/${chatId}/messages`);

export const sendMessageToClientPortal = (chatId, payload) =>
  makeRequest(
    'POST',
    `${CLIENT_PORTAL_CHATS}/chats/${chatId}/messages`,
    payload,
  );

export const closeClientPortalChat = chatId =>
  makeRequest('DELETE', `${CLIENT_PORTAL_CHATS}/chats/${chatId}`);

export const getClientPortalPusherSettings = () =>
  makeRequest('GET', `${CLIENT_PORTAL_CHATS}/get-pusher-settings`);
export const getClientPortalAdmins = () =>
  makeRequest('GET', `${CLIENT_PORTAL_CHATS}/admins`);

export const assignResourceToClientPortalChat = (chatId, assigneeId) =>
  makeRequest('POST', `${CLIENT_PORTAL_CHATS}/chats/${chatId}/assignUser`, {
    assigneeId,
  });

export const getClientPortalDefaultMappings = configId =>
  makeRequest(
    'GET',
    `${CLIENT_PORTAL_ADMIN}/configurations/${configId}/mapping`,
  );

export const addClientPortalDefaultMappings = (configId, payload) =>
  makeRequest(
    'POST',
    `${CLIENT_PORTAL_ADMIN}/configurations/${configId}/mapping`,
    payload,
  );

export const getClientPortalCompanies = configId =>
  makeRequest(
    'GET',
    `${CLIENT_PORTAL_ADMIN}/configurations/${configId}/companies`,
  );

export const pushMessagesToTicket = clientChatId =>
  makeRequest(
    `POST`,
    `${CLIENT_PORTAL_CHATS}/chats/${clientChatId}/push-to-ticket`,
  );

export const pushMessagesToEmail = (clientChatId, payload) =>
  makeRequest(
    'POST',
    `${CLIENT_PORTAL_CHATS}/chats/${clientChatId}/push-to-email`,
    payload,
  );

export const getClientPortalChat = chatId =>
  makeRequest('GET', `${CLIENT_PORTAL_CHATS}/chats/${chatId}`);

export const reopenClientPortalChat = chatId =>
  makeRequest('PUT', `${CLIENT_PORTAL_CHATS}/chats/${chatId}/reopen`);

export const getClientPortalUser = userId =>
  makeRequest('GET', `${CLIENT_PORTAL_USERS}/${userId}`);

export const getClientPortalRoles = () =>
  makeRequest('GET', `${CLIENT_PORTAL_USERS}/roles/`);

export const updateClientPortalUser = payload =>
  makeRequest('PUT', `${CLIENT_PORTAL_USERS}/${payload.id}`, payload);

export const deleteClientPortalUser = id =>
  makeRequest('DELETE', `${CLIENT_PORTAL_USERS}/${id}`);

export const getCrmCompanies = crmId =>
  makeRequest('GET', `${CLIENT_PORTAL_USERS}/companies/${crmId}`);
