import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { currentUserDataSelector } from '@store/selectors';
import {
  ThunkAddChat,
  ThunkGetAvailableUsers,
} from '@store/slices/chats/thunks';
import { ChatsAvailableUsers } from '@store/slices/chats';
import { CHAT_TYPES } from '@constants/chatTypes';

import Checkbox from '@ui/components/common/Checkbox';
import Input from '@ui/components/Auth/Common/Input';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import InputWithAutocomplete from '@ui/components/common/Input/InputWithAutocomplete';
import DropDown from '@ui/components/Auth/Common/DropDown';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from './styles';

const CreateChatDialog = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(true);
  const [groupChat, setGroupChat] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = useSelector(currentUserDataSelector);
  const availableUsers = useSelector(ChatsAvailableUsers);

  const submit = values => {
    setLoading(true);
    const payload = {
      name: groupChat ? values.name : null,
      type: groupChat ? CHAT_TYPES.group : CHAT_TYPES.private,
      participants: groupChat
        ? values.participants.map(p => p.value)
        : [values.participants],
    };

    dispatch(ThunkAddChat(payload))
      .unwrap()
      .then(() => {
        setOpen(false);
        enqueueSnackbar('Successfully created', { variant: 'success' });
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetAvailableUsers());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
    >
      <DialogTitle>Create new chat</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Group Chat"
                labelSize={2}
                contentSize={10}
                fullWidth
                content={
                  <Field
                    name="groupChat"
                    id="groupChat"
                    render={Checkbox}
                    input={{
                      value: groupChat,
                      onChange: () => {
                        setGroupChat(!groupChat);
                      },
                    }}
                    disabled={loading}
                  />
                }
              />
              {groupChat ? (
                <>
                  <FieldWrapper
                    label="Name"
                    labelSize={2}
                    contentSize={10}
                    classNameContainer={classes.fullWidth}
                    fullWidth
                    content={
                      <Field
                        name="name"
                        id="name"
                        render={Input}
                        disabled={loading}
                      />
                    }
                  />
                  <FieldWrapper
                    label="Participants"
                    labelSize={2}
                    contentSize={10}
                    classNameContainer={classes.fullWidth}
                    fullWidth
                    content={
                      <InputWithAutocomplete
                        name="participants"
                        options={availableUsers.filter(
                          user => user.value !== currentUser.id,
                        )}
                        disabled={loading}
                        disableCloseOnSelect
                        limitTags={5}
                        getOptionValue={i => i}
                        getOptionLabel={i => i.label}
                        getOptionSelected={(options, value) =>
                          options.value === value.value
                        }
                      />
                    }
                  />
                </>
              ) : (
                <FieldWrapper
                  label="User"
                  labelSize={2}
                  contentSize={10}
                  classNameContainer={classes.fullWidth}
                  fullWidth
                  content={
                    <Field
                      name="participants"
                      options={availableUsers.filter(user => user.info)}
                      disabled={loading}
                      render={DropDown}
                    />
                  }
                />
              )}
              <div className={classes.buttonContainer}>
                <ReusableButton
                  viewType="red"
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
                <ReusableButton
                  label="Create"
                  type="submit"
                  onClick={() => {}}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateChatDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default CreateChatDialog;
