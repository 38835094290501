import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useAsyncDispatch = (asyncFn, actionType) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    let isMounted = true;
    asyncFn().then(data => {
      setLoading(false);
      if (isMounted) dispatch(actionType(data));
    });
    return () => {
      isMounted = false;
    };
  }, [asyncFn, actionType, dispatch]);
  return loading;
};

export default useAsyncDispatch;
