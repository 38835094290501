export const optInSettings = [
  { id: 'sendSms', label: 'Send SMS' },
  { id: 'sendEmail', label: 'Send Email' },
  { id: 'updatePhone', label: 'Update Contact Phone' },
  { id: 'updateEmail', label: 'Update Contact Email' },
  { id: 'createPhone', label: 'Create Contact Cell Phone' },
  { id: 'lock', label: 'Lock user input' },
  { id: 'notateTicket', label: 'Add note to ticket' },
];

export const formSet = { labelSize: 6, inputSize: 3 };
