import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { reopenClientPortalChat } from '@services/requests/clientPortal';

export const ThunkReopenClientPortalChat = createAsyncThunk(
  `${CLIENT_PORTALS}/reopenClientPortalChat`,
  ({ chatId }) => reopenClientPortalChat(chatId),
);

export default {};
