import React from 'react';
import { ReactComponent as IntegrationIcon } from '@assets/icons/key.svg';
import List from './Integrations';

const Icon = () => <IntegrationIcon alt="keyIcon" />;

export default {
  list: List,
  icon: Icon,
};
