import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, PictureAsPdfRounded } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import Table from '@components/Table';
import ReusableButton from '@common/Button/Button';

import { BILLING } from '@constants/routes';
import { TableTypes } from '@components/Table/constants';

import getFormattedDate from '@utils/getFormattedDate';

const Invoices = () => {
  const { push } = useHistory();

  const openExternalLink = useCallback(
    link => () => {
      window.open(link, '_blank');
    },
    [],
  );

  const handleBack = useCallback(() => {
    push(BILLING);
  }, [push]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        margin: '0px auto',
        boxSizing: 'border-box',
        padding: '50px',
        background: '#8080801a',
        display: 'grid',
        gridTemplateRows: '50px 1fr',
        gap: '5px',
      }}
    >
      <div>
        <ReusableButton label="Back" onClick={handleBack} />
      </div>
      <Table
        resource="/Billing/invoices"
        columns={[
          {
            name: 'Invoice #',
            key: 'number',
            sortable: true,
            searchable: true,
            width: 200,
          },
          {
            name: 'Date',
            key: 'created',
            type: TableTypes.actions,
            accessor: i => <>{getFormattedDate(i.created)}</>,
            width: 200,
          },
          {
            name: 'Total',
            key: 'total',
            sortable: true,
            searchable: true,
            type: TableTypes.actions,
            width: 150,
            accessor: i => (
              <>
                {`${
                  i.currency === 'usd' ? '$' : `${i.currency.toUpperCase()}`
                }${Number(i.total / 100).toFixed(2)}`}
              </>
            ),
          },
          {
            name: 'Description',
            key: 'description',
            sortable: true,
            searchable: true,
          },
          {
            name: 'Status',
            key: 'status',
            sortable: true,
            searchable: true,
            type: TableTypes.actions,
            width: 120,
            accessor: i => (
              <span style={{ textTransform: 'capitalize' }}>{i.status}</span>
            ),
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            width: 120,
            labelAlignment: 'center',
            accessor: i => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  gap: '10px',
                }}
              >
                <Tooltip title="Payment" style={{ cursor: 'pointer' }}>
                  <Link onClick={openExternalLink(i.receiptUrl)} />
                </Tooltip>

                <Tooltip title="Download Invoice" style={{ cursor: 'pointer' }}>
                  <PictureAsPdfRounded
                    onClick={openExternalLink(i.invoicePdf)}
                  />
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Invoices;
