import React from 'react';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import { Box } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';
import { basePermissions } from '@constants/permissions';
import Authorize from '@common/Authorize/Authorize';
import useStyles from './styles';
import { sanitizeProps } from '../helpers';

const ListActions = ({ customResource, handleAddRecord, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.listActionContainer}>
      <Authorize
        {...sanitizeProps(props)}
        customResource={customResource}
        requiredPermissions={[basePermissions.create]}
      >
        <ReusableButton
          onClick={handleAddRecord}
          label=""
          toolTip="Add settings"
          size="md"
        >
          <>
            <Add className={classes.plusIcon} />
            Create
          </>
        </ReusableButton>
      </Authorize>
    </Box>
  );
};

ListActions.propTypes = {
  handleAddRecord: PropTypes.func,
  customResource: PropTypes.string,
};

export default ListActions;
