import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cx from 'classnames';
import DropDown from '@components/Auth/Common/DropDown';
import { ssidOptions, thresholdOptions, periodOptions } from './options';
import useStyles from './styles';

const SsidRangeTimeAlert = ({ filterText }) => {
  const classes = useStyles();
  return (
    <div className={classes.flexDisplaying}>
      <span>Clients on</span>
      <Field
        id="ssidNum"
        name="ssidNum"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
        component={DropDown}
        options={ssidOptions}
      />
      <span>width</span>
      <Field
        id="threshold"
        name="threshold"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.periodWidth)}
        component={DropDown}
        options={thresholdOptions}
      />
      <span>{filterText}</span>
      <Field
        id="duration"
        name="duration"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.sizesWidth)}
        component={DropDown}
        options={periodOptions}
      />
      <span>minutes</span>
    </div>
  );
};

SsidRangeTimeAlert.propTypes = {
  filterText: PropTypes.string,
};

export default SsidRangeTimeAlert;
