import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import useStyles from '@components/DataSources/Integrations/pages/create/styles';
import { SECURITY_INTEGRATION } from '@constants/routes';
import { securityIntegrationSources } from '@constants/integrationSources';
import { useRedirect } from 'react-admin';

import { ThunkGetMsAuthSetuperLink } from '@store/slices/securityIntegrations/thunks';

import SelectIntegration from './components/SelectIntegration';
import BaseSettings from './components/BaseSettings';

const getSteps = () => ['Select integration', 'Input base settings'];

const CreateSecurityIntegrations = () => {
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const [integrationType, setIntegrationType] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleBaseSettingsSubmit = () => {
    redirect(SECURITY_INTEGRATION);
  };

  const handleSelectType = name => {
    if (name === securityIntegrationSources.MicrosoftAuthenticator.name) {
      dispatch(ThunkGetMsAuthSetuperLink())
        .unwrap()
        .then(res => {
          if (res?.data) {
            redirect(res?.data);
          }
        });
    } else {
      setIntegrationType(name);
      handleNext();
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <SelectIntegration handleSelect={handleSelectType} />;
      case 1:
        return (
          <BaseSettings
            integrationType={integrationType}
            onSubmit={handleBaseSettingsSubmit}
            onBack={handleBack}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <Stepper activeStep={activeStep} classes={{ root: classes.root }}>
        {steps.map(label => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
    </div>
  );
};

export default CreateSecurityIntegrations;
