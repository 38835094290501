import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contentWrapper: {
    paddingBottom: '8px',
    minWidth: '250px',
  },
  loaderWrapper: {
    height: '230px',
  },
  number: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 700,
  },
  loader: {
    height: '24px',
    width: '24px',
  },
}));

export default useStyles;
