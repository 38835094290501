import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { removeClientPortalLogo } from '@services/requests/clientPortal';

export const ThunkRemoveClientPortalLogo = createAsyncThunk(
  `${CLIENT_PORTALS}/removeClientPortalLogo`,
  ({ resourceId }) =>
    resourceId ? removeClientPortalLogo(resourceId) : undefined,
);

export default {};
