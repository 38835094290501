import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    backgroundColor: '#F5F5F5',
    margin: '0 0 5px 0',
    width: '98%',
    minHeight: '70px',
    border: '1px solid #D9D5D5',
    boxShadow: 'none',
  },
  chip: {
    margin: '8px 3px',
  },
  flexWrapper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    marginRight: '0.625rem',
  },
  labelWrapper: {
    width: '100%',
    textAlign: 'left',
    margin: '5px',
    color: '#2F2F2F',
    fontSize: '12px',
    fontWeight: '600',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actionButton: {
    justifyContent: 'flex-end',
  },
  btnWrapper: {
    margin: '0 0.25rem',
    '&:last-child': {
      margin: '0 0.25rem',
    },
  },
}));

export default useStyles;
