import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA } from '@store/slices/resources';
import { generatePassword } from '@services/requests/secureData';

export const ThunkGeneratePassword = createAsyncThunk(
  `${SECURE_DATA}/generatePassword`,
  ({ params }) => generatePassword(new URLSearchParams(params)),
);

export default {};
