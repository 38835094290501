import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingConfigurationsByCrmType } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingConfigurationByCrmType = createAsyncThunk(
  `${MESSAGING}/getMessagingConfigurationsByCrmType`,
  async crmType => {
    const configurations = await getMessagingConfigurationsByCrmType(crmType);
    return configurations;
  },
);

export default {};
