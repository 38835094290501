import React from 'react';

import storage from '@constants/localStorage';

import useStyles from './styles';

const AppLogo = () => {
  const classes = useStyles();
  const logoUrl = sessionStorage.getItem(storage.BASE_APP_LOGO);

  return (
    <div>
      <img src={logoUrl} alt="" className={classes.appLogo} />
    </div>
  );
};

export default AppLogo;
