import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  BillingDataSelector,
  DiscountDataSelector,
} from '@store/slices/billing';

import { AI_PRICE_PLAN } from '@constants/routes';

import ChangeCardDataDialog from '@ui/pages/billing/Billing/dialogs/ChangeCardDataDialog';
import ApplyDealerCodeDialog from '@ui/pages/billing/Billing/dialogs/ApplyDealerCodeDialog';
import ChangeBillingAddressDialog from '@ui/pages/billing/Billing/dialogs/ChangeBillingAddressDialog';
import ChangeBillingEmailDialog from '@ui/pages/billing/Billing/dialogs/ChangeBillingEmailDialog';

import useStyles from '../styles';

const BillingActions = () => {
  const [
    openChangeBillingAddressDialog,
    setOpenChangeBillingAddressDialog,
  ] = useState(false);
  const [
    openChangeBillingEmailDialog,
    setOpenChangeBillingEmailDialog,
  ] = useState(false);
  const [openApplyDealerCodeDialog, setOpenApplyDealerCodeDialog] = useState(
    false,
  );
  const [openChangeCardDataDialog, setOpenChangeCardDataDialog] = useState(
    false,
  );

  const classes = useStyles();
  const { push } = useHistory();
  const billingData = useSelector(BillingDataSelector);
  const discountData = useSelector(DiscountDataSelector);

  if (billingData.isDemoSubscription) return <></>;
  return (
    <div>
      <div className={classes.header}>Actions</div>

      <div className={classes.list}>
        <div
          className={classes.action}
          onClick={() => {
            setOpenChangeCardDataDialog(true);
          }}
          role="presentation"
        >
          Change Card Data
        </div>
        <div
          className={classes.action}
          onClick={() => {
            setOpenChangeBillingEmailDialog(true);
          }}
          role="presentation"
        >
          Change Billing Email
        </div>
        <div
          className={classes.action}
          onClick={() => {
            setOpenChangeBillingAddressDialog(true);
          }}
          role="presentation"
        >
          Change Billing Address
        </div>
        {discountData === 'discountData' && (
          <div
            className={classes.action}
            onClick={() => {
              setOpenApplyDealerCodeDialog(true);
            }}
            role="presentation"
          >
            Apply Dealer Code
          </div>
        )}

        <div
          className={classes.action}
          onClick={() => {
            push(AI_PRICE_PLAN);
          }}
          role="presentation"
        >
          Edit AI VoiceAssist Price Plan
        </div>
      </div>

      {openChangeCardDataDialog && (
        <ChangeCardDataDialog
          onClose={() => {
            setOpenChangeCardDataDialog(false);
          }}
          open={openChangeCardDataDialog}
        />
      )}

      {openApplyDealerCodeDialog && (
        <ApplyDealerCodeDialog
          open={openApplyDealerCodeDialog}
          onClose={() => {
            setOpenApplyDealerCodeDialog(false);
          }}
        />
      )}

      {openChangeBillingAddressDialog && (
        <ChangeBillingAddressDialog
          onClose={() => {
            setOpenChangeBillingAddressDialog(false);
          }}
          open={openChangeBillingAddressDialog}
        />
      )}

      {openChangeBillingEmailDialog && (
        <ChangeBillingEmailDialog
          onClose={() => {
            setOpenChangeBillingEmailDialog(false);
          }}
          open={openChangeBillingEmailDialog}
        />
      )}
    </div>
  );
};

export default BillingActions;
