import { createAsyncThunk } from '@reduxjs/toolkit';
import { EXTRA_RESOURCES } from '@store/slices/resources';
import { getExtraResources } from '@services/requests/subscriptions';

export const ThunkGetAvailableExtraResources = createAsyncThunk(
  `${EXTRA_RESOURCES}/getAvailableOptions`,
  async () => {
    const res = await getExtraResources();
    return res;
  },
);

export default {};
