import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getIntegrations } from '@ui/components/Ticketing/Integrations/helpers';

import CreateCrm from '@ui/components/Ticketing/Integrations/pages/createCrm/CreateCrm';
import Loading from '@ui/components/common/Loading/Loading';

import useStyles from '../styles';

const PsaDefaultSetup = ({ handleForward }) => {
  const [loading, setLoading] = useState(true);
  const [showSetup, setShowSetup] = useState(false);

  const classes = useStyles();

  const onMount = useCallback(() => {
    setLoading(true);
    getIntegrations()
      .then(res => {
        if (res?.length) {
          setShowSetup(false);
        } else {
          setShowSetup(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.itemContainer}>
      {showSetup ? (
        <CreateCrm showSmallCrmIcon makeStepForward={handleForward} />
      ) : (
        'Your PSA Connection is already configured.'
      )}
    </div>
  );
};

PsaDefaultSetup.propTypes = {
  handleForward: PropTypes.func.isRequired,
};

export default PsaDefaultSetup;
