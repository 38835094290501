import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloClientsByDefaultId } from '@services/requests/haloTickets';

export const ThunkGetHaloClientsByDefaultId = createAsyncThunk(
  `${HALO_TICKET}/getHaloClients`,
  async crmDefaultId => {
    const clients = await getHaloClientsByDefaultId(crmDefaultId);
    return clients;
  },
);

export default {};
