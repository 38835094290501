import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import { ThunkGetMessagingConfigurationByCrmType } from '@store/slices/messaging/thunks';
import {
  ThunkCheckExistingMessagingChannelFromTicket,
  ThunkCreateMessagingChannelFromTicket,
  ThunkGetCrmTicketPhoneNumber,
} from '@store/slices/crmTicket/thunks';
import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';

import { requiredValidator } from '@utils/validators';
import { MESSAGING_CHANNEL } from '@constants/routes';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';
import Input from '@ui/components/Auth/Common/Input';
import DropDown from '@ui/components/Auth/Common/DropDown';
import FormSwitch from '@ui/components/Auth/Common/FormSwitch';

import useStyles from '../../PsaTickets/styles';

const CreateMessagingChannelDialog = React.memo(
  ({
    open,
    setOpen,
    record,
    crmDefaultId,
    crmType,
    handleAssignContactClick,
  }) => {
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [mode, setMode] = useState(0);
    const [channelInfo, setChannelInfo] = useState(undefined);
    // form states
    const [configurations, setConfigurations] = useState([]);
    const [predefinedPhone, setPredefinedPhone] = useState(null);
    const classes = useStyles();

    const handleClose = () => {
      setOpen(false);
    };

    const dispatch = useDispatch();

    const submit = async values => {
      setProcessing(true);
      let formattedPhone = '';

      await dispatch(ThunkBeautifyPhoneNumber(values.userPhone))
        .unwrap()
        .then(res => {
          formattedPhone = res.data;
        });

      const payload = {
        ...values,
        userPhone: formattedPhone,
        ticketId: record.id,
      };

      await dispatch(
        ThunkCreateMessagingChannelFromTicket({
          crmId: crmDefaultId,
          payload,
        }),
      )
        .unwrap()
        .then(res => {
          window.open(
            `#${MESSAGING_CHANNEL.replace(
              ':configurationId',
              payload.messagingConfigurationId,
            ).replace(':channelId', res.channelId)}`,
            '_blank',
          );
          handleClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    };

    const validate = values => ({
      userPhone: requiredValidator(values.userPhone),
      messagingConfigurationId: requiredValidator(
        values.messagingConfigurationId,
      ),
      smsMessage: requiredValidator(values.smsMessage),
    });

    const onMount = useCallback(async () => {
      setLoading(true);

      const channel = await dispatch(
        ThunkCheckExistingMessagingChannelFromTicket({
          crmId: crmDefaultId,
          ticketId: record.id,
        }),
      ).unwrap();

      if (!channel.isExists) {
        await Promise.all([
          new Promise(res =>
            res(
              dispatch(
                ThunkGetMessagingConfigurationByCrmType(crmType),
              ).unwrap(),
            ),
          ),
          new Promise(res =>
            res(
              dispatch(
                ThunkGetCrmTicketPhoneNumber({
                  crmId: crmDefaultId,
                  ticketId: record.id,
                }),
              ).unwrap(),
            ),
          ),
        ]).then(res => {
          setConfigurations(res[0]);
          setPredefinedPhone(res[1]);
        });
      } else {
        setChannelInfo(channel);
        setMode(1);
      }

      setLoading(false);
    }, []);

    useEffect(() => {
      onMount();
    }, [onMount]);

    const form = (
      <Form
        onSubmit={submit}
        initialValues={{
          userPhone: predefinedPhone?.contactPhone,
        }}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div>
              <span>Ticket #</span>
              {predefinedPhone?.ticketNumber}
            </div>
            <div>
              <span>Company: </span>
              {predefinedPhone?.companyName}
            </div>
            <div>
              <span>Contact: </span>
              {predefinedPhone?.contactName}
            </div>

            {!record?.contactId && (
              <ReusableButton
                label="Assign Contact"
                onClick={handleAssignContactClick}
              />
            )}
            <FieldWrapper
              label="User Phone"
              labelSize={12}
              contentSize={12}
              fullWidth
              classNameLabelInner={classes.alignLeft}
              content={
                <Field
                  name="userPhone"
                  id="userPhone"
                  render={Input}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Messaging Configuration"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <Field
                  name="messagingConfigurationId"
                  id="messagingConfigurationId"
                  render={DropDown}
                  disabled={processing}
                  options={configurations}
                />
              }
              classNameLabelInner={classes.alignLeft}
            />

            <FieldWrapper
              label="SMS Message"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <Field
                  name="smsMessage"
                  id="smsMessage"
                  render={Input}
                  disabled={processing}
                />
              }
              classNameLabelInner={classes.alignLeft}
            />

            <FieldWrapper
              label="Create Triage"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <Field
                  name="createTriage"
                  id="createTriage"
                  render={FormSwitch}
                  disabled={processing}
                />
              }
              classNameLabelInner={classes.alignLeft}
            />

            <div className={classes.formActions}>
              <ReusableButton
                label="Cancel"
                onClick={() => {
                  handleClose();
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
                loading={processing}
              />
            </div>
          </form>
        )}
      />
    );

    const placeholder = (
      <div>
        Channel is already exist.
        <br />
        <Link
          to={MESSAGING_CHANNEL.replace(
            ':configurationId',
            channelInfo?.configurationId,
          ).replace(':channelId', channelInfo?.channelId)}
        >
          Go to Channel
        </Link>
        <br />
        <div className={classes.formActions}>
          <ReusableButton
            label="Cancel"
            onClick={() => {
              handleClose();
            }}
            disabled={processing}
          />
        </div>
      </div>
    );

    return (
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Create SMS Channel</DialogTitle>
        <DialogContent>
          {loading ? <Loading /> : <>{mode === 1 ? placeholder : form}</>}
        </DialogContent>
      </Dialog>
    );
  },
);

CreateMessagingChannelDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  crmDefaultId: PropTypes.number,
  record: PropTypes.shape({
    contactId: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  crmType: PropTypes.string,
  handleAssignContactClick: PropTypes.func,
};

export default CreateMessagingChannelDialog;
