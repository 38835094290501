export const booleanOptions = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
  {
    label: 'All',
    value: '',
  },
];

export default {};
