import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import {
  addAssigneeUri,
  crmTicketDefaults,
  dataViews,
  deletePolicyUri,
  getServiceNowSourcesUrl,
  getTenantUsersAutocompleteUri,
  integrationBoards,
  integrationBoardStatuses,
  integrationBoardTypes,
  integrationCallers,
  integrationCategories,
  integrationCompanies,
  integrationGroups,
  integrationImpacts,
  integrationPriority,
  integrationSources,
  integrationStatusesAndTypes,
  integrationSyncCompanies,
  integrationSyncUsers,
  integrationUrgency,
  ticketCategories,
  ticketing,
  toggleAcknowledgeUri,
  user,
} from '@constants/appRoutes';
import crmSources from '@constants/crmSources';
import connectWiseImage from '@assets/crms/CW_icon.png';
import autoTaskImage from '@assets/crms/autotask.png';
import kaseyaImage from '@assets/crms/kaseya.png';
import serviceNowImage from '@assets/crms/servicenow.png';
import syncroImage from '@assets/crms/syncro.png';
import putData from '@services/api/common/putData';
import postData from '@services/api/common/postData';
import deleteData from '@services/api/common/deleteData';
import postWithResponse from '@services/api/common/postWithResponse';
import haloImage from '@assets/crms/halo.png';
import zendeskImage from '@assets/crms/zendesk.png';
import superOpsImage from '@assets/crms/superOps.png';
import microsoft365 from '@assets/crms/microsoft365.png';

const mapToParams = items => items.map(i => `id=${i}`).join('&');

export const getIntegrations = () =>
  getData(appConfig.baseUrl.concat(ticketing));

export const getDefaults = id =>
  getData(`${appConfig.baseUrl.concat(crmTicketDefaults)}${id}/all`);

export const makeDefaultEnabled = id =>
  putData(`${appConfig.baseUrl.concat(crmTicketDefaults)}${id}/makeEnabled`);

export const getById = id =>
  getData(`${appConfig.baseUrl.concat(ticketing)}${id}`);

export const getStatusesAndTypes = crmId =>
  getData(`${appConfig.baseUrl.concat(integrationStatusesAndTypes(crmId))}`);

export const deleteIntegration = id =>
  deleteData(`${appConfig.baseUrl.concat(ticketing)}${id}`, true);

export const deleteDefault = id =>
  deleteData(`${appConfig.baseUrl.concat(crmTicketDefaults)}${id}`);

export const createCrmIntegration = data =>
  postWithResponse(appConfig.baseUrl.concat(ticketing), data);

export const createTicketDefault = data =>
  postData(appConfig.baseUrl.concat(crmTicketDefaults), data);

export const updateIntegration = (id, data) =>
  putData(`${appConfig.baseUrl.concat(ticketing)}${id}`, data);

export const updateTicketDefault = (id, data) =>
  putData(`${appConfig.baseUrl.concat(crmTicketDefaults)}${id}`, data);

export const getCrmImage = name => {
  switch (name) {
    case crmSources.ConnectWise.name:
      return connectWiseImage;
    case crmSources.Autotask.name:
      return autoTaskImage;
    case crmSources.Kaseya.name:
      return kaseyaImage;
    case crmSources.ServiceNow.name:
      return serviceNowImage;
    case crmSources.SyncroMsp.name:
      return syncroImage;
    case crmSources.Halo.name:
      return haloImage;
    case crmSources.Zendesk.name:
      return zendeskImage;
    case crmSources.SuperOps.name:
      return superOpsImage;
    case crmSources.Microsoft.name:
      return microsoft365;
    default:
      return null;
  }
};

export const getCrmLabel = name => {
  switch (name) {
    case crmSources.ConnectWise.name:
      return crmSources.ConnectWise.label;
    case crmSources.Autotask.name:
      return crmSources.Autotask.label;
    case crmSources.Kaseya.name:
      return crmSources.Kaseya.label;
    case crmSources.ServiceNow.name:
      return crmSources.ServiceNow.label;
    case crmSources.SyncroMsp.name:
      return crmSources.SyncroMsp.label;
    case crmSources.Halo.name:
      return crmSources.Halo.label;
    case crmSources.Zendesk.name:
      return crmSources.Zendesk.label;
    case crmSources.SuperOps.name:
      return crmSources.SuperOps.label;
    default:
      return null;
  }
};

export const getBoardsOptions = crmId =>
  getData(`${appConfig.baseUrl}${integrationBoards(crmId)}`);

export const getPriorityOptions = crmId =>
  getData(`${appConfig.baseUrl}${integrationPriority(crmId)}`);

export const getIntegrationSources = crmId =>
  getData(`${appConfig.baseUrl}${integrationSources(crmId)}`);

export const getIntegrationCompanies = crmId =>
  getData(`${appConfig.baseUrl}${integrationCompanies}${crmId}`);

export const getTicketCategories = crmId =>
  getData(`${appConfig.baseUrl}${ticketCategories(crmId)}`);

export const syncCompanies = (crmId, filterModel) =>
  postData(
    `${appConfig.baseUrl}${integrationSyncCompanies}${crmId}`,
    filterModel,
  );

export const syncUsers = crmId =>
  postData(`${appConfig.baseUrl}${integrationSyncUsers}${crmId}`, null);

export const verifyCredentials = crmId =>
  putData(`${appConfig.baseUrl}${ticketing}${crmId}/verify`, null);

export const getBoardTypes = (crmId, boardId) =>
  getData(`${appConfig.baseUrl}${integrationBoardTypes(crmId)}${boardId}`);

export const getBoardStatuses = (crmId, boardId) =>
  getData(`${appConfig.baseUrl}${integrationBoardStatuses(crmId)}${boardId}`);

export const toggleAcknowledge = data =>
  putData(`${appConfig.baseUrl}${toggleAcknowledgeUri}`, data);

export const getTenantUsersAutocomplete = () =>
  getData(`${appConfig.baseUrl}${getTenantUsersAutocompleteUri}`);

export const addAssignee = (userId, itemIds, dataViewId) =>
  putData(
    `${
      appConfig.baseUrl
    }${addAssigneeUri}${userId}?dataViewId=${dataViewId}&${mapToParams(
      itemIds,
    )}`,
  );

export const deletePolicy = itemId =>
  deleteData(`${appConfig.baseUrl.concat(deletePolicyUri)}${itemId}`);

export const deleteAuditView = itemId =>
  deleteData(`${appConfig.baseUrl.concat(dataViews)}${itemId}`);

export const deleteUser = itemId =>
  deleteData(`${appConfig.baseUrl.concat(user)}${itemId}`);

export const getServiceNowSources = () =>
  getData(`${appConfig.baseUrl}${getServiceNowSourcesUrl}`);

export const getCallers = crmId =>
  getData(`${appConfig.baseUrl}${integrationCallers(crmId)}`);

export const getGroups = crmId =>
  getData(`${appConfig.baseUrl}${integrationGroups(crmId)}`);

export const getUrgency = crmId =>
  getData(`${appConfig.baseUrl}${integrationUrgency(crmId)}`);

export const getImpacts = crmId =>
  getData(`${appConfig.baseUrl}${integrationImpacts(crmId)}`);

export const getCategories = crmId =>
  getData(`${appConfig.baseUrl}${integrationCategories(crmId)}`);

export const cloneCrmDefault = defaultId =>
  postWithResponse(
    `${appConfig.baseUrl}${crmTicketDefaults}clone/${defaultId}`,
    null,
  );

export const getHaloCrmClients = crmId =>
  getData(`${appConfig.baseUrl}/Integration/halo/${crmId}/clients`);

export const getHaloCrmSites = (crmId, clientId) =>
  getData(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/sites?clientId=${clientId}`,
  );

export const getHaloCrmUsers = (crmId, clientId, siteId) =>
  getData(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/users?clientId=${clientId}&siteId=${siteId}`,
  );

export const getHaloCrmRequiredFields = (crmId, ticketType) =>
  getData(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/ticketTypes/${ticketType}//fields/required`,
  );

export const getHaloCrmCategories = (crmId, ticketType) =>
  getData(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/ticketTypes/${ticketType}/categories`,
  );

export const getHaloCrmImpacts = crmId =>
  getData(`${appConfig.baseUrl}/Integration/${crmId}/impacts`);

export const getHaloCrmUrgency = crmId =>
  getData(`${appConfig.baseUrl}/Integration/${crmId}/urgency`);

export const getHaloCrmTicketTypes = crmId =>
  getData(`${appConfig.baseUrl}/Integration/halo/${crmId}/ticketTypes`);

export const getHaloCrmTeams = crmId =>
  getData(`${appConfig.baseUrl}/Integration/halo/${crmId}/teams`);

export const getHaloCrmStatuses = crmId =>
  getData(`${appConfig.baseUrl}/Integration/halo/${crmId}/statuses`);

export const getHaloServiceLevelAgreements = crmId =>
  getData(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/serviceLevelAgreements`,
  );

export const getHaloPriorities = (crmId, slaId) =>
  getData(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/priorities?slaId=${slaId}`,
  );

export const validateHaloIntegrationDefaults = (crmId, data) =>
  postWithResponse(
    `${appConfig.baseUrl}/Integration/halo/${crmId}/validate`,
    data,
  );
