import React from 'react';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import useStyles from '../styles';

const ButtonsBlock = ({ loading, handleBack, handleSubmit, selectedNames }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonBlock}>
      <ReusableButton
        size="md"
        label="Back"
        onClick={handleBack}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <ReusableButton
        size="md"
        viewType="black"
        label="Submit"
        onClick={() => handleSubmit(selectedNames)}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
    </div>
  );
};

ButtonsBlock.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedNames: PropTypes.arrayOf(PropTypes.string),
};

export default ButtonsBlock;
