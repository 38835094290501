/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, isPending } from '@reduxjs/toolkit';
import { CHATS } from '../resources';
import {
  ThunkGetUserChats,
  ThunkGetChatMessages,
  ThunkGetAvailableUsers,
  ThunkAddChat,
  ThunkSendChatMessage,
} from './thunks';

const chats = createEntityAdapter({
  selectId: chat => chat.id,
});

const chatsSelector = chats.getSelectors(state => state[CHATS]);

const chatsSlice = createSlice({
  name: CHATS,
  initialState: chats.getInitialState({
    isLoading: true,
    messages: [],
    activeChatId: null,
    notifications: {},
    availableUsers: [],
  }),
  reducers: {
    ActionChatSetActive: (state, action) => {
      if (state.activeChatId) {
        chats.updateOne(state, {
          id: state.activeChatId,
          changes: { isActive: false },
        });
      }
      state.activeChatId = action.payload;
      chats.updateOne(state, {
        id: action.payload,
        changes: { isActive: true },
      });
    },
    ActionSetNotifications: (state, { payload }) => {
      state.notifications = payload;
      state.ids = state.ids.sort((a, b) =>
        (state.notifications[b]?.length || 0) <
        (state.notifications[a]?.length || 0)
          ? -1
          : 1,
      );
    },
    ActionRemoveNotifications: (state, { payload }) => {
      state.notifications[payload] = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(ThunkGetUserChats.fulfilled, (state, { payload }) => {
        chats.setAll(state, payload);
        state.isLoading = false;
      })

      .addCase(ThunkGetChatMessages.fulfilled, (state, { payload }) => {
        state.messages = payload;
      })

      .addCase(ThunkGetAvailableUsers.fulfilled, (state, { payload }) => {
        state.availableUsers = payload;
      })

      .addCase(ThunkAddChat.fulfilled, (state, { payload }) => {
        chats.addOne(state, payload);
      })

      .addCase(ThunkSendChatMessage.fulfilled, (state, { payload }) => {
        state.messages.push(payload);
      })

      .addMatcher(isPending(ThunkGetUserChats), state => {
        state.isLoading = true;
      });
  },
});

export default chatsSlice.reducer;

export const {
  ActionChatSetActive,
  ActionSetNotifications,
  ActionRemoveNotifications,
} = chatsSlice.actions;

export const ChatsIdsSelector = chatsSelector.selectIds;
export const ChatsLoadingSelector = state => state[CHATS].isLoading;
export const ChatSelector = chatsSelector.selectById;
export const ChatsActiveIdSelector = state => state[CHATS].activeChatId;
export const ChatsNotificationsSelector = state => state[CHATS].notifications;
export const ChatsAvailableUsers = state => state[CHATS].availableUsers;
