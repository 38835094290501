import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingConfiguration } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetMessagingConfiguration = createAsyncThunk(
  `${MESSAGING}/getMessagingConfiguration`,
  async configurationId => {
    const configuration = await getMessagingConfiguration(configurationId);
    return configuration;
  },
);

export default {};
