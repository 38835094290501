import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dividerWithTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    color: 'black',
    whiteSpace: 'nowrap',
    marginTop: '10px',
    fontWeight: 'bold',
  },
  divider: {
    width: '100%',
    height: '2px',
    background: '#0D1835FF',
  },
}));

export default useStyles;
