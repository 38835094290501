import {css} from '@linaria/core';

import type {CalculatedColumn, SortColumn} from './types';
import type {HeaderRowProps} from './HeaderRow';
import SortableHeaderCell from './headerCells/SortableHeaderCell';
import {getCellStyle, getCellClassname} from './utils';
import {useRovingCellRef} from './hooks';

const cellResizable = css`
  touch-action: none;

  &::after {
    content: '';
    cursor: col-resize;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
  }
`;

const cellResizableClassname = `rdg-cell-resizable ${cellResizable}`;

type SharedHeaderRowProps<R, SR> = Pick<HeaderRowProps<R, SR, React.Key>,
    | 'sortColumns'
    | 'onSortColumnsChange'
    | 'allRowsSelected'
    | 'onAllRowsSelectionChange'
    | 'selectCell'
    | 'onColumnResize'
    | 'shouldFocusGrid'>;

export interface HeaderCellProps<R, SR> extends SharedHeaderRowProps<R, SR> {
    column: CalculatedColumn<R, SR>;
    colSpan: number | undefined;
    isCellSelected: boolean;
}

export default function HeaderCell<R, SR>({
                                              column,
                                              colSpan,
                                              isCellSelected,
                                              onColumnResize,
                                              allRowsSelected,
                                              onAllRowsSelectionChange,
                                              sortColumns,
                                              onSortColumnsChange,
                                              selectCell,
                                              shouldFocusGrid
                                          }: HeaderCellProps<R, SR>) {
    const {ref, tabIndex, onFocus} = useRovingCellRef(isCellSelected);
    const sortIndex = sortColumns?.findIndex((sort) => sort.columnKey === column.key);
    const sortColumn =
        sortIndex !== undefined && sortIndex > -1 ? sortColumns![sortIndex] : undefined;
    const sortDirection = sortColumn?.direction;
    const priority = sortColumn !== undefined && sortColumns!.length > 1 ? sortIndex! + 1 : undefined;
    const ariaSort =
        sortDirection && !priority ? (sortDirection === 'ASC' ? 'ascending' : 'descending') : undefined;

    const className = getCellClassname(column, column.headerCellClass, {
        [cellResizableClassname]: column.resizable
    });

    function onPointerDown(event: React.PointerEvent<HTMLDivElement>) {
        if (event.pointerType === 'mouse' && event.buttons !== 1) {
            return;
        }

        const {currentTarget, pointerId} = event;
        const {right} = currentTarget.getBoundingClientRect();
        const offset = right - event.clientX;

        if (offset > 11) {
            // +1px to account for the border size
            return;
        }

        function onPointerMove(event: PointerEvent) {
            const width = event.clientX + offset - currentTarget.getBoundingClientRect().left;
            if (width > 0) {
                onColumnResize(column, width);
            }
        }

        function onLostPointerCapture() {
            currentTarget.removeEventListener('pointermove', onPointerMove);
            currentTarget.removeEventListener('lostpointercapture', onLostPointerCapture);
        }

        currentTarget.setPointerCapture(pointerId);
        currentTarget.addEventListener('pointermove', onPointerMove);
        currentTarget.addEventListener('lostpointercapture', onLostPointerCapture);
    }

    function onSort(ctrlClick: boolean) {
        if (onSortColumnsChange == null) return;
        const {sortDescendingFirst} = column;
        if (sortColumn === undefined) {
            // not currently sorted
            const nextSort: SortColumn = {
                columnKey: column.key,
                direction: sortDescendingFirst ? 'DESC' : 'ASC'
            };
            onSortColumnsChange(sortColumns && ctrlClick ? [...sortColumns, nextSort] : [nextSort]);
        } else {
            let nextSortColumn: SortColumn | undefined;
            if (
                (sortDescendingFirst && sortDirection === 'DESC') ||
                (!sortDescendingFirst && sortDirection === 'ASC')
            ) {
                nextSortColumn = {
                    columnKey: column.key,
                    direction: sortDirection === 'ASC' ? 'DESC' : 'ASC'
                };
            }
            if (ctrlClick) {
                const nextSortColumns = [...sortColumns!];
                if (nextSortColumn) {
                    // swap direction
                    nextSortColumns[sortIndex!] = nextSortColumn;
                } else {
                    // remove sort
                    nextSortColumns.splice(sortIndex!, 1);
                }
                onSortColumnsChange(nextSortColumns);
            } else {
                onSortColumnsChange(nextSortColumn ? [nextSortColumn] : []);
            }
        }
    }

    function onClick() {
        selectCell(column.idx);
    }

    function handleFocus(event: React.FocusEvent<HTMLDivElement>) {
        onFocus(event);
        if (shouldFocusGrid) {
            // Select the first header cell if there is no selected cell
            selectCell(0);
        }
    }

    function getCell() {
        if (column.headerRenderer) {
            return (
                <column.headerRenderer
                    column={column}
                    sortDirection={sortDirection}
                    priority={priority}
                    onSort={onSort}
                    allRowsSelected={allRowsSelected}
                    onAllRowsSelectionChange={onAllRowsSelectionChange}
                    isCellSelected={isCellSelected}
                />
            );
        }

        if (column.sortable) {
            return (
                <SortableHeaderCell
                    onSort={onSort}
                    sortDirection={sortDirection}
                    priority={priority}
                    isCellSelected={isCellSelected}
                >
                    {column.name}
                </SortableHeaderCell>
            );
        }

        return column.name;
    }


    return (
        <div
            role="columnheader"
            aria-colindex={column.idx + 1}
            aria-selected={isCellSelected}
            aria-sort={ariaSort}
            aria-colspan={colSpan}
            ref={ref}
            // set the tabIndex to 0 when there is no selected cell so grid can receive focus
            tabIndex={shouldFocusGrid ? 0 : tabIndex}
            className={className}
            style={{
                ...getCellStyle(column, colSpan),
                borderRight: (column.key === 'checkbox'&& !column.isLastFrozenColumn)?'none':'1px solid #ddd'
            }}
            onFocus={handleFocus}
            onClick={onClick}
            onPointerDown={column.resizable ? onPointerDown : undefined}
        >
            {getCell()}
        </div>
    );
}
