/*
  This is an util for creation Redux action based on the arguments that you passes
  to createAction(). Execute createAction() will return the function.
  That function will return the object with args that you passed to it.

  @examples
  ***********************************
    action = createAction('ACTION')
    actionObject = action();
    actionObject === {
      type: 'ACTION',
    }
  ***********************************
    action = createAction('ACTION', 'arg', 'someAnotherArg')
    actionObject = action(5, 'some text');
    actionObject === {
      type: 'ACTION',
      arg: 5,
      someAnotherArg: 'some text'
    }
*/

const createAction = (type, ...keys) => (...args) =>
  keys.reduce((prev, key, index) => ({ ...prev, [key]: args[index] }), {
    type,
  });

export default createAction;
