import { parsePhoneNumberFromString } from 'libphonenumber-js';

const phoneValidator = (
  contactPhoneNumber,
  phoneCountryCode,
  isRequired = true,
) => {
  if (!isRequired && !contactPhoneNumber) {
    return undefined;
  }
  if (!contactPhoneNumber) {
    return 'Required field';
  }

  const sanitizedPhoneNumber = contactPhoneNumber.replace(/[\s()-]/g, '');

  if (/\D/.test(sanitizedPhoneNumber)) {
    return 'Phone number contains invalid characters';
  }

  const [countryCode = undefined, phoneCode = ''] = (
    phoneCountryCode || ''
  ).split(' ');
  const phoneNumber = parsePhoneNumberFromString(
    `${phoneCode}${sanitizedPhoneNumber}`,
    countryCode,
  );

  return phoneNumber?.isValid() ? undefined : 'Invalid phone number';
};

export default phoneValidator;
