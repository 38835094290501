import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '10px 0px',
    '& button': {
      marginLeft: '15px',
    },
  },
  fullWidth: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    '& > :nth-child(n/2)': {
      width: '20%',
    },
    '& > :nth-child(2n)': {
      width: '100%',
    },
  },
}));

export default useStyles;
