import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '90%',
    display: 'flex',
    position: 'fixed',
    width: '-webkit-fill-available',
  },
  chatListContainer: {
    position: 'absolute',
    width: '30%',
    height: '93vh',
    overflow: 'auto',
    borderRight: '2px solid lightgrey',
  },
  chatItemContainer: {
    width: '100%',
    height: '55px',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    display: 'flex',
    padding: '0px',
    margin: '10px 0px',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    background: 'inherit',
  },
  chatItemContent: {
    width: '100%',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    padding: '0px 15px',
    '&:hover': {
      background: '#4b505880 !important',
    },
    '& svg': {
      color: '#356d9e',
      marginRight: '10px',
    },
  },
  chatItemActive: {
    background: '#2b2e33',
    color: 'white',
  },
  chatListButtons: {
    padding: '10px 10px',
  },
  chatDotIcon: {
    marginLeft: 'auto',
    alignItems: 'center',
  },
  haveUnread: {
    fontWeight: '800',
  },
}));

export default useStyles;
