import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { sendChatMessage } from '@services/requests/liveChats';

export const ThunkSendChatMessage = createAsyncThunk(
  `${LIVE_CHATS}/getSendChatMessage`,
  payload => sendChatMessage(payload),
);

export default {};
