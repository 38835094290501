export const integrationSources = Object.freeze({
  Meraki: { name: 0, label: 'Meraki' },
  Sophos: { name: 1, label: 'Sophos' },
});

export const integrationSourcesEnum = Object.freeze({
  0: integrationSources.Meraki.label,
  1: integrationSources.Sophos.label,
});

export const securityIntegrationSources = Object.freeze({
  Duo: { name: 0, label: 'Duo Auth API' },
  DuoAdmin: { name: 1, label: 'Duo Admin' },
  DuoWebSdk: { name: 2, label: 'Duo Web Sdk' },
  MicrosoftAuthenticator: { name: 3, label: 'Microsoft Authenticator' },
});

export const securityIntegrationSourcesEnum = Object.freeze({
  0: securityIntegrationSources.Duo.label,
  1: securityIntegrationSources.DuoAdmin.label,
  2: securityIntegrationSources.DuoWebSdk.label,
});
