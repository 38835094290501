import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField, LinearProgress } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';

import {
  ThunkGetChannelHistory,
  ThunkSendChannelMessage,
} from '@store/slices/messaging/thunks';
import {
  MessagingChannelHistorySelector,
  MessagingChannelSelector,
  MessagingConfigurationSelector,
  restoreHistoryOrder,
  reverseHistory,
} from '@store/slices/messaging';

import ImportExportIcon from '@assets/icons/importExport.svg';
import RefreshIcon from '@assets/icons/refresh.svg';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ReusableButton from '@ui/components/common/Button/Button';
import Switch from '@common/FilterInputs/Switch';
import HistoryItem from './HistoryItem';

import useStyles from '../styles';

const History = React.memo(
  ({
    channelId,
    handleInfoHide = () => {},
    hideInfo = false,
    hideExpandButton = false,
  }) => {
    const [message, setMessage] = useState('');
    const [openFullSize, setOpenFullSize] = useState(false);
    const [imageLink, setImageLink] = useState(undefined);
    const [hideMessage, setHideMessage] = useState(false);
    const [asInternal, setAsInternal] = useState(false);
    const [sendProcessing, setSendProcessing] = useState(false);
    const [orderDesc, setOrderDesc] = useState(false);

    const classes = useStyles({
      smallerVersion: hideExpandButton,
    });
    const dispatch = useDispatch();
    const ref = useRef();

    const history = useSelector(MessagingChannelHistorySelector);
    const channel = useSelector(MessagingChannelSelector);
    const configuration = useSelector(MessagingConfigurationSelector);
    const { templateText } = useSelector(state => state.messaging);

    const handleMessageSend = useCallback(() => {
      setSendProcessing(true);
      dispatch(
        ThunkSendChannelMessage({
          channelId,
          payload: { message, hideMessage, asInternal },
        }),
      )
        .unwrap()
        .then(() => {
          setMessage('');
        })
        .finally(() => setSendProcessing(false));
    }, [dispatch, channelId, message, configuration, hideMessage, asInternal]);

    const handleFullSizeOpen = useCallback(item => {
      setOpenFullSize(true);
      setImageLink(item);
    }, []);

    const handleFullImageClose = useCallback(() => {
      setOpenFullSize(false);
      setImageLink(undefined);
    }, []);

    const onMount = useCallback(() => {
      dispatch(ThunkGetChannelHistory({ channelId, orderDesc }));
    }, [dispatch, channelId, orderDesc]);

    const handleRefresh = () => {
      onMount();
    };

    const handleChangeOrder = () => {
      setOrderDesc(!orderDesc);
      dispatch(reverseHistory());
    };

    useEffect(() => {
      onMount();
    }, [onMount]);

    useEffect(() => {
      dispatch(restoreHistoryOrder());
    }, []);

    useEffect(() => {
      if (templateText !== '') {
        setMessage(templateText);
      }
    }, [templateText]);

    useEffect(() => {
      if (!orderDesc) {
        ref.current.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, [history, ref, orderDesc]);

    return (
      <div className={classes.tripleBox}>
        <div className={classes.boxHeader}>
          History
          {!hideExpandButton && (
            <ReusableButton
              label={!hideInfo ? 'Expand' : 'Collapse'}
              onClick={() => {
                handleInfoHide(!hideInfo);
              }}
            />
          )}
          {hideExpandButton && (
            <div>
              <ActionFieldItem
                alt="Order"
                handler={() => {
                  handleChangeOrder();
                }}
                toolTip="Change Order"
                icon={ImportExportIcon}
              />
              <ActionFieldItem
                alt="Order"
                handler={() => {
                  handleRefresh();
                }}
                toolTip="Refresh"
                icon={RefreshIcon}
              />
            </div>
          )}
        </div>

        <div className={classes.historyItemList}>
          {history.map(item => (
            <HistoryItem
              key={item.messageSid}
              {...item}
              clientNumber={channel.userNumber}
              handleFullSizeOpen={handleFullSizeOpen}
              useAltDesignForMessages={hideExpandButton}
            />
          ))}

          <div ref={ref} />
        </div>
        <div className={classes.chatInput}>
          <TextField
            variant="outlined"
            className={classes.chatTextInput}
            multiline
            minRows={1}
            maxRows={3}
            value={message}
            onChange={({ target }) => {
              setMessage(target.value);
            }}
            onKeyPress={() => {
              if (window.event.ctrlKey && window.event.keyCode === 13) {
                handleMessageSend(message);
              }
            }}
            disabled={channel.isClosed || sendProcessing}
          />

          <SendIcon
            onClick={() => {
              if (!channel.isClosed) {
                handleMessageSend(message);
              }
            }}
            style={{ cursor: channel.isClosed ? 'not-allowed' : 'pointer' }}
          />
        </div>
        {sendProcessing && <LinearProgress className={classes.progressStyle} />}
        <div className={classes.checkBoxesGrid}>
          <div className={classes.messagingCheckbox}>
            <Switch
              checked={hideMessage}
              input={{
                value: hideMessage,
                onChange: e => {
                  setHideMessage(e.target.checked);
                },
              }}
            />
            <span>Hide sensitive data</span>
          </div>

          <div className={classes.messagingCheckbox}>
            <Switch
              checked={asInternal}
              input={{
                value: asInternal,
                onChange: e => {
                  setAsInternal(e.target.checked);
                },
              }}
            />
            <span>As internal note</span>
          </div>
        </div>

        {openFullSize && imageLink && (
          <div
            className={classes.fullSizeImage}
            onClick={() => {
              handleFullImageClose();
            }}
            role="presentation"
          >
            <img src={imageLink} alt="" />
          </div>
        )}
      </div>
    );
  },
);

History.propTypes = {
  channelId: PropTypes.string,
  handleInfoHide: PropTypes.func,
  hideInfo: PropTypes.bool,
  hideExpandButton: PropTypes.bool,
};

export default History;
