import React from 'react';

import {
  ExitToApp as ExitToAppIcon,
  Mail as MailIcon,
} from '@material-ui/icons';

import { ReactComponent as SettingsIcon } from '@assets/icons/setting.svg';
import { ReactComponent as IconAudit } from '@assets/icons/audit.svg';
import { ReactComponent as IntegrationsIcon } from '@assets/icons/integrations.svg';
import cx from 'classnames';
import Card from '@components/DataSources/Integrations/components/Card';
import Meraki from '@assets/integrationSources/Meraki.png';

import MailBoxCard from '@components/MailBoxesPage/components/MailBoxCard/MailBoxCard';
import useStyles from './TemplateStyles';

const Template = colors => {
  const classes = useStyles(colors);

  const MailBoxCardData = {
    attentionRequired: 0,
    errors: [],
    errorsString: '',
    failed: 39,
    id: 8,
    ignored: 4,
    name: 'veeam',
    parsersCount: 8,
    processed: 170,
    quotaExceeded: 0,
    total: 164,
    totalParsersCount: 59,
  };

  return (
    <div className={classes.template}>
      <div className={classes.appBar}>
        <MailIcon />
        <span className={classes.userName}>User</span>
        <ExitToAppIcon />
      </div>
      <div className={classes.container}>
        <div className={classes.sideBar}>
          <div className={classes.sideBarItem}>
            <SettingsIcon alt="settingsIcon" />
            <span>Home</span>
          </div>
          <div className={cx(classes.sideBarItem, classes.selectedItem)}>
            <IconAudit alt="settingsIcon" />
            <span>Data</span>
          </div>
          <div className={classes.sideBarItem}>
            <IntegrationsIcon alt="settingsIcon" />
            <span>Users</span>
          </div>
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>head1</th>
              <th>head2</th>
              <th>head3</th>
              <th>head4</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>cell1_1</td>
              <td>cell2_1</td>
              <td>cell3_1</td>
              <td>cell4_1</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.mailBoxCard}>
          <MailBoxCard key={MailBoxCardData.id} item={MailBoxCardData} />
        </div>
        <div className={classes.card}>
          <Card item={{ name: 'Meraki' }} img={Meraki} readOnly />
        </div>
      </div>
    </div>
  );
};

export default Template;
