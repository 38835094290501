import appConfig from '@configs/appConfig';
import postData from '@services/api/common/postData';
import { types, typeValues } from '@constants/appRoutes';
import getData from '@services/api/common/getData';
import deleteData from '@services/api/common/deleteData';
import postWithResponse from '@services/api/common/postWithResponse';
import putWithResponse from '@services/api/common/putWithResponse';

// Splits comma separated list with respect to escaped commas
export const splitCsl = list => {
  let start = 0;
  const arr = [];
  for (let i = start; i < list.length; ++i) {
    if (list[i] === ',' && (i === 0 || list[i - 1] !== '\\')) {
      arr.push(list.substr(start, i - start));
      start = i + 1;
    }
  }
  if (start <= list.length) {
    arr.push(list.substr(start, list.length - start));
  }
  return arr;
};

export const createType = data =>
  postWithResponse(appConfig.baseUrl.concat(types), data);

export const getTypes = () => getData(appConfig.baseUrl.concat(types));

export const deleteType = id =>
  deleteData(appConfig.baseUrl.concat(types).concat(id));

export const createTypeValue = (id, data) =>
  postWithResponse(appConfig.baseUrl.concat(typeValues).concat(id), data);

export const cloneType = id =>
  postWithResponse(`${appConfig.baseUrl}${types}clone/${id}`, null);

export const deleteTypeValue = id =>
  deleteData(appConfig.baseUrl.concat(typeValues).concat(id));

export const updateTypeValue = (id, data) =>
  putWithResponse(appConfig.baseUrl.concat(typeValues).concat(id), data);

export const checkIfTypeNameIsExist = name =>
  getData(`${appConfig.baseUrl}${types}isNameExists?name=${name}`);

export const editTypeName = (id, name) =>
  postData(`${appConfig.baseUrl}/Types/rename/${id}?name=${name}`);

export const getTypeValue = id =>
  getData(`${appConfig.baseUrl}${typeValues}${id}`);
