import React, { useState } from 'react';
import HookUrl from '@components/WebHooks/components/HookUrl';
import DisplayData from '@components/WebHooks/components/DisplayData';
import useStyles from '../styles';

const Create = () => {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [awaitingHook, setAwaitingHook] = useState(false);
  return (
    <div className={classes.container}>
      <HookUrl
        url={url}
        setUrl={setUrl}
        setAwaitingHook={setAwaitingHook}
        displayTemplates
      />
      <DisplayData awaitingHook={awaitingHook} />
    </div>
  );
};

export default Create;
