import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import useAsync from '@services/api/common/useAsync';
import {
  getEmailGroupVariables,
  getUsersForPhone,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import DeepTemplateMenu from '@components/Ticketing/Notifications/components/DeepTemplateMenu';

const initMenuPosition = {
  mouseX: null,
  mouseY: null,
};

const PhoneInput = ({ disabled, form, templateVariables }) => {
  const [menuPosition, setMenuPosition] = useState(initMenuPosition);
  const [userOptions, setUsersOptions] = useState([]);
  const optionsLoading = useAsync(getUsersForPhone, setUsersOptions);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const groupsLoading = useAsync(getEmailGroupVariables, setGroupsOptions);
  const setDefaultMenuPosition = () => setMenuPosition(initMenuPosition);

  return (
    <>
      <ActionButton
        handler={event =>
          setMenuPosition({
            mouseX: event.clientX + 2,
            mouseY: event.clientY + 4,
          })
        }
        icon={<img src={iconPlus} alt="iconPlus" />}
        toolTip="Add template variable"
        disabled={disabled}
      />
      <InputWithAutocomplete
        name="phoneNumber"
        options={userOptions}
        loading={optionsLoading || groupsLoading}
        disableCloseOnSelect
        freeSolo
        getOptionValue={i => (typeof i === 'object' ? i.value : i)}
        getOptionLabel={i => (typeof i === 'object' ? i.label : i)}
      />
      <DeepTemplateMenu
        menuPosition={menuPosition}
        setDefaultMenuPosition={setDefaultMenuPosition}
        form={form}
        name="phoneNumber"
        templateVariables={templateVariables}
        groupsVariables={groupsOptions}
      />
    </>
  );
};

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.objectOf(PropTypes.any),
  templateVariables: PropTypes.arrayOf(PropTypes.any),
};

export default PhoneInput;
