import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ShowAllBenefitsDialog from '../dialogs/ShowAllBenefitsDialog';

import useStyles from '../styles';

const PricePlan = ({
  name,
  basePriceCents,
  pricePerTechCents,
  scopes,
  isAvailable,
  selected = false,
  handlePlanSelected,
  id,
}) => {
  const [openBenefitsDialog, setOpenBenefitsDialog] = useState(false);

  const classes = useStyles();

  return (
    <div
      className={cx(classes.pricePlan, {
        [classes.selectedPricePlan]: selected,
        [classes.disabledPricePlan]: !isAvailable,
      })}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (isAvailable) {
          handlePlanSelected(id);
        }
      }}
      role="presentation"
    >
      <div className={classes.pricePlanHeader}>{`${name} Plan`}</div>

      <div>
        {`$${Number(basePriceCents / 100).toFixed(2)} / month for 5 techs.`}
      </div>

      <div>
        {pricePerTechCents
          ? `Additional Techs as $${Number(pricePerTechCents / 100).toFixed(
              2,
            )} per Tech.`
          : ''}
      </div>

      <ul>
        {scopes.Header && <>{scopes.Header}</>}
        {scopes.Items.slice(0, 2).map(i => (
          <li key={i}>{i}</li>
        ))}
        <div
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setOpenBenefitsDialog(true);
          }}
          role="presentation"
        >
          View All Benefits
        </div>
      </ul>

      {openBenefitsDialog && (
        <ShowAllBenefitsDialog
          open={openBenefitsDialog}
          onClose={e => {
            e.preventDefault();
            e.stopPropagation();
            setOpenBenefitsDialog(false);
          }}
          scopes={scopes.Items}
          scopeDescriptions={scopes.Header}
          planName={name}
        />
      )}
    </div>
  );
};

PricePlan.propTypes = {
  name: PropTypes.string.isRequired,
  basePriceCents: PropTypes.number.isRequired,
  pricePerTechCents: PropTypes.number.isRequired,
  scopes: PropTypes.shape({
    Header: PropTypes.string.isRequired,
    Items: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  handlePlanSelected: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default PricePlan;
