import localStorageConst from '@constants/localStorage';
import themeTypes from '@constants/themeTypes';
import localStorage from '@services/localStorage';
import { actionTypes } from '../actions';

const getTheme = () => localStorage.getItem(localStorageConst.THEME_TYPE);

const setScheme = theme => {
  localStorage.setItem(localStorageConst.THEME_TYPE, theme);
};

const defaultState = getTheme() || themeTypes.LIGHT;

const themeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_THEME: {
      // eslint-disable-next-line no-unused-expressions
      state === themeTypes.LIGHT
        ? setScheme(themeTypes.DARK)
        : setScheme(themeTypes.LIGHT);
      return getTheme();
    }
    default: {
      return state;
    }
  }
};

export default themeReducer;
