import { makeRequest } from '@services/requests/makeRequest';
import { SMS_CAMPAIGN_GROUPS } from '@services/requests/requestResources';

export const getSmsCampaignGroups = () =>
  makeRequest('GET', `${SMS_CAMPAIGN_GROUPS}`);

export const addSmsCampaignGroup = payload =>
  makeRequest('POST', `${SMS_CAMPAIGN_GROUPS}`, payload);

export const deleteSmsCampaignGroup = id =>
  makeRequest('DELETE', `${SMS_CAMPAIGN_GROUPS}/${id}`);

export const editSmsCampaignGroupItem = (id, payload) =>
  makeRequest('PUT', `${SMS_CAMPAIGN_GROUPS}/items/${id}`, payload);

export const deleteSmsCampaignGroupItem = ids =>
  makeRequest(
    'DELETE',
    `${SMS_CAMPAIGN_GROUPS}/items?${ids.map(i => `id=${i}`).join('&')}`,
  );

export const getCrmUserByCrmId = (crmId, crmCompanyId) =>
  makeRequest(
    'GET',
    `${SMS_CAMPAIGN_GROUPS}/${crmId}/crmUsers${
      crmCompanyId ? `?companyId=${crmCompanyId}` : ''
    }`,
  );

export const addUsersToSmsCampaignGroup = payload =>
  makeRequest('POST', `${SMS_CAMPAIGN_GROUPS}/items/addList`, payload);

export const getSmsCampaignGroup = smsGroupId =>
  makeRequest('GET', `${SMS_CAMPAIGN_GROUPS}/${smsGroupId}`);

export const sendGroupMessage = payload =>
  makeRequest('POST', `${SMS_CAMPAIGN_GROUPS}/sendSms`, payload);
