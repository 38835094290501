export const triggers = [
  {
    label: 'CompanyID',
    value: '1',
  },
  {
    label: 'Title',
    value: '2',
  },
  {
    label: 'ContactID',
    value: '3',
  },
  {
    label: 'Description',
    value: '4',
  },
  {
    label: 'Status',
    value: '5',
  },
  {
    label: 'Priority',
    value: '6',
  },
  {
    label: 'Source',
    value: '7',
  },
  {
    label: 'EstimatedHours',
    value: '10',
  },
  {
    label: 'AssignedResourceID',
    value: '11',
  },
  {
    label: 'DueDateTime',
    value: '12',
  },
  {
    label: 'BillingCodeID',
    value: '13',
  },
  {
    label: 'QueueID',
    value: '14',
  },
  {
    label: 'ContractID',
    value: '16',
  },
  {
    label: 'AssignedResourceRoleID',
    value: '17',
  },
  {
    label: 'IssueType',
    value: '18',
  },
  {
    label: 'SubIssueType',
    value: '19',
  },
  {
    label: 'ConfigurationItemID',
    value: '20',
  },
  {
    label: 'TicketCategory',
    value: '21',
  },
  {
    label: 'TicketType',
    value: '22',
  },
  {
    label: 'CompanyLocationID',
    value: '23',
  },
  {
    label: 'OpportunityID',
    value: '24',
  },
  {
    label: 'ProblemTicketId',
    value: '25',
  },
  {
    label: 'ProjectID',
    value: '26',
  },
  {
    label: 'PurchaseOrderNumber',
    value: '28',
  },
  {
    label: 'ContractServiceBundleID',
    value: '29',
  },
  {
    label: 'ContractServiceID',
    value: '30',
  },
  {
    label: 'ExternalID',
    value: '31',
  },
  {
    label: 'ChangeApprovalBoard',
    value: '32',
  },
  {
    label: 'ChangeApprovalStatus',
    value: '33',
  },
  {
    label: 'ChangeApprovalType',
    value: '34',
  },
  {
    label: 'ChangeInfoField1',
    value: '35',
  },
  {
    label: 'ChangeInfoField2',
    value: '36',
  },
  {
    label: 'ChangeInfoField3',
    value: '37',
  },
  {
    label: 'ChangeInfoField4',
    value: '38',
  },
  {
    label: 'ChangeInfoField5',
    value: '39',
  },
  {
    label: 'CompletedByResourceID',
    value: '40',
  },
  {
    label: 'CompletedDate',
    value: '41',
  },
  {
    label: 'FirstResponseDateTime',
    value: '44',
  },
  {
    label: 'FirstResponseDueDateTime',
    value: '45',
  },
  {
    label: 'IsAssignedToCoManaged',
    value: '47',
  },
  {
    label: 'IsVisibleToCoManaged',
    value: '48',
  },
  {
    label: 'LastActivityDate',
    value: '49',
  },
  {
    label: 'LastCustomerNotificationDateTime',
    value: '51',
  },
  {
    label: 'LastTrackedModificationDateTime',
    value: '52',
  },
  {
    label: 'MonitorID',
    value: '53',
  },
  {
    label: 'MonitorTypeID',
    value: '54',
  },
  {
    label: 'Resolution',
    value: '56',
  },
  {
    label: 'ResolutionPlanDateTime',
    value: '57',
  },
  {
    label: 'ResolutionPlanDueDateTime',
    value: '58',
  },
  {
    label: 'ResolvedDateTime',
    value: '59',
  },
  {
    label: 'ResolvedDueDateTime',
    value: '60',
  },
  {
    label: 'RmaStatus',
    value: '61',
  },
  {
    label: 'RmaType',
    value: '62',
  },
  {
    label: 'RMMAlertID',
    value: '63',
  },
  {
    label: 'ServiceLevelAgreementHasBeenMet',
    value: '64',
  },
  {
    label: 'ServiceLevelAgreementID',
    value: '65',
  },
  {
    label: 'OrganizationalLevelAssociationID',
    value: '76',
  },
];
