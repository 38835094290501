import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paperContainer: {
    width: '100%',
    height: '82vh',
    overflow: 'auto',
    maxWidth: '80vw',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'nowrap',
  },
  row: {
    display: 'grid',
    borderRadius: '8px',
    gridTemplateColumns: props => `1fr repeat(${props.count}, 1fr) 35px`,
  },
  rowCell: {
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
      padding: '10px',
      minWidth: '250px',
      textAlign: 'center',
    },
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    padding: '10px',
    '& > button': {
      marginRight: '10px',
    },
  },
  buttonContainerLast: {
    marginBottom: '25px',
  },
  inputStyles: {
    '& div > div': {
      width: '100%',
    },
  },
  reverserButton: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  searchContainer: {
    display: 'flex',
  },
}));

export default useStyles;
