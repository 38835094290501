import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useStyles from './styles';

const EndpointItem = ({ item, activeEndpoint, setActiveEndpoint }) => {
  const classes = useStyles();
  return (
    <button
      type="button"
      key={item.id}
      className={cx(
        classes.baseButton,
        classes.networksButton,
        item.id === activeEndpoint?.id && classes.activeOrganizationButton,
      )}
      onClick={() => setActiveEndpoint(item)}
    >
      <span>{`${item.type} ${item.hostname}`}</span>
    </button>
  );
};

EndpointItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  activeEndpoint: PropTypes.objectOf(PropTypes.any),
  setActiveEndpoint: PropTypes.func.isRequired,
};

export default EndpointItem;
