import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Pusher from 'pusher-js';
import {
  ThunkGetChatById,
  ThunkGetPusherConfig,
} from '@store/slices/liveChats/thunks';
import Loading from '@common/Loading/Loading';
import { SelectCurrentUserInfo } from '@store/slices/liveChats/selectors';
import { addMessage } from '@store/slices/liveChats';
import LiveChatAdminActionPanel from '@components/LiveChat/components/LiveChatAdminActionPanel';
import LiveChatWindow from '@components/LiveChat/components/LiveChatWindow';
import { LIVE_CHAT } from '@constants/routes';

import useStyles from '../styles';

const LiveChatDetails = ({ match }) => {
  const { id } = match.params;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [pusherConfig, setPusherConfig] = useState(undefined);
  const [chat, setChat] = useState(null);

  const userInfo = useSelector(SelectCurrentUserInfo);

  const pusher = useMemo(() => {
    if (pusherConfig) {
      const p = new Pusher(pusherConfig.key, {
        cluster: pusherConfig.cluster,
      });
      p.subscribe(`${id}`);
      return p;
    }
    return null;
  }, [pusherConfig]);

  useEffect(() => {
    if (pusher) {
      pusher.bind('NewUserMessage', data => {
        const message = {
          ...data,
          senderName: data?.technicianName ?? data.userName,
        };
        dispatch(addMessage(message));
      });

      pusher.bind('NewAdminMessage', data => {
        const message = {
          id: new Date().getTime(),
          message: data.message,
          dateTime: data.dateTime,
          technicianName: data?.userName,
          userName: null,
          type: data.type,
          metadata: data.metadata,
        };
        dispatch(addMessage(message));
      });
    }
    return () => {
      if (pusher) {
        pusher.allChannels().forEach(ch => {
          pusher.unsubscribe(ch.name);
        });
        pusher.disconnect();
      }
    };
  }, [id, pusher, dispatch]);

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      new Promise(res => res(dispatch(ThunkGetPusherConfig()).unwrap())),
      new Promise(res => res(dispatch(ThunkGetChatById({ id })).unwrap())),
    ])
      .then(res => {
        setPusherConfig(res[0]);
        setChat(res[1]);
      })
      .finally(() => setLoading(false));
  }, [dispatch, id]);

  useEffect(() => {
    onMount();
  }, [id, onMount]);

  if (loading) return <Loading />;

  return (
    <div className={classes.liveChatContainer}>
      <div className={classes.liveChatDialogHeader}>
        {userInfo.userName && (
          <>
            <div>
              {`Live Chat with ${userInfo.userName}`}
              {chat?.isClosed && <span>&nbsp;[Closed]</span>}
            </div>
            <div className={classes.liveChatHeaderVerified}>
              {userInfo.isVerified && 'Verified'}
            </div>
          </>
        )}
      </div>
      <div>
        <LiveChatAdminActionPanel
          liveChatId={id}
          onClose={() => push(LIVE_CHAT)}
          isChatClosed={chat?.isClosed}
          selectedResource={chat?.resourceId}
          hasTicket={!!chat?.ticketId}
          ticketLink={chat?.ticketLink}
          ticketId={chat?.ticketId}
          handleDataReload={onMount}
        />
        <LiveChatWindow
          liveChatId={id}
          isChatClosed={chat?.isClosed}
          hasTicket={!!chat?.ticketId}
        />
      </div>
    </div>
  );
};

export default LiveChatDetails;
