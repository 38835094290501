import React, { useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import useStyles from '@components/DataSources/Integrations/pages/create/styles';
import SelectIntegration from '@components/DataSources/Integrations/pages/create/SelectIntegration';
import BaseSettings from '@components/DataSources/Integrations/pages/create/BaseSettings';
import { DATA_SOURCES_INTEGRATION } from '@constants/routes';
import { useRedirect } from 'react-admin';

const getSteps = () => ['Select integration', 'Input base settings'];

const Create = () => {
  const redirect = useRedirect();
  const [integrationType, setIntegrationType] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleBaseSettingsSubmit = () => {
    redirect(DATA_SOURCES_INTEGRATION);
  };

  const handleSelectType = name => {
    setIntegrationType(name);
    handleNext();
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <SelectIntegration handleSelect={handleSelectType} />;
      case 1:
        return (
          <BaseSettings
            integrationType={integrationType}
            onSubmit={handleBaseSettingsSubmit}
            onBack={handleBack}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <Stepper activeStep={activeStep} classes={{ root: classes.root }}>
        {steps.map(label => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
    </div>
  );
};

export default Create;
