import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Container from '@material-ui/core/Container';
import { getTiles } from '@components/Dashboard/helper';
import { actions } from '@store/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';
import NavBar from '@components/Dashboard/components/NavBar';
import DashboardHeader from './DashboardHeader';
import GridLayout from './GridLayout';
import useStyles from './styles';

const Dashboard = () => {
  const lastSelectedId = localStorage.getItem(localStorageConst.DASHBOARD_ID);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleFullScreen = useFullScreenHandle();
  const [dashboardId, setDashboardId] = useState(lastSelectedId || 0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dashboardId !== 0) {
      setLoading(true);
      getTiles(dashboardId)
        .then(d => {
          dispatch(actions.dashboard_setTiles(d));
          localStorage.setItem(localStorageConst.DASHBOARD_ID, dashboardId);
        })
        .catch(e => console.log(e.message))
        .finally(() => setLoading(false));
    }
  }, [dashboardId, dispatch]);

  const handleChangeDashboard = useCallback(
    id => {
      setDashboardId(id);
    },
    [setDashboardId],
  );

  return (
    <div className={classes.wrapper}>
      {loading && <LinearProgress />}
      <Container maxWidth={false} className={classes.container}>
        <DashboardHeader
          onChange={handleChangeDashboard}
          currentDashboardId={dashboardId}
          setDashboardId={setDashboardId}
          loading={loading}
          setLoading={setLoading}
          handleFullScreen={handleFullScreen}
        />
      </Container>
      {dashboardId === 0 ? (
        <Typography style={{ textAlign: 'center' }}>
          Please, select dashboard or add new with button &nbsp;
          <b>Create New Dashboard</b>
        </Typography>
      ) : (
        <>
          <FullScreen
            handle={handleFullScreen}
            className={cx({
              [classes.fullscreen]: handleFullScreen.active,
            })}
          >
            {handleFullScreen.active && (
              <NavBar
                dashboardId={dashboardId}
                setDashboardId={setDashboardId}
              />
            )}
            <GridLayout
              loading={loading}
              setLoading={setLoading}
              isFullScreenActive={handleFullScreen.active}
            />
          </FullScreen>
        </>
      )}
    </div>
  );
};

export default Dashboard;
