import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  bannerCommon: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minWidth: 350,
    borderRadius: '0 25px 25px 0',
  },
  list: {
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '14px',
    lineHeight: '40px',
    '& ul li': {
      color: '#EC6A4E',
    },
    '& ul li span': {
      color: '#FFFFFF',
    },
  },
  bannerBackground: {
    [theme.breakpoints.down('xs')]: { display: 'none' },
    background: 'linear-gradient(160.29deg, #33849E 3.73%, #1A3670 98.46%);',
  },
  bannerBackgroundBlack: {
    background: 'linear-gradient(117.3deg, #626772 0.68%, #111620 95.06%)',
  },
  circle1: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    width: '35vmin',
    height: '35vmin',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  circle2: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    width: '30vmin',
    height: '30vmin',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  circle3: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    width: '25vmin',
    height: '25vmin',
    background: 'rgba(255, 255, 255, 0.2)',
  },
  commonCircleStyles: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  envelope: {
    width: '15vmin',
    height: '15vmin',
  },
  creditCard: {
    width: '50%',
  },
  bannerText: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '28px',
    lineHeight: '52px',
    color: '#FFFFFF',
  },
  tempStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    textAlign: 'center',

    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    color: '#FFFFFF',
  },
  planStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '15px 0',
    width: '90%',
  },
}));

export default useStyles;
