import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { createUserFromInvite } from '@services/requests/usersInvite';

export const ThunkCreateUserFromInvite = createAsyncThunk(
  `${USER}/createUserFromInvite`,
  async ({ payload }) => createUserFromInvite(payload),
);

export default {};
