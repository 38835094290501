import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTicketStatusesByChannelId } from '@services/requests/crmTicket';
import { CRM } from '@store/slices/resources';

export const ThunkGetTicketStatusesByChannelId = createAsyncThunk(
  `${CRM}/getTicketStatusesByChannelId`,
  async ticketId => {
    const statuses = await getTicketStatusesByChannelId(ticketId);
    return statuses;
  },
);

export default {};
