import { createAsyncThunk } from '@reduxjs/toolkit';
import { toggleConnectWiseTicketResolution } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkToggleConnectWiseTicketResolution = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/toggleConnectWiseTicketResolution`,
  async ({ crmDefaultId, ticketId, noteId, resolutionState }) => {
    const res = await toggleConnectWiseTicketResolution(
      crmDefaultId,
      ticketId,
      noteId,
      resolutionState,
    );
    return res;
  },
);

export default {};
