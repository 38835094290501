import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '0 10px',
    flexWrap: 'wrap',
    maxHeight: 'calc(100vh - 56px)',
    height: '100%',
    overflow: 'auto',
  },
}));

export default useStyles;
