import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import useStyles from '../styles';

const Wrapper = ({ label, children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.wrapper}>
      <div className={classes.wrapperLabel}>{label}</div>
      <div>{children}</div>
    </Paper>
  );
};

Wrapper.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Wrapper;
