import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow, Sync } from '@material-ui/icons';

import useStyles from '@common/Styles/actionFieldStyles';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import EditStripeIdModal from '@components/Admin/Subscriptions/EditStripeIdModal';
import EditIcon from '@assets/icons/edit.svg';

const ActionField = ({
  record,
  handleToggleStateClick,
  handleSyncClick,
  loading,
}) => {
  const classes = useStyles();
  const [modalData, setModalData] = useState(null);

  const toggleObject = record?.cancelAtPeriodEnd
    ? {
        icon: <PlayArrow />,
        tip: 'UnPause subscription',
      }
    : {
        icon: <Pause />,
        tip: 'Pause subscription',
      };

  return (
    <div className={classes.container}>
      <ActionFieldItem
        handler={() => {
          setModalData(record);
        }}
        toolTip="Edit"
        icon={EditIcon}
        classNameWrapper={classes.editIcon}
      />
      <ActionFieldItem
        handler={() => handleToggleStateClick(record)}
        toolTip={toggleObject.tip}
        disabled={loading}
      >
        {toggleObject.icon}
      </ActionFieldItem>
      <ActionFieldItem
        handler={() => handleSyncClick(record)}
        toolTip="Sync with stripe"
        disabled={loading}
      >
        <Sync />
      </ActionFieldItem>

      {modalData && (
        <EditStripeIdModal modalData={modalData} setModalData={setModalData} />
      )}
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.shape({
    cancelAtPeriodEnd: PropTypes.bool,
    stripeId: PropTypes.number,
  }),
  handleToggleStateClick: PropTypes.func.isRequired,
  handleSyncClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ActionField;
