import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';
import { NEVER_EXPIRED } from '@constants/commonConstants';

const DateTimeField = ({ source, record = {}, highlightExpired = false }) => {
  const { isExpired, isEternal } = {
    isExpired:
      highlightExpired && moment.utc(moment.now()) > moment.utc(record[source]),
    isEternal:
      highlightExpired && moment.utc(record[source]).year() === NEVER_EXPIRED,
  };

  const displayData = record[source]
    ? moment
        .utc(record[source])
        .local()
        .format(defaultDateFormat)
    : '';
  return (
    <span style={highlightExpired && isExpired ? { color: 'red' } : null}>
      {isExpired ? 'Expired' : isEternal ? 'Never' : displayData}
    </span>
  );
};

DateTimeField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
  highlightExpired: PropTypes.bool,
};

export default DateTimeField;
