import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';
import {
  usageUserUsage,
  usegeStatUrl,
  pricePlanMyPlan,
  pricePlan,
} from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';

export const getUsage = () => getData(appConfig.baseUrl.concat(usageUserUsage));

export const getStat = () => getData(appConfig.baseUrl.concat(usegeStatUrl));

export const getMyPlan = () =>
  getData(appConfig.baseUrl.concat(pricePlanMyPlan));

export const getPlans = () => getData(appConfig.baseUrl.concat(pricePlan));

export const scaleUp = data =>
  postWithResponse(appConfig.baseUrl.concat(pricePlan), data);
