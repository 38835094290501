import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '16px 0',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    margin: '0 2rem 0 0.5rem',
  },
  numberInout: {
    minWidth: '74px',
  },
}));

export default useStyles;
