import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import cx from 'classnames';

import menuIcon from '@assets/icons/menu.svg';
import useStyles from '@common/Styles/actionFieldStyles';

const ITEM_HEIGHT = 45;

const ItemMenu = ({
  options,
  itemsHeightMultiplier = 5.5,
  openOnHover = false,
  disabled,
  toolTip = '',
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={toolTip} interactive>
        <span
          className={cx(classes.container, classes.icon, {
            [classes.disabled]: disabled,
          })}
          onClick={event => {
            if (!openOnHover && !disabled) {
              setAnchorEl(event.currentTarget);
            }
          }}
          onMouseEnter={event => {
            if (openOnHover && !disabled) {
              event.preventDefault();
              setAnchorEl(event.currentTarget);
            }
          }}
          role="presentation"
        >
          <img
            className={cx(classes.iconImage, classes.iconImageSize)}
            src={menuIcon}
            alt="menuIcon"
          />
        </span>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * itemsHeightMultiplier,
            width: '20ch',
          },
        }}
      >
        <div
          onMouseLeave={() => {
            if (openOnHover) {
              setAnchorEl(null);
            }
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option.caption}
              onClick={() => {
                setAnchorEl(null);
                option.handler();
              }}
              disabled={option.disabled}
            >
              <span className={classes.iconSpacing}>{option.icon}</span>
              {option.caption}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </>
  );
};

ItemMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      caption: PropTypes.string,
      handler: PropTypes.func,
    }),
  ),
  itemsHeightMultiplier: PropTypes.number,
  openOnHover: PropTypes.bool,
  disabled: PropTypes.bool,
  toolTip: PropTypes.string,
};

export default ItemMenu;
