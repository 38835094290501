import React from 'react';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useRedirect } from 'react-admin';
import useStyles from './styles';

const BackButton = ({ url }) => {
  const redirect = useRedirect();

  const classes = useStyles();

  const handleBack = () => redirect(url);

  return (
    <ReusableButton
      classNameWrapper={classes.backButtonWrapper}
      size="md"
      onClick={handleBack}
    >
      <>
        <ArrowBackIcon className={classes.iconBlack} />
        Back
      </>
    </ReusableButton>
  );
};

BackButton.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BackButton;
