import { makeRequest } from '@services/requests/makeRequest';
import { TICKET_CREATION_TEMPLATES } from '@services/requests/requestResources';

export const getTicketCreationTemplates = psaType =>
  makeRequest(
    'GET',
    `${TICKET_CREATION_TEMPLATES}/getOptions?psaType=${psaType}`,
  );

export const deleteTicketCreationTemplate = templateId =>
  makeRequest('DELETE', `${TICKET_CREATION_TEMPLATES}/${templateId}`);

export const editTicketCreationTemplate = (templateId, psaType, payload) =>
  makeRequest(
    'PUT',
    `${TICKET_CREATION_TEMPLATES}/${templateId}?psaType=${psaType}`,
    payload,
  );

export const createTicketCreationTemplate = (psaType, payload) =>
  makeRequest(
    'POST',
    `${TICKET_CREATION_TEMPLATES}?psaType=${psaType}`,
    payload,
  );

export const getTicketCreationTemplate = templateId =>
  makeRequest('GET', `${TICKET_CREATION_TEMPLATES}/${templateId}`);
