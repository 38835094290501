import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontStyle: 'normal',
  },
  iconBack: {
    fill: theme.palette.info.main,
  },
  buttonWrapper: {
    '& span': {
      textTransform: 'uppercase',
    },
  },
  splitContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      '& #displayEmailElement, #parserElement': {
        width: '100% !important',
      },
    },
    flexWrap: 'wrap',
    display: 'flex',
  },
}));

export default useStyles;
