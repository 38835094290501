import React, { useMemo, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import UsersFromCrmForm from '@components/Settings/NotificationGroups/components/UsersFromCrmForm';
import Table from '@components/Table';

import useAsync from '@services/api/common/useAsync';
import { sortOrders } from '@constants/filters';
import { actions } from '@store/actions';
import { TableTypes } from '@components/Table/constants';
import ListActions from './ListActions';
import UsersGridActionField from './UsersGridActionField';
import NotificationGroupUserForm from './NotificationGroupUserForm';
import {
  addCrmUsers,
  getCrmUsers,
  getTenantUsers,
  addTenantUsers,
} from '../helpers';

import useStyles from '../styles';

const resource = 'NotificationGroups/users';

export const userSources = Object.freeze([
  { label: 'Manual', value: 0, name: 'Manual', color: '#C91118' },
  { label: 'PSA', value: 1, name: 'PSA', color: '#35C91F' },
  { label: 'Local', value: 2, name: 'Local', color: '#1833A9' },
]);

const UsersGrid = ({ id }) => {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedEntity, setEditedEntity] = useState(null);
  const [crmUsersOptions, setCrmUsersOptions] = useState([]);
  const [tenantUsersOptions, setTenantUsersOptions] = useState([]);
  const [usersPayload, setUsersPayload] = useState(null);

  useAsync(getCrmUsers, setCrmUsersOptions);
  useAsync(getTenantUsers, setTenantUsersOptions);

  const onEdit = value => {
    setEditedEntity(value);
    setEditDialogOpen(true);
  };

  const onCreate = () => {
    setEditedEntity(null);
    setEditDialogOpen(true);
  };

  const onDelete = value => {
    setDeletedItemId(value.id);
    setDeleteDialogOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Email',
        key: 'email',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Phone',
        key: 'phone',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Source',
        key: 'source',
        sortable: true,
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: [{ label: 'All', value: '' }, ...userSources],
        filterByAutocomplete: 'source',
        isCustomAccessor: true,
        accessor: row => (
          <StatusesAccessor item={row.source} array={userSources} />
        ),
      },
      {
        name: 'Actions',
        key: 'actions',
        type: TableTypes.actions,
        accessor: rowData => (
          <UsersGridActionField
            record={rowData}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ),
        width: 150,
        minWidth: 150,
      },
    ],
    [],
  );

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: deletedItemId })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: deletedItemId }));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setDeletedItemId(null);
        setDeleteDialogOpen(false);
        setLoading(false);
      });
  };

  const createFromCrm = () => {
    setUsersPayload({
      options: crmUsersOptions,
      onSubmit: addCrmUsers,
      type: 'CRM',
    });
  };

  const createFromTenant = () => {
    setUsersPayload({
      options: tenantUsersOptions,
      onSubmit: addTenantUsers,
      type: 'Tenant',
    });
  };

  return (
    <div className={classes.userGridContainer}>
      <Table
        actionsComponent={
          <ListActions
            onCreate={onCreate}
            onCreateFromCrm={createFromCrm}
            onCreateFromTenant={createFromTenant}
          />
        }
        columns={columns}
        resource={`${resource}/${id}`}
        defaultSort={{
          fieldName: 'name',
          order: sortOrders.asc,
        }}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete user from group?"
        content="This action will delete current user from group and it can not be undone."
        loading={loading}
      />
      <NotificationGroupUserForm
        init={editedEntity}
        open={editDialogOpen}
        onClose={() => {
          setEditedEntity(null);
          setEditDialogOpen(false);
        }}
        groupId={id}
      />
      <UsersFromCrmForm
        groupId={id}
        onClose={() => setUsersPayload(null)}
        payload={usersPayload}
      />
    </div>
  );
};

UsersGrid.propTypes = {
  id: PropTypes.string,
};

export default UsersGrid;
