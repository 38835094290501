import React from 'react';

import LoginPageBanner from '@ui/common/LoginPageBanner';
import AppLogo from '@ui/common/AppLogo';
import ErrorProvider from '@components/ErrorProvider/ErrorProvider';

import LoginForm from './components/LoginForm';

import useStyles from './styles';

const Login = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ErrorProvider>
        <LoginPageBanner />
        <div className={classes.activeContainer}>
          <AppLogo />

          <LoginForm />
        </div>
      </ErrorProvider>
    </div>
  );
};

export default Login;
