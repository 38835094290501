/* eslint-disable no-param-reassign */
import { createSlice, isPending } from '@reduxjs/toolkit';
import { NOTIFICATION_GROUPS } from '@store/slices/resources';
import { ThunkGetNotificationGroupsAutocomplete } from '@store/slices/notificationGroups/thunks';

const initialState = {
  options: [],
  isLoading: true,
};

const phoneNumbersSlice = createSlice({
  name: NOTIFICATION_GROUPS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetNotificationGroupsAutocomplete.fulfilled,
        (state, { payload }) => {
          state.options = payload;
          state.isLoading = false;
        },
      )

      .addMatcher(isPending(ThunkGetNotificationGroupsAutocomplete), state => {
        state.isLoading = true;
      });
  },
});

export default phoneNumbersSlice.reducer;

export const NotificationGroupsOptionsSelector = state =>
  state[NOTIFICATION_GROUPS].options;
