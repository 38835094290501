import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useNotify } from 'react-admin';

import {
  ThunkGetSecureDataCountries,
  ThunkGetSecureDataSettings,
  ThunkUpdateSecureDataSettings,
} from '@store/slices/secureDataSettings/thunks';
import { secureDataSettingsInitialSelector } from '@store/slices/secureDataSettings/selectors';

import expirationPeriodOptions, {
  fileExpirationOptions,
} from '@constants/expirationPeriodOptions';
import { maxLengthProps } from '@utils/maxLengthProps';

import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@ui/components/Auth/Common/Input';
import HtmlInput from '@common/HtmlInput';
import FormSwitch from '@common/FilterInputs/Switch';
import DividerWithTitle from '@components/DividerWithTitle';

import { useSnackbar } from 'notistack';
import useStyles from './styles';

// eslint-disable-next-line no-template-curly-in-string
const linkSubstitute = '${link}';

const periodOptions = Object.entries(expirationPeriodOptions).map(i => i[1]);

const SecureDataSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const init = useSelector(secureDataSettingsInitialSelector);
  const { enqueueSnackbar } = useSnackbar();

  const submit = useCallback(
    values => {
      setProcessing(true);
      dispatch(ThunkUpdateSecureDataSettings(values))
        .unwrap()
        .then(() => {
          enqueueSnackbar('Settings updated', { variant: 'success' });
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch],
  );

  const onMount = useCallback(async () => {
    setLoading(true);

    Promise.all([
      dispatch(ThunkGetSecureDataSettings()).unwrap(),
      dispatch(ThunkGetSecureDataCountries()).unwrap(),
    ])
      .then(([_, countriesAutocomplete]) => {
        setCountries(countriesAutocomplete);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  const validate = values => {
    const result = {};
    if (!values.smsTemplate?.includes(linkSubstitute))
      result.smsTemplate = `Required ${linkSubstitute}`;

    if (!values.emailTemplate?.includes(linkSubstitute))
      result.emailTemplate = `Required ${linkSubstitute}`;

    return result;
  };

  return (
    <div
      className={classes.container}
      style={{
        background: '#eaeaea',
        maxWidth: '1000px',
        margin: '0 auto',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Form
        onSubmit={submit}
        initialValues={init}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
          >
            <FieldWrapper
              label="Disable technician ability to change link, logs and file settings on secure data page"
              labelSize={11}
              contentSize={1}
              fullWidth
              classNameContainer={classes.inputContainer}
              classNameContent={classes.alignRight}
              content={
                <Field
                  id="lockOptions"
                  name="lockOptions"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Enable File Sending"
              labelSize={11}
              contentSize={1}
              fullWidth
              classNameContainer={classes.inputContainer}
              classNameContent={classes.alignRight}
              content={
                <Field
                  id="isFileSendingEnabled"
                  name="isFileSendingEnabled"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />

            <DividerWithTitle title="Link Settings" />
            <FieldWrapper
              label="Single Use Link"
              labelSize={11}
              contentSize={1}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              classNameContent={classes.alignRight}
              content={
                <Field
                  id="removeAfterView"
                  name="removeAfterView"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Time to live for secure text send links"
              labelSize={8}
              contentSize={4}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              content={
                <Field
                  id="period"
                  name="period"
                  render={DropDown}
                  options={periodOptions}
                  disabled={loading || processing}
                />
              }
            />

            <DividerWithTitle title="Logs Settings" />
            <FieldWrapper
              label="Save logs to ticket/contact"
              labelSize={11}
              contentSize={1}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              classNameContent={classes.alignRight}
              content={
                <Field
                  id="saveClientNote"
                  name="saveClientNote"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Save logs to Internal Note"
              labelSize={11}
              contentSize={1}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              classNameContent={classes.alignRight}
              content={
                <Field
                  id="saveLogsInternalNote"
                  name="saveLogsInternalNote"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Save data to Internal Note"
              labelSize={11}
              contentSize={1}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              classNameContent={classes.alignRight}
              content={
                <Field
                  id="saveDataInternalNote"
                  name="saveDataInternalNote"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={processing}
                />
              }
            />

            <DividerWithTitle title="File Sending Settings" />

            <FieldWrapper
              label="File save expiration period"
              labelSize={8}
              contentSize={4}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              content={
                <Field
                  id="filePeriod"
                  name="filePeriod"
                  render={DropDown}
                  options={fileExpirationOptions}
                  disabled={loading || processing}
                />
              }
            />

            <FieldWrapper
              label="File save location"
              labelSize={8}
              contentSize={4}
              fullWidth
              classNameLabelInner={classes.labels}
              classNameContainer={classes.inputContainer}
              content={
                <Field
                  id="fileLocation"
                  name="fileLocation"
                  render={DropDown}
                  options={countries}
                  disabled={loading || processing}
                />
              }
            />

            <DividerWithTitle title="Templates" />

            <FieldWrapper
              label="Page header text"
              labelSize={12}
              contentSize={12}
              fullWidth
              classNameContainer={classes.inputContainer}
              classNameLabelInner={classes.inputContainer}
              content={
                <Field
                  name="pageHeaderMessage"
                  id="pageHeaderMessage"
                  style={{ background: '#fff' }}
                  render={Input}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Message on Reveal Secure Data"
              labelSize={12}
              contentSize={12}
              fullWidth
              classNameContainer={classes.inputContainer}
              classNameLabelInner={classes.inputContainer}
              content={
                <Field
                  name="onLinkUsedMessage"
                  id="onLinkUsedMessage"
                  render={Input}
                  disabled={processing}
                  style={{ background: '#fff' }}
                />
              }
            />

            <FieldWrapper
              label="SMS template"
              labelSize={12}
              contentSize={12}
              fullWidth
              classNameContainer={classes.smsTemplate}
              classNameLabelInner={classes.inputContainer}
              content={
                <Field
                  name="smsTemplate"
                  id="smsTemplate"
                  render={Input}
                  multiline
                  minRows={2}
                  {...maxLengthProps(values.smsTemplate)}
                  style={{ background: '#fff' }}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Email template"
              labelSize={12}
              contentSize={12}
              classNameContainer={classes.inputContainer}
              classNameLabelInner={classes.inputContainer}
              content={
                <HtmlInput
                  name="emailTemplate"
                  form={form}
                  reactQuillClassName={classes.quill}
                />
              }
            />

            <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-end',
              }}
            >
              <ReusableButton
                viewType="blue"
                label="Save"
                disabled={processing}
                type="submit"
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default SecureDataSettings;
