import { makeRequest } from '../makeRequest';
import { PASSWORDS } from '../requestResources';

export const createPassword = payload =>
  makeRequest('POST', `${PASSWORDS}`, payload);

export const deletePassword = id => makeRequest('DELETE', `${PASSWORDS}/${id}`);

export const checkCode = (code, password) =>
  makeRequest(
    'GET',
    `${PASSWORDS}/check/${code}${password ? `?password=${password}` : ''}`,
  );

export const getPassword = (code, secretPhrase) =>
  makeRequest(
    'GET',
    `${PASSWORDS}/${code}${secretPhrase ? `?password=${secretPhrase}` : ''}`,
  );

export default {};
