import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaTicketNotes } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaTicketNotes = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaTicketNotes`,
  ({ crmDefaultId, ticketId }) => getKaseyaTicketNotes(crmDefaultId, ticketId),
);

export default {};
