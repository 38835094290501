import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

const DetailedViewAccessor = ({ value }) => {
  const [open, setOpen] = useState(false);
  const data = (
    <div
      role="presentation"
      style={{ display: 'inline-grid', margin: '1rem' }}
      onClick={() => setOpen(true)}
    >
      {value.filter(distinct).map(i => (
        <span key={i} style={{ lineHeight: '20px' }}>
          {i}
        </span>
      ))}
    </div>
  );
  return value ? (
    <>
      {data}
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {data}
      </Dialog>
    </>
  ) : (
    ''
  );
};

DetailedViewAccessor.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
};

export default DetailedViewAccessor;
