import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from '@common/Styles/actionFieldStyles';

const ActionFieldItem = ({
  icon,
  alt,
  handler,
  toolTip = '',
  children,
  classNameWrapper,
  disabled,
  ignoreDefaultIconSize = false,
  style,
  id = '',
}) => {
  const classes = useStyles();
  const combinedClasses = cx(classes.iconImage, {
    [classes.disabled]: disabled,
    [classes.iconImageSize]: !ignoreDefaultIconSize,
  });

  return (
    <>
      <Tooltip title={toolTip} interactive>
        <span
          className={cx(classes.icon, classNameWrapper)}
          onClick={disabled ? undefined : handler}
          role="presentation"
          style={style}
          id={id}
        >
          {children ? (
            React.cloneElement(children, {
              className: combinedClasses,
              color: 'primary',
            })
          ) : (
            <img className={combinedClasses} src={icon} alt={alt} />
          )}
        </span>
      </Tooltip>
    </>
  );
};

ActionFieldItem.propTypes = {
  icon: PropTypes.string,
  alt: PropTypes.string,
  handler: PropTypes.func.isRequired,
  toolTip: PropTypes.string,
  children: PropTypes.node,
  classNameWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  ignoreDefaultIconSize: PropTypes.bool,
};

export default ActionFieldItem;
