import React from 'react';
import PropTypes from 'prop-types';

const DisplaySelector = ({ isAllow, children }) => {
  return isAllow ? <>{children}</> : null;
};

DisplaySelector.propTypes = {
  isAllow: PropTypes.bool,
  children: PropTypes.node,
};

export default DisplaySelector;
