import crmSources from '@constants/crmSources';
import {
  connectWiseFields,
  autoTaskFields,
  kaseyaFields,
  syncroMspFields,
  serviceNowFields,
} from './crmDisplayData';
import crmDefaultFields from './constants';

const getDisplayObject = crmType => {
  switch (crmType) {
    case crmSources.ConnectWise.name:
      return connectWiseFields;
    case crmSources.Autotask.name:
      return autoTaskFields;
    case crmSources.Kaseya.name:
      return kaseyaFields;
    case crmSources.SyncroMsp.name:
      return syncroMspFields;
    case crmSources.ServiceNow.name:
      return serviceNowFields;
    default:
      return [crmDefaultFields.name];
  }
};

export default getDisplayObject;
