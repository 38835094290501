import React, { useCallback, useState } from 'react';
import Loading from '@common/Loading/Loading';

import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import cx from 'classnames';

import ReusableButton from '@common/Button/Button';
import TabPanel from '@common/TabPanel/TabPanel';
import backIcon from '@assets/icons/arrowGrey.svg';
import { DEVICE_MONITORS } from '@constants/routes';
import useAsync from '@services/api/common/useAsync';
import Download from './components/Download/Download';
import GoogleSheets from './components/GoogleSheets/GoogleSheets';
import FtpExport from './components/FTP/FtpExport';
import { getDownloadLinks } from '../helpers';

import useStyles from './styles';

const MonitoringExportPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [includeServiceInfo, setIncludeServiceInfo] = useState(false);
  const [resource, setResource] = useState({});
  const getData = useCallback(() => getDownloadLinks(), []);
  const loading = useAsync(getData, setResource);

  const tabsData = [
    {
      label: 'Download',
      value: <Download resource={resource} />,
    },
    {
      label: 'Google sheets',
      value: <GoogleSheets resource={resource} />,
    },
    {
      label: 'FTP',
      value: (
        <FtpExport
          resource={resource}
          includeServiceInfo={includeServiceInfo}
          setIncludeServiceInfo={setIncludeServiceInfo}
        />
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={cx(classes.wrapper, classes.margin)}>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <ReusableButton
            size="md"
            onClick={() => history.push(DEVICE_MONITORS)}
            icon={null}
          >
            <>
              <img src={backIcon} alt="back" />
              Back
            </>
          </ReusableButton>
        </Grid>
        <Grid item xs={12}>
          <TabPanel
            classNameWrapperTabs={classes.tabsWrapper}
            tabs={tabsData}
            viewTypeLink="flooded"
            isTransparent
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MonitoringExportPage;
