import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  ThunkDeleteClientPortalDefault,
  ThunkGetClientPortalDefaults,
} from '@store/slices/clientPortalDefaults/thunks';
import { ClientPortalDefaultsSelector } from '@store/slices/clientPortalDefaults';

import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import CreateClientPortalDefault from '@components/ClientPortalDefaults/dialogs/CreateClientPortalDefault';
import ReusableButton from '@common/Button/Button';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import { crmSourcesEnum } from '@constants/crmSources';

import useStyles from './styles';

const ClientPortalDefaults = () => {
  const [
    openCreateClientPortalDialog,
    setOpenCreateClientPortalDialog,
  ] = useState(false);
  const [openEditClientPortalDialog, setOpenEditClientPortalDialog] = useState(
    false,
  );
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState({ isOpen: false });

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onDeleteConfirmed = () => {
    dispatch(ThunkDeleteClientPortalDefault({ defaultId: deleteDialogOpen.id }))
      .then(({ error }) => {
        if (!error) {
          enqueueSnackbar('PSA default was deleted ', { variant: 'success' });
        } else {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      })
      .finally(() => setDeleteDialogOpen({ isOpen: false }));
  };

  const columns = [
    { label: 'Name', value: 'name' },
    {
      label: 'Type',
      handler: item => <>{crmSourcesEnum[item.type]}</>,
    },
    {
      label: 'Actions',
      handler: item => (
        <>
          <ActionFieldItem
            handler={() => {
              setSelectedItem(item);
              setOpenEditClientPortalDialog(true);
            }}
            icon={EditIcon}
            alt="edit"
          />
          <ActionFieldItem
            handler={() => {
              setDeleteDialogOpen({
                isOpen: true,
                id: item.id,
                name: item.name,
              });
            }}
            icon={DeleteIcon}
            alt="delete"
          />
        </>
      ),
    },
  ];

  const onMount = useCallback(() => {
    dispatch(ThunkGetClientPortalDefaults());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.container}>
      <ReusableButton
        label="Create Default"
        onClick={() => {
          setOpenCreateClientPortalDialog(true);
        }}
      />
      <SimpleTable selector={ClientPortalDefaultsSelector} columns={columns} />

      {openCreateClientPortalDialog && (
        <CreateClientPortalDefault
          open={openCreateClientPortalDialog}
          handleClose={() => {
            setOpenCreateClientPortalDialog(false);
          }}
        />
      )}

      {openEditClientPortalDialog && (
        <CreateClientPortalDefault
          open={openEditClientPortalDialog}
          handleClose={() => {
            setSelectedItem(undefined);
            setOpenEditClientPortalDialog(false);
          }}
          item={selectedItem}
        />
      )}
      <ConfirmDialog
        open={deleteDialogOpen.isOpen}
        setOpen={() => setDeleteDialogOpen({ isOpen: false })}
        onAccept={onDeleteConfirmed}
        onCancel={() => setDeleteDialogOpen({ isOpen: false })}
        title="Delete Ticket Defaults"
        content={`Are you sure you want to delete the Ticket Defaults - ${deleteDialogOpen.name} ?`}
      />
    </div>
  );
};

export default ClientPortalDefaults;
