import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaAccounts } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaAccounts = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaAccounts`,
  ({ crmDefaultId }) => getKaseyaAccounts(crmDefaultId),
);

export default {};
