import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_DEFAULT } from '@store/slices/resources';
import { getClientPortalDefault } from '@services/requests/clientPortal';

export const ThunkGetClientPortalDefaults = createAsyncThunk(
  `${CLIENT_PORTAL_DEFAULT}/getClientPortalDefault`,
  () => getClientPortalDefault(),
);

export default {};
