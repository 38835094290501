import { createAsyncThunk } from '@reduxjs/toolkit';
import { OPTIN_SETTINGS } from '@store/slices/resources';
import { getOptInSettings } from '@services/requests/optInSettings';

export const ThunkGetOptInSettings = createAsyncThunk(
  `${OPTIN_SETTINGS}/getOptInSettings`,
  async () => {
    const res = await getOptInSettings();
    return res;
  },
);

export default {};
