import { createAsyncThunk } from '@reduxjs/toolkit';
import { hetHaloClients } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetHaloClients = createAsyncThunk(
  `${INTEGRATION}/halo/getClients`,
  async id => {
    const res = await hetHaloClients(id);
    return res;
  },
);

export default {};
