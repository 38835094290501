import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';

import { ThunkAddSmsCampaignGroup } from '@store/slices/smsCampaigns/thunks/addSmsCampaignGroup';
import maxLengthValidator from '@utils/validators/maxLengthValidator';

import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const CreateSmsCampaignGroupDialog = ({ open, setOpen, onSubmit }) => {
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkAddSmsCampaignGroup({ name: values.groupName }))
      .unwrap()
      .then(data => onSubmit(data))
      .finally(() => {
        setProcessing(false);
        setOpen(false);
      });
  };

  const validate = values => {
    return {
      groupName: maxLengthValidator(values.groupName, 30),
    };
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create SMS Campaign Group</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FieldWrapper
                label="Group Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="groupName"
                    id="groupName"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <div className={classes.formActions}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => setOpen(false)}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateSmsCampaignGroupDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CreateSmsCampaignGroupDialog;
