import { makeRequest } from '../makeRequest';
import { OPT_IN, TICKET_UPDATE } from '../requestResources';

export const getConnectWiseTicketScheduleEntries = (crmId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/tickets/${ticketId}/scheduleEntries`,
  );

export const addConnectWiseTicketScheduleEntry = (crmId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/connectwise/${crmId}/scheduleEntries`,
    payload,
  );

export const getConnectWiseTicketScheduleEntry = (crmId, scheduleEntryId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/scheduleEntries/${scheduleEntryId}`,
  );

export const editConnectWiseTicketScheduleEntry = (
  crmId,
  scheduleEntryId,
  payload,
) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmId}/scheduleEntries/${scheduleEntryId}`,
    payload,
  );

export const deleteConnectWiseTicketScheduleEntry = (crmId, scheduleEntryId) =>
  makeRequest(
    'DELETE',
    `${TICKET_UPDATE}/connectwise/${crmId}/scheduleEntries/${scheduleEntryId}`,
  );

export const getConnectWiseTicketBoards = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/boards`);

export const getConnectWiseTicketCompanies = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/companies`);

export const getConnectWiseTicketStatuses = (crmId, boardId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/boards/${boardId}/statuses`,
  );

export const getConnectWiseTicketTypes = (crmId, boardId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/boards/${boardId}/types`,
  );

export const getConnectWiseTicketPriorities = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/priorities`);

export const getConnectWiseTicketSources = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/sources`);

export const getConnectWiseTicketSites = (crmId, companyId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/companies/${companyId}/sites`,
  );

export const getConnectWiseTicketTeams = (crmId, companyId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/companies/${companyId}/teams`,
  );

export const getConnectWiseTicketImpacts = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/impacts`);

export const getConnectWiseTicketSeverities = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/severities`);

export const getCrmTicketTimeEntries = (crmId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/tickets/${ticketId}/timeEntries`,
  );

export const getConnectWiseTicketMembers = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/members`);

export const addCrmTicketTimeEntry = (crmId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/connectwise/${crmId}/timeEntries`,
    payload,
  );

export const deleteCrmTicketTimeEntry = (crmId, timeEntryId) =>
  makeRequest(
    'DELETE',
    `${TICKET_UPDATE}/connectwise/${crmId}/timeEntries/${timeEntryId}`,
  );

export const getConnectWiseTicketTimeEntry = (crmId, timeEntryId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/timeEntries/${timeEntryId}`,
  );

export const editConnectWiseTicketTimeEntry = (crmId, timeEntryId, payload) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmId}/timeEntries/${timeEntryId}`,
    payload,
  );

export const getConnectWiseTicketAgreements = (crmId, companyId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/companies/${companyId}/agreements`,
  );

export const getConnectWiseTicketLocations = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/locations`);

export const getConnectWiseTicketScheduleStatuses = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/scheduleStatuses`);

export const getConnectWiseTicketWorkRoles = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/workRoles`);

export const getConnectWiseTicketWorkTypes = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/workTypes`);

export const getConnectWiseTicketSystemLocations = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/systemLocations`);

export const getConnectWiseTicketDepartments = crmId =>
  makeRequest('GET', `${TICKET_UPDATE}/connectwise/${crmId}/departments`);

export const getConnectWiseTicketContacts = (crmId, companyId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmId}/contacts${
      companyId ? `?companyId=${companyId}` : ''
    }`,
  );

export const assignConnectWiseTicketContact = (crmId, ticketId, contactId) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmId}/tickets/${ticketId}/assignContact/${contactId}`,
  );

export const assignConnectWiseTicketMember = (crmId, ticketId, memberId) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmId}/tickets/${ticketId}/assignOwner/${memberId}`,
  );

export const getConnectWiseTicketNotes = (crmDefaultId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/connectwise/${crmDefaultId}/tickets/${ticketId}/notes`,
  );

export const addConnectWiseTicketNote = (crmDefaultId, ticketId, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/connectwise/${crmDefaultId}/tickets/${ticketId}/notes`,
    payload,
  );

export const editConnectWiseTicketNote = (
  crmDefaultId,
  ticketId,
  noteId,
  payload,
) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}`,
    payload,
  );

export const deleteConnectWiseTicketNote = (crmDefaultId, ticketId, noteId) =>
  makeRequest(
    'DELETE',
    `${TICKET_UPDATE}/connectwise/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}`,
  );

export const toggleConnectWiseTicketResolution = (
  crmDefaultId,
  ticketId,
  noteId,
  resolution,
) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}/toggleResolutionFlag?isEnable=${resolution}`,
  );

export const toggleConnectWiseTicketIssue = (
  crmDefaultId,
  ticketId,
  noteId,
  issue,
) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/connectwise/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}/toggleIssueFlag?isEnable=${issue}`,
  );

export const getTicketContact = (tenantId, crmId, contactId) =>
  makeRequest(
    'GET',
    `${OPT_IN}/tenant/${tenantId}/crm/${crmId}/contacts/${contactId}`,
  );

export default {};
