import { createAsyncThunk } from '@reduxjs/toolkit';
import { editConnectWiseTicketScheduleEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkEditConnectWiseTicketScheduleEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/editConnectWiseTicketScheduleEntry`,
  async ({ crmId, scheduleEntryId, payload }) => {
    const scheduleEntry = await editConnectWiseTicketScheduleEntry(
      crmId,
      scheduleEntryId,
      payload,
    );

    return scheduleEntry;
  },
);

export default {};
