import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaPriorities } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaPriorities = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaPriorities`,
  ({ crmDefaultId }) => getKaseyaPriorities(crmDefaultId),
);

export default {};
