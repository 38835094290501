import React, { useCallback, useMemo, useState } from 'react';
import Split from 'react-split';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import signalRMessageTypes from '@constants/signalRMessageTypes';
import { getWebHookEvent } from '@constants/signalRMessageEvents';
import { getTenantIdFromStorage } from '@services/api';
import DataViewCreator from '@components/WebHooks/components/DataViewCreator';
import {
  getWebHookData,
  submitWebHook,
  test,
} from '@components/WebHooks/helpers';
import DisplayFieldTransformers from '@components/WebHooks/components/DisplayFieldTransformers';
import { WEB_HOOKS } from '@constants/routes';
import DisplayHookBody from '@components/WebHooks/components/DisplayHookBody';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Loading from '@common/Loading/Loading';
import useStyles from '../styles';
import { useSignalREvent } from '../../../../hooks/signalR';

const initState = {
  hookBody: '',
  fields: [],
  incidentData: {
    createIncident: false,
    priority: 0,
    createIfSubjectIsUnique: false,
    triggerRule: 1,
    periodSize: '2',
    periodType: 2,
  },
};

const fieldToTransformerModel = (field, index) => {
  return {
    id: index,
    checked: true,
    name: field.replace('.', '__'),
    path: field,
    identifier: false,
    type: 0,
  };
};

const transformerToTransformerModel = (tr, index) => {
  return {
    id: index,
    checked: true,
    name: tr.name.replace('.', '__'),
    path: tr.path,
    identifier: tr.identifier,
    type: tr.type,
  };
};

const DisplayData = ({ externalData, awaitingHook }) => {
  const hookLock = !!externalData;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const redirect = useRedirect();
  const [changed, setChanged] = useState(false);
  const [data, setData] = useState(initState);
  const [errorsDialogOpen, setErrorsDialogOpen] = useState(false);
  const [currentErrors, setCurrentErrors] = useState([]);
  const [nameControl, setNameControl] = useState({});
  const [createViewOpen, setCreateViewOpen] = useState(false);
  const [modelForSave, setModelForSave] = useState({});
  const [incidentControl, setIncidentControl] = useState(
    initState.incidentData,
  );

  const setResponse = (d, id, useHook = true) => {
    const obj = JSON.parse(d.data);
    const ft = useHook
      ? d.fields.map((field, idx) => fieldToTransformerModel(field, idx))
      : JSON.parse(d.fieldTransformers).map((tr, idx) =>
          transformerToTransformerModel(tr, idx),
        );
    const response = {
      hookBody: JSON.stringify(obj, null, 2),
      transformers: ft,
      integrationGuid: d.integrationGuid,
      id,
    };
    setData(response);
    if (!useHook) {
      setNameControl({
        name: d.integrationName,
        touched: true,
        error: undefined,
      });
      setIncidentControl({
        createIncident: d.createIncident,
        priority: d.priority,
        createIfSubjectIsUnique: d.createIfSubjectIsUnique,
        triggerRule: d.triggerRule,
        periodSize: d.periodSize,
        periodType: d.periodType,
      });
    }
  };

  const onMessageHandler = useCallback(message => {
    const webHookEvent = getWebHookEvent(message);
    if (webHookEvent && webHookEvent.tenantId === getTenantIdFromStorage()) {
      setChanged(true);
      getWebHookData(webHookEvent.webHookId)
        .then(d => setResponse(d, webHookEvent.webHookId))
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    }
  }, []);

  useMemo(() => {
    if (externalData && externalData.id) {
      setResponse(externalData, externalData.id, false);
    }
  }, [externalData]);

  useSignalREvent(signalRMessageTypes.webHook, onMessageHandler);

  const makeDesigion = testResponse => {
    if (testResponse.errors.length) {
      setCurrentErrors(testResponse.errors);
      setErrorsDialogOpen(true);
      return;
    }
    setCreateViewOpen(true);
  };

  const submitAfterDataViewDialog = (model, dataView) => {
    setChanged(false);
    const dataModel = {
      integrationName: model.name,
      integrationGuid: model.integrationGuid,
      data: data.hookBody,
      fieldTransformers: model.fieldTransformer,
      dataView,
      ...incidentControl,
    };
    submitWebHook(dataModel, data.id)
      .then(() => {
        enqueueSnackbar('Data View successfully created', { variant: 'error' });
        redirect(WEB_HOOKS);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const onSubmit = values => {
    setCurrentErrors([]);
    const payload = {
      ...values,
      integrationGuid: data.integrationGuid,
    };
    setModelForSave(payload);
    test(payload, hookLock)
      .then(d => makeDesigion(d))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  return (
    <>
      <Prompt
        when={changed}
        message="You have unsaved changes, all data will be lost! Please, submit before living page"
      />
      <div>
        {data.hookBody ? (
          <>
            <Split
              split="vertical"
              sizes={[45, 45]}
              expandToMin={false}
              gutterSize={10}
              gutterAlign="center"
              snapOffset={30}
              dragInterval={1}
              direction="horizontal"
              cursor="col-resize"
              className={classes.splitContainer}
            >
              <DisplayHookBody text={data.hookBody} />
              <DisplayFieldTransformers
                transformersData={data.transformers}
                hookData={data.hookBody}
                onSubmit={onSubmit}
                nameControl={nameControl}
                setNameControl={setNameControl}
                editMode={hookLock}
                incidentControl={incidentControl}
                setIncidentControl={setIncidentControl}
              />
            </Split>
          </>
        ) : (
          <div>
            <p>Please, generate new hook URL and send hook example</p>
            <p>After receiving data you will be able to create parser</p>
            {awaitingHook && (
              <Loading
                customHeader="Awaiting"
                customText="while hook will be processed"
              />
            )}
          </div>
        )}
        <Dialog
          onClose={() => setErrorsDialogOpen(false)}
          aria-labelledby="simple-dialog-title"
          open={errorsDialogOpen}
        >
          <DialogTitle id="simple-dialog-title">
            Unable to save because errors
          </DialogTitle>
          <DialogContent>
            <ul className={classes.ul}>
              {currentErrors.map(e => (
                <li className={classes.li} key={e}>
                  {e}
                </li>
              ))}
            </ul>
          </DialogContent>
        </Dialog>
        <DataViewCreator
          open={createViewOpen}
          setOpen={setCreateViewOpen}
          onSubmit={submitAfterDataViewDialog}
          model={modelForSave}
        />
      </div>
    </>
  );
};

DisplayData.propTypes = {
  externalData: PropTypes.objectOf(PropTypes.any),
  awaitingHook: PropTypes.bool,
};

export default DisplayData;
