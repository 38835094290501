import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloTicketStatuses } from '@services/requests/haloTickets';

export const ThunkGetHaloTicketStatuses = createAsyncThunk(
  `${HALO_TICKET}/getHaloTicketStatuses`,
  async crmId => {
    const statuses = await getHaloTicketStatuses(crmId);
    return statuses;
  },
);

export default {};
