import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Block from './detailsComponents/Block';

const useStyles = makeStyles(() => ({
  fieldMargin: {
    margin: '0 0 0.2rem 1rem',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const Field = ({ caption, value }) => {
  const { fieldMargin, bold } = useStyles();
  return (
    <div className={fieldMargin}>
      <span className={bold}>{caption}</span>
      <span className={bold}>:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

const NameValueList = ({ items }) => {
  if (!Array.isArray(items)) {
    return null;
  }
  return items.map(item => (
    <div key={item.name}>
      {item.name}
      &nbsp;-&nbsp;
      {item.status}
    </div>
  ));
};

const AssignedProducts = ({ items }) => {
  const { fieldMargin, bold } = useStyles();
  if (!Array.isArray(items)) {
    return null;
  }
  return items.map(item => (
    <div key={item.code} className={fieldMargin}>
      <span className={bold}>Name:&nbsp;</span>
      <span>{item.code}</span>
      <span className={bold}>&nbsp;ver.&nbsp;</span>
      <span>{item.version}</span>
      <span className={bold}>&nbsp;status:&nbsp;</span>
      <span>{item.status}</span>
    </div>
  ));
};

const SimpleList = ({ items }) => {
  const { fieldMargin } = useStyles();
  if (!Array.isArray(items)) {
    return null;
  }
  return items.map(item => (
    <span key={item} className={fieldMargin}>
      {item}
    </span>
  ));
};

const EndpointDetails = ({ item }) => {
  if (!item) {
    return null;
  }

  return (
    <>
      <Block name="Common information">
        <Field caption="Type" value={item.type} />
        <Field caption="Host Name" value={item.hostname} />
        <Field caption="Id" value={item.id} />
        <Field
          caption="Last seen at"
          value={moment(item.lastSeenAt).format(defaultDateFormat)}
        />
        <Field
          caption="Tamper enabled"
          value={item.tamperProtectionEnabled ? 'YES' : 'NO'}
        />
      </Block>
      <Block name="Health and Services">
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Field caption="Overall" value={item.health?.overall} />
            <Field caption="Status" value={item.health?.threats?.status} />
            <Field
              caption="Services status"
              value={item.health?.services?.status}
            />
          </Grid>
          <Grid item xs={7}>
            <NameValueList items={item.health?.services?.serviceDetails} />
          </Grid>
        </Grid>
      </Block>
      <Block name="Opertion system">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Field caption="Platform" value={item.os?.platform} />
            <Field caption="Name" value={item.os?.name} />
            <Field
              caption="Is server OS"
              value={item.os?.isServer ? 'YES' : 'NO'}
            />
          </Grid>
          <Grid item xs={6}>
            <Field caption="Major version" value={item.os?.majorVersion} />
            <Field caption="Minor version" value={item.os?.minorVersion} />
            <Field caption="Build" value={item.os?.build} />
          </Grid>
        </Grid>
      </Block>
      <Block name="Associated Person">
        <Field caption="Name" value={item.associatedPerson?.name} />
        <Field caption="Via Login" value={item.associatedPerson?.viaLogin} />
        <Field caption="Id" value={item.associatedPerson?.id} />
      </Block>
      <Block name="Assigned Products">
        <AssignedProducts items={item.assignedProducts} />
      </Block>
      <Block name="IP v4 Addresses">
        <SimpleList items={item.ipv4Addresses} />
      </Block>
      <Block name="MAC Addresses">
        <SimpleList items={item.macAddresses} />
      </Block>
    </>
  );
};

EndpointDetails.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default EndpointDetails;
