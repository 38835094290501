import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '25px 0px',
    height: '88vh',
    margin: '15px',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    overflowY: 'auto',
  },
  firstRow: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    margin: '0px 15px',
    maxWidth: '82vw',
    '@media (max-width: 1600px)': {
      gridTemplateColumns: '1fr',
    },
  },
  recentChannels: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  box: {
    background: '#cdcdcd',
    margin: '15px',
    padding: '15px',
    borderRadius: '15px',
    height: '38vh',
    position: 'relative',
    '@media (max-width: 1600px)': {
      height: 'unset',
    },
  },
  dashboardCounter: {
    display: 'grid',
    gridTemplateRows: '1fr 5fr',
  },
  dashboardCounterHeader: { fontWeight: '800' },
  dashboardCounterBody: {
    fontSize: '100px',
  },
  dashboardCounterAlignment: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  secondRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    margin: '0px 15px',
    maxWidth: '82vw',
    '@media (max-width: 1600px)': {
      gridTemplateColumns: '1fr',
    },
  },
  card: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.2)',
    },
  },
  chartFiltersContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chartFilterContainer: {
    margin: '10px',
    padding: '10px',
    minWidth: '120px',
  },
  dashboardTableHeader: {
    padding: '0px 10px',
    fontWeight: 800,
    fontSize: '1.2rem',
  },
  dashboardCustomInputs: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      padding: '15px',
    },
  },
  chartContainer: {
    height: '300px',
    '& > div': {
      height: '300px',
    },
  },
  pieContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
