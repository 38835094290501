import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  defaultBgColor,
  defaultCircleBorderColor,
} from '@constants/defaultBageColors';

import useStyles from './styles';

const Circle = ({ bgColor, borderColor, classNameWrapper }) => {
  const classes = useStyles();
  const backgroundColor = bgColor || defaultBgColor;
  const bordColor = borderColor || defaultCircleBorderColor;
  return (
    <div
      className={cx(classes.div, classNameWrapper)}
      style={{
        backgroundColor: `${backgroundColor}`,
        border: `1px solid ${bordColor}`,
      }}
    />
  );
};

Circle.propTypes = {
  bgColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  classNameWrapper: PropTypes.string,
};

export default Circle;
