import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Delete as DeleteIcon, MoreVert } from '@material-ui/icons/';
import { InputAdornment } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';

import Input from '@ui/components/Auth/Common/Input';
import DropDown from '@ui/components/Auth/Common/DropDown';

import useStyles from './style';

const ReEscalationItem = ({
  item,
  handleDelete,
  groups,
  users,
  handleChange,
  index,
}) => {
  const [minutes, setMinutes] = useState(
    item.pastDueMinutes ? item.pastDueMinutes : 0,
  );
  const [groupId, setGroupId] = useState(
    item.notificationGroupId ? item.notificationGroupId : undefined,
  );
  const [userId, setUserId] = useState(
    item.notificationUserId ? item.notificationUserId : undefined,
  );

  const classes = useStyles();

  useEffect(() => {
    handleChange({
      id: item.id,
      pastDueMinutes: minutes,
      notificationGroupId: groupId,
      notificationUserId: userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, groupId, userId]);

  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {provided => (
        <div
          className={classes.reEscalationItem}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Input
            type="number"
            input={{
              value: minutes,
              onChange: e => {
                setMinutes(e.target.value);
              },
            }}
            meta={{ touched: true, error: false }}
            dateProps={{
              min: 0,
            }}
            endAdornment={
              <InputAdornment position="end">minutes</InputAdornment>
            }
          />

          {/* Drop down for Groups */}
          <DropDown
            options={groups}
            placeholder="Groups"
            input={{
              onChange: e => {
                setGroupId(e.target.value);
              },
              value: groupId,
            }}
          />

          {/* Drop down for Users */}
          <DropDown
            options={users}
            input={{
              onChange: e => {
                setUserId(e.target.value);
              },
              value: userId,
            }}
          />

          <DeleteIcon
            alt="delete"
            onClick={() => {
              handleDelete(item.id);
            }}
            className={classes.reEscalationItemIcon}
          />

          <MoreVert />
        </div>
      )}
    </Draggable>
  );
};

ReEscalationItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    pastDueMinutes: PropTypes.number,
    notificationGroupId: PropTypes.number,
    notificationUserId: PropTypes.number,
  }),
  handleDelete: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func,
  index: PropTypes.number,
};

export default ReEscalationItem;
