import { createAsyncThunk } from '@reduxjs/toolkit';
import { hetHaloSites } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetHaloSites = createAsyncThunk(
  `${INTEGRATION}/halo/getSites`,
  async ({ crmId, clientId }) => {
    const res = await hetHaloSites(crmId, clientId);
    return res;
  },
);

export default {};
