import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isNull from 'lodash/isNull';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import iconEdit from '@assets/icons/edit.svg';
import iconDelete from '@assets/icons/delete.svg';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { DRAGGABLE_HANDLE_CLASS } from '../constants';
import TileContent from './TileContent';
import useStyles from './styles';

const Tile = ({ item, onDelete, handleEdit, isFullScreenActive }) => {
  const classes = useStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isNew = useMemo(() => isNull(item.dashboardItemId), [
    item.dashboardItemId,
  ]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <div className={cx(classes.cardHeader, DRAGGABLE_HANDLE_CLASS)}>
        <div style={{ display: isFullScreenActive ? 'none' : 'block' }}>
          <ActionButton
            icon={<img src={iconEdit} alt="iconEdit" />}
            handler={event => {
              event.stopPropagation();
              handleEdit(item);
            }}
            classNameWrapper={classes.editButton}
            disabled={isNew}
          />
        </div>
        <Typography variant="subtitle2" align="center" noWrap>
          {item.title}
        </Typography>
        <div style={{ display: isFullScreenActive ? 'none' : 'block' }}>
          <ActionButton
            icon={<img src={iconDelete} alt="iconDelete" />}
            handler={() => setDeleteDialogOpen(true)}
            classNameWrapper={classes.removeButton}
          />
        </div>
      </div>
      <div className={classes.title}>
        <TileContent
          tile={item}
          isNew={isNew}
          onEdit={() => handleEdit(item)}
        />
      </div>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={() => {
          setDeleteDialogOpen(false);
          onDelete(item.id);
        }}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Remove this tile?"
        content="This action will delete current tile and it can not be undone."
      />
    </Card>
  );
};

Tile.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  isFullScreenActive: PropTypes.bool,
};

export default Tile;
