import React from 'react';
import { Layout } from 'react-admin';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from '@services/themes/mainTheme';
import { useNavigationBreadcrumbs } from '@components/Layout/useNavigationBreadcrumbs';
import SideBar from './SideBar';
import AppBar from './AppBar';
import ErrorProvider from '../ErrorProvider/ErrorProvider';

import useStyles from './styles';

const createTheme = type => (type === 'dark' ? darkTheme : lightTheme());

const MyLayout = props => {
  const classes = useStyles();
  const theme = useSelector(state => createTheme(state.theme));
  const breadcrumbs = useNavigationBreadcrumbs();

  const { children, ...restProps } = props;

  return (
    <div className={classes.layoutOverride}>
      <ErrorProvider>
        <Layout
          {...restProps}
          appBar={AppBar}
          menu={SideBar}
          className={classes.layoutWrapper}
          {...(theme ? { theme } : {})}
        >
          <div className={classes.contentWrapper}>
            {breadcrumbs}
            {children}
          </div>
        </Layout>
      </ErrorProvider>
    </div>
  );
};

export default MyLayout;
