import React from 'react';
import { ReactComponent as SubscriptionsIcon } from '@assets/icons/subscriptions.svg';
import List from './List';

const Icon = () => <SubscriptionsIcon alt="subscriptionsIcon" />;

export default {
  list: List,
  icon: Icon,
};
