import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '25px',
    margin: '0px 5px 0px 5px',
    '& span': {
      cursor: 'pointer',
    },
    '& svg': {
      cursor: 'pointer',
      position: 'absolute',
      color: ({ hasNotifications }) =>
        hasNotifications ? theme.palette.appBar.icon : '#999799 !important',
      '& path': {
        color: ({ hasNotifications }) =>
          hasNotifications ? theme.palette.appBar.icon : '#999799 !important',
      },
    },
  },
}));

export default useStyles;
