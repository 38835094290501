import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketImpacts } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketImpacts = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketImpacts`,
  async crmId => {
    const impacts = await getConnectWiseTicketImpacts(crmId);
    return impacts;
  },
);

export default {};
