import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  reEscalationControl: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    '& svg': {
      cursor: 'pointer',
    },
  },
  reEscalationItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px',
    '& div': {
      marginRight: '10px',
    },
  },
  reEscalationItemIcon: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  reEscalationItemMinutes: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
