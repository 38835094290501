import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import {
  ThunkCreateClientPortalDefault,
  ThunkUpdateClientPortalDefault,
} from '@store/slices/clientPortalDefaults/thunks';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import DropDown from '@components/Auth/Common/DropDown';
import CWClientPortalForm from '@components/ClientPortalDefaults/components/CWClientPortalForm';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { requiredValidator } from '@utils/validators';
import crmSources from '@constants/crmSources';
import { OnChange } from 'react-final-form-listeners';
import AutotaskClientPortalForm from '@components/ClientPortalDefaults/components/AutotaskClientPortalForm';
import HaloClientPortalForm from '@components/ClientPortalDefaults/components/HaloClientPortalForm';
import SuperOpsClientPortalForm from '@components/ClientPortalDefaults/components/SuperOpsClientPortalForm';

const alllowedPsas = [
  crmSources.ConnectWise.name,
  crmSources.Autotask.name,
  crmSources.Halo.name,
  crmSources.SuperOps.name,
];

const CreateClientPortalDefault = ({ open, handleClose, item = undefined }) => {
  const [psaOptions, setPsaOptions] = useState([]);
  const [psaType, setPsaType] = useState();
  const [initPsa, setInitPsa] = useState(null);

  const sharedClasses = useSharedStyles();

  const dispatch = useDispatch();

  const submit = values => {
    const payload = {
      ...values,
      type: psaOptions.find(i => i.id === values.psaId).crmType,
    };
    if (!item) {
      dispatch(ThunkCreateClientPortalDefault({ payload }))
        .unwrap()
        .then(() => {
          handleClose();
        });
    } else {
      dispatch(
        ThunkUpdateClientPortalDefault({ payload, defaultId: payload.id }),
      )
        .unwrap()
        .then(() => {
          handleClose();
        });
    }
  };

  const validateDefault = useCallback(
    values => {
      switch (psaType) {
        case crmSources.ConnectWise.name:
          return {
            BoardId: requiredValidator(values?.default?.BoardId),
            TicketTypeId: requiredValidator(values?.default?.TicketTypeId),
            TicketOpenStatusId: requiredValidator(
              values?.default?.TicketOpenStatusId,
            ),
            TicketCloseStatusId: requiredValidator(
              values?.default?.TicketCloseStatusId,
            ),
            SourceId: requiredValidator(values?.default?.SourceId),
            PriorityId: requiredValidator(values?.default?.PriorityId),
          };
        case crmSources.Autotask.name:
          return {
            QueueId: requiredValidator(values?.default?.QueueId),
            CategoryId: requiredValidator(values?.default?.CategoryId),
            OpenStatusTicketId: requiredValidator(
              values?.default?.OpenStatusTicketId,
            ),
            ClosedTicketStatusId: requiredValidator(
              values?.default?.ClosedTicketStatusId,
            ),
            PriorityId: requiredValidator(values?.default?.PriorityId),
            SourceId: requiredValidator(values?.default?.SourceId),
          };
        case crmSources.Halo.name:
          return {
            ClientId: requiredValidator(values?.default?.ClientId),
            SiteId: requiredValidator(values?.default?.SiteId),
            UserId: requiredValidator(values?.default?.UserId),
            TeamId: requiredValidator(values?.default?.TeamId),
            OpenStatusId: requiredValidator(values?.default?.OpenStatusId),
            ClosedTicketStatusId: requiredValidator(
              values?.default?.ClosedTicketStatusId,
            ),
            TicketOnMessageStatusId: requiredValidator(
              values?.default?.TicketOnMessageStatusId,
            ),
            TicketTypeId: requiredValidator(values?.default?.TicketTypeId),
          };
        case crmSources.SuperOps.name:
          return {
            TicketTypeId: requiredValidator(values?.default?.TicketTypeId),
            TicketOpenStatusId: requiredValidator(
              values?.default?.TicketOpenStatusId,
            ),
            TicketCloseStatusId: requiredValidator(
              values?.default?.TicketCloseStatusId,
            ),
          };
        default:
          return {};
      }
    },
    [psaType],
  );

  const validate = values => {
    return {
      name: requiredValidator(values.name),
      psaId: requiredValidator(values.psaId),
      default: validateDefault(values),
    };
  };

  const onMount = useCallback(() => {
    dispatch(ThunkGetCrmSources())
      .unwrap()
      .then(res => {
        const allowedOptions = res.filter(p =>
          alllowedPsas.includes(p.crmType),
        );
        setPsaOptions(allowedOptions);

        if (item) {
          const selectedPsa = res.find(i => i.id === item.psaId);
          setPsaType(selectedPsa.crmType);
        }

        if (!item && allowedOptions.length === 1) {
          setInitPsa(allowedOptions[0].id);
        }
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const getForm = useCallback(
    (form, values) => {
      if (!values?.psaId) return null;
      switch (psaType) {
        case crmSources.ConnectWise.name:
          return <CWClientPortalForm psaId={values.psaId} item={item} />;
        case crmSources.Autotask.name:
          return (
            <AutotaskClientPortalForm
              crmId={values.psaId}
              values={values}
              form={form}
              item={item}
            />
          );
        case crmSources.Halo.name:
          return (
            <HaloClientPortalForm
              form={form}
              item={item}
              values={values}
              crmId={values.psaId}
            />
          );
        case crmSources.SuperOps.name:
          return (
            <SuperOpsClientPortalForm
              form={form}
              item={item}
              values={values}
              crmId={values.psaId}
            />
          );
        default:
          return null;
      }
    },
    [psaType],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>
        {item ? 'Edit ' : 'Create '}
        Client Portal Default
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={item ?? { psaId: initPsa }}
          validate={validate}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <div className={sharedClasses.twoColumnsContainer}>
                <FieldWrapper
                  label="Name"
                  contentSize={12}
                  isRequired
                  content={<Field id="name" name="name" component={Input} />}
                />

                <FieldWrapper
                  label="PSA Integration"
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="psaId"
                      name="psaId"
                      component={DropDown}
                      options={psaOptions}
                      labelName="name"
                      valueName="id"
                      disabled={!!item}
                    />
                  }
                />
                <OnChange name="psaId">
                  {value => {
                    const psaOption = psaOptions.find(i => i.id === value);
                    setPsaType(psaOption.crmType);
                  }}
                </OnChange>
                {getForm(form, values)}
              </div>

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    handleClose();
                  }}
                />

                <ReusableButton label="Submit" type="submit" />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateClientPortalDefault;
