import { createAsyncThunk } from '@reduxjs/toolkit';
import { closeTriageTicket } from '@services/requests/triage';
import { TRIAGE } from '@store/slices/resources';

export const ThunkCloseTriageTicket = createAsyncThunk(
  `${TRIAGE}/closeTriageTicket`,
  async id => {
    const ticket = await closeTriageTicket(id);
    return ticket;
  },
);

export default {};
