import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTemplateVariables } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetTemplateVariables = createAsyncThunk(
  `${MESSAGING}/getTemplateVariables`,
  async () => {
    const variables = await getTemplateVariables();
    return variables;
  },
);

export default {};
