import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketWorkTypes } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketWorkTypes = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketWorkTypes`,
  async crmId => {
    const types = await getConnectWiseTicketWorkTypes(crmId);
    return types;
  },
);

export default {};
