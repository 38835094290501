import React from 'react';
import { url } from './helpers';

const RoadMap = () => {
  return (
    <iframe
      title="roadmap"
      width="100%"
      height="100%"
      src={url}
      frameBorder="0"
    />
  );
};

export default RoadMap;
