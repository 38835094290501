import React from 'react';
import Paper from '@common/Paper/Paper';
import { useSelector } from 'react-redux';
import { pricePlanInfo } from '@store/selectors';
import CurrentPlan from './components/currentPlan';
import CurrentPlanNew from './components/currentPlanNew/CurrentPlanNew';
import Statistic from './components/statistic';
import useStyles from './styles';

const Usage = () => {
  const classes = useStyles();
  const plan = useSelector(pricePlanInfo);

  return (
    <div className={classes.usageContainer}>
      <Paper>
        {plan?.isSubscriptionObsolete ? (
          <>
            <CurrentPlan />
            <Statistic />
          </>
        ) : (
          <CurrentPlanNew />
        )}
      </Paper>
    </div>
  );
};

export default Usage;
