import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MessagingConfigurationLogsSelector } from '@store/slices/messaging';
import { ThunkGetConfigurationLogs } from '@store/slices/messaging/thunks';
import getFormattedDate from '@utils/getFormattedDate';
import { MESSAGING_CHANNEL } from '@constants/routes';

import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';

import useStyles from '../styles';

const ConfigurationLogsDialog = ({ open, setOpen, item }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const logs = useSelector(MessagingConfigurationLogsSelector);

  const onMount = useCallback(async () => {
    if (item) {
      setLoading(true);
      await dispatch(ThunkGetConfigurationLogs({ configurationId: item.id }));
      setLoading(false);
    }
  }, [dispatch, item]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        <div className={classes.logsDialogHeader}>
          Logs
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            {logs.map(log => (
              <div key={log.id} style={{ color: log.color || 'black' }}>
                {`${`[${getFormattedDate(log.dateTime)}] `}`}

                {`${log.from} -> ${log.to} `}
                {log.channelId ? (
                  <button
                    type="button"
                    style={{
                      textDecoration: 'underline',
                      margin: '0px 5px',
                      cursor: 'pointer',
                      background: 'transparent',
                      border: 'none',
                      fontSize: '15px',
                    }}
                    onClick={() => {
                      push(
                        MESSAGING_CHANNEL.replace(
                          ':configurationId',
                          item.id,
                        ).replace(':channelId', log.channelId),
                      );
                    }}
                  >
                    Channel ID:
                    {log.channelId}
                  </button>
                ) : (
                  ''
                )}
                {log.message}
              </div>
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

ConfigurationLogsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default ConfigurationLogsDialog;
