import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChannelHistory } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetChannelHistory = createAsyncThunk(
  `${MESSAGING}/getChannelHistory`,
  async ({ channelId, userOnly = false, orderDesc = false }) => {
    const history = await getChannelHistory(channelId, userOnly, orderDesc);
    return { history, userOnly };
  },
);

export default {};
