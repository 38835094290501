import React, { useCallback, useEffect, useState } from 'react';
import Loading from '@common/Loading/Loading';
import { useDispatch } from 'react-redux';
import { ThunkGetOptInSettings } from '@store/slices/optInSettings/thunks';
import OptInConfiguration from '@ui/pages/optIn/OptInSettings/components/OptInConfiguration';
import OptInBrandingSettings from '@ui/pages/optIn/OptInSettings/components/OptInBrandingSettings';

const OptInSettings = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetOptInSettings());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount().then(() => {});
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div
      style={{
        padding: '30px',
      }}
    >
      <OptInConfiguration />

      <OptInBrandingSettings />
    </div>
  );
};

export default OptInSettings;
