import React from 'react';
import { Grid } from '@material-ui/core';

import DisplayHookBody from '@ui/components/WebHooks/components/DisplayHookBody';
import useStyles from '../styles';

const WebHookResultDetails = ({
  hookData,
  parsedData,
  unrecognizedValues,
  errorMessage,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12} className={classes.blockWrapper}>
        <Grid item xs={12} md={6} className={classes.detailsBlock}>
          Hook Data <br />
          <DisplayHookBody text={hookData} />
        </Grid>

        <Grid item xs={12} md={6} className={classes.detailsBlock}>
          Parsed Data <br />
          <DisplayHookBody text={parsedData} />
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.blockWrapper}>
        <Grid item xs={12} md={6} className={classes.detailsBlock}>
          Unrecognized values
          <br />
          <DisplayHookBody text={unrecognizedValues} />
        </Grid>

        <Grid item xs={12} md={6} className={classes.detailsBlock}>
          {errorMessage}
        </Grid>
      </Grid>
    </>
  );
};

export default WebHookResultDetails;
