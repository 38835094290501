import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMON } from '@store/slices/resources';
import { beautifyPhoneNumber } from '@services/requests/common';

export const ThunkBeautifyPhoneNumber = createAsyncThunk(
  `${COMMON}/beautifyPhoneNumber`,
  async value => {
    const res = await beautifyPhoneNumber(value);
    return res;
  },
);

export default {};
