import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAutotaskTicketContacts } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetAutotaskTicketContacts = createAsyncThunk(
  `${CRM_TICKET}/getAutotaskTicketContacts`,
  async ({ crmId, companyId = undefined }) => {
    const contacts = await getAutotaskTicketContacts(crmId, companyId);
    return contacts.sort((a, b) => (a.label > b.label ? 1 : -1));
  },
);

export default {};
