import postWithResponse from '@services/api/common/postWithResponse';
import appConfig from '@configs/appConfig';
import { coupons } from '@constants/appRoutes';

export const generateCode = () =>
  Math.random()
    .toString(36)
    .substr(2, 6)
    .toUpperCase();

export const updateItems = data =>
  postWithResponse(`${appConfig.baseUrl}${coupons}`, data);
