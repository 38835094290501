import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  form: {
    padding: '50px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    background: 'white',
    borderRadius: '10px',
    width: '50%',
    margin: '0 auto',
  },
}));

export default useStyles;
