import React from 'react';
import PropTypes from 'prop-types';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import QuickSearchToolbar from '@common/MuiVirtualizedTable/components/QuickSearchToolbar';

import iconPlus from '@assets/icons/plus.svg';
import iconMinus from '@assets/icons/remove.svg';

import useStyles from '../styles';

const ActionsBlock = ({
  names,
  setSelectedNames,
  onFilterChange,
  filterValue,
  onFilterClear,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.flex}>
      <ActionButton
        icon={<img src={iconPlus} alt="iconPlus" />}
        handler={() => setSelectedNames(names)}
        toolTip="Select all"
      />
      <ActionButton
        icon={<img src={iconMinus} alt="iconMinus" />}
        handler={() => setSelectedNames([])}
        toolTip="Deselect all"
      />
      <div className={classes.marginLeft}>
        <QuickSearchToolbar
          onChange={onFilterChange}
          value={filterValue}
          clearSearch={onFilterClear}
        />
      </div>
    </div>
  );
};

ActionsBlock.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  setSelectedNames: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filterValue: PropTypes.string,
  onFilterClear: PropTypes.func.isRequired,
};

export default ActionsBlock;
