const useSmsSettings = ({ data, setData }) => {
  const handlePhoneChange = value =>
    setData({
      ...data,
      phoneNumber: value,
    });

  return { handlePhoneChange };
};

export default useSmsSettings;
