import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import moment from 'moment';

import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import DatePicker from 'react-datepicker';
import DropDown from '@components/Auth/Common/DropDown';
import { NEVER_EXPIRED } from '@constants/commonConstants';
import expirationPeriodOptions from '@constants/expirationPeriodOptions';
import { useSelector } from 'react-redux';
import { tenantOptions } from '@store/selectors/smsList';
import { Field, Form } from 'react-final-form';
import useStyles from './styles';

const initialDate = new Date(moment().add(1, 'days'));

const CreateDialog = ({ open, onClose, onCreate }) => {
  const classes = useStyles();
  const options = useSelector(tenantOptions);

  const [periodValue, setPeriodValue] = useState(0);
  const [dateValue, setDateValue] = useState(null);
  const [datePickerVisible, setDataPickerVisible] = useState(true);

  useEffect(() => {
    if (open) {
      setDateValue(initialDate);
    }
  }, [open]);

  const onPeriodChange = value => {
    setDataPickerVisible(true);
    setPeriodValue(value);
    switch (value) {
      case expirationPeriodOptions.day.value:
        setDateValue(initialDate);
        break;
      case expirationPeriodOptions.tenDays.value:
        setDateValue(new Date(moment().add(10, 'd')));
        break;
      case expirationPeriodOptions.month.value:
        setDateValue(new Date(moment().add(1, 'M')));
        break;
      case expirationPeriodOptions.year.value:
        setDateValue(new Date(moment().add(1, 'y')));
        break;
      case expirationPeriodOptions.custom.value:
        break;
      case expirationPeriodOptions.never.value:
        setDataPickerVisible(false);
        setDateValue(
          new Date(moment().set({ year: NEVER_EXPIRED, month: 1, day: 1 })),
        );
        break;
      default:
        setDateValue(0);
        setDateValue(initialDate);
        break;
    }
  };

  const onDateChange = value => {
    setDateValue(value);
    setPeriodValue(4);
  };

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (values.name?.length > 30) {
      errors.name = 'Length more than 30 symbols';
    }
    if (!values.tenantId) {
      errors.tenantId = 'Required';
    }
    return errors;
  };

  const submit = values => {
    onCreate({
      ...values,
      expirationDateUtc: moment.utc(dateValue).toISOString(),
    });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <DialogTitle id="simple-dialog-title">
              Generate new API key
            </DialogTitle>
            <DialogContent>
              <div className={classes.formContainer}>
                <Field
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  placeholder="Key name"
                />
                <Field
                  id="tenantId"
                  name="tenantId"
                  fullWidth
                  options={options}
                  labelName="value"
                  valueName="id"
                  component={DropDown}
                  classNameWrapper={classes.inputWrapper}
                  label="Tenant"
                />
                <span className={classes.formText}>Expiration period</span>
                <DropDown
                  options={Object.values(expirationPeriodOptions)}
                  classNameWrapper={classes.marginTop}
                  input={{
                    value: periodValue,
                    onChange: e => onPeriodChange(+e.target.value),
                  }}
                />

                {datePickerVisible && (
                  <DatePicker
                    selected={dateValue}
                    onChange={onDateChange}
                    inline
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <ReusableButton label="Cancel" onClick={onClose} />
              <ReusableButton type="submit" viewType="black" label="Confirm" />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateDialog;
