import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import { tableKeys } from '@common/MuiVirtualizedTable/constants';
import DateAccessor from '@common/MuiVirtualizedTable/components/accessors/DateAccessor';
import LongTextAccessor from '@common/MuiVirtualizedTable/components/accessors/LongTextAccessor';

const columns = [
  {
    key: 'raisedAt',
    name: 'Raised at',
    sort: 'raisedAt',
    accessor: row => <DateAccessor value={row.raisedAt} />,
    width: 160,
  },
  {
    key: 'category',
    name: 'category',
    sort: 'category',
    width: 15,
  },
  {
    key: 'product',
    name: 'product',
    sort: 'product',
    width: 15,
  },
  {
    key: 'severity',
    name: 'severity',
    sort: 'severity',
    width: 10,
  },
  {
    key: 'description',
    name: 'description',
    accessor: row => <LongTextAccessor value={row.description} />,
    rowHeight: 50,
  },
];

const AlertsGrid = ({ data }) => {
  const rowsData = useMemo(() => {
    return data.map(i => {
      return {
        ...i,
      };
    });
  }, [data]);

  return (
    <MuiVirtualizedListView
      tableKey={tableKeys.sophosAlerts}
      data={rowsData}
      columns={columns}
    />
  );
};

AlertsGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default AlertsGrid;
