import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getDeviceMonitorMetrics,
  getDeviceStatistic,
} from '@components/DeviceMonitors/helpers';
import Loading from '@common/Loading/Loading';
import DetailsChart from './DetailsChart';
import DetailsEvents from './DetailsEvents';

const DetailsWrapper = ({ id, children }) => {
  const [lastEvent, setLastEvent] = useState(null);
  const [data, setData] = useState({
    metrics: [],
    statistic: {},
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([getDeviceMonitorMetrics(id), getDeviceStatistic(id)])
      .then(responses => {
        const [metrics, statistic] = responses;
        setData({
          metrics: metrics.reverse(),
          statistic,
        });
      })
      .catch(e => console.error(e.message))
      .finally(() => setLoading(false));
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {children}
      <DetailsChart
        metrics={data.metrics}
        lastEvent={lastEvent}
        statistic={data.statistic}
      />
      <DetailsEvents id={id} setLastEvent={setLastEvent} />
    </>
  );
};

DetailsWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

export default DetailsWrapper;
