import React from 'react';

const defaultConstructor = option => `${option.code} ${option.dialCode}`;
const addFlagsToOptions = (
  countries,
  valueConstructor = defaultConstructor,
  labelConstructor = defaultConstructor,
  countryCodeName = 'code',
) =>
  countries.map(list => ({
    value: valueConstructor(list),
    label: labelConstructor(list),
    icon: (
      <img
        alt={list[countryCodeName]}
        src={`https://flagcdn.com/w20/${list[
          countryCodeName
        ].toLowerCase()}.png`}
      />
    ),
  }));

export default addFlagsToOptions;
