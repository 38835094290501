import { createAsyncThunk } from '@reduxjs/toolkit';
import { assignConnectWiseTicketMember } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkAssignConnectWiseTicketMember = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/assignConnectWiseTicketMember`,
  async ({ crmId, ticketId, memberId }) => {
    await assignConnectWiseTicketMember(crmId, ticketId, memberId);
    return true;
  },
);

export default {};
