import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import Checkbox from '@common/Checkbox';

import {
  ThunkCloseConversation,
  ThunkGetTicketStatusesByChatId,
} from '@store/slices/liveChats/thunks';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Input from '@ui/components/Auth/Common/Input';
import { emailValidator } from '@utils/validators';

const CloseLiveChatWIthTicketDialog = ({ open, setOpen, liveChatId }) => {
  const [processing, setProcessing] = useState(false);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [ticketStatus, setTicketStatus] = useState(undefined);

  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();

  const validate = values => {
    if (values.putToEmail && emailValidator(values.email)) {
      return { email: emailValidator(values.email) };
    }
  };

  const submit = useCallback(
    async values => {
      setProcessing(true);
      await dispatch(ThunkCloseConversation({ liveChatId, payload: values }))
        .unwrap()
        .then(() => {
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, liveChatId, setOpen],
  );

  const onMount = useCallback(async () => {
    setProcessing(true);
    await dispatch(ThunkGetTicketStatusesByChatId({ liveChatId }))
      .unwrap()
      .then(res => {
        setTicketStatuses(res.options);
        setTicketStatus(res.current);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, liveChatId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Close Conversation</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={{
            ticketStatus,
            putToTicket: false,
            putToEmail: false,
            email: '',
          }}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              Are you sure you want to close this conversation?
              <FieldWrapper
                label="Ticket Status"
                labelSize={12}
                contentSize={12}
                fullWidth
                infoText="Left blank if you don't want to close ticket"
                content={
                  <Field
                    name="ticketStatus"
                    id="ticketStatus"
                    render={AutocompleteFormInput}
                    items={ticketStatuses}
                    loading={processing}
                  />
                }
              />
              <FieldWrapper
                label="Put conversation to ticket note"
                labelSize={10}
                contentSize={2}
                fullWidth
                content={
                  <Field
                    name="putToTicket"
                    id="putToTicket"
                    render={Checkbox}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Send conversation throw email"
                labelSize={10}
                contentSize={2}
                fullWidth
                content={
                  <Field
                    name="putToEmail"
                    id="putToEmail"
                    render={Checkbox}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Email"
                labelSize={2}
                contentSize={10}
                fullWidth
                content={
                  <Field
                    name="email"
                    id="email"
                    render={Input}
                    disabled={processing || !values.putToEmail}
                  />
                }
              />
              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CloseLiveChatWIthTicketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  liveChatId: PropTypes.number.isRequired,
};
export default CloseLiveChatWIthTicketDialog;
