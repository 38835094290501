import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '25px',
    margin: '0px 15px 0px 5px',
    '& span': {
      cursor: 'pointer',
    },
    '& svg': {
      cursor: 'pointer',
      position: 'absolute',
      '& path': {
        color: theme.palette.appBar.icon,
      },
    },
    '& > a': {
      cursor: 'pointer',
      '&:visited': {
        color: 'black',
      },
      '&:active': {
        color: 'black',
      },
      '&:link': {
        color: 'black',
      },
    },
  },
  links: {
    color: 'black',
    '&:visited': {
      color: 'black',
    },
    '&:active': {
      color: 'black',
    },
    '&:link': {
      color: 'black',
    },
  },
  sendEmailItem: {
    display: 'flex',
    gap: '3rem',
  },
}));

export default useStyles;
