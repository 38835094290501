import { ADMIN, SEND_SMS } from '@services/requests/requestResources';
import { makeRequest } from '../makeRequest';

export const getTenantsOptions = () => makeRequest('GET', `${ADMIN}/Tenants`);

export const GetAccessModules = tenantId =>
  makeRequest('GET', `${ADMIN}/${tenantId}/accessModules`);

export const GetAccessModulesTemplate = () =>
  makeRequest('GET', `${ADMIN}/accessModulesTemplate`);

export const UpdateAccessOptions = (tenantId, values) =>
  makeRequest('PUT', `${ADMIN}/${tenantId}/accessModules`, values);

export const UpdateAccessOptionsTemplate = values =>
  makeRequest('PUT', `${ADMIN}/accessModulesTemplate`, values);
