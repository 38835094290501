import axios from 'axios';
import appConfig from '@configs/appConfig';
import LocalStorage from '@constants/localStorage';

export const cancelTokenSource = axios.CancelToken.source();

export const axiosInstance = axios.create({
  baseURL: `${appConfig.baseUrl}/`,
  cancelToken: cancelTokenSource.token,
});

axiosInstance.interceptors.request.use(async config => {
  const accessToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});
