import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
  ThunkCreateChatBot,
  ThunkUpdateChatBot,
} from '@store/slices/chatBots/thunks';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import StepsHolder from '@components/ChatBots/components/StepsHolder';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';

import { triggerAutocomplete } from '@components/ChatBots/constants';
import { requiredValidator } from '@utils/validators';
import useSharedStyles from '@components/CrmTicket/sharedStyles';

const CreateChatBotDialog = ({ open, handleClose, item }) => {
  const [processing, setProcessing] = useState(false);

  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();

  const validate = values => ({
    name: requiredValidator(values.name),
    trigger: requiredValidator(values.trigger, true),
    steps: requiredValidator(values.steps?.length),
  });

  const submit = async values => {
    setProcessing(true);
    if (!item) {
      const payload = {
        ...values,
        steps: values.steps.map(({ message }, index) => ({
          id: index,
          message,
        })),
      };

      await dispatch(ThunkCreateChatBot({ payload }))
        .unwrap()
        .then(() => {
          handleClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      const payload = {
        ...values,
        steps: values.steps.map(({ message }, index) => ({
          id: index,
          message,
        })),
      };

      await dispatch(ThunkUpdateChatBot({ chatBotId: item.id, payload }))
        .unwrap()
        .then(() => {
          handleClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {item ? 'Edit ' : 'Create '}
        Chat Sequence
      </DialogTitle>
      <DialogContent
        style={{ height: '50vh', maxWidth: '1000px', width: '100vw' }}
      >
        <Form
          onSubmit={submit}
          initialValues={item || {}}
          validate={validate}
          render={({ handleSubmit }) => (
            <form
              style={{
                display: 'grid',
                gridTemplateRows: '1fr 80px',
                height: '100%',
                boxSizing: 'border-box',
              }}
              onSubmit={handleSubmit}
            >
              <div>
                <FieldWrapper
                  label="Name:"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="name"
                      id="name"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Trigger:"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="trigger"
                      id="trigger"
                      render={DropDown}
                      options={triggerAutocomplete}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Steps:"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={<StepsHolder />}
                />
              </div>

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={handleClose}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateChatBotDialog;
