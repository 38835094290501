/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo, useCallback } from 'react';
import cx from 'classnames';
import InfoBox from '@common/InfoBox/InfoBox';
import planStatuses from '../planStatuses';

import useStyles from './styles';

const PlanToggleButton = ({
  name = '',
  priceCents = '',
  id = '',
  onChange,
  value,
  scopes = { Items: [] },
  basePriceCents,
  pricePerTechCents,
  minUsers,
}) => {
  const palette = useMemo(() => {
    switch (value) {
      case planStatuses.allow:
        return {
          gradientA: '#ffffff',
          gradientB: '#fff',
          font: '#000000',
          cursor: 'pointer',
        };
      case planStatuses.selected:
        return {
          gradientA: '#2F498A',
          gradientB: '#fff',
          font: '#fff',
          cursor: 'default',
        };
      case planStatuses.deny:
        return {
          gradientA: '#D3D3D3',
          gradientB: '#fff',
          font: '#646464',
          cursor: 'default',
        };
      default:
        return {
          gradientA: '#FFFFFF',
          gradientB: '#fff',
          font: '#fff',
          cursor: 'default',
        };
    }
  }, [value]);

  const classes = useStyles({ palette });

  const onToggle = useCallback(() => {
    if (value === planStatuses.allow) {
      onChange({
        id,
        priceCents,
        name,
      });
    }
  }, [value, onChange]);

  const preparedScopes = useMemo(() => {
    return scopes.Items.map(
      scope =>
        `<div style="margin: 5px 0; font-family: 'Montserrat'">-&nbsp;${scope}</div>`,
    ).join('');
  }, [scopes]);

  const getPrice = useCallback(price => (price / 100).toFixed(2), []);

  return (
    <div
      className={classes.toggleContainer}
      role="presentation"
      onClick={onToggle}
    >
      <span className={classes.period}>{name.toUpperCase()}</span>
      <span className={cx(classes.bold, classes.price)}>
        ${getPrice(basePriceCents)}
        <InfoBox text={preparedScopes} />
      </span>
      <span className={classes.period}>for first {minUsers} tech</span>
      <span className={classes.period}>
        {`$${getPrice(pricePerTechCents)}`}
      </span>
      <span className={classes.period}>each tech over {minUsers}</span>
    </div>
  );
};

export default PlanToggleButton;
