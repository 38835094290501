import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  editProfileButton: {
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  userInfoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5rem',
  },
  userInfo: {
    width: '35rem',
    padding: '1.25rem',
    borderRadius: '0.8125rem',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentWrapper: {
    width: '100%',
  },
  imageWrapper: {
    width: '50%',

    '& div': {
      width: '100%',
    },

    '& img': {
      width: '100%',
      maxHeight: '100%',
    },

    '& label': {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.info.main,
    },
  },
  textWrapper: {
    width: '100%',

    '& label': {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.info.main,
    },
  },
  formWrapper: {
    [theme.breakpoints.up('sm')]: {
      margin: '1.25rem auto',
    },
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    width: '30rem',
    padding: '1rem',
    borderRadius: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: '1.25rem',
    width: '100%',
    justifyContent: 'center',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
  labelWrapper: {
    fontWeight: 'bold',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputContainer: {
    width: '100%',
    margin: '0.625rem 0',
  },
  block: {
    display: 'flex',
    justifyContent: 'center',
  },
  fieldLabelOuter: {
    textAlign: 'left',
  },
  containerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: '35rem',
    },
  },
}));

export default styles;
