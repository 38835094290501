import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { getClientPortalConfiguration } from '@services/requests/clientPortal';

export const ThunkGetClientPortalConfigurationById = createAsyncThunk(
  `${CLIENT_PORTALS}/getClientPortalConfigurationById`,
  ({ id }) => getClientPortalConfiguration(id),
);

export default {};
