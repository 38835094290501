import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketMembers } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketMembers = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketMembers`,
  async crmId => {
    const members = await getConnectWiseTicketMembers(crmId);
    return members;
  },
);

export default {};
