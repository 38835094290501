/* eslint-disable no-param-reassign */
import { createSlice, isPending } from '@reduxjs/toolkit';
import { BUFFER_PHONE_NUMBERS } from '../resources';
import {
  ThunkGetPhoneNumbers,
  ThunkBuyPhoneNumber,
  ThunkAssignPhoneNumber,
} from './thunks';

const initialState = {
  list: [],
  isLoading: true,
};

const bufferPhoneNumbersSlice = createSlice({
  name: BUFFER_PHONE_NUMBERS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ThunkGetPhoneNumbers.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.isLoading = false;
      })

      .addCase(ThunkAssignPhoneNumber.fulfilled, (state, { payload }) => {
        state.list = state.list.filter(i => i.id !== payload);
      })

      .addCase(ThunkBuyPhoneNumber.fulfilled, (state, action) => {
        state.list.push(action.payload);
        state.isLoading = false;
      })

      .addCase(ThunkBuyPhoneNumber.rejected, state => {
        state.isLoading = false;
      })

      .addMatcher(isPending(ThunkGetPhoneNumbers), state => {
        state.isLoading = true;
      })

      .addMatcher(isPending(ThunkBuyPhoneNumber), state => {
        state.isLoading = true;
      });
  },
});

export default bufferPhoneNumbersSlice.reducer;

export const PhoneNumbersListSelector = state =>
  state[BUFFER_PHONE_NUMBERS].list;
export const PhoneNumbersLoadingSelector = state =>
  state[BUFFER_PHONE_NUMBERS].isLoading;
