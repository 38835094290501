import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import classnames from 'classnames';

import {
  ThunkGetVerificationSettings,
  ThunkResetTechVerificationSettings,
  ThunkResetVerificationScreenSettings,
  ThunkSaveVerificationSettings,
} from '@store/slices/verificationSettings/thunks';

import {
  emailMaxLength,
  smsMaxLength,
} from '@components/Settings/VerificationSettings/components/constants';

import { requiredValidator } from '@utils/validators';
import maxLengthValidator from '@utils/validators/maxLengthValidator';

import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import ValidationCodeSettings from '@components/Settings/VerificationSettings/components/ValidationCodeSettings';
import ValidationScreenSettings from '@components/Settings/VerificationSettings/components/ValidationScreenSettings';
import PsaNotesSettings from '@components/Settings/VerificationSettings/components/PsaNotesSettings';
import PsaTechResponseSettings from '@components/Settings/VerificationSettings/components/PsaTechResponseSettings';
import VerificationPhone from '@components/Settings/VerificationSettings/components/VerificationPhone';

import { useGetPricePlan } from '../../../../hooks/useGetPricePlan';

import useStyles from './styles';

const VerificationSettings = ({
  onCancel,
  skipBorders = false,
  customClass,
}) => {
  const classes = useStyles({ skipBorders });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isFreePlan } = useGetPricePlan();

  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [initial, setInitial] = useState({});

  const submit = values => {
    setProcessing(true);

    dispatch(ThunkSaveVerificationSettings(values))
      .unwrap()
      .then(d => {
        setInitial(d);
        enqueueSnackbar('Settings updated', { variant: 'success' });
      })
      .finally(() => setProcessing(false));
  };

  const validate = values => {
    const techMessage = values.useCodeForTechVerification
      ? requiredValidator(values.techVerificationCodeConfirmMessage)
      : undefined;
    return {
      smsBody: maxLengthValidator(values.smsBody, smsMaxLength, false),
      emailBody: maxLengthValidator(values.emailBody, emailMaxLength, false),
      emailLinkBody: maxLengthValidator(
        values.emailLinkBody,
        emailMaxLength,
        false,
      ),
      durationMin:
        values.durationMin < 1 || values.durationMin > 120
          ? 'Invalid value'
          : undefined,
      resetVerificationInMin:
        values.resetVerification && values.resetVerificationInMin < 1
          ? 'Invalid value'
          : undefined,
      headerText: requiredValidator(values.headerText),
      headerColor: requiredValidator(values.headerColor),
      gradientColor1: requiredValidator(values.gradientColor1),
      gradientColor2: requiredValidator(values.gradientColor2),
      successMessageText: requiredValidator(values.successMessageText),
      successMessageColor: requiredValidator(values.successMessageColor),
      failedMessageText: requiredValidator(values.failedMessageText),
      failedMessageColor: requiredValidator(values.failedMessageColor),
      techVerificationCodeConfirmMessage: techMessage,
      ticketNoteIfVerificationExpired: values.addTicketNoteIfVerificationExpired
        ? requiredValidator(values.ticketNoteIfVerificationExpired)
        : undefined,
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    dispatch(ThunkGetVerificationSettings())
      .unwrap()
      .then(d => setInitial(d))
      .finally(() => setLoading(false));
  }, [dispatch]);

  const handleVerificationScreenSettingsReset = useCallback(
    form => () => {
      setProcessing(true);
      dispatch(ThunkResetVerificationScreenSettings()).then(() => {
        dispatch(ThunkGetVerificationSettings())
          .unwrap()
          .then(d => form.reset(d))
          .finally(() => setProcessing(false));
      });
    },
    [dispatch],
  );

  const handleTechVerificationSettingsReset = useCallback(
    form => () => {
      setProcessing(true);
      dispatch(ThunkResetTechVerificationSettings()).then(() => {
        dispatch(ThunkGetVerificationSettings())
          .unwrap()
          .then(d => form.reset(d))
          .finally(() => setProcessing(false));
      });
    },
    [dispatch],
  );

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <div className={classnames(classes.container, customClass)}>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={initial}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            {!isFreePlan && <VerificationPhone />}

            <PsaTechResponseSettings
              processing={processing}
              form={form}
              values={values}
              onReset={handleTechVerificationSettingsReset(form)}
            />

            <ValidationCodeSettings
              processing={processing}
              form={form}
              values={values}
            />

            <ValidationScreenSettings
              processing={processing}
              form={form}
              values={values}
              onReset={handleVerificationScreenSettingsReset(form)}
            />

            <PsaNotesSettings processing={processing} values={values} />

            <div className={classes.buttonContainer}>
              {onCancel && (
                <ReusableButton
                  label="Cancel"
                  onClick={onCancel}
                  style={{ marginRight: '0.5rem' }}
                />
              )}
              <ReusableButton
                label="Submit"
                type="submit"
                loading={processing}
                disabled={processing}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

VerificationSettings.propTypes = {
  onCancel: PropTypes.func,
  skipBorders: PropTypes.bool,
  customClass: PropTypes.string,
};

export default VerificationSettings;
