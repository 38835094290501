import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA } from '@store/slices/resources';
import { sendSecureLink } from '@services/requests/secureData';

export const ThunkSendSecureLink = createAsyncThunk(
  `${SECURE_DATA}/sendSecureLink`,
  ({ payload }) => sendSecureLink(payload),
);

export default {};
