import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloTeams } from '@services/requests/haloTickets';

export const ThunkGetHaloTeams = createAsyncThunk(
  `${HALO_TICKET}/getHaloTeams`,
  async crmId => {
    const teams = await getHaloTeams(crmId);
    return teams;
  },
);

export default {};
