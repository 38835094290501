import { createAsyncThunk } from '@reduxjs/toolkit';
import { COUNTRIES } from '@store/slices/resources';
import { getCountries } from '@services/requests/сountries';

export const ThunkGetCountries = createAsyncThunk(
  `${COUNTRIES}/getCountries`,
  async () => {
    const countries = await getCountries();

    return countries;
  },
);

export default {};
