import { createAsyncThunk } from '@reduxjs/toolkit';
import { createContactFromBlockedSms } from '@services/requests/messaging';
import { SMS_BLOCKED } from '@store/slices/resources';

export const ThunkCreateContactFromBlockedSms = createAsyncThunk(
  `${SMS_BLOCKED}/createContactFromBlockedSms`,
  async ({ blockedMessageId, payload }) =>
    createContactFromBlockedSms(blockedMessageId, payload),
);

export default {};
