import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { actions } from '@store/actions';
import { ThunkUpdateAIPricePlan } from '@store/slices/helpdeskAI/thunks';

import { ReactComponent as DoubleCheckIcon } from '@assets/icons/updated/check_double.svg';
import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const PricePlan = ({
  name,
  monthlyPrice,
  amountOfMinutes,
  scopes,
  isSelected = false,
  id,
}) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const myPlan = useSelector(state => state.pricePlan.plan);

  const handlePlanChange = () => {
    setProcessing(true);
    dispatch(ThunkUpdateAIPricePlan({ planId: id }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Plan updated successfully', {
          variant: 'success',
        });
        dispatch(
          actions.setPricePlan({
            ...myPlan,
            helpdeskAiPricePlanId: id,
            helpdeskAiLimit: amountOfMinutes,
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div
      className={cx(classes.pricePlan, {
        [classes.pricePlan_active]: isSelected,
      })}
    >
      <h2 className={classes.pricePlan_name}>
        {name}
        <>
          {isSelected && (
            <div className={classes.pricePlan_selected}>
              Selected
              <DoubleCheckIcon />
            </div>
          )}
        </>
      </h2>

      <div className={classes.pricePlan_price}>
        <b>{`$${monthlyPrice}/`}</b>
        month
      </div>

      <ul className={classes.benefitsList}>
        <li>{`${amountOfMinutes} minutes of calls per months`}</li>
        {scopes?.Items?.length &&
          scopes?.Items.filter(scope => !!scope).map(scope => (
            <li key={scope}>{scope}</li>
          ))}
      </ul>

      <ReusableButton
        viewType="blue"
        disabled={isSelected || processing}
        loading={processing}
        onClick={handlePlanChange}
      >
        Select Plan
      </ReusableButton>
    </div>
  );
};

PricePlan.propTypes = {
  name: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.number.isRequired,
  amountOfMinutes: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  scopes: PropTypes.shape({ Items: PropTypes.arrayOf(PropTypes.string) }),
  id: PropTypes.string.isRequired,
};

export default PricePlan;
