import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

export const useGroupFormatterStyles = makeStyles<Theme, unknown>(
  () => ({
    row: {
      left: ({width}) => `${width/ 2 - 69}px`,
      position: "absolute",
      color:'white',
      transform: "translateX(-50%)"
    }
  }));

