import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAssignConnectWiseTicketMember,
  ThunkGetConnectWiseTicketMembers,
} from '@store/slices/connectWiseTicket/thunks';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../styles';

const AssignMemberDialog = ({ open, setOpen, record, crmDefaultId }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [members, setMembers] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async values => {
    setProcessing(true);

    await dispatch(
      ThunkAssignConnectWiseTicketMember({
        crmId: crmDefaultId,
        ticketId: record.id,
        memberId: values.memberId,
      }),
    ).unwrap();

    const member = members.find(item => item.value === values.memberId);
    const updatedList = list.map(item =>
      item.id === record.id
        ? {
            ...record,
            owner: member.label,
            ownerId: values.memberId,
          }
        : item,
    );
    dispatch(actions.saveList(updatedList));
    setProcessing(false);
    handleClose();
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    const membersData = await dispatch(
      ThunkGetConnectWiseTicketMembers(crmDefaultId),
    ).unwrap();
    setMembers(membersData);
    setLoading(false);
  }, [dispatch, crmDefaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Assign Member</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            initialValues={{
              memberId: +record.ownerId,
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <FieldWrapper
                  label="Owner"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      name="memberId"
                      id="memberId"
                      render={AutocompleteFormInput}
                      items={members}
                      disabled={processing}
                    />
                  }
                  classNameLabelInner={classes.alignLeft}
                />
                <div className={classes.formActions}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      handleClose();
                    }}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AssignMemberDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  crmDefaultId: PropTypes.number,
  record: PropTypes.shape({
    ownerId: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default AssignMemberDialog;
