import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    height: '83vh',
    margin: '20px',
    borderRadius: '15px',
    padding: '20px',
    background: 'white',
    position: 'relative',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '10px 0px',
  },
  tableActionContainer: {
    width: '40%',
    margin: '20px 26px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: '2',
  },
  tableContainer: {
    position: 'absolute',
    top: '6rem',
    width: '97%',
  },
  groupActionButtons: {
    display: 'flex',
    marginLeft: '15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '350px',
  },
  alignLeft: {
    textAlign: 'start',
  },
  formActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
}));

export default useStyles;
