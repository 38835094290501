import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { resetTechVerificationSettings } from '@services/requests/verificationSettings';

export const ThunkResetTechVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/resetTechVerificationSettings`,
  () => resetTechVerificationSettings(),
);

export default {};
