import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      width: '31rem',
      margin: '0.5rem auto',
    },
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0.5rem 2.5rem',
    border: '0.0625rem solid #EBEBEB',
    backgroundColor: theme.palette.background.default,
    margin: '0.5rem',
    borderRadius: '0.8125rem',
  },
  inputGroup: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    width: '100%',
    marginTop: '0.625rem',
  },
  imageGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '2.5rem',
  },
  font: {
    fontStyle: 'normal',
    fontSize: '0.875rem',
  },
  title: {
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  textPartOne: {
    textAlign: 'center',
    marginTop: '2.5rem',
    fontSize: '1rem',
  },
  textPartTwo: {
    textAlign: 'center',
    marginTop: '0.25rem',
    fontSize: '1rem',
  },
  block: {
    [theme.breakpoints.up('sm')]: {},
    width: '93%',
    textAlign: 'center',
    margin: '0.5rem 0',
  },
  gray: {
    color: 'gray',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
  inputSmallWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: '49%',
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: '2.5rem',
    marginLeft: 0,
  },

  buttonInvoice: {
    marginTop: '0.5rem',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vpnIcon: {
    fill: '#F3A594',
  },
  aStyle: {
    display: 'table-cell',
  },
  preWidth: {
    width: '49%',
  },
}));

export default useStyles;
