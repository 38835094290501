import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

const DetailsTableItem = ({
  contentFirstCell,
  contentSecondCell,
  contentThirdCell,
  contentFourthCell,
}) => {
  console.log('contentFirstCell', contentFirstCell);
  console.log('contentSecondCell', contentSecondCell);
  console.log('contentThirdCell', contentThirdCell);
  console.log('contentFourthCell', contentFourthCell);
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {contentFirstCell !== undefined && (
        <TableCell align="center">{contentFirstCell.toString()}</TableCell>
      )}
      {contentSecondCell && (
        <TableCell align="center">{contentSecondCell}</TableCell>
      )}
      {contentThirdCell && (
        <TableCell align="center">{contentThirdCell}</TableCell>
      )}
      {contentFourthCell && (
        <TableCell align="center">{contentFourthCell}</TableCell>
      )}
    </TableRow>
  );
};

DetailsTableItem.propTypes = {
  contentFirstCell: PropTypes.bool,
  contentSecondCell: PropTypes.string,
  contentThirdCell: PropTypes.string,
  contentFourthCell: PropTypes.string,
};

export default DetailsTableItem;
