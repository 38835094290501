import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationBoardStatuses } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationBoardStatuses = createAsyncThunk(
  `${INTEGRATION}/getIntegrationBoardStatuses`,
  async ({ id, boardId }) => {
    const boardStatuses = await getIntegrationBoardStatuses(id, boardId);
    return boardStatuses;
  },
);

export default {};
