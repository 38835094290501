import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION } from '@store/slices/resources';
import { axiosInstance } from '@components/Table/axios';

export const ThunkGetConnectWiseIntegrationCompanies = createAsyncThunk(
  `${INTEGRATION}/getConnectWiseIntegrationCompanies`,
  async ({ crmId }) => {
    const { data, headers } = await axiosInstance.request({
      method: 'GET',
      url: `/Integration/v2/connectwise/${crmId}/companies`,
      params: {
        _start: 0,
        _end: 20,
      },
    });
    return { data, totalOptionsCount: +headers.get('x-total-count') };
  },
);

export default {};
