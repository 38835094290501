import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  formWrapper: {
    [theme.breakpoints.up('sm')]: {
      margin: '1.25rem auto',
    },
  },
}));

export default styles;
