export const notificationTypes = Object.freeze({
  Ticket: 'Ticket',
  Email: 'Email',
  Sms: 'Sms',
  WebHook: 'WebHook',
  Slack: 'Slack',
  Teams: 'Teams',
  Ftp: 'Ftp',
  Voice: 'Voice',
});

export default {};
