import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '12px',
    '& tbody': {
      maxHeight: '73vh',
    },
  },
  actionsContainer: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '300px',
  },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
}));

export default useStyles;
