import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    margin: '0.5rem 0',
    textAlign: 'left',
    fontSize: '0.875rem',
  },
  deadLineFields: { display: 'flex', gap: '10px', alignItems: 'flex-end' },
}));

export default useStyles;
