import React from 'react';
import { ReactComponent as CustomTypesIcon } from '@assets/icons/customTypes.svg';
import Types from './Types';

const Icon = () => <CustomTypesIcon alt="customTypesIcon" />;

export default {
  list: Types,
  icon: Icon,
};
