/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TREE_VIEW } from '../resources';
import {
  ThunkGetCrmDefaults,
  ThunkGetCrmSources,
  ThunkGetCrmDefaultBoards,
  ThunkGetCrmBoardStatuses,
  ThunkGetCrmDefaultCompanies,
} from './thunks';

const crmSourceSlice = createSlice({
  name: TREE_VIEW,
  reducers: {},
  initialState: {
    crmSources: [],
    crmDefaults: {},
    boards: {},
    statuses: {},
    companies: {},
  },
  extraReducers: builder => {
    builder.addCase(ThunkGetCrmSources.fulfilled, (state, action) => {
      state.crmSources = action.payload;
    });

    builder.addCase(ThunkGetCrmDefaults.fulfilled, (state, action) => {
      state.crmDefaults = { ...state.crmDefaults, ...action.payload };
    });

    builder.addCase(ThunkGetCrmDefaultBoards.fulfilled, (state, action) => {
      state.boards = { ...state.boards, ...action.payload };
    });

    builder.addCase(ThunkGetCrmBoardStatuses.fulfilled, (state, action) => {
      state.statuses = { ...state.statuses, ...action.payload };
    });

    builder.addCase(ThunkGetCrmDefaultCompanies.fulfilled, (state, action) => {
      state.companies = { ...state.companies, ...action.payload };
    });
  },
});

export default crmSourceSlice.reducer;
