import { createAsyncThunk } from '@reduxjs/toolkit';
import { EMAIL_SETTINGS } from '@store/slices/resources';
import { getEmailTemplatesSettings } from '@services/requests/emailSettings';

export const ThunkGetEmailTemplatesSettings = createAsyncThunk(
  `${EMAIL_SETTINGS}/getEmailTemplatesSettings`,
  () => getEmailTemplatesSettings(),
);

export default {};
