import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';

const SophosForm = ({ classes, loading, useLabels }) => {
  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>Api key:</Typography>
      )}
      <Field
        id="publicKey"
        name="publicKey"
        styleType="main"
        fullWidth
        inputView="text"
        component={Input}
        type="text"
        classNameWrapper={classes.inputWrapper}
        placeholder="Client ID"
        disabled={loading}
      />
      <Field
        id="privateKey"
        name="privateKey"
        styleType="main"
        fullWidth
        inputView="text"
        component={Input}
        type="password"
        classNameWrapper={classes.inputWrapper}
        placeholder="Client Secret"
        disabled={loading}
      />
    </>
  );
};

SophosForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default SophosForm;
