import React from 'react';
import PropTypes from 'prop-types';
import crmSources from '@constants/crmSources';
import CreateCrmCard from './SelectCrmCard';
import useStyles from '../formStyles';

const SelectCrm = ({ handleSelect, showSmallCrmIcon }) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      {Object.entries(crmSources)
        // TODO this was disabled according to a product owner requirements
        .filter(p => p[1].name !== crmSources.ServiceNow.name)
        .map(i => (
          <CreateCrmCard
            key={i[0]}
            item={i[1]}
            handleClick={handleSelect}
            showSmallCrmIcon={showSmallCrmIcon}
          />
        ))}
    </div>
  );
};

SelectCrm.propTypes = {
  handleSelect: PropTypes.func.isRequired,
};

export default SelectCrm;
