import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Dialog } from '@material-ui/core';
import DialogTitle from '@common/Dialog/DialogTitle';
import PostPanel from './PostPanel';

import useStyles from './styles';

const DetailsDialog = ({
  isOpen,
  onClose,
  record,
  fields,
  typeValues,
  onRefresh,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle onClose={onClose}>Data details</DialogTitle>
      <DialogContent>
        {(record.acknowledge || record.assignment) && (
          <div className={classes.header}>
            <div>
              <b>Acknowledge:</b>
              &nbsp;
              {record.acknowledge}
            </div>
            <div>
              <b>Assignee:</b>
              &nbsp;
              {record.assignment}
            </div>
          </div>
        )}
        <PostPanel
          records={[record]}
          typeValues={typeValues}
          fields={fields.filter(i => i.identifier)}
          showActions={false}
          onClose={onClose}
          onRefresh={onRefresh}
        />
        <div className={classes.resultsText}>
          <span>Results</span>
        </div>
        <PostPanel
          // eslint-disable-next-line no-underscore-dangle
          records={record.__data__}
          typeValues={typeValues}
          fields={fields.filter(i => !i.identifier)}
          onClose={onClose}
          onRefresh={onRefresh}
          recordId={record.id}
        />
      </DialogContent>
    </Dialog>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

DetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  typeValues: PropTypes.arrayOf(PropTypes.object),
};

export default DetailsDialog;
