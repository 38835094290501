import React from 'react';
import PropTypes from 'prop-types';
import Loading from '@common/Loading/Loading';
import useLoadingData from '@components/Hooks/useLoadingData';
import { getDeviceMonitor } from '../helpers';
import Form from '../components/Details/Form';

const Edit = ({ match }) => {
  const { data, loading } = useLoadingData({
    apiRequest: getDeviceMonitor,
    id: match.params.id,
  });

  return loading ? <Loading /> : <Form data={data} />;
};

Edit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Edit;
