import { createAsyncThunk } from '@reduxjs/toolkit';
import { softDeleteTriages } from '@services/requests/triage';
import { TRIAGE } from '@store/slices/resources';

export const ThunkSoftDeleteTriages = createAsyncThunk(
  `${TRIAGE}/softDeleteTriages`,
  async ids => {
    await softDeleteTriages(ids);
    return ids;
  },
);

export default {};
