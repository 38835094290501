import { createAsyncThunk } from '@reduxjs/toolkit';
import { autotaskCheckCodeRequest } from '@services/requests/psaPods';
import { AUTOTASK_POD } from '@store/slices/resources';

export const ThunkAutotaskCheckCode = createAsyncThunk(
  `${AUTOTASK_POD}/autotaskCheckCode`,
  async payload => {
    const res = await autotaskCheckCodeRequest(payload);
    return res;
  },
);

export default {};
