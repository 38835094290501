import React from 'react';
import PropTypes from 'prop-types';
import { Button, useListContext } from 'react-admin';
import cx from 'classnames';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

import useStyles from './styles';

const HeaderTable = ({ column }) => {
  const { name, sort } = column;
  const classes = useStyles();

  const { setSort } = useListContext();

  return (
    <div className={classes.containerHeader}>
      {sort ? (
        <Button className={classes.tableHeaderButton} onClick={setSort}>
          <>
            {name}
            <ArrowUpward className={classes.arrow} />
            <ArrowDownward className={cx(classes.arrow, classes.arrowDown)} />
          </>
        </Button>
      ) : (
        column.name
      )}
    </div>
  );
};

HeaderTable.propTypes = {
  column: PropTypes.shape({
    sort: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
};

export default HeaderTable;
