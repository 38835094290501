import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../styles';
import HomePageTile from '../HomePageTile';

const CategoryItem = ({ item }) => {
  const classes = useStyles();

  if (!item.items.length) return null;
  return (
    <div>
      <div className={classes.categoryItemLabel}>{item.label}</div>
      <div className={classes.categoryItemList}>
        {item.items.map(({ title, description, icon, path }) => (
          <HomePageTile
            key={title}
            Icon={icon}
            title={title}
            description={description}
            path={path}
          />
        ))}
      </div>
    </div>
  );
};

CategoryItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        path: PropTypes.string,
      }),
    ),
  }),
};

export default CategoryItem;
