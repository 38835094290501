import React from 'react';
import PropTypes from 'prop-types';
import { useSafeSetState } from 'react-admin';
import { useSnackbar } from 'notistack';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import cx from 'classnames';
import { upsertDashboard } from '@components/Dashboard/helper';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardOptions } from '@store/selectors/dashboard';
import { actions } from '@store/actions';
import useStyles from '../styles';

const init = {
  id: 0,
  name: '',
};

const DashboardForm = ({ onClose, open, data, setDashboardId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const initialData = data || init;
  const options = useSelector(dashboardOptions);
  const headerText = data ? 'Update dashboard name' : 'Create new dashboard';
  const [loading, setLoading] = useSafeSetState(false);

  const validate = values => {
    return {
      name: !values.name ? 'Required' : undefined,
    };
  };

  const submit = values => {
    setLoading(true);
    upsertDashboard(values)
      .then(d => {
        const newOptions = [...options];
        const idx = newOptions.findIndex(i => i.id === d.id);
        if (idx === -1) {
          newOptions.push(d);
          setDashboardId(d.id);
        } else {
          newOptions.splice(idx, 1, d);
        }
        dispatch(actions.dashboard_setOptions(newOptions));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{headerText}</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialData}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  styleType="main"
                  inputView="text"
                  placeholder="Dashboard name"
                  disabled={loading}
                />
              </div>
              <div className={classes.modalButtonContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={onClose}
                  disabled={loading}
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                />
                <ReusableButton
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  loading={loading}
                  label="OK"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

DashboardForm.propTypes = {
  onClose: PropTypes.func,
  setDashboardId: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
};

export default DashboardForm;
