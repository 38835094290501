import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import putWithResponse from '@services/api/common/putWithResponse';
import { dashboard, notificationSettings } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';
import deleteData from '@services/api/common/deleteData';

export const getTiles = dashboardId =>
  getData(`${appConfig.baseUrl}${dashboard}${dashboardId}/Tiles`);

export const getDashboards = () => getData(`${appConfig.baseUrl}${dashboard}`);

export const updateTiles = data =>
  putWithResponse(`${appConfig.baseUrl}${dashboard}UpdateTiles`, data);

export const createTile = dashboardId =>
  postWithResponse(
    `${appConfig.baseUrl}${dashboard}${dashboardId}/Tiles`,
    null,
  );

export const deleteTile = tileId =>
  deleteData(`${appConfig.baseUrl}${dashboard}Tiles/${tileId}`);

export const upsertDashboard = data =>
  postWithResponse(`${appConfig.baseUrl}${dashboard}`, data);

export const deleteDashboard = id =>
  deleteData(`${appConfig.baseUrl}${dashboard}${id}`);

export const updateTile = data =>
  putWithResponse(`${appConfig.baseUrl}${dashboard}Tiles`, data);

export const getDataViewOptions = () =>
  getData(`${appConfig.baseUrl}${notificationSettings}auditViews`);

export const getTileItemData = itemId =>
  getData(`${appConfig.baseUrl}${dashboard}Tiles/Item/${itemId}`);
