import React from 'react';
import PropTypes from 'prop-types';
import IgnoredList from './IgnoredList';

const Ignored = ({ mailBoxId }) => {
  const resource = 'letters/'.concat(mailBoxId).concat('/ignored');

  return <IgnoredList resource={resource} mailBoxId={mailBoxId} />;
};

Ignored.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Ignored;
