import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

import MessagingConfigurationForm from '../components/MessagingConfigurationsForm';

const AddConfigurationDialog = ({
  open,
  setOpen,
  item,
  setItem,
  setIsNewConfiguration,
}) => {
  useEffect(() => {
    return () => {
      if (item) {
        setItem(undefined);
      }
    };
  }, [item, setItem]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      size="md"
    >
      <DialogTitle>
        {item ? 'Edit ' : 'Add '}
        Configuration
      </DialogTitle>
      <DialogContent>
        <MessagingConfigurationForm
          setOpen={setOpen}
          item={item}
          setIsNewConfiguration={setIsNewConfiguration}
        />
      </DialogContent>
    </Dialog>
  );
};

AddConfigurationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setItem: PropTypes.func,
  setIsNewConfiguration: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    crmType: PropTypes.number,
    crmDefaultId: PropTypes.number,
    mainPhoneNumberId: PropTypes.number,
    mainPhoneNumber: PropTypes.string,
    allowUsersCreateChannel: PropTypes.bool,
    allowOnlyPsaUsersCreateChannel: PropTypes.bool,
    sendConfirmationBack: PropTypes.bool,
    channelLinkToTicket: PropTypes.string,
    shiftTicketTime: PropTypes.string,
    shiftTimeValue: PropTypes.string,
    notificationGroupId: PropTypes.number,
  }),
};

export default AddConfigurationDialog;
