/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CONNECT_WISE_TICKET } from '../resources';
import {
  ThunkGetConnectWiseTicketScheduleEntries,
  ThunkAddConnectWiseTicketScheduleEntry,
  ThunkDeleteConnectWiseTicketScheduleEntry,
  ThunkGetConnectWiseTicketTimeEntries,
  ThunkAddConnectWiseTicketTimeEntry,
  ThunkDeleteConnectWiseTicketTimeEntry,
  ThunkEditConnectWiseTicketTimeEntry,
  ThunkEditConnectWiseTicketScheduleEntry,
  ThunkGetConnectWiseTicketNotes,
  ThunkAddConnectWiseTicketNote,
  ThunkEditConnectWiseTicketNote,
  ThunkDeleteConnectWiseTicketNote,
  ThunkToggleConnectWiseTicketIssue,
  ThunkToggleConnectWiseTicketResolution,
} from './thunks';

const connectWiseTicketSlice = createSlice({
  name: CONNECT_WISE_TICKET,
  initialState: {
    scheduleEntries: [],
    timeEntries: [],
    notes: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // * * Schedule Entries
      .addCase(
        ThunkGetConnectWiseTicketScheduleEntries.fulfilled,
        (state, { payload }) => {
          state.scheduleEntries = payload;
        },
      )

      .addCase(
        ThunkAddConnectWiseTicketScheduleEntry.fulfilled,
        (state, { payload }) => {
          state.scheduleEntries.push(payload);
        },
      )

      .addCase(
        ThunkDeleteConnectWiseTicketScheduleEntry.fulfilled,
        (state, { payload }) => {
          state.scheduleEntries = state.scheduleEntries.filter(
            item => item.id !== payload,
          );
        },
      )

      .addCase(
        ThunkEditConnectWiseTicketScheduleEntry.fulfilled,
        (state, { payload }) => {
          state.scheduleEntries = state.scheduleEntries.map(i =>
            i.id !== payload.id ? i : payload,
          );
        },
      )

      //   * * Time Entries
      .addCase(
        ThunkGetConnectWiseTicketTimeEntries.fulfilled,
        (state, { payload }) => {
          state.timeEntries = payload;
        },
      )

      .addCase(
        ThunkAddConnectWiseTicketTimeEntry.fulfilled,
        (state, { payload }) => {
          state.timeEntries.push(payload);
        },
      )

      .addCase(
        ThunkDeleteConnectWiseTicketTimeEntry.fulfilled,
        (state, { payload }) => {
          state.timeEntries = state.timeEntries.filter(i => i.id !== payload);
        },
      )

      .addCase(
        ThunkEditConnectWiseTicketTimeEntry.fulfilled,
        (state, { payload }) => {
          state.timeEntries = state.timeEntries.map(i =>
            i.id !== payload.id ? i : payload,
          );
        },
      )

      // ** Notes
      .addCase(
        ThunkGetConnectWiseTicketNotes.fulfilled,
        (state, { payload }) => {
          state.notes = payload;
        },
      )
      .addCase(
        ThunkAddConnectWiseTicketNote.fulfilled,
        (state, { payload }) => {
          state.notes.push(payload);
        },
      )
      .addCase(
        ThunkEditConnectWiseTicketNote.fulfilled,
        (state, { payload }) => {
          state.notes = state.notes.map(i =>
            i.id !== payload.id ? i : payload,
          );
        },
      )
      .addCase(
        ThunkDeleteConnectWiseTicketNote.fulfilled,
        (state, { payload }) => {
          state.notes = state.notes.filter(i => i.id !== payload);
        },
      )
      .addCase(
        ThunkToggleConnectWiseTicketIssue.fulfilled,
        (state, { payload }) => {
          state.notes = state.notes.map(i =>
            i.id !== payload.noteId
              ? i
              : {
                  ...i,
                  resolutionFlag: payload.resolutionFlag,
                  issueFlag: payload.issueFlag,
                },
          );
        },
      )
      .addCase(
        ThunkToggleConnectWiseTicketResolution.fulfilled,
        (state, { payload }) => {
          state.notes = state.notes.map(i =>
            i.id !== payload.noteId
              ? i
              : {
                  ...i,
                  resolutionFlag: payload.resolutionFlag,
                  issueFlag: payload.issueFlag,
                },
          );
        },
      );
  },
});

export default connectWiseTicketSlice.reducer;

export const ConnectWiseTicketScheduleEntriesSelector = state =>
  state[CONNECT_WISE_TICKET].scheduleEntries;
export const ConnectWiseTicketTimeEntrySelector = state =>
  state[CONNECT_WISE_TICKET].timeEntries;
export const ConnectWiseTicketNotes = state => state[CONNECT_WISE_TICKET].notes;
