import { KASEYA_POD, TICKET_UPDATE } from '@services/requests/requestResources';
import { makeRequest } from '../makeRequest';

export const getKaseyaAccounts = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/accounts`);

export const getKaseyaLocations = (crmDefaultId, accountId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/accounts/${accountId}/locations`,
  );

export const getKaseyaContacts = (crmDefaultId, accountId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/accounts/${accountId}/contacts`,
  );

export const getAllKaseyaContacts = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/contacts`);

export const getKaseyaQueues = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/queues`);

export const getKaseyaStatuses = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/statuses`);

export const getKaseyaPriorities = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/priorities`);

export const getKaseyaTypes = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/types`);

export const getKaseyaIssueTypes = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/issueTypes`);

export const getKaseyaIssueSubTypes = (crmDefaultId, issueTypeId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/issueTypes/${issueTypeId}/issueSubTypes`,
  );

export const updateKaseyaTicket = (crmDefaultId, payload) =>
  makeRequest('PUT', `${TICKET_UPDATE}/kaseya/${crmDefaultId}`, payload);

export const assignKaseyaTicketContact = (crmDefaultId, ticketId, contactId) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/tickets/${ticketId}/assignContact/${contactId}`,
  );

export const getKaseyaTicketNotes = (crmDefaultId, ticketId) =>
  makeRequest(
    'GET',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/tickets/${ticketId}/notes`,
  );

export const createKaseyaTicketNote = (crmDefaultId, ticketNumber, payload) =>
  makeRequest(
    'POST',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/tickets/${ticketNumber}/notes`,
    payload,
  );

export const updateKaseyaTicketNote = (
  crmDefaultId,
  ticketId,
  noteId,
  payload,
) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}`,
    payload,
  );

export const deleteKaseyaTicketNote = (crmDefaultId, ticketId, noteId) =>
  makeRequest(
    'DELETE',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/tickets/${ticketId}/notes/${noteId}`,
  );

export const kaseyaSendCode = payload =>
  makeRequest('POST', `${KASEYA_POD}/send`, payload);

export const kaseyaCheckCode = payload =>
  makeRequest('POST', `${KASEYA_POD}/check`, payload);

export const getKaseyaAssignees = crmDefaultId =>
  makeRequest('GET', `${TICKET_UPDATE}/kaseya/${crmDefaultId}/assignees`);

export const assignKaseyaTicketAssignee = (
  crmDefaultId,
  ticketId,
  assigneeId,
) =>
  makeRequest(
    'PUT',
    `${TICKET_UPDATE}/kaseya/${crmDefaultId}/tickets/${ticketId}/assignResource/${assigneeId}`,
  );
