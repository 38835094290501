import { createAsyncThunk } from '@reduxjs/toolkit';
import { HALO_TICKET } from '@store/slices/resources';
import { getHaloUsers } from '@services/requests/haloTickets';

export const ThunkGetHaloUsers = createAsyncThunk(
  `${HALO_TICKET}/getHaloUsers`,
  async ({
    crmId,
    clientId = undefined,
    siteId = undefined,
    useNameOnly = false,
  }) => {
    const users = await getHaloUsers(crmId, clientId, siteId, useNameOnly);
    return users;
  },
);

export default {};
