import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import StatusCircle from '@common/StatusCircle/StatusCircle';
import ActionField from '@common/ActionFields/ActionField';
import Table from '@components/Table';
import { actions } from '@store/actions';
import { TableTypes } from '@components/Table/constants';
import DeleteDialog from './DeleteDialog';
import { toggleEnabled } from './helpers';

import useStyles from '../styles';

const List = ({ resource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [recordForDelete, setRecordForDelete] = useState(null);

  const handleMakeEnabled = useCallback(
    (id, isActive) => {
      toggleEnabled(id, !isActive)
        .then(() => {
          dispatch(
            actions.patchListItem({
              id,
              field: 'enabled',
              value: !isActive,
            }),
          );
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [dispatch, enqueueSnackbar],
  );

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        key: 'id',
        sortable: true,
        width: 100,
        minWidth: 100,
      },
      {
        name: 'Tenant Name',
        key: 'tenantName',
        sortable: true,
        searchable: true,
        width: 270,
        minWidth: 200,
      },
      {
        name: 'Tenant Alias',
        key: 'tenantAlias',
        width: 270,
        minWidth: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Users count',
        key: 'usersCount',
        sort: 'usersCount',
        width: 150,
        minWidth: 150,
      },
      {
        name: 'Tenant Admin',
        key: 'tenantAdmin',
        minWidth: 200,
        searchable: true,
      },
      {
        name: 'Enabled',
        key: 'enabled',
        isCustomAccessor: true,
        accessor: row => (
          <div className={classes.center}>
            <StatusCircle
              handleClick={handleMakeEnabled}
              row={row}
              rowKey="enabled"
            />
          </div>
        ),
        width: 100,
        minWidth: 100,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        minWidth: 100,
        accessor: rowData => (
          <div className={classes.center}>
            <ActionField
              label="Actions"
              record={rowData}
              onDelete={setRecordForDelete}
              fieldKey="delete"
            />
          </div>
        ),
      },
    ],
    [classes.center, handleMakeEnabled],
  );

  return (
    <div className={classes.tenantContainer}>
      <Table
        columns={columns}
        resource={resource}
        defaultSort={{ fieldName: 'tenantName', order: 'ASC' }}
      />
      <DeleteDialog
        isOpen={!!recordForDelete}
        onClose={() => setRecordForDelete(null)}
        record={recordForDelete}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
