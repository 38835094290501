import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmDefaultBoards } from '@services/requests/treeView';
import { TREE_VIEW } from '@store/slices/resources';

export const ThunkGetCrmDefaultBoards = createAsyncThunk(
  `${TREE_VIEW}/getCrmDefaultBoards`,
  async ({ crmId }) => {
    const boards = await getCrmDefaultBoards(crmId);
    return { [crmId]: boards };
  },
);

export default {};
