/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, isFulfilled } from '@reduxjs/toolkit';
import { INCIDENT } from '../resources';
import {
  ThunkGetIncident,
  ThunkResolveIncident,
  ThunkReOpenIncident,
  ThunkAddAssigneeForIncident,
  ThunkGetIncidentNotes,
  ThunkAddIncidentNote,
  ThunkGetIncidentTickets,
  ThunkGetIncidentTimeEntry,
  ThunkAddIncidentTimeEntry,
  ThunkEditIncidentNote,
  ThunkDeleteIncidentNote,
} from './thunks';

const initialState = {
  current: {},
  logs: [],
  notes: [],
  tickets: [],
  timeEntries: [],
  isPending: true,
  isProcessing: false,
};

const incidentsSlice = createSlice({
  name: INCIDENT,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ThunkGetIncident.fulfilled, (state, { payload }) => {
        state.current = payload.incident;
        state.logs = payload.logs;
      })

      .addCase(ThunkResolveIncident.fulfilled, (state, { payload }) => {
        state.current = payload.incident;
        state.logs = payload.logs;
      })

      .addCase(ThunkReOpenIncident.fulfilled, (state, { payload }) => {
        state.current = payload.incident;
        state.logs = payload.logs;
      })

      .addCase(ThunkAddAssigneeForIncident.fulfilled, (state, { payload }) => {
        state.current = payload.incident;
        state.logs = payload.logs;
      })

      .addCase(ThunkGetIncidentNotes.fulfilled, (state, { payload }) => {
        state.notes = payload;
      })

      .addCase(ThunkAddIncidentNote.fulfilled, (state, { payload }) => {
        state.notes.push(payload);
      })

      .addCase(ThunkGetIncidentTickets.fulfilled, (state, { payload }) => {
        state.tickets = payload;
      })

      .addCase(ThunkGetIncidentTimeEntry.fulfilled, (state, { payload }) => {
        state.timeEntries = payload;
      })

      .addCase(ThunkAddIncidentTimeEntry.fulfilled, (state, { payload }) => {
        state.timeEntries.push(payload);
      })

      .addCase(ThunkEditIncidentNote.fulfilled, (state, { payload }) => {
        state.notes = state.notes.map(note =>
          note.id === payload.id ? payload : note,
        );
      })

      .addCase(ThunkDeleteIncidentNote.fulfilled, (state, { payload }) => {
        state.notes = state.notes.filter(
          note => note.id !== payload.noteId && note,
        );
      })

      .addMatcher(
        isAnyOf(
          ThunkGetIncident.pending,
          ThunkGetIncidentNotes.pending,
          ThunkGetIncidentTimeEntry.pending,
          ThunkGetIncidentTickets.pending,
        ),
        state => {
          state.isPending = true;
        },
      )

      .addMatcher(
        isFulfilled(
          ThunkGetIncidentNotes,
          ThunkGetIncident,
          ThunkGetIncidentTimeEntry,
          ThunkGetIncidentTickets,
        ),
        state => {
          state.isPending = false;
        },
      )

      .addMatcher(
        isAnyOf(ThunkReOpenIncident.pending, ThunkResolveIncident.pending),
        state => {
          state.isProcessing = true;
        },
      )

      .addMatcher(
        isAnyOf(ThunkReOpenIncident.fulfilled, ThunkResolveIncident.fulfilled),
        state => {
          state.isProcessing = false;
        },
      );
  },
});

export default incidentsSlice.reducer;
