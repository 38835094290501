import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const REFRESH_TILE_TIME = 60 * 1000;

const UpdatedAgo = ({ isNew, updatedAgo }) => {
  const [date, setDate] = useState(null);

  const updatedAgoView = useMemo(
    () =>
      isNew ? 'No data available' : date && `Updated ${moment(date).fromNow()}`,
    [isNew, date],
  );

  useEffect(() => {
    let interval;
    setDate(updatedAgo || null);
    if (!isNew) {
      interval = setInterval(() => {
        setDate(null);
        setDate(updatedAgo || null);
      }, REFRESH_TILE_TIME);
    }

    return () => clearInterval(interval);
  }, [isNew, updatedAgo]);

  return (
    <Typography variant="caption" color="textSecondary" noWrap>
      {updatedAgoView}
    </Typography>
  );
};

UpdatedAgo.propTypes = {
  isNew: PropTypes.bool,
  updatedAgo: PropTypes.string,
};

export default UpdatedAgo;
