import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import {
  dmTicketSettings,
  dmEmailSettings,
  dmSmsSettings,
  dmWebHookSettings,
  dmSlackSettings,
  dmTeamsSettings,
} from '@constants/appRoutes';
import postData from '@services/api/common/postData';
import postWithResponse from '@services/api/common/postWithResponse';

export const getTicketSettings = id =>
  getData(`${appConfig.baseUrl.concat(dmTicketSettings)}${id}`);

export const postTicketSettings = (id, data) =>
  postData(`${appConfig.baseUrl.concat(dmTicketSettings)}${id}`, data);

export const postEmailSettings = (id, data) =>
  postData(`${appConfig.baseUrl.concat(dmEmailSettings)}${id}`, data);

export const getEmailSettings = id =>
  getData(`${appConfig.baseUrl.concat(dmEmailSettings)}${id}`);

export const postSmsSettings = (id, data) =>
  postData(`${appConfig.baseUrl.concat(dmSmsSettings)}${id}`, data);

export const getSmsSettings = id =>
  getData(`${appConfig.baseUrl.concat(dmSmsSettings)}${id}`);

export const getWebHookSettings = id =>
  getData(`${appConfig.baseUrl.concat(dmWebHookSettings)}${id}`);

export const postWebHookSettings = (id, data) =>
  postData(`${appConfig.baseUrl.concat(dmWebHookSettings)}${id}`, data);

export const testWebHook = data =>
  postWithResponse(
    `${appConfig.baseUrl.concat(dmWebHookSettings)}testUrl`,
    data,
  );

export const getSlackSettings = id =>
  getData(`${appConfig.baseUrl.concat(dmSlackSettings)}${id}`);

export const testSlack = data =>
  postWithResponse(`${appConfig.baseUrl.concat(dmSlackSettings)}testUrl`, data);

export const postSlackSettings = (id, data) =>
  postData(`${appConfig.baseUrl.concat(dmSlackSettings)}${id}`, data);

export const postTeamsSettings = (id, data) =>
  postData(`${appConfig.baseUrl.concat(dmTeamsSettings)}${id}`, data);

export const testTeams = data =>
  postWithResponse(`${appConfig.baseUrl.concat(dmTeamsSettings)}testUrl`, data);

export const getTeamsSettings = id =>
  getData(`${appConfig.baseUrl.concat(dmTeamsSettings)}${id}`);
