import React from 'react';
import PropTypes from 'prop-types';
import { Button, useListContext } from 'react-admin';
import cx from 'classnames';
import { ArrowUpward } from '@material-ui/icons';

import { sortOrders } from '@constants/filters';
import useStyles from '../styles';

const Header = ({ column }) => {
  const { key, name, sort, align } = column;
  const classes = useStyles();

  const { setSort, setFilters, filterValues, currentSort } = useListContext();

  const setSorting = () => {
    setSort(sort);
    setFilters({}, filterValues, true);
  };
  const isCurrentFieldSorted = currentSort?.field === key;

  const alignClasses = {
    center: classes.alignCellCenter,
    left: classes.alignCellLeft,
    right: classes.alignCellRight,
  };

  const classNameForButtonIcon = cx(classes.tableHeaderButtonIcon, {
    [classes.tableHeaderButtonIconRotate]:
      isCurrentFieldSorted && currentSort?.order === sortOrders.asc,
  });
  return (
    <div className={alignClasses[align || 'center']}>
      {sort ? (
        <Button onClick={setSorting} className={classes.tableHeaderButton}>
          <>
            {name}
            {isCurrentFieldSorted && (
              <ArrowUpward className={classNameForButtonIcon} />
            )}
          </>
        </Button>
      ) : (
        <span className={classes.tableHeader}>{column.name}</span>
      )}
    </div>
  );
};

Header.propTypes = {
  column: PropTypes.shape({
    align: PropTypes.string,
    sort: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
};

export default Header;
