import appConfig from '@configs/appConfig';
import postWithResponse from '@services/api/common/postWithResponse';
import getData from '@services/api/common/getData';
import putData from '@services/api/common/putData';

export const setTenantTimeZone = data =>
  postWithResponse(`${appConfig.baseUrl}/tenantSettings/timeZone`, data);

export const getTenantSetting = () =>
  getData(`${appConfig.baseUrl}/tenantSettings`);

export const disableSetupWizard = () =>
  putData(`${appConfig.baseUrl}/User/disableStartupWizard`);

export default {};
