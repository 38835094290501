import React, { useState } from 'react';
import SophosAlertsDashboard from '@components/DataSources/Dashboards/Sophos/alerts/SophosAlertsDashboard';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';
import SophosEndpointsDashboard from '@components/DataSources/Dashboards/Sophos/endpoints/SophosEndpointsDashboard';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  navBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  autoComplete: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 5px 0 0',
  },
  name: {
    minWidth: '10rem',
    textAlign: 'center',
    color: 'gray',
  },
}));

const options = ['Alerts', 'Endpoints'];

const Selector = ({ onNext, onPrev, name, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.navBar}>
      <IconButton aria-label="prev" onClick={onPrev} disabled={loading}>
        <ArrowBackIosIcon color="disabled" />
      </IconButton>
      <div className={classes.name}>{name}</div>
      <IconButton aria-label="forward" onClick={onNext} disabled={loading}>
        <ArrowForwardIosIcon color="disabled" />
      </IconButton>
    </div>
  );
};

const SophosDashboard = () => {
  const [current, setCurrent] = useState(options[0]);
  const [loading, setLoading] = useState(true);

  const setNext = () => {
    setCurrent(current === options[0] ? options[1] : options[0]);
    setLoading(true);
  };

  return (
    <>
      <Selector
        name={current}
        onNext={setNext}
        onPrev={setNext}
        loading={loading}
      />
      {current === options[0] ? (
        <SophosAlertsDashboard loading={loading} setLoading={setLoading} />
      ) : (
        <SophosEndpointsDashboard loading={loading} setLoading={setLoading} />
      )}
    </>
  );
};

export default SophosDashboard;
