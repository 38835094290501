import { makeRequest } from '@services/requests/makeRequest';
import { CREATE_TICKET } from '@services/requests/requestResources';

export const createConnectWiseTicket = (psaId, payload) =>
  makeRequest(
    'POST',
    `${CREATE_TICKET}/${psaId}/connectwise/createTicket`,
    payload,
  );

export const getConnectWiseCompanies = psaId =>
  makeRequest('GET', `${CREATE_TICKET}/${psaId}/connectwise/companies`);

export const getConnectWiseTicketPriorities = psaId =>
  makeRequest('GET', `${CREATE_TICKET}/${psaId}/connectwise/priorities`);

export const createAutotaskTicket = (psaId, payload) =>
  makeRequest(
    'POST',
    `${CREATE_TICKET}/${psaId}/autotask/createTicket`,
    payload,
  );

export const getAutotaskCompanies = psaId =>
  makeRequest('GET', `${CREATE_TICKET}/${psaId}/autotask/companies`);

export const getPsaContacts = (psaId, companyId = null, sortAsc = false) =>
  makeRequest(
    'GET',
    `${CREATE_TICKET}/${psaId}/contacts?${
      companyId ? `companyId=${companyId}` : ''
    }${sortAsc ? '&alphaSort=true' : ''}`,
  );

export const getKaseyaCompanies = psaId =>
  makeRequest('GET', `${CREATE_TICKET}/${psaId}/kaseya/companies`);

export const createKaseyaTicket = (psaId, payload) =>
  makeRequest('POST', `${CREATE_TICKET}/${psaId}/kaseya/createTicket`, payload);

export const getKaseyaAssignees = psaId =>
  makeRequest('GET', `${CREATE_TICKET}/${psaId}/kaseya/assignees`);

export const getConnectWiseSites = (psaId, companyId) =>
  makeRequest(
    'GET',
    `${CREATE_TICKET}/${psaId}/connectwise/companies/${companyId}/sites`,
  );

export const getConnectWiseAgreements = (psaId, companyId) =>
  makeRequest(
    'GET',
    `${CREATE_TICKET}/${psaId}/connectwise/companies/${companyId}/agreements`,
  );

export default {};
