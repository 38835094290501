import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmTicketOptions } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetAutotaskTicketOptions = createAsyncThunk(
  `${CRM_TICKET}/getCrmTicketOptions`,
  async ({ crmId, options }) => {
    const ticketOptions = await getCrmTicketOptions(crmId, options);
    return ticketOptions;
  },
);

export default {};
