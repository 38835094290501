import { createAsyncThunk } from '@reduxjs/toolkit';
import { CRM_TICKET } from '@store/slices/resources';
import { getHaloUsers } from '@services/requests/haloTickets';

export const ThunkGetHaloTicketContacts = createAsyncThunk(
  `${CRM_TICKET}/getHaloTicketContacts`,
  async ({ crmId, companyId = undefined }) => {
    const contacts = await getHaloUsers(crmId, companyId);
    return contacts.sort((a, b) => (a.label > b.label ? 1 : -1));
  },
);

export default {};
