import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { AttachFile as AttachFileIcon } from '@material-ui/icons';

import getFormattedDate from '@utils/getFormattedDate';

import useStyles from '../../Chats/Chats/components/ActiveChat/styles';

const SimpleMessage = ({ message }) => {
  const classes = useStyles();

  return <p className={cx(classes.chatMessageText)}>{message.message}</p>;
};

const Attachment = ({ message }) => {
  const classes = useStyles();

  const { Path, FileName, ContentType } = JSON.parse(message.metadata);

  const handleClick = useCallback(() => {
    window.open(Path, '_blank');
  }, [Path]);

  const getContent = useMemo(() => {
    if (ContentType.includes('image')) {
      return <img src={Path} alt="preview" />;
    }
    return (
      <>
        <AttachFileIcon />
        {FileName}
      </>
    );
  }, []);

  return (
    <p
      className={cx(classes.chatMessageText, classes.attachment)}
      onClick={handleClick}
      role="presentation"
    >
      {getContent}
    </p>
  );
};

const LiveChatMessage = ({
  message,
  extraMessageText = undefined,
  extraMessageAction = () => {},
}) => {
  const classes = useStyles();

  const isSendByCurrentUser = useMemo(() => message?.technicianName, [message]);

  const getInfoContent = useMemo(() => {
    return (
      <>
        {!isSendByCurrentUser && !message?.technicianName
          ? `${message.senderName}, `
          : message?.technicianName
          ? `${message?.technicianName}, `
          : ''}
        {getFormattedDate(message.dateTime)}
      </>
    );
  }, [message, isSendByCurrentUser]);

  const getMessage = useMemo(() => {
    switch (+message.type) {
      case 0:
        return <SimpleMessage message={message} />;
      case 1:
        return <Attachment message={message} />;
      default:
        return <></>;
    }
  }, [message]);

  return (
    <div
      className={cx(classes.chatMessage, {
        [classes.chatMessageAlignRight]: isSendByCurrentUser,
        [classes.chatMessageError]: message.hasError,
      })}
      key={message.id}
    >
      <span
        className={cx(classes.chatMessageDate, {
          [classes.chatMessageAlignRightDate]: isSendByCurrentUser,
        })}
      >
        {getInfoContent}
      </span>

      {getMessage}

      {extraMessageText && (
        <div
          className={classes.addMessageToNote}
          role="presentation"
          onClick={() => {
            extraMessageAction(message);
          }}
        >
          {extraMessageText}
        </div>
      )}
    </div>
  );
};

LiveChatMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.any),
};

export default LiveChatMessage;
