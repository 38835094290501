import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import useStyles from '../styles';

const TableCellWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.cell} align="center">
      {children}
    </TableCell>
  );
};

const TableHeader = ({ headers }) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.header}>
      <TableRow>
        {headers.map(header => (
          <TableCellWrapper key={header}>{header}</TableCellWrapper>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableCellWrapper.propTypes = {
  children: PropTypes.node,
};

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
};

export default TableHeader;
