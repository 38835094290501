import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkIfTenantHasPhone } from '@services/requests/phoneNumbers';
import { PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkCheckIfTenantHasPhone = createAsyncThunk(
  `${PHONE_NUMBERS}/checkIfTenantHasPhone`,
  async () => {
    const res = await checkIfTenantHasPhone();
    return res;
  },
);

export default {};
