import { createAsyncThunk } from '@reduxjs/toolkit';
import { stopTimer } from '@services/requests/ticketTimer';
import { TICKET_TIMER } from '@store/slices/resources';

export const ThunkStopTimer = createAsyncThunk(
  `${TICKET_TIMER}/stopTimer`,
  async ({ payload }) => {
    const res = await stopTimer(payload);
    return res;
  },
);

export default {};
