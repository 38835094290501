import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {},
  dropzoneWrapper: {
    '& .dzu-inputLabelWithFiles': {
      display: 'none',
    },
    '& .dzu-dropzone': {
      overflow: 'auto',
      minHeight: '4rem',
    },

    '& .dzu-previewContainer': {
      padding: '0.375rem 3%',
      position: 'relative',
    },

    '& .dzu-inputLabel': {
      fontSize: '1rem',
      color: theme.palette.info.main,
    },

    '& .dzu-previewImage': {
      maxWidth: '100%',
      maxHeight: '100%',
    },

    '& .dzu-previewStatusContainer': {
      position: 'absolute',
      top: '1.125rem',
      right: '1.125rem',
    },
  },
}));

export default useStyles;
