import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import { calculateDurationEventsTime } from '../utils';
import EventsWrapper from './EventsWrapper';
import useStyles from './styles';

const defaultColumns = [
  {
    key: 'deviceEventType',
    name: 'Event',
    accessor: row => <EventsWrapper deviceEventType={row.deviceEventType} />,
  },
  {
    key: 'dateTime',
    name: 'Date-Time',
    accessor: row =>
      moment
        .parseZone(row.dateTime)
        .local()
        .format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    key: 'responseCode',
    name: 'Reason',
  },
  {
    key: 'duration',
    name: 'Duration',
  },
];

const DetailsEvents = memo(({ id, setLastEvent }) => {
  const resource = `DeviceMonitors/${id}/getEvents`;

  const classes = useStyles();

  const rowsWrapping = data => {
    const formattedData = calculateDurationEventsTime(data);
    if (formattedData.length) setLastEvent(formattedData[0]);
    return formattedData;
  };

  return (
    <div className={classes.detailsEventsContainer}>
      <MuiVirtualizedTable
        actions={null}
        columns={defaultColumns}
        resource={resource}
        rowsWrapping={rowsWrapping}
      />
    </div>
  );
});

DetailsEvents.propTypes = {
  id: PropTypes.string,
  setLastEvent: PropTypes.func,
};

export default DetailsEvents;
