import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketStatuses } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketStatuses = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketStatuses`,
  async ({ crmId, boardId }) => {
    const statuses = await getConnectWiseTicketStatuses(crmId, boardId);
    return statuses;
  },
);

export default {};
