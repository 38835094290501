import { makeRequest } from '@services/requests/makeRequest';

export const getSuperOpsCompanies = psaId =>
  makeRequest('GET', `/TicketCreate/${psaId}/superOps/companies`);

export const getSuperOpsStatuses = crmId =>
  makeRequest('GET', `/TicketUpdate/superOps/${crmId}/statuses`);

export const getSuperOpsTypes = crmId =>
  makeRequest('GET', `/TicketUpdate/superOps/${crmId}/types`);

export const getSuperOpsCompaniesByDefaultId = crmId =>
  makeRequest('GET', `/TicketUpdate/superOps/${crmId}/companies`);
