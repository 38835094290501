import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../styles';

const ColorCircle = React.memo(({ circleBgColor, circleBorderColor }) => {
  const classes = useStyles({ circleBgColor, circleBorderColor });
  return <div className={classes.circleColor} />;
});

ColorCircle.propTypes = {
  circleBgColor: PropTypes.string,
  circleBorderColor: PropTypes.string,
};

export default ColorCircle;
