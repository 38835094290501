import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendChannelMessage } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkSendChannelMessage = createAsyncThunk(
  `${MESSAGING}/sendChannelMessage`,
  async ({ channelId, payload }) => {
    const res = await sendChannelMessage(channelId, payload);
    return res;
  },
);

export default {};
