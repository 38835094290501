import { createAsyncThunk } from '@reduxjs/toolkit';
import { addCrmUser } from '@services/requests/crm';
import { CRM } from '@store/slices/resources';

export const ThunkAddCrmUser = createAsyncThunk(
  `${CRM}/addCrmUser`,
  async ({ crmDefaultId, payload, companyName }) => {
    await addCrmUser(crmDefaultId, payload);
    return {
      id: `${payload.companyId}${payload.fistName}${payload.lastName}`,
      name: `${payload.firstName} ${payload.lastName}`,
      company: companyName,
      phone: payload.phone,
    };
  },
);

export default {};
