import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmOptions } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkGetCrmOptions = createAsyncThunk(
  `${CRM_TICKET}/getCrmOptions`,
  async () => {
    const options = await getCrmOptions();
    return options;
  },
);

export default {};
