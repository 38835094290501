import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTOTASK_TICKET } from '@store/slices/resources';
import { updateAutotaskNote } from '@services/requests/autotaskTicket';

export const ThunkUpdateAutotaskNote = createAsyncThunk(
  `${AUTOTASK_TICKET}/updateAutotaskNote`,
  ({ crmDefaultId, ticketId, noteId, payload }) =>
    updateAutotaskNote(crmDefaultId, ticketId, noteId, payload),
);

export default {};
