import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';

import { deleteDeviceMonitor } from '@components/DeviceMonitors/helpers';

import {
  DEVICE_MONITORS_DETAILS,
  DEVICE_MONITORS_UPDATE,
} from '@constants/routes';

import ViewIcon from '@assets/icons/visibility.svg';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import useStyles from '../styles';

const TableRowActions = ({ itemId }) => {
  const [processing, setProcessing] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const classes = useStyles();
  const list = useSelector(listSelector);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleDelete = () => {
    setProcessing(true);
    deleteDeviceMonitor(itemId)
      .then(() => {
        const newList = list.filter(item => String(item.id) !== String(itemId));
        dispatch(actions.saveList(newList));
        setOpenDeleteDialog(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleEdit = () => {
    push(DEVICE_MONITORS_UPDATE.replace(':id', itemId));
  };

  const handleView = () => {
    push(DEVICE_MONITORS_DETAILS.replace(':id', itemId));
  };

  return (
    <div className={classes.tableRowActions}>
      <ActionFieldItem
        handler={handleView}
        icon={ViewIcon}
        toolTip="View Details"
      />

      <ActionFieldItem handler={handleEdit} icon={EditIcon} toolTip="Edit" />

      <ActionFieldItem
        handler={() => {
          setOpenDeleteDialog(true);
        }}
        icon={DeleteIcon}
        toolTip="Delete"
      />

      {openDeleteDialog && (
        <ConfirmDialog
          open
          onAccept={handleDelete}
          title="Confirm Action"
          loading={processing}
          content="Are you sure you want to delete this item?"
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
        />
      )}
    </div>
  );
};

TableRowActions.propTypes = {
  itemId: PropTypes.string,
};
export default TableRowActions;
