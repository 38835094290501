import React from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput } from 'react-admin';

import {
  Dialog as MuiDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { LOGIN_ROUTE } from '@constants/routes';
import Toolbar from './Toolbar';

const Dialog = ({ open, onToggle, onSave, ...props }) => {
  return (
    <MuiDialog open={open} onClose={onToggle} fullWidth maxWidth="sm">
      <DialogTitle>
        Are you sure that you want to delete your profile?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>You can not undo this action!</DialogContentText>
        <SimpleForm
          {...props}
          toolbar={<Toolbar onToggle={onToggle} />}
          redirect={LOGIN_ROUTE}
        >
          <TextInput
            autoFocus
            fullWidth
            variant="outlined"
            type="password"
            source="confirmPassword"
          />
        </SimpleForm>
      </DialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default Dialog;
