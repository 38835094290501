import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0.25rem',
    },
    padding: '1.25rem',
    backgroundColor: theme.palette.tenantSettings.background,
    borderRadius: '0.8125rem',
  },
}));

export default useStyles;
