import * as React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from '@common/LetterStatuses/StatusBadge';

const StatusField = ({ source, record = {} }) => {
  return <StatusBadge status={record[source]} />;
};

StatusField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
};

export default StatusField;
