import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getResources } from '@services/requests/liveChats';

export const ThunkGetResources = createAsyncThunk(
  `${LIVE_CHATS}/getResources`,
  () => getResources(),
);

export default {};
