import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
  countryCode: {
    '& .MuiInputBase-root': {
      minHeight: '40px',
    },
  },
}));

export default useStyles;
