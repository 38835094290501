import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import FileUploader from '@common/FileUploader/FileUploader';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { upload } from '@constants/appRoutes';
import { createQueryParams } from '@utils/index';
import SwitchComponent from '@common/FilterInputs/Switch';
import FieldWrapper from '@common/form/FieldWrapper';
import { useListContext } from 'react-admin';
import { useSnackbar } from 'notistack';
import DialogTitle from '@common/Dialog/DialogTitle';
import { getImportHelp } from '@constants/help';
import HelpBox from '@common/HelpBox/HelpBox';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { ReactComponent as ExportIcon } from '@assets/icons/export.svg';
import downLoadTemplate from '@components/UploadFileDialog/helpers';
import useStyles from './styles';

const UploadDialog = ({
  open,
  setOpen,
  setModalDetails,
  type,
  displaySkip = true,
}) => {
  const [skipExisting, setSkipExisting] = useState(false);
  const { setFilters, filterValues } = useListContext();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const onSendFormData = async file => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await sendFormData(
      `${appConfig.baseUrl.concat(upload)}?${createQueryParams({
        type,
        skipExisting,
      })}`,
      formData,
    );
    return response.json();
  };

  const checkingResponseState = response => {
    if (response.message)
      return enqueueSnackbar(response.message, { variant: 'error' });
    return response;
  };

  const handleModalsState = response => {
    setOpen(false);
    setModalDetails({
      open: true,
      data: response,
    });
    if (setFilters) setFilters({}, filterValues, true);
  };

  const handleClose = () => setOpen(false);

  const handleUpload = async file => {
    try {
      const response = checkingResponseState(await onSendFormData(file));
      if (response) handleModalsState(response);
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const title = `Upload files .csv format`;
  const contentTitle = `Type: ${type}`;

  const handleDownLoad = async () => {
    const blob = await (await downLoadTemplate(type)).blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${type}.csv`;
    link.click();
  };

  return (
    <MuiDialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentTitle}
          <HelpBox resource={getImportHelp(type)} />
        </DialogContentText>
        <FieldWrapper
          label="Download file"
          classNameContainer={classes.downLoadWrapper}
          content={
            <ActionButton
              handler={handleDownLoad}
              icon={<ExportIcon stroke="#000" />}
            />
          }
        />
        {displaySkip && (
          <FieldWrapper
            label="Skip Existing"
            content={
              <SwitchComponent
                input={{
                  checked: skipExisting,
                  onChange: (_, value) => setSkipExisting(value),
                }}
              />
            }
          />
        )}
        <FileUploader
          handleUpload={handleUpload}
          format="csv"
          maxFiles={20}
          maxSizeBytes={10000000}
        />
      </DialogContent>
    </MuiDialog>
  );
};

UploadDialog.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setModalDetails: PropTypes.func,
  displaySkip: PropTypes.bool,
};

export default UploadDialog;
