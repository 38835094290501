import React from 'react';
import { Link } from 'react-router-dom';

import dangerIcon from '@assets/icons/dangerTriangle.svg';
import useStyles from '../GoogleSheets/styles';

const WebHooks = () => {
  const classes = useStyles();

  return (
    <div className={classes.tabBase}>
      <div className={classes.displayFlex}>
        <img src={dangerIcon} alt="dangerIcon" />
        <span className={classes.caption}>
          &nbsp; WebHooks shifted to the notifications region
        </span>
      </div>
      <div className={classes.marginTop}>
        To use webHooks as a trigger for a new event, follow the
        <Link to="/notificationSettings" className={classes.link}>
          link
        </Link>
      </div>
    </div>
  );
};

export default WebHooks;
