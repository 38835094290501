import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { removeFirstElementOfErrorArray } from '@store/slices/errors';
import { useSnackbar } from 'notistack';

const ErrorProvider = ({ children }) => {
  const errors = useSelector(state => state.errors.errors);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    if (errors.length > 0) {
      enqueueSnackbar(errors[0].message.slice(13), { variant: 'error' });
      dispatch(removeFirstElementOfErrorArray());
    }
  }, [errors, dispatch, enqueueSnackbar]);

  return <>{children}</>;
};

ErrorProvider.propTypes = {
  children: PropTypes.element,
};

export default ErrorProvider;
