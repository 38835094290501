import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISSUE_TYPES } from '@store/slices/resources';
import { deleteIssueType } from '@services/requests/issueTypes';

export const ThunkDeleteIssueType = createAsyncThunk(
  `${ISSUE_TYPES}/deleteSurvey`,
  async ({ id }) => {
    await deleteIssueType(id);
    return id;
  },
);
