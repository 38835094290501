import React from 'react';
import PropTypes from 'prop-types';
import linkIcon from '@assets/icons/link.svg';
import { Link, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: '0.3125rem',
    transition: '0.3s',
    width: '13px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
}));

const ExternalLink = ({ url }) => {
  const classes = useStyles();
  return (
    <Tooltip title={url}>
      <Link target="_blank" href={url} onClick={e => e.stopPropagation()}>
        <img src={linkIcon} alt="linkIcon" className={classes.iconButton} />
      </Link>
    </Tooltip>
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ExternalLink;
