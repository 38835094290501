import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { checkIfTenantHasVerificationPhone } from '@services/requests/verificationSettings';

export const ThunkCheckIfTenantHasVerificationNumber = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/checkIfTenantHasVerificationPhone`,
  () => checkIfTenantHasVerificationPhone(),
);

export default {};
