import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  displayFlex: {
    display: 'flex',
    marginTop: '8px',
  },
  marginLeft: {
    marginLeft: '5px',
  },
}));

export default useStyles;
