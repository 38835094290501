import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '@ui/components/common/Loading/Loading';
import { NOTIFICATIONS_GROUPS, SCHEDULE_GROUP } from '@constants/routes';
import ReusableButton from '@common/Button/Button';
import { getNotificationGroupAutocomplete } from '../helper';

import useStyles from './styles';

const SchedulerPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  const onMount = useCallback(() => {
    setLoading(true);
    getNotificationGroupAutocomplete()
      .then(res => {
        if (res.length) {
          setLoading(false);
          push(SCHEDULE_GROUP.replace(':groupId', res[0].value));
        }
      })
      .finally(() => setLoading(false));
  }, [push]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.noGroupsText}>
      <div className={classes.elementSpace}>
        Notification groups not found, please, create one before open calendar
      </div>
      <ReusableButton
        label="Notification groups"
        onClick={() => push(NOTIFICATIONS_GROUPS)}
      />
    </div>
  );
};

export default SchedulerPage;
