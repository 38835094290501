import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketDepartments } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketDepartments = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketDepartments`,
  async crmId => {
    const departments = await getConnectWiseTicketDepartments(crmId);
    return departments;
  },
);

export default {};
