import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

const HomePageTile = ({ Icon, title, description, path }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    push(path);
  }, [push, path]);
  return (
    <Grid
      xs={12}
      md={3}
      className={classes.tileContainer}
      onClick={handleClick}
      role="presentation"
    >
      <div className={classes.tileIconContainer}>
        <Icon />
        <div className={classes.tileIconBackdrop} />
      </div>

      <div className={classes.tileTextContainer}>
        <div className={classes.tileTextTitle}>{title}</div>
        <div className={classes.tileTextDescription}>{description}</div>
      </div>
    </Grid>
  );
};

HomePageTile.propTypes = {
  Icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
};

export default HomePageTile;
