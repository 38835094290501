import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkSendLiveChatLogsToEmail } from '@store/slices/liveChats/thunks';
import { emailValidator } from '@utils/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const SendLogsToEmailDialog = ({ open, setOpen, liveChatId }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      ThunkSendLiveChatLogsToEmail({ chatId: liveChatId, payload: values }),
    )
      .unwrap()
      .then(() => {
        setOpen(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    email: emailValidator(values.email),
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Send Logs to Email</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Email"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                content={
                  <Field
                    name="email"
                    id="email"
                    render={Input}
                    loading={processing}
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

SendLogsToEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  liveChatId: PropTypes.number.isRequired,
};

export default SendLogsToEmailDialog;
