import React from 'react';
import { useVersion } from 'react-admin';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { toggleGlobal } from '@components/MailBox/helpers';
import { actions } from '@store/actions';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import ActionField from './ActionField';

import useStyles from './styles';

const List = props => {
  const { resource } = props;
  const { enqueueSnackbar } = useSnackbar();
  const version = useVersion();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleToggleGlobal = record => {
    toggleGlobal(record.id, !record.isGlobal)
      .then(data => {
        const nerRecord = {
          ...record,
          isGlobal: !record.isGlobal,
          tenantName: data.tenantName,
        };
        dispatch(actions.updateItemInList(nerRecord));
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
  };

  const columns = [
    {
      name: 'Name',
      key: 'name',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      name: 'Tenant',
      sortable: true,
      searchable: true,
      key: 'tenantName',
      minWidth: 200,
    },
    {
      name: 'Actions',
      key: TableTypes.actions,
      type: TableTypes.actions,
      width: 90,
      minWidth: 90,
      accessor: rowData => (
        <ActionField record={rowData} handleMakeGlobal={handleToggleGlobal} />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <Table
        key={version}
        defaultSort={{ fieldName: 'tenantName', order: 'ASC' }}
        columns={columns}
        resource={resource}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
