import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
  },
  description: {
    marginBottom: '1rem',
    fontSize: 'xx-large',
  },
  text: {
    fontSize: 'x-large',
  },
  imageRoot: {
    maxWidth: '350px',
    width: '100%',
    marginTop: '1.5rem',
    objectFit: 'contain',
  },
}));

export default useStyles;
