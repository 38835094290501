import React from 'react';
import { useAuthenticated } from 'react-admin';
import ChangePassword from '@components/ChangePassword';
import TabPanel from '@common/TabPanel/TabPanel';
import Edit from './Edit';
import useStyles from './styles';

const tabs = [
  { label: 'Profile', value: <Edit /> },
  { label: 'Change Password', value: <ChangePassword /> },
];

const MyProfile = () => {
  const classes = useStyles();

  useAuthenticated();

  return (
    <>
      <TabPanel
        classNameWrapperTabs={classes.tabsWrapper}
        isTransparent
        tabs={tabs}
        viewTypeLink="flooded"
      />
    </>
  );
};

export default MyProfile;
