import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTimer } from '@services/requests/ticketTimer';
import { TICKET_TIMER } from '@store/slices/resources';

export const ThunkGetTimer = createAsyncThunk(
  `${TICKET_TIMER}/getTimer`,
  async ({ payload }) => {
    const res = await getTimer(payload);
    return res;
  },
);

export default {};
