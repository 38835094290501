import appConfig from '@configs/appConfig';
import {
  auditViewAutocomplete,
  dataViews,
  exportUrl,
  ftpConnections,
  letters,
  policy,
  typeValues,
  users,
  crmTickets,
  webHooks,
} from '@constants/appRoutes';
import getData from '@services/api/common/getData';
import deleteData from '@services/api/common/deleteData';
import postWithResponse from '@services/api/common/postWithResponse';

export const getAuditViewAutocomplete = () =>
  getData(appConfig.baseUrl.concat(auditViewAutocomplete));

export const getTypeValues = params =>
  getData(`${appConfig.baseUrl}${typeValues}?${params}`);

export const getAuditView = id =>
  getData(`${appConfig.baseUrl}${dataViews}${id}`);

export const getMailBoxId = letterId =>
  getData(`${appConfig.baseUrl}${letters}${letterId}/getMailBoxId`);

export const getWebHookId = hookId =>
  getData(`${appConfig.baseUrl}${webHooks}results/${hookId}/getHookId`);

export const deleteDataItem = (id, letterId) =>
  deleteData(
    `${appConfig.baseUrl}${policy}DeleteData/${id}/letter/${letterId}`,
  );

export const getDownloadLinks = body =>
  postWithResponse(`${appConfig.baseUrl}${exportUrl}getLinks`, body);

export const getFtpConnections = () =>
  getData(`${appConfig.baseUrl}${ftpConnections}connections`);

export const postFtpUpload = data =>
  postWithResponse(`${appConfig.baseUrl}${ftpConnections}upload`, data);

export const getHintUsers = (chatOnly = false) =>
  getData(
    `${appConfig.baseUrl}${users}/hint-info${
      chatOnly ? '?forLiveChat=true' : ''
    }`,
  );

export const getTickets = ticketIds =>
  getData(
    `${appConfig.baseUrl}${crmTickets}getTickets?${ticketIds
      .map(i => `id=${i}`)
      .join('&')}`,
  );

export const getTicketsByIncident = incId =>
  getData(
    `${appConfig.baseUrl}${crmTickets}getTicketsByIncidentId?id=${incId}`,
  );

export const getTicketLink = (ticketId, crmDefaultId) =>
  getData(
    `${appConfig.baseUrl}${crmTickets}getLink?ticketId=${ticketId}&crmDefaultId=${crmDefaultId}`,
  );
