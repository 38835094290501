import { createAsyncThunk } from '@reduxjs/toolkit';
import { BILLING } from '@store/slices/resources';
import { checkSubscriptionDiscount } from '@services/requests/subscriptions';

export const ThunkCheckSubscriptionDiscount = createAsyncThunk(
  `${BILLING}/checkSubscriptionDiscount`,
  async () => {
    const res = await checkSubscriptionDiscount();
    return res;
  },
);

export default {};
