import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';

import { ThunkHelpdeskToggleState } from '@store/slices/helpdeskAI/thunks';

import Table from '@components/Table';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import HelpdeskTableActions from '@ui/pages/portalSettings/HelpdeskAI/components/HelpdeskTableActions';
import ReusableButton from '@common/Button/Button';
import CreateHelpdeskIntegration from '@ui/pages/portalSettings/HelpdeskAI/components/CreateHelpdeskIntegration';

import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import { TableTypes } from '@components/Table/constants';

import useStyles from './styles';

const HelpdeskAI = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const handleToggleState = useCallback(
    (id, state) => {
      dispatch(ThunkHelpdeskToggleState({ id, state: !state }))
        .unwrap()
        .then(res => {
          const updatedList = list.map(item =>
            item.id === res.id ? { ...item, isActive: res.state } : item,
          );
          dispatch(actions.saveList(updatedList));
        });
    },
    [list, dispatch],
  );

  const columns = [
    {
      name: 'Agent Name',
      key: 'agentName',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Incoming Phone Number',
      key: 'incomingPhoneNumber',
      isCustomAccessor: true,
      accessor: row => (
        <div>{nationalFormatPhoneNumber(row.incomingPhoneNumber, true)}</div>
      ),
    },
    {
      name: 'Enabled',
      key: 'isActive',
      width: 100,
      isCustomAccessor: true,
      accessor: row => (
        <div className={classes.alignCenter}>
          <StatusCircle
            handleClick={handleToggleState}
            row={row}
            rowKey="isActive"
          />
        </div>
      ),
    },
    {
      name: 'Actions',
      key: 'actions',
      type: TableTypes.actions,
      width: 100,
      isCustomAccessor: true,
      accessor: row => <HelpdeskTableActions row={row} />,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.actions}>
        <ReusableButton
          viewType="blue"
          onClick={() => {
            setOpenCreateDialog(true);
          }}
          label="Add New"
          id="aiAssists-add-new"
        />
      </div>
      <Table
        resource="/HelpdeskAiAssistants"
        columns={columns}
        defaultSort={{ fieldName: 'id', order: 'DESC' }}
      />

      {openCreateDialog && (
        <CreateHelpdeskIntegration
          open
          onClose={() => {
            setOpenCreateDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default HelpdeskAI;
