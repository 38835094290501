import React, { useCallback, useContext, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { LOGIN_ROUTE } from '@constants/routes';
import { useSnackbar } from 'notistack';

import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import Checkbox from '@common/Checkbox';

import { ReactComponent as EyeIcon } from '@assets/icons/eye.svg';
import { ReactComponent as EyeClosedIcon } from '@assets/icons/eye-closed.svg';

import { SignInContext, SingInDispatchContext } from '../context';
import { signUpPages } from '../constants';
import { userDataValidation } from '../helpers';

import useLoginStyles from '../../Login/styles';

const UserData = ({ handleForward }) => {
  const [showPassword, setShowPassword] = useState(false);

  const classes = useLoginStyles();
  const { enqueueSnackbar } = useSnackbar();

  const data = useContext(SignInContext);
  const contextDispatch = useContext(SingInDispatchContext);

  const handleAddUserData = userData => {
    contextDispatch({
      type: 'addUserData',
      userData,
    });
  };

  const submit = useCallback(
    values => {
      if (values.agreeToTermsOfUse) {
        handleAddUserData(values);
        handleForward(signUpPages.userData);
      } else {
        enqueueSnackbar('Please agree to term of use before proceeding next', {
          variant: 'error',
        });
      }
    },
    [handleAddUserData],
  );

  return (
    <Form
      onSubmit={submit}
      validate={userDataValidation}
      initialValues={data.userData}
      render={({ handleSubmit }) => (
        <div>
          <div className={classes.typographyContainer}>
            <h5>Sign Up</h5>
            <p>Welcome!</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={classes.fieldItem}>
              <p>Full Name</p>
              <Field
                id="signUp-userData-username"
                name="username"
                component={Input}
                placeholder="Enter your full name"
                autoComplete="name"
              />
            </div>

            <div className={classes.fieldItem}>
              <p>Email</p>
              <Field
                id="signUp-userData-email"
                name="email"
                component={Input}
                placeholder="Enter your email"
              />
            </div>

            <div className={classes.fieldItem}>
              <p>Company Name</p>
              <Field
                id="signUp-userData-companyName"
                name="companyName"
                component={Input}
                placeholder="Enter your company name"
                autoComplete="organization"
              />
            </div>

            <div className={classes.fieldItem}>
              <p>Password</p>
              <Field
                id="signUp-userData-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                component={Input}
                placeholder="Password"
                endAdornment={
                  <div
                    onClick={() => setShowPassword(prevState => !prevState)}
                    role="presentation"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                  </div>
                }
              />
            </div>
            <div className={cx(classes.fieldItem, classes.checkboxContainer)}>
              <Field
                id="signUp-userData-agreeToTermsOfUse"
                name="agreeToTermsOfUse"
                component={Checkbox}
              />
              <p>I agree to Terms of Service and Privacy Policy</p>
            </div>

            <div>
              <ReusableButton
                id="signUp-userData-submit"
                label="Sign Up"
                type="submit"
                classNameWrapper={classes.button}
              />
            </div>
          </form>

          <div>
            <div className={classes.signUpContainer}>
              Already have an account?
              <Link to={LOGIN_ROUTE}>Login</Link>
            </div>
          </div>
        </div>
      )}
    />
  );
};

UserData.propTypes = {
  handleForward: PropTypes.func.isRequired,
};
export default UserData;
