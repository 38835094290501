import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteCrmTicketTimeEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkDeleteConnectWiseTicketTimeEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/deleteCrmTicketTimeEntry`,
  async ({ timeEntryId, crmId }) => {
    await deleteCrmTicketTimeEntry(crmId, timeEntryId);
    return timeEntryId;
  },
);

export default {};
