import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import cx from 'classnames';
import { getIntegrationCompaniesNames } from '@components/Settings/LocaleCompany/helpers';
import Loading from '@common/Loading/Loading';
import { ActionsBlock, ButtonsBlock, ItemsBlock } from '../../common';
import useStyles from '../../styles';

const SelectCompanies = ({ integration, handleBack, handleSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    setLoading(true);
    getIntegrationCompaniesNames(integration.id, integration.type)
      .then(d => {
        const nameValues = d.map(i => i.label);
        setNames(nameValues);
        setFilteredNames(nameValues);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [integration, enqueueSnackbar]);

  const onCheckBoxClick = (name, checked) => {
    if (checked) {
      const newArr = [...selectedNames].filter(i => i !== name);
      setSelectedNames(newArr);
    } else {
      setSelectedNames([...selectedNames, name]);
    }
  };

  const onFilterChange = event => {
    const { value } = event.target;
    setFilterValue(value);
    if (value) {
      setFilteredNames(
        names.filter(i => i.toLowerCase().includes(value.toLowerCase())),
      );
    } else setFilteredNames(names);
  };

  const onFilterClear = () => {
    setFilterValue('');
    setFilteredNames(names);
  };

  if (loading) return <Loading />;

  return (
    <div className={cx(classes.selectorLayout, classes.threeRowsDivide)}>
      <ActionsBlock
        names={filteredNames}
        filterValue={filterValue}
        onFilterClear={onFilterClear}
        onFilterChange={onFilterChange}
        setSelectedNames={setSelectedNames}
      />
      <ItemsBlock
        names={filteredNames}
        selectedNames={selectedNames}
        onCheckBoxClick={onCheckBoxClick}
      />
      <ButtonsBlock
        loading={loading}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        selectedNames={selectedNames}
      />
    </div>
  );
};

SelectCompanies.propTypes = {
  integration: PropTypes.shape({ id: PropTypes.number, type: PropTypes.string })
    .isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SelectCompanies;
