import React from 'react';
import PropTypes from 'prop-types';

import InfoBox from '@common/InfoBox/InfoBox';
import useStyles from './formElementStyles';

const FormElement = ({ label, children, infoText }) => {
  const classes = useStyles();
  return (
    <div className={classes.field}>
      <div className={classes.label}>
        <div className={infoText && classes.infoStyle}>
          {label}
          {infoText && (
            <div className={classes.info}>
              <InfoBox text={infoText} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.component}>{children}</div>
    </div>
  );
};

FormElement.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  infoText: PropTypes.string,
};

export default FormElement;
