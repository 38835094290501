import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem',
  },
  selectWrapper: {
    width: '18rem',
  },
}));

export default useStyles;
