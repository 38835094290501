import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '@components/Ticketing/Notifications/TicketSettings/components/styles';
import { useDispatch, useSelector } from 'react-redux';
import { notificationDataViewSelector } from '@store/selectors';
import { teamsSettings } from '@store/selectors/notifications';
import Loading from '@common/Loading/Loading';
import {
  getTeamsSettings,
  postTeamsSettings,
  testTeams,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import useDisabled from '@components/Hooks/useDisabled';
import { basePermissions } from '@constants/permissions';
import { actions } from '@store/actions';
import Paper from '@common/Paper/Paper';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import TestArea from '@common/TestArea/TestArea';
import HelpBox from '@common/HelpBox/HelpBox';
import help from '@constants/help';
import useTest from '@components/Hooks/useTest';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import SwitchInput from '@common/FilterInputs/Switch';
import DropDown from '@components/Auth/Common/DropDown';
import { ThunkGetTeamsConnections } from '@store/slices/teamsConnections/thunks';
import {
  InputWithTemplate,
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import useTicketSetting from '../../hooks/useTicketSetting';
import { teamSettingsValidator } from '../../validators';
import { handleDoWChange } from '../../utils';

const ddOptions = [
  { label: 'One', value: 0 },
  { label: 'Two', value: 1 },
  { label: 'Three', value: 2 },
];

const TeamsSettings = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [connectionOptions, setConnectionOptions] = useState([]);

  const dataViewId = useSelector(notificationDataViewSelector);
  const storedData = useSelector(teamsSettings);

  const {
    submit,
    loading,
    setLoading,
    handleBack,
    setData,
    data,
  } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    tileKey: 'isTeams',
    action: actions.saveTeamsSetting,
    apiPostRequest: postTeamsSettings,
    apiGetSetting: getTeamsSettings,
  });

  const { templateVariables } = useTemplateVariables({
    paramsId: match.params.id,
    dataViewId,
  });

  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testTeams,
  });
  const onDoWChange = handleDoWChange(setData);

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  const getTestUrl = useCallback(
    formValues => {
      if (!formValues.useConnection) return formValues.url;
      const currentConnection = connectionOptions.find(
        p => p.id === formValues.teamsConnectionId,
      );
      return currentConnection?.url ?? 'undefined';
    },
    [connectionOptions],
  );

  const onMount = useCallback(() => {
    dispatch(ThunkGetTeamsConnections())
      .unwrap()
      .then(d => setConnectionOptions(d));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={teamSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.contentFormWrapper}>
              <div className={classes.header}>
                <span>Please, create app before integration</span>
                <HelpBox resource={help.teamsCreateApp} />
              </div>

              <FieldWrapper
                label="Type"
                labelSize={2}
                contentSize={10}
                content={
                  <div className={classes.descriptionWrapper}>
                    <Field
                      id="useConnection"
                      name="useConnection"
                      fullWidth
                      type="checkbox"
                      component={SwitchInput}
                      disabled={disabled}
                    />
                    <span className={classes.description}>
                      {values.useConnection ? 'Teams connection' : 'Direct URL'}
                    </span>
                  </div>
                }
              />

              {!values.useConnection && (
                <FieldWrapper
                  label="URL"
                  labelSize={2}
                  contentSize={10}
                  content={
                    <Field
                      id="url"
                      name="url"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={disabled}
                    />
                  }
                />
              )}

              {values.useConnection && (
                <FieldWrapper
                  label="Connection"
                  labelSize={2}
                  contentSize={10}
                  content={
                    <Field
                      id="teamsConnectionId"
                      name="teamsConnectionId"
                      fullWidth
                      labelName="name"
                      valueName="id"
                      options={connectionOptions}
                      component={DropDown}
                      disabled={disabled}
                    />
                  }
                />
              )}

              <FieldWrapper
                label="Message"
                labelSize={3}
                content={
                  <InputWithTemplate
                    name="template"
                    disabled={disabled}
                    form={form}
                    templateVariables={templateVariables}
                    multiline
                    variant="outlined"
                  />
                }
              />
              <TestArea
                onClick={() => handleTestClick({ url: getTestUrl(values) })}
                loading={loading}
                testOutput={testOutput}
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

TeamsSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default TeamsSettings;
