import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const Input = ({
  className,
  labelClassName,
  labelWithFilesClassName,
  style,
  labelStyle,
  labelWithFilesStyle,
  getFilesFromEvent,
  accept,
  multiple,
  disabled,
  content,
  withFilesContent,
  onFiles,
  type,
  files,
}) => {
  const webkitDirectoryValue = useMemo(() => (type === 'folder' ? '' : null), [
    type,
  ]);
  return (
    <label
      className={files.length > 0 ? labelWithFilesClassName : labelClassName}
      style={files.length > 0 ? labelWithFilesStyle : labelStyle}
    >
      {files.length > 0 ? withFilesContent : content}
      <input
        className={className}
        style={style}
        type="file"
        disabled={disabled}
        accept={accept}
        webkitdirectory={webkitDirectoryValue}
        multiple={multiple}
        onChange={async e => {
          const { target } = e;
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          // @ts-ignore
          target.value = null;
        }}
      />
    </label>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(['folder', 'file']),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelWithFilesClassName: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  labelStyle: PropTypes.objectOf(PropTypes.any),
  labelWithFilesStyle: PropTypes.objectOf(PropTypes.any),
  getFilesFromEvent: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  content: PropTypes.node,
  withFilesContent: PropTypes.node,
  onFiles: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  extra: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reject: PropTypes.bool.isRequired,
    dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    minSizeBytes: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
};

export default Input;
