import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Paper from '@common/Paper/Paper';
import crmSources from '@constants/crmSources';
import CrmDefaultForm from '../updateCrm/CrmDefaults/CrmDefaultForm';
import HaloDefaults from '../HaloIntegration/HaloDefaults';
import ZendeskDefaultForm from '../Zendesk/ZendeskDefaultForm';
import SuperOpsDefaultForm from '../SuperOps/SuperOpsDefaultForm';
import SyncroDefaultForm from '../Syncro/SyncroDefaultForm';

const TicketDefaults = ({
  data,
  onSubmit,
  onBack,
  crmId,
  customCancelText,
  crmType,
}) => {
  const getCrmForm = useMemo(() => {
    switch (crmType) {
      case crmSources.Halo.name:
        return (
          <HaloDefaults onSubmit={onSubmit} onBack={onBack} crmId={crmId} />
        );
      case crmSources.Zendesk.name:
        return (
          <ZendeskDefaultForm
            onBack={onBack}
            onSubmit={onSubmit}
            crmId={crmId}
          />
        );
      case crmSources.SuperOps.name:
        return (
          <SuperOpsDefaultForm
            onBack={onBack}
            onSubmit={onSubmit}
            crmId={crmId}
          />
        );
      case crmSources.SyncroMsp.name:
        return (
          <SyncroDefaultForm
            onBack={onBack}
            onSubmit={onSubmit}
            crmId={crmId}
          />
        );
      default:
        return (
          <CrmDefaultForm
            onSubmit={onSubmit}
            crmId={crmId.toString()}
            onBack={onBack}
            data={data}
            customCancelText={customCancelText}
          />
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmType, onSubmit, onBack, crmId]);

  return <Paper>{getCrmForm}</Paper>;
};

TicketDefaults.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  crmId: PropTypes.number.isRequired,
  customCancelText: PropTypes.string,
  crmType: PropTypes.string,
};

export default TicketDefaults;
