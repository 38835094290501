import crmSources from '@constants/crmSources';

export const availableCrmOptions = [
  crmSources.ConnectWise.idx,
  crmSources.Autotask.idx,
  crmSources.Halo.idx,
  crmSources.Kaseya.idx,
];

export default {};
