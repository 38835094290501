import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ASSIGN_TICKET_TO_TECH_REQUEST } from '@constants/routes';
import getFormattedDate from '@utils/getFormattedDate';

import ReusableButton from '@common/Button/Button';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import VerifyTechDialog from '@ui/pages/technicianVerification/TechnicianVerificationTable/dialogs/VerifyTechDialog';

import { normalizePascalCase } from '@utils/normalizePascalCase';

import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';

import useStyles from './styles';

const TechnicianVerificationTable = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const resource = 'VerifiedPsaTechBySms';
  const classes = useStyles();
  const { push } = useHistory();

  const isVerifiedOptions = [
    { label: 'All', value: '' },
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  const defaultSort = { fieldName: 'createdDate', order: 'DESC' };

  const onConfirm = item => {
    setFormOpen(true);
    setSelectedRequest(item);
  };

  return (
    <div className={classes.container}>
      <Table
        actions={null}
        defaultSort={defaultSort}
        columns={[
          {
            name: 'From',
            key: 'from',
            sortable: true,
            searchable: true,
            type: TableTypes.phoneNumber,
            minWidth: 150,
          },
          {
            name: 'Contact Name',
            key: 'contactName',
            sortable: true,
            searchable: true,
            minWidth: 200,
          },
          {
            name: 'Created Date',
            key: 'createdDate',
            sortable: true,
            searchable: true,
            minWidth: 210,
            isCustomAccessor: true,
            accessor: row => <>{getFormattedDate(row.createdDate)}</>,
          },
          {
            name: 'Is Verified',
            key: 'isVerified',
            minWidth: 150,
            type: TableTypes.dropdown,
            isCustomAccessor: true,
            sortable: true,
            searchable: true,
            labelAlignment: 'center',
            dropDownValues: isVerifiedOptions,
            filterByAutocomplete: 'isVerified',
            accessor: rowData => (
              <BooleanAccessor value={rowData.isVerified} useMultiColor />
            ),
          },
          {
            name: 'Tech Account Name',
            key: 'techAccountName',
            minWidth: 200,
            sortable: true,
            searchable: true,
          },
          {
            name: 'Verified Date',
            key: 'verifiedDate',
            sortable: true,
            searchable: true,
            minWidth: 200,
            isCustomAccessor: true,
            accessor: row =>
              row.verifiedDate && <>{getFormattedDate(row.verifiedDate)}</>,
          },
          {
            key: 'code',
            name: 'Code',
            minWidth: 100,
          },
          {
            key: 'type',
            name: 'Type',
            isCustomAccessor: true,
            minWidth: 170,
            accessor: item => <>{normalizePascalCase(item?.type)}</>,
          },
          {
            key: 'ticketId',
            name: 'Ticket ID',
            minWidth: 100,
          },
          {
            minWidth: 200,
            width: 200,
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: item => (
              <div className={classes.actions}>
                {(item?.type.toLowerCase().includes('sms') ||
                  item?.type.includes('ClientPortalToEnterCode')) &&
                  !item.isVerified && (
                    <ReusableButton
                      label="Assign"
                      viewType="blue"
                      onClick={() => {
                        push(
                          ASSIGN_TICKET_TO_TECH_REQUEST.replace(
                            ':requestId',
                            item.id,
                          ),
                        );
                      }}
                    />
                  )}
                {!item.isVerified &&
                  !item?.type.includes('ClientPortalToEnterCode') && (
                    <div>
                      <ReusableButton
                        label="Confirm"
                        viewType="blue"
                        onClick={() => {
                          onConfirm(item);
                        }}
                      />
                    </div>
                  )}
              </div>
            ),
          },
        ]}
        resource={resource}
      />
      {formOpen && (
        <VerifyTechDialog
          onClose={() => {
            setFormOpen(false);
          }}
          open={formOpen}
          from={selectedRequest.from}
          verifiedPsaTechBySmsId={selectedRequest.id}
          code={selectedRequest?.code}
          contactId={selectedRequest?.contactId}
          crmIdFromRequest={selectedRequest?.crmId}
          type={selectedRequest?.type}
        />
      )}
    </div>
  );
};

export default TechnicianVerificationTable;
