import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRecentlyCreatedChannels } from '@services/requests/messagingDashboard';
import { MESSAGING_DASHBOARD } from '@store/slices/resources';

export const ThunkGetRecentlyCreatedChannels = createAsyncThunk(
  `${MESSAGING_DASHBOARD}/getRecentlyCreatedChannels`,
  async () => {
    const channels = await getRecentlyCreatedChannels();
    return channels;
  },
);

export default {};
