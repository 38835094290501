import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getTicketStatusesByChatId } from '@services/requests/liveChats';

export const ThunkGetTicketStatusesByChatId = createAsyncThunk(
  `${LIVE_CHATS}/getTicketStatusesByChatId`,
  ({ liveChatId }) => getTicketStatusesByChatId(liveChatId),
);

export default {};
