import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0px',
    margin: '20px 80px',
    fontStyle: 'normal',
  },
  droppableContainer: {
    display: 'flex',
    padding: '0 16px 0 11px',
    gap: '16px',
  },
  formContainer: {
    margin: '0',
    width: 'calc(75% - 16px)',
    height: '80vh',
    overflowY: 'scroll',
    padding: '20px 40px',
  },
  genericPipelines: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  crmTreeWrapper: {
    background: 'white',
    padding: '20px',
    border: '0.0625rem solid rgb(235, 235, 235)',
    borderRadius: '15px',
    height: '80vh',
    right: '10px',
    width: '25%',
    minWidth: '400px',
    overflowY: 'auto',
  },
  sourceField: { marginBottom: '12px' },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
    width: '100%',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentFormWrapper: {
    width: '100%',
  },
  notificationTypes: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  delayContainer: {
    alignItems: 'center',
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    width: '100%',
    marginTop: '2.5rem',
  },
  inputWrapper: {
    marginBottom: '0.625rem',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:last-child': {
        marginLeft: '1.875rem',
      },
    },
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  actionButton: {
    borderRadius: '20px',
    cursor: 'pointer',
    minWidth: '28px',
    margin: '0 5px 0 5px',
    '&:hover': {
      background: '#efefef',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  circle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px #848484',
    },
  },
  iconContainer: {
    margin: '0px 5px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  notificationTypesContainer: {
    padding: '10px 0',
  },
  rounded: {
    fontStyle: 'normal',
    borderRadius: '0.3125rem',
  },
  fullWidth: {
    width: '100%',
  },
  marginLeft: {
    margin: '0 1rem',
  },
  tilesSpace: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'space-between',
    },
    marginTop: '1rem',
  },
  typesContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  typesIcon: {
    margin: '0.8rem',
    width: '1.8rem',
  },
  horizontalAlignment: {
    display: 'flex',
    alignItems: 'center',
  },
  listActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notificationContainer: {
    margin: '15px 16px 0',
    '& tbody': {
      maxHeight: '64vh',
    },
  },
}));

export default useStyles;
