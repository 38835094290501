import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessagingConfigurationDefaultValues } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetSmsConfigurationDefaultValues = createAsyncThunk(
  `${MESSAGING}/getMessagingConfigurationDefaultValues`,
  async () => {
    const defaults = await getMessagingConfigurationDefaultValues();
    return defaults;
  },
);

export default {};
