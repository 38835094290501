import composeValidators from '@utils/validators/composeValidators';
import { requiredValidator } from '@utils/validators';

const isUrl = url => {
  const regExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
  return regExp.test(url);
};

const urlValidator = url => {
  if (!isUrl(url)) return 'Not an URL';
};

const handleErrors = values => {
  return {
    url: composeValidators(requiredValidator, urlValidator)(values.url),
  };
};
export default handleErrors;
