import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useAsync from '@services/api/common/useAsync';
import {
  getIntegrationImage,
  getIntegrations,
} from '@components/DataSources/helpers';
import Card from '@components/DataSources/Integrations/components/Card';

import useStyles from '../../styles';

const SelectIntegration = ({ onSelect }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  useAsync(getIntegrations, setData);
  return (
    <div className={classes.selectIntegrationContainer}>
      {data.map(i => (
        <Card
          key={i.id}
          item={i}
          readOnly={false}
          getImage={getIntegrationImage}
          handleClick={() => onSelect(i.id)}
        />
      ))}
    </div>
  );
};

SelectIntegration.propTypes = {
  onSelect: PropTypes.func,
};

export default SelectIntegration;
