import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientPortals } from '@services/requests/crm';
import { CLIENT_PORTAL_USERS } from '@store/slices/resources';

export const ThunkGetClientPortals = createAsyncThunk(
  `${CLIENT_PORTAL_USERS}/getClientPortals`,
  async psaId => {
    const result = await getClientPortals(psaId);
    return result;
  },
);

export default {};
