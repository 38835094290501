import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    '& button:first-child': {
      marginRight: '0.5rem',
    },
  },
  buttonWrapper: {
    margin: '0 0.2rem',
  },
}));

export default useStyles;
