import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { addUsersToSmsCampaignGroup } from '@services/requests/smsCampaigns';

export const ThunkAddUsersToSmsCampaignGroup = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/addUsersToSmsCampaignGroup`,
  async ({ payload }) => addUsersToSmsCampaignGroup(payload),
);

export default {};
