import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteMessagingTemplate } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkDeleteMessagingTemplate = createAsyncThunk(
  `${MESSAGING}/deleteMessagingTemplate`,
  async payload => {
    await deleteMessagingTemplate(payload);
    return payload;
  },
);

export default {};
