import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from './styles';

const Modal = ({ open, setOpen, isCloseButton = true, title, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          {children}
          {isCloseButton && (
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isCloseButton: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Modal;
