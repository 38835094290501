import { OPT_IN } from '../requestResources';
import { makeRequest } from '../makeRequest';

export const getOptInUrl = payload =>
  makeRequest('POST', `${OPT_IN}/getOptInUrl`, payload);

export const sendOptInUrl = payload =>
  makeRequest('POST', `${OPT_IN}/sendOptInUrl`, payload);

export const validateOptInKey = apiKey =>
  makeRequest('GET', `${OPT_IN}/validateOptInKey?key=${apiKey}`);

export const getOptInSettings = tenantId =>
  makeRequest('GET', `${OPT_IN}/settings/${tenantId}`);

export const getContactMethodsOptions = (tenantId, crmId) =>
  makeRequest(
    'GET',
    `${OPT_IN}/tenant/${tenantId}/crm/${crmId}/preferredContactMethods`,
  );

export const saveOptInForm = (apiKey, payload) =>
  makeRequest('POST', `${OPT_IN}/saveOptInForm?key=${apiKey}`, payload);

export const getOptInSmsTemplate = () =>
  makeRequest('GET', `${OPT_IN}/getOptInSmsTemplate`);

export const addOptInSmsTemplate = payload =>
  makeRequest('POST', `${OPT_IN}/addOptInSmsTemplate`, payload);

export const groupOptIn = payload =>
  makeRequest('POST', `${OPT_IN}/groupOptIn`, payload);

export const deleteOptInItem = id => makeRequest('DELETE', `${OPT_IN}/${id}`);

export const getGroupOptInData = (crmId, ids) =>
  makeRequest(
    'GET',
    `${OPT_IN}/${crmId}/groupOptIn?${ids.map(i => `id=${i}`).join('&')}`,
  );

export const addPhoneToPsaContact = payload =>
  makeRequest('POST', `${OPT_IN}/addCellPhone`, payload);
