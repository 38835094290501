import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { updateKaseyaTicketNote } from '@services/requests/kaseyaTicket';

export const ThunkUpdateKaseyaTicketNote = createAsyncThunk(
  `${KASEYA_TICKET}/updateKaseyaTicketNote`,
  ({ crmDefaultId, ticketId, noteId, payload }) =>
    updateKaseyaTicketNote(crmDefaultId, ticketId, noteId, payload),
);

export default {};
