import React from 'react';
import PropTypes from 'prop-types';
import MuiSlider from '@common/MuiSlider/MuiSlider';
import { FormHelperText } from '@material-ui/core';

const SliderWrapper = ({ input, meta, ...rest }) => {
  const isError = meta && meta.error && meta.touched;
  return (
    <>
      <MuiSlider {...input} {...rest} />
      <FormHelperText error={isError} />
    </>
  );
};

SliderWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
  }),
  meta: PropTypes.objectOf(PropTypes.any),
};

export default SliderWrapper;
