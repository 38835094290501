import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(theme => ({
  container: {
    margin: props => (props.skipBorders ? '0' : '1rem 10vw'),
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    height: '90vh',
    overflowY: 'auto',
  },
  smsTemplate: {
    marginBottom: theme.spacing(2),
  },
  labels: {
    paddingLeft: '10px',
    color: 'black',
    boxSizingL: 'border-box',
    fontWeight: 'normal',
  },
  inputContainer: {
    fontWeight: 'normal',
  },
  alignRight: {
    textAlign: 'right !important',
  },
  quill: {
    background: 'white',
  },
}));

export default useStyles;
