import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURE_DATA } from '@store/slices/resources';
import { checkSecureLink } from '@services/requests/secureData';

export const ThunkCheckSecureLink = createAsyncThunk(
  `${SECURE_DATA}/checkSecureLink`,
  ({ code }) => checkSecureLink(code),
);

export default {};
