import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';
import crmSources from '@constants/crmSources';

import DropDown from '@ui/components/Auth/Common/DropDown';
import Loading from '@ui/components/common/Loading/Loading';
import KaseyaTicketTable from '@components/PsaTickets/components/Kaseya/KaseyaTicketTable';
import ZendeskTicketTable from '@components/PsaTickets/components/Zendesk/ZendeskTicketTable';
import SuperOpsTicketTable from '@components/PsaTickets/components/SuperOps/SuperOpsTicketTable';
import SyncroMspTicketTable from '@components/PsaTickets/components/SyncroMsp/SyncroMspTicketTable';
import ConnectWiseTicketTable from './components/ConnectWise/ConnectWiseTicketTable';
import HaloTicketTable from './components/Halo/HaloTicketTable';
import AutotaskTicketTable from './components/Autotask/AutotaskTicketTable';

import useStyles from './styles';

const PsaTickets = () => {
  const [loading, setLoading] = useState(false);

  const [selectedPsa, setSelectedPsa] = useState(
    localStorage.getItem('selectedPSA') || 0,
  );
  const [crmOptions, setCrmOptions] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    ({ target }) => {
      setSelectedPsa(target.value);
      localStorage.setItem('selectedPSA', target.value);
    },
    [crmOptions],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    const options = await dispatch(ThunkGetCrmOptions()).unwrap();
    setCrmOptions(options);

    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!!crmOptions && crmOptions.length === 1) {
      handleChange({ target: { value: crmOptions[0].defaultId } });
    }
  }, [crmOptions]);

  const table = useMemo(() => {
    if (crmOptions.length > 0) {
      const selectedOption = crmOptions.find(
        option => option.defaultId === +selectedPsa,
      );
      switch (selectedOption?.type) {
        case crmSources.ConnectWise.idx:
          return (
            <ConnectWiseTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        case crmSources.Autotask.idx:
          return (
            <AutotaskTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        case crmSources.Halo.idx:
          return (
            <HaloTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        case crmSources.Kaseya.idx:
          return (
            <KaseyaTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        case crmSources.Zendesk.idx:
          return (
            <ZendeskTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        case crmSources.SuperOps.idx:
          return (
            <SuperOpsTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        case crmSources.SyncroMsp.idx:
          return (
            <SyncroMspTicketTable
              defaultId={selectedOption.defaultId}
              crmId={selectedOption.crmId}
            />
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  }, [selectedPsa, crmOptions]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.dropDownContainer}>
        <DropDown
          input={{
            onChange: handleChange,
            value: selectedPsa,
          }}
          options={crmOptions}
          labelName="crmName"
          valueName="defaultId"
        />
      </div>
      {selectedPsa ? table : <>Select PSA</>}
    </div>
  );
};

PsaTickets.propTypes = {};

export default PsaTickets;
