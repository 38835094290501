import React, { useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ThunkGetTicketLink } from '@store/slices/crmTicket/thunks';
import { SelectIncidentTickets } from '@store/slices/incident/selectors';

import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import SimpleTable from '../SimpleTable';

import useStyles from '../../styles';

const Tickets = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLinkClick = useCallback(
    async ({ ticketId, crmDefaultId }) => {
      dispatch(ThunkGetTicketLink({ ticketId, crmDefaultId }))
        .unwrap()
        .then(res => {
          window.open(res.data, '_blank');
        });
    },
    [dispatch],
  );

  const columns = Object.freeze([
    {
      label: 'Id',
      value: 'ticketId',
    },
    { label: 'Status', value: 'status', color: 'statusColor' },
    { label: 'PSA', value: 'crmName' },
    {
      label: '',
      handler: item =>
        item.crmDefaultId ? (
          <Tooltip title="Open Ticket" style={{ cursor: 'pointer' }}>
            <LinkIcon
              onClick={() => {
                handleLinkClick(item);
              }}
            />
          </Tooltip>
        ) : (
          <div>no psa</div>
        ),
    },
  ]);

  return (
    <div className={classes.block}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderTitle}>Tickets</div>
      </div>

      <div>
        <SimpleTable columns={columns} selector={SelectIncidentTickets} />
      </div>
    </div>
  );
});

export default Tickets;
