import React from 'react';
import PropTypes from 'prop-types';
import QueryBuilder from 'react-querybuilder';
import {
  defaultCombinators,
  defaultOperators,
  templateRulesFields,
} from './params';

import useStyles from './styles';

import AddGroupActions from './components/AddGroupActions';
import AddRuleActions from './components/AddRuleActions';
import RemoveAction from './components/RemoveAction';

const ReactQueryBuilder = ({ data, onChange }) => {
  const classes = useStyles();
  const controlElements = {
    addGroupAction: AddGroupActions,
    addRuleAction: AddRuleActions,
    removeRuleAction: RemoveAction,
    removeGroupAction: RemoveAction,
  };

  return (
    <QueryBuilder
      query={data}
      onQueryChange={onChange}
      controlElements={controlElements}
      fields={templateRulesFields}
      operators={defaultOperators}
      combinators={defaultCombinators}
      controlClassnames={classes}
      enableMountQueryChange={false}
    />
  );
};

ReactQueryBuilder.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default ReactQueryBuilder;
