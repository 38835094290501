import { createAsyncThunk } from '@reduxjs/toolkit';
import { BILLING } from '@store/slices/resources';
import { addSubscriptionDiscount } from '@services/requests/subscriptions';

export const ThunkAddSubscriptionDiscount = createAsyncThunk(
  `${BILLING}/addSubscriptionDiscount`,
  async code => {
    const res = await addSubscriptionDiscount(code);
    return res;
  },
);

export default {};
