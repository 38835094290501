import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAutotaskTicketTimeEntry } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkDeleteAutotaskTicketTimeEntry = createAsyncThunk(
  `${CRM_TICKET}/deleteAutotaskTicketTimeEntry`,
  async ({ crmId, timeEntryId }) => {
    await deleteAutotaskTicketTimeEntry(crmId, timeEntryId);
    return timeEntryId;
  },
);

export default {};
