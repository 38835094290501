import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import useStyles from './styles';

const BoxWrapper = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.formWrapper}>{children}</Box>;
};

BoxWrapper.propTypes = {
  children: PropTypes.node,
};

export default BoxWrapper;
