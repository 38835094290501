import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  form: {
    margin: '15px auto',
    padding: '20px 20px',
    maxWidth: '50%',
    border: '1px solid #00000052',
    borderRadius: '15px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    margin: '10px 0px 0px 0px',
  },
  twoColumns: {
    display: 'flex',
    gap: '10px',
  },
}));

export default useStyles;
