import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConnectWiseTicketScheduleEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkAddConnectWiseTicketScheduleEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/addConnectWiseTicketScheduleEntry`,
  async ({ crmId, payload }) => {
    const scheduleEntry = await addConnectWiseTicketScheduleEntry(
      crmId,
      payload,
    );

    return scheduleEntry;
  },
);

export default {};
