import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const initState = { key: '', fileName: '' };
const Card = ({ data, icon, onClick }) => {
  const classes = useStyles();
  const [payload, setPayload] = useState(initState);

  useEffect(() => {
    if (data && data.value) {
      const keyMatch = data.value.match(
        /export\/devMonitors\/(.*)\/download/gi,
      );
      if (keyMatch) {
        const key = keyMatch[0].substring(
          'export/devMonitors/'.length,
          keyMatch[0].length - '/download'.length,
        );
        setPayload({ key, fileName: data.label });
      }
    } else {
      setPayload(initState);
    }
  }, [data]);

  return data ? (
    <div
      className={classes.base}
      onClick={() => onClick(payload)}
      role="presentation"
    >
      <div>{icon}</div>
      <div>
        <span>{data?.label}</span>
      </div>
    </div>
  ) : null;
};

Card.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
};

export default Card;
