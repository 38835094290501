import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { sendMicrosoftPush } from '@services/requests/psaPods';

export const ThunkSendMicrosoftPush = createAsyncThunk(
  `${CONNECTWISE_POD}/sendMicrosoftPush`,
  async payload => {
    const res = await sendMicrosoftPush(payload);
    return res;
  },
);

export default {};
