import { makeRequest } from '@services/requests/makeRequest';
import { SMS_BLACK_LIST } from '@services/requests/requestResources';

export const deleteSmsBlackListNumber = deleteParams =>
  makeRequest('DELETE', `/${SMS_BLACK_LIST}?${deleteParams}`);

export const addSmsBlackListNumber = phoneNumber =>
  makeRequest('POST', `/${SMS_BLACK_LIST}`, {
    blockedPhoneNumber: phoneNumber,
  });
