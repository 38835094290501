import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChatMessages } from '@services/requests/chats';
import { CHATS } from '@store/slices/resources';

export const ThunkGetChatMessages = createAsyncThunk(
  `${CHATS}/getChatMessages`,
  async payload => {
    const messages = await getChatMessages(payload);
    return messages;
  },
);

export default {};
