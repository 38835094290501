import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '98%',
    alignSelf: 'center',
    marginTop: '2rem',
    paddingLeft: '12px',
    height: '76vh',
  },
  form: {
    width: '70%',
    alignSelf: 'center',
    marginTop: '2rem',
    height: '90vh',
    overflowY: 'auto',
    paddingBottom: '15px',
  },
  buttons: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    '& > *': { marginLeft: '5px' },
  },
  label: {
    height: '53px',
    alignItems: 'center',
    display: 'flex',
  },
  box: {
    display: 'flex',
    justifyContent: 'initial',
    alignItems: 'center',
  },
  margins: {
    margin: '0 1rem 0 1rem',
  },
  activeFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emailBox: {},
  alignAuto: {
    alignSelf: 'auto',
  },
  paper: {
    marginBottom: '0.5rem',
    padding: '15px',
  },
}));

export default useStyles;
