import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import {
  ClientPortalCompaniesSelector,
  ClientPortalDefaultsSelector,
  ClientPortalInvalidItemsSelector,
  ClientPortalProcessingSelector,
  remove,
  setError,
  updateCompany,
  updateDefault,
} from '@store/slices/clientPortalDefaultMapping';
import DropDown from '@components/Auth/Common/DropDown';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDelete from '@assets/icons/cross.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import useStyles from '../styles';

const CwCompanyMappingItem = ({ item, crmType }) => {
  const { id, psaCompanyId, clientPortalDefaultId } = item;

  const defaults = useSelector(ClientPortalDefaultsSelector);
  const companies = useSelector(ClientPortalCompaniesSelector);
  const invalidItems = useSelector(ClientPortalInvalidItemsSelector);
  const processing = useSelector(ClientPortalProcessingSelector);

  const errors = useMemo(() => invalidItems.filter(i => i.id === id), [
    id,
    invalidItems,
  ]);

  const classes = useStyles({ errors });
  const dispatch = useDispatch();

  const resetError = () => dispatch(setError({ id, error: undefined }));

  const updateCompanyHandler = companyId => {
    dispatch(updateCompany({ id, companyId }));
    resetError();
  };

  const updateDefaultHandler = defaultId => {
    dispatch(updateDefault({ id, defaultId }));
    resetError();
  };

  const removeDefaultHandler = () => dispatch(remove({ id }));

  return (
    <div className={classes.companyMappingItem}>
      <DropDown
        classNameWrapper={classes.companyMappingItemDropdown}
        input={{
          value: psaCompanyId,
          onChange: e => updateCompanyHandler(e.target.value),
        }}
        options={companies}
        disabled={processing}
      />
      <DropDown
        classNameWrapper={classes.companyMappingItemDropdown}
        input={{
          value: clientPortalDefaultId,
          onChange: e => updateDefaultHandler(e.target.value),
        }}
        options={defaults.filter(i => i.info === crmType)}
        disabled={processing}
      />
      <ActionButton
        classNameWrapper={classes.icon}
        icon={<img src={iconDelete} alt="iconDelete" />}
        handler={removeDefaultHandler}
        toolTip="Remove"
        disabled={processing}
      />
      {!!errors.length && (
        <ActionButton
          classNameWrapper={classnames(classes.icon, classes.errorIcon)}
          icon={<ErrorOutlineIcon style={{ color: 'red' }} />}
          handler={null}
          toolTip={errors.map(obj => obj.error).join(', ')}
        />
      )}
    </div>
  );
};

CwCompanyMappingItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    psaCompanyId: PropTypes.number,
    clientPortalDefaultId: PropTypes.number,
    isError: PropTypes.bool,
  }),
};

export default CwCompanyMappingItem;
