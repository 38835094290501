import { createAsyncThunk } from '@reduxjs/toolkit';
import { TICKET_CREATION_TEMPLATES } from '@store/slices/resources';
import { deleteTicketCreationTemplate } from '@services/requests/ticketCreationTemplates';

export const ThunkDeleteTicketCreationTemplate = createAsyncThunk(
  `${TICKET_CREATION_TEMPLATES}/deleteTicketCreationTemplate`,
  async ({ templateId }) => {
    await deleteTicketCreationTemplate(templateId);
    return { id: templateId };
  },
);

export default {};
