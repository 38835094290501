import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { OPTIN_SETTINGS } from '@store/slices/resources';

export const ThunkAddOptInFormLogo = createAsyncThunk(
  `${OPTIN_SETTINGS}/addOptInLogo`,
  async ({ file }) => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await sendFormData(
      `${appConfig.baseUrl}/OptInSettings/upload-logo`,
      formData,
    ).then(r => r.text());

    return res;
  },
);

export default {};
