import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import {
  ThunkDeleteChatBot,
  ThunkGetChatBots,
} from '@store/slices/chatBots/thunks';
import { ChatBotsSelector } from '@store/slices/chatBots';

import Loading from '@common/Loading/Loading';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import ReusableButton from '@common/Button/Button';
import CreateChatBotDialog from '@components/ChatBots/dialogs/CreateChatBotDialog';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';

import useStyles from './styles';

const ChatBots = () => {
  const [loading, setLoading] = useState(true);
  const [openCreateChatBotDialog, setOpenCreateChatBotDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    setSelectedItem(undefined);
    setOpenCreateChatBotDialog(false);
  };

  const handleDelete = () => {
    dispatch(ThunkDeleteChatBot({ chatBotId: selectedItem.id }))
      .unwrap()
      .then(() => {
        setSelectedItem(undefined);
      })
      .finally(() => {
        setOpenDeleteDialog(false);
      });
  };

  const columns = useMemo(
    () => [
      { label: 'Name', value: 'name' },
      {
        label: 'Actions',
        handler: i => (
          <>
            <ActionFieldItem
              handler={() => {
                setSelectedItem(i);
                setOpenCreateChatBotDialog(true);
              }}
              icon={EditIcon}
              alt="Edit"
              toolTip="Edit"
            />

            <ActionFieldItem
              handler={() => {
                setSelectedItem(i);
                setOpenDeleteDialog(true);
              }}
              icon={DeleteIcon}
              alt="Delete"
              toolTip="Delete"
            />
          </>
        ),
      },
    ],
    [],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetChatBots())
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">Chat Sequences</Typography>
        <ReusableButton
          label="Add Sequence"
          onClick={() => {
            setOpenCreateChatBotDialog(true);
          }}
        />
      </div>
      <SimpleTable selector={ChatBotsSelector} columns={columns} />

      {openCreateChatBotDialog && (
        <CreateChatBotDialog
          open={openCreateChatBotDialog}
          handleClose={handleClose}
          item={selectedItem}
        />
      )}

      {openDeleteDialog && (
        <ConfirmDialog
          onAccept={handleDelete}
          onCancel={() => {
            setSelectedItem(undefined);
            setOpenDeleteDialog(false);
          }}
          title="Confirm Action"
          content="Are you sure you want to delete this item?"
          open={openDeleteDialog}
        />
      )}
    </div>
  );
};

export default ChatBots;
