import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import {
  TypeInput,
  TypeMeta,
  TypeOptions,
  TypeSize,
} from '@common/propTypes/common';

import useStyles from './dropDownStyles';

const DropDown = ({
  label,
  options,
  disabled,
  input: { onChange, ...restInput },
  size = 'medium',
  labelName = 'label',
  valueName = 'value',
  classNameWrapper,
  children,
  meta,
  allowEmpty,
  showLabel = false,
  suppressInlineLabel = false,
  onInputChange,
  id,
}) => {
  const classes = useStyles();
  const isError = meta && meta.error && meta.touched;

  const handleChange = useCallback(
    (event, option) => {
      onChange(event);
      if (onInputChange) {
        onInputChange(event.target.value, option);
      }
    },
    [onChange, onInputChange],
  );

  const renderSelectedMultipleValue = useCallback(
    selected => {
      if (restInput.multiple) {
        return options
          .filter(option => selected.includes(option[valueName]))
          .map(option => option[labelName])
          .join(', ');
      }
      return [];
    },
    [restInput.multiple, options, valueName, labelName],
  );

  return (
    <div className={classes.fieldWrapper}>
      <FormControl
        variant="outlined"
        className={cx(classes.formControl, classNameWrapper)}
        disabled={disabled}
        size={size}
        error={isError}
      >
        {(!restInput.value || showLabel) && !suppressInlineLabel && (
          <InputLabel
            id={label}
            classes={{
              root: classes.label,
              shrink: classNameWrapper,
            }}
          >
            {label}
          </InputLabel>
        )}
        <Select
          classes={{ root: classes.rootSelect }}
          {...restInput}
          renderValue={
            restInput.multiple ? renderSelectedMultipleValue : undefined
          }
          labelId={label}
          label={label}
          id={id}
          onChange={handleChange}
        >
          {allowEmpty && <MenuItem value="" className={classes.emptyItem} />}
          {children ||
            options.map((option, idx) => (
              <MenuItem
                key={option[valueName]}
                value={option[valueName]}
                id={`${id}-${option[valueName] ?? idx}`}
              >
                {option?.icon && (
                  <span className={classes.icon}>{option.icon}</span>
                )}
                {option[labelName] || 'All'}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {isError && (
        <FormHelperText error={isError} className={classes.helperText}>
          {meta?.error}
        </FormHelperText>
      )}
    </div>
  );
};

DropDown.propTypes = {
  input: TypeInput,
  meta: TypeMeta,
  size: TypeSize,
  label: PropTypes.string,
  options: PropTypes.arrayOf(TypeOptions),
  disabled: PropTypes.bool,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  classNameWrapper: PropTypes.string,
  children: PropTypes.node,
  showLabel: PropTypes.bool,
  suppressInlineLabel: PropTypes.bool,
  onInputChange: PropTypes.func,
  allowEmpty: PropTypes.bool,
};

export default DropDown;
