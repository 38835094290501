import { useCallback } from 'react';

const useCombinedRefs = (...refs) => {
  return useCallback(
    handle => {
      refs.forEach(ref => {
        ref(handle);
      });
    },
    [refs],
  );
};

export default useCombinedRefs;
