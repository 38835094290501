import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ReusableButton from '@common/Button/Button';
import useAsync from '@services/api/common/useAsync';
import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { getArrayParams } from '@components/Helpers/queryHelpers';
import CreatorCheckBox from './CreatorCheckBox';
import CreatorInput from './CreatorInput';
import useStyles from './styles';

const nameInputModel = {
  value: '',
  touched: false,
  error: '',
};

const DataViewCreator = ({
  open,
  setOpen,
  model,
  onSubmit,
  mailBoxId,
  parserId,
}) => {
  const classes = useStyles();
  const [idFieldsExists, setIdFieldsExists] = useState(false);
  const [dataViews, setDataViews] = useState([]);
  const [nameInput, setNameInput] = useState(nameInputModel);
  const [linkMailbox, setLinkMailbox] = useState(false);
  const [linkParser, setLinkParser] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSuitableDataViews = useCallback(async () => {
    if (open) {
      setLoading(true);
      const transformers = JSON.parse(model.fieldTransformer);
      let preParams = `mailBoxId=${mailBoxId}&`;
      if (parserId) {
        preParams += `parserId=${parserId}&`;
      }
      const identifiers = getArrayParams(
        transformers.filter(i => i.identifier),
        'path',
        'id',
      );
      setIdFieldsExists(!!identifiers);
      const idents = await getData(
        `${appConfig.baseUrl}/DataViews/data-views?${preParams}${identifiers}`,
      );
      setLoading(false);
      return idents.data;
    }
    return Promise.resolve([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNameInput(prev => ({ ...prev, value: model.name }));
  }, [model.name]);
  useAsync(getSuitableDataViews, setDataViews);

  const validateName = () => {
    if (!nameInput.value) {
      setNameInput({ ...nameInput, touched: true, error: 'required' });
      return false;
    }
    return true;
  };

  const handleCreateClick = () => {
    if (!validateName()) {
      return;
    }
    setLoading(true);
    const dataView = {
      name: nameInput.value,
      linkMailbox,
      linkParser,
    };
    setOpen(false);
    onSubmit(model, dataView);
  };

  const handleIgnoreClick = () => {
    setLoading(true);
    setOpen(false);
    onSubmit(model);
  };

  const handleNameChange = value =>
    setNameInput({ value, touched: true, error: '' });

  const createView = (
    <>
      <DialogTitle>Data View Creation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={cx(classes.displayFlex, classes.flexDirColumn)}>
            <CreatorInput
              input={nameInput}
              label="Name"
              setValue={handleNameChange}
            />
            <div className={cx(classes.displayFlex, classes.justifySpace)}>
              <CreatorCheckBox
                checked={linkMailbox}
                setChecked={setLinkMailbox}
                label="Link to mailbox"
              />
              <CreatorCheckBox
                checked={linkParser}
                setChecked={setLinkParser}
                label="Link to parser"
              />
            </div>
          </div>
        </DialogContentText>
        <DialogActions>
          <ReusableButton
            label="Skip"
            viewType="black"
            onClick={handleIgnoreClick}
            size="md"
          />
          <ReusableButton
            label="Create"
            viewType="black"
            onClick={handleCreateClick}
            size="md"
          />
        </DialogActions>
      </DialogContent>
    </>
  );

  const viewsExists = (
    <>
      <DialogTitle>Data Views</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>Data from this parser will be available from</span>
          <ul>
            {dataViews.map(i => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </DialogContentText>
        <DialogActions>
          <ReusableButton
            label="Create anyway"
            viewType="red"
            onClick={handleCreateClick}
            size="md"
          />
          <ReusableButton
            label="OK"
            viewType="black"
            onClick={handleIgnoreClick}
            size="md"
          />
        </DialogActions>
      </DialogContent>
    </>
  );

  if (!idFieldsExists) {
    return (
      <MuiDialog open={open} maxWidth="sm">
        <DialogTitle>There are some data views</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Unable to create data view without ID fields</span>
          </DialogContentText>
          <DialogActions>
            <ReusableButton
              label="OK"
              viewType="black"
              onClick={handleIgnoreClick}
              size="md"
            />
          </DialogActions>
        </DialogContent>
      </MuiDialog>
    );
  }

  const renderBody = () => {
    if (loading) return null;
    if (dataViews.length) return viewsExists;
    return createView;
  };

  return (
    <MuiDialog open={open} maxWidth="sm">
      {renderBody()}
    </MuiDialog>
  );
};

DataViewCreator.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mailBoxId: PropTypes.number,
  parserId: PropTypes.number,
};

export default DataViewCreator;
