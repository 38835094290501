import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { removeOptInLogo } from '@services/requests/optInSettings';

export const ThunkRemoveOptInLogo = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/removeOptInLogo`,
  () => removeOptInLogo(),
);

export default {};
