/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, isPending } from '@reduxjs/toolkit';
import { MESSAGING } from '../resources';
import {
  ThunkGetMessagingConfigurations,
  ThunkAddMessagingConfiguration,
  ThunkDeleteMessagingConfiguration,
  ThunkUpdateMessagingConfiguration,
  ThunkGetMessagingChannels,
  ThunkGetMessagingConfigurationsAutocomplete,
  ThunkGetMessagingConfiguration,
  ThunkGetMessagingChannel,
  ThunkGetChannelHistory,
  ThunkSendChannelMessage,
  ThunkGetChannelLogs,
  ThunkCloseChannel,
  ThunkGetMessagingTemplates,
  ThunkAddMessagingTemplate,
  ThunkDeleteMessagingTemplate,
  ThunkUpdateMessagingTemplate,
  ThunkGetPsaContactsAutocomplete,
  ThunkGetTemplateVariables,
  ThunkGetConfigurationLogs,
  ThunkCloneMessagingTemplate,
  ThunkGetMessagingChannelCrmInfo,
  ThunkUpdateChannelUserName,
  ThunkGetPsaCompaniesAutocomplete,
  ThunkGetConversationTemplates,
  ThunkAddConversationTemplate,
  ThunkDeleteConversationTemplate,
  ThunkEditConversationTemplate,
  ThunkGetLiveChatConversationTemplates,
  ThunkAddLiveChatConversationTemplate,
  ThunkDeleteLiveChatConversationTemplate,
  ThunkEditLiveChatConversationTemplate,
  ThunkDeleteTechnicianNumber,
  ThunkAddTechnicianNumber,
} from './thunks';

const initialState = {
  configurations: [],
  channels: [],
  configurationsAutocomplete: [],
  isLoading: true,
  channel: undefined,
  configuration: undefined,
  ticket: [],
  history: [],
  logs: [],
  configurationLogs: [],
  templates: [],
  psaContacts: [],
  psaCompanies: [],
  templateVariables: [],
  crmInfo: [],
  smsConversations: [],
  liveChatConversations: [],
  clientHistory: [],
  templateText: '',
  orderDesc: false,
};

const messagingSlice = createSlice({
  name: MESSAGING,
  initialState,
  reducers: {
    addHistory: (state, action) => {
      if (!state.orderDesc) {
        state.history.push(action.payload);
      } else {
        state.history = [action.payload, ...state.history];
      }
    },
    setTemplateText: (state, action) => {
      state.templateText = action.payload;
    },
    reverseHistory: state => {
      state.orderDesc = !state.orderDesc;
    },
    restoreHistoryOrder: state => {
      state.orderDesc = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetMessagingConfigurations.fulfilled,
        (state, { payload }) => {
          state.configurations = payload;
          state.isLoading = false;
        },
      )

      .addCase(
        ThunkAddMessagingConfiguration.fulfilled,
        (state, { payload }) => {
          state.configurations.push(payload);
        },
      )

      .addCase(
        ThunkUpdateMessagingConfiguration.fulfilled,
        (state, { payload }) => {
          state.configurations = state.configurations.map(item =>
            item.id !== payload.id ? item : payload,
          );
        },
      )

      .addCase(
        ThunkDeleteMessagingConfiguration.fulfilled,
        (state, { payload }) => {
          state.configurations = state.configurations.filter(
            item => item.id !== payload,
          );
        },
      )

      .addCase(ThunkGetMessagingChannels.fulfilled, (state, { payload }) => {
        state.channels = payload;
      })

      .addCase(
        ThunkGetMessagingConfigurationsAutocomplete.fulfilled,
        (state, { payload }) => {
          state.configurationsAutocomplete = payload;
        },
      )

      .addCase(
        ThunkGetMessagingConfiguration.fulfilled,
        (state, { payload }) => {
          state.configuration = payload;
          state.ticket = [
            {
              ...state.ticket[0],
              crmName: payload.crmName,
              crmType: payload.crmType,
            },
          ];
        },
      )

      .addCase(ThunkGetMessagingChannel.fulfilled, (state, { payload }) => {
        state.channel = payload;
        state.ticket = [{ ...state.ticket[0], ticketId: payload.ticketId }];
      })

      .addCase(ThunkGetChannelHistory.fulfilled, (state, { payload }) => {
        if (payload.userOnly) {
          state.clientHistory = payload.history;
        } else {
          state.history = payload.history;
        }
      })

      .addCase(ThunkSendChannelMessage.fulfilled, (state, { payload }) => {
        state.history.push(payload);
      })

      .addCase(ThunkGetChannelLogs.fulfilled, (state, { payload }) => {
        state.logs = payload;
      })

      .addCase(ThunkCloseChannel.fulfilled, state => {
        if (state?.channel) {
          state.channel.isClosed = true;
        }
      })

      .addCase(ThunkGetMessagingTemplates.fulfilled, (state, { payload }) => {
        state.templates = payload;
      })

      .addCase(ThunkAddMessagingTemplate.fulfilled, (state, { payload }) => {
        state.templates.push(payload);
      })

      .addCase(ThunkCloneMessagingTemplate.fulfilled, (state, { payload }) => {
        state.templates.push(payload);
      })

      .addCase(ThunkDeleteMessagingTemplate.fulfilled, (state, { payload }) => {
        state.templates = state.templates.filter(item => item.id !== payload);
      })

      .addCase(ThunkUpdateMessagingTemplate.fulfilled, (state, { payload }) => {
        state.templates = state.templates.map(item =>
          item.id !== payload.id ? item : payload,
        );
      })

      .addCase(
        ThunkGetPsaContactsAutocomplete.fulfilled,
        (state, { payload }) => {
          state.psaContacts = payload;
        },
      )

      .addCase(
        ThunkGetPsaCompaniesAutocomplete.fulfilled,
        (state, { payload }) => {
          state.psaCompanies = payload;
        },
      )

      .addCase(ThunkGetTemplateVariables.fulfilled, (state, { payload }) => {
        state.templateVariables = payload;
      })

      .addCase(ThunkGetConfigurationLogs.fulfilled, (state, { payload }) => {
        state.configurationLogs = payload;
      })

      .addCase(
        ThunkGetMessagingChannelCrmInfo.fulfilled,
        (state, { payload }) => {
          state.crmInfo = payload;
        },
      )

      .addCase(ThunkUpdateChannelUserName.fulfilled, (state, { payload }) => {
        state.channel.userName = payload;
      })

      .addCase(ThunkGetConversationTemplates.fulfilled, (state, action) => {
        state.smsConversations = action.payload;
      })

      .addCase(ThunkAddConversationTemplate.fulfilled, (state, action) => {
        state.smsConversations.push(action.payload);
      })

      .addCase(ThunkDeleteConversationTemplate.fulfilled, (state, action) => {
        state.smsConversations = state.smsConversations.filter(
          i => i.id !== action.payload,
        );
      })

      .addCase(
        ThunkEditConversationTemplate.fulfilled,
        (state, { payload }) => {
          state.liveChatConversations = state.liveChatConversations.map(i =>
            i.id !== payload.id ? i : payload,
          );
        },
      )

      .addCase(
        ThunkGetLiveChatConversationTemplates.fulfilled,
        (state, action) => {
          state.liveChatConversations = action.payload;
        },
      )

      .addCase(
        ThunkAddLiveChatConversationTemplate.fulfilled,
        (state, action) => {
          state.liveChatConversations.push(action.payload);
        },
      )

      .addCase(
        ThunkDeleteLiveChatConversationTemplate.fulfilled,
        (state, action) => {
          state.liveChatConversations = state.liveChatConversations.filter(
            i => i.id !== action.payload,
          );
        },
      )

      .addCase(
        ThunkEditLiveChatConversationTemplate.fulfilled,
        (state, { payload }) => {
          state.liveChatConversations = state.liveChatConversations.map(i =>
            i.id !== payload.id ? i : payload,
          );
        },
      )

      .addCase(ThunkDeleteTechnicianNumber.fulfilled, state => {
        state.channel = {
          ...state.channel,
          techPhone: undefined,
          techName: undefined,
        };
      })

      .addCase(ThunkAddTechnicianNumber.fulfilled, (state, { payload }) => {
        state.channel = {
          ...state.channel,
          techPhone: payload.phoneNumber,
          techName: payload.name,
        };
      })

      .addMatcher(
        isAnyOf(isPending(ThunkGetMessagingConfigurations)),
        state => {
          state.isLoading = true;
        },
      );
  },
});

export default messagingSlice.reducer;
export const {
  addHistory,
  reverseHistory,
  setTemplateText,
  restoreHistoryOrder,
} = messagingSlice.actions;

export const MessagingConfigurationsSelector = state =>
  state[MESSAGING].configurations;
export const MessagingLoadingSelector = state => state[MESSAGING].isLoading;
export const MessagingConfigurationAutocompleteSelector = state =>
  state[MESSAGING].configurationsAutocomplete;
export const MessagingChannelSelector = state => state[MESSAGING].channel;
export const MessagingConfigurationSelector = state =>
  state[MESSAGING].configuration;
export const MessagingChannelHistorySelector = state =>
  state[MESSAGING].history;
export const MessagingChannelLogsSelector = state => state[MESSAGING].logs;
export const MessagingTemplatesSelector = state => state[MESSAGING].templates;
export const MessagingPsaContactsSelector = state =>
  state[MESSAGING].psaContacts;
export const MessagingPsaCompaniesSelector = state =>
  state[MESSAGING].psaCompanies;
export const MessagingTemplateVariablesSelector = state =>
  state[MESSAGING].templateVariables;
export const MessagingConfigurationLogsSelector = state =>
  state[MESSAGING].configurationLogs;
export const MessagingChannelCrmInfoSelector = state =>
  state[MESSAGING].crmInfo;
export const ConversationTemplatesSelector = state =>
  state[MESSAGING].smsConversations;
export const LiveChatConversationTemplatesSelector = state =>
  state[MESSAGING].liveChatConversations;
export const MessagingClientHistorySelector = state =>
  state[MESSAGING].clientHistory;
