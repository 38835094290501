import { getHeaders } from '@utils/transfered';
import localStorage from '@services/localStorage';

const deleteWithResponse = async url => {
  const request = new Request(url, {
    method: 'DELETE',
    headers: getHeaders(true),
  });
  const response = await fetch(request);
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('accessToken');
    window.location.href = '#/login';
    return;
  }
  if (response.status < 200 || response.status >= 300) {
    const errorData = await response.json();
    const message = errorData || response.statusText;
    throw message;
  }

  return await response.json();
};

export default deleteWithResponse;
