import { createAsyncThunk } from '@reduxjs/toolkit';
import { addAssigneeForIncident } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';
import { normalizeIncident } from '../generator';

export const ThunkAddAssigneeForIncident = createAsyncThunk(
  `${INCIDENT}/addAssigneeForIncident`,
  async ({ assigneeId, incidentId }) => {
    const incident = await addAssigneeForIncident(assigneeId, incidentId);
    const logs = incident[0].logs.split(';');
    return {
      incident: normalizeIncident(incident[0]),
      logs,
    };
  },
);

export default {};
