import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import NumberInput from '@components/Auth/Common/NumberInput';
import moment from 'moment';
import useStyles from './styles';

const calculate = value => {
  const minutes = moment.duration(value, 'seconds').minutes();
  const seconds = moment.duration(value, 'seconds').seconds();
  return { minutes, seconds };
};

const SecondsPeriodPicker = ({
  input: { value, onChange },
  meta,
  disabled = false,
}) => {
  const classes = useStyles();

  const [seconds, setSeconds] = useState(calculate(value).seconds);
  const [minutes, setMinutes] = useState(calculate(value).minutes);

  useEffect(() => {
    onChange(+minutes * 60 + +seconds);
  }, [minutes, seconds, onChange]);

  const items = useMemo(
    () => [
      {
        id: 1,
        val: minutes,
        func: setMinutes,
        text: 'minute(s)',
        maxValue: 59,
        minValue: 0,
      },
      {
        id: 2,
        val: seconds,
        func: setSeconds,
        text: 'second(s)',
        maxValue: 59,
        minValue: 30,
      },
    ],
    [seconds, minutes],
  );

  return (
    <div className={classes.container}>
      {items.map(item => (
        <div key={item.id} className={classes.item}>
          <NumberInput
            input={{
              value: item.val,
              onChange: e => item.func(e.target.value),
            }}
            meta={meta}
            min={item.minValue}
            max={item.maxValue}
            disabled={disabled}
          />
          <span className={classes.text}>{item.text}</span>
        </div>
      ))}
    </div>
  );
};

SecondsPeriodPicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default SecondsPeriodPicker;
