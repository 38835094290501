import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConversationTemplate } from '@services/requests/messaging';
import { SMS_CONVERSATIONS } from '@store/slices/resources';

export const ThunkDeleteConversationTemplate = createAsyncThunk(
  `${SMS_CONVERSATIONS}/deleteConversation`,
  async id => {
    await deleteConversationTemplate(id);
    return id;
  },
);

export default {};
