import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    boxSizing: 'border-box',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignContent: 'center',
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '10px 0px',
    boxSizing: 'border-box',
  },
  dialog: {
    width: '650px',
    minHeight: '650px',
    display: props => (props.loading ? 'flex' : 'unset'),
    justifyContent: 'center',
    alignItems: 'center',
  },
  incomingPhoneNumber: {
    '& .MuiInputBase-root': {
      minHeight: '40px',
    },
  },
  column: {
    display: 'grid',
    gap: '15px',
    gridTemplateColumns: '1.5fr 2fr',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '0px 20px',
    boxSizing: 'border-box',
  },
  icon: {
    position: 'absolute',
    left: '0px',
    zIndex: 11000,
    transform: 'translate(-110%, 0px)',
  },
  templateInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    position: 'relative',
  },
}));

export default useStyles;
