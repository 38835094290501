import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    overflow: 'hidden',
    height: '100%',
  },
  table: {
    height: '100%',
    overflow: 'hidden',
    display: 'grid',
    flexDirection: 'column',
    gridTemplateRows: '1fr 60px',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: theme.palette.common.white,
  },
  headerStickyPosition: {
    top: '56px',
  },
  tableBody: {
    overflow: 'auto',
    '@media (max-width: 700px)': {
      width: '1200px',
      overflowX: 'auto',
    },
  },
  tableRow: {
    display: 'flex',
  },
  tableCell: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    wordBreak: 'break-word',
    maxHeight: '65px',
    height: '65px',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  bulk: {
    background: theme.palette.primary.main,
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    gap: '40px',
    zIndex: '9999',
  },
  tableRowAligned: {
    alignItems: 'center',
  },
  booleanContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  checkbox: {
    padding: '0px',
    width: '20px',
    height: '20px',
  },
  arrow: {
    marginLeft: 'auto',
    display: 'flex !important',
    alignItems: 'flex-end',
    height: '100%',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  select: {
    '& .MuiSelect-select': {
      paddingRight: 0,
    },
  },
  listItem: {
    width: 'auto !Important',
    '& .MuiListItem-gutters': { padding: 0 },
  },
  loader: {
    width: '100%',
  },
}));

export default useStyles;
