import { useContext } from 'react';
import clsx from "clsx";
import { css } from '@linaria/core';
import type { GroupFormatterProps } from '../types';
import { useFocusRef } from '../hooks';
import tableContext from '../context/tableStyle'
import { useGroupFormatterStyles } from '../style/groupFormatterStyle';

const groupCellContent = css`
  outline: none;
`;

const groupCellContentClassname = `rdg-group-cell-content ${groupCellContent}`;
const excluded = ['null','undefined']

export function ToggleGroupFormatter<R, SR>({
  groupKey,
  isCellSelected,
  toggleGroup
}: GroupFormatterProps<R, SR>) {
  const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);
  const { width } = useContext(tableContext);



  const classes = useGroupFormatterStyles({width})

  const classNames = clsx(groupCellContentClassname,classes.row)

  function handleKeyDown({ key }: React.KeyboardEvent<HTMLSpanElement>) {
    if (key === 'Enter') {
      toggleGroup();
    }
  }
  const text =  excluded.includes(groupKey) ? '': groupKey;

  return (
    <span
      ref={ref}
      className={classNames}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
    >
      {text}
    </span>
  );
}
