import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  invalidEmailText: {
    color: 'red',
    textAlign: 'center',
    marginTop: '1rem',
  },
}));

export default useStyles;
