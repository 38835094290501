import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRICE_PLANS } from '@store/slices/resources';
import { getBillingAddress } from '@services/requests/billing';

export const ThunkGetBillingAddress = createAsyncThunk(
  `${PRICE_PLANS}/getBillingAddress`,
  () => getBillingAddress(),
);

export default {};
