import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dragContainer: ({ isDragging, isOver }) => ({
    opacity: isDragging ? 0.5 : 1,
    textAlign: 'center',
    backgroundColor: isOver && '#ececec',
    cursor: 'move',
  }),
  resetMenuButton: {
    marginTop: '0.3rem',
    padding: 'revert',
  },
  formGroupContainer: {
    margin: '0 1rem',
  },
  menuDescription: {
    fontSize: '0.875rem',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '500',
    lineHeight: '1.57',
    color: '#607D8B',
    paddingTop: '0.75rem',
  },
  formCheckBox: {
    textTransform: 'capitalize',
  },
  headerContainer: {
    textAlign: 'center',
  },
  formControl: {
    paddingBottom: '0.5rem',
  },
  menuContainer: {
    width: '240px',
  },
}));

export default useStyles;
