import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Modal from '@ui/common/Modal/Modal';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';

import Input from '@components/Auth/Common/Input';
import { editSubscriptionId } from '@components/Admin/Subscriptions/helpers';

import { actions } from '@store/actions';
import { requiredValidator } from '@utils/validators';

import useStyles from './styles';

const EditStripeIdModal = ({ modalData, setModalData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState(false);

  const submit = values => {
    setProcessing(true);
    editSubscriptionId(modalData.id, {
      stripeId: values.stripeId,
    })
      .then(() => {
        setModalData(null);
        dispatch(
          actions.updateItemInList({
            ...modalData,
            stripeId: values.stripeId,
          }),
        );
        enqueueSnackbar('Subscription ID was updated', {
          variant: 'success',
        });
      })
      .catch(err => {
        enqueueSnackbar(err.title || 'Something went wrong', {
          variant: 'error',
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    stripeId: requiredValidator(values.stripeId),
  });

  return (
    <Modal
      open
      setOpen={setModalData}
      title="Edit Subscription ID"
      isCloseButton={false}
    >
      <Form
        onSubmit={submit}
        initialValues={{ stripeId: modalData.stripeId }}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FieldWrapper
              label="Subscription ID"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="stripeId"
                  id="stripeId"
                  render={Input}
                  disabled={processing}
                />
              }
            />
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setModalData(null);
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
              />
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

EditStripeIdModal.propTypes = {
  setModalData: PropTypes.func,
  modalData: PropTypes.shape({
    stripeId: PropTypes.number,
  }),
};

export default EditStripeIdModal;
