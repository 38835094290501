import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';

import GrayIconsTheme from '@services/themes/GrayIconsTheme';
import useStyles from './styles';

const CustomCheckbox = ({ input = {}, classNameWrapper, ...rest }) => {
  const classes = useStyles();

  return (
    <GrayIconsTheme>
      <Checkbox
        classes={{ root: cx(classes.checkboxWrapper, classNameWrapper) }}
        checkedIcon={<CheckBoxOutlined />}
        {...input}
        {...rest}
      />
    </GrayIconsTheme>
  );
};

CustomCheckbox.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  classNameWrapper: PropTypes.string,
};

export default CustomCheckbox;
