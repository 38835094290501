import React from 'react';
import PropTypes from 'prop-types';

import HyperlinkAccessor from '@common/MuiVirtualizedTable/components/accessors/HyperlinkAccessor';
import DetailedViewAccessor from '@common/MuiVirtualizedTable/components/accessors/DetailedViewAccessor';
import BooleanValueAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanValueAccessor';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import booleanOptions from '@utils/constants/options';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDashboard from '@assets/icons/dashboard.svg';
import { useToggle } from '@components/Hooks/useToggle';
import { Box, Modal } from '@material-ui/core';
import AlertHookTemplate from '@components/DataSources/AlertForm/AlertHookTemplate';

import useStyles from '@components/DataSources/Integrations/pages/Meraki/styles';

const AlertSettingGrid = ({ resource, id, organizationName }) => {
  const classes = useStyles();
  const [alertFormOpened, toggleAlertFormOpened] = useToggle(false);
  const columns = [
    {
      key: 'networkName',
      name: 'Network',
      width: 200,
      isCustomAccessor: true,
      sortable: true,
      searchable: true,
      accessor: row => (
        <HyperlinkAccessor href={row.networkUrl} value={row.networkName} />
      ),
    },
    {
      key: 'allAdmins',
      name: 'All Admins',
      width: 150,
      isCustomAccessor: true,
      accessor: row => (
        <BooleanValueAccessor
          value={row.alertSettings?.defaultDestinations.allAdmins}
        />
      ),
      type: TableTypes.boolean,
    },
    {
      key: 'snmp',
      name: 'snmp',
      width: 150,
      sortable: true,
      searchable: true,
      type: TableTypes.dropdown,
      dropDownValues: booleanOptions,
      filterByAutocomplete: 'snmp',
      isCustomAccessor: true,
      accessor: row => (
        <BooleanValueAccessor
          value={row.alertSettings?.defaultDestinations.snmp}
        />
      ),
    },
    {
      key: 'emails',
      name: 'Emails',
      sortable: true,
      searchable: true,
      width: 350,
      isCustomAccessor: true,
      accessor: row => <DetailedViewAccessor value={row.emails} />,
    },
    {
      key: 'webHooks',
      type: TableTypes.actions,
      name: 'Web hooks',
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      width: 350,
      accessor: row => <DetailedViewAccessor value={row.webHooks} />,
    },
  ];

  return (
    <Table
      actionsComponent={
        <>
          <span className={classes.organizationHeader}>
            {`Alert setting - ${organizationName}`}
            <ActionButton
              icon={<img src={iconDashboard} alt="iconDashboard" />}
              handler={toggleAlertFormOpened}
              toolTip="to form"
            />
          </span>

          <Modal open={alertFormOpened} onClose={toggleAlertFormOpened}>
            <Box className={classes.modalBox}>
              <AlertHookTemplate
                onCancel={toggleAlertFormOpened}
                integrationId={id}
              />
            </Box>
          </Modal>
        </>
      }
      resource={resource}
      bulkActions
      columns={columns}
    />
  );
};

AlertSettingGrid.propTypes = {
  resource: PropTypes.string,
  id: PropTypes.number,
  organizationName: PropTypes.string,
};

export default AlertSettingGrid;
