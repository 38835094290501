import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTOTASK_TICKET } from '@store/slices/resources';
import { getAutotaskNotes } from '@services/requests/autotaskTicket';

export const ThunkGetAutotaskNotes = createAsyncThunk(
  `${AUTOTASK_TICKET}/getAutotaskNotes`,
  ({ crmDefaultId, ticketId }) => getAutotaskNotes(crmDefaultId, ticketId),
);

export default {};
