import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons/';
import { getMerakiOrganizations } from '@components/DataSources/helpers';

let timer = null;

function debounce(cb, delay = 500) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

const QuickSearchToolbar = ({
  setFilteredOrganization,
  integrationId,
  end,
  setSubLoading,
}) => {
  const [search, setSearch] = useState('');

  const handleSearch = value => {
    setSubLoading(true);
    getMerakiOrganizations(integrationId, 0, end, value)
      .then(res => {
        setFilteredOrganization(res);
      })
      .finally(() => {
        setSubLoading(false);
      });
  };

  const handler = debounce(value => {
    handleSearch(value);
  }, 400);

  const onSearchChange = e => {
    e.preventDefault();
    setSearch(e.target.value);
    handler(e.target.value);
  };

  const onSearchClear = () => {
    setSearch('');
    handleSearch();
  };

  return (
    <div>
      <TextField
        variant="standard"
        value={search}
        onChange={onSearchChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: search ? 'visible' : 'hidden' }}
              onClick={onSearchClear}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

QuickSearchToolbar.propTypes = {
  setFilteredOrganization: PropTypes.func.isRequired,
  integrationId: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  setSubLoading: PropTypes.func.isRequired,
};

export default QuickSearchToolbar;
