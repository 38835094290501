import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  font: {
    fontStyle: 'normal',
  },
  currentPlan: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
  infoItem: {
    margin: '5px',
  },
  grayedInfoItem: {
    color: '#A4AFC1',
  },
  redInfoItem: {
    color: '#ff000087',
  },
  progressBar: {
    margin: '20px 0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  activeBanner: data => ({
    fontWeight: 'bold',
    background: !data.active && '#ff000087',
    borderRadius: !data.active && '3px',
    padding: !data.active && '2px',
  }),
}));

export default useStyles;
