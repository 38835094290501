import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  stepperHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
  stepperContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'nowrap',
  },
  stepper: {
    width: '100%',
    minHeight: '80%',
  },
  dialogTitle: { width: '1000px' },
  dialogContent: { height: '80vh' },
  itemContainer: {
    padding: props => (!props.addChipStyles ? '20px 35px' : '0px'),
    '& li': {
      '& div': {
        maxWidth: 'unset',
        border: '2px solid #1b3670',
        fontSize: 'medium',
        fontWeight: 700,
      },
    },
  },
  userSetupExternalLogins: {
    width: '100%',
    maxHeight: '250px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userSetupExternalLoginsItems: { display: 'flex', justifyContent: 'center' },
  userSetupExternalLoginsImages: { width: '150px', cursor: 'pointer' },
  externalLoginCheckIcon: { display: 'flex', alignItems: 'flex-end' },
  neverShowAgainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    color: '#3A3E45',
    fontSize: '14px',
  },
  timeZoneContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 200px',
    alignItems: 'center',
  },
  timeZoneButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mfaSetup: {
    margin: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  notificationsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    width: '100%',
  },
  notificationsRowItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '50%',
  },
  notificationRowItemHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '10px',
  },
  userSetup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  inviteDialogItemsList: {
    overflowY: 'auto',
    minHeight: '20vh',
    marginTop: '2vh',
  },
  inviteDialogItemsListHeader: {
    '& > div': {
      fontWeight: '700',
    },
  },
  inviteDialogResultsHeader: {
    display: 'flex',
    gap: '10px',
  },
  inviteDialogResultsList: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr 1fr',
    gap: '10px',
  },
  inviteDialogResultsHasErrors: {
    gridTemplateColumns: '4fr 1fr 6fr',
  },
  verificationSettingsWrapper: {
    padding: '20px 35px',
  },
}));

export default useStyles;
