import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_DEFAULT } from '@store/slices/resources';
import { deleteClientPortalDefault } from '@services/requests/clientPortal';

export const ThunkDeleteClientPortalDefault = createAsyncThunk(
  `${CLIENT_PORTAL_DEFAULT}/deleteClientPortalDefault`,
  async ({ defaultId }) => {
    await deleteClientPortalDefault(defaultId);
    return defaultId;
  },
);

export default {};
