import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRedirect, useRefresh, useVersion } from 'react-admin';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';

import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import TableLabel from '@common/TableLabel/TableLabel';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';
import { sortOrders } from '@constants/filters';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import { getPredefinedEvent } from '@constants/signalRMessageEvents';
import eventBus from '@services/eventBus';
import { socketMessageEvents as events } from '@constants/events';
import TestBox from '@common/TestBox/TestBox';
import TableDateLabel from '@common/TableLabel/TableDateLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import DateTimeField from '@common/TableComponents/DateTimeField';

import UploadDialog from '@components/MailBox/components/Documents/UploadDialog';
import letterStatuses from '@constants/letterStatuses';
import useTableDateLabel from '@common/hooks/useTableDateLabel';
import TableDatePicker from '@common/TableDatePicker/TableDatePicker';
import { useLocation } from 'react-router-dom';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import TriggersList from '@components/MailBox/components/Documents/components/TriggersList';
import useStyles from './styles';

import WarningMessage from './WarningMessage';
import BulkActionButtons from './BulkActions/BulkActions';
import ListActions from '../Common/ListActions';
import StatusField from './StatusField';
import {
  getLetterById,
  removeLetter,
  reParseMessage,
  testMessage,
} from '../../helpers';
import ActionField from './Actions/ActionField';
import SubjectField from '../Common/SubjectField';

const ignoredStatus = 'Ignored';

const LettersList = ({ resource, mailBoxId }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const version = useVersion();
  const dispatch = useDispatch();
  const location = useLocation();

  const list = useSelector(listSelector);

  const [defaultFilters, setDefaultFilters] = useState({});
  const [warningIsOpen, setWarningIsOpen] = useState(false);

  useMemo(() => {
    const dataFromRoute =
      location.search &&
      location.search.match(`\\?date=(\\d{4}-\\d{2}-\\d{2})`)[1];
    if (dataFromRoute) {
      const dateMoment = moment(dataFromRoute).utc();
      const dateFrom = dateMoment.toISOString();
      const dateTo = dateMoment.add(1, 'days').toISOString();
      setDefaultFilters({ dateTime_From: dateFrom, dateTime_To: dateTo });
    }
  }, [location.search]);

  const onMessageHandler = useCallback(
    ({ detail: message }) => {
      const messageData = getPredefinedEvent(message);
      if (messageData && messageData.mailBoxId === mailBoxId) {
        getLetterById(messageData.letterId)
          .then(data => {
            if (data.status === ignoredStatus) {
              dispatch(actions.removeItemFromList(data));
            } else {
              dispatch(actions.updateItemInList(data));
            }
          })
          .catch(() =>
            enqueueSnackbar('Unable to update list. Press refresh', {
              variant: 'error',
            }),
          );
      }
    },
    [dispatch, mailBoxId],
  );

  useEffect(() => {
    eventBus.on(events.SocketMessageReceived, onMessageHandler);

    return () =>
      eventBus.remove(events.SocketMessageReceived, onMessageHandler);
  }, [onMessageHandler]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const onRefreshClick = async value => {
    const result = await reParseMessage(value.id);
    if (result) {
      enqueueSnackbar('Message re-parse queued', { variant: 'info' });
    } else {
      enqueueSnackbar('Message re-parse failed', { variant: 'error' });
    }
  };

  const onDeleteClick = value => {
    setDeleteId(value.id);
    setDeleteDialogOpen(true);
  };
  const onAddClick = value => {
    redirect(`/mailbox/${mailBoxId}/letter/${value.messageId}/createParser`);
  };

  const onEditClick = value => {
    redirect(
      `/mailbox/${mailBoxId}/letter/${value.messageId}/parser/${value.parserId}/updateParser`,
    );
  };

  const onDeleteLetterHandler = async () => {
    const result = await removeLetter(deleteId);
    if (!result) {
      enqueueSnackbar('Unable to remove record', { variant: 'error' });
    } else {
      const newList = list.filter(item => item.id !== deleteId);
      dispatch(actions.saveList(newList));
      refresh();
    }
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onCancelDeleteHandler = () => {
    setDeleteDialogOpen(null);
    setDeleteId(null);
  };

  const onLetterClick = value => {
    redirect(`/mailbox/${mailBoxId}/letter/${value.messageId}`);
  };

  const [testDialogOpen, setTestDialogOpen] = useState(false);
  const [testDialogData, setTestDialogData] = useState(null);
  const [testDialogLoading, setTestDialogLoading] = useState(false);
  const [triggersForMessage, setTriggersForMessage] = useState(null);

  const onTestClick = messageId => {
    setTestDialogLoading(true);
    setTestDialogOpen(true);
    testMessage(messageId, +mailBoxId)
      .then(data => {
        setTestDialogData(data);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setTestDialogLoading(false));
  };

  const onTestDialogClose = () => {
    setTestDialogData(null);
    setTestDialogOpen(false);
  };
  const {
    date: dateTime,
    isOpen: isOpenDateTime,
    setIsOpen: setIsOpenDateTime,
    dateDispatcher: dateTimeDispatcher,
  } = useTableDateLabel();
  const {
    date: dateParsed,
    isOpen: isOpenDateParsed,
    setIsOpen: setIsOpenDateParsed,
    dateDispatcher: dateParsedDispatcher,
  } = useTableDateLabel();

  const displayTriggers = (id, status, row) => {
    if (status) {
      setTriggersForMessage(row.messageId);
    }
  };

  return (
    <div className={classes.tableWrapper} key={version}>
      <WarningMessage
        mailboxId={mailBoxId}
        setWarningIsOpen={setWarningIsOpen}
      />
      <div className={classes.tableContainer}>
        <MuiVirtualizedTable
          resource={resource}
          actions={
            <ListActions
              onRefresh={refresh}
              onUploadOpen={() => setUploadDialogOpen(true)}
            />
          }
          bulkActions={<BulkActionButtons mailBoxId={mailBoxId} />}
          defaultSort={{
            field: 'dateTime',
            order: sortOrders.desc,
          }}
          defaultFilters={defaultFilters}
          columns={[
            {
              name: <TableLabel item={{ field: 'mailFrom', name: 'Sender' }} />,
              key: 'mailFrom',
              minWidth: 270,
              fontSize: 'standard',
              align: 'left',
            },
            {
              name: <TableLabel item={{ field: 'subject', name: 'Subject' }} />,
              key: 'subject',
              fontSize: 'standard',
              align: 'left',
              accessor: rowData => (
                <SubjectField
                  record={rowData}
                  key="subject"
                  source="subject"
                  label="Subject"
                  onClick={onLetterClick}
                  sortable
                />
              ),
              minWidth: 358,
            },
            {
              name: (
                <TableDropDown
                  item={{ field: 'status', name: 'Status' }}
                  options={letterStatuses}
                />
              ),
              key: 'status',
              fontSize: 'standard',

              accessor: rowData => (
                <StatusField
                  key="status"
                  record={rowData}
                  source="status"
                  label="Status"
                  sortable
                />
              ),
              width: 170,
            },
            {
              name: 'Trigger',
              key: 'isTriggered',
              width: 110,
              accessor: row => (
                <StatusCircle
                  handleClick={displayTriggers}
                  row={row}
                  rowKey="isTriggered"
                />
              ),
            },
            {
              name: (
                <TableDateLabel
                  item={{ field: 'dateTime', name: 'Date Received' }}
                  date={dateTime}
                  setIsOpen={setIsOpenDateTime}
                />
              ),
              key: 'dateTime',
              accessor: rowData => (
                <DateTimeField
                  record={rowData}
                  key="dateTime"
                  source="dateTime"
                  label="Received date"
                  sortable
                />
              ),
              minWidth: 165,
            },
            {
              name: (
                <TableLabel item={{ field: 'parserName', name: 'Parser' }} />
              ),
              key: 'parserName',
              fontSize: 'standard',
              minWidth: 260,
            },
            {
              name: (
                <TableDateLabel
                  item={{ field: 'dateParsed', name: 'Parse Date' }}
                  date={dateParsed}
                  setIsOpen={setIsOpenDateParsed}
                />
              ),
              key: 'dateParsed',
              accessor: rowData => (
                <DateTimeField
                  record={rowData}
                  key="dateParsed"
                  source="dateParsed"
                  label="Last Parsed Date"
                  sortable={false}
                />
              ),
              minWidth: 165,
            },
            {
              name: 'Actions',
              key: 'actions',
              accessor: rowData => (
                <ActionField
                  label="Actions"
                  record={rowData}
                  handleUpdateClick={onRefreshClick}
                  handleDeleteClick={onDeleteClick}
                  handleAddClick={onAddClick}
                  handleUpdateParserClick={onEditClick}
                  handleTestClick={onTestClick}
                  mailBoxId={mailBoxId}
                />
              ),
              minWidth: 206,
            },
          ]}
        >
          <TableDatePicker
            date={dateTime}
            dateDispatcher={dateTimeDispatcher}
            setIsOpen={setIsOpenDateTime}
            isOpen={isOpenDateTime}
            field="dateTime"
          />
          <TableDatePicker
            date={dateParsed}
            dateDispatcher={dateParsedDispatcher}
            setIsOpen={setIsOpenDateParsed}
            isOpen={isOpenDateParsed}
            field="dateParsed"
          />
        </MuiVirtualizedTable>
      </div>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteLetterHandler}
        onCancel={onCancelDeleteHandler}
        title="Deleting the letter"
        content="Are you sure to remove the letter. This action will not affect total statistics."
      />
      <UploadDialog open={uploadDialogOpen} setOpen={setUploadDialogOpen} />
      <TestBox
        open={testDialogOpen}
        onClose={onTestDialogClose}
        loading={testDialogLoading}
        data={testDialogData}
      />
      <TriggersList
        isOpen={!!triggersForMessage}
        onClose={() => setTriggersForMessage(null)}
        letterId={triggersForMessage}
      />
    </div>
  );
};

LettersList.propTypes = {
  resource: PropTypes.string,
  mailBoxId: PropTypes.string,
};

export default LettersList;
