import React, { useCallback, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { enqueueSnackbar } from 'notistack';
import { TEXT_UPDATE_SUCESS } from '@constants/texts/common';
import Loading from '@common/Loading/Loading';
import { Field, Form } from 'react-final-form';
import useAsync from '@services/api/common/useAsync';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import roles from '@constants/roles';
import {
  getRoleIdFromStorage,
  setTenantImageUrlToStorage,
} from '@services/api';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import UploadImageField from '@components/Profile/components/UploadImageField/UploadImageField';
import useUploadImage from '@components/Profile/hook/useUploadImage';
import { HOME_ROUTE } from '@constants/routes';
import FormSwitch from '@common/FilterInputs/Switch';
import HtmlInput from '@common/HtmlInput';
import CountrySettings from '@components/Settings/TenantSettings/CountrySettings';
import { getTenantSettings, getTimeZones, postTenantSettings } from './helpers';
import useStyles from './styles';

const TenantSettings = () => {
  const classes = useStyles();
  const redirect = useRedirect();

  const readOnlyMode = getRoleIdFromStorage() === roles.READ_ONLY;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [timeZones, setTimeZones] = useState([]);
  useAsync(getTimeZones, setTimeZones);
  useAsync(getTenantSettings, setData);

  const {
    imageUri,
    showLoader,
    startLoading,
    finishLoading,
    setImage,
    removeImage,
  } = useUploadImage(data?.tenantImageUrl);

  const dataLoaded = !!data && !!timeZones;

  useEffect(() => {
    if (data) setImage(data.tenantImageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submit = async values => {
    const { footer, ...payload } = values;
    const requestBody = {
      ...payload,
      emailFooter: footer,
      tenantImageUrl: imageUri,
    };
    setLoading(true);
    const result = await postTenantSettings(requestBody);
    if (!result) {
      setLoading(false);
      enqueueSnackbar('Unable to update ticket settings', { variant: 'error' });
      return;
    }
    setTenantImageUrlToStorage(imageUri);
    setLoading(false);
    enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' });
    redirect(HOME_ROUTE);
  };

  const parseResult = async result => {
    const parsedResult = await result.json();
    return parsedResult?.tenantImageUrl;
  };

  const uploadImageCompleted = useCallback(
    form => {
      form.change('useTenantImageAsMailHeader', true);
    },
    [finishLoading],
  );

  return dataLoaded ? (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          initialValues={{ ...data, footer: data.emailFooter || '' }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} noValidate>
              <UploadImageField
                title="components.fileUploader.imageUploader.tenant.title"
                uploadApiUrl="/tenantSettings/tenant-image"
                imageUrl={parseResult}
                imageActions={{
                  imageUri,
                  showLoader,
                  startLoading,
                  finishLoading: img => {
                    finishLoading(img);
                    uploadImageCompleted(form);
                  },
                  removeImage,
                }}
                avatarVariant="rounded"
              />
              <CountrySettings loading={loading} readOnlyMode={readOnlyMode} />
              <FieldWrapper
                classNameGridContainer={classes.gridContainer}
                classNameLabelOuter={classes.fieldLabelOuter}
                justify="space-evenly"
                label="Time Zone"
                labelSize={3}
                contentSize={9}
                content={
                  <Field
                    id="timeZone"
                    name="timeZone"
                    component={DropDown}
                    options={timeZones}
                    disabled={loading || readOnlyMode}
                  />
                }
              />
              <div className={classes.formContentWrapper}>
                <FieldWrapper
                  classNameGridContainer={classes.gridContainer}
                  classNameLabelOuter={classes.fieldLabelOuter}
                  label="Use tenant logo as email header"
                  labelSize={6}
                  contentSize={6}
                  content={
                    <Field
                      id="useTenantImageAsMailHeader"
                      name="useTenantImageAsMailHeader"
                      type="checkbox"
                      component={FormSwitch}
                      disabled={loading || readOnlyMode}
                    />
                  }
                />

                <FieldWrapper
                  classNameGridContainer={classes.gridContainer}
                  classNameLabelOuter={classes.fieldLabelOuter}
                  label="Hide email Logo"
                  labelSize={6}
                  contentSize={6}
                  content={
                    <Field
                      id="hideEmailBanner"
                      name="hideEmailBanner"
                      type="checkbox"
                      component={FormSwitch}
                      disabled={loading || readOnlyMode}
                    />
                  }
                />

                <FieldWrapper
                  label="Email signature"
                  labelSize={3}
                  contentSize={9}
                  content={
                    <HtmlInput
                      name="emailSignature"
                      form={form}
                      reactQuillClassName={classes.reactQuillClassName}
                    />
                  }
                />

                <FieldWrapper
                  classNameGridContainer={classes.gridContainer}
                  classNameLabelOuter={classes.fieldLabelOuter}
                  label="Email footer"
                  labelSize={3}
                  contentSize={9}
                  content={
                    <Field
                      id="footer"
                      name="footer"
                      inputView="text"
                      styleType="main"
                      fullWidth
                      component={Input}
                      disabled={loading || readOnlyMode}
                    />
                  }
                />
              </div>
              <div className={classes.buttonWrapper}>
                {readOnlyMode || (
                  <ReusableButton
                    size="md"
                    viewType="black"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    label="Update"
                  />
                )}
              </div>
            </form>
          )}
        />
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default TenantSettings;
