import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { getVerificationSettings } from '@services/requests/verificationSettings';

export const ThunkGetVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getVerificationSettings`,
  async () => {
    const res = await getVerificationSettings();
    return res;
  },
);

export default {};
