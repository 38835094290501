import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateAutotaskTicket } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkUpdateAutotaskTicket = createAsyncThunk(
  `${CRM_TICKET}/updateAutotaskTicket`,
  async ({ crmId, payload }) => {
    const ticket = await updateAutotaskTicket(crmId, payload);
    return ticket;
  },
);

export default {};
