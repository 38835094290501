import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntegrationResources } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetIntegrationResources = createAsyncThunk(
  `${INTEGRATION}/getIntegrationResources`,
  async ({ id, type }) => {
    const resources = await getIntegrationResources(id, type);
    return resources;
  },
);

export default {};
