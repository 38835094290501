import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TextField } from '@material-ui/core';

import Circle from '@common/Circle/Circle';
import useStyles from '../styles';

const CircleSelector = ({
  bgColor,
  setBgColor,
  borderColor,
  setBorderColor,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.colorPicker}>
        <div className={classes.colorBlock}>Base Color:</div>
        <div className={classes.colorBlock}>
          <TextField
            type="color"
            onChange={e => setBgColor(e.target.value)}
            className={classes.colorPicketInput}
            value={bgColor}
            InputProps={{ disableUnderline: true }}
          />
        </div>
        <div className={classes.colorBlock}>Border Color:</div>
        <div className={classes.colorBlock}>
          <TextField
            type="color"
            onChange={e => setBorderColor(e.target.value)}
            className={classes.colorPicketInput}
            value={borderColor}
            InputProps={{ disableUnderline: true }}
          />
        </div>
        <div className={classes.spacingLeft}>
          <Circle bgColor={bgColor} borderColor={borderColor} />
        </div>
      </div>
    </Paper>
  );
};

CircleSelector.propTypes = {
  bgColor: PropTypes.string.isRequired,
  setBgColor: PropTypes.func.isRequired,
  borderColor: PropTypes.string.isRequired,
  setBorderColor: PropTypes.func.isRequired,
};

export default CircleSelector;
