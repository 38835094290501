import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { removeWidgetLogo } from '@services/requests/liveChats';

export const ThunkRemoveWidgetLogo = createAsyncThunk(
  `${LIVE_CHATS}/removeWidgetLogo`,
  ({ resourceId }) => removeWidgetLogo(resourceId),
);

export default {};
