import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import Loading from '@common/Loading/Loading';
import {
  ActionsBlock,
  ButtonsBlock,
  DataViewFields,
  ItemsBlock,
} from '../../common';

import { getDataViewData } from '../../../../helpers';

import useStyles from '../../styles';

const SelectValues = ({ currentView, handleBack, handleSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState([]);
  const [activeField, setActiveField] = useState(null);
  const [fields, setFields] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    if (currentView) {
      setLoading(true);
      getDataViewData(currentView.value)
        .then(d => {
          const fieldMap = Object.keys(d);
          setActiveField(fieldMap[0]);
          setNames(d[fieldMap[0]] ?? []);
          setFilteredNames(d[fieldMap[0]] ?? []);
          setFields(fieldMap);
          setData(d);
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
        .finally(() => setLoading(false));
    }
  }, [currentView]);

  const onCheckBoxClick = (name, checked) => {
    if (checked) {
      const newArr = [...selectedNames].filter(i => i !== name);
      setSelectedNames(newArr);
    } else {
      setSelectedNames([...selectedNames, name]);
    }
  };

  const onFieldClick = field => {
    if (field !== activeField) {
      setActiveField(field);
      setNames(data[field] ?? []);
      setFilteredNames(data[field] ?? []);
      setSelectedNames([]);
      setFilterValue('');
    }
  };

  const onFilterChange = event => {
    const { value } = event.target;
    setFilterValue(value);
    if (value) {
      setFilteredNames(
        names.filter(i => i.toLowerCase().includes(value.toLowerCase())),
      );
    } else setFilteredNames(names);
  };

  const onFilterClear = () => {
    setFilterValue('');
    setFilteredNames(names);
  };

  if (loading) return <Loading classNameWrapper={classes.loading} />;

  return (
    <div className={classes.selectorLayout}>
      <div>
        <DataViewFields
          fields={fields}
          activeField={activeField}
          onClick={onFieldClick}
        />
        <ActionsBlock
          names={filteredNames}
          filterValue={filterValue}
          onFilterClear={onFilterClear}
          onFilterChange={onFilterChange}
          setSelectedNames={setSelectedNames}
        />
        <ItemsBlock
          names={filteredNames}
          selectedNames={selectedNames}
          onCheckBoxClick={onCheckBoxClick}
        />
      </div>
      <ButtonsBlock
        loading={loading}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        selectedNames={selectedNames}
      />
    </div>
  );
};

SelectValues.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  currentView: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default SelectValues;
