import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { createLiveChatConfigurations } from '@services/requests/liveChats';

export const ThunkCreateLiveChatConfigurations = createAsyncThunk(
  `${LIVE_CHATS}/createLiveChatConfigurations`,
  ({ payload }) => createLiveChatConfigurations(payload),
);

export default {};
