import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  actionButton: {
    minWidth: 'auto',
  },
  editButton: {
    padding: '0.3125rem',
    transition: '0.3s',
    minWidth: 0,
    color: 'transparent',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
