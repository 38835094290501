import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ThunkDeleteConnectWiseTicketTimeEntry,
  ThunkGetConnectWiseTicketTimeEntries,
} from '@store/slices/connectWiseTicket/thunks';
import { ConnectWiseTicketTimeEntrySelector } from '@store/slices/connectWiseTicket';
import getFormattedDate from '@utils/getFormattedDate';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';
import SimpleTable from '@ui/components/Triggers/pages/Incident/components/SimpleTable';
import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import AddCWTicketTimeEntryDialog from '../dialogs/AddCWTimeEntry';

import useStyles from '../sharedStyles';

const CWTimeEntries = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const [
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
  ] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    {
      label: 'Company',
      value: 'company',
    },
    {
      label: 'Member',
      value: 'member',
    },
    {
      label: 'Type',
      value: 'workType',
    },
    {
      label: 'Role',
      value: 'workRole',
    },
    {
      label: 'Start Time',
      handler: item => getFormattedDate(item?.timeStart),
    },
    {
      label: 'End Time',
      handler: item => getFormattedDate(item?.timeEnd),
    },
    {
      label: 'Hours',
      value: 'actualHours',
    },
    {
      label: 'Action',
      handler: item => (
        <div>
          <ActionFieldItem
            icon={EditIcon}
            alt="Edit"
            handler={() => {
              setSelectedItem(item);
              setOpenAddTimeEntryDialog(true);
            }}
            toolTip="Edit"
          />

          <ActionFieldItem
            icon={DeleteIcon}
            alt="Delete"
            handler={() => {
              setSelectedItem(item);
              setOpenDeleteConfirmationDialog(true);
            }}
            toolTip="Delete"
          />
        </div>
      ),
    },
  ];

  const handleDeleteItem = async () => {
    setProcessing(true);
    await dispatch(
      ThunkDeleteConnectWiseTicketTimeEntry({
        timeEntryId: selectedItem.id,
        crmId: match.params.crmDefaultId,
      }),
    );
    setSelectedItem(undefined);
    setProcessing(false);
    setOpenDeleteConfirmationDialog(false);
  };

  const onMount = useCallback(async () => {
    if (match.params.crmDefaultId && match.params.ticketId) {
      setLoading(true);
      await dispatch(
        ThunkGetConnectWiseTicketTimeEntries({
          crmId: match.params.crmDefaultId,
          ticketId: match.params.ticketId,
        }),
      );
      setLoading(false);
    }
  }, [dispatch, match.params.crmDefaultId, match.params.ticketId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.containerActions}>
        <ReusableButton
          label="Add Time Entry"
          onClick={() => {
            setOpenAddTimeEntryDialog(true);
          }}
        />
      </div>
      <SimpleTable
        selector={ConnectWiseTicketTimeEntrySelector}
        columns={columns}
      />

      {openAddTimeEntryDialog && (
        <AddCWTicketTimeEntryDialog
          open={openAddTimeEntryDialog}
          setOpen={setOpenAddTimeEntryDialog}
          crmDefaultId={match.params.crmDefaultId}
          ticketId={match.params.ticketId}
          timeEntryId={selectedItem?.id}
          setSelectedItem={setSelectedItem}
        />
      )}

      {openDeleteConfirmationDialog && (
        <ConfirmDialog
          open={openDeleteConfirmationDialog}
          setOpen={setOpenDeleteConfirmationDialog}
          onCancel={() => {
            setOpenDeleteConfirmationDialog(false);
            setSelectedItem(undefined);
          }}
          title="Confirm action"
          content="Are you sure you want to delete this item?"
          onAccept={() => {
            handleDeleteItem();
          }}
          loading={processing}
        />
      )}
    </div>
  );
};

CWTimeEntries.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      crmDefaultId: PropTypes.string,
      ticketId: PropTypes.string,
    }),
  }),
};

export default CWTimeEntries;
