import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ReusableButton from '@common/Button/Button';
import PlusIcon from '@assets/icons/btnPlus.svg';

import useStyles from './styles';

const CreateButton = ({ label, onClick, classNameWrapper, ...rest }) => {
  const classes = useStyles();

  return (
    <ReusableButton
      onClick={onClick}
      classNameWrapper={cx(classes.btnWrapper, classNameWrapper)}
      {...rest}
    >
      <>
        <img src={PlusIcon} alt="icon" />
        {label}
      </>
    </ReusableButton>
  );
};

CreateButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  classNameWrapper: PropTypes.string,
};

export default CreateButton;
