import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import useStyles from './styles';
import useCombinedRefs from '../hooks/useCombineRefs';

const DraggableHeader = ({ onColumnsReorder, column, children }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }) {
      onColumnsReorder(key, column.key);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const classes = useStyles({ isOver, isDragging });

  return (
    <div ref={useCombinedRefs(drag, drop)} className={classes.dragContainer}>
      {children}
    </div>
  );
};

DraggableHeader.propTypes = {
  column: PropTypes.objectOf(PropTypes.any),
  onColumnsReorder: PropTypes.func,
  children: PropTypes.node,
};

export default DraggableHeader;
