import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { assignResourceToClientPortalChat } from '@services/requests/clientPortal';

export const ThunkAssignResourceToClientPortalChat = createAsyncThunk(
  `${CLIENT_PORTALS}/assignResourceToClientPortalChat`,
  ({ id, assigneeId }) => assignResourceToClientPortalChat(id, assigneeId),
);

export default {};
