import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconEdit from '@assets/icons/edit.svg';

import useStyles from './styles';

const LettersActions = ({ record, handleUpdateClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.containerLetter}>
      <ActionButton
        icon={<img src={iconEdit} alt="iconEdit" />}
        handler={() => handleUpdateClick(record)}
      />
    </div>
  );
};

LettersActions.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleUpdateClick: PropTypes.func.isRequired,
};

export default LettersActions;
