import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const HyperlinkAccessor = ({ href, value }) => {
  return (
    <Link
      target="_blank"
      href={href}
      onClick={e => e.stopPropagation()}
      title={value}
    >
      {value}
    </Link>
  );
};

HyperlinkAccessor.propTypes = {
  href: PropTypes.string,
  value: PropTypes.string,
};

export default HyperlinkAccessor;
