import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: '90vh',
    width: '100%',
    overflow: 'auto',
    fontStyle: 'normal',
    marginTop: '15px',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },
}));

export default useStyles;
