import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getPsaContacts } from '@services/requests/createTicket';

export const ThunkGetPsaContacts = createAsyncThunk(
  `${CREATE_TICKET}/getPsaContacts`,
  ({ psaId, companyId = null, sortAsc = false }) =>
    getPsaContacts(psaId, companyId, sortAsc),
);

export default {};
