import { createSelector } from '@reduxjs/toolkit';

export const dashboardChangedLayouts = ({ dashboard }) =>
  dashboard.changedLayouts;

export const dashboardCurrentTiles = createSelector(
  state => state.dashboard.currentDashboardTiles,
  state => state.dashboard.dashboardTilesMap,
  (tiles, tilesMap) => tiles.map(id => tilesMap[id]),
);

export const dashboardOptions = ({ dashboard }) => dashboard.dashboardList;

export const dashboardDataViewOptions = ({ dashboard }) =>
  dashboard.dataViewOptions;

export const dashboardItemData = itemId => ({ dashboard }) =>
  dashboard.dashboardTilesItemMap[itemId];
