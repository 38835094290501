import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  colorPickerField: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '0.5rem',
    alignItems: 'center',
  },
  colorPicker: {
    '&.material-ui-rc-color-picker-panel': {
      paddingBottom: theme.spacing(1),
      zIndex: 9999,
      backgroundColor: theme.palette.common.white,
      border: '1px solid grey',
    },
  },
}));

export default useStyles;
