import React, { useState } from 'react';
import { useListContext } from 'react-admin';
import { useSelector } from 'react-redux';
import useStyles from '@components/Billing/SmsLogs/styles';
import { crmOptions } from '@store/selectors/crmUsers';
import useAsyncDispatch from '@services/api/common/useAsyncDispatch';
import { actions } from '@store/actions';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import { getCrmOptions } from './helpers';

const CrmPanel = () => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const options = useSelector(crmOptions);
  const loadData = useAsyncDispatch(getCrmOptions, actions.crmUsers_setOptions);
  const { setFilters, filterValues } = useListContext();

  const handleChange = item => {
    setValue(item);
    setFilters({ q: +item?.value }, filterValues, true);
  };

  return (
    <div className={cx(classes.border, classes.displayFlex)}>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <ViewAutoComplete
              options={options}
              onChange={handleChange}
              currentView={value}
              textFieldLabel="Select PSA"
              loading={loadData}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CrmPanel;
