import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMessagingChannelFromTicket } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkCreateMessagingChannelFromTicket = createAsyncThunk(
  `${CRM_TICKET}/createMessagingChannelFromTicket`,
  async ({ crmId, payload }) => {
    const channel = await createMessagingChannelFromTicket(crmId, payload);
    return channel;
  },
);

export default {};
