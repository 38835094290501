import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    height: '83vh',
    margin: '20px',
    borderRadius: '15px',
    padding: '20px',
    background: 'white',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '10px 0px',
  },
  tableActionContainer: {
    width: '25%',
    margin: '5px 26px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkBoxesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export default useStyles;
