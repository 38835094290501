import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendSms } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkSendSms = createAsyncThunk(
  `${MESSAGING}/sendSms`,
  async payload => {
    await sendSms(payload);
  },
);

export default {};
