import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPhoneNumbers } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkGetAllPhoneNumbers = createAsyncThunk(
  `${MESSAGING}/getAllPhoneNumbers`,
  async () => {
    const phones = await getPhoneNumbers();
    return phones;
  },
);

export default {};
