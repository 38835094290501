import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';
import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import FormSwitch from '@common/FilterInputs/Switch';
import Paper from '@common/Paper/Paper';
import { notificationDataViewSelector } from '@store/selectors/notifications';
import { actions } from '@store/actions';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components/Hooks/useDisabled';
import useTicketingSettings from '@components/Ticketing/Notifications/hooks/useTicketingSettings';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import {
  InputWithTemplate,
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import { getTicketSettings, postTicketSettings } from '../helpers';
import useTicketSetting from '../../hooks/useTicketSetting';
import { handleDoWChange } from '../../utils';
import useStyles from './styles';

const TicketingSettings = ({ match }) => {
  const classes = useStyles();
  const dataViewId = useSelector(notificationDataViewSelector);
  const [companyFieldNameOptions, setCompanyFieldNameOptions] = useState([]);

  const paramsId = match.params.id;
  const {
    submit,
    loading,
    handleBack,
    data,
    setData,
    isCreate,
  } = useTicketSetting({
    paramsId: match.params.id,
    action: actions.saveTicketSetting,
    tileKey: 'isTicket',
    preventOnGettingSetting: true,
    apiPostRequest: postTicketSettings,
    apiGetSetting: getTicketSettings,
  });

  const {
    defaultsOptions,
    crmOptions,
    getOpenDescription,
    handleCrmChanged,
    allDefaults,
  } = useTicketingSettings({
    paramsId,
    setData,
    isCreate,
    getDetailsFunc: getTicketSettings,
  });

  const { templateVariables } = useTemplateVariables({
    paramsId: match.params.id,
    dataViewId,
  });

  useEffect(() => {
    const options = templateVariables.map(i => ({
      label: i.label,
      value: i.value.substring(2, i.value.length - 1),
    }));
    setCompanyFieldNameOptions(options);
  }, [templateVariables]);

  const onDoWChange = handleDoWChange(setData);

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined' && !!allDefaults;

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <div className={classes.contentFormWrapper}>
              <FieldWrapper
                label={
                  values.ticketOperation ? 'Close Ticket' : 'Create Ticket'
                }
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <Field
                      id="ticketOperation"
                      name="ticketOperation"
                      type="checkbox"
                      component={FormSwitch}
                      disabled={disabled}
                      viewType="selector"
                    />
                    <span className={classes.openTextDecorator}>
                      {getOpenDescription(values.ticketOperation)}
                    </span>
                  </div>
                }
              />
              <FieldWrapper
                label="PSA"
                labelSize={3}
                content={
                  <div>
                    <Field
                      id="crm"
                      name="crm"
                      component={DropDown}
                      options={crmOptions}
                      disabled={disabled}
                    />
                    <OnChange name="crm">
                      {value => {
                        handleCrmChanged(value);
                      }}
                    </OnChange>
                  </div>
                }
              />
              <FieldWrapper
                label="PSA Defaults"
                labelSize={3}
                content={
                  <Field
                    id="crmTicketDefaultId"
                    name="crmTicketDefaultId"
                    component={DropDown}
                    options={defaultsOptions}
                    disabled={disabled || !defaultsOptions.length}
                  />
                }
              />
              {!values.ticketOperation && (
                <>
                  <FieldWrapper
                    label="Ticket Subject"
                    labelSize={3}
                    content={
                      <InputWithTemplate
                        name="ticketSubject"
                        disabled={disabled}
                        form={form}
                        templateVariables={templateVariables}
                      />
                    }
                  />
                  <FieldWrapper
                    label="Company name field"
                    labelSize={3}
                    infoText='Select field which will provide company name for ticket. Do not forget to assign <a href="#/localCompany">company to PSA companies</a>'
                    content={
                      <Field
                        id="companyNameField"
                        name="companyNameField"
                        options={companyFieldNameOptions}
                        component={DropDown}
                        disabled={disabled}
                      />
                    }
                  />
                  <FieldWrapper
                    label="Body"
                    labelSize={3}
                    content={
                      <InputWithTemplate
                        name="body"
                        disabled={disabled}
                        form={form}
                        templateVariables={templateVariables}
                        multiline
                        variant="outlined"
                      />
                    }
                  />
                </>
              )}
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

TicketingSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default TicketingSettings;
