import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '@components/Ticketing/Notifications/TicketSettings/components/styles';
import { useSelector } from 'react-redux';
import { teamsSettings } from '@store/selectors/notifications';
import Loading from '@common/Loading/Loading';
import useDisabled from '@components/Hooks/useDisabled';
import { basePermissions } from '@constants/permissions';
import { actions } from '@store/actions';
import Paper from '@common/Paper/Paper';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import iconArrow from '@assets/icons/arrowGrey.svg';
import TestArea from '@common/TestArea/TestArea';
import HelpBox from '@common/HelpBox/HelpBox';
import help from '@constants/help';
import useTest from '@components/Hooks/useTest';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import { getTemplates } from '@components/Ticketing/Notifications/DmSettings/helpers';
import iconPlus from '@assets/icons/plusGrey.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import { teamSettingsValidator } from '../../validators';
import useTicketSetting from '../../hooks/useTicketSetting';
import { getTeamsSettings, postTeamsSettings, testTeams } from './helpers';

const TeamsSettings = ({ match }) => {
  const classes = useStyles();

  const [inputName, setInputName] = useState('');

  const storedData = useSelector(teamsSettings);

  const {
    submit,
    loading,
    setLoading,
    handleBack,
    data,
    anchorEl,
    setAnchorEl,
  } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    tileKey: 'isTeams',
    action: actions.saveTeamsSetting,
    apiPostRequest: postTeamsSettings,
    apiGetSetting: getTeamsSettings,
  });

  const { templateVariables } = useTemplateVariables({ func: getTemplates });

  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testTeams,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  const openVariablesClick = (target, name) => {
    setAnchorEl(target);
    setInputName(name);
  };

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={teamSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.contentFormWrapper}>
              <div className={classes.header}>
                <span>Please, create app before integration</span>
                <HelpBox resource={help.teamsCreateApp} />
              </div>
              <FieldWrapper
                label="Url"
                labelSize={3}
                content={
                  <Field
                    id="url"
                    name="url"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Template"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <ActionButton
                      handler={event =>
                        openVariablesClick(event.currentTarget, 'template')
                      }
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={disabled}
                    />
                    <Field
                      id="template"
                      name="template"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={disabled}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label="Recover Template"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <ActionButton
                      handler={event =>
                        openVariablesClick(
                          event.currentTarget,
                          'recoverTemplate',
                        )
                      }
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={disabled}
                    />
                    <Field
                      id="template"
                      name="recoverTemplate"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={disabled}
                    />
                  </div>
                }
              />

              <TestArea
                onClick={() =>
                  handleTestClick({ url: form.getFieldState('url').value })
                }
                loading={loading}
                testOutput={testOutput}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  Back
                </>
              </ReusableButton>
              {editMode && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                  label="Update"
                />
              )}
            </div>
            <TemplateMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              form={form}
              name={inputName}
              templateVariables={templateVariables}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

TeamsSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default TeamsSettings;
