import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';

import DateTimePicker from '@common/DateTimePicker/DateTimePicker';
import { TypeLabelValue } from '@common/propTypes/common';
import FormMultiselect from '@components/FormMultiselect';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@components/Auth/Common/Input';

import { haloRequiredFieldsType } from '../haloDefaultsForm';
import crmDefaultFields from '../../updateCrm/CrmDefaults/constants';

import useStyles from '../../updateCrm/styles';

const HaloRequiredFields = ({
  requiredFields,
  loading,
  crmCategories,
  crmImpacts,
  crmUrgency,
}) => {
  const classes = useStyles();

  const content = useCallback(
    field => {
      switch (field.field) {
        case haloRequiredFieldsType.category:
          return (
            <div>
              <Typography className={classes.inputLabel}>
                Ticket Category:
              </Typography>
              <Field
                id={crmDefaultFields.category}
                name={crmDefaultFields.category}
                size="small"
                component={DropDown}
                allowEmpty
                options={crmCategories}
                disabled={loading}
              />
            </div>
          );
        case haloRequiredFieldsType.impact:
          return (
            <div>
              <Typography className={classes.inputLabel}>
                Ticket Impact:
              </Typography>
              <Field
                id={crmDefaultFields.impact}
                name={crmDefaultFields.impact}
                size="small"
                labelName="name"
                valueName="id"
                component={DropDown}
                allowEmpty
                options={crmImpacts}
                disabled={loading}
              />
            </div>
          );
        case haloRequiredFieldsType.urgency:
          return (
            <div>
              <Typography className={classes.inputLabel}>
                Ticket Urgency:
              </Typography>
              <Field
                id={crmDefaultFields.urgency}
                name={crmDefaultFields.urgency}
                size="small"
                component={DropDown}
                allowEmpty
                labelName="name"
                valueName="id"
                options={crmUrgency}
                disabled={loading}
              />
            </div>
          );
        case haloRequiredFieldsType.text:
          return (
            <div>
              <Typography className={classes.inputLabel}>
                {field.label}
              </Typography>
              <Field
                id={field.name}
                name={`crmSpecificSettings.${field.name}`}
                component={Input}
                placeholder={field.label}
                fullWidth
                disabled={loading}
              />
            </div>
          );
        case haloRequiredFieldsType.date:
          return (
            <div className={classes.dateWrapper}>
              <Typography className={classes.inputLabel}>
                {field.label}
              </Typography>
              <Field
                name={`crmSpecificSettings.${field.name}`}
                id={field.name}
                showLabel
                type="date"
                component={Input}
              />
            </div>
          );
        case haloRequiredFieldsType.groupFields:
          return (
            <>{field.groupFields.map(groupField => content(groupField))}</>
          );
        case haloRequiredFieldsType.customTextArea:
          return (
            <div>
              <Typography className={classes.inputLabel}>
                {field.label}
              </Typography>
              <Field
                name={`crmSpecificSettings.${field.name}`}
                id={field.name}
                component={Input}
                multiline
                minRows={4}
                maxRows={4}
              />
            </div>
          );
        case haloRequiredFieldsType.customMultiSelect:
          return (
            <div className={classes.multiSelect}>
              <Typography className={classes.inputLabel}>
                {field.label}
              </Typography>
              <Field
                name={`crmSpecificSettings.${field.name}`}
                id={field.name}
                showLabel
                component={FormMultiselect}
                options={field.values}
              />
            </div>
          );
        case haloRequiredFieldsType.deadline:
          return (
            <Field
              name={`crmSpecificSettings.${field.name}`}
              id={field.name}
              label={field.label}
              component={DateTimePicker}
            />
          );
        default:
          return <></>;
      }
    },
    [classes, crmCategories, crmImpacts, crmUrgency, loading],
  );

  return <>{requiredFields.map(field => content(field))}</>;
};

HaloRequiredFields.propTypes = {
  requiredFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.number,
      label: PropTypes.string,
      name: PropTypes.string,
      values: PropTypes.arrayOf(TypeLabelValue),
    }),
  ),
  loading: PropTypes.bool,
  crmCategories: PropTypes.arrayOf(TypeLabelValue),
  crmImpacts: PropTypes.arrayOf(TypeLabelValue),
  crmUrgency: PropTypes.arrayOf(TypeLabelValue),
};
export default HaloRequiredFields;
