import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { addClientPortalConfigurations } from '@services/requests/clientPortal';

export const ThunkAddClientPortalConfigurations = createAsyncThunk(
  `${CLIENT_PORTALS}/addClientPortalConfigurations`,
  ({ payload }) => addClientPortalConfigurations(payload),
);

export default {};
