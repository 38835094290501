import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import { useSelector } from 'react-redux';
import { dashboardOptions } from '@store/selectors/dashboard';
import useStyles from './styles';

const getNextPrev = (arr, item) => {
  if (!arr.length || arr.length < 2) {
    return { nextId: 0, prevId: 0 };
  }
  const idx = arr.findIndex(i => i === item);
  return {
    nextId: idx === arr.length - 1 ? arr[0] : arr[idx + 1],
    prevId: idx === 0 ? arr[arr.length - 1] : arr[idx - 1],
  };
};

const autoCompleteOptions = [
  { value: null, label: 'off' },
  { value: 10000, label: '10 sec' },
  { value: 30000, label: '30 sec' },
  { value: 60000, label: '1 min' },
];

const NavBar = ({ dashboardId, setDashboardId }) => {
  const classes = useStyles();
  const options = useSelector(dashboardOptions);
  const [timeoutValue, setTimeoutValue] = useState(autoCompleteOptions[0]);
  const { name, enabled, next, prev } = useMemo(() => {
    const currentName = options.find(i => i.id === dashboardId)?.name ?? '';
    const enableNav = options.length && options.length > 1;
    const ids = options.map(i => i.id);
    const { nextId, prevId } = getNextPrev(ids, dashboardId);
    return {
      name: currentName,
      enabled: enableNav,
      next: nextId,
      prev: prevId,
    };
  }, [dashboardId, options]);

  useEffect(() => {
    const timer =
      timeoutValue?.value &&
      setTimeout(() => setDashboardId(next), timeoutValue.value);
    return () => clearTimeout(timer);
  }, [next, setDashboardId, timeoutValue]);

  const handleChange = item => {
    if (!item) setTimeoutValue(autoCompleteOptions[0]);
    setTimeoutValue(item);
  };

  return (
    <div className={classes.container}>
      <div className={classes.navBar}>
        <IconButton
          aria-label="prev"
          disabled={!enabled}
          onClick={() => setDashboardId(prev)}
        >
          <ArrowBackIosIcon color="disabled" />
        </IconButton>
        <div className={classes.name}>{name}</div>
        <IconButton
          aria-label="forward"
          disabled={!enabled}
          onClick={() => setDashboardId(next)}
        >
          <ArrowForwardIosIcon color="disabled" />
        </IconButton>
      </div>
      <div className={classes.autoComplete}>
        <ViewAutoComplete
          options={autoCompleteOptions}
          onChange={handleChange}
          currentView={timeoutValue}
          textFieldLabel="Auto scroll"
          disablePortal
        />
      </div>
    </div>
  );
};

NavBar.propTypes = {
  dashboardId: PropTypes.number,
  setDashboardId: PropTypes.func.isRequired,
};

export default NavBar;
