import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketScheduleEntries } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketScheduleEntries = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketScheduleEntries`,
  async ({ crmId, ticketId }) => {
    const scheduleEntries = await getConnectWiseTicketScheduleEntries(
      crmId,
      ticketId,
    );

    return scheduleEntries;
  },
);

export default {};
