import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import iconCopy from '@assets/icons/copyGrey.svg';
import useStyles from './styles';

const CopyToClipboard = ({ text, onCopy }) => {
  const classes = useStyles();

  const handleCopyAction = () => {
    if (onCopy) {
      onCopy(text);
    }
  };

  return (
    <Copy text={text} onCopy={handleCopyAction}>
      <img
        src={iconCopy}
        alt="iconCopy"
        className={classes.codeBlockCopyButton}
      />
    </Copy>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};

export default CopyToClipboard;
