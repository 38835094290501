import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: '0.5rem',
    },
  },
  selectWrapper: {
    border: 'none',
    margin: '0 5px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 2rem',
    },
    minWidth: '200px',
    '& .MuiInputLabel-shrink': {
      background: 'none',
      fontWeight: 'bold',
      color: theme.palette.textColor,
    },
  },
  rootAutocomplete: {
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#999999',
        borderWidth: '1px',
      },
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-1.25rem',
      marginLeft: '14px',
      marginRight: '14px',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#999999',
      },
    },
  },
}));

export default useStyles;
