import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
  ThunkGetSmsCampaignGroup,
  ThunkGetSmsCampaignGroups,
} from '@store/slices/smsCampaigns/thunks';
import { requiredValidator } from '@utils/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@common/Loading/Loading';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { SMS_CAMPAIGN_SOURCES } from '@constants/smsCampaign';

const ImportFromSmsGroup = ({ open, onClose, onAdd }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [smsGroups, setSmsGroups] = useState([]);

  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();

  const validate = values => {
    return {
      smsGroupId: requiredValidator(values.smsGroupId),
    };
  };

  const getSourceType = typeId => {
    switch (typeId) {
      case 0:
        return SMS_CAMPAIGN_SOURCES.MANUAL;
      case 1:
        return SMS_CAMPAIGN_SOURCES.PSA;
      case 2:
        return SMS_CAMPAIGN_SOURCES.Tenant;
      default:
        return SMS_CAMPAIGN_SOURCES.MANUAL;
    }
  };

  const submit = ({ smsGroupId }) => {
    setProcessing(true);

    dispatch(ThunkGetSmsCampaignGroup({ smsGroupId }))
      .unwrap()
      .then(res => {
        onAdd(
          res.items.map(i => ({
            name: i.fullName,
            phone: i.phoneNumber,
            id: i.id,
            type: getSourceType(i.sourceType),
          })),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetSmsCampaignGroups())
      .unwrap()
      .then(res => {
        setSmsGroups(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import from Messaging Group</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading classNameWrapper={sharedStyles.loading} />
        ) : (
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Messaging Group"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="smsGroupId"
                      name="smsGroupId"
                      component={DropDown}
                      options={smsGroups}
                      labelName="name"
                      valueName="id"
                      disabled={processing}
                    />
                  }
                />

                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={onClose}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Submit"
                    type="submit"
                    viewType="blue"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

ImportFromSmsGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default ImportFromSmsGroup;
