import React, { useLayoutEffect } from 'react';
import { useRedirect } from 'react-admin';

const NotificationsWrapper = () => {
  const redirect = useRedirect();
  useLayoutEffect(() => {
    redirect('/notifications/data');
  }, [redirect]);

  return <></>;
};

export default NotificationsWrapper;
