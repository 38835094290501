import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkAddSmsCampaignGroup } from '@store/slices/smsCampaigns/thunks';

import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';

import { requiredValidator } from '@utils/validators';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const CreateNewSmsGroup = ({ open, onClose, items }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();

  const submit = ({ name }) => {
    setProcessing(true);
    dispatch(
      ThunkAddSmsCampaignGroup({
        name,
        items: items.map(i => ({ fullName: i.name, phoneNumber: i.phone })),
      }),
    )
      .unwrap()
      .then(() => {
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({ name: requiredValidator(values.name) });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Messaging Group</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Group Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={onClose}
                  disabled={processing}
                />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateNewSmsGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
  ),
};
export default CreateNewSmsGroup;
