import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import appConfig from '@configs/appConfig';
import { SignalREvents } from '@constants/events';
import { SignalRContext } from '../contexts/signalR';

const SignalRProvider = ({ children }) => {
  const [methods, setMethods] = useState([]);

  const user = useSelector(state => state.user.currentUser);

  const signalRConnection = useMemo(
    () =>
      (!!appConfig.baseUrl &&
        new HubConnectionBuilder()
          .withUrl(appConfig.baseUrl?.concat('/hub'))
          // .withAutomaticReconnect()
          .build()) ||
      undefined,
    [],
  );

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.start();
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.stop();
      }
    };
  }, [signalRConnection]);

  /**
   * @description Use effect to handle tenant subscription
   */
  useEffect(() => {
    if (signalRConnection && user?.tenantId) {
      const interval = setInterval(() => {
        console.log(signalRConnection.state);

        // If the SignalR connection state is "Connected", send TenantSubscription
        if (signalRConnection.state === HubConnectionState.Connected) {
          clearInterval(interval);
          signalRConnection.send(
            SignalREvents.TenantSubscription,
            user.tenantId,
          );
        }
      }, 1000);

      setTimeout(() => {
        if (signalRConnection.state !== HubConnectionState.Connected) {
          console.log(
            'Error while connection to resource. Please reload this page',
          );
        }

        if (!user.tenantId) {
          console.log('tenant id was not provided properly', user.tenantId);
        }
      }, 4000);

      setTimeout(() => {
        if (signalRConnection.state !== HubConnectionState.Connected) {
        }
      }, 5000);

      const connectionCheck = setInterval(() => {
        if (
          signalRConnection.state === HubConnectionState.Connected &&
          user.tenantId
        ) {
          clearInterval(connectionCheck);
        }
      }, 1000);
    }
  }, [signalRConnection, user?.tenantId, user]);

  return (
    <SignalRContext.Provider
      value={{
        connection: signalRConnection,
        methods,
        setMethods: arg => {
          setMethods([...methods, arg]);
        },
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
