import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Checkbox from '@common/Checkbox/Checkbox';
import FieldWrapper from '@common/form/FieldWrapper';

const CheckBoxWrapper = ({ formSet, id, label, processing }) => {
  return (
    <FieldWrapper
      label={label}
      labelSize={formSet.labelSize}
      contentSize={formSet.inputSize}
      fullWidth
      content={
        <Field
          name={id}
          id={id}
          render={Checkbox}
          type="checkbox"
          disabled={processing}
        />
      }
    />
  );
};

CheckBoxWrapper.propTypes = {
  formSet: PropTypes.shape({
    labelSize: PropTypes.number.isRequired,
    inputSize: PropTypes.number.isRequired,
  }),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  processing: PropTypes.bool,
};

export default CheckBoxWrapper;
