import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel } from '@material-ui/core';

import DropDown from '@components/Auth/Common/DropDown';
import useStyles from './styles';

const defaultLastResultField = {
  label: 'Email received date',
  value: '__time',
};

const LastResultField = ({ options, value, onChange }) => {
  const classes = useStyles();

  options.unshift(defaultLastResultField);
  const enhancedValue = value || defaultLastResultField.value;
  return (
    <div>
      <InputLabel id="last-result-select">Last Result Order Field</InputLabel>
      <DropDown
        options={options}
        id="last-result-select"
        labelId="last-result-select"
        classNameWrapper={classes.lastResult_select}
        input={{
          value: enhancedValue,
          onChange,
        }}
      />
    </div>
  );
};

LastResultField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LastResultField;
