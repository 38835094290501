import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import CustomRadio from '@common/Radio/Radio';
import afterSubmitActions from '@constants/afterSubmitActions';

import useStyles from './styles';

const AfterSubmitActions = ({ value, setValue }) => {
  const classes = useStyles();

  return (
    <div className={classes.spacing}>
      <FormControl component="fieldset">
        <FormLabel component="legend">After submit action</FormLabel>
        <RadioGroup row value={value} onChange={e => setValue(e.target.value)}>
          <FormControlLabel
            value={afterSubmitActions.None}
            control={<CustomRadio />}
            label="None"
          />
          <FormControlLabel
            value={afterSubmitActions.reParseCurrent}
            control={<CustomRadio />}
            label="Reparse Current"
          />
          <FormControlLabel
            value={afterSubmitActions.reParseLast50}
            control={<CustomRadio />}
            label="Reparse last 50"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

AfterSubmitActions.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default AfterSubmitActions;
