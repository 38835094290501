import { createSlice, isAnyOf, isPending } from '@reduxjs/toolkit';
import {
  ThunkGetAccessModules,
  ThunkGetTenantsOptions,
} from '@store/slices/admin/thunks';
import { ThunkGetAccessModulesTemplate } from '@store/slices/admin/thunks/getAccessModulesTemplate';
import { TENANT_MODULES } from '../resources';

const initialState = {
  tenantOptions: [],
  isLoading: false,
  isFormLoading: false,
  accessModules: {},
  accessModulesTemplate: {},
};

const adminSlice = createSlice({
  name: TENANT_MODULES,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ThunkGetTenantsOptions.fulfilled, (state, { payload }) => {
        state.tenantOptions = payload;
        state.isLoading = false;
      })

      .addCase(ThunkGetAccessModules.fulfilled, (state, { payload }) => {
        state.accessModules = payload;
        state.isFormLoading = false;
      })

      .addCase(
        ThunkGetAccessModulesTemplate.fulfilled,
        (state, { payload }) => {
          state.accessModulesTemplate = payload;
          state.isFormLoading = false;
        },
      )

      .addMatcher(isAnyOf(isPending(ThunkGetTenantsOptions)), state => {
        state.isLoading = true;
      })

      .addMatcher(isAnyOf(isPending(ThunkGetAccessModules)), state => {
        state.isFormLoading = true;
      })

      .addMatcher(isAnyOf(isPending(ThunkGetAccessModulesTemplate)), state => {
        state.isFormLoading = true;
      });
  },
});

export default adminSlice.reducer;

export const TenantsOptionsSelector = state =>
  state[TENANT_MODULES].tenantOptions;
export const TenantOptionsLoadingSelector = state =>
  state[TENANT_MODULES].isLoading;
export const AccessModulesSelector = state =>
  state[TENANT_MODULES].accessModules;
export const AccessModulesLoadingSelector = state =>
  state[TENANT_MODULES].isFormLoading;
export const AccessModulesTemplateSelector = state =>
  state[TENANT_MODULES].accessModulesTemplate;
