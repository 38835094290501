import appConfig from '@configs/appConfig';
import getData from '@services/api/common/getData';

export const getMailBoxOptions = () =>
  getData(`${appConfig.baseUrl}/MailBoxes/getAsAutocomplete`);

export const getGenericSourcesOptions = () =>
  getData(`${appConfig.baseUrl}/GenericSources/getAsAutocomplete`);

export const getFieldOptions = () =>
  getData(`${appConfig.baseUrl}/Parsers/fieldTransformers`);

export const getParserOptions = () =>
  getData(`${appConfig.baseUrl}/Parsers/getAsAutocomplete`);

export const getWebHookOptions = () =>
  getData(`${appConfig.baseUrl}/WebHooks/getAsAutocomplete`);
