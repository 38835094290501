import React from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../styles';

const SchedulerItem = ({ event, testEvents = false }) => {
  const classes = useStyles({ color: event.backgroundColor });

  return (
    <div className={classes.scheduleItem}>
      {testEvents
        ? `${event.title}`
        : `[ ${event.extendedProps.userName}]  ${event.title} `}
    </div>
  );
};

SchedulerItem.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
    extendedProps: PropTypes.shape({
      userName: PropTypes.string,
    }),
  }),
  testEvents: PropTypes.bool,
};

export default SchedulerItem;
