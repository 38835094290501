import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmBoardStatuses } from '@services/requests/treeView';
import { TREE_VIEW } from '@store/slices/resources';

export const ThunkGetCrmBoardStatuses = createAsyncThunk(
  `${TREE_VIEW}/getCrmBoardStatuses`,
  async ({ crmId, boardId }) => {
    const statuses = await getCrmBoardStatuses(crmId, boardId);
    return {
      [boardId]: statuses,
    };
  },
);

export default {};
