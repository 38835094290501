import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { deleteClientPortalConfigurations } from '@services/requests/clientPortal';

export const ThunkDeleteClientPortalConfigurations = createAsyncThunk(
  `${CLIENT_PORTALS}/deleteClientPortalConfigurations`,
  ({ id }) => {
    deleteClientPortalConfigurations(id);
    return id;
  },
);

export default {};
