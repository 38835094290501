import React from 'react';
import { Paper as MuiPaper } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useStyles from './styles';

const Paper = ({ classNameBlock, classNameContainer, style, children }) => {
  const classes = useStyles();
  return (
    <MuiPaper
      className={cx(classes.container, classNameContainer)}
      style={style}
    >
      <div className={cx(classes.block, classNameBlock)}>{children}</div>
    </MuiPaper>
  );
};

Paper.propTypes = {
  children: PropTypes.node,
  classNameContainer: PropTypes.string,
  classNameBlock: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
};

export default Paper;
