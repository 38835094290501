import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    padding: '20px',
    borderRadius: '15px',
    '& .MuiTable-root': {
      height: '73vh',
    },
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '1rem 2rem',
  },
  configSelector: {
    width: '25rem',
  },
  selectLanguage: {
    marginLeft: '2.5rem',
    fontFamily: "'Montserrat'",
  },
  actionButton: {
    cursor: 'pointer',
    margin: '0 10px 0 10px',
  },
}));

export default useStyles;
