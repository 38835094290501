import { useEffect, useState } from 'react';

import { debounce } from '@utils/index';
import { sortOrders } from '@constants/filters';

import columnsTable from '@services/columnsTable';

const sanitizeGrouping = filtersState => {
  const { groupBy, ...rest } = filtersState;
  return rest;
};

const getOppositeOrder = (direction, isCurrentFieldSorted) =>
  direction === sortOrders.asc && isCurrentFieldSorted
    ? sortOrders.desc
    : sortOrders.asc;

const useControllerTableProps = props => {
  const { tableKey, groupByValue, data, sort, setSort } = props;

  const [filters, setFilters] = useState({});
  const [displayedFilters, setDisplayedFilters] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);

  const handleColumnResize = (colunmKey, width) => {
    columnsTable.setColumnsTableSize(tableKey, { colunmKey, width });
  };

  const handleColumnResizeDebounce = debounce(handleColumnResize, 500);

  const changeFiltersDebounce = debounce(value => {
    if (!Object.keys(value).length) {
      setFilters(value);
      return;
    }
    setFilters(prev => ({ ...prev, ...value }));
  }, 500);

  const resetSelectedIds = () => setSelectedIds([]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (groupByValue) {
      changeFiltersDebounce({ groupBy: groupByValue });
      return () => setFilters(sanitizeGrouping);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupByValue]);

  const onToggleItem = selectData => {
    if (Array.isArray(selectData) && selectData.length === data.length) {
      const filteredIds =
        selectedIds.length === selectData.length ? [] : selectData;
      setSelectedIds(filteredIds);
    } else {
      const idsForFiltering = Array.isArray(selectData)
        ? selectData
        : [selectData];
      const isExistId = selectedIds.some(o => idsForFiltering.includes(o));

      const updatedIds = isExistId
        ? selectedIds.filter(id => !idsForFiltering.includes(id))
        : [...selectedIds, ...idsForFiltering];
      setSelectedIds(updatedIds);
    }
  };

  const onUnselectItems = () => setSelectedIds([]);

  const onSelect = () => {};

  const setSortCustom = value => {
    const currentDirection = sort.order;
    const isCurrentFieldSorted = sort.field === value;
    setSort({
      field: value,
      order: getOppositeOrder(currentDirection, isCurrentFieldSorted),
    });
  };

  const hideFilter = value => {
    delete filters[value];
    setFilters(filters);

    setDisplayedFilters({ ...displayedFilters, [value]: '' });
  };

  const showFilter = value =>
    setDisplayedFilters({ ...displayedFilters, [value]: 'value' });

  return {
    handleColumnResizeDebounce,
    changeFiltersDebounce,
    onToggleItem,
    onUnselectItems,
    onSelect,
    setSortCustom,
    showFilter,
    hideFilter,
    filters,
    displayedFilters,
    selectedIds,
    resetSelectedIds,
  };
};

export default useControllerTableProps;
