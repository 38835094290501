import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { deleteSmsCampaignGroup } from '@services/requests/smsCampaigns';

export const ThunkDeleteSmsCampaignGroup = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/deleteSmsCampaignGroup`,
  async payload => {
    const data = await deleteSmsCampaignGroup(payload);
    return data;
  },
);

export default {};
