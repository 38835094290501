import React from 'react';
import { useHistory } from 'react-router-dom';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';

import {
  WEB_HOOKS_EDIT,
  WEB_HOOKS_RESULT,
  WEB_HOOKS_RESULT_UNRECOGNIZED,
} from '@constants/routes';

import iconView from '@assets/icons/visibility.svg';
import iconLink from '@assets/icons/link_orange.svg';
import iconPlus from '@assets/icons/plus.svg';

const ActionsField = ({ webHook, webHookId }) => {
  const { push } = useHistory();

  return (
    <>
      <ActionFieldItem
        icon={iconView}
        alt="View"
        handler={() => {
          push(WEB_HOOKS_RESULT.replace(':id', webHook.id));
        }}
        toolTip="View Results"
      />
      <ActionFieldItem
        icon={iconLink}
        alt="Open webhook"
        handler={() => {
          push(WEB_HOOKS_EDIT.replace(':id', webHookId));
        }}
        toolTip="Open webhook"
      />
      {webHook.status === '1' && (
        <ActionFieldItem
          icon={iconPlus}
          alt="Fix errors"
          handler={() => {
            push(
              WEB_HOOKS_RESULT_UNRECOGNIZED.replace(':id', `id=${webHook.id}`),
            );
          }}
          toolTip="Fix errors"
        />
      )}
    </>
  );
};

export default ActionsField;
