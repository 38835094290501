import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { deleteKaseyaTicketNote } from '@services/requests/kaseyaTicket';

export const ThunkDeleteKaseyaTicketNote = createAsyncThunk(
  `${KASEYA_TICKET}/deleteKaseyaTicketNote`,
  async ({ crmDefaultId, ticketId, noteId }) => {
    await deleteKaseyaTicketNote(crmDefaultId, ticketId, noteId);
    return { id: noteId };
  },
);

export default {};
