import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Breadcrumbs, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import {
  getNotificationsBreadcrumbs,
  getNotificationSettingsBreadcrumbs,
  Titles,
} from '@components/Layout/constants';

import useStyles from '@components/Layout/styles';

export const useNavigationBreadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/');
  let lastPath = parts[parts.length - 1];

  // check if lastPath is id
  if (!Number.isNaN(Number(lastPath))) {
    // setting the path with the Id
    lastPath = `${parts[1]}Id`;
  }

  useEffect(() => {
    document.title = Titles[lastPath]?.tabTitleValue
      ? `MSP Process - ${Titles[lastPath].tabTitleValue}`
      : 'MSP Process';
  }, [lastPath]);

  // generate breadcrumbs values for difficult path's

  if (
    parts[1] === 'notificationSettings' ||
    parts[1] === 'notificationSettingsCreate'
  ) {
    lastPath = 'notificationSettings';
    Titles.notificationSettings = getNotificationSettingsBreadcrumbs(parts);
  }

  if (parts[1] === 'notifications') {
    lastPath = 'notifications';
    Titles.notifications = getNotificationsBreadcrumbs(parts);
  }

  const customPath = path.match(/[^/]+/g);
  if (
    `${customPath?.[0]}-${customPath?.[1]}` === 'schedule-group' &&
    customPath.length === 2
  ) {
    lastPath = 'scheduleGroup';
  }

  if (
    `${customPath?.[0]}-${customPath?.[1]}` === 'meraki-dashboard' &&
    customPath.length === 2
  ) {
    lastPath = 'merakiId';
  }

  if (
    `${customPath?.[0]}-${customPath?.[1]}` === 'sophos-dashboard' &&
    customPath.length === 2
  ) {
    lastPath = 'sophosId';
  }

  if (
    `${customPath?.[0]}-${customPath?.[2]}` === 'mailbox-dashboard' &&
    customPath.length === 3
  ) {
    lastPath = 'mailDashBoard';
  }

  if (
    `${customPath?.[0]}-${customPath?.[1]}-${customPath?.[3]}` ===
      'schedule-group-rule' &&
    customPath[customPath.length - 1] !== 'scheduleCreate'
  ) {
    lastPath = 'scheduleEdit';
  }

  if (`${customPath?.[0]}-${customPath?.[2]}` === 'mailbox-letter') {
    lastPath = 'mailboxLetter';
  }

  let breadcrumbs = [];

  if (Titles[lastPath]?.breadcrumbs) {
    breadcrumbs = [...Titles[lastPath].breadcrumbs];
  }

  if (`${customPath?.[0]}-${customPath?.[2]}` === 'mailbox-letter') {
    breadcrumbs[1].value = `#/${customPath[0]}/${customPath[1]}/documents`;
  }

  return useMemo(
    () => (
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        {!!breadcrumbs.length &&
          breadcrumbs.map((breadcrumb, index, array) =>
            // check if is readOnly element
            (index === array.length - 1 || !index || !breadcrumb.value) &&
            !breadcrumb.isLink ? (
              <Typography
                color="textPrimary"
                key={breadcrumb.value}
                className={cx({ [classes.fontWeight700]: !index })}
              >
                {breadcrumb.label}
              </Typography>
            ) : (
              <Link
                key={breadcrumb.value}
                color="inherit"
                href={breadcrumb.value}
              >
                {breadcrumb.label}
              </Link>
            ),
          )}
      </Breadcrumbs>
    ),
    [breadcrumbs, classes, location],
  );
};

export default {};
