import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    '&:last-child': {
      marginLeft: '1.875rem',
    },
  },
  testArea: {
    display: 'flex',
    margin: '1rem 0',
  },
  testOutput: {
    marginLeft: '1rem',
    border: '1px solid #e3e2e2',
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '3px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  testDynamicArea: {
    color: props => (props?.testOutput?.isOk ? 'green' : 'red'),
    justifyContent: 'center',
  },
}));

export default useStyles;
