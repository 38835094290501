export default function(phoneNumber) {
  if (
    !phoneNumber ||
    !phoneNumber.match(/^\+?([\d\-\(\)\s]?)+\d+$/g) ||
    phoneNumber.length < 10
  ) {
    return 'Invalid phone number';
  }

  return undefined;
}
