import getData from '@services/api/common/getData';
import { tenantImage, tenantSettings, timeZones } from '@constants/appRoutes';
import appConfig from '@configs/appConfig';
import postData from '@services/api/common/postData';

export const getTenantSettings = () =>
  getData(`${appConfig.baseUrl}${tenantSettings}`);

export const getTimeZones = () => getData(`${appConfig.baseUrl}${timeZones}`);

export const postTenantSettings = data =>
  postData(`${appConfig.baseUrl.concat(tenantSettings)}`, data);

export const getTenantImage = () =>
  getData(`${appConfig.baseUrl}${tenantImage}`);
