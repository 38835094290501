import React from 'react';
import PropTypes from 'prop-types';
import { AccordionSummary } from '@material-ui/core';
import {
  CheckCircleOutlineRounded,
  RadioButtonUncheckedRounded,
} from '@material-ui/icons';

const AccordionSummaryRequired = ({
  title,
  isChecked,
  hideRequired = false,
}) => {
  return (
    <AccordionSummary>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>{title}</div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            fontSize: '13px',
            color: 'grey',
          }}
        >
          {!hideRequired && <>Required</>}
          {isChecked ? (
            <CheckCircleOutlineRounded />
          ) : (
            <RadioButtonUncheckedRounded />
          )}
        </div>
      </div>
    </AccordionSummary>
  );
};

AccordionSummaryRequired.propTypes = {
  title: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  hideRequired: PropTypes.bool,
};

export default AccordionSummaryRequired;
