import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';

import Switch from '@common/FilterInputs/Switch';
import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../styles';
import { useTableContext } from '../../../../../hooks/table';

const TableActions = React.memo(({ handleAddChannelClick }) => {
  const [skipClosed, setSkipClosed] = useState({ value: true });
  const classes = useStyles();
  const { setFilteringObject, filtering } = useTableContext();
  const handleChange = item => {
    setSkipClosed(item);
    setFilteringObject(filtering, 'isClosed', item?.value ? 'false' : '');
  };
  return (
    <div className={classes.switchWrapper}>
      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: () => {
                handleChange({ value: !skipClosed.value });
              },
              value: skipClosed.value,
            }}
            checked={skipClosed.value}
          />
        }
        label="Skip Closed"
        labelPlacement="start"
      />
      <ReusableButton
        label="Add Channel"
        onClick={() => {
          handleAddChannelClick();
        }}
      />
    </div>
  );
});

TableActions.propTypes = {
  handleAddChannelClick: PropTypes.func,
};

export default TableActions;
