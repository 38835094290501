import React, { useCallback, useEffect, useState } from 'react';
import Loading from '@common/Loading/Loading';

import moment from 'moment';
import cx from 'classnames';
import PurchaseExtraResources from '@components/PricingPlan/components/Usage/components/extraResources/PurchaseExtraResources';
import { getUsage } from '../../../../helpers';
import ProgressBar from '../progressBar';
import useStyles from './styles';

const getPercent = (total, amount) => {
  const percent = (amount * 100) / total;
  const result = percent > 100 ? 100 : percent;
  return Number(result.toFixed(1));
};

const CurrentPlan = () => {
  const [data, setData] = useState({});
  const classes = useStyles(data);

  const [loading, setLoading] = useState(true);
  const onMount = useCallback(() => {
    setLoading(true);
    getUsage()
      .then(res => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const getAvailableSms = number => {
    return (
      <div>
        You can send up to &nbsp;
        <span className={classes.bold}>
          {number}
          &nbsp; SMS
        </span>
        &nbsp; this month
      </div>
    );
  };

  const getNextRest = value => {
    return (
      <span>
        Next rest on the
        <b>
          &nbsp;
          {moment(value).format('MM/D/YY')}
        </b>
      </span>
    );
  };

  const getPeriod = value => {
    if (moment(value).isSame(moment(), 'day')) {
      return 'today';
    }
    return moment
      .duration(moment(value).diff(moment(), 'hour'), 'hours')
      .humanize(true, { d: 100 });
  };

  const dateIsBefore = value => moment(value).isBefore(moment.today);
  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className={cx(classes.font, classes.currentPlan)}>
        CURRENT PLAN
        <span className={classes.bold}>
          {': '.concat(data.planName).concat(' ')}
          PLAN
        </span>
      </div>
      <div className={cx(classes.font, classes.currentPlan)}>
        Subscription status
        <span>:&nbsp;</span>
        <span className={classes.activeBanner}>{data.status}</span>
      </div>
      <div className={`${classes.innerContainer} ${classes.font}`}>
        <div>
          <div className={classes.infoItem}>
            {/* {getAvailableEmails(data.emailsTotal)} */}
            {getAvailableSms(data.smsTotal)}
            {/* {geDevicesLimit(data.devices)} */}
          </div>
          <div className={classes.infoItem}>
            {/* {getCurrentBalance(data.accountBalance)} */}
          </div>
        </div>
        <div>
          <div className={classes.infoItem}>{getNextRest(data.nextRest)}</div>
          <div
            className={`${classes.infoItem} ${
              dateIsBefore(data.nextRest)
                ? classes.redInfoItem
                : classes.grayedInfoItem
            }`}
          >
            {getPeriod(data.nextRest)}
          </div>
        </div>
      </div>
      <div>
        {!data?.isDemo && <PurchaseExtraResources onPurchase={onMount} />}
      </div>

      <div className={classes.progressBar}>
        <ProgressBar
          completed={getPercent(data.smsTotal, data.smsSent)}
          total={data.smsTotal}
          amount={data.smsSent}
          unit="SMS"
        />
      </div>
    </div>
  );
};

export default CurrentPlan;
