import React from 'react';

import ColorPicker from 'material-ui-rc-color-picker';
import useStyles from './formColorPickerFieldStyles';

const FormColorPickerField = ({ input, label, ...props }) => {
  const classes = useStyles();

  const onHandleChange = value => {
    input.onChange(value.color);
  };

  return (
    <div className={classes.colorPickerField}>
      <ColorPicker
        {...input}
        {...props}
        key={input.name}
        enableAlpha={false}
        color={input.value}
        onChange={onHandleChange}
        mode="RGB"
        className={classes.colorPicker}
      />
      <label>{label}</label>
    </div>
  );
};

export default FormColorPickerField;
