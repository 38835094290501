import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_DEFAULT } from '@store/slices/resources';
import { createClientPortalDefault } from '@services/requests/clientPortal';

export const ThunkCreateClientPortalDefault = createAsyncThunk(
  `${CLIENT_PORTAL_DEFAULT}/createClientPortalDefault`,
  ({ payload }) => createClientPortalDefault(payload),
);

export default {};
