import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field } from 'react-final-form';
import Input from '@common/Input/Input';
import { daysOfWeekArray } from '@constants/daysOfWeek';
import StringArraySelector from '@common/form/StringArraySelector/StringArraySelector';

const ScheduledPartOfForm = ({ values, disabled }) => {
  return (
    !values.isScheduled || (
      <div>
        <FieldWrapper
          label="Send time"
          labelSize={3}
          content={
            <div style={{ display: 'flex' }}>
              <Field
                id="hours"
                name="hours"
                placeholder="Hours"
                component={Input}
                styleType="main"
                type="number"
                inputView="number"
                disabled={disabled}
                min={0}
                max={23}
              />
              <Field
                id="minutes"
                name="minutes"
                placeholder="Minutes"
                component={Input}
                styleType="main"
                type="number"
                inputView="number"
                disabled={disabled}
                min={0}
                max={59}
              />
            </div>
          }
        />
        <FieldWrapper
          label="Create ticket after (days)"
          labelSize={3}
          content={
            <Field
              id="days"
              name="days"
              component={Input}
              styleType="main"
              fullWidth
              type="number"
              inputView="number"
              disabled={disabled}
            />
          }
        />
        <FieldWrapper
          label="Days of week"
          alignItems="center"
          labelSize={3}
          content={
            <Field
              id="daysOfWeek"
              name="daysOfWeek"
              itemsList={daysOfWeekArray}
              component={StringArraySelector}
              disabled={disabled}
            />
          }
        />
      </div>
    )
  );
};

ScheduledPartOfForm.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default ScheduledPartOfForm;
