export const defaultCombinators = [
  { name: 'OR', label: 'OR' },
  { name: 'AND', label: 'AND' },
];

export const defaultOperators = [
  { name: 'Equals', label: 'Equals' },
  { name: 'NotEquals', label: "Doesn't equal" },
  { name: 'Contains', label: 'Contains' },
  { name: 'NotContains', label: "Doesn't contain" },
  { name: 'Matches', label: 'Matches regex' },
  { name: 'NotMatches', label: "Doesn't match regex" },
];

export const templateRulesFields = [
  { name: 'TextBody', label: 'Text Body' },
  { name: 'HtmlBody', label: 'Text HTML' },
  { name: 'Subject', label: 'Subject' },
  { name: 'From', label: 'From' },
  { name: 'To', label: 'To' },
];
