import React from 'react';
import PropTypes from 'prop-types';
import { integrationSources } from '@constants/integrationSources';
import CreateCrmCard from './SelectIntegrationCard';
import useStyles from '../formStyles';

const SelectIntegration = ({ handleSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      {Object.entries(integrationSources).map(i => (
        <CreateCrmCard key={i[0]} item={i[1]} handleClick={handleSelect} />
      ))}
    </div>
  );
};

SelectIntegration.propTypes = {
  handleSelect: PropTypes.func.isRequired,
};

export default SelectIntegration;
