import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ControlPoint as ControlPointIcon } from '@material-ui/icons';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { reorder } from '@ui/components/AuditViews/helpers/reorder';
import ReEscalationItem from './ReEscalationItem';

import useStyles from './style';

const ReEscalation = ({
  input: { value, onChange },
  meta: { touched },
  groups,
  users,
}) => {
  const classes = useStyles();

  const items = useMemo(
    () =>
      (value &&
        value.map(item => ({
          id: item.id ? item.id : Math.floor(Math.random() * 10000),
          ...item,
        }))) ||
      [],
    [value],
  );

  const handleAdd = () => {
    onChange([...items, { id: Math.floor(Math.random() * 1000) }]);
  };

  const handleDelete = id => {
    onChange(items.filter(item => item.id !== id));
  };

  const handleChange = item => {
    onChange(items.map(i => (i.id === item.id ? item : i)));
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const newRecord = reorder(items, source.index, destination.index);

    onChange(newRecord);
  };

  return (
    <div>
      <div className={classes.reEscalationControl}>
        <ControlPointIcon alt="add" onClick={handleAdd} />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="reEscalationRules-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((i, index) => (
                <ReEscalationItem
                  key={i.id}
                  item={i}
                  handleDelete={handleDelete}
                  touched={touched}
                  groups={groups}
                  users={users}
                  handleChange={handleChange}
                  index={index}
                />
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

ReEscalation.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

export default ReEscalation;
