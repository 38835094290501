// eslint-disable-next-line import/no-cycle
import { getFileExtension } from './helper';
// eslint-disable-next-line import/no-cycle
import {
  csvTsvType,
  docxType,
  pdfType,
  textType,
  xlsxType,
} from './dataSources';

const create = data => {
  return { body: data };
};

export const supportedTypes = [
  'txt',
  'csv',
  'tsv',
  'xlsx',
  'xls',
  'docx',
  'pdf',
  'eml',
];

export const displayInHtmlMode = ['csv', 'tsv', 'xlsx', 'xls', 'docx', 'pdf'];

const getAttachmentBody = ({ name, content }) => {
  const ext = getFileExtension(name);
  switch (ext) {
    case 'csv':
    case 'tsv':
      return create(csvTsvType(content));
    case 'xlsx':
    case 'xls':
      return create(xlsxType(content));
    case 'docx':
    case 'doc':
      return create(docxType(content));
    case 'pdf':
      return create(pdfType(content));
    default:
      return create(textType(content));
  }
};

const getFromCache = () => {
  const cache = {};
  return ({ ContentID, content, name }) => {
    const cacheName = `${ContentID}${name}`;
    if (cache[cacheName]) {
      return cache[cacheName];
    }
    const result = getAttachmentBody({ name, content });
    cache[cacheName] = result;
    return result;
  };
};

export default getFromCache();
