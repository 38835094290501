import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { getScript } from '@services/requests/liveChats';

export const ThunkLiveChatScript = createAsyncThunk(
  `${LIVE_CHATS}/getLiveChatScript`,
  id => getScript(id),
);

export default {};
