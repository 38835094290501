import React from 'react';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import iconDelete from '@assets/icons/deleteWhite.svg';

import useStyles from '../styles';

const DeleteButton = ({ onClick, disabled = false }) => {
  const classes = useStyles();

  return (
    <ReusableButton
      onClick={onClick}
      disabled={disabled}
      viewType="black"
      classNameWrapper={classes.buttonDeleteWrapper}
    >
      <>
        <img src={iconDelete} alt="iconDelete" />
        Delete
      </>
    </ReusableButton>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DeleteButton;
