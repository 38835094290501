import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { DEVICE_MONITORS } from '@constants/routes';
import { Typography } from '@material-ui/core';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';
import ReusableButton from '@common/Button/Button';

import { useRedirect } from 'react-admin';
import DetailsWrapper from '@components/DeviceMonitors/components/Details/DetailsWrapper';
import useAsync from '@services/api/common/useAsync';
import { getDeviceMonitor } from '../helpers';
import BackButton from '../components/BackButton';
import autoRefreshing from './utils';
import useStyles from './styles';

const Details = ({ match }) => {
  const {
    params: { id },
  } = match;

  const redirect = useRedirect();

  const classes = useStyles();

  const [selectedAutoRefreshValue, setSelectedAutoRefreshValue] = useState(
    localStorage.getItem(localStorageConst.DEVICE_MONOTIR_DETAILS_TIMER) ||
      autoRefreshing[0].value,
  );

  const handleTvMode = () => {
    redirect(`${DEVICE_MONITORS}/${id}/details/TVMode`);
  };

  const [deviceMonitor, setDeviceMonitor] = useState({});

  const memorizedGetDeviceMonitor = useCallback(() => getDeviceMonitor(id), [
    id,
  ]);

  useAsync(memorizedGetDeviceMonitor, setDeviceMonitor);

  useEffect(() => {
    let timer;
    if (selectedAutoRefreshValue) {
      const timeToMilliseconds = selectedAutoRefreshValue * 60 * 1000;
      timer = setTimeout(() => {
        window.location.reload();
      }, timeToMilliseconds);
    }
    return () => clearTimeout(timer);
  }, [selectedAutoRefreshValue]);

  const handleChangeSelectedValue = e => {
    setSelectedAutoRefreshValue(e.target.value);
    localStorage.setItem(
      localStorageConst.DEVICE_MONOTIR_DETAILS_TIMER,
      e.target.value,
    );
  };

  return (
    <DetailsWrapper id={id}>
      <div className={classes.detailsContainer}>
        <BackButton url={DEVICE_MONITORS} />
        <div className={classes.displayFlex}>
          {deviceMonitor.customerName && (
            <Typography variant="h6" gutterBottom component="div">
              <span className={classes.deviceMonitorLabel}>Customer:</span>
              {deviceMonitor.customerName}
              &nbsp; &nbsp;
            </Typography>
          )}
          <Typography variant="h6" gutterBottom component="div">
            <span className={classes.deviceMonitorLabel}>Device Monitor:</span>
            {deviceMonitor.deviceName}
          </Typography>
        </div>
      </div>
      <div
        className={cx(classes.detailsContainer, classes.autoRefreshContainer)}
      >
        <FieldWrapper
          label="Auto refresh"
          contentSize={2}
          labelSize={0}
          content={
            <DropDown
              input={{
                value: selectedAutoRefreshValue,
                onChange: handleChangeSelectedValue,
              }}
              options={autoRefreshing}
            />
          }
        />
        <ReusableButton
          viewType="black"
          type="button"
          size="xl"
          onClick={handleTvMode}
          label="TV Mode"
        />
      </div>
    </DetailsWrapper>
  );
};

Details.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default Details;
