import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRedirect } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import useAsync from '@services/api/common/useAsync';
import iconDashboard from '@assets/icons/dashboard.svg';
import iconSelectAll from '@assets/icons/accept.svg';
import iconDeSelectAll from '@assets/icons/remove.svg';
import { getIntegrationInfo } from '@components/DataSources/helpers';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import QuickSearchToolbar from '@components/DataSources/Dashboards/components/QuickSearchToolbar';
import useStyles from './styles';

const IntegrationHeader = ({
  isMeraki,
  integrationId,
  onSelectAll,
  onDeselectAll,
  end,
  setFilteredOrganization,
  setSubLoading,
}) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [data, setData] = useState({});
  const getData = useCallback(() => getIntegrationInfo(integrationId), [
    integrationId,
  ]);
  useAsync(getData, setData);

  return (
    <Grid item xs={3}>
      <div className={classes.headerContainer}>
        Integration:&nbsp;
        {data.name}
      </div>
      <div className={classes.headerButtons}>
        <div>
          <ActionButton
            icon={<img src={iconSelectAll} alt="iconSelectAll" />}
            handler={onSelectAll}
            toolTip="Select all"
          />
          <ActionButton
            icon={<img src={iconDeSelectAll} alt="iconDeSelectAll" />}
            handler={onDeselectAll}
            toolTip="Deselect all"
          />
        </div>
        <QuickSearchToolbar
          setFilteredOrganization={setFilteredOrganization}
          integrationId={integrationId}
          end={end}
          setSubLoading={setSubLoading}
        />
        <ActionButton
          icon={<img src={iconDashboard} alt="iconDashboard" />}
          handler={() =>
            redirect(isMeraki ? `dashboard/${integrationId}` : 'dashboard')
          }
          toolTip="To Dashboard"
        />
      </div>
    </Grid>
  );
};

IntegrationHeader.propTypes = {
  integrationId: PropTypes.string.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  end: PropTypes.number.isRequired,
  setFilteredOrganization: PropTypes.func.isRequired,
  setSubLoading: PropTypes.func.isRequired,
  isMeraki: PropTypes.bool,
};

export default IntegrationHeader;
