import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ExtraResourceItem = ({ label, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.itemContainer} onClick={onClick}>
      <span>purchase</span>
      <span>{label}</span>
    </div>
  );
};

ExtraResourceItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExtraResourceItem;
