import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ChatsIdsSelector } from '@store/slices/chats';
// import { getActiveChatSelector, getChatNotifications } from '@store/selectors';

import ReusableButton from '@ui/components/common/Button/Button';
import CreateChatDialog from '@ui/components/Chats/CreateChatDialog/CreateChatDialog';

import useStyles from '../../styles';
import ChatListItem from './ChatListItem';

const ChatsList = () => {
  const [showNewChatDialog, setShowNewChatDialog] = useState(false);
  const classes = useStyles();

  const chatIds = useSelector(ChatsIdsSelector);

  return (
    <div className={classes.chatListContainer}>
      <div className={classes.chatListButtons}>
        <ReusableButton
          label="Add new chat"
          onClick={() => {
            setShowNewChatDialog(true);
          }}
        />
      </div>
      {chatIds.map(chatId => (
        <ChatListItem key={chatId} chatId={chatId} />
      ))}

      {showNewChatDialog && (
        <CreateChatDialog
          open={showNewChatDialog}
          setOpen={setShowNewChatDialog}
        />
      )}
    </div>
  );
};

export default ChatsList;
