import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    height: '90%',
    padding: '20px',
    margin: '20px',
    background: 'white',
    boxSizing: 'border-box',
  },
  companyMappingItem: {
    position: 'relative',
    display: 'flex',
    width: 'fit-content',
    border: props => `1px solid ${props.errors?.length ? 'red' : '#d1d1d1'}`,
    alignItems: 'center',
    padding: '15px',
    borderRadius: '12px',
    margin: '10px 0',
    '&> :first-child': {
      marginRight: '1.5rem',
    },
  },
  companyMappingItemDropdown: {
    width: '25rem',
  },
  icon: {
    position: 'absolute',
    right: '-35px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  errorIcon: {
    right: '-70px',
  },
});

export default useStyles;
