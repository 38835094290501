import React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { Input } from '@material-ui/core';

import useStyles from './styles';

const TextMaskCustom = React.forwardRef(
  ({ onChange, name, mask, ...rest }, ref) => {
    return (
      <IMaskInput
        {...rest}
        mask={mask}
        inputRef={ref}
        onAccept={value => onChange({ target: { name, value } })}
        overwrite
      />
    );
  },
);

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.string,
};

const FormattedInputs = ({
  input: { onChange, value },
  meta: { touched, error },
  disabled = false,
  id,
  mask,
}) => {
  const classes = useStyles({ hasError: !!(touched && error) });
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <Input
      value={value}
      error={!!(touched && error)}
      helperText={touched && error}
      onChange={handleChange}
      name={id}
      id={id}
      inputComponent={TextMaskCustom}
      inputProps={{ mask }}
      variant="outlined"
      size="small"
      fullWidth
      disabled={disabled}
      className={classes.input}
    />
  );
};

FormattedInputs.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  mask: PropTypes.string,
};

export default FormattedInputs;
