import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  cell: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: '#ddd',
  },
}));

export default useStyles;
