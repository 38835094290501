import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { listSelector } from '@store/selectors';

import {
  deleteDeviceMonitors,
  updateDeviceMonitors,
} from '@components/DeviceMonitors/helpers';
import { actions } from '@store/actions';

import { ReactComponent as DeleteIcon } from '@assets/icons/deleteWhite.svg';
import { ReactComponent as UpdateIcon } from '@assets/icons/circular-arrow.svg';

import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import { useTableContext } from '../../../../../hooks/table';

import useStyles from '../styles';

const BulkActions = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [valueToUpdate, setValueToUpdate] = useState(null);
  const [processing, setProcessing] = useState(false);

  const { selectedIds, deselectAllItems } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const classes = useStyles();

  const onClickDelete = () => {
    const params = selectedIds.map(i => `id=${i}`).join('&');

    setProcessing(true);
    deleteDeviceMonitors(params)
      .then(({ error }) => {
        if (!error) {
          dispatch(actions.removeItemFromList({ selectedIds }));
          deselectAllItems();
          enqueueSnackbar(`Successfully removed`, { variant: 'success' });
          setDeleteDialogOpen(false);
        } else {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const updateMany = () => {
    setProcessing(true);
    const params = selectedIds.map(i => `id=${i}`).join('&');
    updateDeviceMonitors(params, valueToUpdate)
      .then(() => {
        const newList = list.map(item => {
          if (selectedIds.includes(item.id))
            return { ...item, enabled: valueToUpdate };
          return item;
        });
        dispatch(actions.saveList(newList));
        deselectAllItems();
        enqueueSnackbar(`Successfully updated`, { variant: 'success' });
      })
      .catch(() =>
        enqueueSnackbar(`Elements state updating was failed`, {
          variant: 'error',
        }),
      )
      .finally(() => {
        setOpen(false);
        setValueToUpdate(null);
        setProcessing(false);
      });
  };

  const handleClickRunningState = () => {
    const enableValues = list
      .filter(item => selectedIds.includes(item.id))
      .map(i => i.enabled);
    const isAllTrue = enableValues.every(v => v);
    const isAllFalse = enableValues.every(v => !v);
    if (!isAllTrue && !isAllFalse) {
      enqueueSnackbar(
        "Can't update elements running state with the differing values",
        { variant: 'error' },
      );
    } else {
      setValueToUpdate(!isAllTrue);
      setOpen(true);
    }
  };

  const handleConfirm = () => {
    updateMany();
  };

  return (
    <div className={classes.bulkActions}>
      <ReusableButton onClick={() => setDeleteDialogOpen(true)} size="lg">
        <div className={classes.bulkActionsButtons}>
          <DeleteIcon />
          Delete Selected
        </div>
      </ReusableButton>

      <ReusableButton onClick={handleClickRunningState} size="lg">
        <div className={classes.bulkActionsButtons}>
          <UpdateIcon />
          Update Running State
        </div>
      </ReusableButton>

      {open && (
        <ConfirmDialog
          open
          title="Confirm action"
          content="Are you sure you want to update the running state?"
          onClose={() => setOpen(false)}
          onAccept={handleConfirm}
          loading={processing}
        />
      )}

      {deleteDialogOpen && (
        <ConfirmDialog
          open
          setOpen={setDeleteDialogOpen}
          onAccept={onClickDelete}
          onCancel={() => setDeleteDialogOpen(false)}
          title="Confirm action"
          content="Are you sure you want to delete the selected items?"
          loading={processing}
        />
      )}
    </div>
  );
};

export default BulkActions;
