import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { resetUserPassword } from '@services/requests/auth';

export const ThunkResetUserPassword = createAsyncThunk(
  `${AUTH}/resetUserPassword`,
  ({ payload }) => resetUserPassword(payload),
);

export default {};
