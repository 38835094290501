import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkExistingMessagingChannelFromTicket } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkCheckExistingMessagingChannelFromTicket = createAsyncThunk(
  `${CRM_TICKET}/checkExistingMessagingChannelFromTicket`,
  async ({ crmId, ticketId }) => {
    const res = await checkExistingMessagingChannelFromTicket(crmId, ticketId);
    return res;
  },
);

export default {};
