import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { resetVerificationScreenSettings } from '@services/requests/verificationSettings';

export const ThunkResetVerificationScreenSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/resetVerificationScreenSettings`,
  () => resetVerificationScreenSettings(),
);

export default {};
