import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BooleanField } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { enqueueSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import ReusableButton from '@common/Button/Button';
import ChipField from '@common/Chip/ChipField';
import FormWrapper from '@components/DataSources/HookTemplates/components/FormWrapper';
import Input from '@common/Input/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import {
  createTemplateData,
  deleteTemplateData,
  getTemplateData,
  updateTemplateData,
} from '@components/DataSources/helpers';
import { actions } from '@store/actions';
import CreateWizard from '@components/DataSources/HookTemplates/CreateWizard/CreateWizard';
import { listSelector } from '@store/selectors';
import DateTimeField from '@common/TableComponents/DateTimeField';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import ActionField from './components/ActionField';

import useStyles from './styles';

const HookTemplates = () => {
  const resource = 'merakiAlertTemplates';
  const classes = useStyles();
  const list = useSelector(listSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const { push } = useHistory();
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [data, setData] = useState(null);
  const [wizardOpen, setWizardOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  const onNameChange = event => {
    setName(event.target.value);
    setNameError(null);
  };

  const create = payload => {
    createTemplateData(payload)
      .then(d => {
        dispatch(actions.updateItemInList(d));
        setName('');
        setData(null);
        setFormOpen(false);
        enqueueSnackbar('Template created', { variant: 'success' });
      })
      .catch(e =>
        enqueueSnackbar(`${e.message}: ${e.exception}`, { variant: 'error' }),
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const update = (payload, id) => {
    updateTemplateData(id, payload)
      .then(d => {
        dispatch(actions.updateItemInList(d));
        setName('');
        setData(null);
        setFormOpen(false);
        enqueueSnackbar('Template created', { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${e.message}: ${e.exception}`, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (values, id) => {
    if (!name) {
      setNameError('required');
      return;
    }
    setLoading(true);
    const payload = {
      id: id || 0,
      name,
      ...values,
    };
    if (id) {
      update(payload, id);
    } else {
      create(payload);
    }
  };

  const onDelete = useCallback(
    id => {
      deleteTemplateData(id)
        .then(() => {
          dispatch(actions.removeItemFromList({ id }));
          enqueueSnackbar('Template erased', { variant: 'success' });
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [dispatch],
  );

  const onEdit = useCallback(id => {
    setLoading(true);
    getTemplateData(id)
      .then(d => {
        setName(d.name);
        setData(d);
        setFormOpen(true);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, []);

  const onApplyTemplate = id => {
    setSelectedTemplateId(+id);
    setWizardOpen(true);
  };

  const onWizardClose = () => {
    setSelectedTemplateId(0);
    setWizardOpen(false);
  };

  const onCreate = () => {
    setName('');
    setNameError(null);
    setData(null);
    setFormOpen(true);
  };

  const onApply = (templateId, count) => {
    if (count) {
      const newList = list.map(obj => {
        if (+obj.id === templateId) {
          return {
            ...obj,
            lastApplyDateTime: moment.utc(),
            wasChangedAfterApply: false,
          };
        }
        return obj;
      });
      dispatch(actions.saveList(newList));
    }
  };

  useEffect(() => {
    const id = +params.get('id'); // convert to number
    if (params.get('id')) {
      push(location.pathname);
      onEdit(id);
    }
  }, [params]);

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        minWidth: 200,
        sortable: true,
      },
      {
        name: 'Alert Admins',
        key: 'allAdmins',
        sortable: true,
        searchable: true,
        minWidth: 175,
        width: 175,
        type: TableTypes.boolean,
      },
      {
        name: 'Use SNMP',
        key: 'snmp',
        type: TableTypes.boolean,
        width: 125,
        minWidth: 125,
      },
      {
        name: 'Email behaviour',
        key: 'emailsTemplateBehaviour',
        minWidth: 175,
      },
      {
        name: 'Hooks',
        key: 'hooksTemplateBehaviour',
        width: 125,
        minWidth: 125,
      },
      {
        name: 'Email',
        key: 'emails',
        isCustomAccessor: true,
        width: 200,
        minWidth: 200,
        accessor: rowData => <ChipField record={rowData} source="emails" />,
      },
      {
        name: 'Selected hooks',
        key: 'httpServerIds',
        accessor: rowData => (
          <ChipField record={rowData} source="httpServerIds" />
        ),
        width: 150,
        minWidth: 150,
      },
      {
        name: 'Last Application',
        key: 'lastApplyDateTime',
        sort: 'lastApplyDateTime',
        width: 175,
        minWidth: 175,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="lastApplyDateTime"
            source="lastApplyDateTime"
            sortable
          />
        ),
      },
      {
        name: 'Applied By',
        key: 'lastApplyUser',
        width: 165,
        minWidth: 165,
      },
      {
        name: 'Was changed',
        key: 'wasChangedAfterApply',
        width: 150,
        minWidth: 150,
        isCustomAccessor: true,
        accessor: rowData => (
          <div className={classes.booleanFieldContainer}>
            {rowData.wasChangedAfterApply && (
              <BooleanField record={rowData} source="wasChangedAfterApply" />
            )}
          </div>
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            onEdit={onEdit}
            onDelete={onDelete}
            onApply={onApplyTemplate}
          />
        ),
        width: 125,
        minWidth: 125,
      },
    ],
    [classes.booleanFieldContainer, onDelete, onEdit],
  );

  return (
    <div className={classes.tableWrapper}>
      <ReusableButton
        size="md"
        label="Add"
        onClick={onCreate}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <Table
        columns={columns}
        resource={resource}
        defaultSort={{ fieldName: 'name', order: 'ASC' }}
      />
      <Modal open={formOpen} onClose={() => setFormOpen(false)}>
        <div className={classes.modalBox}>
          <Paper className={classes.smallPadding}>
            <Paper>
              <Form onSubmit={() => {}}>
                {() => {
                  return (
                    <FieldWrapper
                      label="Template name"
                      contentSize={8}
                      labelSize={2}
                      content={
                        <Field
                          id="templateName"
                          name="name"
                          disabled={false}
                          styleType="main"
                          inputView="text"
                          fullWidth
                          meta={{ touched: true, error: nameError }}
                          input={{
                            value: name,
                            onChange: onNameChange,
                          }}
                          component={Input}
                        />
                      }
                    />
                  );
                }}
              </Form>
            </Paper>
            <FormWrapper
              onCancel={() => setFormOpen(false)}
              onSubmit={onSubmit}
              data={data}
            />
          </Paper>
        </div>
      </Modal>
      <Modal open={wizardOpen} onClose={onWizardClose}>
        <div className={cx(classes.modalBox, classes.wizardModalBox)}>
          <CreateWizard
            templateId={selectedTemplateId}
            onClose={onWizardClose}
            onApply={onApply}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HookTemplates;
