import { createAsyncThunk } from '@reduxjs/toolkit';
import { createKaseyaTicket } from '@services/requests/createTicket';
import { CREATE_TICKET } from '@store/slices/resources';

const generatePayload = values => ({
  title: values.title,
  details: values.description,
  companyId: +values.defaultCompany || null,
  companyLocationId: +values.locationId || null,
  boardId: +values.defaultBoardId || null,
  boardTypeId: +values.typeId || null,
  boardSubtypeId: +values.subTypeId || null,
  priorityId: +values.ticketPriority || null,
  statusId: +values.openBoardStatusId || null,
  categoryId: +values.ticketCategoryId || null,
  assigneeId: +values.assigneeId || null,
});

// * used to create ticket without psa default id, only with psa integration id
export const ThunkCreateKaseyaTicket = createAsyncThunk(
  `${CREATE_TICKET}/createKaseyaTicket`,
  ({ psaId, payload }) => createKaseyaTicket(psaId, generatePayload(payload)),
);

export default {};
