import React from 'react';
import cx from 'classnames';

import backdrop from '@assets/backdrops/workChat.png';
import slack from '@assets/logos/slack.png';
import google from '@assets/logos/googleMini.png';
import datto from '@assets/logos/datto.png';
import kaseya from '@assets/logos/kaseya.png';
import teams from '@assets/logos/teams.png';
import cw from '@assets/logos/cw.png';

import useStyles from './styles';

const LoginPageBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.bannerContainer}>
      <img src={backdrop} alt="backdrop" className={classes.backdrop} />
      <img
        src={slack}
        alt="slack"
        className={cx(classes.logo, classes.slack)}
      />

      <img
        src={google}
        alt="google"
        className={cx(classes.logo, classes.google)}
      />
      <img
        src={datto}
        alt="datto"
        className={cx(classes.logo, classes.datto)}
      />
      <img
        src={kaseya}
        alt="kaseya"
        className={cx(classes.logo, classes.kaseya)}
      />
      <img
        src={teams}
        alt="teams"
        className={cx(classes.logo, classes.teams)}
      />
      <img src={cw} alt="cw" className={cx(classes.logo, classes.cw)} />
    </div>
  );
};

export default LoginPageBanner;
