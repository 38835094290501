import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import UserIcon from '@assets/icons/user.svg';
import CloseIcon from '@assets/icons/cross.svg';
import CreateTicketIcon from '@assets/icons/plus.svg';
import LinkIcon from '@assets/icons/link.svg';
import NoteIcon from '@assets/icons/test.svg';
import EmailIcon from '@assets/icons/email_active.svg';
import ReopenIcon from '@assets/icons/refreshItem.svg';

import IdentifyLiveChatUserDialog from '@components/LiveChat/dialogs/IdentifyLiveChatUserDialog';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import SearchableMenu from '@common/SearchableMenu';
import PushMessagesToTicketNoteDialog from '@components/LiveChat/dialogs/PushMessagesToTicketNoteDialog';
import SendLogsToEmailDialog from '@components/LiveChat/dialogs/SendLogsToEmailDialog';
import CloseLiveChatWIthTicketDialog from '@components/LiveChat/dialogs/CloseLiveChatWIthTicketDialog';

import {
  ThunkCloseConversation,
  ThunkSendChatMessage,
} from '@store/slices/liveChats/thunks';
import { ThunkGetLiveChatConversationTemplates } from '@store/slices/messaging/thunks';
import {
  LIVE_CHAT_CONVERSATIONS,
  LIVE_CHAT_CREATE_TICKET,
} from '@constants/routes';
import { SelectResourcesList } from '@store/slices/liveChats/selectors';

import useStyles from './styles';

const LiveChatAdminActionPanel = ({
  liveChatId,
  onClose,
  isChatClosed,
  selectedResource,
  hasTicket,
  ticketLink,
}) => {
  const [openIdentifyUserDialog, setOpenIdentifyUserDialog] = useState(false);
  const [
    openCloseConversationDialog,
    setOpenCloseConversationDialog,
  ] = useState(false);
  const [conversationTemplates, setConversationTemplates] = useState([]);
  const [openPushToNoteDialog, setOpenPushToNoteDialog] = useState(false);
  const [openSendLogsToEmail, setOpenSendLogsToEmail] = useState(false);
  const [openReopenDialog, setOpenReopenDialog] = useState(false);

  const classes = useStyles();
  const resourcesOptions = useSelector(SelectResourcesList);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const resource = useMemo(() => {
    return resourcesOptions.find(i => i.id === selectedResource);
  }, [resourcesOptions, selectedResource]);

  const handleReopen = () => {
    setOpenReopenDialog(true);
  };

  const handleCreateTicket = useCallback(() => {
    push(
      LIVE_CHAT_CREATE_TICKET.replace(':psaType', resource.crmType)
        .replace(':psaId', resource.crmId)
        .replace(':chatId', liveChatId),
    );
  }, [resource, liveChatId, push]);

  const handleIdentifyUser = useCallback(() => {
    setOpenIdentifyUserDialog(true);
  }, []);

  const handleCloseConversation = useCallback(() => {
    setOpenCloseConversationDialog(true);
  }, []);

  const handleCloseAction = useCallback(async () => {
    await dispatch(ThunkCloseConversation({ liveChatId }))
      .unwrap()
      .finally(onClose);
  }, [liveChatId]);

  const handleMessageSend = async ({ text, type = undefined }) => {
    if (type === 'link') {
      push(LIVE_CHAT_CONVERSATIONS);
      return;
    }
    const payload = {
      chatId: liveChatId,
      message: text,
    };
    await dispatch(ThunkSendChatMessage(payload));
  };

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetLiveChatConversationTemplates())
      .unwrap()
      .then(res => {
        setConversationTemplates(res);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <div className={classes.actionsContainer}>
        <div>
          <SearchableMenu
            menuItems={conversationTemplates}
            onMenuItemClick={handleMessageSend}
            buttonLabel="Conversations"
            searchable
            allowNavigate
            navigateItem={{
              name: 'Edit Templates',
              path: LIVE_CHAT_CONVERSATIONS,
            }}
          />
        </div>

        <div className={classes.actions}>
          {hasTicket && !isChatClosed && (
            <ActionFieldItem
              handler={() => {
                setOpenPushToNoteDialog(true);
              }}
              icon={NoteIcon}
              toolTip="Push Messages to Ticket Note"
            />
          )}

          <ActionFieldItem
            handler={() => {
              setOpenSendLogsToEmail(true);
            }}
            icon={EmailIcon}
            toolTip="Send Chat Logs to Email"
          />

          <ActionFieldItem
            handler={handleIdentifyUser}
            icon={UserIcon}
            toolTip="Identify User"
          />

          {!isChatClosed && (
            <ActionFieldItem
              handler={handleCloseConversation}
              icon={CloseIcon}
              toolTip="Close Conversation"
            />
          )}

          {isChatClosed && (
            <ActionFieldItem
              handler={handleReopen}
              icon={ReopenIcon}
              toolTip="Re-open Conversation"
            />
          )}

          {!isChatClosed && (
            <>
              {!hasTicket ? (
                <ActionFieldItem
                  handler={handleCreateTicket}
                  icon={CreateTicketIcon}
                  toolTip="Create Ticket"
                />
              ) : (
                <ActionFieldItem
                  handler={() => {
                    window.open(ticketLink, '_blank');
                  }}
                  icon={LinkIcon}
                  toolTip="Open Ticket"
                />
              )}
            </>
          )}
        </div>

        {openIdentifyUserDialog && (
          <IdentifyLiveChatUserDialog
            setOpen={setOpenIdentifyUserDialog}
            liveChatId={liveChatId}
            open={openIdentifyUserDialog}
          />
        )}

        {openPushToNoteDialog && (
          <PushMessagesToTicketNoteDialog
            open={openPushToNoteDialog}
            setOpen={setOpenPushToNoteDialog}
            liveChatId={liveChatId}
          />
        )}

        {openSendLogsToEmail && (
          <SendLogsToEmailDialog
            setOpen={setOpenSendLogsToEmail}
            liveChatId={liveChatId}
            open={openSendLogsToEmail}
          />
        )}
      </div>
      {/* USE Different Dialogs for Closing Chat with and without linked ticket */}
      {!hasTicket && (
        <ConfirmDialog
          open={!!openCloseConversationDialog}
          setOpen={setOpenCloseConversationDialog}
          onAccept={handleCloseAction}
          onCancel={() => setOpenCloseConversationDialog(false)}
          title="Close conversation"
          content="Are you sure you want to close this conversation? This action can not be undone."
        />
      )}
      {hasTicket && (
        <CloseLiveChatWIthTicketDialog
          setOpen={setOpenCloseConversationDialog}
          liveChatId={liveChatId}
          open={openCloseConversationDialog}
        />
      )}
    </>
  );
};

LiveChatAdminActionPanel.propTypes = {
  liveChatId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  isChatClosed: PropTypes.bool.isRequired,
};

export default LiveChatAdminActionPanel;
