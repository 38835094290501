import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { createTheme, ThemeProvider } from '@material-ui/core';

import { ThunkCheckCode } from '@store/slices/psaPods/thunks';

import UnauthorizedLayout from '@components/UnauthorizedLayout';
import { lightTheme } from '@services/themes/mainTheme';
import Loading from '@common/Loading/Loading';

import ReusableButton from '@common/Button/Button';
import { ThunkGetValidationConfig } from '@store/slices/psaPods/thunks/getValidarionConfiguration';
import initConfiguration from '@components/Settings/VerificationSettings/initConfiguration';
import useStyles from './styles';

const theme = createTheme(lightTheme());

const PsaContactValidation = ({
  location: { search },
  match: {
    params: { crmId },
  },
}) => {
  const [config, setConfig] = useState(initConfiguration);

  const classes = useStyles(config);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({ isSuccess: false, text: '' });

  const onValidate = useCallback(() => {
    const code = new URLSearchParams(search).get('code');
    setLoading(true);
    dispatch(ThunkCheckCode({ crmId, code }))
      .unwrap()
      .then(() => {
        setResult({
          isSuccess: true,
          text: config.successMessageText,
        });
      })
      .catch(e => {
        setResult({
          isSuccess: false,
          text: config.failedMessageText.replace('${error}', e.message),
        });
      })
      .finally(() => setLoading(false));
  }, [config.failedMessageText, crmId, dispatch, search]);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetValidationConfig({ crmId }))
      .unwrap()
      .then(d => setConfig(d))
      .finally(() => setLoading(false));
  }, [crmId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <UnauthorizedLayout
      baseColorA={config.gradientColor1}
      baseColorB={config.gradientColor2}
    >
      <ThemeProvider theme={theme}>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.container}>
            {config.logo && (
              <img alt="Logo" src={config.logo} className={classes.imageRoot} />
            )}
            <div
              className={classes.description}
              style={{ color: config.headerColor }}
            >
              {config.headerText}
            </div>
            <div
              className={classes.text}
              style={{
                color: result.isSuccess
                  ? config.successMessageColor
                  : config.failedMessageColor,
              }}
            >
              {result.text}
            </div>
            {!result.text && (
              <ReusableButton
                size="md"
                viewType="black"
                label="Validate"
                onClick={onValidate}
                loading={loading}
                disabled={loading}
              />
            )}
          </div>
        )}
      </ThemeProvider>
    </UnauthorizedLayout>
  );
};

PsaContactValidation.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.any).isRequired,
  }),
};

export default PsaContactValidation;
