import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: '12px',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  buttonWrapper: {
    margin: '0 0 8px',
  },
}));

export default useStyles;
