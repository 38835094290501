import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import Modal from '@ui/common/Modal/Modal';
import { TenantSettingsStateSelector } from '@store/slices/common';
import { ThunkGetTenantSettingsPhoneNumbers } from '@store/slices/common/thunks/getTenantSettingsPhoneNumbers';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import Loading from '@common/Loading/Loading';
import useStyles from './styles';

const PhoneNumbersModal = ({ setOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { phoneNumbers, isLoading } = useSelector(TenantSettingsStateSelector);

  useEffect(() => {
    dispatch(ThunkGetTenantSettingsPhoneNumbers());
  }, [dispatch]);

  const content = useMemo(
    () =>
      phoneNumbers.length ? (
        phoneNumbers.map((number, index, array) => (
          <div key={number.value} className={classes.number}>
            {number.label !== array[index - 1]?.label && (
              <Typography className={classes.bold}>
                {`${number.label} number:`}
              </Typography>
            )}
            <Typography component="span">
              {nationalFormatPhoneNumber(number.value)}
            </Typography>
          </div>
        ))
      ) : (
        <div>No phone numbers available</div>
      ),
    [phoneNumbers, classes],
  );

  return (
    <Modal open setOpen={setOpen} title="Phone numbers">
      <div
        className={cx(classes.contentWrapper, {
          [classes.loaderWrapper]: isLoading,
        })}
      >
        {isLoading ? (
          <Loading classNameWrapper={classes.loaderWrapper} />
        ) : (
          content
        )}
      </div>
    </Modal>
  );
};

PhoneNumbersModal.propTypes = {
  setOpen: PropTypes.func,
};

export default PhoneNumbersModal;
