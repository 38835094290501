import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkGetChatMessages,
  ThunkIdentifyLiveChatUser,
} from '@store/slices/liveChats/thunks';
import { SelectCurrentUserInfo } from '@store/slices/liveChats/selectors';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import Checkbox from '@common/Checkbox/Checkbox';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

const IdentifyLiveChatUserDialog = ({ open, setOpen, liveChatId }) => {
  const [processing, setProcessing] = useState(false);

  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();
  const userInfo = useSelector(SelectCurrentUserInfo);

  const getMessages = async () => {
    await dispatch(ThunkGetChatMessages({ id: liveChatId }));
  };

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      ThunkIdentifyLiveChatUser({
        liveChatId,
        payload: values,
      }),
    )
      .unwrap()
      .then(() => {
        setOpen(false);
        getMessages();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Identify User</DialogTitle>
      <DialogContent style={{ width: '30vw' }}>
        <Form
          onSubmit={submit}
          initialValues={{
            name: userInfo?.userName || '',
            ...userInfo,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="User Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Is Verified"
                labelSize={3}
                contentSize={9}
                fullWidth
                content={
                  <Field
                    name="isVerified"
                    id="isVerified"
                    render={Checkbox}
                    disabled={processing}
                    checked={values.isVerified}
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />
                <ReusableButton
                  type="submit"
                  label="Submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

IdentifyLiveChatUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  liveChatId: PropTypes.number.isRequired,
};

export default IdentifyLiveChatUserDialog;
