import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import { defaultDateFormat } from '@constants/formats';
import webHookStatuses from '@constants/webHookStatuses';
import WebHookResultsHeader from './components/WebHookResultsHeader';
import WebHookResultDetails from './components/WebHookResultDetails';

import { getWebHookResult, reParseWebHookResult } from '../../helpers';

import useStyles from './styles';

const WebHookResult = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [webHookData, setWebHookData] = useState({});

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const recordStatus = useMemo(
    () => webHookStatuses[webHookData?.status]?.label,
    [webHookData?.status],
  );

  const recordColor = useMemo(() => webHookStatuses[webHookData?.status]?.hex, [
    webHookData?.status,
  ]);

  const recordDate = useMemo(
    () =>
      moment
        .utc(webHookData?.date)
        .local()
        .format(defaultDateFormat),
    [webHookData?.date],
  );

  const hookData = useMemo(() => {
    if (webHookData?.hookData) {
      return JSON.stringify(JSON.parse(webHookData?.hookData), null, 2);
    }
  }, [webHookData?.hookData]);

  const parsedData = useMemo(() => {
    if (webHookData?.parsedData) {
      return JSON.stringify(JSON.parse(webHookData?.parsedData), null, 2);
    }
  }, [webHookData?.parsedData]);

  const unrecognizedValues = useMemo(() => {
    if (webHookData?.unrecognizedValues) {
      return JSON.stringify(
        JSON.parse(webHookData?.unrecognizedValues),
        null,
        2,
      );
    }
  }, [webHookData?.unrecognizedValues]);

  const handleProceedAgain = id => {
    reParseWebHookResult(id);
  };

  const handleHookRedirect = id => {
    push(`/webHooks/${id}`);
  };

  const onMount = () => {
    setLoading(true);
    getWebHookResult(match.params.id)
      .then(res => {
        setWebHookData(res);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    onMount();
  }, [match.params.id]);

  if (loading) return <Loading />;
  return (
    <Grid container className={classes.container}>
      <WebHookResultsHeader
        recordId={webHookData?.id}
        recordStatus={recordStatus}
        recordColor={recordColor}
        recordDate={recordDate}
      />

      <Grid container item xs={12} className={classes.blockWrapper}>
        <ReusableButton
          label="Send Again"
          onClick={() => {
            handleProceedAgain(webHookData?.id);
          }}
          classNameWrapper={classes.buttonWrapper}
        />
        <ReusableButton
          label="Open Hook"
          onClick={() => {
            handleHookRedirect(webHookData?.webHookId);
          }}
          classNameWrapper={classes.buttonWrapper}
        />
      </Grid>

      <WebHookResultDetails
        hookData={hookData}
        parsedData={parsedData}
        unrecognizedValues={unrecognizedValues}
        errorMessage={webHookData?.errorMessage}
      />
    </Grid>
  );
};

export default WebHookResult;
