import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  plusIcon: {
    fill: theme.palette.info.main,
    marginRight: '0.5rem',
  },
  vpnIcon: {
    fill: '#F3A594',
  },
  disabledVpnIcon: {
    fill: '#A9A9A9',
  },
  greenVpnIcon: {
    fill: '#20a926',
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  switcher: {
    '& .MuiTypography-root': {
      transform: 'rotate(180deg) scaleX(-1)',
    },
  },
  root: { justifyContent: 'space-between' },
  avatar: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
