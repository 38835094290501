import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThunkCloseTriageTicket } from '@store/slices/triage/thunks';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import crmSources, { crmSourcesByType } from '@constants/crmSources';
import { CRM_TICKET_TIME_ENTRIES, CRM_TICKET_EDIT } from '@constants/routes';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import CloseIcon from '@assets/icons/tick.svg';
import CloseActiveIcon from '@assets/icons/tick_active.svg';
import CalendarIcon from '@assets/icons/calendar_green.svg';
import EditIcon from '@assets/icons/edit.svg';
import CalendarGrey from '@assets/icons/calendar.svg';

import useStyles from '../styles';

const TableItemActions = ({ record }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { push } = useHistory();

  const isClosed = useMemo(() => +record.ticketStatus === 2, [record]);

  const handleCloseClick = async ({ id }) => {
    if (!isClosed) {
      const updatedTicket = await dispatch(ThunkCloseTriageTicket(id)).unwrap();

      dispatch(
        actions.saveList(
          list.map(item =>
            item.id === updatedTicket.id ? updatedTicket : item,
          ),
        ),
      );
    }
  };

  const handleTimeEntryClick = ({ crmDefaultId, ticketId }) => {
    push(
      CRM_TICKET_TIME_ENTRIES.replace(':crmDefaultId', crmDefaultId).replace(
        ':ticketId',
        ticketId,
      ),
    );
  };

  const handleTicketEditClick = ({ crmDefaultId, ticketId, crmType }) => {
    push(
      CRM_TICKET_EDIT.replace(':crmDefaultId', crmDefaultId)
        .replace(':ticketId', ticketId)
        .replace(':crmName', crmSourcesByType[crmType].name),
    );
  };

  return (
    <div>
      <ActionFieldItem
        icon={EditIcon}
        toolTip="Edit Ticket"
        handler={() => {
          handleTicketEditClick(record);
        }}
        alt="Close"
      />

      {record.crmType === crmSources.ConnectWise.name ? (
        <ActionFieldItem
          icon={CalendarIcon}
          toolTip="Time Entries"
          handler={() => {
            handleTimeEntryClick(record);
          }}
          alt="Time Entries"
        />
      ) : (
        <ActionFieldItem
          icon={CalendarGrey}
          toolTip="Time Entries"
          handler={() => {}}
          alt="Time Entries"
          classNameWrapper={classes.unActiveTick}
        />
      )}

      {!isClosed ? (
        <ActionFieldItem
          icon={CloseActiveIcon}
          toolTip="Close Ticket"
          handler={() => {
            handleCloseClick(record);
          }}
          alt="Close"
        />
      ) : (
        <ActionFieldItem
          icon={CloseIcon}
          toolTip="Ticket already closed"
          handler={() => {}}
          alt="Close"
          classNameWrapper={classes.unActiveTick}
        />
      )}
    </div>
  );
};

TableItemActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    ticketStatus: PropTypes.string,
    crmType: PropTypes.string,
  }),
};
export default TableItemActions;
