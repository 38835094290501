import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteTeamsConnection } from '@services/requests/teamsConnections';
import { TEAMS_CONNECTIONS } from '@store/slices/resources';

export const ThunkDeleteTeamsConnections = createAsyncThunk(
  `${TEAMS_CONNECTIONS}/deleteTeamsConnection`,
  async id => {
    await deleteTeamsConnection(id);
    return id;
  },
);

export default {};
