import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogContent,
  TableBody,
} from '@material-ui/core';
import DialogTitle from '@common/Dialog/DialogTitle';
import AccordionWrapper from '@components/UploadFileDialog/AccordionWrapper';
import TableItem from './TableItem';
import TableHeader from './TableHeader';

const initData = {
  open: false,
  data: {},
};

const DetailsDialog = ({ setModalDetails, statistic, open, children }) => {
  const handleOnClose = () => setModalDetails(initData);

  return (
    <MuiDialog
      fullWidth
      maxWidth="false"
      open={open}
      onClose={() => setModalDetails(initData)}
    >
      <DialogTitle onClose={handleOnClose}>Uploaded file details</DialogTitle>
      <DialogContent>
        <AccordionWrapper
          summary="Statistic"
          id="panel1a-header"
          ariaControls="panel1a-content"
          defaultExpanded
        >
          <TableHeader
            headers={[
              'Created',
              'Row with error',
              'Skipped',
              'Total',
              'Updated',
              'Errors',
            ]}
          />
          <TableBody>
            {statistic && (
              <TableItem
                keys={[
                  'created',
                  'rowWithError',
                  'skipped',
                  'total',
                  'updated',
                  'errors',
                ]}
                data={statistic}
              />
            )}
          </TableBody>
        </AccordionWrapper>

        <AccordionWrapper
          summary="Imported Rows"
          id="panel2a-header"
          ariaControls="panel2a-content"
        >
          {children}
        </AccordionWrapper>
      </DialogContent>
    </MuiDialog>
  );
};

AccordionWrapper.propTypes = {
  summary: PropTypes.string,
  ariaControls: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
};

DetailsDialog.propTypes = {
  setModalDetails: PropTypes.func,
  open: PropTypes.bool,
  statistic: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

export default DetailsDialog;
