import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@common/Dialog/DialogTitle';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { getTickets } from '@components/Audit/helpers';
import TicketList from './TicketList';

const TicketsListDialog = ({ isOpen, onClose, ticketIds }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose}>Created tickets</DialogTitle>
      <DialogContent>
        <TicketList handler={getTickets} identifier={ticketIds} />
      </DialogContent>
    </Dialog>
  );
};

TicketsListDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ticketIds: PropTypes.arrayOf(PropTypes.number),
};

export default TicketsListDialog;
