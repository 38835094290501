import React from 'react';
import PropTypes from 'prop-types';
import { grayColor, greenColor } from '@constants/palette';

import useStyles from './styles';

const StatusCircle = ({ record = {}, handleClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div
        role="presentation"
        onClick={() => handleClick(record.id, record.isEnabled)}
        className={classes.circle}
        style={
          record.isEnabled
            ? { backgroundColor: `${greenColor}` }
            : { backgroundColor: `${grayColor}` }
        }
      />
    </div>
  );
};

StatusCircle.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleClick: PropTypes.func.isRequired,
};

export default StatusCircle;
