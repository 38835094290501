import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_CAMPAIGN_GROUPS } from '@store/slices/resources';
import { getSmsCampaignGroup } from '@services/requests/smsCampaigns';

export const ThunkGetSmsCampaignGroup = createAsyncThunk(
  `${SMS_CAMPAIGN_GROUPS}/getSmsCampaignGroup`,
  ({ smsGroupId }) => getSmsCampaignGroup(smsGroupId),
);

export default {};
