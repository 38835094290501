import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  linearProgress: {
    position: 'absolute',
    width: '100%',
    maxWidth: '100% !important',
  },
  tableContent: {
    backgroundColor: '#F7F9FB',
    boxShadow: 'none',
    paddingBottom: '1px',
  },
  container: {
    padding: '0 1.75rem',
  },
  root: {
    '& .MuiToolbar-gutters': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        paddingLeft: '4px',
        paddingRight: '4px',
        overflow: 'auto',
        '& .MuiTypography-subtitle1': {
          margin: '12px 0 0 12px',
        },
        '& .MuiToolbar-gutters': {
          backgroundColor: 'inherit',
        },
      },
    },
  },

  dataGridWrapper: ({ tableHeight, tableMaxHeight }) => ({
    display: 'block',
    height: tableHeight,
    maxHeight: tableMaxHeight,
    fontFamily: 'Nunito, Montserrat,"Segoe UI",Arial,sans-serif',
    fontSize: '1rem',
    borderRadius: '0 0.375rem 0.375rem 0.375rem',
    overflow: 'auto',
  }),

  standard: {
    fontSize: '1rem',
  },

  small: {
    fontSize: '0.875rem',
  },

  spaceWrapper: {
    padding: '0 1.75em',
  },

  tableWrapper: {
    width: '100%',
    position: 'relative',

    '& .ReactVirtualized__Table__headerRow': {
      background: theme.palette.listView.main,
      '&::after': {
        display: 'none',
      },
    },
    '& .rdg-header-row': {
      background: theme.palette.listView.main,
    },
    '& .content-container': {
      background: theme.palette.listView.main,
    },
  },

  table: {
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
      border: 'solid #E2E2E2',
      borderWidth: '0.0625rem 0.0625rem 0 0.0625rem',
      borderRadius: '0.375rem 0.375rem 0 0',
      cursor: 'default',
    },
  },
  tableHeader: {
    color: theme.palette.listView.headerText,
  },
  tableHeaderRow: {
    width: 'max-content',
  },
  tableRow: {
    '& div[role=gridcell]': {
      background: theme.palette.listView.main,
      cursor: 'default',
      borderRight: 'none',
      borderLeft: 'none',
      boxShadow: 'none',
    },
  },
  tableGrid: {
    border: 'solid #E2E2E2',
    borderWidth: '0 0.0625rem 0.0625rem 0.0625rem',

    '& .ReactVirtualized__Grid__innerScrollContainer': {
      backgroundColor: 'white',
    },
  },
  checkboxWrapper: {
    height: '100%',
    textAlign: 'center',
  },
  tableHeaderCell: {
    alignItems: 'flex-start',
    wordBreak: 'initial',
    width: '100%',
    background: '#F5F5F5',
    justifyContent: 'center',
    color: theme.palette.listView.headerText,
    fontSize: '1rem',
    fontWeight: 600,
  },
  tableHeaderButton: {
    textTransform: 'none',
    color: theme.palette.listView.headerText,
    fontSize: '1rem',
    fontWeight: 600,
    padding: 0,
  },
  tableHeaderButtonIcon: {
    transition: '0.3s',
    fill: theme.palette.warning.main,
  },
  tableHeaderButtonIconRotate: {
    transform: 'rotate(-180deg)',
  },
  tableHeaderDraggable: {
    padding: '0.125rem 0.5rem',
    borderRadius: '0.3125rem',
    background: theme.palette.info.light,

    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableCell: {
    flex: 1,
    cursor: 'default',
    height: '100%',
    color: theme.palette.listView.text,

    '& span': {
      msTextOverflow: 'ellipsis',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  cropCell: {
    padding: '0rem 1rem 0rem 1rem',
    alignItems: 'flex-start',
  },
  alignCellCenter: {
    textAlign: 'center',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  alignCellLeft: {
    textAlign: 'left',
  },
  alignCellRight: {
    textAlign: 'right',
  },
  noClick: {
    cursor: 'initial',
  },
  notFoundDataBlock: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F5F5F5',
    borderBottom: '1px solid #D9D5D5',
    borderLeft: '1px solid #D9D5D5',
  },
  notFoundDataBlockImage: {
    width: '2rem',
    height: '2rem',
  },
  notFoundDataBlockText: {
    color: theme.palette.info.main,
    fontSize: '1rem',
    textTransform: 'uppercase',
  },
  imgTextWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    minWidth: '120px',
  },
  vertIconContainer: ({ headerHeight }) => ({
    backgroundColor: '#b3a7a7',
    border: 'none',
    borderRadius: '15px 0px 0px 15px',
    height: headerHeight,
    /* borderRadius: '10px 5px 30px 0px', */
    position: 'absolute',
    outline: 'none',
    left: '-1.5rem',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    zIndex: 1,
    cursor: 'pointer',
  }),
  vertIcon: {
    color: '#fff',
  },
}));

export default useStyles;
