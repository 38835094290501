import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISSUE_TYPES } from '@store/slices/resources';
import { cloneIssueType } from '@services/requests/issueTypes';

export const ThunkCloneIssueType = createAsyncThunk(
  `${ISSUE_TYPES}/cloneSurvey`,
  async ({ id }) => {
    const data = await cloneIssueType(id);
    return data;
  },
);
