import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { ThunkAssignPhoneNumber } from '@store/slices/bufferPhoneNumbers/thunks';
import ReusableButton from '@ui/components/common/Button/Button';
import Table from '@components/Table/Table';
import { BUFFER_PHONE_NUMBERS } from '@services/requests/requestResources';
import { TableTypes } from '@components/Table/constants';
import { actions } from '@store/actions';
import { AVAILABLE_PHONE_NUMBERS } from '@constants/routes';

import useStyles from './styles';

const BufferPhoneNumbers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const assignPhoneNumber = useCallback(
    (id, isMain, assignType) => {
      const payload = { id, isMain, assignType };
      if (assignType) {
        delete payload.isMain;
      }
      dispatch(ThunkAssignPhoneNumber(payload)).then(({ error }) => {
        if (!error) {
          dispatch(actions.removeItemFromList({ id }));
        }
      });
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Phone Number',
        key: 'phoneNumber',
        type: TableTypes.phoneNumber,
        minWidth: 200,
      },
      {
        name: 'Country',
        key: 'country',
        minWidth: 100,
      },
      {
        name: 'Type',
        key: 'type',
        minWidth: 100,
        isUpperCase: true,
      },
      {
        name: 'Status',
        key: 'status',
        minWidth: 200,
      },
      {
        width: 470,
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        minWidth: 470,
        accessor: item => (
          <>
            {item.isAvailable && (
              <Grid container justifyContent="space-between">
                <ReusableButton
                  label="Assign AI Assistant"
                  onClick={() => {
                    assignPhoneNumber(item.id, true, 'ai-assistant');
                  }}
                />
                <ReusableButton
                  label="Assign Main"
                  onClick={() => {
                    assignPhoneNumber(item.id, true);
                  }}
                />
                <ReusableButton
                  label="Assign Channel"
                  onClick={() => {
                    assignPhoneNumber(item.id, false);
                  }}
                />
              </Grid>
            )}
          </>
        ),
      },
    ],
    [assignPhoneNumber],
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <ReusableButton
          label="Purchase"
          onClick={() => {
            history.push(AVAILABLE_PHONE_NUMBERS);
          }}
        />
      </div>
      <Table
        columns={columns}
        resource={BUFFER_PHONE_NUMBERS}
        className={classes.table}
      />
    </div>
  );
};

export default BufferPhoneNumbers;
