import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  testBox: {
    minHeight: '300px',
    padding: '0 10px',
  },
  identifierErrors: {
    margin: '4px 0',
  },
}));

export default useStyles;
