import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import getItemName from '@components/Helpers/fileNameHelpers';
import { Button } from '@material-ui/core';
import getIconByMimeType, { downloadBase64File } from './helper';

import useStyles from '../styles';

const AttachmentBadge = ({ item, isMaxContent = false }) => {
  const classes = useStyles({ isMaxContent });

  const handleClick = () =>
    downloadBase64File(item.contentType, item.content, item.name);

  return (
    <Button
      onClick={handleClick}
      className={cx(classes.flex, classes.attachmentBadge)}
    >
      <img
        src={getIconByMimeType(item.name)}
        alt="type"
        className={classes.marginRight}
      />
      {getItemName(item.name)}
    </Button>
  );
};

AttachmentBadge.propTypes = {
  item: PropTypes.shape({
    ContentId: PropTypes.string,
    content: PropTypes.string,
    contentLength: PropTypes.number,
    contentType: PropTypes.string,
    name: PropTypes.string,
    isMaxContent: PropTypes.bool,
  }),
};

export default AttachmentBadge;
