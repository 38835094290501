import { createAsyncThunk } from '@reduxjs/toolkit';
import { reOpenIncident } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';
import { normalizeIncident } from '../generator';

export const ThunkReOpenIncident = createAsyncThunk(
  `${INCIDENT}/reOpenIncident`,
  async id => {
    const incident = await reOpenIncident(id);
    const logs = incident[0].logs.split(';');
    return {
      incident: normalizeIncident(incident[0]),
      logs,
    };
  },
);

export default {};
