import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  codeBlockCopyButton: {
    marginBottom: '-9px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
}));

export default useStyles;
