import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHaloPodDataByTicketId } from '@services/requests/psaPods';
import { COMMON_POD } from '@store/slices/resources';

export const ThunkGetHaloPodDataByTicketId = createAsyncThunk(
  `${COMMON_POD}/getHaloPodDataByTicketId`,
  async ({ crmId, ticketId }) => getHaloPodDataByTicketId(crmId, ticketId),
);

export default {};
