import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkPodSaveToContact } from '@store/slices/psaPods/thunks';
import { actions } from '@store/actions';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';

import useSharedStyles from '@ui/components/CrmTicket/sharedStyles';
import usePodSaveContactRoute from '@components/Hooks/usePodSaveContactRoute';

const EditContactDialog = ({
  open,
  setOpen,
  psaType,
  record,
  psaId,
  contactId,
}) => {
  const [processing, setProcessing] = useState(false);

  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();

  const saveContactRoute = usePodSaveContactRoute({ psaType });

  const submit = async values => {
    setProcessing(true);
    const payload = {
      route: saveContactRoute,
      type: 'Phone',
      value: values.phone,
      crmId: psaId,
      contactId,
    };
    await dispatch(ThunkPodSaveToContact(payload))
      .unwrap()
      .then(() => {
        setOpen(false);
        dispatch(
          actions.updateItemInList({
            ...record,
            phone: values.phone,
          }),
        );
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Edit Contact</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={record}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Phone"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={sharedClasses.alignLeft}
                content={
                  <Field
                    name="phone"
                    id="phone"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

EditContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  psaType: PropTypes.string.isRequired,
  record: PropTypes.shape({
    phone: PropTypes.string,
  }).isRequired,
  psaId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};

export default EditContactDialog;
