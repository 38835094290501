import { createAsyncThunk } from '@reduxjs/toolkit';
import { CRM_TICKET } from '@store/slices/resources';
import { assignHaloTicketContact } from '@services/requests/haloTickets';

export const ThunkAssignHaloTicketContact = createAsyncThunk(
  `${CRM_TICKET}/assignHaloTicketContact`,
  async ({ crmId, ticketId, contactId }) => {
    await assignHaloTicketContact(crmId, ticketId, contactId);
    return true;
  },
);

export default {};
