import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useAsync from '@services/api/common/useAsync';
import Loading from '../common/Loading/Loading';

import { getMailBoxes } from './helpers';

const DocumentsPage = () => {
  const [data, setData] = useState();
  const [mailBoxId] = useState(localStorage.getItem('mailBoxId'));
  const { push } = useHistory();
  useAsync(getMailBoxes, data => {
    setData(data[0].id);
  });

  useEffect(() => {
    if (mailBoxId) {
      push(`mailBox/${mailBoxId}/documents`);
    }
    if (data) {
      push(`mailBox/${data}/documents`);
    }
  }, [mailBoxId, data]);
  return <Loading />;
};

export default DocumentsPage;
