import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteCrmIntegration } from '@services/requests/crm';

export const ThunkDeleteIntegration = createAsyncThunk(
  'crm/deleteIntegration',
  crmId =>
    deleteCrmIntegration(crmId).then(res =>
      res.status === 200 ? true : res.json(),
    ),
);

export default {};
