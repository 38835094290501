import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@store/actions';

const useDeleteRecord = (apiRequest, callback) => {
  const [open, setOpen] = useState(false);
  const [removeId, setRemoveId] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onCancel = () => {
    setOpen(false);
    setRemoveId(null);
    setLoading(false);
  };

  const handleDeleteRecordWrapper = async () => {
    try {
      setLoading(true);
      await apiRequest(removeId);
      if (callback) callback(removeId);
      else {
        dispatch(actions.removeItemFromList({ id: removeId }));
      }
      onCancel();
    } catch (e) {
      console.error(e.message);
      onCancel();
    }
  };
  const handleDeleteIcon = id => {
    setOpen(true);
    setRemoveId(id);
  };
  return {
    open,
    loading,
    onCancel,
    onDelete: handleDeleteRecordWrapper,
    handleDeleteIcon,
  };
};

export default useDeleteRecord;
