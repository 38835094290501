import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';

import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from '../styles';

const DialogParsedObject = ({ object, onClose, open = true }) => {
  const classes = useStyles();

  const fields = useMemo(() => {
    try {
      return Object.entries(JSON.parse(object));
    } catch (e) {
      return [['Data', object]];
    }
  }, [object]);

  const renderObject = obj => {
    if (typeof obj === 'string') {
      return obj;
    }
    const ObjectKeys = Object.keys(obj);

    return ObjectKeys.map(key => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        return (
          <TreeItem
            nodeId={crypto.randomUUID()}
            key={crypto.randomUUID()}
            style={{ marginLeft: '20px' }}
            label={key}
          >
            {renderObject(obj[key])}
          </TreeItem>
        );
      }

      return (
        <div key={crypto.randomUUID()} style={{ marginLeft: '20px' }}>
          <div>
            <div>
              <b>{`${key}:`}</b>
            </div>
            <div style={{ wordBreak: 'break-word' }}>{obj[key]}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent style={{ width: '700px' }}>
        <TreeView
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            borderRadius: '5px',
          }}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ExpandMoreIcon />}
        >
          {fields.map(([key, value]) => (
            <div
              key={key}
              style={{
                background: '#ededed',
                padding: '10px',
                boxSizing: 'border-box',
              }}
            >
              <div>
                <b>{`${key}:`}</b>
              </div>
              <div style={{ marginLeft: '10px' }}>{renderObject(value)}</div>
            </div>
          ))}
        </TreeView>

        <div>
          <ReusableButton label="Close" onClick={onClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

DialogParsedObject.propTypes = {
  object: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default DialogParsedObject;
