import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-final-form';

import { reorder } from '@components/AuditViews/helpers/reorder';
import StepsHolderItem from '@components/ChatBots/components/StepsHolderItem';
import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const StepsHolder = () => {
  const [list, setList] = useState([]);

  const classes = useStyles();
  const form = useForm();

  const handleDeleteStep = useCallback(
    itemId => {
      setList(list.filter(i => i.id !== itemId));
    },
    [list],
  );

  const handleUpdateStep = useCallback(
    item => {
      setList(list.map(i => (i.id === item.id ? item : i)));
    },
    [list],
  );

  const handleAddStep = useCallback(() => {
    setList([
      ...list,
      { id: crypto.randomUUID(), message: '', openInEditMode: true },
    ]);
  }, [list]);

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newRecord = reorder(list, source.index, destination.index);
    setList(newRecord);
  };

  useEffect(() => {
    if (list?.length) {
      form.change('steps', list);
    }
  }, [list, form]);

  useEffect(() => {
    const steps = form.getState().values?.steps;
    if (steps) {
      setList(
        steps.map(i => ({ id: crypto.randomUUID(), message: i.message })),
      );
    }
  }, [form]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="stepper-list">
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.stepsHolderList}
          >
            {list.map((r, index) => (
              <StepsHolderItem
                key={r.id}
                item={r}
                index={index}
                handleUpdateStep={handleUpdateStep}
                handleDeleteStep={handleDeleteStep}
                openInEditMode={r.openInEditMode}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <ReusableButton label="Add Step" onClick={handleAddStep} />
    </DragDropContext>
  );
};

export default StepsHolder;
