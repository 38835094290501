import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '@components/CreateTicket/styles';
import {
  ThunkGetHaloClients,
  ThunkGetHaloStatuses,
  ThunkGetHaloTeams,
  ThunkGetHaloTicketTypes,
  ThunkGetHaloSites,
  ThunkGetHaloUsers,
} from '@store/slices/integration/thunks';
import { Field } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import { OnChange } from 'react-final-form-listeners';

const HaloClientPortalForm = ({ values, form, crmId, item }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const [clients, setClients] = useState([]);
  const [teams, setTeams] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);

  // dependent entities
  const [sites, setSites] = useState([]);
  const [users, setUsers] = useState([]);

  const onMount = useCallback(async () => {
    setProcessing(true);
    await Promise.all([
      new Promise(res => res(dispatch(ThunkGetHaloClients(crmId)).unwrap())),
      new Promise(res => res(dispatch(ThunkGetHaloTeams(crmId)).unwrap())),
      new Promise(res => res(dispatch(ThunkGetHaloStatuses(crmId)).unwrap())),
      new Promise(res =>
        res(dispatch(ThunkGetHaloTicketTypes(crmId)).unwrap()),
      ),
    ])
      .then(res => {
        setClients(res[0]);
        setTeams(res[1]);
        setStatuses(res[2]);
        setTicketTypes(res[3]);
      })
      .finally(() => setProcessing(false));
  }, [dispatch, crmId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handleClientIdChange = useCallback(
    (value, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change('default.SiteId', '');
          form.change('default.UserId', '');
        });
      }

      setProcessing(true);
      dispatch(ThunkGetHaloSites({ crmId, clientId: value }))
        .unwrap()
        .then(d => setSites(d))
        .finally(() => setProcessing(false));
    },
    [crmId, dispatch, form],
  );

  const handleSiteIdChange = useCallback(
    (value, clientId, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change('default.UserId', '');
        });
      }

      if (!value) return;

      setProcessing(true);
      dispatch(ThunkGetHaloUsers({ crmId, clientId, siteId: value }))
        .unwrap()
        .then(d => setUsers(d))
        .finally(() => setProcessing(false));
    },
    [crmId, dispatch, form],
  );

  useEffect(() => {
    if (item) {
      handleClientIdChange(item.default.ClientId, true);
      handleSiteIdChange(item.default.SiteId, item.default.ClientId, true);
    }
  }, []);

  return (
    <>
      <FieldWrapper
        label="Client"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.ClientId"
            name="default.ClientId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={clients}
            disabled={processing}
          />
        }
      />

      <OnChange name="default.ClientId">
        {value => handleClientIdChange(value)}
      </OnChange>

      <FieldWrapper
        label="Site"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.SiteId"
            name="default.SiteId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={sites}
            disabled={processing || !values?.default?.ClientId}
          />
        }
      />

      <OnChange name="default.SiteId">
        {value => handleSiteIdChange(value, values.default.ClientId)}
      </OnChange>

      <FieldWrapper
        label="User"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.UserId"
            name="default.UserId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={users}
            disabled={
              processing ||
              !values?.default?.ClientId ||
              !values?.default?.SiteId
            }
          />
        }
      />

      <FieldWrapper
        label="Team"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TeamId"
            name="default.TeamId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={teams}
            disabled={processing}
          />
        }
      />
      <FieldWrapper
        label="Open Ticket Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.OpenStatusId"
            name="default.OpenStatusId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={statuses}
            disabled={processing}
          />
        }
      />
      <FieldWrapper
        label="Closed Ticket Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.ClosedTicketStatusId"
            name="default.ClosedTicketStatusId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={statuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Message Received Status"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketOnMessageStatusId"
            name="default.TicketOnMessageStatusId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={statuses}
            disabled={processing}
          />
        }
      />

      <FieldWrapper
        label="Ticket Type"
        labelSize={12}
        contentSize={12}
        isRequired
        content={
          <Field
            id="default.TicketTypeId"
            name="default.TicketTypeId"
            size="small"
            suppressInlineLabel
            labelName="name"
            valueName="id"
            component={DropDown}
            options={ticketTypes}
            disabled={processing}
          />
        }
      />
    </>
  );
};

export default HaloClientPortalForm;
