import crmSources from '@constants/crmSources';
import { validateRequired } from '@common/functions/validators';

const ATvalidateRequired = values => {
  return {
    name: validateRequired(values.name),
    publicKey: validateRequired(values.publicKey),
    privateKey: validateRequired(values.privateKey),
  };
};

const CWvalidateRequired = values => {
  return {
    name: validateRequired(values.name),
    uri: validateRequired(values.uri),
    publicKey: validateRequired(values.publicKey),
    privateKey: validateRequired(values.privateKey),
    companyId: validateRequired(values.companyId),
  };
};

const ServiceNowvalidateRequired = values => {
  return {
    name: validateRequired(values.name),
    uri: validateRequired(values.uri),
    publicKey: validateRequired(values.publicKey),
    privateKey: validateRequired(values.privateKey),
    companyQuery: validateRequired(values.companyQuery),
    companyTableSource: validateRequired(values.companyTableSource),
  };
};

const KaseyavalidateRequired = values => {
  return {
    name: validateRequired(values.name),
    publicKey: validateRequired(values.publicKey),
    privateKey: validateRequired(values.privateKey),
  };
};

const SyncroMspvalidateRequired = values => {
  return {
    name: validateRequired(values.name),
    uri: validateRequired(values.uri),
    privateKey: validateRequired(values.privateKey),
  };
};

const HaloBaseSettingsValidation = values => ({
  name: validateRequired(values.name),
  publicKey: validateRequired(values.publicKey),
  privateKey: validateRequired(values.privateKey),
  uri: validateRequired(values.uri),
});

const getValidation = (values, crmType) => {
  switch (crmType) {
    case crmSources.ConnectWise.name:
      return CWvalidateRequired(values);
    case crmSources.Autotask.name:
      return ATvalidateRequired(values);
    case crmSources.Kaseya.name:
      return KaseyavalidateRequired(values);
    case crmSources.ServiceNow.name:
      return ServiceNowvalidateRequired(values);
    case crmSources.SyncroMsp.name:
      return SyncroMspvalidateRequired(values);
    case crmSources.Halo.name:
      return HaloBaseSettingsValidation(values);
    default:
      return null;
  }
};

export default getValidation;
