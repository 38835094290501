import { createAsyncThunk } from '@reduxjs/toolkit';
import { addCrmTicketTimeEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkAddConnectWiseTicketTimeEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/addCrmTicketTimeEntry`,
  async ({ crmId, payload }) => {
    const timeEntry = await addCrmTicketTimeEntry(crmId, payload);
    return timeEntry;
  },
);

export default {};
