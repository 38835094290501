import React from 'react';
import PropTypes from 'prop-types';

const TitledValueAccessor = ({ value }) => <span title={value}>{value}</span>;

TitledValueAccessor.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.any),
  ]),
};

export default TitledValueAccessor;
