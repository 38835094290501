import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import DropDown from '@components/Auth/Common/DropDown';
import { periodOptions } from './options';
import useStyles from './styles';

const TimeoutFilter = () => {
  const classes = useStyles();
  return (
    <div className={classes.flexDisplaying}>
      <span>A device goes offline for</span>
      <Field
        id="timeout"
        name="timeout"
        styleType="main"
        inputView="text"
        classNameWrapper={cx(classes.fieldWrapper, classes.sizesWidth)}
        component={DropDown}
        options={periodOptions}
      />
      <span>minutes</span>
    </div>
  );
};

export default TimeoutFilter;
