import { createContext, useReducer } from 'react';

export const SignInContext = createContext(null);
export const SingInDispatchContext = createContext(null);

export const useSignContext = () => {
  const tasksReducer = (tasks, action) => {
    switch (action.type) {
      case 'addUserData': {
        return {
          ...tasks,
          userData: action.userData,
        };
      }
      case 'setSelectedPlan': {
        return {
          ...tasks,
          selectedPlanId: action.planId,
        };
      }
      case 'setTechnicianCount': {
        return {
          ...tasks,
          technicianCount: +action.technicianCount,
        };
      }
      case 'setInitTechnicianCount': {
        return {
          ...tasks,
          initTechnicianCount: +action.initTechnicianCount,
        };
      }
      case 'setCardData': {
        return {
          ...tasks,
          cardData: action.cardData,
        };
      }
      default: {
        throw Error(`Unknown action: ${action.type}`);
      }
    }
  };

  const [signInData, contextDispatch] = useReducer(tasksReducer, {
    userData: {},
    selectedPlanId: undefined,
    technicianCount: 0,
    initTechnicianCount: 0,
    cardData: {},
  });

  return {
    signInData,
    contextDispatch,
  };
};
