import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolBar: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1.875rem',
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1.25rem',
    },
  },
  button: {
    '&:last-child': {
      marginLeft: '0.8rem',
    },
  },
  tableHeaderRow: {
    verticalAlign: 'top',
    paddingTop: 5,
  },
  tableHeaderCell: {
    textAlign: 'center',
    paddingTop: 15,
  },
  tableHeaderForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  tableHeaderButton: {
    position: 'absolute',
    zIndex: 2,
    top: '0.7rem',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 'auto',
    padding: 0,

    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  tableHeaderButtonIcon: {
    transition: '0.3s',
  },
  tableHeaderButtonIconRotate: {
    transform: 'rotate(-180deg)',
  },
  tableHeaderInput: {
    '& input': {
      padding: '5px 21px',
      borderRadius: '0.3125rem',
      border: '0.0625rem solid #ebc7c0',
      background: theme.palette.input.background,
    },

    '& .MuiFilledInput-underline:before': {
      display: 'none',
    },
  },
  inputSmallWrapper: {
    marginTop: '0.49rem',
    border: 'none',

    '& .MuiSelect-select': {
      padding: '0.33rem 1.2rem',
      borderRadius: '0.1875rem',
      background: theme.palette.input.background,
      border: '0.0625rem solid #ebc7c0',
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.input.color,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(1.3rem, 0rem) scale(1)',
    },
  },
  circleWrapper: {
    marginRight: '0.125rem',

    '&:last-child': {
      marginRight: 0,
    },
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    flexGrow: 1,
  },
  wrapper: {
    position: 'relative',
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
    },
    margin: '20px 30px -25px',
  },
  warningMessage: {
    textAlign: 'center',
    marginTop: '1rem',
    color: 'red',
  },
  switcher: {
    padding: '0.38rem 0',
    '& .MuiTypography-root': {
      transform: 'rotate(180deg) scaleX(-1)',
    },
    '& .MuiSwitch-thumb': {
      background: 'linear-gradient(180deg, #CBCBCB 0%, #CBCBCB 100%)',
    },
    '& .Mui-checked': {
      transform: 'translateX(30px)',
    },
  },
  switcherForm: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up('lg')]: {
      width: '28rem',
    },
  },
  formControl: {
    [theme.breakpoints.up('lg')]: {
      top: '3.5rem',
      zIndex: 2,
    },
  },

  margin: {
    margin: '1rem',
  },
  tabsWrapper: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingTop: '1.75rem',
    marginTop: 0,
    borderBottom: '0.0625rem solid #D9D5D5',
    display: 'flex',
  },
  badge: {
    borderRadius: '2px',
    padding: '7px 25px',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  ticketsContainer: {
    minWidth: '45rem',
    minHeight: '15rem',
  },
  wrap: {
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  tableControls: {
    padding: '10px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  aligned: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '0px',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  table: {
    padding: '20px',
    boxSizing: 'border-box',
  },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  actions: {
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
}));

export default useStyles;
