import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendFormData, uploadFile } from '@services/api';
import appConfig from '@configs/appConfig';
import { CLIENT_PORTALS } from '@store/slices/resources';

export const ThunkAddClientPortalLogo = createAsyncThunk(
  `${CLIENT_PORTALS}/addClientPortalLogo`,
  ({ file, resourceId }) => {
    const formData = new FormData();
    formData.append('file', file);

    if (!resourceId) {
      return uploadFile(
        `${appConfig.baseUrl}/clientPortal/configurations/upload-logo`,
        formData,
      );
    }
    return sendFormData(
      `${appConfig.baseUrl}/clientPortal/configurations/${resourceId}/upload-logo`,
      formData,
    );
  },
);

export default {};
