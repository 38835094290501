/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ThunkGetSmsCampaignGroups } from '@store/slices/smsCampaigns/thunks/getSmsCampaignGroups';
import { ThunkAddSmsCampaignGroup } from '@store/slices/smsCampaigns/thunks/addSmsCampaignGroup';
import { SMS_CAMPAIGN_GROUPS } from '../resources';

const initialState = {
  campaignGroups: [],
};

const smsCampaignGroupSlice = createSlice({
  name: SMS_CAMPAIGN_GROUPS,
  initialState,
  reducers: {
    deleteSmsCampaignGroup: (state, action) => {
      state.campaignGroups = state.campaignGroups.filter(
        p => p.id !== action.payload,
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(
      ThunkGetSmsCampaignGroups.fulfilled,
      (state, { payload }) => {
        state.campaignGroups = payload;
      },
    );

    builder.addCase(
      ThunkAddSmsCampaignGroup.fulfilled,
      (state, { payload }) => {
        state.campaignGroups.push(payload);
      },
    );
  },
});

export default smsCampaignGroupSlice.reducer;

export const { deleteSmsCampaignGroup } = smsCampaignGroupSlice.actions;

export const SmsCampaignGroupsAutocompleteSelector = state =>
  state[SMS_CAMPAIGN_GROUPS].campaignGroups;
