import React from 'react';
import { ReactComponent as ParsersIcon } from '@assets/icons/parsers.svg';
import List from './CustomFields';

const Icon = () => <ParsersIcon alt="customFieldsIcon" />;

export default {
  list: List,
  icon: Icon,
};
