import React from 'react';
import PropTypes from 'prop-types';
import linkifyHtml from 'linkify-html';
import * as linkify from 'linkifyjs';

import { defaultBgColor } from '@constants/defaultBageColors';
import displayTypes from '@constants/displayTypes';
import Circle from '@common/Circle/Circle';
import { customTypeIdMeasure } from '@constants/numericConstants';

import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';

import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';
import useStyles from './styles';

const ColumnViewSelector = ({ source, item, typeValues, record = {} }) => {
  const classes = useStyles();
  const displayedData = record[source];

  const coverWithHtml = text => {
    return typeof text === 'string' && linkify.find(text).length ? (
      <div
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(text, { target: 'blank' }),
        }}
      />
    ) : (
      text
    );
  };

  if (typeof displayedData === 'boolean') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {displayedData ? <CheckIcon /> : <ClearIcon />}
      </div>
    );
  }

  if (!item.type) {
    return <span>{coverWithHtml(displayedData)}</span>;
  }

  if (item.type < customTypeIdMeasure) {
    if (item.type === 3) {
      return displayedData
        ? moment
            .utc(record[source])
            .local()
            .format(defaultDateFormat)
        : '';
    }
    return <span>{coverWithHtml(displayedData)}</span>;
  }

  const typeValuesOfCurrentType = typeValues.filter(
    i => i.typeId === item.type,
  );
  if (item.type >= 1000 && item.displayType) {
    const dataMatches = typeValuesOfCurrentType.find(
      i => i.id === +displayedData,
    );
    if (dataMatches) {
      const firstDataMatch = dataMatches;
      if (item.displayType === displayTypes.Text) {
        return <span>{firstDataMatch.name}</span>;
      }
      if (item.displayType === displayTypes.Badge) {
        const bgColor = firstDataMatch.backgroundColor
          ? firstDataMatch.backgroundColor
          : defaultBgColor;
        return (
          <span
            className={classes.badge}
            style={{ backgroundColor: `${bgColor}` }}
          >
            {firstDataMatch.name}
          </span>
        );
      }
      if (item.displayType === displayTypes.Circle) {
        return (
          <Circle
            bgColor={firstDataMatch.circleBgColor}
            borderColor={firstDataMatch.circleBorderColor}
          />
        );
      }
    }
  }

  return (
    <div>
      <span>{coverWithHtml(displayedData)}</span>
    </div>
  );
};

ColumnViewSelector.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
  typeValues: PropTypes.arrayOf(PropTypes.object),
};

export default ColumnViewSelector;
