import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: '0 0 5px 0',
    width: '100%',
  },
  chip: {
    margin: '8px 3px',
  },
  mainBlock: {
    textAlign: 'left',
  },
  label: {
    fontSize: '14px',
    margin: '0.5rem 0',
    fontWeight: 'bold',
  },
}));

export default useStyles;
