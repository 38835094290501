import { createAsyncThunk } from '@reduxjs/toolkit';
import { TICKET_CREATION_TEMPLATES } from '@store/slices/resources';
import { createTicketCreationTemplate } from '@services/requests/ticketCreationTemplates';

const generatePayload = values => ({
  ...values,
  data: {
    ...values.data,
    createChannel: values.data.createChannel || false,
  },
});

export const ThunkCreateTicketCreationTemplate = createAsyncThunk(
  `${TICKET_CREATION_TEMPLATES}/createTicketCreationTemplate`,
  async ({ psaType, payload }) => {
    const template = await createTicketCreationTemplate(
      psaType,
      generatePayload(payload),
    );
    return {
      label: template.name,
      value: template.id,
    };
  },
);

export default {};
