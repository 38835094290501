import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { updateLiveChatConfigurations } from '@services/requests/liveChats';

export const ThunkUpdateLiveChatConfigurations = createAsyncThunk(
  `${LIVE_CHATS}/updateLiveChatConfigurations`,
  ({ configurationId, payload }) =>
    updateLiveChatConfigurations(configurationId, payload),
);

export default {};
