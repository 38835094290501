import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { deleteVerificationScreenSettings } from '@services/requests/verificationSettings';

export const ThunkDeleteCompaniesVerificationSettings = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/deleteCompaniesVerificationSettings`,
  companySettingId => deleteVerificationScreenSettings(companySettingId),
);

export default {};
