const labelSize = 3;
const contentSize = 9;
const smsMaxLength = 1224;
const emailMaxLength = 1000;
const accordionStyle = {
  display: 'block',
  padding: '1rem 3rem',
};

export { labelSize, contentSize, smsMaxLength, emailMaxLength, accordionStyle };
