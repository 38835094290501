import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field, useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';

import { ThunkGetMessagingConfigurationByCrmType } from '@store/slices/messaging/thunks';

import FormSwitch from '@components/Auth/Common/FormSwitch';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@components/Auth/Common/Input';

const TicketCreationMessagingChannel = ({ psaType, disabled }) => {
  const [processing, setProcessing] = useState(false);
  const [createChannel, setCreateChannel] = useState(false);
  const [configurations, setConfigurations] = useState([]);

  const dispatch = useDispatch();
  const form = useForm();

  const onMount = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkGetMessagingConfigurationByCrmType(psaType))
      .unwrap()
      .then(res => {
        setConfigurations(res);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [psaType, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    setCreateChannel(form.getState().values.createChannel);
  }, [form]);

  return (
    <>
      <FieldWrapper
        label="Create Messaging Channel"
        labelSize={10}
        contentSize={2}
        showLabel
        content={
          <Field
            name="createChannel"
            id="createChannel"
            render={FormSwitch}
            disabled={disabled || processing}
            checked={createChannel}
          />
        }
      />

      <OnChange name="createChannel">
        {value => {
          setCreateChannel(value);
        }}
      </OnChange>
      {createChannel && (
        <>
          <FieldWrapper
            label="Messaging Configuration"
            labelSize={3}
            contentSize={9}
            showLabel
            isRequired
            content={
              <Field
                name="messagingConfigurationId"
                id="messagingConfigurationId"
                render={DropDown}
                options={configurations}
                disabled={disabled || processing}
              />
            }
          />

          <FieldWrapper
            label="Phone Number"
            labelSize={3}
            contentSize={9}
            showLabel
            isRequired
            content={
              <Field
                name="userPhone"
                id="userPhone"
                render={Input}
                disabled={disabled || processing}
              />
            }
          />
        </>
      )}
    </>
  );
};

TicketCreationMessagingChannel.propTypes = {
  psaType: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TicketCreationMessagingChannel;
