import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontStyle: 'normal',
  },
  contentFormWrapper: {
    width: '100%',
  },
  formItem: {
    alignItems: 'center',
    fontStyle: 'normal',
  },
  monospace: {
    fontFamily: 'monospace',
  },
  textCenter: {
    textAlign: 'center',
  },
  noMargin: {
    margin: '0.5rem 0 0 0',
    width: '35rem',
    justifyContent: 'end',
  },
}));

export default useStyles;
