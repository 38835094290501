import { createAsyncThunk } from '@reduxjs/toolkit';
import { hetHaloStatuses } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetHaloStatuses = createAsyncThunk(
  `${INTEGRATION}/halo/getStatuses`,
  async id => {
    const res = await hetHaloStatuses(id);
    return res;
  },
);

export default {};
