const sanitizeProps = ({
  loading,
  loaded,
  onToggleItem,
  onUnselectItems,
  perPage,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSort,
  showFilter,
  totalPages,
  exporter,
  filterValues,
  toolTip,
  ...sanitizedProps
}) => sanitizedProps;

export default sanitizeProps;
