import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccessModulesLoadingSelector,
  AccessModulesSelector,
} from '@store/slices/admin';
import Loading from '@common/Loading/Loading';
import {
  ThunkGetAccessModules,
  ThunkUpdateAccessModules,
} from '@store/slices/admin/thunks';
import { Field, Form } from 'react-final-form';
import ReusableButton from '@common/Button/Button';
import Checkbox from '@common/Checkbox';
import { fields } from './helpers';
import useStyles from './styles';

const TenantModulesForm = ({ tenantId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);

  const model = useSelector(AccessModulesSelector);
  const loading = useSelector(AccessModulesLoadingSelector);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetAccessModules(tenantId));
  }, [dispatch, tenantId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const submit = useCallback(
    values => {
      setProcessing(true);
      dispatch(ThunkUpdateAccessModules({ tenantId, values }))
        .unwrap()
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, tenantId],
  );

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      initialValues={model}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.checkBoxesGrid}>
            {fields.map(item => (
              <div key={item.name} className={classes.formItem}>
                <Field
                  name={item.name}
                  id={item.name}
                  type="checkbox"
                  component={Checkbox}
                  disabled={processing}
                />
                <span>{item.description}</span>
              </div>
            ))}
          </div>
          <div className={classes.dialogActions}>
            <ReusableButton
              label="Submit"
              type="submit"
              disabled={processing}
            />
          </div>
        </form>
      )}
    />
  );
};

TenantModulesForm.propTypes = {
  tenantId: PropTypes.number.isRequired,
};

export default TenantModulesForm;
