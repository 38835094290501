import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import InfoBox from '@common/InfoBox/InfoBox';
import {
  accordionStyle,
  contentSize,
  labelSize,
} from '@components/Settings/VerificationSettings/components/constants';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field } from 'react-final-form';
import Checkbox from '@common/Checkbox/Checkbox';

const PsaNotesSettings = ({ processing, values }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        PSA notes settings
        <InfoBox text="Allows to adjust notes to PSA entities" />
      </AccordionSummary>
      <AccordionDetails style={accordionStyle}>
        <FieldWrapper
          label="Add note to the contact"
          labelSize={labelSize}
          contentSize={contentSize}
          fullWidth
          content={
            <Field
              name="addContactNote"
              id="addContactNote"
              render={Checkbox}
              type="checkbox"
              disabled={processing}
            />
          }
        />

        <FieldWrapper
          label="Add note to the ticket"
          labelSize={labelSize}
          contentSize={contentSize}
          fullWidth
          content={
            <Field
              name="addTicketNote"
              id="addTicketNote"
              render={Checkbox}
              type="checkbox"
              disabled={processing}
            />
          }
        />

        <FieldWrapper
          label="Make ticket note internal"
          labelSize={labelSize}
          contentSize={contentSize}
          fullWidth
          content={
            <Field
              name="internalTicketNote"
              id="internalTicketNote"
              render={Checkbox}
              type="checkbox"
              disabled={processing || !values.addTicketNote}
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  );
};

PsaNotesSettings.propTypes = {
  processing: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.any),
};

export default PsaNotesSettings;
