import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';

import { ThunkGetMessagingTemplates } from '@store/slices/messaging/thunks';
import { MessagingTemplatesSelector } from '@store/slices/messaging';

import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';

const TicketCreationInputsTemplates = ({ inputFields = {} }) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const messagingTemplates = useSelector(MessagingTemplatesSelector);
  const form = useForm();

  const handleTemplatesChange = useCallback(
    value => {
      const selectedTemplate = messagingTemplates.find(i => i.id === value);

      form.change(inputFields.description, selectedTemplate?.description || '');
      form.change(inputFields.title, selectedTemplate?.subject || '');
    },
    [form, inputFields.description, inputFields.title, messagingTemplates],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetMessagingTemplates());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const templateOptions = useMemo(() => {
    if (messagingTemplates?.length) {
      return messagingTemplates.map(i => ({
        label: i.subject,
        value: i.id,
      }));
    }
    return [];
  }, [messagingTemplates]);

  return (
    <>
      <FieldWrapper
        label="Text Templates"
        labelSize={12}
        contentSize={12}
        content={
          <Field
            name="messagingTemplateId"
            id="messagingTemplateId"
            render={AutocompleteFormInput}
            loading={loading}
            items={templateOptions}
          />
        }
      />
      <OnChange name="messagingTemplateId">{handleTemplatesChange}</OnChange>
    </>
  );
};

TicketCreationInputsTemplates.propTypes = {
  inputFields: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default TicketCreationInputsTemplates;
