import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { defaultDateFormat } from '@constants/formats';

const transform = item => {
  if (!item) return '';
  const date = item.substring(item.indexOf('[') + 1, item.lastIndexOf(']'));
  const mom = moment
    .utc(date, 'DD-MM-YY hh:mm')
    .local()
    .format(defaultDateFormat);
  return item.replace(date, mom);
};

export const getIncidentLogs = logString =>
  logString ? logString.split(';').map(transform) : [];

const TicketLogs = ({ logString }) => {
  const logs = useMemo(
    () =>
      getIncidentLogs(logString).map(i => (
        <>
          {i}
          <br />
        </>
      )),
    [logString],
  );

  return (
    <div>
      <Typography variant="h6">
        <b>Alert logs</b>
      </Typography>
      <div>
        <Typography style={{ fontFamily: 'monospace' }}>{logs}</Typography>
      </div>
    </div>
  );
};

TicketLogs.propTypes = {
  logString: PropTypes.string,
};

export default TicketLogs;
