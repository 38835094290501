import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { pricePlanInfo } from '@store/selectors';
import { PRICE_PLANS } from '@components/Layout/SideBar/sideBarItems';

export const useGetPricePlan = () => {
  const myPlan = useSelector(pricePlanInfo);

  return useMemo(() => {
    const isFreePlan =
      myPlan?.id === PRICE_PLANS.FREE && !myPlan?.isDemoSubscription;
    const isDemoPlan = myPlan?.isDemoSubscription;
    const isProPlan = myPlan?.id === PRICE_PLANS.PRO; // include all features

    return { isFreePlan, isDemoPlan, isProPlan, myPlan };
  }, [myPlan]);
};

export default {};
