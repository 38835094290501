import { createAsyncThunk } from '@reduxjs/toolkit';
import { addOptInSmsTemplate } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {Object} payload
 * @param {string} payload.smsTemplate
 * @param {string} payload.ticketNoteTemplate
 * @param {string} payload.emailSubjectTemplate
 * @param {string} payload.emailHeaderTemplate
 * @param {string} payload.emailBodyTemplate
 */
export const ThunkAddOptInSmsTemplate = createAsyncThunk(
  `${OPT_IN}/addOptInSmsTemplate`,
  async ({ payload }) => {
    const res = await addOptInSmsTemplate(payload);
    return res;
  },
);

export default {};
