import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    margin: props => (props.skipBorders ? '0' : '1rem 10vw'),
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    overflowY: 'auto',
  },
  dialogTemplate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  },
  dialogLabelText: {
    width: '100%',
    textAlign: 'end',
  },
  dialogLabelContainer: {
    width: '100%',
  },
  formItem: {
    marginBottom: '2rem',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  gradientRow: {
    display: 'flex',
    gap: '3rem',
  },
  logoField: {
    marginBottom: '1rem',
  },
  formContainer: {
    padding: '10px 30px',
    width: '100%',
  },
  linkContainer: {
    background: 'grey',
    padding: '20px',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  phoneNumberExists: {
    width: '100%',
    fontSize: 'x-large',
    fontFamily: "'Montserrat'",
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    alignItems: 'center',
  },
  noPhonePage: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
  },
}));

export default useStyles;
