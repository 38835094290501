import React, { useMemo } from 'react';
import Invoices from '@components/UnauthorizedPages/SharedBilling/Pages/Invoices';
import Address from '@components/UnauthorizedPages/SharedBilling/Pages/Address';
import Card from '@components/UnauthorizedPages/SharedBilling/Pages/Card';
import TabPanel from '@common/TabPanel/TabPanel';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Index = ({ authKey }) => {
  const classes = useStyles();

  const tabs = useMemo(
    () => [
      {
        label: 'Invoices',
        value: <Invoices authKey={authKey} />,
      },
      {
        label: 'Billing Address',
        value: <Address authKey={authKey} />,
      },
      {
        label: 'Credit Card',
        value: <Card authKey={authKey} />,
      },
    ],
    [authKey],
  );

  return (
    <TabPanel
      classNameWrapperTabs={classes.tabsWrapper}
      tabs={tabs}
      viewTypeLink="flooded"
    />
  );
};

Index.propTypes = {
  authKey: PropTypes.string,
};

export default Index;
