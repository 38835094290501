import { updateChatBot } from '@services/requests/chatBots';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CHATS_BOTS } from '../../resources';

export const ThunkUpdateChatBot = createAsyncThunk(
  `${CHATS_BOTS}/updateChatBot`,
  ({ chatBotId, payload }) => updateChatBot(chatBotId, payload),
);

export default {};
