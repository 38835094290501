import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconWidth: {
    [theme.breakpoints.up('sm')]: {
      width: '50px',
    },
  },
  tabBase: {},
  displayFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  marginLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2rem',
    },
  },
}));

export default useStyles;
