import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkGetRecentlyCreatedChannels,
  ThunkGetMessagingChannelsStatistic,
  ThunkGetMessagingCrmStatistic,
} from '@store/slices/messagingDashboard/thunks';
import {
  MessagingDashboardChannelsStatistic,
  MessagingDashboardCrmPieStatistic,
} from '@store/slices/messagingDashboard';

import Loading from '@ui/components/common/Loading/Loading';
import useStyles from './styles';

const DashboardChart = React.lazy(() => import('./components/DashboardCharts'));
const DashboardRecentTable = React.lazy(() =>
  import('./components/DashboardRecentTable'),
);

const DashboardPie = React.lazy(() => import('./components/DashboardPieChart'));

const MessagingDashboard = React.memo(() => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const dispatch = useDispatch();
  const statistic = useSelector(MessagingDashboardChannelsStatistic);
  const crmStatistic = useSelector(MessagingDashboardCrmPieStatistic);

  const onMount = useCallback(async () => {
    setLoading(true);

    await dispatch(ThunkGetRecentlyCreatedChannels());
    await dispatch(ThunkGetMessagingChannelsStatistic());
    await dispatch(ThunkGetMessagingCrmStatistic());

    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <React.Suspense fallback={<Loading />}>
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <DashboardRecentTable />
          <DashboardPie
            label="PSA Usage"
            data={crmStatistic}
            dataLabel="Channels Number"
          />
        </div>

        <div className={classes.secondRow}>
          <DashboardPie
            label="Channels"
            data={statistic.pieData}
            dataLabel="Total"
          />
          <DashboardChart />
        </div>
      </div>
    </React.Suspense>
  );
});

MessagingDashboard.propTypes = {};

export default MessagingDashboard;
