import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';

export const ThunkAddVerificationScreenLogo = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/addVerificationScreenLogo`,
  ({ file }) => {
    const formData = new FormData();
    formData.append('file', file);
    return sendFormData(
      `${appConfig.baseUrl}/VerificationSettings/upload-logo`,
      formData,
    );
  },
);

export default {};
