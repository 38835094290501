import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ThunkCloneIssueType,
  ThunkDeleteIssueType,
  ThunkGetIssueTypes,
} from '@store/slices/issueTypes/thunks';
import Loading from '@common/Loading/Loading';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import {
  IssueTypesSelector,
  pushIssueTypeToList,
} from '@store/slices/issueTypes';
import ReusableButton from '@common/Button/Button';
import { useRedirect } from 'react-admin';
import { ISSUE_TYPE, ISSUE_TYPE_CREATE } from '@constants/routes';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import CloneIcon from '@assets/icons/copy_blue.svg';

import useStyles from './styles';

const IssueTypes = () => {
  const [loading, setLoading] = useState(true);
  const [deleteDialogProps, setDeleteDialogProps] = useState({
    isOpen: false,
  });

  const dispatch = useDispatch();
  const redirect = useRedirect();
  const classes = useStyles();

  const handleDelete = useCallback(
    id => {
      dispatch(ThunkDeleteIssueType({ id }))
        .unwrap()
        .finally(() => {
          setDeleteDialogProps({ isOpen: false });
        });
    },
    [dispatch],
  );

  const handleClone = useCallback(
    id => {
      dispatch(ThunkCloneIssueType({ id }))
        .unwrap()
        .then(newRecord => dispatch(pushIssueTypeToList(newRecord)));
    },
    [dispatch],
  );

  const columns = [
    {
      label: 'Title',
      value: 'title',
    },
    {
      label: 'Actions',
      headerClassName: classes.actionsColumn,
      handler: item => (
        <div className={classes.actionsColumn}>
          <ActionFieldItem
            handler={() => {
              redirect(ISSUE_TYPE.replace(':id', item.id));
            }}
            toolTip="Edit"
            icon={EditIcon}
          />

          <ActionFieldItem
            handler={() => {
              setDeleteDialogProps({ isOpen: true, id: item.id });
            }}
            toolTip="Delete"
            icon={DeleteIcon}
          />

          <ActionFieldItem
            handler={() => handleClone(item.id)}
            toolTip="Clone"
            icon={CloneIcon}
          />
        </div>
      ),
    },
  ];

  const handleClick = useCallback(() => {
    redirect(ISSUE_TYPE_CREATE);
  }, []);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetIssueTypes())
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loading />;
  return (
    <div className={classes.tableContainer}>
      <div>
        <ReusableButton label="Create New" onClick={handleClick} />
      </div>
      <SimpleTable selector={IssueTypesSelector} columns={columns} />
      {deleteDialogProps.isOpen && (
        <ConfirmDialog
          onAccept={() => {
            handleDelete(deleteDialogProps.id);
          }}
          onCancel={() => {
            setDeleteDialogProps({ isOpen: false });
          }}
          title="Confirm action"
          content="Are you sure you want to delete this item?"
          open={deleteDialogProps.isOpen}
        />
      )}
    </div>
  );
};

export default IssueTypes;
