import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Loading } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { ThunkGetCrmDefaultsAutocomplete } from '@store/slices/crm/thunks';
import { ThunkLinkTriageToTicket } from '@store/slices/triage/thunks';
import { ThunkGetCrmTicketPhoneNumber } from '@store/slices/crmTicket/thunks';
import { ThunkGetMessagingConfigurationByCrmType } from '@store/slices/messaging/thunks';

import { requiredValidator } from '@utils/validators';
import phoneValidation from '@constants/phoneValidation';

import DropDown from '@ui/components/Auth/Common/DropDown';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import FormSwitch from '@ui/components/Auth/Common/FormSwitch';

import { availableCrmOptions } from '@components/Messaging/helpers';
import crmSources from '@constants/crmSources';

import useStyles from '../styles';
import useSharedStyles from '../../../CrmTicket/sharedStyles';

const AttachTicketDialog = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [crmDefaults, setCrmDefaults] = useState([]);
  const [configuration, setConfigurations] = useState([]);
  const [syncTicket, setSyncTicket] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const list = useSelector(listSelector);

  const crmOptions = Object.values(crmSources)
    .filter(crm => _.includes(availableCrmOptions, crm.idx))
    .map(i => ({
      label: i.label,
      value: i.idx,
    }));

  const handlePhoneSync = ({ ticketId, crmDefaultId }, form) => {
    setSyncTicket(true);
    dispatch(ThunkGetCrmTicketPhoneNumber({ crmId: crmDefaultId, ticketId }))
      .then(data => {
        if (data && data.payload) {
          form.change('userPhone', data.payload.contactPhone);
          setTicketInfo(data.payload);
        }
      })
      .finally(() => setSyncTicket(false));
  };

  const handleCrmChange = (id, form) => {
    setProcessing(true);
    const defaults = crmDefaults.filter(option => option.info === id);
    if (defaults.length === 1) {
      form.change('crmDefaultId', defaults[0].value);
    }
    dispatch(ThunkGetMessagingConfigurationByCrmType(id))
      .unwrap()
      .then(res => {
        setConfigurations(res);
        if (res.length === 1) {
          form.change('messagingConfigurationId', res[0].value);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const submit = async values => {
    setProcessing(true);
    let payload = {
      ticketId: values.ticketId,
      crmDefaultId: values.crmDefaultId,
    };
    if (values.createChannel) {
      payload = {
        ticketId: values.ticketId,
        crmDefaultId: values.crmDefaultId,
        messagingConfigurationId: values.messagingConfigurationId,
        userPhone: values.userPhone,
        smsMessage: values.smsMessage,
        createChannel: values.createChannel,
      };
    }
    await dispatch(ThunkLinkTriageToTicket(payload))
      .unwrap()
      .then(res => {
        dispatch(actions.saveList([...list, res]));
        setOpen(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      crmId: requiredValidator(values.crmId),
      crmDefaultId: requiredValidator(values.crmDefaultId),
      ticketId: requiredValidator(values.ticketId),
      messagingConfigurationId: values.createChannel
        ? requiredValidator(values.messagingConfigurationId)
        : undefined,
      userPhone: values.createChannel
        ? phoneValidation(values.userPhone)
        : undefined,
      smsMessage: values.createChannel
        ? requiredValidator(values.smsMessage)
        : undefined,
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(ThunkGetCrmDefaultsAutocomplete()).unwrap();
    setCrmDefaults(res);
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Attach Ticket</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="PSA"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="crmId"
                      id="crmId"
                      render={DropDown}
                      options={crmOptions}
                      disabled={processing}
                    />
                  }
                />

                <OnChange name="crmId">
                  {value => handleCrmChange(value, form)}
                </OnChange>

                <FieldWrapper
                  label="PSA Default"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="crmDefaultId"
                      id="crmDefaultId"
                      render={DropDown}
                      options={crmDefaults.filter(
                        option => option.info === values.crmId,
                      )}
                      disabled={!values.crmId || processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Ticket Id"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="ticketId"
                      id="ticketId"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Create Messaging Channel"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="createChannel"
                      id="createChannel"
                      render={FormSwitch}
                      disabled={processing || !values.crmId}
                    />
                  }
                />
                {values.createChannel && (
                  <>
                    <FieldWrapper
                      label="Messaging Configuration"
                      labelSize={12}
                      contentSize={12}
                      showLabel
                      classNameLabelInner={classes.alignLeft}
                      isRequired
                      content={
                        <Field
                          name="messagingConfigurationId"
                          id="messagingConfigurationId"
                          render={DropDown}
                          options={configuration}
                          disabled={processing}
                          rows={4}
                          multiline
                        />
                      }
                    />

                    <FieldWrapper
                      label="Phone Number"
                      labelSize={12}
                      contentSize={12}
                      showLabel
                      classNameLabelInner={classes.alignLeft}
                      isRequired
                      infoText="+Country ex +1 for US must be used before the phone number"
                      content={
                        <Field
                          name="userPhone"
                          id="userPhone"
                          render={Input}
                          disabled={processing || syncTicket}
                        />
                      }
                    />

                    <ReusableButton
                      label="Sync With Ticket"
                      disabled={!values.ticketId || syncTicket}
                      onClick={() => {
                        handlePhoneSync(values, form);
                      }}
                      loading={syncTicket}
                    />

                    {ticketInfo && (
                      <>
                        <div>
                          <span>Company: </span>
                          {ticketInfo.companyName}
                        </div>
                        <div>
                          <span>Contact: </span>
                          {ticketInfo.contactName}
                        </div>
                      </>
                    )}

                    <FieldWrapper
                      label="SMS Message"
                      labelSize={12}
                      contentSize={12}
                      showLabel
                      classNameLabelInner={classes.alignLeft}
                      isRequired
                      content={
                        <Field
                          name="smsMessage"
                          id="smsMessage"
                          render={Input}
                          disabled={processing}
                        />
                      }
                    />
                  </>
                )}

                <div className={sharedClasses.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      setOpen(false);
                    }}
                    disabled={processing}
                  />

                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AttachTicketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AttachTicketDialog;
