import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTAL_MAPPINGS } from '@store/slices/resources';
import { getClientPortalDefaultMappings } from '@services/requests/clientPortal';

export const ThunkGetClientPortalDefaultsMappings = createAsyncThunk(
  `${CLIENT_PORTAL_MAPPINGS}/getClientPortalDefaultsMappings`,
  ({ id }) => getClientPortalDefaultMappings(id),
);

export default {};
