import { makeRequest } from '@services/requests/makeRequest';
import {
  USERS,
  PSA_CONTACT_CLIENT_PORTAL,
} from '@services/requests/requestResources';

export const sendUserInvites = payload =>
  makeRequest('POST', `${USERS}/invite-users`, payload);

export const checkUserInviteCode = code =>
  makeRequest('GET', `${USERS}/check-invitation-code?code=${code}`);

export const deleteUserInvite = id =>
  makeRequest('DELETE', `${USERS}/invite-users/${id}`);

export const createUserFromInvite = payload =>
  makeRequest('POST', `${USERS}/create-invited-users`, payload);

export const bulkSendUserInvites = payload =>
  makeRequest(
    'POST',
    `${PSA_CONTACT_CLIENT_PORTAL}/bulkAssignContact`,
    payload,
  );

export default {};
