import React from 'react';
import PropTypes from 'prop-types';
import PlaneLoader from '@assets/icons/plane_loader.svg';
import { Backdrop } from '@material-ui/core';
import useStyles from './styles';

const FullScreenLoading = ({ display }) => {
  const classes = useStyles();
  return (
    display && (
      <Backdrop className={classes.backdrop} open={display}>
        <img src={PlaneLoader} alt="Loading" />
      </Backdrop>
    )
  );
};

FullScreenLoading.propTypes = {
  display: PropTypes.bool.isRequired,
};

export default FullScreenLoading;
