import React from 'react';
import { ReactComponent as TenantSettingsIcon } from '@assets/icons/tenantSettings.svg';
import TenantSettings from './TenantSettings';

const Icon = () => <TenantSettingsIcon alt="tenantSettingsIcon" />;

export default {
  list: TenantSettings,
  icon: Icon,
};
