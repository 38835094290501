import { createAsyncThunk } from '@reduxjs/toolkit';
import { addATTicketTimeEntry } from '@services/requests/crmTicket';
import { CRM_TICKET } from '@store/slices/resources';

export const ThunkAddATTicketTimeEntry = createAsyncThunk(
  `${CRM_TICKET}/addATTicketTimeEntry`,
  async ({ crmId, ticketId, payload }) => {
    const { resource, ...rest } = payload;
    const timeEntry = await addATTicketTimeEntry(crmId, ticketId, rest);
    return { ...timeEntry, resource };
  },
);

export default {};
