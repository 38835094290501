import { createSlice } from '@reduxjs/toolkit';
import { TRIAGE } from '../resources';

import { ThunkCreateTriageTicket, ThunkCloseTriageTicket } from './thunks';

const triageSlice = createSlice({
  name: TRIAGE,
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkCreateTriageTicket.fulfilled, (state, { payload }) => {
      return payload;
    });

    builder.addCase(ThunkCloseTriageTicket.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export default triageSlice.reducer;
