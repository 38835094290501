import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { sendResetPasswordEmail } from '@services/requests/auth';

export const ThunkSendResetPasswordEmail = createAsyncThunk(
  `${AUTH}/sendResetPasswordEmail`,
  ({ payload }) => sendResetPasswordEmail(payload),
);

export default {};
