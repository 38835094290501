import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmSources } from '@services/requests/treeView';
import { TREE_VIEW } from '@store/slices/resources';

export const ThunkGetCrmSources = createAsyncThunk(
  `${TREE_VIEW}/getCrmSources`,
  async () => {
    const sources = await getCrmSources();
    return sources;
  },
);

export default {};
