import { createAsyncThunk } from '@reduxjs/toolkit';
import { CONNECTWISE_POD } from '@store/slices/resources';
import { getConnectwisePodDataByContactId } from '@services/requests/psaPods';

export const ThunkGetConnectWisePodDataByContactId = createAsyncThunk(
  `${CONNECTWISE_POD}/getConnectwisePodDataByContactId`,
  async ({ crmId, contactId }) => {
    const res = await getConnectwisePodDataByContactId(crmId, contactId);
    return res;
  },
);

export default {};
