import { createAsyncThunk } from '@reduxjs/toolkit';
import { addIncidentNote } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';

export const ThunkAddIncidentNote = createAsyncThunk(
  `${INCIDENT}/addIncidentNote`,
  async payload => {
    const note = await addIncidentNote(payload);
    return note;
  },
);

export default {};
