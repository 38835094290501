const duplicateName = (value, values) => {
  const names = values.filter(t => t.name === value);
  return names.length > 1 ? 'Duplicated!' : undefined;
};

export const initData = {
  localCrmList: [],
  localCompaniesCrmList: [],
  localCompanies: [],
};

export const nameValidate = values => {
  const errors = values.reduce((acc, value, index, arr) => {
    const err = {};

    if (!value.name) err.name = 'Required';
    if (!Object.keys(err).length) {
      err.name = duplicateName(value.name, arr);
    }

    return [...acc, err];
  }, []);

  return errors;
};

const editDistance = (s1, s2) => {
  const left = s1.toLowerCase();
  const right = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= left.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= right.length; j++) {
      if (i === 0) costs[j] = j;
      else if (j > 0) {
        let newValue = costs[j - 1];
        if (left.charAt(i - 1) !== right.charAt(j - 1))
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
        costs[j - 1] = lastValue;
        lastValue = newValue;
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
};

const similarity = (s1, s2) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
};

const getBindings = (name, crm) => {
  try {
    if (!crm.companyOptions.length) return undefined;
    let max = { weight: 0, id: 0, val: '' };

    crm.companyOptions.forEach(co => {
      const current = {
        weight: similarity(name, co.label),
        id: co.value,
        val: co.label,
      };

      if (current.weight >= max.weight) max = current;
    });

    return max.weight > 0.85
      ? { crmId: crm.crmId, crmCompanyId: max.id }
      : undefined;
  } catch {
    return undefined;
  }
};

const tryFindBindings = (name, crmItemsList) =>
  crmItemsList.map(crm => getBindings(name, crm));

export const getNewRecordWithBindings = (name, crmItemsList) => {
  const item = {
    id: 0,
    name: name || '',
    bindings: [],
  };

  if (!name || !crmItemsList?.length) return item;
  item.bindings = tryFindBindings(name, crmItemsList).filter(n => !!n);
  return item;
};
