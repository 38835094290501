import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendChatMessage } from '@services/requests/chats';
import { CHATS } from '@store/slices/resources';

export const ThunkSendChatMessage = createAsyncThunk(
  `${CHATS}/sendChatMessage`,
  async ({ payload, message: { senderId, senderName, dateTime } }) => {
    const res = await sendChatMessage(payload);
    return { ...res, senderId, senderName, dateTime };
  },
);

export default {};
