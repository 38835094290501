import React, { useMemo } from 'react';

const AttachmentMessage = ({ metadata, resourceName = null, fromPodPage }) => {
  const { ContentType, ThumbPath, Path, FileName } = JSON.parse(metadata);

  const getContent = useMemo(() => {
    if (ContentType.includes('image/')) {
      return (
        <div className="image">
          <img
            src={ThumbPath ?? Path}
            alt={FileName}
            onClick={() => {
              if (!fromPodPage) {
                window.open(Path, '_blank');
              }
            }}
            role="presentation"
          />
        </div>
      );
    }

    if (ContentType.includes('application/')) {
      return (
        <div className="file">
          <a href={Path} target="_blank" rel="noreferrer noopener" download>
            {FileName}
          </a>
        </div>
      );
    }

    return <div>Unsupported File Type</div>;
  }, []);

  return (
    <div className={!resourceName ? 'clientAttachment' : 'adminAttachment'}>
      {getContent}
    </div>
  );
};

export default AttachmentMessage;
