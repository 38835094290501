import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { getCrmUsersByDefaultsId } from '@services/requests/user';

export const ThunkGetCrmUsersByDefaultsId = createAsyncThunk(
  `${USER}/getCrmUsersByDefaultsId`,
  async crmDefaultsId => {
    const users = await getCrmUsersByDefaultsId(crmDefaultsId);
    return users;
  },
);

export default {};
