import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ATTicketTimeEntriesSelector } from '@store/slices/crmTicket';
import {
  ThunkDeleteAutotaskTicketTimeEntry,
  ThunkGetATTicketTimeEntries,
} from '@store/slices/crmTicket/thunks';
import getFormattedDate from '@utils/getFormattedDate';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import SimpleTable from '@ui/components/Triggers/pages/Incident/components/SimpleTable';
import Loading from '@ui/components/common/Loading/Loading';
import ReusableButton from '@ui/components/common/Button/Button';
import AddATTimeEntryDialog from '../dialogs/AddATTimeEntry';

import useStyles from '../sharedStyles';

const ATTimeEntries = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [openAddTimeEntryDialog, setOpenAddTimeEntryDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    {
      label: 'Resource',
      value: 'resource',
    },
    {
      label: 'Start Time',
      handler: item => <>{getFormattedDate(item.startDateTime)}</>,
    },
    {
      label: 'End Time',
      handler: item => <>{getFormattedDate(item.endDateTime)}</>,
    },
    {
      label: 'Summary Notes',
      value: 'summaryNotes',
    },
    {
      label: 'Action',
      handler: item => (
        <div>
          <ActionFieldItem
            icon={EditIcon}
            alt="Edit"
            handler={() => {
              setSelectedItem(item);
              setOpenAddTimeEntryDialog(true);
            }}
            toolTip="Edit"
          />

          <ActionFieldItem
            icon={DeleteIcon}
            alt="Delete"
            handler={() => {
              setSelectedItem(item);
              setOpenConfirmDeleteDialog(true);
            }}
            toolTip="Delete"
          />
        </div>
      ),
    },
  ];

  const handleDeleteItem = async () => {
    setProcessing(true);
    await dispatch(
      ThunkDeleteAutotaskTicketTimeEntry({
        timeEntryId: selectedItem.id,
        crmId: match.params.crmDefaultId,
      }),
    );
    setSelectedItem(undefined);
    setProcessing(false);
    setOpenConfirmDeleteDialog(false);
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(
      ThunkGetATTicketTimeEntries({
        crmId: match.params.crmDefaultId,
        ticketId: match.params.ticketId,
      }),
    );
    setLoading(false);
  }, [dispatch, match]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.containerActions}>
        <ReusableButton
          label="Add Time Entry"
          size="md"
          onClick={() => {
            setOpenAddTimeEntryDialog(true);
          }}
        />
      </div>
      <SimpleTable selector={ATTicketTimeEntriesSelector} columns={columns} />

      {openAddTimeEntryDialog && (
        <AddATTimeEntryDialog
          open={openAddTimeEntryDialog}
          setOpen={setOpenAddTimeEntryDialog}
          defaultId={match.params.crmDefaultId}
          ticketId={match.params.ticketId}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}

      {openConfirmDeleteDialog && (
        <ConfirmDialog
          open={openConfirmDeleteDialog}
          setOpen={setOpenConfirmDeleteDialog}
          onCancel={() => {
            setOpenConfirmDeleteDialog(false);
            setSelectedItem(undefined);
          }}
          title="Confirm action"
          content="Are you sure you want to delete this item?"
          onAccept={() => {
            handleDeleteItem();
          }}
          loading={processing}
        />
      )}
    </div>
  );
};

ATTimeEntries.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      crmDefaultId: PropTypes.string,
      ticketId: PropTypes.string,
    }),
  }),
};

export default ATTimeEntries;
