import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { webHooks } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';
import putWithResponse from '@services/api/common/putWithResponse';
import deleteData from '@services/api/common/deleteData';
import postData from '@services/api/common/postData';
import deleteWithResponse from '@services/api/common/deleteWithResponse';

export const getUrl = () =>
  getData(appConfig.baseUrl.concat(webHooks).concat('hook-url'));

export const getWebHookData = id =>
  getData(`${appConfig.baseUrl}${webHooks}fields/${id}`);

export const getTypes = () =>
  getData(appConfig.baseUrl.concat('/Types/allExtended'));

export const test = (data, isEdit = false) =>
  postWithResponse(
    `${appConfig.baseUrl}${webHooks}test?isEdit=${isEdit}`,
    data,
  );

export const submitWebHook = (data, id) =>
  putWithResponse(appConfig.baseUrl.concat(webHooks).concat(id), data);

export const deleteRecord = id =>
  deleteData(`${appConfig.baseUrl.concat(webHooks)}${id}`);

export const getRecord = id =>
  getData(appConfig.baseUrl.concat(webHooks).concat(id));

export const sendHook = (url, data) => postData(url, data);

export const getWebHookResult = id =>
  isNaN(id)
    ? getData(`${appConfig.baseUrl}${webHooks}resultsByIdentity/${id}`)
    : getData(`${appConfig.baseUrl}${webHooks}results/${id}`);

export const reParseWebHookResult = id =>
  postWithResponse(`${appConfig.baseUrl}${webHooks}reparseData?resultId=${id}`);

export const reParseWebHookResults = resultsIds =>
  postWithResponse(`${appConfig.baseUrl}${webHooks}reparseData?${resultsIds}`);

export const deleteWebHookResults = resultsIds =>
  deleteWithResponse(`${appConfig.baseUrl}${webHooks}results?${resultsIds}`);

export const getUnrecognizedValues = ids =>
  getData(`${appConfig.baseUrl}${webHooks}unrecognizedValues?${ids}`);
