import React from 'react';
import PropTypes from 'prop-types';

import smsIcon from '@assets/icons/sms.svg';
import mailIcon from '@assets/icons/email_active.svg';
import ticketIcon from '@assets/icons/ticket.svg';
import webHookIcon from '@assets/icons/webhooks.svg';
import slackIcon from '@assets/icons/slack.svg';
import teamsIcon from '@assets/icons/teams.svg';
import ftpIcon from '@assets/icons/ftp.svg';
import voiceIcon from '@assets/icons/voice.svg';

import NotificationIcon from '@common/NotificationIcon/NotificationIcon';
import useStyles from './Form/stylesNotificationSettingsForm';

const icons = [
  { icon: ticketIcon, status: 'isTicket' },
  { icon: mailIcon, status: 'isEmail' },
  { icon: smsIcon, status: 'isSms' },
  { icon: webHookIcon, status: 'isWebHook' },
  { icon: slackIcon, status: 'isSlack' },
  { icon: teamsIcon, status: 'isTeams' },
  { icon: ftpIcon, status: 'isFtp' },
  { icon: voiceIcon, status: 'isVoice' },
];

const NotificationTypes = ({ record, useFtp = true }) => {
  const classes = useStyles();
  const images = useFtp ? icons : icons.filter(i => i.status !== 'isFtp');
  return (
    <div className={classes.typesContainer}>
      {images.map(i => (
        <NotificationIcon record={record} item={i} key={i.status} />
      ))}
    </div>
  );
};

NotificationTypes.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  useFtp: PropTypes.bool,
};

export default NotificationTypes;
