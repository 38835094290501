import { makeRequest } from '@services/requests/makeRequest';
import { TOKEN, USER } from '@services/requests/requestResources';

export const login = payload => makeRequest('POST', `${TOKEN}`, payload);

export const preCheck = payload =>
  makeRequest('POST', `${USER}/preCheck`, payload);

export const mfaLogin = payload =>
  makeRequest('POST', `${USER}/MultiFactorLogin`, payload);

export const externalLogin = payload =>
  makeRequest('POST', `${USER}/ExternalLogin`, payload);

export const checkDealerCode = code =>
  makeRequest('GET', `/Account/check-code?code=${code}`);

export const registerUser = payload =>
  makeRequest('POST', '/Account/register', payload);

export const sendResetPasswordEmail = payload =>
  makeRequest('PATCH', '/Account/send-forgot-password-email', payload);

export const verifyPasswordResetCode = code =>
  makeRequest('GET', `/Account/verifyCode?verificationCode=${code}`);

export const resetUserPassword = payload =>
  makeRequest('PATCH', '/Account/reset-password', payload);

export const registerDemoUser = (authKey, payload) =>
  makeRequest('POST', `/Account/registerDemo?authKey=${authKey}`, payload);
