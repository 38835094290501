import React from 'react';
import { useOnLogin } from '@ui/pages/auth/Login/helpers';
import Loading from '@common/Loading/Loading';

const DuoLogin = props => {
  const {
    location: { search },
  } = props;
  const urlParams = new URLSearchParams(search);

  const res = JSON.parse(urlParams.get('data'));

  useOnLogin({
    accessToken: res.AccessToken,
    refreshToken: res.RefreshToken,
    userId: res.UserId,
    username: res.Username,
  });
  return <Loading />;
};

export default DuoLogin;
