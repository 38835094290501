import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Paper, TextField } from '@material-ui/core';

import useStyles from '../styles';

const ColorSelector = ({ textColor, bgColor, setBgColor }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={cx(classes.colorPicker, classes.colorSelectorContainer)}>
        <div className={classes.colorBlock}>Background Color:</div>
        <div>
          <TextField
            type="color"
            onChange={e => setBgColor(e.target.value)}
            className={classes.colorPicketInput}
            value={bgColor}
            InputProps={{ disableUnderline: true }}
          />
        </div>
        <div
          className={classes.exampleBlock}
          style={{ color: `${textColor}`, backgroundColor: `${bgColor}` }}
        >
          Text Example
        </div>
      </div>
    </Paper>
  );
};

ColorSelector.propTypes = {
  textColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  setBgColor: PropTypes.func.isRequired,
};

export default ColorSelector;
