import { useEffect, useMemo, useState } from 'react';
import debounce from './debounce';

const useInfiniteLoader = (listName, itemName, listLength = 20, callback) => {
  const [number, setNumber] = useState();

  const rootComponent = useMemo(() => document.getElementsByName(listName), [
    listName,
  ]);
  const itemComponents = useMemo(
    () => document.getElementsByName(`${itemName}${number}`),
    [itemName, number],
  );

  const handleIntersection = entries => {
    const { isIntersecting } = entries[0];
    if (isIntersecting) {
      debounce(callback, 100)();
    }
  };

  useEffect(() => {
    setNumber(listLength - 2);
  }, [listLength]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: rootComponent[0] ? rootComponent[0] : null,
      rootMargin: '0px',
      threshold: 1.0,
    });
    if (itemComponents[0] && rootComponent[0] && listLength > 10) {
      observer.observe(itemComponents[0]);
    }

    return () => {
      observer.disconnect();
    };
  }, [rootComponent[0], itemComponents[0]]);
};

export default useInfiniteLoader;
