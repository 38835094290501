import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  TenantOptionsLoadingSelector,
  TenantsOptionsSelector,
} from '@store/slices/admin';
import Loading from '@common/Loading/Loading';
import { ThunkGetTenantsOptions } from '@store/slices/admin/thunks/getTenantsOptions';
import DropDown from '@components/Auth/Common/DropDown';
import TenantModulesForm from '@components/Admin/TenantModules/TenantModulesForm';

import useStyles from './styles';

const TenantModules = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedTenant, setSelectedTenant] = useState(null);

  const tenantOptions = useSelector(TenantsOptionsSelector);
  const loading = useSelector(TenantOptionsLoadingSelector);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetTenantsOptions());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.tableActions}>
        <div className={classes.tableActionContainer}>
          <DropDown
            options={tenantOptions}
            input={{
              onChange: e => setSelectedTenant(e.target.value),
              value: selectedTenant,
            }}
          />
        </div>
      </div>
      {!!selectedTenant && <TenantModulesForm tenantId={selectedTenant} />}
    </div>
  );
};

export default TenantModules;
