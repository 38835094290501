export const selectionTypeOptions = [
  { label: 'Match First', value: 'matchfirst' },
  { label: 'Match All', value: 'matchall' },
];

export const regexOptions = [
  { label: 'Singleline', value: 'singleline' },
  { label: 'Ignore Case', value: 'ignorecase' },
  { label: 'Right To Left', value: 'righttoleft' },
  { label: 'Multiline', value: 'multiline' },
];

export const sourceOptions = [
  { label: 'HTML Body', value: 'HtmlBody' },
  { label: 'Text Body', value: 'TextBody' },
  { label: 'Subject', value: 'Subject' },
  { label: 'From', value: 'From' },
  { label: 'From Name', value: 'FromName' },
  { label: 'To', value: 'To' },
  { label: 'CC', value: 'Cc' },
  { label: 'BCC', value: 'Bcc' },
  { label: 'Reply To', value: 'ReplyTo' },
  { label: 'Original Recipient', value: 'OriginalRecipient' },
  { label: 'Date', value: 'Date' },
  { label: 'Tag', value: 'Tag' },
  { label: 'Attachment', value: 'Attachment' },
  { label: 'Headers', value: 'Headers' },
];

export const occurenceOptions = [
  { label: 'All occurrences', value: 'all' },
  { label: 'Selected element', value: 'element' },
  { label: 'First occurrence', value: 'first' },
];
