import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { pushMessageAsNoteToTicket } from '@services/requests/liveChats';

export const ThunkPushMessageAsNoteToTicket = createAsyncThunk(
  `${LIVE_CHATS}/pushMessageAsNoteToTicket`,
  ({ messageId }) => pushMessageAsNoteToTicket(messageId),
);

export default {};
