import { actionTypes } from '../actions';

const defaultState = {
  selectedViewType: 0,
};

const initialState = [];

const parsersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.LETTER_DETAILS_SET_VIEW_TYPE:
      return { ...state, selectedViewType: action.payload };
    case actionTypes.SET_SELECTED_EXPRESSION_NAMES:
      return { ...state, selectedExpressionNames: action.payload };
    case actionTypes.DELETE_SELECTED_EXPRESSION_NAME: {
      const expressions = state.selectedExpressionNames.filter(
        e => e !== action.payload,
      );
      return { ...state, selectedExpressionNames: expressions };
    }
    case actionTypes.RESET_SELECTED_EXPRESSION_NAMES:
      return { ...state, selectedExpressionNames: initialState };
    default:
      return state;
  }
};

export default parsersReducer;
