import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const DividerWithTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.dividerWithTitle}>
      <div style={{ display: 'flex', gap: '5px' }}>
        <div>{title}</div>
      </div>
      <div className={classes.divider} />
    </div>
  );
};

DividerWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DividerWithTitle;
