import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnectWiseTicketContacts } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkGetConnectWiseTicketContacts = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/getConnectWiseTicketContacts`,
  async ({ crmId, companyId = undefined, withPhone = false }) => {
    const contacts = await getConnectWiseTicketContacts(crmId, companyId);
    if (withPhone) {
      return contacts
        .map(item => ({
          value: item.value,
          label: `${item.label} <${item.info}>`,
          info: item.info,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1));
    }
    return contacts.sort((a, b) => (a.label > b.label ? 1 : -1));
  },
);

export default {};
