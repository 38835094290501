import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import cx from 'classnames';

import {
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkGetIntegrationPriorities,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
  ThunkGetIntegrationTicketCategories,
  ThunkGetIntegrationResources,
} from '@store/slices/integration/thunks';
import {
  ThunkGetAutotaskCompanies,
  ThunkCreateAutotaskTicket,
  ThunkGetPsaContacts,
} from '@store/slices/createTicket/thunks';

import crmSources, { crmSourcesByType } from '@constants/crmSources';

import Loading from '@common/Loading/Loading';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import TicketLinkDialog from '@components/CreateTicket/dialogs/TicketLinkDialog';
import TicketCreationTemplates from '@components/CreateTicket/components/TicketCreationTemplates';
import TicketCreationInputsTemplates from '@components/CreateTicket/components/TicketCreationInputsTemplates';
import TicketCreationMessagingChannel from '@components/CreateTicket/components/TicketCreationMessagingChannel';

import { isRequiredValidation } from '@ui/components/Helpers/valudationHelpers';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';

const AutotaskCreateTicketForm = ({ psaId }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [openTicketLinkDialog, setOpenTicketLinkDialog] = useState(false);
  const [link, setLink] = useState(undefined);
  const [ticketId, setTicketId] = useState(undefined);
  const [channelCreationResponse, setChannelCreationResponse] = useState(
    undefined,
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [ticketCategories, setTicketCategories] = useState([]);
  const [serviceBoards, setServiceBoards] = useState([]);
  const [sources, setSources] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [resources, setResources] = useState([]);

  // Board related options
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardSubTypes, setSubTypes] = useState({});

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkCreateAutotaskTicket({ psaId, payload: values }))
      .unwrap()
      .then(({ ticketLink, ticketId: createdTicketId, ...rest }) => {
        setLink(ticketLink);
        setTicketId(createdTicketId);
        if (rest.configId && rest.channelId) {
          setChannelCreationResponse({
            ...rest,
            channelCreated: values.channelCreated || false,
          });
        }
        setOpenTicketLinkDialog(true);
        enqueueSnackbar('Successfully created', { variant: 'success' });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleCompanyChange = value => {
    dispatch(
      ThunkGetPsaContacts({
        psaId,
        companyId: value,
      }),
    )
      .unwrap()
      .then(res => {
        setContacts(res);
      });
  };

  const handleContactChange = (value, form) => {
    const selected = contacts.find(i => i.value === value);
    form.change('userPhone', selected?.info);
  };

  const handleBoardIdChange = useCallback(
    async (value, form, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change('issueType', '');
          form.change('subIssueType', '');
          form.change('status', '');
        });
      }

      setProcessing(true);

      if (value) {
        await Promise.all([
          new Promise(res =>
            res(
              dispatch(
                ThunkGetIntegrationBoardTypes({
                  id: psaId,
                  boardId: value,
                }),
              ).unwrap(),
            ),
          ),
        ]).then(res => {
          setBoardTypes(res[0]);
          setSubTypes(
            res[0].reduce((acc, cur) => {
              acc[cur.id] = cur.boardSubTypes;
              return acc;
            }, {}),
          );
        });
      }
      setProcessing(false);
    },
    [dispatch, psaId],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(ThunkGetIntegrationTicketCategories({ id: psaId })).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationSources({
              id: psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationServiceBoards({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationPriorities({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetAutotaskCompanies({ psaId })).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationResources({
              id: psaId,
              type: crmSourcesByType.Autotask.name,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationBoardStatuses({
              id: psaId,
              boardId: 0,
            }),
          ).unwrap(),
        ),
      ),
    ])
      .then(res => {
        setTicketCategories(res[0]);
        setSources(res[1]);
        setServiceBoards(res[2]);
        setPriorities(res[3]);
        setCompanies(res[4]);
        setResources(res[5]);
        setBoardStatuses(
          res[6].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, psaId]);

  const handleDialogClose = () => {
    setOpenTicketLinkDialog(false);
    setLink(undefined);
    setTicketId(undefined);
    onMount();
  };

  const onSelect = async (d, form) => {
    if (d.boardId) {
      handleBoardIdChange(d.boardId, {}, true);
    }
    if (d.companyId) {
      await dispatch(
        ThunkGetPsaContacts({
          psaId,
          companyId: d.companyId,
        }),
      )
        .unwrap()
        .then(res => {
          setContacts(res);
        });
    }

    const fields = form.getRegisteredFields();
    fields.forEach(field => {
      if (field === 'messagingTemplateId' && !d[field]) {
        return;
      }

      form.change(field, d[field] || '');
      form.change('messagingConfigurationId', d.messagingConfigurationId || '');
      form.change('userPhone', d.userPhone || '');
    });
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  const validate = values => ({
    title: isRequiredValidation(values.title),
    description: isRequiredValidation(values.description),
    contactId: isRequiredValidation(values.contactId),
    companyId: isRequiredValidation(values.companyId),
    status: isRequiredValidation(values.status),
    priority: isRequiredValidation(values.priority),
    source: isRequiredValidation(values.source),
    boardId:
      values.boardId || values.assignedResourceId
        ? undefined
        : 'Please specify a value for the Queue field and/or the Primary Resource field.',
    assignedResourceId:
      values.boardId || values.assignedResourceId
        ? undefined
        : 'Please specify a value for the Queue field and/or the Primary Resource field.',
  });

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          {/* Templates */}
          <div className={cx(classes.templatesBox, classes.boxShadow)}>
            <TicketCreationInputsTemplates
              inputFields={{ description: 'description', title: 'title' }}
            />
            <TicketCreationTemplates
              psaType={crmSources.Autotask.name}
              onSelect={onSelect}
            />
          </div>

          <div className={cx(classes.formContainer, classes.boxShadow)}>
            <FieldWrapper
              label="Title"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="title"
                  id="title"
                  render={Input}
                  loading={processing}
                />
              }
            />
            <FieldWrapper
              label="Description"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="description"
                  id="description"
                  render={Input}
                  multiline
                  minRows={4}
                  loading={processing}
                />
              }
            />

            <FieldWrapper
              label="Company"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="companyId"
                  id="companyId"
                  render={AutocompleteFormInput}
                  loading={processing}
                  items={companies}
                />
              }
            />
            <OnChange name="companyId">{handleCompanyChange}</OnChange>

            <FieldWrapper
              label="Contact"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="contactId"
                  id="contactId"
                  render={AutocompleteFormInput}
                  loading={processing || !values.companyId || !contacts.length}
                  items={contacts}
                />
              }
            />
            <OnChange name="contactId">
              {value => {
                handleContactChange(value, form);
              }}
            </OnChange>

            <FieldWrapper
              label="Queue"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="boardId"
                  name="boardId"
                  size="small"
                  suppressInlineLabel
                  allowEmpty
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={serviceBoards}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Primary Resource"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="assignedResourceId"
                  name="assignedResourceId"
                  size="small"
                  allowEmpty
                  suppressInlineLabel
                  component={AutocompleteFormInput}
                  disabled={processing}
                  items={resources}
                />
              }
            />

            {form.getRegisteredFields().some(item => item === 'boardId') && (
              <OnChange name="boardId">
                {value => {
                  handleBoardIdChange(value, form);
                }}
              </OnChange>
            )}
            <FieldWrapper
              label="Issue Type"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="issueType"
                  name="issueType"
                  size="small"
                  label="Ticket Type"
                  suppressInlineLabel
                  component={DropDown}
                  labelName="name"
                  valueName="id"
                  allowEmpty
                  options={boardTypes}
                  disabled={processing || !values?.boardId}
                />
              }
            />
            <FieldWrapper
              label="Issue Sub Type"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="subIssueType"
                  name="subIssueType"
                  size="small"
                  label="Ticket SubType"
                  labelName="name"
                  valueName="id"
                  suppressInlineLabel
                  component={DropDown}
                  allowEmpty
                  options={boardSubTypes[values.issueType] || []}
                  disabled={processing || !values?.issueType}
                />
              }
            />
            <FieldWrapper
              label="Ticket Status"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="status"
                  name="status"
                  size="small"
                  label="New Ticket Status"
                  component={DropDown}
                  options={boardStatuses}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Ticket Category"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="ticketCategory"
                  name="ticketCategory"
                  size="small"
                  label="Ticket Category"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={ticketCategories}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Ticket Priority"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="priority"
                  name="priority"
                  size="small"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={priorities}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Ticket Source"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="source"
                  name="source"
                  size="small"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={sources}
                  disabled={processing}
                />
              }
            />

            <TicketCreationMessagingChannel
              psaType={crmSources.Autotask.idx}
              disabled={processing}
            />
          </div>

          <div className={sharedClasses.dialogActionsContainer}>
            <ReusableButton
              viewType="white"
              buttonType="simple"
              label="Submit"
              type="submit"
              disabled={processing}
              loading={processing}
            />
          </div>
          {openTicketLinkDialog && (
            <TicketLinkDialog
              onClose={handleDialogClose}
              link={link}
              open={openTicketLinkDialog}
              ticketId={ticketId}
              channelCreationResponse={channelCreationResponse}
            />
          )}
        </form>
      )}
    />
  );
};

AutotaskCreateTicketForm.propTypes = {
  psaId: PropTypes.number.isRequired,
};
export default AutotaskCreateTicketForm;
