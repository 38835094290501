import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { SelectIncidentNotes } from '@store/slices/incident/selectors';

import ReusableButton from '@ui/components/common/Button/Button';
import NoteItem from './NoteItem';
import AddNotesDialog from '../dialogs/AddNotesDialog';

import useStyles from '../../styles';

const Notes = React.memo(({ incidentId }) => {
  const [openAddNoteDialog, setOpenAddNoteDialog] = useState(false);
  const classes = useStyles();

  const notes = useSelector(SelectIncidentNotes);
  return (
    <div className={classes.block}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderTitle}>Notes</div>
        <div>
          <ReusableButton
            label="Add Note"
            onClick={() => {
              setOpenAddNoteDialog(true);
            }}
          />
        </div>
      </div>

      <div className={classes.noteBlock}>
        {notes.map(note => (
          <NoteItem key={note.id} {...note} incidentId={incidentId} />
        ))}
      </div>

      {openAddNoteDialog && (
        <AddNotesDialog
          open={openAddNoteDialog}
          setOpen={setOpenAddNoteDialog}
          incidentId={incidentId}
        />
      )}
    </div>
  );
});

Notes.propTypes = {
  incidentId: PropTypes.string,
};
export default Notes;
