import { createAsyncThunk } from '@reduxjs/toolkit';
import { autotaskSendCode } from '@services/requests/psaPods';
import { AUTOTASK_POD } from '@store/slices/resources';

export const ThunkAutotaskSendCode = createAsyncThunk(
  `${AUTOTASK_POD}/autotaskSendCode`,
  async payload => {
    const res = await autotaskSendCode(payload);
    return res;
  },
);

export default {};
