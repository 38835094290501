import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

const TableItem = ({ data, keys }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {keys.map(key => (
        <TableCell key={key} align="center">
          {data[key]}
        </TableCell>
      ))}
    </TableRow>
  );
};

TableItem.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.objectOf(PropTypes.any),
};

export default TableItem;
