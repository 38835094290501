import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { genericPipelines, ticketing } from '@constants/appRoutes';

export const getGenericPipelinesOptions = () =>
  getData(`${appConfig.baseUrl}${genericPipelines}`);

export const getGenericPipelineFields = id =>
  getData(`${appConfig.baseUrl}${genericPipelines}${id}/fields`);

export const getGenericCrmOptions = () =>
  getData(`${appConfig.baseUrl}${ticketing}getForMenu`);
