import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '50px 16px 0',
    '& tbody': {
      maxHeight: '68vh',
    },
  },
  switchWrapper: {
    display: 'flex',
    minWidth: '280px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      marginTop: '6px',
    },
  },
  actions: {
    display: 'flex',
    padding: '16px',
  },
  dialogActions: {
    margin: '25px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '10px',
    },
  },
  itemContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 250px) 1fr',
  },
  channelDetailsAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  channelDetailsLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  dropDown: {
    width: '250px',
  },
  loader: {
    width: '300px',
    height: '300px',
  },
}));

export default useStyles;
