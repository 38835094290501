import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkAddPhoneToPsaContact } from '@store/slices/optIn/thunks';
import phoneValidation from '@constants/phoneValidation';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const CreatePhoneForPsaContactDialog = ({
  open,
  onClose,
  crmId,
  contactId,
  onSuccess,
}) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();

  const validate = values => ({ phone: phoneValidation(values.phone) });

  const submit = ({ phone }) => {
    setProcessing(true);
    const payload = { phone, crmId, contactId };
    dispatch(ThunkAddPhoneToPsaContact({ payload }))
      .unwrap()
      .then(() => {
        onSuccess();
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Phone</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Phone Number:"
                labelSize={12}
                contentSize={12}
                fullWidth
                isRequired
                content={
                  <Field
                    name="phone"
                    id="phone"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={onClose}
                  disabled={processing}
                />
                <ReusableButton
                  label="Create"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreatePhoneForPsaContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  crmId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};

export default CreatePhoneForPsaContactDialog;
