import React from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import CustomChip from '@common/Chip/Chip';
import useStyles from '../styles';

const EmailSelector = ({ data, setData }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const validateEmail = email =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

  const getFormValue = form => form.getState().values.value;

  const onKeyPress = (form, event) => {
    const { value } = event.target;
    if (event.keyCode === 13 && value) {
      event.preventDefault();
      if (validateEmail(value)) {
        const newData = [...data];
        newData.push(value);
        setData(newData);
        form.reset();
      } else {
        enqueueSnackbar('Not an email', { variant: 'error' });
      }
    }
  };

  const handleChange = (form, event) => {
    const { value } = event.target;
    form.change('value', value);
  };

  const handleDelete = item => {
    const newData = [...data.filter(i => i !== item)];
    setData(newData);
  };

  const handleArrowClick = form => {
    const syntheticEvent = {
      target: {
        value: getFormValue(form),
      },
      keyCode: 13,
    };
    onKeyPress(form, syntheticEvent);
  };

  return (
    <Paper className={classes.emailComponentMinHeight}>
      <Form onSubmit={() => {}} initialValues={{ value: '' }}>
        {({ form }) => (
          <>
            <div className={classes.dataSelectorWrapper}>
              <Field
                name="value"
                placeholder="Add emails"
                inputView="text"
                styleType="main"
                helperText={false}
                fullWidth
                classNameWrapper={classes.chipFieldWrapper}
                input={{
                  onChange: event => handleChange(form, event),
                  onKeyDown: event => onKeyPress(form, event),
                  value: getFormValue(form),
                }}
                component={Input}
              />
              <ArrowDownwardIcon
                onClick={() => handleArrowClick(form)}
                className={classes.iconDown}
              />
            </div>
            <div className={classes.chipsWrapper}>
              {data.map(i => {
                return (
                  <li key={i}>
                    <CustomChip
                      label={i.replace('\\', '')}
                      onDelete={() => handleDelete(i)}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </div>
          </>
        )}
      </Form>
    </Paper>
  );
};

EmailSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  setData: PropTypes.func.isRequired,
};

export default EmailSelector;
