import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputWrapper: {
    marginTop: '0.625rem',
  },
  buttonWrapper: {
    marginTop: '2.5rem',
    marginLeft: 0,
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:last-child': {
        marginLeft: '1.875rem',
      },
    },
  },
  inputLabel: {
    width: '100%',
    fontWeight: 'bold',
    marginTop: '0.5rem',
    marginBottom: '-0.5rem',
    textAlign: 'left',
    fontSize: '0.875rem',
  },
  inputSwitch: {
    marginTop: '0',
  },
  displayFlex: {
    display: 'flex',
  },
}));

export default useStyles;
