import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'normal',
  },
  item: {
    marginLeft: '10px',
    minWidth: '70px',
    height: '40px',
    textAlign: 'center',
  },
  borderRoot: {
    borderRadius: '10px 0 0 10px',
  },
  borderItem: {
    borderRadius: '10px',
  },
  angle: {
    width: '0',
    height: '0',
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
  },
  inline: {
    height: '100%',
    padding: '10px',
  },
  typeSelector: {
    marginRight: '10px',
    width: '6.25rem',
  },
  overFlow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  lastResult_select: {
    width: '15.625rem',
  },
  inputWrapper: {
    marginLeft: '10px',
  },
  buttonWrapper: {
    marginTop: '0.75rem',
  },
  vpnIcon: {
    fill: '#F3A594',
  },
}));

export default useStyles;
