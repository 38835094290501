import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import iconDelete from '@assets/icons/delete.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';

import useStyles from './hooksTableStyles';

const TableRow = ({ id, name, url, isHeader, onDelete }) => {
  const classes = useStyles();
  const nameClass = isHeader
    ? cx(classes.nameCol, classes.header)
    : classes.nameCol;
  const urlClass = isHeader
    ? cx(classes.urlCol, classes.header)
    : classes.urlCol;

  return (
    <div className={classes.rowContainer}>
      <div className={nameClass}>{name}</div>
      <div className={urlClass}>{url}</div>
      <div className={classes.actionCol}>
        {!isHeader && (
          <ActionButton
            icon={<img src={iconDelete} alt="iconDelete" />}
            handler={() => onDelete(id)}
          />
        )}
      </div>
    </div>
  );
};

const HooksTable = ({ data, onDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <TableRow name="Name" url="URL" isHeader />
      {data.map(d => (
        <TableRow
          key={d.id}
          id={d.id}
          name={d.name}
          url={d.url}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

HooksTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default HooksTable;
