import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';
import { getKaseyaAssignees } from '@services/requests/createTicket';

export const ThunkGetKaseyaAssignees = createAsyncThunk(
  `${CREATE_TICKET}/getKaseyaAssignees`,
  ({ psaId }) => getKaseyaAssignees(psaId),
);

export default {};
