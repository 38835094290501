import React from 'react';
import { useRedirect, useSafeSetState } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Button, CircularProgress } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import cx from 'classnames';
import { useSnackbar } from 'notistack';

import { getHeaders } from '@utils/transfered';

import Input from '@common/Input/Input';
import { checkMailBoxName } from '@components/MailBox/helpers';

import appConfig from '@configs/appConfig';
import useStyles from './styles';

const FirstMailBox = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const redirect = useRedirect();

  const validate = async values => {
    const name = values.name ? values.name : ' ';
    const errors = {
      name: undefined,
    };

    const checkName = await checkMailBoxName(name);
    errors.name = checkName.data !== 'Allow' ? checkName.data : undefined;

    if (!/^([A-Za-z0-9_]*)$/.test(name)) {
      errors.name = 'Name contains incorrect symbols';
    }

    if (!values.name) {
      errors.name = 'Required';
    }
    return errors;
  };

  const submit = async values => {
    setLoading(true);
    const response = await fetch(appConfig.baseUrl.concat('/MailBoxes'), {
      method: 'POST',
      body: JSON.stringify(values),
      headers: getHeaders(true),
    }).catch(e => {
      const message = e.message || response.statusText;
      enqueueSnackbar(message, { variant: 'error' });
    });

    if (response.status === 401 || response.status === 403) {
      const json = await response.json();
      enqueueSnackbar(json.message, { variant: 'error' });
    } else if (response.status < 200 || response.status >= 300) {
      enqueueSnackbar('Server error', { variant: 'error' });
    } else {
      enqueueSnackbar('Success', { variant: 'success' });
      redirect('/home');
    }
    setLoading(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.element}>
        <span className={cx(classes.baseFont, classes.header)}>
          {`Welcome to ${appConfig.projectName}`}
        </span>
      </div>
      <div className={classes.element}>
        <span className={cx(classes.baseFont, classes.subHeader)}>
          CREATE NEW MAILBOX!
        </span>
      </div>
      <div className={classes.element}>
        <span className={cx(classes.baseFont, classes.subHeader2)}>
          First, enter a name for your mailbox, and then click “Next”
        </span>
      </div>
      <div className={classes.element}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  component={Input}
                  styleType="main"
                  inputView="text"
                  classNameWrapper={classes.fieldWrapper}
                  placeholder="Mailbox Name"
                  disabled={loading}
                />
                <p className={cx(classes.baseFont, classes.domain)}>
                  {'@'.concat(appConfig.mailBoxDomain)}
                </p>
              </div>
              <div className={cx(classes.element, classes.elementSpace)}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                >
                  {loading && <CircularProgress size={18} thickness={2} />}
                  <div className={classes.buttonContainer}>
                    <span className={classes.buttonContainerText}>Next</span>
                    <ArrowForwardIcon className={classes.buttonContainerIcon} />
                  </div>
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default FirstMailBox;
