export const ssidOptions = [{ label: 'Any SSID', value: 0 }];

export const portOptions = [{ label: 'any port', value: 'any port' }];

export const thresholdOptions = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
];

export const periodOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '60', value: 60 },
];

export const dataSizeOptions = [
  { label: 'KB', value: 0 },
  { label: 'MB', value: 1 },
  { label: 'GB', value: 2 },
  { label: 'TB', value: 3 },
];

export const longPeriodOptions = [
  { label: '20 minutes', value: 1200 },
  { label: '4 hours', value: 14400 },
  { label: '1 day', value: 1526400 },
];

export const mediumPeriodOptions = [
  { label: '30 minutes', value: 30 },
  { label: '2 hours', value: 120 },
  { label: '6 hours', value: 360 },
  { label: '12 hours', value: 720 },
];

export const periodInMinutesOptions = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '1 hour', value: 60 },
  { label: '2 hours', value: 120 },
];
