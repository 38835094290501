import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import { removeVerificationScreenLogo } from '@services/requests/verificationSettings';

export const ThunkRemoveVerificationScreenLogo = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/removeVerificationScreen`,
  () => removeVerificationScreenLogo(),
);

export default {};
