import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER } from '@store/slices/resources';
import { getMfaCode } from '@services/requests/user';

export const ThunkGetMfaCode = createAsyncThunk(
  `${USER}/getMfaCode`,
  async () => {
    return getMfaCode();
  },
);

export default {};
