/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { TEST_EMAIL } from '../resources';
import { ThunkGetEmailTestingTypes } from './thunks';

const testEmailSlice = createSlice({
  name: TEST_EMAIL,
  initialState: {
    types: [],
    isLoading: true,
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetEmailTestingTypes.fulfilled, (state, action) => {
      state.types = action.payload;
    });

    builder.addMatcher(isAnyOf(ThunkGetEmailTestingTypes.fulfilled), state => {
      state.isLoading = false;
    });
  },
});

export default testEmailSlice.reducer;
