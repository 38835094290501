import { createAsyncThunk } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import { pushMessagesToEmail } from '@services/requests/clientPortal';

export const ThunkPushMessagesToEmail = createAsyncThunk(
  `${CLIENT_PORTALS}/pushMessagesToEmail`,
  ({ clientChatId, payload }) => pushMessagesToEmail(clientChatId, payload),
);

export default {};
