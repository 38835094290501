import { PRICE_PLANS } from '@components/Layout/SideBar/sideBarItems';

export const steps = [
  {
    id: 0,
    label: 'User Setup',
    pricePlans: [
      PRICE_PLANS.FREE,
      PRICE_PLANS.VERIFICATION_PLUS,
      PRICE_PLANS.PRO,
    ],
  },
  {
    id: 1,
    label: 'PSA Setup',
    pricePlans: [
      PRICE_PLANS.FREE,
      PRICE_PLANS.VERIFICATION_PLUS,
      PRICE_PLANS.PRO,
    ],
  },
  {
    id: 2,
    label: 'Messaging Setup',
    pricePlans: [PRICE_PLANS.PRO],
  },
  {
    id: 3,
    label: 'Invite Users',
    pricePlans: [
      PRICE_PLANS.FREE,
      PRICE_PLANS.VERIFICATION_PLUS,
      PRICE_PLANS.PRO,
    ],
  },
  {
    id: 4,
    label: 'Verification Settings',
    pricePlans: [
      PRICE_PLANS.FREE,
      PRICE_PLANS.VERIFICATION_PLUS,
      PRICE_PLANS.PRO,
    ],
  },
];

export const stepsLength = steps.length;
export default {};
