import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { deviceMonitors, getCompaniesAutocomplete } from '@constants/appRoutes';

const types = {
  '0': { name: 'Failure(s)', color: '#3A3E45' },
  '1': { name: 'milliseconds (ms) threshold', color: '#EC6A4E' },
};

export const deviceThresholdTypes = [
  { label: types['0'].name, value: '0' },
  { label: types['1'].name, value: '1' },
];

export const getCompaniesAutocompleteOption = search =>
  getData(`${appConfig.baseUrl}${getCompaniesAutocomplete(search)}`);

export const getRegionsAutocompleteOptions = () =>
  getData(`${appConfig.baseUrl}${deviceMonitors}getRegions`);

export default types;
