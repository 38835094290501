import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  ThunkAddAssigneeForIncident,
  ThunkGetIncidentTickets,
  ThunkReOpenIncident,
  ThunkResolveIncident,
} from '@store/slices/incident/thunks';
import {
  SelectIncidentLogs,
  SelectIncidentProcessing,
} from '@store/slices/incident/selectors';

import { INCIDENTS } from '@constants/routes';
import { detailsBody } from '@components/Triggers/pages/Incident/constants';
import useAsync from '@services/api/common/useAsync';
import { getHintUsers } from '@ui/components/Audit/helpers';

import AssignDialog from '@components/Audit/dialogs/AssignDialog';
import ReusableButton from '@ui/components/common/Button/Button';
import DetailsBlockItem from './DetailsBlockItem';
import LogsDialog from '../dialogs/LogsDialog';

import useStyles from '../../styles';

const Details = React.memo(({ incident }) => {
  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [openAssigneeDialog, setOpenAssigneeDialog] = useState(false);
  const [users, setUsers] = useState([]);
  useAsync(getHintUsers, setUsers);

  const classes = useStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const isProcessing = useSelector(SelectIncidentProcessing);

  const detailsTable = useMemo(
    () =>
      detailsBody.map(({ label, value, color, link }) => (
        <DetailsBlockItem
          key={`${label}`}
          label={label}
          value={incident[value]}
          color={incident[color]}
          link={incident[link]}
        />
      )),
    [incident],
  );

  const handleEditClick = id => {
    push(INCIDENTS.replace(':id', id));
  };

  const handleResolveClick = id => {
    dispatch(ThunkResolveIncident(id))
      .unwrap()
      .then(() => {
        dispatch(ThunkGetIncidentTickets(id));
      });
  };

  const handleReOpenClick = id => {
    dispatch(ThunkReOpenIncident(id))
      .unwrap()
      .then(() => {
        dispatch(ThunkGetIncidentTickets(id));
      });
  };

  const handleAssigneeSelect = async (assigneeId, incidentId) => {
    await dispatch(ThunkAddAssigneeForIncident({ assigneeId, incidentId }));
    setOpenAssigneeDialog(false);
  };

  return (
    <div className={classes.block}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderTitle}>Details</div>
        <div className={classes.blockHeaderActions}>
          <ReusableButton
            label="Edit"
            onClick={() => {
              handleEditClick(incident.id);
            }}
          />

          <ReusableButton
            label="Logs"
            onClick={() => {
              setOpenLogsDialog(true);
            }}
          />
          {incident.incidentStatus === 'Resolved' ? (
            <ReusableButton
              label="ReOpen"
              onClick={() => {
                handleReOpenClick(incident.id);
              }}
              loading={isProcessing}
              disabled={isProcessing}
            />
          ) : (
            <ReusableButton
              label="Resolve"
              onClick={() => {
                handleResolveClick(incident.id);
              }}
              loading={isProcessing}
              disabled={isProcessing}
            />
          )}

          {/* TODO ADD ASSIGN TO handler */}
          <ReusableButton
            label="Assign To"
            onClick={() => {
              setOpenAssigneeDialog(true);
            }}
          />
        </div>
      </div>

      <div className={classes.detailsBody}>{detailsTable}</div>

      {openLogsDialog && (
        <LogsDialog
          open={openLogsDialog}
          setOpen={setOpenLogsDialog}
          selector={SelectIncidentLogs}
        />
      )}

      {openAssigneeDialog && (
        <AssignDialog
          open={openAssigneeDialog}
          onClose={() => setOpenAssigneeDialog(false)}
          onSelect={value => {
            handleAssigneeSelect(value, incident.id);
          }}
          users={users}
        />
      )}
    </div>
  );
});

Details.propTypes = {
  incident: PropTypes.shape({
    id: PropTypes.number,
    assigneeName: PropTypes.string,
    emailSubject: PropTypes.string,
    triggerId: PropTypes.string,
    escalated: PropTypes.bool,
    tags: PropTypes.string,
    sourceName: PropTypes.string,
    sourceType: PropTypes.string,
    dateTriggered: PropTypes.string,
    incidentStatus: PropTypes.string,
    incidentStatusColor: PropTypes.string,
    priorityLabel: PropTypes.string,
    priorityColor: PropTypes.string,
  }),
};
export default Details;
