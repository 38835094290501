import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  displayBlock: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      margin: '0 20px ',
    },
  },
  imageBlock: {
    marginBottom: '30px',
    textAlign: 'left',
  },
  imageText: {
    color: '#9D9D9DFF',

    fontWeight: 'bold',
    fontSize: 'small',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
  circleLoader: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  imageRoot: {
    width: '60px',
    height: '60px',
    marginTop: '0.625rem',
  },
  spacingLeft: {
    marginLeft: '0.625rem',
  },
  buttonImageText: {
    color: '#0E96E2',
    fontSize: '1.125rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    cursor: 'pointer',
  },
  circle: {
    width: '.25rem',
    height: '.25rem',
    backgroundColor: 'rgb(120, 120, 120)',
    margin: '0 0.5rem',
    borderRadius: '50%',
    display: 'inline-block',
  },
}));

export default useStyles;
