import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    errors: [],
  },
  reducers: {
    addErrors(state, action) {
      state.errors.push(action.payload);
    },
    removeFirstElementOfErrorArray(state) {
      state.errors.shift();
    },
  },
});

export const { addErrors, removeFirstElementOfErrorArray } = errorSlice.actions;
export default errorSlice.reducer;
