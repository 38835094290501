import { useMemo } from 'react';
import { usePermissions } from 'react-admin';

const useDisabled = (loading, permissionKey, basePermissionKey) => {
  const { permissions } = usePermissions();

  const editMode = useMemo(
    () => permissions && permissions[permissionKey][basePermissionKey],
    [permissions, permissionKey, basePermissionKey],
  );

  const disabled = useMemo(() => loading || !editMode, [loading, editMode]);

  return { editMode, disabled };
};

export default useDisabled;
