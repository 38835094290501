import { createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_INTEGRATIONS } from '@store/slices/resources';
import { getMsAuthSetuperLink } from '@services/requests/securityIntegrations';

export const ThunkGetMsAuthSetuperLink = createAsyncThunk(
  `${SECURITY_INTEGRATIONS}/getMsAuthSetuperLink`,
  async () => {
    const data = await getMsAuthSetuperLink();
    return data;
  },
);

export default {};
