import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  field: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    width: '100%',
    margin: '10px 0',
  },
  label: {
    minWidth: '200px',
    textAlign: 'right',
    paddingRight: '10px',
    alignSelf: 'center',
  },
  component: {
    width: '100%',
  },
  info: {
    alignSelf: 'center',
  },
  infoStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldLabelOuter: {
    display: 'table',
    overflow: 'hidden',
    height: '45px',
    width: '100%',
    textAlign: 'end',
  },
  fieldLabelInner: {
    display: 'table-cell',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    maxWidth: '8.375rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'start',
  },
  alignCenter: {
    alignSelf: 'center',
  },
  checkbox: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
  disabled: {
    opacity: 0.5,
  },
}));

export default useStyles;
