import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { usage } from '@constants/appRoutes';

export const hooksUsage = () => getData(`${appConfig.baseUrl}${usage}hooks`);

export const apisUsage = () => getData(`${appConfig.baseUrl}${usage}apis`);

export const storageUsage = () =>
  getData(`${appConfig.baseUrl}${usage}storage`);
