import React from 'react';
import PropTypes from 'prop-types';
import {
  FailToConnectAlert,
  PortAlert,
  PortTimeoutAlert,
  SsidRangeMediumTimeAlert,
  SsidRangeTimeAlert,
  TimeoutFilter,
  UsageAlert,
} from './filters';
import alertTypes from '../alertTypes';

const AlertFilters = ({ alertType, filterText, form }) => {
  switch (alertType) {
    case alertTypes.usageAlert:
      return <UsageAlert />;
    case alertTypes.gatewayDown:
    case alertTypes.repeaterDown:
    case alertTypes.applianceDown:
    case alertTypes.switchDown:
    case alertTypes.cellularGatewayDown:
      return <TimeoutFilter />;
    case alertTypes.portDown:
      return <PortTimeoutAlert />;
    case alertTypes.portError:
    case alertTypes.portSpeed:
      return <PortAlert filterText={filterText} />;
    case alertTypes.snr:
      return <SsidRangeTimeAlert filterText={filterText} />;
    case alertTypes.highWirelessUsage:
      return <SsidRangeMediumTimeAlert filterText={filterText} />;
    case alertTypes.onboarding:
      return <FailToConnectAlert form={form} />;
    default:
      return null;
  }
};

AlertFilters.propTypes = {
  alertType: PropTypes.string,
  filterText: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any),
};

export default AlertFilters;
