import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useSearch = (regExp = '\\?id=(\\d+)') => {
  const location = useLocation();
  return useMemo(() => location.search && location.search.match(regExp)[1], [
    location.search,
    regExp,
  ]);
};

export default useSearch;
