import React, { useEffect, useState } from 'react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import UnauthorizedLayout from '@components/UnauthorizedLayout';
import CodeBlock from '@common/HelpBox/CodeBlock';
import storage from '@constants/localStorage';
import { getLastPath } from '@utils/getLastPath';

import useStyles from './styles';

const allowedPages = ['main', 'public-api'];

const HelpPage = ({ location }) => {
  const logoUrl = sessionStorage.getItem(storage.BASE_APP_LOGO);
  const classes = useStyles();
  const [data, setData] = useState('');
  let chapter = getLastPath(location.pathname);
  if (!allowedPages.includes(chapter)) {
    [chapter] = allowedPages;
  }

  useEffect(() => {
    import(`@help/${chapter.toLowerCase()}.md`)
      .then(module => fetch(module.default))
      .then(resp => resp.text())
      .then(setData)
      .catch(e => console.error(e.message));
  }, [location, chapter]);

  return (
    <UnauthorizedLayout>
      <img alt="logo" src={logoUrl} />
      <Paper className={classes.container}>
        <ReactMarkdown
          components={{
            code(props) {
              return <CodeBlock {...props} />;
            },
          }}
          remarkPlugins={[remarkGfm]}
        >
          {data}
        </ReactMarkdown>
      </Paper>
    </UnauthorizedLayout>
  );
};

export default HelpPage;
