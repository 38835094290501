import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    overflowY: 'auto',
    background:
      'linear-gradient(5.32deg, #012169 26.17%, #455FAE 94.33%, #091456 173.86%), linear-gradient(190.8deg, #5368D7 -160.67%, #7FABFF -90.38%, #5368D7 5.75%)',

    '@media (max-width: 1100px)': {
      gridTemplateColumns: '1fr',
    },
  },
  activeContainer: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > div': {
      width: '70%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    '@media (max-width: 1100px)': {
      padding: '0 150px',
      '& > div': {
        width: '100%',
        margin: '0 auto',
        padding: '10px',
        boxSizing: 'border-box',
      },
    },
  },
  typographyContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& h5': {
      padding: '0px',
      margin: '5px',
      fontSize: 'xx-large',
      fontWeight: 800,
    },
    '& p': {
      margin: '0px',
    },
    '@media (max-width: 1100px)': {
      '& h5': {
        fontSize: 'larger',
      },
      '& p': {
        display: 'none',
      },
    },
  },
  fieldItem: {
    margin: '25px',
    '& p': {
      margin: '0px',
      fontWeight: '800',
    },
    '& svg': {
      '& path': {
        fill: 'grey',
      },
    },
    '@media (max-width: 770px)': {
      margin: '10px',
    },
    '@media (max-height: 1000px)': {
      margin: '10px',
    },
  },
  link: {
    margin: '0px 25px',
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#24418d',
    cursor: 'pointer',
    fontWeight: '600',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    width: 'calc(100% - 50px)',
    background: '#012169',
    color: 'white',
    textTransform: 'capitalize',
    margin: '0px 25px 30px 25px',
    fontFamily: 'Nunito, sans-serif',
    '&:hover': {
      background: 'black',
    },
  },
  externalLoginsContainer: {
    display: 'flex',
    padding: '30px',
    gap: '30px',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
    '& svg': {
      width: '50%',
      cursor: 'pointer',
      height: '100%',
      padding: '10px 30px',
      background: '#B2B2B217',
      justifySelf: 'center',
      boxSizing: 'content-box',
      maxHeight: '25px',
      borderRadius: '10px',
    },
  },
  signUpContainer: {
    textAlign: 'center',
    color: 'grey',
    '& a': {
      fontWeight: 'bold',
      margin: '0px 5px',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  checkboxContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
