import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import MuiCheckbox from '@common/Checkbox/Checkbox';

const FormControlCheckBox = ({ input, ...props }) => (
  <FormControlLabel {...input} {...props} control={<MuiCheckbox />} />
);

FormControlCheckBox.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  meta: PropTypes.objectOf(PropTypes.any),
};

export default FormControlCheckBox;
