import { makeRequest } from '@services/requests/makeRequest';
import { INTEGRATION, ISSUE_TYPES } from '@services/requests/requestResources';

export const getIssueTypes = () => makeRequest('GET', `${ISSUE_TYPES}`);

export const createIssueType = payload =>
  makeRequest('POST', `${ISSUE_TYPES}`, payload);

export const getIssueType = id => makeRequest('GET', `${ISSUE_TYPES}/${id}`);

export const updateIssueType = (id, payload) =>
  makeRequest('PUT', `${ISSUE_TYPES}/${id}`, payload);

export const deleteIssueType = id =>
  makeRequest('DELETE', `${ISSUE_TYPES}/${id}`);

export const cloneIssueType = id =>
  makeRequest('POST', `${ISSUE_TYPES}/clone/${id}`);

export const getIntegrationSyncroTypes = id =>
  makeRequest('GET', `${INTEGRATION}/syncro/${id}/issueTypes`);
