/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf, isFulfilled } from '@reduxjs/toolkit';
import { CLIENT_PORTALS } from '@store/slices/resources';
import {
  ThunkAddClientPortalConfigurations,
  ThunkEditClientPortalConfigurations,
  ThunkGetClientPortalChatMessages,
  ThunkGetClientPortalConfigurationOptions,
  ThunkGetClientPortalConfigurations,
  ThunkGetClientPortalPusherSettings,
  ThunkGetCPDefaultsAutocomplete,
  ThunkSendMessageToClientPortal,
} from '@store/slices/clientPortalAdmin/thunks';
import { ThunkDeleteClientPortalConfigurations } from '@store/slices/clientPortalAdmin/thunks/deleteClientPortalConfigurations';

const initialState = {
  configurations: [],
  configurationsLoading: false,
  configurationDefaultsOptions: [],
  configOptions: [],
  configOptionsLoading: false,
  messages: [],
  pusherSettings: undefined,
  pendingMessages: [],
};

const clientPortalSlice = createSlice({
  name: CLIENT_PORTALS,
  initialState,
  reducers: {
    addClientPortalMessage: (state, action) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetClientPortalConfigurations.fulfilled,
        (state, { payload }) => {
          state.configurationsOptions = payload;
        },
      )

      .addCase(
        ThunkGetClientPortalConfigurationOptions.fulfilled,
        (state, { payload }) => {
          state.configOptions = payload;
        },
      )

      .addCase(
        ThunkGetCPDefaultsAutocomplete.fulfilled,
        (state, { payload }) => {
          state.configurationDefaultsOptions = payload;
        },
      )

      .addCase(
        ThunkAddClientPortalConfigurations.fulfilled,
        (state, { payload }) => {
          state.configurationsOptions = [
            ...state.configurationsOptions,
            payload,
          ];
        },
      )

      .addCase(
        ThunkDeleteClientPortalConfigurations.fulfilled,
        (state, { payload }) => {
          state.configurationsOptions = state.configurationsOptions.filter(
            p => p.id !== payload,
          );
        },
      )

      .addCase(
        ThunkEditClientPortalConfigurations.fulfilled,
        (state, { payload }) => {
          state.configurationsOptions = state.configurationsOptions.map(i =>
            i.id === payload.id ? payload : i,
          );
        },
      )

      .addCase(
        ThunkGetClientPortalChatMessages.fulfilled,
        (state, { payload }) => {
          state.messages = payload;
        },
      )

      .addCase(ThunkSendMessageToClientPortal.fulfilled, (state, action) => {
        const pendingMessage = state.pendingMessages.find(
          i => i.requestId === action.meta.requestId,
        );

        if (pendingMessage) {
          state.messages = state.messages.map(msg =>
            msg.id === pendingMessage.messageId
              ? { ...msg, status: undefined }
              : msg,
          );

          const pendingMessageIndex = state.pendingMessages.indexOf(
            pendingMessage,
          );

          state.pendingMessages.splice(1, pendingMessageIndex);
        }
      })

      .addCase(ThunkSendMessageToClientPortal.pending, (state, action) => {
        const messageId = new Date().getTime();
        const message = {
          id: messageId,
          message: action.meta.arg.payload.message,
          dateTime: new Date().toISOString(),
          contactName: null,
          type: 0,
          metadata: null,
          resourceName: action.meta.arg.payload.resourceName,
          status: 'loading',
        };

        state.pendingMessages.push({
          messageId,
          requestId: action.meta.requestId,
        });
        state.messages.push(message);
      })

      .addCase(ThunkSendMessageToClientPortal.rejected, (state, action) => {
        const pendingMessage = state.pendingMessages.find(
          i => i.requestId === action.meta.requestId,
        );
        if (pendingMessage) {
          state.messages = state.messages.map(msg =>
            msg.id === pendingMessage.messageId
              ? { ...msg, status: 'error' }
              : msg,
          );
        }
      })

      .addCase(
        ThunkGetClientPortalPusherSettings.fulfilled,
        (state, { payload }) => {
          state.pusherSettings = payload;
        },
      )

      .addMatcher(
        isAnyOf(ThunkGetClientPortalConfigurations.pending),
        state => {
          state.configurationsLoading = true;
        },
      )

      .addMatcher(isFulfilled(ThunkGetClientPortalConfigurations), state => {
        state.configurationsLoading = false;
      })

      .addMatcher(
        isAnyOf(ThunkGetClientPortalConfigurationOptions.pending),
        state => {
          state.configOptionsLoading = true;
        },
      )

      .addMatcher(
        isFulfilled(ThunkGetClientPortalConfigurationOptions),
        state => {
          state.configOptionsLoading = false;
        },
      );
  },
});

export default clientPortalSlice.reducer;
export const { addClientPortalMessage } = clientPortalSlice.actions;
