import { downloadCSV } from 'react-admin';
import moment from 'moment';

const fileName = 'export';

const formatDate = timestamp => {
  if (!timestamp) {
    return null;
  }
  return moment(new Date(timestamp)).format('dd-LL-yyyy HH:mm:ss');
};

const defaultConfig = {
  rowDelimiter: ';',
  forceTextDelimiter: true,
};

export const retrieveConfigFromHeaders = (headers = []) => {
  return headers.reduce(
    (acc, { id, name }) => ({
      headers: [...acc.headers, id],
      rename: [...acc.rename, name],
    }),
    { headers: [], rename: [] },
  );
};

export const retrieveFieldsFromHeaders = (headers = [], base, translate) => {
  return headers.map(source => ({
    source,
    label: translate(base.concat(source)),
  }));
};

const exporter = (
  config = {},
  dateFields = ['createdAt', 'updatedAt'],
  fileFields = ['files'],
) => async entities => {
  const { headers } = config;
  const jsonExportConfig = { ...defaultConfig, ...config };
  const entitiesForExport = entities.map(entity => {
    const entityForExport = headers
      ? headers.reduce((acc, prop) => ({ ...acc, [prop]: entity[prop] }), {})
      : { ...entity };

    dateFields.forEach(field => {
      if (entityForExport[field]) {
        entityForExport[field] = formatDate(entityForExport[field]);
      }
    });
    fileFields.forEach(field => {
      if (entityForExport[field]) {
        entityForExport[field] = (entityForExport[field] || []).join(' ');
      }
    });

    return entityForExport;
  });

  const { default: jsonExport } = await import('jsonexport/dist');

  jsonExport(entitiesForExport, jsonExportConfig, (err, csv) => {
    downloadCSV(csv, fileName); // download as 'export.csv` file
  });
};

export default exporter;
