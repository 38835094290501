import emailsTemplateBehaviourOptions from '@constants/emailsTemplateBehaviourOptions';

const marakiAlertFormInitial = {
  snmp: false,
  allAdmins: false,
  emailsTemplateBehaviour: emailsTemplateBehaviourOptions.Overwrite,
  hooksTemplateBehaviour: emailsTemplateBehaviourOptions.Overwrite,
};

export default marakiAlertFormInitial;
