export default {
  USER_ID: 'userId',
  FIREBASE_TOKEN: 'RAFirebaseClientToken',
  CLIENT_PERMISSIONS: 'permissions',
  PROFILE_IMAGE: 'profileImage',
  LANGUAGE: 'clientLanguage',
  CURRENT_COMPANY_ID: 'CURRENT_COMPANY_ID',
  INVITATION_DATA: 'invitationData',
  REFRESH_TOKEN: 'refreshToken',
  THEME_TYPE: 'themeType',
  TENANT_ID: 'tenantId',
  SELECTED_AUDITVIEW: 'selected_auditView',
  SELECTED_GROUPBY: 'selected_groupBy',
  ROLE_ID: 'roleId',
  AFTERSAVE_ACTION: 'afterSave_action',
  ACCESS_TOKEN: 'accessToken',
  DEVICE_MONOTIR_DETAILS_TIMER: 'deviceMonitorDetailsTimer',
  TENANT_IMAGE: 'tenantImage',
  DASHBOARD_ID: 'dashboardId',
  PALETTE: 'palette',
  TENANT_NAME: 'tenantName',
  ALLOWED_RESOURCES: 'allowedResources',
  BASE_APP_LOGO: 'baseAppLogo',
};
