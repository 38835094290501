import React, { useContext } from 'react';

import { ColumnsContext } from '../context/columns';
import Header from './Header';
import DraggableHeader from './DraggableHeader';

const DraggableHeaderWrapper = props => {
  const { columns, setIsDraggable, setColumnsInLocalStorage } = useContext(
    ColumnsContext,
  );

  const handleColumnsReorder = (sourceKey, targetKey) => {
    const reorderedColumns = [...columns];
    const sourceColumnIndex = columns.findIndex(c => c.key === sourceKey);
    const targetColumnIndex = columns.findIndex(c => c.key === targetKey);

    reorderedColumns[sourceColumnIndex] = columns[targetColumnIndex];
    reorderedColumns[targetColumnIndex] = columns[sourceColumnIndex];
    /**
     * we should make first setIsDraggable(true) before setColumns(reorderedColumns)
     */
    setIsDraggable(true);
    setColumnsInLocalStorage(reorderedColumns);
  };
  return (
    <DraggableHeader {...props} onColumnsReorder={handleColumnsReorder}>
      <Header {...props} />
    </DraggableHeader>
  );
};

export default DraggableHeaderWrapper;
