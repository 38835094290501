import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkGetUserChats } from '@store/slices/chats/thunks';
import { ChatsLoadingSelector } from '@store/slices/chats';

import Loading from '@ui/components/common/Loading/Loading';
import ChatsList from './components/ChatsList/ChatsList';
import Chat from './components/ActiveChat/Chat';

import useStyles from './styles';

const Chats = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(ChatsLoadingSelector);

  const onMount = useCallback(() => {
    dispatch(ThunkGetUserChats());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (isLoading) return <Loading />;
  return (
    <div className={classes.container}>
      <ChatsList />
      <Chat />
    </div>
  );
};

export default Chats;
