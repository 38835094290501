import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  itemContainer: {
    padding: '10px',
    background: 'white',
    boxSizing: 'content-box',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  itemHeader: {
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '30% 30%',
  },
  container: {
    padding: '30px',
    maxHeight: '100%',
    background: 'white',
    margin: '15px auto',
    boxSizing: 'border-box',
    borderRadius: '15px',
    width: '55%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '5px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  list: {
    overflowY: 'auto',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  options: {
    padding: '20px',
    boxSizing: 'border-box',
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    marginLeft: '5px',
    fontWeight: 'bolder',
    color: 'black',
    '& svg': {
      '& path': {
        fill: 'black',
      },
    },
  },
  optionInput: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
  },
  iconsBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
  },
  helper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

    fontSize: 'small',
    fontWeight: '800',

    color: 'grey',
    '& > svg': {
      '& > path': {
        color: 'grey',
      },
    },
  },
  checkbox: {
    margin: 0,
    '& .MuiFormControlLabel-label': {
      marginLeft: '4px',
    },
  },
  divider: {
    marginTop: '5px',
  },
}));

export default useStyles;
