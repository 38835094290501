import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkCheckDealerCode } from '@store/slices/auth/thunks';
import { OnChange } from 'react-final-form-listeners';
import ListItem from '@ui/pages/billing/Billing/components/ListItem';
import {
  BillingDataSelector,
  MinTechCountSelectorSelector,
  TechCountSelector,
  updateDiscountCode,
} from '@store/slices/billing';
import { getPricePlanCount } from '@ui/pages/billing/Billing/helpers';
import { ThunkAddSubscriptionDiscount } from '@store/slices/billing/thunks';

const ApplyDealerCodeDialog = ({ open, onClose }) => {
  const [allowCode, setAllowCode] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [discountData, setDiscountData] = useState(undefined);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const billingData = useSelector(BillingDataSelector);
  const techCount = useSelector(TechCountSelector);
  const minTechCount = useSelector(MinTechCountSelectorSelector);

  const submit = useCallback(
    ({ code }) => {
      setProcessing(true);
      dispatch(ThunkAddSubscriptionDiscount(code))
        .unwrap()
        .then(() => {
          dispatch(updateDiscountCode({ data: discountData.description }));
          setProcessing(false);
          onClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [discountData?.description, dispatch, onClose],
  );

  const checkDealerCode = useCallback(
    code => {
      if (code.length > 5) {
        setProcessing(true);
        dispatch(ThunkCheckDealerCode({ code }))
          .unwrap()
          .then(res => {
            if (res.amount) {
              setAllowCode(true);
              setDiscountData(res);
            }
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [dispatch],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Apply Dealer Code</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Code"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    placeholder="Enter your dealer code"
                    name="code"
                    id="code"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <OnChange name="code">{checkDealerCode}</OnChange>

              {discountData && (
                <div style={{ margin: '10px 0px' }}>
                  <ListItem
                    text={discountData.description}
                    title="Description:"
                  />

                  <ListItem
                    text={getPricePlanCount(
                      billingData,
                      techCount,
                      minTechCount,
                      { data: discountData.description },
                    )}
                    title="Total Price:"
                  />
                </div>
              )}

              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton label="Close" onClick={onClose} />
                <ReusableButton
                  label="Submit"
                  type="submit"
                  viewType="blue"
                  disabled={!allowCode}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ApplyDealerCodeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ApplyDealerCodeDialog;
