import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { createTheme } from '@material-ui/core';
import { lightTheme } from '@services/themes/mainTheme';
import { useSelector } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import set from 'lodash/set';
import { dashboardChangedLayouts } from '@store/selectors';
import { selectChartConfig } from '@components/Dashboard/GridLayout/Tile/Layouts/utils';
import { chartStyle } from '@components/Dashboard/GridLayout/Tile/Layouts/constants';
import { DashboardChangedLayoutType } from '@components/Dashboard/GridLayout/constants';
import { chartTypes } from '@components/Dashboard/Modals/Tile/form';

const theme = createTheme(lightTheme());

const Chart = ({ data, tileId, chartType }) => {
  const chartRef = useRef(null);
  const changedLayouts = useSelector(dashboardChangedLayouts);

  const caption = useMemo(() => data && data.groupedBy, [data]);

  const config = useMemo(() => {
    if (data) {
      const pieConfig = selectChartConfig(chartType)(data, theme);
      if (chartType !== chartTypes.histogram) {
        set(pieConfig, 'title.text', `Grouped by ${caption}`);
      }
      return pieConfig;
    }
    return null;
  }, [data, caption, theme]);

  useEffect(() => {
    const isCurrentChanged = changedLayouts.find(
      ({ id, type }) =>
        id === tileId && type === DashboardChangedLayoutType.Resize,
    );
    if (isCurrentChanged) {
      console.log(chartRef.current?.chart?.reflow());
    }
  }, [changedLayouts, tileId]);

  return (
    <>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={config}
        containerProps={{ style: chartStyle }}
      />
    </>
  );
};

Chart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  tileId: PropTypes.number,
  chartType: PropTypes.number.isRequired,
};

export default Chart;
