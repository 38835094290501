import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { MoreVert, SaveRounded } from '@material-ui/icons';
import { Draggable } from 'react-beautiful-dnd';

import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';

import Input from '@components/Auth/Common/Input';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import useStyles from '../styles';

const StepsHolderItem = ({
  item,
  index,
  handleUpdateStep,
  handleDeleteStep,
  openInEditMode = false,
}) => {
  const [editMode, setEditMode] = useState(openInEditMode);
  const [inputValue, setInputValue] = useState(item.message || '');

  const classes = useStyles();

  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {provided => (
        <Grid
          item
          xs={12}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={classes.stepsHolderItem}>
            {editMode ? (
              <Input
                meta={{
                  touched: false,
                }}
                input={{
                  value: inputValue,
                  onChange: e => {
                    setInputValue(e.target.value);
                  },
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (inputValue) {
                      setEditMode(false);
                      handleUpdateStep({
                        ...item,
                        message: inputValue,
                      });
                    }
                  }
                }}
              />
            ) : (
              <>{item.message}</>
            )}

            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {editMode ? (
                <ActionFieldItem
                  handler={() => {
                    setEditMode(false);
                    handleUpdateStep({
                      ...item,
                      message: inputValue,
                    });
                  }}
                  classNameWrapper={classes.alignCenter}
                  alt="save"
                  toolTip="Save Change"
                >
                  <SaveRounded />
                </ActionFieldItem>
              ) : (
                <ActionFieldItem
                  handler={() => {
                    setEditMode(true);
                  }}
                  icon={EditIcon}
                  classNameWrapper={classes.alignCenter}
                  alt="edit"
                  toolTip="Edit Item"
                />
              )}

              <ActionFieldItem
                handler={() => {
                  handleDeleteStep(item.id);
                }}
                icon={DeleteIcon}
                classNameWrapper={classes.alignCenter}
                alt="delete"
                toolTip="Delete"
              />
              <MoreVert />
            </Box>
          </div>
        </Grid>
      )}
    </Draggable>
  );
};

export default StepsHolderItem;
