/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AUTOTASK_TICKET } from '@store/slices/resources';
import {
  ThunkCreateAutotaskNote,
  ThunkGetAutotaskNotes,
  ThunkUpdateAutotaskNote,
} from '@store/slices/autotaskTicket/thunks';

const autotaskTicketSlice = createSlice({
  name: AUTOTASK_TICKET,
  initialState: {
    notes: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetAutotaskNotes.fulfilled, (state, { payload }) => {
      state.notes = payload;
    });

    builder.addCase(ThunkCreateAutotaskNote.fulfilled, (state, { payload }) => {
      state.notes.push(payload);
    });

    builder.addCase(ThunkUpdateAutotaskNote.fulfilled, (state, { payload }) => {
      state.notes = state.notes.map(i => (i.id === payload.id ? payload : i));
    });
  },
});

export default autotaskTicketSlice.reducer;
export const AutotaskTicketNotesSelector = state =>
  state[AUTOTASK_TICKET].notes;
