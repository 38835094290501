import appConfig from '@configs/appConfig';
import { user } from '@constants/appRoutes';
import putWithResponse from '@services/api/common/putWithResponse';

export const linkAccount = (token, type) =>
  putWithResponse(`${appConfig.baseUrl}${user}LinkAccount`, {
    token,
    tokenType: type,
  });

export const unLinkAccount = type =>
  putWithResponse(`${appConfig.baseUrl}${user}UnLinkAccount/${type}`, null);
