import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
  ThunkAddIncidentTimeEntry,
  ThunkGetIncident,
} from '@store/slices/incident/thunks';
import { validateRequired } from '@common/functions/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

import useStyles from '../../styles';

const dateFormat = 'YYYY-MM-DD[T]kk:mm';

const initialValues = {
  startDateTime: moment().format(dateFormat),
  endDateTime: moment()
    .add(1, 'h')
    .format(dateFormat),
  note: '',
};

const AddTimeEntryDialog = ({ open, onClose, incidentId }) => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const submit = values => {
    const payload = {
      startDateTime: moment(values.startDateTime)
        .utc()
        .toISOString(),
      endDateTime: moment(values.endDateTime)
        .utc()
        .toISOString(),
      note: values.note,
      incidentId: +incidentId,
      accountId: localStorage.getItem(localStorageConst.USER_ID),
    };

    setLoading(true);
    dispatch(ThunkAddIncidentTimeEntry(payload))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Successfully added', { variant: 'success' });
        onClose();
        dispatch(ThunkGetIncident(incidentId));
      });
  };

  const validation = values => {
    const validationResult = {
      startDateTime: validateRequired(values.startDateTime),
      endDateTime: validateRequired(values.endDateTime),
      note: validateRequired(values.note),
    };

    if (moment(values.startDateTime).isSameOrAfter(values.endDateTime)) {
      validationResult.endDateTime = 'Invalid value';
    }

    return validationResult;
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle className={classes.title} id="simple-dialog-title">
        Add time entry
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validation}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Start"
                labelSize={3}
                contentSize={8}
                content={
                  <Field
                    showLabel
                    id="startDateTime"
                    name="startDateTime"
                    component={Input}
                    type="datetime-local"
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="End"
                labelSize={3}
                contentSize={8}
                content={
                  <Field
                    showLabel
                    id="endDateTime"
                    name="endDateTime"
                    component={Input}
                    type="datetime-local"
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="Note"
                labelSize={3}
                contentSize={8}
                content={
                  <Field
                    showLabel
                    id="note"
                    name="note"
                    multiline
                    rows={3}
                    component={Input}
                    disabled={loading}
                  />
                }
              />
              <DialogActions>
                <div>
                  <ReusableButton
                    size="md"
                    viewType="red"
                    label="Cancel"
                    onClick={onClose}
                    disabled={loading}
                  />
                  <ReusableButton
                    size="md"
                    label="Create"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  />
                </div>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddTimeEntryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  incidentId: PropTypes.number.isRequired,
};

export default AddTimeEntryDialog;
