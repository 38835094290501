import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConnectWiseTicketScheduleEntry } from '@services/requests/connectWiseTicket';
import { CONNECT_WISE_TICKET } from '@store/slices/resources';

export const ThunkDeleteConnectWiseTicketScheduleEntry = createAsyncThunk(
  `${CONNECT_WISE_TICKET}/deleteConnectWiseTicketScheduleEntry`,
  async ({ crmId, scheduleEntryId }) => {
    await deleteConnectWiseTicketScheduleEntry(crmId, scheduleEntryId);
    return scheduleEntryId;
  },
);

export default {};
