import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIncidentTimeEntries } from '@services/requests/incidents';
import { INCIDENT } from '@store/slices/resources';
import { normalizeTimeEntry } from '../generator';

export const ThunkGetIncidentTimeEntry = createAsyncThunk(
  `${INCIDENT}/getIncidentTimeEntry`,
  async incidentId => {
    const incidentTimeEntries = await getIncidentTimeEntries(incidentId);
    return incidentTimeEntries.map(timeEntry => normalizeTimeEntry(timeEntry));
  },
);

export default {};
