import { createAsyncThunk } from '@reduxjs/toolkit';
import { LIVE_CHATS } from '@store/slices/resources';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { LIVE_CHAT_ADMIN } from '@services/requests/requestResources';

export const ThunkUploadWidgetLogo = createAsyncThunk(
  `${LIVE_CHATS}/uploadWidgetLogo`,
  ({ resourceId, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    return sendFormData(
      `${appConfig.baseUrl}${LIVE_CHAT_ADMIN}/resources/${resourceId}/upload-logo`,
      formData,
    );
  },
);

export default {};
