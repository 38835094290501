import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  switcher: {
    height: '2.125rem',
    padding: '0.75rem 0.5rem',
    overflow: 'initial',
    transform: 'matrix(1, 0, 0, -1, 0, 12)',

    '& .MuiSwitch-root': {
      width: '4.375rem',
      height: '2.125rem',
      padding: '0.75rem 0.5rem',
      overflow: 'initial',
    },
    '& .MuiIconButton-root': {
      '&:hover': {
        background: 'none',
      },
    },

    '& .Mui-checked': {
      '& .MuiSwitch-thumb': {
        background: '#1b3670',
        boxShadow: '0 0 0.3125rem #73C766',
        marginLeft: '-0.1rem',
      },
    },

    '& .MuiTypography-root': {
      marginTop: '0.6875rem',
    },

    '& .MuiSwitch-thumb': {
      background: props => `linear-gradient(180deg, ${props.colorType})`,
      boxShadow: 'none',
      marginLeft: '0.2rem',
      marginTop: '0.375rem',
    },

    '& .MuiSwitch-track': {
      height: '1.5rem',
      borderRadius: '1.25rem',
      border: '0.0625rem solid grey',
      backgroundColor: 'white !important',
    },
  },
}));

export default useStyles;
