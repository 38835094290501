const setItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

const getItem = key => JSON.parse(localStorage.getItem(key));

const tryGetItem = key => {
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (err) {
    return undefined;
  }
};

const removeItem = key => localStorage.removeItem(key);

export default {
  getItem,
  setItem,
  removeItem,
  tryGetItem,
};
