import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';
import CopyIcon from '@assets/icons/copy_blue.svg';
import ViewLogsIcon from '@assets/icons/documents.svg';

import useStyles from '@common/Styles/actionFieldStyles';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

const ActionField = ({
  onEdit,
  record,
  onCopy,
  onViewLogs,
  onDelete,
  fieldKey,
  onClone,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {onEdit && (
        <ActionFieldItem handler={() => onEdit(record)} toolTip="Edit">
          <img src={EditIcon} alt="edit" />
        </ActionFieldItem>
      )}
      {onCopy && (
        <ActionFieldItem
          handler={() => onCopy(record[fieldKey])}
          toolTip="copy to clipboard"
        >
          <img src={CopyIcon} alt="copy to clipboard" />
        </ActionFieldItem>
      )}
      {onClone && (
        <ActionFieldItem handler={() => onClone(record)} toolTip="clone">
          <img src={CopyIcon} alt="clone" />
        </ActionFieldItem>
      )}
      {onViewLogs && (
        <ActionFieldItem handler={() => onViewLogs(record)} toolTip="View logs">
          <img src={ViewLogsIcon} alt="logs" />
        </ActionFieldItem>
      )}
      {!!children && <>{children}</>}
      {onDelete && (
        <ActionFieldItem handler={() => onDelete(record)} toolTip="Delete">
          <img src={DeleteIcon} alt="delete" />
        </ActionFieldItem>
      )}
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  fieldKey: PropTypes.string,
  onClone: PropTypes.func,
  onViewLogs: PropTypes.func,
  children: PropTypes.node,
};

export default ActionField;
