import { createAsyncThunk } from '@reduxjs/toolkit';
import { SMS_BLACK_LIST } from '@store/slices/resources';
import { deleteSmsBlackListNumber } from '@services/requests/smsBlackList';

export const ThunkDeleteSmsBlackListNumbers = createAsyncThunk(
  `${SMS_BLACK_LIST}/deleteSmsBlackListNumber`,
  async idList => {
    const params = idList.map(i => `id=${i}`).join('&');
    await deleteSmsBlackListNumber(params);
    return idList;
  },
);

export default {};
