import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMessagingTemplate } from '@services/requests/messaging';
import { MESSAGING } from '@store/slices/resources';

export const ThunkUpdateMessagingTemplate = createAsyncThunk(
  `${MESSAGING}/updateMessagingTemplate`,
  async ({ id, payload }) => {
    const template = await updateMessagingTemplate(id, payload);
    return template;
  },
);

export default {};
