export const daysOfWeek = Object.freeze({
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
});

export const daysOfWeekArray = [
  daysOfWeek.Sunday,
  daysOfWeek.Monday,
  daysOfWeek.Tuesday,
  daysOfWeek.Wednesday,
  daysOfWeek.Thursday,
  daysOfWeek.Friday,
  daysOfWeek.Saturday,
];
