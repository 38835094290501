import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import { OnChange } from 'react-final-form-listeners';
import { ThunkGetCrmDefaultsAutocomplete } from '@store/slices/crm/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { requiredValidator } from '@utils/validators';
import { ThunkGetCrmUsersByDefaultsId } from '@store/slices/user/thunks';
import { ThunkLinkCrmUsersToAccount } from '@store/slices/user/thunks/linkCrmUserToAccount';
import { actions } from '@store/actions';
import { currentUserDataSelector } from '@store/selectors';
import crmSources, { crmSourcesByType } from '@constants/crmSources';
import useStyles from '../../styles';

const LinkCrmUserDialog = ({ open, onClose, crmInfo, formChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type, field } = crmInfo;
  const currentUserData = useSelector(currentUserDataSelector);
  const [crmDefaults, setCrmDefaults] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({
    crmDefaultId: null,
    userId: null,
  });

  const onMount = useCallback(async () => {
    const res = await dispatch(ThunkGetCrmDefaultsAutocomplete()).unwrap();
    const defaults = res.filter(option => option.info === type.idx);
    if (defaults.length === 1) {
      setInitialValues({
        crmDefaultId: defaults[0].value,
        userId: null,
      });
    }
    setCrmDefaults(defaults);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const submit = async values => {
    setProcessing(true);
    const user = filteredUsers.find(u => u.value === values.userId);
    const payload = {
      isChecked: true,
      type: type.name,
      identity: user?.identity,
      name: user?.label,
      ...values,
    };
    await dispatch(ThunkLinkCrmUsersToAccount(payload))
      .unwrap()
      .then(() => {
        formChange(field, true);

        const newUserData = {
          ...currentUserData,
        };

        if (
          crmSourcesByType[payload.type]?.name === crmSources.ConnectWise.label
        ) {
          newUserData.cwUserLinked = true;
          newUserData.connectwiseLinkedUser = {
            ...payload,
            type: crmSources[crmSourcesByType[payload.type]?.name]?.idx,
          };
        }
        if (
          crmSourcesByType[payload.type]?.name === crmSources.Autotask.label
        ) {
          newUserData.atUserLinked = true;
          newUserData.autotaskLinkedUser = {
            ...payload,
            type: crmSources[crmSourcesByType[payload.type]?.name]?.idx,
          };
        }

        dispatch(actions.setCurrentUser(newUserData));

        setProcessing(false);
        onClose();
      });
  };

  const validate = values => {
    return {
      crmDefaultId: requiredValidator(values.crmDefaultId),
      userId: requiredValidator(values.userId),
    };
  };

  const handleCrmDefaultChange = async id => {
    setProcessing(true);
    await dispatch(ThunkGetCrmUsersByDefaultsId(id))
      .unwrap()
      .then(res => {
        setFilteredUsers(
          res.map(item => ({
            label: item.name,
            value: item.id,
            identity: item.identity,
          })),
        );
      });

    setProcessing(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogWith}>
        <span>Link current user to </span>
        <span>{type.label}</span>
        <span> user</span>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FieldWrapper
                label="PSA Default"
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="crmDefaultId"
                    id="crmDefaultId"
                    render={DropDown}
                    options={crmDefaults}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="PSA User"
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="userId"
                    id="userId"
                    render={AutocompleteFormInput}
                    items={filteredUsers}
                    loading={!values.crmDefaultId || processing}
                  />
                }
              />

              <OnChange name="crmDefaultId">
                {value => handleCrmDefaultChange(value)}
              </OnChange>

              <div className={classes.dialogActionsContainer}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    formChange(field, false);
                    onClose();
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

LinkCrmUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  crmInfo: PropTypes.shape({
    type: PropTypes.objectOf(PropTypes.any),
    field: PropTypes.string,
  }),
  formChange: PropTypes.func.isRequired,
};

export default LinkCrmUserDialog;
