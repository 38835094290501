import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LiveChatConversationTemplatesSelector } from '@store/slices/messaging';
import { ThunkGetLiveChatConversationTemplates } from '@store/slices/messaging/thunks';

import Loading from '../common/Loading/Loading';
import SimpleTable from '../Triggers/pages/Incident/components/SimpleTable';
import LiveChatConversationTemplatesTableActions from './components/LiveChatConversationTemplatesTableActions';

import useStyles from './styles';
import LiveChatConversationTemplatesTableItemActions from './components/LiveChatConvesationTemplatesTableItemActions';

const LiveChatConversationTemplates = () => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    { label: 'Name', value: 'name' },
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Actions',
      handler: item => (
        <LiveChatConversationTemplatesTableItemActions record={item} />
      ),
    },
  ];

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetLiveChatConversationTemplates());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <LiveChatConversationTemplatesTableActions />
      <SimpleTable
        selector={LiveChatConversationTemplatesSelector}
        columns={columns}
      />
    </div>
  );
};

export default LiveChatConversationTemplates;
