import { createAsyncThunk } from '@reduxjs/toolkit';
import { OPTIN_SETTINGS } from '@store/slices/resources';
import { saveOptInSettings } from '@services/requests/optInSettings';

export const ThunkSaveOptInSettings = createAsyncThunk(
  `${OPTIN_SETTINGS}/saveOptInSettings`,
  async payload => {
    const res = await saveOptInSettings(payload);
    return res;
  },
);

export default {};
