import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { setTenantTimeZone } from '@components/SetupWizard/helpers';
import { COMMON } from '@store/slices/resources';
import { TenantSettingsSelector } from '@store/slices/common';
import { ThunkGetTimeZones } from '@store/slices/common/thunks';

import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';

import useStyles from '../styles';

const TimeZone = () => {
  const tenantSettings = useSelector(TenantSettingsSelector);

  const [loading, setLoading] = useState(true);
  const [timeZoneInputValue, setTimeZoneInputValue] = useState(
    tenantSettings?.timeZone ?? '',
  );

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const timeZones = useSelector(state => state[COMMON].types);

  const handleSetTimeZoneClick = useCallback(() => {
    if (timeZoneInputValue !== '') {
      setTenantTimeZone({ data: timeZoneInputValue }).then(() => {
        enqueueSnackbar('Successfully set time zone for tenant', {
          variant: 'success',
        });
      });
    }
  }, [timeZoneInputValue, enqueueSnackbar]);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetTimeZones());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Accordion>
      <AccordionSummary>Select Preferred Time Zone For Tenant</AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.timeZoneContainer}>
            <div>
              <AutocompleteFormInput
                items={timeZones}
                input={{
                  value: timeZoneInputValue || [],
                  onChange: inputValue => {
                    setTimeZoneInputValue(inputValue);
                  },
                }}
                placeholder="Time Zones"
              />
            </div>
            <div className={classes.timeZoneButtonContainer}>
              <ReusableButton
                viewType="white"
                buttonType="simple"
                label="Set Time Zone"
                onClick={() => {
                  handleSetTimeZoneClick();
                }}
                textSize="mini"
              />
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

TimeZone.propTypes = {};

export default TimeZone;
