import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Person as PersonIcon, Group as GroupIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';

import {
  ChatSelector,
  ActionChatSetActive,
  ChatsNotificationsSelector,
  ActionRemoveNotifications,
} from '@store/slices/chats';
import { CHAT_TYPES } from '@constants/chatTypes';
import { currentUserDataSelector } from '@store/selectors';
import useStyles from '../../styles';

const ChatListItem = React.memo(({ chatId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector(currentUserDataSelector);
  const notifications = useSelector(ChatsNotificationsSelector);
  const { type, name, id, isActive = false } = useSelector(state =>
    ChatSelector(state, chatId),
  );

  const getChatName = chatName => {
    const usersName = chatName.split('|');
    return usersName[0] !== currentUser?.username ? usersName[0] : usersName[1];
  };

  const setActiveChat = useCallback(
    chat => {
      dispatch(ActionChatSetActive(chat));
      dispatch(ActionRemoveNotifications(chat));
    },
    [dispatch],
  );

  const notificationCount = useMemo(() => notifications[chatId] || [], [
    notifications,
    chatId,
  ]);

  return (
    <button
      type="button"
      className={cx(classes.chatItemContainer, {
        [classes.chatItemActive]: isActive,
      })}
      onClick={() => {
        setActiveChat(id);
      }}
    >
      <div className={cx(classes.chatItemContent, {})}>
        {type === CHAT_TYPES.private ? <PersonIcon /> : <GroupIcon />}
        {type === CHAT_TYPES.private ? getChatName(name) : name}

        {notificationCount.length > 0 && (
          <Badge
            overlap="rectangular"
            color="primary"
            badgeContent={notificationCount.length}
            className={classes.chatDotIcon}
          />
        )}
      </div>
    </button>
  );
});

ChatListItem.propTypes = {
  chatId: PropTypes.number,
};

export default ChatListItem;
