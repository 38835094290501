import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusGrey.svg';

const AddRuleActions = ({ handleOnClick }) => {
  return (
    <ReusableButton onClick={handleOnClick}>
      <>
        <img src={iconPlus} alt="iconPlus" />
        Add rule
      </>
    </ReusableButton>
  );
};

AddRuleActions.propTypes = {
  handleOnClick: PropTypes.func,
};

export default AddRuleActions;
