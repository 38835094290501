import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: '50%',
    height: '100%',
    margin: '0 auto',
    paddingTop: '15px',
  },
  alignLeft: {
    textAlign: 'left',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginRight: '10px',
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionMargin: {
    margin: '6px 0 0 10px',
  },
}));

export default useStyles;
