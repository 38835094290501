import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmUsersByDefaultsId } from '@services/requests/crm';
import { CRM } from '@store/slices/resources';

export const ThunkGetCrmUsersByDefaultsId = createAsyncThunk(
  `${CRM}/getCrmUsersByDefaultsId`,
  async ({ crmDefaultId, companyId = null }) => {
    const users = await getCrmUsersByDefaultsId(crmDefaultId, companyId);
    return users;
  },
);

export default {};
