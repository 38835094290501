import { createAsyncThunk } from '@reduxjs/toolkit';
import { getZendeskIntegrationStatuses } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetZendeskIntegrationStatuses = createAsyncThunk(
  `${INTEGRATION}/getZendeskIntegrationStatuses`,
  async ({ crmId }) => {
    const statuses = await getZendeskIntegrationStatuses(crmId);
    return statuses;
  },
);

export default {};
