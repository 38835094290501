import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ViewIcon from '@assets/icons/visibility.svg';
import { CLIENT_PORTAL_CHAT_DETAILS } from '@constants/routes';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import UserIcon from '@assets/icons/user.svg';
import ClientPortalChatAssignDialog from '@components/ClientPortal/dialogs/ClientPortalChatAssignDialog';
import { actions } from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';

import { ThunkAssignResourceToClientPortalChat } from '@store/slices/clientPortalAdmin/thunks';
import useStyles from '../styles';

const ClientPortalChatTableActions = ({ id }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const dispatch = useDispatch();
  const list = useSelector(listSelector);

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleAssignDialogSelectValue = async value => {
    setProcessing(true);
    await dispatch(
      ThunkAssignResourceToClientPortalChat({
        id,
        assigneeId: value,
      }),
    )
      .unwrap()
      .then(response => {
        const listItem = list.find(p => p.id === id);
        listItem.assigneeId = response.id.toString();
        listItem.assigneeName = response.value;
        dispatch(actions.updateItemInList(listItem));
      })
      .finally(() => {
        setProcessing(false);
        setAssignDialogOpen(false);
      });
  };

  return (
    <>
      <div className={classes.actionsContainer}>
        <ActionFieldItem
          icon={ViewIcon}
          alt="view"
          handler={() => {
            push(CLIENT_PORTAL_CHAT_DETAILS.replace(':id', id));
          }}
          toolTip="View"
        />
        <ActionFieldItem
          icon={UserIcon}
          alt="assign"
          handler={() => {
            setAssignDialogOpen(id);
          }}
          toolTip="Assign"
        />
      </div>
      <ClientPortalChatAssignDialog
        open={assignDialogOpen}
        onSelect={handleAssignDialogSelectValue}
        onClose={() => setAssignDialogOpen(false)}
        disabled={processing}
      />
    </>
  );
};

export default ClientPortalChatTableActions;
