import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '2rem',
  },
  tabsWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    paddingTop: '1.75rem',
    marginTop: 0,
    borderBottom: '0.0625rem solid #D9D5D5',
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      width: '31rem',
      margin: '0.5rem auto',
    },
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0.5rem 2.5rem',
    border: '0.0625rem solid #EBEBEB',
    backgroundColor: theme.palette.background.default,
    margin: '0.5rem',
    borderRadius: '0.8125rem',
  },
  font: {
    fontStyle: 'normal',
    fontSize: '0.875rem',
  },
  title: {
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  textPartTwo: {
    textAlign: 'center',
    marginTop: '0.25rem',
    fontSize: '1rem',
  },
  block: {
    [theme.breakpoints.up('sm')]: {},
    width: '93%',
    textAlign: 'center',
    margin: '0.5rem 0',
  },
  buttonInvoice: {
    marginTop: '0.5rem',
  },
  inputGroup: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    width: '100%',
    marginTop: '0.625rem',
  },
  imageGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '2.5rem',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
  buttonWrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: '2.5rem',
    marginLeft: 0,
  },
}));

export default useStyles;
