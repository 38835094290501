import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    overflow: 'auto',
  },
  tabsWrapper: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.tabs.background,
    paddingTop: '1.75rem',
    marginTop: 0,
    borderBottom: '0.0625rem solid #D9D5D5',
    display: 'flex',
  },
}));

export default useStyles;
