import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH } from '@store/slices/resources';
import { verifyPasswordResetCode } from '@services/requests/auth';

export const ThunkVerifyResetPasswordCode = createAsyncThunk(
  `${AUTH}/verifyPasswordResetCode`,
  ({ code }) => verifyPasswordResetCode(code),
);

export default {};
