import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: props =>
      `linear-gradient(117.3deg, ${props.baseColorA} 0.68%, ${props.baseColorB} 95.06%)`,
    height: '100vh',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export default styles;
