import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { currentUserDataSelector } from '@store/selectors';

import Roles from '@constants/roles';

import ReusableButton from '@common/Button/Button';
import OptInForm from '@components/Messaging/MessagingTemplates/components/OptInForm';
import TemplateDialog from '@components/Messaging/MessagingTemplates/dialogs/TemplateDialog';

import { useGetPricePlan } from '../../../../hooks/useGetPricePlan';

import useStyles from '../styles';

const TemplateActions = () => {
  const classes = useStyles();
  const currentUser = useSelector(currentUserDataSelector);
  const { isFreePlan } = useGetPricePlan();

  const [optInDialogOpen, setOptInDialogOpen] = useState(false);

  return (
    <div className={classes.buttonsRegion}>
      {currentUser &&
        currentUser?.roleId !== Roles.READ_ONLY &&
        !isFreePlan && (
          <ReusableButton
            size="md"
            textSize="mini"
            label="Opt-in template"
            onClick={() => setOptInDialogOpen(true)}
          />
        )}
      <TemplateDialog
        open={optInDialogOpen}
        setOpen={setOptInDialogOpen}
        content={
          <OptInForm
            onSubmit={() => setOptInDialogOpen(false)}
            onCancel={() => setOptInDialogOpen(false)}
          />
        }
        title="Opt-in process template form"
      />
    </div>
  );
};

export default TemplateActions;
