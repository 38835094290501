import { useMemo, useState } from 'react';

import { COUNT_PRODUCT_PER_PAGE } from '@constants/filters';
import useFilter from '@common/MuiVirtualizedTable/hooks/useFilter';
import useControllerTableBase from '@common/MuiVirtualizedTable/hooks/useControllerTableBase';

const useControllerListViewProps = props => {
  const {
    tableKey,
    data,
    groupByValue,
    rowsWrapping,
    defaultSort,
    searchText,
    defaultFilters,
    columns,
  } = props;

  const [perPage, setPerPage] = useState(COUNT_PRODUCT_PER_PAGE);
  const [sort, setSort] = useState(defaultSort || {});
  const {
    handleColumnResizeDebounce,
    changeFiltersDebounce,
    onToggleItem,
    onUnselectItems,
    onSelect,
    setSortCustom,
    showFilter,
    hideFilter,
    filters,
    displayedFilters,
    selectedIds,
    resetSelectedIds,
  } = useControllerTableBase({
    data,
    tableKey,
    groupByValue,
    sort,
    setSort,
    defaultFilters,
  });

  const searchFields = useMemo(() => columns.map(i => i.key), [columns]);

  const search = useMemo(
    () =>
      searchText && {
        query: searchText,
        fields: searchFields,
      },
    [searchFields, searchText],
  );

  const { data: result } = useFilter({
    data,
    filters,
    sort,
    search,
  });

  const rows = useMemo(() => (rowsWrapping ? rowsWrapping(result) : result), [
    result,
    rowsWrapping,
  ]);

  return {
    perPage,
    setPerPage,
    setSort: setSortCustom,
    currentSort: sort,
    onToggleItem,
    selectedIds,
    onSelect,
    setFilters: changeFiltersDebounce,
    hideFilter,
    showFilter,
    onUnselectItems,
    filterValues: filters,
    displayedFilters,
    data: result,
    rows,
    ids: result?.map(({ id }) => id) || [],
    handleColumnResizeDebounce,
    resetSelectedIds,
  };
};

export default useControllerListViewProps;
