import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBufferPhoneNumbers } from '@services/requests/phoneNumbers';
import { BUFFER_PHONE_NUMBERS } from '@store/slices/resources';

export const ThunkGetPhoneNumbers = createAsyncThunk(
  `${BUFFER_PHONE_NUMBERS}/getBufferPhoneNumbers`,
  async () => {
    const phoneNumbers = await getBufferPhoneNumbers();
    return phoneNumbers;
  },
);

export default {};
