import { useReducer, useState } from 'react';

const initialDatesFilter = { start: null, end: null };

const init = () => initialDatesFilter;

const reducer = (state, action) => {
  switch (action.type) {
    case 'change': {
      return action.payload;
    }
    case 'reset': {
      return init();
    }
    default: {
      return init();
    }
  }
};

const useTableDateLabel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, dispatch] = useReducer(reducer, initialDatesFilter, init);

  return { date, isOpen, dateDispatcher: dispatch, setIsOpen };
};

export default useTableDateLabel;
