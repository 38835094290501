import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { ChatSelector } from '@store/slices/chats';
import { currentUserDataSelector, getChatMessages } from '@store/selectors';
import { ThunkSendChatMessage } from '@store/slices/chats/thunks/sendChatMessage';
import { PUSHER_EVENTS } from '@constants/pusherEvents';

import ChatMessage from './ChatMessage';

import useStyles from '../styles';

const ChatBody = ({ pusher, activeChatId }) => {
  const [inputText, setInputText] = useState('');
  const [newMessages, setNewMessages] = useState([]);
  const [socketNewMessage, setMessage] = useState();

  const classes = useStyles();
  const ref = useRef();
  const dispatch = useDispatch();

  const messages = useSelector(getChatMessages);
  const selectedChat = useSelector(state => ChatSelector(state, activeChatId));
  const currentUser = useSelector(currentUserDataSelector);

  const handleMessageSend = (message, chatId) => {
    if (message === '') {
      return;
    }
    setInputText('');
    const payload = {
      message,
      chatId,
    };

    const uniqueIdPart1 = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    const uniqueIdPart2 = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

    const newMessage = {
      id: `${uniqueIdPart1}${uniqueIdPart2}`,
      chatId: selectedChat.id,
      chatChannel: selectedChat.chatChannel,
      dateTime: new Date().toISOString(),
      message,
      senderId: currentUser.id,
      senderName: currentUser.username,
      isLoading: true,
      hasError: false,
    };
    const messagesArray = [...newMessages, newMessage];
    setNewMessages(messagesArray);
    try {
      dispatch(ThunkSendChatMessage({ payload, message: newMessage }))
        .unwrap()
        .then(() => {
          setNewMessages(newMessages.filter(item => item.id !== newMessage.id));
        });
    } catch (e) {
      setNewMessages([...newMessages, { ...newMessage, hasError: true }]);
    }
  };

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'auto' });
  }, [messages, newMessages]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const channel = useMemo(() => pusher.subscribe(selectedChat.chatChannel), [
    selectedChat,
  ]);

  useEffect(() => {
    channel.bind(PUSHER_EVENTS.newMessage, data => {
      if (
        data.SenderId !== currentUser.id &&
        !newMessages.some(i => i.id === data.MessageId)
      ) {
        const newMessage = {
          id: data.MessageId,
          chatId: data.ChatId,
          chatChannel: data.ChatChannel,
          message: data.Message,
          senderId: data.SenderId,
          senderName: data.SenderName,
          dateTime: new Date().toISOString(),
          isLoading: false,
          hasError: false,
        };
        setMessage(newMessage);
      }
    });

    return () => {
      if (channel.subscribed) {
        pusher.unsubscribe(selectedChat.chatChannel);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (socketNewMessage) {
      setNewMessages([...newMessages, socketNewMessage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketNewMessage]);

  return (
    <div className={classes.chatBody}>
      <div className={classes.chatMessages}>
        {messages.map(message => (
          <ChatMessage message={message} key={message.id} />
        ))}
        {newMessages.length !== 0 &&
          newMessages.map(message => (
            <ChatMessage message={message} key={`new${message.id}`} />
          ))}
        <div ref={ref} />
      </div>
      <div className={classes.chatInput}>
        <TextField
          variant="outlined"
          className={classes.chatTextInput}
          multiline
          rows={1}
          rowsMax={3}
          value={inputText}
          onChange={({ target }) => {
            setInputText(target.value);
          }}
          onKeyPress={() => {
            if (window.event.ctrlKey && window.event.keyCode === 13) {
              handleMessageSend(inputText, selectedChat.id);
            }
          }}
        />

        <SendIcon
          onClick={() => {
            handleMessageSend(inputText, selectedChat.id);
          }}
        />
      </div>
    </div>
  );
};

ChatBody.propTypes = {
  pusher: PropTypes.objectOf(PropTypes.any),
  activeChatId: PropTypes.number,
};

export default ChatBody;
