import { useEffect, useMemo } from 'react';
import ThunkGetCountries from '@store/slices/countries/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { countryCodesListSelector } from '@store/slices/countries';
import addFlagsToOptions from '@constants/addFlagsToOptions';

export const useCountryCodesOptions = () => {
  const { countries, loading } = useSelector(countryCodesListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries?.length) {
      dispatch(ThunkGetCountries());
    }
  }, [countries.length, dispatch]);

  return useMemo(
    () => ({
      loading,
      countries,
      countryCodesOptions: addFlagsToOptions(countries),
    }),
    [countries, loading],
  );
};

export default {};
