import { createAsyncThunk } from '@reduxjs/toolkit';
import { KASEYA_TICKET } from '@store/slices/resources';
import { getKaseyaTypes } from '@services/requests/kaseyaTicket';

export const ThunkGetKaseyaTypes = createAsyncThunk(
  `${KASEYA_TICKET}/getKaseyaTypes`,
  ({ crmDefaultId }) => getKaseyaTypes(crmDefaultId),
);

export default {};
