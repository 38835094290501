import { LIVE_CHATS } from '../resources';

export const SelectResourcesList = state => state[LIVE_CHATS].resourcesOptions;

export const SelectResourceOptions = state =>
  state[LIVE_CHATS].resourcesOptions.map(i => ({
    label: i.name,
    value: +i.id,
  }));

export const SelectResourceOptionsLoading = state =>
  state[LIVE_CHATS].resourcesLoading;
export const SelectCurrentChatsMessages = state =>
  state[LIVE_CHATS].currentChatMessages;
export const SelectCurrentChatsMessagesLoading = state =>
  state[LIVE_CHATS].currentChatMessagesLoading;

export const SelectCurrentUserInfo = state => state[LIVE_CHATS].currentUserInfo;

export const LiveChatConfigurationSelector = state =>
  state[LIVE_CHATS].liveChatConfigurations;
export default {};
