import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRedirect } from 'react-admin';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Input from '@components/Auth/Common/Input';
import Paper from '@common/Paper/Paper';
import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import { SECURITY_INTEGRATION } from '@constants/routes';

import {
  ThunkAddSecurityIntegration,
  ThunkUpdateSecurityIntegration,
} from '@store/slices/securityIntegrations/thunks';

import hasLeadingOrTrailingSpaces from '@utils/validators/hasLeadingOrTrailingSpacesValidator';

import useStyles from './formStyles';

const BaseSettings = ({ item, integrationType, onSubmit, onBack }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isEdit = Boolean(item);

  const [loading, setLoading] = useState(false);

  const submit = async values => {
    const data = {
      id: item?.id,
      type: integrationType,
      ...values,
    };
    setLoading(true);

    if (isEdit) {
      await dispatch(ThunkUpdateSecurityIntegration(data))
        .unwrap()
        .then(() => {
          setLoading(false);
          if (onSubmit) {
            onSubmit();
          }
          redirect(SECURITY_INTEGRATION);
        })
        .catch(e => {
          setLoading(false);
          enqueueSnackbar(`Unable to edit integration: ${e.message}`, {
            variant: 'error',
          });
        });
    } else {
      await dispatch(ThunkAddSecurityIntegration(data))
        .unwrap()
        .then(() => {
          setLoading(false);
          if (onSubmit) {
            onSubmit();
          }
          redirect(SECURITY_INTEGRATION);
        })
        .catch(e => {
          setLoading(false);
          enqueueSnackbar(`Unable to create integration: ${e.message}`, {
            variant: 'error',
          });
        });
    }
  };

  const validate = values => {
    return {
      integrationKey: hasLeadingOrTrailingSpaces(values.integrationKey),
      host: hasLeadingOrTrailingSpaces(values.host),
      secretKey: hasLeadingOrTrailingSpaces(values.secretKey),
    };
  };

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={
          item && {
            id: item?.id,
            type: integrationType,
            name: item?.name,
            integrationKey: item?.integrationKey,
            secretKey: item?.secretKey,
            host: item?.host,
          }
        }
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <FieldWrapper
                label="Integration Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="Integration Key"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="integrationKey"
                    id="integrationKey"
                    render={Input}
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="Host"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="host"
                    id="host"
                    render={Input}
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="Secret key"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="secretKey"
                    id="secretKey"
                    type="password"
                    render={Input}
                    autoComplete="new-password"
                    disabled={loading}
                  />
                }
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                label="Cancel"
                classNameWrapper={classes.button}
                onClick={onBack}
                disabled={loading}
              />
              <ReusableButton
                size="md"
                viewType="black"
                type="submit"
                classNameWrapper={classes.button}
                disabled={loading}
                loading={loading}
                label="Submit"
              />
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

BaseSettings.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  integrationType: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func.isRequired,
};

export default BaseSettings;
