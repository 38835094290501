import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { downloadCSV, useMutation } from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { actions } from '@store/actions';
import {
  COUNT_PRODUCT_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
} from '@constants/filters';

import useControllerTableBase from '@common/MuiVirtualizedTable/hooks/useControllerTableBase';
import { addErrors } from '@store/slices/errors';

const pickFields = (i, fields) =>
  Object.keys(i)
    .filter(key => fields.indexOf(key) >= 0)
    .reduce((obj2, key) => Object.assign(obj2, { [key]: i[key] }), {});

const keyBy = (array, key) =>
  array?.reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

const formatData = data => {
  /**
   * created this function for solving problem with value which type is "number"
   */

  return data.map(item => {
    const cloneObj = { ...item };
    Object.keys(cloneObj).forEach(key => {
      if (typeof item[key] === 'number') {
        cloneObj[key] = cloneObj[key].toString();
      }
    });
    return cloneObj;
  });
};

const useControllerTableProps = props => {
  const {
    resource,
    defaultFilters,
    defaultSort,
    columns,
    groupByValue,
    rowsWrapping,
    selectorKey,
  } = props;

  const dataFromStore =
    useSelector(state => {
      if (
        Object.prototype.toString.call(state.list.list) === '[object Object]' &&
        !selectorKey
      ) {
        return [];
      }
      return selectorKey ? state.list.list[selectorKey] : state.list.list;
    }) || [];

  const dispatch = useDispatch();

  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [perPage, setPerPage] = useState(COUNT_PRODUCT_PER_PAGE);
  const [sort, setSort] = useState(defaultSort || {});

  const {
    handleColumnResizeDebounce,
    changeFiltersDebounce,
    onToggleItem,
    onUnselectItems,
    onSelect,
    setSortCustom,
    showFilter,
    hideFilter,
    filters,
    displayedFilters,
    selectedIds,
    resetSelectedIds,
  } = useControllerTableBase({
    data: dataFromStore,
    tableKey: resource,
    groupByValue,
    sort,
    setSort,
    defaultFilters,
  });

  const [
    getListDebounce,
    { data = [], loading, error, total, loaded },
  ] = useMutation({
    type: 'getList',
    resource,
    payload: {
      pagination: { page, perPage },
      sort,
      filter:
        defaultFilters && Object.keys(filters).length === 0
          ? defaultFilters
          : { ...defaultFilters, ...filters },
    },
  });

  useEffect(() => {
    setPage(DEFAULT_PAGE_NUMBER);
    setPerPage(COUNT_PRODUCT_PER_PAGE);
  }, [filters, sort, resource]);

  useEffect(() => {
    if (!selectorKey) {
      dispatch(actions.saveList([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getListDebounce();
    }, 1000);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort, resource, page]);

  useEffect(() => {
    if (data && !error) {
      const formattedData = formatData(data);

      const saveData =
        page === DEFAULT_PAGE_NUMBER
          ? formattedData
          : [...dataFromStore, ...formattedData];
      dispatch(
        actions.saveList(
          selectorKey ? { data: saveData, key: selectorKey } : saveData,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error?.response?.data?.message) {
      dispatch(
        addErrors({ message: `RequestError: ${error.response.data.message}` }),
      );
    }
  }, [error]);

  const exporter = () => {
    const filteredColumns = columns.filter(item => item.key);
    const headersDoc = filteredColumns.map(item =>
      typeof item.name === 'string' ? item.name : item.key,
    );
    const fields = filteredColumns.map(item => item.key);
    const payload = dataFromStore.map(i => pickFields(i, fields));

    jsonExport(
      payload,
      {
        headersDoc,
      },
      (err, csv) => {
        downloadCSV(csv, resource);
      },
    );
  };

  const rows = useMemo(
    () => (rowsWrapping ? rowsWrapping(dataFromStore) : dataFromStore),
    [dataFromStore, rowsWrapping],
  );

  return {
    exporter,
    page,
    perPage,
    setPage,
    setPerPage,
    setSort: setSortCustom,
    currentSort: sort,
    basePath: `/${resource}`,
    resource,
    onToggleItem,
    selectedIds,
    onSelect,
    setFilters: changeFiltersDebounce,
    hideFilter,
    showFilter,
    onUnselectItems,
    filterValues: filters,
    displayedFilters,
    data: keyBy(dataFromStore, 'id'),
    onRefreshList: getListDebounce,
    rows,
    loading,
    loaded,
    total,
    ids: dataFromStore?.map(({ id }) => id) || [],
    handleColumnResizeDebounce,
    resetSelectedIds,
  };
};

export default useControllerTableProps;
