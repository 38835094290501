import React, { useState } from 'react';

import ReusableButton from '@ui/components/common/Button/Button';
import AddLiveChatConversationTemplateDialog from '../dialogs/AddLiveChatConversationTemplateDialog';

import useStyles from '../styles';

const LiveChatConversationTemplatesTableActions = () => {
  const [openAddTemplateDialog, setOpenAddTemplateDialog] = useState(false);

  const classes = useStyles();
  return (
    <div className={classes.templatesTableActions}>
      <ReusableButton
        label="Add Template"
        onClick={() => {
          setOpenAddTemplateDialog(true);
        }}
      />

      {openAddTemplateDialog && (
        <AddLiveChatConversationTemplateDialog
          open={openAddTemplateDialog}
          setOpen={setOpenAddTemplateDialog}
        />
      )}
    </div>
  );
};

LiveChatConversationTemplatesTableActions.propTypes = {};

export default LiveChatConversationTemplatesTableActions;
