import React, { useState } from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';

import EditIcon from '@assets/icons/edit.svg';
import OptInIcon from '@assets/icons/documents.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import OptInDialog from '@components/integrations/connectwise/dialogs/OptInDialog';
import PodDialog from '@components/PodDialog';
import EditContactDialogV2 from '@components/PsaContacts/dialogs/EditContactDialogV2';

import useStyles from '../../../styles';

const Microsoft365ContactsTableActions = ({ record, psaId }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openOptInDialog, setOpenOptInDialog] = useState(false);

  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();

  const handleEdit = () => {
    setOpenEditDialog(true);
  };

  return (
    <div className={classes.tableActions}>
      <ActionFieldItem
        alt="Edit"
        handler={handleEdit}
        toolTip="Edit"
        icon={EditIcon}
      />
      <ActionFieldItem
        alt="Open POD"
        handler={() => {
          setOpenPodDialog(true);
        }}
        toolTip="Open POD"
        icon={ShieldPersonIcon}
        ignoreDefaultIconSize
      />
      <ActionFieldItem
        alt="Opt-in form"
        handler={() => {
          setOpenOptInDialog(true);
        }}
        toolTip="Opt-in form"
        icon={OptInIcon}
      />
      <ActionFieldItem
        alt="Secure Data"
        handler={() => setOpenSecureDialog(true)}
        toolTip="Secure data"
        icon={SyncIcon}
        ignoreDefaultIconSize
      />

      {openEditDialog && (
        <EditContactDialogV2
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          record={record}
          psaId={psaId}
          contactId={record.id}
          psaType={crmSources.Microsoft.label}
        />
      )}

      {openPodDialog && (
        <PodDialog
          itemId={+record.id}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="contact"
          psaId={psaId}
          psa={crmSources.Microsoft.label}
          page="verification"
        />
      )}

      {/* OPT IN */}
      {openOptInDialog && (
        <OptInDialog
          open={openOptInDialog}
          setOpen={setOpenOptInDialog}
          data={{
            ContactPhone: record.phone,
            ContactEmail: record.email,
            ContactId: record.id,
            TicketId: 0,
          }}
          psaId={psaId}
          hideNote
          psaType={crmSources.Microsoft.label}
        />
      )}

      {/* Send secure data */}
      {openSecureDialog && (
        <PodDialog
          itemId={+record.id}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="contact"
          psaId={psaId}
          psa={crmSources.Microsoft.label}
          page="secureData"
        />
      )}
    </div>
  );
};

Microsoft365ContactsTableActions.propTypes = {
  record: PropTypes.shape({
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  psaId: PropTypes.number,
};

export default Microsoft365ContactsTableActions;
