import React from 'react';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Input from '@components/Auth/Common/Input';
import FormSwitch from '@components/Auth/Common/FormSwitch';

const KaseyaNoteForm = ({ processing }) => {
  return (
    <>
      <FieldWrapper
        label="Details:"
        labelSize={12}
        contentSize={12}
        fullWidth
        content={
          <Field
            name="details"
            id="details"
            render={Input}
            disabled={processing}
            multiline
            minRows={5}
          />
        }
      />
      {/* DISABLED DUE TO UNAVAILABILITY TO CHANGE PRIVATE STATE FROM AT API */}
      <FieldWrapper
        label="Is Internal:"
        labelSize={12}
        contentSize={12}
        fullWidth
        content={
          <Field
            name="isInternal"
            id="isInternal"
            type="checkbox"
            viewType="selector"
            render={FormSwitch}
            disabled={processing}
            // checked={values.isInternal}
          />
        }
      />
    </>
  );
};

KaseyaNoteForm.propTypes = {
  processing: PropTypes.bool,
};

export default KaseyaNoteForm;
