import { makeRequest } from '../makeRequest';
import { BUFFER_PHONE_NUMBERS, PHONE_NUMBERS } from '../requestResources';

export const getPhoneNumbers = (getAll = false) =>
  makeRequest('GET', `${PHONE_NUMBERS}${getAll ? '?q=getAll' : ''}`);

export const getBufferPhoneNumbers = () =>
  makeRequest('GET', `${BUFFER_PHONE_NUMBERS}`);

export const getVerificationBufferPhoneNumbers = () =>
  makeRequest('GET', `${BUFFER_PHONE_NUMBERS}/verificationNumbers`);
export const deletePhoneNumber = id =>
  makeRequest('DELETE', `${PHONE_NUMBERS}/${id}`);

export const buyPhoneNumber = data =>
  makeRequest('POST', `${BUFFER_PHONE_NUMBERS}/buy/`, data, undefined, false);

export const assignPhoneNumber = (data, assignType = '') =>
  makeRequest('POST', `${BUFFER_PHONE_NUMBERS}/assign/${assignType}`, data);

export const checkIfTenantHasPhone = () =>
  makeRequest('GET', `${PHONE_NUMBERS}/numbers/checkConfigExists`);

export const getPhoneNumbersCountries = () =>
  makeRequest('GET', `${BUFFER_PHONE_NUMBERS}/countries`);
