import { createAsyncThunk } from '@reduxjs/toolkit';
import { TENANT_MODULES } from '@store/slices/resources';
import { UpdateAccessOptionsTemplate } from '@services/requests/admin';

export const ThunkUpdateAccessModuleTemplate = createAsyncThunk(
  `${TENANT_MODULES}/UpdateAccessModuleTemplate`,
  async values => {
    const result = await UpdateAccessOptionsTemplate(values);
    return result;
  },
);

export default {};
