import { createAsyncThunk } from '@reduxjs/toolkit';
import { clearAutotaskCache } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkClearAutotaskCache = createAsyncThunk(
  `${INTEGRATION}/clearAutotaskCache`,
  async ({ crmId }) => {
    await clearAutotaskCache(crmId);
    return true;
  },
);

export default {};
