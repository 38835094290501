import { makeRequest } from '../makeRequest';
import { TRIAGE } from '../requestResources';

export const createTriageTicket = payload =>
  makeRequest('POST', `${TRIAGE}`, payload);

export const closeTriageTicket = id =>
  makeRequest('PUT', `${TRIAGE}/closeItem/${id}`);

export const linkTriageToTicket = payload =>
  makeRequest('POST', `${TRIAGE}/linkToTicket`, payload);

export const closeTriageTickets = ids =>
  makeRequest(
    'PUT',
    `${TRIAGE}/closeItems?${ids.map(id => `ids=${id}`).join('&')}`,
  );

export const softDeleteTriages = ids =>
  makeRequest(
    'DELETE',
    `${TRIAGE}/softDelete?${ids.map(id => `id=${id}`).join('&')}`,
  );

export default {};
